
export const Path = ["id", "company_no", "name", "is_configured"]

export const Heading = (t) => [
    { title: t("id"), field: "id" },
    { title: t("Company No"), field: "company_no" },
    { title: t("Company Name"), field: "name" },
    { title: t("Configuration"), field: "is_configured" }
]

export const Type = [
    { type: ["text"], name: "id" },
    { type: ["text"], name: "company_no" },
    { type: ["text"], name: "name" },
    { type: ["configure_button"], name: "is_configured" },

]

