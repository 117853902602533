import { Box, Button, Divider, Stack, Typography, Grid } from "@mui/material";
import { Contact,ContactAccount } from "./component"
import React, { useState } from "react";
import { useStyles } from "./styles";
import { createState } from "../../utils/createQuotationUtils";
import { returnOppertunityEditPayload } from "./utils";
import { useApolloClient } from "@apollo/client";
import { getLeadDetails } from "../../graphql/quotationQueries";
import { ValidateEmail } from "../../utils";
import { boardingPropertyType } from "../../screens/residentOnboarding/utils/residentOnboard";
import {returnConvertOppertunityEditPayload} from "./utils"
export const CreateLead = ({ company_id, onClose, title = "Create Opportunity", lead_id, reload = () => false, is_convert = false, convert_data,company,proxy_id,is_quotation=false,property_purpose,revenue_type,onNext , state1 , setState1=()=>false,is_opportunity=false , is_lead=false, t = () => false,moduleId=""}) => {

    const classes = useStyles()
    const [step, setStep] = useState(lead_id ? 2 : 1);
    const client = useApolloClient();
    const [dataNew, setDataNew] = React.useState({ ...createState });
    //update state
    const updateStateNew = (key, value, key1, value1) => {
        let error = dataNew.error;
        error[key] = "";
        setDataNew({ ...dataNew, [key]: value, [key1]: value1, error });
    };
    //validate contact
    const validateType = () => {
        let isValid = true;
        let error = dataNew.error;     
        if (!dataNew?.purpose) {
            isValid = false;
            error.purpose = t("Property Type is Required");
        }
        if (!dataNew?.revenue_type || dataNew?.revenue_type === null || dataNew?.revenue_type === "") {
            isValid = false;
            error.revenue_type = t("Revenue Type is Required");
        }
        setDataNew({ ...dataNew, error });
        return isValid;
    };
     //validate contact
     const validataNewContact = () => {
        let isValid = true;
        let error = dataNew.error;
        if(dataNew?.is_exist){
            if (!dataNew?.contact && !is_convert) {
                isValid = false;
                error.contact = "Contact is Required";
            }
            }else{
                if (dataNew?.name?.length === 0) {
                    isValid = false;
                    error.name = t("Contact Name is Required");
                }
                if (dataNew?.email_id?.length === 0) {
                    isValid = false;
                    error.email_id = t("Email ID is Required");
                }
                if(dataNew?.email_id?.length > 0 && !ValidateEmail(dataNew?.email_id)){
                    isValid = false;
                    error.email_id = "Invalid Email ID";
                }
                if (!dataNew?.mobile) {
                    isValid = false;
                    error.mobile = t("Mobile No is Required");
                }
            }

        setDataNew({ ...dataNew, error });
        return isValid;
    };
    //previous btn function
    const previous = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };
    //next function if newlead
    const next = () => {
        if (step === 1) {
            if (validateType()) {
                setStep(2);
            }
        }
        if (step === 2) {
            if (validataNewContact()) {
                let data;
                if(is_quotation){
                    data={
                        is_exist:dataNew?.is_exist,
                        account:dataNew?.billing ?? undefined,
                        contact:dataNew?.is_exist ?  {...dataNew?.contact,image_url:{src:dataNew?.contact?.image_url}}:{
                            first_name:dataNew?.name,
                            label:dataNew?.name,
                            mobileNo:dataNew?.mobile?.mobile,
                            mobile_no_country_code:dataNew?.mobile?.mobile_code,
                            mobile_no:dataNew?.mobile?.mobile,
                            email_id:dataNew?.email_id,
                            email:dataNew?.email_id,
                            image_url:dataNew?.image_url
        
                        }
                    }
                }else{
                    data={
                        ...dataNew,
                        is_exist:dataNew?.is_exist,
                        purpose:dataNew?.purpose?.label,
                        revenue_type:dataNew?.revenue_type?.label,
                        isEdit:lead_id?true:false,
                        account:dataNew?.billing ?? undefined,
                        contact:dataNew?.is_exist ?  {...dataNew?.contact,image_url:dataNew?.contact?.image_url}:{
                            first_name:dataNew?.name,
                            label:dataNew?.name,
                            mobileNo:dataNew?.mobile?.mobile,
                            mobile_no_country_code:dataNew?.mobile?.mobile_code,
                            mobile_no:dataNew?.mobile?.mobile,
                            email_id:dataNew?.email_id,
                            email:dataNew?.email_id,
                            image_url:dataNew?.image_url?.src
        
                        },
                    }
                }

                onNext(data)
            }
        }

    };
    //get oppertunity details
    const getOppertunityDeatils = () => {
        client
            .query({
                query: getLeadDetails(),
                fetchPolicy: "network-only",
                variables: {
                    id: lead_id,
                },
            })
            .then((res) => {
                let data = returnOppertunityEditPayload(res?.data?.lead[0]);
                setDataNew(data);
            })
            .catch((err) => console.log(err));
    };


    React.useEffect(() => {
        if (lead_id) {
            getOppertunityDeatils()
        } else if (is_convert) {
            setStep(2)
            let convertData = returnConvertOppertunityEditPayload({...convert_data?.data,unit:convert_data?.data?.proxy_lead_units}, company, "", proxy_id);
            setDataNew(convertData);
        }else if(is_quotation){
            setDataNew({
                ...dataNew,
                purpose: boardingPropertyType?.find(i => i?.title === property_purpose),
                revenue_type:{label:revenue_type,value:revenue_type}
            })
            
        }
        //eslint-disable-next-line
    }, [])

    const component = {
        1: <Contact t={t} company_id={company_id} is_quotation={is_quotation} is_read_only={is_quotation||is_convert} is_convert={is_convert} setDataNew={setDataNew} updateStateNew={updateStateNew} dataNew={dataNew} is_lead={is_lead} moduleId={moduleId}/>,
        2:<ContactAccount t={t} company_id={company_id} is_read_only={is_convert} setDataNew={setDataNew} updateStateNew={updateStateNew} dataNew={dataNew} is_quotation={is_quotation} is_exist={dataNew?.is_exist} setState1={setState1} state1={state1} is_opportunity={is_opportunity} is_lead={is_lead}/>,
        // 3: <Lead company_id={company_id} is_read_only={is_convert} setDataNew={setDataNew} updateStateNew={updateStateNew} dataNew={dataNew} />,
        // 4: <Units company_id={company_id} is_read_only={is_convert} setCreateState={setDataNew} updateState={updateStateNew} createState={dataNew} />
    }

    return (

        <Box width={500} className={classes.drawerBox}>
            <Box className={classes.header}>
                <Stack direction={"row"} alignItems={"center"} p={1.5}>
                    <Typography className={classes.drawerCloseBtn} onClick={() => {
                        setDataNew(createState)
                        onClose()
                    }}>X</Typography>
                    <Typography className={classes.drawerHeading}>{title}</Typography>
                </Stack>
                <Divider />
            </Box>
            <Box className={classes.body} p={2}>
                {component[step]}
            </Box>
            <Box className={classes.bottom} sx={{ width: "100%" }}>
                <Grid container className={classes.btnroot} spacing={1}>
                    <Grid item xs={4}>
                        <Button
                            className={classes.previousbtn}
                            variant="contained"
                            onClick={previous}
                            disabled={is_convert ? true : lead_id ? step === 2 : step === 1}
                        >
                            {t("Previous")}
                        </Button>
                    </Grid>
                    <Grid item xs={8} textAlign={"end"}>
                        <Button
                            className={classes.submitbtn}
                            variant="contained"
                            onClick={next}
                        >
                            {step===1? t("Next") : t("Create")}
                        </Button>

                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
}