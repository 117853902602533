import gql from "graphql-tag";

export const GET_UNIT_CATEGORY_RESIDENTIAL = (offset, limit, searchText, funParams) => gql`
query GET_UNIT_CATEGORY{
   count:unit_category_master(
     where: {
     name:{iregex:"${searchText}"}
     is_active:true
     name: { in:["Residential Unit","Bedspace","Room Space","Plot","Land","Storage Space","Parking Unit"] }
    }){
      count:count_id
    }

  unit_category_master(
     where: {
     name:{iregex:"${searchText}"}
     is_active:true
    name: { in:["Residential Unit","Bedspace","Room Space","Plot","Land","Storage Space","Parking Unit"] }
    }
     offset:${offset}
       limit:${limit}

  ) {
 label:name
    value:id
}
}`

export const GET_UNIT_CATEGORY_COMMERCIAL = (offset, limit, searchText, funParams) => gql`
query GET_UNIT_CATEGORY{
   count:unit_category_master(
     where: {
     name:{iregex:"${searchText}"}
     is_active:true
     name: { in:["Commercial Unit","Workspace","Room Space","Plot","Land","Storage Space","Parking Unit","Bedspace","Marketing Space"] }
    }){
      count:count_id
    }

  unit_category_master(
     where: {
     name:{iregex:"${searchText}"}
     is_active:true
     name: { in:["Commercial Unit","Workspace","Room Space","Plot","Land","Storage Space","Parking Unit","Bedspace","Marketing Space"] }
    }
     offset:${offset}
       limit:${limit}

  ) {
 label:name
    value:id
}
}`

export const GET_UNIT_CATEGORY_LEASE = (offset, limit, searchText, funParams) => gql`
query GET_UNIT_CATEGORY{
   count:unit_category_master(
     where: {
     name:{iregex:"${searchText}"}
     is_active:true
     ${funParams?.ids?.length>0? `id:{in:[${funParams?.ids}]}`:``}
    }){
      count:count_id
    }

  unit_category_master(
     where: {
     name:{iregex:"${searchText}"}
     is_active:true
     ${funParams?.ids?.length>0? `id:{in:[${funParams?.ids}]}`:``}
    }
     offset:${offset}
       limit:${limit}

  ) {
 label:name
    value:id
}
}`