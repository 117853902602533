import * as React from "react"

const Security = (props) => (
  <svg
    data-name="Group 96941"
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.212}
        y1={0.046}
        x2={0.784}
        y2={1.408}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#5961c3" />
        <stop offset={1} stopColor="#3a41ac" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.843}
        y1={0.172}
        x2={-0.382}
        y2={0.811}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#e5a505" />
        <stop offset={0.011} stopColor="#e9a804" />
        <stop offset={0.061} stopColor="#f4b102" />
        <stop offset={0.133} stopColor="#fbb600" />
        <stop offset={0.331} stopColor="#fdb700" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={1.367}
        y1={0.173}
        x2={0.178}
        y2={0.793}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fede00" />
        <stop offset={1} stopColor="#ffd000" />
      </linearGradient>
    </defs>
    <g transform="translate(6.634 4)">
      <ellipse
        data-name="Ellipse 129193"
        cx={8.78}
        cy={4.976}
        rx={8.78}
        ry={4.976}
        transform="translate(.585)"
        fill="#5059c9"
      />
      <path
        data-name="Path 93457"
        d="M17.366 30.571C12.193 30.571 8 33.452 8 36.592v.669a1.17 1.17 0 0 0 1.171 1.171h16.39a1.17 1.17 0 0 0 1.171-1.171v-.669c0-3.14-4.193-6.021-9.366-6.021Z"
        transform="translate(-8 -14.432)"
        fill="url(#a)"
      />
      <path
        data-name="Path 93458"
        d="M12.293 15.851a9.222 9.222 0 0 1-2.927 6.978 9.222 9.222 0 0 1-2.927-6.978v-2.388h5.854Z"
        fill="#fff"
      />
      <path
        data-name="Path 93459"
        d="M12.293 15.851a9.222 9.222 0 0 1-2.927 6.978 9.222 9.222 0 0 1-2.927-6.978v-2.388h5.854Z"
        fill="#fff"
      />
      <path
        data-name="Path 93460"
        d="M12.293 15.805c0 1.11-2.927 2.341-2.927 2.341s-2.927-1.231-2.927-2.341v-4.118h5.854Z"
        fill="#ecbc3e"
      />
      <path
        data-name="Path 93461"
        d="m8.195 18.731.585.585-.457 2.393a6.986 6.986 0 0 0 1.043 1.143 6.978 6.978 0 0 0 1.043-1.143l-.457-2.392.585-.585-1.171-.586Z"
        fill="#3a41ac"
      />
      <path
        data-name="Path 93462"
        d="M14.587 8.78h-.308v2.341h.408a.6.6 0 0 0 .609-.433l.2-1a.834.834 0 0 0-.909-.908Z"
        fill="#ecbc3e"
      />
      <path
        data-name="Path 93463"
        d="M4.146 8.78h.308v2.341h-.412a.6.6 0 0 1-.609-.433l-.2-1a.834.834 0 0 1 .913-.908Z"
        fill="#ecbc3e"
      />
      <path
        data-name="Path 93464"
        d="M4.218 6.133a13.279 13.279 0 0 0-.12 1.872v3.921c0 2.006 3.161 4.465 5.268 4.465s5.268-2.459 5.268-4.465V8.005a14.185 14.185 0 0 0-.075-1.513Z"
        fill="#ffd56b"
      />
      <path
        data-name="Path 93465"
        d="M9.366 4.417c-4.683 0-5.854 1.455-5.854 3.193a3.519 3.519 0 0 0 .585 2.341c0-1.756.585-4.683 2.341-4.683.742 0 1.331 1.171 2.927 1.171 1.348 0 1.683-1.171 2.927-1.171 1.756 0 2.341 2.927 2.341 4.683.585-.585.585-2.341.585-2.341.001-2.008-1.169-3.193-5.852-3.193Z"
        fill="#864407"
      />
      <ellipse
        data-name="Ellipse 129194"
        cx={4.683}
        cy={2.341}
        rx={4.683}
        ry={2.341}
        transform="translate(4.683 4.683)"
        fill="#5059c9"
      />
      <path
        data-name="Path 93466"
        d="M9.366 4.098c-3.233 0-5.854.561-5.854 1.254V7.61s.585-.585 5.854-.585 5.854.585 5.854.585V5.352c-.001-.693-2.621-1.254-5.854-1.254Z"
        fill="#7b83eb"
      />
      <path
        data-name="Path 93467"
        d="M25.171 10.585 24 10v2.341a1.17 1.17 0 0 0 1.171-1.171Z"
        transform="translate(-14.634 -5.902)"
        fill="url(#b)"
      />
      <path
        data-name="Path 93468"
        d="M23.171 10 22 10.585v.585a1.17 1.17 0 0 0 1.171 1.171Z"
        transform="translate(-13.805 -5.902)"
        fill="url(#c)"
      />
    </g>
  </svg>
)

export default Security
