import { Box, Button, Divider, Grid, IconButton, Paper, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AlertDialog, FilterGenerator, SearchFilter, Subheader, TableWithPagination } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, enum_types, enumSelect, getCompanyOption, getRoutePermissionNew, getSiteVisitRequestHeading, NetWorkCallMethods, siteVisitRequestDataType, siteVisitRequestPath } from "../../utils";
import { CreateInspection } from "./components/createInspection";
import { useStyles } from "./style";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import moment from "moment";
import FilterIcon from "../../assets/filterIcon";
const UnitReadiness = ({ t }) => {
    const classes = useStyles()
    const alert = useContext(AlertContext)
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)
    const [companyList, setCompanyList] = useState([])
    const [selectedCompany, setSelectedCompany] = useState({})
    const [permission, setPermission] = useState({})
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [drawer, setDrawer] = React.useState(false)
    const [statusOptionList, setStatusOptionsList] = React.useState([])
    const [filterData, setFilterData] = React.useState({
        status: null,
        dateRange: {
            startDate: null,
            endDate: null
        },
        resource: null
    });
    const [isCreate, setIsCreate] = useState(false)
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [tableData, setTableData] = useState([])
    // const StatusOptionList = [
    //     { label: 'Pending', value: 'Pending' },
    //     { label: 'Completed', value: 'Completed' },
    // ]
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getSiteVisitData(0, limit, value?.value, "", filterData)
    }
    const handleSearch = (value) => {
        setSearchText(value)
        getSiteVisitData(0, 10, selectedCompany?.value, value, filterData)
    }
    const getEnum = async () => {
        const result = await enumSelect([
            enum_types?.agreement_inspection_status_type_new
        ]);
        setStatusOptionsList(result?.agreement_inspection_status_type_new)
    };
    const handleIcon = (type, data) => {
        history.push({
            pathname: Routes?.viewUnitReadinessDetails,
            state: {
                request_id: data?.request_id,
                reference_id: data?.job_no,
                unit_id: data?.unit_id,
                agreement_inspection_id: data?.id,
                id:data?.id,
                status: data?.status,
                request_type: "unit_readiness",
                currency_code:selectedCompany?.code
            }
        })
    }
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getSiteVisitData(offset, 10, selectedCompany?.value, "", filterData)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getSiteVisitData(0, value, selectedCompany?.value, "", filterData)
    }
    const onClose = () => {
        setIsCreate(false)
    }
    const openFilter = () => {
        setDrawer(true)
    }
    const handleCloseFilter = () => {
        setDrawer(false)
    }
    //To filter data based on status
    const handleFilter = (data) => {
        getSiteVisitData(0, 10, selectedCompany?.value, "", data)
        setFilterData(data)
    }
    const manualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                ...i,
                label: i?.name,
                value: i?.id
            }
        })
        return details
    };
    //get tableData 
    const getSiteVisitData = (offset = 0, limit = 10, company_id, search = "", filterData) => {
        const payload = {
            company_id: company_id,
            offset: offset,
            limit: limit,
            request_type: ["unit_readiness"],
            search: search,
            "resources": filterData?.resource?.map((x) => { return x?.value }) ?? [],
            "start_date": filterData?.dateRange?.startDate ? moment(filterData?.dateRange?.startDate).format("YYYY-MM-DD") : null,
            "end_date": filterData?.dateRange?.endDate ? moment(filterData?.dateRange?.endDate).format("YYYY-MM-DD") : null,
            "status": filterData?.status?.map((x) => { return x?.value }) ?? null
        }

        NetworkCall(
            `${config.api_url}/general_inspection/`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            const data = response?.data?.data?.data?.map((val) => {
                return ({
                    ...val,
                    hours: val?.eta > 0 ? `${val?.eta} ${val?.eta > 1 ? "hrs" : "hr"}` : 0,
                    other_hours: val?.other_hours > 0 ? `${val?.other_hours} ${val?.other_hours > 1 ? "hrs" : "hr"}` : 0,
                    KPI: val?.overbooking_count > 0 ? "Delayed" : val?.ontime_count > 0 ? "Ontime" : "-",
                    actual_hours: val?.actual_hours !== null ? (val?.actual_hours > 0 ? `${val?.actual_hours} ${val?.actual_hours > 1 ? "hrs" : "hr"}` : "<1 hr") : "-"
                })
            })
            setTableData({ list: data, count: response?.data?.data?.count })

        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    //To filter data based on status
    // const handleFilter = (data) => {
    //     getGeneralInspectionData(selectedCompany?.value, 0, limit, "", data)
    //     setFilterData(data)
    // }
    React.useEffect(() => {

        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth)

        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                getEnum()
                getSiteVisitData(0, 10, company?.selected?.value, "", filterData)
            }
        }
        // eslint-disable-next-line
    }, [auth])
    const reload = () => {
        getSiteVisitData(0, 10, selectedCompany?.value, "", filterData)
    }
    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={true}
                    title={t("unitReadiness")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e)
                    }}
                />
                {
                    loading ?
                        <NewLoader minusHeight="158px" />
                        :
                        <Paper className={classes.root}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={4}>
                                    {/*search */}
                                    <SearchFilter
                                        handleChange={handleSearch}
                                        value={searchText}
                                        placeholder={t("searchInspection")}
                                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                    />
                                </Grid>
                                <Grid item xs={8} alignContent="flex-end">
                                    <Stack
                                        divider={<Divider orientation="vertical" flexItem />}
                                        spacing={2}
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        direction="row">
                                        <Box onClick={openFilter}>
                                        {(filterData?.status?.length > 0 || filterData?.resource?.length > 0 || filterData?.dateRange?.startDate !== null) ?
                                                <FilterIcon />
                                                : <IconButton
                                                    size="small"
                                                    className={classes.filterIconbtn}
                                                >
                                                    <img src="/images/filter.svg" alt="filter" />
                                                </IconButton>}
                                        </Box>



                                        {/*add btn */}
                                        {/* {permission?.create && */}
                                        <Button onClick={() => setIsCreate(true)} className={classes.addIcon} variant="contained">{t("createNewInspection")}</Button>
                                        {/* }  */}

                                    </Stack>
                                </Grid>
                            </Grid>
                            <TableWithPagination
                                heading={getSiteVisitRequestHeading(t)}
                                rows={tableData?.list}
                                dataType={siteVisitRequestDataType}
                                path={siteVisitRequestPath}
                                showpagination
                                tableType="no-side"
                                handleIcon={handleIcon}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={tableData?.count}
                                page={page}
                                limit={limit}
                                className={classes.reqTable}
                                view={true}
                                height={`calc(100vh - 300px)`}
                                edit={true}
                                delete={true} />
                        </Paper>
                }
                <AlertDialog
                    open={isCreate}
                    header={t("Create New Inspection")}
                    medium
                    onClose={() => onClose()}
                    component={
                        <CreateInspection t={t} companyId={selectedCompany?.value} onClose={onClose} reload={reload} />
                    }
                />
                {drawer && (
                    <FilterGenerator
                        open={drawer}
                        onClose={() => handleCloseFilter()}
                        components={[
                            {
                                component: "select",
                                value: filterData?.resource,
                                options: [],
                                isMulti: true,
                                label: t("Resource"),
                                state_name: "resource",
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "/resources/getAll",
                                        {
                                            company_id: selectedCompany?.value,
                                            active: [true]

                                        },
                                        search,
                                        array,
                                        handleLoading,
                                        "result",
                                        {},
                                        manualResponse
                                    )
                                ,
                                debounceTimeout: 800,
                                isPaginate: true,
                            },
                            {
                                component: "date_range",
                                value: filterData?.dateRange,
                                state_name: "dateRange",
                                label: t("Requested On"),
                                toLabel: t("To"),
                                startPlaceholder: t("Select Start Date"),
                                endPlaceholder: t("Select End Date")
                            },
                            {

                                component: "select",
                                value: filterData?.status,
                                options: statusOptionList,
                                isMulti: true,
                                state_name: "status",
                                label: t("Status"),

                            },


                        ]}
                        onApply={(value) => handleFilter(value)}
                    />
                )}
                {/* filter component
                {openFilter && (
                    <FilterGenerator
                        open={openFilter}
                        onClose={() => setOpenFilter(false)}
                        components={[
                            {
                                component: "toggleButton",
                                value: filterData?.status,
                                state_name: "status",
                                label: "Status",
                                options: StatusOptionList,
                                isMulti: true
                            },


                        ]}
                        onApply={(value) => handleFilter(value)}
                    />
                )} */}
            </Box>
        )
    }
    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
}

export default withNamespaces("inspection")(UnitReadiness) 