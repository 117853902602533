import { Box } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { DetailsCardCoponent } from "../../../components";

const AgreementInformation = ({ details = {}, t }) => {
    const card = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("agreementNumber"),
            subtitle: `${details?.agreement_no}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "date",
            heading: t("agreementStartDate"),
            subtitle: `${details?.lease_start_date}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "date",
            heading: t("agreementEndDate"),
            subtitle: `${details?.lease_end_date}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("agreementType"),
            subtitle: `${details?.agreement_type ?? '-'}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("agreementRevenueType"),
            subtitle: `${details?.quotation?.revenue_type ?? "-"}`
        },

    ]

    return (
        <Box p={2}>
            <DetailsCardCoponent components={card} />
        </Box>
    )
}
export default withNamespaces("agreement")(AgreementInformation); 