import { useApolloClient } from "@apollo/client";
import { Box, Fab } from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import React from "react";
import { AlertDialog, LoadingSection, TableWithPagination } from "../../../components";
import { AlertContext } from "../../../contexts";
import { GET_TODO, UPDATE_TODO } from "../../../graphql/oppertunityQueries";
import { AlertProps } from "../../../utils";
import { tododataType, todoheading, todopath, todoState } from "../../../utils/oppertunityUtils";
import { TodoForm } from "../component";
import { useStyles } from "../styles";


export const Todo = ({ oppertunity_id = "", permission = {},disabled=false,t=()=>false }) => {
    const language = localStorage.getItem("i18nextLng")
    const classes = useStyles();
    const client = useApolloClient();
    const [open, setOpen] = React.useState({ bool: false, id: null })
    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState({ ...todoState });
    const alert = React.useContext(AlertContext);
    const [list, setList] = React.useState({
        list: [],
        count: 0
    })
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);

    //onclose dialog
    const onClose = () => {
        setOpen({
            bool: !open?.bool,
            id: null
        })
        setData({ ...todoState })
    }
    //get todo list
    const getTodo = (offset = 0) => {
        setLoading(true)
        client.query({
            query: GET_TODO,
            fetchPolicy: 'network-only',
            variables: {
                id: oppertunity_id,
                offset: offset,
                limit: limit
            }
        }).then((response) => {
            setList({
                list: response?.data?.lead_checklist?.map((val) => {
                    return {
                        ...val,
                        icon: "edit"
                    }
                }),
                count: response?.data?.count[0]?.count
            })
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })

    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getTodo(offset, limit)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getTodo(0, value,)
    }
    //initialload
    React.useEffect(() => {
        if (permission?.read) {
            getTodo(0)
        }
        // eslint-disable-next-line 
    }, [permission])
    //onCheck
    const onCheck = (val) => {
        client.mutate({
            mutation: UPDATE_TODO,
            variables: {
                id: val?.id,
                payload: {
                    is_active: val?.is_active ? true : false,
                }
            }
        }).then((response) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Todo Successfully Updated"),
            });
        }).catch((err) => {
            console.log(err)
        })
    }
    //handle icon
    const handleIcon = (type, value) => {

        if (type === "edit") {
            setData({ ...data, name: value?.name, description: value?.description, is_active: value?.is_active })
            setOpen({
                bool: true,
                id: value?.id
            })
        }
        if (type === "checkBox") {
            onCheck(value)
        }
    }

    return (
        <>
            {
                loading ? <LoadingSection top={"5vh"} message={t("Loading Todo...")} /> :
                    <Box className={classes.tableRoot}>
                        {/*to do table */}
                        <TableWithPagination
                            heading={todoheading(t)}
                            rows={list?.list}
                            path={todopath}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            tableType="no-side"
                            dataType={tododataType}
                            height={`calc(100vh - 360px)`}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={list?.count}
                            page={page}
                            limit={limit}
                            count={list?.count}
                            handleIcon={handleIcon}
                            view={permission?.read}
                            edit={permission?.update}
                            delete={permission?.delete} />
                        {(permission?.create && !disabled) && <Fab className={language === "ar" ? classes.speedDialArabic : classes.speedDial} onClick={onClose}>
                            <SpeedDialIcon />
                        </Fab>}
                        {/*to do form */}
                        <AlertDialog
                            open={open?.bool}
                            isNormal={true}
                            header={t("Create To Do")}
                            onClose={onClose}
                            component={
                                <TodoForm t={t} id={open?.id} data={data} setData={setData} oppertunity_id={oppertunity_id} onClose={onClose} reload={() => getTodo(0)} />
                            }
                        />
                    </Box>
            }
        </>
    )
}