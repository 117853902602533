
import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    drawerBox: {
        width: '456px',
        position: "relative"
    },
    header: {
        position: "fixed",
        top: 0,
        width: "100%",
        backgroundColor:"#F2F4F7"
    },
    bottom: {
        position: "fixed",
        bottom: 0,
        padding:'8px',
        width:'456px'
    },
    body: {
        // position: "fixed",
        top: 0,
        marginTop: '64px',
        height: "calc(100vh - 130px)",
        overflow: "auto",
        width:"100%"
    },
    drawerCloseBtn: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
        background: theme.palette.background.tertiary,
        borderRadius: 4,
        height: 32,
        width: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    drawerHeading: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
        marginInlineStart:"16px"
    },
    btnroot: {
        backgroundColor: "white",
        padding: "8px 14px 14px 14px",
    },
    previousbtn: {
        backgroundColor: "white",
        color: theme.typography.color.primary,
        border: "1px solid #CED3DD",
        borderRadius: theme.palette.borderRadius,
        padding: "8px 12px",
        boxShadow: "none",
        '&:hover': {
            backgroundColor: "white",
        },
        fontFamily:Bold

    },
    submitbtn: {
        borderRadius: theme.palette.borderRadius,
        marginInlineStart: "8px",
        padding: "8px 12px",
        boxShadow: "none",
        fontFamily:Bold
    },
    labelBold:{
        color:"#091B29",
        fontFamily:Bold,
        fontSize:"0.875rem",
        marginBottom:"8px",

    }
}))