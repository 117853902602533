import { useApolloClient } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DialogDrawer } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { UOM_LIST } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, PurposeHead, PurposePath, occupencyDashboradHead, occupencyDashboradPath } from "../../utils";
import { propertyDetailsFunction } from "./common";
import { CardDetails } from "./components";
import { HandOverDateComponent } from "./handoverDate";
import { Map } from "./map";
import { useStylesCreation } from "./style";
import { withNamespaces } from "react-i18next";
import GraphComponent from "../propertymanagerdashboard/components/graphcomponent";
const scale = {
  x: {
    stacked: true,
    grid: {
      // drawBorder: false,
      display: false,
    }
  },
  y: {
    stacked: true,
    grid: {
      // drawBorder: false,
      display: false,
    }
  },
};

// eslint-disable-next-line

const scaleLine = {
  x: {
    grid: {
      display: false
    }
  },
  y: {
    grid: {
      display: false
    }
  }
}
const DashBoard = (props) => {
  const { t = () => false } = props;
  // states
  const client = useApolloClient()
  const [propertyDetails, setPropertyDetails] = useState("");
  const [zoom, setZoom] = useState(false);
  const [zoomGraph, setZoomGraph] = useState("");
  const [loader, setLoader] = useState(false);
  const [occupancyData, setOccupancyData] = useState({
    list: [],
    count: 0
  })
  const [selectedUOM, setSelectedUOM] = React.useState([])
  const [UOMOptions, setUOMOptions] = React.useState([])
  const [purposeAreaData, setPurposeAreaData] = React.useState({
    list: [],
    count: 0
  })
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [occupancyPage, setOccupancyPage] = useState(1)
  const [occupancyLimit, setOccupancyLimit] = useState(10)
  // context
  const alert = React.useContext(AlertContext);

  // use history

  const history = useHistory()

  //   classes
  const classes = useStylesCreation();
  const GetUOMLIST = () => {
    client
      .query({
        query: UOM_LIST,
        fetchPolicy: "network-only",
        variables: {

        }
      }).then((res) => {
        setUOMOptions(res?.data?.uom_master)
        setSelectedUOM(res?.data?.uom_master?.[0])
        getPurposeAreaStatistics(0, 10, props?.selectedcompany?.value, res?.data?.uom_master?.[0]?.value)
      })
  }
  //getDashboardDetails
  const getDashboardDetails = (company) => {
    setLoader(true);
    const payload = {
      company_id: company ?? undefined,
    };
    NetworkCall(
      `${config.api_url}/property_manager`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const property = propertyDetailsFunction(res?.data?.data, t);
        setPropertyDetails(property);
        setLoader(false);
      })
      .catch((error) => {
        // alert.setSnack({
        //   ...alert,
        //   open: true,
        //   severity: AlertProps.severity.error,
        //   msg: "Some Thing Went Wrong",
        //   vertical: AlertProps.vertical.top,
        //   horizontal: AlertProps.horizontal.center,
        // });
      });
  }
  const getOccupancyData = (offset = 0, limit = 10, company, fetchMore = false) => {
    const payload = {
      company_id: company ?? undefined,
      start: offset,
      length: limit,
    };
    NetworkCall(
      `${config.api_url}/property_manager/occpancy_vs_property`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = res?.data?.data?.data
        if (fetchMore) {
          setOccupancyData({ list: occupancyData?.concat(result), cout: res?.data?.data?.count })
        }
        else {
          setOccupancyData({ list: result, count: res?.data?.data?.count })
        }
        setLoader(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  }
  // get purpose Area statistics
  const getPurposeAreaStatistics = (offset = 0, limit = 10, company, uom_id = "", fetchMore = false) => {
    const payload = {
      company_id: company ?? undefined,
      start: offset,
      length: limit,
      uom_id: uom_id
    };
    NetworkCall(
      `${config.api_url}/property_manager/purpose_area_statistics`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = res?.data?.data?.list
        if (fetchMore) {
          setPurposeAreaData({ list: purposeAreaData?.concat(result), count: res?.data?.data?.count })
        }
        else {
          setPurposeAreaData({ list: result, count: res?.data?.data?.count })
        }
        setLoader(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  }

  // zoom function
  const zoomGraphFunction = (e, data) => {
    setZoomGraph(e);
    setZoom(true);
  };
  useEffect(() => {
    // company get
    if (props?.selectedcompany?.label?.length > 0 && props?.selectedTeams?.label?.length > 0) {
      getDashboardDetails(props?.selectedcompany?.value, props?.selectedTeams);
      getOccupancyData(0, 10, props?.selectedcompany?.value)
      GetUOMLIST()
    }
    //eslint-disable-next-line
  }, [props?.selectedTeams]);

  // close popup
  const handleCloseModule = () => {
    setZoom(false);
  };
  const handleChangeUOM = (val) => {
    setSelectedUOM(val)
    getPurposeAreaStatistics(0, 10, props?.selectedcompany?.value, val?.value)
  }
  // const getHeigth =()=>{
  //   switch(val)
  // }

  const occupancyNavigate = (propertyId) => {
    history.push(Routes.unit + "?propertyID=" + propertyId)
  }

  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getPurposeAreaStatistics(offset, 10, props?.selectedcompany?.value, selectedUOM?.value)
  }
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getPurposeAreaStatistics(0, limit, props?.selectedcompany?.value, selectedUOM?.value)
  }
  const handleOccupancyPagination = (value) => {
    setOccupancyPage(value);
    let offset = (value - 1) * limit;
    getOccupancyData(offset, 10, props?.selectedcompany?.value)
  }
  const handleOccupancyChangeLimit = (value) => {
    setOccupancyLimit(value);
    setPage(1);
    getOccupancyData(0, limit, props?.selectedcompany?.value)
  }
  return (loader ?
    (
      <NewLoader minusHeight="100px" />
    ) :
    (<div className={classes.root}>
      {/* section 1 */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={1.5} sx={{ display: "flex" }}>
          {/* property cards */}
          <Grid container spacing={2}>
            {propertyDetails?.propertyDetails?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} padding={16} maxWidth="250px" t={t} />
                </Grid >
              );
            })}
          </Grid >
        </Grid >
        <Grid item xs={12} sm={8} lg={3}>
          {/* property graph */}
          <GraphComponent
            title={t("Property Types")}
            header={t("Property Types")}
            graphData={propertyDetails?.propertyType ?? []}
            isPie
            margin={"40px"}
            onZoomClick={zoomGraphFunction}
            isZoom
            minWidth={true}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
              }
            }
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '16px',
                sm: "3px",
              },
              height: {
                md: "400px"
              }
            }}
            t={t}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={1.5} sx={{ display: "flex" }}>
          {/* unit card details */}
          <Grid container spacing={2}>
            {propertyDetails?.unitDetails?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} padding={16} t={t} />
                </Grid >
              );
            })}
          </Grid >
        </Grid >
        <Grid item xs={12} sm={8} lg={3}>
          {/* unit graph */}
          <GraphComponent
            title={t("Unit Status")}
            header={t("Unit Status")}
            graphData={propertyDetails?.unitstatusGraph ?? []}
            isBar
            scale={scale}
            margin={"0px"}
            onZoomClick={(e) => zoomGraphFunction(e, "isBar")}
            isZoom
            gheight={250}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
            t={t}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={3} sx={{ display: "flex" }}>
          {/* reveserd details card */}
          <Grid container spacing={2}>
            {propertyDetails?.unitStatus?.map((x) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={6}>
                  <CardDetails data={x} padding={16} t={t} />
                </Grid>
              );
            })}
          </Grid>
        </Grid >
      </Grid >

      {/* setion2 */}
      < Box mt={2} >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={7}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2.4} lg={2.4} sx={{ display: "flex" }}>
                {/* residental cards */}
                <Grid container spacing={2}>
                  {propertyDetails?.residentalDetails?.map((x) => {
                    return (
                      <Grid item xs={12}>
                        <CardDetails t={t} data={x} maxWidth="250px" padding={16} />
                      </Grid >
                    );
                  })}
                </Grid >
              </Grid >
              <Grid item xs={12} sm={4.8} lg={4.8}>
                {/* Unit Category graph */}
                <GraphComponent
                  title={t("Unit Category")}
                  header={t("Unit Category")}
                  graphData={propertyDetails?.unit_catagory ?? []}
                  isBar
                  scale={scale}
                  margin={"20px"}
                  onZoomClick={(e) => zoomGraphFunction(e, "isBar")}
                  isZoom
                  gheight={250}
                  justifyContent={'start'}
                  gradiantStyle={{
                    margin: '15px 0px 0px',
                    top: {
                      lg: "auto",
                      md: '7px',
                      sm: "3px",
                    },
                    height: {
                      sm: "63px"
                    }
                  }}
                  t={t}
                />
              </Grid>
              <Grid item xs={12} sm={4.6} lg={4.8}>
                {/* Unit Category graph */}
                <GraphComponent
                  title={t("Unit Type")}
                  header={t("Unit Type")}
                  graphData={propertyDetails?.unitType}
                  isPie
                  margin={"40px"}
                  minWidth={true}
                  onZoomClick={zoomGraphFunction}
                  isZoom
                  textMargin={'0px 0px 16px'}
                  divider1={
                    {
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 12
                    }
                  }
                  divider={
                    {
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 12
                    }
                  }
                  gradiantStyle={{
                    margin: '15px 0px 0px',
                    top: {
                      lg: "auto",
                      md: '16px',
                      sm: "0px important",
                    },
                    bottom: {
                      lg: "auto",
                      md: '16px',
                      sm: "0px",
                    },
                    height: {
                      md: "400px"
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2.4} lg={2.4} sx={{ display: "flex" }}>
                {/* residental cards */}
                <Grid container spacing={2}>
                  {propertyDetails?.soldDetails?.map((x) => {
                    return (
                      <Grid item xs={12}>
                        <CardDetails data={x} maxWidth="250px" padding={16} t={t} />
                      </Grid >
                    );
                  })}
                </Grid >
              </Grid >
              <Grid item xs={12} sm={9.4} lg={9.6}>
                <GraphComponent
                  isTable
                  isSelect
                  title={t("Unit Type & Area Statistics")}
                  header={t("Unit Type & Area Statistics")}
                  tableData={purposeAreaData?.list}
                  tabelheight={350}
                  heading={PurposeHead(t)}
                  path={PurposePath}
                  handleChange={handleChangeUOM}
                  handlePagination={handlePagination}
                  handleChangeLimit={handleChangeLimit}
                  options={UOMOptions}
                  value={selectedUOM}
                  totalRowsCount={purposeAreaData?.count}
                  page={page}
                  limit={limit}
                  showpagination={true}
                  dataType={[
                    // { type: ["text"], name: "property_no" },
                    { type: ["text"], name: "purpose" },
                    { type: ["text"], name: "carpetArea" },
                    { type: ["text"], name: "buildupArea" },
                    { type: ["text"], name: "superbuildupArea" },
                    { type: ["text"], name: "unit_count" },
                  ]}
                  t={t}
                  occupancyNavigate={occupancyNavigate}
                  title_div_style={{
                    paddingBottom: 0
                  }}
                />
              </Grid>
            </Grid >
          </Grid >
          <Grid item xs={12} sm={12} lg={5}>
            <Map selectedcompany={props?.selectedcompany} selectedTeams={props?.selectedTeams} t={t} />
          </Grid>
        </Grid>
      </Box >
      {/* section 3 */}
      < Box mt={2} >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={6}>
            <HandOverDateComponent
              selectedcompany={props?.selectedcompany} selectedTeams={props?.selectedTeams} t={t}
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={6}>
            <GraphComponent
              isTable
              title={t("Occupancy By Property")}
              header={t("Occupancy By Property")}
              tableData={occupancyData?.list ?? []}
              tabelheight={400}
              heading={occupencyDashboradHead(t)}
              path={occupencyDashboradPath}
              page={occupancyPage}
              limit={occupancyLimit}
              handleChangeLimit={handleOccupancyChangeLimit}
              handlePagination={handleOccupancyPagination}
              showpagination={true}
              totalRowsCount={occupancyData?.count}
              dataType={[
                // { type: ["text"], name: "property_no" },
                { type: ["text"], name: "name" },
                { type: ["text"], name: "total_unit" },
                { type: ["text"], name: "vacant_unit" },
                { type: ["text"], name: "occupied_unit" },
                { type: ["text"], name: "occupied_percentage" },
              ]}
              occupancyNavigate={false}
              t={t}
            />
            {/* <RequestsComponent selectedcompany={props?.selectedcompany} selectedTeams={props?.selectedTeams} matches={matches} /> */}
          </Grid>
        </Grid >
      </Box >
      {/* section 4 */}
      {/* <Grid container spacing={2} mt={0.3}>
        <Grid item xs={12} sm={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6}>
              Unit Category graph
              <GraphComponent
                title={"Unit Category"}
                graphData={propertyDetails?.unit_catagory ?? []}
                isBar
                scale={scale}
                margin={"30px 0px"}
                onZoomClick={(e) => zoomGraphFunction(e, "isBar")}
                isZoom
                gheight={450}
                gwidth={700}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <GraphComponent
                isTable
                title={"Occupancy By Property"}
                tableData={propertyDetails?.property_unit_occupancy ?? []}
                tabelheight={matches ? "calc(100vh - 355px)" : "calc(100vh - 248px)"}
                heading={occupencyDashboradHead}
                path={occupencyDashboradPath}
                dataType={[
                  { type: ["text"], name: "property_no" },
                  { type: ["text"], name: "name" },
                  { type: ["text"], name: "total_units" },
                  { type: ["text"], name: "vacant" },
                  { type: ["text"], name: "total_occupied_units" },
                  { type: ["text"], name: "occupancy" },
                ]}
                occupancyNavigate={occupancyNavigate}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <GraphComponent
                title={"Vacant Units By Property"}
                graphData={propertyDetails?.vacant_unit_by_property ?? []}
                isBar
                indexAxis={"y"}
                margin={"30px"}
                isDatalabels
                onZoomClick={(e)=>zoomGraphFunction(e,"isBar")}
                isZoom
                gheight={450}
                gwidth={700}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6}>
              <GraphComponent
                title={"Occupancy Trend Analysis"}
                graphData={
                  propertyDetails?.property_occupancy_trends ?? []
                }
                line
                margin={"30px"}
                onZoomClick={(e)=>zoomGraphFunction(e,"isBar")}
                isZoom
                scale={scaleLine ?? false}
                gheight={240}
                // gwidth={800}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <GraphComponent
                isTable
                title={"Occupancy By Property"}
                tableData={propertyDetails?.property_unit_occupancy ?? []}
                tabelheight={matches ? "calc(100vh - 355px)" : "calc(100vh - 248px)"}
                heading={occupencyDashboradHead}
                path={occupencyDashboradPath}
                dataType={[
                  { type: ["text"], name: "property_no" },
                  { type: ["text"], name: "name" },
                  { type: ["text"], name: "total_units" },
                  { type: ["text"], name: "total_occupied_units" },
                  { type: ["text"], name: "occupancy" },
                ]}
                occupancyNavigate={occupancyNavigate}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}

      {/* zoom dialog for each graph */}
      <DialogDrawer
        header={t(zoomGraph)}
        maxWidth={zoomGraph === "Map View" ? "lg" : "md"}
        handleClose={handleCloseModule}
        open={zoom}
        height={zoomGraph === "Map View" ? '700px' : "auto"}
        borderRadius={"12px"}
        padding={'0px'}
        onClose={handleCloseModule}
        component={
          <div>
            {zoomGraph === "Property Types" && (
              <GraphComponent
                title={t("Property Types")}
                graphData={propertyDetails?.propertyType ?? []}
                isPie
                margin={"20px"}
                padding={"16px"}
                maxHeightGraph={"250px"}
                minWidth={false}
                height={"auto"}
                is_popUp
                justifyContent={'start'}
                divider1={
                  {
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7
                  }
                }

                gradiantStyle={{
                  margin: '0px 0px 0px',
                  display: "flex",
                  alignItems: "center",
                  grid_template: "100%",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '-8px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
                t={t}
              />
            )}
            {zoomGraph === "Unit Status" && (
              <GraphComponent
                title={t("Unit Status")}
                header={t("Unit Status")}
                graphData={propertyDetails?.unitstatusGraph}
                isBar
                scale={scale}
                margin={"20px"}
                minWidth={false}
                gheight={'500px'}
                gwidth={'400px'}
                is_popUp
                justifyContent={'start'}
                padding={"0px 16px 16px"}
                divider1={
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 8,
                    md: 8,
                    lg: 8
                  }
                }

                gradiantStyle={{
                  margin: '15px 0px 0px',
                  grid_template: "100%",
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '16px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
                t={t}
              />
            )}
            {zoomGraph === "Unit Type" && (
              <GraphComponent
                title={t("Unit Type")}
                header={t("Unit Type")}
                graphData={propertyDetails?.unitType}
                isPie
                margin={"20px"}
                padding={"16px"}
                maxHeightGraph={"250px"}
                minWidth={false}
                height={"auto"}
                is_popUp
                justifyContent={'start'}
                divider1={
                  {
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7
                  }
                }

                gradiantStyle={{
                  margin: '0px 0px 0px',
                  display: "flex",
                  alignItems: "center",
                  grid_template: "100%",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '-8px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
                t={t}
              />
            )}
            {zoomGraph === "Residential Vs Commerical" && (
              <GraphComponent
                title={t("Residential Vs Commerical")}
                header={t("Residential Vs Commerical")}
                graphData={propertyDetails?.residential_vs_commerical ?? []}
                isPie
                innerRadius={50}
                paddingAngle={2}
                isTotal
                total={propertyDetails?.residential_vs_commerical_total ?? 0}
                margin={"20px"}
                padding={"16px"}
                maxHeightGraph={"250px"}
                minWidth={false}
                height={"auto"}
                is_popUp
                t={t}
                justifyContent={'start'}
                divider1={
                  {
                    xs: 12,
                    sm: 7.4,
                    md: 7.4,
                    lg: 7.4
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 4.6,
                    md: 4.6,
                    lg: 4.6
                  }
                }

                gradiantStyle={{
                  margin: '15px 0px 0px',
                  grid_template: "100%",

                  top: {
                    lg: "auto",
                    md: '16px',
                    sm: "auto",
                  },
                  height: {
                    md: "400px",
                    sm: "600px"
                  }
                }}
              />
            )}
            {zoomGraph === "Unit Category" && (
              <GraphComponent
                title={t("Unit Category")}
                header={t("Unit Category")}
                graphData={propertyDetails?.unit_catagory ?? []}
                isBar
                scale={scale}
                margin={"0px"}
                minWidth={false}
                is_popUp
                justifyContent={'start'}
                padding={"0px 16px 16px"}
                gheight={'500px'}
                gwidth={'400px'}
                divider1={
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 8,
                    md: 8,
                    lg: 8
                  }
                }
                t={t}

                gradiantStyle={{
                  margin: '15px 0px 0px',
                  grid_template: "100%",
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '16px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}

              />
            )}
            {zoomGraph === "Vacant Units By Property" && (
              <GraphComponent
                title={t("Vacant Units By Property")}
                header={t("Vacant Units By Property")}
                graphData={propertyDetails?.vacant_unit_by_property ?? []}
                isBar
                scale={scale}
                margin={"0px"}
                minWidth={false}
                gheight={'228px'}
                gwidth={400}
                is_popUp
                justifyContent={'start'}
                padding={"0px 16px 16px"}
                divider1={
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 8,
                    md: 8,
                    lg: 8
                  }
                }

                gradiantStyle={{
                  margin: '15px 0px 0px',
                  grid_template: "100%",

                  top: {
                    lg: "auto",
                    md: '16px',
                    sm: "auto",
                  },
                  height: {
                    md: "400px",
                    sm: "124px"

                  }
                }}
                t={t}
              />
            )}
            {zoomGraph === "Occupancy Trend Analysis" && (
              <GraphComponent
                title={t("Occupancy Trend Analysis")}
                header={t("Occupancy Trend Analysis")}
                graphData={propertyDetails?.property_occupancy_trends ?? []}
                line
                scale={scaleLine}
                margin={"0px"}
                minWidth={false}
                gheight={'228px'}
                gwidth={400}
                is_popUp
                justifyContent={'start'}
                padding={"0px 16px 16px"}
                divider1={
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 8,
                    md: 8,
                    lg: 8
                  }
                }
                gradiantStyle={{
                  margin: '15px 0px 0px',
                  grid_template: "100%",

                  top: {
                    lg: "auto",
                    md: '16px',
                    sm: "auto",
                  },
                  height: {
                    md: "400px",
                    sm: "600px"

                  }
                }}
                t={t}
              />
            )}
          </div>
        }
      />
    </div >)
  );
};
export default withNamespaces("propertyDashBoard")(DashBoard);
