import gql from "graphql-tag";

export let GET_ALREADY_PAID = (
    company_id, client, status = [], searchText = "", offset = 0, limit = 10, start_date, end_date, min_amount, max_amount) => {
    return gql`
query GET_ALREADY_PAID_INVOICE {
    compensated_invoices(
      where: {
        ${status ? `is_active: { in: [${status}] }` : ``}
        invoice: { client: { eq: "${client}" } }
        invoice: { company_id: { eq: ${company_id} } }
        or: { trx_no: { iregex: "${searchText}" }, created_by: { first_name: { iregex: "${searchText}" } } }
        ${start_date ? `created_at: { gte: "${start_date}" }` : ``}
        ${end_date ? `created_at: { lt: "${end_date}" }` : ``}
        ${min_amount ? `amount_paid: { gte: "${min_amount}" }` : ``}
        ${max_amount ? `amount_paid: { lte: "${max_amount}" }` : ``}

      }
      offset: ${offset}
      limit: ${limit}
    ) {
      id
      trx_no
      bank
      updated_at
      payment_mode
      amount_paid
      reference_id
      created_byByID {
        first_name
        mobile_no
        email_id
        image_url
        mobile_no_country_code
      }
      company {
        currency {
          symbol
        }
      }
      invoice {
        invoice_no
        company_id
        account_no
        invoice_date
      }
    }
  }`
}

export let GET_ACCOUNT = (offset, limit, searchText, { company_id }) => gql`
query GET_ACCOUNT{
  count: contact_account(
    where:{
       or:{
         name:{iregex:"${searchText}"}
         company_id:"${company_id}"
      }
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  contact_account(
      where:{
       or:{
         name:{iregex:"${searchText}"}
         company_id:"${company_id}"
      }
        is_active:{eq:true}
         }
      offset:${offset}
      limit:${limit}
    ){
         label:account_no
      	 value:id

      }
}
`

export const GET_RECEIPTS = (offset,limit,searchText, {account_no}) => gql`
query GET_RECEIPT { 
  count: reciepts(
    where:{
      contact_account:{
        account_no:"${account_no}"
      }
      is_active:true
      reciepts_no:{iregex:"${searchText}"}
    }
  ){
    count:count_id
  }
  reciepts(
    where:{
      contact_account:{
        account_no:"${account_no}"
      }
      is_active:true
      reciepts_no:{iregex:"${searchText}"}
    }
    offset:${offset}
    limit:${limit}
  ){
    value:id
    label:reciepts_no
  }
}
`

export const GET_SELECTED_USER_INVOICE = (invoice_no,ref_id) => gql`
query GET_ALREADY_PAID_INVOICE_SELECTED_USER {
  count: compensated_invoices{
    count:count_id
  }
  compensated_invoices(
    where: {
      invoice: { invoice_no: { eq: "${invoice_no}" } }
      reference_id:{eq:"${ref_id}"}
    }
  ) {
    id
    trx_no
    bank
    updated_at
    payment_mode
    amount_paid
    reference_id
    notes
    compensated_type
    remarks
    created_at
    paid_on
    created_by {
      first_name
      mobile_no
      email_id
      image_url
      mobile_no_country_code
    }
    invoice {
      invoice_no
      company_id
      account_no
      invoice_date
      invoice_total_amount
      company {
        currency {
          symbol
        }
      }
    }
  }
}
`


export const DECLINE_RECONCILE_MUTATION = gql`
mutation {
  compensated_invoices(where: { id: { eq: $id } }, update: $payload) {
    id
  }
}
` 

// export const DECLINE_MUTATION = gql`
// mutation {
//   compensated_invoices(where: { id: { eq: $id } }, update: $payload) {
//     id
//   }
// }
// ` 