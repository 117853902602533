import CloseIcon from '@mui/icons-material/Close';
import TopicSharpIcon from '@mui/icons-material/TopicSharp';
import {
    Box,
    Button, Dialog,
    Drawer, Grid, Hidden, IconButton, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SearchFilter, TableWithPagination, UploadReports } from '../../components';
import { config } from '../../config';
import { AlertContext } from "../../contexts";
import {
    GET_PROPERTY_BY_COMPANY_ID as queryOne
} from '../../graphql/queries';
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { AlertProps, ExtraBold, NetWorkCallMethods, SemiBold } from '../../utils';
import { Propertyheading, Propertypath } from '../../utils/tableData';
import { Bold } from '../../utils';
const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 88px)`,
        overflow: "auto"
    },

    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
    },
    text: {
        fontSize:"1.5rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize:"1rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "10px"
    },
    tableHead: {
        backgroundColor: "#F2F4F7",
        color: theme.typography.color.secondary,
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.75rem",
        fontFamily: SemiBold
    },
    thead: {
        backgroundColor: '#F2F4F7',
        '& th:first-child': {
            borderRadius: '1em 0 0 1em'
        },
        '& th:last-child': {
            borderRadius: '0 1em 1em 0'
        }
    },
    tbody: {
        backgroundColor: '#F2F4F7',
        '& th:first-child': {
            borderRadius: '1em 0 0 1em'
        },
        '& th:last-child': {
            borderRadius: '0 1em 1em 0'
        }
    },
    businessType: {
        backgroundColor: "#78B1FE",
        color: "white",
        padding: "1px",
        borderRadius: theme.palette.borderRadius,
        textAlign: "center"
    },
    bottomTitle: {
        fontSize:"1.125rem",
        fontFamily: ExtraBold

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px",
        backgroundColor:"#F2F4F7"
    },
    contentBottom: {
        // padding: "12px",

    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    }
}))

export const CreatedProperty = props => {

    const history = useHistory();
    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const [showDrawer, setShowDrawer] = React.useState(false);
    const [propertyList, setPropertyList] = React.useState([]);
    const handleClick = (data) => {
        history.push(Routes.propertyDetails + "?propertyId=" + data?.id)
    }
    const properyRow = propertyList?.map((val, index) => {
        let _d;
        try {
            _d = {
                sno: "",
                propertyName: val?.name,
                location: val?.city_area_master?.area_name + ", " + val?.city_area_master?.city_master?.city,
                blocks: val?.block.length,
                floors: val?.floor.length,
                units: val?.unit.length,
                propertyType: val?.property_group_master?.group_name,
                icon: "editview",
                id: val?.id,
            };
        } catch (err) {

        }
        return _d;
    });

    React.useEffect(() => {
        const payload = {
            query: queryOne,
            variables: {
                companyID: 1
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setPropertyList(response?.data?.data?.property);
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
            });
        // eslint-disable-next-line
    }, []);

    return <div className={classes.root}>

        {properyRow.length < 0 ?
            <>
                <Grid container className={classes.content} >
                    <Grid item xs={12}>
                        <img src='/images/propertybg.svg' alt='pp' />
                        <Typography className={classes.text}>
                            No Property Found
                        </Typography>
                        <Typography className={classes.subText}>
                            You Can Just, Create New Property
                        </Typography>
                        <br />
                    </Grid>
                    <Grid item xs={12} >
                        <Button
                            variant="contained"
                            className={classes.btn}
                            onClick={() => history.push(Routes.addProperty)}
                        >
                            Add A Property
                        </Button>&nbsp;&nbsp;&nbsp;
                        <Button
                            variant="outlined"
                            color='secondary'
                            className={classes.btn2}
                            onClick={() => setShowDrawer(true)}
                        >
                            Bulk Upload The Data With Our Excel Template
                        </Button>
                        <br />
                    </Grid>
                </Grid>
                {/* Drawer */}
                <Hidden smUp>
                    <Drawer
                        className={classes.drawer}
                        open={showDrawer}
                        anchor="bottom"
                        onClose={() => setShowDrawer(false)}>
                        <div className={classes.contentBottom}>
                            <Box display="flex" p={2} alignItems="center" className={classes.titleroot}>
                                <Box flexGrow={1}>
                                    <Typography className={classes.bottomTitle}>
                                        Reset Password
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={() => setShowDrawer(false)}>
                                        <CloseIcon color="secondary" />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Grid container>
                                <Grid item xs={6} sx={{ padding: "16px", borderRight: "1px solid #c1c1c1" }}>
                                    <Box display={"flex"}
                                        alignItems={"center"}
                                        className={classes.grid}
                                    >
                                        <Box >
                                            <TopicSharpIcon />
                                        </Box>

                                        <Box flexGrow={1} marginLeft={"6px"}>
                                            <Typography className={classes.title}>
                                                Bulk Upload Template
                                            </Typography>
                                            <Typography className={classes.subTitle}>
                                                Download
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <br />
                                    <Box display={"flex"}
                                        alignItems={"center"}
                                        className={classes.grid}
                                    >
                                        <Box >
                                            <TopicSharpIcon />
                                        </Box>

                                        <Typography className={classes.title}>
                                            Bulk Asset Manager
                                        </Typography>
                                        <Typography className={classes.subTitle}>
                                            Go to Link
                                        </Typography>

                                    </Box>

                                </Grid>
                                <Grid item xs={6} sx={{ padding: "12px" }}>
                                    <UploadReports />
                                </Grid>
                            </Grid>
                        </div>
                    </Drawer>
                </Hidden>
                {/* Dialog */}
                <Hidden smDown>
                    <Dialog
                        fullWidth={true}
                        maxWidth="sm"
                        open={showDrawer}>
                        <div className={classes.contentBottom}>
                            <Box display="flex" p={1} alignItems="center" className={classes.titleroot}>
                                <Box flexGrow={1}>
                                    <Typography className={classes.bottomTitle}>
                                        Add Bulk Property
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={() => setShowDrawer(false)}>
                                        <CloseIcon color="secondary" />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Grid container>
                                <Grid item xs={6} sx={{ padding: "16px", borderRight: "1px solid #c1c1c1" }}>
                                    <Box display={"flex"}
                                        alignItems={"center"}
                                        className={classes.grid}
                                    >
                                        <Box >
                                            <TopicSharpIcon />
                                        </Box>

                                        <Box flexGrow={1} marginLeft={"6px"}>
                                            <Typography className={classes.title}>
                                                Bulk Upload Template
                                            </Typography>
                                            <Typography className={classes.subTitle}>
                                                Download
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <br />
                                    <Box display={"flex"}
                                        alignItems={"center"}
                                        className={classes.grid}
                                    >
                                        <Box >
                                            <TopicSharpIcon />
                                        </Box>

                                        <Box flexGrow={1} marginLeft={"6px"}>
                                            <Typography className={classes.title}>
                                                Bulk Asset Manager
                                            </Typography>
                                            <Typography className={classes.subTitle}>
                                                Go to Link
                                            </Typography>
                                        </Box>

                                    </Box>

                                </Grid>
                                <Grid item xs={6} sx={{ padding: "12px" }}>
                                    <UploadReports />
                                </Grid>
                            </Grid>
                        </div>

                    </Dialog>
                </Hidden>
            </> :
            <>
                <Grid container className={classes.content} spacing={2}>
                    <Grid item xs={6}>
                        <SearchFilter />
                    </Grid>

                    <Grid item xs={6} textAlign={"right"}>
                        <Box
                            display={"flex"}
                            sx={{ float: "right" }}
                        >
                            <Box>
                                <img src='/images/filter.svg' alt='filter' className={classes.img} />
                            </Box>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Box>
                                <Button variant="outlined" color='secondary' className={classes.btn2}>
                                    Add Bulk Properties
                                </Button>
                            </Box>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Box>
                                <Button
                                    variant="contained"
                                    className={classes.btn}
                                    onClick={() => history.push(Routes.addProperty)}
                                >
                                    Add a Property
                                </Button>
                            </Box>
                        </Box>

                    </Grid>
                    <Grid item xs={12}>
                        <TableWithPagination
                            heading={Propertyheading}
                            rows={properyRow}
                            path={Propertypath}
                            showpagination={false}
                            count="2"
                            tableType="normal"
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            onClick={handleClick}
                            dataType={[
                                { type: ["increment"], name: "sno" },
                                { type: ["text"], name: "propertyName" },
                                { type: ["text"], name: "location" },
                                { type: ["text"], name: "blocks" },
                                { type: ["text"], name: "floors" },
                                { type: ["text"], name: "units" },
                                { type: ["propertyType"], name: "propertyType" },
                                { type: ["icon"], icon: "icon" },
                            ]}
                            view={true}
                            edit={true}
                            delete={true} />


                    </Grid>

                </Grid>
            </>}

    </div>
}