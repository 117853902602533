import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ServicesMaster } from "../../components";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {

    },
}));

const Master = ({ t }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ServicesMaster t={t} />
        </div>
    );
};
export default withNamespaces("master")(Master)
