export const UtilitiesMasterPath = [
    "icon",
    // "reference_no",
    // "utility_category",
    "name",
    // "source",
    // "provider",
    // "uom",
    "price",
    // "billing",
    // "taxable",
    // "tax_group",
    "status",
];

export const UtilitiesMasterHeading = ({ t = () => false }) => [
    { title: "", field: "icon" },
    // { title: t("Utility ID"), field: "reference_no" },
    // { title: t("Utility Category"), field: "utility_category" },
    { title: t("Name"), field: "name" },
    // { title: t("Source"), field: "source" },
    // { title: t("Provider"), field: "provider" },
    // { title: t("UOM"), field: "uom" },
    { title: t("Price"), field: "price" },
    // { title: t("Billing"), field: "billing" },
    // { title: t("Taxable"), field: "taxable" },
    // { title: t("Tax Group"), field: "tax_group" },
    { title: t("Status"), field: "status" },
];

export const UtilitiesMasterDataType = [
    { type: ["more"], icon: "icon" },
    // { type: ["text"], name: "reference_no" },
    // { type: ["long_text"], name: "utility_category", tooltip_placement: "top", max_width: "300px" },
    { type: ["long_text"], name: "name", tooltip_placement: "top", max_width: "300px" },
    // { type: ["text"], name: "source" },
    // { type: ["text"], name: "provider" },
    // { type: ["text"], name: "uom" },
    { type: ["price"], name: "price" },
    // { type: ["yes_no"], name: "billing" },
    // { type: ["yes_no"], name: "taxable" },
    // { type: ["text"], name: "tax_group" },
    { type: ["status"], name: "status" },
];