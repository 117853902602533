// import { Box, Grid, Typography } from "@mui/material";
// import React from "react";
// import { TextBox, ToggleButtonComponent } from "../../components";
// import {SelectBox} from "../../components";
// import styled from '@mui/material/styles/styled';
// import { AlertProps, LocalStorageKeys } from "../../utils";
// import { GET_IDENTIFICAITON_MASTER } from "../../graphql/queries";
// import { withNamespaces } from "react-i18next";
// import { useApolloClient } from "@apollo/client";
// import { AlertContext } from "../../contexts";
// import { useStyles } from "./style";

// const Label = styled(Typography)(({ theme }) => ({
//     color: theme.typography.color.tertiary,
//     fontSize:"0.75rem",
//     fontFamily: "OpenSans-SemiBold, Roboto-SemiBold, Arial, sans-serif"
// }));

// const KycDetails = (props) => {
//     const { t } = props
//     const classes = useStyles();
//     const buttonStyle = {
//         height: "45px",
//         minWidth: "45px",
//         borderColor: "#E4E8EE",
//         borderRadius: 1,
//         backgroundColor: "#fff",
//     }
//     const YesNOOptions = [
//         { label: "Yes", value: true },
//         { label: "No", value: false },
//     ];

//     const [loading, setLoading] = React.useState(false);
//     const clientID = localStorage.getItem(LocalStorageKeys.clinetID) ?? undefined
//     const client = useApolloClient()
//     const alert = React.useContext(AlertContext)

//     const loadOptions = async (search = "", array, type) => {
//         setLoading(type)
//         let result, query, offset, limit, companyID, variables = 0
//         limit = 10
//         companyID = props?.data?.company?.value
//         if (search && !Boolean(array?.length)) { offset = 0 }
//         else { offset = array?.length }

//         switch (type) {
//             case "Identification_master":
//                 query = GET_IDENTIFICAITON_MASTER
//                 variables = { clientID, companyID, offset, limit, search }
//                 result = await networkCallBack(query, variables)
//                 return {
//                     options: [...result?.Identification_master],
//                     hasMore: (array?.length + result?.property?.length) < result?.count[0]?.count
//                 }
//             default:
//                 return { options: [] }
//         }
//     }

//     const networkCallBack = async (query, variables = {}) => {
//         const options = await client.query({
//             query, fetchPolicy: "network-only", variables
//         }).then((r) => {
//             let main = r?.data
//             setLoading(null)
//             return main
//         }).catch((e) => {
//             setLoading(null)
//             alert.setSnack({
//                 ...alert, open: true,
//                 severity: AlertProps.severity.error, msg: t("SomeThingWentWrong")
//             })
//             return null
//         })
//         return options
//     }

//     return (
//         <Box className={classes.kycContent}>
//             <Grid container  spacing={3}>
//                 <Grid item xs={12} md={4} lg={3}>
//                     <SelectBox
//                         label={t("IDType")}
//                         placeholder={t("SelectType")}
//                         value={props?.data?.idType}
//                         onChange={(value) => props?.updateState("idType", value)}
//                         loading={loading === "Identification_master"}
//                         isPaginate
//                         debounceTimeout={800}
//                         loadOptions={(search, array) => loadOptions(search, array, 'Identification_master')} />
//                 </Grid>
//                 <Grid item xs={12} md={4} lg={3}>
//                     <TextBox
//                         label={t("IDNumber")}
//                         placeholder={t("EnterNumber")}
//                         value={props?.data?.idNumber ?? ""}
//                         onChange={(e) => props?.updateState("idNumber", e.target.value)} />
//                 </Grid>
//                 <Grid item xs={12} md={4} lg={3}>
//                     <Label
//                         variant="body1"
//                         color="textsecondary"
//                         align="left"
//                         gutterBottom>
//                         {t("Barred")}
//                     </Label>
//                     <ToggleButtonComponent
//                         options={YesNOOptions}
//                         value={props?.data?.barred ?? []}
//                         onChange={(value) => props?.updateState('barred', value)}
//                         isMulti={false}
//                         fullWidth={false}
//                         buttonStyle={buttonStyle}
//                     // isError={error?.initialConfig?.referAndWin?.error ?? false}
//                     // errorMessage={error?.initialConfig?.referAndWin?.errorMsg ?? ''}
//                     />
//                 </Grid>
//             </Grid>
//         </Box>
//     )
// }

// export default withNamespaces("domesticHelper")(KycDetails)



import { Box } from "@mui/material";
import { ProofComponent } from "../../components";
import { useStyles } from "./style";
export const KycDetails = ({ t, update = () => false, data = [] }) => {
    const classes = useStyles()

    return (
        <Box marginTop="16px" className={classes.box}>
            <ProofComponent t={t} file={data} update={update} />
        </Box>
    )
}