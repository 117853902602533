import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { NewDatePicker, SelectBox, TextBox ,DatePickerTimeNew} from "../../../components";
import { config } from "../../../config";
import {
  GET_MAINTENANCE_CATEGORY, GET_MAINTENANCE_SUB_CATEGORY, GET_PROPERTY_BASED_ON_COMPANY,
  SELECTUNITS
} from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  dropdown: {
    margin: "10px 0px",
  },
}));

export const Service = (props) => {
  const classes = useStyles();
  // const [unit, setUnit] = React.useState([]);
  const [loading, setLoading] = React.useState(false)
  let option = JSON.parse(localStorage.getItem("role"))
    ?.filter((item) => item.name === "Manager")
    .map((v) => v);

  const properyRow = option?.map((val, index) => {
    let _d;
    try {
      _d = {
        value: val.company_id,
        label: val.company_name,
      };
    } catch (err) {

    }
    return _d;
  });

  const loadOptions = async (search = "", array, type) => {

    setLoading(type);
    let result, query, offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    }
    else {
      offset = array?.length;
    }

    switch (type) {
      case 'propertyName':
        if (props?.data?.company?.label?.length > 0) {

          query = GET_PROPERTY_BASED_ON_COMPANY(offset, 10, search, props?.data?.company?.value).loc.source.body;
          result = await networkCallBack(query);
          return {
            options: [...result?.property],
            hasMore: (array?.length + result?.property?.length) < result?.count[0]?.count
          }
        }
        else {
          setLoading(null);
          return { options: [] }
        }
      case 'unit':
        if (props?.data?.propperty?.label?.length > 0) {

          query = SELECTUNITS(offset, 10, search, props?.data?.propperty?.value).loc.source.body;
          result = await networkCallBack(query);
          return {
            options: [...result?.unit],
            hasMore: (array?.length + result?.unit?.length) < result?.count[0]?.count
          }
        }
        else {
          setLoading(null);
          return { options: [] }
        }

      case 'catagory':
        query = GET_MAINTENANCE_CATEGORY(offset, 10, search).loc.source.body;
        result = await networkCallBack(query);
        return {
          options: [...result?.maintenance_category_master],
          hasMore: (array?.length + result?.maintenance_category_master?.length) < result?.count[0]?.count
        }
      case 'subcatagory':
        if (props?.data?.category?.label?.length > 0) {

          query = GET_MAINTENANCE_SUB_CATEGORY(offset, 10, search, props?.data?.category?.value).loc.source.body;
          result = await networkCallBack(query);
          return {
            options: [...result?.maintenance_sub_category_master],
            hasMore: (array?.length + result?.maintenance_sub_category_master?.length) < result?.count[0]?.count
          }
        } else {
          setLoading(null);
          return { options: [] }
        }


      default:
        return { options: [] }
    }
  }
  const networkCallBack = async (query, variable = {}) => {

    let payload = {
      query,
      variable: variable
    };

    const options = await NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        setLoading(null);
        return main
      })
      .catch((error) => {
        setLoading(null);

        return null
      });

    return options
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {/* company */}
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Company"
              options={properyRow}
              placeholder="Select Company"
              value={props?.data?.company}
              onChange={(value) => {
                props?.updateState("company", value);
              }}
              isError={props?.data?.error?.company?.length > 0}
              errorMessage={props?.data?.error?.company}
              isReadOnly
            />
          </div>
          {/* Category */}
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Category"
              isPaginate
              loadOptions={(search, array) => loadOptions(search, array, 'catagory')}
              loading={loading === "catagory"}
              debounceTimeout={800}
              placeholder="Select Category"
              value={props?.data?.category}
              onChange={(value) => {
                props?.updateState("category", value);
              }}
              isError={props?.data?.error?.category?.length > 0}
              errorMessage={props?.data?.error?.category}
            />
          </div>
          {/* Sub-Category */}
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Sub-Category"

              isPaginate
              loadOptions={(search, array) => loadOptions(search, array, 'subcatagory')}
              loading={loading === "subcatagory"}
              debounceTimeout={800}
              placeholder="Select Sub-Category"
              value={props?.data?.subcategory}
              key={JSON.stringify(props?.data?.category)}
              onChange={(value) => {
                props?.updateState("subcategory", value);
              }}
              isError={props?.data?.error?.subcategory?.length > 0}
              errorMessage={props?.data?.error?.subcategory}
            />
          </div>

          {/* Date */}
          <div className={classes.dropdown}>
            <DatePickerTimeNew
              isRequired
              isTime
              disableFuture={true}
              disablePast={false}
              minDate={new Date()}
              label="Preferred Visit Date & Time"
              placeholder="Preferred Visit Date & Time"
              value={props?.data?.preferreddate}
              onChange={(value) => props?.updateState("preferreddate", value)}
              isError={props?.data?.error?.preferreddate?.length > 0}
              errorMessage={props?.data?.error?.preferreddate}
            />
          </div>


        </Grid>
        <Grid item xs={6}>
          {/*  */}
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Property"
              placeholder="Select Property"
              value={props?.data?.propperty}
              onChange={(value) => {
                props?.updateState("propperty", value);
              }}
              key={JSON.stringify(props?.data?.company)}
              isError={props?.data?.error?.propperty?.length > 0}
              errorMessage={props?.data?.error?.propperty}
              isPaginate
              loadOptions={(search, array) => loadOptions(search, array, 'propertyName')}
              loading={loading === "propertyName"}
              debounceTimeout={800}
            />
          </div>
          {/* units */}
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Unit"
              isPaginate
              loadOptions={(search, array) => loadOptions(search, array, 'unit')}
              loading={loading === "unit"}
              debounceTimeout={800}
              placeholder="Select Unit"
              key={JSON.stringify(props?.data?.propperty)}
              value={props?.data?.unit}
              onChange={(value) => {
                props?.updateState("unit", value);
              }}
              isError={props?.data?.error?.unit?.length > 0}
              errorMessage={props?.data?.error?.unit}
            />
          </div>

          {/* problem tittle */}
          <div className={classes.dropdown}>
            <TextBox
              isrequired
              label="Problem Title"
              placeholder="Problem Title"
              value={props?.data?.problem}
              onChange={(e) => {
                props?.updateState("problem", e.target.value);
              }}
              isError={props?.data?.error?.problem?.length > 0}
              errorMessage={props?.data?.error?.problem}
            />
          </div>
          {/* Date */}
          <div className={classes.dropdown}>
            <NewDatePicker
              maxDate={new Date()}
              required
              value={props?.data?.since ?? ""}
              label="Problem Since"
              placeholder="Problem Since"
              onChange={(value) => {
                props?.updateState("since", value);
              }}
              isError={props?.data?.error?.since?.length > 0}
              errorMessage={props?.data?.error?.since}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          {/* Description */}
          <div>
            <TextBox
              multiline
              isrequired
              label="Problem Description"
              placeholder="Problem Description"
              value={props?.data?.description}
              onChange={(e) => {
                props?.updateState("description", e.target.value);
              }}
              isError={props?.data?.error?.description?.length > 0}
              errorMessage={props?.data?.error?.description}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
