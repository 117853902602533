import { Box, Button, FormControlLabel, Grid, InputAdornment, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from 'react';
import { SemiBold, remCalc } from "../../utils";
import CalendarWithTime from "../calendarWithTime";
import { DurationBox } from "../durationbox";
import { CustomSelect, MinMaxTextFieldWithSelect } from "../filterGenerator/components";
import { CompanySettingsList, CompanyToggle, RenewalSelect, CustomCheckBox, DatePickerNew, DatePickerTimeNew, Editor, LogoUpload, MobileNumberInputComponent, NewDatePicker, PopoverDatePicker2, SelectBox, StartEndDate, TextBox, TextWithToogle } from "../index";

import { MemberCard } from "../memberSelect";
import { PaymentSelectBox } from "../paymentSelectBox";
import PrioritySelect from "../prioritySelect/prioritySelect";
import { UserListDropDown } from "../userListDropDown";
import { PurposeSelect } from "../purposeSelect";
import PrioritySelect1 from "../priority/priority";
import { AgreementCustomDate } from "../agreementCycle"
import { VisitorSlotSelection } from "../visitorSlotSelection/slotSelection";

export const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    fontSize: "0.75rem",
    marginBottom: "5px",

  },
  durationBox: {
    display: "flex",
    border: "1px solid #E4E8EE",
    borderRadius: "4px",

  },
  borderRight: {
    borderRight: "1px solid #E4E8EE",
  },
  durationType: {
    padding: "10px"
  },
  durationLabel: {
    fontSize: "0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    marginBottom: "5px"
  },
  gracetext: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
  },
  menuList: {
    '&:hover': {
      backgroundColor: "transparent"
    }
  },
  toggleText: {
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: theme.typography.color.primary
  }
}));
export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, width, height, tamW, tamH, tamM }) => ({
  width: width ?? 46,
  height: height ?? 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: 'translateX(22px)',
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#5078E1",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: tamW ?? 18,
    height: tamH ?? 18,
    margin: tamM ?? 1
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 26,
  height: 14,

  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#F2F4F7' : '#F2F4F7',

      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 10,
    height: 10,

    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: "#78B1FE"

  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#F2F4F7",

    boxSizing: 'border-box',
  },
}));
export const FormGenerator = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(false)
  const datePickerOpen = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }
  const {
    components = [],
    t = () => false
  } = props;
  const classes = useStyles()
  // input1: "Min",
  //     input2: "Max",
  //         select: "Select"
  const switchComponents = (val) => {
    if (val?.isActive) {
      switch (val.component) {
        case "text":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <TextBox
                isrequired={val?.isRequired}
                label={val?.label}
                placeholder={val?.placeholder}
                value={val.value}
                onChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                multiline={val?.multiline}
                isReadonly={val?.isReadonly}
                type={val?.type}
                color={val?.color}
                startAdornment={val?.startAdornment}
                endAdornment={val?.endAdornment}
                fontSize={val?.fontSize}
                onBlur={val?.onBlur}
                height={val?.height}
                padding={val?.padding}
              />
            </Grid>
          );
        case "button":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Button
                sx={{
                  padding: "8px",
                  boxShadow: "none",
                  marginTop: val?.top ?? "4px",
                }}
                onClick={val?.onClick}
                variant={val?.variant ?? "contained"}
                fullWidth
                disabled={val?.disable}
              >
                {val?.label}
              </Button>
            </Grid>
          );
        case "check":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <CustomCheckBox
                label={val?.label}
                checked={val?.value}
                onChange={val?.onChange}
              />
            </Grid>
          );
        case "select":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <SelectBox
                menuPlacement={val?.menuPlacement}
                parentID={val?.id}
                isRequired={val?.isRequired}
                label={val?.label}
                placeholder={val?.placeholder}
                value={val.value}
                zIndex={val?.zIndex}
                onChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                isReadOnly={val?.isReadonly}
                isPaginate={val?.isPaginate}
                loadOptions={val?.loadOptions}
                loading={val?.loading}
                debounceTimeout={800}
                options={val?.options ?? []}
                key={val?.key}
                menuOptionHeight={val?.menuOptionHeight}
                selectHeight={val?.selectHeight}
                fontFamily={val?.fontFamily}
                isClearable={val?.isClearable}
                customOptionComponent={val?.customComponent}
                color={val?.color}
                menu_text_transfrom={val?.menu_text_transfrom}
                isMulti={val?.isMulti}
              />
            </Grid>
          );
        case "renewalSelect":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <RenewalSelect
                val={val}
              />
            </Grid>
          );

        case "custom_select":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <CustomSelect
                menuPlacement={val?.menuPlacement}
                parentID={val?.id}
                isRequired={val?.isRequired}
                required={val?.isRequired}
                label={val?.label}
                placeholder={val?.placeholder}
                value={val.value}
                zIndex={val?.zIndex}
                onChange={val?.onChange}
                error={val?.error?.length > 0}
                errorText={val?.error}
                backgroundColor={val?.backgroundColor}
                isReadOnly={val?.isReadonly}
                isPaginate={val?.isPaginate}
                loadOptions={val?.loadOptions}
                loading={val?.loading}
                debounceTimeout={800}
                options={val?.options ?? []}
                key={val?.key}
              />
            </Grid>
          );
        case "date":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <DatePickerNew
                value={val?.value}
                label={val?.label}
                handleChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                isReadonly={val?.isReadonly}
                isrequired={val?.isRequired}
                minDate={val?.minDate}
                placeholder={val?.placeholder}
                noBorder={val?.isReadonly}
                maxDate={val?.maxDate}
                height={val?.height}
              />
            </Grid>
          );
        case "textWithToogle":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <TextWithToogle
                value={val?.value}
                label={val?.label}
                onChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                isReadonly={val?.isReadonly}
                custom={val?.custom}
                openCustom={val?.openCustom}
                options={val?.options}
                isrequired={val?.isrequired}
                is_toogle={val?.is_toogle}
              />
            </Grid>
          );
        case "toggle":
          return (
            <>
              <Grid
                item
                xs={val?.size?.xs}
                sm={val?.size?.sm}
                md={val?.size?.md}
                lg={val?.size?.lg}
              >
                <Stack  >
                  <Box style={{ display: val?.is_Oppertunity ? "flex" : "block", width: "100%" }} alignItems="flex-end">

                    <Box>
                      <Stack direction="row" justifyContent="space-between" width="100%">
                        <Typography className={classes.Label} noWrap>
                          <Stack direction="row" spacing={1}>
                            {val.label}
                            {val.isRequired && (
                              <Typography
                                variant="caption"
                                style={{ color: "red", marginLeft: 2 }}
                              >
                                *
                              </Typography>
                            )}
                          </Stack>
                        </Typography>

                        {val.applyGracePeriod &&
                          <Stack direction="row" spacing={1}>
                            <Typography className={classes.gracetext}
                              sx={{ color: val?.applyGracePeriodValue ? "#98A0AC" : "#78B1FE" }}
                            >{t("Beginning")}</Typography>
                            <StyledSwitch inputProps={{ 'aria-label': 'ant design' }} checked={val?.applyGracePeriodValue}
                              onChange={(e) =>
                                val?.OnApplyGracePeriod(e.target.checked)
                              } />
                            <Typography className={classes.gracetext} sx={{ color: val?.applyGracePeriodValue ? "#78B1FE" : "#98A0AC" }}>{t("End")}</Typography>
                          </Stack>
                        }
                      </Stack>


                      <CompanyToggle
                        t={t}
                        value={val?.value}
                        onChange={val?.onChange}
                        isMulti={true}
                        fullWidth={false}
                        openCustom={val?.openCustom}
                        options={val?.options}
                        height="45px"

                        customValue={val?.customValue}
                        custom={val?.custom}
                        disabled={val?.isReadonly}
                        selectBillingCycle={val?.selectBillingCycle}
                        date={val?.billingCycleDate}
                        onDateChange={val?.onDateChange}
                        day={val?.value}
                        onCustomChange={val?.onChange}
                        is_Oppertunity={val?.is_Oppertunity}
                        textBoxIsReadOnly={val?.textBoxIsReadOnly}
                        is_billing={true}

                      />
                    </Box>

                    {val?.customDay && (
                      <>
                        {
                          <Box marginLeft="4px">
                            <AgreementCustomDate
                              value={val?.customValue}
                              onChange={(e) => {
                                val?.onCustom(e)
                              }}
                              disabled={val?.isReadonly}
                            />
                          </Box>

                        }</>
                    )}



                  </Box>

                  {
                    val.error?.length > 0 && (
                      <Typography variant={"caption"} color={"error"}>
                        {val.error}
                      </Typography>
                    )
                  }
                </Stack >
              </Grid >
            </>

          );
        case "mobile":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <MobileNumberInputComponent
                isRequired={val?.isRequired}
                label={val?.label}
                placeholder={val?.placeholder}
                value={val.value}
                handleChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                multiline={val?.multiline}
                isReadonly={val?.isReadonly}
                fontSize={val?.fontSize}
                xs={val?.xs}
                xs1={val?.xs1}

              />
            </Grid>
          );
        case "minMax":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Typography
                variant="body1"
                className={classes.Label}
                gutterBottom
              >
                {val?.label}
                {val.isRequired && (
                  <Typography color="error" variant="caption">
                    *
                  </Typography>
                )}
              </Typography>
              <MinMaxTextFieldWithSelect
                value={val?.value}
                onChange={val?.onChange}
                placeholders={{
                  input1: "Min",
                  input2: "Max",
                  select: "Select",
                }}
                endAdornmentLabels={{
                  input1: "Min",
                  input2: "Max",
                }}
                aysnc={val?.aysnc}
                disabled
                options={val?.options}
                select={true}
                loadOptions={val?.loadOptions}
                error={val?.error?.length > 0}
                errorText={val?.error}
              />
            </Grid>
          );
        case "textEditer":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Editor
                value={val?.value}
                label={val?.label}
                handleChange={val?.onChange}
                id={val?.id}
                height={val?.height}
                isReadonly={val?.isReadonly}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
              />
            </Grid>
          );
        case "toggleCheck":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <CompanySettingsList
                title={val?.label}
                onChange={val?.onChange}
                value={val?.value}
                toggle={val?.toggle}
                custom={val?.custom}
                openCustom={val?.openCustom}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                options={val?.option}
              />
            </Grid>
          );
        case "upload":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <LogoUpload
                logo_title={val?.label}
                handleChange={val?.onChange}
                data={val?.value}
              />
            </Grid>
          );
        case "popoverDate":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Typography
                variant="body1"
                className={classes.Label}
                gutterBottom
              >
                {val?.label}{" "}
                <Typography color="error" variant="caption">
                  *
                </Typography>
              </Typography>
              <PopoverDatePicker2
                disabled={val?.disabled}
                border={true}
                placeholder="Lease Start & End Date"
                startDate={val?.startDate}
                endDate={val?.endDate}
                updateState={val?.onChange}
                label={{
                  start: "Lease Start Date",
                  end: "Lease End Date",
                }}
              />
              {val?.error > 0 && (
                <Typography color="error" variant="caption" gutterBottom>
                  {val?.error}
                </Typography>
              )}
            </Grid>
          );
        case "datePickerWithTime":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <DatePickerTimeNew
                isRequired={val?.isRequired}
                isTime={val?.isNot ? false : true}
                minDate={val?.minDate}
                maxDate={val?.maxDate}
                label={val?.label}
                placeholder={val?.placeholder}
                value={val?.value}
                onChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                border={val?.border}
                is_popover={val?.is_popover}

              />
            </Grid>
          );
        case "customdate":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <StartEndDate
                label={val?.label}
                data={val?.data}
                option={val?.options}
                value={val?.value}
                isError={val?.error?.length > 0}
                errMsg={val?.error}
                onChange={val?.onChange}
                isrequired={val?.isRequired}
                isReadonly={val?.isReadonly}
              />
            </Grid>
          );
        case "datepicker":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <NewDatePicker
                isRequired={val?.isRequired}
                disableFuture={val?.disableFuture}
                disablePast={val?.disablePast}
                minDate={val?.minDate}
                maxDate={val?.maxDate}
                label={val?.label}
                placeholder={val?.placeholder}
                value={val?.value}
                onChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                newDate={val?.newDate}
                height={val?.height}
              />
            </Grid>
          );
        case "user":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <UserListDropDown
                isrequired={val?.isRequired}
                value={val?.value}
                onChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                label={val?.label}
                placeholder={val?.placeholder}
                isRequired={val?.isRequired}
                company_id={val?.company_id}
              />
            </Grid>
          )
        case "duration":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <DurationBox
                val={val}
                value={val?.value}
                options={val?.options}
                onChange={val?.onChange}
                isError={val?.isError}
                errorMessage={val?.errorMessage}
                isReadonly={val?.isReadonly}
                isRequired={val?.isRequired}
                fontFamily={val?.fontFamily}
                size={val?.item_size}
              />
            </Grid>
          );
        case "priority":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Box style={{ borderRadius: "4px" }}>
                <PrioritySelect
                  t={t}
                  options={val.options}
                  value={val?.value}
                  onChange={val?.onChange}
                  isRequired={val?.isRequired}
                  is_dot={val?.is_dot}
                  placeHolder={val?.placeholder}
                  isPaginate={val?.isPaginate}
                  loadOptions={val?.loadOptions}
                  loading={val?.loading}
                  debounceTimeout={800}
                  label={val?.label}
                  backgroundColor={val?.backgroundColor}
                  menuPlacement={val?.menuPlacement}

                // menuPlacement={val?.menuPlacement}
                // parentID={val?.id}
                // label={val?.label}
                // placeholder={val?.placeholder}
                // zIndex={val?.zIndex}
                // isError={val?.error?.length > 0}
                // errorMessage={val?.error}
                // isReadOnly={val?.isReadonly}

                // key={val?.key}
                // menuOptionHeight={val?.menuOptionHeight}
                />
              </Box>
            </Grid>
          );
        case "priority1":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Box style={{ borderRadius: "4px" }}>
                <PrioritySelect1
                  options={val.options}
                  value={val?.value}
                  onChange={val?.onChange}
                  isRequired={val?.isRequired}
                  is_dot={val?.is_dot}
                  placeHolder={val?.placeholder}
                  isPaginate={val?.isPaginate}
                  loadOptions={val?.loadOptions}
                  loading={val?.loading}
                  debounceTimeout={800}
                  label={val?.label}
                  backgroundColor={val?.backgroundColor}
                  menuPlacement={val?.menuPlacement}

                // menuPlacement={val?.menuPlacement}
                // parentID={val?.id}
                // label={val?.label}
                // placeholder={val?.placeholder}
                // zIndex={val?.zIndex}
                // isError={val?.error?.length > 0}
                // errorMessage={val?.error}
                // isReadOnly={val?.isReadonly}

                // key={val?.key}
                // menuOptionHeight={val?.menuOptionHeight}
                />

              </Box>
            </Grid>
          );

        case "payment":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <PaymentSelectBox
                options={val.options}
                label={val?.label}
                handleChange={val?.onChange}
                value={val?.value}
                mileStoneName={val?.mileStoneName}
                mileStoneId={val?.mileStoneId}
                setMileStoneName={val?.setMileStoneName}
                companyId={val?.companyId}
                error={val?.error}
                isrequired={val?.isRequired}
                data={val?.data}
                isRequired={val?.isRequired}
                menuIsOpen={val?.menuIsOpen}
                setMenuIsOpen={val?.setMenuIsOpen}

              />
            </Grid>
          );
        case "memberSelect":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <MemberCard
                options={val.options}
                label={val?.label}
                onChange={val?.onChange}
                value={val?.value}
                error={val?.error}
                data={val?.data}
                isRequired={val?.isRequired}
                selectedMember={val?.selectedMember}
                loadOptions={val?.loadOptions}
                isPaginate={val?.isPaginate}
                memberDropdown={val?.memberDropdown}
                isReadOnly={val?.isReadOnly}
                key={val?.key}


              />
            </Grid>
          )
        case "newDatePickerAutoTime":
          return (
            <Grid xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}>
              <Box onClick={(event) => setAnchorEl(event.currentTarget)} m={val?.margin ?? 2}>
                <TextBox
                  isReadonly={val?.disabled}
                  isrequired={val?.isRequired}
                  label={val?.label}
                  placeholder={val?.placeholder}
                  endAdornment={
                    <InputAdornment className={classes.selectAdornment} position="end">
                      <img src={'/images/icons8-calendar (5).svg'} alt="" />
                    </InputAdornment>
                  }
                  value={val?.value ? val?.noTime ? `${moment(val?.value).format("DD-MM-YY")}` : `${moment(val?.value).format("DD-MM-YY HH:mm")}` : ""} />
              </Box>

              <Menu
                anchorEl={anchorEl}
                open={datePickerOpen}
                className={classes.menublock}
                onClose={handleClose}
                PaperProps={{ sx: { width: "360px", backgroundColor: "white", border: "1px solid #E4E8EE", boxShadow: "0px 0px 16px #00000014" } }}
              >
                <MenuItem className={classes.menuList} disableRipple>
                  <CalendarWithTime
                    handleDateChange={date => val?.onChange(date)}
                    dateTime={val?.value ? val?.value : new Date()}
                    setAnchorEl={setAnchorEl}
                    noTime={val?.noTime}
                  />
                </MenuItem>
              </Menu>
            </Grid>
          )

        case "purpose_choose":
          return (
            <Grid item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <PurposeSelect
                value={val?.value}
                options={val?.options}
                label={val?.label}
                placeholder={val?.placeholder}
                onChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                isrequired={val?.isrequired}
              />

            </Grid>
          )
        case "toggleButton":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Box display={"flex"} justifyContent={"space-between"} alignItems="center">
                <Typography className={classes.Label}>{val?.label}</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography className={classes.toggleText}>{val?.value ? "Yes" : "No"}</Typography>
                  <FormControlLabel
                    className={classes.toggle}
                    checked={val?.value}
                    onChange={(e) => val?.onChange(e.target.checked)}
                    control={<IOSSwitch sx={{ m: 1 }} disabled={val?.isReadonly} />}
                  />
                </Stack>
              </Box>
            </Grid>
          );
        case "timeSlot": {
          return (
            <Grid xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}>
              <VisitorSlotSelection
                label={val?.label}
                isRequired={val?.isRequired}
                value={val?.value}
                options={val?.options}
                updateState={val?.onChange}
                error={val?.error}
                handleChange={val?.handleChange}
                selectedSlot={val?.selectedSlot}
              />
            </Grid>

          )
        }
        default:
          return null;
      }
    }
  }

  return (
    <Box>
      {
        <Grid container spacing={props?.spacing ?? 2}>
          {
            components?.length && components?.map(_ => (
              <>
                {switchComponents(_)}

              </>
            ))
          }
        </Grid>
      }
    </Box>
  )
}