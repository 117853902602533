import { Box, Button, Divider, Stack, Typography } from "@mui/material"
import React from "react"
// import { Occupied, Reserved } from "../../../assets"
// import { BedIcon } from "../../../assets/propertyBoard/bedIcon"
// import { FloorIcon } from "../../../assets/propertyBoard/floorIcon"
// import { BathIcon } from "../../propertyFinder4/components"
import { useStyles } from "./style"
export const getColor = (val) => {
    switch (val) {
        case "Vacant":
            return { background: "#EEF9EE", color: "#5AC782", buttonColor: "#5AC782" }
        case "Block":
            return { background: "#FF9340", color: "#FFFFFF", buttonColor: "#FF9340" }
        case "Occupied":
            return { background: "#FF4B4B", color: "#FFFFFF", buttonColor: "#FF4B4B" }

        case "Sold":
            return { background: "#FFECEC", color: "#FF4B4B" }
        case "Residental":
            return { background: "#78B1FE" }
        case "Commercial":
            return { background: "#896DB3" }
        case "Bedroom":
            return { background: "#499364" }
        case "Bed Only":
            return { background: "#B3776D" }
        default:
            return { background: "#78B1FE" }

    }
}
export const PropertyDetailCard = ({ t, val = {}, openViewModal = () => false, MoreDetails = () => false, selectedCompany = "" }) => {
    const classes = useStyles()
    const [ishover, setIsHover] = React.useState(false)
    return (

        <>
            <Box className={classes.propertyCard}

                onMouseOver={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}>
                <Box className={classes.textOnimg}>
                    <Box className={classes.propertyImage}>
                        <img src={val.image !== "" ? val.image : "/images/propertyImagePlaceholder.svg"} alt="unit_image" className={ishover ? classes.hoverUnitImg : classes.unit_img} />
                    </Box>
                    <Box className={classes?.property_type}>

                        <Typography className={classes.property_text}>{val?.property_type}</Typography></Box>

                </Box>
                <Box mt={1} display={"flex"} justifyContent={"space-between"} alignItems="center">
                    <Typography className={classes.unitName} style={{ color: val?.status === "Occupied" && "#98A0AC" }}>{val?.unit_name}</Typography>
                </Box>
                <Box>
                    <Stack direction="row" spacing={1} alignItems={"center"} divider={<Divider orientation="vertical" flexItem />}>
                        <Typography className={classes.amounttxt} noWrap>{val?.unit_type}</Typography>
                        <Typography className={classes.amounttxt}>{val?.area}</Typography>
                    </Stack>
                </Box>
                <Box mt={1}>
                    <Typography className={classes.startfrom}>{t("Start From")}</Typography>
                    <Stack direction="row" alignItems={"center"} spacing={0.5} mt={0.5}>
                        {/* <Typography className={classes.roomrent}>{selectedCompany?.currency_symbol}</Typography> */}
                        <Typography className={classes.roomrent}>{`${val?.room_rent} ${selectedCompany?.currency_symbol}`}</Typography>
                        <Typography className={classes.paymentPeriod}>/ {val?.payment_period === "Monthly" ? t("Month") : val?.payment_period}</Typography>
                    </Stack>
                    <Typography className={classes.taxtxt}>+ {t("taxes & charges")}</Typography>
                </Box>
                {/* {val?.no_of_bedrooms > 1 ?
                    <Box className={classes.Morebeds}>
                        <Stack direction="row" alignItems={"center"} spacing={1}>
                            <BedIcon />
                            <Typography className={classes.agreementTxt}>{`${val?.no_of_bedrooms} Beds Available`}</Typography>

                        </Stack>

                    </Box> :
                    <Stack justifyContent="space-evenly" mt={1} direction="row" spacing={2} alignItems="center">

                        {val?.unit_type &&
                            <Stack direction="row" spacing={0.5} alignItems="end">
                                <FloorIcon />
                                <Typography className={classes.floorTxt}>{val?.unit_type}</Typography>


                            </Stack>
                        }
                        {val?.bed &&
                            <Box className={classes.dotunit}></Box>
                        }
                        {val?.bed &&
                            <Stack direction="row" spacing={1} alignItems="end">
                                <BedIcon />
                                <Typography className={classes.floorTxt}>{val?.bed}</Typography>


                            </Stack>
                        }
                        {val?.bathrooms &&
                            <Box className={classes.dotunit}></Box>
                        }
                        {val?.bathrooms &&
                            <Stack direction="row" spacing={1} alignItems="end">
                                <BathIcon />
                                <Typography className={classes.floorTxt}>{val?.bathrooms}</Typography>
                            </Stack>
                        }

                    </Stack>
                } */}
                {ishover ?
                    <Box
                        className={classes.fixedStatusbtn}
                        display={"flex"}
                        justifyContent={"center"}
                        onClick={() => { (val?.no_of_bedrooms > 1 ? MoreDetails(val) : openViewModal(val)) }}
                    >
                        <Button variant="outlined" fullWidth className={classes.viewbtn}>{t("View Details")}</Button>
                    </Box>
                    :
                    <Box className={classes.fixedStatusbtn}>
                        <Box mt={1}>
                            <Button variant="contained" fullWidth style={{ backgroundColor: getColor(val?.execution_status === "Block" ? "Block" : val?.vacany_status)?.buttonColor }} className={classes.statusbtn}>{val?.execution_status === "Block" ? `Blocked` : `${t(val?.vacany_status)}`}</Button>

                        </Box >
                    </Box >
                }
                {/* {val?.status &&
                    <Box className={classes?.status_type}>{val?.status === "Occupied" ? 
                    <Occupied/>
                    : val?.status === "Reserved" ? <Reserved/> : ""}</Box>
                } */}

            </Box >

        </>
    )
}