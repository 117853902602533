import * as React from "react"

const PhoneAccount = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 48450"
                    transform="translate(257 136)"
                    fill="#5078e1"
                    d="M0 0h16v16H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 102680">
            <g
                data-name="Mask Group 6286"
                transform="translate(-257 -136)"
                clipPath="url(#a)"
            >
                <path
                    data-name="icons8-phone (1)"
                    d="m264.314 138.308.563 1.328a1.518 1.518 0 0 1-.333 1.683l-1.052 1a.5.5 0 0 0-.154.437 5.349 5.349 0 0 0 .676 1.823 5.743 5.743 0 0 0 1.271 1.527.5.5 0 0 0 .48.085l1.228-.41a1.333 1.333 0 0 1 1.527.537l.808 1.186a1.521 1.521 0 0 1-.169 1.91l-.536.538a1.979 1.979 0 0 1-2 .51q-2.5-.778-4.589-4.622t-1.481-6.53a2.136 2.136 0 0 1 1.409-1.575l.705-.225a1.352 1.352 0 0 1 1.647.798Z"
                    fill="#5078e1"
                />
            </g>
        </g>
    </svg>
)

export default PhoneAccount
