import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold, remCalc } from "../../../utils";

export const componentsStyle = makeStyles((theme) => ({
    parentBox: {
        padding: "12px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: theme.palette.borderRadius,
        height: (props) => props?.master ? "250px" : "180px"
    },
    tag: {
        fontFamily: Bold,
        fontSize: "0.75rem",
        color: "#5AC782",
        backgroundColor: "#EEF9EE",
        borderRadius: "4px",
        padding: "4px"
    },
    headerParent: {
        marginInlineStart: "10px"
    }
    ,
    header: {
        fontSize: remCalc(16),
        fontFamily: ExtraBold,
        color: "#071741",
        marginTop: "10px"

    },
    subHeader: {
        fontFamily: SemiBold,
        fontSize: remCalc(12),
        color: "#98A0AC",
        marginTop: "10px"

    },
    imgParent: {
        height: (props) => props?.master ? "140px" : "67px"
    },
    img: {
        width: "100%",
        objectFit: "cover",
        height: (props) => props?.master ? "140px" : "67px",
        borderRadius: "4px",
    },
    imgParent2: {
        height: (props) => props?.master ? "140px" : "67px",
        display: "flex",
        justifyContent: "space-between"
    },
    img2: {
        objectFit: "cover",
        height: (props) => props?.master ? "140px" : "67px",
        borderRadius: "4px",
        [theme.breakpoints.down("md")]: {
            width: "60px"
        },
        [theme.breakpoints.up("md")]: {
            width: "100px"
        },
        [theme.breakpoints.up("lg")]: {
            width: "100px"
        },
        [theme.breakpoints.up("xl")]: {
            width: "200px"
        },

    },
    text2: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: "#98A0AC"
    },
    text3: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "#5078E1"
    },
    text: {
        fontSize: "1rem",
        fontFamily: Bold,
        color: "#091B29",
        direction: "ltr"
    },
    //uploard card css
    uploadBox: {
        padding: "12px",
        backgroundColor: "#FFFFFF",
        border: "1px solid #E4E8EE",
        // boxShadow: "0px 0px 24px #00000014",
        borderRadius: "8px",
    },
    boxSection: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxSection2: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 6
    },
    uploadHeader: {
        fontSize: "0.875rem",
        fontFamily: Bold,
        color: "#4E5A6B"
    },
    icon: {
        fontSize: "1rem",
        color: "#98A0AC",
        cursor: "pointer"
    },
    cancelBtn: {
        border: `1px solid #CED3DD`,
        backgroundColor: "white",
        height: "40px",
        color: "#091B29",
        borderRadius: "8px",
        fontSize: "0.875rem",
        fontFamily: Bold,
        "&:hover": {
            backgroundColor: "white",
            border: `1px solid #CED3DD`,

        },
    },
    btn: {
        marginInlineStart: "-5px",
        color: "#5078E1",
        fontSize: remCalc(12),
        fontFamily: Bold,
        "&:hover": {
            backgroundColor: "inherit",
        },
    },
    saveBtn: {
        borderRadius: "8px",
        height: "40px",
        fontSize: "0.875rem",
        fontFamily: Bold,
        marginLeft: "10px"

    },
    uploadText: {
        fontSize: "0.875rem",
        fontFamily: (props) => props?.link ? SemiBold : Bold,
        color: theme.typography.color.tertiary,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "noWrap",
        maxWidth: "260px"
    },
    drap2: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        height: "70px",
        borderRadius: "4px",
        textAlign: "center",
        background: "#F5F7FA",
        border: "2px dashed #E4E8EE",
    },
    textAndUpload: {
        marginTop: (props) => props?.data?.link ? "18px" : "10px",
        marginBottom: (props) => props?.data?.link && "6px"
    }
}))