import gql from "graphql-tag";

export const GET_ALL_Tools = gql` 
query GET_ALL_Tools($offset: Int, $limit: Int, $searchText: String, $companyId: ID, $client: String) {
  count: tools_master(
    where: {or: {name: {iregex: $searchText}, tool_id: {iregex: $searchText}}, company_id: {eq: $companyId},  client: {eq: $client},
       is_active:{in:$status}
}
  ) {
    count: count_id
  }
  tools_master(
    where: {or: {name: {iregex: $searchText}, tool_id: {iregex: $searchText}}, company_id: {eq: $companyId},client: {eq: $client},
       is_active:{in:$status}
}
    offset: $offset
    limit: $limit
    order_by: {created_at: desc, name: asc}
  ) {
    id
    description
    tool_id
    name
    rate
    period
    company {
      id
      name
      currency{
        id
        symbol
      }
    }
    tools_assets {
      id
      url
      is_active
      file_meta
    }
    is_active
  }
}

`;
export const UPDATE_TOOLS_MASTER = gql`
mutation UPDATE_TOOLS_MASTER(
    $ID: ID
    $updatePayload: toolsMasterInput
  ) {
    tools_master(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`

