import {
  GET_PROPERTY_GROUP_MASTER, GET_RENTAL_BREAKUP_MASTER, GET_UNIT_RENTAL_BREAKUP_MASTER,
  GET_BLOCK_TYPE_BREAKUP_MASTER,
  GET_UNIT_PURPOSE_MASTER,
  GET_UNIT_TYPE_MASTER,
  GET_AMENITIES_TYPE_MASTER, UPDATE_PROPERTY_GROUP, UPDATE_RENTAL_GROUP,
  UPDATE_UNIT_RENTAL_GROUP,
  UPDATE_BLOCK_TYPE,
  UPDATE_UNIT_PURPOSE,
  UPDATE_UNIT_TYPE,
  UPDATE_AMENITIES_TYPE,
  EDIT_PROPERTY_GROUP,
  EDIT_RENTAL_GROUP,
  EDIT_BLOCK_TYPE,
  EDIT_UNIT_TYPE,
  EDIT_UNIT_PURPOSE,
  EDIT_AMENITIES_TYPE,
  EDIT_UNIT_RENTAL_GROUP,
  PROPERTY_SEARCH,
  RENTAL_SEARCH,
  UNIT_RENTAL_SEARCH, BLOCK_TYPE_SEARCH, UNIT_TYPE_SEARCH, AMENITIES_SEARCH
} from '../graphql/propertyMasterQuery';
import {
  PropertyPath,
  PropertyHeading,
  RentalPath,
  Rentalheading,
  PropertyType,
  RentalType,
  UnitPath,
  UnitHeading,
  UnitType,
  Propertydrawer,
  Unitdrawer,
  BlockPath,
  BlockType,
  BlockHeading,
  Blockdrawer,
  UnitTypetype,
  UnitTypedrawer,
  UnitTypePath,
  UnitTypeHeading,
  UnitPurposetype,
  UnitPurposedrawer,
  UnitPurposePath,
  UnitPurposeHeading,
  AmenitiesHeading,
  AmenitiesPath,
  Amenitiestype,
  Amenitiesdrawer,
  Rentaldrawer,
} from '.'
import { LocalStorageKeys } from "../utils";
export const propertyActions = (type, offset, limit, search, company, status, t=()=>false) => {
  switch (type) {
    case 'Property Type':
      return {
        type: GET_PROPERTY_GROUP_MASTER(offset, limit, search, localStorage.getItem(LocalStorageKeys.clinetID), company?.value, status).loc.source.body,
        resfunc: (array) => {
          let arr = array?.property_group_master?.map((data, index) => {
            let mainData = {}
            mainData.id = data?.id;
            // mainData.company_name = {
            //   label: data?.company_master?.name,
            //   value: data?.company_master?.id
            // };
            mainData.purpose = {
              label: data?.purpose,
              value: data?.purpose
            };
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_active = data?.is_active;
            mainData.is_external = data?.is_external;
            mainData.description = data?.description;
            mainData.group_name = data?.group_name
            mainData.icon = "editviewtoggle";
            mainData.index = offset + index + 1;
            return mainData
          })
          return arr
        },
        tbdata: {
          heading: PropertyHeading(t),
          path: PropertyPath,
          type: PropertyType,
          drawer: Propertydrawer(company , t),
        }
      }
    case 'Pricing Component':
      return {
        type: GET_RENTAL_BREAKUP_MASTER(offset, limit, search, localStorage.getItem(LocalStorageKeys.clinetID) ?? "").loc.source.body,
        resfunc: (array) => {
          let arr = array?.pricing_component?.map((data, index) => {
            let rental = {};
            rental.id = data?.id;
            rental.company_name = {
              label: data?.company_master?.name,
              value: data?.company_master?.id
            };
            rental.revenue_type = data?.revenue_type;
            rental.name = data?.name
            if (data.primary === true) { rental.primary = "Yes" } else { rental.primary = "No" }
            if (data.refundable === true) { rental.refundable = "Yes" } else { rental.refundable = "No" }
            if (data.is_onetime === true) { rental.is_onetime = "Yes" } else { rental.is_onetime = "No" }
            if (data.is_reserve_quality === true) { rental.is_reserve_quality = "Yes" } else { rental.is_reserve_quality = "No" }
            if (data.taxtable === true) { rental.taxtable = "Yes" } else { rental.taxtable = "No" }
            if (data.is_parking === true) { rental.is_parking = "Yes" } else { rental.is_parking = "No" }
            if (data.is_quantity === true) { rental.is_quantity = "Yes" } else { rental.is_quantity = "No" }
            if (data.is_override === true) { rental.is_override = "Yes" } else { rental.is_override = "No" }
            if (data.is_short_term === true) { rental.is_short_term = "Yes" } else { rental.is_short_term = "No" }
            rental.payment_period = data.payment_period;
            rental.is_active = data.is_active;
            rental.status = data?.is_active ? "Active" : "Inactive";
            rental.is_external = data.is_external;
            rental.index = offset + index + 1;
            rental.icon = "editviewtoggle";

            return rental;
          })
          return arr
        },
        tbdata: {
          heading: Rentalheading(t),
          path: RentalPath,
          type: RentalType,
          drawer: Rentaldrawer(t)
        }
      }
    case 'Unit Rental Breakup':
      return {
        type: GET_UNIT_RENTAL_BREAKUP_MASTER(offset, limit, search, localStorage.getItem(LocalStorageKeys.clinetID)).loc.source.body,
        resfunc: (array) => {
          let arr = array?.unit_rental_breakup.map((data, index) => {
            const uObj = {}
            uObj.id = data?.id
            uObj.component_type = {
              label: data?.rental_breakup_master?.description,
              value: data?.rental_breakup_master?.id
            }
            uObj.unit_name = data?.unit?.name
            uObj.period_type = data?.period_type
            uObj.currency = {
              label: data?.currency_master?.symbol,
              value: data?.currency_master?.id
            }
            uObj.value = data?.value
            uObj.value_type = data?.value_basis_type ? Unitdrawer?.find(_ => _?.state_name === "value_type")?.options?.find(_ => _?.value === data?.value_basis_type)?.label ?? '' : ''
            uObj.display = data?.display_percentage
            uObj.tax_group = {
              label: data?.vat_group_master?.group_name,
              value: data?.vat_group_master?.id
            }
            uObj.icon = "editviewtoggle"

            uObj.index = offset + index + 1;
            uObj.is_active = data.is_active;
            uObj.status = data?.is_active ? "Active" : "Inactive";
            uObj.is_external = data.is_external
            return uObj;
          })
          return arr
        },
        tbdata: {
          heading: UnitHeading,
          path: UnitPath,
          type: UnitType,
          drawer: Unitdrawer,
        }
      }
    case 'Block Type':
      return {
        type: GET_BLOCK_TYPE_BREAKUP_MASTER(offset, limit, search, localStorage.getItem(LocalStorageKeys.clinetID), company?.value, status).loc.source.body,
        resfunc: (array) => {
          let arr = array?.block_type_master.map((data, index) => {
            const bObj = {}
            bObj.id = data?.id
            // bObj.company_name = {
            //   label: data?.company_master?.name,
            //   value: data?.company_master?.id
            // };
            bObj.name = data?.name
            bObj.icon = "editviewtoggle"

            bObj.is_active = data.is_active;
            bObj.status = data?.is_active ? "Active" : "Inactive";
            bObj.is_external = data.is_external
            bObj.index = offset + index + 1;
            return bObj;
          })
          return arr
        },
        tbdata: {
          heading: BlockHeading(t),
          path: BlockPath,
          type: BlockType,
          drawer: Blockdrawer(company , t),
        }
      }
    case 'Unit Purpose':
      return {
        type: GET_UNIT_PURPOSE_MASTER,
        resfunc: (array) => {
          let arr = array?.unit_purpose_master.map(data => {
            const upObj = {}
            upObj.id = data?.id
            upObj.type = data?.type
            upObj.icon = "editviewtoggle"

            upObj.is_active = data.is_active
            upObj.status = data?.is_active ? "Active" : "Inactive";
            upObj.is_external = data.is_external
            return upObj;
          })
          return arr
        },
        tbdata: {
          heading: UnitPurposeHeading,
          path: UnitPurposePath,
          type: UnitPurposetype,
          drawer: UnitPurposedrawer,
        }
      }
    case 'Unit Type':
      return {
        type: GET_UNIT_TYPE_MASTER(offset, limit, search, localStorage.getItem(LocalStorageKeys.clinetID), company?.value, status).loc.source.body,
        resfunc: (array) => {
          let arr = array?.unit_type_master.map((data, index) => {
            const utObj = {}
            utObj.id = data?.id
            utObj.name = data?.name
            // utObj.company_name = {
            //   label: data?.company_master?.name,
            //   value: data?.company_master?.id
            // };
            utObj.purpose = {
              label: data?.purpose,
              value: data?.purpose
            };

            utObj.icon = "editviewtoggle"

            utObj.is_active = data.is_active
            utObj.status = data?.is_active ? "Active" : "Inactive";
            utObj.is_external = data.is_external
            utObj.index = offset + index + 1;
            return utObj;
          })
          return arr
        },
        tbdata: {
          heading: UnitTypeHeading(t),
          path: UnitTypePath,
          type: UnitTypetype,
          drawer: UnitTypedrawer(company,t),
        }
      }
    case 'Ameneties':
      return {
        type: GET_AMENITIES_TYPE_MASTER(offset, limit, search, localStorage.getItem(LocalStorageKeys.clinetID)).loc.source.body,
        resfunc: (array) => {
          let arr = array?.amenities_type_master.map((data, index) => {
            const aObj = {}
            aObj.id = data?.id
            aObj.company_name = {
              label: data?.company_master?.name,
              value: data?.company_master?.id
            };
            aObj.name = data?.amenities_name
            aObj.settings = data?.settings
            aObj.description = data?.description
            aObj.notes = data?.notes
            aObj.icon = "editviewtoggle"
            aObj.is_external = data.is_external
            aObj.is_active = data.is_active
            aObj.status = data?.is_active ? "Active" : "Inactive";
            aObj.index = offset + index + 1;
            return aObj;
          })
          return arr
        },
        tbdata: {
          heading: AmenitiesHeading(t),
          path: AmenitiesPath,
          type: Amenitiestype,
          drawer: Amenitiesdrawer(t),
        }
      }
    default:
      return null
  }
}

export const propertyUpdateActions = {
  'Property Type': {
    type: UPDATE_PROPERTY_GROUP,
  },
  'Pricing Component': {
    type: UPDATE_RENTAL_GROUP,
  },
  'Unit Rental Breakup': {
    type: UPDATE_UNIT_RENTAL_GROUP,
  },
  'Block Type': {
    type: UPDATE_BLOCK_TYPE,
  },
  'Unit Purpose': {
    type: UPDATE_UNIT_PURPOSE,
  },
  'Unit Type': {
    type: UPDATE_UNIT_TYPE,
  },
  'Ameneties': {
    type: UPDATE_AMENITIES_TYPE,
  },
}

export const propertyEditActions = {
  'Property Type': {
    type: EDIT_PROPERTY_GROUP,
    key: 'propertyId'
  },
  'Pricing Component': {
    type: EDIT_RENTAL_GROUP,
    key: 'rentalId'
  },
  'Block Type': {
    type: EDIT_BLOCK_TYPE,
    key: 'blockId'
  },
  'Unit Rental Breakup': {
    type: EDIT_UNIT_RENTAL_GROUP,
    key: 'unit_rentalId'
  },
  'Unit Purpose': {
    type: EDIT_UNIT_PURPOSE,
    key: 'unitId'
  },
  'Unit Type': {
    type: EDIT_UNIT_TYPE,
    key: 'unit_typeId'
  },
  'Ameneties': {
    type: EDIT_AMENITIES_TYPE,
    key: 'amenitiesId'
  },
}

export const propertySearch = {
  'Property Type': {
    type: PROPERTY_SEARCH,
    resfunc: (array) => {
      let arr = array?.property_group_master?.map(data => {
        let mainData = {}
        mainData.id = data?.id;
        mainData.company_name = data?.company_master?.name;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.description = data?.description;
        mainData.group_name = data?.group_name
        mainData.icon = "editviewtoggle";
        return mainData
      })
      return arr
    },
    tbdata: {
      heading: PropertyHeading,
      path: PropertyPath,
      type: PropertyType,
      drawer: Propertydrawer,
    }
  },
  "Pricing Component": {
    type: RENTAL_SEARCH,
    resfunc: (array) => {
      let arr = array?.pricing_component?.map(data => {
        let rental = {};
        rental.company_name = ""
        if (data.primary === true) {
          rental.primary = "Yes"
        }
        else {
          rental.primary = "No"
        }
        if (data.taxable === true) {
          rental.taxable = "Yes"
        }
        else {
          rental.taxable = "No"
        }
        if (data.is_onetime === true) {
          rental.is_onetime = "Yes"
        }
        else {
          rental.is_onetime = "No"
        }
        if (data.refundable === true) {
          rental.refundable = "Yes"
        }
        else {
          rental.refundable = "No"
        }
        if (data.is_reserve_quality === true) {
          rental.is_reserve_quality = "Yes"
        }
        else {
          rental.is_reserve_quality = "No"
        }
        rental.id = data?.id;
        rental.payment_period = data.payment_period;
        rental.description = data.description;
        rental.is_active = data.is_active;
        rental.is_external = data.is_external;
        rental.icon = "editviewtoggle"
        return rental;
      })
      return arr
    },
    tbdata: {
      heading: Rentalheading,
      path: RentalPath,
      type: RentalType,
      drawer: Rentaldrawer
    }
  },
  "Unit Rental Breakup": {
    type: UNIT_RENTAL_SEARCH,
    resfunc: (array) => {
      let arr = array?.unit_rental_breakup.map(data => {
        const uObj = {}
        uObj.id = data?.id
        uObj.component_type = data?.pricing_component?.description
        uObj.unit_name = data?.unit?.name
        uObj.period_type = data?.period_type
        uObj.currency = data?.currency_master?.symbol
        uObj.value = data?.value
        uObj.value_type = data?.value_basis_type
        uObj.display = data?.display_percentage
        uObj.tax_group = data?.vat_group_master?.group_name
        uObj.icon = "editviewtoggle"
        uObj.is_active = data.is_active
        uObj.is_external = data.is_external
        return uObj;
      })
      return arr
    },
    tbdata: {
      heading: UnitHeading,
      path: UnitPath,
      type: UnitType,
      drawer: Unitdrawer,
    }
  },
  "Block Type": {
    type: BLOCK_TYPE_SEARCH,
    resfunc: (array) => {
      let arr = array?.block_type_master.map(data => {
        const bObj = {}
        bObj.id = data?.id
        bObj.name = data?.name
        bObj.icon = "editviewtoggle"
        bObj.is_active = data.is_active
        bObj.is_external = data.is_external
        return bObj;
      })
      return arr
    },
    tbdata: {
      heading: BlockHeading,
      path: BlockPath,
      type: BlockType,
      drawer: Blockdrawer,
    }
  },
  "Unit Type": {
    type: UNIT_TYPE_SEARCH,
    resfunc: (array) => {
      let arr = array?.unit_type_master.map(data => {
        const utObj = {}
        utObj.id = data?.id
        utObj.name = data?.name
        utObj.icon = "editviewtoggle"
        utObj.is_active = data.is_active
        utObj.is_external = data.is_external
        return utObj;
      })
      return arr
    },
    tbdata: {
      heading: UnitTypeHeading,
      path: UnitTypePath,
      type: UnitTypetype,
      drawer: UnitTypedrawer,
    }
  },
  "Ameneties": {
    type: AMENITIES_SEARCH,
    resfunc: (array) => {
      let arr = array?.amenities_type_master.map(data => {
        const aObj = {}
        aObj.id = data?.id
        aObj.name = data?.amenities_name
        aObj.description = data?.description
        aObj.notes = data?.notes
        aObj.icon = "editviewtoggle"
        aObj.is_external = data.is_external
        aObj.is_active = data.is_active
        return aObj;
      })
      return arr
    },
    tbdata: {
      heading: AmenitiesHeading,
      path: AmenitiesPath,
      type: Amenitiestype,
      drawer: Amenitiesdrawer,
    }
  },
}