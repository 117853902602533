//import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ManagedInvoices } from "../../assets";
import { PropertyTabs, Subheader } from "../../components";
import { config } from "../../config";
import { AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { HistoryTag, Receipt } from "./sections";
import { ReceiptStyles } from "./styles";

const ReceiptView = ({ t }) => {
    const classes = ReceiptStyles()
    const history = useHistory();
    const { state } = useLocation()
    const [value, setValue] = React.useState(1);
    const [details, setDetails] = React.useState({});
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getReceipt();
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const tabTittle = [
        {
            label: t('receipts'),
            imgSelect: <ManagedInvoices color={value === 1 ? '#5078E1' : '#98A0AC'} />,
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: t('history'),
            imgSelect: <ManagedInvoices color={value === 2 ? '#5078E1' : '#98A0AC'} />,
            value: 2,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        }
    ]
    //get receipt
    const getReceipt = () => {
        const payload = {
            receipt_id: state?.id
        }
        NetworkCall(
            `${config.api_url}/settlement/get_receipt`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setDetails(response?.data?.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    //on change in tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const render = () => {
        return <>
            {/* subheader */}
            <Subheader title={t("Receipt View")} goBack={() => history.goBack()} />
            <PropertyTabs value={value} handleChange={handleChange}
                tab1={<Receipt details={details} id={state?.id} />}
                tab2={<HistoryTag id={state?.id} />}
                tabTittle={tabTittle}
                padding={true}
                t={t}


            />
        </>
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}

export default withNamespaces("receipt")(ReceiptView); 