import { Box, Grid, Stack, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import React, { useState } from "react";
import {
  Editor,
  MapWithFields,
  MobileNumberInputComponent,
  SelectBox,
  TextBox,
} from "../../../components";
import LogoUpload from "../../../components/LogoUpload";
import { GET_COUNTRY_MASTER_DROPDOWN_REGION } from "../../../graphql/bussinessMasterQuery";
import { COMPANY_TYPE_MASTER } from "../../../graphql/queries";
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import { mapResult } from "../../../utils/common";
import { useStylesCreation } from "../../propertcreation/createproperty/style";
import { config } from "../../../config";
import { Bold } from "../../../utils";
const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize:"0.75rem",
  fontFamily: Bold,
  color: theme.typography.color.secondary,
  marginBottom: theme.spacing(1),
}));

const STATUS = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

export const CompanyProfile = (props) => {
  const { data = {}, error = {}, updateState = null, mapLoad, checkCode = () => false, t } = props;


  const classes = useStylesCreation();
  const [loading, setLoading] = useState(false);

  const mapResultData = (map) => {
    let result = mapResult(map);
    updateState("", "address", result);
  };

  const loadOptionData = async (search, array, type) => {
    setLoading(type);
    if (type === "companyCountry") {
      return loadOptions(
        search,
        array,
        setLoading,
        GET_COUNTRY_MASTER_DROPDOWN_REGION,
        "country_master",
        {
          isActive: true,
          api_url: `${config?.api_url}/graphql`,
          // api_url: `${config?.graphql_url}`,
          url: true
        }
      );
    }
    if (type === "companyType") {
      return loadOptions(
        search,
        array,
        setLoading,
        COMPANY_TYPE_MASTER,
        "company_type_master",
        { isActive: true }
      );
    } else {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const {
    profileDetails = null,
    logo = null,
    address = null,
    contactInformation = null,
  } = data;
  const [fullScreenMap, setFullScreenMap] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener("fullscreenchange", existFullscreen);
    return () => {
      document.removeEventListener("fullscreenchange", existFullscreen);
    };
  }, []);
  //exist full screen
  const existFullscreen = () => {
    if (document.fullscreenElement === null) {
      setFullScreenMap(false);
    }
  };
  //open full screen
  const fullScreenControl = () => {
    setFullScreenMap(true);
    document.getElementsByClassName("map-wrapper")[0]?.requestFullscreen();
  };


  return (
    <div>
      <Stack gap={2} direction={{ sm: "row", xs: "column" }}>
        <Box className={classes.imagebox}>
          <LogoUpload
            isrequired
            isCompany
            logo_title={"Logo Image"}
            handleChange={(value) => updateState("", "logo", value)}
            data={logo}
            isError={error?.logo?.src?.error ?? false}
            errorMessage={error?.logo?.src?.errorMsg}
          />
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.imagebox1} sx={{ height: 1 }}>
              <Typography className={classes.title}>{t("Profile Details")}</Typography>
              <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <TextBox
                    isrequired
                    label={t("Company Code")}
                    placeholder={t("Enter Company Code")}
                    value={profileDetails?.companyCode ?? ""}
                    onChange={(e) =>
                      updateState(
                        "profileDetails",
                        "companyCode",
                        e.target.value
                      )
                    }
                    onBlur={(val) => val?.target?.value?.length > 0 && checkCode(val?.target?.value)}

                    // isReadonly={props?.isEdit}
                    isError={error?.profileDetails?.companyCode?.error ?? false}
                    errorMessage={error?.profileDetails?.companyCode?.errorMsg}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3.5} xl={2}>
                  <TextBox
                    isrequired
                    label={t("Company Name")}
                    placeholder={t("Enter Company Name")}
                    value={profileDetails?.companyName ?? ""}
                    onChange={(e) =>
                      updateState(
                        "profileDetails",
                        "companyName",
                        e.target.value
                      )
                    }
                    isError={error?.profileDetails?.companyName?.error ?? false}
                    errorMessage={error?.profileDetails?.companyName?.errorMsg}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3.5} xl={2}>
                  <TextBox
                    isrequired
                    label={t("Company Long Name")}
                    placeholder={t("Enter Company Long Name")}
                    value={profileDetails?.companyLongName ?? ""}
                    onChange={(e) =>
                      updateState(
                        "profileDetails",
                        "companyLongName",
                        e.target.value
                      )
                    }
                    isError={error?.profileDetails?.companyLongName?.error ?? false}
                    errorMessage={error?.profileDetails?.companyLongName?.errorMsg}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={1.5} xl={2}>
                  <SelectBox
                    isRequired={true}
                    label={t("Status")}
                    options={STATUS}
                    value={profileDetails?.status ?? null}
                    onChange={(value) =>
                      updateState("profileDetails", "status", value)
                    }
                    isError={error?.profileDetails?.status?.error ?? false}
                    errorMessage={error?.profileDetails?.status?.errorMsg}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <SelectBox
                    isRequired={true}
                    label={t("Company's Country")}
                    placeholder={t("Select Country")}
                    // options={[]}
                    loadOptions={(search, array) =>
                      loadOptionData(search, array, "companyCountry")
                    }
                    isPaginate
                    debounceTimeout={800}
                    loading={loading === "companyCountry"}
                    // isSearchable={false}
                    value={profileDetails?.companyCountry ?? null}
                    onChange={(value) =>
                      updateState("profileDetails", "companyCountry", value)
                    }
                    isError={
                      error?.profileDetails?.companyCountry?.error ?? false
                    }
                    errorMessage={
                      error?.profileDetails?.companyCountry?.errorMsg
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
                  <SelectBox
                    isRequired={true}
                    label={t("Company Type")}
                    placeholder={t("Select Company Type")}
                    loadOptions={(search, array) =>
                      loadOptionData(search, array, "companyType")
                    }
                    isPaginate
                    debounceTimeout={800}
                    loading={loading === "companyType"}
                    value={profileDetails?.companyType ?? null}
                    onChange={(value) =>
                      updateState("profileDetails", "companyType", value)
                    }
                    isError={error?.profileDetails?.companyType?.error ?? false}
                    errorMessage={error?.profileDetails?.companyType?.errorMsg}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    height={"100px"}
                    value={profileDetails?.businessDescription ?? ""}
                    label={t("Business Description")}
                    handleChange={(e) =>
                      updateState("profileDetails", "businessDescription", e)
                    }
                    id={props?.id}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <div className="map-wrapper">
        <Box
          className={classes.imagebox1}
          style={{ marginTop: "24px", position: "relative" }}
        >
          <CustomTypography>{t("Address")}</CustomTypography>
          <MapWithFields
            fullScreenMap={fullScreenMap}
            fullScreenControl={fullScreenControl}
            mapOptions={{
              isInput: true,
              center: {
                lat: address?.latitude,
                lng: address?.longitude,
              },
              lat: address?.latitude,
              lng: address?.longitude,
              mapLoad,
            }}
            mapHeight={"370px"}
            breakpoints={{
              map: {
                xs: 12,
                sm: 12,
                md: 5,
                lg: 6,
                xl: 6,
              },
              components: {
                xs: 12,
                sm: 12,
                md: 7,
                lg: 6,
                xl: 6,
              },
            }}
            mapResult={mapResultData}
            fields={[
              {
                label: t("Door Number"),
                component: "TextField",
                value: address?.doorNumber,
                state_name: "doorNumber",
                isrequired: false,
                placeholder: t("Enter Door Number"),
                error: error?.address?.doorNumber?.error,
                errorMessage: error?.address?.doorNumber?.errorMsg,
                breakpoints: {
                  sm: 6,
                  md: 3,
                },
              },
              {
                label: t("Address Line 1"),
                component: "TextField",
                value: address?.addressLineOne,
                state_name: "addressLineOne",
                isrequired: false,
                placeholder: t("Enter Address Line 1"),
                errorMessage: error?.address?.addressLineOne?.errorMsg,
                error: error?.address?.addressLineOne?.error,
                breakpoints: {
                  sm: 6,
                  md: 4.5,
                },
              },
              {
                label: t("Address Line 2"),
                component: "TextField",
                value: address?.addressLineTwo,
                state_name: "addressLineTwo",
                isrequired: false,
                placeholder: t("Enter Address Line 2"),
                errorMessage: error?.address?.addressLineTwo?.errorMsg,
                error: error?.address?.addressLineTwo?.error,
                breakpoints: {
                  sm: 6,
                  md: 4.5,
                },
              },
              {
                label: t("Landmark"),
                component: "TextField",
                value: address?.landmark,
                state_name: "landmark",
                isrequired: false,
                placeholder: t("Enter Landmark"),
                errorMessage: error?.address?.landmark?.errorMsg,
                error: error?.address?.landmark?.error,
                breakpoints: {
                  sm: 6,
                  md: 3,
                },
              },
              {
                label: t("Area"),
                component: "TextField",
                value: address?.area,
                state_name: "area",
                placeholder: t("Enter Area"),
                errorMessage: error?.address?.area?.errorMsg,
                error: error?.address?.area?.error,
                isrequired:true,
                breakpoints: {
                  sm: 6,
                  md: 3,
                },
              },
              {
                label: t("City / District"),
                component: "TextField",
                value: address?.city,
                state_name: "city",
                isrequired: true,
                placeholder: t("Enter City"),
                errorMessage: error?.address?.city?.errorMsg,
                error: error?.address?.city?.error,
                breakpoints: {
                  sm: 6,
                  md: 3,
                },
              },
              {
                label: t("State"),
                component: "TextField",
                state_name: "state",
                value: address?.state,
                isrequired: true,
                placeholder: t("Enter State"),
                errorMessage: error?.address?.state?.errorMsg,
                error: error?.address?.state?.error,
                breakpoints: {
                  sm: 6,
                  md: 3,
                },
              },
              {
                label: t("Country"),
                component: "TextField",
                value: address?.country,
                state_name: "country",
                isrequired: true,
                placeholder: t("Enter Country"),
                errorMessage: error?.address?.country?.errorMsg,
                error: error?.address?.country?.error,
                breakpoints: {
                  sm: 6,
                  md: 3,
                },
              },
              {
                label: t("Pincode"),
                component: "TextField",
                state_name: "pincode",
                value: address?.pincode,
                isrequired: false,
                placeholder: t("Enter Pincode"),
                errorMessage: error?.address?.pincode?.errorMsg,
                error: error?.address?.pincode?.error,
                breakpoints: {
                  sm: 6,
                  md: 3,
                },
              },
              {
                label: t("Latitude"),
                component: "TextField",
                state_name: "latitude",
                value: address?.latitude,
                isrequired: false,
                placeholder: t("Enter Latitude"),
                errorMessage: error?.address?.latitude?.errorMsg,
                error: error?.address?.latitude?.error,
                breakpoints: {
                  sm: 6,
                  md: 3,
                },
              },
              {
                label: t("Longitude"),
                component: "TextField",
                state_name: "longitude",
                value: address?.longitude,
                isrequired: false,
                placeholder: t("Enter Longitude"),
                errorMessage: error?.address?.longitude?.errorMsg,
                error: error?.address?.longitude?.error,
                breakpoints: {
                  sm: 6,
                  md: 3,
                },
              },
            ]}
            onChangeFields={(key, value) => updateState("address", key, value)}
          />
        </Box>
      </div>
      <Box className={classes.imagebox1} style={{ marginTop: "24px" }}>

        <CustomTypography>{t("Contact & Other Information")}</CustomTypography>
        <Grid container ontainer rowSpacing={1} columnSpacing={3}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <MobileNumberInputComponent
              xs={4}
              label={t("Phone Number")}
              placeholder={t("Enter Phone Number")}
              value={contactInformation?.primaryTelephone}
              isRequired
              handleChange={(value) =>
                updateState("contactInformation", "primaryTelephone", value)
              }
              isError={
                error?.contactInformation?.primaryTelephone?.error ?? false
              }
              errorMessage={
                error?.contactInformation?.primaryTelephone?.errorMsg ?? ""
              }
              onKeyPress={(e) => {
                if (e.key === "e") {
                  e.preventDefault();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <MobileNumberInputComponent
              label={t("Mobile Number")}
              placeholder={t("Enter Mobile Number")}
              value={contactInformation?.primaryMobile}
              isRequired
              handleChange={(value) =>
                updateState("contactInformation", "primaryMobile", value)
              }
              isError={error?.contactInformation?.primaryMobile?.error ?? false}
              errorMessage={
                error?.contactInformation?.primaryMobile?.errorMsg ?? ""
              }
              onKeyPress={(e) => {
                if (e.key === "e") {
                  e.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextBox
              isrequired
              label={t("Email Address")}
              placeholder={t("Enter Email Address")}
              value={contactInformation?.primaryEmailAddress ?? ""}
              onChange={(e) =>
                updateState(
                  "contactInformation",
                  "primaryEmailAddress",
                  e.target.value
                )
              }
              isError={
                error?.contactInformation?.primaryEmailAddress?.error ?? false
              }
              errorMessage={
                error?.contactInformation?.primaryEmailAddress?.errorMsg ??
                false
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextBox
              isrequired
              label={t("Website")}
              placeholder={t("Enter Website")}
              value={contactInformation?.website ?? ""}
              onChange={(e) =>
                updateState("contactInformation", "website", e.target.value)
              }
              isError={error?.contactInformation?.website?.error ?? false}
              errorMessage={
                error?.contactInformation?.website?.errorMsg ?? false
              }
            />
          </Grid>
        </Grid >
      </Box >
    </div >
  );
};
