import gql from "graphql-tag";
import { LocalStorageKeys } from "../utils";

export let GET_CURRENCY = (offset, limit, searchText) => gql`
query GET_CURRENCY{
  count: currency_master(
    where:{
       or:{
         name:{iregex:"${searchText}"}
        symbol:{iregex:"${searchText}"}
      }
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  currency_master(
      where:{
       or:{
         name:{iregex:"${searchText}"}
        symbol:{iregex:"${searchText}"}
      }
        is_active:{eq:true}
         }
      offset:${offset}
      limit:${limit}
    ){
         label:name
      	value:id

      }
}
`;
export let GET_VAT_GROUP = (offset, limit, searchText) => gql`
query GET_CURRENCY{
  count: vat_group_master(
    where:{
      group_name:{iregex:"${searchText}"}
      is_active:{eq:true}
      client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    }
  ) {
    count:count_id
  }
  vat_group_master(
      where:{
        group_name:{iregex:"${searchText}"}
        is_active:{eq:true}
         }
         client:{eq:"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
      offset:${offset}
      limit:${limit}
    ){
         label:group_name
        value:id

      }
}
`;
export const GET_AGREEMENT_DETAILS = gql`
{
  agreement(where: { id: $id }) {
    id
    payment_mode
    agreement_no
    agreement_status
    agreement_assets{
      id
      url
    }
    lease_period
    quotation_payment_schedule (where: { agreement_id: $id }) {
      description:name
      due_date: payment_due_date
      start_date: from
      to_date: to
      payment_period
      total: total_amount
    }
    billing_start_date
    agreement_type
    agreement_date
    lease_start_date
    lease_end_date
    total_amount
    total_discount
    billing_cycle_date
    total_refundable
    bill_generation
    is_agreement_lock
    increment_escalation
    auto_renewal_escalation
    contract_start_date
    auto_renewal
    is_renewal
    company {
      id
      name
      payment_terms
    }
    total_tax
    account_id
    broker: broker_accountByID {
      id
      name
      account_no
      logo
      email_id
      mobile_no
      mobile_no_country_code
    }
    customer_account: accountByID {
      id
      name
      account_no
      logo
      email_id
      mobile_no
      mobile_no_country_code
    }
    agreement_request(
      where:{is_active:true}
      order_by: {created_at: desc}

      ){
      id
      status
      agreement_id
      request_purpose
    }
    invoice_account: contact_account {
      id
      name
      logo
      email_id
      mobile_no
      mobile_no_country_code
    }
    contact {
      id
      first_name
      last_name
      user_profiles {
        user_no
      }
      image_url
      email_id
      mobile_no
      mobile_no_country_code
      phone_code
      know_your_client(where:{verified:true, valid_to: { gteq: $to }}) {
        id
        remarks
        proof_id
        name
        number
        valid_from
        is_mandatory
        url
        valid_to
        countryByID {
          country_name
        }
        status
        proofByID {
          type
        }
      }
    }
    sale_man: created_byByID {
      id
      first_name
      last_name
      image_url
      email_id
      mobile_no
      mobile_no_country_code
    }
    quotation {
      currency_id
      currency {
        id
        symbol
        name
      }
      id
      company {
        payment_terms
      }
        reservation(
        where: { is_active: true }
        orderby: { created_at: desc }
        limit: 1
      ) {
        id
        reservation_no
        reserved_amount
        reservation_status
        reservation_start_date
        reservation_expiry_date
      }
      auto_renewal_escalation
      billing_day
      bill_generation
      billing_cycle_date
      billing_start_date
      contract_start_date
      occpation_date
      unit_usage
      total_amount
      total_tax
      total_discount
      lease_period
      revenue_type
      quotation_assets {
        url
      }
      lead: leadByID {
        id
        lead_no
        subject
        lease_start_date
        lease_end_date
        grace_period_value
        apply_grace_period
        payment_mode
        unit_usage
        revenue_type
        payment_period
        lease_period
        value
        contract_start_date
        occpation_date
      }
      created_by
      user_profiles {
        id
        first_name
        last_name
        image_url
        email_id
        mobile_no
        mobile_no_country_code
      }
      quotation_no
      quote_start_date
      quotation_expiry_date
      unit_usage
      payment_mode
      lease_end_date
      quotation_units {
        id
        unit {
          name
          unit_type {
            id
            name
          }
          unit_no
          logo
        }
      }
      quotation_payment_schedule {
        description:name
        due_date: payment_due_date
        start_date: from
        to_date: to
        payment_period
        total: total_amount
      }
    }
  }
  is_parent_agreement: agreement(where: {parent_agreement_id: $id}){
    id
    agreement_status
  }

}

`;
export const UPDATE_PAYMENT_PEROID = gql`
mutation (
  $id: ID
  $payload: payload
) {
  agreement(
    where: { id: { eq: $id } }
    update: $payload
  ) {
    id
  }}
`;
export const GET_MANAGED_INVOICES = gql`
query {
  count: quotation_payment_schedule(
    where: { agreement_id: $id, description: { iregex: $search },is_active:true }
  ) {
    count: count_id
  }
  quotation_payment_schedule(
    offset: $offset
    limit: $limit
    where: { agreement_id: $id, description: { iregex: $search },is_active:true }
    orderby: { payment_due_date: asc }
  ) {
    id
    account_no
    description:name
    quotation_id
    due_date: payment_due_date
    start_date: from
    to_date: to
    payment_period
    total: total_amount
    schedule_no
    name
    status
    cheque_name
    break_up
    bank_name
    invoice_type
    payment_schedule_cheques{
      id
      cheque_name
      bank_name
    }
    quotation{
      revenue_type
    }
    invoice(where: { agreement_id: $id }) {
      id
      invoice_no
    }

    currency_id
    currency {
      symbol
    }
  }
}

`;
export const GET_INVOICES = gql`
query {
  count: invoice(
    where: { agreement_id: $id, invoice_no: { iregex: $search } }
  ) {
    count: count_id
  }
  invoice(
    offset: $offset
    limit: $limit
    where: { agreement_id: $id, invoice_no: { iregex: $search } }
    orderby: { created_at: desc }
  ) {

    id
    invoice_no
    invoice_type
    payment_status
    invoice_date
    invoice_due_amount
    payment_due_date
    invoice_total_amount
    invoice_total_tax_amount
    vat_group{
      group_name
    }
  }
}

`;
export const GET_INVOICE_DETAILS = gql`
query {
  invoice(where: { id: $id }) {
     id
    invoice_no
    invoice_type
    payment_status
    invoice_date
    invoice_due_amount
    payment_due_date
    invoice_total_amount
    invoice_total_tax_amount
    asset_url
    currency_master{
      symbol
    }
     payment_schedule{
      id
      payment_schedule_cheques{
        id
        cheque_name
        bank_name
        remarks
      }
    }
  }
}

`;
export const UPDATE_KYC_STATUS = gql`
mutation (
  $id: ID
  $payload: payload
) {
  know_your_client(
    where: { id: { eq: $id } }
    update: $payload
  ) {
    id
  }}
`;
export const UPDATE_PAYMENT_SCEDULE = gql`
mutation (
  $id: ID
  $payload: payload
) {
  quotation_payment_schedule(
    where: { id: { eq: $id } }
    update: $payload
  ) {
    id
  }}
`;
export const UPDATE_RENEWEL = gql`
mutation (
  $id: ID
  $payload: payload
) {
  quotation(
    where: { id: { eq: $id } }
    update: $payload
  ) {
    id
  }}
`;
export const CHEQUE_INSERT = gql`
  mutation($insert: [payment_schedule_chequesInput!]) {
    payment_schedule_cheques(insert: $insert ) {
      id
    }}
`;
export const UPDATE_CHEQUE = gql`
mutation (
  $id: ID
  $payload: payload
) {
  payment_schedule_cheques(
    where: { id: { eq: $id } }
    update: $payload
  ) {
    id
  }}
`;
export const GET_TIME_LINE = gql`
query {
  agreement_trackers(
    where: { agreement_id: $id , is_active:true}
    order_by: { created_at: desc }
  ) {
    id
    remarks
    status
    type
    assignee:assignedByID{
       first_name
       email_id
      last_name
      image_url
      mobile_no
      mobile_no_country_code
      id
    }
    created_by
    create:created_byByID{
      first_name
       last_name
       image_url
       email_id
 
       mobile_no
       mobile_no_country_code
   }
    user_profiles:created_byByID{
     first_name
      last_name
      image_url
      email_id

      mobile_no
      mobile_no_country_code
  }

    created_at
  }
  kyc_approval_requests(
    where: {aggreement_id: $id, is_active: true ,not:{status:"declined"} }
    order_by: {created_at: desc}
  ){
      id
    }
}


`;
export const TIMLINE_INSERT = gql`
  mutation($insert: [agreement_trackersInput!]) {
    agreement_trackers(insert: $insert ) {
      id
    }}
`;

export const GET_AGREMENT_INSPECTION = gql`
query {
  count: agreement_inspection(
    where: {
      agreement_units: { agreement_id: $id }
      reference_id: { iregex: $search }
    }
  ) {
    count: count_id
  }
  agreement_inspection(
    where: {
      agreement_units: { agreement_id: $id }
      reference_id: { iregex: $search }
    }
    orderby: { created_at: desc }
    offset: $offset
    limit: $limit
  ) {
    id
    reference_id
    created_at
    is_parking_component
    is_parking_unit
    to_inspection
    unit_id
    status
    execution
    agreement_units {
      id
      agreement_unit_users {
        contact {
          first_name
          mobile_no
          mobile_no_country_code
        }
      }
    }
      agreement_inspection_request{
      id
      agreement_id
      request_type
    }
    unit {
      id
      unit_no:name
      property {
        id
        property_no
        name
      }
    }
  }
}

`

export const GET_CONTACT = gql`
query {
  contact(
    where: { first_name: { iregex: $search } client:$client }
    offset: $offset
    limit: $limit
  ) {
    id
    name:first_name
    image_url
    email_id
    mobile_no
    mobile_no_country_code
  }
}

`

export const UPDATE_TRACK = gql`
mutation (
  $id: ID
  $payload: payload
) {
  agreement_trackers(
    where: { id: { eq: $id } }
    update: $payload
  ) {
    id
  }}
`;

export const GET_MANAGED_INVOICES_DETAILS = (invoice_id, id, agreement_id) => gql`
query {
  ${invoice_id ? `invoice_receipt_settlement(where: { invoice_id: "${invoice_id}" }){
    receipt{
      payment_mode
      cheque_no
      bank_name
          }
  }` : ''}
  
  ${id ? `payment_schedule_cheques(where: { payment_schedule: "${id}" }){
    cheque_name
    bank_name
  }` : ''}

  quotation_payment_schedule(
    where: { id: "${id}" }
  ) {
    id
    quotation_id
    description
    due_date: payment_due_date
    start_date: from
    to_date: to
    payment_period
    total: total_amount
    schedule_no
    break_up
    name
    status
    cheque_name
    bank_name
    invoice_type
    account_no
    payment_schedule_cheques{
      id
      cheque_name
      bank_name
    }
    invoice(where: { agreement_id: "${agreement_id}" }) {
      id
      invoice_no
    }
    currency_id
    currency {
      symbol
    }
  }
}

`;