/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  DrawerComponent,
  MobileNumberInputComponent,
  SearchFilter,
  SelectBox,
  TableWithPagination,
  TextBox,
  UseDebounce
} from "../../../components";
import MasterUploadModal from "../../../components";
import { config } from "../../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import {
  InsertData,
  ProfessionInsertData,
  updateEmergencyContact,
  UpdateEmergeny,
  UpdateEmergenyProfession,
  updateProfessionmaster
} from "../../../graphql/communityManager/mutations";
import {
  EmerencyNumber,
  GetList, GET_PROPERTY_BASED_ON_COMPANY, profession_master, PropertyName
} from "../../../graphql/communityManager/queries";
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import { AlertProps, LocalStorageKeys } from "../../../utils";
import { NetWorkCallMethods } from "../../../utils/constants";
import { useStylesMaster } from "./style";

const Propertylist = [
  {
    name: "Emergency Numbers",
    page: 1,
  },
  {
    name: "Profession Masters",
    page: 2,
  },
];

export const Master = () => {
  const classes = useStylesMaster();
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [emergency, setEmergency] = React.useState({
    name: "",
    propertyname: "",
    department: "",
    mobilenumber: "",
    id: "",
    error: {
      name: "",
      propertyname: "",
      department: "",
      mobilenumber: "",
    },
  });
  // profession name

  const [profession, setprofession] = React.useState({
    id: "",
    name: "",
    description: "",
    error: {
      id: "",
      name: "",
      description: "",
    },
  });
  const [value, setValue] = React.useState(1);
  const [searchtable, setsearchtable] = React.useState("");
  const [searchtable1, setsearchtable1] = React.useState("");
  const [data, setData] = React.useState({});
  const [id, setId] = React.useState(null);
  // const [isEdit, setisEdit] = React.useState(false);
  // const [isEditprofession, setisEditprofession] = React.useState(false);

  const [isEditEmergency, setisEditEmergency] = React.useState(false);
  const [isEditprofession1, setisEditprofession1] = React.useState(false);
  const [propertylist, setPropertylist] = React.useState([]);
  const [uploadModal, setUploadModal] = React.useState(false);
  const [view, setView] = React.useState(false);
  // const [disable, setDisable] = React.useState(false);
  const backdrop = React.useContext(BackdropContext);
  const [loading, setLoading] = React.useState(false)
  const urlSearch = useLocation().search;
  const initialTitle = new URLSearchParams(urlSearch).get("title");
  const clientID = localStorage.getItem(LocalStorageKeys.clinetID)
  const history = useHistory();
  const handleList = (val) => {
    let tempName = "";
    if (val === 1) {
      tempName = "Emergency Numbers";
    } else if (val === 2) {
      tempName = "Profession Masters";
    }
    history.push(Routes.master + "?title=" + tempName);
  };

  const commonFunc = (val) => {
    setValue(val);
    setPage(1);
    setLimit(10);
    setsearchtable("");
    setsearchtable1("");
    if (val === 1) {
      getBlockType();
    } else if (val === 2) {
      professionShow();
    }
  }

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  //debounce hook
  const debounce = UseDebounce();

  const handlePagination = (val) => {
    setPage(val);
    let offset = (val - 1) * limit;
    if (value === 1) {
      showList(id, offset, limit, searchtable);
    } else if (value === 2) {
      professionShow(offset, limit, searchtable1);
    }
  };

  const handleChangeLimit = (val) => {
    setLimit(val);
    setPage(1);
    if (value === 1) {
      showList(id, 0, val, searchtable);
    } else if (value === 2) {
      professionShow(0, val, searchtable1);
    }
  };

  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    if (value === 1) {
      showList(id, 0, limit, e);
    } else if (value === 2) {
      professionShow(0, limit, e);
    }
  };

  const handleUploadModal = (val, type) => {
    // selected tab master name
    if (type === "refresh") {
      if (val === "Emergency Numbers") {
        showList(id);
      }
      if (val === "Profession Masters") {
        professionShow();
      }
      setUploadModal(!uploadModal);
    } else {
      setUploadModal(!uploadModal);
    }
  };

  const toggleDrawer = (keys, v, key) => {
    if (keys === "edit") {
      setView(false);
      let remove = {
        id: "",
        name: "",
        description: "",
        error: {
          id: "",
          name: "",
          description: "",
        },
      };
      let remove1 = {
        name: "",
        propertyname: "",
        department: "",
        mobilenumber: "",
        id: "",
        error: {
          name: "",
          propertyname: "",
          department: "",
          mobilenumber: "",
        },
      };
      setprofession(remove);
      setEmergency(remove1);
      setOpen(!open);
      if (key === "edit") {
        let temp = {
          id: v.id,
          name: v.Name,
          propertyname: {
            value: v.property_id,
            label: v.PropertyName,
          },
          department: v.Department,
          mobilenumber: {
            mobile: v.mobile,
            mobile_code: v.code,
          },
          error: {
            name: "",
            propertyname: "",
            department: "",
            mobilenumber: "",
          },
        };

        setEmergency(temp);
        setisEditEmergency(true);
      }
      if (key === "professionedit") {
        let temp = {
          id: v.id,
          name: v.name,
          description: v.description,
          error: {
            id: "",
            name: "",
          },
        };

        setprofession(temp);
        setisEditprofession1(true);
      }
    }
    if (keys === "view") {
      // setisEdit(false);
      // setisEditprofession(false);
      if (key === "emergeny") {
        setView(true);
        // setisEdit(true);
        setOpen(!open);
        let temp = {
          id: v.id,
          name: v.Name,
          propertyname: {
            value: v.property_id,
            label: v.PropertyName,
          },
          department: v.Department,
          mobilenumber: {
            mobile: v.mobile,
            mobile_code: v.code,
          },
          error: {
            name: "",
            propertyname: "",
            department: "",
            mobilenumber: "",
          },
        };

        setEmergency(temp);

      }
      if (key === "profession") {
        setView(true);
        // setisEditprofession(true);
        setOpen(!open);
        let temp = {
          id: v.id,
          name: v.name,
          description: v.description,
          error: {
            id: "",
            name: "",
          },
        };

        setprofession(temp);
      }
    }
    if (keys === "toggle") {
      setView(false);
      const payload = {
        query: key === "edit" ? updateEmergencyContact : updateProfessionmaster,
        variables: {
          id: v.id,
          data: {
            is_active: v.is_active ? false : true,
          },
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          if (key === "edit") {
            showList(id);
          } else {
            professionShow();
          }
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong",
          });
        });
    }
    if (keys === "close") {
      let remove = {
        id: "",
        name: "",
        description: "",
        error: {
          id: "",
          name: "",
          description: "",
        },
      };
      let remove1 = {
        name: "",
        propertyname: "",
        department: "",
        mobilenumber: "",
        id: "",
        error: {
          name: "",
          propertyname: "",
          department: "",
          mobilenumber: "",
        },
      };
      setprofession(remove);
      setEmergency(remove1);
      setOpen(false)
      setisEditEmergency(false)
      setisEditprofession1(false)
      setView(false)
    }
  };

  const updateState = (key, value) => {
    let error = emergency.error;
    error[key] = "";
    setEmergency({ ...emergency, [key]: value, error });
  };

  const isIamValide = () => {
    let isValid = true;
    let error = emergency.error;
    //Checking name
    if (emergency.name.length === 0) {
      isValid = false;
      error.name = "Name is Required";
    }
    if (emergency.department.length === 0) {
      isValid = false;
      error.department = "Department is Required";
    }
    if (emergency.mobilenumber.length === 0) {
      isValid = false;
      error.mobilenumber = "Mobilenumber is Required";
    }
    if (emergency.propertyname.length === 0) {
      isValid = false;
      error.propertyname = "Property Name is Required";
    }
    if (emergency.name.length === 0 || emergency.department.length === 0 ||
      emergency.mobilenumber.length === 0 || emergency.propertyname.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all mandatory field",
      });
    }
    setEmergency({ ...emergency, error });

    return isValid;
  };

  const isIamValideProfession = () => {
    let isValid = true;
    let error = profession.error;
    //Checking name
    if (profession?.name?.length === 0) {
      isValid = false;
      error.name = "Name is Required";
    }
    if (profession?.description?.length === 0) {
      isValid = false;
      error.description = "Description is Required";
    }
    if (profession?.name?.length === 0 || profession?.description?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all mandatory field",
      });
    }
    setprofession({ ...profession, error });

    return isValid;
  };

  const updateStateprofession = (key, value) => {
    let error = profession.error;
    error[key] = "";
    setprofession({ ...profession, [key]: value, error });

  };

  const getBlockType = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const payload = {
      query: EmerencyNumber,
      variables: {
        id: localStorage.getItem("profileID"),
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {

        showList(response.data.data.access_control.map((x) => x.property_id));
        setId(response.data.data.access_control.map((x) => x.property_id));
        // professionShow()
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };
  const loadOptions = async (search = "", array, type) => {

    setLoading(type);
    let result, query, offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    }
    else {
      offset = array?.length;
    }
    const companyIDs = auth?.auth?.auth?.access?.[auth?.auth?.auth?.activeRole]?.company?.map((item) => (
      { value: item.value, label: item.label }));
    let companyID = companyIDs?.map(({ value }) => value);

    switch (type) {
      case 'propertyName':
        if (!companyID?.length) {
          setLoading(null);
          return { options: [] }
        }
        query = GET_PROPERTY_BASED_ON_COMPANY(offset, 10, search, companyID, localStorage.getItem(LocalStorageKeys?.userProfileID), localStorage.getItem(LocalStorageKeys?.roleID)).loc.source.body;
        result = await networkCallBack(query);

        return {
          options: [...result?.property?.map(x => x?.property)],
          hasMore: (array?.length + result?.property?.length) < result?.count[0]?.count
        }
      default:
        return { options: [] }
    }
  }
  const networkCallBack = async (query, variable = {}) => {

    let payload = {
      query,
      variable: variable
    };

    const options = await NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        setLoading(null);
        return main
      })
      .catch((error) => {
        setLoading(null);
        console.log(error);
        return null
      });

    return options
  }

  const inSert = () => {
    if (isEditEmergency === true) {
      if (isIamValide()) {
        // setDisable(true)
        const payload = {
          query: UpdateEmergeny,
          variables: {
            proprtyId: emergency.id,
            data: {
              property_id: emergency.propertyname.value,
              name: emergency.name,
              department: emergency.department,
              contact_number: emergency.mobilenumber.mobile,
              country_code: emergency.mobilenumber.mobile_code,
              is_active: true,
              updated_at: new Date().toISOString(),
              updated_by: localStorage.getItem("profileID"),
            },
          },
        };
        NetworkCall(
          `${config.graphql_url}`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        )
          .then((response) => {
            // toggleDrawer();
            showList(id);
            setisEditEmergency(false);
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Updated Successfully",
            });
            // setOpen(false)
            setisEditEmergency(false);
            // let remove1 = {
            //   name: "",
            //   propertyname: "",
            //   department: "",
            //   mobilenumber: "",
            //   id: "",
            //   error: {
            //     name: "",
            //     propertyname: "",
            //     department: "",
            //     mobilenumber: "",
            //   },
            // };
            // setEmergency(remove1);
            toggleDrawer('close')
          })
          .catch((error) => {
            // setDisable(false)
            setisEditEmergency(false);
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Some Thing Went Wrong",
            });
          });
      }
    } else {
      if (isIamValide()) {
        // setDisable(true)
        setisEditEmergency(false);
        const payload = {
          query: InsertData,
          variables: {
            data: {
              property_id: emergency.propertyname.value,
              name: emergency.name,
              department: emergency.department,
              contact_number: emergency.mobilenumber.mobile,
              country_code: emergency.mobilenumber.mobile_code,
              is_active: true,
              client: clientID

            },
          },
        };
        NetworkCall(
          `${config.graphql_url}`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        )
          .then((response) => {
            showList(id);
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Successfully Created",
            });
            toggleDrawer('close')
          })
          .catch((error) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Some Thing Went Wrong",
            });
          });
      }
    }
  };

  const inSertProfession = () => {
    if (isEditprofession1 === true) {

      if (isIamValideProfession()) {
        // setDisable(true)
        const payload = {
          query: UpdateEmergenyProfession,
          variables: {
            proprtyId: profession.id,
            data: {
              name: profession.name,
              description: profession.description,
              is_active: true,
              updated_at: new Date().toISOString(),
            },
          },
        };
        NetworkCall(
          `${config.graphql_url}`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        )
          .then((response) => {
            toggleDrawer();
            professionShow();
            // setDisable(false)
            setisEditprofession1(false);
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Updated Successfully",
            });
            let remove = {
              id: "",
              name: "",
              description: "",
              error: {
                id: "",
                name: "",
                description: "",
              },
            };
            setprofession(remove);
            setOpen(false)
          })
          .catch((error) => {
            // setDisable(false)
            setisEditprofession1(false);
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Some Thing Went Wrong",
            });
          });

      }

    } else {
      setisEditprofession1(false);
      if (isIamValideProfession()) {
        const payload = {
          query: ProfessionInsertData,
          variables: {
            data: {
              name: profession.name,
              description: profession.description,
              is_active: true,
              client: clientID

            },
          },
        };
        NetworkCall(
          `${config.graphql_url}`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        )
          .then((response) => {
            // toggleDrawer();
            professionShow();
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Created Successfully",
            });
            setOpen(false)
          })
          .catch((error) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Some Thing Went Wrong",
            });
          });

      }
    }
  };

  const showList = (val, offset = 0, limit = 10, searchText = "") => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const payload = {
      query: GetList,
      // query: GetList(val,offset,limit,searchText).loc.source.body,
      variables: {
        property_id: val,
        client: clientID,
        offset,
        limit,
        searchText,
        data: {
          is_active: true,
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setData({
          data: response?.data?.data?.emergency_master,
          totalRowsCount: response?.data?.data?.totalCountData?.[0]?.totalCount,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {

        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };

  const searchSection = (v) => {

    setsearchtable(v);
    debounce(() => searchTableFunction(v), 800);
  };

  const searchSection1 = (v) => {
    setsearchtable1(v);
    debounce(() => searchTableFunction(v), 800);
    // searchtable1
  };

  const professionShow = (offset = 0, limit = 10, searchText = "") => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const payload = {
      query: profession_master(clientID, offset, limit, searchText).loc.source.body,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setPropertylist({
          data: response?.data?.data?.profession_master,
          totalRowsCount: response?.data?.data?.totalCountData?.[0]?.totalCount,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };

  const BlockPath = [
    "PropertyName",
    "Name",
    "Department",
    "ContactNumber",
  ];

  const BlockHeading = [
    { title: "Property Name", field: "PropertyName" },
    { title: "Name", field: "Name" },
    { title: "Department", field: "Department" },
    { title: "ContactNumber", field: "ContactNumber" },
    { title: "", field: "icon" },
  ];

  const BlockRow = useCallback(
    data?.data?.map((val, index) => {
      let _d;
      try {
        _d = {
          id: val.id,
          property_id: val.property.id,
          PropertyName: val.property.name,
          Name: val.name,
          Department: val.department,
          code: val.country_code,
          mobile: val.contact_number,
          index: (page - 1) * limit + index + 1,
          is_active: val?.is_active,
          icon: "viewedittoggle",
        };
      } catch (err) {

      }
      return _d;
    }),
    [data]
  );
  // profession master

  const ProfessionPath = ["name", "description"];

  const ProfessionHeading = [
    { title: "Name", field: "name" },
    { title: "Description", field: "description" },
    { title: "", field: "icon" },
  ];
  const ProfessionRow = useCallback(
    propertylist?.data?.map((val, index) => {
      let _d;
      try {
        _d = {
          id: val.id,
          name: val.name,
          description: val.description,
          index: (page - 1) * limit + index + 1,
          is_active: val?.is_active,
          icon: "viewedittoggle",
        };
      } catch (err) {

      }
      return _d;
    }),
    [propertylist]
  );

  React.useEffect(() => {
    if (initialTitle === "Emergency Numbers") {
      commonFunc(1)
    } else if (initialTitle === "Profession Masters") {
      commonFunc(2)
    } else {
      commonFunc(1)
    }
    // getBlockType();
    // professionShow();
    // eslint-disable-next-line
  }, [initialTitle]);


  return (
    <div className={classes.root}>
      <Grid constainer spacing={2} direction="row" className={classes.parent}>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridList}>
          <SearchFilter
            handleChange={setSearch}
            value={search}
            placeholder={"Search"}
          />
          <div
            style={{
              height: `calc(100vh - 156px)`,
              overflow: "auto",
              marginTop: "4px",
            }}
          >
            <List>
              {Propertylist?.filter((val) => {
                if (search === "") {
                  return val;
                } else if (
                  val.name.toLowerCase().includes(search.toLowerCase())
                ) {
                  return val;
                }
              }).map((val) => (
                <ListItem
                  className={
                    val.page === value ? classes.listText : classes.list
                  }
                  onClick={() => handleList(val.page)}
                >
                  <ListItemText primary={val.name} />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          className={classes.grid}
          justifyContent="space-between"
        >
          <Grid container spacing={3}>
            <Grid item xs={4}>
              {value === 1 && (
                <SearchFilter
                  handleChange={searchSection}
                  value={searchtable}
                  placeholder={"Search By Department"}
                />
              )}
              {value === 2 && (
                <SearchFilter
                  handleChange={searchSection1}
                  value={searchtable1}
                  placeholder={"Search By Name"}
                />
              )}
            </Grid>
            <Grid item xs={8} textAlign="right">
              <Button
                color="primary"
                variant={"outlined"}
                onClick={handleUploadModal}
                style={{ marginRight: 12 }}
              >
                Bulk Upload
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpen(true)}
              >
                Add new
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.table}>
            {value === 1 && BlockHeading && (
              <TableWithPagination
                heading={BlockHeading}
                rows={BlockRow}
                path={BlockPath}
                searchtable={searchtable}
                count="2"
                name={PropertyName}
                tableType=""
                showpagination={true}
                handleIcon={toggleDrawer}
                dataType={[
                  { type: ["text"], name: "PropertyName" },
                  { type: ["text"], name: "Name" },
                  { type: ["text"], name: "Department" },
                  { type: ["phone"], name: "ContactNumber" },
                  { type: ["icon"], icon: "icon" },
                ]}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                onClick={() => console.log("")}
                totalRowsCount={data?.totalRowsCount}
                page={page}
                limit={limit}
                height={'calc(100vh - 240px)'}
                view={true}
                edit={true}
                delete={true} />
            )}
            {value === 2 && (
              <TableWithPagination
                heading={ProfessionHeading}
                rows={ProfessionRow}
                path={ProfessionPath}
                count="2"
                tableType=""
                showpagination={true}
                handleIcon={toggleDrawer}
                onClick={() => console.log("")}
                dataType={[
                  { type: ["text"], name: "name" },
                  { type: ["text"], name: "description" },
                  { type: ["icon"], icon: "icon" },
                ]}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={propertylist?.totalRowsCount}
                page={page}
                limit={limit}
                height={'calc(100vh - 240px)'}
                view={true}
                edit={true}
                delete={true} />
            )}

            <DrawerComponent
              open={open}
              // toggleDrawer={toggleDrawer}
              // onClose={toggleDrawer}
              component={
                <div className={classes.drawerwidth}>
                  <Box className={classes.Header}>
                    <Box className={classes.closeIcondrawermap}>
                      <Typography variant="h6" onClick={() => toggleDrawer('close')}>
                        <img src="\images\closebutton.svg" alt="close" />
                      </Typography>
                    </Box>
                    {value === 1 && (
                      <Typography variant="h6" className={classes.title} noWrap>
                        Create Emergency Number
                      </Typography>
                    )}
                    {
                      value === 2 &&
                      <Typography variant="h6" className={classes.title} noWrap>
                        Create Profession
                      </Typography>
                    }
                  </Box>
                  <div className={classes.createnewsection}>
                    {/* emergeny drawer */}
                    {value === 1 && (
                      <>
                        {/* property name */}
                        <SelectBox
                          isRequired
                          label="Property Name"
                          isPaginate
                          debounceTimeout={800}
                          loadOptions={(search, array) => loadOptions(search, array, 'propertyName')}

                          loading={loading === "propertyName"}
                          placeholder="Property Name"
                          isReadOnly={view === true ? true : false}
                          value={emergency.propertyname}
                          //options={propertyID}
                          onChange={(value) =>
                            updateState("propertyname", value)
                          }
                          isError={emergency?.error?.propertyname?.length > 0}
                          errorMessage={emergency?.error?.propertyname}
                        />

                        {/* name */}
                        <div className={classes.input}>
                          <TextBox
                            isrequired
                            label={"Name"}
                            type={"text"}
                            placeholder={"Name"}
                            value={emergency.name}
                            isReadonly={view === true ? true : false}
                            onChange={(e) =>
                              updateState("name", e.target.value)
                            }
                            isError={emergency?.error?.name?.length > 0}
                            errorMessage={emergency?.error?.name}
                          />
                        </div>

                        {/* department */}
                        <div className={classes.input}>
                          <TextBox
                            isrequired
                            label={"department"}
                            type={"text"}
                            placeholder={"department"}
                            isReadonly={view === true ? true : false}
                            value={emergency.department}
                            onChange={(e) =>
                              updateState("department", e.target.value)
                            }
                            isError={emergency?.error?.department?.length > 0}
                            errorMessage={emergency?.error?.department}
                          />
                        </div>

                        {/* mobile */}
                        <div className={classes.input}>
                          <MobileNumberInputComponent
                            isRequired
                            isReadonly={view === true ? true : false}
                            label="Contact Number "
                            handleChange={(value) =>
                              updateState("mobilenumber", value)
                            }
                            value={emergency?.mobilenumber}
                            isError={emergency?.error?.mobilenumber?.length > 0}
                            errorMessage={emergency?.error?.mobilenumber}
                          />
                        </div>
                      </>
                    )}

                    {/* profession drawer */}
                    {value === 2 && (
                      <>
                        {/* profession name */}
                        <div>
                          <TextBox
                            isrequired
                            label={"Profession Name"}
                            type={"text"}
                            placeholder={"Profession Name"}
                            value={profession.name}
                            isReadonly={view === true ? true : false}
                            onChange={(e) =>
                              updateStateprofession("name", e.target.value)
                            }
                            isError={profession?.error?.name?.length > 0}
                            errorMessage={profession?.error?.name}
                          />
                        </div>

                        {/*  description */}
                        <div className={classes.input}>
                          <TextBox
                            isrequired
                            label={"Description"}
                            type={"text"}
                            isReadonly={view === true ? true : false}
                            placeholder={"Description"}
                            value={profession.description}
                            multiline
                            rows={3}
                            onChange={(e) =>
                              updateStateprofession(
                                "description",
                                e.target.value
                              )
                            }
                            isError={profession?.error?.description?.length > 0}
                            errorMessage={profession?.error?.description}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <Box className={classes.btnparent}>
                    {value === 1 && (
                      <>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={inSert}
                          disabled={view === true ? true : false}
                        >

                          {isEditEmergency ? "Update" : "Create"}
                        </Button>
                      </>
                    )}
                    {value === 2 && (
                      <>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={inSertProfession}
                          disabled={view === true ? true : false}
                        >
                          {isEditprofession1 ? "Update" : "Create"}
                        </Button>
                      </>
                    )}
                  </Box>
                </div>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Upload Modal */}
      {uploadModal && (
        <MasterUploadModal
          open={uploadModal}
          closeModal={handleUploadModal}
          docsType={value === 1 ? "Emergency Numbers" : "Profession Masters"}
        />
      )}
    </div>
  );
};
