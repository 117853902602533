import React from 'react'
import { AlreadyPaidTable } from './components/alreadyPaidTable'
import { AlreadyPaidHeading, NetWorkCallMethods, getRoutePermissionNew, getCompanyOption, accessCheckRender, AlreadyPaidPath } from '../../utils'
import styled from '@mui/material/styles/styled';
import { Subheader, UseDebounce } from "../../components"
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import moment from 'moment';
import { AuthContext, BackdropContext } from "../../contexts";
import { withNamespaces } from 'react-i18next';

const CustomPaper = styled('div')(({ theme }) => ({
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    height: `calc(100vh - 150px)`,
    overflow: "auto",
    margin: "16px",
    position: "relative"
}))

const AlreadyPaid = ({
    t = () => false
}) => {
    const [datas, setData] = React.useState([])
    const [tablerows, setTablerows] = React.useState("")
    const [filterData, setFilterData] = React.useState([])
    const [permission, setPermission] = React.useState({});
    const [selectedCompany, setSelectedCompany] = React.useState("");
    const [companyList, setCompanyList] = React.useState([]);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState("");
    const debounce = UseDebounce();

    const getAlreadyPaid = (e, limit, offset) => {
        const variables = {
            "company_id": selectedCompany?.value,
            "offset": offset,
            "limit": limit,
            "start_date": filterData?.dateRange?.startDate ? moment(filterData?.dateRange?.startDate).format("YYYY-MM-DD") : null,
            "end_date": filterData?.dateRange?.endDate ? moment(filterData?.dateRange?.endDate).add(1, 'days').format("YYYY-MM-DD") : null,
            "min": filterData?.amountMinMax?.fromAmount ?? 0,
            "max": filterData?.amountMinMax?.toAmount ?? 0,
            "search": e ?? "",
            "account_no": filterData?.accountNumber?.label ?? null
        }
        NetworkCall(
            `${config.api_url}/compensated_invoices/list`,
            NetWorkCallMethods.post,
            variables,
            null,
            true,
            false
        )
            .then((response) => {
                let result = response?.data?.data?.map((e) => {
                    return (
                        {
                            request_id: e?.reference_id ?? "-",
                            requested_by: e?.requested_by ?? "-",
                            invoice_number: e?.invoice_no ?? "-",
                            reference_num: e?.trx_no ?? "-",
                            date: e?.created_at ? moment(e?.created_at).format("YYYY-MM-DD") : "-",
                            agreement_no: e?.agreement_no ?? "-",
                            amount_paid: `${e?.amount_paid} ${e?.symbol}` ?? "-",
                            payment_date: e?.paid_on ?? "-",
                            payment_method: e?.payment_mode ?? "-",
                            status: e?.compensated_type ?? "-"
                        }
                    )
                })
                setTablerows(result)
                setData(response?.data)
            })
            .catch((error) => {

            });
    }
    // Construct row for table
    // const construct_rows = () => {
    //     let arr = datas?.data?.map((e) => {
    //         return (
    //             {
    //                 request_id: e?.reference_id ?? "-",
    //                 requested_by: e?.requested_by ?? "-",
    //                 invoice_number: e?.invoice_no ?? "-",
    //                 reference_num: e?.reference_id ?? "-",
    //                 date: e?.created_at ? moment(e?.created_at).format("YYYY-MM-DD") : "-",
    //                 agreement_no: e?.agreement_no ?? "-",
    //                 amount_paid: e?.amount_paid ?? "-",
    //                 payment_date: e?.paid_on ?? "-",
    //                 payment_method: e?.payment_mode ?? "-",
    //                 status: e?.compensated_type ?? "-"
    //             }
    //         )
    //     })
    //     setTablerows(arr)
    // }


    // React.useEffect(() => {
    //     construct_rows()
    //     // eslint-disable-next-line
    // }, [datas])

    // Search function
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getAlreadyPaid(e, limit, 0)
    }

    // get filter data 

    const getFilterData = (e) => {
        setFilterData(e)
    }
    React.useEffect(() => {
        if (selectedCompany?.value) {
            getAlreadyPaid()
        }
        // eslint-disable-next-line
    }, [selectedCompany, filterData])

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm);
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    setCompanyList(company?.list);
                    setSelectedCompany(company?.selected);
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    //   company change
    const handleCompanyChange = (e) => {
        setSelectedCompany(e);
    };

    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getAlreadyPaid("", limit, offset);
    };

    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
    };


    const render = () => {

        return (
            <div>
                <Subheader hideBackButton={true}
                    title={t("Already Paid")}
                    select
                    options={companyList ?? []}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e);
                    }}
                />
                <CustomPaper m={1}>
                    <AlreadyPaidTable
                        row={tablerows}
                        heading={AlreadyPaidHeading(t)}
                        handleSearch={handleSearch}
                        searchText={searchText}
                        getFilterData={getFilterData}
                        selectedCompany={selectedCompany}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        page={page}
                        limit={limit}
                        rowcount={datas?.count}
                        t={t} 
                        filterData={filterData}
                        pathName={AlreadyPaidPath} />
                </CustomPaper>
            </div>
        )
    }


    return <>{accessCheckRender(render, permission)}</>
}
export default withNamespaces("alreadyPaid")(AlreadyPaid)
