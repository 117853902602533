/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from "@apollo/client";
import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  FilterGenerator,
  Subheader,
  UseDebounce,
} from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { UPDATE_PROPERTY } from "../../graphql/mutations";
import {
  GET_PROPERTY_TYPE,
  companyDetailsInProprty,
  PROPERTY_HIERARCHY_MASTER_OPTION,
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, enumSelect, enum_types, ExtraBold, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods, SemiBold } from "../../utils";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import { AccountTable, DetailsContainer } from "./component";
import { Bold } from "../../utils";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    margin: "16px",
  },
  content: {
    textAlign: "center",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "none",
    fontSize:"0.875rem",
  },
  btn2: {
    borderRadius: theme.palette.borderRadius,
    color: theme.palette.secondary.main,
  },
  text: {
    fontSize:"1.5rem",
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize:"1rem",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  subText: {
    fontSize:"1rem",
    fontFamily: SemiBold,
    color: theme.typography.color.secondary,
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    //marginTop: "4px",
    padding: "12px",
  },
  tableHead: {
    backgroundColor: "#F2F4F7",
    color: theme.typography.color.secondary,
    borderRadius: theme.palette.borderRadius,
    fontSize:"0.75rem",
    fontFamily: SemiBold,
  },
  thead: {
    backgroundColor: "#F2F4F7",
    "& th:first-child": {
      borderRadius: "1em 0 0 1em",
    },
    "& th:last-child": {
      borderRadius: "0 1em 1em 0",
    },
  },
  tbody: {
    backgroundColor: "#F2F4F7",
    "& th:first-child": {
      borderRadius: "1em 0 0 1em",
    },
    "& th:last-child": {
      borderRadius: "0 1em 1em 0",
    },
  },
  businessType: {
    backgroundColor: "#78B1FE",
    color: "white",
    padding: "1px",
    borderRadius: theme.palette.borderRadius,
    textAlign: "center",
  },
  bottomTitle: {
    fontSize:"1.125rem",
    fontFamily: ExtraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    // padding: "12px",
  },

  grid: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius,
    padding: "4px",
  },
  title: {
    fontSize:"0.875rem",
    fontFamily: Bold,
    color: theme.typography.color.primary,
  },
  subTitle: {
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    color: theme.palette.primary.main,
  },
  divider: {
    height: "12px",
    width: "2px",
    backgroundColor: "black",
  },
  detailsContainer: {
    borderInlineStart: "1px solid #E4E8EE",

  },
  noFound: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - 250px)`
  },
  noData: {
    color: '#4E5A6B',
    margin: 0,
    overflow: 'hidden',
    fontSize:"0.75rem",
    fontFamily: Bold,
    fontWeight: 'bold',
  },
  noDataTeams: {
    textAlign: "center",
    marginTop: "25%",

  },
  root2: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    margin: "16px",
    height: `calc(100vh - 148px)`
  },
}));

const Properties = (props) => {
  const { loading, handleLoading, t } = props;
  const history = useHistory();
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext);
  const [drawer, setDrawer] = React.useState(null);
  // const [showDrawer, setShowDrawer] = React.useState(false);
  const [propertyList, setPropertyList] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const client = useApolloClient();
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedTeams, setSelectedTeams] = React.useState('')

  // const [uploadModal, setUploadModal] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [selectedCompany, setSelectedCompany] = React.useState({});

  const [filterData, setFilterData] = React.useState({ status: true });
  const [stats, setStats] = React.useState(null);
  const [detailLoader, setDetailLoader] = React.useState(false);
  const [companyDetails, setCompanyDetails] = React.useState({})
  // state
  const [enumValue, setEnumValue] = React.useState({ purpose_global_type: [], });
  // get enum
  const getEnum = async () => {
    const result = await enumSelect([enum_types?.purpose_global_type]);
    setEnumValue({ purpose_global_type: result?.purpose_global_type });
  };
  const [permission, setPermission] = React.useState({})
  const [loader, setLoader] = React.useState(true)

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        getEnum()
        getCompany()
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //debounce hook
  const debounce = UseDebounce();
  //more options
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getPropertyByCompanyID(offset, limit, searchText);
  };
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getPropertyByCompanyID(0, value, searchText);
    getPropertyByCompanyDetails(selectedCompany?.value)
  };
  const changeactive = async (data, value) => {
    client
      .mutate({
        mutation: UPDATE_PROPERTY,
        variables: {
          ID: data,
          updatePayload: {
            is_active: value === "Inactive" ? true : false,
          },
        },
      })
      .then((rs) => {
        getPropertyByCompanyID();
      })
      .catch((er) => { });
  };
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getPropertyByCompanyID(0, limit, e);
  };

  const handleOnClickAddPropertyButton = () => {
    history.push({
      pathname: Routes.propertycreation,
      state: {
        main: {
          data: companyDetails ?? {},
          company: selectedCompany,
          teams: selectedTeams,
        },
      },
    });
  };
  const handleIcon = (type, data, status) => {
    if (type === "edit") {
      history.push({
        pathname: Routes.propertycreation,
        state: {
          main: {
            company: selectedCompany,
            teams: selectedTeams,
            companyID: data?.companyID,
            propertyID: data?.id,
            isEdit: true,
          },
        },
      });
      // history.push(Routes.propertycreation + "?companyID=" + data?.companyID + "&propertyID=" + data?.id + "&topNavBarTitle=" + data?.propertyName)
    } else if (type === "info"||type==="double_click") {
      history.push(
        Routes.propertyview +
        "?companyID=" +
        data?.companyID +
        "&propertyID=" +
        data?.id +
        "&name=" +
        data?.propertyName
      );
    } else if (type === "active") {
      changeactive(data.id, status);
    } else if (type === "view") {
      const tempList = propertyList?.data?.map(_ => {
        return { ..._, selectedRow: _?.id === data?.id ?? false }
      })
      setPropertyList({ ...propertyList, data: tempList })
      getPropertyStats(data?.id);
    }
  };
  const onUnitClick = (data, val) => {
    if (val === "block") {
      history.push(Routes.block + "?propertyID=" + data?.id);
    } else if (val === "floor") {
      history.push(Routes.floor + "?propertyID=" + data?.id);
    } else if (val === "unit") {
      history.push(Routes.unit + "?propertyID=" + data?.id);
    }
    // if (val === "unit") {
    //     history.push(Routes.unitTable + "?companyID=" + data?.companyID + "&propertyID=" + data?.id + "&topNavBarTitle=" + data?.propertyName)
    // } else
  };
  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const propertyRow = React.useCallback(
    propertyList?.data?.map((val, index, array) => {
      let _d;
      try {
        _d = {
          index: (page - 1) * limit + index + 1,
          selectedRow: val?.selectedRow,
          propertyNo: val?.property_no,
          propertyName: val?.name,
          companyName: val?.company_master_name,
          area: val?.area ?? "-",
          city: val?.city ?? "-",
          location: (val?.area || val?.city) ?
            ((val?.area ? (val?.area + ", ") : "") + (val?.city ?? "")) : "-",
          blocks: val?.block.length,
          floors: val?.floor.length,
          units: val?.unit.length,
          propertyType: val?.property_group_master_group_name,
          propertyPurpose: val?.property_purpose ?? "-",
          propertyHierarchy: val?.property_hierarchy_name,
          icon: "editview",
          companyID: val?.company_master_id,
          id: val?.id,
        };
      } catch (err) { }
      return _d;
    }),
    [propertyList]
  );

  const getPropertyByCompanyID = (offset = 0, limit = 10, search = "") => {
    let companyID = selectedCompany?.value;
    let propertyGroudID = filterData.property_type?.length
      ? filterData?.property_type?.map(({ value }) => value)
      : [];
    let propertyPurpose = filterData.property_purpose?.length
      ? filterData?.property_purpose?.map(({ value }) => value)
      : [];
    let propertyHierarchyID = filterData.property_hierarchy?.length
      ? filterData?.property_hierarchy?.map(({ value }) => value)
      : [];
    let is_active = [true, false].includes(filterData?.status) ? [filterData?.status] : [];
    let teamsId = selectedTeams?.value;

    const payload = {
      company_id: companyID,
      status: is_active,
      property_groud_id: propertyGroudID,
      property_hierarchy_id: propertyHierarchyID,
      property_purpose: propertyPurpose,
      offset: offset,
      limit: limit,
      search: search
    }
    if (teamsId !== "noteams") {
      payload["team"] = teamsId
      payload["team_all_property"] = true
    }
    NetworkCall(
      `${config.api_url}/property/getAllProperties`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setPropertyList({
          data: response?.data?.data?.property,
          totalRowsCount: response?.data?.data?.count,
        });
        if (response?.data?.data?.property?.length > 0) {
          getPropertyStats(response?.data?.data?.property?.[0]?.id);
        } else {
          setDetailLoader(false);
        }
        setLoader(false)
        handleLoading(false)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };
  const getPropertyByCompanyDetails = (companyID) => {
    const payload = {
      query: companyDetailsInProprty(companyID).loc.source.body,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setCompanyDetails(response?.data?.data)
        setLoader(false)
        handleLoading(false)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };

  React.useEffect(() => {
    if (selectedTeams?.value) {
      getPropertyByCompanyID();
    }
  }, [selectedTeams, filterData]);

  const getCompany = () => {
    let company = getCompanyOption(backdrop, auth, alert)
    if (company) {
      setCompanyList(company?.list)
      setSelectedCompany(company?.selected)
      if (company?.list?.length > 0) {
        getPropertyByCompanyDetails(company?.selected?.value)
      }
    }
  }

  const handleCompanyChange = (value) => {
    setLoader(true)
    setDetailLoader(true);
    setSelectedCompany(value);
    getPropertyByCompanyDetails(value?.value)
  };

  const onApplyFilter = (value) => {
    setLoader(true)
    setDetailLoader(true);
    setFilterData(value);
  };

  const getPropertyStats = (id) => {
    setDetailLoader(true);
    const payload = {
      property_id: id,
    };
    NetworkCall(
      `${config.api_url}/property/stats`,
      NetWorkCallMethods.post,
      payload,
      true,
      true,
      false
    )
      .then((res) => {
        const unitCatagory = res?.data?.data?.unit_category?.map((x) => {
          return {
            name: x?.name,
            count: parseInt(x?.count),
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
          };
        });
        const unit_type = res?.data?.data?.unit_type?.map((x) => {
          return {
            name: x?.unit_type,
            count: parseInt(x?.count),
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
          };
        });
        let tempAssets = res?.data?.data?.assets?.map((_) => _)
        res?.data?.data?.property_details?.logo &&
          (tempAssets = [{ url: res?.data?.data?.property_details?.logo }, ...tempAssets])
        setStats({
          data: res?.data?.data,
          unit_catagory: unitCatagory ?? [],
          unit_type: unit_type ?? [],
          assets: tempAssets
        });
        setDetailLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setDetailLoader(false);
      });
  };

  // Function to change property
  const handleTeamsChange = (team) => {
    setSelectedTeams(team)
  }

  const render = () => {
    return <>
      <Subheader
        hideBackButton={true}
        title={t("Properties")}
        select
        options={companyList}
        value={selectedCompany}
        goBack={() => {
          history.goBack(-1);
        }}
        onchange={(e) => {
          handleCompanyChange(e);
        }}
        selectTeamsOptions
        handleTeamsChange={handleTeamsChange}
        companyId={selectedCompany?.value}
        moduleId={moduleId}
        selectedTeams={selectedTeams}
      />
      {
        loader ? (
          <NewLoader minusHeight="100px" />
        ) : (
          <Box className={selectedTeams ? classes.root : classes.root2}>
            <Grid container sx={{height:{sm:"calc(100vh - 153px)"}, overflow:"auto"}}>
              {selectedTeams ?
                <>
                  <Grid item xs={12} md={7} lg={8} sm={12}>
                    {/*table */}
                    <Box p={2}>
                      <AccountTable
                        permission={permission}
                        draweropen={draweropen}
                        data={propertyRow}
                        selectedCompany={selectedCompany}
                        handleChangeLimit={handleChangeLimit}
                        handlePagination={handlePagination}
                        page={page}
                        handleSearch={handleSearch}
                        searchText={searchText}
                        limit={limit}
                        handleIcon={handleIcon}
                        onUnitClick={onUnitClick}
                        totalRowsCount={propertyList?.totalRowsCount}
                        handleOnClickAddPropertyButton={handleOnClickAddPropertyButton}
                        handleChange={handleSearch}
                        t={t}
                        enable_double_click={true}
                        enable_single_click={true}
                        filterData={filterData}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={5} lg={4} sm={12}>
                    {/*details */}
                    {
                      detailLoader ? (
                        <NewLoader minusHeight="100px" />
                      ) : (
                        <Box p={"16px 0px 16px 16px"} className={classes.detailsContainer}>
                          {propertyRow?.length > 0 ? (
                            <DetailsContainer t={t} stats={stats} loading={detailLoader} permission={permission} />
                          ) : (
                            <div className={classes.noFound}>
                              <Typography className={classes.noData}>{t("No Data")}</Typography>
                            </div>
                          )}
                        </Box>)}
                  </Grid>
                </>
                :
                <Grid item xs={12} className={classes.noDataTeams}>
                  <Typography className={classes.text}>
                    {t("No data found")}
                  </Typography>
                </Grid>

              }
            </Grid>
            {drawer && (
              <FilterGenerator
                open={drawer}
                onClose={drawerclose}
                components={[
                  {
                    component: "select",
                    value: filterData?.property_type,
                    options: [],
                    isMulti: true,
                    label: t("Property Type"),
                    state_name: "property_type",
                    placeholder: t("Select Property Type"),
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        GET_PROPERTY_TYPE,
                        "property_group_master"
                      ),
                    debounceTimeout: 800,
                    isPaginate: true,
                  },
                  {
                    component: "select",
                    value: filterData?.property_hierarchy,
                    options: [],
                    isMulti: true,
                    label: t("Property Hierarchy"),
                    state_name: "property_hierarchy",
                    placeholder: t("Select Property Hierarchy"),
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        PROPERTY_HIERARCHY_MASTER_OPTION,
                        "property_hierarchy_master"
                      ),
                    debounceTimeout: 800,
                    isPaginate: true,
                  },
                  {
                    component: "select",
                    value: filterData?.property_purpose,
                    options: enumValue?.purpose_global_type,
                    isMulti: true,
                    state_name: "property_purpose",
                    placeholder: t("Select Property Purpose"),
                    label: t("Property Purpose"),
                  },
                  {
                    component: "toggleButton",
                    value: filterData?.status,
                    options: [
                      { label: t("Active"), value: true },
                      { label: t("Inactive"), value: false },
                    ],
                    state_name: "status",
                    label: t("Status"),
                    // required:true
                  },
                ]}
                onApply={(value) => onApplyFilter(value)}
              />
            )}
          </Box>)}
    </>
  }

  return (
    <div>
      {accessCheckRender(render, permission, "", loading)}
    </div>
  );
};
export default withNamespaces("properties")(Properties)
