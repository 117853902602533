import gql from "graphql-tag";

export const GET_TYPE_MASTER = gql`
query {
  resource_type_master(where: { is_active: true }) {
    value: id
    label: name
    id
    resource_code

  }
}

`

export const GET_TYPE_MASTERS = gql`
query {
  resource_type_master(where: { is_active: true }) {
    value: resource_code
    label: name
    id

    resource_code
  }
}

`
export const GET_MODULE = (offset, limit, searchText) => gql`
query GET_MODULE{
  count: client_roles(
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
    }) {
    count:count_id
  }
  client_roles(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
    }
    ){
      value:id
   module_id:module_id
   label: name
  }
}`;
export const GET_WORKING_HOURES = (offset, limit, searchText, { company_id }) => gql`
query GET_WORKING_HOURES{
  count: working_hours_master(
    where: {
      is_active:{eq:true}
      is_delete:{eq:false}
      company_id:${company_id}
    }) {
    count:count_id
  }
  working_hours_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
    where: {
      is_active:{eq:true}
      is_delete:{eq:false}
      company_id:${company_id}
    }
    ){
    from
    shift_name
    to
    id
  }
}`;
export const GET_USER_PROFILE = gql`
query GET_USER_PROFILE{
  count: user_profiles(
    where: {
      first_name: { iregex: $search }
      is_active:{eq:true}
      client:$client
      contact:{company_id:$company_id}

    }) {
    count:count_id
  }
 list: user_profiles(
    offset: $offset
    limit: $limit
    
    order_by: { created_at: desc }
    where: {
      is_active:{eq:true}
     first_name: { iregex: $search }
      client:$client
      contact:{company_id:$company_id}
    }
    ){
     label:first_name
   value:id
   id
   name:first_name
  email_id
  mobile_no
  mobile_no_country_code
  image_url
  }
}
`;
export const GET_USER_PROFILES_LIST = gql`
query {
  list: user_profiles(
    where: { 
       is_active: true,
       first_name: { iregex: $search },
       client:$client 
      }
    offset: $offset
    limit: $limit
  ) {
    id
label:first_name
   value:id
  email_id
  mobile_no
  mobile_no_country_code
  }
}
`
export const GET_CONTACT_ACCOUNT = gql`
query {
  list: contact_account(
    where: { 
       is_active: true,
       name: { iregex: $search },
       client:$client 
       company_id:$company
      }
    offset: $offset
    limit: $limit
  ) {
    id
   label:name
   name
  value:id
  email_id
  mobile_no
  image_url:logo
  mobile_no_country_code
  }
}
`
export const GET_TOOLS = (offset, limit, searchText, { company_id }) => gql`
query GET_TOOLS{
  count: tools_master(
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
      company_id:${company_id}
    }) {
    count:count_id
  }
  tools_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
    }
    ){
   value:id
   label: name
   rate
   period
  }
}`;
export const GET_VEHICLE = (offset, limit, searchText, { company_id }) => gql`
query GET_TOOLS{
  count: vehicle_master(
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
      company_id:${company_id}
    }) {
    count:count_id
  }
  vehicle_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
         company_id:${company_id}
    }
    ){
   value:id
   label: name
    rate
   period
  }
}`;
export const GET_CONTACT = gql`
query {
  list: contact(
    where: { 
       is_active: true,
       first_name: { iregex: $search },
       client:$client 
       relationship:"Company Employee",
       company_id:$company
      }
    offset: $offset
    limit: $limit
  ) {
    id
    label:first_name
    image_url
   value:id
   name:first_name
  email_id
  mobile_no
  mobile_no_country_code
  image_url
  user_profile {
    id
  }
  }
}
`;
export const GET_DEPORTMENT = (offset, limit, searchText, { company_id }) => gql`
query GET_DEPORTMENT{
  count: department_master(
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
      is_delete:{eq:false}
       company_id:${company_id}
    }) {
    count:count_id
  }
  department_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
      is_delete:{eq:false}
       company_id:${company_id}
    }
    ){
   value:id
   label: name
  }
}`;
export const GET_JOBS = (offset, limit, searchText, { company_id },) => gql`
query GET_JOBS{
  count: job_master(
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
      is_delete:{eq:false}
          company_id:${company_id}

    }) {
    count:count_id
  }
  job_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
      is_delete:{eq:false}
          company_id:${company_id}
    }
    ){
   value:id
   label: name
  }
}`;


export const UPDATE_RESOURCES = gql`
mutation UPDATE_RESOURCES {
    resources(
      where: { id: { eq: $id } }
      update: $updatePayload
    ) {
      id
    }
  }`;



export const GET_LEAVE_MASTER = gql`
query{
  leave_master(where:{
    is_active:true
  }){
    value:id
    label:name
  }
}
`

export const GET_CONTACT_DETAILS = gql`
query {
  list: contact(
    where: { 
       is_active: true,
       first_name: { iregex: $search },
       client:$client 
       relationship:"Company Employee"
       company_id:{eq:$company}
      }
    offset: $offset
    limit: $limit
  ) {
    id
    label:first_name
    image_url
   value:id
   name:first_name
  email_id
  mobile_no
  mobile_no_country_code
  image_url
  user_profile {
    id
  }
  }
}
`;
export let GET_OPTIONS_FOR_UNIT_CATEGORY__UTILITIES = (
  offset,
  limit,
  searchText,
  obj,
) => gql`
        query GET_OPTIONS_FOR_UNIT_CATEGORY {
          count: unit_category_master (
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
            }
            offset:${offset}
            limit:${limit}
          ) {
            count: count_id
          }
          unit_category_master(
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
            }
            offset:${offset}
            limit:${limit}
          ) {
            value: id
            label: name
          }


        }`;
export let GET_OPTIONS_FOR_UNIT_TYPE__UTILITIES = (
  offset,
  limit,
  searchText,
  obj,
  companyID
) => gql`
                query GET_OPTIONS_FOR_UNIT_TYPE {
                  count: unit_type_master (
                    where: {
                      is_active: { eq: true }
                      name: { iregex: "${searchText}" }
                      company_id: { eq: ${companyID} }
                    }
                    offset:${offset}
                    limit:${limit}
                  ) {
                    count: count_id
                  }
                  unit_type_master(
                    where: {
                      is_active: { eq: true }
                      name: { iregex: "${searchText}" }
                      company_id: { eq: ${companyID} }
                    }
                    offset:${offset}
                    limit:${limit}
                  ) {
                    value: id
                    label: name
                  }        
                }`;