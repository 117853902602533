import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Divider, Stack, Typography, MenuItem } from "@mui/material";
import React from "react";
import { PriorityStyles } from "./styles";
const PrioritySelect = ({
  value = "",
  onChange = () => false,
  options = [],
  isRequired = false,
  isLabel = true,
  customHeight = "45px",
  menuPlacement = "bottom",
  marginTop="6px",
  showLabel=true,
  t = () => false
}) => {
  // const [initialvalue, setInitialValue] = useState("low")
  const [open, setOpen] = React.useState(false);
  const handleClick = (event) => {
    setOpen(!open);
  };

  const handleClose = (key, value) => {
    if (key === "save") {
      onChange(value);
    }
  };


  const colorObject = {
    "low": "#06D6A0",
    "medium": "#FFD166",
    "high": "#EFB93C",
    "urgent": "#EF476F",
    "Plan": "#FFA024",
    "Develop": "#5AC782",
    "Release": "#5078E1",
    "Block": "#EF476F",
    "green": "#48B76E",
    "orange": "#F68F4E",
    "yellow": "#ECCB3A",
    "red": "#EC5150"
  }

  const classes = PriorityStyles({ menuPlacement, customHeight ,marginTop});
  return (
    <Box>
      {showLabel&&
        <Typography className={classes.priorityLabel}>
        {isLabel && t("Priority")}
        {isRequired ? (
          <Typography variant="caption" className={classes.required}>
            *
          </Typography>
        ) : (
          <Typography
            variant="caption"
            className={classes.required}
          ></Typography>
        )}
      </Typography>
      }
      <Box position={"relative"} onClick={handleClick}>
        <Box
          className={classes.priorityBox}
          onClick={handleClick}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          style={{
            backgroundColor: isLabel && colorObject[value],
          }}
        >
          <Typography className={classes.initialText} style={{ color: !isLabel && colorObject[value] }}>{value}</Typography>
          <KeyboardArrowDown className={classes.arrowIcon} style={{ color: !isLabel && "#E4E8EE" }} />
        </Box>
        {open &&
          <Box
            className={classes.dropDown}
          >
            {options.map((x, index) => {
              return (
                <MenuItem style={{ padding: "0px" }} onClick={() => handleClose("save", x)}>
                  <Box sx={{ width: "100%" }} >
                    <Stack direction={"row"} spacing={1} p={1}>
                      <Box
                        className={classes.dot}
                        style={{
                          backgroundColor: colorObject[x?.value],
                        }}
                      ></Box>
                      <Typography className={classes.priorityName}>
                        {x.label}
                      </Typography>
                    </Stack>
                    {index !== options.length - 1 && (
                      <Divider className={classes.priorityDivider} />
                    )}
                  </Box>
                </MenuItem>
              );
            })}

          </Box>
        }
      </Box>
      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={classes.dropDown}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{ sx: { width: "460px", backgroundColor: "white", border: "1px solid #E4E8EE", boxShadow: "0px 0px 16px #00000014" } }}
        className={classes.menu}
      >
        {options.map((x, index) => {
          return (
            <MenuItem onClick={() => handleClose("save", x?.value)}>
              <Box sx={{ width: 155 }}>
                <Stack direction={"row"} spacing={1} mb={1}>
                  <Box
                    className={classes.dot}
                    style={{
                      backgroundColor: colorObject[x?.value],
                    }}
                  ></Box>
                  <Typography className={classes.priorityName}>
                    {x.label}
                  </Typography>
                </Stack>
                {index !== options.length - 1 && (
                  <Divider className={classes.priorityDivider} />
                )}
              </Box>
            </MenuItem>
          );
        })}
      </Menu> */}
    </Box>
  );

};
export default PrioritySelect;
