import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { AlertDialog, LoadingSection, SearchFilter, TableWithPagination, UseDebounce } from "../../../components";
import { AlertContext } from "../../../contexts";
import { GET_COUNTRY_MASTER } from "../../../graphql/bussinessMasterQuery";
import { CREATE_TAX_GROUP, TAX_GROUP } from "../../../graphql/clientSettingsQueries";
import { AlertProps, enumSelect, enum_types, LocalStorageKeys } from "../../../utils";
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import { taxCreateState, taxdataType, taxheading, taxpath } from "../../../utils/clientsettings";
import { SettingSave } from "../../companyCreation/components";
import { FormGenerator } from "../components";
export const Tax = ({ selected = {}, t = () => false }) => {


    const [open, setOpen] = React.useState(false);
    const client = useApolloClient();
    const alert = React.useContext(AlertContext);
    const [data, setData] = React.useState({ ...taxCreateState })
    const [loading, setLoading] = React.useState(true)
    const [limits, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = React.useState("");
    //debounce
    const debounce = UseDebounce();
    const [taxList, setTaxList] = React.useState({
        list: [],
        count: 0
    })
    const [enumValue, setEnumValue] = React.useState({
        vat_code_type: [],
    })
    //get taxlist
    const getTaxlist = (limit, offset, search) => {
        setLoading(true)
        client.query({
            query: TAX_GROUP,
            fetchPolicy: 'network-only',
            variables: {
                limit: limit,
                offset: offset,
                searchText: search
            },
        }).then((response) => {
            setTaxList({
                list: response.data.vat_group_master?.map((val) => {
                    return ({ ...val, country: val?.country?.country_name ?? "-" })
                }),
                count: response.data.count[0]?.count
            })
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limits;
        getTaxlist(limits, offset)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getTaxlist(value, 0)
    }
    const onClickRow = (data) => {
        setOpen(true)
    }
    //update data
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    }
    //validate
    const validateForm = () => {
        let isValid = true;
        let error = data.error;

        if (data?.tax_group?.length === 0) {
            isValid = false;
            error.tax_group = t("Tax Group is Required");
        }
        if (data?.country?.length === 0) {
            isValid = false;
            error.country = t("Country is Required");
        }
        if (data?.code?.length === 0) {
            isValid = false;
            error.code = t("Tax Code is Required");
        }

        setData({ ...data, error });
        return isValid;
    }
    //getenum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.vat_code_type])
        setEnumValue({
            vat_code_type: result?.vat_code_type,
        })
    }
    React.useEffect(() => {
        getEnum()
        getTaxlist(limits, 0, "")
        // eslint-disable-next-line
    }, [])
    //on submit form
    const onSubmit = () => {

        if (validateForm()) {
            client.mutate({
                mutation: CREATE_TAX_GROUP,
                variables: {
                    insert: [
                        {
                            group_name: data?.tax_group,
                            country_id: data?.country?.value,
                            vat_group_code: data?.code?.value,
                            is_active: data?.status,
                            client: localStorage.getItem(LocalStorageKeys.clinetID)
                        },
                    ],
                }
            }).then(rs => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Tax Group Successfully Created"),
                });
                getTaxlist(10, 0)
                setOpen(false)
            }).catch(er => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            })

        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please Fill All Mandatory Field"),
            });
        }
    }
    //form data
    const formData = [
        {
            isActive: true,
            component: "text",
            label: t("Tax Group"),
            value: data?.tax_group,
            placeholder: t("Tax Group"),
            onChange: (value) => updateState("tax_group", value?.target.value),
            error: data?.error?.tax_group,
            isRequired: true
        },
        {
            isActive: true,
            component: "select",
            label: t("Country"),
            value: data?.country,
            placeholder: t("Country"),
            onChange: (value) => updateState("country", value),
            error: data?.error?.country,
            isRequired: true,
            isPaginate: true,
            loadOptions: (search, array, handleLoading) => loadOptions(
                search,
                array,
                handleLoading,
                GET_COUNTRY_MASTER,
                'country_master',
                { isActive: true },
                { label: "country_name", value: "id" }
            ),
        },
        {
            isActive: true,
            component: "select",
            label: t("Tax Group Code"),
            value: data?.code,
            placeholder: t("Tax Group Code"),
            onChange: (value) => updateState("code", value),
            error: data?.error?.code,
            isRequired: true,
            isPaginate: true,
            options: enumValue?.vat_code_type
        },
        {
            isActive: true,
            component: "check",
            label: t("Status"),
            value: data?.status,
            onChange: (value) => updateState("status", value.target.checked)

        },
        {
            isActive: true,
            component: "button",
            label: t("Submit"),
            value: data?.status,
            onClick: onSubmit
        },
    ]
    //search in table
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getTaxlist(limits, 0, e)
    }
    return (
        <Box>
            <Box>
                {/*setting save */}
                <SettingSave
                    btn={true}
                    btnName={t("Create Tax Group")}
                    onSave={onClickRow}
                    title={selected?.label} subTitle={selected?.subtitle} t={t} />
                {
                    loading ?
                        <LoadingSection bottom={"45vh"} message="Fetching ..." />
                        :
                        <>
                            <Box padding="8px 12px 0px 12px" width={"330px"}>
                                <SearchFilter value={searchText} placeholder={t("Search Country")} handleChange={(value) => handleSearch(value)} />
                            </Box>
                            <Box padding="0px 12px">
                                {/*table */}
                                <TableWithPagination
                                    heading={taxheading(t)}
                                    rows={taxList?.list}
                                    path={taxpath}
                                    showpagination={true}
                                    dataType={taxdataType}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={taxList?.count}
                                    page={page}
                                    limit={limits}
                                    height={'calc(100vh - 351px)'}
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            </Box>
                        </>
                }
            </Box>
            {/*add dialog */}
            <AlertDialog
                isNormal
                header={t("Tax Group")}
                onClose={() => setOpen(false)}
                open={open}
                component={
                    <Box p={2}>
                        <FormGenerator  t={t} components={formData} />
                    </Box>
                }
            />
        </Box>
    )
}