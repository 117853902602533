import gql from "graphql-tag";
// eslint-disable-next-line

import { LocalStorageKeys } from "../utils";

export const GET_AMENITIES_REFERENCE = (offset, limit, searchText) => gql`
query GET_AMENITIES_CATEGORY_LIST{
    count: amenities_type_master(
      where:{
        amenities_name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
    ) {
      count:count_id
    }
    amenities_type_master(
        where:{
            amenities_name:{iregex:"${searchText}"}
          is_active:{eq:true}
    
           }
        offset:${offset}
        limit:${limit}
      ){
           label:amenities_name
          value:id
          
        }
  }
`

export const GET_AMENITIES_CATEGORY = (offset, limit, searchText) => gql`
query GET_AMENITY_CATEGORY {
    count: amenity_category(
      where: { name: { iregex: "${searchText}" }, is_active: { eq: true }, client: {eq :"${localStorage.getItem(LocalStorageKeys.clinetID)}"} }
    ) {
      count: count_id
    }
    amenity_category(
      where: { name: { iregex: "${searchText}" }, is_active: { eq: true }, client: {eq :"${localStorage.getItem(LocalStorageKeys.clinetID)}"} }
    ) {
      label:name,
      value:id
      id
    }
  }
  
`


export const GET_PROPERTY_AMENITIES = (id) => gql`
query GET_PROPERTY_AMENITIES {
    data:property_facilities(where: { id: { eq: "${id}" } }) {
      id
      property_id
      amenities_type {
        label: amenities_name
        value: id
        is_booking
      }
      is_active
      is_chargeable
      rate
      currency_id
      period_type:period
      total_slots
      slot_partition
      applicable_days
      selected_slots
      amenity_category_id
      is_overbooking
      slots_info
      is_taxable
      amenity_category {
        label:name
        value:id
      }
      vat_group_id
      vat_group_master{
        value:id
        label:group_name
      }
    }
  }
`

export const GET_UNIT_AMENITY = (id) => gql`
query GET_UNIT_AMENITY {
  data:unit_amenities_breakup(
    where: { id: { eq: "${id}" } }
  ) {
    id
    unit_id
    amenities_type{
      label:amenities_name
      value:id
    }
    is_active
    currency_id
    rate
    period_type
    total_slots
    slot_partition
    applicable_days
    selected_slots
    is_chargeable
    amenity_category_id
    amenity_category{
      label:name
      value:id
    }
  }
}

`

export const GET_AMENITY_NAME = (offset, limit, searchText, { categoryID }, companyId) => {
  return gql`

query GET_AMENITY_NAME{
count: amenities_type_master(
  where:{
    amenities_name:{iregex:"${searchText}"}
    amenity_category_id: { eq: "${categoryID}" }
    company_id:{ eq:"${companyId}"}
    is_active: true
    is_delete: false
  }
  offset:${offset}
    limit:${limit}
) {
  count:count_id
}
amenities_type_master(
    where:{
        amenities_name:{iregex:"${searchText}"}
        amenity_category_id: { eq: "${categoryID}" }
        company_id:{ eq:"${companyId}"}
        is_active: true
        is_delete: false
       }
    offset:${offset}
    limit:${limit}
  ){
       label:amenities_name
      value:id
      is_booking
    }
}

`
}