import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
// import MyGoogleMap from '../../components/interactivemap/myGoogleMaps';
import { AlertDialog } from '../../components'
import { contactsViewStyles } from "./contactsViewStyle";
import { stringAvatar } from '../../utils'
import { format } from "date-fns";

export const ContactsView = (props) => {
    const { t } = props;
    const classes = contactsViewStyles()
    const [imageViwer, setImageViwer] = React.useState(false)
    // const { data } = props;
    // //eslint-disable-next-line
    // const address = data ? {
    //     latitude: data?.latitude,
    //     longitude: data?.longitude,
    // } : {};


    // const GoogleMap = React.useCallback(() => {
    //     return (
    //         <MyGoogleMap
    //             lat={address?.latitude}
    //             lng={address?.longitude}
    //             center={{
    //                 lat: address?.latitude,
    //                 lng: address?.longitude
    //             }}
    //             zoom={13} readonly />
    //     )
    //     //eslint-disable-next-line
    // }, [address])
    return (
        <>
            <Box className={classes.boxTab}>
                {/* FIRST HALF */}
                <div className={classes.card}>

                    <Grid container bgcolor="#F5F7FA">
                        <Grid item xs={12} md={12} lg={2} className={classes.imgDiv}>

                            <Avatar src={props?.data?.image_url} className={classes.unitImg}
                                {...stringAvatar(props?.data?.first_name)}
                                onClick={() => setImageViwer(true)}></Avatar>
                        </Grid>
                        <Grid item xs={12} md={12} lg={10} className={classes.imgDiv2}>
                            <Typography className={classes.title} style={{ marginBottom: '10px' }}>{t("Profile Details")}</Typography>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12} md={6} lg={3}>

                                    <Box>
                                        <Typography className={classes.heading}>{t("Company Name")}</Typography>
                                        <Typography className={classes.sub} noWrap>{props?.data?.company_name ?? "-"}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Name")}</Typography>
                                        <Typography className={classes.sub} noWrap>{props?.data?.first_name ?? "-"}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Job Title")}</Typography>
                                        <Typography className={classes.sub} noWrap>{(props?.data?.job_title && props?.data?.job_title !== "") ? props?.data?.job_title : "-"}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Company ID")}</Typography>
                                        <Typography className={classes.sub} noWrap>{(props?.data?.employee_id && props?.data?.employee_id !== "") ? props?.data?.employee_id : '-'}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Gender")}</Typography>
                                        <Typography className={classes.sub} noWrap>{(props?.data?.sex_type && props?.data?.sex_type !== "") ? props?.data?.sex_type : "-"}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Relationship")}</Typography>
                                        <Typography className={classes.sub} noWrap>{(props?.data?.relationship && props?.data?.relationship !== "") ? props?.data?.relationship : "-"}</Typography>

                                    </Box>
                                </Grid>
                                {
                                    props?.data?.vendor_name?.length > 0 &&
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Box>
                                            <Typography className={classes.heading}>{t("Vendor")}</Typography>
                                            <Typography className={classes.sub} noWrap>{(props?.data?.vendor_name && props?.data?.vendor_name !== "") ? props?.data?.vendor_name : "-"}</Typography>

                                        </Box>
                                    </Grid>
                                }
                                {
                                    props?.data?.service_provider?.name?.length > 0 &&
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Box>
                                            <Typography className={classes.heading}>{t("Service Provider")}</Typography>
                                            <Typography className={classes.sub} noWrap>{(props?.data?.national_id && props?.data?.national_id !== "") ? props?.data?.service_provider?.name : "-"}</Typography>
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={12} md={6} lg={3}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Department")}</Typography>
                                        <Typography className={classes.sub} noWrap>{props?.data?.department?.length ? props?.data?.department : "-"}</Typography>
                                    </Box>
                                </Grid>
                                {props?.data?.reporting?.name &&
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Box marginTop="4px">

                                            <Typography className={classes.heading}>{t("Reporting To")}</Typography>
                                            <Typography className={classes.sub} noWrap display={"flex"} alignItems={"center"}>
                                                <Avatar
                                                    {...stringAvatar(props?.data?.first_name)}
                                                    src={props?.data?.reporting?.name} />

                                                &nbsp;&nbsp;{(props?.data?.reporting?.name && props?.data?.reporting?.name !== "") ? props?.data?.reporting?.name : "-"}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                }
                                {
                                    props?.data?.job_name &&
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Box>
                                            <Typography className={classes.heading}>{t("Job Title")}</Typography>
                                            <Typography className={classes.sub} noWrap>{props?.data?.job_title}</Typography>
                                        </Box>
                                    </Grid>
                                }
                                {
                                    props?.data?.job_name &&
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Box>
                                            <Typography className={classes.heading}>{t("Jobs & Role")}</Typography>
                                            <Typography className={classes.sub} noWrap>{props?.data?.job_name}</Typography>
                                        </Box>
                                    </Grid>
                                }

                            </Grid>
                        </Grid >
                        {/* <Grid item md={0} lg={4.5} /> */}
                    </Grid >
                    <br />
                    <Divider />
                    <br />

                    {/* SECOND HALF */}
                    <div>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={4}>
                            <div className={classes.map}>
                                <GoogleMap />
                            </div>
                        </Grid> */}

                            {/* when map component is needed plz use below commented grid item */}
                            {/* <Grid item xs={4}> */}
                            <Grid item xs={12} md={12} lg={6}>
                                <div className={classes.addressRoot}>
                                    <Stack direction="row" >
                                        <img src='/images/loc.svg' alt='' />
                                        <Typography className={classes.title2}>&nbsp;{t("Address")}</Typography>
                                    </Stack>
                                    <Box height="12px" />
                                    <Typography className={classes.sub}>
                                        {props?.data?.door_no ? props?.data?.door_no + ", " : ""}
                                        {props?.data?.street_1 ? props?.data?.street_1 + ", " : ""}
                                        {props?.data?.street_2 ? props?.data?.street_2 + ", " : ""}
                                        {props?.data?.landmark ? props?.data?.landmark + ", " : ""}
                                        {props?.data?.area ? props?.data?.area + ", " : ""}
                                        {props?.data?.city ? props?.data?.city + ", " : ""}
                                        {props?.data?.state ? props?.data?.state + ", " : ""}
                                        {props?.data?.country ? props?.data?.country + ", " : "-"}
                                        {props?.data?.zipcode ?? ""}
                                    </Typography >

                                </div >
                            </Grid >

                            {/* when map component is needed plz use below commented grid item */}
                            {/* <Grid item xs={4}> */}
                            <Grid item md={12} lg={6}>
                                <div className={classes.addressRoot}>
                                    <Stack direction="row" >
                                        <img src='/images/loc.svg' alt='' />
                                        <Typography sx={{ marginInlineStart: "6px" }} className={classes.title}>{t("Contact & Other Information")}</Typography>
                                    </Stack>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} mt={"8px"} >
                                            <Typography className={classes.heading}>{t("Telephone")}:</Typography>
                                            <Typography className={classes.subMob} noWrap>
                                                {(props?.data?.phone && props?.data?.phone !== "") ?
                                                    ((props?.data?.phone_code ?? "") + (props?.data?.phone_code && "-") + props?.data?.phone) : "-"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} mt={"8px"}>
                                            <Typography className={classes.heading}>{t("Mobile Number")} :</Typography>
                                            <Typography className={classes.subMob} noWrap>
                                                {(props?.data?.mobile_no && props?.data?.mobile_no !== "") ?
                                                    ((props?.data?.mobile_no_country_code ?? "") + (props?.data?.mobile_no_country_code && "-") + props?.data?.mobile_no) : "-"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.heading}>{t("Contact")}:</Typography>
                                            <Typography className={classes.sub} noWrap>{props?.data?.preferred_mode_contact ?? '-'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.heading}>{t("Email Address")} :</Typography>
                                            <Typography className={classes.subEmail} noWrap>{props?.data?.email_id ?? '-'}</Typography>
                                        </Grid>
                                        {
                                            props?.data?.login_email &&
                                            <Grid item xs={6}>
                                                <Typography className={classes.heading}>{t("Login Email Address")} :</Typography>
                                                <Typography className={classes.subEmail} noWrap>{props?.data?.login_email ?? '-'}</Typography>
                                            </Grid>
                                        }

                                    </Grid>
                                </div>
                            </Grid>
                        </Grid >
                    </div >
                    {/* <Divider /> */}
                    {/* As per CR commented */}
                    {/* <div style={{ marginTop: '20px' }}>
                        <Stack direction="row" marginBottom={2} >
                            <img src='/images/Group 7015.svg' alt='' />
                            <Typography className={classes.title2}>&nbsp;Emergency Contact Information</Typography>
                        </Stack>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>Preferred Method Of Contact </Typography>
                                <Typography className={classes.sub} noWrap>{(props?.data?.preferred_mode_contact && props?.data?.preferred_mode_contact !== "") ? props?.data?.preferred_mode_contact : "-"}</Typography>
                            </Grid>

                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>Emergency Contact Name </Typography>
                                <Typography className={classes.sub} noWrap>{(props?.data?.emergency_contact_name && props?.data?.emergency_contact_name !== "") ? props?.data?.emergency_contact_name : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>Emergency Contact Relationship </Typography>
                                <Typography className={classes.sub} noWrap>{(props?.data?.emergency_contact_relationship && props?.data?.emergency_contact_relationship !== "") ? props?.data?.emergency_contact_relationship : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>Emergency Contact Number </Typography>

                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.emergency_contact_number && props?.data?.emergency_contact_number !== "") ?
                                        ((props?.data?.emergency_contact_code ?? "") + (props?.data?.emergency_contact_code && "-") + props?.data?.emergency_contact_number) : "-"}</Typography>
                            </Grid>

                        </Grid>
                    </div > */}
                    <AlertDialog isNormal isnotTitle component={
                        <div style={{ display: 'flex' }}>
                            <img src={props?.data?.image_url} alt='' style={{ objectFit: 'cover', width: '444px' }} />
                        </div>
                    } open={imageViwer} onClose={() => setImageViwer(false)} />
                    <Divider />
                    {/* nantional details */}

                    {/* As per CR commented */}
                    {/* <div className={classes.margin}>
                        <Typography className={classes.title} >ID Details</Typography>
                        <Grid container rowSpacing={2} marginTop={1}>
                            <Grid item xs={12} md={6} lg={3}>
                                <Typography className={classes.heading}>Nationality</Typography>

                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.nationality && props?.data?.nationality !== "") ? props?.data?.nationality : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Typography className={classes.heading}>National ID</Typography>
                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.national_id && props?.data?.national_id !== "") ? props?.data?.national_id : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Typography className={classes.heading}>National ID Expiry</Typography>
                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.national_id_expiry && props?.data?.national_id_expiry !== "") ?
                                        convertTimeUtcToZoneCalander(props?.data?.national_id_expiry) : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Typography className={classes.heading}>Passport ID</Typography>
                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.passport_id && props?.data?.passport_id !== "") ? props?.data?.passport_id : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Typography className={classes.heading}>Passport Expiry</Typography>

                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.passport_expiry && props?.data?.passport_expiry !== "") ?
                                        convertTimeUtcToZoneCalander(props?.data?.passport_expiry) : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Typography className={classes.heading}>Resident ID</Typography>
                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.resident_id && props?.data?.resident_id !== "") ? props?.data?.resident_id : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Typography className={classes.heading}>Resident ID Expiry</Typography>
                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.resident_id_expiry && props?.data?.resident_id_expiry !== "") ?
                                        convertTimeUtcToZoneCalander(props?.data?.resident_id_expiry) : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Typography className={classes.heading}>Visa Number</Typography>
                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.visa_no && props?.data?.visa_no !== "") ? props?.data?.visa_no : "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Typography className={classes.heading}>Visa Expiry</Typography>
                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.visa_expiry && props?.data?.visa_expiry !== "") ?
                                        convertTimeUtcToZoneCalander(props?.data?.visa_expiry) : "-"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider /> */}

                    {/* bank details */}
                    {/* As per CR commented */}
                    {/* <div className={classes.margin}>
                        <Typography className={classes.title} >Banking Basic Details</Typography>
                        <Grid container rowSpacing={2} marginTop={1}>
                            <Grid item xs={12} md={6} lg={3}>
                                <Typography className={classes.heading}>Bank Name</Typography>

                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.bank_name && props?.data?.bank_name !== "") ? props?.data?.bank_name : "-"}
                                </Typography>
                            </Grid>
    <Grid item xs={12} md={6} lg={3}>
        <Typography className={classes.heading}>Branch Name</Typography>
            <Typography className={classes.sub} noWrap>
                {(props?.data?.branch_name && props?.data?.branch_name !== "") ? props?.data?.branch_name : "-"}
            </Typography>
        </Grid>
    <Grid item xs={12} md={6} lg={3}>
        <Typography className={classes.heading}>Currency</Typography>
            <Typography className={classes.sub} noWrap>
                {(props?.data?.bank_currency_id && props?.data?.bank_currency_id !== "") ?
                    (props?.data?.bank_currency_symbol + "-" + props?.data?.bank_currency_code + "-" + props?.data?.bank_currency_name) : "-"}
            </Typography>
        </Grid>
    <Grid item xs={12} md={6} lg={3}>
        <Typography className={classes.heading}>Bank Account Type</Typography>
            <Typography className={classes.sub} noWrap>
                {(props?.data?.account_type && props?.data?.account_type !== "") ? props?.data?.account_type : "-"}
            </Typography>
        </Grid>
    <Grid item xs={12} md={6} lg={3}>
        <Typography className={classes.heading}>Account Number</Typography>
            <Typography className={classes.sub} noWrap>
                {(props?.data?.account_no && props?.data?.account_no !== "") ? props?.data?.account_no : "-"}
            </Typography>
        </Grid>
    <Grid item xs={12} md={6} lg={3}>
        <Typography className={classes.heading}>Bank Routing Type</Typography>
            <Typography className={classes.sub} noWrap>
                {(props?.data?.routing_type && props?.data?.routing_type !== "") ? props?.data?.routing_type : "-"}
            </Typography>
        </Grid>
    <Grid item xs={12} md={6} lg={3}>
        <Typography className={classes.heading}>Bank Routing Code</Typography>
            <Typography className={classes.sub} noWrap>
                {(props?.data?.routing_code && props?.data?.routing_code !== "") ? props?.data?.routing_code : "-"}
            </Typography>
        </Grid>
    <Grid item xs={12} md={6} lg={3}>
        <Typography className={classes.heading}>Preferred Cash Collection Office</Typography>
            <Typography className={classes.sub} noWrap>
                {(props?.data?.cash_collection_office && props?.data?.cash_collection_office !== "") ? props?.data?.cash_collection_office : "-"}
            </Typography>
        </Grid>

    </Grid>
                    </div >
    <Divider /> * /}

{/* BANKING Address */ }
                    {/* As per CR commented */}
                    {/* <div className={classes.margin}>
                        <Typography className={classes.title} >Banking Address Details</Typography>
                        <Grid container rowSpacing={2} marginTop={1}>
                            <Grid item xs={12} md={6} lg={3}>
                                <Typography className={classes.heading}>Address Line 1</Typography>
                                <Typography className={classes.sub} noWrap>
                                    {(props?.data?.address_1 && props?.data?.address_1 !== "") ? props?.data?.address_1 : "-"}
                                </Typography>
                            </Grid>
    <Grid item xs={12} md={6} lg={3}>
        <Typography className={classes.heading}>Address Line 2</Typography>
            <Typography className={classes.sub} noWrap>
                {(props?.data?.address_2 && props?.data?.address_2 !== "") ? props?.data?.address_2 : "-"}
            </Typography>
        </Grid>
    <Grid item xs={12} md={6} lg={3}>
        <Typography className={classes.heading}>City</Typography>
            <Typography className={classes.sub} noWrap>
                {(props?.data?.bank_city && props?.data?.bank_city !== "") ? props?.data?.bank_city : "-"}
            </Typography>
        </Grid>
    <Grid item xs={12} md={6} lg={3}>
        <Typography className={classes.heading}>Country</Typography>
            <Typography className={classes.sub} noWrap>
                {(props?.data?.bank_country_name && props?.data?.bank_country_name !== "") ? props?.data?.bank_country_name : "-"}
            </Typography>
        </Grid>
    </Grid>
                    </div > * /}
    < Divider />

    {/* social profile */ }

                    <div className={classes.borderRoot}>
                        <Typography className={classes.title} >{t("Social Profile")}</Typography>
                        <Grid container rowSpacing={1} marginTop={"4px"}>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Whatsapp")} : </Typography>
                                <Typography className={classes.sub} noWrap>{(props?.data?.whatsapp && props?.data?.whatsapp !== "") ? props?.data?.whatsapp : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Facebook")} :</Typography>
                                <Typography className={classes.sub} noWrap>{(props?.data?.facebook && props?.data?.facebook !== "") ? props?.data?.facebook : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Twitter")} :</Typography>
                                <Typography className={classes.sub} noWrap>{(props?.data?.twitter && props?.data?.twitter !== "") ? props?.data?.twitter : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Linkedin")} : </Typography>
                                <Typography className={classes.sub} noWrap>{(props?.data?.linkedin && props?.data?.linkedin !== "") ? props?.data?.linkedin : "-"}</Typography>
                            </Grid>
                        </Grid>
                    </div >


                    <div className={classes.borderRoot}>
                        <Typography className={classes.title} >{t("ID Details")}</Typography>
                        <Grid container rowSpacing={1} marginTop={"4px"}>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("National ID")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.national_id ?? "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("National ID Expiry")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.national_id_expiry ? format(new Date(props?.data?.national_id_expiry), "dd MMM yy") : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Passport ID")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.passport_id ?? "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Passport ID Expiry")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.passport_expiry ? format(new Date(props?.data?.passport_expiry), "dd MMM yy") : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Resident ID")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.resident_id ?? "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Resident ID Expiry")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.resident_id_expiry ? format(new Date(props?.data?.resident_id_expiry), "dd MMM yy") : "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Visa Number")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.visa_no ?? "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Typography className={classes.heading}>{t("Visa Expiry")} </Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.visa_expiry ? format(new Date(props?.data?.visa_expiry), "dd MMM yy") : "-"}</Typography>
                            </Grid>
                        </Grid>
                    </div >
                </div >
            </Box >

        </>
    )
}