import gql from "graphql-tag";

export const Path = ["unit", "name", "gender", "relationship", "proof_type", "proof_id", "lease_period", "is_active"]

export const Heading = (t) => [
    { title: t("Unit Name"), field: "unit" },
    { title: t("Name"), field: "name" },
    { title: t("Gender"), field: "gender" },
    { title: t("Relationship"), field: "relationship" },
    { title: t("Proof Type"), field: "proof_type" },
    { title: t("Proof ID"), field: "proof_id" },
    { title: t("Lease Period"), field: "lease_period" },
    { title: t("Status"), field: "is_active", },
]

export const Type = [
    { type: ["long_text"], name: "unit" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "gender" },
    { type: ["text"], name: "relationship" },
    { type: ["text"], name: "proof_type" },
    { type: ["text"], name: "proof_id" },
    { type: ["text"], name: "lease_period" },
    { type: ["status"], name: "is_active" },
]

export const period_options = (t = () => false) => [
    { label: t("Current"), value: "current" },
    { label: t("Past"), value: "past" }
]

export const GET_UNITS_LIST = gql`
query GET_UNITS(
  $client: String
  $property_ids: [String]
  $offset: Int
  $limit: Int
  $search: String
) {
  count: unit(
    where: {
      client: { eq: $client }
      is_active: { eq: true }
      execution_status: { eq: "Release" }
      property_id: { in: $property_ids }
      or: { name: { iregex: $search }, unit_no: { iregex: $search } }
    }
  ) {
    count: count_id
  }
  unit(
    where: {
      client: { eq: $client }
      is_active: { eq: true }
      execution_status: { eq: "Release" }
      property_id: { in: $property_ids }
      or: { name: { iregex: $search }, unit_no: { iregex: $search } }
    }
    offset: $offset
    limit: $limit
    order_by: { name: asc }
  ) {
    id
    name
    unit_no
  }
}`;