import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UseDebounce } from "../../components/index";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { CREATE_ACCOUNT_USER } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, isEmptyObject, LocalStorageKeys, NetWorkCallMethods, ValidateEmail } from "../../utils";
import { DialogHeader } from "../index";
import { Account, AddContact, Preview } from "./steps";
import QuotationConformComponent from '../../components/quotationConfirm'
import { GET_CONTACT_DETAILS, GET_PROSPECTIVE_DETAILS } from "../../graphql/quotationQueries";


const initialState = {
    profilePic: "",
    name: "",
    email: "",
    mobile: "",
    alternativemobile: "",
    telephone: "",
    fax: "",
    nationality: "",
    gender: "",
    files: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    idType: "",
    idNo: "",
    idExpire: "",
    address: "",
    passportNo: "",
    visaNo: "",
    passportExipre: "",
    contact_id: "",
    proofFile: [],
    error: {
        name: "",
        email: "",
        mobile: "",
        nationality: "",
        gender: "",
        idType: "",
        idNo: "",
        idExpire: "",
        passportNo: "",
        visaNo: "",
        passportExipre: "",
        proofFile: ""
    }
}
export const QuotationContactCreate = ({
    onClose = () => false,
    data = {},
    company_id = "",
    setSelectedAcc = () => false,
    selectedAcc = {},
    total = "",
    reload = () => false,
    quotation_id = "",
    email_id = null,
    is_prospect = false,
    type,
    t = () => false,
    is_quote = false,
    onPrimaryChange = () => false,
    checkPrimary = () => false,
    primary = false,
    setPrimary = () => false,
    is_conform = false,
    details = {},
    is_tenant = false,
    lead_id = null
}) => {
    const client = useApolloClient();
    const debounce = UseDebounce()

    const [step, setStep] = React.useState(is_conform ? 4 : 1);
    const backdrop = React.useContext(BackdropContext)
    const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
    const history = useHistory()
    const [disable, setDisable] = React.useState(null)
    const [contact, setContact] = React.useState({ ...initialState })
    const [contactID, setContactID] = React.useState(!!data?.quott?.contact_id ? data?.quott?.contact_id : "")
    const alert = React.useContext(AlertContext);
    const [disableBTN, setDisableBTN] = React.useState(false)
    const [isNew, setIsNew] = React.useState(null)
    const [conform, setConform] = React.useState(false)
    const openDrawer = () => {
        setConform(!conform)
    }
    const [images, setImages] = React.useState([])

    //check existing contact
    const checkExsits = (type, value, msg) => {
        setDisable(false)
        let datas = {
            tenantId: `${config.tenantId}`,
            [type]: value
        }
        NetworkCall(
            `${config.api_url}/contact/findContactExisting`,
            NetWorkCallMethods.post,
            datas,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.is_Exist && value?.length > 0) {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.info,
                        msg: `${msg} ${t("is already exists")}`,
                    });
                } else {
                    setDisableBTN(false)
                }

            })
            .catch((error) => {
                console.log(error)
            });
    }
    //update state of contact
    const updateState = (key, value) => {
        if (key === 'email') {
            debounce(() => checkExsits("email_id", value, 'Email Id'), 1000);
        }
        if (key === "mobile") {
            debounce(() => checkExsits("mobile_no", value?.mobile, 'Mobile Number'), 1000);
        }
        let error = contact.error;
        error[key] = "";
        setContact({ ...contact, [key]: value, error });
    };
    //validate contact
    const validateContact = () => {
        let isValid = true;
        let error = contact.error;
        //validate name
        if (contact?.name?.length === 0) {
            isValid = false;
            error.name = t("Name is Required");
        }
        //validate email
        if (contact?.email?.length === 0) {
            isValid = false;
            error.email = t("Email ID is Required");
        }
        //validate email
        if (contact?.email?.length > 0 && ValidateEmail(contact?.email) === false) {
            isValid = false;
            error.email = t("Invalide Email ID");
        }
        //validate phone
        if (contact?.mobile?.length === 0) {
            isValid = false;
            error.mobile = t("Phone Number is Required");
        }

        setContact({ ...contact, error });
        return isValid;
    }
    //create contact form
    const createContact = (agreement, reference) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "",
        });
        if (contactID) {
            setStep(4)
        }
        setDisable(true)
        let currentDate = new Date().toISOString();

        let datas = {
            company_id: data?.quott?.company_id ?? null,
            image_url: contact?.profilePic?.src ?? null,
            first_name: contact?.name ?? null,
            street_1: contact?.address?.addressLineOne ?? null,
            street_2: contact?.address?.addressLineTwo ?? null,
            landmark: contact?.address?.landmark ?? null,
            city: contact?.address?.district ?? null,
            state: contact?.address?.state ?? null,
            zipcode: contact?.address?.zipcode ?? null,
            country: contact?.address?.country ?? null,
            latitude: contact?.address?.latitude ? parseFloat(contact?.address?.latitude) : null,
            longitude: contact?.address?.longitude ? parseFloat(contact?.address?.longitude) : null,
            phone_code: contact?.telephone?.mobile_code ?? null,
            phone: contact?.telephone?.mobile ?? null,
            mobile_no_country_code: contact?.mobile?.mobile_code ?? null,
            mobile_no: contact?.mobile?.mobile ?? null,
            email_id: contact?.email ? contact?.email?.trim() : null,
            facebook: contact?.facebook ?? null,
            twitter: contact?.twitter ?? null,
            linkedin: contact?.linkedin ?? null,
            nationality: contact?.nationality ?? null,
            created_by: localStorage.getItem(LocalStorageKeys.profileID),
            lead_id: data?.quott?.lead_id,
            alternative_mobile: contact?.alternativemobile?.mobile,
            alternative_mobile_country_code: contact?.alternativemobile?.mobile_code,
            created_at: currentDate,
            user_profile_id: localStorage.getItem(LocalStorageKeys.profileID) ?? null,
            is_active: true,
            proof: contact?.proofFile?.filter(val => val?.is_active).map(val => {
                return {
                    id: val?.id ?? undefined,
                    proof_id: val?.proof_id ?? val?.type?.value,
                    proof_type: val?.proof_type ?? val?.type?.proof_type,
                    kyc_type: "Both",
                    name: val?.name,
                    number: val?.id_no,
                    valid_from: val?.valid_from,
                    valid_to: val?.valid_to,
                    url: val?.file,
                    is_mandatory: val?.mandatry_verify,
                    country_id: val?.issuing_country?.value,
                    is_active: val?.is_active
                }
            }),
            whatsapp: data?.whatsApp ?? null,
            sex_type: data?.sex?.value ?? null,
            employee_id: data?.employee_id ?? null,
            primary_account: data?.primary_account?.value ?? null
        }
        NetworkCall(
            `${config.api_url}/contact/upsert`,
            NetWorkCallMethods.post,
            datas,
            null,
            true,
            false
        )
            .then((response) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                setContactID(response?.data?.data?.id)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Contact Successfully Created"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                setStep(4)
                setDisableBTN(false)
                if (agreement) {
                    setDisableBTN(true)
                    createAccountUser(reference, response?.data?.data?.id)
                }
            })
            .catch((error) => {
                setDisableBTN(false)
                console.log(error)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            });
    }
    //on create Agreement
    const createAgreemment = (reference, contact_id) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "",
        });
        setDisableBTN(true)
        const data = {
            account_id: selectedAcc?.account_no,
            quotationId: quotation_id,
            contact_id: !!contactID ? contactID : contact_id,
            order_details: {
                order_date: reference?.order_date,
                order_reference: reference?.order_reference,
                order_value: reference?.order_value
            },
            assets: reference?.uploadedAssets,
            lead_id,
            is_tenant,
            is_workspace:moduleId ==="35"?true:false
        }

        NetworkCall(
            `${config.api_url}/agreement/create`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Agreement Successfully Created"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                setDisableBTN(false)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                history.push(Routes.aggreements)
            })
            .catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                setDisableBTN(false)

            });
    }
    const agreementCreation = (reference) => {
        if (!contactID) {
            return alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please Fill Contact Details"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
        if (!selectedAcc?.id) {
            return alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please Fill Account Details"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
        if (!!contactID) {
            setDisableBTN(true)
            createAccountUser(reference)
        }
        else {
            createContact(true, reference)

        }
    }
    //on create account user
    const createAccountUser = (reference, contact_id) => {
        setDisable(true)
        setDisableBTN(true)
        client.mutate({
            mutation: CREATE_ACCOUNT_USER,
            variables: {
                insert: [
                    {
                        contact_account: selectedAcc?.id,
                        contact_id: !!contactID ? contactID : contact_id,
                        client: localStorage.getItem(LocalStorageKeys.clinetID)
                    }
                ]
            }
        }).then(rs => {
            createAgreemment(reference, contact_id)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Account association successful"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
            setDisable(false)
        }).catch(er => {
            setStep(4)
            setDisableBTN(false)
        })
    }


    const upsertContact = () => {
        if (validateContact() && isEmptyObject(contact?.proofFile)) {
            createContact()
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please Fill all Mendatery Field"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
    }
    //on previous
    const previous = (s) => {
        setStep(s)
    }

    const QuoteConform = () => {
        return (
            <QuotationConformComponent conform={conform} openDrawer={openDrawer} data={{
                contact: {
                    img: contact?.profilePic,
                    name: contact?.name,
                    mobile: contact?.mobile,
                    telephone: contact?.telephone,
                    email: contact?.email,
                    id: contact?.id
                },
                account: selectedAcc,
                unit: data?.units?.length,
                quote_details: data?.quott,
            }}
                disable={disable}
                onChangeStep={(v) => setStep(v)}
                agreementCreation={agreementCreation}
                disableBTN={disableBTN}
                contactID={contactID}
                images={images}
                setImages={setImages}
            />
        )
    }
    const getContact = () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "",
        });
        client.query({
            query: GET_CONTACT_DETAILS,
            fetchPolicy: 'network-only',
            variables: {
                id: email_id
            }
        }).then((response) => {
            let res = response.data.contact[0]
            if (res) {
                setDisable(true)
                setContactID(res?.id)
                setContact({
                    ...contact,
                    profilePic: res?.image_url ?? "",
                    name: res?.first_name ?? "",
                    email: res?.email_id ?? "",
                    mobile: {
                        mobile: res?.mobile_no ?? "",
                        mobile_code: res?.mobile_no_country_code ?? ""
                    },
                    alternativemobile: {
                        mobile: res?.alternative_mobile?.length > 0 ? res?.alternative_mobile : '',
                        mobile_code: res?.alternative_mobile_country_code ?? null,
                    },
                    telephone: {
                        mobile: (res?.phone !== "undefined" && res?.phone?.length > 0) ? res?.phone : "",
                        mobile_code: (res?.phone_code !== "undefined" && res?.phone_code !== "null" && res?.phone_code?.length > 0) ? res?.phone_code : null
                    },
                    fax: {
                        mobile: (res?.fax_no !== "undefined" && res?.fax_no?.length > 0) ? res?.fax_no : "",
                        mobile_code: (res?.phone_code !== "undefined" && res?.phone_code?.length > 0) ? res?.phone_code : null
                    },
                    nationality: res?.nationality,
                    facebook: res?.facebook,
                    twitter: res?.twitter,
                    linkedin: res?.linkedin,
                    idType: res?.identification_master,
                    idNo: res?.identification_no,
                    idExpire: res?.identification_expriy,
                    address: res?.address,
                    passportNo: res?.passport_id,
                    visaNo: res?.visa_no,
                    passportExipre: res?.passport_expiry,
                    primary_account_no: res?.primary_account_no,
                    id: res?.id
                })
                if (isNew === null) {
                    setIsNew(false)
                }
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }
            if (!response?.data?.contact?.length > 0 && is_prospect) {
                getprospective()
                if (isNew === null) {
                    setIsNew(true)
                }
            }
        }).catch((err) => {
            console.log(err)
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
        })
    }

    const getprospective = () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "",
        });
        client.query({
            query: GET_PROSPECTIVE_DETAILS,
            fetchPolicy: 'network-only',
            variables: {
                id: email_id
            }
        }).then((response) => {
            let res = response?.data?.prospective[0]
            if (res) {
                setDisable(false)
                // setContactID(res?.id)
                setContact({
                    ...contact,
                    profilePic: res?.image_url ?? "",
                    name: res?.first_name ?? "",
                    email: res?.email_id ?? "",
                    mobile: {
                        mobile: res?.mobile_no ?? "",
                        mobile_code: res?.mobile_no_country_code ?? ""
                    },
                    alternativemobile: {
                        mobile: res?.alternative_mobile?.length > 0 ? res?.alternative_mobile : '',
                        mobile_code: res?.alternative_mobile_country_code ?? null,
                    },
                    telephone: {
                        mobile: (res?.phone !== "undefined" && res?.phone?.length > 0) ? res?.phone : "",
                        mobile_code: (res?.phone_code !== "undefined" && res?.phone_code?.length > 0) ? res?.phone_code : null
                    },
                    fax: {
                        mobile: (res?.fax_no !== "undefined" && res?.fax_no?.length > 0) ? res?.fax_no : "",
                        mobile_code: (res?.phone_code !== "undefined" && res?.phone_code?.length > 0) ? res?.phone_code : null
                    },

                    facebook: res?.facebook,
                    twitter: res?.twitter,
                    linkedin: res?.linkedin,
                    address: {
                        addressLineOne: res?.addressLineOne,
                        addressLineTwo: res?.addressLineTwo,
                        landmark: res?.landmark,
                        country: res?.country,
                        state: res?.state,
                        district: res?.district,
                        city: res?.city
                    }
                })
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }
        }).catch((err) => {
            console.log(err)
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
        })
    }


    useEffect(() => {
        if (email_id) {
            getContact()
        }
        // eslint-disable-next-line
    }, [email_id])
    let components = {
        1: <AddContact t={t} setContactID={setContactID} data={contact} setContact={setContact} contactId={contactID} email_id={email_id} contact={contact} updateState={updateState} disable={disable} setDisable={setDisable} is_prospect={is_prospect} setIsNew={setIsNew} isNew={isNew} previous={previous} upsertContact={upsertContact} details={data} />,
        2: <Account contact={contact} t={t} type={type} company_id={company_id} setSelectedAcc={setSelectedAcc} email_id={email_id} selectedAcc={selectedAcc} contactId={contactID} is_quote={is_quote} checkPrimary={checkPrimary} primaryState={primary} setPrimary={setPrimary} previous={previous} />,
        3: <Preview t={t} total={total} email_id={email_id || contact?.email} contact={contact} disable={disable} data={data} selectedAcc={selectedAcc} isNew={isNew} />,
        4: <QuoteConform />
    }


    return (
        <Box width={"428px"}>
            <DialogHeader p={1.5} isLeft title={t("Convert To Agreement")} onClose={onClose} border="1px solid #E4E8EE" />
            <Box overflow="auto">
                {components[step]}
            </Box>
        </Box>
    )
}