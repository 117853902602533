import gql from "graphql-tag";

// options for pricing select
export let GET_OPTIONS_FOR_PRICING_TABLE_FORM = (
  offset,
  limit,
  searchText,
  obj,
  companyID
) => gql`
  query GET_OPTIONS_FOR_PRICING_TABLE_FORM {
    count: pricing_component (
      where: {
        is_active: { eq: true }
        is_delete: { eq: false }
        name: { iregex: "${searchText}" }
        company_id: { eq: ${companyID} }
        primary: { eq: ${obj?.componentType?.is_primary} }
        is_onetime: { eq: ${obj?.componentType?.is_one_time} }
        refundable: { eq: ${obj?.componentType?.is_refundable} }
        is_quantity: { eq: ${obj?.componentType?.is_item_based_component} }
        is_parking: { eq: ${obj?.componentType?.is_parking_slot} }
        revenue_type: { eq: "${obj?.revenueType}" }
      }
      offset:${offset}
      limit:${limit}
    ) {
      count: count_id
    }
    pricing_component(
      where: {
        is_active: { eq: true }
        is_delete: { eq: false }
        name: { iregex: "${searchText}" }
        company_id: { eq: ${companyID} }
        primary: { eq: ${obj?.componentType?.is_primary} }
        is_onetime: { eq: ${obj?.componentType?.is_one_time} }
        refundable: { eq: ${obj?.componentType?.is_refundable} }
        is_quantity: { eq: ${obj?.componentType?.is_item_based_component} }
        is_parking: { eq: ${obj?.componentType?.is_parking_slot} }
        revenue_type: { eq: "${obj?.revenueType}" }
      }
      offset:${offset}
      limit:${limit}
    ) {
      value: id
      label: name
      unit_count:unit{
            count_id
          }
    }


  }`;

// options for property purpose
export let GET_OPTIONS_FOR_PROPERTY_PURPOSE = (
  offset,
  limit,
  searchText,
  obj,
  companyID
) => gql`
    query GET_OPTIONS_FOR_PROPERTY_PURPOSE {
      count: property_group_master (
        where: {
          is_active: { eq: true }
          group_name: { iregex: "${searchText}" }
          company_id: { eq: ${companyID} }
        }
        offset:${offset}
        limit:${limit}
      ) {
        count: count_id
      }
      property_group_master(
        where: {
          is_active: { eq: true }
          group_name: { iregex: "${searchText}" }
          company_id: { eq: ${companyID} }
        }
        offset:${offset}
        limit:${limit}
      ) {
        value: id
        label: group_name
      }


    }`;

// options for property
export let GET_OPTIONS_FOR_PROPERTY = (
  offset,
  limit,
  searchText,
  obj,
  companyID
) => gql`
        query GET_OPTIONS_FOR_PROPERTY_PURPOSE {
          count: property (
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
              company_id: { eq: ${companyID} }
              property_group_id:{ eq: ${obj?.property_type} }
            }
            offset:${offset}
            limit:${limit}
          ) {
            count: count_id
          }
          property(
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
              company_id: { eq: ${companyID} }
              property_group_id:{ eq: ${obj?.property_type} }
            }
            offset:${offset}
            limit:${limit}
          ) {
            value: id
            label: name
            unit_count:unit{
              count_id
            }
            address {
              city
            }
          }


        }`;

// options for unit type
export let GET_OPTIONS_FOR_UNIT_TYPE = (
  offset,
  limit,
  searchText,
  obj,
  companyID
) => gql`
        query GET_OPTIONS_FOR_UNIT_TYPE {
          count: unit_type_master (
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
              company_id: { eq: ${companyID} }
            }
            offset:${offset}
            limit:${limit}
          ) {
            count: count_id
          }
          unit_type_master(
            where: {
              is_active: { eq: true }
              name: { iregex: "${searchText}" }
              company_id: { eq: ${companyID} }
            }
            offset:${offset}
            limit:${limit}
          ) {
            value: id
            label: name
          }


        }`;
// view Pricing Appraisal
export let VIEW_PRICING_APPRAISAL = (id, property_ids) => gql`
query GET_OPTIONS_FOR_UNIT_TYPE ($propertyID:[String]){
 pricing_appraisals(
                    where: {
                      id: { eq: "${id}" }
                    }
                  ) {
                    id
                    reference_no
                    component_type_applicablity
                    component_revenue_type
                    component_based
                    present_specific_value
                    present_range_value
                    new_appraisal_amount
                    effective_start_date
                    effective_end_date
                    appraisal_type
                    status
                    pricing_component {
                      name
                    }
                    ${property_ids?.length > 0
    ? `
                    property
                    (
                      where: {
                        id: { in: $propertyID }
                      }
                    ){
                      name
                      address{
                        city
                      }
                      no_of_units:unit{
                        count_id
                      }
                    }
                        `
    : ""
  }
                  }


                }`;

export const UPDATE_APRRAISAL = gql`
  mutation UPDATE_APRRAISAL(
    $ID: ID
    $updatePayload: pricing_appraisals
  ) {
    pricing_appraisals(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }
`;