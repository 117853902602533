import React, { useState, useEffect } from "react";
import { SelectBox } from "../../../components";
import { CustomSelect } from "../../../components/filterGenerator/components";
import { Divider, Box, Typography, Grid } from "@mui/material";
import { againstPropertyStyle } from "../style";
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import {
  GET_OPTIONS_FOR_PROPERTY_PURPOSE,
  GET_OPTIONS_FOR_PROPERTY,
  GET_OPTIONS_FOR_UNIT_TYPE,
} from "../../../graphql/pricingAppraisal";
import { enumSelect, enum_types, SemiBold } from "../../../utils";

export const PropertyDetails = (props) => {
  const { updateState, state, t } = props;
  //   classes
  const classes = againstPropertyStyle();
  // state
  const [enumValue, setEnumValue] = useState({
    revenue_type: [],
    value_basis_type: [],
  });
  // get enum
  const getEnum = async () => {
    const result = await enumSelect([
      enum_types?.purpose_global_type,
      enum_types?.unit_category,
    ]);
    setEnumValue({
      purpose_global_type: result?.purpose_global_type,
      unit_category: result?.unit_category,
    });
  };
  // useEffect
  useEffect(() => {
    getEnum();
  }, []);

  return (
    <div>
      {/* Property Purpoe list */}
      <CustomSelect
        color="#98A0AC"
        fontSize="14px"
        marginBottom="4px"
        fontFamily={SemiBold}
        label={t("Select Property Type")}
        placeholder={t("Search Property Type Here")}
        value={state?.type ?? null}
        onChange={(value) => updateState("type", value)}
        error={state?.error?.type?.length > 0 ?? false}
        errorText={state?.error?.type}
        required={true}
        isPaginate
        debounceTimeout={800}
        maxMenuHeight="200px"
        loadOptions={(search, array, handleLoading) =>
          loadOptions(
            search,
            array,
            handleLoading,
            GET_OPTIONS_FOR_PROPERTY_PURPOSE,
            "property_group_master",
            {
              isActive: true,
            },
            { label: "label", value: "value" },
            false,
            false,
            props?.company?.value
          )
        }
      />
      <Box height={"13px"} />
      <CustomSelect
        color="#98A0AC"
        fontSize="14px"
        marginBottom="4px"
        fontFamily={SemiBold}
        label={t("Select Property")}
        placeholder={t("Search Property here")}
        isMulti
        value={state?.property}
        onChange={(value) => updateState("property", value)}
        error={state?.error?.property?.length > 0}
        errorText={state?.error?.property}
        required={true}
        isPaginate={state?.type?.label?.length > 0 ? true : false}
        debounceTimeout={800}
        maxMenuHeight="200px"
        key={JSON.stringify(state?.type)}
        loadOptions={(search, array, handleLoading) =>
          loadOptions(
            search,
            array,
            handleLoading,
            GET_OPTIONS_FOR_PROPERTY,
            "property",
            {
              isActive: true,
              property_type: state?.type?.value ?? "",
            },
            {
              label: t("label"),
              value: "value",
              unit_count: "unit_count",
              address: "address",
            },
            false,
            false,
            props?.company?.value,
            true
          )
        }
      />
      <Box className={classes.divider}>
        <Divider />
      </Box>
      <Typography className={classes.valueTitle}>
        {t("ADDITIONAL PROPERTY DETAILS")}
      </Typography>
      <Grid container spacing={2} mt={0.4}>
        {/* Unit Purpose */}
        <Grid item xs={4}>
          <SelectBox
            color="#ffffff"
            isRequired
            label={t("Unit Purpose")}
            placeholder={t("Select Unit Purpose")}
            value={state?.unitPurpose}
            onChange={(value) => {
              props?.updateState("unitPurpose", value);
            }}
            isError={state?.error?.unitPurpose?.length > 0}
            errorMessage={state?.error?.unitPurpose}
            options={enumValue?.purpose_global_type ?? []}
          />
        </Grid>
        {/* Unit Catergory */}
        <Grid item xs={4}>
          <SelectBox
            color="#ffffff"
            isRequired
            label={t("Unit Catergory")}
            placeholder="Select Unit Catergory"
            value={state?.unitCatergory}
            onChange={(value) => {
              props?.updateState("unitCatergory", value);
            }}
            isError={state?.error?.unitCatergory?.length > 0}
            errorMessage={state?.error?.unitCatergory}
            options={enumValue?.unit_category ?? []}
          />
        </Grid>
        {/* Unit type */}
        <Grid item xs={4}>
          <SelectBox
            color="#ffffff"
            isRequired
            label={t("Unit Type")}
            placeholder={t("Select Unit Type")}
            value={state?.unitType}
            onChange={(value) => {
              props?.updateState("unitType", value);
            }}
            isError={state?.error?.unitType?.length > 0}
            errorMessage={state?.error?.unitType}
            isPaginate={state?.unitPurpose?.label?.length > 0 ? true : false}
            debounceTimeout={800}
            key={JSON.stringify(state?.unitPurpose)}
            loadOptions={(search, array, handleLoading) =>
              loadOptions(
                search,
                array,
                handleLoading,
                GET_OPTIONS_FOR_UNIT_TYPE,
                "unit_type_master",
                {
                  isActive: true,
                  purpose: state?.unitPurpose?.value ?? "",

                },
                { label: "label", value: "value" },
                false,
                false,
                props?.company?.value
              )
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};
