import React from "react"
import { useApolloClient } from "@apollo/client";
import { useStylesManagement } from "./style"
import { Box, Stack, Typography, Avatar } from "@mui/material"
import { GET_MANAGEMENT_HISTORY } from "../../graphql/queries"
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { stringAvatar } from "../../utils";
export const HistoryViewCard = ({ formdata = "", t }) => {
    const [historyData, setHistoryData] = React.useState([])
    const classes = useStylesManagement()
    const client = useApolloClient();
    const [offset, setOffset] = React.useState(0)
    const [limit] = React.useState(10)
    const role = formdata?.role
    React.useEffect(() => {
        getHistoryData()
        // eslint-disable-next-line
    }, [])
    // infinity scroll function for history
    const fetchMoreData = () => {
        setOffset(offset + 10);
        getHistoryData();
    };
    //Get History 
    const getHistoryData = () => {
        client.query({
            query: GET_MANAGEMENT_HISTORY,
            fetchPolicy: 'network-only',
            variables: {
                "offset": offset,
                "limit": limit,
                "commiteeID": formdata?.id

            }
        }).then((res) => {
            setHistoryData(res?.data?.management_commitee_history)
        }).catch((err) => {
            console.log(err)

        })
    }
    return (
        <Box className={classes.historyCard}>
            <Box className={classes.role} style={{ backgroundColor: `${role.bgColor}`, color: `${role.bgColor === "#EBFBFF" ? "#2CA2BF" : role.bgColor === "#EBF0FF" ? "#5071CE" : role.bgColor === "#FFFAEB" ? "#ECC049" : "black"}` }}>{role.label}</Box>
            <InfiniteScroll
                dataLength={historyData?.length ?? 0}
                next={fetchMoreData}
                hasMore={true}
                height={300}
            >
                {historyData?.length > 0 ? historyData?.map((details, i) => {
                    return (
                        <Box>
                            <Stack direction="row" spacing={1}>
                                <Box className={classes.historydot}></Box>
                                <Box display="flex">
                                    <Typography className={classes.historyTitle}>{t("Assigned On")}</Typography>
                                    <Typography className={classes.titlecontent}>{moment(details.created_at).format("DD-MM-YYYY")}</Typography>
                                </Box>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                {historyData.length - 1 !== i ?
                                    <Box className={classes.dashedborder}></Box>
                                    :
                                    <Box className={classes.noborder} />
                                }
                                <Avatar src={details?.user_profileByID?.image_url ?? ""} className={classes.avatarStyle} {...stringAvatar(details?.user_profileByID?.first_name)} />
                                <Box className={classes.detailsBlock}>
                                    <Typography className={classes.memberName}>{`${details?.user_profileByID?.first_name ?? "-"} 
                                    (${details?.management_committee?.unit?.unit_no ?? ""})`}
                                    </Typography>
                                    <Typography className={classes.subdetails}>{details?.user_profileByID?.mobile_no ?? "-"}</Typography>
                                    <Typography className={classes.subdetails}>{details?.user_profileByID?.email_id ?? "-"}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    )
                })
                    :
                    <Typography sx={{ textAlign: "center" }}>{t("No History Found")}</Typography>
                }
            </InfiniteScroll>
        </Box>
    )
}