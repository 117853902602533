import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Box, Typography } from "@mui/material";
import { dashboardStyles } from "./style"

export const PieChartsWithCurve = (props) => {

      // classes
      const classes = dashboardStyles(props);
      return (
            <div className={classes.root}>
                  <Doughnut
                        data={{
                              datasets: [
                                    {
                                          data: props?.data?.map((x) => x?.value),
                                          backgroundColor: props?.data?.map((x) => x?.fill),
                                          borderColor: props?.data?.map((x) => x?.fill),
                                          cutout: "80%",
                                          borderRadius: props?.radius,
                                          borderWidth: props?.borderWidth,
                                          line: { borderWidth: 1 },
                                    },
                              ],
                        }}
                        height={460}
                        width={460}
                        options={{
                              animation: false,
                              plugins: {
                                    responsive: true,
                                    legend: false,
                                    tooltip: {
                                          enabled: false
                                    },
                                    animation: false,
                                    hover: {
                                          animationDuration: false, // duration of animations when hovering an item
                                    },
                              },
                        }}
                  />
                  <Box className={classes.totalBox}>
                        <Box>
                              <Typography className={classes.count}>{props?.total}</Typography>
                        </Box>
                        <Box>
                              <Typography className={classes.detailsCard2}>Total</Typography>
                        </Box>
                  </Box>
            </div>
      );
};
