import gql from "graphql-tag";

export const GET_ALL_VEHICLE = gql` 
query GET_ALL_VEHICLE($offset: Int, $limit: Int, $searchText: String, $companyId: ID, $client: String) {
  count: vehicle_master(
    where: {or: {name: {iregex: $searchText}, vehicle_id: {iregex: $searchText}}, company_id: {eq: $companyId},  client: {eq: $client},
       is_active:{in:$status}
}
  ) {
    count: count_id
  }
  vehicle_master(
    where: {or: {name: {iregex: $searchText}, vehicle_id: {iregex: $searchText}}, company_id: {eq: $companyId},client: {eq: $client},
       is_active:{in:$status}
}
    offset: $offset
    limit: $limit
    order_by: {created_at: desc, name: asc}
  ) {
    id
    description
    vehicle_id
    name
    number
    rate
    period
    company {
      id
      name
      currency{
        id
        symbol
      }
    }
    vehicle_type
    vehicle_assets(where:{is_active:true}) {
      id
      url
      vehicle_id
      is_active
      file_meta
    }
    is_active
  }
}

`;
export const UPDATE_VEHICLE_MASTER = gql`
mutation UPDATE_VEHICLE_MASTER(
    $ID: ID
    $updatePayload: vehicleMasterInput
  ) {
    vehicle_master(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`