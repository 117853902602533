import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
import { DeleteIcon } from "../../assets";
import { DialogBox, LoadingSection, SearchFilter, Subheader, TextBox } from "../../components";
import { DocumentViewer } from "../../components/fileViewer";
import PrioritySelect from "../../components/prioritySelect/prioritySelect";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import ShowMoreText from "react-show-more-text";
import { AlertProps, NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { useStyles } from "./style";

const CheckListItemView = ({ t }) => {
    const colorOptions = [{ label: "green", value: "green" }, { label: "orange", value: "orange" }, { label: "yellow", value: "yellow" }, { label: "red", value: "red" }]
    const classes = useStyles()
    const history = useHistory()
    const size = useWindowDimensions()
    const [searchText, setSearchText] = React.useState("")
    const [optionData, setOptionData] = React.useState([])
    const [data, setData] = React.useState([])
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const alert = React.useContext(AlertContext)
    const [isView, setIsView] = React.useState({ bool: false, data: {} })
    const search = useLocation().search
    const id = new URLSearchParams(search).get("id");
    const groupType = new URLSearchParams(search).get("type");

    React.useEffect(() => {
        getCheckListViewDetails()
        getCheckListItems(0, 10, "")
        //eslint-disable-next-line
    }, [])

    const getCheckListViewDetails = () => {

        const payload = {
            id: id
        }

        NetworkCall(
            `${config.api_url}/check_list/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setData(response?.data)
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    const getCheckListItems = (offset = 0, limit = 10, search = "") => {

        const payload = {
            "check_list_id": id,
            "search": search,
            "start": offset,
            "length": limit
        }

        NetworkCall(
            `${config.api_url}/check_list_condition`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            setOptionData(addId(response?.data?.data))
        }).catch((err) => {
            setLoading(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    const handleSearch = (val) => {
        setSearchText(val)
        getCheckListItems(0, 10, val)
    }
    const validateForm = () => {
        let is_valid = false
        is_valid = optionData?.filter((val) => { return val?.is_active })?.every((x) => {
            return x?.condition_name !== ""
        })
        return is_valid
    }
    const handleSubmit = () => {
        if (validateForm()) {
            setIsDisableBtn(true)
            const payload = {
                "upsert_fields": optionData?.map((x) => {
                    return {
                        check_list_id: id,
                        id: x?.id ?? undefined,
                        color_code: x?.color_code,
                        condition_name: x?.condition_name,
                        is_active: x?.is_active
                    }
                })
            }
            NetworkCall(
                `${config.api_url}/check_list_condition/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((res) => {
                    setIsDisableBtn(false)
                    getCheckListItems(0, 10, "")
                    history.goBack(-1)
                    // history.push(Routes?.checkListGroup)
                    alert.setSnack({
                        ...alert, open: true, msg: t("Item Condition Updated Successfully"),
                        severity: AlertProps.severity.success
                    })
                })
                .catch((error) => {
                    setIsDisableBtn(false)
                    if (error.response) {
                        // Request made and server responded
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.center,
                        });

                    }
                    else {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("Something went wrong please try again"),
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.center,
                        });
                    }

                });
        }
        else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill condition name"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
    }
    const addId = (arr, option = false) => {
        return arr.map(function (obj, index) {
            return Object.assign({}, obj, { auto_inc_id: index });
        });
    };
    const handleOption = () => {
        if (optionData?.length < 5) {
            let option = {
                condition_name: "",
                color_code: "green",
                is_active: true
            }
            setOptionData(addId([...optionData, option]))
        }
        else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Limit exceeded,You can't add more than 5 conditions"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }

    }
    const updateState = (key, value, data) => {
        const result = optionData?.map((x, i) => {
            if (data?.auto_inc_id === x?.auto_inc_id) {
                return {
                    ...x,
                    [key]: value
                }
            }
            else {
                return x
            }
        })
        setOptionData(result)
    }
    const removeOption = (val) => {
        const result = optionData?.map((x, i) => {
            if (val?.auto_inc_id === x?.auto_inc_id) {
                return {
                    ...x,
                    is_active: false
                }
            }
            else {
                return x
            }
        })
        setOptionData(result)
    }
    return (
        <Box>
            <Subheader
                title={data?.name}
                goBack={() => history.goBack(-1)}
            />
            {
                loading ?
                    <LoadingSection top="20vh" message={t("loading")} /> :
                    <Box className={classes.root}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={3} lg={3} className={classes.applyBorder} style={{ height: size?.height - 150 }}>
                                <Box p={1}>
                                    <Typography className={classes.detailhdr}>{t("Details")}</Typography>
                                    <Box height={size?.height - 200} overflow="scroll">
                                        <Box mt={1}>
                                            <Typography className={classes.subhdrs}>{t("Check List Name")}</Typography>
                                            <Typography className={classes.subhdrvalues}>{data?.name ?? "-"}</Typography>
                                        </Box>
                                        {data?.group_name !== null &&
                                            <Box mt={1}>
                                                <Typography className={classes.subhdrs}>{t("Check List Group Name")}</Typography>
                                                <Typography className={classes.subhdrvalues}>{data?.group_name ?? "-"}</Typography>
                                            </Box>
                                        }
                                        {data?.inspection_type !== null &&
                                            <Box mt={1}>
                                                <Typography className={classes.subhdrs}>{t("Item Type")}</Typography>
                                                <Typography className={classes.subhdrvalues}>{data?.inspection_item_type ?? "-"}</Typography>
                                            </Box>
                                        }
                                        {groupType === "General" &&
                                            data?.feed_category_id !== null &&
                                            <Box mt={1}>
                                                <Typography className={classes.subhdrs}>{t("Item Category")}</Typography>
                                                <Typography className={classes.subhdrvalues}>{data?.feed_back_name ?? "-"}</Typography>
                                            </Box>
                                        }
                                        {groupType === "Item Group" && data?.inspection_item !== null &&
                                            <Box mt={1}>
                                                <Typography className={classes.subhdrs}>{t("Item Name")}</Typography>
                                                <Typography className={classes.subhdrvalues}>{data?.item_name ?? "-"}</Typography>
                                            </Box>
                                        }
                                        <Box mt={1}>
                                            <Typography className={classes.subhdrs}>{t("Status")}</Typography>
                                            <Typography className={classes.subhdrvalues} style={{ color: data?.is_active ? "#5AC782" : "#FF4B4B" }}>{data?.is_active ? "Active" : "In-active"}</Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography className={classes.subhdrs}>{t("Url")}</Typography>
                                            <a href={data?.url} target="blank" className={classes.subhdrvalues}><Typography className={classes.subhdrvalues} noWrap>{data?.url}</Typography></a>
                                        </Box>
                                        {data?.instructions !== null &&
                                            <Box mt={1}>
                                                <Typography className={classes.subhdrs}>{t("Instructions")}</Typography>
                                                <ShowMoreText
                                                    lines={5}
                                                    more="Show More"
                                                    less="Show Less"
                                                    className={classes.subhdrvalues}
                                                    anchorClass={classes.seeMoreLessTextStyle}
                                                    expanded={false}
                                                    truncatedEndingComponent={"... "}
                                                >
                                                    <Typography className={classes.subhdrvalues}>{data?.instructions?.split("\n")?.map((item, i) => <div key={i}>{item}</div>)}</Typography>
                                                </ShowMoreText>
                                            </Box>
                                        }
                                        {Array?.isArray(data?.assets) && data?.assets?.length > 0 &&
                                            <>
                                                <Box mb={1.5} mt={1.5}>
                                                    <Divider />
                                                </Box>
                                                <Typography className={classes.detailhdr}>{t("Documents")}</Typography>
                                                <Box mt={2}>
                                                    <Grid container spacing={1}>
                                                        {
                                                            data?.assets?.map((x) => {
                                                                return x?.file_meta?.type === "pdf" ?
                                                                    <Grid item xs={12} sm={3} lg={4} onClick={() => setIsView({ bool: true, data: x })}>
                                                                        <Box className={classes.documentImg}>
                                                                            <DocumentViewer url={x?.url} />
                                                                        </Box>
                                                                    </Grid>
                                                                    :
                                                                    <Grid item xs={12} sm={3} lg={4} onClick={() => setIsView({ bool: true, data: x })}>
                                                                        <img src={x?.url} alt="documents" className={classes.documentImg} />
                                                                    </Grid>
                                                            })
                                                        }
                                                    </Grid>
                                                </Box>
                                            </>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                <Box p={1}>
                                    <Box display="flex" justifyContent={"space-between"} mt={1} alignItems={"center"}>
                                        <Box width="350px">
                                            <SearchFilter value={searchText} placeholder={t("Search")}
                                                handleChange={(value) => handleSearch(value)} />

                                        </Box>
                                        {optionData?.length > 0 &&
                                            <Button variant="contained" className={classes.approvebtn} onClick={() => handleSubmit()} disabled={isDisableBtn}>{t("Save")}</Button>
                                        }
                                    </Box>

                                    <Box mt={3}>
                                        <Grid container spacing={1} className={classes.optionsheader}>
                                            <Grid item xs={6} sm={6} md={5} lg={4}>
                                                <Typography className={classes.detailhdr}>{t("Condition Name")}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                                <Typography className={classes.detailhdr}>{t("Color")}</Typography>
                                            </Grid>
                                        </Grid>
                                        {optionData?.length > 0 &&
                                            <Box mt={2}>
                                                {optionData?.filter((x) => { return x?.is_active })?.map((val, index) => {
                                                    return (
                                                        <Box mb={2}>
                                                            <Grid container alignItems={"center"} spacing={2}>
                                                                <Grid item xs={6} sm={6} md={5} lg={4}>
                                                                    <TextBox
                                                                        label=""
                                                                        placeholder="Enter"
                                                                        onChange={(e) => updateState("condition_name", e.target.value, val)}
                                                                        value={val?.condition_name}
                                                                        height={42}
                                                                        padding={"8px 8px"}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={3} lg={3}>
                                                                    <PrioritySelect
                                                                        options={colorOptions}
                                                                        value={val?.color_code}
                                                                        customHeight={35}
                                                                        showLabel={false}
                                                                        marginTop={0}
                                                                        onChange={(value) => updateState("color_code", value?.value, val)}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={4} lg={4}>
                                                                    <DeleteIcon onClick={() => removeOption(val)} style={{ cursor: "pointer" }} />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        }
                                        <Box>
                                            <Typography className={classes.addnew} onClick={() => handleOption()}>{t("Add New Condition +")}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
            }
            <DialogBox
                maxWidth={"sm"}
                open={isView?.bool}
                onClose={() => setIsView({ ...isView, bool: false })}
                header={t("View")}
                component={
                    <Box p={2}>
                        {isView?.data?.file_meta?.type === "pdf" ?
                            <DocumentViewer url={isView?.data?.url} />
                            :
                            <img
                                src={isView?.data?.url}
                                alt="asset"
                                height="500px"
                                width="100%"
                                style={{
                                    objectFit: "contain",
                                    borderRadius: "8px"
                                }}
                            />
                        }
                    </Box>
                }
            />
        </Box>
    )
}
export default withNamespaces("checkListGroup")(CheckListItemView)