import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import EditIconSvg from "../../../assets/editIcon"
import { TemplateIcon } from "../../../assets/templateIcon"
import { CustomSelectOptions } from "../../../components"
import { GET_MAINTANANCE_REQUEST } from "../../../graphql/inspectionTemplate"
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions"
import { Cards } from "./card"
import { useStyles } from "./style"
export const Preview = ({ t = () => false, selectedUnit = {}, selectedInspectionItem = {}, setStep = () => false, maintenanceRequest = [], updateState = () => false, setData = () => false, data = {} }) => {

    const classes = useStyles()
    React.useEffect(() => {
        setData({ ...data, disable: true })
        //eslint-disable-next-line
    }, [])
    return (
        <Box p={2} height={350}>
            <Typography className={classes.previewHdr}>{t("UNIT DETAILS")}</Typography>
            <Box mt={1}><Cards details={selectedUnit} edit={true} setStep={setStep} unit={true} updateState={updateState} /></Box>
            <Box mt={2}>
                <Typography className={classes.previewHdr}>{t("Inspection Details")}</Typography>
                <Box className={classes.unitblock} style={{ backgroundColor: "#FEEAEA80" }} mt={1}>
                    <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                        <Box className={classes.templateIcon}><TemplateIcon /></Box>
                        <Box >
                            <Typography className={classes.name}>{t("Unit Name")}</Typography>
                            <Typography className={classes.location}>{`${selectedInspectionItem?.length} ${t("Items selected for inspection")}`}</Typography>

                        </Box>
                    </Stack>
                    <Box className={classes.checkIcon} onClick={() => setStep(3)}><EditIconSvg /></Box>
                </Box>
            </Box>
            <Box mt={2}>
                <Typography className={classes.previewHdr}>{t("Maintenance Request")}</Typography>
                <Box mt={2}>
                    <CustomSelectOptions
                        menuPlacement="top"
                        // placeholder={t("search_maintenanceRequest")}
                        padding="12px"
                        loadOptions={(search, array, handleLoading) => loadOptions(search, array, handleLoading, GET_MAINTANANCE_REQUEST, "request", { unit_id: selectedUnit?.id })}
                        value={maintenanceRequest}
                        isPaginate={true}
                        // key={JSON.stringify(assignData)}
                        onChange={(val) => updateState("maintenanceRequest", val)}
                    // options={props?.resourceList}
                    // noBorder={true} 
                    />
                </Box>
            </Box>

            {maintenanceRequest?.label &&
                <Box mt={2}>
                    <Cards details={maintenanceRequest} request={true} />
                </Box>
            }
            <Box height="30px" />
        </Box>
    )
}