import { Box, Button, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { withNamespaces } from "react-i18next"
import { useHistory } from "react-router"
import { Subheader, UseDebounce } from "../../../components"
import { config } from "../../../config"
import { AlertContext, BackdropContext } from "../../../contexts"
import { GET_PROPERTY_BY_USER } from "../../../graphql/queries"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, enumSelect, enum_types } from "../../../utils"
import { PropertySelect } from "../../createAnnounsment/component"
import { useStyles } from "../style"
import { tabTittle } from "../utils"
import Form from "./form"
import { Routes } from "../../../router/routes"

const BasicDetails = ({ state = {}, t = () => false }) => {
    const history = useHistory()
    const classes = useStyles()
    const alert = useContext(AlertContext);
    const backdrop = useContext(BackdropContext);
    //debounce hook
    const debounce = UseDebounce();
    // ------------------------STATE-----------------------------------
    const [listingDetails, setListingDetails] = useState({
        property: [],
        role: [],
        propertyselectAll: false,
        roleselectAll: false,
    });
    const [value, setValue] = useState({
        tabValue: 0,
    });
    const [listingDetails1, setListingDetails1] = useState({
        role: [],
        roleselectAll: false,
    });
    const [searchText, setSearchText] = useState({
        propertySearch: "",
        roleSearch: "",
        offsetProperty: 0,
        offsetRole: 0,
    });
    const [selectedProperty, setSelectedProperty] = React.useState([])
    const [selectedBuilds, setSelectedBuilds] = React.useState([])
    const [questions, setQuestions] = useState([])
    const [data, setData] = useState({
        form_title: "",
        is_mandatory: false,
        form_type: "draft",
        error: {
            form_title: "",
        }
    })
    useEffect(() => {
        if (state?.main?.is_edit) {
            GetFeedBackDetailsById()
        } else {
            getBuilds([],"", 0);
            GetList(false,[],"", 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.main?.is_edit]);
    // get feedBack details by id
    const GetFeedBackDetailsById = () => {
        const payload = {
            id: state?.main?.data?.id,
            company_id: state?.main?.company?.value,
            offset: 0,
            limit: 10
        }
        NetworkCall(
            `${config?.api_url}/survey/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then(async (res) => {
            const enumData = await enumSelect([enum_types.feedback_element_type])
            const result = res?.data?.data
            setData({ ...data, "form_title": result?.survey_details?.title, "id": result?.survey_details?.id })
            setQuestions(result?.survey_details?.survey_element?.map((x) => {
                return {
                    question: x?.title,
                    type: x?.type,
                    form_id: x?.id,
                    name: enumData?.feedback_element_type?.filter((val) => val.value === x.type)?.[0]?.label,
                    is_mandatory: x?.is_mandatory,
                    options: x?.data_type_options,
                    start_range: x.type === "rating" ? x.data_type_options?.[0]?.value : 1,
                    end_range: x.type === "rating" ? x.data_type_options?.[x.data_type_options?.length - 1]?.value : 10,
                }
            }))
            getBuilds(result?.survey_details?.to_builds, "", 0);
            GetList(result?.survey_details?.is_all_property, result?.survey_details?.property_ids, "", 0);
        }).catch((err) => {
            console.log(err)
        })
    }
    const GetList = (is_all_property = false, property_ids = [], searchText, offSet, filter, isSearch) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        let clientID = localStorage.getItem(LocalStorageKeys.clinetID);
        const payload = {
            query: GET_PROPERTY_BY_USER(
                offSet,
                15,
                searchText,
                clientID,
                state?.main?.company?.value
            ),
        };
        NetworkCall(
            `${config.api_url}/graphql`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const listData = res?.data?.data?.property?.map((x) => {
                    return {
                        title: x?.name,
                        subTitle: `${x?.address?.state} , ${x?.address?.country}`,
                        is_active:
                            (is_all_property && state?.main?.is_edit) ||
                                listingDetails?.propertyselectAll
                                ? true
                                : false,
                        logo: x?.logo,
                        id: x?.id,
                    };
                });
                let combined = listData?.map((item) => {
                    return {
                        ...item,
                        is_active: (property_ids?.length > 0 && property_ids?.map((i) => i)?.includes(item?.id ?? "")) ? true : false,
                    };
                });
                if (filter) {
                    setListingDetails({
                        ...listingDetails,
                        property:
                            !is_all_property && state?.main?.is_edit
                                ? listingDetails?.property?.concat(combined) ?? []
                                : listingDetails?.property?.concat(listData) ?? [],
                    });
                } else {
                    let finalArray = []
                    listData?.map((e) => {
                        if (selectedProperty?.includes(e?.id)) {
                            return finalArray.push({
                                ...e,
                                is_active: true
                            })
                        } else {
                            return finalArray.push(e)
                        }
                    })
                    setListingDetails({
                        ...listingDetails,
                        propertyselectAll: state?.main?.is_edit
                            ? is_all_property
                            : listingDetails?.propertyselectAll,
                        property:
                            !is_all_property && state?.main?.is_edit
                                ? combined ?? []
                                : isSearch ? finalArray : listData ?? [],
                    });
                }
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            })
            .catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    };
    // get builds list
    const getBuilds = (to_builds = [], search, offset, filter, isSearch) => {
        const requestPayload = {
            offset: offset ?? 0,
            limit: 10,
            search: search ?? "",
        };
        NetworkCall(
            `${config.api_url}/app_build`,
            NetWorkCallMethods.post,
            requestPayload,
            null,
            true,
            false
        )
            .then((res) => {
                const listData = res?.data?.data?.map((x) => {
                    return {
                        title: x?.build_name,
                        subTitle: x?.build_code,
                        is_active: false,
                    };
                });
                let combined = listData?.map((item) => {
                    return {
                        ...item,
                        is_active: (to_builds?.length > 0 && to_builds?.map((i) => i)?.includes(item?.subTitle ?? "")) ? true : false,
                    };
                });
                if (filter) {
                    setListingDetails1({
                        ...listingDetails1,
                        role: [...listingDetails1?.role, ...combined]
                    });
                } else {
                    let finalArray = []
                    listData?.map((e) => {
                        if (selectedBuilds?.includes(e?.subTitle)) {
                            return finalArray.push({
                                ...e,
                                is_active: true
                            })
                        } else {
                            return finalArray.push(e)
                        }
                    })
                    setListingDetails1({
                        ...listingDetails1,
                        roleselectAll:
                            state?.main?.is_edit &&
                                to_builds?.length === res?.data?.data?.length
                                ? true
                                : false,
                        role: state?.main?.is_edit ? combined ?? [] : isSearch ? finalArray : listData ?? [],
                    });
                }
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong. Please try again"),
                });
            });
    };
    // choose property
    const chooseProperty = (value, index, key) => {
        if (selectedProperty.includes(listingDetails?.property?.[index]?.id)) {
            setSelectedProperty(selectedProperty?.filter((i) => i !== listingDetails?.property?.[index]?.id))
        } else {
            setSelectedProperty([...selectedProperty, listingDetails?.property?.[index]?.id])
        }

        if (value !== undefined) {
            let result = [...listingDetails?.property];
            result[index][key] = value;
            setListingDetails({
                ...listingDetails,
                property: result,
            });
        }
    };

    // choose role
    const chooseRole = (value, index, key) => {
        if (selectedBuilds?.includes(listingDetails1?.role?.[index]?.subTitle)) {
            setSelectedBuilds(selectedBuilds?.filter((i) => i !== listingDetails1?.role?.[index]?.subTitle))
        } else {
            setSelectedBuilds([...selectedBuilds, listingDetails1?.role?.[index]?.subTitle])
        }

        if (value !== undefined) {
            let result = [...listingDetails1?.role];
            result[index][key] = value;
            setListingDetails1({
                ...listingDetails1,
                role: result,
            });
        }
    };

    // select all
    const ChooseAll = (key) => {
        const property = listingDetails?.property?.map((x) => {
            return {
                ...x,
                is_active: !listingDetails?.propertyselectAll,
            };
        });
        const role = listingDetails1?.role?.map((x) => {
            return {
                ...x,
                is_active: !listingDetails1?.roleselectAll,
            };
        });
        if (key === "property") {
            setListingDetails({
                ...listingDetails,
                property: property,
                propertyselectAll: !listingDetails?.propertyselectAll,
            });
        } else {
            setListingDetails1({
                ...listingDetails1,
                role: role,
                roleselectAll: !listingDetails1?.roleselectAll,
            });
        }
    };

    const updateState = (k, v) => {
        setValue({ ...value, [k]: v });
    };
    const next = () => {
        if (value?.tabValue === 0) {
            if (!listingDetails?.property?.some((x) => x?.is_active)) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.info,
                    msg: t("Please Select Property."),
                });
                return true;
            } else if (!listingDetails1?.role?.some((x) => x?.is_active)) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.info,
                    msg: t("Please Select Apps."),
                });
                return true;
            } else {
                updateState("tabValue", value?.tabValue + 1);
            }
        }
    };
    // previous click
    const previous = () => {
        updateState("tabValue", value?.tabValue - 1);
    };
    // search function for role
    const searchFunctionBuild = (e) => {
        debounce(() => getBuilds([],e, 0, false, true), 800);
        setSearchText({
            ...searchText,
            roleSearch: e ?? "",
        });
    };
    // search function for role
    const searchFunctionRole = (e) => {
        debounce(() => GetList(false,[],e, 0, false, true), 800);
        setSearchText({
            ...searchText,
            propertySearch: e ?? "",
        });
    };
    // infinity scroll function for roles
    const fetchMoreDataRole = () => {
        setSearchText({
            ...searchText,
            offsetRole: searchText?.offsetRole + 10,
        });
        getBuilds([],searchText?.roleSearch, searchText?.offsetRole + 10, true);
    };
    // infinity scroll function for property
    const fetchMoreDataProperty = () => {
        setSearchText({
            ...searchText,
            offsetProperty: searchText?.offsetProperty + 15,
        });
        GetList(false,[],searchText?.propertySearch, searchText?.offsetProperty + 15, true);
    };
    const validate = () => {
        let isValid = true;
        let error = data.error;

        if (data?.form_title?.length === 0) {
            isValid = false;
            error.form_title = t("Form Title is required")
        }
        setData({ ...data, error });
        return isValid;
    }
    // announsment create
    const publish = (is_draft) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: `${is_draft ? "Drafting..." : "Publishing..."}`,
        });
        if (validate()) {
            const payload = {
                id: state?.main?.is_edit ? data?.id : undefined,
                company_id: state?.main?.company?.value,
                properties: listingDetails?.propertyselectAll
                    ? []
                    : listingDetails?.property
                        ?.filter((x) => x?.is_active === true)
                        ?.map((x) => x?.id),
                to_builds: listingDetails1?.role
                    ?.filter((x) => x?.is_active === true)
                    ?.map((x) => x?.subTitle),
                title: data?.form_title,
                is_draft: is_draft,
                is_all_property: listingDetails?.propertyselectAll,
                survey_element: questions?.map((val, i) => {
                    return {
                        id: val?.form_id ?? undefined,
                        type: val?.type,
                        order: i + 1,
                        title: val?.question,
                        data_type_options: val?.type !== "text" ? val?.options?.map((x) => { return { label: x.label, value: x.value } }) : undefined,
                        is_mandatory: val?.is_mandatory
                    }
                })
            };
            NetworkCall(
                `${config?.api_url}/survey/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    history.push(Routes?.surveyForm);
                    backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                    });
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: `Survey Form ${state?.main?.is_edit
                            ? "Updated "
                            : is_draft
                                ? "Saved as Draft"
                                : "Published"
                            } Successfully.`,
                    });
                })
                .catch((error) => {
                    backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                    });
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Internal error. Please try again later."),
                    });
                });
        }
    };
    const component = {
        0: (
            <PropertySelect
                t={t}
                chooseProperty={chooseProperty}
                chooseRole={chooseRole}
                listingDetails={listingDetails}
                ChooseAll={ChooseAll}
                searchFunctionBuild={searchFunctionBuild}
                searchFunctionRole={searchFunctionRole}
                searchText={searchText}
                fetchMoreDataRole={fetchMoreDataRole}
                fetchMoreDataProperty={fetchMoreDataProperty}
                listingDetails1={listingDetails1}
            />
        ),
        1: (
            <Form data={data} setData={setData} questions={questions} setQuestions={setQuestions} state={state} />
        ),
    };
    return (
        <Box>
            <Subheader
                title={state?.main?.is_edit ? t("Edit") : state?.main?.is_view ? t("View") : t("Create")}
                goBack={() => {
                    history.goBack(-1);
                }}
            />
            <Box className={classes.tab}>
                {tabTittle(value?.tabValue, classes, state?.main?.is_view)?.filter((x) => { return x?.is_active })?.map((val, index) => {
                    return (
                        <Box className={val?.selectBack}>
                            {val?.imgSelect ?? ""}
                            <Typography className={val?.className}>{t(val?.label)}</Typography>
                            <Box className={classes.borderAbsolute} />
                        </Box>
                    );
                })}
            </Box>
            <div>{component[value?.tabValue]}</div>
            {/* bottom btns */}
            <Box className={classes.bottombtn}>
                <Box>
                    <Button
                        variant="outlined"
                        className={classes.Cancel}
                        onClick={previous}
                        disabled={value?.tabValue > 0 ? false : true}
                    >
                        {t("Back")}
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="outlined"
                        className={classes.Cancel}
                        onClick={() => publish(true)}
                        disabled={
                            value?.tabValue === 1 && data?.form_title?.length > 0
                                ? false
                                : true
                        }
                    >
                        {t("Save As Draft")}
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.next}
                        onClick={value?.tabValue === 1 ? () => publish(false) : next}
                        disabled={
                            data?.form_title?.length > 0 || value?.tabValue === 0
                                ? false
                                : true
                        }
                    >
                        {value?.tabValue === 1 ? t("Publish") : t("Next")}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default withNamespaces("formBuilder")(BasicDetails);