import { Box, Grid } from "@mui/material";
import React from "react";
import { TimeLineList } from "../../../components";
import { AggrementTabStyles } from "./styles";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { config } from "../../../config";
export const TimeLine = ({ details = {}, reload = () => false, agreement_id = "", parentAgreement = [], terminatedAgreement = [] }) => {
    const classes = AggrementTabStyles();
    const [data, setData] = React.useState([])
    const [load, setLoad] = React.useState(false)
    const [kycRequest, setKycRequest] = React.useState([])

    //get time line list
    const getTimeline = () => {


        const payload={
            agreement_id: details?.id,
          }
      
          NetworkCall(
            `${config.api_url}/agreement_tracker/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setData(response?.data?.agreement_trackers)
            setKycRequest(response?.data?.kyc_approval_requests)
            // reload()
            setLoad(true)
        }).catch((err) => {
            console.log(err)
        })
    }
    //initial load
    React.useEffect(() => {
        getTimeline()
        // eslint-disable-next-line
    }, [])
    return (
        <Box className={classes.timelineRoot}>
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <Box>
                        <TimeLineList reload={getTimeline} list={data} details={details} agreement_id={agreement_id} load={load} setLoad={setLoad} kycRequest={kycRequest} agreementReload={reload} parentAgreement={parentAgreement} terminatedAgreement={terminatedAgreement} />
                    </Box >
                </Grid >
            </Grid >

        </Box >
    )
}