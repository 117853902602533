import { useApolloClient } from '@apollo/client'
import { Box, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { LoadingSection } from '../../components'
import { AlertContext } from '../../contexts'
import { AlertProps, convertTimeUtcToZoneCalander } from '../../utils'
import { DeclineQRcode } from './components'
import { ProfileCard } from './components/profileCard'
import { DeclineQRcodeStyle } from './components/style'
import { SLOT_DETAILS } from './utils'

export const DeclinePopup = ({ data = {}, t,selectedCompany={} }) => {
    const classes = DeclineQRcodeStyle()
    const client = useApolloClient()
    const [popUpData, setPopUpData] = React.useState([])
    const [loading, setLoading] = React.useState(null)
    const alert = React.useContext(AlertContext)

    const getPassDetails = (id) => {
        setLoading(true)
        client.query({
            query: SLOT_DETAILS,
            fetchPolicy: 'network-only',
            variables: {
                securityRequestId: id,
            }
        })
            .then((response) => {
                setPopUpData(response?.data?.security_request?.[0]);
                setLoading(false)

            })
            .catch((err) => {
                setLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    };
    React.useEffect(() => {
        if (data?.security_request_id?.length > 0) {
            getPassDetails(data?.security_request_id);
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <Box padding={"18px"}>
            {loading ?
                <LoadingSection /> :
                <Grid container>
                    <Grid item xs={5.8} className={classes.box}>
                        <Box p={2}>
                            <ProfileCard data={popUpData?.user_profiles} />
                            <Divider style={{ margin: "20px 0px" }} />
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography className={classes.label}>{t("PARKING SLOT")}</Typography>
                                    <Typography className={classes.subLabel}>{popUpData?.parking_slot?.parking_id ?? "-"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.label}>{t("Reference Id")}</Typography>
                                    <Typography className={classes.subLabel}>{popUpData?.parking_slot?.reference_id ?? "-"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <Typography className={classes.label}>{t("UNIT")}</Typography> */}
                                    <Typography className={classes.label}>{t("VEHICLE TYPE")}</Typography>

                                    <Typography className={classes.subLabel}>{popUpData?.vechicle_type ?? "-"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.label}>{t("VEHICLE NUMBER")}</Typography>
                                    <Typography className={classes.subLabel}>{popUpData?.vechicle_number ?? "-"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.label}>{t("GATE ENTRY")}</Typography>
                                    <Typography className={classes.subLabel}>{popUpData?.approved_access_gate?.name ?? "-"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.label}>{t("PARKING START & END DATE")}</Typography>
                                    <Typography className={classes.subLabel}> {popUpData?.request_from ? convertTimeUtcToZoneCalander(popUpData?.request_from) : ""}
                                        &nbsp;-&nbsp; {popUpData?.request_to ? convertTimeUtcToZoneCalander(popUpData?.request_to) : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.subLabel}> <span className={classes.qr}>{t("Requested on")}</span> {
                                        popUpData?.created_at && convertTimeUtcToZoneCalander(popUpData?.created_at)},  <span className={classes.qr}>{t("Approved on")}</span> {
                                            popUpData?.updated_at && convertTimeUtcToZoneCalander(popUpData?.updated_at)
                                        }</Typography>
                                </Grid>
                                <Grid item xs={12} textAlign={"center"}>
                                    <span className={classes.status}>{popUpData?.status}</span>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={0.4} />
                    <Grid item xs={5.8} className={classes.box}>
                        <DeclineQRcode data={popUpData} selectedCompany={selectedCompany}/>
                    </Grid>
                </Grid>
            }

        </Box>
    )
}