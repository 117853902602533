import gql from "graphql-tag";

export const GET_BOOKED_RESOURCES =({type , job})=> gql`
query {
  count:booked_resources( where: {
    is_active: true
    resources: { contact: { first_name: { iregex: $search } } }
    ${job?.length > 0
      ? `resources: { jobByID: { name: { in: [${job}] } } }`
      : ""
    }
    ${type?.length > 0
      ? `inspection_resourceByID: { agreement_inspectionByID: { requestByID: { request_type:{in: ["${type}"] }} } }`
      : ""
    }
  })
{
  count: count_id
}
    booked_resources(
      where: {
        is_active: true
        resources: { contact: { first_name: { iregex: $search } } }
      }
      offset: $offset
      limit: $limit
    ) {
      start_date_time
      end_date_time
      eta_minutes
      inspection_resourceByID{
        agreement_inspectionByID{
          requestByID{
            request_type
  }
  }
      }
      resources {
        reference_id
        contact {
          first_name
        }
        jobByID {
          name
        }
      }
    }
  }
  
`

export let GET_JOB_MASTER = (offset, limit, searchText, obj) => gql`
query GET_JOB_MASTER{
  count: job_master(
    where:{
      company_id:{eq:"${obj?.companyId}"}
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      client:{eq:"${obj?.client}"}
    }
  ) {
    count:count_id
  }
  job_master(
      where:{
        company_id:{eq:"${obj?.companyId}"}
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
        client:{eq:"${obj?.client}"}

      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`
  ;
