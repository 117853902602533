import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  NetWorkCallMethods,
  accessCheckRender,
  getRoutePermissionNew,
  pageName,
} from "../../utils";
import GraphComponent from "../propertymanagerdashboard/components/graphcomponent";
import { useStyles } from "./style";
import { DialogDrawer } from "../../components";
import { BackendRoutes } from "../../router/routes";
import { constructResponseGetStatData } from "./utils/helperFunctions";
import { CardDetails as NewCardDetails } from "../propertymanagerdashboard/components";

export const DashBoard = ({
  loading = true,
  handleLoading = () => false,
  t = () => false,
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const alert = useContext(AlertContext);

  const [permission_state, set_permission_state] = useState("");
  const [stat_data, set_stat_data] = useState("");
  const [is_zoom, set_is_zoom] = useState(false);
  const [zoom_graph_title, set_zoom_graph_title] = useState("");

  useEffect(() => {
    const permission = getRoutePermissionNew(auth);
    if (permission) {
      set_permission_state(permission);
      if (permission?.read) {
        getInitialData();
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const getInitialData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        await Promise.all([getStatData()]);
        handleLoading(false);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getStatData = () => {
    NetworkCall(
      `${config?.api_url}${BackendRoutes.client_manager}`,
      NetWorkCallMethods?.post,
      {},
      null,
      true,
      false
    )
      .then((res) => {
        const temp_stat_data = constructResponseGetStatData({
          data: res?.data?.data,
          t,
        });
        set_stat_data(temp_stat_data);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps?.severity?.error,
        });
      });
  };

  const handleOnZoomClick = (e) => {
    set_zoom_graph_title(e);
    set_is_zoom(true);
  };

  const handleCloseModule = () => {
    set_is_zoom(false);
  };

  const render = () => {
    return (
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
            <Grid container spacing={2}>
              {stat_data?.count_1?.map((_) => {
                return (
                  <Grid item xs={12} sm={4}>
                    <NewCardDetails data={_} padding={16} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.summaryCard}>
              <Typography className={classes.widget_header} mb={1}>
                {t("Subscription Summary")}
              </Typography>
              <Grid container spacing={2}>
                {stat_data?.subscription_summary?.map((_) => {
                  return (
                    <Grid item xs={6}>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        className={classes.subscribeCard}
                      >
                        <Box>{_?.icon}</Box>
                        <Box>
                          <Typography className={classes.title}>
                            &#x202a;{_?.date}&#x202c;
                          </Typography>
                          <Typography className={classes.subTitle}>
                            {_?.description}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack className={classes?.widget_section} direction={"column"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography className={classes.widget_header} mb={1}>
                  {`${t("Property Units")} - ${stat_data?.property_units}`}
                </Typography>
                <Typography className={classes.widget_header} mb={1}>
                  {`${t("Seats")} - ${stat_data?.seats}`}
                </Typography>
              </Stack>
              <Grid container spacing={2}>
                {stat_data?.count_2?.map((val) => {
                  return (
                    <Grid item xs={12} sm={6} md={3}>
                      <Box
                        className={classes.widget_internal_card_1}
                        sx={{ height: "100%" }}
                      >
                        <Stack
                          direction={"column"}
                          justifyContent={"space-between"}
                          textAlign={"center"}
                          sx={{ height: "100%" }}
                        >
                          <Typography
                            className={classes.widget_internal_card_1_text_2}
                          >
                            {val?.label}
                          </Typography>
                          <Typography
                            className={classes.widget_internal_card_1_text_1}
                          >
                            {val?.count}
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex"}}>
            <Box className={classes.summaryCard} sx={{ width: "100%" }}>
              <Box mt={1}>
                <Box mt={1} >
                  <GraphComponent
                    is_gauge_bar
                    graphTitle={"Storage Utilization"}
                    header={t("Storage Utilization")}
                    height={"100px"}
                    padding={"0px"}
                    gaugeData={stat_data?.storage_utilization}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack className={classes?.widget_section} direction={"column"}>
                  <Typography className={classes.widget_header} mb={1}>
                    {`${t("Listing Units")} - ${stat_data?.listing_units}`}
                  </Typography>
                  <Grid container spacing={2}>
                    {stat_data?.count_3?.map((val) => {
                      return (
                        <Grid item xs={12} sm={6} md={3}>
                          <Box
                            className={classes.widget_internal_card_1}
                            sx={{ height: "100%" }}
                          >
                            <Stack
                              direction={"column"}
                              justifyContent={"space-between"}
                              textAlign={"center"}
                              sx={{ height: "100%" }}
                            >
                              <Typography
                                className={
                                  classes.widget_internal_card_1_text_2
                                }
                              >
                                {val?.label}
                              </Typography>
                              <Typography
                                className={
                                  classes.widget_internal_card_1_text_1
                                }
                              >
                                {val?.count}
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack className={classes?.widget_section} direction={"column"}>
                  <Typography className={classes.widget_header} mb={1}>
                    {`${t("Items & Assets")} - ${stat_data?.items_assets}`}
                  </Typography>
                  <Grid container spacing={2}>
                    {stat_data?.count_4?.map((_, i) => {
                      return (
                        <Grid item xs={12} sm={i === 2 ? 12 : 6} md={4}>
                          <Box
                            className={classes.widget_internal_card_1}
                            sx={{ height: "100%" }}
                          >
                            <Stack
                              direction={"column"}
                              spacing={{ xs: 0, sm: "10px", md: 0 }}
                              justifyContent={"space-between"}
                              textAlign={"center"}
                              sx={{ height: "100%" }}
                            >
                              <Typography
                                className={
                                  classes.widget_internal_card_1_text_2
                                }
                              >
                                {_?.label}
                              </Typography>
                              <Typography
                                className={
                                  classes.widget_internal_card_1_text_1
                                }
                              >
                                {_?.count}
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
            <GraphComponent
              graphTitle={t("Resources")}
              chartHeader={t("Resources")}
              height={"290px"}
              graphData={stat_data?.resource_chart}
              is_variable_pie_new
              minWidth={true}
              padding={"0px 16px 16px"}
              isShowLegend={false}
              textMargin={"0px 0px 16px"}
              divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              relativeGrid={{ sm: "inherit" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack className={classes?.widget_section} direction={"column"}>
                  <Typography className={classes.widget_header} mb={1}>
                    {`${t("Resources")} - ${stat_data?.resources}`}
                  </Typography>
                  <Grid container spacing={2}>
                    {stat_data?.count_5?.map((val) => {
                      return (
                        <Grid item xs={12} sm={6} md={3}>
                          <Box
                            className={classes.widget_internal_card_1}
                            sx={{ height: "100%" }}
                          >
                            <Stack
                              direction={"column"}
                              justifyContent={"space-between"}
                              textAlign={"center"}
                              sx={{ height: "100%" }}
                            >
                              <Typography
                                className={
                                  classes.widget_internal_card_1_text_2
                                }
                              >
                                {val?.label}
                              </Typography>
                              <Typography
                                className={
                                  classes.widget_internal_card_1_text_1
                                }
                              >
                                {val?.count}
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack className={classes?.widget_section}>
                  <Grid container spacing={2}>
                    {stat_data?.count_6?.map((val) => {
                      return (
                        <Grid item xs={12} sm={6} md={3}>
                          <Box
                            className={classes.widget_internal_card_1}
                            sx={{ height: "100%" }}
                          >
                            <Stack
                              direction={"column"}
                              spacing={"32px"}
                              justifyContent={"space-between"}
                              textAlign={"center"}
                              sx={{ height: "100%" }}
                            >
                              <Typography
                                className={
                                  classes.widget_internal_card_1_text_2
                                }
                              >
                                {val?.label}
                              </Typography>
                              <Typography
                                className={
                                  classes.widget_internal_card_1_text_1
                                }
                              >
                                {val?.count}
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
            <GraphComponent
              graphTitle={t("Roles")}
              chartHeader={t("Roles")}
              height={"290px"}
              graphData={stat_data?.roles_chart}
              isTotal={true}
              is_pie_new
               minWidth={true}
              padding={"15px 16px 16px"}
              isShowLegend={false}
              textMargin={"0px 0px 16px"}
              divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              relativeGrid={{ sm: "inherit" }}
            />
          </Grid>
        </Grid>
        <DialogDrawer
          header={t(zoom_graph_title)}
          maxWidth={"md"}
          handleClose={handleCloseModule}
          open={is_zoom}
          height={"auto"}
          borderRadius={"12px"}
          padding={"0px"}
          onClose={handleCloseModule}
        />
      </Box>
    );
  };

  return (
    <div>
      {accessCheckRender(render, permission_state, pageName.dashboard, loading)}
    </div>
  );
};
