import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
    root: {
        margin: "12px",
        backgroundColor: "white",
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: theme.palette.borderRadius
    },
    tabRoot: {
        height: `calc(100vh - 269px)`,
        overflow: "auto",
        padding: "20px",
        position: "relative"
    },
    speedDial: {
        padding: "12px",
        backgroundColor: "#5078E1",
        color: "white",
        position: "absolute",
        bottom: 50,
        right: 10,
        "&:hover": {
            backgroundColor: "#5078E1",
        },
    },
    speedDialArabic: {
        padding: "12px",
        backgroundColor: "#5078E1",
        color: "white",
        position: "absolute",
        bottom: 50,
        left: 10,
        "&:hover": {
            backgroundColor: "#5078E1",
        },
    },
    btnRoot: {
        padding: "8px 20px",
        backgroundColor: "white"
    },
    iconBtn: {
        padding: "8px 24px",
        border: "1px solid #E4E8EE",
        boxShadow: "none",
        backgroundColor: "white",
        color: "#091B29",
        "&:hover": {
            backgroundColor: "white",
        },
    },
    closeBtn: {
        padding: "8px 24px",
        boxShadow: "none",
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "white",
        },
    },
    requsetBtn: {
        padding: "8px 24px",
        boxShadow: "none",
    }
}));