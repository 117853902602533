/* eslint-disable no-unused-vars */
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  DrawerComponent,
  FilterComponent,
  SearchFilter, Subheader, TableWithPagination, FilterGenerator
} from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { accessCheckRender, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods, AlertProps, enumSelect, enum_types, wrapLabelsInT } from "../../utils";
import { Leadheading, Leadpath } from "../../utils/tableData";
import { CreateLeadContext } from "./leadcontext";
import { useStyles } from "./styles";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { LIST_COMPANY_CONTACT, GET_LEAD_STATUS_MASTER, LIST_COMPANY_OWNER } from '../../graphql/queries'
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import { CreateLead } from "../../components/createLead"
import { DownloadImage } from "../../assets";

export const Leads = (props) => {
  const {
    leadList,
    getListData,
    openFilter,
    OpenDrawer,
    searchdata,
    total,
    clearAll,
    companySelectFunction,
    selectedCompany,
    setSelectedCompany,
    t = () => false
  } = React.useContext(CreateLeadContext);

  const auth = React.useContext(AuthContext);
  const history = useHistory();
  const [listTab, setTab] = React.useState("");
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const { state } = useLocation();
  const [companyList, setCompanyList] = React.useState([]);
  const backdrop = React.useContext(BackdropContext)
  const alert = React.useContext(AlertContext)
  const [permission, setPermission] = React.useState({})
  const [selectedTeams, setSelectedTeams] = React.useState('')
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
  const clientId = localStorage.getItem(LocalStorageKeys.clinetID);
  const [create, setCreate] = React.useState({
    open: false,
    lead_id: null
  })
const [state1,setState1]=React.useState(true)
  const [status, setStatus] = React.useState({
    value: [],
    enum: []
  })
  const [filterData, setFilterData] = React.useState({
    periorty: "",
    status: [2],
    name: "",
    lead: ""
  })
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        if (localStorage.getItem("authToken")) {
          let company = getCompanyOption(backdrop, auth, alert)
          if (company) {
            setCompanyList(company?.list)
            getStatusMaster()
            if (state?.main?.leadNew) {
              setSelectedCompany(state?.main?.selectedCompany ?? company?.selected)
            }
            else {
              setSelectedCompany(company?.selected)
            }
          }
        } else {
          history.push(Routes.login);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);


  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getListData(offset, limit, listTab, "key", selectedCompany, selectedTeams ,filterData);
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getListData(0, value, listTab, "key", selectedCompany, selectedTeams , filterData);
  };

  const handleIcon = (type, data) => {
    if (type === "edit") {


      history.push({
        pathname: Routes.createOpportunity,
        state: {
          id: data?.id,
          company: {
            value: data?.company_id,
            label: data?.company_name
          }
        }
      });

      setCreate({
        open: true,
        lead_id: data?.id
      })
    } else if (type === "double_click") {
      history.push({
        pathname: Routes.leadsDetails,
        state: {
          company: selectedCompany,
          id: data?.id
        }
      });
    }
  };

  const onNext = (val) => {
    history.push({
      pathname: Routes.createOpportunity,
      state: { opportunity: val, company: selectedCompany, is_opportunity: true , primary:state1  }
    })
  }

  const goCreateOppertunity = () => {
    handleCreate("open", true)
  }
  // Function to change property
  const handleTeamsChange = (team) => {
    setSelectedTeams(team)
    getListData(0, 10, listTab, "key", selectedCompany, team , filterData);

  }

  const handleDownload = () => {
    getListData(0, 10, listTab, "key", selectedCompany, selectedTeams, filterData, true);
  }

  // get status master
  const getStatusMaster = () => {
    const payload = {
      query: GET_LEAD_STATUS_MASTER,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        getEnum(response?.data?.data?.lead_status_master)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  }

  const getEnum = async (value) => {
    const result = await enumSelect([enum_types.urgent_type])
    setStatus({
      value: result?.urgent_type ?? [],
      enum: value ?? []
    })
  }

  const onApplyFilter = (value) => {
    setFilterData(value)
    getListData(0, 10, listTab, "filter", selectedCompany, selectedTeams, value)
  }

  const handleCreate = (key, value) => {
    setCreate({
      ...create,
      [key]: value,
      lead_id: null
    })
  }

  const render = () => {
    return <>
      <Subheader hideBackButton={true} title={`${t("Opportunity")}(${total ?? 0
        })`} select options={companyList} value={selectedCompany}
        onchange={(e) => {
          companySelectFunction(e)
        }

        }
        selectTeamsOptions
        handleTeamsChange={handleTeamsChange}
        companyId={selectedCompany?.value}
        moduleId={moduleId}
        selectedTeams={selectedTeams} />
      <div className={classes.root}>
        {
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="primary" />
          </Backdrop>
        }
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={4}>
            <SearchFilter
              value={searchdata}
              handleChange={(value) => getListData(0, 10, value, "key", selectedCompany, selectedTeams, filterData)}
              placeholder={t("Search by Opportunity ID")}
              customfieldSx={{ height: '40px' }}
            />
          </Grid>

          <Grid item xs={8} textAlign={"right"} display={'flex'} justifyContent={'end'}>
            <Box display={"flex"} sx={{ float: "right" }}>
              <Box>
                <IconButton
                  size="small"
                  className={classes.img}
                  onClick={handleDownload}
                  // disabled={list?.data === 0}
                >
                  <DownloadImage />
                </IconButton>
              </Box>
              <Divider orientation="vertical" p={1} height={2} sx={{ marginInline: "16px" }} />
              <Box>
                <IconButton
                  size="small"
                  className={classes.img}
                  onClick={OpenDrawer}
                >
                  <img src="/images/filter.svg" alt="filter" />
                </IconButton>
              </Box>
              <Divider orientation="vertical" p={1} height={2} sx={{ marginInline: "16px" }} />
              {/* &nbsp; &nbsp; &nbsp; */}
              {permission?.create && <Box>
                <Button
                  variant="contained"
                  onClick={goCreateOppertunity}
                  sx={{ height: "40px" }}
                >
                  {t("Add opportunity")}
                </Button>
              </Box>}
            </Box>
          </Grid>
          {/* Main tableData */}
          <Grid item xs={12} className={classes.tableMain}>

            <TableWithPagination
              heading={Leadheading(t)}
              rows={leadList}
              path={Leadpath}
              showpagination={true}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handleIcon={handleIcon}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={total}
              page={page}
              limit={limit}
              tableType="no-side"
              dataType={[
                { type: ["more_5"], icon: "icon" },
                { type: ["text"], name: "leadNo" },
                { type: ["date"], name: "date" },
                { type: ["long_text"], name: "description" },
                { type: ["text"], name: "purpose" },
                { type: ["text"], name: "opportunity_type" },
                { type: ["text"], name: "activeQuote" },
                { type: ["text"], name: "value" },
                { type: ["text"], name: "owner" },
                { type: ["text"], name: "contact_name" },
                { type: ["text"], name: "contact_email" },
                { type: ["text"], name: "company_name" },
                { type: ["text"], name: "country" },
                { type: ["text"], name: "contact_mobile_no" },
                { type: ["text"], name: "source" },
                { type: ["oppertunity_status"], name: "status" },
                { type: ["date"], name: "dateOfOpen" },
                { type: ["date"], name: "dateOfClosed" },
                // { type: ["text"], name: "leadId" },
              ]}
              height={"calc(100vh - 315px)"}
              view={permission?.read}
              edit={permission?.update}
              delete={permission?.delete}
              enable_double_click />
          </Grid>


          {/* create section */}
          <React.Fragment key={'right'}>
            <Drawer
              anchor={'right'}
              open={create?.open}
              onClose={() => handleCreate("open", false)}
            >
              <CreateLead
                t={t}
                title={`${create?.lead_id ? (moduleId==="35"?t("Update Workspace Opportunity"):t("Update Opportunity")) : (moduleId==="35"?t("Create Workspace Opportunity"):t("Create Opportunity"))}`}
                onClose={() => handleCreate("open", false)}
                state={{
                  SelectedCompany: selectedCompany,
                  company: companyList
                }}
                moduleId={moduleId}
                btnName="Opportunity"
                oppertunity_only={true}
                reload={() => {
                  getListData(0, 10, "", "key", selectedCompany, selectedTeams,filterData)
                  handleCreate("open", false)
                }}
                company_id={selectedCompany?.value}
                // quote_id={state?.selected_id}
                lead_id={create?.lead_id}
                onNext={onNext}
                setState1={setState1}
                state1={state1}
                is_opportunity={true}
                // revenue_type={"Lease"} 
              />
            </Drawer >
          </React.Fragment >

          {
            openFilter &&
            <FilterGenerator
              open={openFilter}
              onClose={OpenDrawer}
              components={[
                {
                  component: "toggleButton",
                  value: filterData?.periorty,
                  options: wrapLabelsInT(status?.value, t),
                  isMulti: true,
                  state_name: "periorty",
                  label: t("Priority Type"),
                  // required:true
                },
                {
                  component: "toggleButton",
                  value: filterData?.status,
                  options: wrapLabelsInT(status?.enum, t)  ?? [],
                  isMulti: true,
                  state_name: "status",
                  label: t("Status"),
                  // required:true
                },
                {
                  component: "select",
                  value: filterData?.name,
                  isMulti: true,
                  label: t("Contact Name"),
                  state_name: "name",
                  placeholder: t("Select Contact Name"),
                  loadOptions: (search, array, handleLoading) =>
                    loadOptions(
                      search,
                      array,
                      handleLoading,
                      LIST_COMPANY_CONTACT,
                      "contact",
                      { isActive: true, company_id: selectedCompany?.value, clientId: clientId },
                      { label: "label", value: "value" },
                      false,
                      false,
                      selectedCompany?.value
                    ),
                  debounceTimeout: 800,
                  isPaginate: true,
                },
                {
                  component: "select",
                  value: filterData?.lead,
                  isMulti: true,
                  label: t("Lead Owner"),
                  state_name: "lead",
                  placeholder: t("Select Lead Owner"),
                  loadOptions: (search, array, handleLoading) =>
                    loadOptions(
                      search,
                      array,
                      handleLoading,
                      LIST_COMPANY_OWNER,
                      "lead_owners",
                      { isActive: true, company_id: selectedCompany?.value, clientId: clientId },
                      { label: "label", value: "value" },
                      false,
                      false,
                      selectedCompany?.value
                    ),
                    debounceTimeout: 800,
                    isPaginate: true,
                },
              ]}
              onApply={(value) => onApplyFilter(value)}
            />
          }
        </Grid >
      </div >
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};
