import { useApolloClient } from "@apollo/client";
import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Stepper, Subheader } from "../../components";
import { AuthContext, BackdropContext } from "../../contexts";
import { CreateOppertunityContext } from "../../contexts/createOppertunityContext";
import {
  GETCOMPANYDETAILS,
} from "../../graphql/quotationQueries";
import { Routes } from "../../router/routes";
import { LocalStorageKeys, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew } from "../../utils";
import {
  returnConvertOppertunityEditPayload, returnOppertunityEditPayload
} from "../../utils/createQuotationUtils";
import { Attachments, LeadNew, LeadPreview } from "./steps";
import { useStyles } from "./styles";
import { retrunOppertunity } from "./utills";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

export const CreateOpportunity = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const { state } = useLocation();
  const [edit, setEdit] = React.useState(false)
  const auth = React.useContext(AuthContext)
  const backdrop = React.useContext(BackdropContext)
  const [permission, setPermission] = React.useState({})  
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)


  //context state
  const { step, previous, next, setDataNew, dataNew, setSelectedAccount, disableButton, t = () => false } =
    React.useContext(CreateOppertunityContext);
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        backdrop.setBackDrop({
          ...backdrop,
          open: true,
          message: "Loading...",
        });

        if (state?.company) {
          if (state?.id) {
            getOppertunityDeatils();
          } else {
            getCompanyDetails();
          }
        } else {
          history.push(Routes.leads);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const stepsNew = [
    {
      label: `${edit ? t("Edit Prospective") : t("Add Prospective")}`,
      step: 1,
      value: step,
    },
    {
      label: t("Attachments"),
      step: 2,
      value: step,
    },
    {
      label: t("Opportunity Details"),
      step: 3,
      value: step,
    },
    // {
    //   label: `${edit ? t("Preview and Edit Lead") : t("Preview and Create Lead")}`,
    //   step: 4,
    //   value: step,
    // },
  ];
  //edit lead
  // const editstepsNew = [
  //   {
  //     label: t("Opportunity Details"),
  //     step: 2,
  //     value: step,
  //     stepValue: "1",
  //   },
  //   {
  //     label: `${edit ? t("Preview and Edit Lead") : t("Preview and Create Lead")}`,
  //     step: 3,
  //     value: step,
  //     stepValue: "2",
  //   },
  // ];

  //get company details
  const getCompanyDetails = (value) => {
    client
      .query({
        query: GETCOMPANYDETAILS,
        fetchPolicy: "network-only",
        variables: {
          id: state?.company?.value || state?.main?.company?.value,
        },
      })
      .then((res) => {

        let data;
        let account = {
          normal: null,
          broker: null
        };
        if (state?.is_convert) {
          data = returnConvertOppertunityEditPayload({ ...state?.lead, ...state?.lead?.contact, state }, state?.company, res?.data?.company_master[0], state?.proxy_id, true)
          account = {
            normal: state?.lead?.account,
            broker: null
          }
        } else {
          data = retrunOppertunity({ company: res?.data?.company_master[0], opportunity: state?.opportunity, state })
          account = {
            normal: state?.opportunity?.account,
            broker: null
          }
        }


        
        setDataNew({
          ...dataNew,
          ...data
        });


        setSelectedAccount(account)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });

      })
      .catch((err) => console.log(err));
  };

  //get oppertunity details
  const getOppertunityDeatils = async() => {
    // client
    //   .query({
    //     query: getLeadDetails(),
    //     fetchPolicy: "network-only",
    //     variables: {
    //       id: state?.id,
    //     },
    //   })
      await NetworkCall(
        `${config?.api_url}/queries/get_lead_details`,
        NetWorkCallMethods.post,
        {
          id: state?.id,
        },
        null,
        true,
        false
      )
      .then(async (res) => {

        if (res?.data?.data?.contact_account?.id) {
          const payload = {
            account_id: res?.data?.data?.contact_account?.id,
            email: res?.data?.data?.contact?.email,
            is_existing: true,
            company_id: state?.company?.value || state?.main?.company?.value
          };

          await NetworkCall(
            `${config?.api_url}/contact/check_primary`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          )
            .then((response) => {
              let data = returnOppertunityEditPayload({ ...res?.data?.data, is_existing: true }, state, response?.data?.data?.primary, response?.data?.data?.is_existing);

              setSelectedAccount({
                normal: res?.data?.data?.contact_account,
                broker: res?.data?.data?.broker ?? null,
                agent:  res?.data?.data?.broker_agent ? {
                  ...res?.data?.data?.broker_agent,
                  label: res?.data?.data?.broker_agent?.name
                } : ""
              })


              setEdit(true)
              setDataNew(data);
              return backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
              });
            })
            .catch((err) => {
              console.log(err , 'err')
              backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "Loading...",
              });
            });
        }
        else {
          let data = returnOppertunityEditPayload({ ...res?.data?.data, is_existing: true }, state);

          setSelectedAccount({
            normal: res?.data?.data?.contact_account,
            broker: res?.data?.data?.broker ?? null,
            agent:  res?.data?.data?.broker_agent ? {
              ...res?.data?.data?.broker_agent,
              label: res?.data?.data?.broker_agent?.name
            } : ""
          })

          setEdit(true)
          setDataNew(data);
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        }



      })
      .catch((err) => console.log(err));
  };

  const newLeadComponent = {
    1: <LeadNew is_convert={state?.is_convert} contact_id={state?.opportunity?.contact?.value} />,
    2: <Attachments t={t} id={state?.id ?? null} />,
    3: <LeadPreview />,
  };


  const render = () => {
    return <>
      {/*sub header*/}
      <Subheader
        goBack={() => history.goBack()}
        title={`${edit ? (moduleId==="35"?t("Edit Workspace Opportunity"):t("Edit Opportunity")) : (moduleId==="35"?t("Create Workspace Opportunity"):t("Create Opportunity"))}`}
      />
      <Stepper t={t} step={step} steps={stepsNew} />
      {/*conditional component*/}
      <Box className={classes.content}>
        {newLeadComponent[step]}
      </Box>

      {/*btn container */}
      <Grid container className={classes.btnroot} spacing={1}>
        <Grid item xs={6}>
          <Button
            className={classes.previousbtn}
            variant="contained"
            onClick={previous}
            disabled={step === 1}
          >
            {t("Previous")}
          </Button>
        </Grid>
        <Grid item xs={6} textAlign={"end"}>

          <Button
            className={classes.previousbtn}
            variant="contained"
            onClick={() => history.goBack(-1)}
          >
            {t("Cancel")}
          </Button >

          {((state?.id && permission?.update) || (!Boolean(state?.id) && permission?.create)) &&
            <Button
              className={classes.submitbtn}
              variant="contained"
              disabled={disableButton}
              onClick={next}
            >
              {step === 3 ? t(edit ? "Update" : "Create") : t("Next")}
            </Button >}
        </Grid >
      </Grid >
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}</>
  );
};
