import React from 'react'
import { DailyCalendarStyle, eventListStyle } from './style'
import { Grid, Box } from '@mui/material'
import moment from 'moment'
import { Stack, Typography } from '@mui/material'
import { SwitchColors } from './weeklyCalendar'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ViewActivity } from './viewMore'
import { AlertDialog } from '../../../components'
import PhoneCallIcon from '../../../assets/phonecall'
import FollowupIcon from '../../../assets/followup'
import EventIcon from '../../../assets/event'
import EmailSlIcon from '../../../assets/emailsl'
import QuotationIcon from '../../../assets/quotation'
import SiteVisitsIcon from '../../../assets/sitevisits'
import AppoinmentIcon from '../../../assets/appointment'
import MeetIcon from '../../../assets/meet'

const activityLogo = {
    "Phone call": <PhoneCallIcon />,
    "Follow up": <FollowupIcon />,
    "Event": <EventIcon />,
    "Send Email": <EmailSlIcon />,
    "Send Quotation": <QuotationIcon />,
    "Arrange Site Visit": <SiteVisitsIcon />,
    "Appointment": <AppoinmentIcon />,
    "Meeting": <MeetIcon />
}

export const DailyCalendar = ({ progress = 0, list = [], handleEvent = () => false, openDeclinePopup = () => false, t=()=>false }) => {
    const classes = DailyCalendarStyle()
    const [viewMore, setViewMore] = React.useState(false)
    const [selected, setSelected] = React.useState({})
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));

    const hours = [
        { label: "00:00-04:00", start: "00", end: "04" },
        { label: "04:00-08:00", start: "04", end: "08" },
        { label: "08:00-12:00", start: "08", end: "12" },
        { label: "12:00-16:00", start: "12", end: "16" },
        { label: "16:00-20:00", start: "16", end: "20" },
        { label: "20:00-24:00", start: "20", end: "24" },
    ]

    return (
        <Box className={classes.calRow}>
            {
                hours?.map(e => {
                    return (
                        <>
                            <Grid container>
                                <Grid item md={1} sm={1} className={classes.timeCell}>
                                    <Box marginTop="12px">
                                        {e?.label}
                                    </Box>
                                </Grid>
                                <Grid item md={11} sm={11} className={classes.eventCell} p={1} sx={{ overflow: "auto" }}>
                                    {
                                        list?.[0]?.calendarData?.filter((item) => (item.start.slice(11, 13) >= e.start && item.end.slice(11, 13) <= e.end))?.map((data) => {
                                            return (
                                                data?.activity?.map((x) => {
                                                    return (
                                                        // moment.utc(x?.date_time).format("HH") === e?.start &&
                                                        <>
                                                            {
                                                                data?.activity?.length === 1 ?

                                                                    <HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                <CustomToolTip t={t} data={x} openDeclinePopup={openDeclinePopup} setViewMore={setViewMore} setSelected={setSelected} />
                                                                            </React.Fragment>
                                                                        }
                                                                        arrow className={classes.customTooltip}
                                                                    >
                                                                        <Box className={classes.contentbox} style={{ backgroundColor: SwitchColors(x?.activity_name) }}>
                                                                            <Stack direction="row" spacing={1} alignItems="center" p={1}>
                                                                                {/* <img src={SwitchActivityIcons(x?.activity_name)} alt="icon" /> */}
                                                                                <Box>
                                                                                    {activityLogo?.[x?.activity_name]}
                                                                                </Box>
                                                                                <Typography className={classes.mobileNo}>{x?.activity_name}</Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}>{x?.first_name}</Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}> {x?.created_by_mobile_no} </Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}> {x?.lead_email_id} </Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <div className={classes.statusbox}><Typography className={classes.statushdr}>{x?.status}</Typography></div>
                                                                            </Stack>
                                                                        </Box>

                                                                    </HtmlTooltip> :


                                                                    < HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                <CustomToolTip data={x} openDeclinePopup={openDeclinePopup} setViewMore={setViewMore} setSelected={setSelected} t={t}/>
                                                                            </React.Fragment>
                                                                        }
                                                                        arrow className={classes.customTooltip}
                                                                    >
                                                                        <Box className={classes.contentbox} style={{ backgroundColor: SwitchColors(x?.activity_name) }} mt={1} mb={1}>
                                                                            <Stack direction="row" spacing={1} alignItems="center" p={1}>
                                                                                <Box>
                                                                                    {activityLogo?.[x?.activity_name]}
                                                                                </Box>
                                                                                <Typography className={classes.mobileNo}>{x?.activity_name}</Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}>{x?.first_name}</Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}> {x?.created_by_mobile_no} </Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <Typography className={classes.mobileNo}> {x?.lead_email_id} </Typography>
                                                                                <div className={classes.dot}></div>
                                                                                <div className={classes.statusbox}><Typography className={classes.statushdr}>{x?.status}</Typography></div>
                                                                            </Stack>
                                                                        </Box>

                                                                    </HtmlTooltip>



                                                            }
                                                        </>
                                                    )
                                                })
                                            )
                                        })}
                                </Grid>
                            </Grid>
                        </>
                    )
                })
            }

            <AlertDialog
                md
                open={viewMore}
                onClose={() => setViewMore(false)}
                header={t("Activity")}
                component={<ViewActivity data={selected} handleClose={() => setViewMore(false)} t={t}/>}

            />
        </Box >
    )
}
const CustomToolTip = ({ t=()=>false, data = {}, startTime = null, endTime = null, openDeclinePopup = () => false, setViewMore = () => false, setSelected = () => false }) => {
    const classes = eventListStyle()
    return (
        <Stack p={1}>
            <Stack direction={"column"} spacing={1}>
                <Stack direction="row" spacing={1}>
                    <Box>
                        {activityLogo?.[data?.activity_name]}
                    </Box>
                    <Typography className={classes.ttTitle}> {data?.activity_name} </Typography>
                </Stack>
                <Stack pl={3.5}>
                    <Typography className={classes.ttsecTitle}> {moment.utc(new Date(data?.date_time)).format('DD-MM-YYYY hh mm A')}  </Typography>
                </Stack>
                <Stack pl={3.5}>
                    <Typography className={classes.ttassigned_by}>Created By : {data?.first_name}, {`${data?.mobile_no_country_code ?? ""} ${data?.mobile_no ?? ""}`} </Typography>
                </Stack>
                <Stack pl={3.5}>
                    <Typography className={classes.moreLabel} onClick={() => {
                        setSelected(data)
                        setViewMore(true)
                    }}> {t("View more")}</Typography>
                </Stack>
            </Stack>

        </Stack>
    )
}