import moment from "moment"
import { addId } from "../requestView/steps/utils"
import { NetworkCall } from "../../networkcall"
import { config } from "../../config"
import { NetWorkCallMethods } from "../../utils"

//selected_items -> selected_unit or asset
export const initialState = {
    PMPType: "Unit",
    pmp_criteria: "Regular",
    plan_name: "",
    plan_description: "",
    unit_type: "",
    category: "",
    sub_category: "",
    occurence: "",
    frequency: "",
    execution_end_date: null,
    execution_start_date: null,
    resource_type: "internal",
    contract_account: "",
    vendor_name: "",
    profession: "",
    job: "",
    properties: "",
    selected_items: [],
    selected_resources: [],
    selected_checklist: {},
    selected_account: {},
    selected_contract: null,
    selected_properties: null,
    contract_details: null,
    schedule_work_orders: [],
    selected_period: "Monthly",
    selected_dynamic_checklist: [],
    BOM: [],
    execution_hours: null,
    is_selected_allItems: false,
    dynamic_schedule: addId([
        {
            execution_start_date: null,
            frequency: "",
            occurence: "",
            execution_end_date: ""
        }
    ]),
    dynamic_plan_preview: [],
    error: {
        plan_name: "",
        plan_description: "",
        unit_type: "",
        category: "",
        sub_category: "",
        occurence: "",
        frequency: "",
        execution_end_date: null,
        execution_start_date: null
    }
}
export const CriteriaList = (t, type) => {
    return (
        [
            {
                title: t("Regular"),
                sub_title: type === "dynamic" ? t("Plan schedule maintenance for monthly,quarterly & half-yearly") : t("Routine checks at scheduled intervals"),
                is_selected: true
            },
            {
                title: t("Metered"),
                sub_title: t("Schedule maintenance based on usage metrics like distance or hours"),
                is_selected: false
            },
            {
                title: t("Condition"),
                sub_title: t("Maintenance Schedule by specific conditions,ensuring timely actions"),
                is_selected: false
            },
        ]
    )
}
export const steps = [
    { value: 0, label: "Scope The Plan" },
    { value: 1, label: "Unit/Asset" },
    { value: 2, label: "Resources" },
    { value: 3, label: "Checklists" },
    { value: 4, label: "Projected BOM" },
    { value: 5, label: "Schedule Work Orders" }
]
export const DynamicPlanSteps = [
    { value: 0, label: "Scope The Plan" },
    { value: 1, label: "Properties" },
    { value: 2, label: "Checklists" },
    { value: 3, label: "Projected BOM" },
    { value: 4, label: "Plan Preview" }
]
export const AssetPath = ["reference_no", "name", "item_category", "item_subcategory", "property_name", "unit_name", "is_active", "icon"]

export const AssetHeading = (t) => [
    { title: t("ID"), field: "reference_no" },
    { title: t("Item Name"), field: "name" },
    { title: t("Category"), field: "item_category" },
    { title: t("Sub Category"), field: "item_subcategory", },
    { title: t("Property"), field: "property_name", },
    { title: t("Unit"), field: "unit_name", },
    { title: t("Status"), field: "is_active", },
    { title: t(""), field: "icon", },
]

export const AssetDataType = [
    { type: ["text"], name: "reference_no" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "item_category" },
    { type: ["text"], name: "item_subcategory" },
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "unit_name" },
    { type: ["status"], name: "is_active" },
    { type: ["deleteIcon"], name: "icon" },
]
export const UnitPath = ["unit_no", "unit_type", "name", "property", "unit_location", "is_active", "icon"]

export const UnitHeading = (t) => [
    { title: t("ID"), field: "unit_no" },
    { title: t("Unit Type"), field: "unit_type" },
    { title: t("Unit Name"), field: "name" },
    { title: t("Property"), field: "property", },
    { title: t("Unit Location"), field: "unit_location", },
    { title: t("Status"), field: "is_active" },
    { title: t(""), field: "icon" },

]

export const UnitDataType = [
    { type: ["text"], name: "unit_no" },
    { type: ["text"], name: "unit_type" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "property" },
    { type: ["text"], name: "unit_location" },
    { type: ["status"], name: "is_active" },
    { type: ["deleteIcon"], name: "icon" },
]
export const ResourcePath = ["image_url", "reference_id", "user_no", "name", "job", "department", "functional_location", "icon"]

export const ResourceHeading = (t) => [
    { title: t("Picture"), field: "image_url" },
    { title: t("Resource Id"), field: "reference_id" },
    { title: t("Contact Id"), field: "user_no" },
    { title: t("Name"), field: "name" },
    // { title: t("Profession"), field: "profession" },
    { title: t("Job"), field: "job", },
    { title: t("Department"), field: "department", },
    { title: t("Functional Location"), field: "functional_location" },
    { title: t(""), field: "icon" },

]

export const ResourceDataType = [
    { type: ["avatarmanagement"], name: "image_url" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "user_no" },
    { type: ["text"], name: "name" },
    // { type: ["text"], name: "profession" },
    { type: ["text"], name: "job" },
    { type: ["text"], name: "department" },
    { type: ["text"], name: "functional_location" },
    { type: ["deleteIcon"], name: "icon" },

]
export const CheckListPath = ["group_id", "name", "inspection_type", "check_list_count", "is_active"]

export const CheckListHeading = (t) => [
    { title: t("Group ID"), field: "group_id" },
    { title: t("Checklist Group Name"), field: "name" },
    { title: t("Item Type"), field: "inspection_type" },
    // { title: t("Unit Type"), field: "unit_type", },
    // { title: t("Item Category"), field: "item_category", },
    { title: t("No.of Checklists"), field: "check_list_count", },
    { title: t("Status"), field: "is_active" },

]

export const CheckListDataType = [
    { type: ["text"], name: "group_id" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "inspection_type" },
    // { type: ["date"], name: "unit_type" },
    // { type: ["text"], name: "item_category" },
    { type: ["text"], name: "check_list_count" },
    { type: ["status"], name: "is_active" },
]
export const CheckListFormPath = ["is_checked", "group_id", "name", "inspection_type", "unit_type", "item_category", "check_list_count", "is_active", "icon"]

export const CheckListFormHeading = (t) => [
    { Checkbox: ["checkbox"], field: "is_checked" },
    { title: t("Group ID"), field: "group_id" },
    { title: t("Checklist Group Name"), field: "name" },
    { title: t("Item Type"), field: "inspection_type" },
    { title: t("Unit Type"), field: "unit_type", },
    { title: t("Item Category"), field: "item_category", },
    { title: t("No.of Checklists"), field: "check_list_count", },
    { title: t("Status"), field: "is_active" },
    { title: "", name: "icon" },
]

export const CheckListFormDataType = [
    { type: ["checkBox_custom"], name: "is_checked" },
    { type: ["text"], name: "group_id" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "inspection_type" },
    { type: ["text"], name: "unit_type" },
    { type: ["text"], name: "item_category" },
    { type: ["text"], name: "check_list_count" },
    { type: ["status"], name: "is_active" },
    { type: ["arrow"], name: "icon" },
]
export const ListPath = ["name", "condition", "is_mandatory", "is_active"]

export const ListHeading = (t) => [
    { title: t("Check List Name"), field: "name" },
    { title: t("Conditions"), field: "condition" },
    { title: t("Mandatory CheckList"), field: "is_mandatory" },
    { title: t("Status"), field: "is_active" },
]

export const ListDataType = [
    { type: ["text"], name: "name" },
    { type: ["text"], name: "condition" },
    { type: ["text"], name: "is_mandatory" },
    { type: ["status"], name: "is_active" },
]
export const ScheduleWorkOrdersPath = ["execution", "reference_no", "item_name", "unit_name", "property_name", "resource", "validation", "delete"]

export const ScheduleWorkOrdersHeading = (t) => [
    { title: t("Execution Date"), field: "execution" },
    { title: t("Item ID"), field: "reference_no" },
    { title: t("Item Name"), field: "item_name" },
    { title: t("Unit"), field: "unit_name", },
    { title: t("Property"), field: "property_name", },
    { title: t("Resource"), field: "resource", },
    { title: t("Job Validation"), field: "validation" },
    { title: t(""), field: "delete" },

]

export const ScheduleWorkOrdersDataType = [
    { type: ["date"], name: "execution" },
    { type: ["text"], name: "reference_no" },
    { type: ["text"], name: "item_name" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "property_name" },
    { type: ["select_box"], name: "resource" },
    { type: ["job_validation"], name: "validation" },
    { type: ["deleteIcon"], name: "delete" },
]
export const OverLapSchedulePath = ["execution", "reference_id", "item_reference_no", "item_name", "unit_name", "property_name", "resource_name"]

export const OverLapScheduleHeading = (t) => [
    { title: t("Schedule Date"), field: "execution" },
    { title: t("Schedule ID"), field: "reference_id" },
    { title: t("Item ID"), field: "item_reference_no" },
    { title: t("Item Name"), field: "item_name" },
    { title: t("Unit"), field: "unit_name", },
    { title: t("Property"), field: "property_name", },
    { title: t("Resource"), field: "resource_name", }

]

export const OverLapScheduleDataType = [
    { type: ["date"], name: "execution" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "item_reference_no" },
    { type: ["text"], name: "item_name" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "resource_name" },
]
export const TableHeader = (t) => {
    return (
        [
            {
                title: t("Item Type"),
                width: 200
            },
            {
                title: t("Category"),
                width: 230
            },
            {
                title: t("Subcategory"),
                width: 230
            },
            {
                title: t("Item"),
                width: 260
            },
            {
                title: t("Value"),
                width: 120
            },
            // {
            //     title: t("UMO")
            // },
            {
                title: t("QTY"),
                width: 60
            },
            {
                title: t("Total Amount"),
                width: 130
            },
            {
                title: "",
                value: "delete"
            },
        ]
    )
}
export const frequencyOptions = [
    {
        value: "Weekly",
        label: "Weekly",
        day_count: 7
    },
    {
        value: "Bi-Weekly",
        label: "Bi-Weekly",
        day_count: 14
    },
    {
        value: "Monthly",
        label: "Monthly",
        day_count: 30
    },
    {
        value: "Bi-Monthly",
        label: "Bi-Monthly",
        day_count: 60
    },
    {
        value: "Quarterly",
        label: "Quarterly",
        day_count: 90
    },
    {
        value: "Half-Yearly",
        label: "Half-Yearly",
        day_count: 180
    },
]

export const constructViewData = (val, PlannerType) => {
    return {
        id: val?.plan_data?.id,
        resource_type: val?.plan_data?.resource_type,
        PMPType: val?.plan_data?.pmp_type,
        pmp_criteria: val?.plan_data?.pmp_criteria,
        plan_name: val?.plan_data?.name,
        plan_description: val?.plan_data?.description,
        unit_type: val?.plan_data?.unit_type_master,
        category: val?.plan_data?.category_id !== null ? { value: val?.plan_data?.category?.value, label: val?.plan_data?.category?.label } : "",
        sub_category: val?.plan_data?.subcategory_id !== null ? { value: val?.plan_data?.subcategory?.value, label: val?.plan_data?.subcategory?.label } : "",
        occurence: val?.plan_data?.occurrences > 1 ? val?.plan_data?.occurrences : 1,
        frequency: val?.plan_data?.frequency !== null ? frequencyOptions?.filter((x) => { return x?.value === val?.plan_data?.frequency })?.[0] : "",
        execution_start_date: val?.plan_data?.start_date !== null ? new Date(val?.plan_data?.start_date) : null,
        execution_end_date: val?.plan_data?.end_date !== null ? new Date(val?.plan_data?.end_date) : null,
        contract_account: val?.plan_data?.contract !== null ? val?.plan_data?.contract?.account : "",
        vendor_name: val?.plan_data?.vendor_id !== null ? { value: val?.plan_data?.vendor?.value, label: val?.plan_data?.vendor?.label } : "",
        profession: val?.plan_data?.skills?.length > 0 ? val?.plan_data?.skills : [],
        job: val?.plan_data?.roles?.length > 0 ? val?.plan_data?.roles : [],
        properties: val?.plan_data?.properties ?? null,
        selected_items: val?.unit_asset?.length > 0 ? val?.unit_asset?.filter((x) => { return (x?.plan_config_type === "Unit" || x?.plan_config_type === "Asset") })?.map((i) => {
            return {
                ...i,
                edit_id: i?.id,
                name: i?.plan_config_type === "Asset" ? i?.item_name : i.unit_name,
                property: i.property_name,
                unit_location: i.block !== null ? i.block + i.floor : "-",
                unit_type: i.unit_type,
                is_active: true,
                is_selected: true,
                item_category: i?.category_name,
                item_subcategory: i?.subcategory_name,
                reference_no: i?.item_reference_no,
            }
        }) : [],
        selected_resources: val?.unit_asset?.length > 0 ? val?.unit_asset?.filter((x) => { return x?.plan_config_type === "Resource" })?.map((i) => {
            return {
                ...i,
                edit_id: i?.id,
                name: i?.first_name,
                label: i?.first_name,
                value: i?.resource_id,
                job: i?.job_name,
                image:i?.image_url,
                location: i?.functional_location,
                department: i?.department_name,
                is_active: true,
                is_selected: true,
            }
        }) : [],
        selected_checklist: val?.unit_asset?.length > 0 ? val?.unit_asset?.filter((x) => { return x?.plan_config_type === "Checklist" })?.map((i) => {
            return {
                ...i,
                edit_id: i?.id,
                name: i?.checklist_group_name,
                inspection_type: i?.checklist_inspection_type,
                check_list_count: i?.checlist_count,
                item_category: i?.checklist_category,
                is_active: true
            }
        }) : [],
        execution_hours: val?.plan_data?.execution_hour,
        selected_properties: PlannerType === "Fixed" ? val?.plan_data?.properties?.map((x) => {
            return {
                ...x,
                is_selected: true,
                property: { ...x }
            }
        }) ?? null : val?.unit_asset?.length > 0 ? val?.unit_asset?.filter((x) => { return x?.dynamic_config_type === "Property" })?.map((i) => {
            return {
                ...i,
                id: i?.property_id,
                name: i?.property_name,
                is_checked: true,
                property_no: i?.property_no,
                location: `${i?.city},${i?.country}`,
                is_active: true,
                is_selected: true
            }
        }) : [],
        contract_details: val?.plan_data?.contract !== null ? val?.plan_data?.contract : "",
        view_schedule_work_orders: val?.schedule?.length > 0 ? val?.schedule?.map((x) => { return { ...x, is_active: true } }) : [],
        view_executed_work_orders: val?.executed?.map((x) => { return { ...x, is_active: true,execution:moment(x?.execution).format("DD MMM YYYY") } }) ?? [],
        BOM: val?.bom?.length > 0 ? val.bom?.map((x) => {
            return {
                ...x,
                edit_id: x?.id,
                item_type: { value: x?.item_type, label: x?.item_type },
                item_category: { value: x?.category_id, label: x?.category_name },
                item_subCategory: { value: x?.subcategory_id, label: x?.subcategory_name },
                itemName: { value: x?.inspection_item, label: x?.item_name },
                price: x?.amount_per_item,
                qty: x?.quatity,
                amount: x?.line_amount,
                lineAmount: x?.line_amount,
                is_active: true,
            }
        }) : [],
        dynamic_schedule: val?.unit_asset?.length > 0 ? val?.unit_asset?.filter((x) => { return x?.dynamic_config_type === "Schedule" })?.map((i) => {
            return {
                ...i,
                edit_id: i?.id,
                execution_start_date: new Date(i?.start_date),
                execution_end_date: new Date(i?.end_date),
                frequency: frequencyOptions?.filter((x) => { return x?.value === i?.frequency })?.[0],
                occurence: i?.occurrences
            }
        }) : [],
        selected_dynamic_checklist: val?.unit_asset?.length > 0 ? val?.unit_asset?.filter((x) => { return x?.dynamic_config_type === "Checklist" })?.map((i) => {
            return {
                ...i,
                edit_id: i?.id,
                name: i?.checklist_group_name,
                inspection_type: i?.checklist_inspection_type,
                check_list_count: i?.checlist_count,
                item_category: i?.checklist_category,
                is_active: true,
                selected_period: i?.frequency
            }
        }) : [],
        selected_dynamic_properties: val?.unit_asset?.length > 0 ? val?.unit_asset?.filter((x) => { return x?.dynamic_config_type === "Property" })?.map((i) => {
            return i
        }) : [],
        dynamic_plan_preview: val?.schedule?.map((x) => {
            return {
                "property_id": x?.property_id,
                ...x,
                edit_id: x?.id,
                execution: `${moment(x?.start_date)?.format("DD MMM YYYY")} - ${moment(x?.end_date)?.format("DD MMM YYYY")}`,
                "frequency": x?.period,
                "criteria": val?.plan_data?.pmp_criteria,
                "type": val?.plan_data?.plan_type,
                "start_date": x?.start_date,
                "end_date": x?.end_date,
                name: x?.property_name,
            }
        })
    }
}
export const PropertyPath = ["is_checked", "property_no", "name", "location", "is_active"]

export const PropertyHeading = (t) => [
    { Checkbox: ["checkbox"], field: "is_checked" },
    { title: t("Property Id"), field: "property_no" },
    { title: t("Property Name"), field: "name" },
    { title: t("Location"), field: "location", },
    { title: t("Status"), field: "is_active" },

]

export const PropertyDataType = [
    { type: ["checkBox_custom"], name: "is_checked" },
    { type: ["text"], name: "property_no" },
    { type: ["text"], name: "name" },
    { type: ["long_text"], name: "location" },
    { type: ["status"], name: "is_active" },
]
export const PlanPath = ["property_name", "criteria", "frequency", "execution", "start_date"]

export const PlanHeading = (t) => [
    { title: t("Property Name"), field: "property_name" },
    // { title: t("Unit/Asset Type"), field: "type" },
    { title: t("Criteria"), field: "criteria" },
    { title: t("Frequency"), field: "frequency" },
    { title: t("Execution Period"), field: "execution" },
    { title: t("Schedule Date"), field: "start_date" },
]

export const PlanDataType = [
    { type: ["text"], name: "property_name" },
    // { type: ["text"], name: "type" },
    { type: ["text"], name: "criteria" },
    { type: ["text"], name: "frequency" },
    { type: ["text"], name: "execution" },
    { type: ["date"], name: "start_date" },
]
export const planPreviewTabOptions = [
    {
        value: "1",
        label: "Weekly",
        count: 0
    },
    {
        value: "2",
        label: "Bi-Weekly",
        count: 0
    },
    {
        value: "3",
        label: "Monthly",
        count: 0
    },
    {
        value: "4",
        label: "Bi-Monthly",
        count: 0
    },
    {
        value: "5",
        label: "Quarterly",
        count: 0
    },
    {
        value: "6",
        label: "Half-Yearly",
        count: 0
    },
]

export const loadOptionsApis = async (
    endpoint,
    queryVariables,
    search = "",
    array = [],
    handleLoading = null,
    extractLabel,
    mappingVariable = {},
    manualResponse,
) => {

    let result,
        offset = 0;

    if (search && !Boolean(array?.length)) {
        offset = 0;
    } else {
        offset = array?.length;
    }
    result = await networkCallBackApi(endpoint, { ...queryVariables, search }, handleLoading, offset);
    //Select options should be label,value pairs like ({label:'',value:''})

    //manually construct array
    if (manualResponse) {
        if (extractLabel === "list") {
            result["data"] = manualResponse(result);
        }
        else {
            result[extractLabel] = manualResponse(result[extractLabel]);
        }
    }
    return {
        options: [...result?.data?.[extractLabel]],
        hasMore:
            array?.length + result?.data?.[extractLabel]?.length < (result?.data?.count?.[0]?.count ?? result?.data?.count),
    };
};

const networkCallBackApi = async (endpoint, variable = {}, handleLoading, offset) => {

    let payload = {
        ...variable,
        offset,
        limit: 10
    };

    const options = await NetworkCall(
        `${config.api_url}/${endpoint}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    )
        .then((response) => {
            let main = response.data;
            handleLoading && handleLoading();
            return main;
        })
        .catch((error) => {
            handleLoading && handleLoading();
            return null;
        });

    return options;
};