import gql from "graphql-tag";
export const GETLEADBYOWNER = gql`
query($search: String, $companyId: String, $clinetID: String) {
  lead(
    where: {
      company_id: $companyId
      client: $clinetID
      prospective: { first_name: { iregex: $searchText } }
    }
    offset: $offset
    limit: $limit
  ) {
    urgent
    lead_no
    created_by
    id
    apply_grace_period
    lease_start_date
    lease_end_date
    lease_period
    occpation_date
    contract_start_date
    value
    grace_period_value
    grace_period_type
    created_at
    budget_range
    value_type
    revenue_type
    unit_usage
    required_area
    billing_cycle_date
    payment_mode
    turnover_type
    billing_cycle_date
    bill_generation
    payment_period
    revenue_based_lease
    auto_renewal_escalation
    auto_renewal
    billing_start_date
    include_market
    increment_escalation
    billing_day
    open_agreement
    company_id
    company {
      id
      uom
    }
    currency_id
    currency {
      id
      symbol
    }
    contact: prospective {
      id
      first_name
      last_name
      email: email_id
      mobile_no
      get_assets_url_id: image_url
    }
  }
}

`;


export let GET_LEAD_TYPE = (offset, limit, searchText) => gql`
query GET_LEAD_TYPE {
  count: lead_type_master(
    where: {
      name: { iregex: "${searchText}" }
      is_active: { eq: true }
    }
     offset: ${offset}
      limit: ${limit}
  ) {
    count: count_id
  }
  lead_type_master(
    where: { name: { iregex: "${searchText}" }, is_active: { eq: true } }
      offset: ${offset}
      limit: ${limit}
  ) {
    label: name
    value: id
  }
}

`
  ;

export let get_identification_master = (offset, limit, searchText, company_id = []) => gql`
query get_identification_master{
  count: Identification_master(
    where:{
      type:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  Identification_master(
      where:{
        type:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:type
      	value:id
      }
}
`
  ;

export const GETCOMPANYDETAILS = gql`
  query{
  company_master(where:{id:{eq:$id}}){
    id
    uom
     uom_master{
      value:id
      label:name
    }
    currency_id
     payment_period
     billing_cycle_date
     billing_cycle
     billing_day
     grace_period
    currencyByID{
      id
      symbol
    }
  }
}`

export const GETUOMMASTER =(ids)=> gql`
  query{
  uom_master(where:{
    is_active:true,
    ${ids?.length > 0 ? `id:{in:[${ids}]}`:``}
  }){
    value:id
    label:name
    mesurmentLabel:name
  }
}`

export const GETCONTACT = gql`
query($search: String) {
  contact(
    where: {
      is_active:true
      client:$client
      or: {
        first_name: { iregex: $searchText }
        last_name: { iregex: $searchText }
        email_id: { iregex: $searchText }
        mobile_no: { iregex: $searchText }
      }
    }

    offset: $offset
    limit: $limit
  ) {
    image_url
    first_name
    last_name
    mobile_no
    mobile_no_country_code
    alternative_mobile
    alternative_mobile_country_code
    fax_no
    facebook
    twitter
    linkedin
    phone
    phone_code
    email_id
    email:email_id
    image_url
    street_1
    street_2
    street_3
    city
    district
    state
    zipcode
    address{
     addressLineOne:street_1
     addressLineTwo: street_2
     landmark: street_3
      country
      state
      district
    }
  }
}

`;

export const GET_UNIT_RENT_BREAK_UP = gql`
query {
  quotation_units(where: { id: { eq: $unitID } }) {
    unit_id
    unit_total_primary
    unit {
      id
      assets: unit_assets(is_active: { eq: true }) {
        id
        url
        priority
        asset_type
      }
      asset: unit_assets(
        where: { is_active: true, asset_type: $masterType }
        orderby: { priority: asc }
      ) {
        id
        url
        asset_type
      }
      name
      unit_no
      unit_type{
        id
        name
      }
      total_baths
      total_rooms
      area_metric
      total_area
      total_bed_rooms
      property_id
      uom_master{
        id 
        name
      }
      property {
        id
        name
      }
      block_id
      block {
        id
        name
      }
      floor_id
      floor {
        id
        name
      }
    }

    quotation_rent_breakup {
      inspection_item{
        id
        name
      }
      before_tax
      before_rent_amount
      component_value
      rent_amount
      currency_id
     rent_breakup_id
     is_wallet_item
      rent_breakup{
        name
        primary
        refundable
        is_parking
        is_onetime
        is_wallet
        is_late_fee
      }
      currency{
        id
        symbol
      }
      tax
    }
  }
}
`

export const GET_COMPANY_PAYMENT = gql`query {
  company: company_master(where: { id: $id, client: $client }) {
    id
    name
    label:payment_period
  }
}
`

export const GET_CONTACT_DETAILS = gql`
query {
  contact(where:{email_id:$id}) {
    first_name
    id
    mobile_no
    primary_account_no
    mobile_no_country_code
    alternative_mobile
    alternative_mobile_country_code
    fax_no
    facebook
    twitter
    linkedin
    phone
    phone_code
    email_id
    nationality
    identification_id
    identification_master{
      value:id
      label:type
    }
    identification_no
    identification_expriy
    passport_id
    visa_no
    passport_expiry
    image_url
    address{
     addressLineOne:street_1
     addressLineTwo: street_2
     landmark: street_3
      country
      state
      district
      city
    }
  }
}

`

export const GET_ACCOUNT_BY_COMPANY = gql`
query {
  contact_account(
    where: { company_id: $id, client: $client, name: { iregex: $searchText } , relationship:$type }
    offset:$offset
    limit:$limit
  ) {
    value:id
    label:name
    id
    name
    account_no
  }
}
`

export const GET_IDENTIFICATION_MASTER = gql`
query{
  Identification_master(where:{
     type: { iregex: $searchText }
     client: {eq : $client}
     is_active: true
     is_delete:false
  }){
    value:id
    label:type
    proof_type
    is_active
  }
}
`

export const GET_ALL_CUNTRIES = (offset = 0, limit = 10, searchText = "") => gql`
query GET_ALL_CURRENCY{
    count:country_master(
         where:{
            country_name:{iregex:"${searchText}"}
        }
    ){
        count:count_id
    }
    country_master(
        where:{
            country_name:{iregex:"${searchText}"}
        }
        offset:${offset}
        limit:${limit}
    ){
        value:id
        label:country_name
    }
}
`

export const GET_ALL_CUNTRIES_VALUES = (offset = 0, limit = 10, searchText = "") => gql`
query GET_ALL_CURRENCY{
    count:country_master(
         where:{
            country_name:{iregex:"${searchText}"}
        }
    ){
        count:count_id
    }
    country_master(
        where:{
            country_name:{iregex:"${searchText}"}
        }
        offset:${offset}
        limit:${limit}
    ){
        value:country_name
        label:country_name
    }
}
`

export const getLeadDetails = () => gql`
query($iD: String) {
  lead(where: { id: $id }) {
    urgent
    lead_no
    created_by
    subject
    lead_source
    contract_start_date
    occpation_date
    unit_usage
    broker:broker_accountByID{
      id
      name
      value:id
      label:name
      logo
      email_id
      mobile_no_country_code
      account_no
      mobile_no
  }
    id
    apply_grace_period
    lease_start_date
    lease_end_date
    lease_period
    value
    grace_period_value
    grace_period_type
    created_at
    budget_range
    value_type
    revenue_type
    unit_usage
    required_area
    billing_cycle_date
    payment_mode
    turnover_type
    billing_cycle_date
    bill_generation
    payment_period
    revenue_based_lease
    auto_renewal_escalation
    auto_renewal
    billing_start_date
    include_market
    increment_escalation
    billing_day
    open_agreement
    is_agreement_lock
    company_id
    company {
      id
      uom
    }
    currency_id
    currency {
      id
      symbol
    }
    contact_account {
      id
      account_no
      name
      value:id
      label:name
      logo
      email_id
      mobile_no_country_code
      mobile_no
    }
    milestone_template {
      value: id
      label: name
    }
    billing_start_date
    contact: prospective {
      id
      first_name
      last_name
      email: email_id
      mobile_no
      mobile_no_country_code
      alternative_mobile
      alternative_mobile_country_code
      fax_no
      facebook
      linkedin
      twitter
      phone
      street_1
      street_1
      street_2
      street_3
      district
      latitude
      longitude
      country
      state
      door_no
      zipcode
      get_assets_url_id: image_url
      fax_code
      phone_code
      area
      city
      landmark
    }
    company_name
    city
    country{
      value: id
      label: country_name
    }
    unit: lead_units(where: { lead_id: $id, is_active: true }) {
      id
      property_id
      unitByID {
        id
        unit_name: name
        logo
        unit_no
        propertyByID {
          name
          id
        }
      }
    }
    lead_units(where: { is_active: true }) {
      id
      unit {
        id
        owner_id
        unit_no
        unit_name: name
        logo
        property {
          property_id: id
          property_name: name
          label: name
          property_no
          value: id
          url: logo
          label1: total_area
          uom_master {
            name
          }
        }
        contact_account {
          label: name
          value: id
          url: logo
          label1: account_no
        }
      }
    }
    proxy_detail: proxy_lead {
      id
      lead_name
      reference_no
    }
  }
}

`
export const getMailstone = (offset, limit, searchText, object, company_id) => gql`
query {
  count: milestone_template_master(
    where: {
      company_id:${object?.company_id}
      name: { iregex: "${searchText}" }
      is_active:true
      is_delete:false
     
    }   
    ) {
    count: count_id

  }
  
  milestone_template_master(
    where: {
      company_id:${object?.company_id}
      name: { iregex: "${searchText}" }
      is_active:true
      is_delete:false
     
    }   
    order_by: {created_at: desc}
    offset: ${offset}
    limit: ${limit}
  ) {
    value: id
    label: name
    template_type
  }
}

`

export const getMailstoneTemplate = gql`
query {
  milestone_template_items(
    where: { is_active: true, milestone_template_id: $template_id }
    order_by: {created_at: desc}
  ) {
    id
    delivery_milestone_masters {
      id
      name
    }
    description
    percentage:value
    value_type
  }
}

`


export const GETLEADBYOWNERDROPDOWN = (offset, limit, searchText, funParams) => gql`
query GETLEAD{
   count:lead(
     where: {
     is_active:true
     company_id:${funParams.company}
     or: {
      prospective: { first_name: { iregex: "${searchText}" } }
      lead_no: { iregex: "${searchText}" }
    }
     status: 2
    }){
      count:count_id
    }

  lead(
     where: {
     is_active:true
     company_id:${funParams.company}
     or: {
      prospective: { first_name: { iregex: "${searchText}" } }
      lead_no: { iregex: "${searchText}" }
    }
    }
     offset:${offset}
    limit:${limit}
    status: 2
  ) {
    urgent
    lead_no
    created_by
    id
    apply_grace_period
    billing_day
    lease_start_date
    lease_end_date
    lease_period
    occpation_date
    contract_start_date
    values:value
    grace_period_value
    shortlisted:lead_units{
      id
      unit_id
    }
    grace_period_type
    created_at
    budget_range
    value_type
    revenue_type
    unit_usage
    lead_source
    subject
    required_area
    billing_cycle_date
    payment_mode
    turnover_type
    billing_cycle_date
    bill_generation
    payment_period
    revenue_based_lease
    auto_renewal_escalation
    auto_renewal
    billing_start_date
    include_market
    increment_escalation
    open_agreement
    company_id
    company {
      id
      uom
      value:id
    }
    milestone_template
    milestone_templateByID{
        id
      name
    }
    currency_id
    currency {
      id
      symbol
    }
    contact: prospective {
      id
      first_name
      last_name
      email: email_id
      mobile_no
      get_assets_url_id: image_url
      mobile_no_country_code
      phone
      fax_no
         district
      country
      alternative_mobile
      alternative_mobile_country_code
    }
}
}`

export const getTemMailstone = (offset, limit, searchText, object, company_id) => gql`
query {
  count: milestone_template_master(where:
     {
       company_id:${company_id}
      name: { iregex: "${searchText}" }
      is_active:true
      is_delete:false
     }) {
    count: count_id
  }
  milestone_template_master(
    where: {
      company_id:${company_id}
      name: { iregex: "${searchText}" }
      is_active:true
      is_delete:false
    }
    offset: ${offset}
    limit: ${limit}
  ) {
    value: id
    label: name
    template_type
  }
}

`

export const GET_PROSPECTIVE_DETAILS = gql`
query {
  prospective(where:{email_id:$id}) {
    first_name
    id
    mobile_no
    mobile_no_country_code
    alternative_mobile
    alternative_mobile_country_code
    fax_no
    facebook
    twitter
    linkedin
    phone
    phone_code
    email_id
    nationality
    passport_id
    visa_no
    passport_expiry
    image_url
    addressLineOne:street_1
    addressLineTwo: street_2
    landmark: street_3
     country
     state
     district
     city
  }
}

`
export const GET_VENDOR_LIST = (offset = 0, limit = 10, searchText = "", params = {}) => {
  return gql`
query vendor_list {
  count: vendor_master(
    where: {
      company_id: { eq:${params?.companyId} }
      is_active: { eq: true }
      name: { iregex: "${searchText}" }
      client:"${params?.clientId}"
    }
    
  ) {
    count: count_id
  }
  
  vendor_master(
    where: {
      company_id: { eq: ${params?.companyId} }
      is_active: { eq: true }
      name: { iregex: "${searchText}" }
      client:"${params?.clientId}"
    }
    offset: ${offset}
    limit: ${limit}
  ) {
    value:id
    label:name
  }
}
`}



export const GET_SERVICE_PROVIDER_LIST = (offset = 0, limit = 10, searchText = "", params = {}) => {
  return gql`
  query service_provider_list {
    count: service_providers_master(
      where: {
        company_id:{eq:${params?.companyId}}
        is_active: { eq: true }
        name: { iregex: "${searchText}" }
        client:"${params?.clientId}"
      }
    ) {
      count: count_id
    }
    
    service_providers_master(
      where: {
        company_id:{eq:${params?.companyId}}
        is_active: { eq: true }
        name: { iregex: "${searchText}" }
        client:"${params?.clientId}"
      }
      offset: ${offset}
      limit: ${limit}
    ) {
      value:id
      label:name
    }
  }
  `}


export const GET_ACCOUNT_LIST = (offset = 0, limit = 10, searchText = "", params = {}) => {
  return gql`
    query contact_account {
      count: contact_account(
        where: {
          company_id:{eq:${params?.companyId}}
          is_active: { eq: true }
          name: { iregex: "${searchText}" }
          client:"${params?.clientId}"
          ${params?.type?.length ? `account_payment_classification: "${params?.type}"` : ''}

        }
      ) {
        count: count_id
      }
      
      contact_account(
        where: {
          company_id:{eq:${params?.companyId}}
          is_active: { eq: true }
          name: { iregex: "${searchText}" }
          client:"${params?.clientId}"
          ${params?.type?.length ? `account_payment_classification: "${params?.type}"` : ''}
        }
        offset: ${offset}
        limit: ${limit}
      ) {
        value:id
        label:name
        value1:account_no
      }
    }
    `}