import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Subheader } from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GET_PROPERTY_BYCOMPANY } from "../../graphql/scheduleBoardQuery";
import {
  JobType,
  NetWorkCallMethods,
  accessCheckRender,
  getCalendarOptions,
  getCompanyOption,
  getRoutePermissionNew,
  pageName,
} from "../../utils";
import { FacilityDashboard } from "./newDashboard";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { Dialog } from "@mui/material";
import ServiceHubMapView from "../serviceHubDashboard/mapView";

const FacilityManagerDashboard = (props) => {
  const { loading, handleLoading, t } = props;
  // states
  const [selectedCompany, setSelectedCompany] = useState("");
  const [data, setData] = useState([]);
  const [requestDetails, setRequestDetails] = React.useState([]);
  const [companyList, setCompanyList] = useState([]);
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [permission, setPermission] = React.useState({});
  const [calendarOptions, setCalendarOptions] = React.useState([]);
  const [selectedCalendar, setSelectedCalendar] = React.useState({});
  const [propertyData, setPropertyData] = React.useState([]);
  const [propertyValue, setPropertyValue] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [tabValue, setTabValue] = React.useState(1);
  const [showMap, setShowMap] = React.useState(false);
  // use effect to get permission
  useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
          getPropertyDropdown(company?.selected?.value);
          handleLoading(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //handleCompanyChange
  const handleCompanyChange = (val) => {
    setSelectedCompany(val);
    getPropertyDropdown(val?.value);
  };
  const getPropertyDropdown = (company = []) => {
    let payload = {
      query: GET_PROPERTY_BYCOMPANY,
      variables: {
        company_id: [company],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      const result = returnAllPropertyJson(res?.data?.data?.property);
      setPropertyData(result);
      setPropertyValue(result?.[0]);
      let tempCalendarOptions = getCalendarOptions();
      setCalendarOptions(tempCalendarOptions);
      setSelectedCalendar(tempCalendarOptions[6]);
      getDashboardData(
        company,
        result?.[0]?.value,
        tempCalendarOptions[6].value.from_date,
        tempCalendarOptions[6].value.to_date
      );
      getRequestDetails(
        company,
        0,
        10,
        result?.[0]?.value,
        tempCalendarOptions[6].value.from_date,
        tempCalendarOptions[6].value.to_date,
        tabValue,
        searchText,
        false,
        true
      );
    });
  };
  const getDashboardData = (company_id, property_id, from_date, to_date) => {
    const payload = {
      company_id: company_id,
      property_id: property_id,
      start_date: from_date,
      end_date: to_date,
    };
    NetworkCall(
      `${config.api_url}/facility_manager`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const total = res?.data?.inspection_job_type_graph
          ?.map((item) => parseInt(item?.count))
          .reduce((a, b) => a + b, 0);
        const itemSubCategoryTotal = res?.data?.item_sub_category
          ?.map((item) => parseInt(item?.count))
          .reduce((a, b) => a + b, 0);
        const inspection_order_graph =
          res?.data?.inspection_job_type_graph?.map((val) => {
            return {
              name: JobType(val.type),
              count: val?.count,
              fill: val?.fill,
            };
          });
        const WorkOrders = res?.data?.order_graph?.map((val) => {
          return {
            name: val.type,
            count: val?.count,
            fill: val?.fill,
          };
        });
        const item_category = res?.data?.item_category?.map((val) => {
          return {
            name: val.name,
            count: parseInt(val?.count),
            fill: val?.fill,
          };
        });
        const item_sub_category = res?.data?.item_sub_category?.map((val) => {
          return {
            name: val.name,
            count: parseInt(val?.count),
            fill: val?.fill,
          };
        });
        const ProjectedHoursGraphData = {
          labels: res?.data?.actual_other_projected?.map((val) => {
            return val?.type;
          }),
          datasets: [
            {
              label: "",
              data: res?.data?.actual_other_projected?.map((val) => {
                return val?.count;
              }),
              backgroundColor: res?.data?.actual_other_projected?.map((val) => {
                return val?.fill;
              }),
              borderWidth: 1,
              barThickness: 40,
            },
          ],
        };
        const JobStatusGraph = {
          labels: res?.data?.job_status_graph?.map((val) => {
            return val?.type;
          }),
          datasets: [
            {
              label: "",
              data: res?.data?.job_status_graph?.map((val) => {
                return val?.count;
              }),
              backgroundColor: res?.data?.job_status_graph?.map((val) => {
                return val?.fill;
              }),
              borderWidth: 1,
              barThickness: 40,
            },
          ],
        };
        setData({
          ...data,
          ...res?.data,
          details: res?.data,
          inspection_order_graph: inspection_order_graph,
          WorkOrders: WorkOrders,
          ProjectedHoursGraphData: ProjectedHoursGraphData,
          inspectionJobTotal: total,
          job_status_graph: JobStatusGraph,
          item_category: item_category,
          item_sub_category: item_sub_category,
          itemSubCategoryTotal: itemSubCategoryTotal,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get maintenance-general request list
  const getRequestDetails = (
    company_id = "",
    offset = 0,
    limit = 10,
    property_id = [],
    start_date = "",
    end_date = "",
    type = 1,
    search = "",
    fetchMore = false,
    isAllProperty = false
  ) => {
    const payload = {
      property: isAllProperty ? null : property_id?.[0],
      company_id: company_id,
      start_date: start_date,
      end_date: end_date,
      start: offset,
      length: limit,
      search: search,
      type: type,
    };
    NetworkCall(
      `${config.api_url}/inspection_manager/general_maintanence_list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        if (fetchMore) {
          setRequestDetails({
            ...requestDetails,
            requestData: requestDetails?.requestData.concat(
              res?.data?.data?.list
            ),
          });
        } else {
          setRequestDetails({
            ...requestDetails,
            requestData: res?.data?.data?.list,
            maintenanceCount: res?.data?.data?.maintance_count,
            generalCount: res?.data?.data?.general_count,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const returnAllPropertyJson = (list) => {
    const data = list?.map((val) => {
      return {
        label: val?.label,
        value: [val?.value],
      };
    });
    return [
      {
        label: t("All Properties"),
        value: list?.map((i) => {
          return i?.value;
        }),
      },
      ...data,
    ];
  };
  // Function to change the Calendar
  const handleCalendarChange = (date) => {
    if (date?.load) {
      setSelectedCalendar(date);
      getDashboardData(
        selectedCompany?.value,
        propertyValue?.value,
        date.value.from_date,
        date.value.to_date
      );
      getRequestDetails(
        selectedCompany?.value,
        0,
        10,
        propertyValue?.value,
        date.value.from_date,
        date.value.to_date,
        tabValue,
        "",
        false,
        propertyValue?.label === "All Properties" ? true : false
      );
    }
  };
  //handlePropertyChange
  const onPropertyChange = (val) => {
    setPropertyValue(val);
    getDashboardData(
      selectedCompany?.value,
      val?.value,
      selectedCalendar.value.from_date,
      selectedCalendar.value.to_date
    );
    getRequestDetails(
      selectedCompany?.value,
      0,
      10,
      val?.value,
      selectedCalendar.value.from_date,
      selectedCalendar.value.to_date,
      tabValue,
      "",
      false,
      val?.label === "All Properties" ? true : false
    );
  };
  //handleTabChange
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    getRequestDetails(
      selectedCompany?.value,
      0,
      10,
      propertyValue?.value,
      selectedCalendar.value.from_date,
      selectedCalendar.value.to_date,
      newValue,
      "",
      false,
      propertyValue?.label === "All Properties" ? true : false
    );
  };
  //handleSearch
  const handleSearch = (value) => {
    setSearchText(value);
    getRequestDetails(
      selectedCompany?.value,
      0,
      10,
      propertyValue?.value,
      selectedCalendar.value.from_date,
      selectedCalendar.value.to_date,
      tabValue,
      value,
      false,
      propertyValue?.label === "All Properties" ? true : false
    );
  };
  const handlePagination = (value) => {
    setPage(value);
    setLimit(10);
    let offset = (value - 1) * limit;
    getRequestDetails(
      selectedCompany?.value,
      offset,
      10,
      propertyValue?.value,
      selectedCalendar.value.from_date,
      selectedCalendar.value.to_date,
      tabValue,
      "",
      false,
      propertyValue?.label === "All Properties" ? true : false
    );
  };
  const handleMapView = () => {
    setShowMap(true);
  };
  const render = () => {
    return (
      <>
        {/* sub header */}
        <Subheader
          hideBackButton={true}
          title={t("Facility Manager Dashboard")}
          select
          options={companyList}
          value={selectedCompany}
          onchange={(e) => handleCompanyChange(e)}
          calanderselect
          calendarOptions={calendarOptions}
          calanderValue={selectedCalendar}
          onChangeCalendar={(e) => handleCalendarChange(e)}
          onPropertyChange={(e) => {
            onPropertyChange(e);
          }}
          selectProperty
          propertyOptions={propertyData}
          propertyValue={propertyValue}
        />
        <FacilityDashboard
          selectedcompany={selectedCompany}
          t={t}
          data={data}
          requestDetails={requestDetails}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          handlePagination={handlePagination}
          handleSearch={handleSearch}
          handleMapView={handleMapView}
          page={page}
          limit={limit}
        />
        <Dialog fullScreen open={showMap}>
          <ServiceHubMapView
            company_id={selectedCompany?.value}
            property_id={
              propertyValue?.label === "All Properties"
                ? null
                : propertyValue?.value
            }
            date={selectedCalendar}
            setShowMap={setShowMap}
            data={data}
            t={t}
          />
        </Dialog>
      </>
    );
  };

  return (
    <div>
      {accessCheckRender(render, permission, pageName?.dashboard, loading)}
    </div>
  );
};
export default withNamespaces("facilityManagerDashboard")(
  FacilityManagerDashboard
);
