import { useApolloClient } from "@apollo/client";
import {
    Box, Grid
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { FormGenerator } from "../../../components";
import { GET_LEAD_QUOTATION } from "../../../graphql/oppertunityQueries";
import { getMailstoneTemplate } from "../../../graphql/quotationQueries";
import { LocalStorageKeys } from "../../../utils";
import { saledataType, saleheading, salepath } from "../../../utils/createQuotationUtils";
import { LeadDetailsCard } from "./index";
import { MileStoneTemplate } from "./mileStoneTemplate";
import { useStyles } from "./styles";
// import createQuotationContext from "../../../contexts/createQuotationContext";

export const SaleQuotationForm = ({ data, updateState, user = {}, type = "", purpose = "", setData = () => false, selectedAccount, setSelectedAccount = () => false, t = () => false,
opperEdit= false, setOpperEdit= () =>false }) => {
    const classes = useStyles();
    const client = useApolloClient();
    // const { t } = React.useContext(createQuotationContext);
    const state = useLocation()?.state


    //get Quotation list
    // const getQuotation = () => {
    //     client.query({
    //         query: GET_LEAD_QUOTATION,
    //         fetchPolicy: 'network-only',
    //         variables: {
    //             id: data?.lead_id,
    //             offset: 0,
    //             limit: 10
    //         }
    //     }).then((response) => {
    //         let result = (response?.data?.count[0]?.count + 1).toString()
    //         updateState("quotation_description", `${data?.lead_details?.lead_no}/${result?.length === 1 ? '0' : ""}${response?.data?.count[0]?.count + 1}`)
    //     }).catch((err) => {
    //         console.log(err)
    //     })

    // }

    //get template
    const getTemplate = (value, update_des = false) => {
        client.query({
            query: getMailstoneTemplate,
            fetchPolicy: 'network-only',
            variables: {
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                template_id: value?.value
            }
        }).then(async (response) => {
            const list = response?.data?.milestone_template_items?.map((val) => {
                return { ...val, milestone: val?.delivery_milestone_masters?.name }
            })
            let error = data.error;
            error["payment_period"] = "";

            let description = null;

            if (state?.type === "existing lead" && update_des) {
                const res = await client.query({
                    query: GET_LEAD_QUOTATION,
                    fetchPolicy: 'network-only',
                    variables: {
                        id: data?.lead_id,
                        offset: 0,
                        limit: 10
                    }
                })
                let result = (res?.data?.count?.[0]?.count + 1).toString()
               description = `${data?.lead_details?.lead_no}/${result?.length === 1 ? '0' : ""}${res?.data?.count?.[0]?.count + 1}`
            }

            setData({
                ...data,
                quotation_payment_peroid: { value: "Milestone Based" },
                quotation_milestone: value,
                mailstoneList: list,
                mileStoneName: value?.label,
                mileStoneId: value?.value,
                payment_period_value: value,
                quotation_description: description ?? `${data?.lead_no}/01`,
                error
            })

        }).catch((err) => {
            console.log(err)
        })
    }
    const choosePaymentPeriod = (value) => {
        if (value.label === "On Completion") {
            let error = data.error;
            error["payment_period"] = "";
            setData({ ...data,
                 payment_period: { value: "On Completion" }, "mailstoneList": [], mileStoneName: value?.label, quotation_payment_peroid: { value: value?.label }, payment_period_value: value, error })
        }
        else {
            getTemplate(value)
        }

    }
    //form data

    const formLease1 = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 7,
                lg: 9,
            },
            isActive: true,
            component: "text",
            label: "Quotation Description",
            value: data?.quotation_description,
            placeholder: "Quotation Description",
            onChange: (value) => updateState("quotation_description", value.target.value),
            error: data?.error?.quotation_description,
            isRequired: true,
            options: [],
            // multiline: true
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
            },
            isActive: true,
            component: "date",
            label: "Handover Date",
            value: data?.quotation_handover_date,
            placeholder: "Handover Date",
            onChange: (value) => updateState("quotation_handover_date", value),
            error: data?.error?.quotation_handover_date,
            isRequired: true,
            options: [],
            isReadonly: true,
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
            },
            isActive: true,
            component: "date",
            label: "Contact Start Date",
            value: data?.quotation_contract_start_date,
            placeholder: "Contact Start Date",
            onChange: (value) => updateState("quotation_contract_start_date", value),
            error: data?.error?.quotation_contract_start_date,
            isRequired: true,
            options: [],
            isReadonly: true,
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
            },
            isActive: true,
            component: "payment",
            label: "Payment Period",
            value: data?.quotation_payment_peroid?.value,
            placeholder: "Payment Period",
            onChange: choosePaymentPeriod,
            mileStoneName: data?.mileStoneName,
            companyId: data?.company,
            error: data?.error?.quotation_payment_peroid,
            isRequired: true,
            mileStoneId: {
                payment_period: data?.payment_period,
                payment_period_value: data?.payment_period_value?.value,
            },
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
            },
            isActive: true,
            component: "select",
            label: "Payment Options",
            value: data?.quotation_payment_option,
            placeholder: "Payment Options",
            onChange: (value) => updateState("quotation_payment_option", value),
            error: data?.error?.quotation_payment_option,
            isRequired: true,
            options: [],
            isReadonly: true,
        },

    ]

    React.useEffect(() => {
        if ((data?.payment_period_value?.template_type)) {
            getTemplate(data?.payment_period_value, true)
        } else {
            let description = null;

            if (state?.type === "existing lead") {
                const res = client.query({
                    query: GET_LEAD_QUOTATION,
                    fetchPolicy: 'network-only',
                    variables: {
                        id: data?.lead_id,
                        offset: 0,
                        limit: 10
                    }
                })

                let result = (res?.data?.count?.[0]?.count + 1).toString()
                description = `${data?.lead_details?.lead_no}/${result?.length === 1 ? '0' : ""}${res?.data?.count?.[0]?.count + 1}`
            }


            setData({
                ...data,
                quotation_payment_peroid: { value: "On Completion" },
                quotation_description: description ?? `${data?.lead_no}/01`,
            })
        }
        // eslint-disable-next-line
    }, [])


    return (
        <Box >
            <Grid container spacing={1.5} className={classes.leadDetailsRoot}>
                <Grid item lg={3} md={12} sm={12} sx={{ position: "relative", width: "100%" }}>
                    <Box p={2} className={classes.leadDetailsRootDivider}>
                        <LeadDetailsCard
                            data={{
                                name: data?.name,
                                image: data?.image_url?.length !== 0 ? data?.image_url?.src : null,
                                email: data?.email_id,
                                mobile: ` ${data.mobile?.mobile_code ?? ""} ${data.mobile?.mobile ?? "-"}`,
                                no: null,
                                type: data?.revenue_type?.value,
                                purpose: data?.purpose?.value,
                                source: data?.source?.value,
                                units: data?.units
                            }}
                            t={t}
                            hideDetails={true}
                            dataNew={data}
                            setDataNew={setData}
                            updateState={updateState}
                            isReadOnly={true}
                            setSelectedAccount={setSelectedAccount}
                            account={selectedAccount}
                            opperEdit={opperEdit}
                            setOpperEdit={setOpperEdit}
                        />
                        <Box height="18px" />
                        {/* <FormGenerator components={formLease} /> */}
                    </Box>
                </Grid>
                <Grid item lg={9} md={12} sm={12}>
                    <Box p={2} className={classes.leadDetailsRootDivider}>
                        <FormGenerator t={t} components={formLease1} />
                        <Box height="18px" />
                        {
                            data?.mailstoneList?.length > 0 &&
                            <Grid item lg={6} md={12} sm={12}>
                                <MileStoneTemplate
                                    dataType={saledataType}
                                    rows={data?.mailstoneList}
                                    heading={saleheading}
                                    path={salepath}
                                    title={data?.mileStoneName}
                                />
                            </Grid>
                        }

                    </Box>
                </Grid>
            </Grid>
        </Box>


    );
};
