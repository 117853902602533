import * as React from "react"

const FourWheeler = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={23.333} {...props}>
    <defs>
      <linearGradient
        id="a"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#42a3f2" />
        <stop offset={1} stopColor="#42a4eb" />
      </linearGradient>
      <linearGradient
        id="b"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#297cd2" />
      </linearGradient>
    </defs>
    <g transform="translate(0 -4.667)">
      <path
        data-name="Path 96512"
        d="M25.333 28h4a.667.667 0 0 0 .667-.667V24h-5.333v3.333a.667.667 0 0 0 .666.667Z"
        fill="#565b5e"
      />
      <path
        data-name="Path 96513"
        d="M7.333 27.333V24H2v3.333a.667.667 0 0 0 .667.667h4a.667.667 0 0 0 .666-.667Z"
        fill="#565b5e"
      />
      <path
        data-name="Path 96514"
        d="m28 12.667-1.5-5.98a2.666 2.666 0 0 0-2.587-2.02H8.082a2.668 2.668 0 0 0-2.587 2.02L4 12.667l-1.2 1.6a4 4 0 0 0-.8 2.4V24a1.333 1.333 0 0 0 1.333 1.333h25.334A1.333 1.333 0 0 0 30 24v-7.333a4 4 0 0 0-.8-2.4Z"
        fill="url(#a)"
      />
      <circle
        data-name="Ellipse 129408"
        cx={1.667}
        cy={1.667}
        r={1.667}
        transform="translate(24 16)"
        fill="#fff"
      />
      <circle
        data-name="Ellipse 129409"
        cx={1.667}
        cy={1.667}
        r={1.667}
        transform="translate(4.667 16)"
        fill="#fff"
      />
      <path
        data-name="Path 96515"
        d="m10 19.333 1 2.5a1.334 1.334 0 0 0 1.238.838h7.528A1.334 1.334 0 0 0 21 21.829l1-2.5Z"
        fill="url(#b)"
      />
      <path
        data-name="Path 96516"
        d="M30.667 13.333H28V12a1.333 1.333 0 0 1 1.333-1.333h2a.667.667 0 0 1 .667.667V12a1.333 1.333 0 0 1-1.333 1.333Z"
        fill="url(#a)"
      />
      <path
        data-name="Path 96517"
        d="M1.333 13.333H4V12a1.333 1.333 0 0 0-1.333-1.333h-2a.667.667 0 0 0-.667.666V12a1.333 1.333 0 0 0 1.333 1.333Z"
        fill="url(#a)"
      />
      <path
        data-name="Path 96518"
        d="m26.833 13.333-.061-.082-.065-.261-1.5-5.98A1.331 1.331 0 0 0 23.918 6H8.082a1.331 1.331 0 0 0-1.293 1.01l-1.495 5.98-.065.261-.062.082Z"
        fill="url(#b)"
      />
    </g>
  </svg>
)

export default FourWheeler
