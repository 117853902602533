/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Badge, Box, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useApolloClient } from '@apollo/client';
import FilterIMG from "../../assets/filter";
import { FilterGenerator, PropertyTabs, SearchFilter, Subheader, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, useWindowDimensions, timeZoneConverter } from '../../utils';
import InfiniteScroll from "react-infinite-scroll-component";
import { ContactsView } from './contactsView';
import { Opportunities } from './opportunities';
import { contactsStyles } from "./style";
import { GET_PROSPECTIVE, GET_PROSPECTIVE_BY_ID } from '../../graphql/queries';
import { StatusOptionListLang } from '../../utils/propspect/tableUtils';
import { withNamespaces } from 'react-i18next';

const Propspect = ({
    t
}) => {


    const classes = contactsStyles();
    const size = useWindowDimensions()
    const clientID = localStorage.getItem(LocalStorageKeys.clinetID) ?? ""
    const debounce = UseDebounce();
    const client = useApolloClient()
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [fiterDrawer, setFilterDrawer] = React.useState(null);
    const [contactList, setContactList] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const [companyList, setCompanyList] = React.useState([]);
    const [offset, setOffset] = React.useState(0)
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const [selected, setSelected] = React.useState();
    const [contactDetails, setContactDetails] = React.useState(null)
    const [opportunityList, setOpportunityList] = React.useState([])
    const [value, setValue] = React.useState(1);
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const TabTittle = [
        {
            label: t("Contact Details"),
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: t("Opportunities"),
            value: 2,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        },
    ]

    const fetchMoreData = () => {
        getContactByCompanyID(searchText, offset + 20, true)
        setOffset(offset + 20)
    }
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        getContactByCompanyID(e, 0, false)
    }


    const draweropen = () => {
        setFilterDrawer(true);
    };
    const drawerclose = () => {
        setFilterDrawer(false);
    };


    const getContactDetailsByID = (prospectiveID) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", });
        client.query({
            query: GET_PROSPECTIVE_BY_ID,
            fetchPolicy: "network-only",
            variables: { prospectiveID }
        }).then((r) => {
            let tempContactDetails = r?.data?.prospective?.[0]
            let leadByID = tempContactDetails?.leadByID ?? []
            let tempOpportunityList = leadByID?.map?.((_) => {
                return {
                    id: _?.id,
                    lead_no: _?.lead_no,
                    created_at: timeZoneConverter(_?.created_at),
                    subject: _?.subject,
                    unit_usage: _?.unit_usage,
                    lead_source: _?.lead_source,
                    urgent: _?.urgent,
                    owner: _?.lead_owner?.first_name,
                    status: _?.statusByID?.status,
                }
            })
            setContactDetails(tempContactDetails)
            setOpportunityList(tempOpportunityList)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        })
    }

    const getContactByCompanyID = (search, offset, searchBool) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "", });
        client.query({
            query: GET_PROSPECTIVE,
            fetchPolicy: "network-only",
            variables: {
                clientID: clientID, search, offset, limit: 20,
                status: (!filterData?.status || filterData?.status?.length === 0) ?
                    [true, false] : filterData?.status,
                companyID: selectedCompany?.value,
            }
        }).then((r) => {
            if (searchBool) {
                setContactList(
                    contactList.concat(r?.data?.prospective)
                );
            } else {
                setContactList(r?.data?.prospective);
                setSelected(r?.data?.prospective?.[0])
            }
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        })
    };

    React.useEffect(() => {
        if (selectedCompany?.value) {
            getContactByCompanyID(searchText, 0, false);
            setOffset(0)
        }
    }, [selectedCompany, filterData]);

    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }
    const onApplyFilter = (value) => {
        setFilterData(value)
    }
    //tab cbange
    const tabChange = (e, newValue) => {
        setValue(newValue)
    }

    React.useEffect(() => {
        if (selected) {
            getContactDetailsByID(selected?.id)
        }
    }, [selected])

    const onContactSelecte = (val) => {
        setValue(1)
        setSelected(val)
    }

    const render = () => {
        return <>
            <Subheader hideBackButton={true} title={t("Prospective Contacts")}
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            <Grid container className={classes.content} spacing={1}>
                <Grid item xs={4}>
                    <SearchFilter value={searchText} placeholder={t("Search Contacts")} handleChange={(value) => handleSearch(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                </Grid>
                <Grid item xs={8} textAlign={"right"}>
                    <Box display={"flex"} sx={{ justifyContent: "end" }}>
                        <Stack direction="row" spacing={2}
                            divider={<Divider orientation="vertical" flexItem />}>
                            {filterData.relationship?.length > 0 || filterData.status?.length > 0 ?
                                (<IconButton onClick={draweropen} className={classes.img}>
                                    <Badge variant="dot" color="primary">
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>) :
                                (<IconButton onClick={draweropen} className={classes.img}>
                                    <FilterIMG color="#091b29" />
                                </IconButton>)}
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} p={2}>
                <Grid item xs={3}>
                    <Box className={contactList?.length > 0 ? classes.box2 : classes.box}><>
                        <Typography className={classes.heading}>{t("Contacts Lists")}</Typography>
                        <InfiniteScroll
                            dataLength={contactList?.length ?? ""}
                            next={fetchMoreData}
                            hasMore={true}
                            height={size?.height - 250}>
                            <Box className={classes.listRoot}>
                                {contactList?.map((val) => {

                                    return (
                                        <Box
                                            display={"flex"}
                                            onClick={() => onContactSelecte(val)}
                                            className={
                                                selected?.id === val?.id
                                                    ? classes.labelSelectedContact
                                                    : classes.lableContact
                                            }>
                                            <Box>
                                                {val?.image_url ? (
                                                    <Avatar src={val?.image_url} />
                                                ) : (
                                                    <Avatar />
                                                )}
                                            </Box>
                                            <Box sx={{ marginInlineStart: "10px" }}>
                                                <Typography
                                                    className={
                                                        selected?.id === val?.id
                                                            ? classes.labelSelectedContact
                                                            : classes.lableContact
                                                    }>
                                                    {val?.first_name}
                                                    {val?.created_at && <Typography
                                                        className={
                                                            selected?.id === val?.id
                                                                ? classes.jobTextSelected
                                                                : classes.jobText
                                                        }>
                                                        {t("Created At: ")} &#x202a;{timeZoneConverter(val?.created_at)}&#x202c;
                                                    </Typography>}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </InfiniteScroll>
                    </>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box className={contactList?.length > 0 ? classes.box2 : classes.box}>
                        {contactList?.length > 0 ?
                            <>
                                {contactDetails &&
                                    <>
                                        <Grid container p={"10px"} alignItems="center" spacing={1} width={"100%"}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.title}>{contactDetails?.first_name ?? "-"}</Typography>
                                            </Grid>
                                        </Grid>
                                        <PropertyTabs value={value} padding handleChange={tabChange}
                                            tab1={(
                                                <ContactsView t={t} data={contactDetails} />
                                            )}
                                            tab2={(
                                                <Opportunities t={t} data={opportunityList} />
                                            )}
                                            t={t}


                                            tabTittle={TabTittle}
                                        />
                                    </>
                                }
                            </>
                            :
                            <Typography className={classes.noData} >{t("No contacts found")}</Typography>

                        }
                    </Box>
                </Grid>
            </Grid>

            <FilterGenerator open={fiterDrawer} onClose={drawerclose} onApply={(value) => onApplyFilter(value)}
                components={[
                    {
                        component: "toggleButton",
                        value: filterData?.status,
                        options: StatusOptionListLang(t),
                        isMulti: true,
                        state_name: "status",
                        label: t("Status"),
                    },
                ]} />
        </>
    }

    return <div >
        {accessCheckRender(render, permission)}
    </div >
}
export default withNamespaces("propspect")(Propspect)
