import { Box, Grid } from "@mui/material";
import { useContext } from "react";
import { SubscriptionsContext } from "./subscriptionsContext";
import { ItemCard } from "./components/itemCard";

export const Configuration = () => {
    const {
        configurationClasses: classes,
        login_logo_ref,
        login_logo_config_state,
        web_background_ref,
        web_background_config_state,
        mobile_background_ref,
        mobile_background_config_state,
        web_welcome_ref,
        web_welcome_config_state,
        mobile_welcome_ref,
        mobile_welcome_config_state,
    } = useContext(SubscriptionsContext);

    return (
        <Box className={classes.tab_content_div}>
            <Box className={classes.tab_content_inner_div}>
                <Grid container direction={"row"} spacing={"16px"}
                    justifyContent={"start"}>
                    <ItemCard
                        state={login_logo_config_state}
                        upload_file_ref={login_logo_ref} />
                    <ItemCard
                        state={web_background_config_state}
                        upload_file_ref={web_background_ref} />
                    <ItemCard
                        state={mobile_background_config_state}
                        upload_file_ref={mobile_background_ref} />
                    <ItemCard
                        state={web_welcome_config_state}
                        upload_file_ref={web_welcome_ref} />
                    <ItemCard
                        state={mobile_welcome_config_state}
                        upload_file_ref={mobile_welcome_ref} />
                </Grid>
            </Box>
        </Box>
    )
}