import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { AlertDialog, LoadingSection, SearchFilter, TableWithPagination, UseDebounce } from "../../../components";
import { CURRENCY_MASTER } from "../../../graphql/clientSettingsQueries";
import { currencydataType, currencyheading, currencypath } from "../../../utils/clientsettings";
import { SettingSave } from "../../companyCreation/components";
import { FormGenerator } from "../components";

export const Currency = ({ selected = {}, t = () => false }) => {


    const [open, setOpen] = React.useState(false);
    const client = useApolloClient();
    const [selectData, setSelectData] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const [limits, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = React.useState("");
    //debounce
    const debounce = UseDebounce();
    const [currencyList, setcurrencyList] = React.useState({
        list: [],
        count: 0
    })
    //onclick row
    const onClickRow = (data) => {
        setOpen(true)
        setSelectData(data)
    }
    //form json
    const formData = [
        {
            isActive: true,
            component: "text",
            label: t("Country"),
            value: selectData?.country,
            placeholder: t("Country"),
            isReadonly: true
        },
        {
            isActive: true,
            component: "text",
            label: t("Currency"),
            value: selectData?.curreny_name,
            placeholder: t("Currency"),
            isReadonly: true
        },
        {
            isActive: true,
            component: "text",
            label: t("Code"),
            value: selectData?.code,
            placeholder: t("Code"),
            isReadonly: true
        },
        {
            isActive: true,
            component: "text",
            label: t("Symbol"),
            value: selectData?.symbol,
            placeholder: t("Symbol"),
            isReadonly: true
        },
        {
            isActive: true,
            component: "check",
            label: t("Status"),
            value: selectData?.status,
        },
    ]
    //get currency list
    const getCurrencylist = (limit, offset, search) => {
        client.query({
            query: CURRENCY_MASTER,
            fetchPolicy: 'network-only',
            variables: {
                limit: limit,
                offset: offset,
                searchText: search
            },
        }).then((response) => {
            setcurrencyList({
                list: response.data.currency_master?.map((val) => {
                    return ({ ...val, country: val?.country?.country_name ?? "-" })
                }),
                count: response.data.count[0]?.count
            })
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    //initial load
    React.useEffect(() => {
        getCurrencylist(limits, 0, "")
        // eslint-disable-next-line 
    }, [])
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limits;
        getCurrencylist(limits, offset)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getCurrencylist(value, 0)
    }
    //search in table
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getCurrencylist(limits, 0, e)
    }
    return (
        <Box>
            {loading ? <LoadingSection bottom={"45vh"} message="Fetching ..." /> :
                <Box>
                    {/*save section*/}
                    <SettingSave
                        title={selected?.label} subTitle={selected?.subtitle} t={t} />
                    {/*table*/}
                    <Box padding="8px 12px 0px 12px" width={"330px"}>

                        <SearchFilter value={searchText} placeholder={t("Search Currency")} handleChange={(value) => handleSearch(value)} />
                    </Box>
                    <Box padding="0px 12px">
                        <TableWithPagination
                            heading={currencyheading(t)}
                            rows={currencyList?.list}
                            path={currencypath}
                            showpagination={true}
                            onClick={onClickRow}
                            dataType={currencydataType}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={currencyList?.count}
                            page={page}
                            limit={limits}
                            height={'calc(100vh - 351px)'}
                            view={true}
                            edit={true}
                            delete={true} />
                    </Box>
                </Box>
            }
            {/*form dialog*/}
            <AlertDialog
                isNormal
                header={t("Currency")}
                onClose={() => setOpen(false)}
                open={open}
                component={
                    <Box p={2}>
                        <FormGenerator t={t} components={formData} />
                    </Box>
                }
            />
        </Box>
    )
}