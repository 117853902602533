/* eslint-disable react/jsx-no-duplicate-props */
import {
  Checkbox, FormControlLabel, Grid, InputAdornment, Switch, TextField, Typography
} from "@mui/material";
import React from "react";
import { CurrencyInputComponent, MobileNumberInputComponent, Period, SelectBox, SelectUser, TextBox, TextFieldWithSelect, UploadLogo } from "..";
import { DateTime } from "../date";
import SelectBoxv2 from '../selectBox';
import { toEnglishNumber } from "../../utils";

export const generateComponent = ({
  data,
  handleChange = null,
  state,
  classes,
  readOnly,
  parent_id,
  handleLoading,
  loading,
  ...props
}) => {
  const handleUser = (type, data) => {
    handleChange(type, data)
  }
  switch (data.componet_type) {
    case "text":
      return (
        <Grid style={{ paddingTop: "16px" }} md={data?.grid?.md ?? 12}>
          <Typography className={classes.drawerSubHead} color={"tertiary"}>
            {data.label}
            {data?.required && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <TextBox
            id={`${parent_id}_${data.state_name ? data.state_name : ""}__text_field`}
            value={state?.states?.[data?.state_name] ?? ""}
            onChange={(e) => {
              if (data?.type === "number") {
                let number = toEnglishNumber(e.target.value)
                handleChange(data.state_name, number, data.isUpperCase, data?.textLength)
              } else {
                handleChange(data.state_name, e.target.value, data.isUpperCase, data?.textLength)
              }
            }}
            onKeyPress={(e) => {
              if (data?.type === "number" && ['e', 'E']?.includes(e.key)) {
                e.preventDefault();
              }
            }}
            variant="outlined"
            fullWidth
            placeholder="Type here"
            required={data?.required}
            disabled={readOnly}
            type={data?.type ?? "text"}
            size="small"
            name="route"
            multiline={data?.multiline ?? false} //props for text area
            maxRows={data?.maxRows} //props for text area - maximum height
            minRows={data?.minRows} //props for text area - minimum height
            inputProps={{
              style: { backgroundColor: data?.disabled ? "#E0E0E0" : "#fff" },
            }}
            InputLabelProps={{
              style: {},
            }}
            error={state?.error?.[data?.state_name] ?? false}
            className={classes.textbox}
            label={null}
          />
        </Grid>
      );
    case "switch":
      let val = state?.states?.[data?.state_name];
      if (typeof val === "string" && data?.valueOption) {
        val = data?.valueOption[val] ?? false
      }
      return (
        <Grid
          style={{
            display: "flex",
            // justifyContent: "space-between",
            margin: 10,
            // paddingLeft: 10,
            alignItems: "center",
          }}
          xs={6}
        >
          <Typography className={classes.switchTitle}>{data.label}</Typography>
          <Switch
            id={`${parent_id}_${data.state_name ? data.state_name : ""}__switch`}
            checked={val ? val : false}
            onChange={(e) => handleChange(data.state_name, e.target.checked)}
            name={data?.state_name}
            inputProps={{ "aria-label": "secondary checkbox" }}
            disabled={readOnly}
            className={classes.switch}
          />
        </Grid>


      );
    case "checkbox":
      return (
        <Grid style={{ paddingTop: "16px" }} md={data?.grid?.md ?? 12}>
          <FormControlLabel
            control={
              <Checkbox
                id={`${parent_id}_${data.state_name ? data.state_name : ""}__checkbox`}
                checked={state?.states?.[data?.state_name] ?? ""}
                onChange={(e) =>
                  handleChange(data.state_name, e.target.checked)
                }
                name="checkedA"
              />
            }
            label={data.label}
          />
        </Grid>
      );
    case "select":
      let option = data?.options ?? [];
      return (
        <Grid style={{ paddingTop: "16px" }} md={data?.grid?.md ?? 12}>
          <SelectBoxv2
            parent_id={`${parent_id}_${data.state_name ? data.state_name : ""}__selectbox`}
            requireStart={data.required}
            top_title={data.label}
            list={Array.isArray(option) ? option : []}
            placeholder={data?.placeholder ?? "Search here..."}
            value={state?.states?.[data?.state_name] ?? ""}
            onchange={(e, value) => handleChange(data.state_name, value)}
            error={state?.error?.[data?.state_name] ?? false}
            multi={data.multi ?? false}
            disabled={readOnly}
            classes={classes}
          />
        </Grid>
      );
    case "date":
      return (
        <Grid style={{ paddingTop: "16px" }} md={data?.grid?.md ?? 12}>
          <DateTime
            id={`${parent_id}_${data.state_name ? data.state_name : ""}__datepicker`}
            required={data.required}
            title={data.label}
            dateFormat={data.dateFormat}
            disabled={readOnly}
            value={state?.states?.[data?.state_name] ?? null}
            onChange={(value) => handleChange(data.state_name, value)}
            error={state?.error?.[data?.state_name] ?? false}
            minDate={state?.states?.[data?.minDateState] ?? null}
          />
        </Grid>
      );
    case "textField_select":
      return (
        <Grid style={{ paddingTop: "16px" }} md={data?.grid?.md ?? 12}>
          <TextFieldWithSelect
            id={`${parent_id}_${data.state_name ? data.state_name : ""}__text_field_with_select`}
            options={data?.options}
            label={data.label}
            disabled={readOnly}
            value={state?.states?.[data?.state_name] ?? {}}
            onChange={(value) => handleChange(data.state_name, value)}
            required={data?.required}
            error={state?.error?.[data?.state_name] ?? false}
          />
        </Grid>
      );
    case "currency": {
      return (
        <Grid style={{ paddingTop: "16px" }} md={data?.grid?.md ?? 12}>
          <CurrencyInputComponent
            label={data.label}
            isReadonly={readOnly}
            isRequired={data.required}
            isCurrency={true}
            onChange={(value) => handleChange(data.state_name, value)}
            value={state?.states?.[data?.state_name] ?? {}}
          />
        </Grid>
      );
    }
    case "period": {
      return (
        <Grid
          item
          container
          style={{ paddingTop: "16px" }}
          md={data?.grid?.md ?? 12}
          alignItems="center"
        >
          <Period
            label={data.label}
            isReadonly={readOnly}
            isrequired={data.required}
            isCurrency={true}
            state={data.state_name}
            onChange={handleChange}
            value={state?.states?.[data?.state_name] ?? {}}
          />
        </Grid>
      );
    }
    case "Reqirement": {
      return (
        <Grid
          item
          container
          style={{ paddingTop: "16px" }}
          md={data?.grid?.md ?? 12}
          alignItems="center"
        >
          <Period
            label={data.label}
            isReadonly={readOnly}
            isrequired={data.required}
            isCurrency={true}
            state={data.state_name}
            onChange={handleChange}
            value={state?.states?.[data?.state_name] ?? {}}
          />
        </Grid>
      );
    }
    case "textCustom":
      return (
        <Grid style={{ paddingTop: "16px" }} md={data?.grid?.md ?? 12}>
          <Typography className={classes.drawerSubHead}>
            {data.label}
            {data?.required && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <TextField
            id={`${parent_id}_${data.state_name ? data.state_name : ""}__text_field`}
            value={typeof state?.states?.[data?.state_name] === "object" ? state?.states?.[data?.state_name].label : state?.states?.[data?.state_name] ?? ""}
            onChange={(e) => {
              let value = toEnglishNumber(e.target.value);
              if (value) {
                value = Number(value)
                if (value > data?.maxNum) {
                  value = data?.maxNum
                }
                value += ""
              }
              handleChange(data.state_name, value, data.isUpperCase, data?.textLength)
            }}
            variant="outlined"
            fullWidth
            required={data?.required}
            disabled={readOnly || data?.disabled}
            type={data?.type ?? "text"}
            size="small"
            name="route"
            multiline={data?.multiline ?? false} //props for text area
            maxRows={data?.maxRows} //props for text area - maximum height
            minRows={data?.minRows} //props for text area - minimum height
            InputProps={{
              style: { fontFamily: "poppin", fontSize:"0.75rem", backgroundColor: data?.disabled ? "red" : "#fff" },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography style={{ paddingRight: "10px", fontFamily: "poppin", fontSize:"0.75rem", }}>
                    {data?.maxNum ?? ""}
                  </Typography>
                  <Typography style={{ color: "#B6B6B6", fontFamily: "poppin", fontSize:"0.75rem", }}>
                    {data?.uomValue ?? ""}
                  </Typography>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { fontFamily: "poppin", fontSize:"0.75rem" },
            }}
            error={state?.error?.[data?.state_name] ?? false}
            helperText={"* Entered Value Should Be Between 0 -" + data?.maxNum}
          />
        </Grid>
      );
    case "asyncSelect":
      const loadOptions = (search, prevArray, data, state) => {
        handleLoading(data?.state_name)
        return data?.loadOptions(search, prevArray, handleLoading, state)
      }
      return (
        <Grid style={{ paddingTop: "16px" }} md={data?.grid?.md ?? 12}>
          <SelectBox
            isPaginate={data?.isPaginate}
            key={data?.key ? JSON.stringify(state?.states?.[data?.key]) : ''}
            value={state?.states?.[data?.state_name] ?? ""}
            placeholder={data?.placeholder ?? "Search here..."}
            loadOptions={(search, prevArray) => loadOptions(search, prevArray, data, state)}
            onChange={(value) => handleChange(data.state_name, value)}
            options={data?.options || data?.defaultOptions}
            loading={loading === data?.state_name}
            // customStyles={data?.customStyles ?? {}}
            isReadOnly={(readOnly || data?.readOnly) ?? false}
            isMulti={data?.isMulti ?? false}
            debounceTimeout={data?.debounceTimeout}
            isRequired={data?.required}
            label={data.label}
            parentID={parent_id}
          />
        </Grid>
      )
    case 'uploadLogo':
      return (
        <Grid style={{ paddingTop: "16px" }} md={data?.grid?.md ?? 12}>
          <UploadLogo
            conditions={data?.conditions}
            handleChange={(value) => handleChange(data.state_name, { logo: value })}
            accept={data?.accept}
            value={state?.states?.[data?.state_name]?.logo ?? ""}
            disabled={readOnly ?? false}
            required={data?.required ?? false}
          />
        </Grid>
      )
    case "selectUser":
      let option2 = data?.options ?? [];
      return (
        <Grid style={{ paddingTop: "16px" }} md={data?.grid?.md ?? 12}>
          <SelectUser
            isrequired={data.required}
            label={data.label}
            options={Array.isArray(option2) ? option2 : []}
            disableClearable={data.disableClearable}
            placeholder={data?.placeholder ?? "Search here..."}
            value={state?.states?.[data?.state_name] ?? ""}
            onChange={handleUser}
            error={state?.error?.[data?.state_name] ?? false}
            multi={data.multi ?? false}
            state={data?.state_name}
            disabled={readOnly}
            classes={classes}
          />
        </Grid>
      );
    case "mobileNo":
      return (
        <Grid style={{ paddingTop: "16px" }} md={data?.grid?.md ?? 12}>
          <MobileNumberInputComponent
            label="Contact Number"
            placeholder="Enter Contact Number"
            isRequired
            value={{
              mobile_code: state?.states?.[data?.state_name]?.mobile_code ?? "+91",
              mobile: state?.states?.[data?.state_name]?.mobile ?? "",
            }}
            handleChange={(value) => {
              let val = {
                mobile_code: value?.mobile_code,
                mobile: value?.mobile
              }
              handleChange(data.state_name, val)
            }}
            isError={state?.error?.[data?.state_name] ?? false}
            errorMessage={state?.error?.[data?.state_name]}
          />
        </Grid>
      );


    default:
      break;
  }
};