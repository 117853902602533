import React from 'react'
import { PieChartsWithCurve, SelectBox } from "../../components";
import { Box, Grid, Typography, List, ListItem } from "@mui/material";
import { dashboardStyles } from "./style";


export const GraphComponent = (props) => {
      const classes = dashboardStyles(props);
      return (
            <div className={classes.card}>
                  <Grid container spacing={3}>
                        <Grid item xs={12}>
                              <Grid container spacing={3}>
                                    <Grid item xs={8} className={classes.dotParent}>
                                          {/* title */}
                                          <Typography className={classes.titleTop}>{props?.title}</Typography>
                                          {/* tab  */}
                                          <div className={classes.marginLeft}>
                                                {
                                                      props?.isTab && <TabComponent tabList={props?.tabList} keyType={props?.keyType} handleList={props?.handleList} data={props?.data} tabNameState={props?.tabNameState} />
                                                }

                                          </div>
                                    </Grid>

                                    <Grid item xs={4}>
                                          {/* select box */}
                                          {
                                                props?.isSelect && <SelectBox
                                                      options={props?.option ?? []}
                                                      value={props?.dropDownState ?? ""}
                                                      onChange={(value) => {
                                                            props?.handleList(props?.dropDownKey, value);
                                                      }}
                                                />
                                          }

                                    </Grid>
                              </Grid>
                        </Grid>
                  </Grid>
                  {/* pie chart */}
                  <Grid container spacing={3} className={classes.graphParet}>
                        <Grid item xs={5} style={{padding:'24px'}}>

                                    <PieChartsWithCurve
                                          data={props?.dumydata}
                                          isPosition
                                          radius={500}
                                          borderWidth={25}
                                          total={props?.total ?? 0}
                                    />

                        </Grid>
                        <Grid item xs={7}>
                              {/* pie chart details */}
                              <Grid container spacing={3}>
                                    {/* <Grid item xs={12}>
                                          <Typography className={classes.graphDataCount}>{props?.total}</Typography>
                                          <Box className={classes.dotParent}>
                                                <Box><Box className={classes.dot} style={{ backgroundColor: '#DADADA' }} /></Box>
                                                <Box><Typography className={classes.graphDataTitle}>Total</Typography></Box>
                                          </Box>

                                    </Grid> */}
                                    {
                                          props?.graphData?.map((x) => {
                                                return (
                                                      <Grid item xs={6}>
                                                            <Typography className={classes.graphDataCount}>{x?.value ?? 0}</Typography>
                                                            <Box className={classes.dotParent}>
                                                                  <Box><Box className={classes.dot} style={{ backgroundColor: x?.fill }} /></Box>
                                                                  <Box><Typography className={classes.graphDataTitle}>{x?.name}</Typography></Box>
                                                            </Box>
                                                      </Grid>
                                                )
                                          })
                                    }

                              </Grid>
                        </Grid>
                  </Grid>
            </div>
      )
}


// tab component
const TabComponent = (props) => {
      const classes = dashboardStyles(props);
      return (
            <List className={classes.list}>
                  {
                        props?.tabList?.map((x, index) => {
                              return (
                                    <ListItem className={x?.name === props?.tabNameState ? classes.listText : classes.listText1}
                                          onClick={() => props?.handleList(props?.keyType, x?.name)}
                                    >
                                          <Typography className={classes.tabname}>{x?.name}</Typography>
                                    </ListItem>
                              )

                        })
                  }
            </List>
      )
}