export const settinglist = (t) => [
    {
        label: t("Basic Settings"),
        value: 1,
        subtitle: ""
        // "Basic Setting are the major default mandatory setting which basically setup the whole flow setting"
    },
    {
        label: t("Advance Settings"),
        value: 2,
        subtitle: ""
        // "Advanced Setting are the additional setting which can be added addition towards the basic setting"
    },
    // {
    //     label: "Logos & Appearances",
    //     value: 3,
    //     subtitle: "Logos upload and its visual appearance in interface screen"
    // },
    // {
    //     label: "Integration",
    //     value: 4,
    //     subtitle: "Payment Integration API key configuration"
    // },
    // {
    //     label: "Welcome Screen Banners",
    //     value: 5,
    //     subtitle: ""
    // },
    // {
    //     label: "Dashboard Banners",
    //     value: 6,
    //     subtitle: ""
    // },
    // {
    //     label: "Services banner",
    //     value: 7,
    //     subtitle: ""
    // },
    // {
    //     label: "Community banners",
    //     value: 8,
    //     subtitle: ""
    // },
    {
        label: t("Countries"),
        value: 9,
        subtitle: ""
        // "Countries available for the specific client"
    },
    {
        label: t("Resident App Controls"),
        value: 13,
        subtitle: ""
    },
    {
        label: t("Templates"),
        value: 14,
        subtitle: ""
    },
    // {
    //     label: "Currency",
    //     value: 10,
    //     subtitle: "Currency available for the specific client"
    // },
    // {
    //     label: "Tax Group",
    //     value: 11,
    //     subtitle: "Tax Group available for the specific client"
    // },
    // {
    //     label: "Tax Group Item",
    //     value: 12,
    //     subtitle: "Tax Group Item available for the specific client"
    // }
    {
        label: t("Terms & Condition"),
        value: 15,
        subtitle: ""
    },
    {
        label: t("Gateway Controls"),
        value: 16,
        subtitle: ""
    },
    {
        label: t("Onboard Settings"),
        value: 17,
        subtitle: ""
    },
]

export const basicSettingState = {
    cpi_percentage: null,
    setting_id: "",
    default_base_currency: "",
    default_uom: "",
    quotation_validity: { value: null, label: "None" },
    billing_payment_peroid: "",
    billing_payment_terms: "",
    billing_cycle_method: "",
    billing_generate_date: "",
    default_payment_gateway: "",
    loginName: "",
    loginPassword: "",
    loginToken: "",
    paymentProviderName: "",
    provider_url: "",
    billing_payment_terms_value: "",
    lease_period: "",
    reservation_feature: { value: null, label: "None" },
    auto_deposite_cal: "",
    auto_reservation_cal: "",
    grace_peroid: { value: null, label: "None" },
    pricing_override: "",
    short_term_retals: "",
    storage_offering: "",
    index_calculation: "",
    refer_win: "",
    bill_custom_date: "",
    professoinal_banners: "",
    default_time_zone: "",
    level1_block: "",
    level1_floor: "",
    level1_is_primary: "",
    level2_block: "",
    level2_floor: "",
    level2_is_primary: "",
    discussion: {
        is_active: false
    },
    neigbours: {
        is_active: false
    },
    contact: {
        is_active: false
    },
    survey: {
        is_active: false
    },
    walkin: {
        is_active: false
    },
    booking: {
        is_active: false
    },
    polls: {
        is_active: false
    },
    deleivery: {
        is_active: false
    },
    parking: {
        is_active: false
    },
    templates: [],
    deleted_templates: [],
    initial_templates: [],
    terms_conditions_docs: [],
    onboard_type: [{
        type: "Lease",
        value: { label: "tenant", value: "tenant" },
        app: "tenant"
    }, {
        type: "Manage",
        value: { label: "resident", value: "resident" },
        app: "resident"
    }],
    error: {
        default_base_currency: "",
        default_uom: "",
        quotation_validity: "",
        billing_payment_peroid: "",
        billing_payment_terms: "",
        billing_cycle_method: "",
        billing_generate_date: "",
        default_payment_gateway: "",
        loginName: "",
        loginPassword: "",
        loginToken: "",
        paymentProviderName: "",
        provider_url: "",
        billing_payment_terms_value: "",
        lease_period: "",
        reservation_feature: "",
        auto_deposite_cal: "",
        auto_reservation_cal: "",
        grace_peroid: "",
        pricing_override: "",
        short_term_retals: "",
        storage_offering: "",
        index_calculation: "",
        refer_win: "",
        professoinal_banners: "",
        default_time_zone: ""
    }
}

export const advanceSettingState = {
    setting_id: "",
    reservation_feature: { value: null, label: "None" },
    auto_deposite_cal: "",
    auto_reservation_cal: "",
    grace_peroid: { value: null, label: "None" },
    pricing_override: "",
    short_term_retals: "",
    storage_offering: "",
    index_calculation: "",
    refer_win: "",
    professoinal_banners: "",
    level1_block: "",
    level1_floor: "",
    level1_is_primary: "",
    level2_block: "",
    level2_floor: "",
    level2_is_primary: "",
    error: {
        reservation_feature: "",
        auto_deposite_cal: "",
        auto_reservation_cal: "",
        grace_peroid: "",
        pricing_override: "",
        short_term_retals: "",
        storage_offering: "",
        index_calculation: "",
        refer_win: "",
        professoinal_banners: "",
    }
}

export const logopath1 = ["logo", "name"]


export const logoheading1 = (t) => [
    { title: t("Logo"), field: "logo", },
    { title: t("Company Name"), field: "name", },
]

export const logodataType1 =
    [
        { type: ["avatarmanagement"], name: "logo" },
        { type: ["text"], name: "name" },
    ]

export const logopath = ["logo", "name", "count"]


export const logoheading = (t) => [
    { title: t("Logo"), field: "logo", },
    { title: t("Company Name"), field: "name", },
    { title: t("Count"), field: "count", },
]
export const logodataType =
    [
        { type: ["avatarmanagement"], name: "logo" },
        { type: ["text"], name: "name" },
        { type: ["text"], name: "count" },
    ]
export const welcomepath = ["welcome_screen_logo", "name"]


export const welcomeheading = (t) => [
    { title: t("Welcome Banner"), field: "welcome_screen_logo", },
    { title: t("Company Name"), field: "name", },
]

export const welcomedataType =
    [
        { type: ["avatarmanagement"], name: "welcome_screen_logo" },
        { type: ["text"], name: "name" },
    ]


export const logoState = {
    name: "",
    module: "",
    logo: "",
    company: "",
    status: "",
    error: {
        name: "",
        module: "",
        logo: "",
        company: "",
        status: "",
    }
}

export const dasnboardbannerpath = ["updated_by", "uploaded_at", "company", "count"]

export const dasnboardbannerheading = [
    { title: "Updated By", field: "updated_by", },
    { title: "Updated At", field: "uploaded_at", },
    { title: "Company", field: "company", },
    { title: "Count", field: "count", },
]

export const dasnboardbannerdataType =
    [
        { type: ["text"], name: "updated_by" },
        { type: ["date"], name: "uploaded_at" },
        { type: ["text"], name: "company" },
        { type: ["text"], name: "count" },

    ]


export const servicebannerpath = ["updated_by", "uploaded_at", "company", "count"]

export const servicebannerheading = [
    { title: "Updated By", field: "updated_by", },
    { title: "Updated At", field: "uploaded_at", },
    { title: "Company", field: "company", },
    { title: "Count", field: "count", },
]

export const servicebannerdata = [
    {
        id: "1",
        company: "Web App company",
        company_id: 12,
        updated_by: "Bala Vignesh",
        uploaded_at: "2022-05-27T19:53:20.881702+05:30",
        count: 4,
    },
    {
        id: "2",
        company: "Web App company",
        updated_by: "Bala Vignesh",
        uploaded_at: "2022-05-27T19:53:20.881702+05:30",
        count: 4,
        company_id: 12,
    },
    {
        id: "3",
        company: "Web App company",
        updated_by: "Bala Vignesh",
        uploaded_at: "2022-05-27T19:53:20.881702+05:30",
        count: 4,
        company_id: 12,
    },
    {
        id: "4",
        company: "Web App company",
        updated_by: "Bala Vignesh",
        uploaded_at: "2022-05-27T19:53:20.881702+05:30",
        count: 4,
        company_id: 12,
    },
    {
        id: "5",
        company: "Web App company",
        updated_by: "Bala Vignesh",
        uploaded_at: "2022-05-27T19:53:20.881702+05:30",
        count: 4,
        company_id: 12,
    }
]

export const servicebannerdataType =
    [
        { type: ["text"], name: "updated_by" },
        { type: ["date"], name: "uploaded_at" },
        { type: ["text"], name: "company" },
        { type: ["text"], name: "count" },

    ]



export const communitybannerpath = ["updated_by", "uploaded_at", "company", "count"]

export const communitybannerheading = [
    { title: "Updated By", field: "updated_by", },
    { title: "Updated At", field: "uploaded_at", },
    { title: "Company", field: "company", },
    { title: "Count", field: "count", },
]

export const communitybannerdata = [
    {
        id: "1",
        company: "Web App company",
        company_id: 12,
        updated_by: "Bala Vignesh",
        uploaded_at: "2022-05-27T19:53:20.881702+05:30",
        count: 4,
    },
    {
        id: "2",
        company: "Web App company",
        updated_by: "Bala Vignesh",
        uploaded_at: "2022-05-27T19:53:20.881702+05:30",
        count: 4,
        company_id: 12,
    },
    {
        id: "3",
        company: "Web App company",
        updated_by: "Bala Vignesh",
        uploaded_at: "2022-05-27T19:53:20.881702+05:30",
        count: 4,
        company_id: 12,
    },
    {
        id: "4",
        company: "Web App company",
        updated_by: "Bala Vignesh",
        uploaded_at: "2022-05-27T19:53:20.881702+05:30",
        count: 4,
        company_id: 12,
    },
    {
        id: "5",
        company: "Web App company",
        updated_by: "Bala Vignesh",
        uploaded_at: "2022-05-27T19:53:20.881702+05:30",
        count: 4,
        company_id: 12,
    }
]

export const communitybannerdataType =
    [
        { type: ["text"], name: "updated_by" },
        { type: ["date"], name: "uploaded_at" },
        { type: ["text"], name: "company" },
        { type: ["text"], name: "count" },

    ]


export const countrypath = ["name", "status"]

export const countryheading = (t) => [
    { title: t("Country Name"), field: "name", },
    { title: t("Status"), field: "status", },
]
export const countrydataType =
    [
        { type: ["text"], name: "name" },
        { type: ["status"], name: "status" },

    ]


export const currencypath = ["curreny_name", "code", "symbol", "status"]

export const currencyheading = (t) => [
    // { title: "Country", field: "country", },
    { title: t("Currency"), field: "curreny_name", },
    { title: t("Code"), field: "code", },
    { title: t("Symbol"), field: "symbol", },
    { title: t("Status"), field: "status", },
]

export const currencydataType =
    [
        // { type: ["text"], name: "country" },
        { type: ["text"], name: "curreny_name" },
        { type: ["text"], name: "code" },
        { type: ["text"], name: "symbol" },
        { type: ["status"], name: "status" },

    ]



export const taxpath = ["tax_group", "country", "tax_code", "total_rate", "status"]

export const taxheading = (t) => [
    { title: t("Tax Group"), field: "tax_group", },
    { title: t("Country"), field: "country", },
    { title: t("Tax Group Code"), field: "tax_code", },
    // { title: "Total Rate", field: "total_rate", },
    { title: t("Status"), field: "status", },
]

export const taxdataType =
    [
        { type: ["text"], name: "tax_group" },
        { type: ["text"], name: "country" },
        { type: ["text"], name: "tax_code" },
        // { type: ["text"], name: "total_rate" },
        { type: ["status"], name: "status" },

    ]

export const taxCreateState = {
    tax_group: "",
    country: "",
    status: "",
    code: "",
    error: {
        tax_group: "",
        country: "",
        status: "",
        code: "",
    }
}



export const taxitempath = ["tax_group", "tax_item", "tax_code", "total_rate", "status"]

export const taxitemheading = (t) => [
    { title: t("Tax Group"), field: "tax_group", },
    { title: t("Tax Item"), field: "tax_item", },
    { title: t("Tax Item Code"), field: "tax_code", },
    { title: t("Total Rate"), field: "total_rate", },
    { title: t("Status"), field: "status", },
]

export const taxitemdataType =
    [
        { type: ["text"], name: "tax_item" },
        { type: ["text"], name: "tax_group" },
        { type: ["text"], name: "tax_code" },
        { type: ["text"], name: "total_rate" },
        { type: ["status"], name: "status" },

    ]

export const taxitemCreateState = {
    tax_group: "",
    tax_item: "",
    status: "",
    rate: "",
    code: "",
    error: {
        tax_group: "",
        tax_item: "",
        status: "",
        code: "",
        rate: "",
    }
}
export const returnSeetingData = (data) => {
    const level1 = data?.hierarchy.find((x) => x?.hierarchy_id === 5)
    const level2 = data?.hierarchy.find((x) => x?.hierarchy_id === 6)
    const result = {
        setting_id: data?.id,
        bill_custom_date: data?.billing_day,
        default_base_currency: { value: data?.currency ?? "" },
        default_uom: { value: data?.uom_master?.id, label: data?.uom_master?.name ?? "" },
        quotation_validity: { value: data?.quotation_validity ?? null, is_active: (data?.quotation_validity || data?.quotation_validity === null) ? true : false },
        billing_payment_peroid: { value: data?.billing_payment_period ?? "" },
        billing_payment_terms: { value: data?.billing_payment_terms ?? "" },
        billing_cycle_method: { value: data?.billing_cycle_method ?? "" },
        billing_generate_date: { value: data?.billing_generation_date ?? "" },
        default_payment_gateway: { value: data?.payment_gateway_type ?? "" },
        loginName: data?.login_name,
        loginPassword: data?.login_password,
        loginToken: data?.login_token,
        paymentProviderName: data?.payment_provider_name,
        provider_url: data?.payment_provider_url,
        billing_payment_terms_value: data?.billing_payment_terms_value,
        lease_period: { value: data?.lease_period ?? "" },
        reservation_feature: { value: data?.reservation_validity ?? null, is_active: (data?.reservation_validity || data?.reservation_validity === null) ? true : false },
        auto_deposite_cal: { is_active: data?.auto_deposit_calc },
        auto_reservation_cal: { is_active: data?.auto_reservation_calc },
        grace_peroid: { value: data?.grace_period_validity ?? null, is_active: (data?.grace_period_validity || data?.grace_period_validity === null) ? true : false },
        pricing_override: { is_active: data?.is_pricing_override },
        short_term_retals: { is_active: data?.is_short_term },
        storage_offering: { is_active: data?.is_storage_offering },
        refer_win: { is_active: data?.is_refer_win },
        professoinal_banners: { is_active: data?.is_promotional_banners },
        default_time_zone: { value: data?.timezone ?? '' },
        level1_block: level1?.block_label ?? "",
        level1_floor: level1?.floor_label ?? "",
        level1_is_primary: level1?.is_primary ?? "",
        level2_block: level2?.block_label ?? "",
        level2_floor: level2?.floor_label ?? "",
        level2_is_primary: level2?.is_primary ?? "",
        level1_id: level1?.id ?? null,
        level2_id: level2?.id ?? null,
        hierarchy: data?.hierarchy,
        discussion: {
            is_active: data?.is_discussion_forum
        },
        neigbours: {
            is_active: data?.is_my_neighbours
        },
        contact: {
            is_active: data?.is_my_contact
        },
        survey: {
            is_active: data?.is_survey
        },
        walkin: {
            is_active: data?.is_walk_in_track
        },
        parking: {
            is_active: data?.is_parking_alarm_notify
        },
        booking: {
            is_active: data?.is_amenity_booking
        },
        deleivery: {
            is_active: data?.is_delivery_collection
        },
        polls: {
            is_active: data?.is_polls
        },
        templates: data?.templates ?? [],
        deleted_templates: [],
        initial_templates: data?.templates ?? [],
        cpi_percentage: data?.cpi_percentage,
        terms_and_condition: data?.terms_conditions,
        is_visitor_worker_pass_approval: {
            is_active: data?.is_visitor_worker_pass_approval
        },
        is_unit_request_access_approval: {
            is_active: data?.is_unit_request_access_approval
        },
        terms_conditions_docs: data?.terms_conditions_docs ?? [],
        onboard_type: data?.onboard_type ?? [{
            type: "Lease",
            value: { label: "tenant", value: "tenant" },
            app: "tenant"
        }, {
            type: "Manage",
            value: { label: "resident", value: "resident" },
            app: "resident"
        }],
        tax_editable: { is_active: data?.is_taxable },
        error: {
            default_base_currency: "",
            default_uom: "",
            quotation_validity: "",
            billing_payment_peroid: "",
            billing_payment_terms: "",
            billing_cycle_method: "",
            billing_generate_date: "",
            default_payment_gateway: "",
            loginName: "",
            loginPassword: "",
            loginToken: "",
            paymentProviderName: "",
            provider_url: "",
            billing_payment_terms_value: "",
            lease_period: "",
            reservation_feature: "",
            auto_deposite_cal: "",
            auto_reservation_cal: "",
            grace_peroid: "",
            pricing_override: "",
            short_term_retals: "",
            storage_offering: "",
            index_calculation: "",
            refer_win: "",
            professoinal_banners: "",
            default_time_zone: ""
        }
    }
    return result
}

export const returnSaveData = (data) => {
    const deleted_templates = data?.initial_templates?.filter((item) => !data?.templates?.map((x) => x?.value).includes(item?.value))?.map((x) => x?.id)
    const result = {
        "id": data?.setting_id,
        "uom_id": data?.default_uom?.value,
        "quotation_validity": data?.quotation_validity?.value,
        "quotation_validity_type": "Days",
        "billing_payment_period": data?.billing_payment_peroid?.value,
        "billing_cycle_method": data?.billing_cycle_method?.value,
        "billing_generation_date": data?.billing_generate_date?.value,
        "payment_gateway_type": data?.default_payment_gateway?.value,
        "reservation_validity": data?.reservation_feature?.value,
        "reservation_validity_type": "Days",
        "auto_deposit_calc": data?.auto_deposite_cal?.is_active,
        "auto_reservation_calc": data?.auto_reservation_cal?.is_active,
        "grace_period_validity": data?.grace_peroid?.value,
        "grace_period_validity_type": "Days",
        "is_pricing_override": data?.pricing_override?.is_active,
        "is_short_term": data?.short_term_retals?.is_active,
        "is_storage_offering": data?.storage_offering?.is_active,
        "is_refer_win": data?.refer_win?.is_active,
        "is_promotional_banners": data?.professoinal_banners?.is_active,
        "currency_id": data?.default_base_currency?.value?.value,
        "billing_payment_terms": data?.billing_payment_terms?.value,
        "lease_period": data?.lease_period?.value ?? null,
        "login_name": data?.loginName,
        "billing_payment_terms_value": data?.billing_payment_terms_value ?? null,
        "login_password": data?.loginPassword,
        "login_token": data?.loginToken,
        "payment_provider_name": data?.paymentProviderName,
        "payment_provider_url": data?.provider_url,
        "billing_day": data?.billing_generate_date?.value === "Custom Date" ? data?.bill_custom_date : null,
        "timezone_id": data?.default_time_zone?.value?.value ?? null,
        "is_discussion_forum": data?.discussion?.is_active ?? null,
        "is_my_neighbours": data?.neigbours?.is_active ?? null,
        "is_my_contact": data?.contact?.is_active ?? null,
        "is_survey": data?.survey?.is_active ?? null,
        "is_walk_in_track": data?.walkin?.is_active ?? null,
        "is_parking_alarm_notify": data?.parking?.is_active ?? null,
        "is_amenity_booking": data?.booking?.is_active ?? null,
        "is_delivery_collection": data?.deleivery?.is_active ?? null,
        "is_polls": data?.polls?.is_active ?? null,
        "templates": data?.templates?.filter((val) => !val?.id)?.map((x) => {
            return {
                type_master_id: x?.value,
                pdf_type_id: x?.pdf_type_id
            }
        }) ?? [],
        "deleted_templates": deleted_templates?.map((x) => {
            return {
                id: x,
                is_active: false
            }
        }),
        "cpi_percentage": data?.cpi_percentage,
        "is_visitor_worker_pass_approval": Boolean(data?.is_visitor_worker_pass_approval?.is_active),
        "is_unit_request_access_approval": Boolean(data?.is_unit_request_access_approval?.is_active),
        onboard_type: data?.onboard_type ?? [],
        is_taxable: data?.tax_editable?.is_active ?? false
    }
    return result
}