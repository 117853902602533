import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import { AlertDialog, FilterGenerator, LoadingSection, SearchFilter, SelectBox, TableWithPagination, UseDebounce } from "../../../components";
import { tableStyles } from "./style";
import React from "react";
import { userHeading, userPath } from "./utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { AlertProps, NetWorkCallMethods, useWindowDimensions } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { useApolloClient } from "@apollo/client";
import { UPDATE_TEAMS_USER } from "../../../graphql/mutations";
import { Card, Card1 } from "../components/addmembercard";
import FilterIMG from "../../../assets/filter";
export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]
export const UserTable = (props) => {
    const { t } = (props)
    const classes = tableStyles();
    const [open, setOpen] = React.useState(false)
    const size = useWindowDimensions()
    const [loading, setLoading] = React.useState(false)
    const alert = React.useContext(AlertContext);
    const { companyId, teamId } = props
    const [selectedID, setSelectedID] = React.useState([])
    const [user, setUser] = React.useState({
        rights: "",
        data: [],
        view: false,
        edit: false,
        error: {
            data: "",
            rights: ""
        }
    })
    const client = useApolloClient();
    const [limit, setLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState("");
    const [userText, setUserText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [deleteOption, setDeleteOption] = React.useState(true);
    const [tableData, setTableData] = React.useState({
        data: [],
        count: 0
    })
    const debounce = UseDebounce()
    const [offset, setOffset] = React.useState(0)
    const [userDetails, setUserDetails] = React.useState([])
    const [filterData, setFilterData] = React.useState({
        is_active: [true]

    });
    const [fiterDrawer, setFiterDrawer] = React.useState(false)
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    //dialogClose
    const drawerClose = () => {
        setFiterDrawer(false)

    }
    const drawerOpen = () => {
        setFiterDrawer(true)

    }
    //dialogClose

    const dialogClose = () => {
        setUser({
            rights: "",
            data: [],
            view: false,
            edit: false,
            error: {
                data: "",
                rights: ""
            }
        })
        setOpen(false)
        setSelectedID("")
        setOffset(0)
        setUserDetails([])
    }

    const openDialog = () => {
        setOpen(true)
        getUserDropdown(0, "", "search")
    }
    //active changes
    const changeactive = async (data, value) => {
        client
            .mutate({
                mutation: UPDATE_TEAMS_USER,
                variables: {
                    ID: data,
                    updatePayload: {
                        is_active: value === "Inactive" ? true : false,
                    },
                },
            })
            .then((rs) => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Teams User status changed successfully.!!!"),
                });
                getUserList();
            })
            .catch((er) => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong. Internal Error.!!!"),
                });
            });
    };
    const changeSwictherActive = async (data, value) => {
        client
            .mutate({
                mutation: UPDATE_TEAMS_USER,
                variables: {
                    ID: data,
                    updatePayload: {
                        is_team_lead: value === false ? true : false,
                    },
                },
            })
            .then((rs) => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Teams User rights status changed successfully.!!!"),
                });
                getUserList();
            })
            .catch((er) => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong. Internal Error.!!!"),
                });
            });
    };

    //handle icon
    const handleIcon = (type, data, status) => {
        if (type === "active") {
            changeactive(data.team_user_id, status)
        }
        else if (type === "customCheckBox") {
            changeSwictherActive(data.team_user_id, status)
        }
        else if (type === "delete") {
            changeactive(data.team_user_id, data?.is_active)
        }

    }

    const fetchMoreData = () => {
        setOffset(offset + 10);
        getUserDropdown(offset + 10, "", "normal");
    };

    const handleSearching = (e) => {
        setUserText(e)
        debounce(() => getUserDropdown(0, e, "search"), 800)
    }

    //more options
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getUserList(offset, limit, searchText)
    }
    //limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getUserList(0, value, searchText)
    }
    //hanldesearch
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getUserList(0, limit, e)
    }
    //state update
    const updateState = (key, value) => {
        let error = user?.error
        error[key] = ""
        setUser({ ...user, [key]: value, error })
    }
    //validate
    const validate = () => {
        let isValid = true;
        let error = user.error;
        // validate teamname

        if (userDetails?.filter((item) => item?.isSelected === true)?.length === 0) {

            isValid = false;
            error.data = "user is Required";
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Assign user is Required"),
            });
        }
        if (user?.rights?.length === 0) {
            isValid = false;
            error.rights = t("Rights is Required");
        }
        setUser({ ...user, error });

        return isValid;
    }
    //createuser
    const createUser = () => {
        if (validate()) {
            setIsDisableBtn(true)
            const payload = {
                teams_id: teamId,
                user_profiles: userDetails?.length > 0 ? userDetails?.filter((item) => item?.isSelected === true)?.map((val) => val?.user_id) : null,
                company_id: companyId,
                rights: user?.rights?.value ?? null
            }
            NetworkCall(
                `${config.api_url}/hierarchy-team/add_users`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                getUserList()
                dialogClose()
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${user?.id ? t("User Successfully Updated") : t("User Successfully Created")}`,
                });

            }).catch((err) => {
                dialogClose()
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                });
            })
        }



    }

    //table list
    const getUserList = (offset = 0, limit = 10, searchText = "", loading = true) => {
        setLoading(loading)
        let status = filterData?.is_active?.length > 0 ? filterData?.is_active : [true]

        const payload = {
            company_id: companyId,
            search: searchText,
            offset: offset,
            limit: limit,
            teams_id: teamId,
            status: status
        }
        NetworkCall(
            `${config.api_url}/hierarchy-team/get_users_list`,
            NetWorkCallMethods.post,
            payload, null, true, false)
            .then((res) => {
                //table row constructions
                const List = res?.data?.data?.user_list?.map((val, index) => {
                    let _d;
                    try {
                        _d = {
                            index: ((page - 1) * limit) + index + 1,
                            userName: val?.first_name ?? " - ",
                            userNo: val?.user_no ?? " - ",
                            emailId: val?.email_id ?? "-",
                            img: val?.image_url ?? " - ",
                            id: val?.id,
                            phone: `${val?.mobile_no_country_code ?? ""} ${val?.mobile_no ?? "-"}`,
                            rights: val?.rights ?? "-",
                            status: val?.is_active ? "Active" : "Inactive",
                            is_team_lead: val?.is_team_lead,
                            department: val?.department_name ?? "-",
                            ...val

                        };
                    } catch (err) {

                    }
                    return _d;
                });
                setTableData({
                    data: List,
                    count: res?.data?.data?.count
                })
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            })
    }
    //table list
    const getUserDropdown = (offset = 0, searchText = "", k) => {
        const payload = {
            company_id: companyId,
            search: searchText,
            offset: offset,
            limit: 10,
            teams_id: teamId,
        }
        NetworkCall(
            `${config.api_url}/hierarchy-team/get_filter_users`,
            NetWorkCallMethods.post,
            payload, null, true, false)
            .then((res) => {

                let result = res?.data?.data?.user_profiles?.length > 0 ?
                    res?.data?.data?.user_profiles?.map((item) => {
                        return {
                            ...item,
                            isSelected: false
                        }
                    }) : []
                if (k === 'search') {
                    setUserDetails(result)
                }
                else {
                    setUserDetails(userDetails?.concat(result));

                }
            }).catch(err => {
            })
    }
    //initial load
    React.useEffect(() => {
        getUserList(0, limit, "", true)
        // eslint-disable-next-line
    }, [filterData, teamId])

    //rightsOption
    const rightsOption = [
        {
            label: t("Manager"),
            value: "Manager"
        },
        {
            label: t("Member"),
            value: "Member"
        }
    ]

    //select user

    const selectUser = (x) => {
        setUserDetails((prevItems) => {
            return prevItems?.map((item) => {
                if (item?.user_id === x?.user_id) {
                    return { ...item, isSelected: !item.isSelected };
                }
                return item;
            });
        });
    }
    //delete user
    const deleteUser = (data) => {
        let newData = [...new Set(user?.data)]?.filter((val) => val?.user_id !== data?.user_id)
        updateState("data", newData)
    }
    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
    }

    const UserDialog = (props) => {
        return (
            <>
                <Grid container className={classes.customScrollbar}>
                    <Grid item xs={12}>
                        {/* Job */}
                        <SelectBox
                            label={t("Rights")}
                            options={rightsOption}
                            placeholder={t("Select Rights")}
                            value={user?.rights ?? null}
                            onChange={(value) => {
                                updateState("rights", value);
                            }}
                            isError={user?.error?.rights?.length > 0}
                            errorMessage={user?.error?.rights}
                            isRequired
                        />
                    </Grid>
                </Grid>
                <br />
                <div id={'infinity'}>
                    {/* search box */}
                    {
                        // deleteUser === false &&
                        <div
                            onClick={() => setDeleteOption(false)}
                        >
                            <SearchFilter
                                value={userText}
                                handleChange={(value) => handleSearching(value)}
                                placeholder={t("Search User Details")}
                                label={t("Assign User")}
                                customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                            />
                        </div>
                    }
                    {deleteOption ?
                        <div style={{ height: "301px", overflow: "auto" }}>
                            <Card1
                                data={userDetails?.filter(i=>i?.isSelected)}
                                deleteUser={deleteUser}
                            />
                        </div>
                        :
                        <InfiniteScroll
                            dataLength={userDetails?.length ?? null}
                            next={fetchMoreData}
                            hasMore={true}
                            height={size?.height - 600}
                            className={classes.customScrollbar}
                        >
                            <>

                                <Card
                                    data={userDetails}
                                    selectUser={selectUser}
                                    select={selectedID}
                                    selectedData={user?.data}
                                />

                            </>

                        </InfiniteScroll>
                    }

                   


                </div>
                {/* add member button */}
                <Grid container spacing={2} mt={2}>
                   
                    <Grid item xs={6}>
                        {
                            // deleteUser === false && 
                            <Button variant='outlined'
                                fullWidth
                                className={classes.btn3}
                                onClick={dialogClose}
                            > {t("Cancel")}</Button>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {
                            deleteOption === false ?
                                <Button variant='contained'
                                    fullWidth
                                    className={classes.btn2}
                                    onClick={() => setDeleteOption(true)}

                                > {t("Add User")}</Button> :
                                <Button
                                    variant='contained'
                                    fullWidth
                                    className={classes.btn2}
                                    onClick={createUser}
                                    disabled={isDisableBtn}

                                > {t("Add User")}</Button>
                        }
                    </Grid>
                </Grid>

            </>
        )
    }
    return (
        <>
            <Box>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item xs={4}>
                        {/*search field*/}
                        <SearchFilter
                            value={searchText}
                            placeholder={t("Search for users")}
                            handleChange={(value) => handleSearch(value)}
                        />
                    </Grid>

                    <Grid item xs={8} display={'flex'} justifyContent={'end'}>
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "16px" }} />}

                            justifyContent='right'
                        >

                            <IconButton
                                onClick={drawerOpen}
                                className={classes.filterimg}>
                                <Badge variant="dot" color="primary"
                                    invisible={!(filterData?.is_active?.length > 0)}>
                                    <FilterIMG color="#091b29" />
                                </Badge>

                            </IconButton>
                            {props?.permission?.create &&

                                <Button onClick={openDialog} variant="contained" className={classes.btn4} disabled={isDisableBtn}>
                                    {t("Add User")}
                                </Button>
                            }
                        </Stack>


                    </Grid>
                </Grid >
                {loading ? <LoadingSection /> :
                    <TableWithPagination
                        heading={userHeading(t)}
                        rows={tableData?.data}
                        path={userPath}
                        showpagination={true}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        tableType="no-side"
                        count="2"
                        handleIcon={handleIcon}
                        dataType={[
                            { type: ["more_delete"], name: "icon" },
                            { type: ["avatarmanagement"], name: "img" },
                            { type: ["text"], name: "userNo" },
                            { type: ["text"], name: "userName" },
                            { type: ["text"], name: "department" },
                            { type: ["text"], name: "emailId" },
                            { type: ["text"], name: "phone" },
                            { type: ["text"], name: "rights" },
                            { type: ["switcher"], name: "is_team_lead" },

                        ]}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={tableData?.count}
                        page={page}
                        limit={limit}
                        height={`calc(100vh - 396px)`}
                        view={props?.permission?.read}
                        edit={props?.permission?.update}
                        delete={false} />
                }
            </Box >
            <AlertDialog
                open={open}
                isNormal
                scrollHide={true}
                header={t("Add User")}
                onClose={dialogClose}
                component={
                    <Box p={2}>
                        {
                            UserDialog()
                        }
                    </Box>
                }
            />
            <FilterGenerator
                open={fiterDrawer}
                onClose={drawerClose}
                onApply={(value) => onApplyFilter(value)}
                components={[

                    {
                        component: "toggleButton",
                        value: filterData?.is_active,
                        state_name: "is_active",
                        label: t("Status"),
                        options: StatusOptionList,
                        isMulti: true
                    },

                ]} />
        </>
    );
};
