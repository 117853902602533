/* eslint-disable no-unused-vars */
import { Button, Grid, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import useTheme from "@mui/styles/useTheme";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingSection, SearchFilter, Subheader, TableWithPagination } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GET_FILTER_DATA_FOR_PROPERTY_FILTER } from '../../graphql/queries';
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { AlertProps, getCompanyOption, LocalStorageKeys, NetWorkCallMethods, SemiBold } from '../../utils';
import { configurationMasterAction } from '../../utils/configurationMasterAction';
import { returnHeadingType } from '../../utils/configurationMasterData';
import { UploadImageURL } from './imageUploadURL';
import { UploadImage } from './upload';
import { conditions, configurationList, getUrlBasedOnTitle, payloadType } from './utils';

const CustomGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(1),
}))

const CustomTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "fontSize" && prop !== 'mb'
})(({ theme, fontSize = 18, mb = 0 }) => ({

    fontFamily: SemiBold,
    fontSize,
    color: theme.typography.color.primary,
    marginBottom: mb
}))

const CustomBox = styled('div')({
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: 4,
    width: '100%',
    padding: 8
})

const CustomDiv = styled('div')({
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
})

const CustomList = styled(List)(({ theme }) => ({
    height: 'calc(100vh - 213px)',
    overflow: 'auto',
    padding: theme.spacing(1 / 2)
}))

const CustomListItem = styled(ListItem, {
    shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'borderRadius'
})(({ theme, backgroundColor, borderRadius }) => ({
    backgroundColor,
    borderRadius,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    marginTop: theme.spacing(1),
    cursor: 'pointer'
}));

export const ConfigurationMaster = (props) => {

    const theme = useTheme();
    const history = useHistory();
    const auth = React.useContext(AuthContext)
    const backdrop = React.useContext(BackdropContext)
    const [company, setCompany] = useState({
        list: [],
        selected: {}
    })
    const { setBackDrop, open } = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);

    let CompanyBasedType = ['Dashboard Banner', 'Community Banner', 'Service Banner'];

    const [state, setState] = useState({
        configurationList,
        listingSearch: '',
        selectedList: configurationList[0],
        page: 1,
        limit: 10,
        tableSearch: '',
        uploadedImage: {},
        existingImage: {},
    });

    const [data, setData] = useState({
        heading: [],
        type: [],
        row: [],
    })

    const [loading, setLoading] = useState(null);


    const onChangeState = (type = "single", value, key) => {
        if (type === "multiple") {
            setState({ ...state, ...value })
        }
        else {
            setState({
                ...state, [key]: value
            })
        }
    }

    const handleUploadImages = (value) => {
        onChangeState('single', { src: value.src }, 'uploadedImage');
    }

    const handleListFilter = (e) => {
        let copyState = { ...state };
        copyState = {
            listingSearch: e,
            configurationList: configurationList.filter(_ => _?.toLowerCase()?.includes(e?.toLowerCase()))
        }
        onChangeState("multiple", copyState)
    }

    const getCompanyList = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompany({
                selected: company?.selected,
                list: company?.list
            })
        }
    }

    const handleList = (name) => {

        let copyState = { ...state };
        copyState = {
            selectedList: name,
            page: 1,
            limit: 10,
            tableSearch: ''
        }
        onChangeState("multiple", copyState);

        if (checkType(name)) {
            networkCallback(name);
        }
        else {
            networkCallback(name, 'get-banner');
        }

    };

    const handlePagination = (value) => {
        onChangeState("single", value, 'page')
        let offset = (value - 1) * state?.limit;
        networkCallback(state?.selectedList, '', offset, state?.limit)
    }

    const handleChangeLimit = (value) => {

        let copyState = { ...state };
        copyState = {
            page: 1,
            limit: value,
        }

        onChangeState("multiple", copyState)
        networkCallback(state?.selectedList, '', 0, value)
    }

    useEffect(() => {
        setLoading('screen');
        networkCallback(state?.selectedList, 'company-banner/list', 0, 10, 'initial')
        getCompanyList()
        // eslint-disable-next-line
    }, [])

    const getTable = (title, value, count, offset) => {

        let findTitle = checkType(title) ? 'Common Banner' : title
        let construct = configurationMasterAction(findTitle, value, offset);
        setData({
            ...data,
            heading: returnHeadingType[findTitle]?.heading,
            type: returnHeadingType[findTitle]?.type,
            row: {
                data: construct,
                count
            }
        })
        setLoading(null);
    }

    const checkType = (name) => {
        return CompanyBasedType?.includes(name)
    }

    const networkCallback = async (title, url = "company-banner/list", offset = 0, limit = 10, from) => {

        from !== "initial" && setBackDrop({ open: true });

        let extraPayload = {};

        if (checkType(title)) {
            extraPayload = {
                "offset": offset,
                "limit": limit,
                "type": payloadType?.[title]
            };
        }

        else if (url === "get-banner/update") {
            extraPayload = {
                url: {
                    [getUrlBasedOnTitle[title]]: state?.uploadedImage?.src
                }
            }
        }

        let payload = {
            "tenantId": config.tenantId,
            ...extraPayload
        };

        await NetworkCall(
            `${config.api_url}/${url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then(async (response) => {
                let main = response.data.data;
                if (checkType(title)) {
                    getTable(title, main, response.data.totalRecords, offset)
                }
                else if (url === "get-banner/update") {
                    alert.setSnack({
                        open: true,
                        horizontal: AlertProps.horizontal.center,
                        vertical: AlertProps.vertical.top,
                        msg: 'Uploaded successfully',
                        severity: AlertProps.severity.success,
                    });
                    await networkCallback(title, "get-banner")
                }
                else {
                    getUploadData(title, main)
                }
                from !== "initial" && setBackDrop({ open: false });
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
                setLoading(null);
                from !== "initial" && setBackDrop({ open: false });
            });
    }

    const getUploadData = (title, value) => {

        let src = value[getUrlBasedOnTitle[title]];
        let updateState = {
            selectedList: title,
            uploadedImage: { src },
            existingImage: { src },
        }
        onChangeState("multiple", updateState);
        setLoading(null);
    }

    const handleChange = async (type, value, checked) => {

        if (type === "edit" || type === "view") {

            let findTitle = checkType(state?.selectedList) ? 'Company Banner' : state?.selectedList

            history.push({
                pathname: `${Routes.configuration_details?.split('/:')?.[0]}/${value?.id}`,
                state: {
                    edit: type === "edit" ? true : false,
                    condition: findTitle,
                    company: {
                        id: value?.company_id,
                        name: value?.company
                    }
                }
            })
        }
        else if (type === "toggle") {
            //   const c = {
            //     is_active: checked,
            //     updated_at: new Date().toISOString(),
            //     updated_by: localStorage.getItem(LocalStorageKeys.profileID)
            //   }
        }
    };
    //rteurn type
    const returnType = {
        'Dashboard Banner': "Dashboard",
        'Community Banner': "Community",
        'Service Banner': "Services",
    }
    //go create
    const createBanner = () => {
        history.push({
            pathname: Routes.configuration_add,
            state: {
                edit: true,
                title: state?.selectedList,
                company: {
                    name: company?.selected?.label,
                    id: company?.selected?.value,
                    value: company?.selected?.value
                },
                type: returnType[state?.selectedList]
            }
        })
    }

    const checkConditionForUpload = () => {
        return conditions[['Login Banner', 'OTP Banner']?.includes(state?.selectedList) ? 'Client Banner' : state?.selectedList]
    }

    const submit = () => {
        if (state?.uploadedImage?.src) {
            networkCallback(state?.selectedList, 'get-banner/update');
        }
        else {
            alert.setSnack({
                open: true,
                horizontal: AlertProps.horizontal.center,
                vertical: AlertProps.vertical.top,
                msg: 'Please upload an image',
                severity: AlertProps.severity.error,
            });
        }
    }
    //on change company
    const handleCompanyChange = (e) => {
        setCompany({ ...company, selected: e })
    }
    return (
        <div>
            <Subheader title="Configuration"
                options={company?.list}
                select
                value={company?.selected}
                goBack={() => history.push(Routes.dasdboard)}
                onchange={(e) => {
                    handleCompanyChange(e)
                }
                }
            />
            {loading === "screen" ? (
                <LoadingSection
                    bottom={"30vh"}
                    message="Loading Configuration Listing..."
                />
            ) : (
                <Grid container>

                    <CustomGrid item xs={12} sm={5} md={4} lg={3}>
                        <CustomBox>
                            <div style={{ padding: 4 }}>
                                <SearchFilter
                                    value={state?.listingSearch}
                                    handleChange={handleListFilter}
                                />
                            </div>
                            <CustomList>
                                {state?.configurationList?.map((_) => (
                                    <CustomListItem
                                        backgroundColor={_ === state?.selectedList ? theme.palette.secondary?.main : 'transparent'}
                                        borderRadius={_ === state?.selectedList ? 4 : 0}
                                        onClick={() => handleList(_)}
                                    >
                                        <ListItemText
                                            sx={{
                                                color: _ === state?.selectedList ? theme.typography.color.white : theme.typography.color.secondary
                                            }}
                                            primary={_}
                                        />
                                    </CustomListItem>
                                ))}
                            </CustomList>
                        </CustomBox>
                    </CustomGrid>
                    <CustomGrid item xs={12} sm={7} md={8} lg={9}>
                        <CustomBox>
                            <div style={{ padding: 4, height: 'calc(100vh - 165px)', overflow: 'auto' }}>
                                {
                                    checkType(state?.selectedList) ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Stack
                                                    direction="row"
                                                    alignItems={"center"}
                                                    justifyContent={"space-between"}
                                                >
                                                    <CustomTypography>{state?.selectedList}</CustomTypography>
                                                    <Button
                                                        variant={"contained"}
                                                        color={"primary"}
                                                        onClick={createBanner}
                                                    >
                                                        Create Banner
                                                    </Button>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableWithPagination
                                                    heading={data.heading}
                                                    rows={data.row?.data}
                                                    // path={data.path}
                                                    showpagination={true}
                                                    // count="2"
                                                    tableType="no-side"
                                                    handleIcon={handleChange}
                                                    showpdfbtn={false}
                                                    showexcelbtn={false}
                                                    showSearch={false}
                                                    dataType={data.type}
                                                    handlePagination={handlePagination}
                                                    handleChangeLimit={handleChangeLimit}
                                                    totalRowsCount={data?.row?.count}
                                                    page={state?.page}
                                                    limit={state?.limit}
                                                    height={'calc(100vh - 280px)'}
                                                    view={true}
                                                    edit={true}
                                                    delete={true} />
                                            </Grid>
                                        </>

                                    ) : (
                                        <CustomDiv>
                                            <Grid
                                                container
                                                sx={{
                                                    flex: 1,
                                                    alignContent: 'baseline'
                                                }}
                                            >
                                                <CustomGrid item xs={12}>
                                                    <CustomTypography
                                                        fontSize={14}
                                                        mb={8}
                                                    >
                                                        Upload Image
                                                    </CustomTypography>
                                                    <UploadImage
                                                        handleChange={handleUploadImages}
                                                        conditions={checkConditionForUpload()}
                                                        setLoading={(val) => setLoading(val ? 'uploadedImage' : null)}
                                                    />
                                                </CustomGrid>
                                                {
                                                    (loading === "uploadedImage" || state?.uploadedImage?.src) &&
                                                    <CustomGrid item xs={12}>
                                                        <CustomTypography
                                                            fontSize={14}
                                                            mb={8}
                                                        >
                                                            Uploaded Image
                                                        </CustomTypography>
                                                        <UploadImageURL
                                                            data={{
                                                                // src: 'https://protomate.objectstore.e2enetworks.net/156-337x175.png',
                                                                src: state?.uploadedImage?.src,
                                                                // url: _?.url ?? '',
                                                                // name: _?.name,
                                                                // type: _?.type,
                                                                // size: _?.size,
                                                                // id: _?.id

                                                            }}
                                                            loading={loading === "uploadedImage"}
                                                            // handleChange={(value) => onChangeUrl(value, index)}
                                                            showTextField={false}
                                                            handledelete={() => onChangeState('single', {}, 'uploadedImage')}
                                                            // isReadOnly={!state?.isEdit}
                                                            size={checkConditionForUpload()}
                                                        />
                                                    </CustomGrid>}
                                            </Grid>
                                            <Stack alignItems={'flex-end'}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{
                                                        borderRadius: 1
                                                    }}
                                                    disabled={(loading === "submit" || state?.existingImage?.src === state?.uploadedImage?.src)}
                                                    onClick={() => submit()}
                                                >
                                                    {loading === "submit" ? 'Saving...' : 'Save'}
                                                </Button>
                                            </Stack>
                                        </CustomDiv>
                                    )
                                }
                            </div>
                        </CustomBox>
                    </CustomGrid>
                </Grid>
            )}
        </div>
    )
}