import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { TableWithPagination, UseDebounce, SearchFilter } from "../../../components";
import { GET_COMPANY_BANNERS } from "../../../graphql/clientSettingsQueries";
import { LocalStorageKeys } from "../../../utils";
import { logodataType, logoheading, logopath } from "../../../utils/clientsettings";
import { SettingSave } from "../../companyCreation/components";
import { withNamespaces } from "react-i18next";

import Banners from "../components/banners";

const ServiceBanner = ({ t = () => false, selected = {}, type = "" }) => {

    const [open, setOpen] = React.useState(false);
    const client = useApolloClient();
    const [selectedBanner, setSelectedBanner] = React.useState({})
    const [limits, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [bannerListList, setBannerList] = React.useState({
        list: [],
        count: 0
    })
    const [searchText, setSearchText] = React.useState("");

    //onCLick row
    const onClickRow = (data) => {
        setOpen(true)
        setSelectedBanner({
            id: data?.id,
            name: data?.name,
            bid: data?.company_banner?.[0]?.id
        })
    }
    //get banner list
    const getBannerList = (limit, offset, search) => {
        client.query({
            query: GET_COMPANY_BANNERS,
            fetchPolicy: 'network-only',
            variables: {
                limit: limit,
                offset: offset,
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                type: type,
                search: search ?? ""
            },
        }).then((response) => {
            setBannerList({
                list: response.data.company_master?.map((val) => {
                    return ({ ...val, count: val?.company_banner?.[0]?.count?.[0]?.count ?? 0 })
                }),
                count: response.data.count[0]?.count
            })
        }).catch((err) => {
            console.log(err)
        })
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limits;
        getBannerList(limits, offset, "")
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getBannerList(value, 0, "")
    }
    React.useEffect(() => {
        getBannerList(limits, 0, "")
        // eslint-disable-next-line
    }, [])
    //debounce
    const debounce = UseDebounce();
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getBannerList(limits, 0, e)
    }
    //search in table
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    return (
        <Box>
            {/*banner section*/}
            {
                open ? <Banners
                    type={type}
                    title={`${t("Create New")}${type}  ${t("Banner")}`}
                    goBack={() => setOpen(false)}
                    company={selectedBanner}
                    reload={() => getBannerList(10, 0, "")}

                />
                    :
                    <Box>
                        {/*Save section*/}
                        <SettingSave
                            btnName={t("Create Logo & Appearance")}
                            title={selected?.label} subTitle={selected?.subtitle} />
                        <Box padding="8px 12px 0px 12px" width={"330px"}>
                            <SearchFilter value={searchText} placeholder={t("Search Company")} handleChange={(value) => handleSearch(value)} />
                        </Box>
                        <Box padding="8px 12px">
                            {/*table*/}
                            <TableWithPagination
                                heading={logoheading(t)}
                                rows={bannerListList?.list}
                                path={logopath}
                                showpagination={true}
                                onClick={onClickRow}
                                dataType={logodataType}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={bannerListList?.count}
                                page={page}
                                limit={limits}
                                height={'calc(100vh - 351px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                        </Box>
                    </Box>
            }



        </Box>
    )
}
export default withNamespaces("banners")(ServiceBanner)