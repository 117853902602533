/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Box, Button, Grid, List, ListItem, ListItemText, Modal, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SearchFilter, TableWithPagination } from "..";
import MasterUploadModal from ".."
import GenerateForm from  ".."
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import {
  GET_RENTAL_BREAKUP_MASTER_STATE_DATA
} from "../../graphql/propertyMasterQuery";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  AlertProps, LocalStorageKeys, NetWorkCallMethods,
  propertyActions, propertyEditActions, Propertylist, propertyUpdateActions
} from "../../utils";
import { UseDebounce } from '../customHooks';

const useStyles = makeStyles((theme, props) => ({
  root: {
    height: "100%",
  },
  grid: {
    borderRadius: "12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    // height: "100%",
    height: `calc(100vh - 93px)`,
    width: "100%",
    padding: 10,
    margin: 5,
    overflow: "auto",
  },
  button: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: 10,
  },
  gridList: {
    borderRadius: "12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    height: "100%",

    padding: 10,
    margin: 5,
  },
  listText: {
    backgroundColor: "#071741",
    padding: 10,
    borderRadius: 10,
    color: "white",
    opacity: 1,
    cursor: "pointer",
  },
  list: {
    cursor: "pointer",
  },
  close: {
    position: "fixed",
    right: "310px",
    top: 10,
    backgroundColor: "white",
    padding: 5,
    display: "flex",
    borderRadius: 5,
    cursor: "pointer",
  },
}));

export const PropertyMaster = ({
  t
}) => {
  const { setBackDrop, open: loading } = React.useContext(BackdropContext);
  const classes = useStyles();
  const backdrop = React.useContext(BackdropContext);
  const [open, setOpen] = useState(false);
  const [readOnly, setRead] = useState(false);
  const search = useLocation().search;
  const initialTitle = new URLSearchParams(search).get("title");
  const [title, setTitle] = useState(initialTitle ?? "Property Type");
  const history = useHistory();
  const [main, setMain] = useState({
    isD: false,
    Main: ""
  });
  const [edit, isEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [listVal, setListVal] = useState("")
  const [listTab, setTab] = useState("")
  const [list, setList] = useState(Propertylist)
  const [uploadModal, setUploadModal] = useState(false);
  const [disable, setDiable] = useState(false);
  const [drop, setDrop] = useState({
    property: [],
    block: [],
    floor: []
  })
  const [data, setData] = useState({
    heading: "",
    row: "",
    path: "",
    type: "",
    drawer: "",
  });

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  //debounce hook
  const debounce = UseDebounce();


  const alert = React.useContext(AlertContext);

  const getTable = (name, offset = 0, limit = 10, search = "") => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const jsonData = propertyActions(name, offset, limit, search);
    const payload = {
      query: jsonData.type,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        const wData = jsonData.resfunc(main);
        setData({
          heading: jsonData.tbdata.heading,
          row: wData,
          path: jsonData.tbdata.path,
          type: jsonData.tbdata.type,
          drawer: jsonData.tbdata.drawer,
          totalRowsCount: response?.data?.data?.count?.[0]?.count
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      });
  };

  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getTable(title, offset, limit, listTab)
  }

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getTable(title, 0, value, listTab)
  }

  const updateTablefunc = (name, data) => {
    setDiable(true)

    data.created_at = new Date().toISOString();
    data.created_by = localStorage.getItem(LocalStorageKeys.profileID);
    data.updated_at = new Date().toISOString();
    data.updated_by = localStorage.getItem(LocalStorageKeys.profileID);

    const jsonData = propertyUpdateActions[name];
    const payload = {
      query: jsonData.type,
      variables: {
        insert: [{ ...data, client: localStorage.getItem(LocalStorageKeys.clinetID) }],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDiable(false)
        if (page > 1) {
          setPage(1);
        }
        getTable(title, 0, limit);
        setTab("")
        setOpen(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Created Successfully!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      })
      .catch((error) => {
        setDiable(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Not Created!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })

      });
  };

  const editTablefunc = (name, data, id) => {
    setDiable(true)
    data.updated_at = new Date().toISOString();
    data.updated_by = localStorage.getItem(LocalStorageKeys.profileID);

    const jsonData = propertyEditActions[name];
    const payload = {
      query: jsonData.type,
      variables: {
        [jsonData.key]: id ? id : editId,
        demo: [data],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDiable(false)
        let main = response.data.data;
        // searchTableFunction(listTab);
        let offset = (page - 1) * limit;
        getTable(title, offset, limit, listTab)
        setOpen(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Edited Successfully!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      })
      .catch((error) => {
        setDiable(false)

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Not Edited!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      });
  }

  useEffect(() => {
    commonFunc(initialTitle ?? "Property Type")
    // eslint-disable-next-line
  }, [initialTitle]);

  const handleDrawer = async () => {
    // setOpen(true);
    setRead(false);
    isEdit(false)

    const newDrawer = [...data.drawer]?.map((v) => {
      if (v.state_name === "value") {
        v.value = {
          currency: "",
          currency_code: "$",
        };
      } else {
        v.value = "";
      }
      return v
    });
    setData({
      ...data,
      drawer: newDrawer
    })

    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteOpen = (type, value) => {
    setMain({
      isD: true,
      Main: value
    })
  }

  const isDelete = () => {

  }

  const handleChange = async (type, value, checked) => {
    if (type === "edit") {
      // setOpen(true);
      setRead(false);
      isEdit(true);
      setEditId(value.id);
      setBackDrop({
        open: true,
        message: "Fetching data",
      });
      // let newDrawer = [...data.drawer];
      const newDrawer = data?.drawer?.map(async (val) => {
        Object.keys(value)?.map((v) => {
          if (val.state_name === "value") {
            val.value.currency = value["value"];
            val.value.currency_code = value["currency"];
          }
          else {
            if (val.state_name === v) {
              val.value = value[val.state_name];
            }
          }
        });
        if (val?.state_name === "unit_name" && val?.value) {
          //get property,block,floor data
          const payload = {
            query: GET_RENTAL_BREAKUP_MASTER_STATE_DATA(val?.value).loc.source.body,
          };
          await NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          )
            .then((response) => {
              let result = response.data.data.unit_rental_breakup?.[0];
              data.drawer.find(_ => _.state_name === "property_name").value = result?.unit?.floor?.block?.property;
              data.drawer.find(_ => _.state_name === "block_name").value = result?.unit?.floor?.block;
              data.drawer.find(_ => _.state_name === "floor_name").value = result?.unit?.floor;
              val.value = {
                label: result?.unit?.label,
                value: result?.unit?.value
              };
            }).catch((error) => {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            });
        }
        return val
      });
      let DrawerResults = await Promise.all(newDrawer);
      setData({
        ...data,
        drawer: DrawerResults
      })
      setBackDrop({
        open: false,
        message: "",
      });
      setOpen(true);

    }
    if (type === "view") {
      setOpen(true);
      setRead(true);
      let newDrawer = [...data.drawer];
      newDrawer.map((val) => {
        if (val.state_name === "value") {
          val.value.currency = value["value"];
          val.value.currency_code = value["currency"];
        } else {
          Object.keys(value).map((k) => {
            if (val.state_name === k) {
              val.value = value[val.state_name];
            }
          });
        }
      });
    }
    if (type === "toggle") {
      const c = {
        is_active: checked,
        updated_at: new Date().toISOString(),
        updated_by: localStorage.getItem(LocalStorageKeys.profileID),

      }
      editTablefunc(title, c, value.id)
    }
    if (type === "delete") {
      onDeleteOpen(type, value)
    }
  };

  const handleList = (name, index) => {
    history.push(Routes.propertyMaster + "?title=" + name);
  };

  const commonFunc = (name) => {
    setTitle(name);
    setPage(1);
    setLimit(10);
    setTab('');
    getTable(name);
  }

  const saveFunction = (datas, title) => {
    setBackDrop({
      open: true,
      message: "Saving data",
    });
    if (title === "Property Type") {
      const res = {};
      if (datas.company_name.value) {
        res.company_id = datas.company_name.value
      }
      else {
        var newDrawer = [...data.drawer];
        newDrawer.map((keys) => {
          if (keys.state_name === "property_name") {
            keys.options.map(m => {
              if (m.label === datas.company_name) {
                res.company_id = m.value
              }
            })
          }
        })
      }
      // res.company_id = data.company_name.value ? data.company_name.value : data.company_name;
      res.is_active = datas.is_active ? datas.is_active : false;
      res.group_name = datas.group_name;
      res.description = datas.description;
      res.settings = datas?.settings?.value;
      edit ? editTablefunc(title, res) : updateTablefunc(title, res);
    }
    if (title === "Pricing Component") {
      const returnData = (val) => typeof val === "boolean" ? val : val === "Yes" ? true : false;
      let rental = {};
      rental.name = datas.name;
      rental.description = datas.description;
      rental.is_active = returnData(datas?.is_active);
      rental.primary = returnData(datas?.primary);
      rental.refundable = returnData(datas?.refundable);
      rental.taxtable = returnData(datas?.taxtable);
      rental.is_onetime = returnData(datas?.is_onetime);
      rental.is_reserve_quality = returnData(datas?.is_reserve_quality);
      if (datas.payment_period.value) {
        rental.payment_period = datas.payment_period.value
      }
      else {
        var newDrawer = [...data.drawer];
        newDrawer.map((keys) => {
          if (keys.state_name === "payment") {
            keys.options.map(m => {
              if (m.label === datas.payment_period) {
                rental.payment_period = m.value
              }
            })
          }
        })
      }
      // rental.payment_period = data.payment.value ? data.payment.value : data.payment;
      edit ? editTablefunc(title, rental) : updateTablefunc(title, rental);
    }
    if (title === "Block Type") {
      const block = {};
      if (datas.company_name.value) {
        block.company_id = datas.company_name.value
      }
      else {
        var newDrawer = [...data.drawer];
        newDrawer.map((keys) => {
          if (keys.state_name === "property_name") {
            keys.options.map(m => {
              if (m.label === datas.company_name) {
                block.company_id = m.value
              }
            })
          }
        })
      }
      block.name = datas.name;
      block.settings = datas?.settings?.value;
      block.is_active = datas.is_active ? datas.is_active : false;
      edit ? editTablefunc(title, block) : updateTablefunc(title, block);
    }
    if (title === "Unit Purpose") {
      const block = {};
      block.type = datas.type;
      block.is_active = datas.is_active ? datas.is_active : false;
      edit ? editTablefunc(title, block) : updateTablefunc(title, block);
    }
    if (title === "Unit Type") {
      const block = {};
      if (datas.company_name.value) {
        block.company_id = datas.company_name.value
      }
      else {
        var newDrawer = [...data.drawer];
        newDrawer.map((keys) => {
          if (keys.state_name === "property_name") {
            keys.options.map(m => {
              if (m.label === datas.company_name) {
                block.company_id = m.value
              }
            })
          }
        })
      }
      block.name = datas.name;
      block.settings = datas?.settings?.value;
      block.beds = datas?.beds;
      block.baths = datas?.baths;
      block.configured_units = datas?.configured_units;
      block.purpose = datas?.purpose?.value;
      block.is_active = datas.is_active ? datas.is_active : false;
      edit ? editTablefunc(title, block) : updateTablefunc(title, block);
    }
    if (title === "Ameneties") {
      const block = {};
      if (datas.company_name.value) {
        block.company_id = datas.company_name.value
      }
      else {
        var newDrawer = [...data.drawer];
        newDrawer.map((keys) => {
          if (keys.state_name === "property_name") {
            keys.options.map(m => {
              if (m.label === datas.company_name) {
                block.company_id = m.value
              }
            })
          }
        })
      }
      block.amenities_name = datas.name;
      block.settings = datas?.settings?.value;
      block.description = datas.description;
      block.notes = datas.notes;
      block.is_active = datas.is_active ? datas.is_active : false;
      edit ? editTablefunc(title, block) : updateTablefunc(title, block);
    }
    if (title === "Unit Rental Breakup") {
      const unit = {}
      if (datas?.unit_name?.value) {
        unit.unit_id = datas.unit_name.value
      }
      if (datas?.tax_group?.value) {
        unit.vat_group_id = datas.tax_group.value
      }
      if (datas?.component_type?.value) {
        unit.rental_breakup_id = datas?.component_type?.value
      }
      // if(datas?.value.currency_code?.id){
      unit.currency_id = datas?.value.currency_code?.value ? datas?.value.currency_code?.value : 29
      // }
      if (datas?.value_type?.value) {
        unit.value_basis_type = datas.value_type?.value
      }
      unit.period_type = datas.period_type
      unit.value = datas.value.currency
      unit.display_percentage = datas.display
      unit.is_active = datas.is_active ? datas.is_active : false
      edit ? editTablefunc(title, unit) : updateTablefunc(title, unit);
    }
    setBackDrop({
      open: false,
      message: "",
    });
  };

  const handleListFilter = (e) => {
    setListVal(e)
    setList(Propertylist.filter(_ => _.name.toLowerCase().includes(e?.toLowerCase())))
  }

  const handleTab = (e) => {
    setTab(e)
    debounce(() => searchTableFunction(e), 800)
  }

  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getTable(title, 0, limit, e)
  }

  const handleUploadModal = (val, type) => {
    // selected tab master name
    if (type === "refresh") {
      getTable(title);
      setUploadModal(!uploadModal)
    } else {
      setUploadModal(!uploadModal)
    }
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: 2,
  };


  return (
    <div className={classes.root}>
      <Grid constainer spacing={2} direction="row" display="flex">
        <Grid item xs={3} className={classes.gridList}>
          <SearchFilter
            value={listVal}
            handleChange={handleListFilter}
          />
          <div
            style={{
              height: `calc(100vh - 156px)`,
              overflow: "auto",
              marginTop: "4px",
            }}
          >
            <List>
              {list.map((l, index) => (
                <ListItem
                  className={l?.name === title ? classes.listText : classes.list}
                  onClick={() => handleList(l.name, index)}
                >
                  <ListItemText primary={l.name} />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid
          item
          xs={9}
          className={classes.grid}
          justifyContent="space-between"
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xs={6}>
              <SearchFilter
                value={listTab}
                handleChange={handleTab}
              //  placeholder={'Search Group Name'}
              />
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Button
                color="primary"
                variant={"outlined"}
                onClick={handleUploadModal}
                style={{ marginRight: 12 }}
              >
                {t("Bulk Upload")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleDrawer}
              >
                {t("Add new")}
              </Button>
            </Grid>
          </div>
          {data.row && (
            <Grid item xs={12} className={classes.table}>
              <TableWithPagination
                heading={data.heading}
                rows={data.row}
                path={data.path}
                showpagination={true}
                count="2"
                handleIcon={handleChange}
                onClick={() => false}
                // tableType = "normal"
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                dataType={data.type}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={data?.totalRowsCount}
                page={page}
                limit={limit}
                height={'calc(100vh - 221px)'}
                view={true}
                edit={true}
                delete={true} />
            </Grid>
          )}
        </Grid>
      </Grid>


      {data?.drawer && (
        <GenerateForm
          parent_id={"PropertyMasterDrawer"}
          nocancel
          loading={disable}
          open={open}
          readOnly={readOnly}
          save={saveFunction}
          handlecancel={handleClose}
          // changeDrop={changeDrop}
          header={title}
          json={data?.drawer}

        />
      )}

      {/* Upload Modal */}
      {uploadModal &&
        <MasterUploadModal
          open={uploadModal}
          closeModal={handleUploadModal}
          docsType={title}
        />
      }

      {main.isD && (
        <Modal
          open={main.isD}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("Are you sure you want to Delete!")}
            </Typography>
            <div className={classes.button}>
              <Button
                variant="contained"
                size="medium"
                style={{ backgroundColor: "#FF0000", width: "144px" }}
              >
                {t("Yes")}
              </Button>
              <Button
                variant="contained"
                size="medium"
                style={{ backgroundColor: "#4E9F3D", width: "144px" }}
                onClick={() => setMain({ isD: false, Main: '' })}
              >
                {t("No")}
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};
