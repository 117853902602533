import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AlertContext } from '../../contexts';

import { AlertProps, Regular, SemiBold, toEnglishNumber } from '../../utils';

import { useStyles } from "./styles";

const StyledToggleButton = styled(ToggleButton)(({ theme,height="48px !important" }) => ({
    borderRadius: theme.palette.borderRadius,
    width: "max-content",
    fontSize:"0.875rem",
    height: height,
    fontFamily: Regular,
    textTransform: 'capitalize',
    whiteSpace: "nowrap",
    backgroundColor: "white",
    color: "#4E5A6B",
    '&.Mui-selected': {
        fontFamily: SemiBold,
        backgroundColor: theme.palette.primary.main,
        borderColor: `${theme.palette.primary.main} !important`,

        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    '&.MuiToggleButtonGroup-grouped': {
        '&:not(:last-of-type)': {
            borderRadius: theme.palette.borderRadius,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.palette.borderRadius,
            marginLeft: 0,
            borderColor: '#E4E8EE'
        },
    }
}))



const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        paddingRight: 0,
        '& input': {
            fontSize:"0.875rem",
            height: "18px",
            fontFamily: Regular,
            padding: '0px',
            width: 60,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            // borderRadius: theme.palette.borderRadius,
            border: 0
        }
    }
})

const IconWrapper = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: 22,
    height: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
    }
}))

const CustomDiv = styled(Button, {
    shouldForwardProp: prop => prop !== "selected"
})(({ theme, selected }) => ({
    border: `1px solid ${selected ? theme.palette.primary.main : '#E4E8EE'}`,
    backgroundColor: selected ? theme.palette.primary.main : '#fff',
    color: selected ? '#fff' : '#4E5A6B',
    borderRadius: '8px',
    padding: "10px",
    height: 38,
    fontSize:"0.875rem",
    fontFamily: Regular,
    fontWeight: 500,
    '&:hover': {
        backgroundColor: selected ? theme.palette.primary.main : '#fff',
        color: selected ? '#fff' : '#4E5A6B',
    }
}))

export const ToggleButtonComponent = ({
    value = "", // 'string' => single selection ['string1','string2'] for multiple selection
    isMulti = false,
    isReadOnly = false,
    onChange = null,
    buttonGroupStyle = {},
    buttonStyle = {},
    options = [],
    color = "primary",
    fullWidth = true,
    custom = false,
    isError = false,
    errorMessage = "",
    valueType = "string",
    isrequired = false,
    label = null,
    gap = 2,
    height = "",
    marginInlineEnd = "",
    width = "auto",
    t = () => false,
    ...props
}) => {


    const [customValue, setCustomValue] = useState('');
    const [toggle, setToggle] = useState(false);
    const [selectedCustomValue, setSelectedCustomValue] = useState(false);
    const alert = useContext(AlertContext);
    const classes = useStyles(props);

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
        flexWrap: "wrap",
        gap: gap
    })

    const onRemove = (e) => {
        e.stopPropagation();
        setToggle(false);
        setCustomValue('');
        setSelectedCustomValue(false)
        if (isMulti) {
            onChange(value?.filter(_ => _ !== customValue));
        }
        else {
            onChange(null);
        }
    }

    const onChangeState = () => {

        const formatType = (val) => valueType === "string" ? val?.toLowerCase() : Number(val);

        if (options?.some(_ => formatType(_?.value) === formatType(customValue))) {
            alert.setSnack({
                open: true,
                horizontal: AlertProps.horizontal.center,
                vertical: AlertProps.vertical.top,
                msg: `Entered value is already there`,
                severity: AlertProps.severity.error,
            });
        }
        else {
            if (isMulti) {
                let newValue = value?.length ? value : []
                onChange([...newValue, formatType(customValue)])
            }
            else {
                onChange(formatType(customValue));
            }
            setToggle(false);
            setSelectedCustomValue(true);
        }
    }

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            onChangeState()
        }
    }

    const returnValue = useMemo(() => {
        if (custom) {
            if (isMulti) {
                let modifyValue = value?.length ? value : [];
                return modifyValue?.filter(_ => options?.some(option => option?.value === _));
            }
            else {
                return value
            }
        }
        return value
        // eslint-disable-next-line
    }, [value])

    useEffect(() => {
        //only excutes if custom prop is true
        if (custom) {

            if (isMulti) {
                let newValue = value;
                let modifyValue = newValue?.length ? value : [];
                newValue = modifyValue?.filter(_ => !options.some(option => option.value === _) && _);
                if (newValue?.[0] && customValue !== newValue?.[0]) {
                    setCustomValue(newValue?.[0] ?? '');
                    setSelectedCustomValue(true)
                }
            }
            else {
                if (options?.some(_ => _.value === value)) {
                    toggle && setToggle(false);
                    selectedCustomValue && setSelectedCustomValue(false)
                    customValue && setCustomValue('')
                }
                else if (value) {
                    setCustomValue(value);
                    setSelectedCustomValue(true)
                }
            }
        }
        // eslint-disable-next-line
    }, [value])

    const onChangeToggleButton = (value) => {
        if (custom && isMulti && customValue) {
            onChange([...value, customValue])
        }
        else {
            onChange(value)
        }
    }

    const inputType = valueType === "string" ? "text" : "number"

    const getLabel = () => {
        return (
            <Typography className={classes.label} noWrap>
                {label}
                {isrequired ?
                    <Typography variant="caption" className={classes.required}>*</Typography> :
                    <Typography variant="caption" className={classes.required}></Typography>}
            </Typography>
        )
    }

    return (
        <div style={{ width: width ?? '100%' }}>
            {label && getLabel()}
            <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"10px"}
                flexWrap={"wrap"}
            >

                <StyledToggleButtonGroup
                    value={returnValue}
                    exclusive={!isMulti}
                    onChange={(e, newValue) => {
                        if (isMulti) {
                            (newValue?.length > 0) && onChangeToggleButton(newValue)
                        } else {
                            (newValue != null) && onChangeToggleButton(newValue)
                        }                        
                    }}
                    aria-label="toggle button"
                    sx={{ ...buttonGroupStyle }}
                    fullWidth={fullWidth}
                    disabled={isReadOnly}
                >
                    {options?.length && options?.map(_ => (
                        <StyledToggleButton
                        height={height}
                            value={_?.value}
                            aria-label={_?.value}
                            key={_?.value}
                            color={color}
                            sx={{ ...buttonStyle, height: height ?? "35px", marginInlineEnd: marginInlineEnd ?? "" }}



                        >
                            {_?.label}
                        </StyledToggleButton>
                    ))
                    }
                </StyledToggleButtonGroup>
                {
                    custom &&
                    <CustomDiv
                        disableFocusRipple
                        onClick={() => (!customValue && !toggle) && setToggle(true)}
                        selected={selectedCustomValue}
                    >
                        {
                            toggle ? (
                                <CustomTextField
                                    autoComplete={'off'}
                                    autoFocus
                                    value={customValue}

                                    onChange={e => {
                                        if (inputType === "number") {
                                            let value = toEnglishNumber(e.target.value)
                                            setCustomValue(value)
                                        } else {
                                            setCustomValue(e.target.value)

                                        }
                                    }}


                                    onKeyDown={onKeyDown}
                                    type={inputType}
                                    onKeyPress={(e) => {
                                        if (inputType === "number") {
                                            if (['e', 'E']?.includes(e.key)) {
                                                e.preventDefault();
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconWrapper onClick={() => onChangeState()}>
                                                    <DoneIcon htmlColor={"#fff"} sx={{ fontSize:"0.75rem" }} />
                                                </IconWrapper>
                                            </InputAdornment>
                                        ),
                                    }}
                                />) : (
                                <>
                                    {(valueType === "string" ? customValue?.length > 0 : (typeof customValue === "number")) ? (
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            gap={"10px"}
                                        >
                                            {customValue}
                                            <div style={{ display: "flex" }} onClick={onRemove}>
                                                <CloseIcon sx={{ fontSize:"1.125rem" }} />
                                            </div>
                                        </Stack>
                                    ) : "Custom"}
                                </>
                            )
                        }
                    </CustomDiv>
                }
            </Stack>
            {isError && (
                <Typography variant={"caption"} color={"error"}>
                    {errorMessage}
                </Typography>
            )}
        </div>
    )
}


ToggleButton.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string, //single selection
        PropTypes.array //multiple selection
    ]),
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        })
    ),
    color: PropTypes.oneOf(['standard', 'primary', 'secondary', 'error', 'info', 'success', 'warning']),
    valueType: PropTypes.oneOf(['number', 'string']), //it works only custom prop is true
    inputType: PropTypes.oneOf(['number', 'text']), //it works only custom prop is true
    fullWidth: PropTypes.bool,
    custom: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
}