// eslint-disable-next-line
import { useApolloClient } from "@apollo/client";
import { Grid } from "@mui/material";
import moment from "moment-timezone";
import React, { useState } from 'react';
import { ActiveLeadComponent, BookingComponent, NewAgreementComponent, OppertunitiesComponent } from "../../assets/leasemanagerdashboard";
import { Subheader } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { CITY_SELECT_BY_UNIT, getUnits, GET_ACCESS_CONTROL, GET_AGREEMENT_DETAILS, GET_PROPERTY_GROUP_PURPOSE, GET_PROPERTY_TYPE_UNIT, GET_REVENUE_TYPE, PAY_MENT_TOTAL } from "../../graphql/dashboardQuery";
import { AlertProps, enumSelect, enum_types, getCompanyOption, getTotal, keyMapSource, LocalStorageKeys } from '../../utils';
import { ActiveCard } from "../dashboard/components";
import { CardDetails } from './card';
import { GraphComponent } from './graphComponent';
import { dashboardStyles } from "./style";


const tabList = [
      {
            name: 'Property',
            id: 0,
      },
      {
            name: 'Property type',
            id: 1,
      }
]
const tabList1 = [
      {
            name: 'Unit Category',
            id: 0,
      },
      {
            name: 'Unit Type',
            id: 1,
      },
]
// lastThirtyDates
var lastThirtyDates = new Date();
lastThirtyDates.setDate(lastThirtyDates.getDate() - 30);

// lastSixtyDates
var lastSixtyDates = new Date();
lastSixtyDates.setDate(lastSixtyDates.getDate() - 90);

// lastNigntyDates
var lastNigthyDates = new Date();
lastNigthyDates.setDate(lastNigthyDates.getDate() - 90);


export const LeaseManagerDashboard = (props) => {
      const auth = React.useContext(AuthContext);
      // company options
      const [companyList, setCompanyList] = React.useState([]);

      const classes = dashboardStyles(props);
      // enum states
      const [enumValue, setEnumValue] = useState({
            cityEnum: [],
            propertyEnum: [],
            unitEnum: [],
            unitStatus: {},
            revenueEnum: []
      })

      const [propertyID, setPropertyID] = useState([])
      // context

      const backdrop = React.useContext(BackdropContext);
      const alert = React.useContext(AlertContext);

      // apolo clint

      const client = useApolloClient();
      // initial
      const initial = {
            city: null,
            statistic: 'Property',
            statistic1: 'Unit Category',
            propertyType: null,
            unitType: null,
            revenueType: null
      }
      //state

      const [state, setState] = useState({ ...initial })

      const [unitCity, setUnitCity] = useState({
            graphData1: {}
      })

      const [propertyType, setPropertyType] = useState({})

      const [unitcatagory, setCatagory] = useState({})

      const [agreement, setAgreement] = useState([])

      const [revenue, setRevenue] = useState([])

      const [payment, setPayment] = useState([])



      const [selectedCompany, setSelectedCompany] = useState(null)


      // select box function

      const handleList = (key, e) => {
            setState({ ...state, [key]: e })

            if (e === 'Unit Category' || e === 'Unit Type' || e === 'Property type') {
                  getEnumValue(selectedCompany?.value, e, propertyID, true, key)
            }
            if (key === 'city') {
                  getUnitByCity(selectedCompany?.value, e?.value, enumValue?.unitStatus)
            }
            if (key === 'propertyType') {
                  getPropertyType(e?.value, enumValue?.unitStatus, selectedCompany?.value)
            }
            if (key === 'unitType') {
                  getUnityByType(state?.statistic1 === 'Unit Type' ? "unit_type" : "unit_category",
                        state?.statistic1 === 'Unit Type' ? e?.value : e?.value,
                        enumValue?.unitStatus, selectedCompany?.value)
            }

            if (key === 'revenueType') {
                  getRevenue(e?.value, enumValue?.unitStatus, selectedCompany?.value)
            }

      }


      // // get data function

      // const getData = () => {
      //       SocketSlug.addEventListener('message', function (event) {
      //             let tabelData = JSON.parse(event?.data)
      //             if (tabelData?.table === 'property' || tabelData?.table === 'unit' || tabelData?.table === 'lead' || tabelData?.table === 'proxy_lead' || tabelData?.table === 'agreement') {
      //                   getEnumValue(selectedCompany?.value, "", propertyID, true)
      //             }
      //       });
      // }

      // get Enum

      const getEnumValue = async (company, key, data, city, tabkey) => {

            const result = await enumSelect([enum_types?.unit_category, enum_types?.unit_status_type, enum_types?.revenue_type, enum_types?.agreement_status, enum_types?.unit_vacancy_status])

            const filterData = await keyMapSource({ enumType: result?.unit_status_type })
            const agreementData = await keyMapSource({ enumType: result?.agreement_status })
            const princingstatus = await keyMapSource({ enumType: result?.unit_vacancy_status })


            client.query({
                  query: GET_PROPERTY_GROUP_PURPOSE(),
                  fetchPolicy: 'network-only',
                  variables: {
                        property_id: data ?? [],
                        company_id: company
                  }

            }).then((res) => {

                  setEnumValue({
                        ...enumValue, propertyEnum: res?.data?.property_group_master, unitEnum: key === 'Unit Type' ? res?.data?.unit_type_master : result?.unit_category, cityEnum: res?.data?.dashboard_property_unit_stats_by_city, unitStatus: filterData,
                        revenueEnum: result?.revenue_type
                  })


                  setState({
                        ...state,
                        city: res?.data?.dashboard_property_unit_stats_by_city?.[1] ?? null,
                        propertyType: res?.data?.property_group_master?.[0] ?? null,
                        unitType: key === 'Unit Type' ? res?.data?.unit_type_master?.[0] : result?.unit_category?.[0],
                        [tabkey]: key,
                        revenueType: result?.revenue_type?.[0]

                  })

                  if (city) {

                        getUnitByCity(company, state?.city?.value, filterData, res?.data?.dashboard_property_unit_stats_by_city?.[1]?.value ?? null)

                        getPropertyType(res?.data?.property_group_master?.[0]?.value, filterData, company)

                        getUnityByType(key === 'Unit Type' ? "unit_type" : "unit_category",
                              key === 'Unit Type' ? res?.data?.unit_type_master?.[0]?.value : result?.unit_category?.[0]?.value,
                              filterData, company)

                        getAgreementDetails(agreementData, company, data)

                        getRevenue(result?.revenue_type?.[0]?.value, filterData, company)

                        getPayment(company, princingstatus)
                  }


            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }

      // get unit by city

      const getUnitByCity = (company, city, unitStatus, cityType) => {
            let jsonData = {
                  city: city ?? cityType,
                  Vacant: unitStatus?.type1 ?? "",
                  Reserved: unitStatus?.type2 ?? "",
                  Occupied: unitStatus?.type3 ?? "",
                  Lost: "Lost Lead",
                  Converted: "Converted",
                  company: company,
                  clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? ""
            }

            client.query({
                  query: CITY_SELECT_BY_UNIT,
                  fetchPolicy: 'network-only',
                  variables: jsonData
            }).then((res) => {
                  setUnitCity({ ...unitCity, graphData1: res?.data })
            }).catch((err) => {

                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }

      // GETaccess control

      const getProperty = (e) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            client.query({
                  query: GET_ACCESS_CONTROL,
                  fetchPolicy: 'network-only',
                  variables: {
                        companyID: e,
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID),
                        // roleID: localStorage.getItem(LocalStorageKeys.roleID)
                  }
            }).then((res) => {
                  setPropertyID(res?.data?.access_control?.map((x) => x?.property_id) ?? [])
                  getEnumValue(e, state?.statistic1, res?.data?.access_control?.map((x) => x?.property_id), true)
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            }).catch((err) => {
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "Loading",
                  });
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }


      // get unit by property type

      const getPropertyType = (groupname, unitStatus, company) => {
            client.query({
                  query: GET_PROPERTY_TYPE_UNIT,
                  fetchPolicy: 'network-only',
                  variables: {
                        groupname: groupname,
                        Vacant: unitStatus?.type1 ?? "",
                        Reserved: unitStatus?.type2 ?? "",
                        Occupied: unitStatus?.type3 ?? "",
                        company: company,
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? ""
                  }
            }).then((res) => {
                  setPropertyType(res?.data)
            }).catch((err) => {

                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }


      // get unit by catogory and unit type
      const getUnityByType = (type, value, unitStatus, company_id) => {
            client.query({
                  query: getUnits(type),
                  fetchPolicy: 'network-only',
                  variables: {
                        Vacant: unitStatus?.type1 ?? "",
                        Reserved: unitStatus?.type2 ?? "",
                        Occupied: unitStatus?.type3 ?? "",
                        unit_catagory: value ?? null,
                        unit_type: value ?? null,
                        company_id: company_id,
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? ""
                  }
            }).then((res) => {
                  setCatagory(res?.data)
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }


      // get unit by catogory and unit type
      const getAgreementDetails = (type, company, data) => {
            client.query({
                  query: GET_AGREEMENT_DETAILS,
                  fetchPolicy: 'network-only',
                  variables: {
                        created_at_end: `${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`,
                        created_at_start: `${moment(lastThirtyDates).format("YYYY-MM-DD")} 00:00:00`,
                        terminated: type?.type6,
                        company: company,
                        property_id: data ?? [],
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID)
                  }
            }).then((res) => {
                  setAgreement(res?.data)
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }

      // get payment
      const getPayment = (company, princingstatus) => {

            client.query({
                  query: PAY_MENT_TOTAL,
                  fetchPolicy: 'network-only',
                  variables: {
                        created_at_end_thirty: `${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`,
                        created_at_start_thirty: `${moment(lastThirtyDates).format("YYYY-MM-DD")} 00:00:00`,

                        created_at_end_sixty: `${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`,
                        created_at_start_sixty: `${moment(lastSixtyDates).format("YYYY-MM-DD")} 00:00:00`,

                        created_at_end_nignty: `${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`,
                        created_at_start_nignty: `${moment(lastNigthyDates).format("YYYY-MM-DD")} 00:00:00`,
                        company: company,
                        Booked: princingstatus?.type3,
                        Reserved: princingstatus?.type4,
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? ""
                  }
            }).then((res) => {
                  setPayment(res?.data)
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }

      // get revenue
      const getRevenue = (revenue_type, unitStatus, company) => {
            client.query({
                  query: GET_REVENUE_TYPE,
                  fetchPolicy: 'network-only',
                  variables: {
                        Vacant: unitStatus?.type1 ?? "",
                        Reserved: unitStatus?.type2 ?? "",
                        Occupied: unitStatus?.type3 ?? "",
                        revenue_type: revenue_type,
                        company: company,
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID)
                  }
            }).then((res) => {
                  setRevenue(res?.data)
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }

      // card data 1

      const TopCarddata = [
            {
                  id: 1,
                  name: "Active Leads",
                  count: unitCity?.graphData1?.total_lead?.[0]?.count_id,
                  image: <ActiveLeadComponent />,
                  color: "#46B9DA",
                  more: true,
                  additionalCount_1: unitCity?.graphData1?.qualified_lead?.[0]?.count_id,
                  additionalTitle_1: "Qualified Leads",
                  additionalCount_2: unitCity?.graphData1?.lost_lead?.[0]?.count_id,
                  additionalTitle_2: "Disqualified Leads"
            },
            {
                  id: 2,
                  name: "Active Opportunities",
                  count: 12,
                  image: <OppertunitiesComponent />,
                  color: "#E29336",
                  more: true,
                  additionalCount_1: 45,
                  additionalTitle_1: "Won Opportunities",
                  additionalCount_2: 80,
                  additionalTitle_2: "Lost Opportunities"
            },
            {
                  id: 3,
                  name: "New Agreements",
                  count: agreement?.totalagreemet?.[0]?.count_id,
                  image: <NewAgreementComponent />,
                  color: "#E29336",
                  more: true,
                  additionalCount_1: agreement?.renewel?.[0]?.count_id,
                  additionalTitle_1: "Renewals",
                  additionalCount_2: agreement?.Termination?.[0]?.count_id,
                  additionalTitle_2: "Terminations"
            },
      ];

      // graph data 1

      const graphData = [
            {
                  name: 'Vacant Units',
                  fill: '#58D0E0',
                  value: unitCity?.graphData1?.vacant?.[0]?.count_id
            },
            {
                  name: 'Occupied Units',
                  fill: '#FFED4B',
                  value: unitCity?.graphData1?.Occupied?.[0]?.count_id
            },
            {
                  name: 'Reserved Units',
                  fill: '#FF4B4B',
                  value: unitCity?.graphData1?.Reserved?.[0]?.count_id
            },
            {
                  name: 'Inactive Units',
                  fill: '#5AC782',
                  value: unitCity?.graphData1?.inActive?.[0]?.count_id
            },
      ]

      const graphData1 = [
            {
                  name: 'Vacant Units',
                  fill: '#58D0E0',
                  value: 12
            },
            {
                  name: 'Occupied Units',
                  fill: '#FFED4B',
                  value: 151
            },
            {
                  name: 'Reserved Units',
                  fill: '#FF4B4B',
                  value: 1
            },
            {
                  name: 'Inactive Units',
                  fill: '#5AC782',
                  value: 15
            },
      ]

      const graphData2 = [
            {
                  name: 'Vacant Units',
                  fill: '#58D0E0',
                  value: propertyType?.vacant?.[0]?.count_id
            },
            {
                  name: 'Occupied Units',
                  fill: '#FFED4B',
                  value: propertyType?.Occupied?.[0]?.count_id
            },
            {
                  name: 'Reserved Units',
                  fill: '#FF4B4B',
                  value: propertyType?.Reserved?.[0]?.count_id
            },
            {
                  name: 'Inactive Units',
                  fill: '#5AC782',
                  value: propertyType?.inActive?.[0]?.count_id
            },
      ]

      const graphData3 = [
            {
                  name: 'Vacant Units',
                  fill: '#58D0E0',
                  value: unitcatagory?.vacant?.[0]?.count_id
            },
            {
                  name: 'Occupied Units',
                  fill: '#FFED4B',
                  value: unitcatagory?.Occupied?.[0]?.count_id
            },
            {
                  name: 'Reserved Units',
                  fill: '#FF4B4B',
                  value: unitcatagory?.Reserved?.[0]?.count_id
            },
            {
                  name: 'Inactive Units',
                  fill: '#5AC782',
                  value: unitcatagory?.inActive?.[0]?.count_id
            },
      ]

      const TopCarddata_1 = [

            {
                  id: 3,
                  name: "Active Bookings",
                  count: payment?.booked?.[0]?.count ?? 0,
                  image: <BookingComponent />,
                  color: "#E29336",
                  // link: "/invoiceScreen",



            },
            {
                  id: 4,
                  name: "Active Reservations",
                  count: payment?.reservation?.[0]?.count ?? 0,
                  image: 'images/resevation.svg',
                  color: "#E29336",
                  image_type: true
                  // link: "/invoiceScreen",



            },

      ];

      const graphData4 = [
            {
                  name: 'Vacant Units',
                  fill: '#58D0E0',
                  value: revenue?.vacant?.[0]?.count_id
            },
            {
                  name: 'Occupied Units',
                  fill: '#FFED4B',
                  value: revenue?.Occupied?.[0]?.count_id
            },
            {
                  name: 'Reserved Units',
                  fill: '#FF4B4B',
                  value: revenue?.Reserved?.[0]?.count_id
            },
            {
                  name: 'Inactive Units',
                  fill: '#5AC782',
                  value: revenue?.inActive?.[0]?.count_id
            },
      ]


      React.useEffect(() => {
            let company = getCompanyOption(backdrop, auth, alert)
            if (company) {
                  setCompanyList(company?.list)
                  setSelectedCompany(company?.selected)
                  if (company?.list?.length > 0) {
                        getProperty(company?.selected?.value)
                  }
            }
            // eslint-disable-next-line
      }, [auth])


      const cardData = [
            {
                  count: payment?.thirty?.[0]?.count_id,
                  image: 'images/expiry.svg',
                  sub: 'Agreement Expiring in Days 30',
                  image_type: true

            },
            {
                  count: payment?.sixty?.[0]?.count_id,
                  image: 'images/expiry.svg',
                  sub: 'Agreement Expiring in Days 60',
                  image_type: true

            },
            {
                  count: payment?.nignty?.[0]?.count_id,
                  image: 'images/expiry.svg',
                  sub: 'Agreement Expiring in Days  90',
                  image_type: true

            },
            {
                  count: '72',
                  image: 'images/revenue.svg',
                  sub: 'Revenue by Invoice Type',
                  image_type: true

            },
            {
                  count: agreement?.payment?.[0]?.total_count ?? 0,
                  image: 'images/unpostedinvoice.svg',
                  sub: 'Unposted Invoice',
                  image_type: true

            },
      ]
      const handleCompanyChange = (e) => {
            setSelectedCompany(e)
            getProperty(e?.value)
      }

      return (
            <div>
                  <Subheader title="Lease Manager" select options={companyList} value={selectedCompany} hideBackButton={true}
                        onchange={(e) => {
                              handleCompanyChange(e)
                        }

                        } />
                  <div className={classes.root}>
                        {/* first section */}

                        <Grid container spacing={2} >
                              {/* first half */}
                              <Grid item xs={12} md={12} lg={6}>
                                    <Grid container spacing={1.5}>
                                          <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                      {TopCarddata.map((item) => {
                                                            return (
                                                                  <Grid item xs={6}>
                                                                        <ActiveCard data={item} height={"164px"} />
                                                                  </Grid>
                                                            );
                                                      })}
                                                      <Grid item xs={6}>
                                                            <Grid container spacing={1}>
                                                                  {TopCarddata_1.map((item) => {
                                                                        return (
                                                                              <Grid item xs={12}>
                                                                                    <ActiveCard data={item} />
                                                                              </Grid>
                                                                        );
                                                                  })}
                                                            </Grid>
                                                      </Grid>
                                                </Grid>
                                          </Grid>

                                    </Grid>

                              </Grid>
                              {/* graph component 1*/}
                              <Grid item xs={12} md={12} lg={6}>
                                    <GraphComponent tabNameState={state?.statistic ?? ""} keyType='statistic' dumydata={graphData} title={'Statistics by City'} data={state} graphData={graphData} handleList={handleList} isSelect option={enumValue?.cityEnum ?? []} dropDownKey='city' dropDownState={state?.city} total={getTotal(graphData) ?? 0} paddingTop={10} />
                              </Grid>

                        </Grid>

                        {/* second */}
                        <Grid container spacing={3} className={classes.section}>
                              <Grid item xs={12} md={12} lg={6}>
                                    {/* graph component 2*/}
                                    <GraphComponent tabNameState={state?.statistic ?? ""} keyType='statistic' dumydata={graphData1} title={'Occupancy Trends by property by last 5 years'} data={state} graphData={graphData1} tabList={tabList} handleList={handleList} total={getTotal(graphData1) ?? 0}
                                          paddingTop={20} />
                              </Grid>
                              <Grid item xs={12} md={12} lg={6}>
                                    {/* graph component 3*/}
                                    <GraphComponent tabNameState={state?.statistic ?? ""} keyType='statistic' dumydata={graphData2} title={'Statistics'} data={state} graphData={graphData2} tabList={tabList} handleList={handleList} isTab isSelect={state?.statistic === 'Property type' ? true : false} option={enumValue?.propertyEnum ?? []} dropDownKey='propertyType' dropDownState={state?.propertyType} total={getTotal(graphData2) ?? 0} paddingTop={20} />
                              </Grid>

                        </Grid>

                        {/* third */}
                        <Grid container spacing={3} className={classes.section}>
                              <Grid item xs={12} md={12} lg={6}>
                                    {/* graph component 4*/}
                                    <GraphComponent tabNameState={state?.statistic1 ?? ""} keyType='statistic1' dumydata={graphData3} title={'Statistics'} data={state} graphData={graphData3} tabList={tabList1} handleList={handleList} isTab isSelect option={enumValue?.unitEnum ?? []} dropDownKey='unitType' dropDownState={state?.unitType} total={getTotal(graphData3) ?? 0} paddingTop={20} />
                              </Grid>
                              <Grid item xs={12} md={12} lg={6}>
                                    {/* graph component 5*/}
                                    <GraphComponent tabNameState={state?.statistic ?? ""} keyType='statistic' dumydata={graphData4} title={'Statistics by Revenue Type'} data={state} graphData={graphData4} tabList={tabList} handleList={handleList} total={getTotal(graphData4) ?? 0} isSelect option={enumValue?.revenueEnum ?? []} dropDownKey='revenueType' dropDownState={state?.revenueType} paddingTop={10} />
                              </Grid>

                        </Grid>

                        {/* four */}
                        {/* third */}
                        <Grid container spacing={3} className={classes.section}>

                              {
                                    cardData?.map((val) => {
                                          return (
                                                <Grid item xs={6} md={6} lg={2}>
                                                      {/* details catd */}
                                                      <CardDetails data={val} />
                                                </Grid>
                                          )
                                    })
                              }


                        </Grid>
                  </div>
            </div>
      )
}