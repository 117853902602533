import gql from "graphql-tag";

export const GET_ROLES = (offset, limit, searchText) => gql`
query GET_ROLES{
      count: roles(
        where:{
          name:{iregex:"${searchText}"}
          is_active:{eq:true}

        }
      ) {
        count:count_id
      }

         roles(
          where: { is_active: { eq: true }
           name:{iregex:"${searchText}"}
        }
           offset:${offset}
           limit:${limit}
          ) {
        value: id
        label: name

      }
    }`;

export const GET_COMPANY = (offset, limit, searchText, client) => gql`
query GET_COMPANY{
      count: company_master(
        where:{
          name:{iregex:"${searchText}"}
          is_active:{eq:true}
          client:"${client}"
        }
      ) {
        count:count_id
      }

      company_master(
          where: { is_active: { eq: true }
           name:{iregex:"${searchText}"}
           client:"${client}"
        }
           offset:${offset}
           limit:${limit}
          ) {
        value: id
        label: name

      }

      all_company_master:company_master(
        where: { is_active: { eq: true }
         client:"${client}"
      }
        ) {
      value: id
      label: name

    }
    }`;

export const GET_PROPERTY = `
query GET_PROPERTY($search:String , $client:String , $company_id:[Int]){
      count: property(
        where:{
          name:{iregex:$search}
          is_active:{eq:true}
          client:$client
          company_id:{in:$company_id}
        }
      ) {
        count:count_id
      }

      property(
        where:{
          name:{iregex:$search}
          is_active:{eq:true}
          client:$client
          company_id:{in:$company_id}
        }
        offset:$offset
        limit:$limit
          ) {
        value: id
        label: name

      }
    }`;

    export const GET_PROPERTY_SELECT_ALL = `
query GET_PROPERTY($search:String , $client:String ){
      count: property(
        where:{
          name:{iregex:$search}
          is_active:{eq:true}
          client:$client
        }
      ) {
        count:count_id
      }

      property(
        where:{
          name:{iregex:$search}
          is_active:{eq:true}
          client:$client
        }
        offset:$offset
        limit:$limit
          ) {
        value: id
        label: name

      }
    }`;

export const GET_ROLES_BY_ID = (offset, limit, searchText, client, id) => gql`
    query GET_ROLES_BY_ID{
          count: client_roles(
            where:{
              name:{iregex:"${searchText}"}
              is_active:{eq:true}
              client:"${client}"
              is_delete:{eq:false}
              module_id:${id}
            }
            offset:${offset}
            limit:${limit}
          ) {
            count:count_id
          }

          client_roles(
              where: { is_active: { eq: true }
               name:{iregex:"${searchText}"}
               client:"${client}"
               is_delete:{eq:false}
               module_id:${id}
            }
               offset:${offset}
               limit:${limit}
              ) {
            value: id
            label: name

          }
        }`;


export const CHECK_PERMISSION =(searchText)=> gql`
query CHECK_PERMISSION{
  permissions(
          where: { is_delete: { eq: false }
           name:{eq:"${searchText}"}
        }
          ) {
      id
      }
    }`;




export const CHECK_ROLES =(searchText)=>gql`
query CHECK_ROLES{
  client_roles(
          where: { is_delete: { eq: false }
          name:{eq:"${searchText}"}
        }
          ) {
      id
      }
    }`;



export const CHECK_PERMISSION_ROLES = (id) => gql`
query CHECK_PERMISSION_ROLES {
  client_role_permissions(
    where: {
      is_active: { eq: true }
      permission_id: { eq: "${id}" }
    }
  ) {
    id
    client_roles(where: { is_delete: false }) {
      id
    }
  }
}
`;

export const CHECK_PERMISSION_PERMISSION = (id) => gql`
query CHECK_PERMISSION_PERMISSION {
  client_user_role_access(
    where: {
      is_delete: { eq: false }
      client_role_id: { eq: "${id}" }
    }
  ){
    id
    client_role{
      id
    }
  }
}
`;