/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Tab,
} from "@mui/material";
import { ScreensStylesParent } from "./style";
import { Search, PermissionCard, TextBox } from "../components";
import { TreeComponent } from "../components";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { config } from "../../../config";
import { CustomSelect } from "../components/filterGenerator/components/select";

// alert message context
import { AlertContext, BackdropContext } from "../../../contexts";
import { AlertProps, useWindowDimensions, NetWorkCallMethods, } from "../../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { CHECK_PERMISSION, CHECK_PERMISSION_ROLES } from '../queries'
import { UseDebounce } from "../../../components"
import { NetworkCall } from '../../../networkcall';
import moment from "moment";
import { TabContext, TabList, TabPanel } from "@mui/lab";



// add role
const addRoles = {
  Permission: "",
  des: "",
  id: "",
  module: "",
  isEdit: false,
  error: {
    Permission: "",
    des: "",
    id: "",
    module: ""
  },
};

export const PermisionList = (props) => {
  const { t } = props
  const classes = ScreensStylesParent();
  const size = useWindowDimensions();
  // state
  const [value, setValue] = React.useState("1");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addRole, setaddRole] = useState({ ...addRoles });
  const [permissionID, setPermissionID] = useState({
    id: "",
    data: "",
    structure: [],
  });
  const [commonPermissionID, setCommonPermissionID] = useState({
    id: "",
    data: "",
    structure: [],
  });
  const [permissions, setPermissions] = useState([]);
  const [commonPermissions, setCommonPermissions] = useState([]);
  const [permissionSearch, setPermissionSearch] = useState("");
  const [commonPermissionSearch, setCommonPermissionSearch] = useState("");
  const [disable, setDisable] = useState(false);
  const [repository, setRepository] = useState([]);
  const [toggledisable, setToggleDisable] = useState(true);
  const [offset, setOffset] = useState(0);
  const [checkState, setCheckState] = React.useState(false)
  // context
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);

  const debounce = UseDebounce()


  // dialog close function
  const handleClose = (e) => {
    setDialogOpen(!dialogOpen);
    if (e === "close") {
      setaddRole({ ...addRoles });
    }
  };

  // onChange role
  const roleAddedFunction = (key, value) => {
    if (key === 'module') {
      getRepository(value?.value);
    }
    let error = addRole?.error;
    error[key] = "";
    setaddRole({ ...addRole, [key]: value, error });
  };
  //   add role validation
  const validations = () => {
    let isValid = true;
    let error = addRole.error;
    if (addRole?.Permission?.length === 0) {
      isValid = false;
      error.Permission = t("Name is Required");
    }
    if (addRole?.module?.length === 0) {
      isValid = false;
      error.module = t("Module is Required");
    }
    setaddRole({ ...addRole, error });

    return isValid;
  };

  // add role
  const addRoleSave = () => {
    if (checkState) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Permission Already Exists"),
      });
    }
    if (validations()) {
      const data = {
        name: addRole?.Permission,
        description: addRole?.des,
        module_id: addRole?.module?.value
      };
      if (addRole?.isEdit) {
        data["id"] = addRole?.id;
      } else {
        data["structure"] = repository ?? [];
        data["is_active"] = true;
      }
      axios
        .post(
          `${config?.api_url}/iam/${addRole?.isEdit ? "update_permission" : "create_permission"
          }`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then((res) => {
          getpermission(permissionSearch, offset);
          handleClose();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${addRole?.isEdit ? t("Permission Updated successfully") : t("Permission created successfully")
              }.`,
          });
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something Went Wrong."),
          });
        });
    }
  };

  // click checkbox
  const handleChange = (e) => {
    setPermissionID({ ...permissionID, structure: e });

  };

  // select permission
  const seclectPermission = (e) => {
    setToggleDisable(true);
    setPermissionID({
      id: e?.id,
      data: e,
      structure: e?.structure,
    });
    setToggleDisable(false);
  };

  // select common permission
  const seclectCommonPermission = (e) => {
    setCommonPermissionID({
      id: e?.id,
      data: e,
      structure: e?.structure,
    });
    setToggleDisable(false);
  };

  // edit permission
  const editFunction = (data) => {
    setaddRole({
      ...addRole,
      Permission: data?.name,
      des: data?.description,
      id: data?.id,
      isEdit: true,
      module: data?.module
    });
    handleClose();
  };

  // acive permission
  const activePermission = (e, index) => {
    updateFunction("is_active", e);
  };

  // search list
  const searchPermission = (e) => {
    setPermissionSearch(e);
    getpermission(e, 0);
  };

  const searchCommonPermission = (e) => {
    setCommonPermissionSearch(e);
  };
  //handleTabChange
  const handleTabChange = (newValue) => {
    setToggleDisable(true)
    setPermissionID({})
    setCommonPermissionID({})
    setValue(newValue)
  }

  //   get permission
  const getpermission = (searchvalue, offset, key, initial) => {
    if (initial) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading",
      });
    }
    axios
      .get(
        `${config.api_url}/iam/get_permissions${searchvalue?.length > 0 ? `?search=${searchvalue}&` : ""
        }?offset=${offset}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((res) => {
        if (initial) {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: t("Loading"),
          });
        }
        if (key === "scroll") {
          setPermissions(permissions?.concat(res?.data?.data));
        } else {
          setPermissions(res?.data?.data ?? []);
          setPermissionID({
            id: res?.data?.data?.[0]?.id,
            data: res?.data?.data?.[0],
            structure: res?.data?.data?.[0]?.structure,
          });
          setToggleDisable(false);
        }
      })
      .catch((err) => {
        if (initial) {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "Loading",
          });
        }
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
      });
  };
  //   get commonpermission
  const getCommonPermission = (initial) => {
    if (initial) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: t("Loading"),
      });
    }
    const data = {
      module_id: props?.moduleOption?.map((i) => i.value)
    }
    axios
      .post(
        `${config?.api_url}/iam/get_common_permissions`, data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((res) => {
        if (initial) {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: t("Loading"),
          });
        }
        let results = res?.data?.data?.map((i, index) => {
          return {
            id: index,
            ...i
          }
        })
        setCommonPermissions(results ?? []);
        setCommonPermissionID({
          id: results?.[0]?.id,
          data: results?.[0],
          structure: results?.[0]?.structure,
        });
        setToggleDisable(false);
      })
      .catch((err) => {
        if (initial) {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "Loading",
          });
        }
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
      });
  };

  //   get repository
  const getRepository = (e) => {
    setRepository([])
    axios
      .get(
        `${config?.api_url}/iam/get_repository?module_id=${e ?? 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((res) => {
        setRepository(res?.data?.data);
      })
      .catch((err) => { });
  };
  // update JSON
  const updateFunction = (key, value) => {
    setDisable(true);
    const data = {
      id: permissionID?.id,
    };
    if (key === "structure") {
      data["structure"] = permissionID?.structure ?? [];
    } else if (key === "is_active") {
      data["is_active"] = value ?? true;
    } else {
      data["is_delete"] = true;
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: t("Deleting"),
      });
    }
    axios
      .post(`${config?.api_url}/iam/update_permission`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        setDisable(false);
        if (key === "is_active" || key === "is_delete") {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        }
        getpermission("", 0, "", false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Permission Updated successfully."),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        setDisable(false);
      });
  };
  //copyPermissionFunction
  const copyPermissionFunction = (permission_id, client_role_id, commonPermission) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("copying"),
    });
    setDisable(true);
    let data;
    if (commonPermission) {
      data = {
        structure: permission_id?.structure,
        module_id: permission_id?.module_id,
        permission_name: permission_id?.name,
        common_permission: true
      }
    } else {
      data = {
        "permission_id": permission_id,
        "client_role_id": client_role_id,
        common_permission: false
      };
    }
    axios
      .post(`${config?.api_url}/iam/copy_permission`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        setDisable(false);
        getpermission("", 0, "", false);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Permission copied successfully."),
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
        setDisable(false);
      });
  };
  // cancel function
  const cancelFunction = () => {
    setPermissionID({
      id: "",
      data: "",
      structure: [],
    });
    setToggleDisable(false);
  };

  const checkPermission = (id) => {
    const payload = {
      query: CHECK_PERMISSION_ROLES(id).loc.source.body
    }
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      if (res?.data?.data?.client_role_permissions?.map((x) => x?.client_roles).filter(Boolean)?.length > 0) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Permission cannot be deleted as its used in roles"),
        });
      }
      else {
        updateFunction("is_delete");

      }
    })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
      });
  }

  // const deletePermission
  const deletePermission = () => {
    checkPermission(permissionID?.id)
  };

  // infinity scroll
  const fetchMoreData = () => {
    setOffset(offset + 10);
    getpermission(permissionSearch, offset + 10, "scroll", false);
  };

  const check = (data) => {
    const payload = {
      query: data?.query
    }
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      if (res?.data?.data?.permissions?.length > 0) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: `${data?.type} ${t("Already Exists")}`,
        });
        setCheckState(true)
      }
      else {
        setCheckState(false)
      }
    })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
        setCheckState(false)

      });
  }

  const checkpermission = (e) => {
    debounce(() => check({
      type: "Permission",
      query: CHECK_PERMISSION(e?.target?.value).loc.source.body,
    }), 800)
    roleAddedFunction("Permission", e?.target?.value)
  }

  const isNewPermission = (date) => {
    const formatDate = moment(date).format("YYYY-MM-DD")
    const givenDate = new Date(formatDate); // example given date
    const currentDate = new Date(); // current date

    const givenDateUTC = Date.UTC(givenDate.getUTCFullYear(), givenDate.getUTCMonth(), givenDate.getUTCDate()); // given date in UTC
    const currentDateUTC = Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate()); // current date in UTC

    const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000; // number of milliseconds in 7 days

    if (currentDateUTC - givenDateUTC <= sevenDaysInMs) {
      return true
    } else {
      return false
    }

  }

  useEffect(() => {
    if (value === "2") {
      getpermission("", 0, "", true);
    } else {
      getCommonPermission(true);
    }
    // eslint-disable-next-line
  }, [value]);
  const tabTitle = [
    {
      title: t("System Permissions"),
      id: "1",
    },
    {
      title: t("Custom Permissions"),
      id: "2",
    },

  ];
  return (
    <div>
      <Grid container>
        <Grid item sm={6} md={4} lg={4}>
          <TabContext value={value}>
            {/* tab title */}
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                className={classes.tab}
                onChange={(event, newValue) => handleTabChange(newValue)}
                aria-label="lab API tabs example"
              >
                {tabTitle?.map((x) => {
                  return <Tab label={x?.title} value={x?.id} className={classes.tabs} />;
                })}
              </TabList>
            </Box>
            {/* tab body */}

            <TabPanel value="1" className={classes.tab}>
              <div className={classes.section}>
                <Search
                  title={`${commonPermissions?.length} ${t("Permissions")}`}
                  handleAdd={() => handleClose("close")}
                  searchFumction={searchCommonPermission}
                  search={commonPermissionSearch}
                  t={t}
                />
              </div>
            </TabPanel>
            <TabPanel value="2" className={classes.tab}>
              <div className={classes.section}>
                <Search
                  title={`${permissions?.length} ${t("Permissions")}`}
                  handleAdd={() => handleClose("close")}
                  searchFumction={searchPermission}
                  search={permissionSearch}
                />
              </div>
            </TabPanel>
            {/* permission list */}
            <Box>
              {/* permission card */}
              {value === "2" ?
                <InfiniteScroll
                  dataLength={permissions?.length ?? ""}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 350}
                >
                  <div className={classes.section1}>
                    {permissions?.map((x, index) => {
                      return (
                        <PermissionCard
                          title={x?.name}
                          data={x}
                          value={false}
                          seclectPermission={seclectPermission}
                          permissionID={permissionID?.id}
                          id={x?.id}
                          editFunction={editFunction}
                          activePermission={activePermission}
                          index={index}
                          deletePermission={deletePermission}
                          disabled={toggledisable}
                          copyPermissionFunction={copyPermissionFunction}
                          copycard={true}
                          // isNew={isNewPermission(x?.updated_at)}
                          isNew={false}
                          t={t}

                        />
                      );
                    })}
                  </div>
                </InfiniteScroll> :
                <InfiniteScroll
                  dataLength={commonPermissions?.length ?? ""}
                  next={false}
                  hasMore={true}
                  height={size?.height - 350}
                >
                  <div className={classes.section1}>
                    {commonPermissions?.filter((movie) => {
                      if (commonPermissionSearch === "") {
                        return movie;
                      } else if (
                        movie?.name?.toLowerCase()?.includes(commonPermissionSearch?.toLowerCase())
                      ) {
                        return movie;
                      }
                    })?.map((x, index) => {
                      return (
                        <PermissionCard
                          title={x?.name}
                          data={x}
                          value={false}
                          seclectPermission={seclectCommonPermission}
                          permissionID={commonPermissionID?.id}
                          id={x.id}
                          editFunction={false}
                          activePermission={false}
                          index={index}
                          deletePermission={false}
                          disabled={toggledisable}
                          copyPermissionFunction={copyPermissionFunction}
                          copycard={true}
                          // isNew={false}
                          isNew={isNewPermission(x?.updated_at)}

                          commonPermission={true}
                          t={t}
                        />
                      );
                    })}
                  </div>
                </InfiniteScroll>}
            </Box>

          </TabContext>
        </Grid>

        <Grid item sm={6} md={8} lg={8}>
          <div className={classes.section} style={{ borderLeft: '1px solid #E0E0E0', overFlow: "auto" }}>
            <Typography className={classes.screenTitle} >
              {toggledisable ? t("Permission") : t("Edit Permission")}
            </Typography>
          </div>

          {!toggledisable &&
            <Box style={{ overflow: "auto" }}>
              <div className={classes.permissionsection} style={{ borderLeft: '1px solid #E0E0E0' }}>
                <div className={classes.addRoleButton}>
                  <Typography className={classes.screenRepository}>
                    {t("Repository")}
                  </Typography>
                  <div style={{ display: "flex" }}>
                    {[t("Create"), t("Read"), t("Update"), t("Delete")]?.map((x) => {
                      return (
                        <div className={classes?.checkBoxDesign}>
                          <Typography className={classes.screenRepository}>
                            {x}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <Box className={classes.borderLeft}>
                {value === "2" ?
                  <div className={classes.permissionfixedStyle} style={{ height: (!commonPermissionID?.id && permissionID?.id?.length > 0) ? `calc(100vh - 376px)` : `calc(100vh - 296px)` }}>
                    <div className={classes.section1}>
                      <TreeComponent
                        TreeJson={permissionID?.structure ?? []}
                        handleChange={handleChange}
                        previousCheckbox
                        isCheckbox
                        disabled={toggledisable}
                      />
                    </div>
                  </div> :
                  <div className={classes.permissionfixedStyle} style={{ height: (!commonPermissionID?.id && permissionID?.id?.length > 0) ? `calc(100vh - 376px)` : `calc(100vh - 296px)` }}>
                    <div className={classes.section1}>
                      <TreeComponent
                        TreeJson={commonPermissionID?.structure ?? []}
                        handleChange={handleChange}
                        previousCheckbox
                        isCheckbox
                        disabled={toggledisable}
                        commonPermission={true}
                      />
                    </div>
                  </div>
                }

              </Box>
            </Box>

          }

          {(!commonPermissionID?.id && permissionID?.id?.length > 0 && !toggledisable) && (
            <Box className={classes.bottomButton}>
              <Button
                className={classes.cancelBtn}
                variant="outlined"
                onClick={cancelFunction}
              >
                {t("Cancel")}
              </Button>
              <Button
                className={classes.saveBtn}
                variant="contained"
                onClick={() => updateFunction("structure")}
                disabled={disable}
              >
                {t("Update")}
              </Button>
            </Box>
          )}
        </Grid>

      </Grid>

      {/* add pop up */}
      <Dialog
        className={classes.dialog}
        open={dialogOpen}
        onClose={() => handleClose("close")}
        maxWidth="xs"
      >
        <DialogTitle className={classes.header}>
          <span>{addRole?.isEdit ? "Edit" : "Add New"} {t("Permission")}</span>{" "}
          <CloseIcon
            onClick={() => handleClose("close")}
            style={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogParent}>
            <CustomSelect
              marginBottom={4}
              color={"#98A0AC"}
              fontSize={12}
              required
              fontWeight={400}
              label={t("Module")}
              placeholder={t("Select Module")}
              options={props?.moduleOption ?? []}
              onChange={(e) => roleAddedFunction("module", e)}
              value={addRole?.module}
              loading={props?.loading === "module"}
              isPaginate
              debounceTimeout={800}
              loadOptions={props?.loadOptions}
              error={addRole?.error?.module?.length > 0}
              errorText={addRole?.error?.module}
              isReadOnly={addRole?.isEdit ? true : false}
            />
            <Box style={{ height: "10px" }} />
            <TextBox
              placeholder={t("Permission Name")}
              value={addRole?.Permission}
              onChange={(e) =>
                checkpermission(e)
              }
              label={t("Permission Name")}
              isrequired
              isError={addRole?.error?.Permission?.length > 0}
              errorMessage={addRole?.error?.Permission}
            />
            <Box style={{ height: "10px" }} />
            <TextBox
              placeholder={t("Description")}
              multiline={true}
              value={addRole?.des}
              onChange={(e) => roleAddedFunction("des", e?.target?.value)}
              label={t("Description")}
            />
            <Box style={{ height: "10px" }} />
            <Box className={classes.addRoleButton}>
              <Box>
                <Button
                  className={classes.cancelBtn}
                  onClick={() => handleClose("close")}
                >
                  {t("Cancel")}
                </Button>
              </Box>
              <Box>
                <Button className={classes.saveBtn} onClick={addRoleSave}>
                  {addRole?.isEdit ? t("Update") : t("Save")}
                </Button>
              </Box>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
