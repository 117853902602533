import gql from "graphql-tag";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { LocalStorageKeys, NetWorkCallMethods } from "../../../utils";

export const listErrorState = [
    {
        profileDetails: [
            { key: "companyCode" },
            { key: "companyName" },
            { key: "companyCountry" },
        ],
        address: [
            // { key: 'addressLineOne' },
            // { key: 'addressLineTwo' },
            // { key: 'landmark' },
            // { key: 'area' },
            // { key: 'city' },
            // { key: 'state' },
            // { key: 'country' },
            // { key: 'pincode' },
            { key: 'latitude' },
            { key: 'longitude' },
        ],
        contactInformation: [
            // { key: 'primaryContact', component: 'mobile_number', },
            // { key: 'title' },
            // { key: 'primaryMobile', component: 'mobile_number', },
            // { key: 'primaryEmailAddress', component: "email",errorMsg:"Please enter valid emailID" },
            // { key: 'website' },
        ]
    },
    {
        initialConfig: [
            { key: "currency" },
            { key: "uom" },
            { key: "referAndWin", component: "boolean" },
            { key: "gracePeriod", component: "integer" },
        ],
        pricing: [
            // { key: 'pricingMethod' },
            { key: 'paymentPeriod' },
            { key: 'leasePeriod' },
            { key: 'priceOverride', component: "boolean" },
            { key: 'shortTermPricing', component: "boolean" },
        ],
        paymentGatewayConfiguration: [
            { key: 'paymentGateway' },
        ]
    },
    {
        companyEstateRegistration: [
            { key: "companyRegistrationType" },
            { key: "companyRegistrationNumber" },
            { key: "realEstateRegulatoryAuthority" },
            { key: "RERARegistrationNumber" },
            { key: "RERABrokerRegistrationNumber" },
            { key: "realEstateAdvertisingPermit", component: "boolean" },
            { key: "advertisingPermitNumber", dependency: { key: "realEstateAdvertisingPermit", component: "boolean" } },
        ],
        tax: [
            { key: 'taxGroup' },
            { key: 'taxCode' },
            { key: 'taxRegistrationNumber' },
        ],
    },
    {
        bankingBasicDetails: [
            { key: "bankName" },
            { key: "branchName" },
            { key: "currency" },
            { key: "bankAccountType" },
            { key: "accountNumber" },
            { key: "bankRoutingType" },
            { key: "bankRoutingCode" },
            { key: "preferredCashCollectionOffice" },
            { key: "chequeName" },
            { key: "addressLineOne" },
            { key: "city" },
            { key: "country" },
        ],
    },
]

export const networkCallback = async (givenPayload, url, handleLoading, returnFullResponse = false) => {
    const payload = {
        tenantId: `${config.tenantId}`,
        ...givenPayload
    };

    const options = await NetworkCall(
        `${config.api_url}/${url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    )
        .then((response) => {
            let main = response?.data?.data;
            handleLoading && handleLoading(null);
            return returnFullResponse ? response : main
        })
        .catch(() => {
            handleLoading && handleLoading(null);
            return null
        });
    return options
}

export const constructPayload = (companyProfile, companyConfiguration, governmentalDetails, bankingDetails, assets) => {

    const {
        profileDetails = null,
        logo = null,
        address = null,
        contactInformation = null
    } = companyProfile;

    const {
        initialConfig = null,
        pricing = null,
        paymentGatewayConfiguration = null
    } = companyConfiguration;

    const {
        companyEstateRegistration = null,
        tax = null
    } = governmentalDetails;

    const {
        bankingBasicDetails = null,
    } = bankingDetails;


    let payload = {
        "tenantId": config.tenantId,
        "user_profile_id": localStorage.getItem(LocalStorageKeys?.profileID),
        "name": profileDetails?.companyName,
        "currency_id": initialConfig?.currency?.value,
        "country_id": profileDetails?.companyCountry?.value,
        "company_registration_number": companyEstateRegistration?.companyRegistrationNumber,
        "vat_group_id": tax?.taxGroup?.value,
        "cheque_name": bankingBasicDetails?.chequeName,
        "primary_bank_name": bankingBasicDetails?.bankName,
        "primary_account_no": bankingBasicDetails?.accountNumber,
        "bank_routing_code": bankingBasicDetails?.bankRoutingCode,
        "cash_collection_office": bankingBasicDetails?.preferredCashCollectionOffice,
        "account_type": bankingBasicDetails?.bankAccountType,
        "routing_type": bankingBasicDetails?.bankRoutingType?.value,
        "logo": logo?.src,
        "company_no": profileDetails?.companyCode,
        "payment_period": pricing?.paymentPeriod?.value,
        "lease_period": pricing?.leasePeriod?.value,
        "company_registration": companyEstateRegistration?.companyRegistrationType?.value,
        "rera_type": companyEstateRegistration?.realEstateRegulatoryAuthority?.value, //realestatereularAu
        "rera_registration_number": companyEstateRegistration?.RERARegistrationNumber,
        "pricing_method": pricing?.pricingMethod?.value,
        "uom": initialConfig?.uom?.value,
        "description": profileDetails?.businessDescription,
        "grace_period": initialConfig?.gracePeriod,
        "is_price_overide": pricing?.priceOverride,
        "is_short_term_pricing": pricing?.shortTermPricing,
        "is_refer_win_share": initialConfig?.referAndWin,
        "tax_registration_number": tax?.taxRegistrationNumber,
        "rera_advertising_permit": companyEstateRegistration?.realEstateAdvertisingPermit,
        "rera_advertising_permit_no": companyEstateRegistration?.advertisingPermitNumber,
        "payment_gateway": paymentGatewayConfiguration?.paymentGateway?.value,
        "door_no": address?.doorNumber,
        "landmark": address?.landmark,
        "street_1": address?.addressLineOne,
        "street_2": address?.addressLineTwo,
        "city": address?.city,
        "area": address?.area,
        "state": address?.state,
        "zipcode": address?.pincode,
        "country": address?.country,
        "mobile_no_country_code": contactInformation?.primaryMobile?.mobile_code,
        "mobile_no": contactInformation?.primaryMobile?.mobile,
        "alternative_mobile_no_country_code": contactInformation?.primaryContact?.mobile_code,
        "alternative_mobile_no": contactInformation?.primaryContact?.mobile,
        "email_id": contactInformation?.primaryEmailAddress,
        "website": contactInformation?.website,
        "latitude": address?.latitude,
        "longitude": address?.longitude,
        "tax_code_type": tax?.taxCode?.value,
        "address_1": bankingBasicDetails?.addressLineOne,
        "address_2": bankingBasicDetails?.addressLineTwo,
        "title": contactInformation?.title,
        "banking_currency_id": bankingBasicDetails?.currency?.value,
        "bank_country_name": bankingBasicDetails?.country,
        "bank_city_name": bankingBasicDetails?.city,
        "rera_broker_registration_number": companyEstateRegistration?.RERABrokerRegistrationNumber,
        "branch_name": bankingBasicDetails?.branchName,
        "telephone": contactInformation?.primaryTelephone?.mobile,
        "telephone_code": contactInformation?.primaryTelephone?.mobile_code,
        "assetsData": assets?.uploadedImages?.filter(({ id }) => !id)?.map(({ priority, url }) => ({ priority, url })),
        "deleteAssets": assets?.deletedImages?.length ? assets?.deletedImages : []
    }

    return payload
}

export const returnValue = (value = "", delimiter = "") => value ? value : delimiter;

export const constructCompanyDetails = (val) => {

    const returnAssests = (array) => array?.map(({ id = "", url = "", priority = null }) => ({ id, url, priority, assets_master_type: 2 }));

    let constructState = {};
    let information = val?.company_address?.[0] ?? null;

    constructState['companyProfile'] = {
        logo: val?.logo ? {
            src: returnValue(val?.logo, null)
        } : null,
        profileDetails: {
            companyCode: returnValue(val?.company_no),
            companyName: returnValue(val?.name),
            companyCountry: val?.country_master ? {
                label: returnValue(val?.country_master?.country_name),
                value: returnValue(val?.country_master?.id),
            } : null,
            businessDescription: returnValue(val?.description),
        },
        address: {
            latitude: returnValue(information?.latitude, null),
            longitude: returnValue(information?.longitude, null),
            doorNumber: returnValue(information?.door_no),
            addressLineOne: returnValue(information?.street_1),
            addressLineTwo: returnValue(information?.street_2),
            landmark: returnValue(information?.landmark),
            area: returnValue(information?.area),
            city: returnValue(information?.city),
            state: returnValue(information?.state),
            country: returnValue(information?.country),
            pincode: returnValue(information?.zipcode),
        },
        contactInformation: {
            title: returnValue(information?.name),
            primaryContact: {
                mobile: returnValue(information?.alternative_mobile_no, null),
                mobile_code: returnValue(information?.alternative_mobile_no_country_code, null)
            },
            primaryTelephone: {
                mobile: returnValue(information?.telephone, null),
                mobile_code: returnValue(information?.telephone_code, null)
            },
            primaryMobile: {
                mobile: returnValue(information?.mobile_no, null),
                mobile_code: returnValue(information?.mobile_no_country_code, null)
            },
            primaryEmailAddress: returnValue(information?.email_id),
            website: returnValue(information?.website)
        }
    }
    constructState['companyConfiguration'] = {
        initialConfig: {
            currency: val?.currency ? {
                label: returnValue(val?.currency?.name),
                value: returnValue(val?.currency?.id),
            } : null,
            uom: val?.uom ? {
                label: returnValue(val?.uom),
                value: returnValue(val?.uom),
            } : null,
            referAndWin: val?.is_refer_win_share,
            gracePeriod: val?.grace_period
        },
        pricing: {
            pricingMethod: val?.pricing_method ? {
                label: returnValue(val?.pricing_method),
                value: returnValue(val?.pricing_method)
            } : null,
            paymentPeriod: val?.payment_period ? {
                label: returnValue(val?.payment_period),
                value: returnValue(val?.payment_period)
            } : null,
            leasePeriod: val?.lease_period ? {
                label: returnValue(val?.lease_period),
                value: returnValue(val?.lease_period)
            } : null,
            priceOverride: val?.is_price_overide,
            shortTermPricing: val?.is_short_term_pricing
        },
        paymentGatewayConfiguration: {
            paymentGateway: val?.payment_gateway ? {
                label: returnValue(val?.payment_gateway),
                value: returnValue(val?.payment_gateway),
            } : null
        }
    }
    constructState['governmentalDetails'] = {
        companyEstateRegistration: {
            companyRegistrationType: val?.company_registration ? {
                label: returnValue(val?.company_registration),
                value: returnValue(val?.company_registration),
            } : null,
            companyRegistrationNumber: returnValue(val?.company_registration_number),
            realEstateRegulatoryAuthority: val?.rera_type ? {
                label: returnValue(val?.rera_type),
                value: returnValue(val?.rera_type),
            } : null,
            RERARegistrationNumber: returnValue(val?.rera_registration_number),
            RERABrokerRegistrationNumber: returnValue(val?.rera_broker_registration_number),
            realEstateAdvertisingPermit: val?.rera_advertising_permit, //
            advertisingPermitNumber: returnValue(val?.rera_advertising_permit_no)
        },
        tax: {
            taxGroup: val?.vat_group?.id ? {
                label: returnValue(val?.vat_group?.group_name),
                value: returnValue(val?.vat_group?.id),
            } : null,
            taxCode: val?.tax_code_type ? {
                label: returnValue(val?.tax_code_type),
                value: returnValue(val?.tax_code_type),
            } : null,
            taxRegistrationNumber: returnValue(val?.tax_registration_number)
        }
    }
    constructState['bankingDetails'] = {
        bankingBasicDetails: {
            bankName: returnValue(val?.primary_bank_name),
            branchName: returnValue(val?.branch_name),
            currency: {
                label: returnValue(val?.banking_currency?.name),
                value: returnValue(val?.banking_currency?.id),
            },
            bankAccountType: returnValue(val?.account_type),
            accountNumber: returnValue(val?.primary_account_no),
            bankRoutingType: {
                label: returnValue(val?.routing_type),
                value: returnValue(val?.routing_type)
            },
            bankRoutingCode: returnValue(val?.bank_routing_code),
            preferredCashCollectionOffice: returnValue(val?.cash_collection_office),
            chequeName: returnValue(val?.cheque_name),
            addressLineOne: returnValue(val?.address_1),
            addressLineTwo: returnValue(val?.address_2),
            city: returnValue(val?.bank_city_name),
            country: returnValue(val?.bank_country_name),
        }
    }
    constructState['assets'] = {
        // existingImage: val?.company_assets?.length ? returnAssestsID(val?.company_assets) : [],
        uploadedImages: val?.company_assets?.length ? returnAssests(val?.company_assets) : [],
        deletedImages: [],
    }

    return { ...constructState }

}

export const GET_COMPANY_DETAILS = gql`
query GET_COMPANY_DETAILS($id:ID){
    company_master(
        where:{id:{eq:$id}}
    ){
        logo
        company_no
        name
        country_master {
            id
            country_name
          }
        description
        company_address{
            door_no
            street_1
            street_2
            landmark
            area
            city
            state
            country
            zipcode
            mobile_no_country_code
            mobile_no
            telephone
            telephone_code
            alternative_mobile_no_country_code
            alternative_mobile_no
            email_id
            website
            latitude
            longitude
            name

        }
       currency{
            name
            id
        }
        uom
        is_refer_win_share
        grace_period
        pricing_method
        payment_period
        lease_period
        is_price_overide
        is_short_term_pricing
        payment_gateway
        company_registration_number
        company_registration
        rera_type
        rera_registration_number
        rera_broker_registration_number
        rera_advertising_permit
        rera_advertising_permit_no
        vat_group{
            id
            group_name
        }
        tax_code_type
        tax_registration_number
        primary_bank_name
        branch_name
        banking_currency{
            id
            name
        }
        account_type
        primary_account_no
        routing_type
        bank_routing_code
        cash_collection_office
        cheque_name
        address_1
        address_2
        bank_city_name
        bank_country_name
        terms_condition
        company_assets(
            where:{
                is_active:{eq:true}
            }
        ){
            id
            url
            priority
        }
    }
}
`;

export const GET_ALL_CURRENCIES = (offset = 0, limit = 10, searchText = "", extraParams = {}) => gql`
query GET_ALL_CURRENCY{
    count:currency_master(
         where:{
            name:{iregex:"${searchText}"}
            ${extraParams?.countryID ? `country_id:{eq:${extraParams?.countryID}}` : ''}
        }
    ){
        count:count_id
    }
    currency_master(
        where:{
            name:{iregex:"${searchText}"}
            ${extraParams?.countryID ? `country_id:{eq:${extraParams?.countryID}}` : ''}
        }
        offset:${offset}
        limit:${limit}
    ){
        value:id
        label:name
    }
}
`

export const GET_VAT_GROUP_MASTER = (offset = 0, limit = 10, searchText = "", extraParams = {}) => {
    return gql`
    query GET_VAT_GROUP_MASTER{
        count:vat_group_master(
             where:{
                group_name:{iregex:"${searchText}"}
                country_id:{eq:"${extraParams?.id}"}
            }
        ){
            count:count_id
        }
        vat_group_master(
            where:{
                group_name:{iregex:"${searchText}"}
                country_id:{eq:"${extraParams?.id}"}
            }
            offset:${offset}
            limit:${limit}
        ){
            value:id
            label:group_name
        }
    }
    `
}
