import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from 'react';
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SemiBold } from '../../utils';
import './style.css';


import { Box, Divider, Popover, Stack } from "@mui/material";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from '../../contexts';
import { Bold } from "../../utils";
import { Label } from "../label";


const useStyles = makeStyles((theme) => ({
      Label: {
            color: theme.typography.color.tertiary,
            fontFamily: SemiBold,
            fontSize: "0.875rem",
            marginBottom: "5px",
      },
      text: {},

      textbox: {
            height: (props) => (props.multiline ? "unset" : 30),
            width: '100% !important',
            borderRadius: theme.palette.borderRadius,
            border: (props) => (props?.border ?? '1.5px solid #E4E8EE !important'),

            cursor: 'pointer',
            [`& fieldset`]: {
                  borderRadius: theme.palette.borderRadius,
                  height: (props) => (props.multiline ? "unset" : 30),
                  border: "1px solid #CED3DD",
                  "& :hover": {
                        border: "1px solid #5078E1",
                  },
            },
            "& .MuiOutlinedInput-input": {
                  padding: (props) => (props.multiline ? "0px" : "0px"),
            },
            ":-webkit-autofill": {
                  WebkitBoxShadow: "none !important",
                  backgroundColor: "red !important",
            },
            '&:focusVisible': {
                  border: '1.5px solid #E2E2E2',
            },

            padding: '20px 18px'
      },
      calander: {
            // position: 'absolute',
            top: "55%",
            right: '15px',
            marginTop: "-4px"

      },
      placeholder: {
            fontFamily: (props) => props?.fontFamily ?? SemiBold,
            fontSize: "0.875rem",
            whiteSpace: "nowrap",
            color: "#4E5A6B"
      },
      // div: {
      //     min: "300px"
      // },
      customPlaceholder: {
            fontSize: "0.75rem",
            color: "#4E5A6B",
            textAlign: "left",
            whiteSpace: "nowrap",
            fontFamily: SemiBold,
      },
      title: {
            fontFamily: SemiBold,
            fontSize: "0.75rem",
            color: "#4E5A6B",
            marginBottom: "8px"
      },
      type: {
            fontFamily: SemiBold,
            fontSize: "0.75rem",
            color: "#4E5A6B",
            backgroundColor: "#F2F4F7",
            borderRadius: theme.palette.borderRadius,
            padding: "4px 8px",
            textAlign: "center",
            marginRight: "4px",
            marginLeft: "4px",
            cursor: "pointer"

      },
      textField: {
            width: "70px",
            padding: "4px",
            [`& fieldset`]: {
                  borderRadius: theme.palette.borderRadius,
                  height: (props) => (props.multiline ? "unset" : 32),
                  border: "1px solid #E4E8EE",
                  "& :hover": {
                        border: "1px solid #5078E1",
                  },
            },
            "& .MuiOutlinedInput-input": {
                  padding: "11px 14px",
            },
      },
      dialog: {
            "& .MuiPopover-paper": {
                  borderRadius: theme.palette.borderRadius,
                  boxShadow: "0px 8px 24px #0717411F"
            }
      },
      typea: {
            fontFamily: SemiBold,
            fontSize: "0.75rem",

            borderRadius: theme.palette.borderRadius,
            padding: "4px 8px",
            textAlign: "center",
            marginRight: "4px",
            marginLeft: "4px",
            cursor: "pointer",
            backgroundColor: "#5078E1",
            color: "white"
      },
      value: {
            fontFamily: SemiBold,
            fontSize: "0.875rem",
            color: "#091B29",
      },
      customValue: {
            fontFamily: Bold,
            fontSize: "0.875rem",
            color: "#091B29",
      },
      main: {
            border: "1px solid #E4E8EE",
            borderRadius: '4px'
      },
      noneBorder: {
            border: "none",
            borderRadius: '4px'


      },
      calanderrtl: {
            // position: 'absolute',
            top: "55%",
            left: '8px',
      },
      Datepicker: {
            display: "flex",
            alignItems: "center",
            height: (props) => (props.multiline ? "unset" : 45),
            width: '100%',
            borderRadius: theme.palette.borderRadius,
            border: '1.5px solid #E4E8EE !important',
            cursor: 'pointer',
            ":-webkit-autofill": {
                  WebkitBoxShadow: "none !important",
                  backgroundColor: "red !important",
            },
            '&:focusVisible': {
                  border: '1.5px solid #E2E2E2',
            },

            padding: '20px 14px'
      },
      textBox: {
            fontSize: "0.875rem",
            fontFamily: `${SemiBold}`,
            color: "#091B29",
            padding: "0px !important",
            "&::placeholder": {
                  fontSize: "0.875rem !important",
                  fontFamily:SemiBold,
                  color: "#b4b4b4 !important",
            },
      }
}));

export const DatePickerTimeNew = (props) => {
      const classes = useStyles(props);
      const auth = React.useContext(AuthContext)



      const [anchorEl, setAnchorEl] = React.useState(null);


      const handleClick = (event) => {
            if (!props?.isReadonly) {
                  setAnchorEl(event.currentTarget);
            }
      };

      const handleClose = () => {
            setAnchorEl(null);
      };

      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;

      function CustomInput(props) {
            return (
                  <div className={classes.Datepicker}>
                        <input
                              type="text"
                              className={classes.textBox}
                              onClick={props?.onClick}
                              value={props?.value}
                              onChange={props?.onChange}
                              placeholder={props?.placeholder}
                              readOnly
                        />
                        <div style={{ display: "flex", alignItems: "center" }}>
                              <img src={'/images/date_picker_icon.svg'} alt="" />

                        </div>
                  </div>
            );
      }

      return (
            <>
                  <div style={{ position: 'relative' }}>

                        <Label label={props?.label} isRequired={props?.isRequired} />

                        {
                              props?.is_popover ?
                                    <div style={{ marginTop: props?.margintop }}>
                                          <div style={{ backgroundColor: props?.isReadonly ? "#F2F4F7" : "auto", width: "100%" }} className={props?.noBorder ? classes.noneBorder : classes?.main}>
                                                <Box sx={{ cursor: "pointer", position: 'relative', padding: props?.changePlaceHolder ? '10px 4px' : '10px', width: "100%" }} display="flex" alignItems="center" height={props?.height} onClick={handleClick}>
                                                      <Box flexGrow={1} marginLeft="4px">
                                                            {
                                                                  props?.value ?
                                                                        <Typography className={props?.customValue ? classes.customValue : classes.value}>
                                                                              &#x202a;{moment(props?.value).format("DD MMM YY h:mm a")}&#x202c;
                                                                        </Typography>
                                                                        : <Typography className={props?.changePlaceHolder ? classes.customPlaceholder : classes.placeholder}>
                                                                              {props?.placeholder}</Typography>
                                                            }
                                                      </Box>
                                                      <Box marginTop="4px" marginRight="8px">
                                                            <img src={'/images/date_picker_icon.svg'} alt="" />
                                                      </Box>

                                                </Box>
                                                <Popover
                                                      id={id}
                                                      open={open}
                                                      anchorEl={anchorEl}
                                                      onClose={handleClose}
                                                      anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                      }}
                                                      className={classes.dialog}
                                                >
                                                      <div className={classes.div}>
                                                            <Box p={1.4}>


                                                                  <Stack
                                                                        direction="row"
                                                                        divider={<Divider orientation="vertical" flexItem />}
                                                                        spacing={2}
                                                                  >
                                                                        <div>
                                                                              <Typography className={classes.title}>{props?.label}</Typography>

                                                                              <Datepicker
                                                                                    dateFormat={"MM/dd/yyyy h:mm aa"}

                                                                                    selected={props?.value}
                                                                                    onChange={(value) => {
                                                                                          props?.onChange(value)
                                                                                          setAnchorEl(null)
                                                                                    }}
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    disabled
                                                                                    dropdownMode="select"
                                                                                    selectsDisabledDaysInRange
                                                                                    inline
                                                                                    minDate={props?.minDate ?? false}
                                                                                    maxDate={props?.maxDate ?? false}
                                                                                    timeInputLabel="Time:"
                                                                                    showTimeInput={true}
                                                                              //showMonthDropdown
                                                                              />
                                                                        </div>


                                                                  </Stack>



                                                            </Box>


                                                      </div>
                                                </Popover>
                                          </div>
                                    </div> :
                                    <>
                                          <Datepicker
                                                readOnly={props?.isReadonly}
                                                disabled={props?.isReadonly}
                                                placeholderText={props?.placeholder}
                                                timeInputLabel="Time:"
                                                dateFormat={props?.dateFormat ?? "MM/dd/yyyy h:mm aa"}
                                                className={classes.textbox}
                                                showTimeInput={props?.isTime ?? false}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                customInput={<CustomInput />}

                                                // selected={this.state.dob.value}
                                                selected={props?.value}
                                                // onChange={date => props?.onChange(moment(date).format('DD-MM-YYYY,hh:mm A'))}
                                                onChange={date => props?.onChange(date)}
                                                minDate={props?.minDate ?? false}
                                                maxDate={props?.maxDate ?? false}
                                                onKeyDown={(e) => {
                                                      e.preventDefault();
                                                }}
                                                calendarIcon={
                                                      <div className={auth?.auth?.auth?.language === "ar" ? classes.calanderrtl : classes.calander}>
                                                            <img src={'/images/date_picker_icon.svg'} alt="" />
                                                      </div>
                                                }

                                          />
                                    </>



                        }



                  </div>
                  {props?.isError && (
                        <Typography variant={"caption"} color={"error"}>
                              {props?.errorMessage ?? ""}
                        </Typography>
                  )}
            </>
      )
}


