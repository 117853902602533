import { checkNumber } from "../../../utils";

export const TableHeader = (t) => {
    return (
        [
            {
                title: t("ITEM TYPE & CATEGORY")
            },
            {
                title: t("Item Name")
            },
            {
                title: t("Price")
            },
            {
                title: t("QTY")
            },
            {
                title: t("Amount")
            },
            {
                title: t("Discount")
            },
            {
                title: t("Tax Group")
            },
            {
                title: t("Tax Amount")
            },
            {
                title: t("Line Amount")
            },
            {
                title: "",
                value: "delete"
            },
        ]
    )
}
export const DeductionTableHeader = (t) => {
    return (
        [
            {
                title: t("ITEM TYPE & CATEGORY")
            },
            {
                title: t("Item Name")
            },
            {
                title: t("Description")
            },
            {
                title: t("REFERENCE NUMBER")
            },
            // {
            //     title: ""
            // },
            {
                title: ""
            },
            {
                title: t("Amount")
            },
            {
                title: "",
                value: "delete"
            },

        ]
    )
}
export const initialState = {
    itemName: "",
    description: "",
    price: 0,
    qty: 1,
    amount: 0,
    discount: 0,
    taxGroup: "",
    taxAmount: 0,
    lineAmount: 0,
    itemTypeCategory: {
        type: "",
        category: ""
    },
    discountType: { label: "%", value: "%" },
    totalDiscount: 0,
    totalAmount: 0,
    totalTaxes: 0,
    totalTaxableAmount: 0,
    error: {
        itemName: "",
        description: "",
        price: "",
        qty: "",
    }
}
export const initialdeductionState = {
    description: "",
    referenceNumber: "",
    deductionAmount: 0,
    totalDeduction: 0,
    error: {
        deductionAmount: ""
    }
}
export const addId = (arr, option = false) => {
    return arr.map(function (obj, index) {
        return Object.assign({}, obj, option ? { option_id: index } : { auto_inc_id: index });
    });
};



export const calculateAmount = (data, key, value, tableRowData, totalDeduction) => {
    const result = tableRowData?.map((obj, i) => {
        let tax = key === "taxGroup" ?
            value?.vat_group_item?.map((e) => e?.rate)?.reduce((startrate, endrate) => startrate + endrate, 0) :
            key === "itemName" ?
                value?.vat?.vat_item?.map((e) => e?.rate)?.reduce((startrate, endrate) => startrate + endrate, 0)
                : obj?.taxGroup?.vat_group_item?.map((e) => e?.rate)?.reduce((startrate, endrate) => startrate + endrate, 0) ?? 0
        if (obj.auto_inc_id === data.auto_inc_id) {
            switch (key) {
                case "price": {
                    let totalBeforeDiscount = checkNumber(value) * checkNumber(obj?.qty)
                    // discount = totalBeforeDiscount * (checkNumber(value) / 100)           
                    let totalAmountAfterDiscount = obj?.discountType?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(obj?.discount)) / 100) : totalBeforeDiscount - checkNumber(obj?.discount)
                    let totalDiscount = value > 0 ? (obj?.discountType?.value === "%" ? (totalBeforeDiscount * checkNumber(obj?.discount) / 100) : checkNumber(obj?.discount)) : 0
                    let totalAmountBeforeTax = totalBeforeDiscount - totalDiscount
                    let tempTaxes = totalAmountAfterDiscount * (checkNumber(tax) / 100)

                    let tempSubtotal = totalAmountAfterDiscount + tempTaxes
                    return {
                        ...obj,
                        amount: value * obj?.qty,
                        totalAmount: totalAmountBeforeTax,
                        lineAmount: tempSubtotal?.toFixed(2),
                        taxAmount: tempTaxes?.toFixed(2),
                        totalDiscount: totalDiscount,
                        totalAmountAfterTaxes: tempSubtotal?.toFixed(2),
                        [key]: value,
                    }
                }
                case "qty": {
                    let totalBeforeDiscount = checkNumber(value) * checkNumber(obj?.price)
                    // discount = totalBeforeDiscount * (checkNumber(value) / 100)           
                    let totalAmountAfterDiscount = obj?.discountType?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(obj?.discount)) / 100) : totalBeforeDiscount - checkNumber(obj?.discount)
                    let totalDiscount = value > 0 ? (obj?.discountType?.value === "%" ? (totalBeforeDiscount * checkNumber(obj?.discount)) / 100 : checkNumber(obj?.discount)) : 0
                    let totalAmountBeforeTax = totalBeforeDiscount - totalDiscount
                    let tempTaxes = totalAmountAfterDiscount * (checkNumber(tax) / 100)

                    let tempSubtotal = totalAmountAfterDiscount + tempTaxes
                    return {
                        ...obj,
                        amount: value * obj?.price,
                        totalAmount: totalAmountBeforeTax,
                        lineAmount: tempSubtotal?.toFixed(2),
                        taxAmount: tempTaxes?.toFixed(2),
                        totalDiscount: totalDiscount,
                        totalAmountAfterTaxes: tempSubtotal?.toFixed(2),
                        [key]: value,
                    }
                }
                case "discount": {
                    let totalBeforeDiscount = checkNumber(obj?.qty) * checkNumber(obj?.price)
                    // discount = totalBeforeDiscount * (checkNumber(value) / 100)           
                    let totalAmountAfterDiscount = obj?.discountType?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(value)) / 100) : totalBeforeDiscount - checkNumber(value)
                    let totalDiscount = value > 0 ? (obj?.discountType?.value === "%" ? (totalBeforeDiscount * checkNumber(value)) / 100 : checkNumber(value)) : 0

                    let totalAmountBeforeTax = totalBeforeDiscount - totalDiscount
                    let tempTaxes = totalAmountAfterDiscount * (checkNumber(tax) / 100)

                    let tempSubtotal = totalAmountAfterDiscount + tempTaxes
                    return {
                        ...obj,
                        [key]: value,
                        totalAmount: totalAmountBeforeTax,
                        lineAmount: tempSubtotal?.toFixed(2),
                        taxAmount: tempTaxes?.toFixed(2),
                        totalDiscount: totalDiscount,
                        totalAmountAfterTaxes: tempSubtotal?.toFixed(2)
                    }


                }
                case "discountType": {
                    let totalBeforeDiscount = checkNumber(obj?.qty) * checkNumber(obj?.price)
                    if (obj?.discount <= totalBeforeDiscount) {
                        // discount = totalBeforeDiscount * (checkNumber(value) / 100)           
                        let totalAmountAfterDiscount = value?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(obj?.discount)) / 100) : totalBeforeDiscount - checkNumber(obj?.discount)
                        let totalDiscount = obj?.discount > 0 ? (value?.value === "%" ? (totalBeforeDiscount * checkNumber(obj?.discount)) / 100 : checkNumber(obj?.discount)) : 0
                        let tempTaxes = totalAmountAfterDiscount * (checkNumber(tax) / 100)
                        let totalAmountBeforeTax = totalBeforeDiscount - totalDiscount
                        let tempSubtotal = totalAmountAfterDiscount + tempTaxes
                        return {
                            ...obj,
                            [key]: value,
                            totalAmount: totalAmountBeforeTax,
                            lineAmount: tempSubtotal?.toFixed(2),
                            taxAmount: tempTaxes?.toFixed(2),
                            totalDiscount: totalDiscount,
                            totalAmountAfterTaxes: tempSubtotal?.toFixed(2)
                        }

                    }
                    else {
                        return obj
                    }
                }
                case "taxGroup": {
                    let totalBeforeDiscount = checkNumber(obj?.qty) * checkNumber(obj?.price)

                    let totalAmountAfterDiscount = obj?.discountType?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(obj?.discount)) / 100) : totalBeforeDiscount - checkNumber(obj?.discount)
                    let totalDiscount = obj?.discount > 0 ? (obj?.discountType?.value === "%" ? (totalBeforeDiscount * checkNumber(obj?.discount)) / 100 : checkNumber(obj?.discount)) : 0

                    let totalAmountBeforeTax = totalBeforeDiscount - totalDiscount
                    let tempTaxes = totalAmountAfterDiscount * (checkNumber(tax) / 100)

                    let tempSubtotal = totalAmountAfterDiscount + tempTaxes

                    return {
                        ...obj,
                        taxGroup: value,
                        totalAmount: totalAmountBeforeTax?.toFixed(2),
                        taxAmount: tempTaxes?.toFixed(2),
                        lineAmount: tempSubtotal?.toFixed(2),
                        totalDiscount: totalDiscount,
                        totalAmountAfterTaxes: tempSubtotal?.toFixed(2)
                    }

                }
                case "itemName": {
                    let totalBeforeDiscount = checkNumber(obj?.qty) * checkNumber(obj?.price)

                    let totalAmountAfterDiscount = obj?.discountType?.value === "%" ? totalBeforeDiscount - ((totalBeforeDiscount * checkNumber(obj?.discount)) / 100) : totalBeforeDiscount - checkNumber(obj?.discount)
                    let totalDiscount = obj?.discount > 0 ? (obj?.discountType?.value === "%" ? (totalBeforeDiscount * checkNumber(obj?.discount)) / 100 : checkNumber(obj?.discount)) : 0

                    let totalAmountBeforeTax = totalBeforeDiscount - totalDiscount
                    let tempTaxes = value?.vat !== null ? (totalAmountAfterDiscount * (checkNumber(tax) / 100)) : 0

                    let tempSubtotal = totalAmountAfterDiscount + tempTaxes
                    return {
                        ...obj,
                        [key]: value,
                        taxGroup: {
                            label: value?.vat?.group_name,
                            value: value?.vat?.id,
                            vat_group_item: value?.vat?.vat_item
                        },
                        isTaxable: value?.is_taxable,
                        totalAmount: totalAmountBeforeTax?.toFixed(2),
                        taxAmount: tempTaxes?.toFixed(2),
                        lineAmount: tempSubtotal?.toFixed(2),
                        totalDiscount: totalDiscount,
                        totalAmountAfterTaxes: tempSubtotal?.toFixed(2)
                    }
                }
                case "itemTypeCategory": {
                    return {
                        ...obj,
                        [key]: value,
                        itemName: ""
                    }
                }
                case "item_type": {
                    return {
                        ...obj,
                        [key]: value,
                        item_category: "",
                        item_subCategory: ""
                    }
                }
                case "item_category": {
                    return {
                        ...obj,
                        [key]: value,
                        item_subCategory: ""
                    }
                }
                default: {
                    return {
                        ...obj,
                        [key]: value,
                        totalAmount: 0,
                        taxAmount: 0,
                        lineAmount: 0,
                        totalDiscount: 0,
                        totalAmountAfterTaxes: 0
                    }
                }
            }
        }
        else {
            return obj
        }
    })
    return result

}

export const PaidStatus = {
    0: {
        label: "Paid",
        total_amount_tag_color: "#EDF7F8",
        total_amount_label_color: "#6DAFB3",
        total_amount_value_color: "#6DAFB3",
        paid_tag_color: "#6DAFB3",
        view_paid_tag_color: "#6DAFB3",
    },
    1: {
        label: "Unpaid",
        total_amount_tag_color: "#ffe2e2",
        total_amount_label_color: "#ff4b4b",
        total_amount_value_color: "#ff4b4b",
        paid_tag_color: "#ff4b4b",
        view_paid_tag_color: "#ff4b4b",
    },
    2: {
        label: "Partially Paid",
        total_amount_tag_color: "#ffd8c1",
        total_amount_label_color: "#f37b37",
        total_amount_value_color: "#f37b37",
        paid_tag_color: "#f37b37",
        view_paid_tag_color: "#f37b37",
    },
}