import { Box, Grid } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { Subheader } from "../../../components";
import { getSettingList, getSettingList1 } from "../../../utils/agreementUtils";
import { SettingList } from "../../companyCreation/components";
import { AgreementDocument, AgreementInformation, BillingInformation, CustomerInformation, OppertunityInformation, QuotationInformation, QuotationPreview, RenewalInformation, ReservationInfo, RevenueSummery, ConfirmOrdersInformation, TermsAndCondition,WalletCredits } from "../agreementPreviewTabs";
import { AggrementTabStyles } from "./styles";

const AgreementPreview = ({ details = {}, id = "", reload = () => false, t = () => false, onSubmit = () => false }) => {
    const classes = AggrementTabStyles();
    //get preview list
    const list = details?.is_renewal ?
        getSettingList1(t, details?.quotation?.lead, details?.quotation?.id, details?.quotation?.reservation?.[0]?.id, details?.quotation?.id)
        : getSettingList(t, details?.quotation?.lead, details?.quotation?.id, details?.quotation?.reservation?.[0]?.id, details?.quotation?.id);

    const [selected, setSelected] = React.useState(list[0]);

    const sections = {
        1: <CustomerInformation t={t} details={details} />,
        2: <OppertunityInformation t={t} details={details?.quotation?.lead} />,
        3: <QuotationInformation t={t} details={details?.quotation} />,
        4: <ReservationInfo t={t} details={details?.quotation?.reservation?.[0]} />,
        5: <AgreementInformation t={t} details={details} />,
        6: <BillingInformation t={t} reload={reload} id={id} details={details} agree={details} />,
        7: <RenewalInformation t={t} reload={reload} details={details?.quotation} />,
        8: <RevenueSummery t={t} details={details} />,
        9: <QuotationPreview t={t} details={details?.quotation} />,
        10: <AgreementDocument t={t} details={details} />,
        11: <ConfirmOrdersInformation t={t} details={details} />,
        12: <TermsAndCondition t={t} settings={details} onSubmit={onSubmit} />,
        13: <WalletCredits t={t} details={details} type={"wallet"}/>,
        14: <WalletCredits t={t} details={details} type={"wallet_item"}/>,
        15: <WalletCredits t={t} details={details} type={"late_fee"}/>,
        16: <WalletCredits t={t} details={details} type={"commission_bonus"}/>
    }
    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={2.5}>
                    <Box className={classes.box}>
                        {/*tab list */}
                        <Box pt={"4px"}>
                            <SettingList searchBool={false} list={list?.filter((x) => x?.is_active === true)} selected={selected?.value} setSelected={setSelected} />
                        </Box>
                    </Box >

                </Grid >
                <Grid item xs={9.5}>
                    <Box className={classes.box}>
                        {/*sub header */}
                        <Subheader title={selected?.label} hideBackButton={true} />
                        <Box className={classes.box1}>
                            {sections[selected?.value]}
                        </Box>
                    </Box>
                </Grid>

            </Grid >

        </Box >
    )
}
export default withNamespaces("agreement")(AgreementPreview); 