import gql from "graphql-tag";

export const GET_SECURITY_TABEL = (request_type, status, created_at_start, created_at_end, status1, status2, status3, status4) => gql`
query(
      $property_ids: [String]
      $created_at_start: String
      $created_at_end: String
      $clientID:String
    ) {
      received_gate_count: security_request(
        where: {
          request_type: {eq:"${request_type}"},
          ${status ? `delivery_status: { eq:"${status1}" }` : ''},
          property_id: { in: $property_ids },
          created_at: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } }
          is_active:true,
          client:{eq:$clientID},
        }
      ) {
        count: count_id
      }
      yet_recived_gate_count: security_request(
        where: {
          request_type: {eq:"${request_type}"},
          ${status ? `delivery_status: { eq:"${status2}" }` : ''},
          property_id: { in: $property_ids },
          created_at: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } }
          is_active:true,
          client:{eq:$clientID},
        }
      ) {
        count: count_id
      }
      cancelled_gate_count: security_request(
        where: {
          request_type: {eq:"${request_type}"},
          ${status ? `delivery_status: { eq:"${status3}"  }` : ''},
          property_id: { in: $property_ids },
          created_at: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } }
          is_active:true,
          client:{eq:$clientID},
        }
      ) {
        count: count_id
      }
      collected_gate_count: security_request(
        where: {
          request_type: {eq:"${request_type}"},
          ${status ? `delivery_status: { eq:"${status4}" }` : ''},
          property_id: { in: $property_ids },
          created_at: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } }
          is_active:true,
          client:{eq:$clientID},
        }
      ) {
        count: count_id
      }
    }
`


export const GET_ACCESSTABEL = () => gql`
query(
      $property_ids: [String]
      $offset:Int
      $limit:Int
      $clientID:String
    ) {
      count:access_gates_master(
        where: { is_active:true ,property_id: { in: $property_ids } ,  client:{eq:$clientID}, }
        offset:$offset
        limit:$limit
      ) {
      count_id
      }
      access_gates_master(
            where: { is_active:true ,property_id: { in: $property_ids } ,  client:{eq:$clientID}, }
            offset:$offset
            limit:$limit
          ) {
            gate_no
            user_profile {
              id
              first_name
              last_name
              image_url
            }
          }
    }
`



export const GET_SECURITY_TABEL_BY_TYPE = (request_type, created_at_start, created_at_end) => gql`
query(
      $property_ids: [String]
      $created_at_start: String
      $created_at_end: String
      $clientID:String
    ) {
      visitor: security_request(
        where: {
          request_type: {eq:"${request_type?.type1}"},
          property_id: { in: $property_ids },
          created_at: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } }
          is_active:true,
          client:{eq:$clientID},
        }
      ) {
        count: count_id
      }
      worker_gate: security_request(
        where: {
          request_type: {eq:"${request_type?.type2}"},
          property_id: { in: $property_ids },
          created_at: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } }
          is_active:true,
          client:{eq:$clientID},
        }
      ) {
        count: count_id
      }
      parking_pass: security_request(
        where: {
          request_type: {eq:"${request_type?.type3}"},
          property_id: { in: $property_ids },
          created_at: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } }
          is_active:true,
          client:{eq:$clientID},
        }
      ) {
        count: count_id
      }
      delivery_order: security_request(
        where: {
          request_type: {eq:"${request_type?.type4}"},
          property_id: { in: $property_ids },
          created_at: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } }
          is_active:true,
          client:{eq:$clientID},
        }
      ) {
        count: count_id
      }
    }
`


export const GET_ACCESS_CONTROL = gql`
    query($companyID:String , $clientID:String){
      access_control(where:{
        client:{eq:$clientID},
        is_active:true,
        property_id:{is_null	:false}
        company_id:$companyID
      }){
        property_id
      }
    }`




export const GET_SECURITY_GUARD_NO = gql`
query($role: String, $property_id: [String] , $clientID:String) {
  count: access_control(
    where: { roles: { name: $role }, property_id: { in: $property_id } , is_active:true , client:{eq:$clientID},}
  ) {
    count_id
  }
}
`


export const GET_COUNT_OF_VECICLE_TYPE = gql`
query($role: String, $property_id: [String] , $fourwheeler:String , $twowheeler:String  , $bicycle:String  , $clientID:String) {
  four_wheeler: parking_slot(
    where: { vehicle_type: $fourwheeler, property_id: { in: $property_id } , client:{eq:$clientID},}
  ) {
    count_id
  }
  two_wheeler: parking_slot(
    where: { vehicle_type: $twowheeler, property_id: { in: $property_id } , client:{eq:$clientID},}
  ) {
    count_id
  }
  bi_cycle: parking_slot(
    where: { vehicle_type: $bicycle, property_id: { in: $property_id } , client:{eq:$clientID},}
  ) {
    count_id
  }
  parking_violation_stats(
    where: { property_id: {in: $property_id} ,  client:{eq:$clientID},})
  {
    violated_count
  }
}`


export const GET_PARKING_SOLT_DETAILS = (created_at_start, created_at_end) => gql`
query($property_id: [String] , $clientID:String) {
  visitor_occupied: parking_status_dashboard(
    where: {
      property_id: { in: $property_id }
      parking_type: "Visitors"
      parking_status: "occupied",
      created_date: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } },
      client:{eq:$clientID},
    }
  ) {
    total_slots
  }
  Reserved_occupied: parking_status_dashboard(
    where: {
      property_id: { in: $property_id }
      parking_type: "Reserved"
      parking_status: "occupied",
      created_date: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } },
      client:{eq:$clientID},
    }
  ) {
    total_slots
  }
  Reserved_free: parking_status_dashboard(
    where: {
      property_id: { in: $property_id }
      parking_type: "Reserved"
      parking_status: "free",
      created_date: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } },
      client:{eq:$clientID},
    }
  ) {
    total_slots
  }
  Visitors_free: parking_status_dashboard(
    where: {
      property_id: { in: $property_id }
      parking_type: "Visitors"
      parking_status: "free",
      created_date: { and: { gte: "${created_at_start}", lte: "${created_at_end}" } },
      client:{eq:$clientID},
    }
  ) {
    total_slots
  }
}

`
export const GET_PROPERTY_GROUP_PURPOSE = () => gql`
query($property_id:String , $company_id:Int){
  unit_type_master(where:{company_id:{eq:$company_id}}){
   value: id
   label: name
 }
 property_group_master(where:{company_id:{eq:$company_id}}){
   value: id
   label: group_name
 }
 dashboard_property_unit_stats_by_city(distinct_on:[city],is_active:true,property_id:{in:$property_id} , company_id:{eq:$company_id}){
  value:city
  label:city
}
}
`

export const CITY_SELECT_BY_UNIT = gql`
query($city:String , $Vacant:String , $Occupied:String , $Reserved:String , $Lost:String , $Converted:String , $company:Int ,$clientID:String){
  vacant: property(
    where: { address: { city: $city }, unit: { status: $Vacant }, client:{eq:$clientID}, company_id:$company }
  ) {
    count_id
  }
  Occupied: property(
    where: { address: { city: $city }, unit: { status: $Occupied } , client:{eq:$clientID}, company_id:$company}
  ) {
    count_id
  }
  Reserved: property(
    where: { address: { city: $city }, unit: { status: $Reserved }, client:{eq:$clientID}, company_id:$company}
  ) {
    count_id
  }
  inActive: property(
    where: { address: { city: $city }, unit: { is_active: true }, client:{eq:$clientID},company_id:$company }
  ) {
    count_id
  }
  total_lead: proxy_lead(where: { is_active: true }) {
    count_id
  }
  qualified_lead: proxy_lead(where: { is_active: true, status: $Lost , client:{eq:$clientID},company_id:$company}) {
    count_id
  }
  lost_lead: proxy_lead(where: { is_active: true, status: $Converted , client:{eq:$clientID},company_id:$company}) {
    count_id
  }
}
`

export const GET_PROPERTY_TYPE_UNIT = gql`
query($groupname: String, $Vacant: String, $Occupied: String, $Reserved: String , $company:Int , $clientID:String) {
  vacant: property(
    where: {
      property_group: { id: $groupname }
      unit: { status: $Vacant }
      client:{eq:$clientID},
    }
  ) {
    count_id
  }
  Occupied: property(
    where: {
      property_group: { id: $groupname }
      unit: { status: $Occupied }
      client:{eq:$clientID},
      company_id:$company
    }
  ) {
    count_id
  }
  Reserved: property(
    where: {
      property_group: { id: $groupname }
      unit: { status: $Reserved }
      client:{eq:$clientID},
      company_id:$company
    }
  ) {
    count_id
  }
  inActive: property(
    where: {
      property_group: { id: $groupname }
      unit: { is_active: true }
      client:{eq:$clientID},
      company_id:$company
    }
  ) {
    count_id
  }
}
`


export const getUnits = (type) => gql`
query ($unit_catagory: String, $Vacant: String, $Occupied: String, $Reserved: String , $unit_type:Int , $company_id:Int , $clientID:String) {
  vacant: unit(
    where: {
      company_id:$company_id
      client:{eq:$clientID},
      ${type === 'unit_category' ? ` unit_category:$unit_catagory` : ` unit_type:$unit_type`},
      status: $Vacant}
  ) {
    count_id
  }
  Occupied: unit(
    where: {
      company_id:$company_id
      client:{eq:$clientID},
      ${type === 'unit_category' ? ` unit_category:$unit_catagory` : ` unit_type:$unit_type`}, , status: $Occupied}
  ) {
    count_id
  }
  Reserved: unit(
    where: {
      company_id:$company_id
      client:{eq:$clientID},
      ${type === 'unit_category' ? ` unit_category:$unit_catagory` : ` unit_type:$unit_type`}, , status: $Reserved}
  ) {
    count_id
  }
  inActive: unit(
    where: {
      company_id:$company_id
      client:{eq:$clientID},
       ${type === 'unit_category' ? ` unit_category:$unit_catagory` : ` unit_type:$unit_type`}, ,is_active: true}
  ) {
    count_id
  }
}

`


export const GET_AGREEMENT_DETAILS = gql`
query($created_at_start:String , $created_at_end:String , $terminated:String , $company:Int ,   $property_id: [String] , $clientID:String) {
  totalagreemet: agreement(
    where: {
      company_id:$company
      created_at: { and: { gte: $created_at_start, lte: $created_at_end } }
      is_active:true,
      client:{eq:$clientID},
    }
  ) {
    count_id
  }
  renewel: agreement(where:{
    parent_agreement_id:{is_null:false},company_id: $company
  }) {
    count_id
  }
  Termination: agreement(where: { agreement_status: $terminated },      company_id:$company ,   is_active:true , client:{eq:$clientID}) {
    count_id
  }
  payment:dashboard_quotation_payments(where:{property_id:{in:$property_id}}){
    total_count
  }
}
`


export const GET_REVENUE_TYPE = gql`
query(
  $revenue_type: String
  $Vacant: String
  $Occupied: String
  $Reserved: String
  $unit_type: Int
 $company: Int
 $clientID:Int
) {
  vacant: unit(
    where: {
      company_id:$company
       client:{eq:$clientID}
      property: { revenue_type: $revenue_type }
      status: $Vacant
    }
  ) {
    count_id
  }
  Occupied: unit(
    where: {
      company_id:$company
       client:{eq:$clientID}
      property: { revenue_type: $revenue_type }
      status: $Occupied
    }
  ) {
    count_id
  }
  Reserved: unit(
    where: {
      company_id:$company
       client:{eq:$clientID}
      property: { revenue_type: $revenue_type }
      status: $Reserved
    }
  ) {
    count_id
  }
  inActive: unit(
    where: {
      company_id:$company
       client:{eq:$clientID}
      property: { revenue_type: $revenue_type }
      is_active: true
    }
  ) {
    count_id
  }
}
`


export const PAY_MENT_TOTAL = gql`
query(
  $created_at_start_thirty: String
  $created_at_end_thirty: String
  $created_at_start_sixty: String
  $created_at_end_sixty: String
  $created_at_start_nignty: String
  $created_at_end_nignty: String
  $Booked: String
  $Reserved: String
  $company: Int
  $clientID:Int
) {
  thirty: agreement(
    where: {
      client:{eq:$clientID}
      company_id: $company
      created_at: { and: { gte: $created_at_start_thirty, lte: $created_at_end_thirty } }
      is_active: true
    }
  ) {
    count_id
  }
  sixty: agreement(
    where: {
      client:{eq:$clientID}
      company_id: $company
      created_at: { and: { gte: $created_at_start_sixty, lte: $created_at_end_sixty } }
      is_active: true
    }
  ) {
    count_id
  }
  nignty: agreement(
    where: {
      client:{eq:$clientID}
      company_id: $company
      created_at: { and: { gte: $created_at_start_nignty, lte: $created_at_end_nignty } }
      is_active: true
    }
  ) {
    count_id
  }
  booked:
    unit_vacancy_stats(where: {
      status: $Booked ,
      company_id: $company
    }) {
      count
    }

  reservation:
    unit_vacancy_stats(where: {
      status: $Reserved ,
      company_id: $company
    }) {
      count
    }


}
`