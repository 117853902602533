import { useApolloClient } from "@apollo/client"
import { Box } from '@mui/material'
import React from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { AlertDialog, Subheader, UseDebounce } from '../../components'
import { InspectionTemplateList } from '../../components/inspectionTemplateView'
import { config } from "../../config"
import { AlertContext, AuthContext } from "../../contexts"
import { getPropertyDetails } from "../../graphql/inspectionTemplate"
import { withNavBars } from "../../HOCs"
import { NetworkCall } from "../../networkcall"
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods, useWindowDimensions } from '../../utils'
import PropertyDetailsComp from './components/propertyDetails'
import InspectionItemTable from './components/table'
import { useStyles } from './style'
import { withNamespaces } from "react-i18next"
const InsPropertyDetail = ({
    t
}) => {
    const { state } = useLocation();
    const size = useWindowDimensions()
    const alert = React.useContext(AlertContext);
    const client = useApolloClient();
    const auth = React.useContext(AuthContext)
    const [propertyItems, setpropertyItems] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [totalRows, setTotalRows] = React.useState("")
    const [searchText, setSearchText] = React.useState("")
    const [limit, setLimit] = React.useState(10);
    const [permissions, setPermission] = React.useState({})
    const [templateItems, setTemplateItems] = React.useState([])
    const [page, setPage] = React.useState(1);
    const [edit, setEdit] = React.useState(false)
    const [editData, setEditData] = React.useState({})
    const [details, setDetails] = React.useState({})
    const debounce = UseDebounce()
    // Classes
    const classes = useStyles()
    // History
    const history = useHistory();
    //goback
    const goBack = () => {
        history.goBack()
    }
    const handleEdit = (type, data) => {
        setEdit(true)
        setEditData(data)
    }
    const OpenPropertyItemPopup = (type, data) => {
        getTemplateItemViewList(data.id)
        setpropertyItems(true)
    }
    const getProperty = () => {
        client
            .query({
                query: getPropertyDetails,
                fetchPolicy: "network-only",
                variables: {
                    id: state?.id,

                },
            })
            .then((response) => {
                setDetails(response.data.property[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const getTemplateItemViewList = (id) => {

        const payload = {
            id: id,
            is_active: [true]
        }

        NetworkCall(
            `${config.api_url}/inspection_template/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setTemplateItems(response?.data?.data)
                // setTotalRows(response?.data?.count)
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
            })
    }
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)

        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getTemplateItemList(state.company_id, 0, limit, "")
            }
        }

        // eslint-disable-next-line
    }, [auth])
    const handleTablePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getTemplateItemList(state?.company_id, offset, limit, "")
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getTemplateItemList(state.company_id?.value, 0, limit, "")

    }
    //reload
    const reload = () => {
        getTemplateItemList("", 0, 10, {})
    }
    //To get all templates
    const getTemplateItemList = (company_id, offset, limit, searchtext) => {

        const payload = {
            offset: offset,
            limit: limit,
            search: searchtext,
            property_id: state?.id
        }

        NetworkCall(
            `${config.api_url}/inspection_template/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                getProperty()
                setTableData(response?.data?.data)
                setTotalRows(response?.data?.count)
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "data not found",
                });
            })
    }
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getTemplateItemList("", 0, 10, e)
    }
    const render = () => {
        return (
            <Box>
                <Subheader goBack={goBack} title={state?.no} />
                <div style={{ height: size?.height - (64 + 49), overflow: "auto", padding: "24px" }}>
                    <PropertyDetailsComp title={t("propertyDetails")}
                        data={details} t={t} />
                    <Box mt={2} className={classes.root}>
                        <InspectionItemTable
                            t={t}
                            handleIcon={OpenPropertyItemPopup}
                            handleTablePagination={handleTablePagination}
                            handleChangeLimit={handleChangeLimit}
                            page={page}
                            limit={limit}
                            tableData={tableData}
                            permissions={permissions}
                            totalRows={totalRows}
                            details={state}
                            edit={edit}
                            editData={editData}
                            setEdit={setEdit}
                            setEditData={setEditData}
                            reload={reload}
                            setpropertyItems={setpropertyItems}
                            handleSearch={handleSearch}
                            searchText={searchText}
                            company={state?.company?.value}
                            height={`calc(100vh - 432px)`}

                        />
                    </Box>
                </div>                
                <AlertDialog open={propertyItems}
                    onClose={() => setpropertyItems(!propertyItems)}
                    header={state.title}
                    component={<InspectionTemplateList t={t} company={state?.company?.value} templateList={templateItems} handleEdit={handleEdit} />}
                    isNormal
                />
            </Box>
        )
    }

    return (
        <>

            {accessCheckRender(render, permissions)}
        </>
    )
}
const props = {
    boxShadow: false
}

export default withNamespaces("inspection")(withNavBars(InsPropertyDetail, props))
