import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FilterGenerator, LoadingSection, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GET_ACCOUNT_FILTER } from "../../graphql/reciept";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import { ReceiptTable } from "./components";
import { ReceiptStyles } from "./style";

const Receipt = ({ t }) => {
    const classes = ReceiptStyles()
    const history = useHistory();
    const auth = React.useContext(AuthContext);
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext)
    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [drawer, setDrawer] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const debounce = UseDebounce();
    const [limit, setLimit] = React.useState(10);
    const [loading, setLoading] = React.useState(true)
    const [filterData, setFilterData] = React.useState({
        payment_method: null,
        account: null,
        date: {
            startDate: null,
            endDate: null,
        },
    });
    const [filterValue, setFilterValue] = React.useState({
        payment_method: []
    });
    const [receiptList, setReceiptList] = React.useState({
        list: [],
        count: 0,
    })
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
                getEnum();
                if (company?.selected?.value) {
                    getReciptList(company?.selected?.value, "", 0, 10, {})
                }

            }
        }
        // eslint-disable-next-line
    }, [auth]);

    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.payment_mode])
        setFilterValue({
            payment_method: result?.payment_mode
        });
    }
    //get list
    const getReciptList = (company_id, searchText, offset, limits, filterData) => {
        setLoading(true)
        const payload = {
            company_id: company_id,
            search: searchText,
            offset: offset,
            limit: limits,
            account_no: filterData?.account ?? undefined,
            payment_mode: filterData?.payment_method ?? undefined,
            from_date: filterData?.startDate ?? undefined,
            to_date: filterData?.endDate ?? undefined,
        }
        NetworkCall(
            `${config.api_url}/receipt/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                const result = response?.data?.data?.map((val) => {
                    return {
                        ...val,
                        receipt_amount: `${val?.code} ${val?.receipt_amount}`,
                        source: val?.source ?? "-",
                        reference: val?.cheque_no ?? "-"
                    }
                })
                setReceiptList({
                    list: result,
                    count: response.data.count
                })
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })
        // eslint-disable-next-line
    }

    //handle icon
    const handleIcon = (type, data) => {
        if (type === "view") {
            history.push({
                pathname: Routes.receiptView,
                state: {
                    id: data?.id
                }
            })
        } else if (type === "edit") {
            history.push(Routes.createReceipt)
        }
    }
    //handle change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getReciptList(value?.value, "", 0, 10, {})
    }
    //apply filter
    const onApplyFilter = (data) => {
        let result = {
            account: data?.account?.map((val) => val?.value),
            payment_method: data?.payment_method?.map((val) => val?.value),
            startDate: data?.date?.startDate,
            endDate: data?.date?.endDate
        }
        getReciptList(selectedCompany?.value, "", 0, 10, result)
        setFilterData(data)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getReciptList(selectedCompany?.value, "", offset, limit, filterData)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getReciptList(selectedCompany?.value, "", 0, value, filterData)
    }
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getReciptList(selectedCompany?.value, e, 0, limit, filterData)
    }

    const render = () => {
        return <>
            {/* subheader */}
            <Subheader
                hideBackButton={true}
                select={true}
                options={companyList}
                title={t("receipts")}
                value={selectedCompany}
                goBack={() => history.goBack()}
                onchange={(e) => {
                    handleCompanyChange(e)
                }}
            />

            {
                loading ?
                    <LoadingSection top="20vh" message={t("loading")} />
                    :
                    <Box className={classes.root}>
                        <ReceiptTable
                            permission={permission}
                            openfilter={() => setDrawer(true)}
                            handleIcon={handleIcon}
                            value={selectedCompany}
                            handleChangeLimit={handleChangeLimit}
                            handlePagination={handlePagination}
                            page={page}
                            filterData={filterData}
                            list={receiptList}
                            limit={limit}
                            handleSearch={handleSearch}
                            searchText={searchText}
                        />
                    </Box>
            }


            {drawer && (
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.payment_method,
                            options: filterValue?.payment_method,
                            state_name: "payment_method",
                            label: t("Payment Method"),
                            isMulti: true
                        },
                        {
                            component: "select",
                            value: filterData?.account,
                            state_name: "account",
                            label: t("Account"),
                            isPaginate: true,
                            debounceTimeout: 800,
                            isMulti: true,
                            loadOptions: (search, array, handleLoading) => loadOptions(
                                search,
                                array,
                                handleLoading,
                                GET_ACCOUNT_FILTER,
                                'contact_account',
                                { isActive: true },
                                { label: "name", value: "account_no" }
                            ),
                        },
                        {
                            component: "minAndmaxDate",
                            label: {
                                startLabel: t("From"),
                                endLabel: t("To"),
                            },
                            placeholder: {
                                startLabel: t("From"),
                                endLabel: t("To"),
                            },
                            state_name: "date",
                            value: filterData?.date,
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            )}
        </>
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}

export default withNamespaces("receipt")(Receipt); 