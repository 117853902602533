/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import { Box, Button, Divider, Grid, InputAdornment, Stack, Switch, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import React from 'react'
import { SelectBox, TextBox, ToggleButtonComponent } from '../../components'
import { config } from '../../config'
import { AlertContext } from "../../contexts"
import { GET_AMENITIES_CATEGORY, GET_AMENITY_NAME, GET_PROPERTY_AMENITIES, GET_UNIT_AMENITY } from '../../graphql/amenitiesMapping'
import { NetworkCall } from '../../networkcall'
import { AlertProps, enumSelect, enum_types, NetWorkCallMethods } from '../../utils'
import { loadOptions } from '../../utils/asyncPaginateLoadOptions'
import { AddAmenitiesPopupStyle } from './style'
import { addHours, eachHourOfInterval, setHours, setMinutes, subSeconds } from 'date-fns'

const initialState = {
    id: null,
    amenity_category: "",
    amenity_name: "",
    amenity_reference: "",
    slot_type: "Hourly",
    status: true,
    slot: "",
    participant: "",
    chargeable: true,
    chargeable_value: "",
    edit: false,
    view: false,
    days: [],
    slot_time: [],
    error: {
        amenity_category: "",
        amenity_reference: "",
        slot_type: "",
        slot: "",
        chargeable: "",
        amount: "",
        period: "",
        status: "",
        participant: "",
        chargeable_value: "",
        slot_time: "",
        days: "",
    }
}

export const AddAmenitiesPopup = (
    {
        click = () => false,
        currency = {},
        data = {},
        companyId = "",
        closeModel = () => false,
        type = "",
        already_booked = [],
        t
    }
) => {
    const companyID = companyId
    const classes = AddAmenitiesPopupStyle()
    const [AmenitiesMapping, setAmenitiesMapping] = React.useState({ ...initialState })
    const [hours, setHour] = React.useState([])
    const alert = React.useContext(AlertContext);
    React.useEffect(() => {
        gethours()
        getEnum()
        // eslint-disable-next-line 
    }, [])
    const [enumValue, setEnumValue] = React.useState({ amenity_period: [] });
    // get enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types?.amenity_period]);
        setEnumValue({ amenity_period: result?.amenity_period });
    };
    const selectAmenitesDay = (e) => {
        if (AmenitiesMapping?.days?.includes(e)) {
            const result = AmenitiesMapping?.days?.filter((x) => x !== e)
            updateState("days", result ?? [])
        } else {
            updateState("days", [...AmenitiesMapping?.days, e])
        }

    }

    const selectSlotDate = async (e, index) => {
        if (AmenitiesMapping?.slot_time?.flatMap((x) => x).includes(moment(e).format("YYYY-MM-DD HH:mm:ss"))) {
            await AmenitiesMapping?.slot_time?.map((x, index) => {
                if (x.includes(moment(e).format("YYYY-MM-DD HH:mm:ss"))) {
                    const data = [...AmenitiesMapping.slot_time]
                    data[index] = []
                    updateState("slot_time", data)
                }
                return 0
            })
        }
        else {
            let timearr = []
            let validate = false
            for (let i = index; i <= index + parseInt(AmenitiesMapping?.slot) - 1; i++) {
                if (i < 24 && AmenitiesMapping?.slot < 24) {
                    if (!AmenitiesMapping?.slot_time.flatMap((x) => x).includes(moment(hours[i]).format("YYYY-MM-DD HH:mm:ss"))) {
                        timearr.push(moment(hours[i]).format("YYYY-MM-DD HH:mm:ss"))
                    }
                    else {
                        validate = true
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("No ") + AmenitiesMapping?.slot + t(" consecutive hours available to select"),
                        })
                    }
                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Please Select Valid slot"),
                    })
                }
            }

            if (!validate && timearr.length > 0) {
                if (timearr.length < AmenitiesMapping?.slot) {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("No ") + AmenitiesMapping?.slot + t(" consecutive hours available to select"),
                    })
                }
                else {
                    updateState("slot_time", [...AmenitiesMapping?.slot_time, timearr])
                }
            }

            // For Already booked slot check condition

            // let status = already_booked.map(e => {
            //     if (timearr.includes(e)) {
            //         return false
            //     }
            //     return 0
            // })
            // status.includes(false) &&
            // alert.setSnack({
            //     ...alert,
            //     open: true,
            //     severity: AlertProps.severity.error,
            //     msg: "Please Select another slot",
            // })
        }


    }

    // state updation
    const updateState = (key, value) => {
        let error = AmenitiesMapping?.error;
        error[key] = "";
        if (key === "amenity_category") {
            setAmenitiesMapping({ ...AmenitiesMapping, [key]: value, amenity_reference: "", error })
        } else {
            setAmenitiesMapping({ ...AmenitiesMapping, [key]: value, error })
        }
    }
    // Const Status
    const STATUS_OPTIONS = [
        { label: "Active", value: true },
        { label: "InActive", value: false }
    ]
    // Const Days
    const days = [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun"
    ]
    // Hours
    const gethours = () => setHour(eachHourOfInterval({
        start: new Date(moment(new Date()).format("YYYY"), moment(new Date()).format("M") - 1, moment(new Date()).format("D") - 1, 24),
        end: new Date(moment(new Date()).format("YYYY"), moment(new Date()).format("M") - 1, moment(new Date()).format("D"), 23)
    }))
    // Change slot type 
    const changeSlotType = (value) => {
        if (value === "Daily") {
            setAmenitiesMapping({
                ...AmenitiesMapping,
                slot_time: [],
                slot_type: value,
                slot: 1
            })
        }
        else {
            updateState('slot_type', value)
        }
    }
    // change slot
    const changeSlot = (value) => {
        setAmenitiesMapping({
            ...AmenitiesMapping,
            slot_time: [],
            slot: value
        })
    }
    // Use Effect
    React.useEffect(() => {
        if (data?.view || data?.edit) {
            getPropertyAmenitiesDetails(data?.id)
        }
        // eslint-disable-next-line
    }, [data])

    // Get view and edit data
    const getPropertyAmenitiesDetails = (id) => {
        const params = {
            query: type === "property" ? GET_PROPERTY_AMENITIES(id).loc.source.body : GET_UNIT_AMENITY(id).loc.source.body,
            variables: {},
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            params,
            null,
            true,
            false
        )
            .then((res) => {
                if (res) {
                    const result = res?.data?.data?.data[0].selected_slots.map(e => {
                        return eachHourOfInterval({
                            start: setHours(setMinutes(new Date(), e[0].substring(3, 5)), e[0].substring(0, 2)),
                            end: setHours(setMinutes(new Date(), e[1].substring(3, 5)), parseInt(e[1].substring(0, 2)))
                        })
                    })
                    const final_slot_time = result.map((e, i) => {
                        return result[i].map(data => {
                            return moment(data).format("YYYY-MM-DD HH:mm:ss")
                        })
                    })

                    setAmenitiesMapping({
                        data: res?.data?.data?.data[0],
                        id: res?.data?.data?.data[0].id,
                        amenity_category: res?.data?.data?.data[0].amenity_category,
                        amenity_reference: res?.data?.data?.data[0].amenities_type,
                        slot_type: res?.data?.data?.data[0].amenities_type?.is_booking ? "Hourly" : res?.data?.data?.data[0].period_type,
                        status: res?.data?.data?.data[0].is_active ? "Active" : "Inactive",
                        slot: res?.data?.data?.data[0].slot_partition,
                        participant: res?.data?.data?.data[0].slot_partition,
                        chargeable: res?.data?.data?.data[0].is_chargeable,
                        chargeable_value: res?.data?.data?.data[0].rate,
                        edit: data?.type === "edit" ? true : false,
                        view: data?.view ? true : false,
                        days: res?.data?.data?.data[0].applicable_days,
                        slot_time: final_slot_time,
                        error: {
                            amenity_category: "",
                            amenity_reference: "",
                            slot_type: "",
                            slot: "",
                            chargeable: "",
                            amount: "",
                            period: "",
                            status: "",
                            participant: "",
                            chargeable_value: "",
                            slot_time: ""
                        },
                    })
                }
            })
            .catch((error) => {

            });
    }

    //validation
    const validate = (data) => {
        let isValid = true;
        let error = data.error;
        if (data?.amenity_category?.id === 0) {
            isValid = false;
            error.amenity_category = t("Amenity Category  is Required");
        }

        if (data?.amenity_reference?.id === 0) {
            isValid = false;
            error.amenity_reference = "Amenity Reference  is Required";
        }
        if (AmenitiesMapping?.amenity_reference?.is_booking) {
            if (data?.chargeable === null && data?.chargeable_value === "") {
                isValid = false;
                error.chargeable = t("Chargeable is Required");
            }

            if (data?.participant === "") {
                isValid = false;
                error.participant = t("Participant is Required");
            }

            if (data?.slot === "") {
                isValid = false;
                error.slot = t("Slot is Required");
            }

            if (data?.chargeable_value === "") {
                isValid = false;
                error.chargeable_value = t("Chargeable amount is Required");
            }
            if (data?.status === "") {
                isValid = false;
                error.status = t("status is Required");
            }

            if (data?.slot_type === "") {
                isValid = false;
                error.slot_type = t("Slot Type is Required");
            }

            let const_arr = data?.slot_time.map((arr) => {
                if (arr.length !== 0)
                    return [
                        moment(arr[0]).format("HH:mm:ss"),
                        moment(subSeconds(addHours(new Date(arr[arr.length - 1]), 1), 1)).format("HH:mm:ss")
                    ]
            })

            const result = const_arr.filter(element => {
                return element !== undefined
            })


            if (
                data?.amenity_category?.id === 0 ||
                data?.amenity_reference?.value === 0 ||
                data?.chargeable === null ||
                data?.participant === "" || data?.slot === "" ||
                data?.chargeable_value === "" ||
                data?.status === "" ||
                data?.slot_type === "" || (data?.slot_type !== "Daily" && result?.length === 0) || data?.days?.length === 0
            ) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Please fill all mandatory field"),
                });
            }
            else {
                click(AmenitiesMapping)
            }
        }
        else {
            click(AmenitiesMapping)
        }
        setAmenitiesMapping({ ...AmenitiesMapping, error });

        return isValid
    };

    const submit = (arrData) => {
        validate(arrData)
    }

    return (
        <Box >
            <Grid p={2} container className={classes.body}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                        <Grid item md={4.5} xs={4.5}>
                            <SelectBox
                                label={t("Amenity Category")}
                                placeholder={t("Select Amenity Category")}
                                value={AmenitiesMapping?.amenity_category}
                                onChange={(value) => {
                                    updateState("amenity_category", value)
                                }}
                                isError={AmenitiesMapping?.error?.amenity_category?.length > 0}
                                errorMessage={AmenitiesMapping?.error?.amenity_category}
                                isReadOnly={AmenitiesMapping?.view}
                                isPaginate
                                loadOptions={(search, array, handleLoading) => loadOptions(
                                    search,
                                    array,
                                    handleLoading,
                                    GET_AMENITIES_CATEGORY,
                                    'amenity_category',
                                )}
                                debounceTimeout={800}
                                isRequired
                                selectHeight={"40px"}
                            />
                        </Grid>
                        <Grid item md={4.5} xs={4.5}>
                            <Stack>
                                <SelectBox
                                    label={t("Amenity Reference")}
                                    placeholder={t("Select Amenity Reference")}
                                    value={AmenitiesMapping?.amenity_reference}
                                    onChange={(value) => {
                                        updateState("amenity_reference", value);
                                    }}
                                    isError={AmenitiesMapping?.error?.amenity_reference?.length > 0}
                                    errorMessage={AmenitiesMapping?.error?.amenity_reference}
                                    isReadOnly={AmenitiesMapping?.view}
                                    isPaginate
                                    loadOptions={(search, array, handleLoading) => loadOptions(
                                        search,
                                        array,
                                        handleLoading,
                                        GET_AMENITY_NAME,
                                        'amenities_type_master',
                                        { categoryID: AmenitiesMapping?.amenity_category?.id },
                                        { label: "label", value: "value" },
                                        false,
                                        false,
                                        companyID,
                                        false,
                                        true
                                    )}
                                    debounceTimeout={800}
                                    isRequired
                                    key={AmenitiesMapping?.amenity_category?.id}
                                    selectHeight={"40px"}
                                />
                            </Stack>
                        </Grid>

                        <Grid item md={3} xs={3}>
                            <Typography gutterBottom style={{ color: "#98A0AC", fontSize:"0.75rem", marginBottom: "4px", fontWeight: "bold" }}>
                                {t("Status")}
                                <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                    *
                                </Typography>
                            </Typography>
                            <ToggleButtonComponent
                                options={STATUS_OPTIONS}
                                value={AmenitiesMapping?.status}
                                onChange={(value) => updateState('status', value)}
                                isMulti={false}
                                fullWidth={false}
                                isError={AmenitiesMapping?.error?.status?.length > 0}
                                errorMessage={AmenitiesMapping?.error?.status}
                                isReadOnly={AmenitiesMapping?.view}
                                labelFontSize="12px"
                                height="38px"
                            />
                        </Grid>

                    </Grid>
                </Grid>
                {
                    AmenitiesMapping?.amenity_reference?.is_booking &&
                    <Grid item xs={12} md={12} mt={2}>
                        <Grid container spacing={1}>
                            <Grid item md={12} xs={12}>
                                {
                                    AmenitiesMapping?.amenity_reference &&
                                    <Grid container spacing={2}>
                                        <Grid item md={12} xs={12}>
                                            <Grid container alignItems={"center"}>
                                                <Grid item md={3} xs={3}>
                                                    <Typography className={classes.header}>{t("Enter per slot based on")}</Typography>
                                                </Grid>
                                                <Grid item md={4} xs={8}>
                                                    <Stack direction={"row"} spacing={2} >
                                                        {
                                                            AmenitiesMapping?.slot_type === "Hourly" &&
                                                            <TextBox
                                                                label=""
                                                                placeholder="Slot"
                                                                type={"number"}
                                                                height="36px"
                                                                padding="7px 8px 4px 8px"
                                                                fontSize="12px"
                                                                value={AmenitiesMapping.slot}
                                                                isrequired
                                                                onChange={(value) => changeSlot(value.target.value)}
                                                                isError={AmenitiesMapping?.error?.slot?.length > 0}
                                                                errorMessage={AmenitiesMapping?.error?.slot}
                                                                isReadonly={AmenitiesMapping?.view}

                                                            />
                                                        }

                                                        <ToggleButtonComponent
                                                            options={enumValue?.amenity_period}
                                                            value={AmenitiesMapping?.slot_type}
                                                            onChange={(value) => changeSlotType(value)}
                                                            isMulti={false}
                                                            fullWidth={false}
                                                            isError={AmenitiesMapping?.error?.slot_type?.length > 0}
                                                            errorMessage={AmenitiesMapping?.error?.slot_type}
                                                            isReadOnly={AmenitiesMapping?.view}
                                                            isrequired
                                                            labelFontSize="12px"
                                                            height="32px"
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            <Box mt={2}>
                                                <Divider></Divider>
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Grid container alignItems={"center"}>
                                                <Grid item md={3} xs={3}>
                                                    <Typography className={classes.header}>{t("Choose amenity applicable days")}</Typography>
                                                </Grid>
                                                <Grid item md={8} xs={8} alignItems={"center"}>
                                                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                        {
                                                            days.map((e) => {
                                                                return (
                                                                    <Box className={AmenitiesMapping?.view ? 'Mui-disabled' : ""}
                                                                        onClick={() => !AmenitiesMapping?.view && selectAmenitesDay(e)}>
                                                                        <Box className={AmenitiesMapping?.days?.includes(e) ? classes.highlight_day : classes.day}>
                                                                            <Typography className={classes.day_text}>{e}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                )
                                                            })
                                                        }
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            {AmenitiesMapping?.error?.days?.length > 0 && <Box>{AmenitiesMapping?.error?.days}</Box>}
                                            <Box mt={2}>
                                                <Divider></Divider>
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <Grid container alignItems={"center"}>
                                                <Grid item md={3} xs={3}>
                                                    <Typography className={classes.header}>{t("Enter per slot participant allowed")}</Typography>
                                                </Grid>
                                                <Grid item md={4} xs={4} alignItems={"center"}>
                                                    <TextBox
                                                        label=""
                                                        placeholder={""}
                                                        type={"number"}
                                                        height="42px"
                                                        padding="8px"
                                                        fontSize="12px"
                                                        endAdornment={<InputAdornment position="end"><Typography sx={{ fontSize:"0.75rem", color: "#98A0AC", fontWeight: "semibold" }}>Participant / Hour</Typography></InputAdornment>}
                                                        value={AmenitiesMapping?.participant}
                                                        onChange={(e) => {
                                                            updateState("participant", e.target.value);
                                                        }}
                                                        isrequired
                                                        isError={AmenitiesMapping?.error?.participant?.length > 0}
                                                        errorMessage={AmenitiesMapping?.error?.participant}
                                                        isReadonly={AmenitiesMapping?.view}

                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box mt={2}>
                                                <Divider></Divider>
                                            </Box>
                                        </Grid>
                                        {
                                            AmenitiesMapping?.slot_type === "Hourly" &&
                                            <Grid item md={12} xs={12}>
                                                <Grid container alignItems={"center"}>
                                                    <Grid item md={3} xs={3}>
                                                        <Stack spacing={3}>
                                                            <Typography className={classes.header}>{t("Slot Sessions")}</Typography>
                                                            <Stack>
                                                                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                                    <Box className={classes.ex_selected}></Box>
                                                                    <Typography className={classes.desclimer}>{t("Selected Slot")}</Typography>
                                                                </Stack>
                                                                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                                    <Box className={classes.ex_auto_select}></Box>
                                                                    <Typography className={classes.desclimer}>Auto Selected Slot</Typography>
                                                                </Stack>
                                                            </Stack>
                                                            <Typography className={classes.desclimer}>
                                                                {t("If slot hour is more than 1hr, Remaining hours get auto selected")}
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item md={9} xs={9} alignItems={"center"}>
                                                        <Grid container>
                                                            {
                                                                hours.map((e, i) => {
                                                                    return (
                                                                        <Grid item md={1.71}>
                                                                            <Box className={AmenitiesMapping?.view ? 'Mui-disabled' : ""}
                                                                                onClick={() => !AmenitiesMapping?.view && selectSlotDate(e, i)}>
                                                                                <Box
                                                                                    className={Object.freeze(AmenitiesMapping?.slot_time).flatMap((x) => x).includes(moment(e).format("YYYY-MM-DD HH:mm:ss")) ? classes.selected : classes.not_selected}
                                                                                >
                                                                                    {moment(e).format("hh:ss A")}</Box>
                                                                            </Box>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                        {AmenitiesMapping?.error?.slot_time?.length > 0 && <Typography>{AmenitiesMapping?.error?.slot_time}</Typography>}
                                                    </Grid>
                                                </Grid>
                                                <Box mt={2}>
                                                    <Divider></Divider>
                                                </Box>
                                            </Grid>
                                        }
                                        <Grid item md={12}>
                                            <Grid container spacing={2} alignItems={"center"}>
                                                <Grid item md={12} xs={12}>
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item md={3} xs={3}>
                                                            <Typography className={classes.header}>{t("Is this amenity chargeable?")}</Typography>
                                                        </Grid>
                                                        <Grid item md={4} xs={4}>
                                                            <Box className={AmenitiesMapping?.view ? 'Mui-disabled' : ""}>
                                                                <CustomSwitch defaultChecked onChange={(e) => updateState('chargeable', e.target.checked)} checked={AmenitiesMapping.chargeable} disabled={AmenitiesMapping?.view ? true : false} />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={12} xs={12}>
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item md={3} xs={3}>
                                                            <Typography className={classes.header}>{t("Enter per slot chargeable Value")}</Typography>
                                                        </Grid>
                                                        <Grid item md={4} xs={4}>
                                                            <TextBox
                                                                sx={{ width: "150px" }}
                                                                label=""
                                                                placeholder={""}
                                                                type={"number"}
                                                                height="42px"
                                                                padding="8px"
                                                                fontSize="12px"
                                                                endAdornment={<InputAdornment position="end"><Typography sx={{ fontSize:"0.75rem", color: "#98A0AC", fontWeight: "semibold" }}>{currency?.symbol}</Typography></InputAdornment>}
                                                                value={AmenitiesMapping?.chargeable_value}
                                                                onChange={(e) => {
                                                                    updateState("chargeable_value", e.target.value);
                                                                }}
                                                                isrequired
                                                                isError={AmenitiesMapping?.error?.chargeable_value?.length > 0}
                                                                errorMessage={AmenitiesMapping?.error?.chargeable_value}
                                                                isReadonly={AmenitiesMapping?.view}

                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Box mt={2}>
                                                <Divider></Divider>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
            {
                !data?.view &&
                <Stack justifyContent={"space-between"} direction={"row"} sx={{ padding: "4px 12px 4px 12px" }}>
                    <Button variant='outlined' onClick={closeModel}>{t("Cancel")}</Button>
                    <Button variant='contained' onClick={() => submit(AmenitiesMapping)}>{data?.edit ? t("Update") : t("Create")}</Button>
                </Stack>
            }
        </Box>
    )
}


const CustomSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#5AC782',
            '& + .MuiSwitch-track': {
                backgroundColor: '#EEF9EE',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: '#E9E9EA',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
        borderRadius: 50,
        marginTop: 0.5
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));