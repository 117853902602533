import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { LIST_COMPANY_CONTACT, LIST_COMPANY_OWNER } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { CreateLeadContext } from "../../screens/leads/leadcontext";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { SelectBox } from "../select";
const useStyles = makeStyles((theme) => ({
  Label: {
    color: "#4E5A6B",
    fontSize:"0.75rem",
    marginTop: "10px",
  },
  Requirements: {
    padding: "10px",

    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",

    textAlign: "center",
    borderRadius: "10px",

    fontSize:"0.875rem",

    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  Filter: {
    marginBottom: "20px",
  },
}));

export const FilterComponent = () => {
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const {
    filterData,
    upDateFilter,
    selectedCompany
  } = React.useContext(CreateLeadContext);


  const [loading, setLoading] = React.useState(false)

  const loadOptions = async (search = "", array, type) => {

    setLoading(type);
    let result, query, offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    }
    else {
      offset = array?.length;
    }


    switch (type) {
      case 'contactName':
        if (selectedCompany?.label?.length === 0) {
          setLoading(null);
          return { options: [] }
        }
        query = LIST_COMPANY_CONTACT(offset, 10, search, selectedCompany?.value).loc.source.body;
        result = await networkCallBack(query);
        return {
          options: [...result?.contact],
          hasMore: (array?.length + result?.contact?.length) < result?.count[0]?.count
        }
      case 'owner':
        if (filterData?.comapnyname?.label?.length === 0) {
          setLoading(null);
          return { options: [] }

        }
        const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
        query = LIST_COMPANY_OWNER(offset, 10, search, selectedCompany?.value, clientId).loc.source.body;
        result = await networkCallBack(query);
        return {
          options: [...result?.lead_owners],
          hasMore: (array?.length + result?.lead_owners?.length) < result?.count[0]?.count
        }
      default:
        return { options: [] }
    }
  }
  const networkCallBack = async (query, variable = {}) => {

    let payload = {
      query,
      variable: variable
    };

    const options = await NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        setLoading(null);
        return main
      })
      .catch((error) => {
        setLoading(null);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return null
      });

    return options
  }
  return (
    <div>
      {/*Priority Type */}
      <Typography variant="body1" className={classes.Label} gutterBottom>
        Priority Type
      </Typography>
      <Grid container spacing={1} className={classes.Filter}>

        <Grid item xs={4}>
          <Typography
            onClick={() => {
              upDateFilter("priority", "high");
            }}
            className={
              filterData.priority?.includes("high")
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            High
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            onClick={() => {
              upDateFilter("priority", "medium");
            }}
            className={
              filterData.priority?.includes("medium")
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Medium
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            onClick={() => {
              upDateFilter("priority", "low");
            }}
            className={
              filterData.priority?.includes("low")
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Low
          </Typography>
        </Grid>
      </Grid>
      {filterData?.error?.priority?.length > 0 && (
        <span style={{ fontSize:"0.75rem", color: "red" }}>
          Priority Type  is required
        </span>
      )}

      <Divider />
      {/* Status */}
      <Typography variant="body1" className={classes.Label} gutterBottom>
        Status
      </Typography>
      <Grid container spacing={1} className={classes.Filter}>
        <Grid item xs={3}>
          <Typography
            onClick={() => {
              upDateFilter("Status", 2);
            }}
            className={
              filterData?.Status?.includes(2)
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Open
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            onClick={() => {
              upDateFilter("Status", 1);
            }}
            className={
              filterData?.Status?.includes(1)
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Closed
          </Typography>
        </Grid>
      </Grid>
      {filterData?.error?.Status?.length > 0 && (
        <span style={{ fontSize:"0.75rem", color: "red" }}>
          Status is required
        </span>
      )}
      {/* Lead Owner*/}
      <Divider />
      <Typography variant="body1" className={classes.Label} gutterBottom>
        Contact Name
      </Typography>
      <SelectBox
        label=""
        isPaginate
        loadOptions={(search, array) => loadOptions(search, array, 'contactName')}
        loading={loading === "contactName"}
        debounceTimeout={800}
        value={filterData?.contactName}
        onChange={(value) => {
          upDateFilter("contactName", value);
        }}
        //key={JSON.stringify(filterData?.comapnyname)}
        isMulti={true}
        isError={filterData?.error?.contactName?.length > 0}
        errorMessage={filterData?.error?.contactName}
      />

      {/* Lead Name */}
      <Divider style={{ margin: "20px 0px" }} />
      <Typography variant="body1" className={classes.Label} gutterBottom>
        Lead Owner
      </Typography>
      <SelectBox
        label=" "
        isPaginate
        loadOptions={(search, array) => loadOptions(search, array, 'owner')}
        loading={loading === "owner"}
        debounceTimeout={800}
        value={filterData?.leadOwner}
        key={JSON.stringify(filterData?.comapnyname)}
        onChange={(value) => {
          upDateFilter("leadOwner", value);
        }}
        isMulti={true}
        isError={filterData?.error?.leadOwner?.length > 0}
        errorMessage={filterData?.error?.leadOwner}
      />
    </div>
  );
};
