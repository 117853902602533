import gql from "graphql-tag";
export let GET_PROPERTY = gql`
query {
    property(where: { company_id: $company_id ,is_active: true, id:{in:$property}}) {
      value: id
      label: name
    }
  }
`;

export let GET_PROPERTY_BYCOMPANY =`
query {
  property(where: { company_id:{in:$company_id} ,is_active: true}) {
    value: id
    label: name
    address {
      id
      door_no
      street_1
      street_2
      street_3
      landmark
      area
      city
      district
      state
      country
      zipcode
      latitude
      longitude
      __typename
    }
    property_no
    logo
  }
}
`