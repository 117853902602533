/* eslint-disable array-callback-return */
import {
    Grid
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useLocation } from "react-router-dom";
import { AccordionWithTable } from '../../components';
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_ALL_SECURITY_REQUEST, GET_ALL_UNIT_AGREEMENT, GET_ALL_UNIT_INVOICE_OLD, GET_OTHER_RESIDENTS, GET_RESIDNET_ACCOUNTS } from '../../graphql/queries';
import { NetworkCall } from "../../networkcall";
import { AlertProps, ExtraBold, NetWorkCallMethods, SemiBold } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { ResidentDetails } from './residentDetails';
import { Bold } from '../../utils';
import { withNamespaces } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        height: `calc(100vh - 88px)`,
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "0px",
        overflow: "hidden",
    },

    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
        // borderColor: theme.palette.secondary.main,
    },
    text: {
        fontSize:"1.5rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize:"1rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    bottomTitle: {
        fontSize:"1.125rem",
        fontFamily: ExtraBold

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {
        // padding: "12px",

    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    }
}))

const getAllUnitids = (data) => {
    let list = [];
    if (data?.length > 0) {
        data.map(id => {
            list = [...list, ...id.units]
        })
    }
    return list;
}

const ResidentTableDetails = props => {
    const { t } = (props);
    const classes = useStyles();
    const size = useWindowDimensions();
    const alert = React.useContext(AlertContext);
    const [residentDetailsData, setResidentDetailsData] = React.useState([])
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get("userId");
    const companyId = new URLSearchParams(search).get("companyId");
    const getResidentDetailsById = () => {
        const payload = {
            tenantId: `${config.tenantId}`,
            companyId: Number(companyId),
            userId: userId
        };
        NetworkCall(
            `${config.api_url}/owner-resident/resident`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {

                setResidentDetailsData(response?.data?.data);
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong. Please try again"),
                });
            });

    };

    React.useEffect(() => {
        getResidentDetailsById();
        // eslint-disable-next-line
    }, []);

    const allUnitid = getAllUnitids(residentDetailsData[0]?.agreementUnits);
    const agreementId = residentDetailsData[0]?.agreementUnits?.map((val) => val.id)
    const accordionData = [
        {
            id: 1,
            title: "Security Requests",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Request Id ", field: 'requestId', },
                    { title: "Parent Name", field: 'requestName', },
                    { title: "Category", field: 'category', },
                    { title: "Raised Date", field: 'raisedDate', },
                    { title: "Status", field: 'status', },
                    { title: "Description", field: 'description', },

                ],
                path: ["sno", "requestId", "requestName", "category", "raisedDate", "status", "description",],
                dataType: [
                    { type: ["sno"], name: "sno" },
                    { type: ["text"], name: "requestId" },
                    { type: ["text"], name: "requestName" },
                    { type: ["text"], name: "category" },
                    { type: ["text"], name: "raisedDate" },
                    { type: ["status"], name: "status" },
                    { type: ["text"], name: "description" },

                ],
            },
            query: GET_ALL_SECURITY_REQUEST,
            // unitId: unitId,
            unitId: allUnitid
        },
        {
            id: 2,
            title: "Unit Agreements",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Agreement No ", field: 'agreementNo', },
                    { title: "Agreement primary contact name ", field: 'agreementPrimaryContactName', },
                    { title: "Contact No", field: 'contact', },
                    { title: "email Id", field: 'emailAddress', },
                    { title: " ", field: 'icon', },


                ],
                path: ["sno", "agreementNo", "agreementPrimaryContactName", "contact", "emailAddress",],
                dataType: [
                    { type: ["sno"], name: "sno" },
                    { type: ["text"], name: "agreementNo" },
                    { type: ["text"], name: "agreementPrimaryContactName" },
                    { type: ["text"], name: "contact" },
                    { type: ["text"], name: "emailAddress" },
                    { type: ["icon"], name: "icon" },

                ],

            },
            query: GET_ALL_UNIT_AGREEMENT,
            // unitId: unitId,
            unitId: allUnitid
        },
        {
            id: 3,
            title: "Unit Invoices",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Invoice Id ", field: 'invoiceId', },
                    { title: "Invoice Type ", field: 'invoiceType', },
                    { title: "Invoice Total", field: 'invoiceTotal', },
                    { title: "Invoice Status", field: 'invoiceStatus', },
                    { title: " ", field: 'icon', },


                ],
                path: ["sno", "invoiceId", "invoiceType", "invoiceTotal", "invoiceStatus",],
                dataType: [
                    { type: ["sno"], name: "sno" },
                    { type: ["text"], name: "invoiceId" },
                    { type: ["text"], name: "invoiceType" },
                    { type: ["text"], name: "invoiceTotal" },
                    { type: ["status"], name: "invoiceStatus" },
                    { type: ["icon"], name: "icon" },

                ],

            },
            query: GET_ALL_UNIT_INVOICE_OLD,
            unitId: allUnitid
        },
        {
            id: 10,
            title: "Accounts",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Account Name ", field: 'accountName', },
                    { title: "Account Number ", field: 'accountNumber', },
                    { title: "Company Name", field: 'companyName', },
                ],
                path: ["sno", "accountName", "accountNumber", "companyName",],
                dataType: [
                    { type: ["text"], name: "sno" },
                    { type: ["text"], name: "accountName" },
                    { type: ["text"], name: "accountNumber" },
                    { type: ["text"], name: "companyName" },
                ],
            },
            query: GET_RESIDNET_ACCOUNTS,
            agreementId: agreementId
        },
        {
            id: 5,
            title: "Other Residents",
            table: {
                heading: [
                    { title: "S.no", field: "sno", },
                    { title: "Name ", field: 'name', },
                    { title: "Contact Number ", field: 'contactNumber', },
                    { title: "Email Id", field: 'email', },

                ],
                path: ["sno", "name", "contactNumber", "email"],
                dataType: [
                    { type: ["text"], name: "sno" },
                    { type: ["text"], name: "name" },
                    { type: ["text"], name: "contactNumber" },
                    { type: ["text"], name: "email" },

                ],

            },
            query: GET_OTHER_RESIDENTS,
            unitId: allUnitid
        },
    ]

    return <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} >
                {/* {residentDetailsData?.map((item) => ( */}
                <>
                    <ResidentDetails t={t} data={residentDetailsData[0]} />
                </>
                {/* )
                )} */}
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} >
                <div
                    style={{
                        height: size.height - 88,
                        overflow: "scroll",
                    }}
                >
                    <Grid conatiner>
                        {accordionData?.map((item) => (
                            <>
                                <Grid item xs={12} marginTop={2}>
                                    <AccordionWithTable data={item} />
                                </Grid>
                            </>
                        )
                        )}
                    </Grid>
                </div>
            </Grid>
        </Grid>

    </div>
}
export default withNamespaces("residentTableDetails")(ResidentTableDetails)
