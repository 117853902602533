
import gql from "graphql-tag";

export const GET_OPPERTUNITY_DETAILS = gql`
query($search: String, $companyId: String, $clinetID: String) {
  lead(
    where: {
    id:$id
    }
  ) {
    urgent
    lead_no
    created_by
    id
    apply_grace_period
    billing_day
    lease_start_date
    lease_end_date
    lease_period
    occpation_date
    contract_start_date
    values:value
    grace_period_value
    city
   	company_name
    country_master{
      value: id
      label: country_name
    }
    expected_close_date
    probability
    budget_amount
    is_wallet_carry
    shortlisted:lead_units(where:{is_active:true}){
      id
      unit_id
    }
    status
    grace_period_type
    created_at
    budget_range
    value_type
    revenue_type
    unit_usage
    lead_source
    subject
    required_area
    billing_cycle_date
    payment_mode
    turnover_type
    billing_cycle_date
    bill_generation
    payment_period
    revenue_based_lease
    auto_renewal_escalation
    auto_renewal
    billing_start_date
    include_market
    increment_escalation
    open_agreement
    company_id
    company_detail:company {
      id
      uom
      value:id
    }
     milestone_template
    milestone_templateByID{
        id
      name
      template_type
    }
    currency_id
    currency {
      id
      symbol
    }
    contact: prospective {
      id
      first_name
      last_name
      email: email_id
      mobile_no
      get_assets_url_id: image_url
      mobile_no_country_code
      phone
      fax_no
         district
      country
      alternative_mobile
      alternative_mobile_country_code
    }
    account:contact_accountByID{
      label:name
       value:id,
       mobile_no_country_code:account_no,
       url:logo
       value1:account_no
       id
       account_no
       logo
   }
   borker:broker_accountByID{
      label:name
       value:id,
       mobile_no_country_code:account_no,
       url:logo
       value1:account_no
       id
       account_no
       logo
   }
   broker_agent_account{
    label:first_name
    value: id
  }
  conform_interest
  }
}

`;

export const GET_TODO = gql`
query {
  count: lead_checklist(where: { lead_id: $id }) {
    count: count_id
  }
  lead_checklist(
    offset: $offset
    limit: $limit
    order_by: { created_at: desc, name: asc }
    where: { lead_id: $id }
  ) {
    id
    name
    description
    is_active
  }
}

`

export const CREATE_TODO = gql`mutation($insert: [lead_checklistOutput!]) {
  lead_checklist(insert: $insert ) {
   id
  }
}`;

export const UPDATE_TODO = gql`
mutation (
  $id: ID
  $payload: payload
) {
  lead_checklist(
    where: { id: { eq: $id } }
    update: $payload
  ) {
    id
  }
}`;

export const GET_LEAD_QUOTATION = gql`
query {
  count: quotation(where: { lead_id: $id }) {
    count: count_id
  }
  quotation(
    offset: $offset
    limit: $limit
    order_by: { created_at: desc }
    where: { lead_id: $id }
  ) {
    id
    quotationNo: quotation_no
    units: quotation_units {
      count: count_id
    }
    value: total_amount
    periods: lease_period
    duration: lease_duration
    quoteExpireOn: quotation_expiry_date
    status: quote_status
  }
}

`


export const GET_LEAD_ACTIVITIES = gql`
  query ($offset: Int, $limit: Int, $id: String) {
    count: lead_activities(where: { lead_id: { eq: $id } }) {
      count: count_id
    }
    lead_activities(
      where: { lead_id: { eq: $id } }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      priority
      id
      date_time
      phone_number
      phone_code
      email_id
      location
      type
      purpose
      status
      activity_category{
        label:name
        value:id
      }
      notes
      user_profiles:created_byByID{
        first_name
        last_name
      }
      proxy_lead_activity_master:lead_activity_master{
        name
        image_url
        id
        inactive_logo
      }
    }
  }
`;
;


export const ACTIVITY_TYPES = gql`
query{
  lead_activity_master{
    id
    name
    image_url
  }
}`;


export const LEAD_NAME_OPTIONS = (offset, limit, searchTex, role_id) => gql`
query {
  count: client_user_role_access(
    where: {
      is_active: true
      user_profile: { first_name: { iregex: "${searchTex}" } }
      client_role_id:"${role_id}"
    }
  ) {
    count: count_id
  }
  client_user_role_access(
    offset: ${offset}
    limit: ${limit}
    where: {
      is_active: true
      user_profile: { first_name: { iregex: "${searchTex}" } }
      client_role_id:"${role_id}"
    }
  ) {
    id:client_role_id
    user_profile {
      id
      first_name
      last_name
    }
  }
}

`;

export const LEAD_ROLES = (offset, limit, searchText) => gql`
query {
  count: roles(where: { name: { iregex: "${searchText}" } }) {
    count: count_id
  }
  roles(
    offset: ${offset}
    limit: ${limit}
    where: { name: { iregex: "${searchText}" } }
  ) {
    value:id
    label:name
  }
}

`

export const UPDATE_TRANSFER_LEAD = gql`mutation UpdateLeadByLeadID($leadID: String, $updatePayload: leadInput) {
  lead(
    where: { id: { eq: $leadID }}
    update: $updatePayload
  ) {
    id
    transfer_remarks
  }
}`;

export const CLOSE_LEAD = gql`mutation UpdateLeadByLeadID($leadID: ID, $updatePayload: leadInput) {
  lead(
    where: { id: { eq: $leadID }, is_active: { eq: true } }
    update: $updatePayload
  ) {
    id

  }
}`