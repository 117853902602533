import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { FormGenerator } from "../../../components";
import { LocalStorageKeys, addDaysWithLess, billGenENUM, enumSelect, enum_types, getEnumValueOppertunity, wrapLabelsInT } from "../../../utils";
import {
  Billing_cycle_date, Billing_cycle_method, quotation_validity,
  validateCustom,
  validatePaymentperoid
} from "../../../utils/companySettings";
import { getDateValue } from "../../propertyFinder4/utils";
import { LeadDetailsCard } from "./index";
import { useStyles } from "./styles";
import moment from "moment";
import { GET_COUNTRY_MASTER } from "../../../graphql/queries";
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";

export const LeaseForm = ({
  data,
  updateState,
  type = "",
  purpose = "",
  setData = () => false,
  is_convert = false,
  t = () => false,
  contact_id = null,
  opperEdit = false,
  setOpperEdit = () => false
}) => {
  // const { t } = React.useContext(CreateOppertunityContext);
  const classes = useStyles();
  const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
  const [enumValue, setEnum] = React.useState({
    unit_usage: [],
    priority: [],
    source: [],
    lease_type: [],
    billGenCycle: [],
    billing_cycle_date: [],
    payment_period: [],
    apply_grace_period_type: [],
    payment_mode: [],
    turnover_type: [],
    auto_renewal_escalation: [],
  });
  const marketplace = [
    {
      label: t("Yes"),
      value: true,
    },
    {
      label: t("No"),
      value: false,
    },
  ];

  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.urgent_type,
      enum_types.l_period,
      enum_types.lead_source,
      enum_types.billing_cycle,
      enum_types.billing_cycle_date,
      enum_types.unit_payment_period,
      enum_types.apply_grace_period_type,
      enum_types.payment_mode,
      enum_types?.turnover_type,
      enum_types?.auto_renewal_escalation,
    ]);
    setEnum({
      priority: result?.urgent_type,
      lease_type: result?.l_period
        ?.filter((val) => val?.value !== "Quarterly")
        .filter((val) => val?.value !== "Daily"),
      billGenCycle: result?.billing_cycle,
      billing_cycle_date: result?.billing_cycle_date
        ?.filter((i) => i?.value !== billGenENUM.last_day)
        .filter((i) => i?.value !== billGenENUM.mid_date)
        .filter((i) => i?.value !== billGenENUM.first),
      payment_period: result?.payment_value_type
        .filter((val) => val?.value !== "On Completion")
        .filter((val) => val?.value !== "Milestone Based")
        .filter((val) => val?.value !== "Hourly")
        .filter((val) => val?.value !== "Weekly"),
      source: result?.lead_source,
      apply_grace_period_type: result?.apply_grace_period_type,
      payment_mode: result?.payment_mode,
      turnover_type: result?.turnover_type,
      auto_renewal_escalation: result?.auto_renewal_escalation
        ?.filter((val) => val?.value !== "Escalation %")
        .filter((val) => val?.value !== "RERA Index Chart"),
    });
  };
  React.useEffect(() => {
    if (data?.isEdit) {
      updateState("oppertunity_subject", data?.oppertunity_subject)
    }
    else {
      updateState("oppertunity_subject", `${moment(new Date()).format("DD-MM-YYYY HH:mm")} ${data?.name}`)
    }
    getEnum();
    // eslint-disable-next-line
  }, []);

  React.useMemo(() => {
    if ((data?.lease_start_date !== null && data?.duration?.value) ? true : false) {
      let end_date = getDateValue(
        {
          date: data?.lease_start_date ?? "",
          input: data?.duration?.value ?? "",
          period: data?.duration?.select ?? "",
        },
        true,
        data?.grace_peroid ?? 0,
        true
      );
      setData({
        ...data,
        lease_end_date: new Date(end_date) ?? null,
        billing_start_date:
          data?.apply_grace_peroid === "In the Beginning"
            ? (data?.grace_peroid !== null)
              ? addDaysWithLess(data?.lease_start_date, data?.grace_peroid ?? 0)
              : data?.lease_start_date
            : data?.lease_start_date ?? null,
        bill_cycle_date: data?.lease_start_date ? new Date(data?.lease_start_date)?.getDate() > 28 ? 1 : data?.initial_bill_cycle_date : data?.initial_bill_cycle_date
      });
    }
    else {
      if (data?.lease_start_date) {
        setData({
          ...data,
          bill_cycle_date: data?.lease_start_date ? new Date(data?.lease_start_date)?.getDate() > 28 ? 1 : data?.initial_bill_cycle_date : data?.initial_bill_cycle_date
        })

      }
      return false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.lease_start_date,
    data?.duration,
    data?.grace_peroid,
    data?.apply_grace_peroid,
    data?.payment_period
  ]);


  //on change grace peroid value
  const onChangeGracePeroidValue = (value) => {
    let error = data.error;
    error.grace_peroid = "";
    setData({
      ...data,
      grace_peroid: value,
      error,
    });
  };
  //on change start end date
  const onChangestartendDate = async (value) => {
    let error = data?.error;
    error.lease_start_date = "";
    setData({
      ...data,
      lease_start_date: value,
      billing_start_date: value,
      error,
    });
  };
  //on change lease duration
  const onChangeDuration = (value) => {
    let error = data?.error;
    error["duration"] = "";
    setData({
      ...data,
      duration: value,
      payment_period: validatePaymentperoid(
        enumValue?.payment_period,
        value?.value,
        value?.select
      )?.[0],
      error,
    });
  };
  const onChangeGracePeroid = (value) => {
    let apply_grace_period = value === true ? "In the End" : "In the Beginning";
    setData({
      ...data,
      apply_grace_peroid: apply_grace_period,
    });
  };

  const formLease1 = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 7,
        lg: 9,
      },
      isActive: true,
      component: "text",
      label: t("Opportunity Subject"),
      value: data?.oppertunity_subject,
      placeholder: t("Opportunity Subject"),
      onChange: (value) =>
        updateState("oppertunity_subject", value.target.value),
      error: data?.error?.oppertunity_subject,
      isRequired: true,
      options: [],
      height: "60px",
      // multiline: true
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 5,
        lg: 3,
      },
      isActive: true,
      component: "priority",
      label: t("Priority"),
      value: data?.priority,
      placeholder: t("Priority"),
      onChange: (value) => updateState("priority", value?.value),
      error: data?.error?.priority,
      isRequired: true,
      options: enumValue?.priority,
      is_dot: true,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",

      label: type === "Lease" ? t("Lease Start Date") : t("Manage Start Date"),
      value: data?.lease_start_date,
      placeholder: type === "Lease" ? t("Lease Start Date") : t("Manage Start Date"),
      onChange: (value) => onChangestartendDate(value),
      // isReadonly: true,
      isRequired: true,
      error: data?.error?.lease_start_date,
      minDate: new Date()
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "duration",
      label: type === "Lease" ? t("Lease Duration") : t("Manage Duartion"),
      value: data?.duration,
      onChange: (value) => onChangeDuration(value),
      // durationValue: data?.duration.select,
      placeholder: type === "Lease" ? t("Lease Duration") : t("Manage Duration"),
      isRequired: true,
      options: enumValue?.lease_type?.filter((x) => x?.value !== "Weekly"),
      isError: data?.error?.duration?.length > 0,
      errorMessage: data?.error?.duration,
      item_size: {
        item: 3,
        item1: 9
      }
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "toggle",
      label: t("Grace Period"),
      value: data?.grace_peroid,
      placeholder: t("Grace Period"),
      onChange: (value) => onChangeGracePeroidValue(value),
      error: data?.error?.grace_peroid,
      isRequired: true,
      options: wrapLabelsInT(quotation_validity, t),
      isMulti: true,
      openCustom: validateCustom([null], data?.grace_peroid),
      customValue: 0,
      custom: true,
      applyGracePeriod: data?.grace_peroid ? true : false,
      applyGracePeriodValue:
        data?.apply_grace_peroid === "In the Beginning" ? false : true,
      OnApplyGracePeriod: (value) => onChangeGracePeroid(value),
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label: type === "Lease" ? t("Lease End Date") : t("Manage End Date"),
      value: data?.lease_end_date,
      onChange: (value) => updateState("lease_end_date", value),
      placeholder: type === "Lease" ? t("Lease End Date") : t("Manage End Date"),
      isReadonly: true,
      isRequired: true,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label: t("Billing Start Date"),
      value: data?.billing_start_date,
      placeholder: t("Billing Start Date"),
      onChange: (value) => updateState("billing_start_date", value),
      error: data?.error?.billing_start_date,
      isRequired: true,
      isReadonly: true,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: data?.payment_period?.value === "Prepaid" ? false : true,
      component: "toggle",
      label: t("Billing Cycle Method"),
      value: data?.bill_cycle_method,
      placeholder: t("Billing Cycle Method"),
      onChange: (value) => updateState("bill_cycle_method", value),
      error: data?.error?.bill_cycle_method,
      isRequired: true,
      options: wrapLabelsInT(Billing_cycle_method, t),
      isMulti: true,
      isReadonly:
        data?.bill_cycle_method?.length === 0 ||
          data?.bill_cycle_method?.length === null
          ? true
          : false,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: data?.payment_period?.value === "Prepaid" ? false : true,
      component: "toggle",
      label: t("Billing Cycle Date"),
      value: data?.bill_cycle_date,
      placeholder: t("Billing Cycle Date"),

      onChange: (value) => updateState("bill_cycle_date", value),
      error: data?.error?.bill_cycle_date,
      isRequired: true,
      options: wrapLabelsInT(Billing_cycle_date, t) ?? [],
      isMulti: true,
      textBoxIsReadOnly: data?.lease_start_date ? new Date(data?.lease_start_date).getDate() > 28 ? true : false : false,
      openCustom: false,
      customValue: data?.bill_cycle_date === "As per the Agreement Date" ? "" : data?.bill_cycle_date,
      custom: data?.bill_cycle_date === "As per the Agreement Date" ? true : false,
      is_Oppertunity: true,
      customDay: data?.bill_cycle_date === "As per the Agreement Date" ? false : true,
      onCustom: (value) => updateState("bill_cycle_date", value),
      customerror: data?.error?.bill_cycle_date,
      selectBillingCycle: true,


    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg:
          data?.renewal_based_on?.value === "Escalation %" ||
            data?.renewal_based_on?.value === "Increment %"
            ? 3
            : 3,
      },
      isActive: true,
      component: "renewalSelect",
      label: t("Renewal Based on"),
      value: data?.renewal_based_on,
      placeholder: t("Renewal Based on"),
      onChange: (value) => updateState("renewal_based_on", value),
      error: data?.error?.renewal_based_on,
      isRequired: true,
      options: enumValue?.auto_renewal_escalation,
      aysnc: true,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive:
        data?.renewal_based_on?.value === "Escalation %" ||
          data?.renewal_based_on?.value === "Increment %"
          ? true
          : false,
      component: "text",
      label: t("Increment/Escalation Percentage"),
      value: data?.esclation_percentage ?? "",
      placeholder: t("Increment/Escalation Percentage"),
      onChange: (value) =>
        updateState("esclation_percentage", value.target.value),
      error: data?.error?.esclation_percentage,
      isRequired: true,
      options: marketplace,
      type: "number"
    },

    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg:
          data?.renewal_based_on?.value === "Escalation %" ||
            data?.renewal_based_on?.value === "Increment %"
            ? 3
            : 3,
      },
      isActive: true,
      component: "select",
      label: t("Payment Period"),
      value: data?.payment_period,
      placeholder: t("Payment Period"),
      onChange: (value) => updateState("payment_period", value),
      error: data?.error?.payment_period,
      isRequired: true,
      options: getEnumValueOppertunity(data?.duration?.select, data?.duration?.value, enumValue?.payment_period),
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg:
          data?.renewal_based_on?.value === "Escalation %" ||
            data?.renewal_based_on?.value === "Increment %"
            ? 3
            : 3,
      },
      isActive: true,
      component: "select",
      label: t("Payment Options"),
      value: data?.payment_option,
      placeholder: t("Payment Options"),
      onChange: (value) => updateState("payment_option", value),
      error: data?.error?.payment_option,
      isRequired: true,
      options: enumValue?.payment_mode,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "toggle",
      label: t("Auto Renewals"),
      value: data?.auto_renewal,
      placeholder: t("Auto Renewals"),
      onChange: (value) => updateState("auto_renewal", value),
      error: data?.error?.auto_renewal,
      isRequired: true,
      options: marketplace,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "toggle",
      label: t("Agreement Lock In Period"),
      value: data?.agreement_lock,
      placeholder: t("Agreement Lock In Period"),
      onChange: (value) => updateState("agreement_lock", value),
      error: data?.error?.agreement_lock,
      isRequired: true,
      options: marketplace
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "toggle",
      label: t("Wallet Credits Carry Over"),
      value: data?.wallet_carry_over,
      placeholder: t("Wallet Credits Carry Over"),
      onChange: (value) => updateState("wallet_carry_over", value),
      error: data?.error?.wallet_carry_over,
      isRequired: true,
      options: marketplace
    },


  ];

  const formLease2 = [
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "text",
      label: t("Company Name"),
      value: data?.company_name,
      placeholder: t("Company Name"),
      onChange: (value) =>
        updateState("company_name", value.target.value),
      options: [],
      // multiline: true
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "text",
      label: t("City"),
      value: data?.city,
      placeholder: t("City"),
      onChange: (value) =>
        updateState("city", value.target.value),
      options: [],
      // multiline: true
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "select",
      label: t("Country"),
      value: data?.country,
      placeholder: t("Country"),
      onChange: (value) =>
        updateState("country", value),
      isPaginate: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptions(
          search,
          array,
          handleLoading,
          GET_COUNTRY_MASTER,
          "country_master",
        )
      // multiline: true
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "text",
      label: t("Budget Amount"),
      value: data?.budget_amount,
      placeholder: t("Budget Amount"),
      onChange: (value) =>
        updateState("budget_amount", value.target.value),
      options: [],
      type: "number",
      endAdornment: <Typography className={classes.endAdornment}>{selectedCompany?.currency_symbol}</Typography>
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "text",
      label: t("Probability"),
      value: data?.probability,
      placeholder: t("Probability"),
      onChange: (value) =>
        updateState("probability", value.target.value),
      options: [],
      type: "number",
      endAdornment: <Typography className={classes.endAdornment}>%</Typography>
      // multiline: true
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
      },
      isActive: true,
      component: "toggle",
      label: t("Confirm Interest"),
      value: data?.confirm_interest,
      placeholder: t("Confirm Interest"),
      onChange: (value) => updateState("confirm_interest", value),
      error: data?.error?.confirm_interest,
      isRequired: true,
      options: marketplace
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
      },
      isActive: true,
      component: "date",
      label: t("Expected Close Period"),
      value: data?.expected_close_period,
      placeholder: t("Expected Close Period"),
      onChange: (value) => updateState("expected_close_period", value),
      minDate: new Date()
    },

  ];
  return (
    <Box >
      <Grid container spacing={1.5} className={classes.leadDetailsRoot}>
        <Grid item lg={3} md={12} sm={12} sx={{ position: "relative", width: "100%" }}>
          <Box p={2} className={classes.leadDetailsRootDivider}>
            <LeadDetailsCard
              data={{
                name: data?.name,
                image:
                  data?.image_url?.length !== 0 ? data?.image_url : null,
                email: data?.email_id,
                mobile: ` ${data.mobile?.mobile_code ?? ""} ${data.mobile?.mobile ?? "-"
                  }`,
                no: null,
                type: type,
                purpose: purpose,
                source: data?.source?.value,
                units: data?.units,
              }}
              is_lead={true}
              source_enum={enumValue?.source ?? ""}
              is_convert={is_convert}
              contact_id={contact_id}
              opperEdit={opperEdit}
              setOpperEdit={setOpperEdit}
            />

          </Box>
        </Grid>
        <Grid item lg={9} md={12} sm={12}>
          <Box p={2} className={classes.leadDetailsRootDivider}>
            <FormGenerator t={t} components={formLease1} />
            <Box py={2}><Divider></Divider></Box>
            <FormGenerator t={t} components={formLease2} />
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
};
