// import { addDays } from "date-fns";
import { LocalStorageKeys } from ".";
import { getDateValue } from "../screens/propertyFinder4/utils";
import { boardingPropertyType } from "../screens/residentOnboarding/utils/residentOnboard";
import moment from "moment";

export const unitpath = ["image", "name", "unit_id", "property_name"]
export const unitheading = [
  { title: "Image", field: "image", },
  { title: "Name", field: "name", },
  { title: "Unit Id", field: "unit_id", },
  { title: "Property Name", field: "property_name", },
]
export const unitdataType =
  [
    { type: ["avatarmanagement"], name: "image" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "property_name" },

  ]
export const salepath = ["milestone", "percentage"]
export const saleheading = [
  { title: "Description", field: "milestone", },
  { title: `Value`, field: "percentage", },
  // { title: "Payment Date", field: "payment_date", },
]

export const saledataType = [
  { type: ["text"], name: "milestone" },
  { type: [`text`], name: "percentage" },
  // { type: ["date"], name: "payment_date" },
]
export const salerows = [
  {
    milestone: "Description",
    percentage: "78",
    payment_date: "2022-05-28T18:51:15.614988+05:30"
  },
  {
    milestone: "Description",
    percentage: "78",
    payment_date: "2022-05-28T18:51:15.614988+05:30"
  }
]
export const createState = {
  contact: null,
  billingAccount: false,
  is_exist: true,
  img_url: null,
  billing: null,
  type: "",
  name: "",
  image_url: null,
  mobile: null,
  agreement_lock: true,
  alter_mobile: null,
  email_id: "",
  telephone: null,
  fax: null,
  address: "",
  facebook: "",
  twitter: "",
  linkedIn: "",
  //lead details
  lead_subject: "",
  duration: {
    select: "Monthly",
    value: "",
  },
  lease_start_date: null,
  lease_end_date: null,
  billing_start_date: "",
  payment_period: "",
  lead_details: "",
  payment_option: {
    value: "Online Payment",
    label: "Online Payment",
  },
  market_places: false,
  bill_cycle_method: "",
  bill_cycle_date: "As per the Agreement Date",
  oppertunity_subject: "",
  source: {
    value: "Direct Sales",
    label: "Direct Sales",
  },
  priority: "medium",
  revenue_base_lease: false,
  turn_over_rent_type: "",
  required_area: "",
  budget: "",
  esclation_percentage: "",
  earlist_occupation_date: "",
  auto_renewal: false,
  contract_start_date: new Date(),
  delivery_timestone_template: "",
  renewal_based_on: {
    value: "Latest Price",
    label: "Latest Price",
  },
  //quotation states
  purpose: null,
  custom_date: "",
  revenue_type: null,
  initial_bill_cycle_date: "",
  quotation_revenue_based_lease: false,
  quotation_turn_over_rent: "",
  quotation_lease_duration: "",
  quotation_lease_start_date: "",
  quotation_lease_end_date: "",
  quotation_billing_start_date: "",
  quotation_payment_peroid: "",
  quotation_payment_option: "",
  quotation_grace_period: "",
  quotation_billing_cycle: "",
  quotation_billing_cycle_date: "",
  quotation_description: "",
  quotation_renewal_based_on: "",
  quotation_auto_renewal: false,
  convert_to_open_agreement: { is_active: true },
  quotation_escalculation_percent: "",
  quotation_include_market_place: false,
  quotation_handover_date: "",
  short_term: "",
  quotation_contract_start_date: new Date(),
  quotation_milestone: "",
  company: "",
  searchdata: "",
  contact_id: "",
  lead_id: "",
  units: [],
  pdf: "",
  disableForm: false,
  apply_grace_peroid: "In the Beginning",
  grace_peroid: null,
  lease_date: "",
  mailstoneList: "",
  proxy_lead_id: "",
  is_convert: false,
  Whatsapp: "",
  existing: false,
  selectedUnit: [],
  lead_owner: null,
  fixUnit: [],
  edit_owner: null,
  property_id: null,
  changeUnit: [],
  mileStoneName: "",
  payment_period_value: "",
  isEdit: false,
  shortlist_convert: false,
  milestoneTemplate: "",
  typeValue: "",
  reload: false,
  isValid: true,
  mailstoneLists: [],
  show_property: false,
  quote_id: null,
  deleted_property: [],
  unit_type: 1,
  property_against: null,
  properties: [],
  owner: null,
  property: [],
  is_existing: false,
  opportunity_assets: [],
  wallet_carry_over: false,
  company_name: "",
  city: "",
  country: "",
  budget_amount: "",
  probability: "",
  expected_close_period: "",
  confirm_interest: false,
  error: {
    contact: "",
    revenue_type: "",
    purpose: "",
    type: "",
    name: "",
    image_url: "",
    mobile: "",
    alter_mobile: "",
    email_id: "",
    telephone: "",
    fax: "",
    address: "",
    facebook: "",
    twitter: "",
    linkedIn: "",
    //lead details
    duration: "",
    lease_start_date: "",
    lease_end_date: "",
    billing_start_date: "",
    payment_period: "",
    lead_details: "",
    payment_option: "",
    market_places: "",
    bill_cycle_method: "",
    bill_cycle_date: "",
    oppertunity_subject: "",
    source: "",
    priority: "",
    revenue_base_lease: "",
    turn_over_rent_type: "",
    required_area: "",
    budget: "",
    esclation_percentage: "",
    earlist_occupation_date: "",
    auto_renewal: "",
    contract_start_date: "",
    delivery_timestone_template: "",
    renewal_based_on: "",
    custom_date: "",
    quotation_custom_date: null,
    quotation_revenue_based_lease: "",
    quotation_turn_over_rent: "",
    quotation_lease_duration: "",
    quotation_lease_start_date: "",
    quotation_lease_end_date: "",
    quotation_billing_start_date: "",
    quotation_payment_peroid: "",
    quotation_payment_option: "",
    quotation_grace_period: "",
    quotation_billing_cycle: "",
    quotation_billing_cycle_date: "",
    quotation_description: "",
    quotation_renewal_based_on: "",
    quotation_auto_renewal: "",
    convert_to_open_agreement: "",
    quotation_escalculation_percent: "",
    quotation_include_market_place: "",
    quotation_handover_date: "",
    short_term: "",
    quotation_milestone: "",
    company: "",
    searchdata: "",
    contact_id: "",
    lead_id: "",
    units: "",
    pdf: "",
    disableForm: false,
    lease_date: "",
    billingCycleCustomDate: new Date(),
    wallet_carry_over: ""
  },
};
export const returnContactCreatePayload = (dataNew) => {
  let result = {
    "first_name": dataNew?.name,
    "mobile_no_country_code": dataNew?.mobile?.mobile_code,
    "mobile_no": dataNew?.mobile?.mobile,
    "alternative_mobile": dataNew?.alter_mobile?.mobile,
    "alternative_mobile_country_code": dataNew?.alter_mobile?.mobile_code,
    "email_id": dataNew?.email_id,
    "fax_no": dataNew?.fax?.mobile,
    "door_no": dataNew?.address?.doorNo,
    "street_1": dataNew?.address?.addressLineOne,
    "street_2": dataNew?.address?.addressLineTwo,
    "landmark": dataNew?.address?.landmark,
    "country": dataNew?.address?.country,
    "longitude": dataNew?.address?.longitude,
    "latitude": dataNew?.address?.latitude,
    "area": dataNew?.address?.area,
    "state": dataNew?.address?.state,
    "zipcode": dataNew?.address?.pincode,
    "city": dataNew?.address?.city,
    "facebook": dataNew?.facebook,
    "linkedin": dataNew?.linkedIn,
    "twitter": dataNew?.twitter,
    "image_url": dataNew?.image_url,
    "client": localStorage.getItem(LocalStorageKeys.clinetID),
    "phone": dataNew?.telephone?.mobile,
    "phone_code": dataNew?.telephone?.mobile_code,
    "fax_code": dataNew?.fax?.mobile_code,
    "company_id": dataNew?.company,
    "is_active": true
  }
  return result
}
export const returnConvertLeadPayload = (dataNew) => {
  let result = {
    id: dataNew?.proxy_lead_id ?? "",
    data: {
      status: "Converted",
    }
  };
  return result
}
export const returnLeadCreatePayload = (dataNew, prospective_id, selectedAccount) => {
  let occupation_date = dataNew?.earlist_occupation_date?.length !== 0 ? moment(dataNew?.earlist_occupation_date).format("YYYY-MM-DD") : null;
  let contract_start_date = dataNew?.contract_start_date?.length !== 0 ? moment(dataNew?.contract_start_date).format("YYYY-MM-DD") : null;

  let result = {



    "subject": dataNew?.oppertunity_subject?.length > 0 ? dataNew?.oppertunity_subject : null,
    "unit_usage": dataNew?.purpose?.value?.length > 0 ? dataNew?.purpose?.value : null,
    "lead_source": dataNew?.source?.value?.length > 0 ? dataNew?.source?.value : null,
    "urgent": dataNew?.priority?.length > 0 ? dataNew?.priority : null,
    "lease_start_date": dataNew?.revenue_type?.value === "Sale" ? occupation_date : dataNew?.lease_start_date !== null ? new Date(dataNew?.lease_start_date) : null,
    "lease_end_date": dataNew?.revenue_type?.value === "Sale" ? "2999-12-30" : dataNew?.lease_end_date !== null ? new Date(dataNew?.lease_end_date) : null,
    "budget_range": [dataNew?.budget?.min?.length > 0 ? dataNew?.budget?.min : null, dataNew?.budget?.max?.length > 0 ? dataNew?.budget?.max : null],
    "currency_id": dataNew?.budget?.select?.value?.length > 0 ? dataNew?.budget?.select?.value : null,
    "required_area": [dataNew?.required_area?.min?.length > 0 ? dataNew?.required_area?.min : null, dataNew?.required_area?.max?.length > 0 ? dataNew?.required_area?.max : null],
    "lease_period": dataNew?.duration?.select ?? null,
    "value": dataNew?.duration?.value ? parseInt(dataNew?.duration?.value) : null,
    "grace_period_type": "Days",
    "grace_period_value": (dataNew?.grace_peroid?.length > 0 && !dataNew?.grace_peroid) ? dataNew?.grace_peroid : undefined,
    "prospective_id": prospective_id,
    "value_type": dataNew?.required_area?.select?.value ?? null,
    "created_by": localStorage.getItem(LocalStorageKeys.userProfileID),
    "updated_by": localStorage.getItem(LocalStorageKeys.userProfileID),
    "created_at": new Date().toISOString(),
    "updated_at": new Date().toISOString(),
    "lead_owner": localStorage.getItem(LocalStorageKeys.profileID),
    "company_id": dataNew?.company ?? null,
    "revenue_type": dataNew?.revenue_type?.value ?? null,
    "client": localStorage.getItem(LocalStorageKeys.clinetID),
    "billing_start_date": dataNew?.revenue_type?.value === "Sale" ? contract_start_date : dataNew?.billing_start_date?.length !== 0 ? dataNew?.billing_start_date : null,
    "revenue_based_lease": dataNew?.revenue_base_lease ?? null,
    "auto_renewal_escalation": dataNew?.renewal_based_on?.value ?? null,
    "payment_mode": dataNew?.payment_option?.value ?? null,
    "turnover_type": dataNew?.turn_over_rent_type?.value ?? null,
    "bill_generation": dataNew?.bill_cycle_method ?? null,
    "billing_cycle_date": dataNew?.payment_period?.value === "Prepaid" ? null : dataNew?.bill_cycle_date === "As per the Agreement Date" ? dataNew?.bill_cycle_date : "Custom Date",
    "apply_grace_period": dataNew?.apply_grace_peroid?.length > 0 ? dataNew?.apply_grace_peroid : null,
    "include_market": dataNew?.market_places ?? null,
    "increment_escalation": dataNew?.esclation_percentage?.length > 0 ? dataNew?.esclation_percentage : null,
    "billing_day": dataNew?.payment_period?.value === "Prepaid" ? null : dataNew?.custom_date?.length > 0 ? dataNew?.custom_date : null,
    "payment_period": dataNew?.payment_period?.value ?? null,
    "milestone_template": dataNew?.delivery_timestone_template?.value ?? null,
    "contract_start_date": dataNew?.revenue_type?.value === "Sale" ? contract_start_date : null,
    "occpation_date": dataNew?.revenue_type?.value === "Sale" ? occupation_date : null,
    "status": 2,
    "is_active": true,
    "contact_account": selectedAccount?.normal?.id ?? undefined,
    "broker_account": selectedAccount?.broker?.id ?? undefined,
    "is_agreement_lock": dataNew?.agreement_lock,
    is_wallet_carry: dataNew?.wallet_carry_over ?? false,
    company_name: dataNew?.company_name?.length ? dataNew?.company_name : null,
    city: dataNew?.city?.length ? dataNew?.city : null,
    country_id: dataNew?.country?.label?.length ? dataNew?.country?.value : null,
    expected_close_date: dataNew?.expected_close_period.toString()?.length ? dataNew?.expected_close_period : null,
    probability: dataNew?.probability?.toString()?.length ? dataNew?.probability : null,
    budget_amount: dataNew?.budget_amount?.toString()?.length ?  dataNew?.budget_amount : null,
    internal_lead_source_id: dataNew?.internal_lead_source?.label?.length ? dataNew?.internal_lead_source?.value : null,
    broker_agent_account: selectedAccount?.agent?.label?.length ? selectedAccount?.agent?.value : null  
  }

  return result;
}
export const returnOppertunityCreatePayload = (dataNew, prospective_id, selectedAccount, is_edit) => {
  let occupation_date = dataNew?.earlist_occupation_date?.length !== 0 ? moment(dataNew?.earlist_occupation_date).format("YYYY-MM-DD") : null;
  let contract_start_date = dataNew?.contract_start_date?.length !== 0 ? moment(dataNew?.contract_start_date).format("YYYY-MM-DD") : null;
  let result = {
    "subject": dataNew?.oppertunity_subject,
    "unit_usage": dataNew?.purpose?.value?.length > 0 ? dataNew?.purpose?.value : null,
    "lead_source": dataNew?.source?.value,
    "urgent": dataNew?.priority,
    "lease_start_date": dataNew?.revenue_type?.value === "Sale" ? occupation_date : dataNew?.lease_start_date !== null ? dataNew?.lease_start_date : null,
    "lease_end_date": dataNew?.revenue_type?.value === "Sale" ? '2099-12-30' : dataNew?.lease_end_date !== null ? dataNew?.lease_end_date : null,
    //"budget_range": [dataNew?.budget?.min?.length > 0 ? dataNew?.budget?.min : null, dataNew?.budget?.max?.length > 0 ? dataNew?.budget?.max : null],
    "currency_id": dataNew?.budget?.select?.value,
    //"required_area": [dataNew?.required_area?.min?.length > 0 ? dataNew?.required_area?.min : null, dataNew?.required_area?.max?.length > 0 ? dataNew?.required_area?.max : null],
    "lease_period": dataNew?.duration?.select ?? null,
    "value": parseInt(dataNew?.duration?.value),
    "grace_period_type": "Days",
    "grace_period_value": dataNew?.grace_peroid?.length > 0 ? dataNew?.grace_peroid : null,
    "prospective_id": prospective_id,
    "value_type": dataNew?.required_area?.select?.value,
    "created_by": localStorage.getItem(LocalStorageKeys.userProfileID),
    "updated_by": localStorage.getItem(LocalStorageKeys.userProfileID),
    "created_at": !is_edit ? new Date().toISOString() : undefined,
    "updated_at": is_edit ? new Date().toISOString() : undefined,
    "lead_owner": !is_edit ? localStorage.getItem(LocalStorageKeys.profileID) : undefined,
    "company_id": dataNew?.company,
    "revenue_type": dataNew?.revenue_type?.value,
    "client": localStorage.getItem(LocalStorageKeys.clinetID),
    "billing_start_date": dataNew?.revenue_type?.value === "Sale" ? contract_start_date : dataNew?.billing_start_date?.length !== 0 ? new Date(dataNew?.billing_start_date) : null,
    "revenue_based_lease": dataNew?.revenue_base_lease,
    "auto_renewal_escalation": dataNew?.renewal_based_on?.value ?? null,
    "payment_mode": dataNew?.payment_option?.value,
    "turnover_type": dataNew?.turn_over_rent_type?.value,
    "bill_generation": dataNew?.bill_cycle_method,
    "billing_cycle_date": dataNew?.payment_period?.value === "Prepaid" ? null : dataNew?.bill_cycle_date === "As per the Agreement Date" ? dataNew?.bill_cycle_date : "Custom Date",
    "apply_grace_period": dataNew?.apply_grace_peroid?.length > 0 ? dataNew?.apply_grace_peroid : null,
    "include_market": dataNew?.market_places,
    "increment_escalation": dataNew?.esclation_percentage?.length > 0 ? dataNew?.esclation_percentage : null,
    "billing_day": dataNew?.payment_period?.value === "Prepaid" ? null : dataNew?.bill_cycle_date === "As per the Agreement Date" ? null : dataNew?.bill_cycle_date,
    "payment_period": dataNew?.payment_period?.value ?? dataNew?.payment_period,
    "milestone_template": dataNew?.delivery_timestone_template?.value ?? null,
    "contract_start_date": dataNew?.revenue_type?.value === "Sale" ? contract_start_date : null,
    "occpation_date": dataNew?.revenue_type?.value === "Sale" ? occupation_date : null,
    "is_active": true,
    "status": 2,
    "contact_account": selectedAccount?.normal?.id ?? undefined,
    "broker_account": selectedAccount?.broker?.id ?? undefined,
    "auto_renewal": dataNew?.auto_renewal ?? false,
    "proxy_lead_id": dataNew?.proxy_lead_id?.length > 0 ? dataNew?.proxy_lead_id : undefined,
    "is_agreement_lock": dataNew?.agreement_lock,
    is_wallet_carry: dataNew?.wallet_carry_over,
    company_name: dataNew?.company_name?.length ? dataNew?.company_name : null,
    city: dataNew?.city?.length ? dataNew?.city : null,
    country_id: dataNew?.country?.label?.length ? dataNew?.country?.value : null,
    expected_close_date: dataNew?.expected_close_period !== "" ? dataNew?.expected_close_period : null,
    probability: dataNew?.probability?.toString().length ? dataNew?.probability : null,
    budget_amount: dataNew?.budget_amount?.toString()?.length ? dataNew?.budget_amount : null,
    internal_lead_source_id: dataNew?.internal_lead_source?.label?.length ? dataNew?.internal_lead_source?.value : null,
    broker_agent_account: selectedAccount?.agent?.label?.length ? selectedAccount?.agent?.value : null,
    conform_interest: dataNew?.confirm_interest ?? false
  }


  return result;
}
export const returnOppertunityEditPayload = (data, state, primary, existing) => {
  let unit_ids = data?.unit?.map((x) => {
    return {
      id: x?.unitByID?.id ?? "",
      logo: x?.unitByID?.logo ?? "",
      name: x?.unitByID?.unit_name ?? "",
      property_id: x?.unitByID?.property?.id ?? "",
      property_name: x?.unitByID?.property?.name ?? "",
      unit_no: x?.unitByID?.unit_no ?? "",
      is_active: true
    };
  })

  let result = {
    is_existing: true,
    primary: primary,
    name: data?.contact?.first_name,
    image_url: data?.contact?.get_assets_url_id,
    mobile: {
      mobile: data?.contact?.mobile_no,
      mobile_code: data?.contact?.mobile_no_country_code,
    },
    alter_mobile: {
      mobile: data?.contact?.alternative_mobile ?? "",
      mobile_code: data?.contact?.alternative_mobile_country_code,
    },
    email_id: data?.contact?.email ?? "",
    telephone: {
      mobile: data?.contact?.phone ?? "",
      mobile_code: data?.contact?.phone_code,
    },
    fax: {
      mobile: data?.contact?.fax_no ?? "",
      mobile_code: data?.contact?.fax_code,
    },
    address: {
      doorNo: data?.contact?.door_no,
      addressLineOne: data?.contact?.street_1,
      addressLineTwo: data?.contact?.street_2,
      landmark: data?.contact?.landmark,
      country: data?.contact?.country,
      area: data?.contact?.area,
      state: data?.contact?.state,
      pincode: data?.contact?.zipcode,
      city: data?.contact?.city,
      latitude: data?.contact?.latitude,
      longitude: data?.contact?.longitude,
    },
    facebook: data?.contact?.facebook,
    twitter: data?.contact?.twitter,
    linkedIn: data?.contact?.linkedin,
    lead_subject: data?.subject,
    duration: {
      select: data?.lease_period,
      value: data?.value
    },
    lease_start_date: new Date(data?.lease_start_date) ?? null,
    lease_end_date: new Date(data?.lease_end_date) ?? null,
    payment_period: {
      value: data?.payment_period,
      label: data?.payment_period
    },
    payment_option: {
      label: data?.payment_mode,
      value: data?.payment_mode,
    },
    market_places: data?.include_market,
    bill_cycle_method: data?.bill_generation,
    bill_cycle_date: data?.billing_cycle_date === "As per the Agreement Date" ? data?.billing_cycle_date : data?.billing_day,
    oppertunity_subject: data?.subject,
    source: {
      value: data?.lead_source,
      label: data?.lead_source
    },
    priority: data?.urgent,
    revenue_base_lease: data?.revenue_based_lease,
    turn_over_rent_type: data?.turnover_type
      ? {
        label: data?.turnover_type,
        value: data?.turnover_type,
      }
      : "",
    required_area: {
      min: data?.required_area?.[0],
      max: data?.required_area?.[1],
      select: {
        label: data?.company?.uom,
        value: data?.company?.uom,
      },
    },
    budget: {
      min: data?.budget_range?.[0],
      max: data?.budget_range?.[1],
      select: {
        label: data?.currency?.symbol,
        value: data?.currency?.id,
      },
    },
    esclation_percentage: data?.increment_escalation === null ? null : JSON.stringify(data?.increment_escalation),
    auto_renewal: data?.auto_renewal,
    delivery_timestone_template: data?.milestone_template ?? null,
    renewal_based_on: {
      label: data?.auto_renewal_escalation,
      value: data?.auto_renewal_escalation,
    },
    purpose: {
      value: data?.unit_usage,
      label: data?.unit_usage
    },
    revenue_type: {
      value: data?.revenue_type,
      label: data?.revenue_type,
    },
    agreement_lock: data?.is_agreement_lock,
    company: data?.company?.id,
    contact_id: data?.contact?.value,
    lead_id: data?.id,
    lead_no: data?.lead_no,
    lead_source: data?.lead_source,
    lead_name: data?.lead_name,
    disableForm: false,
    apply_grace_peroid: data?.apply_grace_period,
    grace_peroid: data?.grace_period_value !== null ? JSON.stringify(data?.grace_period_value) : null,
    lease_date:
      data?.lease_start_date?.length > 0
        ? {
          input: data?.value,
          period: data?.lease_period,
          date: new Date(data?.lease_start_date),
        }
        : "",
    earlist_occupation_date: data?.revenue_type === "Sale" ? new Date(data?.lease_start_date) : "",
    contract_start_date: new Date(data?.contract_start_date),
    contact_account: data?.contact_account,
    selectedUnit: [],
    lead_owner: null,
    fixUnit: unit_ids ?? [],
    edit_owner: null,
    property_id: data?.unit?.[0]?.property_id ?? null,
    changeUnit: [],
    mileStoneName: data?.milestone_template?.label ?? data?.payment_period,
    mailstoneList: [],
    custom_date: data?.billing_day ?? null,
    isEdit: true,
    units: unit_ids ?? [],
    billing_start_date: data?.grace_peroid !== null ? data?.billing_start_date : null,
    payment_period_value: {
      value: data?.payment_period,
      label: data?.payment_period
    },
    proxy_detail: data?.proxy_detail,
    internal_lead_source: data?.internal?.name ? {
      label: data?.internal?.name,
      value: data?.internal?.id
    } : "",
    city: data?.city ?? "",
    company_name: data?.company_name ?? "",
    wallet_carry_over: data?.is_wallet_carry ?? false,
    country: data?.country?.country_name ? {
      label: data?.country?.country_name,
      value: data?.country?.id
    } : "",
    expected_close_period: data?.expected_close_date ? new Date(data?.expected_close_date) : "",
    probability: data?.probability ?? "",
    budget_amount: data?.budget_amount ?? "",
    confirm_interest: data?.conform_interest ?? false,
    error: {
      name: "",
      image_url: "",
      mobile: "",
      alter_mobile: "",
      email_id: "",
      telephone: "",
      fax: "",
      address: "",
      facebook: "",
      twitter: "",
      linkedIn: "",
      duration: "",
      lease_start_date: "",
      lease_end_date: "",
      billing_start_date: "",
      payment_period: "",
      lead_details: "",
      payment_option: "",
      market_places: "",
      bill_cycle_method: "",
      bill_cycle_date: "",
      oppertunity_subject: "",
      source: "",
      priority: "",
      revenue_base_lease: "",
      turn_over_rent_type: "",
      required_area: "",
      budget: "",
      esclation_percentage: "",
      earlist_occupation_date: "",
      auto_renewal: "",
      contract_start_date: "",
      delivery_timestone_template: "",
      renewal_based_on: "",
      custom_date: null,
      purpose: "",
      revenue_type: "",
      company: "",
      contact_id: "",
      lead_id: "",
      disableForm: false,
      lease_date: "",
    },
  };
  return result
}
export const returnConvertOppertunityEditPayload = (data, company, res, proxy_id, is_converted) => {
  let units = []
  let properties = []

  let unit_ids = is_converted ? data?.units : data?.unit?.map((x) => {
    return {
      id: x?.unitByID?.id ?? "",
      logo: x?.unitByID?.logo ?? "",
      name: x?.unitByID?.unit_name ?? "",
      property_id: x?.unitByID?.propertyByID?.id ?? "",
      property_name: x?.unitByID?.propertyByID?.name ?? "",
      unit_no: x?.unitByID?.unit_no ?? "",
      is_active: true
    };
  })

  if (data?.proxy_lead_units?.length > 0) {
    units = data?.proxy_lead_units?.map((unit) => {
      return {
        "label": unit?.unitByID?.unit_name,
        "value": unit?.unitByID?.id,
        "url": unit?.unitByID?.logo,
        "label1": unit?.unitByID?.unit_no,
        "unit_id": unit?.unitByID?.id,
        "id": unit?.unitByID?.id,
        "unit_no": unit?.unitByID?.unit_no,
        "unit_name": unit?.unitByID?.unit_name,
        "logo": unit?.unitByID?.logo,
        "property_id": unit?.unitByID?.propertyByID?.id,
        "property_name": unit?.unitByID?.propertyByID?.name,
        "status": "Vacant",
        "is_active": true,
        // "lead_unit_id":x?.id,
        "property": unit?.unitByID?.property

      }
    })
  }
  if (data?.proxy_lead_units?.length > 0) {
    properties = data?.proxy_lead_units?.map((unit) => {
      return {
        "label": unit?.unitByID?.unit_name,
        "value": unit?.unitByID?.id,
        "url": unit?.unitByID?.logo,
        "label1": unit?.unitByID?.unit_no,
        "unit_id": unit?.unitByID?.id,
        "id": unit?.unitByID?.id,
        "unit_no": unit?.unitByID?.unit_no,
        "unit_name": unit?.unitByID?.unit_name,
        "logo": unit?.unitByID?.logo,
        "property_id": unit?.unitByID?.propertyByID?.id,
        "property_name": unit?.unitByID?.propertyByID?.name,
        "status": "Vacant",
        "is_active": true,
        // "lead_unit_id":x?.id,
        "property": unit?.unitByID?.property


      }
    })
  }

  let end_date = getDateValue(
    {
      date: data?.lease_start_date ?? "",
      input: data?.duration?.value ?? "",
      period: data?.duration?.select ?? "",
    },
    true,
    0,
    true
  );

  const purpose = data?.purpose === "Mixed" ? "Commercial" : data?.purpose

  let result = {
    properties: properties,
    account: data?.account ?? null,
    owner: units?.length > 0 ? units?.[0]?.contact_account : null,
    property: units,
    is_exist: false,
    billingAccount: false,
    billing: null,
    type: "",
    name: data?.name ?? null,
    image_url: null,
    mobile: data?.mobile_no ? {
      mobile: data?.mobile_no ?? "",
      mobile_code: data?.mobile_no_country_code ?? ""
    } : null,
    alter_mobile: "",
    email_id: data?.email ?? "",
    telephone: "",
    fax: "",
    address: "",
    facebook: "",
    twitter: "",
    linkedIn: "",
    //lead details
    lead_subject: null,
    duration: data?.duration,
    lease_start_date: data?.lease_start_date ? new Date(data?.lease_start_date) : null,
    lease_end_date: new Date(end_date),
    billing_start_date: data?.lease_start_date ? new Date(data?.lease_start_date) : null,
    payment_period: res?.payment_period ? {
      value: res?.payment_period,
      label: res?.payment_period
    } : null,
    quotation_custom_date: null,
    lead_details: "",
    market_places: false,
    bill_cycle_method: res?.billing_cycle ?? null,
    bill_cycle_date: res?.billing_cycle_date ?? "As per the Agreement Date",
    oppertunity_subject: `${moment(new Date()).format("DD-MM-YYYY HH:mm")} ${data?.name}`,
    priority: data?.urgent ?? "medium",
    revenue_base_lease: false,
    disableForm: false,
    turn_over_rent_type: "",

    esclation_percentage: "",
    earlist_occupation_date: data?.revenue_type === "Sale" ? data?.lease_start_date ? new Date(data?.lease_start_date) : null : null,
    auto_renewal: false,
    contract_start_date: "",
    delivery_timestone_template: "",
    //quotation states
    purpose: data?.purpose ? boardingPropertyType.find(i => i?.value === purpose) : null,
    custom_date: res?.billing_day ?? null,
    revenue_type: data?.revenue_type ? {
      label: data?.revenue_type,
      value: data?.revenue_type
    } : "",
    quotation_revenue_based_lease: false,
    quotation_turn_over_rent: "",
    quotation_lease_duration: "",
    quotation_lease_start_date: "",
    quotation_lease_end_date: "",
    quotation_billing_start_date: "",
    quotation_payment_peroid: "",
    quotation_payment_option: "",
    quotation_grace_period: "",
    quotation_billing_cycle: "",
    quotation_billing_cycle_date: "",
    quotation_description: "",
    quotation_renewal_based_on: "",
    quotation_auto_renewal: false,
    convert_to_open_agreement: { is_active: true },
    quotation_escalculation_percent: "",
    quotation_include_market_place: false,
    quotation_handover_date: "",
    short_term: "",
    quotation_contract_start_date: "",
    quotation_milestone: "",
    company: company?.value ?? "",
    searchdata: "",
    contact_id: "",
    lead_id: "",
    pdf: "",
    grace_peroid: null,
    lease_date: "",
    proxy_lead_id: proxy_id,
    is_convert: true,
    payment_option: {
      value: "Online Payment",
      label: "Online Payment",
    },
    source: {
      value: "Direct Sales",
      label: "Direct Sales",
    },
    renewal_based_on: {
      value: "Latest Price",
      label: "Latest Price",
    },
    apply_grace_peroid: "In the Beginning",
    units: unit_ids ?? [],
    fixUnit: unit_ids ?? [],
    edit_owner: null,
    property_id: null,
    changeUnit: [],
    mileStoneName: data?.milestone_template?.label ?? data?.payment_period,
    mailstoneList: [],
    selectedUnit: [],
    deleted_property: [],
    unit_type: 1,
    is_existing: true,
    primary: data?.primary,
    contact: data?.oppertunity?.opportunity?.contact ?? null,
    city: data?.city,
    company_name: data?.company_name,
    country: data?.country,
    internal_lead_source: data?.internal_lead_source,
    confirm_interest: data?.confirm_interest,
    error: {
      type: "",
      name: "",
      image_url: "",
      mobile: "",
      alter_mobile: "",
      email_id: "",
      telephone: "",
      fax: "",
      address: "",
      facebook: "",
      twitter: "",
      linkedIn: "",
      //lead details
      duration: "",
      lease_start_date: "",
      lease_end_date: "",
      billing_start_date: "",
      payment_period: "",
      lead_details: "",
      payment_option: "",
      market_places: "",
      bill_cycle_method: "",
      bill_cycle_date: "",
      oppertunity_subject: "",
      source: "",
      priority: "",
      revenue_base_lease: "",
      turn_over_rent_type: "",
      required_area: "",
      budget: "",
      esclation_percentage: "",
      earlist_occupation_date: "",
      auto_renewal: "",
      contract_start_date: "",
      delivery_timestone_template: "",
      renewal_based_on: "",
      custom_date: "",
      quotation_custom_date: null,
      //quotation states
      purpose: "",
      revenue_type: "",
      quotation_revenue_based_lease: "",
      quotation_turn_over_rent: "",
      quotation_lease_duration: "",
      quotation_lease_start_date: "",
      quotation_lease_end_date: "",
      quotation_billing_start_date: "",
      quotation_payment_peroid: "",
      quotation_payment_option: "",
      quotation_grace_period: "",
      quotation_billing_cycle: "",
      quotation_billing_cycle_date: "",
      quotation_description: "",
      quotation_renewal_based_on: "",
      quotation_auto_renewal: "",
      convert_to_open_agreement: "",
      quotation_escalculation_percent: "",
      quotation_include_market_place: "",
      quotation_handover_date: "",
      short_term: "",
      quotation_milestone: "",
      company: "",
      searchdata: "",
      contact_id: "",
      lead_id: "",
      units: "",
      pdf: "",
      disableForm: false,
      lease_date: "",
    }


  }
  return result
}
export const salepathqutation = (value) => { return ["milestone", value === "percentage" ? "percentage_value" : "percentage_value", "payment_date"] }
export const saleheadingqutation = (value) => {
  return [
    { title: "Description", field: "milestone", },
    { title: `${value ?? "Value"}`, field: value === "percentage" ? "percentage_value" : "percentage_value", },
    { title: "Payment Date", field: "payment_date", },
  ]
}
export const saledataTypequtation = (value) => {
  return [
    { type: ["text"], name: "milestone" },
    { type: [`${value === "percentage" ? "text" : "milestone_text"}`], name: value === "percentage" ? "percentage_value" : "percentage_value" },
    { type: ["milestone_date"], name: "payment_date" },
  ]
}