/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { Button, Grid, List, ListItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  GenerateForm,
  MasterUploadModal,
  SearchFilter,
  TableWithPagination
} from "..";

import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { CHECK_COMPANY_NO, CHECK_EXIST_COMPANY_NO } from "../../graphql/bussinessMasterQuery";
// import {
//   GET_ACTIVITY_CATEGORY_DROPDOWN,
//   GET_CITY_DROPDOWN,
//   GET_COUNTRY_DROPDOWN,
//   GET_LEAD_ACTIVITY_DROPDOWN,
//   GET_STATE_DROPDOWN,
//   GET_VAT_GROUP_DROPDOWN,
// } from "../../graphql/bussinessMasterQuery";ww
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";

import {
  AlertProps,
  BuisnessList,
  bussinessActions,
  bussinesssEditActions,
  bussinessUpdateActions,
  CountryDrawer,
  Countryheading,
  CountryPath,
  CountryRow,
  CountryType,
  inVoiceDrawer,
  inVoiceHeading,
  inVoicePath,
  inVoiceRow,
  inVoiceType,
  leadStatusDrawer,
  leadStatusHeading,
  leadStatusPath,
  leadStatusRow,
  leadStatusType,
  LocalStorageKeys,
  MoveInMoveOutDrawer,
  MoveInMoveOutHeading,
  MoveInMoveOutPath,
  MoveInMoveOutRow,
  MoveInMoveOutType,
  NetWorkCallMethods,
  quotationsDrawer,
  quotationsHeading,
  quotationsPath,
  quotationsRow,
  quotationsType
} from "../../utils";
import { UseDebounce } from "../customHooks";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme, props) => ({
  root: {
    height: "100%",
    // width: "100%",

  },
  grid: {
    borderRadius: "12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    // height: "100%",
    height: `calc(100vh - 93px)`,
    width: "100%",
    padding: 10,
    margin: 5,
    overflow: "auto",
  },
  gridList: {
    borderRadius: "12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    // height: "100%",
    height: `calc(100vh - 93px)`,
    padding: 10,
    margin: 5,
  },
  listText: {
    backgroundColor: "#071741",
    padding: 10,
    borderRadius: 10,
    color: "white",
    opacity: 1,
    cursor: "pointer",
  },
  list: {
    cursor: "pointer",
  },
  close: {
    position: "fixed",
    right: "310px",
    top: 10,
    backgroundColor: "white",
    padding: 5,
    display: "flex",
    borderRadius: 5,
    cursor: "pointer",
  },
}));

const BusinessMasterComponent = ({
  t
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [readOnly, setRead] = useState(false);
  const search = useLocation().search;
  const initialTitle = new URLSearchParams(search).get("title");
  const [title, setTitle] = useState(initialTitle ?? "Country");
  const history = useHistory();
  const [isBig, setBig] = useState(false);
  const backdrop = React.useContext(BackdropContext);
  const [edit, isEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [listVal, setListVal] = useState("");
  const [listTab, setTab] = useState("");
  const [list, setList] = useState(BuisnessList);
  const [disable, setDiable] = useState(false);
  const [data, setData] = useState({
    heading: Countryheading,
    row: CountryRow,
    path: CountryPath,
    type: CountryType,
    drawer: CountryDrawer,
  });

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  //debounce hook
  const debounce = UseDebounce();

  const [uploadModal, setUploadModal] = useState(false);

  const alert = React.useContext(AlertContext);

  const getTable = (name, offset = 0, limit = 10, search = "") => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const jsonData = bussinessActions(name, offset, limit, search);
    const payload = {
      query: jsonData.type,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        const wData = jsonData.resfunc(main);
        setData({
          heading: jsonData.tbdata.heading,
          row: wData,
          path: jsonData.tbdata.path,
          type: jsonData.tbdata.type,
          drawer: jsonData.tbdata.drawer,
          totalRowsCount: response?.data?.data?.count?.[0]?.count,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {

        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        //  alert.setSnack({
        //     ...alert,
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: "Some Thing Went Wrong",
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.center,
        //   });
        console.log(error)
      });
  };

  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getTable(title, offset, limit, listTab);
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getTable(title, 0, value, listTab);
  };

  const updateTableFunc = (name, data) => {
    setOpen(false);
    data.created_at = new Date().toISOString();
    data.created_by = localStorage.getItem(LocalStorageKeys.profileID);
    data.updated_at = new Date().toISOString();
    data.updated_by = localStorage.getItem(LocalStorageKeys.profileID);

    if (title === "Company") {
      const checkCompanyNoPayload = {
        query: CHECK_COMPANY_NO, variables: { companyNo: data?.company_no }
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        checkCompanyNoPayload,
        null,
        true,
        false
      ).then((response) => {
        if (response?.data?.data?.company_master?.length > 0) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Company Code Already Exist!"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          const jsonData = bussinessUpdateActions[name];
          const payload = {
            query: jsonData.type,
            variables: {
              insert: [data],
            },
          };
          NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then(async (response) => {

              let main = response.data.data;
              setOpen(false);
              //Creating placeholder images for dashboard while creating company
              await new Promise(async (resolve, reject) => {
                if (main?.company_master[0]?.id) {
                  const payload = {
                    "tenantId": config.tenantId,
                    "company_id": main?.company_master[0]?.id,
                    "user_profile_id": localStorage.getItem(LocalStorageKeys.profileID),
                  };
                  await NetworkCall(
                    `${config.api_url}/company-banner/seeder`,
                    NetWorkCallMethods.post,
                    payload,
                    null,
                    true,
                    false).then((response) => {

                      resolve();
                    }).catch((error) => {
                      alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Please fill all mandatory field"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                      });
                      console.log(error)
                      reject();
                    });
                }
              }).catch((error) => {
                setOpen(false);
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: t("Some Thing Went Wrong"),
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              });
              //end
              if (page > 1) { setPage(1); }
              getTable(title, 0, limit);
              setTab("");
              setOpen(false);
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Updated Successfully!"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            }).catch((error) => {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            });
        }
      }).catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
    } else {
      const jsonData = bussinessUpdateActions[name];
      const payload = {
        query: jsonData.type,
        variables: {
          insert: [{
            ...data, client: localStorage.getItem(LocalStorageKeys.clinetID)
          }],
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false).then(async (response) => {

          if (page > 1) {
            setPage(1);
          }
          getTable(title, 0, limit);
          setTab("");
          setOpen(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("Updated Successfully!"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }).catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    }


  };

  const editTablefunc = (name, data, id) => {
    setDiable(true)
    data.updated_at = new Date().toISOString();
    data.updated_by = localStorage.getItem(LocalStorageKeys.profileID);
    if (title === "Company") {
      const checkCompanyNoPayload = {
        query: CHECK_EXIST_COMPANY_NO, variables: { companyID: id ? id : editId, companyNo: data?.company_no }
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        checkCompanyNoPayload,
        null,
        true,
        false
      ).then((response) => {

        if (response?.data?.data?.existing?.length > 0) {
          const jsonData = bussinesssEditActions[name];
          const payload = {
            query: jsonData.type,
            variables: {
              Id: id ? id : editId,
              demo: [data],
            },
          };
          NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          )
            .then((response) => {
              setDiable(true)
              let offset = (page - 1) * limit;
              getTable(title, offset, limit, listTab);
              setOpen(false);
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Updated Successfully!"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            })
            .catch((error) => {
              setDiable(false)
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            });
        } else if (response?.data?.data?.new?.length > 0) {
          setDiable(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Company Code Already Exist!"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          const jsonData = bussinesssEditActions[name];
          const payload = {
            query: jsonData.type,
            variables: {
              Id: id ? id : editId,
              demo: [data],
            },
          };
          NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          )
            .then((response) => {
              setDiable(true)
              let offset = (page - 1) * limit;
              getTable(title, offset, limit, listTab);
              setOpen(false);
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Updated Successfully!"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            })
            .catch((error) => {
              setDiable(false)
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            });
        }
      }).catch((error) => {
        setDiable(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
    } else {
      const jsonData = bussinesssEditActions[name];
      const payload = {
        query: jsonData.type,
        variables: {
          Id: id ? id : editId,
          demo: [data],
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {

          let offset = (page - 1) * limit;
          getTable(title, offset, limit, listTab);
          setOpen(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: typeof ("Updated Successfully!"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        })
        .catch((error) => {
          setOpen(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    }
  };

  const saveFunction = (val, title) => {


    if (title === "Country") {


      let res = {};
      res.country_name = val.country_name;
      res.is_active = val.is_active ? val.is_active : false;

      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }

    if (title === "Currency") {
      let res = {};


      res.name = val?.name;
      res.code = val?.code;
      res.symbol = val?.symbol;
      if (val?.country_name?.value) {
        res.country_id = val?.country_name?.value;
      }
      res.is_active = val?.is_active ? val?.is_active : false;
      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }

    if (title === "Tax Group") {

      let res = {};
      res.group_name = val.group_name;
      res.vat_group_code = val.tax_group_code?.value;
      res.total_rate = val.total_rate;
      if (val?.country_name?.value) {
        res.country_id = val.country_name?.value;
      }
      res.is_active = val.is_active ? val.is_active : false;
      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }

    if (title === "Tax Item") {

      let res = {};
      res.group_name = val.group_name;
      res.vat_group_item_code = val.tax_group_code?.value;
      res.rate = val.rate;
      if (val.Vat_group?.value) {
        res.vat_group_master_id = val.Vat_group?.value;
      }
      res.is_active = val.is_active ? val.is_active : false;
      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }

    if (title === "Company") {
      let res = {};
      res.name = val.name;
      res.logo = val.upload_logo?.logo ?? '';
      res.description = val?.description;
      res.company_no = val?.company_code;
      res.city = val?.city?.value;
      res.currency_id = val?.currency?.value;
      res.company_registration_number = val?.company_registration;
      res.vat_group_id = val?.tax_group?.value;
      res.tax_number = val?.tax_registration;
      res.is_active = val.is_active ? val.is_active : false;
      res.cash_collection_office = val.cash_collection_office;
      res.cheque_name = val.cheque_name;
      res.description = val.description;
      res.name = val.name;
      res.primary_bank_name = val.primary_bank_name;
      res.routing_type = val?.routing_type?.value;
      res.account_type = val?.account_type?.value;
      res.primary_account_no = val?.primary_account_no;
      res.bank_routing_code = val?.bank_routing_code;
      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }

    if (title === "State") {

      let res = {};
      res.state_name = val.state_name;
      if (val.country_name?.value) {
        res.country_id = val.country_name?.value;
      }

      res.is_active = val.is_active ? val.is_active : false;
      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }

    if (title === "City") {

      let res = {};
      res.city = val.city;
      if (val.state_name?.value) {
        res.state = val.state_name?.value;
      }

      res.is_active = val.is_active ? val.is_active : false;
      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }

    if (title === "Locality") {

      let res = {};
      res.area_name = val.area_name;
      if (val.city?.value) {
        res.city_master_id = val.city?.value;
      }
      res.description = val.description;
      res.is_active = val.is_active ? val.is_active : false;
      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }

    if (title === "Lead type") {

      let res = {};
      res.name = val.name;
      res.is_active = val.is_active ? val.is_active : false;
      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }

    if (title === "Activity") {

      let res = {};
      res.name = val.type;
      res.is_active = val.is_active ? val.is_active : false;
      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }

    if (title === "Activity Category") {

      let res = {};
      if (val.type?.value) {
        res.lead_activity_master_id = val.type?.value;
      }
      res.name = val.activity_category;
      res.is_active = val.is_active ? val.is_active : false;

      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }

    if (title === "Activity Sub-Category") {

      let res = {};
      res.name = val.sub_category;
      // if (val.type?.value) {
      //   res.lead_activity_master_id = val.type?.value;
      // }
      if (val.activity_category?.value) {
        res.activity_category_master_id = val.activity_category?.value;
      }
      res.is_active = val.is_active ? val.is_active : false;
      edit ? editTablefunc(title, res) : updateTableFunc(title, res);
    }
  };

  useEffect(() => {
    // getCountryMaster();
    getTable(title);
    // eslint-disable-next-line
  }, []);

  const handleDrawer = () => {
    setOpen(true);
    setRead(false);
    isEdit(false);

    const newDrawer = [...data.drawer]?.map((v) => {
      if (v.state_name === "value") {
        v.value = {
          currency: "",
          currency_code: "$",
        };
      } else {
        v.value = "";
      }
      return v
    })

    setData({
      ...data,
      drawer: newDrawer
    })

    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (type, value, checked) => {
    if (type === "edit" || type === "view") {

      if (type === "edit") {
        setRead(false);
        isEdit(true);
        setEditId(value.id);
      }
      else {
        setRead(true);
      }

      const newDrawer = [...data.drawer]?.map((val) => {
        Object.keys(value).map(k => {
          if (val.state_name === k) {
            val.value = value[val.state_name]
          }
        })
        return val
      })

      setData({
        ...data,
        drawer: newDrawer
      })
      setOpen(true);

    }

    if (type === "toggle") {
      const c = {
        is_active: checked,
        updated_at: new Date().toISOString(),
        updated_by: localStorage.getItem(LocalStorageKeys.profileID),
      };
      editTablefunc(title, c, value.id);
    }
  };

  const handleList = (name, index) => {
    setPage(1);
    setLimit(10);
    setTab("");

    setTitle(name);
    history.push(Routes.businessMaster + "?title=" + name);
    if (name === "Country") {
      getTable(name);
      setBig(false);
    }
    if (name === "Currency") {
      getTable(name);
      setBig(false);
    }
    if (name === "Tax Group") {
      getTable(name);
      setBig(false);
    }
    if (name === "Tax Item") {
      getTable(name);
      setBig(false);
    }
    if (name === "Company") {
      getTable(name);
      setBig(false);
    }
    if (name === "State") {
      getTable(name);
      setBig(false);
    }
    if (name === "City") {
      getTable(name);
      setBig(false);
    }
    if (name === "Locality") {
      getTable(name);
      setBig(false);
    }
    if (name === "Invoice") {
      getTable(name);
      setData({
        heading: inVoiceHeading,
        row: inVoiceRow,
        path: inVoicePath,
        type: inVoiceType,
        drawer: inVoiceDrawer,
        totalRowsCount: inVoiceRow?.length,
      });
      setBig(false);
    }
    if (name === "Quotation status") {
      getTable(name);
      setData({
        heading: quotationsHeading,
        row: quotationsRow,
        path: quotationsPath,
        type: quotationsType,
        drawer: quotationsDrawer,
        totalRowsCount: quotationsDrawer?.length,
      });
      setBig(false);
    }
    if (name === "Lead type") {
      getTable(name);

      setBig(false);
    }
    if (name === "Lead status") {
      getTable(name);
      setData({
        heading: leadStatusHeading,
        row: leadStatusRow,
        path: leadStatusPath,
        type: leadStatusType,
        drawer: leadStatusDrawer,
        totalRowsCount: leadStatusRow?.length,
      });
      setBig(false);
    }
    if (name === "Activity") {
      getTable(name);
      setBig(false);
    }
    if (name === "Activity Category") {
      getTable(name);
      setBig(false);
    }
    if (name === "Activity Sub-Category") {
      getTable(name);

      setBig(false);
    }
    if (name === "Move-in Move-out") {
      getTable(name);
      setData({
        heading: MoveInMoveOutHeading,
        row: MoveInMoveOutRow,
        path: MoveInMoveOutPath,
        type: MoveInMoveOutType,
        drawer: MoveInMoveOutDrawer,
        totalRowsCount: MoveInMoveOutHeading?.length,
      });
      setBig(false);
    }
  };

  const handleListFilter = (e) => {
    setListVal(e);
    setList(
      BuisnessList.filter((_) =>
        _.name.toLowerCase().includes(e?.toLowerCase())
      )
    );
  };

  const handleTab = (e) => {
    setTab(e);
    debounce(() => searchTableFunction(e), 800);
  };

  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getTable(title, 0, limit, e);
  };

  const handleUploadModal = (val, type) => {
    // selected tab master name
    if (type === "refresh") {
      getTable(title);
      setUploadModal(!uploadModal);
    } else {
      setUploadModal(!uploadModal);
    }
  };
  return (
    <div className={classes.root}>
      <Grid constainer spacing={2} direction="row" display="flex">
        <Grid item xs={3} className={classes.gridList}>
          <SearchFilter value={listVal} handleChange={handleListFilter} />
          <div
            style={{
              height: `calc(100vh - 156px)`,
              overflow: "auto",
              marginTop: "4px",
            }}
          >
            <List>
              {list.map((l, index) => (
                <ListItem
                  className={
                    l?.name === title ? classes.listText : classes.list
                  }
                  onClick={() => handleList(l?.name, index)}
                >
                  <ListItemText primary={l.name} />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid
          item
          xs={9}
          className={classes.grid}
          justifyContent="space-between"
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xs={6}>
              <SearchFilter value={listTab} handleChange={handleTab} />
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Button
                color="primary"
                variant={"outlined"}
                onClick={handleUploadModal}
                style={{ marginRight: 12 }}
              >
                {t("Bulk Upload")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleDrawer}
              >
                {t("Add new")}
              </Button>
            </Grid>
          </div>

          <Grid item xs={12} className={classes.table}>
            {data?.heading && (
              <TableWithPagination
                heading={data.heading}
                rows={data.row}
                path={data.path}
                handleIcon={handleChange}
                onClick={() => false}
                showpagination={true}
                // tableType = "normal"
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                dataType={data.type}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={data?.totalRowsCount}
                page={page}
                limit={limit}
                height={'calc(100vh - 240px)'}
                view={true}
                edit={true}
                delete={true} />
            )}
          </Grid>
        </Grid>
      </Grid>

      {(data?.drawer && open) && (
        <GenerateForm
          parent_id={"BussinessMasterDrawer"}
          nocancel
          loading={disable}
          open={open}
          readOnly={readOnly}
          save={saveFunction}
          handlecancel={handleClose}
          header={title}
          json={data?.drawer}
        />
      )}
      {/* Upload Modal */}
      {uploadModal && (
        <MasterUploadModal
          open={uploadModal}
          closeModal={handleUploadModal}
          docsType={title}
        />
      )}
    </div>
  );
};
export default withNamespaces("businessMasterComponent")(BusinessMasterComponent)
