/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from '@apollo/client';
import { Badge, Box, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import FilterIMG from "../../assets/filter";
import { FilterGenerator, PropertyTabs, SearchFilter, Subheader, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { UPDATE_CONTACT } from '../../graphql/mutations';
import { GET_SERVICE_PROVIDER, GET_SERVICE_PROVIDER_LIST } from '../../graphql/queries';
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { accessCheckRender, AlertProps, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { SettingList } from '../companyCreation/components';
import ServiceProviderView from '../serviceProviderView/serviceProviderView';

import { GET_ALL_VENDORS, GET_VENDOR_DETAILS } from '../vendors/utils';
import VendorDetails from '../vendors/vendorDetails';
import { ContactsView } from './contactsView';
import { Details } from './details';
import { KycView } from './kyc';
import { useLocation } from 'react-router-dom';
import { contactsStyles } from "./style";
import { withNamespaces } from 'react-i18next';

const Contacts = ({ reltype = "", t = () => false }) => {


    const classes = contactsStyles();
    const client = useApolloClient();
    const history = useHistory();
    const debounce = UseDebounce();
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [fiterDrawer, setFilterDrawer] = React.useState(null);
    const [contactList, setContactList] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const [companyList, setCompanyList] = React.useState([]);
    const [offset, setOffset] = React.useState(0)
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const [selected, setSelected] = React.useState();
    const [status, setStatus] = React.useState();
    const [contactDetails, setContactDetails] = React.useState(null)
    const [value, setValue] = React.useState(1);
    const [permission, setPermission] = React.useState({})
    const [enumValue, setEnumValue] = React.useState({
        relationship: []
    })

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
                getEnum()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.relationship_type])
        setEnumValue({ relationship: result?.relationship_type })
    }

    // hide filter and create button
    const hideCreateAndFilter = ["Owner", "Customer"]
    const location = useLocation()
    const getRelType = () => {
        const pathname = location?.pathname
        switch (pathname) {
            case Routes.residentTable:
                return "Customer"
            case Routes.ownerTable:
                return "Owner"
            case Routes.salesagentlist:
                return "Agent"
            case Routes.vendorListing:
                return "Vendor"
            case Routes.serviceProviderListing:
                return "ServiceProvider"
            default:
                return "AllContact"
        }
    }

    let type = getRelType() ?? reltype


    const path = {
        "Agent": Routes.salesagent,
        "ServiceProvider": Routes.serviceProviderCreateEdit,
        "Vendor": Routes.vendorCreation,
        "AllContact": Routes.createContact,
        "Owner": Routes.createContact

    }

    const button = {
        "Agent": t("Create Agent"),
        "Owner": t("Create Owner"),
        "Customer": t("Create Customer"),
        "ServiceProvider": t("Create Service Provider"),
        "AllContact": t("Create Contact"),
        "Vendor": t("Create Vendor")
    }

    const subTitle = {
        "Agent": t("Sales Agent"),
        "Owner": t("Owner"),
        "Customer": t("Customer"),
        "ServiceProvider": t("Service Provider"),
        "AllContact": t("Contacts"),
        "Vendor": t("Vendors")
    }

    const relationshipType = {
        "Owner": ["External Property Owner"],
        "Agent": ["External Sales Broker"],
        "Customer": ["Customer"],
        "ServiceProvider": ["Service Provider"]
    }

    const listHeading = {
        "Agent": t("Agent Lists"),
        "Owner": t("Owner Lists"),
        "Customer": t("Customer Lists"),
        "ServiceProvider": t("Service Provider Lists"),
        "AllContact": t("Contact Lists"),
        "Vendor": t("Vendor Lists")
    }

    const tabtitle1 = {
        "Agent": t("Agent Details"),
        "Owner": t("Owner Details"),
        "Customer": t("Customer Details"),
        "ServiceProvider": t("Service Provider Details"),
        "AllContact": t("Contact Details"),
        "Vendor": t("Vendor Details")
    }

    const removeFilter = ["ServiceProvider", "Agent", "Vendor"]
    // hide contact details 

    const hideContactDetails = ["ServiceProvider", "Vendor"]

    const tabTittle = [
        {
            label: tabtitle1?.[type],
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: t("KYC Details"),
            value: 2,
            is_active: (type === "ServiceProvider") ? false : true,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: type === "Owner" ? t("Owner Details") : t("Customer Details"),
            value: 3,
            is_active: (type === "Agent" || type === "ServiceProvider" || type === "AllContact" || type === "Customer" || type?.length === 0) ? false : true,
            className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle,
        },
    ]


    const statusOptionList = [
        { label: 'Active', value: true },
        { label: 'Inactive', value: false },
    ];

    const handlelist = (type, searchText, offset, bool) => {
        switch (type) {
            case "ServiceProvider":
                return getServiceProvider(searchText, offset, bool)
            case "Vendor":
                return getVendorList(searchText, offset, bool, selectedCompany?.value)
            default:
                return getContactByCompanyID(searchText, offset, bool)
        }
    }

    const fetchMoreData = () => {
        setOffset(offset + 20)
        handlelist(type, searchText, offset + 20, true)
    }
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        handlelist(type, e, 0, false)
    }


    const draweropen = () => {
        setFilterDrawer(true);
    };
    const drawerclose = () => {
        setFilterDrawer(false);
    };


    const createContact = () => {
        history.push({
            pathname: path?.[type],
            state: {
                main: {
                    company: selectedCompany,
                    companyList: companyList.filter?.(i => i?.value != null),
                    isAllCompany: selectedCompany?.value ? false : true
                },
            },
        });

    }
    const editContact = () => {
        history.push({
            pathname: path?.[type],
            state: {
                main: {
                    id: selected?.id,
                    isEdit: true
                },
            },
        });

    }

    const deleteContact = async () => {
        client.mutate({
            mutation: UPDATE_CONTACT, fetchPolicy: 'network-only',
            variables: { contactID: selected?.id, payload: { is_active: status === false ? true : false } }
        }).then((response) => {
            handlelist(type, searchText, 0, false)
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: response?.data?.contact?.[0]?.is_active ? t("Contact Activated Successfully.!!!") : t("Contact Inactivated Successfully.!!!"),
            });
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong. Internal Error.!!!"),
            });
        });
    }


    const getContactDetailsByID = (contactId) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", });
        let datas = { tenantId: `${config.tenantId}`, id: contactId }
        NetworkCall(
            `${config.api_url}/contact/get`,
            NetWorkCallMethods.post,
            datas, null, true, false
        ).then((res) => {

            let tempContactDetails = res?.data?.data
            setContactDetails(tempContactDetails)
            setStatus(tempContactDetails?.is_active)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch((error) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            setContactDetails(false)
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Internal error. Please try again later."),
            });
        });
    }

    const getServiceProviderDetail = (selected) => {
        // if (selected) {
        //     console.log("called")
        //     backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        //     client.query({
        //         query: GET_SERVICE_PROVIDER, fetchPolicy: 'network-only',
        //         variables: { serviceProviderID: selected }
        //     }).then((r) => {
        //         const tempServiceProvider = r?.data?.service_providers_master?.[0]
        //         setContactDetails(tempServiceProvider)
        //         backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        //     })
        // }else{
        //     console.log("else called")
        // }
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        const payload = {
            query: GET_SERVICE_PROVIDER,
            variables: { serviceProviderID: selected }
        };

        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const tempServiceProvider = res?.data?.data?.service_providers_master?.[0]
            setContactDetails(tempServiceProvider)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((err) => {
            console.log(err)
        });
    }

    const getVendorDetails = (selected) => {
        const id = selected

        // setLoading('screen')
        client.query({
            query: GET_VENDOR_DETAILS,
            fetchPolicy: "network-only",
            variables: {
                id
            }
        }).then(res => {
            setContactDetails(res?.data?.vendor_master?.[0])
            // constructVendorDetailsData(res?.data?.vendor_master?.[0])
        }).catch(err => {
            console.log(err);
            // setLoading(null);
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        })
    }

    const getContactByCompanyID = (search, offset, searchBool) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "", });
        const payload = {
            "tenantId": `${config.tenantId}`,
            "company_id": selectedCompany?.value,
            "search": search,
            "filter": {
                relationship: type === 'AllContact' ?
                    (filterData?.relationship ? filterData?.relationship?.map((val) => val?.value) : []) : relationshipType?.[type],
                // enumValue?.relationship ? enumValue?.relationship?.map((val) => val?.value) :

                // status: (!filterData?.status || filterData?.status?.length === 0) ? [true, false] : filterData?.status

            },
            "offset": offset,
            "limit": 20
        }
        NetworkCall(
            `${config.api_url}/contact/list`,
            NetWorkCallMethods.post,
            payload, null, true, false).then((response) => {
                if (searchBool) {
                    setContactList(
                        contactList.concat(response?.data?.data)
                    );
                } else {
                    setContactList(response?.data?.data);
                    setSelected(response?.data?.data?.[0])
                    if (response?.data?.data?.[0]?.id) {
                        getContactDetailsByID(response?.data?.data?.[0]?.id)
                    }
                }
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            }).catch(() => {
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", });

            });
    };

    // Function to get service provider list based on the input data
    const getServiceProvider = (search, offset, searchBool, limit = 10) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        client.query({
            query: GET_SERVICE_PROVIDER_LIST, fetchPolicy: "network-only",
            variables: {
                clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? "",
                status: (!filterData?.status || filterData?.status?.length === 0) ?
                    [true, false] : filterData?.status,
                companyID: selectedCompany?.value,
                search,
                offset,
                limit,
            }
        }).then((response) => {
            if (searchBool) {
                setContactList(
                    contactList.concat(response?.data?.service_providers_master)
                );
            } else {
                setContactList(response?.data?.service_providers_master);
                setSelected(response?.data?.service_providers_master?.[0])
                if (response?.data?.service_providers_master?.[0]?.id) {
                    getServiceProviderDetail(response?.data?.service_providers_master?.[0]?.id)
                }
            }
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        })
    }

    const getVendorList = (searchText = "", offset = 0, searchBool = true, companyId, limit = 10) => {

        if (searchBool) {
            backdrop.setBackDrop({
                ...backdrop,
                open: true,
                message: "Fetching data",
            });
        }

        client.query({
            query: GET_ALL_VENDORS,
            fetchPolicy: "network-only",
            variables: {
                offset,
                limit: 10,
                searchText,
                companyId,
                client: localStorage.getItem('clinetID')
            }
        }).then((response) => {
            if (searchBool) {
                setContactList(
                    contactList.concat(response?.data?.vendor_master)
                );
            } else {
                setContactList(response?.data?.vendor_master);
                setSelected(response?.data?.vendor_master?.[0])
                if (response?.data?.vendor_master?.[0]?.id) {
                    getVendorDetails(response?.data?.vendor_master?.[0]?.id)
                }
            }
            if (searchBool) {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                })
            };
        }).catch(err => {
            if (searchBool) {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                })
            };
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        })
    }

    React.useEffect(() => {
        if (companyList?.length > 0) {
            if (!Boolean(companyList?.[0]?.value) && ((!Boolean(selectedCompany?.value)) || selectedCompany?.value) && enumValue?.relationship?.length > 0) {
                handlelist(type, searchText, 0, false)
                setOffset(0)
            }
        }
        // eslint-disable-next-line
    }, [selectedCompany, filterData, companyList, enumValue]);

    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList([{ value: null, label: "All Company" }, ...company?.list])
            setSelectedCompany(company?.selected ?? { value: null, label: "All Company" })
        }
    }

    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }
    const onApplyFilter = (value) => {
        setFilterData(value)
    }
    //tab cbange
    const tabChange = (e, newValue) => {
        setValue(newValue)
    }
    // const selectedFunction = (type) => {
    //     switch (type) {
    //         case "ServiceProvider":
    //             return getServiceProviderDetail(selected?.id)
    //         case "Vendor":
    //             return getVendorDetails(selected?.id)
    //         default:
    //             return getContactDetailsByID(selected?.id)
    //     }
    // }

    // React.useEffect(() => {
    //     if (selected &&  type === "AllContact") {
    //         selectedFunction(type)
    //     }
    //     // eslint-disable-next-line
    // }, [selected])


    const viewTabComponent = {
        "ServiceProvider": <ServiceProviderView t={t} data={contactDetails} id={selected?.id} hideSubheader={true} />,
        "Customer": <ContactsView t={t} data={contactDetails} />,
        "Owner": <ContactsView t={t} data={contactDetails} />,
        "AllContact": <ContactsView t={t} data={contactDetails} />,
        "Vendor": <VendorDetails t={t} data={contactDetails} hideSubheader={true} selectedCompany={selectedCompany} />,
        "Agent": <ContactsView t={t} data={contactDetails} />,
    }


    const filterComponentBodyElements = (type) => {
        switch (type) {
            case "AllContact":
                return [
                    {
                        component: "select",
                        value: filterData?.relationship,
                        options: enumValue?.relationship,
                        isMulti: true,
                        state_name: "relationship",
                        label: t("Relationship"),
                        placeholder: t("Select Relationship")
                    },
                    {
                        component: "toggleButton",
                        value: filterData?.status,
                        options: statusOptionList,
                        isMulti: true,
                        state_name: "status",
                        label: t("Status"),
                    }
                ]

            default:
                return [
                    {
                        component: "toggleButton",
                        value: filterData?.status,
                        options: statusOptionList,
                        isMulti: true,
                        state_name: "status",
                        label: t("Status"),
                    }
                ]
        }
    }

    const getSelectedDetail = (data) => {
        setSelected(data)
        switch (type) {
            case "ServiceProvider":
                return data?.id && getServiceProviderDetail(data?.id)
            case "Vendor":
                return data?.id && getVendorDetails(data?.id)
            default:
                return data?.id && getContactDetailsByID(data?.id)
        }
    }

    const render = () => {
        return <>
            <Subheader hideBackButton={true} title={subTitle?.[type]} isAllCompany
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            <Grid container className={classes.content} spacing={1}>
                <Grid item xs={4}>
                    <SearchFilter value={searchText} placeholder={t("Search Contacts")} handleChange={(value) => handleSearch(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                </Grid>
                <Grid item xs={8} textAlign={"right"}>
                    {hideCreateAndFilter?.includes(type) ? false : true && <Box display={"flex"} sx={{ justifyContent: "end" }}>
                        <Stack direction="row" spacing={2}
                            divider={<Divider orientation="vertical" flexItem />}>
                            {
                                !removeFilter.includes(type) && ((filterData.relationship?.length > 0 || filterData.status?.length > 0) ?

                                    (<IconButton onClick={draweropen} className={classes.img}>
                                        <Badge variant="dot" color="primary">
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>) :
                                    (<IconButton onClick={draweropen} className={classes.img}>
                                        <FilterIMG color="#091b29" />
                                    </IconButton>))
                            }
                            {
                                permission.create && <Button variant="contained" className={classes.btn}
                                    onClick={createContact}>
                                    {button?.[type]}
                                </Button>
                            }

                        </Stack >
                    </Box >}
                </Grid >
            </Grid >

            <Box>
                <Grid container spacing={2} p={2}>

                    <Grid item sm={5} md={5} lg={3}>
                        <Box className={contactList?.length > 0 ? classes.box2 : classes.box}>
                            <SettingList
                                list={contactList}
                                selected={selected?.id}
                                setSelected={getSelectedDetail}
                                fetchMoreData={fetchMoreData}
                                contactSetting
                                title={listHeading?.[type]}
                            />
                        </Box>
                    </Grid>

                    <Grid item sm={7} md={7} lg={9}>
                        <Box className={contactList?.length > 0 ? classes.box2 : classes.box}>
                            {contactList?.length > 0 ?
                                <>
                                    {contactDetails &&
                                        <>

                                            {
                                                !hideContactDetails?.includes(type) && <Grid container p={2} alignItems="center" spacing={1}>

                                                    <Grid item xs={4}>
                                                        <Typography className={classes.title}>{contactDetails?.first_name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} textAlign={"right"}>

                                                        <Box display={"flex"} sx={{ justifyContent: "end" }}>
                                                            <Stack direction="row" spacing={2}
                                                                divider={<Divider orientation="vertical" flexItem />}>
                                                                {(status && permission.delete) &&
                                                                    type !== "Customer" &&
                                                                    <Button variant="outlined" className={classes.btn3}
                                                                        onClick={deleteContact}
                                                                    >
                                                                        {t("In-Active")}

                                                                    </Button>}
                                                                {(!status && permission.update) &&
                                                                    <Button variant="outlined" className={classes.btn3}
                                                                        onClick={deleteContact}
                                                                    >
                                                                        {t("Active")}
                                                                    </Button >}
                                                                {
                                                                    permission.update &&
                                                                    type !== "Customer" && <Button variant="contained" className={classes.btn}
                                                                        onClick={editContact}
                                                                    >
                                                                        {t("Edit")}
                                                                    </Button>
                                                                }


                                                            </Stack >
                                                        </Box >
                                                    </Grid >
                                                </Grid >}
                                            {
                                                type === "Vendor" ?
                                                    <VendorDetails id={selected?.id} data={contactDetails} hideSubheader={true} selectedCompany={selectedCompany} />
                                                    :
                                                    <PropertyTabs
                                                        t={t}
                                                        value={value} padding handleChange={tabChange}
                                                        tab1={(
                                                            viewTabComponent?.[type]

                                                        )}
                                                        tab2={(
                                                            <KycView t={t} data={contactDetails} />
                                                        )}
                                                        tab3={(
                                                            <Details data={contactDetails} type={type} />

                                                        )}
                                                        tabTittle={tabTittle}
                                                    />
                                            }
                                        </>
                                    }
                                </>
                                :
                                <Typography className={classes.noData} >{t("No contacts found")}</Typography>

                            }
                        </Box >
                    </Grid >
                </Grid >
            </Box >


            <FilterGenerator open={fiterDrawer} onClose={drawerclose} onApply={(value) => onApplyFilter(value)}
                components={filterComponentBodyElements(type)} />
        </>
    }
    return <div >
        {accessCheckRender(render, permission)}
    </div >
}
export default withNamespaces("contacts")(Contacts)
