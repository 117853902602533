import { Grid, Box, Stack, Typography, Button } from "@mui/material";
import React from "react";
import { MobileNumberInputComponent, TextBox } from "../../index";
import { ProfileUpload } from "../../LogoUpload/profileUpload"
import { useStyle } from "./style"
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import { LocalStorageKeys } from "../../../utils";
import { SELECT_ACCOUNT_ALL } from "../../../graphql/residentOnbordingQueries";
import { Steps } from "../../../screens/residentOnboarding/steps";

export const Account = ({
    data,
    updateState = () => false, t = () => false
}) => {


    const NewAccount=()=>{
        return (
            <Box>
                 <Grid container spacing={2} className={classes.addContactParent} p={2} >
            
            <Grid item xs={12} >
                <ProfileUpload
                    logo_title={""} data={data?.profile_img} profile
                    handleChange={(value) => updateState("profile_img", value)}
                />
            </Grid>
            {/* name  */}
            <Grid item xs={12} >
                <TextBox label={t("Contact Name")} placeholder={t("Contact Name")}
                    value={data?.name} isrequired
                    onChange={(e) => {
                        updateState("name", e.target.value);
                    }}
                    isError={data?.error?.name?.length > 0}
                    errorMessage={data?.error?.name}
                />
            </Grid>

            {/* mobile */}
            <Grid item xs={12}>
                <MobileNumberInputComponent label={t("Mobile")} placeholder={t("Mobile")}
                    value={data?.mobile} isRequired
                    handleChange={(value) => {
                        updateState("mobile", value);
                    }}
                    xs={3.2}
                    isError={data?.error?.mobile?.length > 0}
                    errorMessage={data?.error?.mobile}

                />
            </Grid>

            {/*Telephone  */}
            <Grid item xs={12}>
                <MobileNumberInputComponent
                    label={t("Telephone")}
                    xs={3.2}
                    placeholder={t("Telephone")}
                    value={data?.telephone}
                    handleChange={(value) => {
                        updateState("telephone", value);
                    }}
                />
            </Grid>
            {/*email  */}
            <Grid item xs={12}>
                <TextBox label={t("Email Address")} placeholder={t("Email Address")}
                    value={data?.email} isrequired
                    onChange={(e) => {
                        updateState("email", e.target.value);
                    }}
                    isError={data?.error?.email?.length > 0}
                    errorMessage={data?.error?.email}
                />
            </Grid>



        </Grid>
            </Box>
        )
    }

    const ExistingAccount = () => {
        return (
            <Box>
                   <Steps 
                    t={t}
                   component={[
                    {
                        paddingTop:'0px !important',
                        type: "customSelectBox",
                        placeHolder: "Search Existing Account",
                        value: data?.account,
                        handleChange: (value) => updateState('account', value),
                        options: [],
                        sm: 12,
                        md: 12,
                        lg: 12,
                        isActive: true,
                        menuOptionHeight: 300,
                        color: "#F5F7FA",
                        loadOptions: (search, array, handleLoading) => {
                            return loadOptions(
                                search,
                                array,
                                handleLoading,
                                SELECT_ACCOUNT_ALL,
                                "contact_account",
                                { isActive: true, client: localStorage.getItem(LocalStorageKeys.clinetID) },
                                { contact: true },
                                false,
                                false,

                            )
                        },
                    }
                ]} />
            </Box>
        )
    }

    const back=()=>{
        if(data?.accountType){
           return updateState('step' , 0)
        }
        updateState('accountType' , true)

    }
    const classes = useStyle()
    return (
        <Box >
            <Stack direction="row" justifyContent={'space-between'} p={2}>
                <Typography className={classes.contactTitle}>{data?.accountType ? t("BILLING ACCOUNT DETAILS") : t("CREATE NEW CONTACT")}</Typography>
                {
                    data?.accountType && <Typography className={classes.createNew} onClick={()=>updateState('accountType' , false)}>Create Quick Account</Typography>

                }
            </Stack>
            <Box p={2} style={{height:`calc(100vh - 191px)` , overflow:"auto"}}>
                {
                    data?.accountType ? <ExistingAccount/> : <NewAccount/>
                }
            </Box>
   
            {/* footer */}
            <Box p={2} style={{borderTop:"1px solid #F2F4F7"}}>
                <Stack direction="row" spacing={'8px'} >
                    <Button variant="outline" className={classes.previous} onClick={back}>
                        {t("Back")}
                    </Button>
                    <Button variant="contained" className={classes.next}>
                        {t("Create")}
                    </Button>
                </Stack>
            </Box>
        </Box>

    )
}