import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import ReactQuill from 'react-quill'
import { TermsAndConditionStyle } from './styles';
import NoTermsAndCondition from '../../../assets/notermsandcondition';
import { NewLoader } from '../../../components/newLoader';
import ImgUpload from '../../../components/imgUpload';
import { assestType } from '../../../utils';
import { AlertDialog, Slider } from '../../../components';
import { ASSEST_MASTER_TABLE } from '../../../graphql/queries';
import { useApolloClient } from '@apollo/client';
import { Document } from '../../../assets';
import { returnSaveData } from '../../../utils/clientsettings';

export const TermsAndCondition = ({ t = () => false, onSubmitSettings = () => false, settings = {}, tcLoading = false }) => {
    const client = useApolloClient();
    const [data, setData] = React.useState({
        open: false,
        quil: "",
        edit: false,
        view: false
    })
    const [images, setImages] = React.useState([]);
    const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
    const [uuid, setUUID] = React.useState(1);

    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState("")
    const [list, setList] = React.useState([])
    const [type, setType] = React.useState(
        {
            id: 2
        }
    )

    React.useEffect(() => {
        getAssests()
        if (settings?.terms_and_condition?.length) {
            setData({
                ...data,
                view: true,
                open: true,
                edit: false,
                quil: settings?.terms_and_condition
            })
            setImages(settings?.terms_conditions_docs ?? [])
        }
        // eslint-disable-next-line
    }, [])

    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, false] }],
                [{ color: [] }, { background: [] }],
                ["bold", "italic", "underline", "strike"],
                [{ align: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image"],
                ["emoji"],
                ["code-block"],
            ],
        },
        "emoji-toolbar": true,
        "emoji-textarea": true,
        "emoji-shortname": true,
    }

    const classes = TermsAndConditionStyle()

    const updateState = (key, value) => {
        setData({
            ...data,
            [key]: value
        })
    }

    const handleAssetFile = (data) => {
        if (data.asset_type === 3) {
            setDeletedAssetURL([...deletedAssetURL, data?.id].filter(Boolean));
            setImages(images?.length > 0 ? images?.filter((val) => val?.file_meta?.id !== data?.file_meta?.id) : []);
        } else {
            setDeletedAssetURL([...deletedAssetURL, data?.id].filter(Boolean));
            setImages(images?.length > 0 ? images?.filter((val) => val?.url !== data?.url) : []);
        }

    };

    const handleClick = (val) => {
        setOpen(!open)
        setSelected(val)
    }

    const handleCreateEditSave = () => {
        let payload = returnSaveData(settings)
        payload = {
            ...payload,
            terms_conditions: data?.quil,
            id: settings?.setting_id,
            terms_conditions_docs: images,
            deleted_docs_url: deletedAssetURL,
        }
        if (!data?.edit && !data?.view) {
            updateState("view", true)
            onSubmitSettings(payload)
        } else if (!data?.edit && data?.view) {
            setData({ ...data, edit: true, view: false, quil: settings?.terms_and_condition })
        } else if (data?.edit && !data?.view) {
            onSubmitSettings(payload)
            setData({ ...data, edit: false, view: true })
        }
    }

    const getAssests = () => {
        client.query({
            query: ASSEST_MASTER_TABLE(),
            fetchPolicy: 'network-only',
        }).then((res) => {
            setList(res?.data?.assets_master)
        }).catch((err) => { console.log(err) })
    }

    return (
        <Box p={2}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography className={classes.title}>{t("Terms & Condition")}</Typography>
                {
                    data?.open &&
                    <Button variant={data?.view ? 'outlined' : 'contained'}
                        disableElevation className={data?.view ? classes.editBtn : classes.createSaveBtn}
                        onClick={() => handleCreateEditSave()}>
                        {data?.edit ? t("Save") : data?.view ? t("Edit") : t("Create")}
                    </Button>
                }
            </Stack>
            {
                !data?.open ?
                    <Box className={classes.createBox}>
                        <Stack spacing={2} alignItems={"center"}>
                            <Stack alignItems={"center"} spacing={"21px"}>
                                <NoTermsAndCondition />
                                <Typography className={classes.createBoxText}>{t("No Terms and Conditions Found")}</Typography>
                            </Stack>
                            <Button variant='contained' className={classes.createBoxButton} disableElevation
                                onClick={() => updateState("open", true)}>{t("Create")}</Button>
                        </Stack>
                    </Box> :
                    <Box pt={"10px"} className={classes.contentBox}>
                        {
                            data?.view ?
                                <>
                                    {
                                        !tcLoading ?
                                            <>
                                                <div dangerouslySetInnerHTML={{
                                                    __html: settings?.terms_and_condition,
                                                }} ></div>
                                            </> :
                                            <NewLoader />
                                    }
                                    <Box>
                                        <Typography className={classes.documentTitle}>{t("Documents")}</Typography>
                                        <Grid container p={1}>
                                            {
                                                images?.map((i) => {
                                                    return (
                                                        <Grid item xs={3} onClick={() => handleClick(i)}
                                                            className={classes.imageContainer}>
                                                            {
                                                                i?.asset_type === assestType?.Documents ?
                                                                    <div style={{ height: "150px", width: "100%", overflow: "auto" }}>
                                                                        {/* <DocumentViewer url={i?.url} /> */}

                                                                        <Stack>
                                                                            <Stack rowGap={1} className={classes.detailsBox}>
                                                                                <Document />
                                                                                <Typography className={classes.pdfText}>PDF</Typography>
                                                                            </Stack>
                                                                            <Box sx={{ width: "100%", display: 'flex', justifyContent: "center", marginTop: "8px" }}>
                                                                                <Typography className={classes.pdfName}>{i?.file_meta?.name ?? "-"}</Typography>
                                                                            </Box>
                                                                        </Stack>
                                                                    </div>
                                                                    :
                                                                    <img
                                                                        src={i?.url}
                                                                        alt=""
                                                                        style={{
                                                                            objectFit: "cover",
                                                                            height: "150px",
                                                                            width: "100%",
                                                                            borderRadius: 12
                                                                        }}
                                                                    />
                                                            }
                                                        </Grid>
                                                    )
                                                })
                                            }

                                        </Grid>
                                    </Box>
                                </>
                                :
                                <Box>
                                    <ReactQuill
                                        onChange={(value) => updateState("quil", value)}
                                        value={data?.quil}
                                        modules={modules}
                                        className={classes.reactQuil}
                                        theme="snow"
                                    />
                                    <Box>
                                        <Typography className={classes.documentTitle}>{t("Documents")}</Typography>
                                        <Stack direction={"row"} columnGap={1} alignItems={"center"} my={1}>
                                            {
                                                list?.filter((i) => i?.id === 2 || i?.id === 4)?.map((e) => {
                                                    return (
                                                        <Box className={type?.id === e?.id ? classes.documentButtonSelected : classes.documentButton}
                                                            onClick={() => setType(e)}>
                                                            <Typography className={classes.documentBtnText}>
                                                                {e?.type}
                                                            </Typography>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Stack>
                                        <Box>
                                            <ImgUpload
                                                accept={type?.id === 2 ? ["image/*"] : [".pdf"]}
                                                setUUID={setUUID ?? null}
                                                uuid={uuid ?? null}
                                                updateImageURL={setImages}
                                                handleFile={handleAssetFile}
                                                selectedImageURL={images ?? []}
                                                id={type?.id}
                                                link
                                                termsCondtion={true}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                        }
                    </Box>
            }


            <AlertDialog
                medium={selected?.asset_type === 4 ? false : true}
                header={t("View Document")}
                open={open}
                onClose={() => setOpen(!open)}
                component={
                    <Box alignItems="center">
                        <Slider assets={selected} uploadImage heightSx={140} />
                    </Box>
                }
            />
        </Box>
    )
}