import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../utils";
export const dashboardStyles = makeStyles((theme) => ({
      root: {
            padding: "24px 24px 80px",
            backgroundColor: theme.palette.background.secondary,
            height: `calc(100vh - 64px)`,
            overflow: "auto"
      },
      card: {
            boxShadow: "0px 3px 30px #5C86CB2E",
            borderRadius: theme.palette.borderRadius,
            // border: "3px solid white",
            backgroundColor: theme.palette.background.paper,
            cursor: "pointer",
            minHeight: (props) => props?.height ? props?.height : "100%",
            padding:(props) => props?.padding ? props?.padding : "24px",
            width: '100%'
      },
      Cardcontent: {
            padding: "12px 16px",
      },
      title: {
            fontSize:"1rem",
            color: theme.typography.color.primary,
            fontFamily: ExtraBold,
      },
      titleTop: {
            fontSize:"1rem",
            color: theme.typography.color.primary,
            fontFamily: ExtraBold,
            marginTop: '5px'
      },
      count: {
            fontSize: "1.25rem",
            color: theme.typography.color.primary,
            fontFamily: Bold,
      },
      countCard: {
            fontSize:"1.5rem",
            color: theme.typography.color.primary,
            fontFamily: ExtraBold,
      },

      image: {
            width: 46,
            height: 46,
            backgroundColor: (props) => props?.data?.color ?? "#fff",
      },
      navbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
      },
      graphDataTitle: {
            color: '#98A0AC',
            fontFamily: SemiBold,
            fontSize:"0.75rem",
            marginLeft: '10px'
      },
      graphDataCount: {
            color: '#4E5A6B',
            fontFamily: SemiBold,
            fontSize:"1rem"
      },
      dot: {
            width: '10px',
            height: '10px',
            borderRadius: '50%'
      },
      dotParent: {
            display: 'flex',
            paddingTop: 0
      },
      graphParet: {
            alignItems: 'center',
            paddingTop: (props) => props?.paddingTop ?? "#10px",
      },
      listText: {
            backgroundColor: '#fff',
            width: '120px !important',
            color: '#1C1C1C',
            boxShadow: '#5C86CB2E',
            borderRadius: '4px',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
      },
      listText1: {
            width: '120px !important',
            color: '#98A0AC',
            borderRadius: '4px',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
      },
      tabname: {
            fontSize:"0.75rem",
            fontFamily: Bold,
            cursor: 'pointer'
      },
      list: {
            backgroundColor: '#F5F7FA',
            marginLeft: '10px',
            display: 'flex',
            padding: '5px',
            borderRadius: '4px',
            '&.MuiListItem-root': {
                  width: '100px !important',
                  padding: '0px!important',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  cursor: 'pointer',
                  borderRadius: ' 4px',
            }
      },
      section: {
            marginTop: '10px'
      },
      detailsCard1: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
      },
      detailsCard2: {
            color: '#091B29',
            fontFamily: SemiBold,
            fontSize:"0.875rem",
            maxWidth: (props) => props?.maxWidth ??'107px',
            width: '100%'

      },
      marginLeft: {
            marginLeft: '20px'
      },
      grow: {
            flexGrow: 1,
      },
}))