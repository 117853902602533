import React from 'react';
import Dialog from "@mui/material/Dialog";
import PropTypes from 'prop-types';

export const CustomDialog = (props) =>{

    const {
        open=false,
        onClose=null,
        children="",
        sx={}
    } = props;


    return(
        <>
        {open &&
            <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={sx}
            >   
            <div>
                {children}
            </div>
            </Dialog>
        }
        </>
    )
}

CustomDialog.propTypes={
    open:PropTypes.bool,
    onClose:PropTypes.func,
    children:PropTypes.node,
    sx:PropTypes.oneOfType([PropTypes.array,PropTypes.object])
}