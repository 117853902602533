/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from '@apollo/client';
import { Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { GET_DEPARTMENT } from '../../graphql/queries';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys } from '../../utils';
import { DepartmentHeading, DepartmentPath, DepartmentType, StatusOptionList } from '../../utils/department/departmentListUtils';
import { departmentStyles } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { INSERT_DEPARTMENT_MASTER, UPDATE_DEPARTMENT_MASTER } from '../../graphql/mutations';
import { NewLoader } from '../../components/newLoader';
import { withNamespaces } from 'react-i18next';

const Department = (props) => {
    const { loading, handleLoading, t } = props;

    const defaultAddDepartmentState = {
        formType: "add",
        id: "",
        name: "",
        description: "",
        status: true,
        delete: false,
        error: {
            name: ""
        }
    }

    const classes = departmentStyles()
    const debounce = UseDebounce()
    const client = useApolloClient()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)


    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [DepartmentList, setDepartmentList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState({ status: [true] })
    const [fiterDrawer, setFilterDrawer] = React.useState(false)
    const [addDepartmentDialogOpen, setAddDepartmentDialogOpen] = React.useState(false)
    const [addDepartmentState, setAddDepartmentState] = React.useState({ ...defaultAddDepartmentState })
    const [permission, setPermission] = React.useState({})
    const [loader, setLoader] = React.useState(true)
    const [buttonDisable, setButtonDisable] = React.useState(false)

    // useEffect to get company list for company switcher when loading the screen
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth])

    // useEffect to get department list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedCompany?.value) { getDepartment() }
    }, [selectedCompany, filterData])

    // Function to get department list based on the input data
    const getDepartment = (offset = 0, limit = 10, search = "") => {
        client.query({
            query: GET_DEPARTMENT, fetchPolicy: "network-only",
            variables: {
                clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? "",
                status: (!filterData?.status || filterData?.status?.length === 0) ?
                    [true, false] : filterData?.status,
                companyID: selectedCompany?.value,
                search,
                offset,
                limit,
            }
        }).then((r) => {
            setDepartmentList({
                data: r?.data?.department_master,
                totalRowsCount: r?.data?.count?.[0].count
            })
            setLoader(false)
            handleLoading(false)
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        })
    }

    // Set row data for table
    const DepartmentRows = React.useCallback(DepartmentList?.data?.map((_) => {
        let j
        try {
            j = {
                id: _?.id,
                name: _?.name ?? "-",
                description: _?.description ? _?.description : "-",
                status: _?.is_active ? "Active" : "Inactive",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        }
        return j
    }), [DepartmentList])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setLoader(true)
        setSelectedCompany(value)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in department list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getDepartment(0, limit, e)
    }

    // Function to open add department form
    const handleAddDepartment = () => {
        setButtonDisable(false)
        setAddDepartmentState({ ...defaultAddDepartmentState })
        setAddDepartmentDialogOpen(true)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        const tempData = data?.data
        const tempAddDepartmentState = {
            formType: type,
            id: tempData?.id,
            name: tempData?.name,
            description: tempData?.description,
            status: type === "active" ? !tempData?.is_active : tempData?.is_active,
            delete: type === "delete" ?? tempData?.is_delete,
            error: defaultAddDepartmentState?.error
        }
        setAddDepartmentState({ ...tempAddDepartmentState })
        if (type === "edit" || type === "view") {
            setButtonDisable(false)
            setAddDepartmentDialogOpen(true)
        }
        else if (type === "active" || type === "delete") { handleCreateEdit(tempAddDepartmentState) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getDepartment(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getDepartment(0, value, searchText)
    }

    // Function to update addDepartmentState
    const updateAddDepartmentDialogState = (k, v) => {
        let error = addDepartmentState?.error
        error[k] = ""
        setAddDepartmentState({ ...addDepartmentState, [k]: v, error })
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true
        let error = addDepartmentState.error
        if (addDepartmentState?.name?.length === 0) { isValid = false; error.name = t("Name is Required") }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            })
        }
        setAddDepartmentState({ ...addDepartmentState, error })
        return isValid
    }

    // Function to create a Department
    const handleCreateEdit = async (data) => {
        if ((data?.formType === "active" || data?.formType === "delete") ? true : validate()) {
            setButtonDisable(true)
            const currentDate = new Date().toISOString()
            const profileID = localStorage.getItem(LocalStorageKeys.profileID)

            const payload = {
                company_id: selectedCompany?.value ?? undefined,
                description: data?.description ?? undefined,
                is_active: data?.status ?? undefined,
                is_delete: data?.delete ?? undefined,
                name: data?.name ?? undefined,
                updated_at: currentDate ?? undefined,
                updated_by: profileID ?? undefined
            }

            if (data?.formType === "add") {
                payload.client = localStorage.getItem(LocalStorageKeys.clinetID) ?? undefined
                payload.created_at = currentDate ?? undefined
                payload.created_by = profileID ?? undefined
            }

            client.mutate({
                mutation: data?.id ? UPDATE_DEPARTMENT_MASTER : INSERT_DEPARTMENT_MASTER,
                fetchPolicy: 'network-only',
                variables: {
                    departmentMasterID: data?.id ?? undefined,
                    payload: data?.id ? payload : [payload]
                }
            }).then((r) => {
                setLoader(true)
                setAddDepartmentState({ ...defaultAddDepartmentState })
                getDepartment()
                setAddDepartmentDialogOpen(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: `${data?.id
                        ? (data?.formType === "delete"
                            ? t("Department Deleted Successfully.!!!")
                            : t("Department Updated Successfully.!!!"))
                        : t("Department Created Successfully.!!!")}`,
                })
            }).catch((err) => {
                setButtonDisable(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
                })
            })

        } else { return false }
    }
    const render = () => {
        return <div >
            <Subheader hideBackButton={true} title={t("Department")}
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            {
                loader ? (
                    <NewLoader minusHeight="100px" />
                ) : (
                    <div className={classes.root}>
                        <Grid container className={classes.content} spacing={1}>
                            <Grid item xs={4}>
                                <SearchFilter value={searchText} placeholder={t("Search Department")}
                                    handleChange={(value) => handleSearch(value)}
                                    customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}} />
                            </Grid>
                            <Grid item xs={8}>
                                <Box display={"flex"} justifyContent={"end"}>

                                    <Stack direction="row"
                                        divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "16px" }} />}>
                                        <IconButton onClick={() => setFilterDrawer(!fiterDrawer)}
                                            className={classes.filterButton}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(filterData.status?.length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>
                                        <Button variant="contained" className={classes.button}
                                            onClick={handleAddDepartment}>
                                            {t("Add A Department")}
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid >
                            <Grid item xs={12}>
                                <TableWithPagination
                                    heading={DepartmentHeading(t)}
                                    rows={DepartmentRows}
                                    path={DepartmentPath}
                                    showpagination={true}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    handleIcon={handleTableIcon}
                                    onClick={() => console.log("")}
                                    tableType="no-side"
                                    dataType={DepartmentType}
                                    handlePagination={handleTablePagination}
                                    handleChangeLimit={handleTablePageLimit}
                                    totalRowsCount={DepartmentList?.totalRowsCount}
                                    page={page}
                                    limit={limit}
                                    height={'calc(100vh - 290px)'}
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            </Grid>
                        </Grid >
                        <FilterGenerator open={fiterDrawer} onClose={() => setFilterDrawer(false)}
                            onApply={(value) => {
                                setLoader(true)
                                setFilterData(value)
                            }}
                            components={[
                                {
                                    component: "toggleButton",
                                    value: filterData?.status,
                                    options: StatusOptionList,
                                    isMulti: true,
                                    state_name: "status",
                                    label: t("Status")
                                },
                            ]} />
                        <Dialog
                            className={classes.addDepartmentDialog}
                            open={addDepartmentDialogOpen}
                            onClose={() => setAddDepartmentDialogOpen(false)}>
                            <div className={classes.addDepartmentDialogHeader}>
                                <Typography className={classes.addDepartmentDialogHeaderTitle}>
                                    {addDepartmentState?.formType === "add" ? t("Add Department") :
                                        addDepartmentState?.formType === "edit" ? t("Edit Department") :
                                            addDepartmentState?.formType === "view" ? t("View Department") : t("Add Department")}
                                </Typography>
                                <IconButton onClick={() => setAddDepartmentDialogOpen(false)}
                                    className={classes.addDepartmentDialogCloseButton}>
                                    <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                                </IconButton>
                            </div>
                            <div className={classes.addDepartmentDialogBody}>
                                <TextBox
                                    isrequired
                                    isReadonly={addDepartmentState?.formType === "view"}
                                    label={t("Department Name")}
                                    placeholder={t("Enter Name")}
                                    value={addDepartmentState?.name ?? ""}
                                    onChange={(e) => updateAddDepartmentDialogState("name", e.target.value)}
                                    isError={addDepartmentState?.error?.name?.length > 0}
                                    errorMessage={addDepartmentState?.error?.name} />
                                <Box height={16} />
                                <TextBox
                                    isReadonly={addDepartmentState?.formType === "view"}
                                    label={t("Description")}
                                    placeholder={t("Enter Description")}
                                    multiline
                                    value={addDepartmentState?.description ?? ""}
                                    onChange={(e) => updateAddDepartmentDialogState("description", e.target.value)} />
                                <Box height={16} />
                                <Typography className={classes.addDepartmentDialogFieldLabel} noWrap>{t("Status")}</Typography>
                                <div className={classes.addDepartmentDialogButtonContainer}>
                                    {StatusOptionList.map((_) => {
                                        return <Button className={_?.value === addDepartmentState?.status ?
                                            classes.addDepartmentDialogButtonSelected :
                                            classes.addDepartmentDialogButtonUnSelected}
                                            onClick={() => (addDepartmentState?.formType === "add" ||
                                                addDepartmentState?.formType === "edit") ?
                                                updateAddDepartmentDialogState("status", _?.value) : false}>
                                            {_?.label}
                                        </Button>
                                    })}
                                </div>
                            </div>
                            {(addDepartmentState?.formType === "add" || addDepartmentState?.formType === "edit") &&
                                <div className={classes.addDepartmentDialogFooter}>
                                    {addDepartmentState?.formType === "edit" && <><Button fullWidth
                                        className={classes.addDepartmentDialogFooterCloseButton}
                                        onClick={() => setAddDepartmentDialogOpen(false)}>
                                        {t("Cancel")}
                                    </Button></>}
                                    <Button variant="contained" fullWidth
                                        className={classes.addDepartmentDialogFooterButton}
                                        disabled={buttonDisable}
                                        onClick={() => handleCreateEdit(addDepartmentState)}>
                                        {addDepartmentState?.formType === "add" ? t("Create") : t("Save")}
                                    </Button>
                                </div>}
                        </Dialog>
                    </div >)}
        </div >
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}
export default withNamespaces("department")(Department)
