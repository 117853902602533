import { useApolloClient } from "@apollo/client";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CustomTabs } from "../../components";
import { CustomSelect } from "../../components/filterGenerator/components";
import { TeamsSelect } from "../../components/teamsSelect";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GETLEADBYOWNER } from "../../graphql/quotationQueries";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  AlertProps,
  ExtraBold,
  getCompanyOption,
  getRoutePermissionNew,
  LocalStorageKeys,
  Regular,
  remCalc,
  useWindowDimensions,
} from "../../utils";
import { Filter as FilterComponent } from "../propertyFinder3/component/filter";
import { CustomPaper, CustomTypography, FilterList } from "./components";
import { Commercial, Promotion, Residential } from "./tabPanel";
import {
  commercialValidation,
  filterType,
  getCoords,
  isHas,
  leaseValidation,
  residentialValidation,
  returnFilterValue,
  typeCaps,
} from "./utils";
import { Bold } from "../../utils";
import { withNamespaces } from "react-i18next";
import { Workspace, Professionals } from "../../assets";

const BackgroundImage = styled(Grid)(({ theme, size, is_workspace }) => ({
  backgroundImage: is_workspace
    ? `url(/images/workspace_bg.png)`
    : `url(/images/propertySearchBanner.png)`,
  backgroundSize: is_workspace
    ? "100% 100%"
    : size?.width > 599
    ? "contain"
    : "auto 150px",
  backgroundPositionX: size?.width > 599 ? "auto" : "center",
  paddingBottom: 0,
  paddingLeft: 0,
  backgroundRepeat: "no-repeat",
  height: `100vh`,
  overflow: "auto",
  display: "flex",
  alignItems: is_workspace ? "center" : "start",
  alignContent: is_workspace ? "center" : "start",
  borderRadius: theme.palette.borderRadius,
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
}));
const Wrapper = styled("div")(({ theme, size }) => ({
  padding:
    size?.width > 899
      ? "30px 0px 30px 0px"
      : size?.width > 599
      ? "15px 0px 30px 0px"
      : "8px 0px 30px 0px",
  // maxWidth: '90%',
  //margin: 'auto',
}));

const FilterButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#F1F7FF",
  borderRadius: theme.palette.borderRadius,
  width: 42,
  height: 42,
}));

const PropertyFinder = (props) => {
  const { loading, handleLoading, t = () => false } = props;
  const tabList = [
    { title: t("Residential") },
    { title: t("Commercial") },
    // { title: 'Short Term Rental' },
  ];
  const module = localStorage.getItem(LocalStorageKeys.activeRoleModuleId);
  const auth = React.useContext(AuthContext);
  const theme = useTheme();
  const alert = useContext(AlertContext);
  const history = useHistory();
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId);
  const { state: LocationParams } = useLocation();
  const [buttonDisable, setButtonDisable] = useState(false);

  const size = useWindowDimensions();
  const tabStyles = {
    tabs: {
      minHeight: 32,
      "& .MuiTab-root": {
        padding: "8px 16px !important   ",
      },
      "& .MuiTabs-flexContainer": {
        gap: "4px",
        "& .MuiButtonBase-root": {
          backgroundColor: theme.palette.background.tertiary,
          borderRadius: "4px",
          padding: "8px 12px",
          minHeight: "32px",
          "& p": {
            fontFamily: Regular,
            fontSize: "0.75rem",
            color: theme.typography.color.tertiary,
          },
        },
        "& .Mui-selected": {
          "& p": {
            color: moduleId === "35" ? "#091B29 !important" : "#fff !important",
            fontFamily: Bold,
          },
          backgroundColor:
            moduleId === "35" ? "#F2F4F7" : theme.palette.primary.main,
        },
      },
      "& .MuiTabs-indicator": {
        height: 0,
      },
    },
  };

  const [activeTab, setActiveTab] = useState(0);
  const [location, setLocation] = useState({});

  let initialState = useMemo(
    () => ({
      propertyPurpose: "Lease",
      propertyType: { value: null, label: "All" },
      unitCategory: { value: null, label: "All" },
      duration: {
        date: new Date(),
        input: 12,
        period: "Month",
      },
      handOverDate: { date: new Date() },
      // eslint-disable-next-line
    }),
    [activeTab]
  );

  const [state, setState] = useState({
    residential: {
      ...initialState,
      bedBath: {
        bed: 1,
        bath: 1,
      },
      lead: null,
    },
    commercial: {
      ...initialState,
      lead: null,
    },
    storage: {
      ...initialState,
      lead: null,
    },
    lease: {
      propertyType: { value: null, label: "All" },
      unitCategory: { value: null, label: "All" },
    },
  });

  const [statefilter, setStateFilter] = React.useState({});
  const [filterData, setFilterData] = React.useState({});
  const [drawer, setDrawer] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [teamNumber, setTeamNumber] = useState({});
  const backdrop = useContext(BackdropContext);
  const [selectedLead, setSelectedLead] = React.useState(null);
  const [permission, setPermission] = React.useState({});
  const [leadList, setLeadlist] = useState([]);
  const client = useApolloClient();

  // use effect to get permission
  React.useEffect(() => {
    getLeadList("", 10, true);
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getCurrentLocation();
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setFilterData({
            rangetype: company?.selected?.uom,
            budgettype: company?.selected?.currency,
          });

          if (LocationParams) {
            setSelectedCompany(LocationParams?.company ?? company?.selected);
          } else {
            setSelectedCompany(company?.selected);
          }
          handleLoading(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const getCurrentLocation = async () => {
    const location = await getCoords();
    if (location?.latitude && location?.longitude) {
      setLocation(location);
    }
  };

  const updateStateFilter = (key, value) => {
    setStateFilter({ ...statefilter, [key]: value });
  };

  const onChangeState = (parentKey, key, value) => {
    if (key === "location") {
      setLocation(value);
    } else if (value !== null) {
      // Check if the new value is not null
      let updateState = {
        ...state,
        [parentKey]: {
          ...state?.[parentKey],
          [key]: value,
        },
      };
      setState(updateState);
    }
    // If value is null, it means the user tried to deselect the only selected button.
    // In this case, do nothing to prevent the deselection.
  };

  //get lead
  const getLeadList = (search, offset, searchBool) => {
    client
      .query({
        query: GETLEADBYOWNER,
        fetchPolicy: "network-only",
        variables: {
          companyId: parseInt(selectedCompany?.value),
          searchText: search,
          offset: offset,
          clinetID: localStorage.getItem(LocalStorageKeys.clinetID),
          limit: 10,
        },
      })
      .then((response) => {
        if (searchBool) {
          setLeadlist(response.data.lead);
        } else {
          setLeadlist(leadList.concat(response.data.lead));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLead = (leadDetail) => {
    setSelectedLead(leadDetail);
    let type = switchType(activeTab);
    const periodEnum = {
      Monthly: "Month",
      Yearly: "Year",
    };

    const { lease_start_date, values, lease_period } = leadDetail;
    let lead_fill = {
      duration: {
        date: new Date(lease_start_date),
        input: values,
        period: periodEnum[lease_period],
      },
    };
    setState({
      ...state,
      [type]: { ...state[type], ...lead_fill, lead: leadDetail },
    });
    setFilterData({
      ...filterData,
      facilitesbool: leadDetail?.grace_period_value,
    });
    setActiveTab(switchTypeByvalue(leadDetail?.unit_usage));
  };

  const switchTab = useCallback(() => {
    switch (activeTab) {
      case 0:
        return moduleId === "35" ? (
          <Commercial
            data={state?.commercial}
            onChangeState={(key, value) =>
              onChangeState("commercial", key, value)
            }
            location={location}
            filterData={filterData}
            company={selectedCompany?.value}
            hideRevenue={true}
            handleLead={handleLead}
            selectedLead={selectedLead}
            t={t}
          />
        ) : (
          <Residential
            data={state?.residential}
            onChangeState={(key, value) =>
              onChangeState("residential", key, value)
            }
            location={location}
            company={selectedCompany?.value}
            filterData={filterData}
            handleLead={handleLead}
            selectedLead={selectedLead}
            t={t}
          />
        );
      case 1:
        return (
          <Commercial
            data={state?.commercial}
            onChangeState={(key, value) =>
              onChangeState("commercial", key, value)
            }
            location={location}
            filterData={filterData}
            company={selectedCompany?.value}
            hideRevenue={true}
            handleLead={handleLead}
            selectedLead={selectedLead}
            t={t}
          />
        );
      // case 2:
      //     return (
      //         <Storage
      //             data={state?.storage}
      //             onChangeState={(key, value) => onChangeState('storage', key, value)}
      //             location={location}
      //             filterData={filterData}
      //             company={selectedCompany?.value}
      //         />
      //     );
      // case 2:
      //     return (
      //         <ShortTermRental
      //             data={state?.lease}
      //             onChangeState={(key, value) => onChangeState('lease', key, value)}
      //             location={location}
      //             company={selectedCompany?.value}
      //             handleLead={handleLead}
      //             selectedLead={selectedLead}
      //         />
      //     );
      default:
        return <div />;
    }
    // eslint-disable-next-line
  }, [activeTab, state, location, filterData, selectedCompany]);

  const switchType = (type) => {
    if (type === 0) {
      return moduleId === "35" ? "commercial" : "residential";
    } else if (type === 1) {
      return "commercial";
    } else if (type === 2) {
      return "lease";
    }
  };

  const switchTypeByvalue = (type) => {
    if (type === "Residential") {
      return 0;
    } else if (type === "Commercial") {
      return moduleId === "35" ? 0 : 1;
    }
    // else if (type === 'Storage') {
    //     return 2
    // }
    else if (type === "Lease") {
      return 2;
    } else {
      return 1;
    }
  };

  const validate = () => {
    let result;
    let type = switchType(activeTab);
    if (type === "residential") {
      result = residentialValidation({ ...state?.[type], location });
    } else if (type === "commercial") {
      result = commercialValidation({ ...state?.[type], location });
    } else if (type === "lease") {
      result = leaseValidation({ ...state?.[type], location });
    }

    if (!result) return true;
    showAlert("Please fill all the fields");
    return false;
  };

  const showAlert = (msg = "") => {
    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.error,
      msg,
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
  };

  const search = () => {
    if (validate()) {
      setButtonDisable(true);
      let type = switchType(activeTab);
      history.push({
        pathname: Routes.propertyResult,
        state: {
          data: { ...state?.[type], location, lead: selectedLead },
          type,
          location,
          purpose:
            type === "lease" ? "Commercial" : typeCaps(switchType(activeTab)),
          filterData,
          filterType: returnFilterValue(activeTab),
          companyID: selectedCompany,
          teamNumber: teamNumber,
          moduleId: moduleId,
        },
      });
    }
  };

  const clearFilter = () => {
    setState((prevState) => ({
      ...prevState,
      residential: {
        ...prevState.residential,
        propertyPurpose: "Lease",
      },
      commercial: {
        ...prevState.commercial,
        propertyPurpose: "Lease",
      },
      storage: {
        ...prevState.storage,
        propertyPurpose: "Lease",
      },
      lease: {
        ...prevState.lease,
        propertyPurpose: "Lease",
      },
    }));
    setLocation({});
    // other state resets...
  };

  const onClear = () => {
    setStateFilter({});
  };

  const openFilter = () => {
    setStateFilter(filterData);
    setDrawer(true);
  };

  const onClearChips = (key, value) => {
    let copyFilterData = { ...filterData };
    setFilterData(() => {
      if (key === "range") {
        delete copyFilterData["range1"];
        delete copyFilterData["range2"];
      } else if (key === "budget") {
        delete copyFilterData["budget1"];
        delete copyFilterData["budget2"];
      } else {
        delete copyFilterData[key];
      }
      return {
        ...copyFilterData,
      };
    });
  };

  const handleCompanyChange = (value) => {
    setTeamNumber({});
    setFilterData({
      rangetype: {
        value: value?.uom,
        label: value?.uom,
      },
      budgettype: value?.currency,
    });
    setSelectedCompany(value);
  };

  const handleTeamNumber = (value) => {
    setTeamNumber(value);
  };

  const header = [
    {
      title: t("Effortless"),
    },
    {
      title: t("Workspace"),
      icon: <Workspace />,
      bgColor: "#289295",
      border: "#37BBBE",
    },
    {
      title: t("Discover"),
    },
    {
      title: t("For"),
    },
    {
      title: t("Professionals"),
      icon: <Professionals />,
      bgColor: "#15702A",
      border: "#418E5F",
    },
  ];

  const render = () => {
    return (
      <>
        <Box marginTop="-15px">
          <BackgroundImage
            container
            justifyContent="center"
            size={size}
            is_workspace={moduleId === "35"}
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              display: "grid",
              justifyItems: "center",
              alignContent: "center",
              borderRadius: 0,
              paddingBottom: "35px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {module === "35" ? (
                <Wrapper size={size}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    justifyContent={"center"}
                    marginBottom="12px"
                  >
                    {header?.map((val) => {
                      return (
                        <Box>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems={"center"}
                            sx={{
                              padding: "2px 16px",
                              backgroundColor: val?.bgColor ?? "auto",
                              border: val?.border
                                ? `1px solid ${val?.border}`
                                : "none",
                              borderRadius: "32px",
                            }}
                          >
                            {val?.icon}
                            <CustomTypography
                              color={"#fff"}
                              fontSize={remCalc(36)}
                              fontFamily={ExtraBold}
                              sx={(theme) => ({
                                [theme.breakpoints.down("sm")]: {
                                  fontSize: "25px",
                                },
                              })}
                            >
                              {val?.title}
                            </CustomTypography>
                          </Stack>
                        </Box>
                      );
                    })}
                  </Box>
                </Wrapper>
              ) : (
                <Wrapper size={size}>
                  <CustomTypography
                    color={"#fff"}
                    fontSize={12}
                    fontFamily={Bold}
                  >
                    {t("PROPERTY SEARCH")}
                  </CustomTypography>
                  <CustomTypography
                    color={"#FA6E41"}
                    fontSize={30}
                    fontFamily={Bold}
                    sx={(theme) => ({
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "25px",
                      },
                    })}
                  >
                    <CustomTypography
                      component={"span"}
                      color={"#fff"}
                      fontFamily={"inherit"}
                      fontSize={"inherit"}
                    >
                      {t("Find")} {t("Your Real Estate")}{" "}
                    </CustomTypography>
                    {"& Get"}
                    <CustomTypography
                      component={"span"}
                      color={"#fff"}
                      fontFamily={"inherit"}
                      fontSize={"inherit"}
                    >
                      {" "}
                      {t("Your Dream Space")}{" "}
                    </CustomTypography>
                  </CustomTypography>
                </Wrapper>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={9} marginTop="-25px">
              <CustomPaper
                backgroundColor={"rgba(255, 255, 255, 0.1)"} // semi-transparent white
                borderRadius={theme.palette.borderRadius}
                padding={theme.spacing(1.5)}
                sx={(theme) => ({
                  backdropFilter: "blur(5px)", // blur the background
                  border: "1px solid rgba(255, 255, 255, 0.2)", // optional light border
                  maxWidth: "1200px",
                  marginLeft: "auto",
                  marginBottom: 4,
                  [theme.breakpoints.down("510")]: {
                    maxWidth: "100%",
                  },
                })}
              >
                <CustomTabs
                  tabList={
                    module === "35"
                      ? [{ title: t("Commercial (Lease)") }]
                      : tabList
                  }
                  active={activeTab}
                  onChangeTab={(value) => {
                    setSelectedLead(null);
                    setActiveTab(value);
                  }}
                  clearFilter={clearFilter}
                  alignment={size?.width >= 595 ? "row" : "column"}
                  clearBtnColor="#000"
                  clearBtnWeight="600"
                  tabPanel={<div style={{ paddingTop: 16 }}>{switchTab()}</div>}
                  sx={tabStyles}
                  t={t}
                  rightSideComponent={
                    <Stack
                      direction="row"
                      gap={2}
                      alignItems={"center"}
                      width={size?.width >= 595 ? "auto" : "100%"}
                    >
                      <Box width={size?.width >= 595 ? "220px" : "100%"}>
                        <CustomSelect
                          options={companyList}
                          value={selectedCompany}
                          noSearch={true}
                          onChange={(e) => {
                            handleCompanyChange(e);
                            e?.value &&
                              localStorage.setItem(
                                LocalStorageKeys.selectedCompany,
                                JSON.stringify(e)
                              );
                          }}
                        />
                      </Box>
                      {teamNumber?.value !== "noteams" && (
                        <Box width="220px">
                          <TeamsSelect
                            companyId={selectedCompany?.value}
                            handleTeamsChange={handleTeamNumber}
                            moduleId={moduleId}
                            customSelect
                          />
                        </Box>
                      )}
                      <FilterButton
                        sx={{
                          backgroundColor: "#fff",
                          border: "1px solid #E4E8EE",
                          borderRadius: "4px",
                          "& img": { width: 14, height: 14 },
                        }}
                        onClick={openFilter}
                      >
                        <img src="/images/filterWhite.svg" alt="" />
                      </FilterButton>
                    </Stack>
                  }
                />

                {permission?.read && (
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      {Object.keys(filterData)?.length > 0 && (
                        <div style={{ marginTop: 16 }}>
                          <FilterList
                            filterData={filterData}
                            onClearChips={onClearChips}
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        disabled={buttonDisable}
                        color={"primary"}
                        variant={"contained"}
                        fullWidth
                        sx={{
                          borderRadius: "4px",
                          marginTop: "12px",
                          p: "10px",
                        }}
                        onClick={search}
                      >
                        {t("Search")}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </CustomPaper>
            </Grid>
          </BackgroundImage>
          <Drawer
            anchor="right"
            open={drawer}
            onClose={() => setDrawer(false)}
            sx={{
              "& .MuiDrawer-paper": {
                boxShadow: "none",
                border: 0,
                backgroundColor: "transparent",
              },
            }}
          >
            <FilterComponent
              t={t}
              filter={returnFilterValue(activeTab)}
              type={filterType(switchType(activeTab))}
              applyfilter={() => {
                setDrawer(false);
                setFilterData(statefilter);
              }}
              onClear={onClear}
              state={statefilter}
              company={selectedCompany?.value}
              updateState={updateStateFilter}
              onClose={() => {
                setDrawer(false);
              }}
            />
          </Drawer>
        </Box>
      </>
    );
  };

  return <div>{accessCheckRender(render, permission, "", loading)}</div>;
};
export default withNamespaces("propertyFinder")(PropertyFinder);
