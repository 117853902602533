import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Typography, Box } from "@mui/material";
import { Bold, SemiBold } from "../../utils";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme, props) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: (props) => props?.justifyContent ?? "center",
    alignItems: "center",
    marginTop: "-20px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center !important",
    },
    "& svg": {
      // borderRadius: "50%",
      width: "100%",
      // minWidth: (props) => props?.height ?? "165px !important",
      // height: (props) => props?.height ?? "165px !important",

      position: "relative",
      cursor: "pointer",
      [theme.breakpoints.up("sm")]: {
        width: "100% !important",
        margin: "0 auto",
      },
      "& .recharts-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  center: {
    textAlign: "center",
    position: "absolute",
    left: "0px",
    right: "0px",
    top: (props) => props?.centerTop ? props?.centerTop : "36%",

    zIndex: 1,
    padding: "12px",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      right: "0px",
      top: "36%",
    },
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: SemiBold,
  },
  count: {
    fontSize:"1.125rem",
    color: theme.typography.color.primary,
    fontFamily: Bold,
  },
  tooltip: {
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
    display: "flex",
    alignItems: "center",
    padding: "4px 7px",
    zIndex: 999,
    borderRadius: "4px",
    position: "relative",
  },
  name: {
    color: "#7C8594",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
  },
  value: {
    color: "#4E5A6B",
    fontSize:"1rem",
    fontFamily: SemiBold,

    marginInlineEnd: "8px",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",

    marginInlineEnd: "8px",
  },

  // arrow: {
  //   width: "0px",
  //   height: "0px",
  //   borderLeft:'10px solid transparent',
  //   borderRight:'10px solid transparent',
  //   borderTop:'15px solid #F2F4F7'
  // },
  // arrowParent: {
  //   position: "absolute",
  //   bottom: "-12px",
  //   textAlign:'center',
  //   left:0,
  //   display:'flex',
  //   justifyContent:'center',
  //   alignItems:'center',
  //   width:'100%'
  // },
  chartsm: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 300
    },
    [theme.breakpoints.up('sm')]: {
      height: 500
    },
    position: "relative"
  }

}));

// tool tip
const Tip = ({ setShowTooltip, ...rest }) => {
  const classes = useStyles();
  const [payload, setPayload] = React.useState(rest?.payload);

  React.useEffect(() => {
    rest?.payload?.length && setPayload(rest?.payload);
  }, [rest?.payload]);

  return payload?.length ? (
    <div
      onMouseLeave={() => setShowTooltip(false)}
      onMouseMove={(e) => e.stopPropagation()}
      className={classes.tooltip}
    >
      <Box
        className={classes.dot}
        style={{ backgroundColor: payload?.[0]?.payload?.fill }}
      />
      {rest?.tooltip_prefix && <Typography className={classes.value}>{rest?.tooltip_prefix}</Typography>}
      <Typography className={classes.value}>{payload?.[0]?.value}</Typography>
      <Typography className={classes.name}>{payload?.[0]?.name}</Typography>
      {/* <div className={classes.arrowParent}>
        <Box className={classes.arrow} />
      </div> */}
    </div>
  ) : null;
};

const PieCharts = (props) => {
  const { t } = props
  const [showTooltip, setShowTooltip] = React.useState(false);
  const classes = useStyles(props);
  return (
    <>
      {
        props?.is_popUp ?
          <Box className={classes.chartsm}>
            <ResponsiveContainer >
              {props?.bar ? (
                <BarChart
                  width={1000}
                  height={500}
                  data={props?.data}
                // className={classes.chart}
                >
                  <CartesianGrid
                    stroke="#E4E8EE"
                    vertical={false}
                    strokeDasharray="3"
                  />

                  <Bar dataKey="count" fill="green" barSize={55} />
                  <XAxis axisLine={false} tickLine={false} hide />
                  <YAxis axisLine={false} tickLine={false} />

                  <Tooltip cursor={false} zIndex={1} />
                </BarChart>
              ) : (
                <PieChart
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <Pie
                    onMouseEnter={() => setShowTooltip(true)}
                    data={props?.data}
                    cx="50%"
                    cy="50%"
                    paddingAngle={props?.paddingAngle ?? 0}
                    // outerRadius={100}
                    innerRadius={props?.radius}
                    dataKey="count"
                  >
                    {props?.data.map((entry, index) => (
                      <Cell  style={{outline: 'none'}} key={`cell-${index}`} />
                    ))}
                  </Pie>
                  {showTooltip && (
                    <Tooltip
                      isAnimationActive={false}
                      content={<Tip setShowTooltip={setShowTooltip} tooltip_prefix={props?.tooltip_prefix} />}
                      wrapperStyle={{ visibility: "visible", pointerEvents: "auto" }}
                    />
                  )}
                </PieChart>
              )}
            </ResponsiveContainer>
            {props?.isTotal && (
              <div className={classes.center}>
                <Typography className={classes.count}>{props?.total ?? 0}</Typography>
                <Typography className={classes.title}>{props?.centerTitle ? props?.centerTitle : t("Total")}</Typography>
              </div>
            )}
          </Box>
          :
          <div className={classes.root} style={{ width: '100%', height: props?.height, position: "relative" }}>
            <ResponsiveContainer
              width={props?.width}
              height={200}
              style={{ margin: "0 auto" }}
            >
              {props?.bar ? (
                <BarChart
                  width={props?.width}
                  height={250}
                  data={props?.data}
                  className={classes.chart}
                >
                  <CartesianGrid
                    stroke="#E4E8EE"
                    vertical={false}
                    strokeDasharray="3"
                  />

                  <Bar dataKey="count" fill="green" barSize={55} />
                  <XAxis axisLine={false} tickLine={false} hide />
                  <YAxis axisLine={false} tickLine={false} />

                  <Tooltip cursor={false} zIndex={1} />
                </BarChart>
              ) : (
                <PieChart
                  className={classes.chart}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <Pie
                    onMouseEnter={() => setShowTooltip(true)}
                    data={props?.data}
                    cx="50%"
                    cy="50%"
                    paddingAngle={props?.paddingAngle ?? 0}
                    outerRadius={100}
                    innerRadius={props?.radius}
                    dataKey="count"
                  >
                      {props?.data.map((entry, index) => (
                      <Cell  style={{outline: 'none'}} key={`cell-${index}`} />
                    ))}
                    </Pie>
                  {showTooltip && (
                    <Tooltip
                      isAnimationActive={false}
                      content={<Tip setShowTooltip={setShowTooltip} tooltip_prefix={props?.tooltip_prefix} />}
                      wrapperStyle={{ visibility: "visible", pointerEvents: "auto" }}
                    />
                  )}
                </PieChart>
              )}
            </ResponsiveContainer>
            {props?.isTotal && (
              <div className={classes.center}>
                <Typography className={classes.count}>{props?.total ?? 0}</Typography>
                <Typography className={classes.title}>{props?.centerTitle ? props?.centerTitle : t("Total")}</Typography>
              </div>
            )}
          </div>
      }
    </>
  );
};
export default withNamespaces("graphComponent")(PieCharts)
