import { Box, Typography } from "@mui/material";
import React from "react";
import { CompanySettingsList } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { UOM_MASTER } from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, billGenENUM, enumName, enumSelect, enum_types, NetWorkCallMethods } from "../../../utils";
import { basicSettingState, returnSaveData } from "../../../utils/clientsettings";
import { quotation_validity } from "../../../utils/companySettings";
import { SettingSave } from "../../companyCreation/components";
import { useStyles } from "../styles";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { FormGenerator } from "../../../components";

export const BasicSettings = ({
    selected = {},
    loadOptionData = () => false,
    loading = false,
    settings = {},
    isDisableBtn=false,
    onSubmitSettings = () => false,
    t = () => false

}) => {
    const classes = useStyles();
    const [data, setData] = React.useState({ ...basicSettingState })
    const alert = React.useContext(AlertContext);
    const [UOMValue, setUOMValue] = React.useState()
    const [enumValue, setEnumValue] = React.useState({
        uom: [],
        company_payment_period: [],
        payment_terms: [],
        billing_cycle_date: [],
        bill_gen: [],
        payment_gateway: [],
        company_lease_period: []
    })
    //get enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.area_metric_type, enum_types.company_payment_period, enum_types.payment_terms, enum_types.billing_cycle_date, enum_types.billing_cycle, enum_types.company_payment_gateway, enum_types.company_lease_period])
        setEnumValue({
            uom: result?.area_metric_type,
            company_payment_period: result?.company_payment_period,
            payment_terms: result?.payment_terms,
            billing_cycle_date: result?.billing_cycle_date?.filter(i => i?.value !== billGenENUM.last_day).filter(i => i?.value !== billGenENUM.mid_date).filter(i => i?.value !== billGenENUM.first).reverse(),
            bill_gen: result?.billing_cycle,
            payment_gateway: result?.company_payment_gateway,
            company_lease_period: result?.company_lease_period
        })
    }
    //update data
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    }
    //uom api call
    const getUOMMaster = async () => {
        const payload = {
            query: UOM_MASTER().loc.source.body,
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setUOMValue(response?.data?.data?.uom_master)

            }).catch((error) => {

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    }
    //initial load
    React.useLayoutEffect(() => {
        getEnum()
        getUOMMaster()
        setData(settings)
        // eslint-disable-next-line
    }, [settings])
    //onchange toggle
    // const onChangeToggleView = (index) => {
    //     if (toggleView.includes(index)) {
    //         setToggleView(toggleView?.filter(_ => _ !== index));
    //         return
    //     }
    //     setToggleView([...toggleView, index])
    // }
    //render gateway
    // const renderGatway = () => {
    //     return (

    //         data?.default_payment_gateway?.value === "Solution Provider" &&
    //         <Grid container spacing={1} marginTop="8px">

    //             <Grid item xs={12} sm={6} md={3}>
    //                 <TextBox
    //                     isrequired
    //                     autocomplete={"new-name"}
    //                     label={'Login name'}
    //                     placeholder={'Enter name'}
    //                     value={data?.loginName ?? ''}
    //                     onChange={(e) => updateState('loginName', e.target.value)}
    //                     isError={data?.error?.loginName?.length > 0}
    //                     errorMessage={data?.error?.loginName}
    //                 />
    //             </Grid>
    //             <Grid item xs={12} sm={6} md={3}>
    //                 <TextBox
    //                     // {...textBoxStyle}
    //                     isrequired
    //                     autocomplete={"new-password"}
    //                     label={'Login password'}
    //                     type={toggleView?.includes('1') ? "text" : "password"}
    //                     placeholder={'Enter password'}
    //                     value={data?.loginPassword ?? ''}
    //                     endAdornment={(
    //                         <InputAdornment position="end">
    //                             <IconButton
    //                                 aria-label="toggle password visibility"
    //                                 onClick={() => onChangeToggleView('1')}
    //                                 edge="end"
    //                             >
    //                                 {toggleView?.includes('1') ? <VisibilityOff /> : <Visibility />}
    //                             </IconButton>
    //                         </InputAdornment>
    //                     )}
    //                     isError={data?.error?.loginPassword?.length > 0}
    //                     errorMessage={data?.error?.loginPassword}
    //                     onChange={(e) => updateState('loginPassword', e.target.value)}
    //                 />
    //             </Grid>
    //             <Grid item xs={12} sm={6} md={3}>
    //                 <TextBox
    //                     // {...textBoxStyle}
    //                     isrequired
    //                     label={'Login token'}
    //                     type={toggleView?.includes('2') ? "text" : "password"}
    //                     placeholder={'Enter login token'}
    //                     value={data?.loginToken ?? ''}
    //                     endAdornment={(
    //                         <InputAdornment position="end">
    //                             <IconButton
    //                                 aria-label="toggle password visibility"
    //                                 onClick={() => onChangeToggleView('2')}
    //                                 edge="end"
    //                             >
    //                                 {toggleView?.includes('2') ? <VisibilityOff /> : <Visibility />}
    //                             </IconButton>
    //                         </InputAdornment>
    //                     )}
    //                     isError={data?.error?.loginToken?.length > 0}
    //                     errorMessage={data?.error?.loginToken}
    //                     onChange={(e) => updateState('loginToken', e.target.value)}
    //                 />
    //             </Grid>
    //             <Grid item xs={12} sm={6} md={3}>
    //                 <TextBox
    //                     // {...textBoxStyle}
    //                     isrequired
    //                     label={'Payment Provider Name'}
    //                     placeholder={'Enter Payment Provider Name'}
    //                     value={data?.paymentProviderName ?? ''}
    //                     isError={data?.error?.paymentProviderName?.length > 0}
    //                     errorMessage={data?.error?.paymentProviderName}
    //                     onChange={(e) => updateState('paymentProviderName', e.target.value)}
    //                 />
    //             </Grid>
    //             <Grid item xs={12} sm={6} md={3}>
    //                 <TextBox
    //                     // {...textBoxStyle}
    //                     isrequired
    //                     label={'Url'}
    //                     isError={data?.error?.provider_url?.length > 0}
    //                     errorMessage={data?.error?.provider_url}
    //                     placeholder={'Enter Url'}
    //                     value={data?.provider_url ?? ''}
    //                     onChange={(e) => updateState('provider_url', e.target.value)}
    //                 />
    //             </Grid>
    //         </Grid>
    //     )
    // }
    //validate
    const validateForm = () => {
        let isValid = true;
        let error = data.error;

        if (data?.default_base_currency?.length === 0) {
            isValid = false;

            error.default_base_currency = t("Default Base Currency is Required");
        }
        if (data?.default_time_zone?.length === 0) {
            isValid = false;
            error.default_time_zone = t("Default TimeZone is Required");
        }
        if (data?.default_uom?.length === 0) {
            isValid = false;
            error.default_uom = t("Default Unit Measurement is Required");
        }

        // if (data?.quotation_validity?.is_active === true) {
        //     if (data?.quotation_validity?.value?.length === 0) {
        //         isValid = false;
        //         error.quotation_validity = "Default Quotation Validity is Required";
        //     }
        // }

        if (data?.billing_payment_peroid?.length === 0) {
            isValid = false;

            error.billing_payment_peroid = t("Default Billing Payment Period is Required");
        }
        if (data?.billing_payment_terms?.length === 0) {
            isValid = false;
            error.billing_payment_terms = t("Default Billing Payment Terms is Required");
        }
        if (data?.billing_payment_terms?.value === "User Defined") {
            if (data?.billing_payment_terms_value?.length === 0) {
                isValid = false;
                error.billing_payment_terms = t("Default Billing Payment Terms Value is Required");
            }
        }
        if (data?.billing_cycle_method?.length === 0) {
            isValid = false;

            error.billing_cycle_method = t("Default Billing Cycle Methods is Required");
        }
        if (data?.lease_period?.length === 0) {
            isValid = false;
            error.lease_period = t("Default Lease Peroid is Required");
        }
        if (data?.billing_generate_date?.length === 0) {
            isValid = false;
            error.billing_generate_date = t("Default Billing Generate Date is Required");
        }

        // if (data?.default_payment_gateway?.length === 0) {
        //     isValid = false;
        //     error.default_payment_gateway = "Default Payment Gateway is Required";
        // }

        // if (data?.default_payment_gateway?.value === "Solution Provider") {
        //     if (data?.loginName?.length === 0 || data?.loginPassword?.length === 0 || data?.loginToken?.length === 0 || data?.paymentProviderName?.length === 0 || data?.provider_url?.length === 0) {
        //         isValid = false;
        //         error.default_payment_gateway = "All User Defined Value is Required";
        //     }
        // }

        setData({ ...data, error });
        return isValid;
    }
    //on submit form
    const onSubmit = () => {

        if (validateForm()) {
            const payload = returnSaveData(data)
            onSubmitSettings(payload)
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,

                msg: t("Please Fill All Mandatory Field"),

            });
        }
    }
    return (
        <Box>
            {/*setting save */}
            <SettingSave title={selected?.label} subTitle={selected?.subtitle} btn={true} onSave={onSubmit} isDisableBtn={isDisableBtn} t={t}/>
            <Box className={classes.settingRoot}>
                {/*"Default Base Currency" */}
                <Box padding="8px 12px">
                    <CompanySettingsList

                        title={t("Default Base Currency")}
                        onChange={(value) => updateState("default_base_currency", value)}
                        value={data?.default_base_currency}
                        bgColor="#F2F4F7"
                        type="select"
                        border={false}
                        aysnc={true}
                        loading={loading}
                        loadOptionData={loadOptionData}
                        isError={data?.error?.default_base_currency?.length > 0}
                        errorMessage={data?.error?.default_base_currency}
                        selectBoxWidth={"250px"}

                    />

                </Box>
                {/* default time zone */}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Default Time Zone")}
                        onChange={(value) => updateState("default_time_zone", value)}
                        value={data?.default_time_zone}
                        bgColor="#F2F4F7"
                        type="currency_select"
                        border={false}
                        aysnc={true}
                        // loadOptionData={loadOptionData}
                        isError={data?.error?.default_time_zone?.length > 0}
                        errorMessage={data?.error?.default_time_zone}
                        loadOptionData={(search, array, handleLoading) =>
                            loadOptionsApis(
                                "/timezone",
                                {},
                                search,
                                array,
                                handleLoading,
                                "data",
                                { timezone: true, label: "label" }
                            )
                        }
                    />

                </Box>
                {/*Default Unit Measurement*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Default Unit Measurement")}
                        onChange={(value) => updateState("default_uom", value)}
                        value={data?.default_uom}
                        options={UOMValue}
                        isError={data?.error?.default_uom?.length > 0}
                        errorMessage={data?.error?.default_uom}
                    />
                </Box>
                {/*Default Quotation Validity*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Enable Reservation Feature")}
                        onChange={(value) => updateState("quotation_validity", value)}
                        value={data?.quotation_validity}
                        toggle={true}
                        custom={true}
                        customValue={0}
                        openCustom={
                            data?.quotation_validity?.value === null ? false : true}
                        isError={data?.error?.quotation_validity?.length > 0}
                        errorMessage={data?.error?.quotation_validity}
                        options={data?.quotation_validity?.is_active ? quotation_validity : []}
                    />
                </Box>
                {/*Default Billing Payment Period*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Default Billing Payment Period")}
                        onChange={(value) => updateState("billing_payment_peroid", value)}
                        value={data?.billing_payment_peroid}
                        options={enumValue?.company_payment_period}
                        isError={data?.error?.billing_payment_peroid?.length > 0}
                        errorMessage={data?.error?.billing_payment_peroid}
                    />
                </Box>
                {/*Default Billing lease Period*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Default Lease Period")}
                        onChange={(value) => updateState("lease_period", value)}
                        value={data?.lease_period}
                        options={enumValue?.company_lease_period}
                        isError={data?.error?.lease_period?.length > 0}
                        errorMessage={data?.error?.lease_period}
                    />
                </Box>
                {/*Default Billing Payment Terms*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Default Billing Payment Terms")}
                        onChange={(value) => updateState("billing_payment_terms", value)}
                        value={data?.billing_payment_terms}
                        options={enumValue?.payment_terms}
                        onChangeText={(type, value) => updateState("billing_payment_terms_value", value)}
                        textValue={data?.billing_payment_terms_value}
                        type={data?.billing_payment_terms?.value === enumName.userDefined ? "textbox" : null}
                        isError={data?.error?.billing_payment_terms?.length > 0}
                        errorMessage={data?.error?.billing_payment_terms}
                    />

                </Box>

                {/*Default Billing Cycle Methods*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Default Billing Cycle Methods")}
                        onChange={(value) => updateState("billing_cycle_method", value)}
                        value={data?.billing_cycle_method}
                        options={enumValue?.bill_gen}
                        isError={data?.error?.billing_cycle_method?.length > 0}
                        errorMessage={data?.error?.billing_cycle_method}
                    />
                </Box>
                {/*Default Billing Generate Date*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Default Billing Generate Date")}
                        onChange={(value) => updateState("billing_generate_date", value)}
                        value={data?.billing_generate_date}
                        options={data?.billing_generate_date?.value === "Custom Date" ? enumValue?.billing_cycle_date?.filter((x) => x?.value !== "Custom Date") : enumValue?.billing_cycle_date}
                        isError={data?.error?.billing_generate_date?.length > 0}
                        errorMessage={data?.error?.billing_generate_date}
                        type={data?.billing_generate_date?.value === "Custom Date" ? "textbox" : null}
                        onChangeText={(state, value) => updateState("bill_custom_date", value)}
                        textValue={data?.bill_custom_date}
                    />
                    {/* 
<CompanySettingsList
                        title="Default Billing Generate Date"
                        onChange={(value) => updateState("billing_generate_date", value)}
                        value={data?.billing_generate_date}
                        toggle={true}
                        custom={true}
                        customValue={0}
                        openCustom={
                            data?.billing_generate_date?.value === null ? false : true}
                        isError={data?.error?.billing_generate_date?.length > 0}
                        errorMessage={data?.error?.billing_generate_date}
                        options={enumValue?.billing_cycle_date}
                    /> */}

                </Box>
                {/* cpi percentage 1 */}
                <Box padding="8px 12px">
                    <Box className={classes.root}>
                        <Typography className={classes.title}>{t("Consumer Price Index Percentage")}</Typography>
                        <Box height={'10px'} />
                        <FormGenerator t={t} components={[{
                            isActive: true,
                            component: "text",
                            label: null,
                            type: "number",
                            value: data?.cpi_percentage,
                            placeholder: t("Enter CPI Percentage"),
                            onChange: (e) => updateState("cpi_percentage", e?.target?.value),
                            isRequired: true,
                            size: {
                                xs: 12,
                                sm: 12,
                                md: 6,
                                lg: 4,
                            },
                            isPaginate: true,
                        }
                        ]} />
                    </Box>
                </Box>


                {/*"Default Payment Gateway"*/}
                {/* <Box padding="8px 12px">
                    <CompanySettingsList
                        title="Default Payment Gateway"
                        onChange={(value) => updateState("default_payment_gateway", value)}
                        value={data?.default_payment_gateway}
                        options={enumValue.payment_gateway}
                        render={renderGatway}
                        isError={data?.error?.default_payment_gateway?.length > 0}
                        errorMessage={data?.error?.default_payment_gateway}
                    />
                </Box> */}
            </Box>
        </Box>
    )
}