import * as React from "react"

const AccountPerson = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 48450"
                    transform="translate(257 136)"
                    fill="#5078e1"
                    d="M0 0h16v16H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 102674">
            <g
                data-name="Mask Group 6286"
                transform="translate(-257 -136)"
                clipPath="url(#a)"
            >
                <path
                    d="M264.071 137.552a3.1 3.1 0 1 0 3.1 3.1 3.1 3.1 0 0 0-3.1-3.1Zm3.4 6.19a3.4 3.4 0 1 0 3.4 3.4 3.4 3.4 0 0 0-3.395-3.4Zm-6.959 1.239a1.4 1.4 0 0 0-1.393 1.392v.236a2.632 2.632 0 0 0 .832 1.921 5.817 5.817 0 0 0 4.121 1.4c.17 0 .332-.006.491-.015a4.009 4.009 0 0 1-.474-4.937Zm8.821.619a.31.31 0 0 1 .219.528l-2.476 2.476a.309.309 0 0 1-.438 0l-1.238-1.238a.31.31 0 0 1 .438-.438l1.019 1.024 2.262-2.262a.308.308 0 0 1 .214-.09Z"
                    fill="#5078e1"
                />
            </g>
        </g>
    </svg>
)

export default AccountPerson
