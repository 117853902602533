import React from "react";
import { withNavBars } from "../../HOCs";
import { SecurityDashboard } from "./securitymanagerdashboard";
class SecurityDashboardParent extends React.Component {
  render() {
    return (
        <SecurityDashboard />
    );
  }
}
const props = {
  boxShadow: false
}
export default withNavBars(SecurityDashboardParent,props);