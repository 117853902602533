import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { config } from '../config';
import { LocalStorageKeys } from '../utils';
 
const httpLink = createHttpLink({
  uri: config.graphql_url,
});
 
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(LocalStorageKeys.authToken);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: "Bearer " + config.graphql_url.includes('graphql') ? "test" : token,
      Slug: `${localStorage.getItem(LocalStorageKeys.slug)}`,
    }
  }
});
const wsLink = new WebSocketLink({
  uri: config.socket,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: localStorage.getItem(LocalStorageKeys.authToken),
    },
  }
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);
 
const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    addTypename: false
  })
});
 
export { client };
 