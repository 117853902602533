/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
import { Button, Grid, List, ListItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {

  SearchFilter,
  TableWithPagination
} from "..";
import MasterUploadModal from ".."
import GenerateForm from ".."
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import {
  GET_SECURITY_MASTER,
  GET_SECURITY_MASTER2, SECURITY_ACCESS, SECURITY_MAIN, SECURITY_PROPERTY
} from "../../graphql/securityManager/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { masterList } from "../../utils/securityManager";
import {
  securityActions, securityEditActions, securityOptions, securityUpdateActions
} from "../../utils/securityManager/masterActions";

const useStyles = makeStyles((theme, props) => ({
  root: {
    height: "100%",
  },
  grid: {
    borderRadius: "12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    // height: "100%",
    height: `calc(100vh - 93px)`,
    width: "100%",
    padding: 10,
    margin: 5,
    overflow: "auto",
  },
  gridList: {
    borderRadius: "12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    height: "100%",
    padding: 10,
    margin: 5,
  },
  listText: {
    backgroundColor: "#071741",
    padding: 10,
    borderRadius: 10,
    color: "white",
    opacity: 1,
    cursor: "pointer",
  },
  list: {
    cursor: "pointer",
  },
  close: {
    position: "fixed",
    right: "310px",
    top: 10,
    backgroundColor: "white",
    padding: 5,
    display: "flex",
    borderRadius: 5,
    cursor: "pointer",
  },
}));

export const MasterData = ({
  t
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [readOnly, setRead] = useState(false);
  const backdrop = React.useContext(BackdropContext);
  // const [Index, setIndex] = useState(0);
  const search = useLocation().search;
  const initialTitle = new URLSearchParams(search).get("title");
  const [title, setTitle] = useState(initialTitle ?? "Access Gates");
  const history = useHistory();
  const [Id, setId] = useState([]);
  const [edit, isEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [list, setList] = useState(masterList);
  const [uploadModal, setUploadModal] = useState(false);
  const [data, setData] = useState({
    heading: "",
    row: "",
    path: "",
    type: "",
    drawer: "",
  });
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [searchText, setSearchText] = React.useState("");
  const [searchText1, setSearchText1] = React.useState("");
  const [disable, setDisable] = useState(false);
  const alert = React.useContext(AlertContext);
  //const [currencyOption, setcurrencyOption] = React.useState([]);

  const getTable = (name, id, offset = 0, limit = 10, search = "") => {
    const jsonData = securityActions(name, offset, limit, search);
    const payload = {
      query: jsonData.type,
      variables: {
        property_id: id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        const wData = jsonData.resfunc(main);
        setData({
          heading: jsonData.tbdata.heading,
          row: wData.table,
          path: jsonData.tbdata.path,
          type: jsonData.tbdata.type,
          drawer: jsonData.tbdata.drawer,
          totalRowsCount: response?.data?.data?.count?.[0]?.count,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getTable(title, Id, offset, limit, searchText1);
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getTable(title, Id, 0, value, searchText1);
  };

  useEffect(() => {
    if (Id.length > 0) {
      const payload = {
        query: SECURITY_PROPERTY,
        variables: {
          property_id: Id,
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          let main = response.data.data;
          const newDrawer = [...data.drawer];
          newDrawer.map((v) => {
            v.value = "";
            if (
              v.state_name === "property_name" &&
              v.componet_type === "select"
            ) {
              let op = [];
              main?.property?.map((val) => {
                let obj = {};
                obj.value = val.value;
                obj.label = val.label;
                op.push(obj);
              });
              v.options = op;
            }
            if (
              v.state_name === "security_charge" &&
              v.componet_type === "selectUser"
            ) {
              v.options = [];
            }
          });
        })
        .catch(() => { });
    }
    // eslint-disable-next-line
  }, [data.drawer]);

  const updateTablefunc = (name, data) => {
    setDisable(true)
    data.created_at = new Date().toISOString();
    data.created_by = localStorage.getItem(LocalStorageKeys.userProfileID);
    data.updated_at = new Date().toISOString();
    data.updated_by = localStorage.getItem(LocalStorageKeys.userProfileID);

    const jsonData = securityUpdateActions[name];
    const payload = {
      query: jsonData.type,
      variables: {
        insert: [data],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {

        if (page > 1) {
          setPage(1);
        }
        getTable(name, Id, 0, limit);
        setSearchText1("");
        setOpen(false);
        setDisable(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("New Group Created!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((error) => {
        setDisable(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Not inserted!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });

      });
  };

  const editTablefunc = (name, data, id) => {
    data.updated_at = new Date().toISOString();
    data.updated_by = localStorage.getItem(LocalStorageKeys.userProfileID);

    const jsonData = securityEditActions[name];
    const payload = {
      query: jsonData.type,
      variables: {
        [jsonData.key]: id ? id : editId,
        demo: [data],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {

        let offset = (page - 1) * limit;
        getTable(name, Id, offset, limit, searchText1);
        setOpen(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Group Edited!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((error) => {

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Group Not Edited!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const searchTable = (name, e) => {
    if (page > 1) {
      setPage(1);
    }
    getTable(name, Id, 0, limit, e);
  };

  const Options = async () => {
    const newDrawer = [...data.drawer]?.map(async (v) => {
      v.value = "";
      if (title === "Domestic Help") {
        if (v?.label === "Profession") {
          let getOptions = await getOptionsData("Profession Master Option");
          v.options = getOptions?.profession_master?.length
            ? getOptions?.profession_master
            : [];
        }
        if (v?.label === "ID Type") {
          let getOptions = await getOptionsData(
            "Identification Type Master Option"
          );
          v.options = getOptions?.Identification_master?.length
            ? getOptions?.Identification_master
            : [];
        }
      }
      if (title === "Service Provider") {
        if (v?.label === "Service Category") {
          let getOptions = await getOptionsData("Profession Master Option");
          v.options = getOptions?.profession_master?.length
            ? getOptions?.profession_master
            : [];
        }
      }
      if (title === "Access Gates") {
        if (v?.label === "Security") {
          v.options = [];
        }
      }
      if (title === "Visitor Parking Slots") {
        if (v?.label === "Currency Symbol") {
          let getOptions = await getOptionsData(
            "Currency Option"
          );
          let o = []
          getOptions?.currency_master?.map((key) => {
            let op = {}
            op.value = key.id
            op.label = `${key.symbol} ${key.code}`
            o.push(op)
          })


          v.options = o?.length
            ? o
            : [];
        }
      }
      return v;
    })
    let DrawerResults = await Promise.all(newDrawer);
    setData({
      ...data,
      drawer: DrawerResults,
    });
  }

  const handleDrawer = async () => {

    isEdit(false);
    setEditId(null);
    Options();
    setOpen(true);
  };

  const getOptionsData = async (type) => {
    const jsonData = securityOptions[type];
    const payload = {
      query: jsonData.type,
    };
    const options = await NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        return main ? main : [];
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return [];
      });
    return options;
  };

  useEffect(() => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    let token = localStorage.getItem("authToken");
    let decode = jwt_decode(token);
    const payload = {
      query: SECURITY_MAIN,
      variables: {
        id: decode.id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        if (main.user_profiles[0].id) {
          const payload = {
            query: SECURITY_ACCESS,
            variables: {
              user_profile_id: main.user_profiles[0].id,
              role_id: localStorage.getItem(LocalStorageKeys.roleID),
            },
          };
          NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          )
            .then((response) => {
              let main2 = response.data.data;
              let id = [];
              main2?.access_control?.map((k) => {
                id.push(k.property_id);
              });
              setId(id);
              commonFunc(initialTitle ?? "Access Gates")
              backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
              });
            })
            .catch((error) => {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            });
        }

      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
    // eslint-disable-next-line
  }, [initialTitle]);

  const changeDrop = async (type, val) => {
    if (type === "property_name") {
      let datas = [...data.drawer];
      const value = val;
      const payload = {
        query: GET_SECURITY_MASTER,
        variables: {
          property_id: value,
        },
      };
      await NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then(async (response) => {
          let main = response.data.data;
          let ids = [];
          main?.access_gates_master?.map((m) => {
            ids.push(m.user_profile_id);
          });

          const newData = datas.map(async (d) => {
            if (d.state_name === "security_charge") {
              const payload = {
                query: GET_SECURITY_MASTER2,
                variables: {
                  user_profile_id: ids,
                },
              };
              await NetworkCall(
                `${config.graphql_url}`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
              )
                .then((response) => {
                  const op = [];
                  let main4 = response.data.data;
                  main4?.user_profiles?.map((m) => {
                    const obj = {};
                    obj.value = m.id;
                    obj.label = `${m.first_name}${m.last_name}`;
                    op.push(obj);
                  });
                  d.options = op;
                })
                .catch((error) => {
                  alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                  });
                });
            }
            return d;
          });
          let DrawerResults = await Promise.all(newData);

          setData({
            ...data,
            drawer: DrawerResults,
          });
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = async (type, value, checked) => {
    if (type === "edit") {
      setRead(false);
      isEdit(true);
      setEditId(value?.id);
      // let newDrawer = [...data.drawer]
      const newDrawer = [...data.drawer]?.map(async (val) => {
        await Promise.all(
          Object.keys(value).map(async (k) => {
            if (val.state_name === k) {
              if (val.state_name === "mobile_no") {
                val.value = {
                  mobile: value?.mobileNumber ?? "",
                  mobile_code: value?.countryCode ?? "",
                };
              } else if (val.state_name === "security_charge") {
                val.value = value?.securityCharge;
              } else {
                val.value = value[val.state_name];
              }
            }
            if (title === "Domestic Help") {
              if (val?.label === "Profession" && k === "profession") {
                let getOptions = await getOptionsData(
                  "Profession Master Option"
                );
                val.options = getOptions?.profession_master?.length
                  ? getOptions?.profession_master
                  : [];
                val.value = val.options?.find(
                  (_) => _?.label === value[val.state_name]
                );
              }
              if (val?.label === "ID Type" && k === "id_type") {
                let getOptions = await getOptionsData(
                  "Identification Type Master Option"
                );
                val.options = getOptions?.Identification_master?.length
                  ? getOptions?.Identification_master
                  : [];
                val.value = val.options?.find(
                  (_) => _?.label === value[val.state_name]
                );
              }
            }
            if (title === "Service Provider") {
              if (
                val?.label === "Service Category" &&
                k === "service_cateogry"
              ) {
                let getOptions = await getOptionsData("Service Provider");
                val.options = getOptions?.profession_master?.length
                  ? getOptions?.profession_master
                  : [];
                val.value = val.options?.find(
                  (_) => _?.label === value[val.state_name]
                );
              }
            }

          })
        );
        return val;
      });

      let DrawerResults = await Promise.all(newDrawer);

      setData({
        ...data,
        drawer: DrawerResults,
      });
      setOpen(true);
    }
    if (type === "view") {
      setOpen(true);
      setRead(true);
      let newDrawer = [...data.drawer];
      newDrawer.map((val) => {
        Object.keys(value).map((k) => {
          if (val.state_name === k) {
            val.value = value[val.state_name];
          }
        });
      });
    }
    if (type === "toggle") {
      const c = {
        is_active: checked,
        updated_at: new Date().toISOString(),
        updated_by: localStorage.getItem(LocalStorageKeys.userProfileID),
      };
      editTablefunc(title, c, value.id);
    }
  };

  const handleList = (name, index) => {

    history.push(Routes.masterData + "?title=" + name);
  };

  const commonFunc = (name) => {
    setTitle(name);
    setPage(1);
    setLimit(10);
    setSearchText1("");
    getTable(name, Id);
  }

  const saveFunction = (datas, title) => {
    if (title === "Access Gates") {
      let gate = {};
      gate.description = datas.description;
      gate.name = datas.name;
      gate.gate_no = datas.gate_no;
      gate.property_id = datas.property_name.value;
      gate.user_profile_id = datas.security_charge.value;
      gate.on_duty_date = datas.duty_date;
      gate.created_by = localStorage.getItem(LocalStorageKeys.userProfileID);
      gate.update_by = localStorage.getItem(LocalStorageKeys.userProfileID);
      gate.is_active = datas.is_active === "" ? false : datas.is_active;
      edit ? editTablefunc(title, gate) : updateTablefunc(title, gate);
    }
    if (title === "Visitor Parking Slots") {
      let parking = {};
      parking.is_active = datas.is_active === "" ? false : datas.is_active;
      parking.parking_no = datas.parking_no;
      parking.rate = datas.rate;
      parking.currency_id = datas.currency_symbol?.value;
      if (datas.currency_symbol.value) {
        parking.currency_id = datas.currency_symbol.value;
      } else {
        var newDrawer = [...data.drawer];
        newDrawer.map((keys) => {
          if (keys.state_name === "currency_symbol") {
            keys.options.map((m) => {
              if (m.label === datas.currency_symbol) {
                parking.currency_id = m.value;
              }
            });
          }
        });
      }
      if (datas.property_name.value) {
        parking.property_id = datas.property_name.value;
      } else {
        var newDrawer = [...data.drawer];
        newDrawer.map((keys) => {
          if (keys.state_name === "property_name") {
            keys.options.map((m) => {
              if (m.label === datas.property_name) {
                parking.property_id = m.value;
              }
            });
          }
        });
      }
      edit ? editTablefunc(title, parking) : updateTablefunc(title, parking);
    }
    if (title === "Domestic Help") {
      let domesticHelp = {};
      domesticHelp.property_id = datas?.property_name?.value;
      domesticHelp.gender = datas?.gender?.value;
      domesticHelp.helper_image = "";
      domesticHelp.id_type = datas?.id_type?.value;
      domesticHelp.identification_no = datas?.id_number;
      domesticHelp.is_active =
        datas?.is_active === "" ? false : datas.is_active;
      domesticHelp.mobile_country_code = datas?.mobile_no?.mobile_code;
      domesticHelp.mobile_no = datas?.mobile_no?.mobile;
      domesticHelp.name = datas?.name;
      domesticHelp.profession_id = datas?.profession?.value;
      domesticHelp.qr_code = datas?.qr_code !== "" ? datas?.qr_code : Math.random().toString(36).substr(2, 6);
      edit ? editTablefunc(title, domesticHelp) : updateTablefunc(title, domesticHelp);
    }
    if (title === "Vendor") {
      let vendor = {};
      vendor.is_active = datas?.is_active === "" ? false : datas.is_active;
      vendor.property_id = datas?.property_name?.value;
      vendor.vendor_code = datas?.vendor_code;
      vendor.name = datas?.vendor_name;
      edit ? editTablefunc(title, vendor) : updateTablefunc(title, vendor);
    }
    if (title === "Service Provider") {
      let serviceProvider = {};
      serviceProvider.is_active = datas?.is_active;
      serviceProvider.property_id = datas?.property_name?.value;
      serviceProvider.name = datas?.service_provider_name;
      serviceProvider.service_provider_code = datas?.service_provider_code;
      serviceProvider.profession_id = datas?.service_cateogry?.value;
      edit
        ? editTablefunc(title, serviceProvider)
        : updateTablefunc(title, serviceProvider);
    }
  };

  const handleTab = (e) => {
    setSearchText1(e);
    if (e === "") {
      getTable(title, Id);
    } else {
      searchTable(title, e);
    }
  };

  const handleListFilter = (e) => {
    setSearchText(e);
    setList(
      masterList.filter((_) => _.name.toLowerCase().includes(e?.toLowerCase()))
    );
  };

  const handleUploadModal = (val, type) => {
    // selected tab master name
    if (type === "refresh") {
      getTable(title, Id);
      setUploadModal(!uploadModal);
    } else {
      setUploadModal(!uploadModal);
    }
  };



  return (
    <div className={classes.root}>
      <Grid constainer spacing={2} direction="row" display="flex">
        <Grid item xs={3} className={classes.gridList}>
          <SearchFilter
            placeholder={t("Search Master")}
            value={searchText}
            handleChange={handleListFilter}
          />
          <div
            style={{
              height: `calc(100vh - 156px)`,
              overflow: "auto",
              marginTop: "4px",
            }}
          >
            <List>
              {list.map((l, index) => (
                <ListItem
                  className={l?.name === title ? classes.listText : classes.list}
                  onClick={() => handleList(l.name, index)}
                >
                  <ListItemText primary={l.name} />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid
          item
          xs={9}
          className={classes.grid}
          justifyContent="space-between"
        >
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <SearchFilter
                placeholder={t("Search Master")}
                value={searchText1}
                handleChange={handleTab}
              />
            </Grid>
            <Grid item xs={8} textAlign="right">
              <Button
                color="primary"
                variant={"outlined"}
                onClick={handleUploadModal}
                style={{ marginRight: 12 }}
              >
                {t("Bulk Upload")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleDrawer}
              >
                {t("Add new")}
              </Button>
            </Grid>
          </Grid>
          {data.row.length > 0 && (
            <Grid item xs={12} className={classes.table}>
              <TableWithPagination
                heading={data.heading}
                rows={data.row}
                path={data.path}
                showpagination={true}
                count="2"
                handleIcon={handleChange}
                onClick={() => false}
                // tableType = "normal"
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                dataType={data.type}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={data?.totalRowsCount}
                page={page}
                limit={limit}
                height={'calc(100vh - 240px)'}
                view={true}
                edit={true}
                delete={true} />
            </Grid>
          )}
        </Grid>
      </Grid>

      {data?.drawer && (
        <GenerateForm
          parent_id={"PropertyMasterDrawer"}
          nocancel
          loading={disable}
          open={open}
          readOnly={readOnly}
          save={saveFunction}
          handlecancel={handleClose}
          changeDrop={changeDrop}
          header={title}
          json={data?.drawer}
        />
      )}

      {/* Upload Modal */}
      {uploadModal && (
        <MasterUploadModal
          open={uploadModal}
          closeModal={handleUploadModal}
          docsType={title}
        />
      )}
    </div>
  );
};
