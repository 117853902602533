import React from 'react'
import styled from '@mui/material/styles/styled';
import { NetworkCall } from '../../../networkcall';
import { config } from '../../../config';
import { NetWorkCallMethods, allowed_file_size, multiFileUpload } from '../../../utils';
import { CustomFileUpload } from '../../../components/quotationConfirm/components/customFileUpload';
import { Grid } from '@mui/material';
import { AlertContext } from '../../../contexts';
import { AttachmentCard } from '../components/attachmentCard';
import { NewLoader } from '../../../components/newLoader';

const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#ffffff",
    padding: 16,
    margin: "0px",
    height: "calc(100vh - 200px)",
    overflow: "auto"
}))

const Attachements = ({
    id = "",
    t = () => false
}) => {

    const alert = React.useContext(AlertContext)


    const [attachements, setAttachments] = React.useState([])
    const [loading, setLoading] = React.useState(false)


    React.useEffect(() => {
        getUploadedAssets()
        // eslint-disable-next-line
    }, [])


    const getUploadedAssets = () => {
        setLoading(true)
        const payload = {
            agreement_id: id,
        }

        NetworkCall(
            `${config.api_url}/agreement/get_asset`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setAttachments(res?.data?.data ?? [])
            setLoading(false)
        }).catch((err) => {
        })
    }

    const handleUploadAssets = async (value) => {
        let uploaded_file = await multiFileUpload(value, {}, alert, allowed_file_size)
        const constructedAttachment = uploaded_file?.map((e) => {
            return {
                agreement_id: id,
                is_asset: true,
                url: e?.url,
                file_meta: e?.file_meta,
                is_active: e?.is_active
            }
        })
        setAttachments([
            ...attachements,
            ...constructedAttachment
        ])
        await handleUpload(constructedAttachment)
        // alert.setSnack({
        //     ...alert,
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: "You cannot be upload more than one file",
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.center,
        // });
    }


    const handleAssetFile = (data) => {
        // set_deleted_s3_urls([...deleted_s3_urls, data?.url]);
        // setDeletedAssetURL([...deletedAssetURL, data.id].filter(Boolean));
        // setAttachments(attachements?.filter((val) => val?.url !== data?.url));
        const deletedAsset = {
            id: data?.id,
            is_active: false
        }
        handleUpload([deletedAsset])
    };


    const handleUpload = (assests) => {
        setLoading(true)
        const payload = {
            params: assests
        }

        NetworkCall(
            `${config.api_url}/agreement/upsert_asset`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            getUploadedAssets()
        }).catch((err) => {
        })
    }
    return (
        <>
            <CustomPaper>
                {
                    loading ?
                        <NewLoader minusHeight='300px' />
                        :
                        <Grid container spacing={"12px"}>
                            <Grid item xs={4} sm={4} md={2}>
                                <CustomFileUpload
                                    t={t}
                                    accept={["image/*", ".pdf"]}
                                    updateURL={handleUploadAssets}
                                    selectedURL={attachements ?? []}
                                    // handleFile={handleAssetFile}
                                    multiple
                                    uploadMD={12}
                                    hideImages
                                />
                            </Grid>
                            {/* <Grid item xs={12}> */}
                                
                                    {/* attachements?.length > 0 &&
                                     <Grid container spacing={"12px"}> */}
                                        {
                                            attachements?.map((val) => {
                                                return (
                                                    <Grid item xs={4} sm={4} md={2}>
                                                        <AttachmentCard
                                                            val={val}
                                                            deleteFile={handleAssetFile} />
                                                    </Grid>
                                                )
                                            })
                                        }
                                     {/* </Grid> */}
                                
                            {/* </Grid> */}
                        </Grid>
                }
            </CustomPaper >
        </>
    )
}

export default Attachements