import { useApolloClient } from "@apollo/client";
import { Badge, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import React from "react";
import { useHistory } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import Icon2 from "../../assets/icon2";
import Percentage from "../../assets/percentage";
import { AlertDialog, FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination, TextBox, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { UPDATE__DELIVERY_MILESTONE_TEMPLATE } from "../../graphql/mutations";
import { GET_DELIVERY_MILESTONE_TEMPLATE_TABLE_LIST } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, DeliveryMilestoneTemplateHeading, DeliveryMilestoneTemplatePath, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { MilesStoneView } from "./milestoneView";
import { DeliveryMilestoneTemplateStyles } from "./style";
import { withNamespaces } from "react-i18next";
import PercentageArabic from "../../assets/percentageArabic";
import Icon2Arabic from "../../assets/icon2Arabic";



const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.palette.borderRadius,
    height: "40px"
}))

const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    margin: theme.spacing(3)
}))


const initialState = () => {
    return {
        id: null,
        description: "",
        templateName: "",
        edit: false,
        view: false,
        templateType: "percentage",
        milestoneTemplateItems: [],
        error: {
            templateName: "",
            templateType: "",

        }

    }
}


const DeliveryMilestoneTemplate = ({
    t
}) => {
    const classes = DeliveryMilestoneTemplateStyles()
    const [openDialog, setOpenDialog] = React.useState(false)
    const [openDialog2, setOpenDialog2] = React.useState(false)
    const language = localStorage.getItem("i18nextLng")
    const size = useWindowDimensions();
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext);
    const history = useHistory();
    const [deliveryMilestoneTemplateData, setDeliveryMilestoneTemplateData] = React.useState({ ...initialState() })
    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [deliveryMilestoneMasterList, setDeliveryMilestoneTemplateList] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState("");
    const userProfileId = localStorage.getItem(LocalStorageKeys.userProfileID)
    const [drawer, setDrawer] = React.useState(null);
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const [loading, setLoading] = React.useState(true)
    const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const draweropen = () => {
        setDrawer(true);
    };
    const drawerclose = () => {
        setDrawer(false);
    };
    const debounce = UseDebounce();

    const client = useApolloClient();
    //more options
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        DeliveryMilestoneTemplateTableData(offset, limit, searchText)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        DeliveryMilestoneTemplateTableData(0, value, searchText)
    }

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        DeliveryMilestoneTemplateTableData(0, limit, e)
    }


    //update state
    const updateState = (key, value) => {

        let error = deliveryMilestoneTemplateData?.error;
        error[key] = "";
        setDeliveryMilestoneTemplateData({ ...deliveryMilestoneTemplateData, [key]: value, error })


    }
    //validation
    const validate = () => {
        let isValid = true;
        let error = deliveryMilestoneTemplateData.error;
        if (deliveryMilestoneTemplateData?.templateName?.length === 0) {
            isValid = false;
            error.templateName = t("Template Name  is Required");
        }
        if (deliveryMilestoneTemplateData?.templateType?.length === 0) {
            isValid = false;
            error.templateType = t("Template Based On is Required");
        }
        if (deliveryMilestoneTemplateData?.templateName?.length === 0 || deliveryMilestoneTemplateData?.templateType?.length === 0) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            });
        }
        setDeliveryMilestoneTemplateData({ ...deliveryMilestoneTemplateData, error });

        return isValid;
    };


    //Upsert API FUNCTION
    const UpsertDeliveryMilestoneTemplate = () => {
        if (validate()) {
            let payload;

            if (deliveryMilestoneTemplateData?.id) {
                payload = {
                    name: deliveryMilestoneTemplateData?.templateName,
                    updated_by: userProfileId,
                    template_type: deliveryMilestoneTemplateData?.templateType,
                    company_id: selectedCompany?.value,
                    company: selectedCompany,
                    milestoneTemplateId: deliveryMilestoneTemplateData?.id,
                    is_active: deliveryMilestoneTemplateData?.status,
                    milestoneTemplateItems: deliveryMilestoneTemplateData?.milestoneTemplateItems
                }
            } else {
                payload = {
                    name: deliveryMilestoneTemplateData?.templateName,
                    template_type: deliveryMilestoneTemplateData?.templateType,
                    company_id: selectedCompany?.value,
                    created_by: userProfileId,
                    company: selectedCompany,
                    is_active: true,
                    milestoneTemplateItems: deliveryMilestoneTemplateData?.milestoneTemplateItems

                }
            }
            history.push({
                pathname: Routes.createDeliveryMilestoneTemplate,
                state: {
                    main: payload
                },
            });
        }

    }

    //table listing function
    const DeliveryMilestoneTemplateTableData = async (offset = 0, limit = 10, search = "") => {
        let companyID = [selectedCompany?.value];
        let is_active = filterData?.status ?? [true];
        const payload = {
            query: GET_DELIVERY_MILESTONE_TEMPLATE_TABLE_LIST(clientId, offset, limit, search, companyID, is_active).loc.source.body,

        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setDeliveryMilestoneTemplateList({
                    data: response?.data?.data?.milestone_template_master,
                    totalRowsCount: response?.data?.data?.count?.[0]?.count,

                })
                setLoading(false)
                backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            }).catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
                setLoading(false)

            });
    }

    // is active changing function
    const changeactive = async (data, value) => {
        client.mutate({
            mutation: UPDATE__DELIVERY_MILESTONE_TEMPLATE,
            variables: {
                ID: data,
                updatePayload: {
                    is_active: value === "Inactive" ? true : false
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.warning,
                msg: t("Status Updated"),
            });
            DeliveryMilestoneTemplateTableData()
        }).catch(er => {
            console.log(er)
        })
    }
    //delete function
    const deleteTableData = async (data) => {
        client.mutate({
            mutation: UPDATE__DELIVERY_MILESTONE_TEMPLATE,
            variables: {
                ID: data,
                updatePayload: {
                    is_delete: true
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Deleted"),
            });
            setOpenDialog(false)
            DeliveryMilestoneTemplateTableData()
        }).catch(er => {
            console.log(er)
        })
    }
    //table row constructions
    const DeliveryMilestoneTemplateRow = deliveryMilestoneMasterList?.data?.map((val, index) => {
        let _d;
        try {
            _d = {
                index: ((page - 1) * limit) + index + 1,
                name: val?.name ?? " - ",
                description: val?.description ?? " - ",
                status: val?.is_active ? "Active" : "Inactive",
                IsDelete: val?.is_delete,
                createdBy: val?.created_byByID ? `${val?.created_byByID?.first_name ?? ""} ${val?.created_byByID?.last_name ?? ""}` : "-",
                templateBasedOn: val?.template_type === "percentage" ? "Percentage / Amount Method" : "Variable Method",
                templateType: val?.template_type,
                updatedOn: val?.updated_at ? val?.updated_at : val?.created_at,
                icon: "more",
                id: val?.milestone_template_id,
                milestoneTemplateItems: val?.milestone_template_items ? val?.milestone_template_items?.map((item, index) => {
                    return (
                        {
                            milestoneTemplateItemId: item?.milestone_template_item_id ?? null,
                            name: item?.name ?? "-",
                            description: item?.description ?? "-",
                            item_is_active: item?.is_active,
                            value: item?.value ?? null,
                            value_type: item?.value_type ?? "-",
                            order_by: item?.order_by,
                            index: index,
                            id: item?.id
                        }
                    )
                }) : null,

            };
        } catch (err) {

        }
        return _d;
    });


    React.useEffect(() => {
        if (selectedCompany?.value) {
            DeliveryMilestoneTemplateTableData()
        }
        //eslint-disable-next-line
    }, [selectedCompany, filterData]);

    const handleIcon = (type, data, status) => {
        if (type === "view") {
            setOpenDialog2(true)
            setDeliveryMilestoneTemplateData({
                ...deliveryMilestoneTemplateData,
                templateName: data?.name,
                description: data?.description,
                templateType: data?.templateType,
                status: data?.status === "Active" ? true : false,
                view: true,
                id: data?.id,
                delete: data.IsDelete,
                milestoneTemplateItems: data?.milestoneTemplateItems


            })
        }
        else if (type === "edit") {

            setOpenDialog(true)
            setDeliveryMilestoneTemplateData({
                ...deliveryMilestoneTemplateData,
                templateName: data?.name,
                description: data?.description,
                templateType: data?.templateType,
                status: data?.status === "Active" ? true : false,
                edit: true,
                id: data?.id,
                delete: data.IsDelete,
                milestoneTemplateItems: data?.milestoneTemplateItems

            })
        }
        else if (type === "active") {
            changeactive(data.id, status)
        }
        else if (type === "delete") {
            deleteTableData(data.id, data.IsDelete)
        }

    }



    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
    }


    // add and edit modal
    const addAndEdit = () => {
        return (
            <>
                <div style={{
                    height: size.height - 320,
                    overflow: "scroll",
                    padding: "24px"
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextBox
                                value={deliveryMilestoneTemplateData?.templateName}
                                isrequired
                                label={t("Template Name")}
                                placeholder={t("Enter Template Name")}
                                onChange={(e) => {
                                    updateState("templateName", e.target.value);
                                }}
                                isError={deliveryMilestoneTemplateData?.error?.templateName?.length > 0}
                                errorMessage={deliveryMilestoneTemplateData?.error?.templateName}
                            // isReadonly={deliveryMilestoneTemplateData?.view}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.label} gutterBottom>
                                {t("Template Based On")}<sup style={{ color: "red" }}>*</sup>
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box display={"flex"} onClick={() => {
                                        updateState("templateType", "percentage");
                                    }}
                                        className={
                                            deliveryMilestoneTemplateData.templateType === "percentage"
                                                ? classes.selected
                                                : classes.unSelected
                                        }>
                                        <Box>
                                            <Typography className={classes.heading}
                                            >
                                                {t("Percentage / Amount Method")}
                                            </Typography>
                                            <Typography className={classes.subHeading}
                                            >
                                                {t("you can initiate the first payment as amount or Percentage and rest with percentage Values")}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            {language === "ar" ?
                                                <PercentageArabic /> :
                                                <Percentage />
                                            }
                                        </Box>

                                    </Box>

                                </Grid>
                                <Grid item xs={12}>
                                    <Box display={"flex"} onClick={() => {
                                        updateState("templateType", "variable");
                                    }}
                                        className={
                                            deliveryMilestoneTemplateData.templateType === "variable"
                                                ? classes.selected
                                                : classes.unSelected
                                        }>
                                        <Box>
                                            <Typography className={classes.heading}
                                            >
                                                {t("Variable Method")}
                                            </Typography>
                                            <Typography className={classes.subHeading}
                                            >
                                                {t("In this payment method you can fully customise the payment value manually in quotation generation")}                                       </Typography>
                                        </Box>
                                        <Box>
                                            {language === "ar" ?
                                                <Icon2Arabic /> :
                                                <Icon2 />
                                            }
                                        </Box>

                                    </Box>

                                </Grid>
                            </Grid>
                            {deliveryMilestoneTemplateData.error?.templateType?.length > 0 && (
                                <span style={{ fontSize:"0.75rem", color: "red" }}>
                                    {t("Template based on is required")}
                                </span>
                            )}
                        </Grid>




                    </Grid>
                </div>
                <Grid container padding={"24px"} spacing={2}>
                    {/* {  deliveryMilestoneTemplateData?.view ?
                        <>
                            <Grid item xs={6}>
                                <Button fullWidth variant="outlined" className={classes.btn2}
                                    onClick={() => deleteTableData(deliveryMilestoneTemplateData?.id, deliveryMilestoneTemplateData?.delete)}
                                >
                                    {"Delete"}
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button fullWidth variant="contained" className={classes.btn}
                                    onClick={() => setDeliveryMilestoneTemplateData({
                                        ...deliveryMilestoneTemplateData,
                                        view: false,
                                        edit: true
                                    })
                                    }
                                >
                                    {"Edit"}
                                </Button>
                            </Grid>
                        </> :  */}
                    <Grid item xs={12}>
                        <Button fullWidth variant="contained" className={classes.btn}
                            onClick={UpsertDeliveryMilestoneTemplate}
                        >
                            {deliveryMilestoneTemplateData?.edit ? t("Edit") : t("Create")}
                        </Button>
                    </Grid>

                </Grid>
            </>
        )
    }

    const render = () => {
        return <>
            {loading ? <LoadingSection bottom={"45vh"} message={t("Loading Delivery Milestone Listing...")} />
                :
                <>
                    <div>
                        <Subheader title={t("Delivery Milestone Template")} count={false} hideBackButton select options={companyList} value={selectedCompany}
                            onchange={(e) =>
                                setSelectedCompany(e)
                                // getProperty(e)
                            }
                        />
                        <CustomPaper>
                            <Grid container className={classes.content} spacing={2}>
                                <Grid item xs={4}>
                                    <SearchFilter placeholder={t("Search by delivery milestone name")} value={searchText} handleChange={(value) => handleSearch(value)}
                                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                    />
                                </Grid>
                                <Grid item xs={8} textAlign={"right"}>
                                    <Box
                                        display={"flex"}
                                        sx={{ justifyContent: "end" }}
                                    >

                                        <Box>
                                            {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                                <IconButton onClick={draweropen} className={classes.img}>
                                                    <Badge variant="dot" color="primary">
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>

                                            ) : (
                                                <IconButton onClick={draweropen} className={classes.img}>
                                                    <FilterIMG color="#091b29" />
                                                </IconButton>)}

                                        </Box>

                                        {permission?.create &&
                                            <Box sx={{ marginInlineStart: "16px" }}>
                                                <CustomButton
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => setOpenDialog(true)}
                                                >
                                                    {t("Create New Template")}
                                                </CustomButton>
                                            </Box>}
                                    </Box>
                                </Grid >
                            </Grid >
                            <Grid container>
                                <Grid item xs={12}>
                                    <TableWithPagination
                                        heading={DeliveryMilestoneTemplateHeading(t)}
                                        rows={DeliveryMilestoneTemplateRow?.length ? DeliveryMilestoneTemplateRow : []}
                                        path={DeliveryMilestoneTemplatePath}
                                        showpagination={true}
                                        tableType="no-side"
                                        handleIcon={handleIcon}
                                        onClick={() => null}
                                        showpdfbtn={false}
                                        showexcelbtn={false}
                                        showSearch={false}
                                        dataType={[
                                            { type: ["text"], name: "name" },
                                            { type: ["text"], name: "templateBasedOn" },
                                            { type: ["text"], name: "createdBy" },
                                            { type: ["date"], name: "updatedOn" },
                                            { type: ["status"], name: "status" },
                                            { type: ["more"], icon: "icon" },
                                        ]}
                                        handlePagination={handlePagination}
                                        handleChangeLimit={handleChangeLimit}
                                        totalRowsCount={deliveryMilestoneMasterList?.totalRowsCount}
                                        page={page}
                                        limit={limit}
                                        height={'calc(100vh - 290px)'}
                                        view={permission?.read}
                                        edit={permission?.update}
                                        delete={permission?.delete} />
                                </Grid>
                            </Grid>
                        </CustomPaper >

                    </div >
                    {/* add and edit  and view */}
                    < AlertDialog
                        isNormal
                        header={deliveryMilestoneTemplateData?.edit ? t("Edit Delivery Milestone") : t("Add Delivery Milestone")}
                        onClose={() => {
                            setOpenDialog(false)
                            setDeliveryMilestoneTemplateData({ ...initialState() })
                        }}
                        open={openDialog}
                        component={
                            <>
                                <div>
                                    {
                                        addAndEdit()
                                    }
                                </div>
                            </>
                        }
                    />
                    {/*  view */}
                    <AlertDialog
                        md
                        header={deliveryMilestoneTemplateData?.templateName ?? "-"}
                        onClose={() => {
                            setOpenDialog2(false)
                            setDeliveryMilestoneTemplateData({ ...initialState() })
                        }}
                        open={openDialog2}
                        component={
                            <>
                                <div style={{ height: size.height - 150, overflow: "auto" }} className={classes.bg}>
                                    <MilesStoneView t={t} list={deliveryMilestoneTemplateData?.milestoneTemplateItems ?? []} />
                                </div>

                            </>
                        }
                    />
                    {/* filter drawer */}
                    {
                        drawer &&
                        <FilterGenerator
                            open={drawer}
                            onClose={drawerclose}
                            components={[
                                {
                                    component: "toggleButton",
                                    value: filterData?.status,
                                    options: [
                                        { label: t('Active'), value: true },
                                        { label: t('Inactive'), value: false },
                                    ],
                                    isMulti: true,
                                    state_name: "status",
                                    label: t("Status"),
                                    // required:true
                                },
                            ]}
                            onApply={(value) => onApplyFilter(value)}
                        />
                    }
                </>
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};


export default withNamespaces("unitVacancyPropertyList")(DeliveryMilestoneTemplate);
