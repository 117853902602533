import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { CustomerInfoCard } from "../components";


const CustomerInformation = ({ details = {}, t = () => false }) => {
  return (
    <Box p={2} pt={1}>
      <CustomerInfoCard data={{
        image: details?.customer_account?.logo ?? null,
        name: details?.customer_account?.name ?? "-",
        email: details?.customer_account?.email_id ?? "-",
        phone: `${details?.customer_account?.mobile_no_country_code ?? "-"} ${details?.customer_account?.mobile_no ?? "-"}`,
        title: t("customerAccount"),
        account_number: details?.customer_account?.account_no ?? "-",
      }} />

      {/* <CustomerInfoCard data={{
        image: details?.invoice_account?.logo ?? null,
        name: details?.invoice_account?.name,
        email: details?.invoice_account?.email_id,
        phone: `${details?.invoice_account?.mobile_no_country_code} ${details?.invoice_account?.mobile_no}`,
        title: t("invoiceAccount"),
        account_number: details?.invoice_account?.account_no,
      }} /> */}

      <CustomerInfoCard data={{
        image: details?.contact?.image_url,
        name: `${details?.contact?.first_name ?? '-'} ${details?.contact?.last_name?.length > 0 ? details?.contact?.last_name : ""}`,
        email: details?.contact?.email_id ?? "-",
        phone: `${details?.contact?.mobile_no_country_code ?? "-"} ${details?.contact?.mobile_no ?? ""}`,
        title: t("primaryContact"),
      }} />

      <CustomerInfoCard data={{
        image: details?.sale_man?.image_url,
        name: `${details?.sale_man?.first_name ?? '-'} ${details?.sale_man?.last_name ?? ""}`,
        email: details?.sale_man?.email_id ?? "-",
        phone: `${details?.sale_man?.mobile_no_country_code ?? "-"} ${details?.sale_man?.mobile_no ?? ""}`,
        title: t("Sales Person"),
      }} />
      {
        details?.broker?.id &&
        <CustomerInfoCard data={{
          image: details?.broker?.logo,
          name: `${details?.broker?.name ?? '-'}`,
          email: details?.broker?.email_id ?? "-",
          phone: `${details?.broker?.mobile_no_country_code ?? "-"} ${details?.broker?.mobile_no ?? ""}`,
          title: t("Broker"),
        }} />
      }
    </Box>
  );
};
export default withNamespaces("agreement")(CustomerInformation); 