import {
    Box, Grid, IconButton
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { SearchFilter, TableWithPagination } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_UNITTABLEDETAILS, GET_UNIT_SEARCH } from '../../graphql/queries';
import { NetworkCall } from "../../networkcall";
import { Routes } from '../../router/routes';
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { Bold } from '../../utils';

const useStyles = makeStyles((theme) => ({
    content: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        // padding: "16px",
        //height: `calc(100vh - 88px)`,
        //overflow: "hidden"
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
    },
    text: {
        fontSize:"1rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.secondary
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "10px"
    },
    searchdrop: {
        borderRadius: "10px",
        border: "1px solid #E4E8EE",
        cursor: "pointer",
    },
    chip: {
        backgroundColor: "#F2F4F7",
        marginLeft: "6px",
        marginTop: "10px",
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.875rem",
        cursor: "pointer",
        border: "none",
        boxShadow: "none",
        color: theme.typography.color.secondary,
        "&:hover": {
            border: "none",
            boxShadow: "none",
        },
        display: "flex",
        alignItems: "center",
    },
    Label: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        marginTop: "6px",
    },
    UnitStatusactive: {
        padding: "10px 16px",
        textAlign: "center",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        fontSize:"0.875rem",
        backgroundColor: "#5078E1",
        cursor: "pointer",
    },
    UnitStatus: {
        padding: "10px 16px",
        textAlign: "center",
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.875rem",
        cursor: "pointer",
        backgroundColor: "#F2F4F7",
    },
    previousbtn: {
        backgroundColor: "white",
        color: "#091B29",
        border: "1px solid #CED3DD",
        borderRadius: "10px",
        padding: "12px 8px",
        boxShadow: "none",
    },
    submitbtn: {
        borderRadius: "10px",
        padding: "12px 8px",
    }
}))

export const UnitTable = props => {
    const history = useHistory();
    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const [unitListTable, setUnitListTable] = React.useState([]);
    const [unitSearch, setUnitSearch] = React.useState("");
    const search = useLocation().search;
    const propertyID = new URLSearchParams(search).get("propertyID");
    const getPropertyById = () => {
        const payload = {
            query: GET_UNITTABLEDETAILS,
            variables: { propertyID: propertyID },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setUnitListTable(response?.data?.data?.unit)
            })
            .catch((error) => {
                console.log(error)
            });
    };
    //searchunit
    const SearchUnit = (v) => {
        setUnitSearch(v)
        const payload = {
            query: GET_UNIT_SEARCH,
            variables: {
                search: `%${v}%`,
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setUnitListTable(response?.data?.data?.unit)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong Please try Again",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            });
    }
    React.useEffect(() => {
        getPropertyById();
        // eslint-disable-next-line
    }, []);
    const UnitTablepath = ["unitNo", "unitName", "parentUnit", "unitType", "sqFt", "furnishType", "unitPurpose", "currentStatus", "status"]

    const UnitTableheading = [
        { title: "Unit No", field: "unitno", },
        { title: "Unit Name", field: 'unitName', },
        { title: "Parent Unit", field: "parentUnit", },
        { title: "Unit Type", field: 'unitType', },
        { title: "Sq.Ft", field: 'sqFt', },
        { title: "Furnish Type", field: 'furnishType', },
        { title: "Unit purpose", field: 'unitPurpose', },
        { title: "Current Status", field: 'currentStatus', },
        { title: "", field: "status", },
    ]

    const UnitTablerow = unitListTable?.map((val, index) => {
        let _d;
        let parentUnit = val?.parent_unit.map((item) => {
            return item.name
        })
        try {
            _d = {
                id: val?.id,
                unitNo: val?.unit_no,
                unitName: val?.name,
                parentUnit: parentUnit,
                unitType: val?.unit_type_master?.type,
                sqFt: `${val?.total_area} ${val?.area_metric}`,
                furnishType: val?.furnishing,
                unitPurpose: val?.unit_purpose,
                currentStatus: val?.status,
                status: val?.is_active && "Active",
            };
        } catch (err) {

        }
        return _d;
    });

    const handleClick = (data) => {

        history.push(Routes.unitTableDetails + "?unitId=" + data?.id + "&topNavBarTitle=" + data?.unitName)
    }


    return <div className={classes.root}>
        <Box m={2} p={2} className={classes.content}>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <SearchFilter value={unitSearch} handleChange={SearchUnit} />
                </Grid>
                <Grid item xs={7} textAlign={"right"}>
                    <Box
                        display={"flex"}
                        sx={{ float: "right" }}
                    >
                        <Box>
                            <IconButton size='small' className={classes.img}>
                                <img src='/images/filter.svg' alt='filter' />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <TableWithPagination
                heading={UnitTableheading}
                rows={UnitTablerow}
                path={UnitTablepath}
                showpagination={false}
                count="2"
                tableType="no-side"
                onClick={handleClick}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                dataType={[
                    { type: ["number"], name: "unitNo" },
                    { type: ["text"], name: "unitName" },
                    { type: ["text"], name: "parentUnit" },
                    { type: ["text"], name: "unitType" },
                    { type: ["text"], name: "sqFt" },
                    { type: ["text"], name: "furnishType" },
                    { type: ["text"], name: "unitPurpose" },
                    { type: ["text"], name: "currentStatus" },
                    { type: ["status"], name: "status" },
                ]}
                height={'calc(100vh - 200px)'}
                view={true}
                edit={true}
                delete={true} />
        </Box>
    </div>
}