import gql from "graphql-tag";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils";

export const listErrorState = [
    {
        tax: [
            { key: 'taxGroup' },
            { key: 'taxCode' },
            { key: 'taxRegistrationNumber' },
        ],
        logo: [
            { key: "src" },

        ],
        profileDetails: [
            { key: "companyCode", component: 'company_code', },
            { key: "companyName" },
            { key: "companyType" },
            { key: "companyCountry" },
            { key: "status" },

        ],
        address: [
            // { key: 'addressLineOne' },
            // { key: 'addressLineTwo' },
            // { key: 'landmark' },
            // { key: 'area' },
            // { key: 'city' },
            // { key: 'state' },
            // { key: 'country' },
            // { key: 'pincode' },
            { key: 'latitude' },
            { key: 'longitude' },
        ],
        contactInformation: [
            // { key: 'primaryContact', component: 'mobile_number', },
            // { key: 'title' },
            { key: 'primaryMobile', component: 'mobile_number', },
            { key: 'primaryTelephone', component: 'mobile_number', },
            { key: 'primaryEmailAddress', component: "email", errorMsg: "Please enter valid emailID" },
            { key: 'website' },
        ],

        // bankingBasicDetails: [
        //     { key: "bankName" },
        //     { key: "branchName" },
        //     { key: "currency" },
        //     { key: "bankAccountType" },
        //     { key: "accountNumber" },
        //     { key: "bankRoutingType" },
        //     { key: "bankRoutingCode" },
        //     { key: "preferredCashCollectionOffice" },
        //     { key: "chequeName" },
        //     { key: "addressLineOne" },
        //     { key: "city" },
        //     { key: "country" },
        // ],


    },
    {
        initialConfig: [
            { key: "currency" },
            { key: "uom" },
            { key: 'leasePeriod' },
            { key: "referAndWin", component: "boolean" },
            { key: "gracePeriod", component: "integer" },
        ],
        pricing: [
            { key: 'paymentPeriod' },
            { key: 'priceOverride', component: "boolean" },
            { key: 'shortTermPricing', component: "boolean" },
            { key: 'paymentTerm' },
        ],
        paymentGatewayConfiguration: [
            { key: 'paymentGateway' },
            { key: "loginName", dependency: { key: "paymentGateway", component: "select", value: "Solution Provder" } },
            { key: "loginPassword", dependency: { key: "paymentGateway", component: "select", value: "Solution Provder" } },
            { key: "loginToken", dependency: { key: "paymentGateway", component: "select", value: "Solution Provder" } },
            { key: "paymentProviderName", dependency: { key: "paymentGateway", component: "select", value: "Solution Provder" } },
            { key: "url", component: "website", dependency: { key: "paymentGateway", component: "select", value: "Solution Provder" } },
        ]
    },
    {
        companyEstateRegistration: [
            { key: "companyRegistrationType" },
            { key: "companyRegistrationNumber" },
            { key: "realEstateRegulatoryAuthority" },
            { key: "RERARegistrationNumber" },
            { key: "RERABrokerRegistrationNumber" },
            { key: "realEstateAdvertisingPermit", component: "boolean" },
            { key: "advertisingPermitNumber", dependency: { key: "realEstateAdvertisingPermit", component: "boolean" } },
        ],
        tax: [
            { key: 'taxGroup' },
            { key: 'taxCode' },
            { key: 'taxRegistrationNumber' },
        ],
    },
    {
        bankingBasicDetails: [
            { key: "bankName" },
            { key: "branchName" },
            { key: "currency" },
            { key: "bankAccountType" },
            { key: "accountNumber" },
            { key: "bankRoutingType" },
            { key: "bankRoutingCode" },
            { key: "preferredCashCollectionOffice" },
            { key: "chequeName" },
            { key: "addressLineOne" },
            { key: "city" },
            { key: "country" },
        ],
    },
]

export const networkCallback = async (givenPayload, url, handleLoading, returnFullResponse = false) => {
    const payload = {
        ...givenPayload
    };
    const options = await NetworkCall(
        `${config.api_url}/${url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    )
        .then((response) => {
            let main = response?.data?.data;
            handleLoading && handleLoading(null);
            return returnFullResponse ? response : main
        })
        .catch(() => {
            handleLoading && handleLoading(null);
            return null
        });
    return options
}
const validate = (value) => {
    if (!!value) return value

    else return undefined
}

export const constructPayload = (companyProfile, companyConfiguration, governmentalDetails, bankingDetails, assets, settings = null) => {

    const {
        profileDetails = null,
        logo = null,
        address = null,
        contactInformation = null,
        companyEstateRegistration = null,
        bankingBasicDetails = null,
        TermsAndConditionDetails = null,
        tax = null
    } = companyProfile;


    let payload = {
        "tenantId": config.tenantId,
        "user_profile_id": localStorage.getItem(LocalStorageKeys?.profileID) ?? null,

        "logo": validate(logo?.src),
        "company_no": validate(profileDetails?.companyCode),
        "name": validate(profileDetails?.companyName),
        "is_active": validate(profileDetails?.status?.value),
        "country_id": validate(profileDetails?.companyCountry?.value),
        "company_type_id": validate(profileDetails?.companyType?.value),
        "description": validate(profileDetails?.businessDescription),

        "latitude": validate(address?.latitude),
        "longitude": validate(address?.longitude),
        "door_no": validate(address?.doorNumber?.length > 0 ? address?.doorNumber : null),
        "street_1": validate(address?.addressLineOne?.length > 0 ? address?.addressLineOne : null),
        "street_2": validate(address?.addressLineTwo?.length > 0 ? address?.addressLineTwo : null),
        "landmark": validate(address?.landmark?.length > 0 ? address?.landmark : null),
        "area": validate(address?.area),
        "city": validate(address?.city),
        "state": validate(address?.state),
        "zipcode": validate(address?.pincode),
        "country": validate(address?.country),

        "telephone": validate(contactInformation?.primaryTelephone?.mobile),
        "telephone_code": validate(contactInformation?.primaryTelephone?.mobile_code),
        "mobile_no_country_code": validate(contactInformation?.primaryMobile?.mobile_code),
        "mobile_no": validate(contactInformation?.primaryMobile?.mobile),
        "email_id": validate(contactInformation?.primaryEmailAddress),
        "website": validate(contactInformation?.website),
        "title": validate(contactInformation?.title),
        "alternative_mobile_no_country_code": validate(contactInformation?.primaryContact?.mobile_code),
        "alternative_mobile_no": validate(contactInformation?.primaryContact?.mobile),

        "currency_id": validate(settings?.default_base_currency?.value?.value),
        "uom_id": validate(settings?.default_uom?.value),
        "quote_validity_value": validate(settings?.quotation_validity?.value),
        "quote_validity_type": "Days",
        "lease_period": settings?.lease_period?.value ?? null,
        "is_refer_win_share": settings?.refer_win?.is_active ?? false,
        "is_promotional_banners": settings?.professoinal_banners?.is_active ?? false,
        "grace_period": settings?.grace_peroid?.is_active ? settings?.grace_peroid?.value : null,

        "payment_period": validate(settings?.billing_payment_peroid?.value),
        "is_price_overide": settings?.pricing_override?.is_active ?? false,
        "is_short_term_pricing": settings?.short_term_retals?.is_active ?? false,
        //"pricing_method": pricing?.pricingMethod?.value,
        "payment_terms": settings?.billing_payment_terms === "custom" ? "User Defined" : validate(settings?.billing_payment_terms_value?.value),
        "is_storage_offering": settings?.storage_offering?.is_active ?? false,
        "billing_cycle": validate(settings?.billing_cycle_method?.value),
        "billing_cycle_date": validate(settings?.billing_generate_date?.value),

        "reservation_validity_value": settings?.reservation_feature?.is_active ? settings?.reservation_feature?.value : null,
        "reservation_validity_type": "Days",
        "auto_reservation_calc": settings?.auto_reservation_cal?.is_active ?? false,
        "auto_deposit_calc": settings?.auto_deposite_cal?.is_active ?? false,
        "payment_terms_value": settings?.billing_payment_terms === "custom" ? settings?.billing_payment_terms_value?.value : null,

        "payment_gateway": settings?.default_payment_gateway?.value,
        "login_name": settings?.default_payment_gateway?.value === "Solution Provider" ? settings?.loginName : null,
        "login_password": settings?.default_payment_gateway?.value === "Solution Provider" ? settings?.loginPassword : null,
        "login_token": settings?.default_payment_gateway?.value === "Solution Provider" ? settings?.loginToken : null,
        "payment_provider_name": settings?.default_payment_gateway?.value === "Solution Provider" ? settings?.paymentProviderName : null,
        "payment_provider_url": settings?.default_payment_gateway?.value === "Solution Provider" ? settings?.provider_url : null,

        "company_registration": validate(companyEstateRegistration?.companyRegistrationType?.value),
        "company_registration_number": validate(companyEstateRegistration?.companyRegistrationNumber),
        "rera_type": validate(companyEstateRegistration?.realEstateRegulatoryAuthority?.value), //realestatereularAu
        "rera_registration_number": validate(companyEstateRegistration?.RERARegistrationNumber),
        "rera_broker_registration_number": validate(companyEstateRegistration?.RERABrokerRegistrationNumber),
        "rera_advertising_permit": validate(companyEstateRegistration?.realEstateAdvertisingPermit),
        "rera_advertising_permit_no": validate(companyEstateRegistration?.advertisingPermitNumber),

        "tax_code_type": validate(tax?.taxCode?.value),
        "tax_registration_number": validate(tax?.taxRegistrationNumber),
        "vat_group_id": validate(tax?.taxGroup?.value),
        "cr_no": validate(tax?.cr_no),
        // "total_tax_rate": validate(tax?.totalRateTax),

        "primary_bank_name": validate(bankingBasicDetails?.bankName),
        "branch_name": validate(bankingBasicDetails?.branchName),
        "address_1": validate(bankingBasicDetails?.addressLineOne),
        "address_2": validate(bankingBasicDetails?.addressLineTwo),
        "bank_city_name": validate(bankingBasicDetails?.city),
        "bank_country_name": validate(bankingBasicDetails?.country?.value),
        "routing_type": validate(bankingBasicDetails?.bankRoutingType?.value),
        "bank_routing_code": validate(bankingBasicDetails?.bankRoutingCode),
        "account_type": validate(bankingBasicDetails?.bankAccountType),
        "primary_account_no": validate(bankingBasicDetails?.accountNumber),
        "banking_currency_id": validate(bankingBasicDetails?.currency?.value),
        "cash_collection_office": validate(bankingBasicDetails?.preferredCashCollectionOffice),
        "cheque_name": validate(bankingBasicDetails?.chequeName),
        "billing_day": settings?.billing_generate_date?.value === "Custom Date" ? validate(settings?.bill_custom_date) : null,
        "assetsData": assets?.uploadedImages?.filter(({ id }) => !id)?.map(({ priority, url }) => ({ priority, url })),
        "deleteAssets": assets?.deletedImages?.length ? assets?.deletedImages : [],
        "terms_condition": TermsAndConditionDetails?.termsAndConditionDescription ?? null,
        "timezone": settings?.default_time_zone?.value?.value1 ?? undefined,
        "is_discussion_forum": settings?.discussion?.is_active ?? null,
        "is_my_neighbours": settings?.neigbours?.is_active ?? null,
        "is_my_contact": settings?.contact?.is_active ?? null,
        "is_survey": settings?.survey?.is_active ?? null,
        "is_walk_in_track": settings?.walkin?.is_active ?? null,
        "is_parking_alarm_notify": settings?.parking?.is_active ?? null,
        "is_amenity_booking": settings?.booking?.is_active ?? null,
        "is_delivery_collection": settings?.deleivery?.is_active ?? null,
        "is_polls": settings?.polls?.is_active ?? null,
        "is_visitor_worker_pass_approval": Boolean(settings?.is_visitor_worker_pass_approval?.is_active),
        "is_unit_request_access_approval": Boolean(settings?.is_unit_request_access_approval?.is_active),
        proof: settings?.proof?.length > 0 ? settings?.proof?.filter((val) => val?.is_active)?.map(val => {
            return {
                id: val?.id ?? undefined,
                proof_id: val?.type?.value,
                proof_type: val?.type?.proof_type,
                kyc_type: "Company",
                name: val?.name,
                number: val?.id_no,
                valid_from: val?.valid_from,
                valid_to: val?.valid_to,
                url: val?.file ?? [],
                is_mandatory: val?.mandatry_verify,
                country_id: val?.issuing_country?.value,
                is_active: val?.is_active
            }
        }) : [],
        "size_type": "company_size",
        "long_name": validate(profileDetails?.companyLongName),
        "upfront_charges": settings?.itemMaster?.value
    }

    return payload
}

export const returnEditSettings = (data) => {
    let result = {
        default_base_currency: data?.currency?.id ? {
            value: {
                label: data?.currency?.name,
                value: data?.currency?.id
            }
        } : "",
        bill_custom_date: data?.billing_day ?? "",
        default_uom: data?.uom_master ? {
            value: data?.uom_master?.id,
            label: data?.uom_master?.name,
            id: data?.uom_master?.id
        } : "",
        quotation_validity: data?.quote_validity_value ? {
            value: data?.quote_validity_value
        } : { value: null, label: "None" },
        billing_payment_peroid: data?.payment_period ? {
            value: data?.payment_period
        } : "",
        billing_cycle_method: data?.billing_cycle ? {
            value: data?.billing_cycle
        } : "",
        billing_generate_date: data?.billing_cycle_date ? {
            value: data?.billing_cycle_date
        } : "",
        default_payment_gateway: data?.payment_gateway ? {
            value: data?.payment_gateway
        } : { value: null, label: "None" },
        loginName: data?.login_name ?? "",
        loginPassword: data?.login_password ?? "",
        loginToken: data?.login_token ?? "",
        paymentProviderName: data?.payment_provider_name ?? "",
        provider_url: data?.payment_provider_url ?? "",
        reservation_feature: {
            value: data?.reservation_validity_value,
            is_active: (data?.reservation_validity_value || data?.reservation_validity_value === null) ? true : false
        },
        auto_deposite_cal: {
            is_active: data?.auto_deposit_calc ? true : false,
        },
        auto_reservation_cal: {
            is_active: data?.auto_reservation_calc ? true : false,
        },
        grace_peroid: {
            is_active: (data?.grace_period || data?.grace_period === null) ? true : false,
            value: data?.grace_period
        },
        pricing_override: {
            is_active: data?.is_price_overide ? true : false,
        },
        short_term_retals: {
            is_active: data?.is_short_term_pricing ? true : false,
        },
        storage_offering: {
            is_active: data?.is_storage_offering ? true : false,
        },
        index_calculation: {
            is_active: data?.short_term_retals ? true : false,
        },
        refer_win:
        {
            is_active: data?.is_refer_win_share ? true : false,
        },
        professoinal_banners: {
            is_active: data?.is_promotional_banners ? true : false,
        },
        discussion: {
            is_active: data?.is_discussion_forum
        },
        neigbours: {
            is_active: data?.is_my_neighbours
        },
        contact: {
            is_active: data?.is_my_contact
        },
        survey: {
            is_active: data?.is_survey
        },
        walkin: {
            is_active: data?.is_walk_in_track
        },
        parking: {
            is_active: data?.is_parking_alarm_notify
        },
        booking: {
            is_active: data?.is_amenity_booking
        },
        deleivery: {
            is_active: data?.is_delivery_collection
        },
        polls: {
            is_active: data?.is_polls
        },
        is_visitor_worker_pass_approval: {
            is_active: data?.is_visitor_worker_pass_approval
        },
        is_unit_request_access_approval: {
            is_active: data?.is_unit_request_access_approval
        },
        logo: "",
        billing_payment_terms_value: data?.payment_terms !== "User Defined" ? { value: data?.payment_terms } : { value: data?.payment_terms_value },
        billing_payment_terms: data?.payment_terms === "User Defined" ? "custom" : "",
        logo_status: "",
        lease_period: {
            value: data?.lease_period
        },
        default_time_zone: { value: { value: data?.timezone ?? '', label: data?.timezone ?? '' } },
        proof: data?.know_your_client?.map(val => {
            return {
                id: val?.id,
                type: val?.identification_masterByID,
                kyc_type: "Both",
                name: val?.name,
                id_no: val?.number,
                valid_from: new Date(val?.valid_from),
                valid_to: new Date(val?.valid_to),
                file: val?.url,
                mandatry_verify: val?.is_mandatory,
                issuing_country: val?.country_master,
                is_active: val?.is_active
            }
        }),
        itemType: data?.upfront_charges?.item ? { label: data?.upfront_charges?.item, value: data?.upfront_charges?.item } : "",
        itemCategory: (data?.upfront_charges?.inspection_item_category?.name && data?.upfront_charges?.inspection_item_category?.id) ? { label: data?.upfront_charges?.inspection_item_category?.name, value: data?.upfront_charges?.inspection_item_category?.id } : "",
        itemMaster: (data?.upfront_charges?.name && data?.upfront_charges?.id) ? { label: data?.upfront_charges?.name, value: data?.upfront_charges?.id } : "",
        error: {
            default_base_currency: "",
            default_uom: "",
            quotation_validity: "",
            billing_payment_peroid: "",
            billing_payment_terms: "",
            billing_cycle_method: "",
            billing_generate_date: "",
            default_payment_gateway: "",
            reservation_feature: "",
            auto_deposite_cal: "",
            auto_reservation_cal: "",
            grace_peroid: "",
            pricing_override: "",
            short_term_retals: "",
            storage_offering: "",
            index_calculation: "",
            refer_win: "",
            professoinal_banners: "",
            logo: "",
            logo_status: "",
            loginName: "",
            loginPassword: "",
            loginToken: "",
            paymentProviderName: "",
            provider_url: "",
        }
    }
    return result;
}

export const returnValue = (value = "", delimiter = "") => value ? value : delimiter;

export const constructCompanyDetails = (val, totalTax) => {




    const returnAssests = (array) => array?.map(({ id = "", url = "", priority = null }) => ({ id, url, priority, asset_type: 2 }));

    let constructState = {};
    let information = val?.company_address?.[0] ?? null;
    let vat_group = val?.vat_group?.id ? {
        label: returnValue(val?.vat_group?.group_name),
        value: returnValue(val?.vat_group?.id),
    } : val?.vat_group_master?.id ? {
        label: returnValue(val?.vat_group_master?.group_name),
        value: returnValue(val?.vat_group_master?.id),
    } : null;
    constructState['companyProfile'] = {
        logo: val?.logo ? {
            src: returnValue(val?.logo, null)
        } : null,
        profileDetails: {
            companyCode: returnValue(val?.company_no),
            companyName: returnValue(val?.name),
            companyLongName: returnValue(val?.long_name),
            status: {
                label: returnValue(val?.is_active ? 'Active' : "Inactive"),
                value: val?.is_active ? true : false,
            },
            companyCountry: val?.country ? {
                label: returnValue(val?.country?.country_name),
                value: returnValue(val?.country?.id),
            } : null,
            companyType: val?.company_type_master ? {
                label: returnValue(val?.company_type_master?.label),
                value: returnValue(val?.company_type_master?.value),
            } : null,
            businessDescription: returnValue(val?.description),
        },
        address: {
            latitude: returnValue(information?.latitude, null),
            longitude: returnValue(information?.longitude, null),
            doorNumber: returnValue(information?.door_no),
            addressLineOne: returnValue(information?.street_1),
            addressLineTwo: returnValue(information?.street_2),
            landmark: returnValue(information?.landmark),
            area: returnValue(information?.area),
            city: returnValue(information?.city),
            state: returnValue(information?.state),
            country: returnValue(information?.country),
            pincode: returnValue(information?.zipcode),
        },
        contactInformation: {
            title: returnValue(information?.name),
            primaryContact: {
                mobile: returnValue(information?.alternative_mobile_no, null),
                mobile_code: returnValue(information?.alternative_mobile_no_country_code, null)
            },
            primaryTelephone: {
                mobile: returnValue(information?.telephone, null),
                mobile_code: returnValue(information?.telephone_code, null)
            },
            primaryMobile: {
                mobile: returnValue(information?.mobile_no, null),
                mobile_code: returnValue(information?.mobile_no_country_code, null)
            },
            primaryEmailAddress: returnValue(information?.email_id),
            website: returnValue(information?.website)
        },
        bankingBasicDetails: {
            bankName: returnValue(val?.primary_bank_name),
            branchName: returnValue(val?.branch_name),
            currency: val?.banking_currency?.id ? {
                label: returnValue(val?.banking_currency?.name),
                value: returnValue(val?.banking_currency?.id),
            } :  null,
            bankAccountType: returnValue(val?.account_type),
            accountNumber: returnValue(val?.primary_account_no),
            bankRoutingType:val?.routing_type ? {
                label: returnValue(val?.routing_type),
                value: returnValue(val?.routing_type)
            } : null,
            bankRoutingCode: returnValue(val?.bank_routing_code),
            preferredCashCollectionOffice: returnValue(val?.cash_collection_office),
            chequeName: returnValue(val?.cheque_name),
            addressLineOne: returnValue(val?.address_1),
            addressLineTwo: returnValue(val?.address_2),
            city: returnValue(val?.bank_city_name),
            country: val?.country,
        },
        companyEstateRegistration: {
            companyRegistrationType: val?.company_registration ? {
                label: returnValue(val?.company_registration),
                value: returnValue(val?.company_registration),
            } : null,
            companyRegistrationNumber: returnValue(val?.company_registration_number),
            realEstateRegulatoryAuthority: val?.rera_type ? {
                label: returnValue(val?.rera_type),
                value: returnValue(val?.rera_type),
            } : null,
            RERARegistrationNumber: returnValue(val?.rera_registration_number),
            RERABrokerRegistrationNumber: returnValue(val?.rera_broker_registration_number),
            realEstateAdvertisingPermit: val?.rera_advertising_permit, //
            advertisingPermitNumber: returnValue(val?.rera_advertising_permit_no)
        },
        tax: {
            taxGroup: vat_group,

            taxCode: val?.tax_code_type ? {
                label: returnValue(val?.tax_code_type),
                value: returnValue(val?.tax_code_type),
            } : null,
            taxRegistrationNumber: returnValue(val?.tax_registration_number),
            totalRateTax: returnValue(totalTax),
            cr_no: val?.cr_no
        },
        TermsAndConditionDetails: {
            termsAndConditionDescription: returnValue(val?.terms_condition),
        },
        proof: val
    }
    constructState['companyConfiguration'] = {
        initialConfig: {
            currency: val?.currency ? {
                label: returnValue(val?.currency?.name),
                value: returnValue(val?.currency?.id),
            } : null,
            timezone: val?.timezone ? val?.timezone : null,
            uom: val?.uom_master ? {
                label: returnValue(val?.uom_master?.label),
                value: returnValue(val?.uom_master?.value),
            } : null,
            leasePeriod: val?.lease_period ? {
                label: returnValue(val?.lease_period),
                value: returnValue(val?.lease_period)
            } : null,
            referAndWin: val?.is_refer_win_share,
            promotionalBanners: val?.is_promotional_banners,
            gracePeriod: val?.grace_period ?? "-"
        },
        pricing: {
            pricingMethod: val?.pricing_method ? {
                label: returnValue(val?.pricing_method),
                value: returnValue(val?.pricing_method)
            } : null,
            paymentPeriod: val?.payment_period ? {
                label: returnValue(val?.payment_period),
                value: returnValue(val?.payment_period)
            } : null,
            priceOverride: val?.is_price_overide,
            shortTermPricing: val?.is_short_term_pricing,
            paymentTerm: val?.payment_terms ? {
                label: returnValue(val?.payment_terms),
                value: returnValue(val?.payment_terms)
            } : null,
            paymentTermValue: val?.payment_terms_value,
            ...val
        },
        paymentGatewayConfiguration: {
            paymentGateway: val?.payment_gateway ? {
                label: returnValue(val?.payment_gateway),
                value: returnValue(val?.payment_gateway),
            } : null,
            loginName: returnValue(val?.login_name),
            loginPassword: returnValue(val?.login_password),
            loginToken: returnValue(val?.login_token),
            paymentProviderName: returnValue(val?.payment_provider_name),
            url: returnValue(val?.payment_provider_url),
        }
    }
    constructState['governmentalDetails'] = {
        companyEstateRegistration: {
            companyRegistrationType: val?.company_registration ? {
                label: returnValue(val?.company_registration),
                value: returnValue(val?.company_registration),
            } : null,
            companyRegistrationNumber: returnValue(val?.company_registration_number),
            realEstateRegulatoryAuthority: val?.rera_type ? {
                label: returnValue(val?.rera_type),
                value: returnValue(val?.rera_type),
            } : null,
            RERARegistrationNumber: returnValue(val?.rera_registration_number),
            RERABrokerRegistrationNumber: returnValue(val?.rera_broker_registration_number),
            realEstateAdvertisingPermit: val?.rera_advertising_permit, //
            advertisingPermitNumber: returnValue(val?.rera_advertising_permit_no)
        },
        tax: vat_group,


        taxCode: val?.tax_code_type ? {
            label: returnValue(val?.tax_code_type),
            value: returnValue(val?.tax_code_type),
        } : null,
        taxRegistrationNumber: returnValue(val?.tax_registration_number),
        totalRateTax: returnValue(totalTax),
        cr_no: val?.cr_no
    }

    constructState['bankingDetails'] = {
        bankingBasicDetails: {
            bankName: returnValue(val?.primary_bank_name),
            branchName: returnValue(val?.branch_name),
            currency: {
                label: returnValue(val?.banking_currency?.name),
                value: returnValue(val?.banking_currency?.id),
            },
            bankAccountType: returnValue(val?.account_type),
            accountNumber: returnValue(val?.primary_account_no),
            bankRoutingType: {
                label: returnValue(val?.routing_type),
                value: returnValue(val?.routing_type)
            },
            bankRoutingCode: returnValue(val?.bank_routing_code),
            preferredCashCollectionOffice: returnValue(val?.cash_collection_office),
            chequeName: returnValue(val?.cheque_name),
            addressLineOne: returnValue(val?.address_1),
            addressLineTwo: returnValue(val?.address_2),
            city: returnValue(val?.bank_city_name),
            country: val?.country_master,
        }
    }
    constructState['assets'] = {
        // existingImage: val?.company_assets?.length ? returnAssestsID(val?.company_assets) : [],
        uploadedImages: val?.company_assets?.length > 0 ? returnAssests(val?.company_assets) : [],
        deletedImages: [],
    }
    constructState['upfront_charges']= val?.upfront_charges

    return { ...constructState }

}

export const GET_COMPANY_DETAILS = gql`
query GET_COMPANY_DETAILS($id:ID){
    company_master(
        where:{id:{eq:$id}}
    ){
        logo
        company_no
        name
        long_name
        is_active
        cr_no
        upfront_charges{
            id
            name
            item
            inspection_item_category{
              id 
              name
            }
          }
        country {
            id
            country_name
            value:id
            label:country_name
          }
          country_master{
              value: id
              label:country_name
          }
          company_type_master{
              value: id
              label:name
          }
        description
        timezone
        is_discussion_forum
        is_my_neighbours
        is_my_contact
        is_survey
        is_walk_in_track
        is_parking_alarm_notify
        is_amenity_booking
        is_delivery_collection
        is_polls
        is_visitor_worker_pass_approval
        is_unit_request_access_approval
        company_address{
            door_no
            street_1
            street_2
            landmark
            area
            city
            state
            country
            zipcode
            mobile_no_country_code
            mobile_no
            telephone
            telephone_code
            alternative_mobile_no_country_code
            alternative_mobile_no
            email_id
            website
            latitude
            longitude
            name

        }
       currency{
            name
            id
        }
        uom_master{
            label:name
            value:id
            id:id
        }
        billing_cycle
billing_cycle_date 
quote_validity_value
quote_validity_type
reservation_validity_value
reservation_validity_type
auto_reservation_calc
auto_deposit_calc
billing_day
is_storage_offering
        is_refer_win_share
        grace_period
        pricing_method
        payment_period
        payment_terms
        payment_terms_value
        lease_period
        is_price_overide
        is_promotional_banners
        is_short_term_pricing
        payment_gateway
        login_name
        login_password
        login_token
        payment_provider_name
        payment_provider_url
        company_registration_number
        company_registration
        rera_type
        rera_registration_number
        rera_broker_registration_number
        rera_advertising_permit
        rera_advertising_permit_no
        vat_group_id
        vat_group{
            id
            group_name
        }
        vat_group_master{
            id
            group_name
        }
        tax_code_type
        tax_registration_number
        total_tax_rate
        primary_bank_name
        branch_name
        banking_currency{
            id
            name
        }
        account_type
        primary_account_no
        routing_type
        bank_routing_code
        cash_collection_office
        cheque_name
        address_1
        address_2
        bank_city_name
        bank_country_name
        terms_condition
        company_assets(
            where:{
                is_active:{eq:true}
            }
        ){
            id
            url
            priority
        }
         know_your_client(where:{is_active:true , kyc_type:"Company"}) {
      id
      url
      proof_id
      identification_masterByID{
      value:id
      label:type
       proof_type
        
      }
      proof_type
      kyc_type
      name
      number
      valid_from
      valid_to
      url
      is_mandatory
      country_id
      country_master{
        label:country_name
        value:id
      }
      is_active
    }
    }
}
`;

export const GET_ALL_CURRENCIES = (offset = 0, limit = 10, searchText = "", extraParams = {}) => gql`
query GET_ALL_CURRENCY{
    count:currency_master(
         where:{
            name:{iregex:"${searchText}"}
            is_active:true
        }
    ){
        count:count_id
    }
    currency_master(
        where:{
            name:{iregex:"${searchText}"}
            is_active:true
        }
        offset:${offset}
        limit:${limit}
    ){
        value:id
        label:name
    }
}
`

export const GET_VAT_GROUP_MASTER = (offset = 0, limit = 10, searchText = "", extraParams = {}) => {
    return gql`
    query GET_VAT_GROUP_MASTER{
        count:vat_group_master(
             where:{
                group_name:{iregex:"${searchText}"}
                ${extraParams?.id ? `country_id:{eq:"${extraParams?.id}"}` : ''}
                is_active:true,
                is_delete:false
            }
        ){
            count:count_id
        }
        vat_group_master(
            where:{
                group_name:{iregex:"${searchText}"}
                ${extraParams?.id ? `country_id:{eq:"${extraParams?.id}"}` : ''}
                is_active:true,
                is_delete:false
            }
            offset:${offset}
            limit:${limit}
        ){
            value:id
            label:group_name
        }
    }
    `
}

export const GET_TOTAL_RATE = gql`
query GET_TOTAL_RATE($id:Id){
    vat_group_item(
        where:{
            vat_group_master_id:{eq:$id}
            is_active:{eq:true}
        }
    ){
        sum_rate
    }
}
`;

export const getDefaultSetting = (data) => {
    let result = {
        bill_custom_date: data?.billing_day ?? "",
        default_base_currency: data?.currency ? { value: data?.currency ?? "" } : "",
        default_uom: data?.uom_master ? { value: data?.uom_master?.id, label: data?.uom_master?.name ?? "" } : "",
        quotation_validity: (data?.quotation_validity || data?.quotation_validity === null) ? { value: data?.quotation_validity } : "",
        billing_payment_peroid: data?.billing_payment_period ? { value: data?.billing_payment_period ?? "" } : "",
        billing_payment_terms: data?.billing_payment_terms ? { value: data?.billing_payment_terms ?? "" } : "",
        billing_cycle_method: data?.billing_cycle_method ? { value: data?.billing_cycle_method ?? "" } : "",
        billing_generate_date: data?.billing_generation_date ? { value: data?.billing_generation_date ?? "" } : "",
        default_payment_gateway: { value: null, label: "None" },
        loginName: "",
        loginPassword: "",
        loginToken: "",
        paymentProviderName: "",
        provider_url: "",
        billing_payment_terms_value: data?.billing_payment_terms_value ?? "",
        lease_period: data?.lease_period ? { value: data?.lease_period ?? "" } : "",
        reservation_feature: (data?.reservation_validity || data?.reservation_validity === null) ? { value: data?.reservation_validity, is_active: (data?.reservation_validity || data?.reservation_validity === null) ? true : false } : "",
        auto_deposite_cal: { is_active: data?.auto_deposit_calc },
        auto_reservation_cal: { is_active: data?.auto_reservation_calc },
        grace_peroid: (data?.grace_period_validity || data?.grace_period_validity === null) ? { value: data?.grace_period_validity, is_active: (data?.grace_period_validity || data?.grace_period_validity === null) ? true : false } : "",
        pricing_override: { is_active: data?.is_pricing_override },
        short_term_retals: { is_active: data?.is_short_term },
        storage_offering: { is_active: data?.is_storage_offering },
        refer_win: { is_active: data?.is_refer_win },
        professoinal_banners: { is_active: data?.is_promotional_banners },
        proof: "",
        default_time_zone: { value: data?.timezone ?? '' },
        discussion: {
            is_active: data?.is_discussion_forum
        },
        neigbours: {
            is_active: data?.is_my_neighbours
        },
        contact: {
            is_active: data?.is_my_contact
        },
        survey: {
            is_active: data?.is_survey
        },
        walkin: {
            is_active: data?.is_walk_in_track
        },
        parking: {
            is_active: data?.is_parking_alarm_notify
        },
        booking: {
            is_active: data?.is_amenity_booking
        },
        deleivery: {
            is_active: data?.is_delivery_collection
        },
        polls: {
            is_active: data?.is_polls
        },
        is_visitor_worker_pass_approval: {
            is_active: data?.is_visitor_worker_pass_approval
        },
        is_unit_request_access_approval: {
            is_active: data?.is_unit_request_access_approval
        },
        error: {
            default_base_currency: "",
            default_uom: "",
            quotation_validity: "",
            billing_payment_peroid: "",
            billing_payment_terms: "",
            billing_cycle_method: "",
            billing_generate_date: "",
            default_payment_gateway: "",
            reservation_feature: "",
            auto_deposite_cal: "",
            auto_reservation_cal: "",
            grace_peroid: "",
            pricing_override: "",
            short_term_retals: "",
            storage_offering: "",
            index_calculation: "",
            refer_win: "",
            professoinal_banners: "",
            logo: "",
            logo_status: "",
            loginName: "",
            loginPassword: "",
            loginToken: "",
            paymentProviderName: "",
            provider_url: "",
            lease_period: "",
        }

    }
    return result
}
