import { Avatar, Box, Stack, Typography, Divider } from "@mui/material"
import React from "react"
import { stringAvatar } from "../../../utils"
import { useStyles } from "./styles"
export const InterestedUnitsCard = ({ t = () => false, list = [] }) => {
    const classes = useStyles()
    return (
        <Box className={classes.interestunitcard}>
            <Box className={classes.unitproperty}>
                <Typography className={classes.intTitle}>{t("INTERESTED PROPERTIES AND UNITS")}</Typography>
                {
                    list?.map((data, index) => {
                        return (
                            <>
                                <Stack direction="row" mt={2}>
                                    <Avatar src={data?.logo} {...stringAvatar(data?.name)} />
                                    <Box mt={0.5} marginInlineStart={1}>
                                        <Typography className={classes.unit_name}>{data?.name ?? ""}</Typography>
                                        <Typography className={classes.property_name}>
                                            {data?.unit_no},{data.property_name}
                                        </Typography>
                                    </Box>
                                </Stack >
                                <Box mt={1}>
                                    {index !== list.length - 1 &&
                                        <Divider />
                                    }
                                </Box>
                            </>
                        )
                    })}
            </Box >

        </Box >
    )
}