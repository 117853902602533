import * as React from "react"

const ActiveLeadComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={28.952}
    height={32}
    {...props}
  >
    <defs>
      <radialGradient
        id="c"
        cx={0.179}
        cy={0.11}
        r={1.008}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0370c8" />
        <stop offset={0.484} stopColor="#036fc5" />
        <stop offset={0.775} stopColor="#036abd" />
        <stop offset={1} stopColor="#0362b0" />
      </radialGradient>
      <radialGradient
        id="a"
        cx={0.5}
        cy={1.007}
        r={1.009}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient id="d" cx={0.5} cy={0.5} r={0.5} xlinkHref="#a" />
      <linearGradient
        id="b"
        x1={0.161}
        y1={0.161}
        x2={0.858}
        y2={0.858}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#75daff" />
        <stop offset={1} stopColor="#1ea2e4" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0.233}
        y1={0.086}
        x2={0.763}
        y2={1.078}
        xlinkHref="#b"
      />
    </defs>
    <g transform="translate(-3.81 -2.286)">
      <circle
        data-name="Ellipse 129372"
        cx={2.286}
        cy={2.286}
        r={2.286}
        transform="translate(16 2.286)"
        fill="#21a366"
      />
      <circle
        data-name="Ellipse 129373"
        cx={2.286}
        cy={2.286}
        r={2.286}
        transform="translate(3.81 9.143)"
        fill="#21a366"
      />
      <circle
        data-name="Ellipse 129374"
        cx={2.286}
        cy={2.286}
        r={2.286}
        transform="translate(3.81 22.857)"
        fill="#21a366"
      />
      <circle
        data-name="Ellipse 129375"
        cx={2.286}
        cy={2.286}
        r={2.286}
        transform="translate(28.19 9.143)"
        fill="#21a366"
      />
      <circle
        data-name="Ellipse 129376"
        cx={2.286}
        cy={2.286}
        r={2.286}
        transform="translate(28.19 22.857)"
        fill="#21a366"
      />
      <circle
        data-name="Ellipse 129377"
        cx={2.286}
        cy={2.286}
        r={2.286}
        transform="translate(16 29.714)"
        fill="#21a366"
      />
      <path
        data-name="Path 96406"
        d="M26.667 18.286A8.381 8.381 0 1 1 18.286 9.9a8.381 8.381 0 0 1 8.381 8.386Z"
        fill="url(#c)"
      />
      <path
        data-name="Path 96407"
        d="M18.286 26.667a8.349 8.349 0 0 0 6.139-2.69 6.7 6.7 0 0 0-12.279 0 8.349 8.349 0 0 0 6.14 2.69Z"
        fill="url(#a)"
      />
      <circle
        data-name="Ellipse 129378"
        cx={3.81}
        cy={3.81}
        r={3.81}
        transform="translate(14.476 12.19)"
        fill="url(#d)"
      />
      <circle
        data-name="Ellipse 129379"
        cx={3.048}
        cy={3.048}
        r={3.048}
        transform="translate(15.238 12.952)"
        fill="url(#b)"
      />
      <path
        data-name="Path 96408"
        d="M18.286 26.667a8.342 8.342 0 0 0 5.489-2.057 5.859 5.859 0 0 0-10.978 0 8.342 8.342 0 0 0 5.489 2.057Z"
        fill="url(#e)"
      />
    </g>
  </svg>
)

export default ActiveLeadComponent
