import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { MasterData } from '../../../components';
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {

    },
}));

const Master = (props) => {
    const classes = useStyles();
    const { t } = props

    return (
        <div className={classes.root}>
            <MasterData t={t} />
        </div>
    );
};
export default withNamespaces('master')(Master)
