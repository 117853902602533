import { Avatar, Box, Grid, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from 'react';
import "../../../App.css";
import { Editor, MapWithFields, MobileNumberInputComponent, NewDatePicker, SelectBox, TextBox } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import {
      GET_BLOCK_FORM_MASTER as queryOne
} from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, enumSelect, enum_types, getCustomBlockName, NetWorkCallMethods } from "../../../utils";
import { useStylesCreation } from "./style";
import { Bold } from "../../../utils";

const blockStatus = [
      { label: "Active", value: true, },
      { label: "In-Active", value: false, },
]

const CustomTypography = styled(Typography)(({ theme }) => ({
      fontSize:"0.75rem",
      fontFamily: Bold,
      color: theme.typography.color.secondary,
      marginBottom: theme.spacing(1)
}))
export const BlockDetails = (props) => {
      const { t = () => false } = (props);
      const { mapLoad } = props;
      const [fullScreenMap, setFullScreenMap] = React.useState(false)
      const alert = React.useContext(AlertContext);
      const classes = useStylesCreation();
      const [loading, setLoading] = React.useState(false)
      const [enumValue, setEnum] = React.useState({
            block_purpose: [],
            revenue_type: [],
      })
      const loadOptions = async (search = "", array, type) => {

            setLoading(type);
            let result, query, offset = 0;

            if (search && !Boolean(array?.length)) {
                  offset = 0;
            }
            else {
                  offset = array?.length;
            }

            switch (type) {
                  case 'property_group_master':
                        query = queryOne(offset, 10, search, type, props?.data?.company?.value).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.property_group_master],
                              hasMore: (array?.length + result?.property_group_master?.length) < result?.count[0]?.count
                        }
                  case 'block_type_master':
                        query = queryOne(offset, 10, search, type, props?.data?.company?.value).loc.source.body;
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.block_type_master],
                              hasMore: (array?.length + result?.block_type_master?.length) < result?.count[0]?.count
                        }
                  default:
                        return { options: [] }
            }
      }
      const networkCallBack = async (query, variable = {}) => {

            let payload = {
                  query,
                  variable: variable
            };

            const options = await NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        let main = response.data.data;
                        setLoading(null);
                        return main
                  })
                  .catch((error) => {
                        setLoading(null);
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("Some Thing Went Wrong"),
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.center,
                        });
                        return null
                  });

            return options
      }
      const getEnum = async () => {
            const result = await enumSelect([enum_types.purpose_global_type, enum_types.revenue_type])
            setEnum({
                  block_purpose: result?.purpose_global_type,
                  revenue_type: result?.revenue_type,
            })
      }
      React.useEffect(() => {
            getEnum()
      }, [])

      React.useEffect(() => {
            document.addEventListener("fullscreenchange", existFullscreen)
            return () => {
                  document.removeEventListener("fullscreenchange", existFullscreen)

            }

      }, [])
      //exist full screen
      const existFullscreen = () => {
            if (document.fullscreenElement === null) {
                  setFullScreenMap(false)
            }

      }
      //open full screen
      const fullScreenControl = () => {
            setFullScreenMap(true)
            document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()

      }
      return (
            <div>
                  <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={2}>
                              <Box className={classes.imagebox}>
                                    <Typography className={classes.title}>
                                          {`${getCustomBlockName(props?.data?.property_hierarchy)} ${t(" Image")}`}
                                    </Typography >
                                    <Avatar src={props?.data?.image?.length > 0 ? props?.data?.image : "/images/citynew.svg"} style={{ margin: '0 auto' }} className={props?.data?.image?.length > 0 ? classes.avatar : classes.avatar1} />
                                    <div>
                                          <label> <Box className={classes.button} variant='contained' style={{ marginTop: '10px' }}>{t("Upload Image")}</Box> <input type='file' style={{ display: 'none' }} accept="image/*"
                                                onClick={(event) => {
                                                      event.target.value = null
                                                }}
                                                onChange={(e) => props?.updateimg(e?.target?.files?.[0])} /> </label>
                                    </div>
                                    {
                                          props?.data?.image?.length > 0 ? <Typography className={classes.removeimg} onClick={props?.removeimg}>{t("Remove Image")}</Typography> : props?.data?.error?.image?.length > 0 ? (
                                                <span style={{ fontSize:"0.75rem", color: "red" }}>
                                                      {getCustomBlockName(props?.data?.property_hierarchy) + " "+ t(" Image is required")}
                                                </span>
                                          ) : <span style={{ fontSize:"0.75rem" }}>
                                                <Box height={"18px"} />
                                          </span>
                                    }

                              </Box >
                        </Grid >
                        <Grid item xs={12} md={8} lg={10}>
                              <Box className={classes.imagebox1}>
                                    <Typography className={classes.title}>
                                          {getCustomBlockName(props?.data?.property_hierarchy) + " " + t(" Details")}
                                    </Typography >

                                    <Grid container rowSpacing={1} columnSpacing={3}>
                                          <Grid item xs={12} md={4} lg={3}>
                                                <TextBox
                                                      isrequired
                                                      isReadonly
                                                      label={t("Company")}
                                                      value={props?.data?.company?.label ?? ""} />
                                          </Grid>
                                          <Grid item xs={12} md={4} lg={6}>
                                                <TextBox
                                                      isrequired
                                                      label={
                                                            getCustomBlockName(props?.data?.property_hierarchy) + " " +
                                                            t(" Name")
                                                      }
                                                      placeholder={
                                                            t("Enter ") + " " +
                                                            getCustomBlockName(props?.data?.property_hierarchy) + " " +
                                                            t(" Name")
                                                      }
                                                      value={props?.data?.blockName ?? ""}
                                                      onChange={(e) => {
                                                            props?.updateState("blockName", e.target.value);
                                                      }}
                                                      isError={props?.data?.error?.blockName?.length > 0}
                                                      errorMessage={props?.data?.error?.blockName} />
                                          </Grid>
                                          <Grid item xs={12} md={4} lg={3}>
                                                <SelectBox
                                                      isRequired
                                                      label={t("Status")}
                                                      placeholder={t("Select Status")}
                                                      options={blockStatus ?? []}
                                                      value={props?.data?.status ?? ""}
                                                      onChange={(value) => {
                                                            props?.updateState("status", value);
                                                      }}
                                                      isError={props?.data?.error?.status?.length > 0}
                                                      errorMessage={props?.data?.error?.status} />
                                          </Grid>
                                    </Grid>
                                    <Grid container style={{ marginTop: '8px' }}>
                                          <Grid item xs={12}>
                                                <Editor
                                                      value={props?.data?.description}
                                                      label={
                                                            getCustomBlockName(props?.data?.property_hierarchy) + " " +
                                                            t(" Description")
                                                      }
                                                      handleChange={(e, delta, source, editor) => {
                                                            props?.updateState("description", e);
                                                      }}
                                                      id={props?.id}
                                                      height={"80px"} />
                                          </Grid>
                                    </Grid>
                              </Box >
                        </Grid >
                  </Grid >

                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          isReadOnly
                                          label={t("Property Type")}
                                          placeholder={t("Select Property Type")}
                                          value={props?.data?.propertyType}
                                          onChange={(value) => {
                                                props?.updateState("propertyType", value);
                                          }}
                                          loading={loading === "property_group_master"}
                                          isPaginate
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'property_group_master')}
                                          isError={props?.data?.error?.propertyType?.length > 0}
                                          errorMessage={props?.data?.error?.propertyType} />

                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label={
                                                `${getCustomBlockName(props?.data?.property_hierarchy)} ${t(" Type")}`
                                          }
                                          placeholder={
                                                `${t("Select ")} ${getCustomBlockName(props?.data?.property_hierarchy)} ${t(" Type")}`
                                          }
                                          value={props?.data?.blockType}
                                          onChange={(value) => {
                                                props?.updateState("blockType", value);
                                          }}
                                          loading={loading === "block_type_master"}
                                          isPaginate
                                          debounceTimeout={800}
                                          loadOptions={(search, array) => loadOptions(search, array, 'block_type_master')}
                                          isError={props?.data?.error?.blockType?.length > 0}
                                          errorMessage={props?.data?.error?.blockType} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label={
                                                `${getCustomBlockName(props?.data?.property_hierarchy)} ${t(" Purpose")}`
                                          }
                                          placeholder={
                                                `${t("Select ")} ${getCustomBlockName(props?.data?.property_hierarchy)} ${t(" Purpose")}`
                                          }
                                          options={enumValue?.block_purpose}
                                          value={props?.data?.blockPurpose ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("blockPurpose", value);
                                          }}
                                          isError={props?.data?.error?.blockPurpose?.length > 0}
                                          errorMessage={props?.data?.error?.blockPurpose} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <SelectBox
                                          isRequired
                                          label={t("Revenue Type")}
                                          placeholder={t("Select Revenue Type")}
                                          options={enumValue?.revenue_type}
                                          value={props?.data?.revenueType ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("revenueType", value);
                                          }}
                                          isError={props?.data?.error?.revenueType?.length > 0}
                                          errorMessage={props?.data?.error?.revenueType} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <TextBox
                                          isrequired
                                          isReadonly
                                          label={t("Measurement Unit")}
                                          value={props?.data?.measurementUnit?.label ?? ""} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={2}>
                                    <NewDatePicker
                                          label={t("Year Built")}
                                          placeholder={t("Year Built")}
                                          value={props?.data?.built ?? ""}
                                          isRequired
                                          onChange={(value) => props?.updateState("built", value)}
                                          isError={props?.data?.error?.built?.length > 0}
                                          errorMessage={props?.data?.error?.built}
                                          maxDate={new Date()} />
                              </Grid>
                              {props?.data?.revenueType?.value === "Sale" && <Grid item xs={12} md={3} lg={2}>
                                    <NewDatePicker
                                          label={t("Handover Date")}
                                          placeholder={t("Handover Date")}
                                          value={props?.data?.handover ?? ""}
                                          isRequired
                                          onChange={(value) => props?.updateState("handover", value)}
                                          isError={props?.data?.error?.handover?.length > 0}
                                          errorMessage={props?.data?.error?.handover} />
                              </Grid>}
                        </Grid>
                  </Box>


                  <div className='map-wrapper'>
                        <Box className={classes.imagebox1} style={{ marginTop: '24px', position: 'relative' }}>
                              <CustomTypography>{t("Address")}</CustomTypography>
                              {
                                    props?.load && <MapWithFields
                                          fullScreenMap={fullScreenMap}
                                          fullScreenControl={fullScreenControl}
                                          mapOptions={{
                                                isInput: true,
                                                center: {
                                                      lat: props?.data?.latitude,
                                                      lng: props?.data?.longitude
                                                },
                                                lat: props?.data?.latitude,
                                                lng: props?.data?.longitude,
                                                mapLoad
                                          }}
                                          mapHeight={"370px"}
                                          breakpoints={{
                                                map: {
                                                      xs: 12,
                                                      sm: 12,
                                                      md: 5,
                                                      lg: 6,
                                                      xl: 6
                                                },
                                                components: {
                                                      xs: 12,
                                                      sm: 12,
                                                      md: 7,
                                                      lg: 6,
                                                      xl: 6
                                                }
                                          }}
                                          mapResult={props?.mapResult}
                                          autoCompletePlacement={{
                                                top: 14,
                                          }}
                                          fields={[
                                                {
                                                      label: t("Door Number"),
                                                      component: "TextField",
                                                      value: props?.data?.doorNo,
                                                      state_name: 'doorNo',
                                                      isrequired: false,
                                                      placeholder: t("Enter Door Number"),
                                                      error: props?.data?.error?.doorNo,
                                                      errorMessage: props?.data?.error?.doorNo?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Address Line 1"),
                                                      component: "TextField",
                                                      value: props?.data?.addressLineOne,
                                                      state_name: 'addressLineOne',
                                                      isrequired: false,
                                                      placeholder: t("Enter Address Line 1"),
                                                      errorMessage: props?.data?.error?.addressLineOne,
                                                      error: props?.data?.error?.addressLineOne?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 4.5
                                                      }
                                                },
                                                {
                                                      label: t("Address Line 2"),
                                                      component: "TextField",
                                                      value: props?.data?.addressLineTwo,
                                                      state_name: 'addressLineTwo',
                                                      isrequired: false,
                                                      placeholder: t("Enter Address Line 2"),
                                                      errorMessage: props?.data?.error?.addressLineTwo,
                                                      error: props?.data?.error?.addressLineTwo?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 4.5
                                                      }
                                                },
                                                {
                                                      label: t("Landmark"),
                                                      component: "TextField",
                                                      value: props?.data?.landmark,
                                                      state_name: 'landmark',
                                                      isrequired: false,
                                                      placeholder: t("Enter Landmark"),
                                                      errorMessage: props?.data?.error?.landmark,
                                                      error: props?.data?.error?.landmark?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Area"),
                                                      component: "TextField",
                                                      value: props?.data?.area,
                                                      state_name: 'area',
                                                      isrequired: false,
                                                      placeholder: t("Enter Area"),
                                                      errorMessage: props?.data?.error?.area,
                                                      error: props?.data?.error?.area?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("City"),
                                                      component: "TextField",
                                                      value: props?.data?.city,
                                                      state_name: 'city',
                                                      isrequired: false,
                                                      placeholder: t("Enter City"),
                                                      errorMessage: props?.data?.error?.city,
                                                      error: props?.data?.error?.city?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("State"),
                                                      component: "TextField",
                                                      state_name: 'state',
                                                      value: props?.data?.state,
                                                      isrequired: false,
                                                      placeholder: t("Enter State"),
                                                      errorMessage: props?.data?.error?.state,
                                                      error: props?.data?.error?.state?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Country"),
                                                      component: "TextField",
                                                      value: props?.data?.country,
                                                      state_name: 'country',
                                                      isrequired: false,
                                                      placeholder: t("Enter Country"),
                                                      errorMessage: props?.data?.error?.country,
                                                      error: props?.data?.error?.country?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Pincode"),
                                                      component: "TextField",
                                                      state_name: 'zipcode',
                                                      value: props?.data?.zipcode,
                                                      isrequired: false,
                                                      placeholder: t("Enter Pincode"),
                                                      errorMessage: props?.data?.error?.zipcode,
                                                      error: props?.data?.error?.zipcode?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Latitude"),
                                                      component: "TextField",
                                                      state_name: 'latitude',
                                                      value: props?.data?.latitude,
                                                      isrequired: false,
                                                      placeholder: t("Enter Latitude"),
                                                      errorMessage: props?.data?.error?.latitude,
                                                      error: props?.data?.error?.latitude?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                                {
                                                      label: t("Longitude"),
                                                      component: "TextField",
                                                      state_name: 'longitude',
                                                      value: props?.data?.longitude,
                                                      isrequired: false,
                                                      placeholder: t("Enter Longitude"),
                                                      errorMessage: props?.data?.error?.longitude,
                                                      error: props?.data?.error?.longitude?.length > 0,
                                                      breakpoints: {
                                                            sm: 6,
                                                            md: 3
                                                      }
                                                },
                                          ]}
                                          onChangeFields={(key, value) => props?.updateState(key, value)}
                                    />
                              }

                        </Box >
                  </div >
                  <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                              <Grid item xs={12} md={3} lg={3}>
                                    <MobileNumberInputComponent
                                          label={t("Business Phone")}
                                          placeholder={t("Business Phone")}
                                          value={props?.data?.businessPhone}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("businessPhone", value);
                                          }}
                                          isError={props?.data?.error?.businessPhone?.length > 0}
                                          errorMessage={props?.data?.error?.businessPhone}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <MobileNumberInputComponent
                                          label={t("Mobile Phone")}
                                          placeholder={t("Mobile Phone")}
                                          value={props?.data?.mobilePhone}
                                          isRequired
                                          handleChange={(value) => {
                                                props?.updateState("mobilePhone", value);
                                          }}
                                          isError={props?.data?.error?.mobilePhone?.length > 0}
                                          errorMessage={props?.data?.error?.mobilePhone}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                    />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <TextBox
                                          isrequired
                                          isReadonly
                                          label={t("Website")}
                                          placeholder={t("Enter Website")}
                                          value={props?.data?.website ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("website", e.target.value);
                                          }}
                                          isError={props?.data?.error?.website?.length > 0}
                                          errorMessage={props?.data?.error?.website} />
                              </Grid>
                              <Grid item xs={12} md={3} lg={3}>
                                    <TextBox
                                          isrequired
                                          label={t("Email Address")}
                                          placeholder={t("Enter Email Address")}
                                          value={props?.data?.emailAddress ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("emailAddress", e.target.value);
                                          }}
                                          isError={props?.data?.error?.emailAddress?.length > 0}
                                          errorMessage={props?.data?.error?.emailAddress} />
                              </Grid>
                        </Grid>
                  </Box>
            </div >
      )
}
