import { TableWithPagination } from "../../../components"
import { INTERNAL_TABLE_HEADING, INTERNAL_TABLE_PATH, INTERNAL_TABLE_TYPE } from "../utils/quickViewUtils"

export const InternalTable = ({
    t = () => false,
    size = {},
    miniLeadCardHeight = 0,
    is_table_loading = true,
    table_state = {},
    table_page = 1,
    handleTablePagination = () => false,
    table_limit = 10,
    handleTablePageLimit = () => false,
}) => {
    return (
        <TableWithPagination
            is_loading={is_table_loading}
            tableType="no-side"
            heading={INTERNAL_TABLE_HEADING({ t })}
            path={INTERNAL_TABLE_PATH}
            dataType={INTERNAL_TABLE_TYPE}
            rows={table_state?.rows}
            onClick={() => { }}
            handleIcon={() => console?.log?.("")}
            showpagination={true}
            page={table_page}
            handlePagination={(page) => handleTablePagination({ page })}
            limit={table_limit}
            handleChangeLimit={(limit) => handleTablePageLimit({ limit })}
            totalRowsCount={table_state?.total_rows_count}
            height={size?.height - 230 - miniLeadCardHeight}
            view={true}
            edit={true}
            delete={true} />
    )
}