import {  Grid, Typography , Box , Button  , Stack} from "@mui/material";
import React from "react";
import {  MobileNumberInputComponent, TextBox } from "../../index";
import {ProfileUpload} from "../../LogoUpload/profileUpload"
import { useStyles } from "./styles";
import { Steps } from "../../../screens/residentOnboarding/steps";
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import { SELECT_CONTACT } from "../../../graphql/residentOnbordingQueries";
import { LocalStorageKeys } from "../../../utils";
export const AddContact = ({t=()=>false, data = {}, contact = {}, updateState = () => false, disable = false, contactId = null, email_id = null, setDisable = () => false, setContact = () => false, setContactID = () => false ,is_prospect=false , setIsNew=()=>false , isNew=null,previous=()=>false , upsertContact=()=>false , details}) => {
    const classes = useStyles();
    return (
      <div>
          <Box p={2} style={{ height:'calc(100vh - 124px)', overflow:"auto"}}>
          <Typography className={classes.addresstitle} marginBottom="12px">{t("BILLING PRIMARY CONTACT DETAILS")}</Typography>
          <Box>
            {
                contactId  && 
                <Steps t={t} component={[
                    {
                        type: "customSelectBox",
                        placeHolder: t("Search Existing Contact"),
                        value: data?.account,
                        handleChange: (value) => {
                            setContactID(value?.value)
                            setContact( {
                                ...data,
                                "profilePic":value?.url ?? "",
                                "name":value?.label,
                                "mobile":{mobile:value?.mobile_no , mobile_code:value?.mobile_code},
                                "email":value?.email_id
                            })
                        },
                        options: [],
                        sm: 12,
                        md: 12,
                        lg: 12,
                        isActive: true,
                        menuOptionHeight: 300,
                        color: "#F5F7FA",                      
                        loadOptions: (search, array, handleLoading) => {
                            return loadOptions(
                                search,
                                array,
                                handleLoading,
                                SELECT_CONTACT,
                                "contact",
                                { isActive: true, client: localStorage.getItem(LocalStorageKeys.clinetID) , company_id:JSON.parse(localStorage.getItem(LocalStorageKeys?.selectedCompany))?.value},
                                { contact: true },
                                false,
                                false,

                            )
                        },
                    }
                ]} />
            }
         
          </Box>
            <Grid container spacing={2} className={classes.addContactParent}>

                <Grid item xs={12} >
                    <ProfileUpload 
                    isReadonly={contactId ? true : false}
                        logo_title={""} data={{src:data?.profilePic ?? ""}} profile
                        handleChange={(value) => updateState("profilePic", value?.src)}
                    />
                </Grid>
                        {/* name  */}
                        <Grid item xs={12} >
                            <TextBox label={t("Name")} placeholder={t("Name")}
                                isReadonly={contactId ? true : false}
                                value={data?.name} isrequired
                                onChange={(e) => {
                                    updateState("name", e.target.value);
                                }}
                                isError={data?.error?.name?.length > 0}
                                errorMessage={data?.error?.name}
                            />
                        </Grid>
                        
                        {/* mobile */}
                        <Grid item xs={12}>
                            <MobileNumberInputComponent label={t("Mobile")} placeholder={t("Mobile")}
                                value={data?.mobile} isRequired
                                handleChange={(value) => {
                                    updateState("mobile", value);
                                }}
                                xs={3.2}
                                isError={data?.error?.mobile?.length > 0}
                                errorMessage={data?.error?.mobile}
                                isReadonly={contactId ? true : false}

                            />
                        </Grid>
        
                        {/*Telephone  */}
                        <Grid item xs={12}>
                            <MobileNumberInputComponent
                                label={t("Telephone")}
                                xs={3.2}
                                placeholder={t("Telephone")}
                                value={data?.telephone}
                                handleChange={(value) => {
                                    updateState("telephone", value);
                                }}
                                isReadonly={contactId ? true : false}
                            />
                        </Grid>
                        {/*email  */}
                        <Grid item xs={12}>
                            <TextBox label={t("Email Address")} placeholder={t("Email Address")}
                                value={data?.email} isrequired
                                isReadonly={contactId ? true : false}
                                onChange={(e) => {
                                    updateState("email", e.target.value);
                                }}
                                isError={data?.error?.email?.length > 0}
                                errorMessage={data?.error?.email}
                            />
                        </Grid>
                       
                       
                        
                    </Grid>
        </Box>
         <Box p={2}>
         <Stack display="flex" alignItems="center" justifyContent="space-between" spacing={2} direction="row">
                         <Button className={classes.btn} variant="outlined" onClick={()=>previous(4)}>
                             {t("Back")}
                         </Button>
                         <Button className={classes.sumbitBtn}  variant="contained" onClick={()=>contactId ?  previous(4): upsertContact() }>
                              {contactId ? t("Update") : t("Create")}
         
                         </Button>
                     </Stack>
                             </Box>
      </div>
    );
};