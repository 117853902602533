import { GET_QUOTATION_LISTING } from '../../graphql/quotationListingQuery';
import {timeZoneConverter} from "../../utils"

const returnPeriod = {
    'Monthly': 'months',
    'Quarterly': 'Quarterly',
    'Yearly': 'years',
};

const returnDateFormat = (date, format = "DD MMM YY", delimiter = "") => date ? timeZoneConverter(date , format) : delimiter;
const returnData = (name, delimiter = "") => name ? name?.toString()?.trim() : delimiter;

export const QueryConstruction = (type,offset = 0, limit = 10, searchText = "",extraParams={}) => {
    switch (type) {
        case 'quotation_listing':
            return {
                type: GET_QUOTATION_LISTING(
                    offset,
                    limit,
                    searchText,
                    extraParams?.company_id,
                    extraParams?.unit_id,
                    extraParams?.created_by,
                    extraParams?.status,
                    extraParams?.final_status,
                    ).loc.source.body,
                construct: (data) => {
                    return data?.map((_, index) => ({
                        id: _?.id,
                        index: offset + index + 1,
                        quotationId: returnData(_?.quotation_no, '-'),
                        leadName: returnData(_?.lead?.name, '-'),
                        agent: returnData(`${returnData(_?.lead?.agent?.first_name)} ${returnData(_?.lead?.agent?.last_name)}`, '-'),
                        units: _?.units?.length,
                        value: returnData(`${returnData(_?.currency_master?.symbol)} ${returnData(_?.total_amount)}`, '-'),
                        period: returnData(`${returnData(_?.lease_duration)} ${returnData(returnPeriod[_?.lease_period])}`, '-'),
                        expireOn: returnDateFormat(_?.exipry_on, "", "-"),
                        status: returnData(_?.status, '-'),
                        icon: "view"
                    }))
                }
            }
        case 'quotation_details':
            return {
                // type: GET_QUOTATION_DETAILS(extraParams?.id).loc.source.body,
                construct: (data) => {
                    const returnUnitSummaryDetails = (unit) => {
                        return unit?.length ? unit?.map(_=>({
                            profile_pic:_?.url ? _?.url : '',
                            unitNumber:returnData(_?.unit_no,'-'),
                            blockNumber:returnData(_?.block_no,'-'),
                            floorNumber:returnData(_?.floor_no,'-'),
                            area:returnData(_?.total_area,'-'),
                            unitType:returnData(_?.unit_type,'-'),
                            total:returnData(_?.unit_total_primary,'-'),
                            refundable:returnData(_?.unit_total_refundable,'-'),
                            currency:returnData(_?.currency_symbol)
                        })) : []
                    }

                    return{
                        quotationDetails:{
                            quotationNumber:returnData(data?.quotation_no,'-'),
                            active:data?.is_active ? 'Active' : 'In active',
                            generatedDate:returnDateFormat(data?.created_at,"","-"),
                            quotationStatus:returnData(data?.quote_confirmation,'-'),
                            quotationDescription:returnData(data?.description,'-'),
                            createdBy:'Created By',
                            quotationStartDate:returnDateFormat(data?.quote_start_date,"","-"),
                            quotationExpiryDate:returnDateFormat(data?.quotation_expiry_date,"","-"),
                            leasePeriod:returnData(data?.lease_period,'-'),
                            leaseStartDate:returnDateFormat(data?.lease_start_date,"","-"),
                            leaseEndDate:returnDateFormat(data?.lease_end_date,"","-"),
                            duration: returnData(`${returnData(data?.quotation_duration_value)} ${returnData(data?.quotation_duration)}`, '-'),
                            gracePeriod:returnData(`${returnData(data?.grace_period)} ${returnData(data?.quotation_duration)}`, '-'),
                            agent:returnData(`${returnData(data?.lead_owner_first_name)} ${returnData(data?.lead_owner_last_name)}`, '-'),
                        },
                        leadDetails:{
                            profile_pic:data?.lead_image ? data?.lead_image : '',
                            name:returnData(`${returnData(data?.lead_first_name)} ${returnData(data?.lead_last_name)}`, '-'),
                            mobileNumber:returnData(`${returnData(data?.lead_mobile_no_country_code)} ${returnData(data?.lead_mobile_no)}`, '-'),
                            accountID:returnData(data?.account_no,'-')
                        },
                        unitDetails:returnUnitSummaryDetails(data?.units),
                        total:data?.total,
                        totalAmount:data?.totalAmount,
                        totalRefundable:data?.totalRefundable,
                        currency:data?.currency_symbol,
                        totalproperties:data?.totalproperties,
                        totalUnits:data?.totalUnits,
                    }
                }
            }
        default:
            return null
    }
}