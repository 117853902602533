import gql from "graphql-tag";

export const GET_CORRESPONDENCE_OPTIONS = gql`
query GET_CORRESPONDENCE_OPTIONS($clientID: String) {
  correspondences_type_master(
    where: { client: { eq: $clientID }, is_active: { eq: true } }
    order_by: { id: asc }
  ) {
    value: id
    label: name
  }
  correspondences_level_master(
    where: { client: { eq: $clientID }, is_active: { eq: true } }
    order_by: { id: asc }
  ) {
    value: id
    label: name
  }
  correspondences_status_master(
    where: { client: { eq: $clientID }, is_active: { eq: true } }
    order_by: { id: asc }
  ) {
    value: id
    label: name
  }
}`

export const GET_CORRESPONDENCE_BY_AGREEMENT_UNIT = gql`
  query GET_CORRESPONDENCE_BY_AGREEMENT_UNIT(
    $agreementUnitID: String
    $offset: Int
    $limit: Int
  ) {
    count: correspondence_units(
      where: {
        agreement_unit_id: { eq: $agreementUnitID }
        is_active: { eq: true }
      }
    ) {
      count: count_id
    }
    correspondence_units(
      where: {
        agreement_unit_id: { eq: $agreementUnitID }
        is_active: { eq: true }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      correspondence_id
      agreement_unit_id
      correspondenceByID {
        id
        reference_no
      }
    }
  }`

export const GET_CORRESPONDENCE_BY_CONTACT_ID = gql`
  query GET_CORRESPONDENCE_BY_CONTACT_ID(
    $contactID: String
    $offset: Int
    $limit: Int
  ) {
    count: correspondence_units(where: { contact_id: { eq: $contactID } }) {
      count: count_id
    }
    correspondence_units(
      where: { contact_id: { eq: $contactID } }
      offset: $offset
      limit: $limit
    ) {
      id
      correspondence_id
      contact_id
      correspondenceByID {
        id
        reference_no
      }
    }
  }`