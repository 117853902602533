import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { CloseIconWithBG } from "../../../assets";
import { FormGenerator, UploadComponent } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions } from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { ItemOptionList, StatusOptionList } from "../../../utils/insepectionMaster";
import { InspectEditStyles } from "./style";

const Form = ({ company_id = "", data = {}, setData = () => false, reload = () => false, onClose = () => false, t, itemTypeOptions = [], uomOptions = [],company={} }) => {

    const alert = React.useContext(AlertContext);
    const [assets, setAssets] = React.useState([]);
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const classes = InspectEditStyles()
    const size = useWindowDimensions()
    //update state of master
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        if (key === "item_type") {
            setData({ ...data, [key]: value, error, "item_category": "", "item_subcategory": "" });
        }
        else if (key === "item_category") {
            setData({ ...data, [key]: value, error, "item_subcategory": "" });
        }
        else {
            setData({ ...data, [key]: value, error });
        }


    };
    //get Inspection Items Based on Id 
    const getInspectionItemsById = (item) => {

        const payload = {
            id: item?.id
        }
        NetworkCall(
            `${config.api_url}/inspection_items/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                const val = response?.data?.data
                setData({
                    ...data,
                    isEdit: data?.isEdit,
                    id: val?.id,
                    item: val?.name,
                    item_id: item?.reference_no,
                    status: val?.is_active,
                    item_type: { label: val?.item, value: val?.item },
                    name: val?.name,
                    assets: val?.assets ?? [],
                    item_category: { value: val?.category_id, label: val?.category },
                    item_subcategory: { value: val?.subcategory_id, label: val?.subcategory },
                    manufacturer_name: { value: val?.manufacturer_id, label: val?.manufacturer },
                    tax_group: { value: val?.vat_group_id, label: val?.vat_group_name },
                    hsn_sac_code: val?.hsn_sac_code,
                    is_billing: val?.is_billing,
                    is_consumable: val?.is_consumable,
                    is_Taxable: val?.is_taxable,
                    pantry_billing: val?.pantry_billing,
                    cost_price: val?.costing_value??null,
                    sale_price: val?.sale_value??null,
                    parentItem: val?.parent_id !== null ? { label: val?.parent_name, value: val?.parent_id } : "",
                    uom: val?.period_type !== null ? { value: val?.period_type, label: val?.period_type } : "",
                });
                setAssets(val?.assets??[])
            }).catch((err) => {
                console.log(err)
            })
    }
    React.useEffect(() => {
        if (data?.isEdit || data?.isView) {
            getInspectionItemsById(data)
        }
        //eslint-disable-next-line
    }, [])
    //validate form
    const validateForm = () => {
        let isValid = true;
        let error = data.error;

        if (data?.name?.length === 0) {
            isValid = false;
            error.name = `${t("itemName")} ${t("isRequired")}`;
        }
        if (data?.item_type?.length === 0) {
            isValid = false;
            error.item_type = `${t("itemType")} ${t("isRequired")}`;
        }
        if (data?.item_category?.length === 0) {
            isValid = false;
            error.item_category = `${t("Item Category")} ${t("isRequired")}`;
        }
        if (data?.item_subcategory?.length === 0) {
            isValid = false;
            error.item_subcategory = `${t("Item subcategory")} ${t("isRequired")}`;
        }
        // if (data?.parentItem?.length === 0) {
        //     isValid = false;
        //     error.parentItem = `${t("Parent Item is required")}`;
        // }
        if (data?.item_type?.value === "Inventory" && data?.manufacturer_name?.length === 0) {
            isValid = false;
            error.manufacturer_name = `${t("Manufacturer Name")} ${t("isRequired")}`;
        }
        if (data?.is_billing && data?.cost_price === null) {
            isValid = false;
            error.cost_price = `${t("Cost price is Required")}`;
        }
        if (data?.is_billing && data?.sale_price===null) {
            isValid = false;
            error.sale_price = `${t("Sale price is Required")}`;
        }
        if (data?.status?.length === 0) {
            isValid = false;
            error.status = `${t("status")} ${t("isRequired")}`;
        }
        if (data?.uom?.length === 0) {
            isValid = false;
            error.uom = `${t("UOM is Required")}`;
        }
        setData({ ...data, error });
        return isValid;
    };
    const handleSubmit = () => {
        if (data?.isView) {
            setData({ ...data, isView: false, isEdit: true })
        }
        else {
            onSubmit()
        }
    }
    //on submit
    const onSubmit = () => {

        if (validateForm()) {
            setIsDisableBtn(true)
            const payload = {
                name: data?.name,
                inventory_id: data?.item ? data?.name?.value : undefined,
                is_inventory: false,
                is_active: data?.status,
                item: data?.item_type?.value,
                company_id: data?.isEdit ? undefined : company_id,
                id: data?.id ?? undefined,
                "size_type": "instruction_item_mapping_size",
                "assets": assets,
                "category_id": data?.item_category?.value,
                "subcategory_id": data?.item_subcategory?.value,
                "manufacturer_id": data?.manufacturer_name?.value ?? null,
                "is_billing": data?.is_billing,
                "is_taxable": data?.is_Taxable,
                "hsn_sac_code": data?.hsn_sac_code,
                "vat_group_id": data?.tax_group?.value ?? null,
                "is_consumable": data?.is_consumable,
                "parent_id": data?.parentItem?.value ?? null,
                "sale_value": data?.sale_price,
                "costing_value": data?.cost_price,
                "pantry_billing": data?.pantry_billing,
                "period_type": data?.uom?.value ?? null

            }
            NetworkCall(
                `${config.api_url}/inspection_items/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                setIsDisableBtn(false)
                reload()
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${t("Item Successfully")} ${data?.isEdit ? t("Edited") : t("Created")}`,
                });
                onClose()


            }).catch((err) => {
                setIsDisableBtn(false)
                if (err?.response?.status === 406)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Your  Inspection  limit has been hit! Please upgrade your plan!"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Some Thing Went Wrong"),

                    });
                }


            })
        } else {
            return false
        }
    }
    const InspectionItemManualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                label: i?.name,
                value: i?.id,
                ...i
            }
        })
        return details
    }
    //form data
    const changeInput = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: (data?.isEdit || data?.isView),
            component: "text",
            label: t("Item Id"),
            placeholder: t("Enter Item Id"),
            value: data?.item_id,
            color: "#F6F6F6",
            onChange: (value) => updateState("item_id", value.target.value),
            error: data?.error?.item_id,
            isReadonly: true
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: t("itemType"),
            placeholder: t("selectItemType"),
            value: data?.item_type,
            onChange: (value) => updateState("item_type", value),
            error: data?.error?.item_type,
            isRequired: true,
            options: itemTypeOptions,
            debounceTimeout: 800,
            isPaginate: true,
            isReadonly: data?.isView,
            menuOptionHeight: "150px",
            menuPlacement: "bottom"

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: t("Item Category"),
            placeholder: t("Select Item Category"),
            value: data?.item_category,
            onChange: (value) => updateState("item_category", value),
            error: data?.error?.item_category,
            isRequired: true,
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "inspection_item_category",
                    {
                        "item_type": [data?.item_type?.value]
                    },
                    search,
                    array,
                    handleLoading,
                    "list",
                ),
            key: JSON.stringify(data?.item_type),
            debounceTimeout: 800,
            isPaginate: true,
            isReadonly: data?.isView,
            menuOptionHeight: "150px",
            menuPlacement: "bottom"

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: data?.item_category !== "" ? true : false,
            component: "select",
            label: t("Item Subcategory"),
            placeholder: t("Select Item Subcategory"),
            value: data?.item_subcategory,
            onChange: (value) => updateState("item_subcategory", value),
            error: data?.error?.item_subcategory,
            isRequired: true,
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "inspection_item_subcategory",
                    {
                        "category_id": data?.item_category?.value
                    },
                    search,
                    array,
                    handleLoading,
                    "list",
                ),
            key: JSON.stringify(data?.item_category),
            debounceTimeout: 800,
            isPaginate: true,
            isReadonly: data?.isView,
            menuOptionHeight: "150px",
            menuPlacement: "bottom"

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: (data?.isEdit || data?.item_category === "") ? 12 : 6,
                lg: (data?.isEdit || data?.item_category === "") ? 12 : 6
            },
            isActive: true,
            component: "text",
            label: t("itemName"),
            placeholder: t("enterItemName"),
            value: data?.name,
            onChange: (value) => updateState("name", value.target.value),
            error: data?.error?.name,
            isReadonly: data?.isView,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: data?.item_type?.value === "Inventory" ? true : false,
            component: "select",
            label: t("Manufacturer Name"),
            placeholder: t("Select Manufacturer Name"),
            value: data?.manufacturer_name,
            onChange: (value) => updateState("manufacturer_name", value),
            error: data?.error?.manufacturer_name,
            isRequired: true,
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "manufacturer_master",
                    {
                        "item_type": [data?.item_type?.value]
                    },
                    search,
                    array,
                    handleLoading,
                    "list",
                ),
            debounceTimeout: 800,
            isPaginate: true,
            menuOptionHeight: "150px",
            menuPlacement: "bottom"
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: t("Parent Item"),
            placeholder: t("Select Parent Item"),
            value: data?.parentItem,
            onChange: (value) => updateState("parentItem", value),
            error: data?.error?.parentItem,
            // isRequired: true,
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "/inspection_items/getAll",
                    {
                        "company_id": company_id
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    InspectionItemManualResponse
                ),
            debounceTimeout: 800,
            isPaginate: true,
            menuOptionHeight: "150px",
            menuPlacement: "bottom"
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: t("UOM"),
            placeholder: t(`Select UOM`),
            value: data?.uom,
            onChange: (value) => updateState("uom", value),
            error: data?.error?.uom,
            isRequired: true,
            options: uomOptions,
            debounceTimeout: 800,
            isPaginate: true,
            isReadonly: data?.isView,
            menuOptionHeight: "150px",
            menuPlacement: "bottom"

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "text",
            label: t("Cost Price"),
            placeholder: t("Enter Cost Price"),
            type: "number",
            value: data?.cost_price,
            onChange: (value) => updateState("cost_price", value.target.value),
            error: data?.is_billing ? data?.error?.cost_price : "",
            endAdornment:company?.currency_symbol,
            isReadonly: data?.isView,
            isRequired: data?.is_billing ? true : false,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "text",
            type: "number",
            label: t("Sale Price"),
            placeholder: t("Enter Sale Price"),
            value: data?.sale_price,
            onChange: (value) => updateState("sale_price", value.target.value),
            error: data?.is_billing ? data?.error?.sale_price : "",
            endAdornment:company?.currency_symbol,
            isReadonly: data?.isView,
            isRequired: data?.is_billing ? true : false,
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "toggle",
            label: t("Pantry Billing"),
            placeholder: t("Billing"),
            value: data?.pantry_billing,
            onChange: (value) => updateState("pantry_billing", value),
            error: data?.error?.pantry_billing,
            isRequired: true,
            isReadonly: data?.isView,
            options: ItemOptionList(t)
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "toggle",
            label: t("Consumable"),
            placeholder: t("Consumable"),
            value: data?.is_consumable,
            onChange: (value) => updateState("is_consumable", value),
            error: data?.error?.is_consumable,
            isRequired: true,
            isReadonly: data?.isView,
            options: ItemOptionList(t)
        },
    ]
    const form2 = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggleButton",
            label: t("Billing"),
            value: data?.is_billing,
            isReadonly: data?.isView,
            onChange: (value) => setData({ ...data, is_billing: value, is_Taxable: false, is_consumable: false, hsn_sac_code: "", tax_group: "" }),
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: data?.is_billing,
            component: "toggleButton",
            label: t("Tax Enabled"),
            value: data?.is_Taxable,
            isReadonly: data?.isView,
            onChange: (value) => setData({ ...data, is_Taxable: value, is_consumable: false, hsn_sac_code: "", tax_group: "" }),
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: data?.is_Taxable,
            component: "text",
            label: t("HSN/SAC Code"),
            placeholder: t("Enter HSN/SAC Code"),
            value: data?.hsn_sac_code,
            onChange: (value) => updateState("hsn_sac_code", value.target.value),
            error: data?.error?.hsn_sac_code,
            isReadonly: data?.isView
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: data?.is_Taxable,
            component: "select",
            label: t("Tax Group"),
            placeholder: t("Select Tax Group"),
            value: data?.tax_group,
            onChange: (value) => updateState("tax_group", value),
            error: data?.error?.tax_group,
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "vat-group/getAll",
                    {
                        country_id: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.data?.country?.id
                    },
                    search,
                    array,
                    handleLoading,
                    "vat_group_master",
                ),
            debounceTimeout: 800,
            isPaginate: true,
            menuOptionHeight: "150px",
            menuPlacement: "bottom",
            isReadonly: data?.isView

        },

        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "toggle",
            label: t("status"),
            placeholder: t("status"),
            value: data?.status,
            onChange: (value) => updateState("status", value),
            error: data?.error?.status,
            isRequired: true,
            options: StatusOptionList(t),
            isReadonly: data?.isView
        },

    ]

    return (

        <Box>
            {/*form generator */}
            <Box display={"flex"} justifyContent={"space-between"} p={1.5} alignItems="center" sx={{ backgroundColor: "#F2F4F7" }}>
                <Typography className={classes.drawerHeader}>{data?.isView ? t("View Item") : data?.isEdit ? t("Edit Item") : t("Create Item")}</Typography>
                <Box onClick={onClose} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
            </Box>
            <Divider />
            <Box p={2} width="500px" height={size?.height - 120} overflow="scroll" position={"relative"}>
                <FormGenerator t={t} components={changeInput} />
                <Box mt={1}>
                    <UploadComponent
                        // readOnly={type?.label === resourceTypes?.users && data?.is_exisit}
                        handleChange={(val) => updateState("assets", val)}
                        logo_title={t("Upload Images")}
                        errorMessage={data?.error?.assets}
                        isError={data?.error?.assets?.length > 0}
                        assets={assets}
                        setAssets={setAssets}
                        xs={3}
                        height={"100px"}
                        imageHeight={"98px"}
                        readOnly={data?.isView}
                    />
                </Box>
                <Box mt={1}>
                    <FormGenerator t={t} components={form2} />
                </Box>
            </Box>
            <Box className={classes.fixedBottom}>
                <Button sx={{ height: "40px" }} variant="contained" fullWidth onClick={handleSubmit} disabled={isDisableBtn}>{data?.isEdit ? t("Save") : data?.isView ? t("edit") : t("Create")}</Button>
            </Box>
        </Box >
    )
}
export default withNamespaces("inspectionItemmaster")(Form); 