import gql from "graphql-tag";

export const InsertManagementCommitte = gql`
mutation($insert: [committeeRolesInput!]) {
    committee_roles(insert: $insert ) {
      id
      role_name
      description
      company_id
    is_active
    }}
`

export const getManagementRole = gql`
query GET_COMMITTEE_ROLES(
    $offset: Int
    $limit: Int
    $searchText: String
    $companyId: ID
  ) {
    count: committee_roles(
      where: {
        role_name: { iregex: $searchText }
        company_id: { eq: $companyId }
        is_active: { in: $status }
      }
    ) {
      count: count_id
    }
    committee_roles(
      where: {
        role_name: { iregex: $searchText }
        company_id: { eq: $companyId }
        is_active: { in: $status }
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      id
      role_name
      description
      created_at
      updated_at
      company {
        id
        name
      }
      created_by {
        first_name
        last_name
        id
      }
      is_active
    }
  }
  `

  export const updateManagementRole = gql`
  mutation($id: [committeeRolesInput!]) {
    committee_roles(  where: { id: { eq: $id } }
      update: $data ) {
      id
    }
  }
  `