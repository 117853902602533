import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { CustomToggleButtonComponent, SelectBox, ToggleButtonComponent } from '../../../components';
import { CustomSelect } from "../../../components/filterGenerator/components/select";
import { config } from "../../../config";
import { GET_AMINITIES_LIST, GET_CURRENCY, GET_PROPERTY_FORM_MASTER as queryOne, GET_UOM, UNIT_TYPE } from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { filterStyles } from "./styles";
import { CustomTextFieldComponent } from "./textField";
export const Filter = ({ updateState = () => false, onClose = () => false, applyfilter = () => false, filter = "", state = {}, onClear = () => false, type = [], company = "", t = () => false }) => {
    const classes = filterStyles();
    const [loading, setLoading] = React.useState(false)
    const size = useWindowDimensions()

    const GRACE_PEROID = [

        {
            value: 15,
            label: t("15 Days")
        },
        {
            value: 30,
            label: t("30 Days")
        },
    ]
    const FURNISH_TYPE = [
        {
            "label": t("None"),
            "value": "None"
        },
        {
            "label": t("Unfurnished"),
            "value": "Unfurnished"
        },
        {
            "label": t("Semi-Furnished"),
            "value": "Semi-Furnished"
        },
        {
            "label": t("Furnished"),
            "value": "Furnished"
        },
        {
            "label": t("Shell"),
            "value": "Shell"
        }
    ]


    const PED_ALLOWED = [
        {
            label: t("Allowed"),
            value: "Allowed"
        },
        {
            label: t("Not Allowed"),
            value: "Not Allowed"
        }
    ]
    const unitPurposeList = [
        {
            label: t("Commercial"),
            value: "Commercial",
        },
        {
            label: t("Mixed"),
            value: "Mixed",
        },
        {
            label: t("Residential"),
            value: "Residential",
        },
    ];
    const loadOptions = async (search = "", array, type) => {

        setLoading(type);
        let result, query, offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }
        let userProfileID = localStorage.getItem("profileID")

        switch (type) {
            case 'property_group_master':
                query = queryOne(offset, 10, search, userProfileID, type).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.property_group_master],
                    hasMore: (array?.length + result?.property_group_master?.length) < result?.count[0]?.count
                }
            case 'ameninties':
                query = GET_AMINITIES_LIST(offset, 10, search, company).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.amenities_type_master],
                    hasMore: (array?.length + result?.amenities_type_master?.length) < result?.count[0]?.count
                }
            case 'unittype':
                query = UNIT_TYPE(offset, 10, search, filter, company).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.unit_type_master],
                    hasMore: (array?.length + result?.unit_type_master?.length) < result?.count[0]?.count
                }
            case 'facilites':
                query = GET_AMINITIES_LIST(offset, 10, search, company).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.amenities_type_master],
                    hasMore: (array?.length + result?.amenities_type_master?.length) < result?.count[0]?.count
                }
            case 'currency':
                query = GET_CURRENCY(offset, 10, search).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.currency_master],
                    hasMore: (array?.length + result?.currency_master?.length) < result?.count[0]?.count
                }
            case 'uom':
                query = GET_UOM(offset, 10, search).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.uom_master],
                    hasMore: (array?.length + result?.uom_master?.length) < result?.count[0]?.count
                }

            default:
                return { options: [] }
        }
    }
    const networkCallBack = async (query, variable = {}) => {

        let payload = {
            query,
            variable: variable
        };

        const options = await NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                setLoading(null);
                return main
            })
            .catch((error) => {
                setLoading(null);

                return null
            });

        return options
    }

    return (
        <div>
            <Box display="flex">
                <Box marginRight="12px">
                    <IconButton size="small" onClick={onClose} className={classes.dialogclose}>
                        <CloseIcon size="small" style={{ fontSize: "1.25rem" }} />
                    </IconButton>
                </Box>
                <Box style={{ backgroundColor: "white" }} className={classes.root}>
                    <Box display="flex" alignItems="center" className={classes.titleBar} p={1.3}>

                        <Box flexGrow={1}>
                            <Typography className={classes.title}>{t("Filter")}</Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.clear} onClick={onClear}>{t("Clear Filter")}</Typography>
                        </Box>

                    </Box>
                    <div style={{ height: size?.height - 112, overflow: "scroll" }}>

                        <Box >
                            {
                                type?.propertytype &&
                                <Accordion disableGutters defaultExpanded={state?.propertyType?.length > 0 ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>Property Type </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <CustomSelect
                                            label={false}
                                            placeholder={t("Property Type")}
                                            padding="2px"
                                            color="#F5F7FA"
                                            value={state?.propertyType}
                                            loading={loading === "property_group_master"}
                                            isPaginate
                                            isMulti
                                            onChange={(value) => updateState('propertyType', value)}
                                            debounceTimeout={800}
                                            loadOptions={(search, array) => loadOptions(search, array, 'property_group_master')}
                                        />
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.area &&
                                <Accordion disableGutters defaultExpanded={state?.range1 ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Required Area")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <Grid container >
                                            <Grid item xs={3.5}>
                                                <CustomTextFieldComponent
                                                    height={"48px"}
                                                    value={state?.range1}
                                                    onChange={(e) => updateState("range1", e)}
                                                    placeholder={t("Min")}

                                                />
                                            </Grid>
                                            <Grid item xs={3.5}>
                                                <CustomTextFieldComponent
                                                    height={"48px"}
                                                    value={state?.range2}
                                                    onChange={(e) => updateState("range2", e)}
                                                    placeholder={t("Max")}
                                                    position

                                                />
                                            </Grid>
                                            <Grid item xs={4.5} marginLeft="6px">
                                                <SelectBox
                                                    padding="2px"
                                                    color="#F5F7FA"
                                                    value={state?.rangetype}
                                                    onChange={(value) => updateState('rangetype', value)}
                                                    loading={loading === "uom"}
                                                    isPaginate
                                                    debounceTimeout={800}
                                                    loadOptions={(search, array) => loadOptions(search, array, 'uom')}

                                                />

                                            </Grid>
                                        </Grid>
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.budget &&
                                <Accordion disableGutters defaultExpanded={state?.budget1 ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>t{("Budget")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <Grid container >
                                            <Grid item xs={3.5}>
                                                <CustomTextFieldComponent
                                                    value={state?.budget1}
                                                    onChange={(e) => updateState("budget1", e)}
                                                    placeholder={t("Min")}

                                                />
                                            </Grid>
                                            <Grid item xs={3.5}>
                                                <CustomTextFieldComponent
                                                    value={state?.budget2}
                                                    onChange={(e) => updateState("budget2", e)}
                                                    placeholder={t("Max")}
                                                    position

                                                />
                                            </Grid>
                                            <Grid item xs={4.5} marginLeft="6px">
                                                <SelectBox
                                                    padding="2px"
                                                    color="#F5F7FA"
                                                    isReadOnly
                                                    value={state?.budgettype}
                                                    onChange={(value) => updateState('budgettype', value)}
                                                    //options={BUDGET_OPTIONS}
                                                    loading={loading === "currency"}
                                                    isPaginate
                                                    debounceTimeout={800}
                                                    loadOptions={(search, array) => loadOptions(search, array, 'currency')}
                                                />

                                            </Grid>
                                        </Grid>
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.amenities &&
                                <Accordion disableGutters defaultExpanded={state?.aminities?.length > 0 ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Amenities")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <CustomSelect
                                            padding="2px"
                                            placeholder={t("Amenities")}
                                            color="#F5F7FA"
                                            value={state?.aminities}
                                            isMulti
                                            loading={loading === "ameninties"}
                                            isPaginate
                                            onChange={(value) => updateState('aminities', value)}
                                            debounceTimeout={800}
                                            loadOptions={(search, array) => loadOptions(search, array, 'ameninties')}
                                        />
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.facilites &&
                                <Accordion disableGutters defaultExpanded={state?.facilites?.length > 0 ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Property Amenities")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <CustomSelect

                                            placeholder={t("Property Amenities")}
                                            padding="2px"
                                            // color="#F5F7FA"

                                            value={state?.facilites}
                                            isMulti
                                            loading={loading === "facilites"}
                                            isPaginate
                                            menuPlacement="bottom"

                                            onChange={(value) => updateState('facilites', value)}
                                            debounceTimeout={800}
                                            loadOptions={(search, array) => loadOptions(search, array, 'facilites')}
                                        />
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.grace &&
                                <Accordion disableGutters defaultExpanded={state?.facilitesbool ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Grace Period")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>

                                        <CustomToggleButtonComponent
                                            t={t}
                                            value={state?.facilitesbool}
                                            onChange={(e) => {
                                                updateState("facilitesbool", e)
                                            }}
                                            height="46px"
                                            options={GRACE_PEROID}
                                            isMulti
                                            custom
                                            customValue={0}
                                            handleDelete={() => updateState("facilitesbool", "")}
                                            is_filter
                                        />

                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.unittype &&
                                <Accordion disableGutters defaultExpanded={state?.unittype?.length > 0 ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Unit Type")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <CustomSelect
                                            placeholder={t("Unit Type")}
                                            label={false}
                                            padding="2px"
                                            // color="#F5F7FA"
                                            menuPlacement="auto"

                                            value={state?.unittype}
                                            loading={loading === "unittype"}
                                            isPaginate
                                            isMulti
                                            onChange={(value) => updateState('unittype', value)}
                                            debounceTimeout={800}
                                            loadOptions={(search, array) => loadOptions(search, array, 'unittype')}
                                        />
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.unitpurpose &&
                                <Accordion disableGutters defaultExpanded={state?.unitpurpose?.length > 0 ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Unit Type ")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <CustomSelect
                                            label={false}
                                            padding="2px"
                                            color="#F5F7FA"
                                            options={unitPurposeList}
                                            value={state?.unitpurpose}
                                            onChange={(value) => updateState('unitpurpose', value)}

                                        />
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.furnish &&
                                <Accordion disableGutters defaultExpanded={state?.furnish ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Furnishing")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <ToggleButtonComponent
                                            value={state?.furnish}
                                            onChange={(e) => updateState("furnish", e)}
                                            options={FURNISH_TYPE}
                                            isMulti
                                        />
                                        <Box height="14px" />
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                type?.pet &&
                                <Accordion disableGutters defaultExpanded={state?.pet ? true : false} className={classes.accordion}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.filterTitle}>{t("Pets Allowed")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: "0px 12px" }} className={classes.accordionDetails}>
                                        <ToggleButtonComponent
                                            value={state?.pet}
                                            onChange={(e) => updateState("pet", e)}
                                            options={PED_ALLOWED}
                                            isMulti
                                        />
                                        <Box height="14px" />

                                    </AccordionDetails>
                                </Accordion>
                            }


                        </Box>
                    </div>
                    <Box p={1} className={classes.applyRoot}>
                        <Button onClick={applyfilter} variant="contained" className={classes.apply} fullWidth >{t("Apply")}</Button>

                    </Box>
                </Box>


            </Box>

        </div>
    )
}