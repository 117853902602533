import { Box, Button, Grid, Typography, Stack } from "@mui/material"
import React, { useState } from "react"
import { withNamespaces } from "react-i18next"
import { AlertDialog, Subheader, UseDebounce } from "../../components"
import { UtilityReadingsTable } from "./components/table"
import { useStyles } from "./style"
import { UtilityInfo } from "./components/utilityInfo"
import { Close } from "@mui/icons-material"
import { NetworkCall } from "../../networkcall"
import { config } from "../../config"
import { AlertProps, NetWorkCallMethods, accessCheckRender, getCompanyOption, getRoutePermissionNew } from "../../utils"
import { GET_PROPERTY_BYCOMPANY } from "../../graphql/scheduleBoardQuery"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NewLoader } from "../../components/newLoader"
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions"

const UtilityReadings = ({ t }) => {
    const [companyList, setCompanyList] = React.useState("")
    const [selectedCompany, setSelectedCompany] = React.useState("")
    const [propertyData, setPropertyData] = React.useState("")
    const [propertyValue, setPropertyValue] = React.useState("")
    const [unitData, setUnitData] = React.useState("")
    const [unitValue, setUnitValue] = React.useState("")
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [searchText, setSearchText] = React.useState("")
    const alert = React.useContext(AlertContext)
    const [loading, setLoading] = useState(true)
    const [permission, setPermission] = useState({})
    const [showDialog, setShowDialog] = React.useState(false)
    const [utilityDetails, setUtilityDetails] = React.useState({})
    const [utilityGraphData, setUtilityGraphData] = React.useState([])
    const [utilityInvoiceDetails, setUtilityInvoiceDetails] = React.useState([])
    const [filterData, setFilterData] = React.useState({ utility: [] });
    const [drawer, setDrawer] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [tableData, setTableData] = React.useState({
        list: []
    })
    const debounce = UseDebounce()
    const classes = useStyles()

    //get utility Readings
    const getUtilityReadingList = (offset = 0, limit = 10, companyId = "", propertyId = "", unitId = "", search = "", filterData = {}) => {
        const payload = {
            "search": search,
            "unit_id": unitId,
            "limit": limit,
            "offset": offset,
            "property_id": propertyId,
            "utility_ids": filterData?.utility?.map((x) => { return x.value }),
            "start_date": filterData?.dateRange?.startDate,
            "end_date": filterData?.dateRange?.endDate
        }

        NetworkCall(
            `${config.api_url}/utility_readings/unit_reading_list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const result = response?.data?.data?.readings?.map((val, i) => {
                return {
                    ...val,
                    selectedRow: val?.selectedRow,
                    Property_name: val?.unit?.property?.name,
                    unit_id: val?.unit?.unit_no,
                    meter_no: val?.unit_util?.meter_serial_no,
                    utility_name: val?.unit_util?.util?.utility_name,
                    UOM: val?.reading_no,
                    ratePerUOM: `${val?.currency?.symbol ?? ""} ${val?.cost_per_unit} / ${val?.unit_util?.period}`
                }
            })
                        setLoading(false)
            if (result?.length > 0) {
                setUtilityDetails(result?.[0])
                getUtilityGraphData(result?.[0])
                getUtilityInfoDetails(result?.[0])
            }
            setTableData({ list: result, count: response?.data?.data?.count })

        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    //get property by company id
    const getPropertyDropdown = (company = [], changeProperty = false) => {
      let payload = {
        query: GET_PROPERTY_BYCOMPANY,
        variables: {
          company_id: [company],
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      ).then((res) => {
        setPropertyData(res?.data?.data?.property);
        if (!changeProperty) {
          setPropertyValue(res?.data?.data?.property[0]);
          getUnitByProperty(company, res?.data?.data?.property[0]?.value ?? null);
        }
      });
    };
    //get utility Readings
    const getUnitByProperty = (companyId = "", propertyId = "") => {
        const payload = {
            "property_id": propertyId,
            "company_id": companyId,
            "limit": 10,
            "offset": 0
        }

        NetworkCall(
            `${config.api_url}/unit/property_units`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const allUnits = [{ label: "All units", value: null }]
            const result = response?.data?.data?.units?.map((val) => {
                return {
                    label: val?.name,
                    value: val?.id
                }
            })
            setUnitData(allUnits.concat(result))
            setUnitValue(allUnits?.[0])
            if (result?.length > 0) {
                getUtilityReadingList(0, 10, companyId, propertyId, allUnits?.[0]?.value,"",filterData)
            }

        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    //useEffect
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getPropertyDropdown(company?.selected?.value)
                }
            }
        }
        //eslint-disable-next-line
    }, [auth])

    //handleClose
    const handleClose = () => {
        setShowDialog(false)
    }
    //handleCompany
    const handleCompanyChange = (val) => {
        setSelectedCompany(val)
        getPropertyDropdown(val?.value)
    }
    //on property change
    const onPropertyChange = (val) => {
        setPropertyValue(val)
        getUnitByProperty(selectedCompany?.value, val?.value)
    }
    //on unit change
    const onUnitChange = (val) => {
        setUnitValue(val)
        getUtilityReadingList(0, 10, selectedCompany?.value, propertyValue?.value, val?.value,"",filterData)
    }
    //handle search 
    const handleSearch = (value) => {
        setSearchText(value)
        debounce(() => searchTableFunction(value), 800)
    }

    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getUtilityReadingList(0, 10, selectedCompany?.value, propertyValue?.value, unitValue?.value, e,filterData)
    }
    //handle View
    const handleIcon = (type, data) => {
        setUtilityDetails(data)
        if (type === "post") {
            setShowDialog(true)
        }
        else if (type === "view") {
            getUtilityGraphData(data)
            getUtilityInfoDetails(data)
            const tempList = tableData?.list?.map(_ => {
                return { ..._, selectedRow: _?.id === data?.id ?? false }
            })
            setTableData({ ...tableData, list: tempList })
        }
        else if (type === "active") {
            InactiveUtility(data)
        }

    }
    //post to manage invoice
    const PostToManageInvoice = () => {
        const payload = {
            "company_id": utilityDetails?.company_id,
            "unit_id": utilityDetails?.unit?.id,
            "property_id": utilityDetails?.unit?.property?.id,
            "total_amount": utilityDetails?.bill_total,
            "name": utilityDetails?.utility_name,
            "unit_utility_reading_id": utilityDetails?.id,
            "currency_id": utilityDetails?.currency?.id,
            "break_up": [
                {
                    "utility_name": utilityDetails?.utility_name,
                    "rate": utilityDetails?.bill_total,
                    "units_consumed": utilityDetails?.units_consumed
                }
            ],
            "period_from": utilityDetails?.usage_period_from,
            "period_to": utilityDetails?.usage_period_to
        }

        NetworkCall(
            `${config.api_url}/utility_readings/post_utility_to_managed`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setShowDialog(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Posted to Manage Invoice Successfully"),
            });
            getUtilityReadingList(0, 10, selectedCompany?.value, propertyValue?.value, unitValue?.value)
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went wrong"),
            });
        })
    }
    //Inactivate Utility
    const InactiveUtility = (data) => {
        const payload = {
            "unit_utility_reading_id": data?.id,
        }

        NetworkCall(
            `${config.api_url}/utility_readings/inactive_unitutil_reading`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("In-activated Successfully"),
            });
            getUtilityReadingList(0, 10, selectedCompany?.value, propertyValue?.value, unitValue?.value)
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went wrong"),
            });
        })
    }
    //get utilityGraph data
    const getUtilityGraphData = (utilityData) => {
        const payload = {
            "unit_id": utilityData?.unit?.id,
            "utility_name": utilityData?.utility_name,
            "reading_count": utilityData?.reading_count,
            "meter_no":utilityData?.unit_util?.meter_serial_no
        }

        NetworkCall(
            `${config.api_url}/utility_readings/utility_reading_graph`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const result = {
                "labels": response?.data?.data?.labels,
                "datasets": [
                    {
                        label: response?.data?.data?.datasets?.[0]?.label,
                        data: response?.data?.data?.datasets?.[0]?.data,
                        fill: true,
                        backgroundColor: "#DDFFEA",
                        borderColor: "#82E0A5"
                    }
                ]
            }

            setUtilityGraphData(result)
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went wrong"),
            });
            setUtilityGraphData({
                "labels": [],
                "datasets": [
                    {
                        label: "ssjsj",
                        data: [],
                        fill: true,
                        backgroundColor: "rgba(75,192,192,0.2)",
                        borderColor: "rgba(75,192,192,1)"
                    }
                ]
            })
        })
    }
    //getUtilityInfoDetails
    const getUtilityInfoDetails = (utilityData) => {
        const payload = {
            "unit_utility_reading_id": utilityData?.id
        }

        NetworkCall(
            `${config.api_url}/utility_readings/unit_util_reading_invoice`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setUtilityInvoiceDetails(response?.data?.data?.[0])

        }).catch((err) => {
            console.log(err)
        })
    }

    const maualResponseTenants = (val) => {
        let result = val?.units?.map((val) => {
            return {
                value: val?.id,
                label: val?.name
            }
        })

        return result;
    }
    const draweropen = () => {
        setDrawer(true);
    };
    const drawerclose = () => {
        setDrawer(false);
    };
    const onApplyFilter = (value) => {
        setFilterData(value)
        getUtilityReadingList(0, 10, selectedCompany?.value, propertyValue?.value, unitValue?.value, "", value)
    }
    React.useEffect(() => {
        if (selectedCompany?.value) {
            getPropertyDropdown(selectedCompany?.value, true)
        }
        //eslint-disable-next-line
    }, [selectedCompany, propertyValue])
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getUtilityReadingList(offset, 10, selectedCompany?.value, propertyValue?.value, unitValue?.value, "")
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getUtilityReadingList(0, limit, selectedCompany?.value, propertyValue?.value, unitValue?.value, "")
    }
        const render = () => {
        return (
            <Box>
                <Subheader title={t("utilityReadings")} hideBackButton select options={companyList} value={selectedCompany}
                    onchange={(e) =>
                        handleCompanyChange(e)
                    }
                    onPropertyChange={(e) => {
                        onPropertyChange(e)
                    }}
                    onUnitChange={(e) => {
                        onUnitChange(e)
                    }}
                    selectProperty
                    propertyOptions={propertyData}
                    propertyValue={propertyValue}
                    selectUnit
                    unitData={unitData}
                    unitValue={unitValue}
                    isPaginateUnit={true}
                    unit_change={JSON.stringify(propertyValue)}
                    loadOptionsUnit={(search, array, handleLoading) =>
                        loadOptionsApis(
                            `/unit/property_units`,
                            {
                                "company_id": selectedCompany?.value,
                                "property_id": propertyValue?.value,
                            },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            maualResponseTenants,
                            {
                                all: true,
                                all_label: "All Units"
                            }
                        )
                    }

                />
                <Box className={classes.root}>
                    {
                        loading ?
                            <NewLoader minusHeight="100px" />
                            :
                            <Grid container spacing={1}>
                                <Grid item sm={tableData?.list?.length > 0 ? 7 : 12} md={tableData?.list?.length > 0 ? 8 : 12}>
                                    <UtilityReadingsTable
                                        t={t}
                                        tableData={tableData}
                                        handleIcon={handleIcon}
                                        handleSearch={handleSearch}
                                        utilityData={utilityDetails}
                                        searchText={searchText}
                                        filterData={filterData}
                                        setFilterData={setFilterData}
                                        drawer={drawer}
                                        setDrawer={setDrawer}
                                        drawerOpen={draweropen}
                                        drawerClose={drawerclose}
                                        onApplyFilter={onApplyFilter}
                                        unitValue={unitValue}
                                        propertyValue={propertyValue}
                                        selectedCompany={selectedCompany}
                                        handlePagination={handlePagination}
                                        handleChangeLimit={handleChangeLimit}
                                        page={page}
                                        limit={limit}
                                    />
                                </Grid>
                                {tableData?.list?.length > 0 &&
                                    <Grid item sm={5} md={4}>
                                        <UtilityInfo t={t} utilityData={utilityDetails} utilityGraphData={utilityGraphData} utilityInvoiceDetails={utilityInvoiceDetails}
                                        />
                                    </Grid>
                                }
                            </Grid>
                    }
                </Box>
                <AlertDialog open={showDialog}
                    onClose={() => handleClose()}
                    isnotTitle={true}
                    medium
                    component={
                        <Box className={classes.reqblock} p={1} alignItems="center">
                            <Box display={"flex"} justifyContent="end" onClick={() => handleClose()}>
                                <Close className={classes.closeicon} />
                            </Box>
                            <Grid container mt={5}>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={8}>
                                    <Typography className={classes.q1hdr}>
                                        {t("Are you sure you want to post to managed invoice?")}
                                    </Typography>
                                    <Box mt={1}>
                                        <Typography className={classes.q1subhdr}>
                                            {t("The Process Cannot be reverted once done")}
                                        </Typography>
                                    </Box>
                                    <Stack mt={5} ml={2} direction="row" spacing={1}>
                                        <Button variant="contained" fullWidth className={classes.yesbtn} onClick={() => PostToManageInvoice()}>{t("Yes")}</Button>
                                        <Button variant="outlined" fullWidth className={classes.nobtn} onClick={() => handleClose()}>{t("No")}</Button>

                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                        // <Box>
                        // <ReceiptView/>
                        // </Box>
                    }
                />
            </Box>
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>

    )
}
export default withNamespaces("utilities")(UtilityReadings)