import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../../utils";
export const useStylesOwner = makeStyles((theme) => ({
    imagebox: {
        textAlign: 'center',
        padding: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: theme.palette.borderRadius,
    },
    inputFileStyle: {
        display: 'none'
    },
    profileImageErrorTextStyle: {
        fontSize:"0.75rem",
        color: "red",
    },
    imageViewerImageStyle: {
        objectFit: 'cover', width: '444px'
    },
    imageViewerWrapperStyle: {
        display: 'flex'
    },
    imagebox1: {
        padding: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: theme.palette.borderRadius,
    },
    profileDetailCardStyle: {
        minHeight: "242px",
        padding: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: theme.palette.borderRadius,
    },
    addressDetailCardStyle: {
        padding: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: theme.palette.borderRadius,
        marginTop: '24px',
        position: 'relative'
    },
    contactAndOtherDetailCardStyle: {
        padding: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: theme.palette.borderRadius,
        marginTop: '24px',
    },
    socialProfileDetailCardStyle: {
        padding: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: theme.palette.borderRadius,
        marginTop: '24px',
    },
    idDetailCardStyle: {
        padding: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: theme.palette.borderRadius,
        marginTop: '24px',
    },
    bankDetailCardStyle: {
        padding: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: theme.palette.borderRadius,
    },
    button: {
        padding: '8px 16px',
        borderRadius: theme.palette.borderRadius,
        cursor: 'pointer',
        border: '1px solid #E4E8EE',
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        marginTop: '10px'
    },
    Requirements: {
        padding: "10px",
        textAlign: "center",
        borderRadius: "10px",
        color: "white",
        fontSize:"0.875rem",
        backgroundColor: "#5078E1",
        cursor: "pointer",
    },
    Requirementsqfts: {
        padding: "10px",
        textAlign: "center",
        borderRadius: "10px",
        fontSize:"0.875rem",
        cursor: "pointer",
        border: "1px solid #E4E8EE",
    },
    completedCheckboxStyle: {
        height: "20px",
        width: "20px",
        backgroundColor: "#5078E1",
        border: "1px solid #5078E1",
    },
    checkboxIconStyle: {
        fontSize: "small",
        color: "white",
    },
    checkboxStyle: {
        height: "20px",
        width: "20px",
        backgroundColor: "white",
        border: "1px solid #98A0AC",
    },
    avatar: {
        height: '120px',
        width: '120px',
        margin: '0 auto'
    },
    // title: {
    //   fontSize:"0.75rem",
    //   color: '#4E5A6B',
    //   fontFamily: Bold,
    //   marginBottom: '12px'
    // },
    label: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
    },
    tabListAssests: {
        display: 'flex'
    },
    listText: {
        backgroundColor: '#5078E1',
        width: '120px !important',
        color: '#fff',
        borderRadius: '6px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    listText1: {
        width: '120px !important',
        color: '#98A0AC',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    tabname: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        cursor: 'pointer'
    },
    list: {
        '&.MuiListItem-root': {
            width: '135px !important',
            padding: '0px!important',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            border: '1px solid #E4E8EE',
            cursor: 'pointer'
        }
    },
    next: {
        marginLeft: "10px",
        fontFamily: SemiBold,
        color: "#fff",
        backgroundColor: '#5078E1',
        "&:hover": {
            backgroundColor: '#5078E1',
        },
    },
    Cancel: {
        backgroundColor: "#FFFFFF ",
        color: "#091B29",
        fontSize:"0.875rem",
        fontWeight: 600,
        fontFamily: SemiBold,
        border: '1px solid #E4E8EE',
        "&:hover": {
            backgroundColor: "#FFFFFF ",
        },
    },
    bottombtn: {
        padding: '11px 16px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 16px #00000014',
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #E4E8EE',
        position: 'sticky',
        bottom: '0px',
        width: '100%',
        zIndex: 2,
    },
    removeimg: {
        textDecoration: 'underline',
        color: '#5078E1',
        fontFamily: SemiBold,
        fontSize:"0.75rem",
        marginTop: '6px',
        cursor: 'pointer'
    },
    avatar1: {
        padding: '35px',
        background: '#98A0AC',
        height: '120px',
        width: '120px',
        cursor: 'pointer',
        margin: '0 auto'
    },
    tabtitle: {
        fontSize:"0.75rem",
        color: '#4E5A6B',
        fontFamily: Bold,
    },
    tabtitle1: {
        fontSize:"0.75rem",
        color: '#5078E1',
        fontFamily: Bold,
    },
    subtabtitle: {
        fontSize:"0.75rem",
        color: '#4E5A6B',
        fontFamily: Regular,
    },
    subtabtitle1: {
        fontSize:"0.75rem",
        color: '#5078E1',
        fontFamily: Regular,
    },
    selectBack1: {
        backgroundColor: '#F1F7FF',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    selectBack: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
    },
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 88px)`,
    },

    card: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
    },
    unitImg: {
        height: "120px",
        width: "120px",
        borderRadius: "50%",
        objectFit: "fill",
        display: 'flex',
        alignItems: 'center',

    },
    imgTag: {
        padding: "2px 6px",
        borderRadius: "5px",
        backgroundColor: "#071741",
        color: "white",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        position: "absolute",
        left: "0px",
        right: '0px',
        margin: '0 auto',
        maxWidth: '154px',
        width: '100%',
        bottom: "20px",
        display: "inline",

    },
    imgDiv: {
        position: "relative",
        // borderRight: "1px solid #00000014",
        padding: "4px",
        height: "120px",
        width: "120px",
        borderRadius: "50%",
        objectFit: "fill",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontFamily: Bold
    },
    imgDiv2: {
        padding: "0px 12px"
    },
    heading: {
        color: theme.typography.color.tertiary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        textTransform: "capitalize"
    },
    sub: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        textTransform: "capitalize",
        '& .ql-editor': {
            padding: '0px 0px 16px 0px',
            color: theme.typography.color.primary,
            fontSize:"0.875rem",
            fontFamily: SemiBold,
            textTransform: "capitalize",
        }

    },
    roles: {
        fontSize:"0.875rem",
        color: '#98A0AC',
        fontFamily: SemiBold,
        marginBottom: '2px',
        // marginLeft: '10px'

    },
    addmember: {
        width: '100%',
        padding: "8px",
        height: "40px",
        '&:hover': {
            backgroundColor: '#fff',
        }
    },
    managericon: {
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#E29336',
        justifyContent: 'center',
        padding: '10px 15px'

    },
    // card
    cards: {
        cursor: "pointer",

        backgroundColor: theme.palette.background.paper,
        margin: "auto 4px",
        overflow: "hidden",
    },
    Cardcontent: {
        [theme.breakpoints.up("sm")]: {
            padding: "8px 12px 12px 0px",
        },

        [theme.breakpoints.down("sm")]: {
            padding: "8px 0px",
        },
    },
    title1: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold,
    },
    progress: {
        fontSize:"0.75rem",
        color: "#FFFFFF",
        fontFamily: Bold,
        background: "#78B1FE",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
    },
    approved: {
        fontSize:"0.75rem",
        color: "#FFFFFF",
        fontFamily: Bold,
        background: "#5AC782",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
    },
    delete: {
        fontSize:"0.75rem",
        color: "#FFFFFF",
        fontFamily: Bold,
        background: "red",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
    },
    cancel: {
        fontSize:"0.75rem",
        color: "#FFFFFF",
        fontFamily: Bold,
        background: "#CED3DD",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    sub1: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
    },
    img: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        padding: "2px 8px",
    },
    rejected: {
        fontSize:"0.75rem",
        color: "#FFFFFF",
        fontFamily: Bold,
        background: "#EC903F",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    boxes: {
        flexFlow: 'wrap !important'
    },
    // dialog
    dialog: {
        width: "100%",
        maxWidth: "auto",
        padding: "0 !important",
        "& .MuiPaper-root": {
            width: "100% !important",
            borderRadius: theme.palette.borderRadius,
        },
        "& .MuiDialogContent-root": {
            padding: "0px !important",
            position: 'relative'
        },
    },
    header: {
        border: "1px solid #E4E8EE",
        fontSize:"1rem",
        fontFamily: ExtraBold,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    select: {
        color: '#091B29',
        fontSize:"0.75rem",
        fontFamily: Bold,
        marginLeft: '10px'
    },
    selectBox: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        backgroundColor: '#F5F7FA',
        borderRadius: '20px',
        marginTop: '12px',
        height: '40px',
        cursor: 'pointer'
    },
    avatarcard: {
        height: '30px',
        width: '30px',
        cursor: 'pointer'
    },
    buttoncard: {
        width: '100%',
        color: '#fff',
        fontSize:"0.875rem",
        fontFamily: Bold,
        '&:hover': {
            backgroundColor: '#5078E1',
        }
    },
    createContactRoot: {
        height: `calc(100vh - 120px)`,
        overflow: "auto",
    },
    root2: {
        height: `calc(100vh - 260px)`,
        overflow: "auto"
    },
    box: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "12px"
    },


}));