export const Community_tread = `mutation($id: ID, $data: community_discussionInput) {
      community_discussion(
        where: { id: { eq: $id } }
        update: $data
       
      ) {
        id
      }
    }`;

export const Community_comments = `mutation($id: ID, $data: community_discussion_commentsInput) {
      community_discussion_comments(
        where: { id: { eq: $id } }
        update: $data
        
      ) {
        id
      }
    }`;

export const InsertData = `mutation {
      emergency_master(insert:$data){
        id
      }
    }`;

export const ProfessionInsertData = `mutation($data:[profession_masterInput!]){
      profession_master(insert:$data){
        id
      }
    }`;

export const UpdateEmergeny = `
    mutation($proprtyId: ID, $demo: emergency_masterInputt) {
      emergency_master(
        where: { id: { eq: $proprtyId } }
        update: $data
        
      ) {
        id
      }
    }
    `;

export const UpdateEmergenyProfession = `
    mutation($proprtyId: ID, $demo: profession_masterInput) {
      profession_master(
        where: { id: { eq: $proprtyId } }
        update: $data
        
      ) {
        id
      }
    }
    `;
export const InsertNotification = `
    mutation($insert:[notificationsInput!]) {
 notifications(
    insert: $insert
   
  ) {
    title
    description
    created_at
    created_by
    triggered_at
    triggered_by
    url
    company_id
  }
}`;
export const updateEmergencyContact = `
mutation($id: ID,$data: emergency_masterInput) {
      emergency_master(
        where: { id: { eq: $id } }
        update: $data
       
      ) {
        id
      }
}`
export const updateProfessionmaster = `
mutation($id: ID,$data: profession_masterInput) {
      profession_master(
        where: { id: { eq: $id } }
        update: $data
       
      ) {
        id
      }
}`