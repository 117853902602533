import gql from "graphql-tag";

export const GET_CORRESPONDENCE_OPTIONS = gql`
query GET_CORRESPONDENCE_OPTIONS($clientID: String) {
  correspondences_type_master(
    where: { client: { eq: $clientID }, is_active: { eq: true } }
    order_by: { id: asc }
  ) {
    value: id
    label: name
  }
  correspondences_level_master(
    where: { client: { eq: $clientID }, is_active: { eq: true } }
    order_by: { id: asc }
  ) {
    value: id
    label: name
  }
  correspondences_status_master(
    where: { client: { eq: $clientID }, is_active: { eq: true } }
    order_by: { id: asc }
  ) {
    value: id
    label: name
  }
}`
