
export const retrunOppertunity=({company,opportunity,state})=>{
    return{
      is_existing:state?.opportunity?.is_exist ?? false,
          primary:state?.primary,
        company_id:company?.id,
        custom_date: company?.billing_day??null,
        bill_cycle_method: company?.billing_cycle,
        bill_cycle_date:opportunity?.duration?.fromDate?.getDate() > 28 ? "As per the Agreement Date" : company?.billing_cycle_date,
        purpose: {
          label: opportunity?.purpose,
          value: opportunity?.purpose
        },
        revenue_type: {
          label: opportunity?.revenue_type,
          value: opportunity?.revenue_type
        },
        payment_period: opportunity?.revenue_type === "Sale" ? "" : {
          value: company?.payment_period,
          label: company?.payment_period,
        },
        type: opportunity?.type,
        units: opportunity?.unit,
        company:company?.id,
        name: `${opportunity?.contact?.first_name ?? ""} ${opportunity?.contact?.last_name ?? ""}`,
        email_id: opportunity?.contact?.email_id,
        mobile: {
          mobile: opportunity?.contact?.mobile_no ?? null,
          mobile_code: opportunity?.contact?.mobile_no_country_code ?? null,
        },
        alter_mobile: null,
        telephone: null,
        fax: null,
        image_url: opportunity?.contact?.image_url ?? null,
        existing: true,
        address: {
          street_1: opportunity?.contact?.street_1 ?? "",
          street_2: opportunity?.contact?.street_2 ?? "",
          street_3: opportunity?.contact?.street_3 ?? "",
          city: opportunity?.contact?.city ?? "",
          district: opportunity?.contact?.district ?? "",
          opportunity: opportunity?.contact?.opportunity ?? "",
  
          zipcode: opportunity?.contact?.zipcode ?? "",
        },
        contact:state?.opportunity?.contact

    }


}