import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';
import ImageViewer from "react-simple-image-viewer";
// import MyGoogleMap from '../../../components/interactivemap/myGoogleMaps';
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { unitStyles } from "../style";
import { concat_string, convertTimeUtcToZoneCalander, rich_text_replacer_condition, textReplacer } from "../../../utils";
import { useState } from 'react';
import { returnValue } from '../../companyCreation/utils';

const CustomtoolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "white",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "white",
        padding: "12px",
        color: "#4E5A6B",
        border: "1px solid #E4E8EE"
    },
}));

export const UnitDetail = (props) => {
    const { t } = (props)
    const [isSeeMore, setIsSeeMore] = useState(true)
    const classes = unitStyles({ ...props, isSeeMore })
    const size = useWindowDimensions()
    const seeMoreDivRef = React.useRef(null);
    const [seeMoreHeight, setSeeMoreHeight] = React.useState(null)
    // const { data } = props;
    // const { addressByID } = data ?? {};
    const buildupAreades = t("The area of an apartment that can be covered by a carpet or the net usable area is known as the carpet area. It is the distance between the inner walls. The carpet area would include the areas of the bedroom, living room, kitchen, bathrooms, balconies & staircases within the house/flat. It does not include the external and internal walls, terraces, common areas, lifts, corridors, utility ducts, etc")
    const superbuilup = t("Super Built-up area is referred to as the ‘saleable area’ and homebuyers are usually charged based on this value. It is the sum of the exclusive built-up area, and common areas including the lift, corridors, staircase, clubhouse, and other amenities.")
    // const GoogleMap = React.useCallback(() => {
    //     return (
    //         <MyGoogleMap
    //             lat={addressByID?.latitude}
    //             lng={addressByID?.longitude}
    //             center={{
    //                 lat: addressByID?.latitude,
    //                 lng: addressByID?.longitude
    //             }}
    //             zoom={13}
    //             readonly

    //         />
    //     )
    // }, [addressByID])
    React.useEffect(() => {
        setSeeMoreHeight(seeMoreDivRef?.current?.clientHeight ?? 0)
    }, [seeMoreDivRef?.current?.clientHeight, props]);
    return (
        <div style={{ height: size?.height - 210, overflow: "auto", padding: "4px", margin: "-4px" }}>
            {/*unit detials first card */}
            <Grid container className={classes.card} style={{ padding: "16px 16px 16px 0px", }}>
                <Grid item md={2} lg={2}>
                    <div className={classes.imgDiv}>
                        <img src={props?.data?.logo ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                            onClick={() => props?.openImageViewer([props?.assets?.[0]?.url])}

                        />
                        <Typography className={classes.imgTag}>&nbsp;&nbsp;{props?.data?.unit_no}&nbsp;&nbsp;</Typography>
                    </div>
                    {props?.isViewerOpen && (
                        <ImageViewer
                            src={props?.currentImage}
                            currentIndex={0}
                            onClose={props?.closeImageViewer}
                            disableScroll={false}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.9)",
                            }}
                            closeOnClickOutside={true}
                        />
                    )}

                </Grid>
                <Grid item md={10} lg={10} className={classes.imgDiv2}>
                    <Typography className={classes.title}>{t("UNIT DETAILS")}</Typography>
                    <Grid container>
                        <Grid item md={12} lg={9}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="8px">
                                <Box>
                                    <Typography className={classes.heading}>{t("Company Name")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.property?.company?.name ?? "-"}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>{t("Unit Name")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.name ?? "-"}</Typography>
                                </Box>
                                {/* <Box>
                                    <Typography className={classes.heading}>Occupancy Status</Typography>
                                    <Typography className={classes.sub}>{props?.data?.status ?? "-"}</Typography>
                                </Box> */}
                                <Box>
                                    <Typography className={classes.heading}>{t("Execution Status")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.execution_status ?? "-"}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>{t("Status")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.is_active ? "Active" : "In Active"}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    {props?.data?.description
                        ? <>
                            <Grid container marginTop="14px">
                                <Grid item>
                                    <Typography className={classes.heading}>Unit Description</Typography>
                                </Grid>
                            </Grid>
                            <Grid ref={seeMoreDivRef} container className={classes.descriptionDiv}>
                                <Grid item>
                                    {props?.data?.description !== "<p><br></p>"
                                        ? <Typography
                                            dangerouslySetInnerHTML={{
                                                __html: returnValue(textReplacer({
                                                    text: props?.data?.description,
                                                    condition: rich_text_replacer_condition,
                                                }), "-")
                                            }} />
                                        : <Typography className={classes.sub}>{"-"}</Typography>}
                                </Grid>
                            </Grid>
                            {seeMoreHeight > 199 &&
                                <div
                                    className={classes.seeMore}
                                    onClick={() => setIsSeeMore(!isSeeMore)}>
                                    {isSeeMore ? "See More" : "See Less"}
                                </div>
                            }
                        </>
                        : <Typography className={classes.sub}>{"-"}</Typography>
                    }
                </Grid>
                <Grid item md={0} lg={4.5} />
            </Grid>
            <br />
            {/* owner details */}
            <Grid container className={classes.card} style={{ padding: "16px 16px 16px 0px", }}>
                <Grid item md={2} lg={2} className={classes.imgDiv}>
                    <img src={props?.data?.ownerByID?.contact?.image_url ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                        onClick={() => props?.openImageViewer([props?.data?.ownerByID?.contact?.image_url])}
                    />
                    {props?.isViewerOpen && (
                        <ImageViewer
                            src={props?.currentImage}
                            currentIndex={0}
                            onClose={props?.closeImageViewer}
                            disableScroll={false}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.9)",
                            }}
                            closeOnClickOutside={true}
                        />
                    )}

                </Grid>
                <Grid item md={10} lg={10} className={classes.imgDiv2}>
                    <Typography className={classes.title}>{t("OWNER DETAILS")}</Typography>
                    <Grid container>
                        <Grid item md={12} lg={9}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="8px">
                                <Box>
                                    <Typography className={classes.heading}>{t("Name")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.ownerByID?.contact?.first_name ?? "-"}</Typography>
                                </Box>


                                <Box>
                                    <Typography className={classes.heading}>{t("Mobile No")}</Typography>
                                    <Typography className={classes.sub}>
                                        {(props?.data?.ownerByID?.contact?.mobile_no_country_code ?
                                            (props?.data?.ownerByID?.contact?.mobile_no_country_code + " - ") : "") +
                                            (props?.data?.ownerByID?.contact?.mobile_no ?? "-")}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>{t("Email Id")}</Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.ownerByID?.contact?.email_id ?? "-"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>


                </Grid>
                <Grid item md={0} lg={4.5} />
            </Grid>
            <br />
            {/*unit detials second card */}
            <Grid container className={classes.card}>
                <Grid item md={12}>
                    <Grid container>
                        {/* <Grid item xs={2}>
                            <Typography className={classes.heading}>{t("Property Hierarchy")}</Typography>
                            <Typography className={classes.sub}>{props?.data?.property?.property_hierarchyByID?.label ?? "-"}</Typography>
                        </Grid> */}
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>{t("Property Type")}</Typography>
                            <Typography className={classes.sub}>{props?.data?.property?.property_groupByID?.group_name ?? "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>{t("Revenue Type")}</Typography>
                            <Typography className={classes.sub}>{props?.data?.revenue_type ?? "-"}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>{t("Purpose")}</Typography>
                            <Typography className={classes.sub}>{props?.data?.unit_purpose ?? "-"}</Typography>
                        </Grid>

                        {/* <Grid item xs={2}>
                            <Typography className={classes.heading}>{t("Listing")}</Typography>
                            <Typography className={classes.sub}>{props?.data?.listing_status ?? "-"}</Typography>
                        </Grid> */}
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>{t("Payment Period")}</Typography>
                            <Typography className={classes.sub}>{props?.data?.payment_period ?? "-"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            {/*unit detials third card */}
            <Grid container className={classes.card}>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Seat Count")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.is_seat ? props?.data?.seat_count : "-"}</Typography>
                            </Box>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Unit Category")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.unit_categoryByID?.label ?? "-"}</Typography>
                            </Box>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Measurement Unit")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.uomByID?.label ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Parent Unit")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.parent_unit?.name ?? "-"}</Typography>
                            </Box>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>
                                    {"Super Built-Up Area"}
                                    <CustomtoolTip title={`${superbuilup}`}>
                                        <InfoOutlinedIcon sx={{ fontSize: "1rem", marginLeft: "4px" }} />
                                    </CustomtoolTip>
                                </Typography>
                                <Typography className={classes.sub}>
                                    {props?.data?.total_area ?? "-"}
                                    <span className={classes.matric}>
                                        {props?.data?.uomByID?.label ?? ""}
                                    </span>
                                </Typography>
                            </Box>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Furnishing Type")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.furnishing ?? "-"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Year Built")}</Typography>
                                <Typography className={classes.sub}>
                                    &#x202a;{props?.data?.year_built ? convertTimeUtcToZoneCalander(props?.data?.year_built) : "-"}&#x202c;
                                </Typography>
                            </Box>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>
                                    {"Carpet Area"}
                                    <CustomtoolTip title={`${buildupAreades}`}>
                                        <InfoOutlinedIcon sx={{ fontSize: "1rem", marginLeft: "4px" }} />
                                    </CustomtoolTip>
                                </Typography>
                                <Typography className={classes.sub}>
                                    {props?.data?.carpet_area ?? "-"}
                                    <span className={classes.matric}>
                                        {props?.data?.uomByID?.label ?? ""}
                                    </span>
                                </Typography>
                            </Box>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Rooms")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.total_bed_rooms ?? "0"}</Typography>
                            </Box>

                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Handover Date")}</Typography>
                                <Typography className={classes.sub}>
                                    &#x202a;{props?.data?.planned_hand_over_date ? convertTimeUtcToZoneCalander(props?.data?.planned_hand_over_date) : "-"}&#x202c;
                                </Typography>

                            </Box>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Total Balcony Size")}</Typography>
                                <Typography className={classes.sub}>
                                    {props?.data?.balcony_area ?? "-"}
                                    <span className={classes.matric}>
                                        {props?.data?.uomByID?.label ?? ""}
                                    </span>
                                </Typography>
                            </Box>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Baths")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.total_baths ?? "0"}</Typography>
                            </Box>


                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Orientation")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.orientation ?? "-"}</Typography>
                            </Box>
                            {/* <Box marginTop="8px">
                                <Typography className={classes.heading}>&nbsp;</Typography>
                                <Typography className={classes.sub}>&nbsp;</Typography>
                            </Box> */}
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>Balconies</Typography>
                                <Typography className={classes.sub}>{props?.data?.balconies ?? 0}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Pets Allowed")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.is_pet_allowed ? t("Yes") : t("No")}</Typography>
                            </Box>
                            <Box marginTop="16px">
                                <Typography className={classes.heading}>{t("Unit Type")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.unit_type?.label ?? "-"}</Typography>
                            </Box>
                            {/* <Box marginTop="8px">
                                <Typography className={classes.heading}>&nbsp;</Typography>
                                <Typography className={classes.sub}>&nbsp;</Typography>
                            </Box> */}
                            {props?.data?.is_pet_allowed && <Box marginTop="8px">
                                <Typography className={classes.heading}>{t("Pet Policy")}</Typography>
                                <Typography className={classes.sub}>{props?.data?.pet_policy ?? "-"}</Typography>
                            </Box>}

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />


            {/*unit detials fourth card */}
            <div className={classes.card}>
                <Grid container spacing={2}>
                    {/* <Grid item xs={5}>
                        <div className={classes.map}>
                            <GoogleMap />
                        </div>
                    </Grid> */}
                    {/* when map component is needed plz use below commented grid item */}
                    {/* <Grid item xs={3}> */}
                    <Grid item xs={6}>
                        <div className={classes.addressRoot}>
                            <Stack direction="row" >
                                <img src='/images/loc.svg' alt='' />
                                <Typography className={classes.title}>&nbsp;{t("Address")}</Typography>
                            </Stack>
                            <Box height="12px" />
                            <Typography className={classes.sub}>
                                {concat_string(
                                    {
                                        door_no: props?.data?.addressByID?.door_no ?? "",
                                        street_1: props?.data?.addressByID?.street_1 ?? "",
                                        street_2: props?.data?.addressByID?.street_2 ?? "",
                                        landmark: props?.data?.addressByID?.landmark ?? "",
                                        area: props?.data?.addressByID?.area ?? "",
                                        city: props?.data?.addressByID?.city ?? "",
                                        state: props?.data?.addressByID?.state ?? "",
                                        country: props?.data?.addressByID?.country ?? "",
                                        zipcode: props?.data?.addressByID?.zipcode ?? "",
                                    },
                                    [
                                        "door_no", "street_1", "street_2",
                                        "landmark", "area", "city", "state",
                                        "country", "zipcode",
                                    ]
                                )}
                            </Typography>
                            <Box height="16px" />
                            <Stack direction="row" >
                                <Typography className={classes.heading}>{t("Latitude")} : </Typography>
                                <Typography className={classes.sub1} >&nbsp;{props?.data?.addressByID?.latitude}</Typography>
                            </Stack>
                            <Box height="12px" />
                            <Stack direction="row" >
                                <Typography className={classes.heading}>{t("Longitude")} : </Typography>
                                <Typography className={classes.sub1} >&nbsp;{props?.data?.addressByID?.longitude}</Typography>
                            </Stack>
                        </div>
                    </Grid>
                    {/* when map component is needed plz use below commented grid item */}
                    {/* <Grid item xs={4}> */}
                    <Grid item xs={6}>
                        <div className={classes.addressRoot}>
                            <Stack direction="row" >
                                <img src='/images/Group 7015.svg' alt='' />
                                <Typography className={classes.title}>&nbsp;{t("Contact & Other Information")}</Typography>
                            </Stack>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box marginTop="18px">
                                        <Typography className={classes.heading}>{t("Business Phone")} :</Typography>
                                        <Typography className={classes.sub}>{props?.data?.property?.business_phone_country_code} {props?.data?.property?.business_phone}</Typography>
                                    </Box>
                                    <Box marginTop="12px">
                                        <Typography className={classes.heading}>{t("Website")} :</Typography>
                                        <Typography className={classes.sub}>{props?.data?.property?.website}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box marginTop="18px">
                                        <Typography className={classes.heading}>{t("Mobile Phone")} :</Typography>
                                        <Typography className={classes.sub}>{props?.data?.property?.mobile_country_code} {props?.data?.property?.mobile_phone}</Typography>
                                    </Box>
                                    <Box marginTop="12px">
                                        <Typography className={classes.heading}>{t("Email Address")} :</Typography>
                                        <Typography className={classes.sub}>{props?.data?.property?.email}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div >
    )
}