import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import styled from '@mui/material/styles/styled';
import React, { useContext, useState } from 'react';
import { SelectBox, TextBox, ToggleButtonComponent } from '../../../components';
import { AlertContext } from '../../../contexts';
import { UOM_MASTER } from '../../../graphql/queries';
import { AlertProps, enumName, enum_types, SemiBold } from '../../../utils';
import { loadOptions } from '../../../utils/asyncPaginateLoadOptions';
import { useStylesCreation } from '../../propertcreation/createproperty/style';
import { GET_ALL_CURRENCIES, networkCallback } from '../utils';
import { Bold } from '../../../utils';
const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize:"0.75rem",
    fontFamily: Bold,
    color: theme.typography.color.secondary,
    marginBottom: theme.spacing(1),

}));

const Label = styled(Typography)(({ theme }) => ({
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    fontSize:"0.875rem",
    marginBottom: "5px",

}));


const YesNOOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

const gracePeriodOptions = [
    { label: "None", value: 0 },
    { label: "15 Days", value: 15 },
    { label: "30 Days", value: 30 },
    { label: "60 Days", value: 60 },
    { label: "90 Days", value: 90 },
    // { label: "Custom", value: "Custom" },
];

const buttonStyle = {
    height: "45px",
    minWidth: "45px",
    borderColor: "#E4E8EE",
    borderRadius: 1,
    backgroundColor: "#fff",
}

export const CompanyConfiguration = (props) => {

    const {
        data = {},
        updateState = null,
        error = {},
        countryID = "",
        onChangeParticularState
    } = props;

    const classes = useStylesCreation();

    const [loading, setLoading] = useState(false);
    const alert = useContext(AlertContext);

    const [toggleView, setToggleView] = useState([]);

    const onChangeToggleView = (index) => {
        if (toggleView.includes(index)) {
            setToggleView(toggleView?.filter(_ => _ !== index));
            return
        }
        setToggleView([...toggleView, index])
    }

    const {
        initialConfig = null,
        pricing = null,
        paymentGatewayConfiguration = null
    } = data;

    const loadOptionData = async (search, array, type) => {
        setLoading(type);
        let result;
        let enumTypes = [
            enum_types.area_metric_type, enum_types.pricing_type, enum_types.company_payment_period,
            enum_types.company_lease_period, enum_types.company_payment_gateway, enum_types.payment_terms
        ]

        if (type === "currency") {
            if (countryID) {
                return loadOptions(search, array, setLoading, GET_ALL_CURRENCIES, 'currency_master', { countryID });
            }
            else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Please select country's company in company profile tab",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                setLoading(null);
                return {
                    options: [],
                    hasMore: false
                }
            }
        }
        else if (enumTypes?.includes(type)) {
            result = await networkCallback({ enumName: [type] }, "enum", setLoading);
            if (!result) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something went wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return {
                    options: [],
                    hasMore: false
                }
            }
            return {
                options: result?.[type] ?? [],
                hasMore: false
            }
        }
        else if (type === "uom_master") {
            return loadOptions(search, array, setLoading, UOM_MASTER, 'uom_master', { isActive: true });

        }
        else {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onChangePaymentGatway = (value) => {
        if (value?.value === "Solution Provder") {
            updateState('paymentGatewayConfiguration', 'paymentGateway', value)
        }
        else {
            let updateState = {
                ...data,
                paymentGatewayConfiguration: {
                    ...data.paymentGatewayConfiguration,
                    paymentGateway: value,
                    loginName: "",
                    loginPassword: "",
                    loginToken: "",
                    paymentProviderName: "",
                    url: "",
                }
            }
            onChangeParticularState('companyConfiguration', updateState);
        }
    }

    return (
        <div>
            <Box className={classes.imagebox1}>
                <CustomTypography>Initial Config</CustomTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3}>
                        <SelectBox
                            isRequired={true}
                            label={'Base Currency'}
                            placeholder={'Select Currency'}
                            // options={[]}
                            loading={loading === "currency"}
                            loadOptions={(search, array) => loadOptionData(search, array, "currency")}
                            isPaginate
                            debounceTimeout={800}
                            value={initialConfig?.currency ?? null}
                            onChange={(value) => updateState('initialConfig', 'currency', value)}
                            isError={error?.initialConfig?.currency?.error ?? false}
                            errorMessage={error?.initialConfig?.currency?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <SelectBox
                            isRequired={true}
                            label={'Default UOM'}
                            placeholder={'Select UOM'}
                            // options={[]}
                            loadOptions={(search, array) => loadOptionData(search, array, "uom_master")}
                            isPaginate
                            debounceTimeout={800}
                            loading={loading === "uom_master"}
                            isSearchable={false}
                            value={initialConfig?.uom ?? null}
                            onChange={(value) => updateState('initialConfig', 'uom', value)}
                            isError={error?.initialConfig?.uom?.error ?? false}
                            errorMessage={error?.initialConfig?.uom?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <SelectBox
                            isRequired={true}
                            label={'Lease Period'}
                            placeholder={'Select Lease Period'}
                            // options={[]}
                            loadOptions={(search, array) => loadOptionData(search, array, "company_lease_period")}
                            isPaginate
                            isSearchable={false}
                            debounceTimeout={800}
                            loading={loading === "company_lease_period"}
                            value={initialConfig?.leasePeriod ?? null}
                            onChange={(value) => updateState('initialConfig', 'leasePeriod', value)}
                            isError={error?.initialConfig?.leasePeriod?.error ?? false}
                            errorMessage={error?.initialConfig?.leasePeriod?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Label
                            variant="body1"
                            color="textsecondary"
                            align="left"
                        // noWrap
                        >
                            {"Show Refer & Win"}
                            <Typography component="span" color={"error"} sx={{ ml: 1 / 2, fontSize:"0.75rem" }}>
                                *
                            </Typography>
                        </Label>
                        <ToggleButtonComponent
                            options={YesNOOptions}
                            value={initialConfig?.referAndWin ?? []}
                            onChange={(value) => updateState('initialConfig', 'referAndWin', value)}
                            isMulti={false}
                            fullWidth={false}
                            buttonStyle={buttonStyle}
                            isError={error?.initialConfig?.referAndWin?.error ?? false}
                            errorMessage={error?.initialConfig?.referAndWin?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Label
                            variant="body1"
                            color="textsecondary"
                            align="left"
                            gutterBottom
                        // noWrap
                        >
                            {"Show promotional banners"}
                        </Label>
                        <ToggleButtonComponent
                            options={YesNOOptions}
                            value={initialConfig?.promotionalBanners ?? []}
                            onChange={(value) => updateState('initialConfig', 'promotionalBanners', value)}
                            isMulti={false}
                            fullWidth={false}
                            buttonStyle={buttonStyle}
                            isError={error?.initialConfig?.promotionalBanners?.error ?? false}
                            errorMessage={error?.initialConfig?.promotionalBanners?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Label
                            variant="body1"
                            color="textsecondary"
                            align="left"
                            gutterBottom
                        // noWrap
                        >
                            {"Grace Period"}
                            <Typography component="span" color={"error"} sx={{ ml: 1 / 2, fontSize:"0.75rem" }}>
                                *
                            </Typography>
                        </Label>
                        <ToggleButtonComponent
                            options={gracePeriodOptions}
                            value={initialConfig?.gracePeriod ?? ''}
                            onChange={(value) => updateState('initialConfig', 'gracePeriod', value)}
                            isMulti={false}
                            fullWidth={false}
                            buttonStyle={buttonStyle}
                            isError={error?.initialConfig?.gracePeriod?.error ?? false}
                            errorMessage={error?.initialConfig?.gracePeriod?.errorMsg ?? ''}
                            custom={true}
                            valueType={"number"}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                <CustomTypography>Pricing</CustomTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3}>
                        <SelectBox
                            isRequired={true}
                            label={'Default Payment Period'}
                            placeholder={'Select Payment Period'}
                            // options={[]}
                            loadOptions={(search, array) => loadOptionData(search, array, enum_types.company_payment_period)}
                            isPaginate
                            isSearchable={false}
                            debounceTimeout={800}
                            loading={loading === enum_types.company_payment_period}
                            value={pricing?.paymentPeriod ?? null}
                            onChange={(value) => updateState('pricing', 'paymentPeriod', value)}
                            isError={error?.pricing?.paymentPeriod?.error ?? false}
                            errorMessage={error?.pricing?.paymentPeriod?.errorMsg}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={1.5}>
                        <Label
                            variant="body1"
                            color="textsecondary"
                            align="left"
                            gutterBottom
                        // noWrap
                        >
                            {"Enable Promotional Pricing Options"}
                            <Typography component="span" color={"error"} sx={{ ml: 1 / 2, fontSize:"0.75rem" }}>
                                *
                            </Typography>
                        </Label>
                        <ToggleButtonComponent
                            options={YesNOOptions}
                            value={pricing?.priceOverride ?? []}
                            onChange={(value) => updateState('pricing', 'priceOverride', value)}
                            isMulti={false}
                            fullWidth={false}
                            buttonStyle={buttonStyle}
                            isError={error?.pricing?.priceOverride?.error ?? false}
                            errorMessage={error?.pricing?.priceOverride?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={1.5}>
                        <Label
                            variant="body1"
                            color="textsecondary"
                            align="left"
                            gutterBottom
                        // noWrap
                        >
                            {"Short Term Pricing"}
                            <Typography component="span" color={"error"} sx={{ ml: 1 / 2, fontSize:"0.75rem" }}>
                                *
                            </Typography>
                        </Label>
                        <ToggleButtonComponent
                            options={YesNOOptions}
                            value={pricing?.shortTermPricing ?? []}
                            onChange={(value) => updateState('pricing', 'shortTermPricing', value)}
                            isMulti={false}
                            fullWidth={false}
                            buttonStyle={buttonStyle}
                            isError={error?.pricing?.shortTermPricing?.error ?? false}
                            errorMessage={error?.pricing?.shortTermPricing?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <SelectBox
                            isRequired={true}
                            label={'Payment Terms'}
                            placeholder={'Select Term'}
                            // options={[]}
                            loadOptions={(search, array) => loadOptionData(search, array, enum_types.payment_terms)}
                            isPaginate
                            debounceTimeout={800}
                            loading={loading === enum_types.payment_terms}
                            isSearchable={false}
                            value={pricing?.paymentTerm ?? null}
                            onChange={(value) => updateState('pricing', 'paymentTerm', value)}
                            isError={error?.pricing?.paymentTerm?.error ?? false}
                            errorMessage={error?.pricing?.paymentTerm?.errorMsg}
                        />
                    </Grid>
                    {pricing?.paymentTerm?.value === enumName.userDefined && <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            label="Payment Term Value"
                            placeholder="Enter Value"
                            value={pricing?.paymentTermValue ?? ""}
                            onChange={(e) => updateState("pricing", "paymentTermValue", e.target.value)}
                            isError={error?.pricing?.paymentTermValue?.error ?? false}
                            errorMessage={error?.pricing?.paymentTermValue?.errorMsg}
                        />
                    </Grid>}
                </Grid>
            </Box>
            <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                <CustomTypography>Payment Gateway Configuration</CustomTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <SelectBox
                            isRequired={true}
                            label={'Payment Gateway'}
                            placeholder={'Select Payment Gateway'}
                            // options={[]}
                            loadOptions={(search, array) => loadOptionData(search, array, enum_types.company_payment_gateway)}
                            isPaginate
                            debounceTimeout={800}
                            loading={loading === enum_types.company_payment_gateway}
                            isSearchable={false}
                            value={paymentGatewayConfiguration?.paymentGateway ?? null}
                            onChange={(value) => onChangePaymentGatway(value)}
                            isError={error?.paymentGatewayConfiguration?.paymentGateway?.error ?? false}
                            errorMessage={error?.paymentGatewayConfiguration?.paymentGateway?.errorMsg}
                        />
                    </Grid>
                    {
                        paymentGatewayConfiguration?.paymentGateway?.value === "Solution Provider" &&
                        <>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextBox
                                    // {...textBoxStyle}
                                    isrequired
                                    autocomplete={"new-name"}
                                    label={'Login name'}
                                    placeholder={'Enter name'}
                                    value={paymentGatewayConfiguration?.loginName ?? ''}
                                    onChange={(e) => updateState('paymentGatewayConfiguration', 'loginName', e.target.value)}
                                    isError={error?.paymentGatewayConfiguration?.loginName?.error ?? false}
                                    errorMessage={error?.paymentGatewayConfiguration?.loginName?.errorMsg ?? ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextBox
                                    // {...textBoxStyle}
                                    isrequired
                                    autocomplete={"new-password"}
                                    label={'Login password'}
                                    type={toggleView?.includes('1') ? "text" : "password"}
                                    placeholder={'Enter password'}
                                    value={paymentGatewayConfiguration?.loginPassword ?? ''}
                                    endAdornment={(
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => onChangeToggleView('1')}
                                                edge="end"
                                            >
                                                {toggleView?.includes('1') ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                    onChange={(e) => updateState('paymentGatewayConfiguration', 'loginPassword', e.target.value)}
                                    isError={error?.paymentGatewayConfiguration?.loginPassword?.error ?? false}
                                    errorMessage={error?.paymentGatewayConfiguration?.loginPassword?.errorMsg ?? ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextBox
                                    // {...textBoxStyle}
                                    isrequired
                                    label={'Login token'}
                                    type={toggleView?.includes('2') ? "text" : "password"}
                                    placeholder={'Enter login token'}
                                    value={paymentGatewayConfiguration?.loginToken ?? ''}
                                    endAdornment={(
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => onChangeToggleView('2')}
                                                edge="end"
                                            >
                                                {toggleView?.includes('2') ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                    onChange={(e) => updateState('paymentGatewayConfiguration', 'loginToken', e.target.value)}
                                    isError={error?.paymentGatewayConfiguration?.loginToken?.error ?? false}
                                    errorMessage={error?.paymentGatewayConfiguration?.loginToken?.errorMsg ?? ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextBox
                                    // {...textBoxStyle}
                                    isrequired
                                    label={'Payment Provider Name'}
                                    placeholder={'Enter Payment Provider Name'}
                                    value={paymentGatewayConfiguration?.paymentProviderName ?? ''}
                                    onChange={(e) => updateState('paymentGatewayConfiguration', 'paymentProviderName', e.target.value)}
                                    isError={error?.paymentGatewayConfiguration?.paymentProviderName?.error ?? false}
                                    errorMessage={error?.paymentGatewayConfiguration?.paymentProviderName?.errorMsg ?? ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextBox
                                    // {...textBoxStyle}
                                    isrequired
                                    label={'Url'}
                                    placeholder={'Enter Url'}
                                    value={paymentGatewayConfiguration?.url ?? ''}
                                    onChange={(e) => updateState('paymentGatewayConfiguration', 'url', e.target.value)}
                                    isError={error?.paymentGatewayConfiguration?.url?.error ?? false}
                                    errorMessage={error?.paymentGatewayConfiguration?.url?.errorMsg ?? ''}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        </div>
    )
}