import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import AmenityAccountCardSvg from '../../../assets/amenityAccountCardSvg'
import { SelectBox } from '../../../components'
import { AccountCardStyle } from './style'
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions'
import { AmenitiesBookingContext } from '../amenitiesBookingContext'
import { withNamespaces } from 'react-i18next'
import { AuthContext } from '../../../contexts'
// import { CustomSelect } from '../../../components/filterGenerator/components'
// import { GET_Role_LIST } from '../../../graphql/moveInAssign'

const AccountCard = ({ selectedCompany = {}, t = () => false }) => {
    const classes = AccountCardStyle()
    const { contactDetail, setContactDetail } = React.useContext(AmenitiesBookingContext)
    const auth = React.useContext(AuthContext)

    const manualResponse = (array) => {
        const details = array?.accounts?.map((i) => {
            return {
                ...i,
                label: i?.name,
                value: i?.id
            }
        })
        return details
    };
    return (
        <Box className={classes.card} sx={{ position: "relative" }}>
            <Stack direction={"row"} justifyContent={"space-between"} p={2}>
                <Stack spacing={1}>
                    <Typography className={classes.heading}>{t("Payment receiving account")}</Typography>
                    <SelectBox
                        placeholder={"Choose account here"}
                        selectHeight={34}
                        color={"#fff"}
                        value={contactDetail?.account}
                        onChange={(value) => {
                            setContactDetail({ ...contactDetail, account: value })
                        }}
                        isPaginate
                        loadOptions={(search, array, handleLoading) =>
                            loadOptionsApis(
                                "account/get_company_accounts",
                                {
                                    company_id: selectedCompany?.value,
                                    relationships: ["Walk In Account"],
                                    // limit: 10,
                                    // offset: 0,
                                    // search: ""
                                },
                                search,
                                array,
                                handleLoading,
                                "data",
                                {},
                                manualResponse
                            )

                        }
                        debounceTimeout={800}
                        isRequired
                    />
                </Stack>
                <Box className={auth?.auth?.auth?.language === 'ar' ? classes.dollarRtl : classes.dollar}>
                    <AmenityAccountCardSvg />
                </Box>
            </Stack>
        </Box >
    )
}

export default withNamespaces("amenityBooking")(AccountCard)