import { useApolloClient } from '@apollo/client'
import { Box, Button, Drawer, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React, { useContext, useState } from "react"
import { withNavBars } from "../../../HOCs"
import { useHistory, useLocation } from "react-router-dom"
import { CircleIcon, InspectionIcon } from "../../../assets"
import { AssignIcon } from "../../../assets/assignIcon"
import { CheckCircleIcon } from '../../../assets/checkCircleIcon'
import { TrackClockIcon } from '../../../assets/trackClockIcon'
import { AccordianWithDetails, AlertDialog, EditInspectionHour, IframeViwer, LoadingSection, Subheader, TemplateGenerator, TextBox, VerticalLinearStepper } from "../../../components"
import AssignModal from "../../../components/assignModal/reAssignModal"
import { config } from "../../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts"

import { updateAgreementInspections } from '../../../graphql/inspectionTemplate'
import { NetworkCall } from "../../../networkcall"
import { AlertProps, JobType, NetWorkCallMethods, RemoveZUTCDateTime, useWindowDimensions } from "../../../utils"
import { useStyles } from "../style"
import { RequestCard } from "./requestCard"
import { withNamespaces } from 'react-i18next'
import Close from '@mui/icons-material/Close'



const ViewUnitReadinessDetails = ({
    t
}) => {
    const size = useWindowDimensions()
    const client = useApolloClient()
    const { state } = useLocation()
    const history = useHistory()
    const alert = useContext(AlertContext)
    const classes = useStyles()
    const [assignModal, setAssignModal] = useState(false)
    const [details, setDetails] = useState([])
    const [isComplete, setIsComplete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reAssignModal, setReAssignModal] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)
    const [description, setDescription] = useState("")
    const [showTrack, setShowTrack] = useState(false)
    const [isBilledHour, setIsBilledHour] = React.useState(false)
    const auth = React.useContext(AuthContext);
    const [notesData, setNotesData] = useState({
        managerNotes: [],
        inspectorNotes: []
    })
    const [template, setTemplate] = React.useState({
        bool: false,
        type: ""
    });
    const [pdf, setPdf] = React.useState({
        bool: false,
        data: ""
    })
    const [openpdf, setOpenpdf] = React.useState(false);
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const backdrop = React.useContext(BackdropContext)
    const [itemDetails, setItemDetails] = React.useState([])
    const getInspectionDetails = () => {
        setLoading(true)
        const payload = {
            "request_id": state?.request_id
        }
        NetworkCall(
            `${config.api_url}/general_inspection/details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setLoading(false)
                setDetails({
                    data: response?.data?.data?.details,
                    list: response?.data?.data?.list,
                })
                setNotesData({ managerNotes: response?.data?.data?.details?.agreement_inspection?.inspection_manager_notes ?? [], inspectorNotes: response?.data?.data?.details?.agreement_inspection?.inspector_notes ?? null, costing_materials_notes: response?.data?.data?.details?.agreement_inspection?.costing_materials_notes ?? null })
            })
            .catch((error) => {
                setLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    }
    const getInspectionItemDetails = () => {
        setLoading(true)
        let payload = {}
        payload = {
            "id": state?.request_id,
            "agreement_inspection_id": state?.agreement_inspection_id,
            "agreement_id": state?.agreement_id ?? null,
        }
        // state.request_type === "unit_readiness" && (
        //     payload.type_in = state.request_type
        // )
        NetworkCall(
            `${config.api_url}/agreement_inspection/get_agreement_inspection_detail`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setItemDetails({
                    list: response?.data?.data?.list,
                    unitDetails: { ...response?.data?.data?.unit_based, property_name: response?.data?.data?.unit_details?.property_name, unit_type: response?.data?.data?.unit_details?.unit_type, address: response?.data?.data?.unit_details?.city }
                })
                setLoading(false)
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
                setLoading(false)
            })
    }
    React.useEffect(() => {
        getInspectionDetails()
        getInspectionItemDetails()
        //eslint-disable-next-line
    }, [])
    const goBack = () => {
        history.goBack()
    }
    const openAssignModal = () => {
        setAssignModal(true)
    }
    // const dialogHeader = () => {
    //     return (
    //         <Stack direction="row" spacing={1}>
    //             <Box onClick={() => setAssignModal(false)} className={classes.iconbtn} display="flex" alignItems="center">
    //                 <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
    //             </Box>
    //             <Typography className={classes.dialoghdrtext}>{"Assign Inspector"}</Typography>
    //         </Stack>
    //     )
    // }
    //for assigning
    const handleAssign = (payload) => {
        setIsDisableBtn(true)
        NetworkCall(
            `${config.api_url}/agreement_inspection/assign_resource`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Assigned Successfully"),
                });
                getInspectionDetails()
                setAssignModal(false)
            }).catch((err) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
            })
    }
    //reassign submit
    const reAssign = (payload) => {
        setIsDisableBtn(true)
        NetworkCall(
            `${config.api_url}/agreement_inspection/re_assign_resource`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Re Assigned Successfully"),
                });
                getInspectionDetails()
                setReAssignModal(false)
            }).catch((err) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
            })
    }
    const completeInspection = () => {
        setIsDisableBtn(true)
        const payload = {
            agreement_inspection_id: state?.agreement_inspection_id,
            description: description
        }
        NetworkCall(
            `${config.api_url}/general_inspection/complete_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Inspection Completed Successfully"),
            });
            setIsComplete(false)
            getInspectionDetails()
        }).catch((err) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
            });
        })
    }


    const SwitchButtons = (val) => {
        switch (val) {
            case "Yet to assign":
                return (
                    <Stack direction="row" alignItems={"center"}>
                        {/* <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box> */}

                        <Button
                            variant="outlined"
                            fullWidth
                            className={classes.cancelButton}
                            onClick={() => setCancelModal(true)}
                        >{t("Cancel")}
                        </Button>
                    </Stack>
                )
            case "Assigned":
                return (
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                        {/* <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box> */}
                        <Button
                            variant="outlined"
                            fullWidth
                            className={classes.cancelButton}
                            onClick={() => setCancelModal(true)}
                        >{t("Cancel")}
                        </Button>
                    </Stack>
                )
            case "Yet To Verify":
                return (<Stack direction="row" spacing={2} alignItems={"center"}>
                    {/* <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box> */}
                    <Button
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={() => setCancelModal(true)}
                    >{t("Cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.accoCardButton}
                        onClick={() => details?.data?.inspection_detail?.billable_hour === null ? setIsBilledHour(true) : setIsComplete(true)}>{t("Complete Inspection")}
                    </Button>
                </Stack>)
            // case "Completed":
            //     return (
            //         <Stack direction="row" spacing={2} alignItems={"center"}>
            //             {/* <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box> */}
            //             <Button
            //                 variant="contained"
            //                 // onClick={downloadPdf}
            //                 onClick={() => setTemplate({
            //                     bool: true,
            //                     type: 'view'
            //                 })}
            //                 className={classes.accoCardButton}
            //             >{t("Download Inspection")}
            //             </Button>
            //         </Stack>
            //     )
            default:
                return <Box></Box>

        }
    }

    const cancelInspection = () => {
        setIsDisableBtn(true)
        const payload = {
            description: description,
            "agreement_inspection_id": state?.agreement_inspection_id,
        }
        NetworkCall(
            `${config.api_url}/agreement_inspection/cancel_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${JobType(state?.request_type)} Inspection Cancelled Successfully`,
                });
                setCancelModal(false)
                getInspectionDetails()
            }).catch((err) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
                setLoading(false)
            })

    }
    const generate = async (value) => {
        setOpenpdf(true)
        await backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "...Loading",
        });
        const payload = {
            agreement_inspection_id: state?.agreement_inspection_id,
            type_master_id: value?.value

        };
        await NetworkCall(
            `${config.api_url}/general_inspection/template`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setPdf({
                    bool: true,
                    data: res?.data
                })
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            })
            .catch((err) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "some thing went wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    }
    const onUpdateNotes = (val) => {
        setIsDisableBtn(true)
        const payload = {
            id: state?.agreement_inspection_id,
            update: {
                inspection_manager_notes: [...notesData?.managerNotes, { notes: val }],
            }
        }
        client.mutate({
            mutation: updateAgreementInspections,
            variables: payload
        }).then((res) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Notes Added Successfully"),
            });
            setCancelModal(false)
            getInspectionDetails()
        }).catch((err) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
            });
        })
    }
    const reload = () => {
        getInspectionDetails()
    }

    return (
        <Box>
            <Subheader
                title={state?.reference_id}
                goBack={goBack}
            />
            {
                loading ?
                    <LoadingSection top="20vh" message={"Fetching Details"} />
                    :
                    <Box p={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5} md={4} lg={3}>
                                <Box className={classes.rootblock} style={{ height: size?.height - 150 }}>
                                    <Box style={{ height: details?.data?.agreement_inspection?.status === "Yet To Verify" ? size?.height - 230 : size?.height - 150, overflow: "scroll" }}>
                                        {/* <Box mt={1}>
                                        <RequestCard
                                            title="Site Visit Request"
                                            subTitle="Suresh Kumar K"
                                            subTitle1="T.Nagar, Chennai, Tamilnadu"
                                            backgroundColor="#EDE4FE80"
                                            icon={<SiteVisitIcon />}
                                        />
                                    </Box> */}
                                        {(details?.data?.agreement_inspection?.status === "Yet To Verify" || details?.data?.agreement_inspection?.status === "Completed") &&

                                            <EditInspectionHour
                                                t={t}
                                                reload={reload}
                                                status={details?.data?.agreement_inspection?.status}
                                                agreement_inspection_id={state?.agreement_inspection_id}
                                                resource_id={details?.data?.resource_details?.find(i => i?.is_primary)?.id}
                                            />

                                        }
                                        {details?.data?.inspection_detail &&
                                            <Box mt={1.5}>
                                                <RequestCard
                                                    t={t}
                                                    title={t("Inspection Detail")}
                                                    subTitle={details?.data?.inspection_detail?.reference_id}
                                                    subTitle1={`Inspection on ${moment(details?.data.inspection_detail?.created_at).format("DD MMM YY")}`}
                                                    icon={<InspectionIcon />}
                                                    status={details?.data?.agreement_inspection?.status}
                                                    unit_details={details?.data?.unit_details}
                                                />
                                            </Box>
                                        }
                                        {details?.data?.resource_details?.length > 0 ?
                                            <Box mt={1.5}>
                                                <RequestCard
                                                    t={t}
                                                    title={t("Resource Details")}
                                                    subTitle={details?.data?.resource_details?.[details?.data?.resource_details?.length - 1]?.name}
                                                    subTitle1={`${details?.data?.resource_details?.[details?.data?.resource_details?.length - 1]?.mobile_no_country_code} ${details?.data?.resource_details?.[details?.data?.resource_details?.length - 1]?.mobile_no}`}
                                                    image={true}
                                                    date={details?.data?.agreement_inspection?.execution}
                                                    AllResources={details?.data?.resource_details}
                                                    agreement_inspection_id={details?.data?.agreement_inspection?.id}
                                                    reAssignModal={reAssignModal}
                                                    setReAssignModal={setReAssignModal}
                                                    reAssign={reAssign}
                                                    status={details?.data?.agreement_inspection?.status}

                                                />
                                            </Box>
                                            :
                                            <Box mt={1.5}>
                                                <RequestCard
                                                    t={t}
                                                    assign={true}
                                                    icon={<AssignIcon />}
                                                    openAssignModal={openAssignModal}
                                                />
                                            </Box>
                                        }
                                        {details?.data?.inspection_tracker?.length > 0 &&
                                            <Box mt={2}>
                                                <RequestCard
                                                    t={t}
                                                    title={t("Track Update")}
                                                    details={details?.data?.inspection_tracker}
                                                    subTitle={details?.data?.inspection_tracker?.[0]?.title}
                                                    subTitle1={`By ${details?.data?.inspection_tracker?.[0]?.created_by}, ${moment(details?.data?.inspection_tracker?.[0]?.created_at).format("DD MMM YY HH:mm a")}`}
                                                    icon={<CircleIcon />}
                                                />
                                            </Box>
                                        }
                                    </Box>
                                    <Box className={classes.btnfix}>{SwitchButtons(details?.data?.agreement_inspection?.status)}</Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={7} md={8} lg={9}>
                                <Box className={classes.rootblock1} style={{ height: size?.height - 150, overflow: "auto" }}>
                                    <Stack direction={"row"} justifyContent={"space-between"}>
                                        <Typography className={classes.hdrtitle}>{t("Inspection Details")}</Typography>
                                        {details?.data?.agreement_inspection?.status === "Completed" &&
                                            <Button variant="outlined" className={classes.cancelbtn} onClick={() => setTemplate({
                                                bool: true,
                                                type: 'view'
                                            })}>
                                                <Stack direction={"row"} spacing={1} alignItems="center">
                                                    <img src={"/images/downloadInspection.svg"} alt="download" />
                                                    <Typography className={classes.viewMore}>{t("Inspection Report")}</Typography>
                                                </Stack>
                                            </Button>
                                        }
                                        {/* <Box>{SwitchButtons(details?.data?.agreement_inspection?.status)}</Box> */}
                                    </Stack>
                                    <Box>
                                        <AccordianWithDetails state={state} t={t} data={itemDetails?.list} unitDetails={itemDetails?.unitDetails} notesData={notesData} setNotesData={setNotesData} onUpdateNotes={onUpdateNotes} status={details?.data?.agreement_inspection?.status} type={"unit_readiness"} isDisableBtn={isDisableBtn} unitReadiness={true} />
                                    </Box>
                                </Box>

                            </Grid>
                        </Grid>
                    </Box>
            }
            <Drawer
                anchor={"right"}
                open={assignModal}
                onClose={() => setAssignModal(false)}
            >
                <AssignModal
                    agreement_inspection_id={details?.data?.agreement_inspection?.id}
                    // reassign={true}
                    header={t("Assign Resource")}
                    moduleName={t("Unit Readiness Inspection")}
                    handleClose={() => setAssignModal(false)}
                    buttonName={t("Assign")}
                    onSubmit={handleAssign}
                    isDisableBtn={isDisableBtn}
                />

            </Drawer>
            <AlertDialog open={isBilledHour}
                onClose={() => setIsBilledHour(false)}
                isnotTitle
                medium
                component={
                    // <CompleteInspection />
                    <Box className={classes.reqblock} p={1} alignItems="center">
                        <Box display={"flex"} justifyContent="end" onClick={() => setIsBilledHour(false)}>
                            <Close className={classes.closeicon} />
                        </Box>
                        <Grid container mt={5}>

                            {
                                auth?.auth?.auth?.language === "ar" ? " " : <Grid item xs={4}></Grid>
                            }
                            <Grid item xs={8}>
                                <Typography className={classes.q1hdr}>
                                    {t("Billing Hours not Updated,Do you want to Close?")}
                                </Typography>
                                <Box mt={1}>
                                    <Typography className={classes.q1subhdr}>
                                        {t("The Process Cannot be reverted once done")}
                                    </Typography>
                                </Box>
                                <Stack mt={5} ml={2} direction="row" >
                                    <Button variant="contained" fullWidth className={classes.yesbtn} onClick={() => { return (setIsBilledHour(false), setIsComplete(true)) }} disabled={isDisableBtn}>{t("Yes")}</Button>
                                    <Button variant="outlined" fullWidth className={classes.notxtbtn} onClick={() => setIsBilledHour(false)}>{t("No")}</Button>

                                </Stack>
                            </Grid>
                        </Grid >
                    </Box >
                }

            />
            <AlertDialog open={isComplete}
                onClose={() => { return (setIsComplete(false), setDescription("")) }}
                header={t("Complete Inspection")}
                isNormal
                component={
                    // <CompleteInspection />
                    <Box>
                        <center>
                            <Box className={classes.completeInsp}>
                                <Box mb={10}>
                                    <TextBox label={t("Description")} placeholder={t("Enter Description")} value={description} onChange={(e) => setDescription(e.target.value)} height={100} />
                                </Box>
                                {/* <Typography className={classes.msg}>Are you Sure ,You want to Complete ?</Typography> */}
                                <Grid container mt={4} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className={classes.cancelbtn} onClick={() => { return (setIsComplete(false), setDescription("")) }} variant="outlined">{t("No")}</Button>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth sx={{ color: "white" }} className={classes.successbtn} variant="contained" onClick={completeInspection} disabled={isDisableBtn}>{t("Yes")}</Button>

                                    </Grid>
                                </Grid>


                            </Box>
                        </center>
                    </Box>
                }

            />

            <AlertDialog open={cancelModal}
                onClose={() => { return (setCancelModal(false), setDescription("")) }}
                header={t("Cancel Inspection")}
                isNormal
                component={
                    // <CompleteInspection />
                    <Box>
                        <center>
                            <Box className={classes.completeInsp}>
                                <Box mb={10} textAlign={'left'}>
                                    <TextBox label={t("Description")} placeholder={t("Enter Description")} value={description} onChange={(e) => setDescription(e.target.value)} height={100} />
                                </Box>
                                {/* <Typography className={classes.msg}>Are you Sure ,You want to Cancel ?</Typography> */}
                                <Grid container mt={4} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className={classes.cancelbtn} onClick={() => { return (setCancelModal(false), setDescription("")) }} variant="outlined">{t("No")}</Button>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth sx={{ color: "white" }} className={classes.successbtn} variant="contained" onClick={cancelInspection} disabled={isDisableBtn}>{t("Yes")}</Button>

                                    </Grid>
                                </Grid>


                            </Box>
                        </center>
                    </Box>
                }

            />
            <AlertDialog
                open={showTrack}
                onClose={() => {
                    setShowTrack(false)
                }
                }
                header={t("Track Update")}
                isNormal
                component={<Box p={2} mt={2}>
                    <VerticalLinearStepper data={details?.data?.inspection_tracker?.map((val) => { return { name: val?.title, created_by: val?.created_by, date: RemoveZUTCDateTime(val?.created_at, "DD-MM-YYYY hh:mm A"), icon: val.title === "Inspection Completed" ? <Box className={classes.checkIconcircle}><CheckCircleIcon /></Box> : <TrackClockIcon />, notes: val?.description } })} customTrack />
                </Box>}
            />
            {/* Inspection pdf */}
            <AlertDialog
                header={state?.reference_id}
                onClose={() => {
                    setOpenpdf(false)
                    setPdf({
                        bool: false,
                        data: ""
                    })
                }}
                open={openpdf}
                // render={renderButton()}
                component={
                    <Box p={1} bgcolor={"#F5F7FA"}>
                        <IframeViwer pdf={pdf} height={`250px`} />
                    </Box>

                }
            />
            <TemplateGenerator t={t} name={"Inspection Template"} btnText={template?.type === "view" ? "Generate" : "Send"} open={template?.bool} onClose={() => setTemplate({
                bool: false,
                type: "view"
            })} type={7} generate={generate} />
        </Box>
    )
}
const props = {
    boxShadow: false,
};

export default withNamespaces("inspection")(withNavBars(ViewUnitReadinessDetails, props))