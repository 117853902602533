import { useApolloClient } from "@apollo/client";
import {
    Box, Divider, Grid, Typography
} from "@mui/material";
import moment from "moment";
import React from "react";
import { FormGenerator } from "../../../components";
import { getMailstoneTemplate } from "../../../graphql/quotationQueries";
import { enumSelect, enum_types, LocalStorageKeys } from "../../../utils";
import { saledataType, saleheading, salepath } from "../../../utils/createQuotationUtils";
import { LeadDetailsCard } from "./index";
import { MileStoneTemplate } from "./mileStoneTemplate";
import { useStyles } from "./styles";
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import { GET_COUNTRY_MASTER } from "../../../graphql/queries";

export const SaleForm = ({ data, updateState, type = "", purpose = "", setData = () => false, selectedAccount, setSelectedAccount = () => false, t = () => false, opperEdit = false, setOpperEdit = () => false }) => {
    const classes = useStyles();
    const client = useApolloClient();
    const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))

    // const { t } = React.useContext(createQuotationContext);
    const [enumValue, setEnum] = React.useState({
        priority: [],
        source: [],
        payment_period: [],
        payment_mode: []
    })

    const getEnum = async () => {
        const result = await enumSelect([enum_types.lead_source, enum_types.unit_payment_period, enum_types?.urgent_type, enum_types?.payment_mode])
        setEnum({
            payment_period: result?.payment_value_type?.filter(val => val?.value !== "Weekly").filter(val => val?.value !== "Half Yearly").filter(val => val?.value !== "Yearly").filter(val => val?.value !== "Quarterly").filter(val => val?.value !== "Prepaid").filter(val => val?.value !== "Hourly").filter(val => val?.value !== "Monthly"),
            source: result?.lead_source,
            priority: result?.urgent_type,
            payment_mode: result?.payment_mode,
        })
    }
    React.useEffect(() => {
        getEnum()
        updateState("oppertunity_subject", `${moment(new Date()).format("DD-MM-YYYY HH:mm")} ${data?.name}`)
        // eslint-disable-next-line
    }, [])

    const getTemplate = async (value) => {
        client.query({
            query: getMailstoneTemplate,
            fetchPolicy: 'network-only',
            variables: {
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                template_id: value?.value
            }
        }).then((response) => {
            const list = response?.data?.milestone_template_items?.map((val) => {
                return { ...val, milestone: val?.delivery_milestone_masters?.name, percentage: val?.percentage > 0 ? val?.percentage : '-' }
            })
            let error = data.error;
            error["payment_period"] = "";
            setData({ ...data, payment_period: { value: "Milestone Based" }, delivery_timestone_template: value, mailstoneList: list, mileStoneName: value?.label, quotation_payment_peroid: { value: "Milestone Based" }, payment_period_value: value, error })

        }).catch((err) => {
            console.log(err)
        })
    }
    const choosePaymentPeriod = (value) => {
        if (value.label === "On Completion") {
            let error = data.error;
            error["payment_period"] = "";
            setData({ ...data, payment_period: { value: "On Completion" }, "mailstoneList": [], mileStoneName: value?.label, quotation_payment_peroid: { value: value?.label }, payment_period_value: value, error })
        }
        else {
            getTemplate(value)
        }

    }

    const marketplace = [
        {
            label: t("Yes"),
            value: true,
        },
        {
            label: t("No"),
            value: false,
        }
    ]

    //form data
    const formSale = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 7,
                lg: 9,
            },
            isActive: true,
            component: "text",
            label: t("Opportunity Subject"),
            value: data?.oppertunity_subject,
            placeholder: t("Opportunity Subject"),
            onChange: (value) => updateState("oppertunity_subject", value.target.value),
            error: data?.error?.oppertunity_subject,
            isRequired: true,
            options: [],
            // multiline: true
        },

        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
            },
            isActive: true,
            component: "priority",
            label: t("Priority"),
            value: data?.priority,
            placeholder: t("Priority"),
            onChange: (value) => updateState("priority", value?.value),
            error: data?.error?.priority,
            isRequired: true,
            options: enumValue?.priority
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
            },
            isActive: true,
            component: "date",
            label: t("Earliest Occupation Date"),
            value: data?.earlist_occupation_date,
            placeholder: t("Earliest Occupation Date"),
            onChange: (value) => updateState("earlist_occupation_date", value),
            error: data?.error?.earlist_occupation_date,
            isRequired: true,
            isReadonly: true,
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
            },
            isActive: true,
            component: "date",
            label: t("Contract Start Date"),
            value: data?.contract_start_date,
            placeholder: t("Contract Start Date"),
            onChange: (value) => updateState("contract_start_date", value),
            error: data?.error?.contract_start_date,
            isRequired: true,
            options: enumValue?.lease_type
        },

        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
            },
            isActive: true,
            component: "payment",
            label: t("Payment Period"),
            value: data?.payment_period?.value,
            companyId: data?.company,
            placeholder: t("Payment Period"),
            onChange: choosePaymentPeriod,
            mileStoneName: data?.mileStoneName,
            error: data?.error?.payment_period,
            data: data,
            isRequired: true,
            mileStoneId: {
                payment_period: data?.payment_period,
                payment_period_value: data?.payment_period_value?.value,
            },
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
            },
            isActive: true,
            component: "select",
            label: t("Payment Options"),
            value: data?.payment_option,
            placeholder: t("Payment Options"),
            onChange: (value) => updateState("payment_option", value),
            error: data?.error?.payment_option,
            isRequired: true,
            options: enumValue?.payment_mode
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3
            },
            isActive: true,
            component: "toggle",
            label: t("Wallet Credits Carry Over"),
            value: data?.wallet_carry_over,
            placeholder: t("Wallet Credits Carry Over"),
            onChange: (value) => updateState("wallet_carry_over", value),
            error: data?.error?.wallet_carry_over,
            isRequired: true,
            options: marketplace
        },
    ]

    const formLease2 = [
        {
          size: {
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3
          },
          isActive: true,
          component: "text",
          label: "Company Name",
          value: data?.company_name,
          placeholder: "Company Name",
          onChange: (value) =>
            updateState("company_name", value.target.value),
          options: [],
          // multiline: true
        },
        {
          size: {
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3
          },
          isActive: true,
          component: "text",
          label: "City",
          value: data?.city,
          placeholder: "City",
          onChange: (value) =>
            updateState("city", value.target.value),
          options: [],
          // multiline: true
        },
        {
          size: {
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3
          },
          isActive: true,
          component: "select",
          label: "Country",
          value: data?.country,
          placeholder: "Country",
          onChange: (value) =>
            updateState("country", value),
          isPaginate: true,
          loadOptions: (search, array, handleLoading) =>
            loadOptions(
              search,
              array,
              handleLoading,
              GET_COUNTRY_MASTER,
              "country_master",
            )
          // multiline: true
        },
        {
          size: {
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3
          },
          isActive: true,
          component: "text",
          label: "Budget Amount",
          value: data?.budget_amount,
          placeholder: "Budget Amount",
          onChange: (value) =>
            updateState("budget_amount", value.target.value),
          options: [],
          type: "number",
          endAdornment: <Typography className={classes.endAdornment}>{selectedCompany?.currency_symbol}</Typography>
        },
        {
          size: {
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3
          },
          isActive: true,
          component: "text",
          label: "Probability",
          value: data?.probability,
          placeholder: "Probability",
          onChange: (value) =>
            updateState("probability", value.target.value),
          options: [],
          type: "number",
          endAdornment: <Typography className={classes.endAdornment}>%</Typography>
          // multiline: true
        },
        {
          size: {
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3
          },
          isActive: true,
          component: "toggle",
          label: "Confirm Interest",
          value: data?.confirm_interest,
          placeholder: "Confirm Interest",
          onChange: (value) => updateState("confirm_interest", value),
          error: data?.error?.confirm_interest,
          isRequired: true,
          options: marketplace
        },
        {
          size: {
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3,
          },
          isActive: true,
          component: "date",
          label: "Expected Close Period",
          value: data?.expected_close_period,
          placeholder: "Expected Close Period",
          onChange: (value) => updateState("expected_close_period", value),
          minDate: new Date()
        },
    
      ];

    return (
        <Box p={1}>
            <Grid container spacing={1.5} className={classes.leadDetailsRoot}>
                <Grid item lg={3} md={12} sm={12} sx={{ position: "relative", width: "100%" }}>
                    <Box p={2} className={classes.leadDetailsRootDivider}>
                        <LeadDetailsCard
                            data={{
                                name: data?.name,
                                image: data?.image_url?.length !== 0 ? data?.image_url : null,
                                email: data?.email_id,
                                mobile: ` ${data.mobile?.mobile_code ?? ""} ${data.mobile?.mobile ?? "-"}`,
                                no: null,
                                type: type,
                                purpose: purpose,
                                source: data?.source?.value,
                                units: data?.units
                            }}
                            source_enum={enumValue?.source ?? ""}
                            dataNew={data}
                            setDataNew={setData}
                            updateState={updateState}
                            setSelectedAccount={setSelectedAccount}
                            account={selectedAccount}
                            t={t}
                            opperEdit={opperEdit} 
                            setOpperEdit={setOpperEdit}
                        />
                        <Box height="18px" />



                    </Box>
                </Grid>
                <Grid item lg={9} md={12} sm={12}>
                    <Box p={2} className={classes.leadDetailsRootDivider}>

                        <FormGenerator t={t} components={formSale} />
                        <Box py={2}>
                            <Divider></Divider>
                        </Box>
                        <FormGenerator t={t} components={formLease2} />
                        <Box height="18px" />
                        {data?.mailstoneList?.length > 0 &&
                            <Grid item lg={6} md={12} sm={12}>
                                <MileStoneTemplate
                                    dataType={saledataType}
                                    rows={data?.mailstoneList}
                                    title={data?.mileStoneName}
                                    heading={saleheading}
                                    path={salepath}
                                />
                            </Grid>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box >


    );
};
