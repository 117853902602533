import React from "react";
import { withNavBars } from "../../HOCs";
import { LeaseManagerDashboard } from "./leasemanagerdashboard";
class LeaseManagerDashboardParent extends React.Component {
  render() {
    return (
        <LeaseManagerDashboard />
    );
  }
}
const props = {
  boxShadow: false
}
export default withNavBars(LeaseManagerDashboardParent,props);