import * as React from "react"

const OppertunitiesComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <defs>
      <radialGradient
        id="a"
        cx={0.268}
        cy={-0.077}
        r={1.341}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#6e757b" />
        <stop offset={1} stopColor="#4a4e52" />
      </radialGradient>
      <linearGradient
        id="b"
        x1={0.344}
        y1={-24.855}
        x2={0.75}
        y2={-25.928}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffda1c" />
        <stop offset={1} stopColor="#feb705" />
      </linearGradient>
    </defs>
    <path
      data-name="Path 96409"
      d="M25.905 0H1.529A1.523 1.523 0 0 0 0 1.529v24.376a1.523 1.523 0 0 0 1.529 1.524h24.376a1.523 1.523 0 0 0 1.524-1.524V1.529A1.523 1.523 0 0 0 25.905 0Z"
      fill="#50e6ff"
    />
    <path
      data-name="Path 96410"
      d="M13.715 7.94a3.366 3.366 0 0 0-6.624-.85l6.624 6.625Z"
      fill="#33c481"
    />
    <path
      data-name="Path 96411"
      d="M13.715 19.489a3.366 3.366 0 0 0 6.624.85l-6.624-6.625Z"
      fill="#33c481"
    />
    <path
      data-name="Path 96412"
      d="M19.489 13.715a3.366 3.366 0 0 0 .85-6.624l-6.625 6.624Z"
      fill="#33c481"
    />
    <path
      data-name="Path 96413"
      d="M7.94 13.715a3.366 3.366 0 0 0-.85 6.624l6.625-6.624Z"
      fill="#33c481"
    />
    <path
      data-name="Path 96414"
      d="M13.715 7.94a3.366 3.366 0 0 1 6.624-.85l-6.624 6.625Z"
      fill="#21a366"
    />
    <path
      data-name="Path 96415"
      d="M13.715 19.489a3.366 3.366 0 0 1-6.624.85l6.624-6.625Z"
      fill="#21a366"
    />
    <path
      data-name="Path 96416"
      d="M7.94 13.715a3.366 3.366 0 0 1-.85-6.624l6.625 6.624Z"
      fill="#21a366"
    />
    <path
      data-name="Path 96417"
      d="M19.489 13.715a3.366 3.366 0 0 1 .85 6.624l-6.625-6.624Z"
      fill="#21a366"
    />
    <path
      data-name="Path 96418"
      d="M33.515 32h-6.086v1.515a3.044 3.044 0 1 0 6.088 0s-.002-1.079-.002-1.515Z"
      fill="url(#a)"
      transform="translate(-4.571 -4.571)"
    />
    <path
      data-name="Path 96419"
      d="M25.889 12.191a6.881 6.881 0 0 0-6.873 6.873 11.028 11.028 0 0 0 1.981 5.614 6.121 6.121 0 0 1 1.075 2.433v.318h3.833a1.523 1.523 0 0 0 1.524-1.524V12.371a6.845 6.845 0 0 0-1.54-.18Z"
      opacity={0.05}
    />
    <path
      data-name="Path 96420"
      d="M25.889 12.572a6.5 6.5 0 0 0-6.492 6.492 10.72 10.72 0 0 0 1.925 5.416 6.248 6.248 0 0 1 1.13 2.63v.319h3.453a1.523 1.523 0 0 0 1.524-1.524V12.764a6.457 6.457 0 0 0-1.54-.192Z"
      opacity={0.07}
    />
    <path
      data-name="Path 96421"
      d="M36.571 23.635a6.111 6.111 0 1 0-12.222 0c0 3.565 3.056 6.111 3.056 8.046V32h6.111v-.318c-.001-1.935 3.055-4.482 3.055-8.047Z"
      fill="url(#b)"
      transform="translate(-4.571 -4.571)"
    />
  </svg>
)

export default OppertunitiesComponent
