import React from 'react'
import { AlertDialog, FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components'
import styled from '@mui/material/styles/styled';
import { myOrgTableDatatype, myOrgTableHeading, myOrgTablePath } from './utils/myOrg';
import { Box, Button, Divider, Grid, Stack } from '@mui/material';
import { MyOrgStyle } from './style';
import FilterIcon from '../../assets/filterIcon';
import { HistoryViewCard } from './historyCard';
import { AddForm } from './addForm';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { AlertProps, NetWorkCallMethods, accessCheckRender, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew } from '../../utils';
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import moment from 'moment';
import { NewLoader } from '../../components/newLoader';
import { StatusOptionList } from '../../utils/inventory';
import { withNamespaces } from 'react-i18next';

const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    height: `calc(100vh - 150px)`,
    overflow: "hidden",
    margin: "12px",
    position: "relative"
}))

const MyOrgPage = (props) => {
    const { loading, t = () => false } = props;
    const classes = MyOrgStyle()
    const auth = React.useContext(AuthContext)
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext)
    const debounce = UseDebounce()
    const [permission, setPermission] = React.useState({})
    const [companyList, setCompanyList] = React.useState([])
    const [searchText, setSearchText] = React.useState("")
    const [showForm, setShowForm] = React.useState(false)
    const [selectedMember, setSelectedMember] = React.useState("")
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const [loader, setLoader] = React.useState(false)
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState({
        is_active: [true],
        role_type: ["Client", "Company"]
    });
    const [data, setData] = React.useState({
        page: 1,
        offset: 0,
        limit: 10,
        rowData: []
    })

    const [formdata, setFormData] = React.useState({
        id: undefined,
        role_type: "Client",
        company_Name: "",
        contact: "",
        start_period: new Date(),
        role: "",
        is_active: true,
        type: "add",
        error: {
            role_type: "",
            company_Name: "",
            contact: "",
            start_period: "",
            role: "",
            is_active: "",
        }

    })


    const updateData = (key, value) => {
        setData({ ...data, [key]: value })
    }

    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                }
            }
            getTableData()
            getEnum()
        }
        // eslint-disable-next-line
    }, [auth]);

    const [enumValue, setEnumValue] = React.useState({
        role_type: []
    })

    const getEnum = async () => {
        const result = await enumSelect([
            enum_types?.org_role_type,
        ]);
        setEnumValue({
            role_type: result?.org_role_type ?? []
        });
    };

    const getTableData = (offset = 0, limit = 10, search = "", filter_data = filterData) => {
        setLoader(true)
        const payload = {
            limit: limit,
            offset: offset,
            org_role_type: filter_data?.role_type,
            search: search,
            is_active: filter_data?.is_active
        };
        NetworkCall(
            `${config.api_url}/my_organisation/get_list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((res) => {
                setLoader(false)
                const rowData = res?.data?.data?.map((e) => {
                    return {
                        contact_name: e?.contact_name,
                        contact_id: e?.contact_id,
                        image: e?.image_url,
                        role: {
                            "label": e?.role_name,
                            "value": e?.role_id,
                            "responsibiltes": e?.role_description,
                            "bgColor": "#EBFBFF"
                        },
                        role_id: e?.role_id,
                        is_active: e?.is_active,
                        start_date: e?.start_time ? moment(e?.start_time).format("DD MMM YY") : "-",
                        id: e?.id,
                        role_type: e?.org_role_type,
                        selected_company: { label: e?.company_name, value: e?.company_id }
                    }
                })
                setData({
                    ...data,
                    rowData: rowData,
                    rowCount: res?.data?.count
                })
            }).catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                });
            });
    };


    const handlePagination = (value) => {
        updateData("page", value)
        let offset = (value - 1) * data?.limit;
        getTableData(offset, data?.limit, "")
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setData({ ...data, limit: value, page: 1 })
        getTableData(0, value, "")

    }
    const handleTableSearch = (val) => {
        setSearchText(val)
        debounce(() => getTableData(0, 10, val), 800)
    }
    const handleIcon = (type, data) => {
        console.log("data", data)
        if (type === "edit") {
            setShowForm(true)
            const constructedData = {
                id: data?.id,
                role_type: data?.role_type,
                start_period: new Date(data?.start_date),
                role: data?.role,
                is_active: true,
                contact: { label: data?.contact_name, value: data?.contact_id },
                company_Name: data?.role_type === "Client" ? "" : data?.selected_company,
                type: "edit"
            }
            setFormData({ ...formdata, ...constructedData })
        }
        else if (type === "history") {
            setShowForm(true)
            setFormData({ ...formdata, "type": "history", id: data?.id })
        }
        else if (type === "active") {
            const payload = {
                org_management_id: data?.id,
                is_active: !data?.is_active
            };
            NetworkCall(
                `${config.api_url}/my_organisation/in_active`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false).then((res) => {
                    getTableData()
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: res?.data?.message,
                    });
                }).catch(() => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: AlertProps.message.some_thing_went_wrong,
                    });
                });
        }
    }

    const handleAddMember = () => {
        setShowForm(true)
        setFormData({ ...formdata, type: "add" })
    }

    const onCloseModal = () => {
        setShowForm(false)
        let resetData = {
            id: undefined,
            role_type: "Client",
            company_Name: "",
            contact: "",
            start_period: new Date(),
            is_active: true,
            type: "add",
            error: {
                role_type: "",
                company_Name: "",
                contact: "",
                start_period: "",
                is_active: "",
            }
        }
        setFormData(resetData)
        // setSelectedMember("")
    }

    const updateState = (key, value) => {
        let error = formdata.error;
        error[key] = ""
        setFormData({ ...formdata, [key]: value, error })
    }

    const validateForm = () => {
        let isValid = true;
        let error = formdata.error;
        if (formdata?.role_type?.length === 0) {
            isValid = false;
            error.role_type = t("Role Type is Required");
        }
        if (formdata?.role_type === "Company") {
            if (formdata?.company_Name?.length === 0) {
                isValid = false;
                error.company_Name = t("Company is Required");
            }
        }
        if (!Boolean(formdata?.role?.value)) {
            isValid = false;
            error.role = t("Role is Required");
        }
        if (formdata?.contact?.length === 0) {
            isValid = false;
            error.contact = t("Contact is Required");
        }
        if (formdata?.is_active?.length === 0) {
            isValid = false;
            error.is_active = t("Status is Required");
        }

        setFormData({ ...formdata, error });
        return isValid;
    }

    const onFormSubmit = () => {
        if (validateForm()) {
            setIsDisableBtn(true)
            const payload = {
                id: formdata?.id ?? undefined,
                contact_id: formdata?.contact?.value,
                company: formdata?.company_Name?.value,
                org_role_master_id: formdata?.role?.value,
                org_role_type: formdata?.role_type,
                start_time: formdata?.start_period,
            }
            NetworkCall(
                `${config.api_url}/my_organisation/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((res) => {
                    onCloseModal()
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: res?.data?.data?.message,
                    });
                    getTableData()
                    setIsDisableBtn(false)
                })
                .catch((error) => {
                    onCloseModal()
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: AlertProps.message.some_thing_went_wrong,
                    });
                    setIsDisableBtn(false)
                });
        }
        else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            });
            setIsDisableBtn(false)
        }

    }


    const handleFilter = (value) => {
        setFilterData(value)
        getTableData(0, 10, "", value)
    }

    const render = () => {
        return (
            <>
                <Subheader title={t("My Organization")} hideBackButton />
                <CustomPaper>
                    <Box className={classes.tableSection}>
                        <Grid container>
                            {/* searchbar */}
                            <Grid item xs={4}>
                                <SearchFilter
                                    handleChange={handleTableSearch}
                                    value={searchText}
                                    placeholder={t("Search by Contact Name")}
                                />
                            </Grid>
                            {/* filterIcon */}
                            <Grid item xs={8} >
                                <Stack
                                    direction="row"
                                    divider={<Divider className={classes.dividerStyle} orientation="vertical" flexItem />}
                                    spacing={2}
                                    justifyContent={"end"}
                                >

                                    <FilterIcon onClick={() => setFilterDrawer(true)} />
                                    {permission?.create &&
                                        <div className={classes.addbtn}>
                                            <Button variant="contained"
                                                onClick={() => handleAddMember()}
                                            >{t("Add Member")}</Button>
                                        </div>}
                                </Stack>
                            </Grid>
                        </Grid>
                        {
                            loader ?
                                <NewLoader minusHeight={"300px"} />
                                :
                                <TableWithPagination
                                    heading={myOrgTableHeading(t)}
                                    rows={data?.rowData}
                                    path={myOrgTablePath}
                                    dataType={myOrgTableDatatype}
                                    showpagination={true}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    tableType="no-side"
                                    handleIcon={handleIcon}
                                    delete={false}
                                    history={true}
                                    edit={true}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={data?.rowCount}
                                    page={data?.page}
                                    limit={data?.limit}
                                    height={'calc(100vh - 300px)'}
                                />
                        }
                        <AlertDialog
                            isNormal
                            header={formdata.type === 'add' ? t("Add Member") : formdata.type === 'history' ? t("History") : t("Edit Member")}
                            onClose={() => onCloseModal()}
                            open={showForm}
                            component={
                                formdata?.type === "history" ?
                                    <HistoryViewCard
                                        formdata={formdata}
                                        t={t} />
                                    :
                                    <AddForm
                                        formdata={formdata}
                                        setFormData={setFormData}
                                        updateState={updateState}
                                        selectedMember={selectedMember}
                                        setSelectedMember={setSelectedMember}
                                        onFormSubmit={onFormSubmit}
                                        onCloseModal={onCloseModal}
                                        state={{}}
                                        t={t}
                                        isDisableBtn={isDisableBtn}
                                        companyList={companyList}
                                        enumValue={enumValue}
                                    />

                            }

                        />
                    </Box>
                </CustomPaper>
                <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => handleFilter(value)}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.is_active,
                            options: StatusOptionList,
                            isMulti: true,
                            state_name: "is_active",
                            label: t("Status"),
                        },
                        {
                            component: "toggleButton",
                            value: filterData?.role_type,
                            options: enumValue?.role_type,
                            isMulti: true,
                            state_name: "role_type",
                            label: t("Role Type"),
                        },
                    ]}

                />
            </>
        )
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}

export default withNamespaces("myOrg")(MyOrgPage)