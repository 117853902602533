import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Divider, Popover, Stack, Typography, IconButton, Hidden } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { DateRangePicker } from "materialui-daterange-picker";
import moment from "moment";
import React from "react";
import { CalenderIcon } from '../../assets';
import LeftArrow from '../../assets/leftArrow';
import { CompaniesPrefixIcon, PropertyPrefixIcon, TeamsIcon } from '../../assets/navBarIconsNew';
import RightArrow from '../../assets/rightArrow';
import { LocalStorageKeys, Regular, SemiBold } from '../../utils';
import { SelectBox } from "../select";
import { TeamsSelect } from '../teamsSelect';
import CloseIcon from '@mui/icons-material/Close';
import { Bold } from '../../utils';
import { withNamespaces } from 'react-i18next';
// import UploadRoundedIcon from '@mui/icons-material/UploadRounded';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: (props) => props?.padding ?? "4px",
        marginTop: "-4px",
        borderBottom: (props) => props?.borderBottom ?? "1px solid #00000014"
    },
    titleRoot:{
        [theme.breakpoints.up("md")]: {
            minHeight:"54px"
          },
          [theme.breakpoints.down("md")]: {
            minHeight:"24px"
          },
    },
    iconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: theme.palette.border.secondary,
        padding: "8px",
        borderRadius: "50%",
        height: "24px",
        width: "24px",
        cursor: "pointer",
        direction: "ltr"

    },
    editiconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: theme.palette.border.secondary,
        padding: "8px",
        borderRadius: "50%",
        height: "24px",
        width: "24px",
        cursor: "pointer"
    },
    icon: {
        fontSize: "0.875rem",
        marginLeft: "-2px",
    },
    iconArabic: {
        fontSize: "0.875rem",
        marginLeft: "-3px",

    },
    title: {
        color: theme.typography.color.secondary.main,
        fontFamily: Bold,
        fontSize: "1rem"

    },
    label: {
        color: theme.typography.color.tertiary,
        fontFamily: Bold,
        fontSize: "1rem",
        marginLeft: "2px",
    },
    count: {
        color: "#98A0AC",
        fontSize: "0.875rem",
        fontFamily: SemiBold

    },
    Cancel: {
        backgroundColor: "#FFFFFF ",
        color: "#091B29",
        fontSize: "0.875rem",
        fontWeight: 600,
        fontFamily: SemiBold,
        "&:hover": {
            backgroundColor: "#FFFFFF ",
        },
    },
    next: {
        fontFamily: SemiBold,
        color: "#5078E1",
        "&:hover": {
            backgroundColor: "#FFFFFF ",
        },
    },
    pdfbtn: {
        fontFamily: SemiBold,
        border: "1px solid #5078E1",
        backgroundColor: "#FFFFFF ",
        fontSize: "0.75rem",


        "&:hover": {
            fontFamily: SemiBold,
            border: "1px solid #5078E1",
            backgroundColor: "#FFFFFF ",
            fontSize: "0.75rem",
        },
    },
    box: {
        width: "250px",

    },
    companyGroupDiv: {
        border: "1px solid #CED3DD",
        borderRadius: "4px",
        backgroundColor: "#FFFFFF",
    },
    download_template: {
        "&:hover": {
            backgroundColor: theme.palette?.background?.tertiary1,
        },
    }
}));
const SubheaderComponent = (props) => {
    const { t } = props
    const classes = useStyles(props);
    const language = localStorage.getItem("i18nextLng")
    const [calenderOpen, setCalendarOpen] = React.useState(false);

    const openClosePopOver = () => {
        setCalendarOpen(!calenderOpen)
    }

    const onCalendarDropdownChange = (value) => {
        props?.onChangeCalendar(value)
        if (value.label === "Custom") {
            setCalendarOpen(!calenderOpen)
        }
    }

    const onCalendarPickerChange = (range) => {
        let value = {
            label: "Custom",
            value: {
                from_date: moment(range?.startDate).format("YYYY-MM-DD"),
                to_date: moment(range?.endDate).format("YYYY-MM-DD")
            },
            load: true
        }
        setCalendarOpen(!calenderOpen)
        props?.onChangeCalendar(value)
    }


    const returnCalenderSelectDropDown = () => {
        return (
            <SelectBox
                color="#FFFFFF"
                noBorder={window.innerWidth  > 500}
                left={'2px'}
                selectHeight={"40px"}
                padding={"0px 0px 0px 6px"}
                prefix={<CalenderIcon />}
                options={props?.calendarOptions}
                isReadOnly={props?.calendarIsReadOnly}
                placeholder={props?.calendarPlaceholder}
                label={props?.calendarLabel}
                value={props?.calanderValue}
                onChange={(value) => onCalendarDropdownChange(value)}
                fontFamily={SemiBold}
            />
        )
    }

    const returnCustomCal=()=>{
        return (
            <Box
            style={{
                backgroundColor: "#FFFFFF",
                padding: "2px",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
            }}
            onClick={openClosePopOver}>
            <CalenderIcon />
            <Typography style={{ marginLeft: "6px", fontSize: "0.875rem", fontFamily: Regular, color: "#333333" }}>
                {(props?.calanderValue?.value?.from_date ?? "YYYY-MM-DD") + " to " +
                    (props?.calanderValue?.value?.to_date ?? "YYYY-MM-DD")}
            </Typography>
        </Box>
        )
    }

    const returnSelect=()=>{
        return(
            <SelectBox
            color="#FFFFFF"
            options={props?.options}
            noBorder={window.innerWidth  > 500}
            selectHeight={"40px"}
            top={props?.top}
            padding={"0px 0px 0px 2px"}
            prefix={<CompaniesPrefixIcon />}
            isReadOnly={props?.isReadOnly} placeholder={props?.placeholder}
            label={props?.label} value={(props?.value?.value || props?.isAllCompany) ? props?.value : { label: "Select Company" }}
            fontFamily={SemiBold}
            onChange={(value) => {
                value?.value && localStorage.setItem(LocalStorageKeys.selectedCompany, JSON.stringify(value))
                props?.onchange(value)
            }} />
        )
    }
    const returnSelectNoIconDrop=()=>{
        return(

            <SelectBox
            color="#FFFFFF"
            options={props?.options}
            noBorder={window.innerWidth  > 500}
            selectHeight={"40px"}
            top={props?.top}
            padding={"0px 0px 0px 2px"}
            // prefix={<CompaniesPrefixIcon />}
            isReadOnly={props?.isReadOnly} placeholder={props?.placeholder}
            label={props?.label} value={(props?.value?.value || props?.isAllCompany) ? props?.value : { label: "Select Company" }}
            fontFamily={SemiBold}
            onChange={(value) => {
                value?.value && localStorage.setItem(LocalStorageKeys.selectedCompany, JSON.stringify(value))
                props?.onchange(value)
            }} />
        )
    }
    const teamReturn=()=>{
        return(
            <TeamsSelect
            noBorder={window.innerWidth  > 500}
            selectHeight={"40px"}
            padding={"0px 0px 0px 2px"}
            color="#FFFFFF"
            companyId={props?.companyId}
            prefix={<TeamsIcon />}
            handleTeamsChange={props?.handleTeamsChange}
            moduleId={props?.moduleId}
            otherOptionsIsReadOnly={props?.isReadOnly}
            selectedTeams={props?.selectedTeams?.label?.length > 0 && props?.selectedTeams}
            getTeamsOption={props?.getTeamsOption}
        />
        )
    }
    const readteamReturn=()=>{
        return(
            <SelectBox
            noBorder={window.innerWidth  > 500}
            selectHeight={"40px"}
            padding={"0px 0px 0px 2px"}
            color="#FFFFFF"
            prefix={<TeamsIcon />}
            fontFamily={SemiBold}
            isReadOnly={true}
            value={props?.selectedTeams?.label?.length > 0 && props?.selectedTeams} />
        )
    }
    const returnProperty=()=>{
        return(
            <SelectBox
            noBorder={window.innerWidth  > 500}
            selectHeight={"40px"}
            padding={"0px 0px 0px 2px"}
            color="#FFFFFF"
            prefix={<PropertyPrefixIcon />}
            options={props?.propertyOptions}
            isReadOnly={props?.isReadOnly}
            placeholder={props?.propertyPlaceholder}
            label={props?.propertylabel}
            fontFamily={SemiBold}
            value={props?.propertyValue ? props?.propertyValue : { label: "Select Property" }}
            onChange={(value) => {

                props?.onPropertyChange(value)
            }} />
        )
    }
    const returnUnit=()=>{
        return(
            <SelectBox
            noBorder={window.innerWidth  > 500}
            selectHeight={"40px"}
            padding={"0px 0px 0px 2px"}
            color="#FFFFFF"
            prefix={<PropertyPrefixIcon />}
            options={props?.unitOptions}
            isReadOnly={props?.isReadOnly}
            placeholder={props?.unitPlaceholder}
            label={props?.unitlabel}
            fontFamily={SemiBold}
            isPaginate={props?.isPaginateUnit}
            loadOptions={props?.loadOptionsUnit}
            key={props?.unit_change}
            value={props?.unitValue ? props?.unitValue : { label: "Select Unit" }}
            onChange={(value) => {

                props?.onUnitChange(value)
            }} />
        )
    }

   

    return (<div>
        <Box className={classes.root}>
            <Box  display="flex" alignItems="center" className={classes.titleRoot}>
            {
                !Boolean(props?.hideBackButton) &&
                <Box marginLeft="16px">
                    <Box onClick={props?.goBack} className={classes.iconbtn} display="flex" alignItems="center">
                        {language === "ar" ?
                            <ArrowForwardIosIcon className={classes.iconArabic} htmlColor="black" /> :
                            <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
                        }
                    </Box>
                </Box>
            }
            <Box marginInlineStart="15px">
                <Typography className={classes.title}>{props?.title?.length > 50 ? props?.title + "...." : props?.title}</Typography>
            </Box>
            {
                props?.count && <Box marginLeft="10px">
                    <Typography className={classes.count}>{"(" + props?.count + ")"}</Typography>
                </Box>
            }

            {
                props?.pageoptions?.map((val) => {
                    return (
                        val?.label && <>
                            <Box marginTop="4px" marginLeft="4px"><ArrowForwardIosIcon className={classes.icon} htmlColor="black" /></Box>
                            <Box><Typography className={val?.type === "name" ? classes.title : classes.label}>{val?.label}</Typography></Box>
                        </>
                    )
                })
            }
            <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                {props?.isDetailsarrow &&
                    props?.pageoptionsoption?.map((val) => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Box><Typography className={classes.label}>{val?.label}</Typography></Box>
                                {
                                    val?.isArrow && <Box marginTop="4px" marginLeft="4px"><ArrowForwardIosIcon className={classes.icon} htmlColor="#091B29" /></Box>
                                }
                            </div>
                        )
                    })}
            </div>
            {/* {props?.isEdit &&
                <div style={{ marginLeft: '8px', textAlign: 'left' }} onClick={props?.handleEdit}>
                    <img src='/images/Outlineselect.svg' alt="" />
                </div>} */}
            <Box flexGrow={1} />
            <Hidden smDown>
            <Stack className={classes.companyGroupDiv}
                direction="row"
                justifyContent="right"
                margin={"0px 8px"}
                divider={<Divider orientation="vertical" flexItem />}>
                {props?.calanderselect &&
                    <Box width="250px" marginRight="9px" marginLeft="8px">
                        {returnCalenderSelectDropDown()}
                    </Box >
                }

                {
                    (props?.calanderValue?.label === "Custom") && returnCustomCal()
                 
                }
                {
                    props?.select &&
                    <Box width="250px" marginLeft="8px">
                    {returnSelect()}
                    </Box>}
                {
                    props?.selectNoIcon &&
                    <Box width="250px" marginLeft="8px">
                       {returnSelectNoIconDrop()}
                    </Box>}
                {props?.selectTeamsOptions &&
                    <Box className={props?.selectedTeams?.value !== "noteams" ? classes.box : ""} >
                       {teamReturn()}
                    </Box>}
                {(props?.readOnlyTeam && props?.selectedTeams?.value !== "noteams") &&
                    <Box className={props?.selectedTeams?.value !== "noteams" ? classes.box : ""} >
                        {readteamReturn()}
                    </Box>}
                {props?.selectProperty &&
                    <Box width="250px" marginLeft="8px">

                       {returnProperty()}
                    </Box>}
                {
                    props?.selectUnit &&
                    <Box width="250px">
                       {returnUnit()}
                    </Box>
                }
            </Stack >
            </Hidden>
            {
                props?.previousAndNextBtn &&
                <Box>
                    <Stack direction="row"
                        spacing={0}
                        divider={<Divider orientation="vertical" flexItem />}
                    >
                        <Button
                            onClick={() => props?.previousBtn()}
                            disabled={props?.previousBtnDisabled}
                            variant="text"
                            className={classes.Cancel}
                            startIcon={<LeftArrow color={props?.previousBtnDisabled ? "#98A0AC" : "#091b29"} />} >
                            {""}
                        </Button>
                        <Button
                            onClick={() => props?.nextBtn()}
                            disabled={props?.nextBtnDisabled}
                            variant="text"
                            className={classes.next}
                            endIcon={<RightArrow color={props?.nextBtnDisabled ? "#98A0AC" : "#5078e1"} />} >
                            {""}
                        </Button>
                    </Stack>
                </Box>
            }
            {
                props?.isClose &&
                <IconButton onClick={props?.close}>
                    <CloseIcon />
                </IconButton>

            }
            {
                props?.isDownload &&
                <Button onClick={props?.onClick} contained="outlined" className={classes.pdfbtn}>
                    {t("View PDF")}
                </Button>

            }

            {
                props?.isEdit &&
                <Button variant="contained" onClick={props?.handleEdit}>
                    {t('Edit')}
                </Button>
            }
            {
                props?.handle_download_template &&
                <Button className={classes.download_template}
                    variant="text" onClick={props?.handle_download_template}>
                    {t("Download Template")}
                </Button>
            }

             </Box>
             <Hidden smUp>
                <Stack marginTop={1} spacing={1} p={1}>
                {props?.calanderselect &&returnCalenderSelectDropDown()}
                {props?.calanderValue?.label === "Custom" && returnCustomCal()}
                {props?.select &&returnSelect()}
                {props?.selectNoIcon &&returnSelectNoIconDrop()}
                {props?.selectTeamsOptions &&teamReturn()}
                {(props?.readOnlyTeam && props?.selectedTeams?.value !== "noteams") &&readteamReturn()}
                {props?.selectProperty &&returnProperty()}
                {props?.selectUnit &&returnUnit()}
                </Stack>
            </Hidden>
        </Box >
        <Popover
            open={calenderOpen}
            onClose={openClosePopOver}
            anchorReference="anchorPosition"
            anchorPosition={{ top: 130, left: 1000 }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
            <DateRangePicker
                open={calenderOpen}
                toggle={openClosePopOver}
                onChange={range => onCalendarPickerChange(range)}
                initialDateRange={{
                    startDate: props?.calanderValue?.value?.from_date ?
                        new Date(props?.calanderValue?.value?.from_date) : new Date(),
                    endDate: props?.calanderValue?.value?.to_date ?
                        new Date(props?.calanderValue?.value?.to_date) : new Date(),
                }}
                definedRanges={[]}
            />
        </Popover>
    </div >);
};
export default withNamespaces("accounts")(SubheaderComponent)
