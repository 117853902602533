import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { CreateEditInvoiceDrawerStyle } from './style'
import { FreeTextInvoiceContext } from './freeTextInvoiceContext'
import { NewFormStep } from '../../components/newFormSteps'
import { loadOptions, loadOptionsApis } from '../../utils/asyncPaginateLoadOptions'
import { PropertyUnitSelect } from './components/propertyUnitSelect'
import { AGREEMENT_NUMBER } from '../../graphql/freeTextInvoice'
import { wrapLabelsInT } from '../../utils'

export const CreateEditInvoiceDrawer = () => {
    const classes = CreateEditInvoiceDrawerStyle()
    const {
        freeTextData,
        setFreeTextData,
        updateState,
        updateStateData,
        stateData,
        handleNext,
        selectedCompany,
        getFreeTextInvoiceEnum,
        handleAgreementSelect,
        handleLoadMorePropertyUnit,
        handleSelectPropertyUnit,
        handlePropertyUnitSearch,
        enumValue,
        handleOnClose,
        handleChangeTransactionType,
        handlePeriodFrom,
        handlePeriodTo,
        t,
        initialType
    } = React.useContext(FreeTextInvoiceContext)



    React.useEffect(() => {
        if (initialType === "create") {
            getFreeTextInvoiceEnum("create")
        }
        // eslint-disable-next-line
    }, [])

    const manualAgreementResponse = (array) => array?.map(_ => {
        return {
            ..._,
            label: _?.label,
            value: _?.value,
        }
    });



    const manualAccountResponse = (array) => array?.list?.map?.((i) => {
        return {
            ...i,
            label: i?.name,
            value: i?.id,
            url: i?.logo
        }
    })

    const manualResponseContract = (array) => array?.contracts?.map((i) => {
        return {
            ...i,
            label: i?.contract_no,
            value: i?.contract_id
        }
    })

    const handleAccountType = (value) => {
        setFreeTextData({ ...freeTextData, selectedAccountType: value, selectedAccount: "", selectedAgreement: "" })
    }

    console.log(enumValue?.paymentTerms);
    return (
        <Box className={classes.drawerBox}>
            <Box className={classes.header}>
                <Stack direction={"row"} alignItems={"center"} p={1.5}>
                    <Typography className={classes.drawerCloseBtn}
                        onClick={() => handleOnClose()}>X</Typography>
                    <Typography className={classes.drawerHeading} sx={{ marginInlineStart: 2 }}>
                        {stateData?.type === "view" ? t("View Free Text Transaction") : stateData?.type === "edit" ? t("Edit Free Text Transaction") : t("Create Customer Invoice")}
                    </Typography>
                </Stack>
                <Divider></Divider>
            </Box>
            <Box className={classes.body}>
                <Box p={2}>
                    {
                        stateData?.step === 1 &&
                        <NewFormStep component={[
                            {
                                type: "headingText",
                                heading: t("General Details"),
                                isActive: true,
                                sm: 12,
                                md: 12,
                                lg: 12,
                            },
                            {
                                type: "typeCard",
                                data: enumValue?.transactionType.filter(option => option.value.toLowerCase() !== 'debit'),
                                selected: freeTextData?.selectedTransactionType,
                                handleClick: (value) => handleChangeTransactionType(value),
                                header: t("Transaction Type"),
                                error: freeTextData?.error?.selectedTransactionType?.length > 0,
                                errorMsg: freeTextData?.error?.selectedTransactionType,
                                isActive: true,
                                isRequired: true,
                                isReadOnly: stateData?.type === "view"
                            },
                            {
                            type: "typeCard",
                            data: stateData?.accountType.filter(option => option.value !== 'Vendor'),
                            selected: freeTextData?.selectedAccountType,
                            handleClick: (value) => handleAccountType(value),
                            header: t("Choose Account Type"),
                            error: freeTextData?.error?.selectedAccountType?.length > 0,
                            errorMsg: freeTextData?.error?.selectedAccountType,
                            isActive: true,
                            isRequired: true,
                            isReadOnly: stateData?.type === "view",
                            },
                            {
                                type: "multiLineText",
                                header: t("Description"),
                                isRequired: true,
                                value: freeTextData?.description,
                                handleChange: (value) => updateState("description", value),
                                placeholder: t("Type description here"),
                                isActive: true,
                                sm: 12,
                                md: 12,
                                lg: 12,
                                error: freeTextData?.error?.description?.length > 0,
                                errorMsg: freeTextData?.error?.description,
                                isReadOnly: stateData?.type === "view"
                            },
                            {
                                type: "selectBox",
                                header: t("Invoice Type"),
                                isActive: freeTextData?.selectedTransactionType?.value !== "Credit",
                                options: enumValue?.invoice_type ?? [],
                                value: freeTextData?.invoice_type,
                                handleChange: (value) => updateState("invoice_type", value),
                                placeholder: t("Select Invoice Type"),
                                isrequired: true,
                                sm: 12,
                                md: 12,
                                lg: 12,
                                error: freeTextData?.error?.invoice_type?.length > 0,
                                errorMsg: freeTextData?.error?.invoice_type,
                                isReadOnly: stateData?.type === "view"
                            },
                            {
                                type: "datePicker",
                                isActive: false,
                                header: t("Due Date"),
                                placeholder: t("Select Due Date"),
                                value: freeTextData?.dueDate,
                                handleChange: (value) => updateState("dueDate", value),
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isrequired: true,
                                isReadOnly: stateData?.type === "view"
                            },
                            {
                                type: "datePicker",
                                isActive: false,
                                header: t("Period From"),
                                placeholder: t("Select Period From"),
                                value: freeTextData?.periodFrom,
                                handleChange: (value) => handlePeriodFrom(value),
                                sm: 6,
                                md: 6,
                                lg: 6,
                                isrequired: true,
                                isReadOnly: stateData?.type === "view"
                            },
                            {
                                type: "datePicker",
                                isActive: false,
                                header: t("Period To"),
                                placeholder: t("Select Period To"),
                                value: freeTextData?.periodTo,
                                handleChange: (value) => handlePeriodTo(value),
                                sm: 6,
                                md: 6,
                                lg: 6,
                                isrequired: true,
                                isReadOnly: stateData?.type === "view"
                            },
                            {
                                type: "chooseCardWithSelect",
                                header: t("Customer Account"),
                                selectBoxHeader: t("Agreement"),
                                placeholder: t("Search Account"),
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "account/get_all",
                                        {
                                            company_id: selectedCompany?.value,
                                            type: freeTextData?.selectedAccountType?.value === "Vendor" ?
                                                ["Property Owner", "Sales Broker", "Vendor", "Service Provider"] :
                                                ["Customer", "Walk In Account"]
                                        },
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {},
                                        manualAccountResponse
                                    ),
                                onchange: (value) => setFreeTextData({
                                    ...freeTextData, selectedAccount: value, selectedAgreement: "",
                                    error: { ...freeTextData?.error, selectedAccount: "" }
                                }),
                                onDelete: () => updateState('selectedAccount', ""),
                                value: freeTextData?.selectedAccount,
                                secPlaceholder: t("Select Agreement"),
                                secLoadOptions: (search, array, handleLoading) =>
                                    loadOptions(
                                        search,
                                        array,
                                        handleLoading,
                                        AGREEMENT_NUMBER,
                                        "agreement",
                                        { agreement_id: freeTextData?.selectedAccount?.account_no },
                                        {},
                                        {},
                                        manualAgreementResponse
                                    ),
                                secIsReadOnly: stateData?.type === "view",
                                secOnchange: (value) => handleAgreementSelect(value),
                                secValue: freeTextData?.selectedAgreement,
                                isRequired: true,
                                isActive: freeTextData?.selectedAccountType?.value === "Customer",
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isError: freeTextData?.error?.selectedAccount?.length > 0,
                                errorMsg: freeTextData?.error?.selectedAccount,
                                isSecError: freeTextData?.error?.selectedAgreement?.length > 0,
                                errorSecMsg: freeTextData?.error?.selectedAgreement,
                                isReadOnly: stateData?.type === "view",
                            },
                            {
                                type: "chooseCardWithSelect",
                                header: t("Vendor Account"),
                                selectBoxHeader: t("Contract"),
                                placeholder: t("Search Account"),
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "account/get_all",
                                        {
                                            company_id: selectedCompany?.value,
                                            type: freeTextData?.selectedAccountType?.value === "Vendor" ?
                                                ["Property Owner", "Sales Broker", "Walk In Account", "Vendor", "Service Provider"] :
                                                ["Customer"]
                                        },
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {},
                                        manualAccountResponse
                                    ),
                                onchange: (value) => setFreeTextData({ ...freeTextData, selectedAccount: value, selectedAgreement: "" }),
                                onDelete: () => updateState('selectedAccount', ""),
                                value: freeTextData?.selectedAccount,
                                secPlaceholder: t("Select Contract"),
                                secLoadOptions: (search, array, handleLoading) => loadOptionsApis(
                                    "free_text_invoice/contract_account",
                                    {
                                        "account_no": [freeTextData?.selectedAccount?.account_no]
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                    manualResponseContract
                                ),
                                secIsReadOnly: stateData?.type === "view",
                                isReadOnly: stateData?.type === "view",
                                secOnchange: (value) => handleAgreementSelect(value),
                                secValue: freeTextData?.selectedAgreement,
                                isRequired: true,
                                isActive: freeTextData?.selectedAccountType?.value === "Vendor",
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isError: freeTextData?.error?.selectedAccount?.length > 0,
                                errorMsg: freeTextData?.error?.selectedAccount,
                                isSecError: freeTextData?.error?.selectedAgreement?.length > 0,
                                errorSecMsg: freeTextData?.error?.selectedAgreement
                            },
                            {
                                type: "customToggleButton",
                                header: t("Payment Terms"),
                                list: wrapLabelsInT(enumValue?.paymentTerms, t),
                                value: freeTextData?.selectedPaymentTerms,
                                onSelect: (value) => updateState("selectedPaymentTerms", value),
                                isRequired: true,
                                isActive: true,
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isReadOnly: stateData?.type === "view",
                                error: freeTextData?.error?.selectedPaymentTerms?.length > 0,
                                errorMsg: freeTextData?.error?.selectedPaymentTerms,
                            }
                        ]} />
                    }
                    {
                        stateData?.step === 2 &&
                        <PropertyUnitSelect
                            list={stateData?.propertyUnitList}
                            handleLoadMorePropertyUnit={handleLoadMorePropertyUnit}
                            onselect={(value) => handleSelectPropertyUnit(value)}
                            value={freeTextData?.selectedPropertyUnit}
                            search={(value) => handlePropertyUnitSearch(value)}
                            searchText={freeTextData?.propertyUnitSearchText}
                            error={freeTextData?.error?.selectedPropertyUnit?.length > 0}
                            errorMsg={freeTextData?.error?.selectedPropertyUnit}
                            isReadOnly={stateData?.type === "view"}
                            t={t} />
                    }
                </Box>
            </Box>

            <Box className={classes.bottom} sx={{ width: "100%" }}>
                <Stack direction={"row"} justifyContent={"space-between"}
                    alignItems={"center"} p={1}>

                    {
                        stateData?.step > 1 &&
                        <Button
                            variant='contained'
                            className={classes.previous}
                            disableElevation
                            onClick={() => updateStateData("step", stateData?.step - 1)}
                        >
                            {t("Previous")}
                        </Button>
                    }

                    <Button
                        className={classes.next}
                        fullWidth={stateData?.step === 1 ? true : false}
                        onClick={handleNext}
                        variant='contained'>
                        {t("Next")}
                    </Button>
                </Stack>
            </Box>

        </Box>
    )
}