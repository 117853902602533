import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold, getSummery } from "../../utils";
const useStyles = makeStyles((theme) => ({
    head: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: "#98A0AC",
        padding: "12px"
    },
    dis: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "#4E5A6B",
        padding: "12px"
    },
    qty: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "#4E5A6B"
    },
    amount: {
        fontSize: "0.875rem",
        fontFamily: Bold,
        color: "#091B29",
        padding: "12px",
        direction: "ltr"
    },
    count: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "#FF9340",
        padding: "12px",
        direction: "ltr"
    }
}));
export const QuotationSummery = ({ datas, symbol = "$", unit = [], total, height = "", t }) => {

    // const data = [
    //     {
    //         description: t("Total Amount"),
    //         qty: `${unit?.length} Nos`,
    //         amount: `${symbol} ${datas?.totalAmount?.toFixed(2)}`
    //     },
    //     {
    //         description: t("Total Refundable"),
    //         qty: "0%",
    //         amount: `${symbol} ${datas?.totalrefundableTax?.toFixed(2)}`
    //     },
    //     {
    //         description: t("Total Tax"),
    //         qty: "0%",
    //         amount: `${symbol} ${datas?.totalTax?.toFixed(2)}`
    //     }
    // ]
    const classes = useStyles()
    return (
        <>
            <div style={{ height: `calc(100vh - ${height ? height : "465px"})`, overflow: "scroll" }}>
                <TableContainer sx={{ backgroundColor: "#F5F7FA" }}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow sx={{ borderBottom: "2px solid #EBEEF3" }}>
                                <TableCell className={classes.head} align="left">{t("DESCRIPTION")}</TableCell>
                                <TableCell className={classes.head} align="center"></TableCell>
                                <TableCell className={classes.head} align="right">{t("AMOUNT")}</TableCell>
                            </TableRow>

                        </TableHead>

                        <TableBody  >

                            {getSummery(unit?.flatMap((x) => x?.pricing), t, symbol, datas)?.filter((c) => c?.is_active)?.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: "2px solid #EBEEF3" }}
                                >

                                    <TableCell align="left" className={classes.dis}>{row?.label}</TableCell>
                                    <TableCell align="center" className={classes.qty}></TableCell>
                                    <TableCell align="right" className={classes.amount}>{row?.amount}</TableCell>

                                </TableRow>
                            ))}

                            <TableRow sx={{ borderTop: "2px solid #EBEEF3", borderBottom: "none" }}>
                                <TableCell className={classes.count} align="left">{t("Discount")}</TableCell>
                                <TableCell className={classes.count} align="center"></TableCell>
                                <TableCell className={classes.count} align="right">- {symbol} {datas?.totalDiscount?.toFixed(2)}</TableCell>
                            </TableRow>



                        </TableBody>
                        <br />

                    </Table>
                </TableContainer>
            </div >
            <TableContainer sx={{ backgroundColor: "#F5F7FA" }}>
                <Table size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow sx={{ borderTop: "2px solid #EBEEF3", borderBottom: "none" }}>
                            <TableCell className={classes.amount} align="left">{t("Total")}</TableCell>
                            <TableCell className={classes.amount} align="center"></TableCell>
                            <TableCell className={classes.amount} align="right">{symbol} {total?.toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}