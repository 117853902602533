import { Box, Button, Divider, Stack, Typography } from "@mui/material"
import React from "react"
import { FormGenerator, UploadComponent } from "../../../components"
import { CloseIconWithBG } from "../../../assets"
import { useStyles } from "../style"
import { AlertProps, NetWorkCallMethods, enumSelect, enum_types, useWindowDimensions } from "../../../utils"
import { ItemOptionList, StatusOptionList } from "../../../utils/insepectionMaster"
import { AlertContext } from "../../../contexts"
import { NetworkCall } from "../../../networkcall"
import { config } from "../../../config"
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions"

export const CheckListCreateForm = ({ t, groupType = "Unit", onClose = () => false, data = [], setData = () => false, reload = () => false, group_id = undefined }) => {
    const classes = useStyles()
    const size = useWindowDimensions()
    const alert = React.useContext(AlertContext);
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const [assets, setAssets] = React.useState([])
    const [itemTypeOptions, setItemTypeOptions] = React.useState([])
    React.useEffect(() => {
        getEnum()
        if(data?.is_Edit){
            getCheckListViewDetails()
        }
        //eslint-disable-next-line
    }, [])
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_item_type])
        setItemTypeOptions(result?.inspection_item_type?.filter((val) => { return val?.value !== "Product" }))
    }
    //get check list view  details
    const getCheckListViewDetails = () => {

        const payload = {
            id: data?.id
        }

        NetworkCall(
            `${config.api_url}/check_list/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const value = response?.data
            let editData = {
                id: value?.id,
                check_list_name:{label:value?.feed_back_name,value:value?.feed_category_id},
                url: value?.url,
                is_mandatory: value?.is_mandatory,
                is_hardware: value?.is_software_hardware,
                instructions: value?.instructions,
                item_type:value?.inspection_item_type!==null?{label:value?.inspection_item_type,value:value?.inspection_item_type}:"",
                status: value?.is_active
            }
            setData({ ...data, ...editData })
            setAssets(value?.assets?.length>0?value?.assets:[])
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    //update state of master
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        if (key === "item_type") {
            setData({ ...data, [key]: value, error, "item_category": "", "item_subcategory": "",check_list_name:"" });
        }
        else if (key === "item_category") {
            setData({ ...data, [key]: value, error, "item_subcategory": "" });
        }
        else {
            setData({ ...data, [key]: value, error });
        }
    };
    const validateForm = () => {
        let isValid = true;
        let error = data.error;
        if (groupType!=="Unit"&&data?.item_type?.length === 0) {
            isValid = false;
            error.item_type = `${t("Item type is required")}`;
        }
        if (data?.check_list_name?.length === 0) {
            isValid = false;
            error.check_list_name = `${t("Check List Name is required")}`;
        }
        // if (data?.url?.length === 0) {
        //     isValid = false;
        //     error.url = `${t("URL is required")}`;
        // }
        setData({ ...data, error });
        return isValid;
    }
    const handleSubmit = () => {
        if (validateForm()) {
            setIsDisableBtn(true)
            const payload = {
                "upsert_fields": {
                    "id":data?.is_Edit?data?.id:undefined,
                    "group_id": group_id,
                    "feed_category_id": data?.check_list_name?.value,
                    "name": data?.check_list_name?.label,
                    "is_mandatory": data?.is_mandatory,
                    "inspection_item_type":data?.item_type?.value??undefined,
                    "is_software_hardware": data?.is_hardware,
                    "url": data?.url,
                    "instructions": data?.instructions,
                    "is_active": data?.status,
                    "assets": JSON.stringify(assets)
                }
            }
            NetworkCall(
                `${config.api_url}/check_list/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((res) => {
                    onClose()
                    setIsDisableBtn(false)
                    reload()
                    alert.setSnack({
                        ...alert, open: true, msg: data?.is_Edit ? t("Check List Updated Successfully") : t("Check List Created Successfully"),
                        severity: AlertProps.severity.success
                    })
                })
                .catch((error) => {
                    onClose()
                    setIsDisableBtn(false)
                    if (error.response) {

                        // Request made and server responded
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.center,
                        });

                    }
                    else {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("Something went wrong please try again"),
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.center,
                        });
                    }

                });
        }
    }
    const maualResponse = (val) => {
        let result = val?.map((val) => {
            return {
                value: val?.id,
                label: val?.name
            }
        })
        return result;
    }
    const changeInput = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: t("Item Type"),
            placeholder: t("Select Item type"),
            value: groupType === "Unit" ? { label: "Inspection", value: "Inspection" } : data?.item_type,
            onChange: (value) => updateState("item_type", value),
            isReadonly:groupType === "Unit"?true:false,
            color:groupType === "Unit" ? "#F6F6F6" : "white",
            error: data?.error?.item_type,
            isRequired: true,
            // color:"#F6F6F6",
            options: itemTypeOptions,
            menuOptionHeight: "150px",
            menuPlacement: "bottom"

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: t("Check List Name"),
            placeholder: t("Select Check List Name"),
            value: data?.check_list_name,
            isRequired: true,
            onChange: (value) => updateState("check_list_name", value),
            error: data?.error?.check_list_name,
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "inspection_feedback_category",
                    {
                        type:[data?.item_type?.value]
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    maualResponse
                ),
            key: JSON.stringify(data?.item_type),
            debounceTimeout: 800,
            isPaginate: true,
            // isReadonly:true,
            // color:"#F6F6F6",
            menuOptionHeight: "150px",
            menuPlacement: "bottom"

        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "toggle",
            label: t("Status"),
            placeholder: t("status"),
            value: data?.status,
            onChange: (value) => updateState("status", value),
            error: data?.error?.status,
            isRequired: true,
            options: StatusOptionList(t),
            isReadonly: data?.isView
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "toggle",
            label: t("Is It Mandatory Check List"),
            placeholder: t("status"),
            value: data?.is_mandatory,
            onChange: (value) => updateState("is_mandatory", value),
            error: data?.error?.is_mandatory,
            isRequired: true,
            options: ItemOptionList(t),
            isReadonly: data?.isView
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "toggle",
            label: t("Is It Software & Hardware"),
            placeholder: t("status"),
            value: data?.is_hardware,
            onChange: (value) => updateState("is_hardware", value),
            error: data?.error?.is_hardware,
            isRequired: true,
            options: ItemOptionList(t),
            isReadonly: data?.isView
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("URL"),
            placeholder: t("Enter url"),
            value: data?.url,
            // isRequired: true,
            onChange: (value) => updateState("url", value.target.value),
            isReadonly: data?.isView,
            // error: data?.error?.url,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("Instructions"),
            placeholder: t("Enter Instructions"),
            multiline: true,
            value: data?.instructions,
            onChange: (value) => updateState("instructions", value.target.value),
            isReadonly: data?.isView,
        },
    ]
    return (
        <Box>
            <Stack direction="row" spacing={2} alignItems="center" p={1.5} sx={{backgroundColor:"#F2F4F7"}}>
                <Box onClick={onClose} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
                <Typography className={classes.drawerHeader}>{data?.is_Edit ? t("Edit Check List") : t("Create New Check List")}</Typography>
            </Stack>
            <Divider />
            <Box p={2} width="550px" height={size?.height - 120} overflow="scroll" position={"relative"}>
                <FormGenerator t={t} components={changeInput} />
                <Box mt={1}>
                    <UploadComponent
                        // readOnly={type?.label === resourceTypes?.users && data?.is_exisit}
                        handleChange={(val) => updateState("assets", val)}
                        logo_title={t("Documents")}
                        errorMessage={data?.error?.assets}
                        isError={data?.error?.assets?.length > 0}
                        assets={assets}
                        setAssets={setAssets}
                        xs={6}
                        height={"190px"}
                        accept={"image/*,.pdf"}
                        imageHeight={"188px"}
                        readOnly={data?.isView}
                        border={true}
                    />
                </Box>
            </Box>
            <Box className={classes.fixedBottom}>
                <Button sx={{ height: "40px" }} variant="contained" fullWidth onClick={handleSubmit} disabled={isDisableBtn}>{data?.is_Edit ? t("Update") : t("Create")}</Button>
            </Box>
        </Box>
    )
}