import { useApolloClient } from '@apollo/client';
import { Box, Button, Drawer, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from 'react';
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { CheckCircleIcon } from '../../assets/checkCircleIcon';
import { TrackClockIcon } from '../../assets/trackClockIcon';
import { AccordianWithDetails, AlertDialog, IframeViwer, LoadingSection, Subheader, TemplateGenerator, TextBox, VerticalLinearStepper } from "../../components";
import AssignModal from "../../components/assignModal/reAssignModal";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { updateAgreementInspections } from '../../graphql/inspectionTemplate';
import { NetworkCall } from "../../networkcall";
import { AlertProps, JobType, NetWorkCallMethods, RemoveZUTCDateTime, accessCheckRender, getRoutePermissionNew } from "../../utils";
import InspectionRequestCard from "./components/card";
import { GatePassModal } from "./components/gatePassModal";
import { useStyles } from "./style";
import Close from '@mui/icons-material/Close';
import NoDataFound from '../../assets/noData';
const InspectionRequest = ({ t }) => {
    const alert = React.useContext(AlertContext);
    const [open, setOpen] = useState(false)
    const client = useApolloClient()
    const { state } = useLocation()
    const [isBilledHour, setIsBilledHour] = React.useState(false)
    const auth = React.useContext(AuthContext);
    const classes = useStyles()
    const history = useHistory();
    const [details, setDetails] = useState([])
    const [showTrack, setShowTrack] = useState(false)
    const [itemList, setItemList] = useState([
        {
            item: "",
            expand: [
                {
                    moveIn: {},
                    moveOut: {}
                }
            ],
        }
    ])
    const [assignModal, setAssignModal] = useState(false)
    const [permissions, setPermission] = React.useState({})
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = React.useState(true)
    const [disable, setDisable] = React.useState(false)
    const [cancelModal, setCancelModal] = useState(false)
    const [inspectionStatus, setInspectionStatus] = useState(state.status)
    const [description, setDescription] = useState("")
    const [notesData, setNotesData] = useState({
        managerNotes: [],
        inspectorNotes: []
    })
    const [template, setTemplate] = React.useState({
        bool: false,
        type: ""
    });
    const [pdf, setPdf] = React.useState({
        bool: false,
        data: ""
    })
    const [openpdf, setOpenpdf] = React.useState(false);
    const backdrop = React.useContext(BackdropContext)
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    //goback
    const goBack = () => {
        history.goBack()
    }
    //initial load
    React.useEffect(() => {
        if (auth) {
            const perm = getRoutePermissionNew(auth);
            if (perm) {
                setPermission(perm)
            }
        }
        // eslint-disable-next-line
    }, [auth])

    //get tableData 
    const getAgreementInspectionDetail = () => {
        setLoading(true)
        let payload = {}
        payload = {
            "id": state?.id,
            "agreement_inspection_id": state?.agreement_inspection_id,
            "agreement_id": state?.agreement_id,
        }

        state.type === "unit_handover" && (
            payload.type_in = state.type
        )
        NetworkCall(
            `${config.api_url}/agreement_inspection/get_agreement_inspection_detail`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setDetails(response?.data?.data?.detail)
                setNotesData({ managerNotes: response?.data?.data?.detail?.inspection?.inspection_manager_notes ?? [], inspectorNotes: response?.data?.data?.detail?.inspection?.inspector_notes ?? null })
                if (!state?.data?.is_parking_component && !state?.data?.is_parking_unit) {
                    setItemList({
                        list: response?.data?.data?.list,
                        utility: response?.data?.data?.utility,
                        unitDetails: { ...response?.data?.data?.unit_based, property_name: response?.data?.data?.unit_details?.property_name, unit_type: response?.data?.data?.unit_details?.unit_type, address: response?.data?.data?.unit_details?.city }
                    })
                }
                setLoading(false)
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
                setLoading(false)
            })
    }
    //initial load
    React.useEffect(() => {
        getAgreementInspectionDetail()
        // eslint-disable-next-line
    }, [])
    const handleStatusClick = () => {
        setAssignModal(true)
    }
    //on download pdf
    // const downloadPdf = () => {
    //     if (details?.inspection?.asset_url) {
    //         saveAs(details?.inspection?.asset_url, `${details?.inspection?.reference_id}.pdf`)
    //     }
    // }
    const generate = async (value) => {
        setOpenpdf(true)
        await backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "...Loading",
        });
        const payload = {
            agreement_inspection_id: state?.agreement_inspection_id,
            type_master_id: value?.value

        };
        await NetworkCall(
            state?.type !== "unit_handover" ? `${config.api_url}/move-in-out/template` : `${config.api_url}/general_inspection/template`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setPdf({
                    bool: true,
                    data: res?.data
                })
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            })
            .catch((err) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "some thing went wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    }
    //handleClose Assign Modal
    const handleClose = () => {
        setAssignModal(false)
    }
    const reload = () => {
        getAgreementInspectionDetail()
    }
    const SwitchButtons = (val) => {
        switch (val) {
            case "Yet to assign":
                return (
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                        <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box>
                        {state.type === "unit_handover" &&
                            <Button
                                variant="outlined"
                                className={classes.cancelButton}
                                onClick={() => setCancelModal(true)}
                            >{"Cancel"}
                            </Button>
                        }
                    </Stack>
                )
            case "Assigned":
                return (<Stack direction="row" spacing={2} alignItems="center">
                    <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box>
                    {state.type === "unit_handover" &&
                        <Button
                            variant="outlined"
                            className={classes.cancelButton}
                            onClick={() => setCancelModal(true)}
                        >{t("cancel")}
                        </Button>}</Stack>)
            case "Yet To Verify":
                return (<Stack direction="row" spacing={2} alignItems="center">
                    <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box>
                    {state.type === "unit_handover" &&
                        <Button
                            variant="outlined"
                            className={classes.cancelButton}
                            onClick={() => setCancelModal(true)}
                        >{t("cancel")}
                        </Button>}
                    <Button
                        variant="contained"
                        className={classes.accoCardButton}
                        onClick={() => details?.inspection_detail?.billable_hour === null ? setIsBilledHour(true) : setShowModal(true)}>{t("completeInspection")}
                    </Button>
                </Stack>)
            case "Completed":
                return (<Stack direction="row" spacing={2} alignItems={"center"}>
                    <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box>
                    <Button
                        variant="contained"
                        // onClick={downloadPdf}
                        onClick={() => setTemplate({
                            bool: true,
                            type: 'view'
                        })}
                        className={classes.accoCardButton}
                    >{t("downloadInspection")}
                    </Button>
                </Stack>)
            default:
                return <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box>;
        }
    }
    const CompleteInspection = (status) => {
        setDisable(true)
        const payload = {
            "agreement_unit_id": details?.inspection?.agreement_unit_id,
            "inspection_id": state?.agreement_inspection_id,
            request_id: details?.inspection?.request_id,
            type: state?.type,
            description: description
        }
        NetworkCall(
            `${config.api_url}/move-in-out/complete_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            if (status === "Completed") {
                const payload = {
                    agreement_inspection_id: state.agreement_inspection_id
                }
                NetworkCall(
                    `${config.api_url}/move-in-out/get_statement`,
                    NetWorkCallMethods.post,
                    payload,
                    null,
                    true,
                    false
                ).then((res) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: `${(status === "Rejected" ? t("Cancelled") : t("Completed"))} ${t("Successfully")}`,
                    })
                    setInspectionStatus(status)
                    getAgreementInspectionDetail()
                    setCancelModal(false)
                    setShowModal(false)
                    setDisable(false)
                }).catch((err) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Cannot Generate Pdf"),
                    })
                    setDisable(false)
                })
            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${(status === "Rejected" ? t("Cancelled") : t("Completed"))} ${t("Successfully")}`,
                })
                setInspectionStatus(status)
                getAgreementInspectionDetail()
                setCancelModal(false)
                setShowModal(false)
                setDisable(false)
            }


        }).catch((err) => {
            console.log(err)
        })
    }
    const CancelInspection = (status) => {
        const payload = {
            description: description,
            "agreement_inspection_id": state?.agreement_inspection_id,
        }
        NetworkCall(
            `${config.api_url}/agreement_inspection/cancel_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${JobType(state?.type)} ${t("Inspection Cancelled Successfully")}`,
                });
                setCancelModal(false)
                setInspectionStatus(status)
                getAgreementInspectionDetail()
                setCancelModal(false)
                setShowModal(false)
                setDisable(false)
            }).catch((err) => {
                setDisable(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong.."),
                });
                setLoading(false)
            })

    }
    //reassign submit
    const reAssign = (payload) => {
        setIsDisableBtn(true)
        NetworkCall(
            `${config.api_url}/agreement_inspection/re_assign_resource`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Re Assigned Successfully"),
                });
                handleClose()
                getAgreementInspectionDetail()
            }).catch((err) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
            })
    }
    const onButtonClick = (title) => {
        if (details?.user?.is_active === false && title === "Tenant Login") {
            const payload = {
                "agreement_unit_id": details?.inspection?.agreement_unit_id,
                "inspection_id": state?.agreement_inspection_id
            }
            NetworkCall(
                `${config.api_url}move-in-out/tenant_login_send`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((response) => {
                let msg = response?.data?.message
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${msg}`,
                })


            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                })
            })
        }
        else if (title === "Gate Pass") {
            setOpen(true)
        }
    }
    // const dialogHeader = () => {
    //     return (
    //         <Stack direction="row" spacing={1}>
    //             {/* <Box onClick={handleClose} className={classes.iconbtn} display="flex" alignItems="center">
    //                 <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
    //             </Box> */}
    //             <Typography className={classes.dialoghdrtext}>{t("reAssignInspector")}</Typography>
    //         </Stack>
    //     )
    // }
    const onUpdateNotes = (val) => {
        setIsDisableBtn(true)
        const payload = {
            id: state?.agreement_inspection_id,
            update: {
                inspection_manager_notes: [...notesData?.managerNotes, { notes: val }],
            }
        }
        client.mutate({
            mutation: updateAgreementInspections,
            variables: payload
        }).then((res) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Notes Added Successfully"),
            });
            setCancelModal(false)
            getAgreementInspectionDetail()
        }).catch((err) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
            });
        })
    }

    const handleChange = (value) => {
        setDescription(value)
    }
    const render = () => {
        return (

            <Box>
                <Subheader goBack={goBack} title={state.title} />
                {
                    loading ?
                        <LoadingSection top="20vh" message={"Fetching Details"} />
                        :

                        <Box className={classes.content}>
                            <Box m={2}>
                                <InspectionRequestCard t={t} inspectionStatus={inspectionStatus} setAssignModal={() => {
                                    setAssignModal(true)
                                }}
                                    details={details} handleStatusClick={() => handleStatusClick()} onButtonClick={onButtonClick} reload={reload}/>
                                <Box className={classes.root} mt={2}>
                                    <Stack direction={"row"} justifyContent={"space-between"} m={2}>
                                        <Typography className={classes.accoCardTitle}>{t("inspectionDetails")}</Typography>
                                        <Box>{SwitchButtons(inspectionStatus)}</Box>
                                    </Stack>

                                    <Box p={2} marginTop="-24px">
                                        <AccordianWithDetails t={t} state={state?.data} unitDetails={itemList?.unitDetails} data={itemList?.list} MoveIn={true} MoveOut={state.type === "move-out" && true} notesData={notesData} setNotesData={setNotesData} onUpdateNotes={onUpdateNotes} status={inspectionStatus} isDisableBtn={isDisableBtn} />
                                    </Box>

                                    {itemList?.utility?.length > 0 &&
                                        <>
                                            {/* <Stack direction={"row"} justifyContent={"space-between"} m={2}>
                                                <Typography className={classes.accoCardTitle}>{t("utilities")}</Typography>

                                            </Stack> */}
                                            <Box p={2} marginTop="-24px">
                                                <AccordianWithDetails t={t} state={state?.data} data={itemList?.utility} utility={true} />
                                            </Box>
                                        </>
                                    }
                                </Box>
                            </Box>
                        </Box>
                }
                <Drawer
                    anchor={"right"}
                    open={assignModal}
                    onClose={handleClose}
                >
                    <AssignModal
                        header={t("Re-Assign Resource")}
                        agreement_inspection_id={state.agreement_inspection_id}
                        reassign={true}
                        moduleName={t("moveInInspectionRequest")}
                        handleClose={handleClose}
                        buttonName={t("Re-Assign")}
                        onSubmit={reAssign}
                        isDisableBtn={isDisableBtn}
                    />

                </Drawer>
                <AlertDialog
                    open={showModal}
                    onClose={() => {
                        if (!disable) {
                            return (setShowModal(false), setDescription(""))
                        }
                    }
                    }
                    header={t("completeInspection")}
                    noTitle={true}
                    component={
                        <Box>
                            <center>
                                <Box className={classes.completeInsp}>
                                    <Box mb={10}>
                                        <TextBox label={t("Description")} placeholder={t("Enter Description")} value={description} onChange={(e) => handleChange(e.target.value)} height={100} />
                                    </Box>
                                    {/* <Typography className={classes.msg}>Are you Sure ,You want to Complete ?</Typography> */}
                                    <Grid container mt={4} spacing={1}>
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => setShowModal(false)} variant="outlined" className={classes.cancelbtn}>{t("No")}</Button>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth sx={{ color: "white" }} className={classes.cmpbtn} disabled={disable} onClick={() => CompleteInspection("Completed")} variant="contained">{t("Yes")}</Button>

                                        </Grid>
                                    </Grid>

                                </Box>
                            </center>
                        </Box>
                    }
                    isNormal
                />
                {open &&
                    <GatePassModal t={t} open={open} setOpen={setOpen} title={details?.security_request[0]?.status} request_id={details?.security_request[0]?.id} />
                }
                <AlertDialog
                    open={cancelModal}
                    onClose={() => {
                        if (!disable) {
                            return (setCancelModal(false), setDescription(""))
                        }
                    }}
                    header={t("cancelInspection")}
                    component={
                        <Box>
                            <center>
                                <Box className={classes.completeInsp}>
                                    {/* <Typography className={classes.msg}>Are you Sure ,You want to cancel ?</Typography> */}
                                    <Box mb={10} textAlign={'left'}>
                                        <TextBox label={t("Description")} placeholder={t("Enter Description")} value={description} onChange={(e) => handleChange(e.target.value)} height={100} />
                                    </Box>
                                    <Grid container spacing={1} mt={4}>
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => setCancelModal(false)} variant="outlined" className={classes.cancelbtn}>{t("No")}</Button>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth sx={{ color: "white" }} className={classes.cmpbtn} disabled={disable} onClick={() => CancelInspection("Rejected")} variant="contained">{t("Yes")}</Button>

                                        </Grid>
                                    </Grid>


                                </Box>
                            </center>
                        </Box>
                    }
                    isNormal
                />
                <AlertDialog open={isBilledHour}
                    onClose={() => setIsBilledHour(false)}
                    isnotTitle
                    medium
                    component={
                        // <CompleteInspection />
                        <Box className={classes.reqblock} p={1} alignItems="center">
                            <Box display={"flex"} justifyContent="end" onClick={() => setIsBilledHour(false)}>
                                <Close className={classes.closeicon} />
                            </Box>
                            <Grid container mt={5}>

                                {
                                    auth?.auth?.auth?.language === "ar" ? " " : <Grid item xs={4}></Grid>
                                }
                                <Grid item xs={8}>
                                    <Typography className={classes.q1hdr}>
                                        {t("Billing Hours not Updated,Do you want to Close?")}
                                    </Typography>
                                    <Box mt={1}>
                                        <Typography className={classes.q1subhdr}>
                                            {t("The Process Cannot be reverted once done")}
                                        </Typography>
                                    </Box>
                                    <Stack mt={5} ml={2} direction="row" >
                                        <Button variant="contained" fullWidth className={classes.yesbtn} onClick={() => { return (setIsBilledHour(false), setShowModal(true)) }} disabled={isDisableBtn}>{t("Yes")}</Button>
                                        <Button variant="outlined" fullWidth className={classes.notxtbtn} onClick={() => setIsBilledHour(false)}>{t("No")}</Button>

                                    </Stack>
                                </Grid>
                            </Grid >
                        </Box >
                    }

                />
                <AlertDialog
                    open={showTrack}
                    onClose={() => {
                        setShowTrack(false)
                    }
                    }
                    header={t("Track Update")}
                    isNormal
                    component={<Box p={2}>
                        {details?.inspection_tracker?.length > 0 ?
                            <VerticalLinearStepper data={details?.inspection_tracker?.map((val) => { return { name: val?.title, created_by: val?.created_by, date: RemoveZUTCDateTime(val?.created_at, "DD-MM-YYYY hh:mm A"), icon: val.title === "Inspection Completed" ? <Box className={classes.checkIconcircle}><CheckCircleIcon /></Box> : <TrackClockIcon />, notes: val?.description } })} customTrack />
                            :
                            <center>
                            <NoDataFound />
                            </center>
                        }
                    </Box>}
                />
                {/* Inspection pdf */}
                <AlertDialog
                    header={state.title}
                    onClose={() => {
                        setOpenpdf(false)
                        setPdf({
                            bool: false,
                            data: ""
                        })
                    }}
                    open={openpdf}
                    // render={renderButton()}
                    component={
                        <Box p={1} bgcolor={"#F5F7FA"}>
                            <IframeViwer pdf={pdf} height={`250px`} />
                        </Box>

                    }
                />
                <TemplateGenerator t={t} name={t("Inspection Template")} btnText={template?.type === "view" ? t("Generate") : t("Send")} open={template?.bool} onClose={() => setTemplate({
                    bool: false,
                    type: "view"
                })} type={state.type === "unit_handover" ? 7 : 6} generate={generate} />
            </Box >
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permissions)}
        </Box>
    )
}
export default withNamespaces("inspection")(InspectionRequest)