import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Aggreement, AgreementInvoices, KycDetails, LeadAgreement, ManagedInvoices } from "../../assets";
import { LoadingSection, PropertyTabs, Subheader } from "../../components";
import { AlertContext, AuthContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew } from "../../utils";
import { AccessRights, AgreementPreview, Attachements, Insepection, Invoicesection, KYC, ManagedInvoice, TimeLine } from "./sections";
import { AggrementDetailsStyles } from "./styles";
import jwtDecode from 'jwt-decode';
import moment from "moment";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import AccessRightIcon from "../../assets/accessRights";
import AttachmentIcon from "../../assets/attachment";

const AgreementDetails = ({ t = () => false }) => {
  const classes = AggrementDetailsStyles()
  const history = useHistory()
  const alert = React.useContext(AlertContext)
  const search = useLocation().search;
  const jwt = new URLSearchParams(search).get("agreement_id");
  const id = jwt ? jwtDecode(jwt)?.agreement_id : new URLSearchParams(search).get("id");
  const [value, setValue] = React.useState(1);
  const [details, setDetails] = React.useState({});
  const [loading, setLoading] = React.useState(true)
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})
  const [parentAgreement, setParentAgreement] = React.useState([])
  const [terminatedAgreement, setTerminatedAgreement] = React.useState([])


  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        getAgreementDetails()
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const tabTittle = [
    {
      label: t('agreementPreview'),
      imgSelect: <Aggreement color={value === 1 ? '#5078E1' : '#98A0AC'} />,
      value: 1,
      className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t('timelineTrack'),
      imgSelect: <LeadAgreement color={value === 2 ? '#5078E1' : '#98A0AC'} />,
      value: 2,
      className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t('kycDetails'),
      imgSelect: <KycDetails color={value === 3 ? '#5078E1' : '#98A0AC'} />,
      value: 3,
      className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t('managedInvoice'),
      imgSelect: <ManagedInvoices color={value === 4 ? '#5078E1' : '#98A0AC'} />,
      value: 4,
      className: value === 4 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 4 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 4 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t('invoice'),
      imgSelect: <ManagedInvoices color={value === 5 ? '#5078E1' : '#98A0AC'} />,
      value: 5,
      className: value === 5 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 5 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 5 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t("Access Rights"),
      imgSelect: <AccessRightIcon color={value === 6 ? '#5078E1' : '#98A0AC'} />,
      value: 6,
      className: value === 6 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 6 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 6 ? classes.subtabtitle1 : classes.subtabtitle,
      is_active: details?.agreement_type === "Sale Agreement" ? false : true
    },
    {
      label: t('inspection'),
      imgSelect: <AgreementInvoices color={value === 7 ? '#5078E1' : '#98A0AC'} />,
      value: 7,
      className: value === 7 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 7 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 7 ? classes.subtabtitle1 : classes.subtabtitle
    },    
    {
      label: t("Attachments"),
      imgSelect: <AttachmentIcon color={value === 8 ? '#5078E1' : '#98A0AC'} />,
      value: 8,
      className: value === 8 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 8 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 8 ? classes.subtabtitle1 : classes.subtabtitle
    }
  ]
  const tabTittle1 = [
    {
      label: t('agreementPreview'),
      imgSelect: <Aggreement color={value === 1 ? '#5078E1' : '#98A0AC'} />,
      value: 1,
      className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t('timelineTrack'),
      imgSelect: <LeadAgreement color={value === 2 ? '#5078E1' : '#98A0AC'} />,
      value: 2,
      className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t('invoice'),
      imgSelect: <ManagedInvoices color={value === 3 ? '#5078E1' : '#98A0AC'} />,
      value: 3,
      className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
    },
    {
      label: t("Access Rights"),
      imgSelect: <AccessRightIcon color={value === 4 ? '#5078E1' : '#98A0AC'} />,
      value: 4,
      className: value === 4 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 4 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 4 ? classes.subtabtitle1 : classes.subtabtitle,
      is_active: details?.agreement_type === "Sale Agreement" ? false : true
    },
    {
      label: t('inspection'),
      imgSelect: <AgreementInvoices color={value === 5 ? '#5078E1' : '#98A0AC'} />,
      value: 5,
      className: value === 5 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 5 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 5 ? classes.subtabtitle1 : classes.subtabtitle
    },
    
  ]
  //get agreement details
  const getAgreementDetails = () => {

    const payload = {
      id: id,
      to: `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`,
    }

    NetworkCall(
      `${config.api_url}/agreement/get_details`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      const result = response?.data?.agreement?.map((x) => {
        return {
          ...x,
          order_date: x?.order_date !== null ? moment(x?.order_date).format("DD MMM YYYY") : "-",
          order_value: x?.order_value !== null ? `${x?.quotation?.currency?.symbol} ${x?.order_value}` : "-",
          wallet_credit: x?.agreement_units?.[0]?.agreement_rent_breakup?.filter((v) => v?.pricing_component?.is_wallet)?.map((i) => {
            return {
              ...x,
              reference_id: i?.pricing_component?.reference_no,
              name: i?.pricing_component?.name,
              revenue_type: i?.pricing_component?.revenue_type,
              amount: `${x?.quotation?.currency?.symbol} ${i?.applied_value} / ${i?.period_type}`
            }
          }),
          wallet_item: x?.agreement_units?.[0]?.agreement_rent_breakup?.filter((v) => v?.is_wallet_item)?.map((i) => {
            return {
              ...x,
              reference_id: i?.inspection?.reference_no,
              name: i?.inspection?.name,
              revenue_type: i?.inspection?.item,
              amount: `${x?.quotation?.currency?.symbol} ${i?.applied_value} / ${i?.period_type}`
            }
          }),
          late_fee: x?.agreement_units?.[0]?.agreement_rent_breakup?.filter((v) => v?.pricing_component?.is_late_fee)?.map((i) => {
            return {
              ...x,
              reference_id: i?.pricing_component?.reference_no,
              name: i?.pricing_component?.name,
              revenue_type: i?.pricing_component?.revenue_type,
              amount: `${x?.quotation?.currency?.symbol} ${i?.applied_value} / ${i?.period_type}`
            }
          }),
          commission_bonus: x?.agreement_units?.[0]?.agreement_rent_breakup?.filter((v) => v?.pricing_component?.is_commission_payment)?.map((i) => {
            return {
              ...x,
              reference_id: i?.pricing_component?.reference_no,
              name: i?.pricing_component?.name,
              revenue_type: i?.pricing_component?.revenue_type,
              amount: `${x?.quotation?.currency?.symbol} ${i?.applied_value} / ${i?.period_type}`
            }
          }),
        }
      })
      const wallet_credit_summary = response?.data?.payment_schedule_data?.filter((v) => v?.pricing_component?.is_wallet)?.map((i) => {
        return {
          ...i,
          qty: i?.qty ? i?.qty : 1,
          amount: `${((i?.component_value??0) * (i?.qty ? i?.qty : 1))}`,
          total_amount: `${response?.data?.agreement?.[0]?.quotation?.currency?.symbol} ${i?.amount}`,
          discount: `${i?.discount_percentage} %`
        }
      })
      const late_fee_summary = response?.data?.payment_schedule_data?.filter((v) => v?.pricing_component?.is_late_fee)?.map((i) => {
        return {
          ...i,
          qty: i?.qty ? i?.qty : 1,
          amount: `${((i?.component_value??0) * (i?.qty ? i?.qty : 1))}`,
          total_amount: `${response?.data?.agreement?.[0]?.quotation?.currency?.symbol} ${i?.amount}`,
          discount: `${i?.discount_percentage} %`
        }
      })
      const commission_and_payments_summary = response?.data?.payment_schedule_data?.filter((v) => v?.pricing_component?.is_commission_payment)?.map((i) => {
        return {
          ...i,
          qty: i?.qty ? i?.qty : 1,
          amount: `${((i?.component_value??0) * (i?.qty ? i?.qty : 1))}`,
          total_amount: `${response?.data?.agreement?.[0]?.quotation?.currency?.symbol} ${i?.amount}`,
          discount: `${i?.discount_percentage} %`
        }
      })
      const wallet_item_summary = response?.data?.payment_schedule_data?.filter((v) => v?.is_wallet_item)?.map((i) => {
        return {
          ...i,
          qty: i?.qty ? i?.qty : 1,
          amount: `${((i?.component_value??0) * (i?.qty ? i?.qty : 1))}`,
          total_amount: `${response?.data?.agreement?.[0]?.quotation?.currency?.symbol} ${i?.amount}`,
          discount: `${i?.discount_percentage} %`
        }
      })
      setDetails({ ...result?.[0], wallet_credit_summary: wallet_credit_summary, wallet_item_summary: wallet_item_summary, late_fee_summary: late_fee_summary, commission_and_payments_summary: commission_and_payments_summary })
      const isTrue = (response?.data?.is_parent_agreement?.filter((x) => x?.agreement_status === 'Declined')?.filter((x) => x?.agreement_status === 'Terminated')) ? "" : response?.data?.is_parent_agreement?.length > 0 ? "true" : ""
      setParentAgreement(isTrue)
      setTerminatedAgreement(response?.data?.is_parent_agreement?.filter((x) => x?.agreement_status === 'Declined')?.filter((x) => x?.agreement_status === 'Terminated'))
      setLoading(false)
    }).catch((err) => {
      console.log(err)
      setLoading(false)
    })
  }
  const onSubmit = (payload) => {
    NetworkCall(
      `${config.api_url}/agreement/upsert_terms_conditions`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      getAgreementDetails()
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: t("Terms & Condition updated Successfully"),
      });
    }).catch((err) => {
      console.log(err)
    })
  }
  //on change in tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const render = () => {
    return <>
      <Subheader title={`${t("agreement_detailslow")} (${details?.agreement_no ?? ""})`} goBack={() => history.goBack()} />
      {
        loading ?
          <LoadingSection top="20vh" message={t("loading")} />
          :
          details?.quotation?.id ? <PropertyTabs backgroundColor={"white"} value={value} handleChange={handleChange} padding={'16px'}
            tab1={<AgreementPreview reload={getAgreementDetails} id={id} details={details} onSubmit={onSubmit} />}
            tab2={<TimeLine details={details} reload={getAgreementDetails} agreement_id={id} parentAgreement={parentAgreement} terminatedAgreement={terminatedAgreement} />}
            tab3={<KYC details={details} reload={getAgreementDetails} />}
            tab4={<ManagedInvoice agree={details} id={id} quot_id={details?.quotation?.id} />}
            tab5={<Invoicesection id={id} details={details} />}
            tab6={<AccessRights id={id} t={t} />}
            tab7={<Insepection id={details?.id} details={details} />}            
            tab8={<Attachements id={id} t={t} />}
            tabTittle={tabTittle}
          /> : <PropertyTabs backgroundColor={"white"} value={value} handleChange={handleChange} padding={'16px'}
            tab1={<AgreementPreview reload={getAgreementDetails} id={id} details={details} onSubmit={onSubmit} />}
            tab2={<TimeLine details={details} reload={getAgreementDetails} agreement_id={id} parentAgreement={parentAgreement} terminatedAgreement={terminatedAgreement} />}
            tab3={<Invoicesection id={id} details={details} />}
            tab4={<AccessRights id={id} t={t} />}
            tab5={<Insepection id={details?.id} details={details} />}            
            tabTittle={tabTittle1}
          />
      }</>
  }


  return (
    <Box className={classes.root}>
      {accessCheckRender(render, permission)}
    </Box>
  )
}

export default withNamespaces("agreement")(AgreementDetails);