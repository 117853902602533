import gql from "graphql-tag";

export let GET_PROPERTY_GROUP_MASTER = (offset, limit, searchText, client, company, status) => gql`
query GET_PROPERTY_BY_GROUP_MASTER {
  count: property_group_master(where: {
    company_id:{eq:${company}}
    client:{eq:"${client}"}
            ${status?.length ? `is_active:{in:[${status}]}` : ''}
     is_delete:{eq:false}
     group_name: { iregex: "${searchText}" } } ) {
    count:count_id
  }
  property_group_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc , company_master:{name:asc} }
    where: {     company_id:{eq:${company}}
                ${status?.length ? `is_active:{in:[${status}]}` : ''}

    client:{eq:"${client}"} is_delete:{eq:false} group_name: { iregex: "${searchText}" }}
  ) {
    company_master {
      name
      id
    }
    id
    group_name
    description
    purpose
    is_active
    is_external
  }
}`;

export const GET_RENTAL_BREAKUP_MASTER = (offset, limit, searchText, client) => gql`
query GET_RENTAL_BREAKUP_MASTER{
  count: pricing_component(where: {client:{eq:"${client}"} is_delete:{eq:false} name: { iregex: "${searchText}" } } ) {
    count:count_id
  }
  pricing_component(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc , name:asc }
    where: {client:{eq:"${client}"} is_delete:{eq:false} name: { iregex: "${searchText}" } }
    ){
    id
    company_master {
      name
      id
    }
    name
    revenue_type
    description
    is_active
    primary
    refundable
    is_onetime
    is_reserve_quality
    taxtable
    is_parking
    is_quantity
    is_override
    is_short_term
    is_external
    payment_period
  }
}`;

export const GET_RENTAL_BREAKUP_MASTER_STATE_DATA = (unit_name) => gql`
query GET_RENTAL_BREAKUP_MASTER_STATE_DATA{
  unit_rental_breakup(
    where:{unit : {name:  "${unit_name}"}}
  ){
       id
 	    unit(where: { is_active: { eq: true }}) {
      value: id
      label: name
      floor(where: { is_active: { eq: true } }) {
        value: id
        label: name
        block(where: { is_active: { eq: true } }) {
          value: id
          label: name
          property(where: { is_active: { eq: true } }) {
            value: id
            label: name
          }
        }
      }
    }
  }
}`;

export const GET_BLOCK_FLOOR_UNIT_OPTIONS = (propertyId, blockId, floorId) => gql`
query GET_BLOCK_FLOOR_UNIT_OPTIONS{
  block_list:
  ${propertyId ? `
  block(
    where: {
      ${propertyId ? `property_id: { eq: "${propertyId}" }` : ''}
      is_active: { eq: true }
    }
  ) {
    value:id
    label:name
  }` : ''}
  floor_list:
  ${blockId ? `
  floor(
    where: {
      ${blockId ? `block_id: { eq: "${blockId}" }` : ''}
      is_active: { eq: true }
    }
  ) {
    value:id
    label:name
  }
  ` : ''}

  unit_list:
  ${propertyId && blockId && floorId ? `
  unit(
    where: {
      ${propertyId ? `property_id: { eq: "${propertyId}" }` : ''}
      ${blockId ? `block_id: { eq: "${blockId}" }` : ''}
      ${floorId ? `floor_id: { eq: "${floorId}" }` : ''}
      is_active: { eq: true }
    }
  ) {
    value:id
    label:name
  }
  ` : ''}
}`;


export const GET_UNIT_RENTAL_BREAKUP_MASTER = (offset, limit, searchText, client) => gql`
query GET_UNIT_RENTAL_BREAKUP_MASTER{
  count: unit_rental_breakup(where: { client:{eq:"${client}"} unit: { name : { iregex: "${searchText}" } } } ) {
    count:count_id
  }
  unit_rental_breakup(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc , unit:{name:asc} }
    where: {client:{eq:"${client}"}  unit: { name : { iregex: "${searchText}" } } }
    ){
    id
    period_type
    value_basis_type
    value
    primary
    is_active
    is_external
    refundable
    payment_period
    display_percentage
    unit{
      name
    }
    vat_group_master{
      group_name
      id
    }
     currency_master{
        symbol
        id
      }
      rental_breakup_master{
        description: name
        id
      }
  }
}`;

export const GET_BLOCK_TYPE_BREAKUP_MASTER = (offset, limit, searchText, client, company, status) => gql`
query GET_BLOCK_TYPE_BREAKUP_MASTER{
  count: block_type_master(where: { company_id:{eq:${company}} ,
                ${status?.length ? `is_active:{in:[${status}]}` : ''}
    client:{eq:"${client}"} is_delete:{eq:false} name : { iregex: "${searchText}" } } ) {
    count:count_id
  }
  block_type_master(
  offset: ${offset}
  limit: ${limit}
  order_by: { created_at: desc , name:asc }
  where: { company_id:{eq:${company}} 
              ${status?.length ? `is_active:{in:[${status}]}` : ''}
  client:{eq:"${client}"} is_delete:{eq:false} name : { iregex: "${searchText}" } }
  )
    {
    id
    name
    company_master {
      name
      id
    }
    is_active
    is_external
  }
}`;

export const GET_UNIT_PURPOSE_MASTER = `
query GET_UNIT_PURPOSE_MASTER{
  unit_purpose_master(order_by: { updated_at: desc }){
    id
    type
    is_active
    is_external
  }
}`;

export const GET_CURRENCY = `
query GET_CURRENCY{
  currency_master{
    symbol
    id
    name
  }
}`;

export const GET_UNIT_TYPE_MASTER = (offset, limit, searchText, client, company, status) => gql`
query GET_UNIT_TYPE_MASTER{
  count: unit_type_master(where: {
    company_id:{eq:${company}} 
                ${status?.length ? `is_active:{in:[${status}]}` : ''}
    client:{eq:"${client}"}  is_delete:{eq:false} name : { iregex: "${searchText}" } }) {
    count:count_id
  }
  unit_type_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc , name:asc }
    where: {
      company_id:{eq:${company}}
                ${status?.length ? `is_active:{in:[${status}]}` : ''}

    client:{eq:"${client}"}  is_delete:{eq:false} name : { iregex: "${searchText}" } }
    ){
    id
    name
    company_master {
      name
      id
    }
    purpose
    is_active
    is_external
  }
}`;

export const GET_AMENITIES_TYPE_MASTER = (offset, limit, searchText, client) => gql`
query GET_AMENITIES_TYPE_MASTER{
  count: amenities_type_master(where: { client:{eq:"${client}"} amenities_name : { iregex: "${searchText}" } } ) {
    count:count_id
  }
  amenities_type_master(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc ,amenities_name:asc }
    where: {client:{eq:"${client}"}  amenities_name : { iregex: "${searchText}" } }
    ){
    id
    company_master {
      name
      id
    }
    settings
    amenities_name
    description
    notes
    is_active
    is_external
  }
}`;

export const GET_MOVEIN_MOVEOUT_MASTER = `
query GET_MOVEIN_MOVEOUT_MASTER{
  movein_moveout_ref{
    id
    movein_moveout_master{
      type
    }
    reference_id
    is_active
    is_external
  }
}`;

export const GET_COMPANY_NAME_DROPDOWN = (offset, limit, searchText, isActive = false) => gql`
query GET_COMPANY_NAME_DROPDOWN{
  count:company_master(
    where:{
      ${isActive ? `is_active:{eq:true}` : ''}
      name: { iregex: "${searchText}" }
    }){
    count:count_id
  }
  company_master(
    where:{
      ${isActive ? `is_active:{eq:true}` : ''}
      name: { iregex: "${searchText}" }
    }
    offset: ${offset}
    limit: ${limit}
    ){
    value:id
    label:name
  }
}`;

export const GET_BLOCK_TYPE = `
query GET_BLOCK_TYPE{
  block_type_master(where:{is_active:{eq:true}}){
    id
    type
  }
}`;

export const GET_PROPERTY_NAME_DROPDOWN = (offset, limit, searchText, extraParams = {}) => gql`
  query GET_PROPERTY_NAME_DROPDOWN{
    count:property(
      where:{
        ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
        ${extraParams?.company_id?.length ? `company_id:{in:[${extraParams?.company_id}]}` : ''}
        name:{iregex:"${searchText}"}
      }){
      count:count_id
    }
    property(
      where:{
        ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
        ${extraParams?.company_id?.length ? `company_id:{in:[${extraParams?.company_id}]}` : ''}
        name:{iregex:"${searchText}"}
      }
      offset:${offset}
      limit:${limit}
      order_by:{created_at:desc}
    ){
      value:id
      label:name
    }
  }`

export const GET_COMPONENT_NAME_DROPDOWN = (offset, limit, searchText, extraParams = {}) => gql`
query GET_COMPONENT_NAME_DROPDOWN{
  count:rental_breakup_master(
    where:{
      ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      name:{iregex:"${searchText}"}
    }){
    count:count_id
  }
  rental_breakup_master(
    where:{
      ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      name:{iregex:"${searchText}"}
    }
    offset:${offset}
    limit:${limit}
    order_by:{created_at:desc}
  ){
    value:id
    label:name
  }
}`;

// {
//   "propertyId":"e7a9f09a-5a24-4a23-968b-62e6c445fc55"
// }

export const GET_BLOCK_NAME = (offset, limit, searchText, extraParams = {}) => gql`
query{
  count:block(
  where:{
    ${extraParams?.property_id ? `property_id:{eq:"${extraParams?.property_id}"}` : ''}
    name:{iregex:"${searchText}"}
    ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
  }
  ){
    count:count_id
  }
block(
  where:{
    ${extraParams?.property_id ? `property_id:{eq:"${extraParams?.property_id}"}` : ''}
    name:{iregex:"${searchText}"}
    ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
  }
  offset:${offset}
  limit:${limit}
  order_by:{created_at:desc}
){
  value:id
  label:name
}
}`;

// {
//   "blockId":"18ca2262-e4a8-4a53-80c9-830eed07ad05"
// }

export const GET_FLOOR_NAME = (offset, limit, searchText, extraParams = {}) => gql`
query{
  count:floor(
    where:{
      ${extraParams?.block_id ? `block_id:{eq:"${extraParams?.block_id}"}` : ''}
      ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      name:{iregex:"${searchText}"}
    }
  ){
    count:count_id
  }
  floor(
    where:{
      ${extraParams?.block_id ? `block_id:{eq:"${extraParams?.block_id}"}` : ''}
      ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      name:{iregex:"${searchText}"}
    }
    offset:${offset}
    limit:${limit}
    order_by:{created_at:desc}
  ){
    value:id
    label:name
  }
}`;

// {
//   "property_id":"03467ed2-08a8-4387-a02d-4a7a219746fb",
//   "block_id":"55d8fb6b-9f12-4c5b-b700-c7474b2b1115",
//   "floor_id":"e4216d45-7f6a-4eae-80d5-b65182dd24b7"
// }

export const GET_UNIT_NAME = (offset, limit, searchText, extraParams = {}) => gql`
query{
  count:unit(
    where:{
      ${extraParams?.property_id ? `property_id:{eq:"${extraParams?.property_id}"}` : ''}
      ${extraParams?.block_id ? `block_id:{eq:"${extraParams?.block_id}"}` : ''}
      ${extraParams?.floor_id ? `floor_id:{eq:"${extraParams?.floor_id}"}` : ''}
      ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      name:{iregex:"${searchText}"}
    }
  ){
    count:count_id
  }
  unit(
    where:{
      ${extraParams?.property_id ? `property_id:{eq:"${extraParams?.property_id}"}` : ''}
      ${extraParams?.block_id ? `block_id:{eq:"${extraParams?.block_id}"}` : ''}
      ${extraParams?.floor_id ? `floor_id:{eq:"${extraParams?.floor_id}"}` : ''}
      ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      name:{iregex:"${searchText}"}
    }
    offset:${offset}
    limit:${limit}
    order_by:{created_at:desc}
  ){
    value:id
    label:name
  }
}
`;

export const GET_TAX_GROUP_DROPDOWN = (offset, limit, searchText, extraParams = {}) => gql`
query GET_TAX_GROUP_DROPDOWN{
  count: vat_group_master(
    where:{
      ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      group_name:{iregex:"${searchText}"}
    }){
    count:count_id
  }
  vat_group_master(
    where:{
      ${extraParams?.isActive ? `is_active:{eq:true}` : ''}
      group_name:{iregex:"${searchText}"}
    }
    offset:${offset}
    limit:${limit}
  ){
    value:id
    label:group_name
 }
}`;

export const UPDATE_PROPERTY_GROUP = `
mutation($insert: [property_group_masterOutput!]) {
  property_group_master(
    insert: $insert
  ) {
    id
  }
}`;

export const UPDATE_RENTAL_GROUP = `
mutation($insert: [rental_breakup_masterOutput!]) {
  pricing_component(
    insert: $insert
  ) {
    id
  }
}`;

export const UPDATE_UNIT_RENTAL_GROUP = `
mutation($insert: [unit_rental_breakupOutput!]) {
  unit_rental_breakup(
    insert: $insert
  ) {
    id
  }
}`;

export const UPDATE_BLOCK_TYPE = `
mutation($insert: [block_type_masterOutput!]) {
  block_type_master(
    insert: $insert
  ) {
    id
  }
}`;
export const UPDATE_UNIT_PURPOSE = `
mutation($insert: [unit_purpose_masterOutput!]) {
  unit_purpose_master(
    insert: $insert
  ) {
    id
  }
}`;
export const UPDATE_UNIT_TYPE = `
mutation($insert: [unit_type_masterOutput!]) {
  unit_type_master(
    insert: $insert
  ) {
    id
  }
}`;
export const UPDATE_AMENITIES_TYPE = `
mutation($insert: [amenities_type_masterOutput!]) {
  amenities_type_master(
    insert: $insert
  ) {
    id
  }
}`;

export const EDIT_PROPERTY_GROUP =
  `mutation($propertyId: ID, $demo: property_group_masterInput) {
  property_group_master(
    where: { id: { eq: $propertyId } }
    update: $demo
  ) {
     id
  }
}`;

export const EDIT_RENTAL_GROUP = `
mutation($rentalId: ID,$demo: rental_breakup_masterInput) {
  pricing_component(
    where: { id: { eq: $rentalId } }
    update: $demo
  ) {
    id
  }
}`;


export const EDIT_BLOCK_TYPE = `
mutation($blockId: ID,$demo: block_type_masterInput) {
  block_type_master(
    where: { id: { eq: $blockId } }
    update: $demo
  ) {
    id
  }
}`;

export const EDIT_UNIT_PURPOSE = `
mutation($unitId: ID,$demo: unit_purpose_masterInput) {
  unit_purpose_master(
    where: { id: { eq: $unitId } }
    update: $demo
  ) {
    id
  }
}`;
export const EDIT_UNIT_TYPE = `
mutation($unit_typeId: ID,$demo: unit_type_masterInput) {
  unit_type_master(
    where: { id: { eq: $unit_typeId } }
    update: $demo
  ) {
    id
  }
}`;
export const EDIT_AMENITIES_TYPE = `
mutation($amenitiesId: ID,$demo: amenities_type_masterInput) {
  amenities_type_master(
    where: { id: { eq: $amenitiesId } }
    update: $demo
  ) {
    id
  }
}`;

export const EDIT_UNIT_RENTAL_GROUP = `
mutation($unit_rentalId: ID,$demo: unit_rental_breakupInput) {
  unit_rental_breakup(
    where: { id: { eq: $unit_rentalId } }
    update: $demo
  ) {
    id
  }
}`;

export const PROPERTY_SEARCH =
  `query($search: String) {
  property_group_master(
    where: {
      company_master: { name: { ilike: $search } }
    }
  ) {
    company_master{
      name
    }
    id
    group_name
    description
    is_active
    is_external
  }
}`

export const RENTAL_SEARCH =
  `query($search: String) {
    pricing_component(
    where: {
      description:{ilike:$search}
    }
  ) {
    id
    description
    is_active
    primary
    refundable
    taxtable
    is_onetime
    is_external
    is_reserve_quality
    payment_period
  }
}`

export const UNIT_RENTAL_SEARCH =
  `query($search: String) {
  unit_rental_breakup(where: { unit: { name: { ilike: $search } } }) {
    id
    period_type
    value_basis_type
    value
    primary
    is_active
    is_external
    refundable
    payment_period
    display_percentage
    unit {
      name
    }
    vat_group_master {
      group_name
    }
    currency_master {
      symbol
    }
    rental_breakup_master {
      description
    }
  }
}`

export const BLOCK_TYPE_SEARCH = `
query($search: String) {
  block_type_master(
    where: {
      name:{ilike:$search}
    }
  ) {
    id
    name
    is_active
    is_external
  }
}
`

export const UNIT_TYPE_SEARCH = `
query($search: String) {
  unit_type_master(
    where: {
      name:{ilike:$search}
    }
  ) {
    id
    name
    is_active
    is_external
  }
}
`
export const AMENITIES_SEARCH = `
query($search: String) {
  amenities_type_master(
    where: {
      amenities_name:{ilike:$search}
    }
  ) {
    id
    amenities_name
    description
    notes
    is_active
    is_external
  }
}
`