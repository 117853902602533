import { useApolloClient } from '@apollo/client';
import { Box, Button, Divider, Grid, Menu, Radio, Stack, Typography } from '@mui/material';
import { addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subMonths } from 'date-fns';
import moment from 'moment';
import React from 'react';
import { PlusIcon } from '../../../assets';
import { CheckIcon } from '../../../assets/checkIcon';
import { AlertDialog, CalendarDateChange, TextBox } from '../../../components';
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import { GET_LEAVELIST } from '../../../graphql/timeOffRequest';
import { NetworkCall } from '../../../networkcall';
import { AlertProps, enum_types, LocalStorageKeys, NetWorkCallMethods, timeZoneConverter } from '../../../utils';
import { SwitchIcons } from '../../../utils/timeOffRequestList';
import { MonthlyCalendarStyles } from '../style';
import { CustomCalendar } from './customCalendar';
import ErrorIcon from '@mui/icons-material/Error';
import { StatusComponent } from './statusComponent';
import CancelIcon from '@mui/icons-material/Cancel';

export const MonthlyCalendar = ({ t = () => false, dates = {}, month = {}, events = {}, prev = () => false, next = () => false, leaveList = [], data = [], onSubmit = () => false, isShow = false, setIsShow = () => false, isDisableBtn = false, type, setType = () => false,reload=()=>false }) => {
    const classes = MonthlyCalendarStyles()
    const alert = React.useContext(AlertContext)
    const currentDate = new Date()
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
    const [enumValue, setEnumValue] = React.useState([])
    const [isApply, setIsApply] = React.useState({
        bool: false,
        id: null,
        open_approve_dialog:false,
        selected_leave:{},
        selected_status:null
    })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false)
    const [selectedType, setSelectedType] = React.useState({})
    const [timeDetails, setTimeDetails] = React.useState({
        startTime: "",
        endTime: "",
        error: {
            startTime: "",
            endTime: ""
        }
    })
    const [selectedDate, setSelectedDate] = React.useState({
        startDate: [],
        endDate: [],
        rangeDate: []
    })
    const [hoverDate, sethoverDate] = React.useState([])
    const [leaveType, setLeaveType] = React.useState({
        name: "full_day",
        period: ""
    })
    const [allLeaveList, setAllLeaveList] = React.useState([])
    const [customMonth, setCustomMonth] = React.useState(new Date())
    const [finalDays, setFinalDays] = React.useState([])
    const client = useApolloClient();
    const [disable,setDisable]=React.useState(false)

    const onApproveRequest=(leave,status,bool)=>{
        setIsApply({
            ...isApply,
            selected_leave:leave??null,
            selected_status:status??null,
            open_approve_dialog:bool
        })
    }

    //handle clik
    const handleClick = (event, e) => {
        setLeaveType({
            name: "full_day",
            period: ""
        })
        sethoverDate([e])
        setAnchorEl(event.currentTarget);
    };
    //handle close menu
    const handleClose = (type) => {
        setAnchorEl(null);
    };
    React.useEffect(() => {
        const monthStart = startOfMonth(customMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let finaldays = dateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        getAllLeaveList()
        //eslint-disable-next-line
    }, [])
    //dateRange
    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }
    //get All leave list 
    const getAllLeaveList = () => {

        client.query({
            query: GET_LEAVELIST,
            fetchPolicy: 'network-only',
            variables: { client: localStorage.getItem(LocalStorageKeys?.clinetID) }

        }).then((res) => {
            setAllLeaveList(res.data?.leave_master)
        }).catch((err) => {
            console.log(err)
        })
    }
    const TimeOfffList = () => {
        const onChooseType = (val) => {
            setType(val?.type)
            setSelectedType(val)
            setIsShow(true)
            setOpen(false)
        }
        return (
            <Box p={2}>
                <Typography className={classes.statsTitle}>{t("paidOffstats")}</Typography>

                <Grid container spacing={2}>
                    {allLeaveList.filter(x => x.type === "Paid")?.map((x) => {
                        return (
                            <Grid item xs={3}>
                                <Box className={classes.TimeOfflist} onClick={() => onChooseType(x)}>
                                    <div style={{ display: "flex", justifyContent: "start" }}>
                                        <Box className={classes.icon}><Box mt={1}>{
                                            SwitchIcons(x.name)
                                        }</Box></Box>
                                        <Box style={{ marginInlineStart: 8 }}>
                                            <Typography className={classes.leaveName}>{t(x.name)}</Typography>
                                        </Box>
                                    </div>
                                </Box>
                            </Grid>
                        )
                    })}

                </Grid>
                {(allLeaveList?.length === 0 || allLeaveList.filter((x) => x.type === "Paid")?.length === 0) &&
                    <Box className={classes.leaveName} display="flex" justifyContent="center">
                        No data found
                    </Box>
                }

                <Box mt={2}>
                    <Typography className={classes.statsTitle}>{t("lossOfPay")}</Typography>
                    <Grid container spacing={2}>
                        {allLeaveList.filter(x => x.type === "Earned")?.map((x) => {
                            return (
                                <Grid item xs={3}>
                                    <Box style={{ display: "flex", justifyContent: "start" }} className={classes.TimeOfflist} onClick={() => onChooseType(x)}>
                                        <Box className={classes.icon}><Box mt={1}>{
                                            SwitchIcons(x.name)
                                        }</Box></Box>
                                        <Box marginInlineStart="8px">
                                            <Typography className={classes.leaveName}>{t(x.name)}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                    {(allLeaveList?.length === 0 || allLeaveList.filter((x) => x.type === "Earned")?.length === 0) &&
                        <Box className={classes.leaveName} display="flex" justifyContent="center">
                            {t("noDataFound")}
                        </Box>
                    }
                </Box>
                <Box mt={2}>
                    <Typography className={classes.statsTitle}>{t("Short Leave")}</Typography>
                    <Grid container spacing={2}>
                        {allLeaveList.filter(x => x.type === "Short Leave")?.map((x) => {
                            return (
                                <Grid item xs={3}>
                                    <Box style={{ display: "flex", justifyContent: "start" }} className={classes.TimeOfflist} onClick={() => onChooseType(x)}>
                                        <Box className={classes.icon}><Box mt={1}>{
                                            SwitchIcons(x.name)
                                        }</Box></Box>
                                        <Box marginInlineStart="8px">
                                            <Typography className={classes.leaveName}>{t(x.name)}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                    {(allLeaveList?.length === 0 || allLeaveList.filter((x) => x.type === "Short Leave")?.length === 0) &&
                        <Box className={classes.leaveName} display="flex" justifyContent="center">
                            {t("noDataFound")}
                        </Box>
                    }
                </Box>
            </Box>
        )
    }
    const ChooseListType = () => {
        const monthNext = () => {
            const datebyMonth = addMonths(customMonth, 1)
            const monthStart = startOfMonth(datebyMonth);
            const monthEnd = endOfMonth(monthStart);
            const monthstartDate = startOfWeek(monthStart);
            const monthendDate = endOfWeek(monthEnd);
            let finaldays = dateRange(monthstartDate, monthendDate)
            setFinalDays(finaldays)
            setCustomMonth(addMonths(customMonth, 1))
            setSelectedDate(selectedDate)

        }

        const monthPrev = () => {
            const datebyMonth = subMonths(customMonth, 1)
            const monthStart = startOfMonth(datebyMonth);
            const monthEnd = endOfMonth(monthStart);
            const monthstartDate = startOfWeek(monthStart);
            const monthendDate = endOfWeek(monthEnd);
            let finaldays = dateRange(monthstartDate, monthendDate)
            setFinalDays(finaldays)
            setCustomMonth(subMonths(customMonth, 1))
            setSelectedDate(selectedDate)
        }
        const setStateChange = (e) => {

            setSelectedDate(e)
        }
        const onTimeChange = (k, v) => {
            let error = timeDetails.error
            error[k] = ""
            if (k === "startTime") {
                setTimeDetails({ ...timeDetails, [k]: v, error })
            }
            else if (k === "endTime" && timeDetails?.startTime < v) {
                setTimeDetails({ ...timeDetails, [k]: v, error })
            }
            else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Please Choose End time greater than Start Time"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            }
        }
        //validate form
        const validateForm = () => {
            let isValid = true;
            let error = timeDetails.error;

            if (timeDetails?.startTime?.length === 0) {
                isValid = false;
                error.startTime = t("Start time is required");
            }
            if (timeDetails?.endTime?.length === 0) {
                isValid = false;
                error.endTime = t("End time is required");
            }
            setTimeDetails({ ...timeDetails, error: error });
            return isValid;
        };
        const finalSubmit = (selectedDate, leaveType, selectedType, timeDetails) => {
            if (type === "Short Leave") {
                if (validateForm()) {
                    onSubmit(selectedDate, leaveType, selectedType, timeDetails, setTimeDetails)
                }
            }

            else {
                onSubmit(selectedDate, leaveType, selectedType, timeDetails)
            }
        }
        return (
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CustomCalendar t={t} dates={finalDays} month={customMonth} prev={monthPrev} next={monthNext} selectedDate={selectedDate} setSelectedDate={setStateChange} />
                    </Grid>
                    <Grid item xs={6} className={classes.applyBorder} mt={1}>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Stack direction="row">
                                <Box className={classes.icon}><Box mt={1}>{
                                    SwitchIcons(selectedType?.name)
                                }</Box></Box>
                                <Box marginInlineStart="8px">
                                    <Typography className={classes.leaveName}>{t(selectedType?.name)}</Typography>
                                </Box>
                            </Stack>
                            <Box mt={1.5}>
                                <Typography className={classes.changeEmp} onClick={() => { return (setIsShow(false), setOpen(true)) }}>{t("Change")}</Typography>
                            </Box>
                        </Box>
                        <Box mt={2}>
                            <Typography className={classes.durationText}>{t("Duration Type")}</Typography>
                            {type === "Short Leave" ?
                                <Box mt={1} className={classes.durationBox}>
                                    <Stack direction={"row"} spacing={0.5}>
                                        <Radio
                                            checked={true}
                                            value="a"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                            size="small"
                                        />
                                        <Box>
                                            <Typography className={classes.hourText}>{t("Hourly Base Time off")}</Typography>
                                            <Typography className={classes.descriptext}>{t("Selected hours will be applied as time off for the selected date")}.</Typography>
                                        </Box>
                                    </Stack>
                                    {selectedDate?.startDate?.length > 0 &&
                                        <Stack direction={"row"} spacing={0.5} mt={2}>
                                            <Box ml={5.3} className={classes.timeBox}>
                                                <Typography className={classes.descriptext}>{t("Time Off Date")} :</Typography>
                                                <Typography className={classes.dateText}>&nbsp; &#x202a;{moment(selectedDate?.startDate?.[0]).format("DD MMM YY")}&#x202c; &#x202a;{selectedDate?.endDate?.length > 0 && ` - ${moment(selectedDate?.endDate?.[0]).format("DD MMM YY")}`}&#x202c;</Typography>
                                            </Box>
                                        </Stack>
                                    }
                                    <Stack direction={"row"} spacing={2} mt={2}>
                                        <Box ml={5.3} width={"150px"}>
                                            <TextBox
                                                type={"time"}
                                                label={t("Start Time")}
                                                value={timeDetails?.startTime}
                                                onChange={(e) => onTimeChange("startTime", e.target.value)}
                                                isError={timeDetails?.error?.startTime.length > 0}
                                                errorMessage={timeDetails?.error?.startTime}

                                            />

                                        </Box>
                                        <Typography style={{ marginTop: "35px" }}>-</Typography>
                                        <Box width={"150px"}>
                                            <TextBox
                                                type={"time"}
                                                label={t("End Time")}
                                                value={timeDetails?.endTime}
                                                onChange={(e) => onTimeChange("endTime", e.target.value)}
                                                errorMessage={timeDetails?.error?.endTime}
                                                isError={timeDetails?.error?.endTime?.length > 0}
                                            />
                                        </Box>
                                    </Stack>
                                </Box>
                                :
                                <Box mt={1} className={classes.durationBox}>
                                    <Stack direction={"row"}>
                                        <Radio
                                            checked={leaveType?.name === enumValue?.leave_request_type?.[1]?.value ? true : false}
                                            value={enumValue?.leave_request_type?.[1]?.value}
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': enumValue?.leave_request_type?.[1]?.value }}
                                            size="small"
                                            onClick={(e) => setLeaveType({ ...leaveType, name: enumValue?.leave_request_type[1]?.value, period: "" })}
                                        />
                                        <Box sx={{ marginInlineStart: "4px" }}>
                                            <Typography className={classes.hourText}>{t("Full day time off")}</Typography>
                                            <Typography className={classes.descriptext}>{t("Full day time off will be applied for the selected date")}.</Typography>
                                        </Box>
                                    </Stack>
                                    {selectedDate?.startDate?.length > 0 &&
                                        <Stack direction={"row"} spacing={0.5} mt={2}>

                                            <Box sx={{ marginInlineStart: "42px" }} className={classes.timeBox}>
                                                <Typography className={classes.descriptext}>{t("Time Off Date")} :</Typography>
                                                <Typography className={classes.dateText}>&nbsp; &#x202a;{moment(selectedDate?.startDate?.[0]).format("DD MMM YY")}&#x202c; &#x202a;{selectedDate?.endDate?.length > 0 && ` - ${moment(selectedDate?.endDate?.[0]).format("DD MMM YY")}`}&#x202c;</Typography>

                                            </Box>
                                        </Stack>
                                    }
                                    <Box mt={1} mb={1}>
                                        <Divider />
                                    </Box>
                                    <Stack direction={"row"}>
                                        <Radio
                                            checked={leaveType?.name === "half_day" ? true : false}
                                            value={enumValue?.leave_request_type?.[0]?.value}
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': enumValue?.leave_request_type?.[0]?.value }}
                                            size="small"
                                            onClick={(e) => setLeaveType({ ...leaveType, name: "half_day", period: enumValue?.half_day_type?.[0]?.value })}
                                        />
                                        <Box sx={{ marginInlineStart: "4px" }}>
                                            <Typography className={classes.hourText}>{t("Half day time off")}</Typography>
                                            <Typography className={classes.descriptext}>{t("Selected Session will be applied as time off for the selected date")}.</Typography>
                                        </Box>
                                    </Stack>
                                    <Grid container spacing={2} mt={0.5}>
                                        <Grid item xs={1.3} />
                                        <Grid item xs={4}>
                                            <Button className={leaveType?.period === "first_half" ? classes.submitbtn : classes.closebtn} variant={leaveType?.period === "first_half" ? "contained" : "outlined"} fullWidth onClick={() => setLeaveType({ ...leaveType, period: enumValue?.half_day_type?.[0]?.value, name: "half_day" })}>{t("First Half")}</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button className={leaveType?.period === "second_half" ? classes.submitbtn : classes.closebtn} variant={leaveType?.period === "second_half" ? "contained" : "outlined"} fullWidth onClick={() => setLeaveType({ ...leaveType, period: enumValue?.half_day_type?.[1]?.value, name: "half_day" })}>{t("Second Half")}</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </Box>

                        <Box mt={8}>
                            <Button variant="contained" fullWidth onClick={() => finalSubmit(selectedDate, leaveType, selectedType, timeDetails)} disabled={isDisableBtn}>{t("applyTimeOff")}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    const applyTimeOffRequest = (val, index) => {
        setSelectedDate({ startDate: [new Date(val).toISOString()], endDate: [], rangeDate: [] })
        setCustomMonth(val)
        const monthStart = startOfMonth(val);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let finaldays = dateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        setLeaveType({
            name: "full_day",
            period: ""
        })
        setIsApply({
            bool: true,
            id: index,
        })
    }
    //get Enum
    const getEnum = async () => {
        const payload = {
            enumName: [enum_types.leave_request_type, enum_types.half_day_type],
        };
        const res = await NetworkCall(
            `${config.api_url}/enum`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        );
        setEnumValue({
            leave_request_type: res?.data?.data?.leave_request_type ?? [],
            half_day_type: res?.data?.data?.half_day_type ?? []
        })
    }
    React.useEffect(() => {
        getEnum()
    }, [])

    const closeCustomCalendar = () => {
        setIsShow(false)
        setSelectedDate([])
        setTimeDetails({
            startTime: "",
            endTime: "",
            error: {
                startTime: "",
                endTime: ""
            }
        })
        setLeaveType({
            name: "full_day",
            period: ""
        })
    }

    const onChangeStatus=(data)=>{

        setDisable(true)
        NetworkCall(
            `${config.api_url}/leave_request/update`,
            NetWorkCallMethods.post,
            {upsert_field:data},
            null,
            true,
            false
        ).then((res) => {
            reload()
            setDisable(false);
            setIsApply({
                ...isApply,
                open_approve_dialog:false,
                selected_leave:{},
                selected_status:null
            })
            
        }).catch((error) => {
            setDisable(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong please try again"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    return (
        <Box>
            <Box className={classes.calheader}>
                <CalendarDateChange state={{ "Date": month }} prev={prev} next={next} comp={"month"} />
            </Box>
            <Grid container>

                {days.map((i) => {
                    return (
                        <Grid item xs={1.7}>
                            <Typography className={classes.daysText}>{i}</Typography>
                        </Grid>
                    )
                })}

            </Grid>
            <Grid container mt={0} className={classes.calDates}>
                {
                    dates?.map((e, index) => {
                        return (
                            <Grid item xs={1.7} className={classes.date_outline}>
                                <Stack mt={1} justifyContent={"space-between"} pb={2} alignItems={"center"}>

                                    <Box className={(moment(e).format("DD-MM-YYYY") === moment(currentDate).format("DD-MM-YYYY")) && moment(e).format("M") === moment(month).format("M") ? classes.currentDate : classes.date}>
                                        <span className={moment(e).format("M") === moment(month).format("M")
                                            ? " " : classes.hidden_other_month_dates}>
                                            {moment(e).format("DD")}</span></Box>
                                    <Box mt={2}>{isApply?.bool && index === isApply?.id && moment(e).format("M") === moment(month).format("M")
                                        ?
                                        <Box className={(moment(e).format("DD-MM-YYYY") === moment(currentDate).format("DD-MM-YYYY")) ? classes.applyTimeOffCurrentDate : classes.applyTimeOff} onMouseLeave={() => setIsApply(false)} onClick={() => setOpen(true)} ><PlusIcon color="#78b1fe" />&nbsp;{t("applyTimeOff")}</Box>
                                        :
                                        <>

                                            {data?.dates?.includes(new Date(e).toISOString()) ?

                                                <Stack direction={"row"} flexWrap="wrap" alignItems={"center"} justifyContent={"center"} aria-owns={anchorEl ? "simple-menu" : undefined}

                                                    aria-haspopup="true" onMouseOver={(event) => handleClick(event, e)}>
                                                    <Box>
                                                        {data?.value?.map((val) => {
                                                            return (
                                                                moment(val?.date).format("DD-MM-YYYY") === timeZoneConverter(e, "DD-MM-YYYY") &&
                                                                <Box display={"flex"} alignItems={"center"} >
                                                                    {val?.status === "Approved" && <CheckIcon />}
                                                                    {val?.status === "Pending" && <ErrorIcon  style={{ color: "#975F2E",fontSize:"14px" }} />}
                                                                    {val?.status === "Rejected" && <CancelIcon  style={{ color: "#B43131",fontSize:"14px" }} />}

                                                                    <Typography className={classes?.leavesub}>{val?.name}</Typography>
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                </Stack>
                                                :
                                                moment(e).format("M") === moment(month).format("M") &&
                                                <Box className={classes.plussvg} mt={(moment(e).format("DD-MM-YYYY") === moment(currentDate).format("DD-MM-YYYY")) && -1.5}>
                                                    {
                                                        (moment(e).isAfter(subDays(currentDate, 1))) &&
                                                        <PlusIcon color="#78b1fe" onMouseOver={() => applyTimeOffRequest(e, index)}
                                                            onMouseLeave={() => setIsApply(false)} />
                                                    }

                                                </Box>
                                            }

                                        </>
                                    }
                                    </Box>
                                </Stack>
                            </Grid>
                        )
                    })
                }
            </Grid>
            {/* popover */}
            <Menu
                id="simple-menu"
                className={classes.menuList}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                MenuListProps={{ onMouseLeave: handleClose }}
            >
               
                    <Box className={classes.menuCustomColor} >


                        {data?.value?.map((val,index) => {
                            return (
                                moment(hoverDate?.[0]).format("DD-MM-YYYY") === moment(val?.date).format("DD-MM-YYYY")) &&
                                <Box mt={index === 0 ? 0:1} mb={1}>

                                    <Stack direction="row" >

                                        <Box className={classes.icon}><Box mt={1}>{SwitchIcons(val?.name)}</Box></Box>
                                        <Box mt={1}><Typography className={classes.leaveName}>{val?.name}</Typography></Box>
                                    </Stack>

                                    <Box mt={1} className={classes.popovertext}>
                                        {val?.name === "Short Leave" ?
                                            <>
                                                <Typography className={classes.popoversubtext}>&#x202a;{t("Hourly Time Off on")} {moment(val?.date).format("DD MMM YY")}&#x202c; </Typography>
                                                <Typography className={classes.popoversubtext}>{t("from")} {timeZoneConverter(val?.start_date_time, "HH:mm a")} to {timeZoneConverter(val?.end_date_time, "HH:mm a")}</Typography>
                                            </>
                                            :
                                            <>
                                                {val?.name !== "Work From Home" &&
                                                    <Stack direction={"row"}>
                                                        <Typography sx={{ marginInlineEnd: "4px" }} className={classes.popoversubtext}>{t("Leave Applied for")}</Typography>
                                                        <Typography className={classes.popoversubtext}>&#x202a;{timeZoneConverter(hoverDate?.[0], "DD MMM YY")}&#x202c;</Typography>
                                                    </Stack>
                                                }
                                                {val?.name === "Work From Home" &&
                                                    <Stack direction={"row"}>
                                                        <Typography sx={{ marginInlineEnd: "4px" }} className={classes.popoversubtext}>{t("Applied WFH :")}</Typography>
                                                        <Typography className={classes.popoversubtext}>&#x202a;{timeZoneConverter(hoverDate?.[0], "DD MMM YY")}&#x202c;</Typography>
                                                    </Stack>
                                                }
                                                {/* <Typography className={classes.popoversubtext}>{val?.name !== "Work From Home" ? t("Leave Applied for") : t("Applied WFH :")} {timeZoneConverter(hoverDate?.[0], "DD MMM YY")} </Typography> */}
                                                <Typography className={classes.popoversubtext}> {val?.half_day_type !== null ? `Half Day (${(val?.half_day_type === "first_half" ? t("First Half") : t("Second Half"))})` : "Full Day"}</Typography>
                                            </>
                                        }
                                    </Box>
                                    <Box mt={1}>
                                        {val?.status=== "Pending" &&
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Button className={classes.approveBtn} onClick={()=>onApproveRequest(val,"Approved",true)} fullWidth>Approve</Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button className={classes.rejectBtn} onClick={()=>onApproveRequest(val,"Rejected",true)} fullWidth>Reject</Button>
                                            </Grid>
                                        </Grid>
                                        }
                                    </Box>
                                </Box>

                        })}


                    </Box >
            </Menu >
            {/*apply timeOff dialog*/}
            < AlertDialog
                open={open}
                onClose={() => setOpen(false)}
                header={t("selectTimeOffType")}
                component={TimeOfffList()}
                md
            />
            {/*apply SubtimeOff dialog*/}
            < AlertDialog
                open={isShow}
                onClose={() => closeCustomCalendar()}
                header={t("selectTimeOffType")}
                component={ChooseListType()}
                md
            />
             {/*Approve Rejected*/}
             < AlertDialog
             isNormal
                open={isApply?.open_approve_dialog}
                onClose={()=>onApproveRequest(null,null,false)}
                header={t(isApply?.selected_status==="Approved"?"Approve":"Reject")}
                component={<StatusComponent isDisableBtn={disable} onChangeStatus={onChangeStatus} onClose={()=>onApproveRequest(null,null,false)} t={t} status={isApply?.selected_status} data={isApply?.selected_leave}/>}
                
            />
        </Box >
    )
}
