import { useApolloClient } from "@apollo/client";
import { Badge, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import { FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { UPDATE_AMENITIES_MASTER } from "../../graphql/mutations";
import { GET_CONTACT_AGAINST_COMPANY } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, InvoiceHeading, InvoicePath, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, getCompanyOption, getRoutePermissionNew } from "../../utils";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import { InvoiceStyles } from "./style";





const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    margin: theme.spacing(3)
}))


const Invoice = ({ t }) => {
    const classes = InvoiceStyles()
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext);
    const history = useHistory()
    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [InvoiceList, setInvoiceList] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState("");
    const [drawer, setDrawer] = React.useState(null);
    const [filterData, setFilterData] = React.useState({ status: [true], contact: [], payment_status: ['no-due', 'due'], classification: ['AR'] });
    const [loading, setLoading] = React.useState(true)
    // const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);


    const draweropen = () => {
        setDrawer(true);
    };
    const drawerclose = () => {
        setDrawer(false);
    };
    const debounce = UseDebounce();

    const client = useApolloClient();
    //more options
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        InvoiceTableData(offset, limit, searchText,filterData)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        InvoiceTableData(0, value, searchText,filterData)
    }

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        InvoiceTableData(0, limit, e,filterData)
    }







    //table listing function
    const InvoiceTableData = async (offset = 0, limit = 10, search = "", filterValue) => {
        let company_id = [selectedCompany?.value];
        let is_active = (filterValue?.status?.length > 0 ? filterValue?.status : [true, false]) ?? [true];


        const payload = {
            offset: offset,
            limit: limit,
            search: search,
            company_id,
            is_active,
            contact: filterValue?.contact?.length > 0 ? filterValue?.contact?.map((x) => x?.value) : undefined,
            payment_status: filterValue?.payment_status?.length > 0 ? filterValue?.payment_status : undefined,
            payment_type: filterValue?.classification?.length > 0 ? filterValue?.classification : undefined


        }

        NetworkCall(
            `${config.api_url}/invoice/agreement_invoice`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setInvoiceList({
                data: response?.data?.invoice,
                totalRowsCount: response?.data?.count?.[0]?.count,

            })
            setLoading(false)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })

        }).catch((err) => {
            console.log(err)
        })


        // const payload = {
        //     query: GET_INVOICE_TABLE_LIST(clientId, offset, limit, search, companyID, is_active).loc.source.body,

        // };

    }
    // is active changing function
    const changeactive = async (data, value) => {
        client.mutate({
            mutation: UPDATE_AMENITIES_MASTER,
            variables: {
                ID: data,
                updatePayload: {
                    is_active: value === "Inactive" ? true : false
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Status Updated"),
            });
            InvoiceTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }
    //delete function
    const deleteTableData = async (data) => {
        client.mutate({
            mutation: UPDATE_AMENITIES_MASTER,
            variables: {
                ID: data,
                updatePayload: {
                    is_delete: true
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Deleted"),
            });
            InvoiceTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }
    //table row constructions
    const InvoiceRow = InvoiceList?.data?.map((val, index) => {
        let _d;
        try {
            _d = {
                index: ((page - 1) * limit) + index + 1,
                invoiceNumber: val?.invoice_no ?? " - ",
                accountID: val?.account_no ?? " - ",
                invoiceAmount: `${selectedCompany?.currency_symbol} ${val?.invoice_total_amount}` ?? " - ",
                invoiceType: val?.invoice_type ?? " - ",
                generatedDate: val?.created_at ?? " - ",
                dueDate: val?.payment_due_date ?? " - ",
                balance: `${selectedCompany?.currency_symbol} ${val?.invoice_due_amount}` ?? " - ",
                status: val?.payment_status === "no-due" ? "Paid" : "Unpaid",
                agreementNumber: val?.agreement?.agreement_no ?? "-",
                icon: "more",
                id: val?.id,

            };
        } catch (err) {

        }
        return _d;
    });

    React.useEffect(() => {
        if (selectedCompany?.value) {
            InvoiceTableData(0,10,"",filterData)
        }
        //eslint-disable-next-line
    }, [selectedCompany]);

    const handleIcon = (type, data, status) => {
        if (type === "view") {
            history.push({
                pathname: Routes.invoiceView,
                state: data?.id
            })
        }
        else if (type === "edit") {

        }
        else if (type === "active") {
            changeactive(data.id, status)
        }
        else if (type === "delete") {
            deleteTableData(data.id, data.IsDelete)
        }

    }




    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
        if (value?.status?.length > 0 && value?.payment_status?.length > 0) {
            InvoiceTableData(0,10,"",value)
        }
        else{
            setDrawer(true)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg:((value?.status?.length === 0||value?.status===undefined) && (value?.payment_status?.length === 0||value?.payment_status===undefined)) ?t("Please choose atleast one filter for status and payment status"):(value?.status?.length === 0||value?.status===undefined)?t("Please choose atleast one filter for status"):(value?.payment_status?.length===0||value?.payment_status===undefined)?t("Please choose atleast one filter for payment status"):t("Please choose atleast one filter for status and payment status"),
            });
        }

    }

    const render = () => {
        return <>
            {loading ? <LoadingSection bottom={"45vh"} message={t("Loading Invoice Listing...")} />
                :
                <>
                    <div>

                        <Subheader title={t("Invoice")} count={false} hideBackButton select options={companyList} value={selectedCompany}
                            onchange={(e) =>
                                setSelectedCompany(e)
                                // getProperty(e)
                            }
                        />

                        <CustomPaper>
                            <Grid container className={classes.content} spacing={2}>
                                <Grid item xs={4}>
                                    <SearchFilter placeholder={t("Search by invoice number")} value={searchText} handleChange={(value) => handleSearch(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                                </Grid>
                                <Grid item xs={8} textAlign={"right"} justifyContent={'end'} display={'flex'}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}
                                    >
                                        <Box marginRight={"12px"}>
                                            {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                                <IconButton onClick={draweropen} className={classes.img}>
                                                    <Badge variant="dot" color="primary">
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>

                                            ) : (
                                                <IconButton onClick={draweropen} className={classes.img}>
                                                    <FilterIMG color="#091b29" />
                                                </IconButton>)}

                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TableWithPagination
                                        heading={InvoiceHeading(t)}
                                        rows={InvoiceRow?.length ? InvoiceRow : []}
                                        path={InvoicePath}
                                        showpagination={true}
                                        tableType="no-side"
                                        handleIcon={handleIcon}
                                        onClick={() => null}
                                        showpdfbtn={false}
                                        showexcelbtn={false}
                                        showSearch={false}
                                        dataType={[
                                            { type: ["text"], name: "invoiceNumber" },
                                            { type: ["text"], name: "invoiceType" },
                                            { type: ["text"], name: "agreementNumber" },
                                            { type: ["text"], name: "accountID" },
                                            { type: ["text"], name: "invoiceAmount" },
                                            { type: ["date"], name: "generatedDate" },
                                            { type: ["date"], name: "dueDate" },
                                            { type: ["status"], name: "status" },
                                            { type: ["text"], name: "balance" },
                                        ]}
                                        handlePagination={handlePagination}
                                        handleChangeLimit={handleChangeLimit}
                                        totalRowsCount={InvoiceList?.totalRowsCount}
                                        page={page}
                                        limit={limit}
                                        height={`calc(100vh -  290px)`}
                                        view={permission?.read}
                                        edit={permission?.update}
                                        delete={permission?.delete} />
                                </Grid>
                            </Grid>
                        </CustomPaper>

                    </div>



                    {/* filter drawer */}
                    {
                        drawer &&
                        <FilterGenerator
                            open={drawer}
                            onClose={drawerclose}
                            components={[
                                {
                                    component: "toggleButton",
                                    value: filterData?.status,
                                    options: [
                                        { label: t('Active'), value: true },
                                        { label: t('Inactive'), value: false },
                                    ],
                                    isMulti: true,
                                    state_name: "status",
                                    label: t("Status"),
                                    // required:true
                                },
                                {
                                    component: "toggleButton",
                                    value: filterData?.payment_status,
                                    options: [
                                        { label: t('Paid'), value: 'no-due' },
                                        { label: t('UnPaid'), value: 'due' },
                                    ],
                                    isMulti: true,
                                    state_name: "payment_status",
                                    label: t("Payment Status"),
                                    // required:true
                                },
                                {
                                    isActive: true,
                                    component: "select",
                                    label: t("Choose Contact"),
                                    value: filterData?.contact,
                                    placeholder: t("Select Contact"),
                                    isRequired: true,
                                    isPaginate: true,
                                    isMulti: true,
                                    state_name: "contact",
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptions(
                                            search,
                                            array,
                                            handleLoading,
                                            GET_CONTACT_AGAINST_COMPANY,
                                            "contact",
                                            { isActive: true, company_id: selectedCompany?.value, client_id: localStorage.getItem(LocalStorageKeys?.clinetID) },
                                            { label: "label", value: "value" },
                                            false,
                                            false
                                        ),
                                },
                                {
                                    component: "toggleButton",
                                    value: filterData?.classification,
                                    options: [{
                                        value: "AR",
                                        label: "AR"
                                    },
                                    {
                                        value: "AP",
                                        label: "AP"
                                    }],
                                    isMulti: true,
                                    state_name: "classification",
                                    label: t("Classification")
                                }
                            ]}
                            onApply={(value) => onApplyFilter(value)}
                        />
                    }
                </>
            }
        </>
    }


    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};

export default withNamespaces("invoice")(Invoice);
