export const masterList = [
  {
    name: "Access Gates"
  },
  {
    name: "Visitor Parking Slots"
  },
  {
    name: "Domestic Help"
  },
  {
    name: "Vendor"
  },
  {
    name: "Service Provider"
  },
]

export const GatePath = ["id", "property_name", "name", "gate_no", "security_charge", "duty_date", "description"];

export const Gaterow = [
  {
    id: "",
    property_name: "Rubix Apartment",
    gate_name: "Front Gate",
    gate_number: "Gate Number",
    security_charge: "1",
    duty_date: "Keanu Pearson",
    description: "Description",
    icon: "edittoggle"
  }
]

export const GateHeading = [
  { title: "Sno", field: "index", type: "numeric" },
  { title: "Property Name", field: "property_name" },
  { title: "Gate Name", field: "name" },
  { title: "Gate Number", field: "gate_no", type: "numeric" },
  { title: "Security in Charge", field: "security_charge" },
  { title: "On Duty date", field: "duty_date" },
  { title: "Description", field: "description" },
  { title: "", field: "icon" },
];
export const GateType = [
  { type: ["text"], name: "index" },
  { type: ["text"], name: "property_name" },
  { type: ["text"], name: "name" },
  { type: ["text"], name: "gate_no" },
  { type: ["text"], name: "security_charge" },
  { type: ["text"], name: "duty_date" },
  { type: ["text"], name: "description" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const Gatedrawer = [
  {
    componet_type: "select",
    label: "Property Name",
    state_name: "property_name",
    value: "",
    required: true,
    // disabled: state.edit_data ? true : false
    options: [],
    dependency:true,
    dependencyState:['security_charge']
  },
  {
    componet_type: "text",
    label: "Gate Name",
    state_name: "name",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: "Gate Number",
    state_name: "gate_no",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: "Description",
    state_name: "description",
    value: "",
    required: false,
  },
  {
    componet_type: "date",
    label: "On Duty Date",
    state_name: "duty_date",
    value: "",
    required: true,
  },
  {
    componet_type: "selectUser",
    label: "Security",
    state_name: "security_charge",
    value: "",
    required: true,
    options: []
  },
  {
    componet_type: "switch",
    label: "Active",
    state_name: "is_active",
    value: "",
  }
]


export const ParkingPath = ["id", "property_name", "parking_no"];

export const Parkingrow = [
  {
    id: "",
    property_name: "Rubix Apartment",
    parking_number: "G1-A11",
    icon: "edittoggle"
  }
]

export const ParkingHeading = [
  { title: "Sno", field: "index", type: "numeric" },
  { title: "Property Name", field: "property_name" },
  { title: "Parking Number", field: "parking_no", type: "numeric" },
  {title :"Rate",field:"rate"},
  {title:"Currency Symbol",field:"currency_symbol"},
  { title: "", field: "icon" },
];
export const ParkingType = [
  { type: ["text"], name: "index" },
  { type: ["number"], name: "property_name" },
  { type: ["text"], name: "parking_no" },
  { type: ["text"], name: "rate" },
  { type: ["text"], name: "currency_symbol" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const Parkingdrawer = [
  {
    componet_type: "select",
    label: "Property Name",
    state_name: "property_name",
    value: "",
    required: true,
    // disabled: state.edit_data ? true : false
    options: []
  },
  {
    componet_type: "text",
    label: "Parking Number",
    state_name: "parking_no",
    value: "",
    required: true,
    type:'text'
  },
  {
    componet_type: "text",
    label: "Description",
    state_name: "description",
    value: "",
  },
  {
    componet_type: "text",
    label: "Rate",
    state_name: "rate",
    value: "",
    required: true,
    type:'number'
  },
  {
    componet_type: "select",
    label: "Currency Symbol",
    state_name: "currency_symbol",
    value: "",
    required: true,
    options:[]
  },
  {
    componet_type: "switch",
    label: "Active",
    state_name: "is_active",
    value: "",
  }
]

export const DomesticHelpPath = ["id", "name", "gender", "mobile_no", "id_type", "id_number"];

export const DomesticHelprow = [
  {
    id: "",
    name: "Alan Barnett",
    gender: "Male",
    mobile_no: "91 9998887771",
    id_type: "Passport",
    id_number: "ID-1244",
    icon: "edittoggle"
  }
]

export const DomesticHelpHeading = [
  { title: "Sno", field: "id", type: "numeric" },
  { title: "Name", field: "name" },
  { title: "Gender", field: "gender" },
  { title: "Mobile Number", field: "mobile_no" },
  { title: "ID Type", field: "id_type" },
  { title: "ID Number", field: "id_number" },
  { title: "", field: "icon" },
];
export const DomesticHelpType = [
  { type: ["increment"], name: "id" },
  { type: ["text"], name: "name" },
  { type: ["text"], name: "gender" },
  { type: ["text"], name: "mobile_no" },
  { type: ["text"], name: "id_type" },
  { type: ["text"], name: "id_number" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const DomesticHelpdrawer = [
  {
    componet_type: "select",
    label: "Property Name",
    state_name: "property_name",
    value: "",
    required: true,
    options: []
  },
  {
    componet_type: "text",
    label: "Name",
    state_name: "name",
    value: "",
    required: true,
  },
  {
    componet_type: "mobileNo",
    label: "Mobile",
    state_name: "mobile_no",
    value: {},
    required: true,
  },
  {
    componet_type: "select",
    label: "Gender",
    state_name: "gender",
    value: "",
    required: true,
    options: [
      {
        label: "Male",
        value: "Male"
      },
      {
        label: "Female",
        value: "Female"
      },
      {
        label: "Others",
        value: "Others"
      },
    ]
  },
  {
    componet_type: "select",
    label: "Profession",
    state_name: "profession",
    value: "",
    required: true,
    options: []
  },
  {
    componet_type: "select",
    label: "ID Type",
    state_name: "id_type",
    value: "",
    required: true,
    options: []
  },
  {
    componet_type: "text",
    label: "ID Number",
    state_name: "id_number",
    value: "",
    required: true,
  },
  {
    componet_type: "qrCode",
    label: "QR Code",
    state_name: "qr_code",
    value: "",
  },
  {
    componet_type: "switch",
    label: "Active",
    state_name: "is_active",
    value: "",
  },
]

export const VendorPath = ["id", "vendor_name", "vendor_code"];

export const Vendorrow = [
  {
    id: "",
    vendor_name: "Alan Barnett",
    vendor_code: "Ven-1224",
    icon: "edittoggle"
  },
  {
    id: "",
    vendor_name: "George Bradley",
    vendor_code: "Ven-1224",
    icon: "edittoggle"
  },
  {
    id: "",
    vendor_name: "Harry Pierce",
    vendor_code: "Ven-1224",
    icon: "edittoggle"
  },
]

export const VendorHeading = [
  { title: "Sno", field: "index", type: "numeric" },
  { title: "Vendor Name", field: "vendor_name" },
  { title: "Vendor Code", field: "vendor_code" },
  { title: "", field: "icon" },
];
export const VendorType = [
  { type: ["text"], name: "index" },
  { type: ["text"], name: "vendor_name" },
  { type: ["text"], name: "vendor_code" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const Vendordrawer = [
  {
    componet_type: "select",
    label: "Property Name",
    state_name: "property_name",
    value: "",
    required: true,
    options: []
  },
  {
    componet_type: "text",
    label: "Vendor Name",
    state_name: "vendor_name",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: "Vendor Code",
    state_name: "vendor_code",
    value: "",
    required: true,
  },
  {
    componet_type: "switch",
    label: "Active",
    state_name: "is_active",
    value: "",
  }
]

export const ServiceProviderPath = ["id", "service_provider_name", "service_provider_code", "service_cateogry"];

export const ServiceProviderrow = [
  {
    id: "",
    service_provider_name: "Alan Barnett",
    service_provider_code: "SP-1224",
    service_cateogry: "Plumber",
    icon: "edittoggle"
  },
  {
    id: "",
    service_provider_name: "Alan Barnett",
    service_provider_code: "SP-1224",
    service_cateogry: "Plumber",
    icon: "edittoggle"
  },
  {
    id: "",
    service_provider_name: "Alan Barnett",
    service_provider_code: "SP-1224",
    service_cateogry: "Plumber",
    icon: "edittoggle"
  },
]

export const ServiceProviderHeading = [
  { title: "Sno", field: "index", type: "numeric" },
  { title: "Service Provider Name", field: "service_provider_name" },
  { title: "Service Provider Code", field: "service_provider_code" },
  { title: "Service Category", field: "service_cateogry" },
  { title: "", field: "icon" },
];
export const ServiceProviderType = [
  { type: ["text"], name: "index" },
  { type: ["text"], name: "service_provider_name" },
  { type: ["text"], name: "service_provider_code" },
  { type: ["text"], name: "service_cateogry" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const ServiceProviderdrawer = [
  {
    componet_type: "select",
    label: "Property Name",
    state_name: "property_name",
    value: "",
    required: true,
    options: []
  },
  {
    componet_type: "text",
    label: "Service Provider Name",
    state_name: "service_provider_name",
    value: "",
    required: true,
  },
  {
    componet_type: "text",
    label: "Service Provider Code",
    state_name: "service_provider_code",
    value: "",
    required: true,
  },
  {
    componet_type: "select",
    label: "Service Category",
    state_name: "service_cateogry",
    value: "",
    required: true,
    options: []
  },
  {
    componet_type: "switch",
    label: "Active",
    state_name: "is_active",
    value: "",
  }
]