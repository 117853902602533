import { TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Label } from "../label";
import { useStyles } from "./style";

export const TextBox = (props) => {

  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <>
        {props.label && <Label label={props.label} isRequired={props.isrequired} labelMarginBottom={props.labelMarginBottom} textAlign={props?.textAlign} />}

        <div className={classes.text}>
          <TextField
            className={classes.textbox}
            type={props.type}
            style={{
              backgroundColor: props?.backgroundColor ?? "none"
            }}
            onKeyPress={(e) => {
              if(e?.key === "Enter"){
                props?.handleEnter && props?.handleEnter(e)
              }
              if (props.type === "number") {
                if (e.key === "e") {
                  e.preventDefault();
                }
                if(props?.keyPress){
                  props?.keyPress(e)
                }
              }
            }}
            // autoComplete={false}
            id={props.id}
            placeholder={props.placeholder}
            variant={props.variant ?? "outlined"}
            fullWidth={props?.fullWidth ?? true}
            InputLabelProps={{
              shrink: false,
            }}
            inputProps={{
              readOnly: props?.isReadonly ?? false,
              autoComplete: props?.autocomplete,
              step:props?.step
            }}
            InputProps={{
              endAdornment: props?.endAdornment,
              startAdornment: props?.startAdornment,
              style: {
                fontSize: props?.fontSize ?? "0.875rem",
                padding: props.multiline ? 0 : "none",
                // paddingLeft: props?.paddingLeft ?? "auto"
              },

            }}
            onInput={props?.onInput}
            disabled={props?.isReadonly ?? false}
            size="small"
            multiline={props.multiline}
            rows={props?.rowheight ?? 3}
            autoFocus={props?.autoFocus}
            rowsMax={props?.maxRowheight ?? 10}
            onChange={props.onChange}
            value={props.value ?? ""}
            error={props.error}
            helperText={props.helperText}
            defaultValue={props?.defaultValue ?? ""}
            onBlur={props?.onBlur}
            // onKeyPress={props?.onKeyPress}
            // onKeyPress={props?.onKeyPress}
            onWheel={(e) => e.target.blur()}
            onKeyDown={props?.handleKeydown ? (e) => props?.handleKeydown(e) : () => false}
            onKeyUp={props?.handleKeyUp ? (e) => props?.handleKeyUp(e) : () => false}
            step={props?.step}
          />
        </div>
      </>
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};

TextBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
TextBox.defaultProps = {
  label: "Textbox",
  multiline: false,
  type: "text",
  placeholder: "Type Here...",
};
