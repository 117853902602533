/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from "@apollo/client";
import {
    Box,
    Button, Divider, Grid, Stack, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { Routes } from '../../router/routes';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, SemiBold } from '../../utils';
import { constructVendorListing, GET_ALL_VENDORS, UPDATE_VENDOR_MASTER, vendorTableDetails } from './utils';
import { Bold } from "../../utils";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    content: {
        textAlign: "center"
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize:"0.875rem"
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
    },
    text: {
        fontSize:"1.5rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize:"1rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        //marginTop: "4px",
        padding: "12px"
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary

    },
    divider: {
        height: "12px",
        width: "2px",
        backgroundColor: "black"
    }
}))

const VendorListing = props => {
    const { t } = (props);
    const history = useHistory();
    const classes = useStyles();
    const client = useApolloClient();
    const alert = useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = useContext(BackdropContext);
    const [vendorList, setVendorList] = useState({});
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [companyList, setCompanyList] = useState([]);
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    //debounce hook
    const debounce = UseDebounce();

    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getVendorList(0, 10, "", company?.selected?.value, false)
        }
    }

    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getVendorList(offset, limit, searchText, selectedCompany?.value)
    }

    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getVendorList(0, value, searchText, selectedCompany?.value)
    }

    const changeactive = (ID, updatePayload) => {
        client.mutate({
            mutation: UPDATE_VENDOR_MASTER,
            variables: {
                ID,
                updatePayload
            }
        }).then(rs => {
            getVendorList((page - 1) * limit, limit, searchText, selectedCompany?.value)
        }).catch(er => {
            console.log(er)
        })
    }

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getVendorList(0, limit, e, selectedCompany?.value)
    }

    const handleIcon = (type, data, status) => {
        if (type === "edit" || type === "view") {
            history.push({
                pathname: `${Routes.editVendor?.split('/:')[0]}/${data.id}`,
                state: {
                    from: type,
                    companyID: selectedCompany
                }
            })
        }
        else if (type === "active" || type === "delete") {
            let updatePayload = {
                is_active: status === "Inactive" ? true : false,
                updated_at: new Date().toISOString(),
                updated_by: localStorage.getItem(LocalStorageKeys.profileID),
            }
            if (type === "delete") {
                updatePayload = {
                    is_active: false,
                    is_delete: true,
                }
            }
            changeactive(data.id, updatePayload)
        }
    }

    const getVendorList = (offset = 0, limit = 10, searchText = "", companyId, load = true) => {

        if (load) {
            backdrop.setBackDrop({
                ...backdrop,
                open: true,
                message: "Fetching data",
            });
        }

        client.query({
            query: GET_ALL_VENDORS,
            fetchPolicy: "network-only",
            variables: {
                offset,
                limit,
                searchText,
                companyId,
                client: localStorage.getItem('clinetID')
            }
        }).then(res => {
            let data = constructVendorListing(res?.data?.vendor_master, (page - 1) * limit);
            setVendorList({
                totalRowsCount: res?.data?.count?.[0]?.count,
                data
            })
            if (load) {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                })
            };
        }).catch(err => {
            if (load) {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                })
            };
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        })
    }

    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getVendorList(0, 10, "", value?.value)
    }

    const addNew = () => {
        history.push({
            pathname: Routes.vendorCreation,
            state: {
                companyID: selectedCompany
            }
        })
    }

    const render = () => {
        return <>
            <Subheader hideBackButton={true} title="Vendors" select options={companyList} value={selectedCompany}
                goBack={() => history.goBack(-1)}
                onchange={(e) => {
                    handleCompanyChange(e)
                }

                } />
            <div className={classes.root}>
                {vendorList?.data?.length < 0 ?
                    <>
                        <Grid container className={classes.content} >
                            <Grid item xs={12}>
                                <img src='/images/propertybg.svg' alt='pp' />
                                <Typography className={classes.text}>
                                    {t("No Vendor Found")}
                                </Typography>
                                <Typography className={classes.subText}>
                                    {t("You Can Just, Create New Vendor")}
                                </Typography>
                                <br />
                            </Grid>
                            <Grid item xs={12} >
                                {permission.create && <Button
                                    variant="contained"
                                    className={classes.btn}
                                    onClick={addNew}
                                >
                                    {t("Add A Vendor")}
                                </Button>}
                            </Grid>
                        </Grid>
                    </> :
                    <>
                        <Grid container className={classes.content} spacing={2}>
                            <Grid item xs={4}>
                                <SearchFilter value={searchText} placeholder={t("Search by vendor name or vendor code")} handleChange={(value) => handleSearch(value)} />
                            </Grid>

                            <Grid item xs={8} textAlign={"right"}>
                                <Box
                                    display={"flex"}
                                    sx={{ float: "right" }}
                                >
                                    <Stack
                                        direction="row"
                                        divider={<Divider orientation="vertical" flexItem />}
                                        spacing={2}
                                    >

                                        {/* {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                    <IconButton onClick={draweropen} className={classes.img}>
                                        <Badge variant="dot" color="primary">
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>

                                ) : (
                                    <IconButton onClick={draweropen} className={classes.img}>
                                        <FilterIMG color="#091b29" />
                                    </IconButton>)} */}

                                        {permission.create && <Button
                                            variant="contained"
                                            className={classes.btn}
                                            onClick={addNew}
                                        >
                                            {t("Add a Vendor")}
                                        </Button>}
                                    </Stack>
                                </Box>

                            </Grid>
                            <Grid item xs={12} marginTop="-14px">
                                <TableWithPagination
                                    heading={vendorTableDetails['header']}
                                    rows={vendorList?.data}
                                    showpagination={true}
                                    handleIcon={handleIcon}
                                    onClick={(data) => console.log("")}
                                    tableType="no-side"
                                    dataType={vendorTableDetails['dataType']}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={vendorList?.totalRowsCount}
                                    page={page}
                                    limit={limit}
                                    height={'calc(100vh - 290px)'}
                                    view={permission.read}
                                    edit={permission.update}
                                    delete={permission.delete} />
                            </Grid>

                        </Grid>
                    </>}
            </div>
        </>
    }

    return <div >
        {accessCheckRender(render, permission)}
    </div>
}
export default withNamespaces("vendorCreation")(VendorListing)
