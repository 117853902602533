import React from 'react';
import { Box, Typography, Grid } from "@mui/material";
import { LeadStylesParent } from '../style';
import ShowMoreText from "react-show-more-text";
import moment from 'moment';

export const ProfileCard = (props) => {
      const { t } = (props)
      // styles
      const classes = LeadStylesParent();
      return (
            <div className={classes.profileroot}>
                  <div className={classes.cardBottomLine}>
                        <div className={classes.profilePadding}>
                              <div className={classes.profilecard}> <Typography className={classes.profileTilte}>{props?.viewDetails?.name ?? ""}</Typography> <div><Typography className={classes.leadnumber}>{props?.viewDetails?.reference_no ?? ""}</Typography></div></div>
                              <Box className={classes.profilecardContent}>
                                    {/* <Box> <Typography className={classes.leadcontact}>{props?.viewDetails?.name ?? ""}</Typography></Box> */}
                                    <Box> <Typography className={classes.leadcontactNo}>{props?.viewDetails?.mobile_country_code ?? ""}-{props?.viewDetails?.mobile_no ?? ""}</Typography></Box>
                                    <Box> <Typography className={classes.leadcontact}><Box className={classes.dot} />{props?.viewDetails?.email ?? ""}</Typography></Box>
                              </Box>
                        </div>
                  </div>
                  {/* des */}
                  {
                        props?.viewDetails?.description?.length > 0 &&
                        <div className={`${classes.profilePadding} ${classes.cardBottomLine}`}>
                              <>
                                    <Typography className={classes.profileDescription}>{t("DESCRIPTION / REQUIREMENT")}</Typography>

                                    <ShowMoreText
                                          lines={1}
                                          more="Show More"
                                          less="Show Less"
                                          className={classes.detailsub}
                                          anchorClass={classes.seeMoreLessTextStyle}
                                          expanded={false}
                                          truncatedEndingComponent={"... "}
                                    >
                                          {props?.viewDetails?.description?.split('\n').map((item, i) => <Typography className={classes.profileDescriptioncontant} key={i}>{item}</Typography>)}
                                    </ShowMoreText>
                              </>


                        </div>

                  }
                  {
                        (props?.viewDetails?.archieved_description?.length > 0  || props?.viewDetails?.remarks?.length > 0)&&
                        <div className={`${classes.profilePadding} ${classes.cardBottomLine}`}>
                              <>
                                    <Typography className={classes.profileDescription}>{t("NOTES")}</Typography>
                          {
                              !!props?.viewDetails?.archieved_description && 
                              <ShowMoreText
                              lines={1}
                              more="Show More"
                              less="Show Less"
                              className={classes.detailsub}
                              anchorClass={classes.seeMoreLessTextStyle}
                              expanded={false}
                              truncatedEndingComponent={"... "}
                        >
                              <Typography className={classes.profileDescriptioncontant}>{props?.viewDetails?.archieved_description ?? ""}</Typography>
                        </ShowMoreText>
                          }
                           {
                              !!props?.viewDetails?.remarks  && 
                              <ShowMoreText
                              lines={1}
                              more="Show More"
                              less="Show Less"
                              className={classes.detailsub}
                              anchorClass={classes.seeMoreLessTextStyle}
                              expanded={false}
                              truncatedEndingComponent={"... "}
                        >
                              <Typography className={classes.profileDescriptioncontant}>{props?.viewDetails?.remarks ?? ""}</Typography>
                        </ShowMoreText>
                          }
                                   
                              </>


                        </div>

                  }

                  {/*  */}
                  <Box className={`${classes.profilePadding} ${classes.cardBottomLine}`}>
                        <Grid container spacing={2}>
                              <Grid item sm={12} md={6} lg={6}>
                                    <Box  >
                                          <Typography className={classes.title}>{props?.viewDetails?.purpose ?? '-'}</Typography>
                                          <Typography className={classes.subtitle}>{t("PURPOSE")}</Typography>
                                    </Box>
                              </Grid>
                              <Grid item sm={12} md={6} lg={6}>
                                    <Box className={classes.borderLeft} paddingInlineStart={1.5}>
                                          <Typography className={classes.title} >{props?.viewDetails?.revenue_type}</Typography>
                                          <Typography className={classes.subtitle}>{t("TYPE")}</Typography>
                                    </Box>
                              </Grid>
                        </Grid>
                  </Box>
                  <Box className={`${classes.profilePadding} ${classes.cardBottomLine}`}>
                        <Grid container spacing={2}>
                              <Grid item sm={12} md={6} lg={6}>
                                    <Box  >
                                          {(props?.viewDetails?.value || props?.viewDetails?.lease_period) ?
                                                <Typography className={classes.title}>{props?.viewDetails?.value} {props?.viewDetails?.lease_period}</Typography> :
                                                <Typography className={classes.title}>-</Typography>
                                          }
                                          <Typography className={classes.subtitle}>{t("LEASE DURATION")}</Typography>
                                    </Box>
                              </Grid>
                              <Grid item sm={12} md={6} lg={6}>
                                    <Box className={classes.borderLeft} paddingInlineStart={1.5}>
                                          <Typography className={classes.title} >{props?.viewDetails?.lease_start_date ? moment(props?.viewDetails?.lease_start_date).format('DD-MM-YYYY') : "-" }</Typography>
                                          <Typography className={classes.subtitle}>{t("START PERIOD")}</Typography>
                                    </Box>
                              </Grid>
                        </Grid>
                  </Box>
                  <Box className={`${classes.profilePadding} ${classes.cardBottomLine}`}>
                        <Grid container spacing={2}>
                              <Grid item sm={12} md={6} lg={6}>
                                    <Box  >
                                          <Typography className={classes.title}>{props?.viewDetails?.source}</Typography>
                                          <Typography className={classes.subtitle}>{t("LEAD SOURCE")}</Typography>
                                    </Box>
                              </Grid>
                              <Grid item sm={12} md={6} lg={6}>
                                    <Box className={classes.borderLeft} paddingInlineStart={1.5}>
                                          <Typography className={classes.title} >{props?.viewDetails?.internal_lead_source?.name ?? "-" }</Typography>
                                          <Typography className={classes.subtitle}>{t("Internal Lead Source")}</Typography>
                                    </Box>
                              </Grid>
                        </Grid>
                  </Box>

            </div>
      )
}
