import * as React from "react"

const ByCycle = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={22.545} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={-0.063}
        y1={-0.132}
        x2={0.962}
        y2={1.018}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f44f5a" />
        <stop offset={0.45} stopColor="#ee3d4a" />
        <stop offset={1} stopColor="#bf1237" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={-0.007}
        y1={0.153}
        x2={0.932}
        y2={0.796}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f44f5a" />
        <stop offset={0.4} stopColor="#ee3d4a" />
        <stop offset={1} stopColor="#ba1035" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={-0.032}
        y1={-0.107}
        x2={1.174}
        y2={0.924}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f44f5a" />
        <stop offset={0.4} stopColor="#ee3d4a" />
        <stop offset={1} stopColor="#bd1237" />
      </linearGradient>
    </defs>
    <g transform="translate(-1.455 -6.545)">
      <path
        data-name="Path 96529"
        d="M16.48 22.233 10.971 9.748l1.329-.587 5.509 12.484Z"
        fill="url(#a)"
      />
      <path
        data-name="Path 96530"
        d="M8 29.091a6.545 6.545 0 1 1 6.545-6.545A6.518 6.518 0 0 1 8 29.091Zm0-11.636a5.091 5.091 0 1 0 5.091 5.091A5.06 5.06 0 0 0 8 17.455Z"
        fill="#586169"
      />
      <path
        data-name="Path 96531"
        d="M26.909 29.091a6.545 6.545 0 1 1 6.545-6.545 6.518 6.518 0 0 1-6.545 6.545Zm0-11.636A5.091 5.091 0 1 0 32 22.545a5.06 5.06 0 0 0-5.091-5.09Z"
        fill="#586169"
      />
      <path
        data-name="Path 96532"
        d="M16.727 23.273H8a.727.727 0 0 1-.64-1.073l5.091-9.455a.726.726 0 0 1 .64-.383h9.455a.727.727 0 0 1 .62 1.108l-5.818 9.455a.727.727 0 0 1-.621.348Zm-7.51-1.455h7.1l4.923-8h-7.714Z"
        fill="url(#b)"
      />
      <circle
        data-name="Ellipse 129412"
        cx={1.455}
        cy={1.455}
        r={1.455}
        transform="translate(6.577 21.091)"
        fill="#999"
      />
      <path
        data-name="Path 96533"
        d="M10.758 10.894a1.42 1.42 0 0 1-1.064-.274l-.671-.506a.776.776 0 0 1 .453-1.386h5a.784.784 0 0 1 .785.784.784.784 0 0 1-.662.775Z"
        fill="#565b5e"
      />
      <path
        data-name="Path 96534"
        d="m22.811 10.683 1.164-.291a1.952 1.952 0 0 0-.475-3.847h-1.682V8H23.5a.5.5 0 0 1 .121.981l-1.981.5a.727.727 0 0 0-.529.882.72.72 0 0 0 .042.1h-.009l5.091 12.364 1.345-.554Z"
        fill="url(#c)"
      />
      <path
        data-name="Path 96535"
        d="M22.545 8h-2.909a.728.728 0 0 1-.727-.727.728.728 0 0 1 .727-.727h2.909Z"
        fill="#565b5e"
      />
      <circle
        data-name="Ellipse 129413"
        cx={1.455}
        cy={1.455}
        r={1.455}
        transform="translate(25.455 21.091)"
        fill="#999"
      />
    </g>
  </svg>
)

export default ByCycle
