import gql from "graphql-tag";

export const UPDATE_AGREEMENT_UNIT_ACCESS = `
mutation UPDATE_AGREEMENT_UNIT_ACCESS(
    $agreementAccessID: ID
    $updatePayload: agreement_accessInput
  ) {
    agreement_access(
      where: { id: { eq: $agreementAccessID } }
      update: $updatePayload
    ) {
      id
    }
  }`;

export const LEAD_INSERT = `
  mutation($insert: [contactInput!]) {
    contact(insert: $insert ) {
      id
      first_name
      last_name
      email_id
      mobile_no
      mobile_no_country_code
      alternative_mobile
      street_1
      street_2
      street_3
      city
      state
      country
      facebook
      linkedin
      twitter
    }
  }`;

export const POSTLEADDETAILS = `
mutation($insert: [leadInput!]) {
  lead(insert: $insert ) {
    id
  }
}`;

export const UPDATE_LEAD_INSERT = `
  mutation($id: [contactInput!]) {
    contact(  where: { id: { eq: $id } }
      update: $data ) {
      id
    }
  }`;

export const UPDATE_DETAILS = `
  mutation($id: ID, $data: prospectiveInput) {
    prospective(
      where: { id: { eq: $id } }
      update: $data
    ) {
      id
    }
  }
  `;

export const UPDATE_LEAD = `
mutation($id: ID, $data: leadInput) {
  lead(
    where: { id: { eq: $id } }
    update: $data
)
{
  id
}
}`;
export const ACTIVITYCREATE = `
mutation($insertPayload: [lead_activitiesInput!]) {
  lead_activities(insert: $insertPayload) {
    id
  }
}`;
export const UPDATEACTIVITIES = `
mutation($leadActivityId: ID, $updatePayload: lead_activitiesInput) {
  lead_activities(
    where: { id: { eq: $leadActivityId } }
    update: $updatePayload
  ) {
    id
  }
}`;

export const UPLOADLEADIMAGE = `
mutation($insert: [assetsInput!]) {
  assets(insert: $insert,assets_master_type:4) {
    reference_id
    id
    url
    created_by
    assets_master_type
    priority
  }
}
`;

export const UPLOADLEADIMAGE_UPDATE = `
mutation($insert: assetsInput) {
  assets(insert: $insert, assets_master_type:4) {
    reference_id
    id
    url
    created_by
    assets_master_type
    priority
  }
}
`;

export const IMAGE_UPDATE = `
mutation($id: ID, $data: assetsInput) {
  assets(where: { reference_id: { eq: $id } }, update: $data ) {
    id
    url
  }
}`;

export const SHORTLIST_UNITS_TO_EXISTING_LEAD = `
mutation SHORTLIST_UNITS_TO_EXISTING_LEAD($insertPayload: [lead_unitsInput!]) {
  lead_units(insert: $insertPayload) {
    id
  }
}`;

export const CREATE_QUOTATION = `
mutation CREATE_QUOTATION($insertPayload: [quotationInput!]) {
  quotation(insert: $insertPayload) {
    id
  }
}`;

export const CREATE_QUOTATION_UNITS = `
mutation CREATE_QUOTATION_UNITS($insertPayload: [quotation_unitsInput!]) {
  quotation_units(insert: $insertPayload) {
    id
  }
}`;

export let INSERT_GENERAL_MAINTENANCE_REQUEST_STATUS = (requestType) => gql`
mutation INSERT_GENERAL_MAINTENANCE_REQUEST_STATUS(
  ${requestType === "General" ? `$insertRequestStatusPayload: [general_statusInput!]` : ``}
  ${requestType === "Maintenance" ? `$insertRequestStatusPayload: [maintenance_statusInput!]` : ``}
) {
  ${requestType === "General" ? `general_status(insert: $insertRequestStatusPayload) {
    id
  }`: ``}
  ${requestType === "Maintenance" ? `maintenance_status(insert: $insertRequestStatusPayload) {
    id
  }`: ``}
}`;




export const DELETE_NOTIFICATION = `
mutation DELETE_NOTIFICATION {
  notifications(where: { id: { eq: $noficationID } }, update: $updatePayload) {
    id
    is_active
  }
}`

export const UPDATE_PROPERTY = gql`
mutation UPDATE_PROPERTY(
    $ID: ID
    $updatePayload: propertyInput
  ) {
    property(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`
export const UPDATE_BLOCK = gql`
mutation UPDATE_BLOCK(
    $ID: ID
    $updatePayload: blockInput
  ) {
    block(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`
export const UPDATE_FLOOR = gql`
mutation UPDATE_FLOOR(
    $ID: ID
    $updatePayload: floorInput
  ) {
    floor(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`

export const UPDATE_UNIT = gql`
mutation UPDATE_UNIT(
    $ID: ID
    $updatePayload: unitInput
  ) {
    unit(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`

export const ADD_VACANCY_PERIOD_FOR_UNIT = gql`
mutation ADD_VACANCY_PERIOD_FOR_UNIT {
  unit_vacancy_period(insert: $insertPayload) {
    id
  }
}`

export const ADD_COMPONENT_BASED_PRICING = gql`
mutation ADD_COMPONENT_BASED_PRICING {
  pricing_table(insert: $payload) {
    id
  }
}`;

export const EDIT_COMPONENT_BASED_PRICING = gql`
mutation EDIT_COMPONENT_BASED_PRICING {
  pricing_table(
    where: { id: { eq: $pricingID } }
    update: $payload) {
    id
  }
}`;

export const ADD_SHORT_TERM_PRICING = gql`
mutation ADD_SHORT_TERM_PRICING {
  short_term_pricing(insert: $payload) {
    id
  }
}`;

export const EDIT_SHORT_TERM_PRICING = gql`
mutation EDIT_SHORT_TERM_PRICING {
  short_term_pricing(
    where: { id: { eq: $pricingID } }
    update: $payload) {
    id
  }
}`;

export const UPDATE_COMPONENT_BASED_PRICING = gql`
mutation UPDATE_COMPONENT_BASED_PRICING {
  pricing_table(where: { id: { eq: $pricingID } }, update: $payload) {
    id
  }
}`;

export const ADD_CONFIGURATION = gql`
  mutation UPDATE_CONFIG(
    $ID: ID
    $updatePayload: unitInput
  ) {
    sequence_config(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`

export const UPDATE_UTILITIES_MASTER = gql`
mutation UPDATE_UTILITIES_MASTER(
    $ID: ID
    $updatePayload: utilitiesMasterInput
  ) {
    utilities_master(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`
export const UPDATE_UTILITIY_UTILITIES = gql`
mutation UPDATE_UTILITIY_UTILITIES(
    $ID: ID
    $updatePayload: unitUtilitiesInput
  ) {
    unit_utilties(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`

export const ADD_COMMAND_PROXY_LEAD = `
  mutation($id: String, $data: proxy_leadInput) {
    proxy_lead(where: { id: { eq: $id } }, update: $data) {
      id
    }
  }
  `
export const UPDATE_AMENITIES_MASTER = gql`
mutation UPDATE_AMENITIES_MASTER(
    $ID: ID
    $updatePayload: amenitiesTypeMasterInput
  ) {
    amenities_type_master(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`

export const UPDATE_CONTACT = gql`
  mutation UPDATE_CONTACT($contactID: ID, $payload: contactInput) {
    contact(where: { id: { eq: $contactID } }, update: $payload) {
      id
      is_active
      first_name
      updated_at
    }
  }`;
export const UPDATE_PROPERTY_AMENITIES = gql`
mutation UPDATE_PROPERTY_AMENITIES(
    $ID: ID
    $updatePayload: propertyAmenitiesInput
  ) {
    property_facilities(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`
export const UPDATE_UNIT_AMENITIES = gql`
mutation UPDATE_UNIT_AMENITIES(
    $ID: ID
    $updatePayload: unitAmenitiesInput
  ) {
    unit_amenities_breakup(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`

export const INSERT_PRICING_COMPONENT = gql`
mutation INSERT_PRICING_COMPONENT($payload: [pricing_componentInput!]) {
  pricing_component(insert: $payload) {
    id
  }
}`;

export const UPDATE_PRICING_COMPONENT = gql`
mutation UPDATE_PRICING_COMPONENT(
  $pricingComponentID: ID
  $payload: pricing_componentInput
) {
  pricing_component(
    where: { id: { eq: $pricingComponentID } }
    update: $payload
  ) {
    id
  }
}`;

export const INSERT_PRICING_TABLE = gql`
mutation INSERT_PRICING_TABLE($payload: [pricing_tableInput!]) {
  pricing_table(insert: $payload) {
    id
  }
}`;

export const UPDATE_PRICING_TABLE = gql`
mutation UPDATE_PRICING_TABLE(
  $pricingTableID: ID
  $payload: pricing_tableInput
) {
  pricing_table(where: { id: { eq: $pricingTableID } }, update: $payload) {
    id
  }
}`;


export let INSERT_GENERAL_MAINTENANCE_REQUEST_STATUS_UPDATE = (requestType) => gql`
mutation INSERT_GENERAL_MAINTENANCE_REQUEST_STATUS(
  ${requestType === "General" ? `$updatePayload: $general_statusInput` : ``}
  ${requestType === "Maintenance" ? `$updatePayload: $ maintenance_statusInput` : ``}
) {
  ${requestType === "General" ? `general_status(
    where: { general_id: { eq: $genralRequestId },client:{eq:$client} }
    update: $updatePayload
  ) {
    id
  }`: ``}
  ${requestType === "Maintenance" ? `maintenance_status(
    where: { general_id: { eq: $maintenanceRequestId },client:{eq:$client} }
    update: $updatePayload
  ) {
    id
  }`: ``}
}`;


export const CLOSE_REQUEST = (payload) => gql`
mutation UPDATE_REQUEST_STATUS(
    $updatePayload: ${payload?.inputType}
  ) {
    ${payload?.typename}(
      where: { ${payload?.request_id}: { eq:"${payload?.request}" } ,  client:{eq:"${payload?.client}"} }
      update: $updatePayload
    ) {
      id
    }
  }
`

export const CREATE_CONTACT = `
  mutation($insert: [prospectiveInput!]) {
    prospective(insert: $insert ) {
      id
    }
  }`

export const CREATE_LEAD = `
  mutation($insert: [prospectiveInput!]) {
    lead(insert: $insert ) {
      id
    }
  }`

export const INSERT_PROFESSION_MASTER = gql`
mutation INSERT_PROFESSION_MASTER($payload: [profession_masterInput!]) {
  profession_master(insert: $payload) {
    id
  }
}`;

export const UPDATE_PROFESSION_MASTER = gql`
mutation UPDATE_PROFESSION_MASTER(
  $professionMasterID: ID
  $payload: profession_masterInput
) {
  profession_master(
    where: { id: { eq: $professionMasterID } }
    update: $payload
  ) {
    id
  }
}`;

export const INSERT_DEPARTMENT_MASTER = gql`
mutation INSERT_DEPARTMENT_MASTER($payload: [department_masterInput!]) {
  department_master(insert: $payload) {
    id
  }
}`;

export const UPDATE_DEPARTMENT_MASTER = gql`
mutation UPDATE_DEPARTMENT_MASTER(
  $departmentMasterID: ID
  $payload: department_masterInput
) {
  department_master(
    where: { id: { eq: $departmentMasterID } }
    update: $payload
  ) {
    id
  }
}`;

export const INSERT_PARKING_AREA = gql`
mutation INSERT_PARKING_AREA($payload: [parking_areaInput!]) {
  parking_area(insert: $payload) {
    id
  }
}`;

export const UPDATE_PARKING_AREA = gql`
mutation UPDATE_PARKING_AREA($parkingAreaID: ID, $payload: parking_areaInput) {
  parking_area(where: { id: { eq: $parkingAreaID } }, update: $payload) {
    id
  }
}`;

export const INSERT_ACCESS_GATE = gql`
mutation INSERT_ACCESS_GATE($payload: [access_gates_masterInput!]) {
  access_gates_master(insert: $payload) {
    id
  }
}`;

export const UPDATE_ACCESS_GATE = gql`
mutation UPDATE_ACCESS_GATE(
  $accessGatesMasterID: ID
  $payload: access_gates_masterInput
) {
  access_gates_master(
    where: { id: { eq: $accessGatesMasterID } }
    update: $payload
  ) {
    id
  }
}`;

export const UPDATE_SERVICE_PROVIDER = gql`
mutation UPDATE_SERVICE_PROVIDER(
  $serviceProviderID: ID
  $payload: service_providers_masterInput
) {
  service_providers_master(
    where: { id: { eq: $serviceProviderID } }
    update: $payload
  ) {
    id
  }
}`;

export const INSERT_JOB_MASTER = gql`
mutation INSERT_JOB_MASTER($payload: [job_masterInput!]) {
  job_master(insert: $payload) {
    id
  }
}`;

export const UPDATE_JOB_MASTER = gql`
mutation UPDATE_JOB_MASTER(
  $jobMasterID: ID
  $payload: job_masterInput
) {
  job_master(
    where: { id: { eq: $jobMasterID } }
    update: $payload
  ) {
    id
  }
}`;

export const INSERT_EMERGENCY_MASTER = gql`
mutation INSERT_EMERGENCY_MASTER($payload: [emergency_masterInput!]) {
  emergency_master(insert: $payload) {
    id
  }
}`;

export const UPDATE_EMERGENCY_MASTER = gql`
mutation UPDATE_EMERGENCY_MASTER(
  $emergencyMasterID: ID
  $payload: emergency_masterInput
) {
  emergency_master(
    where: { id: { eq: $emergencyMasterID } }
    update: $payload
  ) {
    id
  }
}`;

export const INSERT_DOMESTIC_HELPER = gql`
mutation INSERT_DOMESTIC_HELPER($payload: [domestic_helper_masterInput!]) {
  domestic_helper_master(insert: $payload) {
    id
  }
}`;

export const UPDATE_DOMESTIC_HELPER = gql`
mutation UPDATE_DOMESTIC_HELPER(
  $domesticHelperID: ID
  $payload: domestic_helper_masterInput
) {
  domestic_helper_master(
    where: { id: { eq: $domesticHelperID } }
    update: $payload
  ) {
    id
  }
}`;


export const CREATE_ACCOUNT_USER = gql`
mutation($insert: [account_usersInput!]) {
  account_users(insert: $insert) {
    id
  }
}

`
export const INSERT_SHORT_TERM_PRICING = gql`
mutation INSERT_SHORT_TERM_PRICING($payload: [short_term_pricingInput!]) {
  short_term_pricing(insert: $payload) {
    id
  }
}`;

export const UPDATE_SHORT_TERM_PRICING = gql`
mutation UPDATE_SHORT_TERM_PRICING(
  $shortTermPricingID: ID
  $payload: short_term_pricingInput
) {
  short_term_pricing(where: { id: { eq: $shortTermPricingID } }, update: $payload) {
    id
  }
}`;
export const UPDATE__DELIVERY_MILESTONE_MASTER = gql`
mutation UPDATE__DELIVERY_MILESTONE_MASTER(
    $ID: ID
    $updatePayload: deliveryMilestoneMastersInput
  ) {
    delivery_milestone_masters(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`
export const UPDATE__DELIVERY_MILESTONE_TEMPLATE = gql`
mutation UPDATE__DELIVERY_MILESTONE_TEMPLATE(
    $ID: ID
    $updatePayload: deliveryMilestoneTemplateInput
  ) {
    milestone_template_master(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`


export const ADD_TODO = `
mutation($insert: [proxy_lead_checklistInput!]) {
  proxy_lead_checklist(insert: $insertPayload) {
    id
  }
}
`;
export const EDIT_TODO = `
mutation(
  $id: ID
  $updatePayload: proxy_lead_checklistInput
  ) {
  proxy_lead_checklist(
    where: { id: { eq: $id } }
    update: $updatePayload
    ) {
    id
  }
}

  `;


export const INSERT_ACTIVITY_CATEGORY = gql`
  mutation INSERT_ACTIVITY_CATEGORY($payload: [activity_category_masterInput!]) {
    activity_category_master(insert: $payload) {
      id
    }
  }`;

export const UPDATE_ACTIVITY_CATEGORY = gql`
  mutation UPDATE_ACTIVITY_CATEGORY(
    $activityCategoryID: ID
    $payload: activity_category_masterInput
  ) {
    activity_category_master(
      where: { id: { eq: $activityCategoryID } }
      update: $payload
    ) {
      id
    }
  }`;

export const INSERT_ACTIVITY_SUB_CATEGORY = gql`
  mutation INSERT_ACTIVITY_SUB_CATEGORY($payload: [activity_sub_category_masterInput!]) {
    activity_sub_category_master(insert: $payload) {
      id
    }
  }`;

export const UPDATE_ACTIVITY_SUB_CATEGORY = gql`
  mutation UPDATE_ACTIVITY_SUB_CATEGORY(
    $activitySubCategoryID: ID
    $payload: activity_sub_category_masterInput
  ) {
    activity_sub_category_master(
      where: { id: { eq: $activitySubCategoryID } }
      update: $payload
    ) {
      id
    }
  }`;

export const UPDATE_PROFILE_DETAILS = gql`
  mutation UPDATE_PROFILE_DETAILS(
    $userID: ID
    $userProfilePayload: user_profilesInput
  ) {
    user_profiles(update: $userProfilePayload, where: { id: { eq: $userID } }) {
      id
    }
  }`;

export const UPDATE_CONTACT_DETAILS = gql`
  mutation UPDATE_CONTACT_DETAILS($contactID: ID, $contactPayload: contactInput) {
    contact(update: $contactPayload, where: { id: { eq: $contactID } }) {
      id
    }
  }`;

export const UPDATE_CLIENT_USER_ROLE_ACCESS = gql`
mutation UPDATE_CLIENT_USER_ROLE_ACCESS(
    $ID: ID
    $updatePayload: clientUserRoleAccessInput
  ) {
    client_user_role_access(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`

export const UNREADNOTIFICATION = `
mutation($id: String, $updatedData: notification_logInput) {
  notification_log(
    where: { notification_id: { eq: $id } }
    update: $updatedData

  ) {
    id
    is_read
  }
}`;

export const LOG_OUT_TOKEN = `
mutation($id:String,$token:String,$data:user_device_tokenInput) {
user_device_token(
    where: { user_profile_id: { eq: $id },token:{eq:$token} }
    update: $data

  ) {
    id
  }
}

`;

export const INSERT_WORKING_HOURS_MASTER = gql`
mutation INSERT_WORKING_HOURS_MASTER($payload: [working_hours_masterInput!]) {
  working_hours_master(insert: $payload) {
    id
  }
}`

export const UPDATE_WORKING_HOURS_MASTER = gql`
mutation UPDATE_WORKING_HOURS_MASTER(
  $working_hours_master_id: ID
  $payload: working_hours_masterInput
) {
  working_hours_master(
    where: { id: { eq: $working_hours_master_id } }
    update: $payload
  ) {
    id
  }
}`

export const UPDATE_INSPECTION_STATUS = gql`
mutation($id: ID, $payload: payload) {
  agreement_inspection(where: { id: { eq: $id } }, update: $payload) {
    id
  }
}
`

export const UPDATE_DIRECTORY_ASSETS = gql`
mutation UPDATE_DIRECTORY_ASSETS($ID: ID, $updatePayload: directory_assetsInput) {
  directory_assets(where: { id: { eq: $ID } }, update: $updatePayload) {
    id
  }
}`

export const UPDATE_NOTES_MAINTANCE_REQUEST = gql`
mutation {
  maintenance_status(update: $payload, where: { id: { eq: $id } }) {
    notes
  }
}`

export const UPDATE_NOTES_GERNERAL_REQUEST = gql`
mutation {
  general_status(update: $payload, where: { id: { eq: $id } }) {
    id
  }
}`


// export const CLOSE_REQUEST_MAINTANCE = gql`
// mutation {
//   maintenance_status(
//      insert: $payload
//    ) {
//     id
//    }
//  }`


export const CLOSE_REQUEST_MAINTANCE = (payload) => gql`
mutation {
    ${payload?.typename}(
         insert: $payload
          ) {
      id
    }
  }
`
export const UPDATE_DIRECTORY = gql`
mutation UPDATE_DIRECTORY($ID: ID, $updatePayload: directoryInput) {
  directory(where: { id: { eq: $ID } }, update: $updatePayload) {
    id
  }
}`

export const UPDATE_TEAMS = gql`
mutation UPDATE_TEAMS(
    $ID: ID
    $updatePayload: teamsInput
  ) {
    teams(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`
export const UPDATE_TEAMS_PROPERTY = gql`
mutation UPDATE_TEAMS_PROPERTY(
    $ID: ID
    $updatePayload: teamsPropertyInput
  ) {
    team_property_access(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`
export const UPDATE_TEAMS_USER = gql`
mutation UPDATE_TEAMS_USER(
    $ID: ID
    $updatePayload: teamsuserInput
  ) {
    team_users_access(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`



