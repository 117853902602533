import gql from "graphql-tag";

export const Community_tread = `mutation($id: ID, $data: community_discussionInput) {
      community_discussion(
        where: { id: { eq: $id } }
        update: $data
      ) {
        id
      }
    }`;


export const Community_comments = `mutation($id: ID, $data: community_discussion_commentsInput) {
      community_discussion_comments(
        where: { id: { eq: $id } }
        update: $data
      ) {
        id
      }
    }`;


export const UPDATE_SECURITY_REQUEST = `
mutation UPDATE_SECURITY_REQUEST(
  $securityRequestID: ID
  $updatePayload: security_requestInput
) {
  security_request(
    where: { id: { eq: $securityRequestID } }
    update: $updatePayload
  ) {
    id
  }
}`;

export const Deliviery_Gate = `
mutation($id: ID, $data: security_requestInput) {
  security_request(
    where: { id: { eq: $id } }
    update: $data
  ) {
    id
  }
}
`;

export const UPDATE_DOMESTIC_HELPER_REQUEST = gql`
mutation UPDATE_DOMESTIC_HELPER_REQUEST(
  $domesticHelperRequestID: ID
  $updateDomesticHelperRequestPayload: domestic_helper_requestInput
) {
  domestic_helper_request(
    where: { id: { eq: $domesticHelperRequestID } }
    update: $updateDomesticHelperRequestPayload
  ) {
    id
    domestic_helper_request_id: id
    helper_id
    unit_id
  }
}`;

export let UPDATE_DOMESTIC_HELPER_UNIT = (status) => gql`
mutation UPDATE_DOMESTIC_HELPER_UNIT(
  ${status === "accept" ? `$insertDomesticHelperUnitsPayload: [domestic_helper_unitsInput!]` : ``}
  ${status === "decline" ? `$domesticHelperUnitsID: ID
  $updateDomesticHelperUnitsPayload: domestic_helper_unitsInput`: ``}
) {
  ${status === "accept" ?
    `insert_domestic_helper_units: domestic_helper_units(
      insert: $insertDomesticHelperUnitsPayload
    ) {
      id
    }` : ``}
  ${status === "decline" ?
    `update_domestic_helper_units: domestic_helper_units(
      where: { id: { eq: $domesticHelperUnitsID } }
      update: $updateDomesticHelperUnitsPayload
    ) {
      id
    }` : ``}
}`;

export const DECLINE_PARKING_SLOT_REQUEST = `
mutation DECLINE_PARKING_SLOT_REQUEST {
  security_request(
    where: { id: { eq: $parkingSlotRequestID } }
    update: $updatePayload
  ) {
    id
    status
    remarks
  }
}`;
export const UPDATE_PARKING_SLOT_MASTER = gql`
mutation UPDATE_PARKING_SLOT_MASTER(
    $ID: ID
    $updatePayload: parkingSlotMasterInput
  ) {
    parking_master(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`