import * as React from "react"

const OwnerIcon = (props) => (
    <svg
        data-name="Group 97061"
        xmlns="http://www.w3.org/2000/svg"
        width={60}
        height={60}
        {...props}
    >
        <rect
            data-name="Rectangle 51108"
            width={60}
            height={60}
            rx={30}
            fill="#ff9518"
            opacity={0.13}
        />
        <path
            d="M28.167 16a6.667 6.667 0 1 0 6.667 6.667A6.674 6.674 0 0 0 28.167 16Zm10.666 10.008a4 4 0 0 0-2 7.458v1.656a1.334 1.334 0 0 0 .391.943l.943.943-1.1 1.07a.8.8 0 0 0-.012 1.132l1.107 1.131L37.1 41.4a.866.866 0 0 0 0 1.22l1.066 1.1a.935.935 0 0 0 1.341 0l.954-.987a1.334 1.334 0 0 0 .376-.928v-8.339a4 4 0 0 0-2-7.458Zm0 2a1.333 1.333 0 1 1-1.333 1.333 1.337 1.337 0 0 1 1.333-1.333ZM20.152 32a2.655 2.655 0 0 0-2.652 2.652v1.014a6.127 6.127 0 0 0 3.538 5.375 15.033 15.033 0 0 0 7.129 1.625 14.424 14.424 0 0 0 7.918-2.112c.027-.031.047-.064.077-.094l.13-.13-.185-.189a2.13 2.13 0 0 1 .034-3.017l.13-.128a2.686 2.686 0 0 1-.771-1.874v-.958c-.065-.053-.127-.109-.19-.164a5.543 5.543 0 0 1-.427-.422l-.02-.021A5.339 5.339 0 0 1 33.891 32Z"
            fill="#ff9518"
        />
    </svg>
)

export default OwnerIcon
