import { Box, Grid, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import React from "react";
import { AlertProps, SemiBold, allowed_file_size, assestType, deleteS3File, multiFileUpload } from "../../utils";
import { AssetCard } from "./assetCard";
import UploadBtn from "./uploadBtn";
import { AlertContext } from "../../contexts";
import { DocumentViewer } from "../fileViewer";
import { saveAs } from 'file-saver';
import { DialogBox } from "../dialogbox";
const CustomTypography = styled(Typography, {
    shouldForwardProp: prop => prop !== "fontFamily" && prop !== "fontSize" && prop !== "color"
})(({ fontFamily, fontSize, color }) => ({
    fontFamily,
    fontSize,
    color
}))

export const UploadComponent = ({
    logo_title,
    isrequired,
    isError,
    errorMessage,
    handleChange = () => false,
    value = [],
    assets = [],
    setAssets = () => false,
    xs = 2,
    amenities = false,
    marginTop = false,
    readOnly = false,
    accept = null,
    placeholder = "Upload Image",
    btnName = null,
    height = "150px",
    imageHeight = "148px",
    isOnlyOne = false,
    border = false,
    isCustomDelete = false,
    isUploadView = false,
    isCustomUpload = false,
    handleImageUpload = () => false,
}) => {
    const theme = useTheme();
    const alert = React.useContext(AlertContext)
    const [isView, setIsView] = React.useState({
        bool: false,
        data: {}
    })
    // const showImageViewer = (x) => {
    //     setIsView({ ...isView, bool: true, data: x })
    // }
    //upload image
    const handleUpload = async (data) => {
        if (isOnlyOne) {
            if (assets?.length !== 1) {
                const uploaded_files = await multiFileUpload(data, { type: assestType?.General_Images }, alert, allowed_file_size)
                if (uploaded_files?.length > 0) {
                    let dataImage = ([...assets, ...uploaded_files])
                    setAssets(dataImage)
                    if (isCustomUpload) {
                        await handleImageUpload(dataImage)
                    }
                }
            } else {
                alert.setSnack({
                    ...alert, open: true, msg: "Signature Already Uploaded",
                    severity: AlertProps.severity.error
                })
            }
        } else {
            const uploaded_files = await multiFileUpload(data, { type: assestType?.General_Images }, alert, allowed_file_size)
            if (uploaded_files?.length > 0) {
                let dataImage = ([...assets, ...uploaded_files])
                setAssets(dataImage)
                if (isCustomUpload) {
                    await handleImageUpload(dataImage)
                }
            }
        }
    }
    //delete asset
    const onDelete = async (i) => {
        setIsView({ bool: false, data: {} })
        if (isCustomDelete) {
            const edited = assets.map((val, index) => {
                if (val?.id === i?.id) {
                    return {
                        ...val,
                        is_active: false
                    }
                }
                else {
                    return val
                }
            });
            await setAssets(edited)
            if (isCustomUpload) {
                handleImageUpload(edited)
            }
        }
        else {
            const edited = assets.filter((val, index) => { return val !== i });
            setAssets(edited)
        }

        deleteS3File({ urls: [i?.url] });
    }

    ///initial load
    const download = (data) => {
        var ext = data?.url.substring(data?.url?.lastIndexOf(".") + 1);
        saveAs(data?.url, `${data?.file_meta?.name}.${ext}`);
        // setIsView({ bool: false, data: {} })
    }
    return (
        <>
            {logo_title &&
                <CustomTypography
                    fontFamily={SemiBold}
                    fontSize={"0.75rem"}
                    margin="12px 0px 12px 0px"

                    color={theme.typography.color.tertiary}
                >
                    {logo_title}
                    {isrequired && (
                        <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                            *
                        </Typography>
                    )}
                </CustomTypography>
            }


            <Grid container spacing={1} alignItems="center">
                {!isUploadView &&
                    <Grid item xs={12} sm={3} lg={xs ?? 12}>
                        <UploadBtn
                            handleChange={handleUpload}
                            assets={assets}
                            amenities={amenities}
                            accept={accept}
                            height={height}
                            isReadonly={readOnly}
                            buttonName={placeholder || btnName}
                        />
                    </Grid>
                }
                {
                    assets?.filter((x) => { return x?.is_active })?.map((val, index) => {
                        return (
                            <Grid item xs={12} sm={3} lg={xs ?? 12} >
                                <Box marginTop={marginTop ? "auto" : null}>
                                    <AssetCard
                                        readOnly={readOnly}
                                        onDelete={() => onDelete(val)}
                                        imageHeight={imageHeight}
                                        border={border}
                                        onClick={() => setIsView({ bool: true, data: val })}
                                        x={val} />
                                </Box>

                            </Grid >
                        )
                    })
                }
                <Grid item xs={12}>
                    {isError && (
                        <Typography variant={"caption"} color={"error"}>
                            {errorMessage}
                        </Typography>
                    )}
                </Grid>

            </Grid >
            <DialogBox
                maxWidth={"sm"}
                open={isView?.bool}
                onClose={() => setIsView({ bool: false, data: {} })}
                header={"Documents"}
                isDownload={true}
                onDownload={() => download(isView?.data)}
                component={
                    <Box p={2}>
                        {isView?.data?.file_meta?.type === "pdf" ?
                            <DocumentViewer url={isView?.data?.url} />
                            :
                            <img
                                src={isView?.data?.url}
                                alt="asset"
                                height="500px"
                                width="100%"
                                style={{
                                    objectFit: "contain",
                                    borderRadius: "8px"
                                }}
                            />
                        }
                    </Box>
                }
            />
        </>
    )
}