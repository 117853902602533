import { useApolloClient } from "@apollo/client";
import { Badge, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import React from "react";
import FilterIMG from "../../assets/filter";
import { AlertDialog, FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination, TextBox, ToggleButtonComponent, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { UPDATE__DELIVERY_MILESTONE_MASTER } from "../../graphql/mutations";
import { GET_DELIVERY_MILESTONE_MASTER_TABLE_LIST } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, DeliveryMilestoneMasterHeading, DeliveryMilestoneMasterPath, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { DeliveryMilestoneMasterStyles } from "./style";
import { withNamespaces } from "react-i18next";



const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.palette.borderRadius,
    height:"40px"
}))

const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    margin: theme.spacing(3)
}))


const initialState = () => {
    return {
        id: null,
        description: "",
        deliveryMilestoneName: "",
        status: "Active",
        edit: false,
        view: false,
        error: {
            deliveryMilestoneName: "",
            description: "",

        }

    }
}

const STATUS_OPTIONS = (t) => [
    { label: t("Active"), value: "Active" },
    { label: t("In-Active"), value: "Inactive" }

]



const DeliveryMilestoneMaster = ({
    t = () => false
}) => {
    const classes = DeliveryMilestoneMasterStyles()
    const [openDialog, setOpenDialog] = React.useState(false)
    const size = useWindowDimensions();
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext);
    const [deliveryMilestoneMasterData, setDeliveryMilestoneMasterData] = React.useState({ ...initialState() })

    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [deliveryMilestoneMasterList, setDeliveryMilestoneMasterList] = React.useState({});
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState("");
    const userProfileId = localStorage.getItem(LocalStorageKeys.userProfileID)
    const [drawer, setDrawer] = React.useState(null);
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const [loading, setLoading] = React.useState(true)
    const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
    const [permission, setPermission] = React.useState({})
    const [isDisableBtn,setIsDisableBtn]=React.useState(false)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const draweropen = () => {
        setDrawer(true);
    };
    const drawerclose = () => {
        setDrawer(false);
    };
    const debounce = UseDebounce();

    const client = useApolloClient();
    //more options
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        DeliveryMilestoneMasterTableData(offset, limit, searchText)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        DeliveryMilestoneMasterTableData(0, value, searchText)
    }

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        DeliveryMilestoneMasterTableData(0, limit, e)
    }


    //update state
    const updateState = (key, value) => {

        let error = deliveryMilestoneMasterData?.error;
        error[key] = "";
        setDeliveryMilestoneMasterData({ ...deliveryMilestoneMasterData, [key]: value })


    }
    //validation
    const validate = () => {
        let isValid = true;
        let error = deliveryMilestoneMasterData.error;
        if (deliveryMilestoneMasterData?.deliveryMilestoneName?.length === 0) {
            isValid = false;
            error.deliveryMilestoneName = t("Delivery Milestone Name is Required");
        }

        if (deliveryMilestoneMasterData?.description?.length === 0) {
            isValid = false;
            error.description = t("Description is Required");
        }
        if (deliveryMilestoneMasterData?.deliveryMilestoneName?.length === 0 || deliveryMilestoneMasterData?.description?.length === 0) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            });
        }
        setDeliveryMilestoneMasterData({ ...deliveryMilestoneMasterData, error });

        return isValid;
    };


    //Upsert API FUNCTION
    const UpsertDeliveryMilestoneMaster = () => {
        if (validate()) {
            setIsDisableBtn(true)
            let payload;
            if (deliveryMilestoneMasterData?.id) {
                payload = {
                    name: deliveryMilestoneMasterData?.deliveryMilestoneName,
                    updated_by: userProfileId,
                    description: deliveryMilestoneMasterData?.description,
                    company_id: selectedCompany?.value,
                    id: deliveryMilestoneMasterData?.id,
                    is_active: deliveryMilestoneMasterData?.status === "Active" ? true : false
                }
            } else {
                payload = {
                    name: deliveryMilestoneMasterData?.deliveryMilestoneName,
                    description: deliveryMilestoneMasterData?.description,
                    company_id: selectedCompany?.value,
                    created_by: userProfileId,
                    is_active: deliveryMilestoneMasterData?.status === "Active" ? true : false
                }
            }
            NetworkCall(
                `${config.api_url}/delivery-milestone-master/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: deliveryMilestoneMasterData?.id ? t("Delivery Milestone Master Updated successfully") : t("Delivery Milestone Master Added successfully"),
                    });
                    setDeliveryMilestoneMasterData({ ...initialState() })
                    setOpenDialog(false)
                    DeliveryMilestoneMasterTableData()
                    setIsDisableBtn(false)
                })
                .catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Internal error. Please try again later."),
                    });
                    setIsDisableBtn(false)
                });

        }

    }

    //table listing function
    const DeliveryMilestoneMasterTableData = async (offset = 0, limit = 10, search = "") => {
        let companyID = [selectedCompany?.value];
        let is_active = filterData?.status ?? [true];
        const payload = {
            query: GET_DELIVERY_MILESTONE_MASTER_TABLE_LIST(clientId, offset, limit, search, companyID, is_active).loc.source.body,

        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setDeliveryMilestoneMasterList({
                    data: response?.data?.data?.delivery_milestone_masters,
                    totalRowsCount: response?.data?.data?.count?.[0]?.count,

                })
                setLoading(false)
                backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            }).catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
                setLoading(false)

            });
    }

    // is active changing function
    const changeactive = async (data, value) => {
        client.mutate({
            mutation: UPDATE__DELIVERY_MILESTONE_MASTER,
            variables: {
                ID: data,
                updatePayload: {
                    is_active: value === "Inactive" ? true : false
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Status Updated"),
            });
            DeliveryMilestoneMasterTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }
    //delete function
    const deleteTableData = async (data) => {
        client.mutate({
            mutation: UPDATE__DELIVERY_MILESTONE_MASTER,
            variables: {
                ID: data,
                updatePayload: {
                    is_delete: true
                }
            }
        }).then(rs => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Deleted"),
            });
            setOpenDialog(false)
            DeliveryMilestoneMasterTableData()
            console.log(rs)
        }).catch(er => {
            console.log(er)
        })
    }
    //table row constructions
    const DeliveryMilestoneMasterRow = deliveryMilestoneMasterList?.data?.map((val, index) => {
        let _d;
        try {
            _d = {
                index: ((page - 1) * limit) + index + 1,
                name: val?.name ?? " - ",
                description: val?.description ?? " - ",
                status: val?.is_active ? "Active" : "Inactive",
                IsDelete: val?.is_delete,
                icon: "more",
                id: val?.id,

            };
        } catch (err) {

        }
        return _d;
    });


    React.useEffect(() => {
        if (selectedCompany?.value) {
            DeliveryMilestoneMasterTableData()
        }
        //eslint-disable-next-line
    }, [selectedCompany, filterData]);

    const handleIcon = (type, data, status) => {

        if (type === "view") {
            setOpenDialog(true)
            setDeliveryMilestoneMasterData({
                ...deliveryMilestoneMasterData,
                deliveryMilestoneName: data?.name,
                description: data?.description,
                status: data?.status,
                view: true,
                id: data?.id,
                delete: data.IsDelete

            })
        }
        else if (type === "edit") {
            setOpenDialog(true)
            setDeliveryMilestoneMasterData({
                ...deliveryMilestoneMasterData,
                deliveryMilestoneName: data?.name,
                description: data?.description,
                status: data?.status,
                edit: true,
                id: data?.id,
                delete: data.IsDelete

            })
        }
        else if (type === "active") {
            changeactive(data.id, status)
        }
        else if (type === "delete") {
            deleteTableData(data.id, data.IsDelete)
        }

    }



    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
    }


    // add and edit modal
    const addAndEdit = () => {
        return (
            <>
                <div style={{
                    height: size.height - 380,
                    overflow: "scroll",
                    padding: "24px 24px 0px 24px"
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextBox
                                value={deliveryMilestoneMasterData?.deliveryMilestoneName}
                                isrequired
                                label={t("Delivery Milestone Name")}
                                placeholder={t("Enter Delivery Milestone Name")}
                                onChange={(e) => {
                                    updateState("deliveryMilestoneName", e.target.value);
                                }}
                                isError={deliveryMilestoneMasterData?.error?.deliveryMilestoneName?.length > 0}
                                errorMessage={deliveryMilestoneMasterData?.error?.deliveryMilestoneName}
                                isReadonly={deliveryMilestoneMasterData?.view}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextBox
                                multiline
                                value={deliveryMilestoneMasterData?.description}
                                isrequired
                                label={t("Description")}
                                placeholder={t("Enter Description")}
                                onChange={(e) => {
                                    updateState("description", e.target.value);
                                }}
                                isError={deliveryMilestoneMasterData?.error?.description?.length > 0}
                                errorMessage={deliveryMilestoneMasterData?.error?.description}
                                isReadonly={deliveryMilestoneMasterData?.view}

                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography gutterBottom style={{ color: "#98A0AC", fontSize:"0.75rem", marginBottom: "4px" }}>
                                {t("Status")}
                            </Typography>
                            <ToggleButtonComponent
                                options={STATUS_OPTIONS(t)}
                                value={deliveryMilestoneMasterData?.status}
                                onChange={(value) => updateState('status', value)}
                                isMulti={false}
                                fullWidth={false}
                                isError={deliveryMilestoneMasterData?.error?.status?.length > 0}
                                errorMessage={deliveryMilestoneMasterData?.error?.status}
                                isReadOnly={deliveryMilestoneMasterData?.view}
                                buttonGroupStyle={{
                                    gap: '12px'
                                }}
                            />
                        </Grid>



                    </Grid>
                </div >
                <Grid container padding={"0px 24px 24px 24px"} spacing={2} width={"100%"}>
                    {deliveryMilestoneMasterData?.view ?
                        <>
                            {permission?.delete &&
                                <Grid item xs={permission?.update ? 6 : 12}>
                                    <Button fullWidth variant="outlined" className={classes.btn2}
                                        onClick={() => deleteTableData(deliveryMilestoneMasterData?.id, deliveryMilestoneMasterData?.delete)}
                                    >
                                        {t("Delete")}
                                    </Button>
                                </Grid>}
                            {permission?.update &&
                                <Grid item xs={permission?.delete ? 6 : 12}>
                                    <Button fullWidth variant="contained" className={classes.btn}
                                        onClick={() => setDeliveryMilestoneMasterData({
                                            ...deliveryMilestoneMasterData,
                                            view: false,
                                            edit: true
                                        })
                                        }
                                    >
                                        {t("Edit")}
                                    </Button>
                                </Grid>}
                        </> :
                        <Grid item xs={12}>
                            <Button fullWidth variant="contained" className={classes.btn}
                                disabled={isDisableBtn}
                                onClick={UpsertDeliveryMilestoneMaster}
                            >
                                {deliveryMilestoneMasterData?.edit ? t("Update") : t("Create")}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </>
        )
    }

    const render = () => {
        return <>
            {loading ? <LoadingSection bottom={"45vh"} message={t("Loading Delivery Milestone Listing...")} />
                :
                <>
                    <div>
                        <Subheader title={t("Delivery Milestone Master")} count={false} hideBackButton select options={companyList} value={selectedCompany}
                            onchange={(e) =>
                                setSelectedCompany(e)
                                // getProperty(e)
                            }
                        />
                        <CustomPaper>
                            <Grid container className={classes.content} spacing={2}>
                                <Grid item xs={4}>
                                    <SearchFilter placeholder={t("Search by delivery milestone name")} value={searchText} handleChange={(value) => handleSearch(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}/>
                                </Grid>
                                <Grid item xs={8} textAlign={"right"}>
                                    <Box
                                        display={"flex"}
                                        sx={{ justifyContent: "end" }}
                                    >
                                        <Box marginRight={"12px"}>
                                            {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                                <IconButton onClick={draweropen} className={classes.img}>
                                                    <Badge variant="dot" color="primary">
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>

                                            ) : (
                                                <IconButton onClick={draweropen} className={classes.img}>
                                                    <FilterIMG color="#091b29" />
                                                </IconButton>)}

                                        </Box>

                                        {permission?.create &&
                                            <Box sx={{ marginInlineStart: "12px" }}>
                                                <CustomButton
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => setOpenDialog(true)}
                                                >
                                                    {t("Create Delivery Milestone")}
                                                </CustomButton>
                                            </Box>}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TableWithPagination
                                        heading={DeliveryMilestoneMasterHeading(t)}
                                        rows={DeliveryMilestoneMasterRow?.length ? DeliveryMilestoneMasterRow : []}
                                        path={DeliveryMilestoneMasterPath}
                                        showpagination={true}
                                        tableType="no-side"
                                        handleIcon={handleIcon}
                                        onClick={() => null}
                                        showpdfbtn={false}
                                        showexcelbtn={false}
                                        showSearch={false}
                                        dataType={[
                                            { type: ["text"], name: "name" },
                                            { type: ["description"], name: "description" },
                                            { type: ["status"], name: "status" },
                                            { type: ["more"], icon: "icon" },
                                        ]}
                                        handlePagination={handlePagination}
                                        handleChangeLimit={handleChangeLimit}
                                        totalRowsCount={deliveryMilestoneMasterList?.totalRowsCount}
                                        page={page}
                                        limit={limit}
                                        height={'calc(100vh - 290px)'}
                                        view={permission?.read}
                                        edit={permission?.update}
                                        delete={permission?.delete} />
                                </Grid>
                            </Grid>
                        </CustomPaper>

                    </div>
                    {/* add and edit  and view */}
                    <AlertDialog
                        isNormal
                        header={deliveryMilestoneMasterData?.view ? t("View  Delivery Milestone") : deliveryMilestoneMasterData?.edit ? t("Edit  Delivery Milestone") : t("Add Delivery Milestone")}
                        onClose={() => {
                            setOpenDialog(false)
                            setDeliveryMilestoneMasterData({ ...initialState() })
                        }}
                        open={openDialog}
                        component={
                            <>
                                <div>
                                    {

                                        addAndEdit()}
                                </div>
                            </>
                        }
                    />


                    {/* filter drawer */}
                    {
                        drawer &&
                        <FilterGenerator
                            open={drawer}
                            onClose={drawerclose}
                            components={[
                                {
                                    component: "toggleButton",
                                    value: filterData?.status,
                                    options: [
                                        { label: t("Active"), value: true },
                                        { label: t("Inactive"), value: false },
                                    ],
                                    isMulti: true,
                                    state_name: "status",
                                    label: t("Status"),
                                    // required:true
                                },
                            ]}
                            onApply={(value) => onApplyFilter(value)}
                        />
                    }
                </>
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};

export default withNamespaces("unitVacancyPropertyList")(DeliveryMilestoneMaster);
