import gql from "graphql-tag";
export const GET_PARKING_GROUPING_MASTER = gql`
query GET_PARKING_GROUPING_MASTER(
    $propertyId: ID
) {
    count: parking_grouping_master(
        where: {
        property_id: { eq: $propertyId }
      is_active: { eq: "true" }
    }
    ) {
        count: count_id
    }
    parking_grouping_master(
        where: {
        property_id: { eq: $propertyId }
      is_active: { eq: "true" }
    }
    ) {
        id
        name

    }
}
`
export const SLOT_DETAILS = gql`
query GET_PROPERTY_TYPE(
    $securityRequestId: securityRequestId
) {
    security_request(
        where: {
        id: { eq: $securityRequestId }
    }
    ) {
        id
    unit{
            id
            unit_no
        }
    parking_slot{
            parking_id
            reference_id
            allocated_from
            allocated_to
        }
        vechicle_type
        approved_from
        approved_to
        request_from
        created_at
        updated_at
        request_to
        requested_access_gate
    approved_access_gate{
            id
            name
        }
        status
        vechicle_number
        qr_no
    user_profiles:created_byByID{
            id
            first_name
            mobile_no
            mobile_no_country_code
            email_id
            image_url
        }


    }
}
`
