import { Box, Grid, Paper } from "@mui/material"
import React, { useContext, useState } from "react"
import { withNamespaces } from "react-i18next"
import { AlertDialog, SearchFilter, TableWithPagination } from "../../../components"
import { NewLoader } from "../../../components/newLoader"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, FeedBackRepliesDataType, FeedBackRepliesHeading, FeedBackRepliesPath, NetWorkCallMethods } from "../../../utils"
import { ViewFeedBack } from "../../customerFeedBack/viewFeedBack"
import { useStyles } from "../style"


const FeedBackReplies = ({ t = () => false, state = {}, data = {} }) => {
    const [loading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState("")
    const [viewDetails, setViewDetails] = useState([])
    const [tableData, setTableData] = React.useState({
        list: [],
        count: 0,
        isView: false,
        data: {}
    })
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const alert = useContext(AlertContext)

    const classes = useStyles()


    const getFeedBackList = (offset = 0, limit = 10, search = "") => {
        const payload = {
            "id": data?.survey_details?.id,
            "company_id": state?.main?.company_id,
            "offset": 0,
            "limit": 10,
            "search": ""
        };
        NetworkCall(
            `${config?.api_url}/survey/get_values`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const result = res?.data?.data?.data?.map((val) => {
                    return {
                        ...val,
                        job: val?.inspection_type
                    }
                })
                setTableData({ list: result, count: res?.data?.count })
                setLoading(false)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                setLoading(false)
            })
    }
    React.useEffect(() => {
        getFeedBackList(0, 10, "")
        // eslint-disable-next-line
    }, [])


    //handleSearch
    const handleSearch = (value) => {
        setSearchText(value)
        getFeedBackList(0, 10, value)

    }
    //handle Icon
    const handleIcon = (type, data) => {
        const result = {
            email_id: data?.email_id,
            created_by_name: data?.created_by_name,
            survey_element: data?.survey_element?.map((x, i) => {
                return {

                    ...x,
                    order: i + 1,
                    data_type_options: [],
                    value: x?.type === "check_box" ? x?.survey_value?.map((val) => { return val?.answer }) : x?.survey_value?.[0]?.answer
                }
            })
        }
        setViewDetails(result)
        setTableData({ ...tableData, isView: true })
    }
    //handlePagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getFeedBackList(offset, 10,"")
    }
    //handleChangeLimit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getFeedBackList(0, value, "")

    }


    const onCloseModal = () => {
        setTableData({ ...tableData, isView: false })
    }
    return (
        <Box>
            {
                loading ?
                    <NewLoader minusHeight="158px" />
                    :
                    <Paper className={classes.Feedbackrepliesroot}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={4}>
                                {/*search */}
                                <SearchFilter
                                    handleChange={handleSearch}
                                    value={searchText}
                                    placeholder={t("search")}
                                    customfieldSx={{ height: '40px' }}
                                />
                            </Grid>
                        </Grid>
                        <TableWithPagination
                            heading={FeedBackRepliesHeading(t)}
                            rows={tableData?.list}
                            dataType={FeedBackRepliesDataType}
                            path={FeedBackRepliesPath}
                            showpagination
                            tableType="no-side"
                            handleIcon={handleIcon}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={tableData?.count}
                            page={page}
                            limit={limit}
                            view={true}
                            height={`calc(100vh - 350px)`}
                            edit={true}
                            delete={true} />
                    </Paper>
            }
            <AlertDialog
                width={"42%"}
                header={`${viewDetails?.created_by_name} (${viewDetails?.email_id})`}
                open={tableData?.isView}
                onClose={onCloseModal}
                component={<ViewFeedBack t={t} details={viewDetails?.survey_element} onCloseModal={onCloseModal} />}
            />
        </Box>
    )


}
export default withNamespaces("feedBack")(FeedBackReplies)