import { Box, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { forwardRef } from 'react';
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarWithTime from "../calendarWithTime";
// import { TextBox } from "../textbox";
import { useStyles } from './style';
import './style.css';
import moment from "moment";
import { Label } from "../label";


export const NewDatePicker = (props) => {
      const classes = useStyles(props);
      const [anchorEl, setAnchorEl] = React.useState(false)
      const datePickerOpen = Boolean(anchorEl)

      const handleClose = () => {
            setAnchorEl(null)
      }
      
      const CustomInput = forwardRef(({ value, onClick }, ref) => (
            
            <div className={classes.Datepicker}
                  onClick={onClick} ref={ref}>
                  <Typography className={value?classes.input:classes.placeholderCustom} >
                        &#x202a;{value ? value : props?.placeholder}&#x202c;
                  </Typography>
                  <div style={{ display: "flex", alignItems: "end" }}>
                        <img src={'/images/date_picker_icon.svg'} alt="" />
                  </div>
            </div>
      ));

      const getLabel = (props) => {
            return (
                  <div className={classes.labelRoot}>
                        {
                              <Typography
                                    variant="body1"
                                    color="textsecondary"
                                    className={classes.Label}
                                    align="left"
                              >
                                    {props?.label}
                              </Typography>
                        }
                        {props?.isRequired && (
                              <Typography color="error" variant="caption">
                                    &nbsp;*
                              </Typography>
                        )}
                  </div>
            );
      };


      return (
            <>
                  {
                        !props?.newDate ?
                              <div className={classes.datePickerRoot}>
                                    {getLabel(props)}
                                    <Datepicker
                                          selected={props?.value}
                                          onChange={date => props?.onChange(date)}
                                          peekNextMonth
                                          disabled={props?.disabled}
                                          showMonthDropdown
                                          showYearDropdown
                                          customInput={<CustomInput />}
                                          dropdownMode="select"
                                          className={classes.textbox}
                                          placeholderText={props?.placeholder}
                                          minDate={props?.minDate ?? false}
                                          maxDate={props?.maxDate ?? false}
                                          calendarIcon={
                                                <img src={'/images/date_picker_icon.svg'} alt="" />
                                          }
                                    />
                              </div >
                              :
                              <div>
                                    <Box>
                                          <Box onClick={(event) => setAnchorEl(event.currentTarget)}>
                                                {/* <TextBox
                                                      isReadonly={props?.disabled}
                                                      isrequired={props?.isRequired}
                                                      label={props?.label}
                                                      placeholder={props?.placeholder}
                                                      endAdornment={
                                                            <InputAdornment position="end">
                                                                  <img src={'/images/date_picker_icon.svg'} alt="" />
                                                            </InputAdornment>
                                                      }
                                                      value={props?.value ? props?.noTime ? moment(props?.value).format("DD MMM YY") : moment(props?.value).format("DD MMM YY HH:mm") : ""}
                                                /> */}
                                                {props.label && <Label label={props.label} isRequired={props.isrequired} labelMarginBottom={props.labelMarginBottom} textAlign={props?.textAlign} />}
                                                <Box py={"12px"} px={1} className={classes.dateBox}>
                                                      <Stack direction={"row"} justifyContent={"space-between"}>
                                                            <Typography className={props?.value ? classes.placeholder : classes.value}>&#x202a;{props?.value ? props?.noTime ? moment(props?.value).format("DD MMM YY") : moment(props?.value).format("DD MMM YY HH:mm") : props?.placeholder}&#x202c;</Typography>
                                                            <img src={'/images/date_picker_icon.svg'} alt="" />
                                                      </Stack>
                                                </Box>

                                          </Box>

                                          <Menu
                                                anchorEl={anchorEl}
                                                open={datePickerOpen}
                                                className={classes.menublock}
                                                onClose={handleClose}
                                                PaperProps={{ sx: { width: props?.noTime ? "400px" : "425px", backgroundColor: "white", border: "1px solid #E4E8EE", boxShadow: "0px 0px 16px #00000014" } }}
                                          >
                                                <MenuItem className={classes.menuList} disableRipple>
                                                      <CalendarWithTime
                                                            handleDateChange={date => props?.onChange(date)}
                                                            dateTime={props?.value ? props?.value : new Date()}
                                                            setAnchorEl={setAnchorEl}
                                                            noTime={props?.noTime}
                                                            hideCondition={false}
                                                      />
                                                </MenuItem >
                                          </Menu >
                                    </Box >
                              </div >}
                  {
                        props.isError && (
                              <Typography variant={"caption"} color={"error"}>
                                    {props.errorMessage}
                              </Typography>
                        )
                  }

            </>
      )
}