import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FilterGenerator, MiniPropertyDetail, Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GET_MANAGEMENT_MEMBERLIST, GET_PROPERTY_DETAILS_FOR_MANAGEMENT } from "../../graphql/queries";
import { withNavBars } from "../../HOCs";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, getRoutePermissionNew, accessCheckRender } from "../../utils";
import { StatusOptionList } from '../../utils/inventory';
import { useStylesManagement } from "./style";
import ManagementCommitteeTable from './table';
import { withNamespaces } from "react-i18next";
const bgColor = ["#EBFBFF", "#FFFAEB", "#EBF0FF"]

const ManagementCommitteeView = (props) => {
      const { t = () => false } = props;

      const history = useHistory();
      const auth = React.useContext(AuthContext);
      const classes = useStylesManagement();
      const backdrop = React.useContext(BackdropContext);
      const alert = React.useContext(AlertContext);
      const [data, setData] = useState({})
      const [limit, setLimit] = React.useState(10);
      const [page, setPage] = React.useState(1)
      const { state } = useLocation();
      const client = useApolloClient();
      const [showForm, setShowForm] = React.useState(false)
      const [tableData, setTableData] = React.useState([])
      const [selectedMember, setSelectedMember] = React.useState("")
      const [filterDrawer, setFilterDrawer] = React.useState(false)
      const [searchText, setSearchText] = React.useState("")
      const [permissions, setPermission] = React.useState({})
      const [filterData, setFilterData] = React.useState({
            is_active: []
      });
      const [formdata, setFormData] = useState({
            id: undefined,
            role_type: "Owner",
            company_Name: "",
            image: "",
            member_name: "",
            role: "",
            start_period: new Date(),
            is_active: true,
            type: "add",
            error: {
                  role_type: "",
                  company_Name: "",
                  member_name: "",
                  role: "",
                  start_period: "",
                  is_active: "",
            }

      })
      const [isDisableBtn,setIsDisableBtn]=React.useState(false)
      //validate 
      const validateForm = () => {
            let isValid = true;
            let error = formdata.error;
            if (formdata?.member_name?.length === 0) {
                  isValid = false;
                  error.member_name = t("Member Name  is Required");
            }
            if (formdata?.role_type?.length === 0) {
                  isValid = false;
                  error.role_type = t("Role Type  is Required");
            }
            if (formdata?.role?.length === 0) {
                  isValid = false;
                  error.role = t("Role  is Required");
            }
            setFormData({ ...formdata, error });
            return isValid;
      }
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        if (state?.main?.propertyID) {
                              propertylist(state?.main?.propertyID)
                              getList(state?.main?.company_id, 0, 10, searchText, filterData)
                        }
                  }
            }

            // eslint-disable-next-line
      }, [auth])
      const propertylist = (v) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            client.query({
                  query: GET_PROPERTY_DETAILS_FOR_MANAGEMENT(),
                  fetchPolicy: 'network-only',
                  variables: {
                        propertyID: v,
                  }
            }).then((res) => {
                  setData(res?.data)
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch((err) => {
                  console.log(err)

            })
      }
      const getList = (companyId, offset = 0, limit = 10, searchText, filterData) => {
            client.query({
                  query: GET_MANAGEMENT_MEMBERLIST(filterData?.is_active),
                  fetchPolicy: 'network-only',
                  variables: {
                        "offset": offset,
                        "limit": limit,
                        "searchText": searchText,
                        "propertyId": state?.main?.propertyID,
                        "is_active": filterData?.is_active
                  }
            }).then((res) => {
                  let rowData = res?.data?.management_committee?.map((x, i) => {
                        return {
                              member_name: x.user_profiles?.label ?? undefined,
                              role: { label: x.committee_role?.label, value: x.committee_role?.value, responsibiltes: x.committee_role.responsibiltes, bgColor: `${bgColor[Math.floor(Math.random() * bgColor.length)]}` },
                              role_id: x.committee_role?.value,
                              is_active: x.is_active,
                              assigned_on: x.from,
                              image: x.user_profiles?.image_url ?? undefined,
                              member_id: x.user_profiles?.value ?? undefined,
                              id: x.id,
                              is_owner: x?.is_owner ? "Owner" : "Resident",
                              unit_id: x?.unit?.id,
                              unit_no: x?.unit?.unit_no,
                              unit_name: x?.unit?.name

                        }
                  })
                  setTableData(rowData)
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch((err) => {
                  console.log(err)

            })
      }
      const onFormSubmit = () => {
            if (validateForm()) {
                  setIsDisableBtn(true)
                  const payload = {
                        "id": formdata?.id ?? undefined,
                        "resident_profile_id": formdata?.member_name?.value,
                        "committee_role_id": formdata?.role?.value,
                        "company_id": state?.main?.company_id,
                        "property_id": state?.main?.propertyID,
                        "from": formdata?.start_period,
                        "is_active": formdata?.is_active,
                        "client": localStorage.getItem(LocalStorageKeys.clinetID),
                        "user_profile_id": localStorage.getItem(LocalStorageKeys.userProfileID),
                        "is_owner": formdata?.role_type === "Owner" ? true : false,
                        "unit_id": selectedMember?.unit_id

                  };
                  NetworkCall(
                        `${config.api_url}/management_commitee/upsert`,
                        NetWorkCallMethods.post,
                        payload,
                        null,
                        true,
                        false
                  )
                        .then((response) => {
                              let msg = response?.data?.data
                              onCloseModal()
                              getList(state?.main?.company_id, 0, limit, "", filterData)
                              alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.success,
                                    msg: `${msg}`,
                              });
                              setIsDisableBtn(false)
                        })
                        .catch((error) => {
                              onCloseModal()
                              alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.error,
                                    msg: t("Some Thing Went Wrong"),
                              });
                              setIsDisableBtn(false)
                        });
            }
            else {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Please fill all mandatory field"),
                  });
                  setIsDisableBtn(false)
            }

      }
      //handle pagination
      const handlePagination = (value) => {
            setPage(value);
            let offset = (value - 1) * limit;
            getList(state?.main?.company_id, offset, limit, "", filterData)
      }

      //on change limit
      const handleChangeLimit = (value) => {
            setLimit(value);
            setPage(1);
            getList(state?.main?.company_id, 0, limit, "", filterData)

      }
      const handleTableSearch = (val) => {
            setSearchText(val)
            getList(state?.main?.company_id, 0, 10, val, filterData)
      }
      const handleIcon = (type, data) => {
            if (type === "edit") {
                  setShowForm(true)
                  formdata.id = data?.id
                  formdata.role = { label: data.role.label, value: data.role_id }
                  formdata.member_name = { label: data.member_name, value: data.member_id }
                  formdata.is_active = data?.is_active
                  formdata.role_type = data?.is_owner
                  setSelectedMember({ id: data.member_id, unit_id: data.unit_id })
                  setFormData({ ...formdata, "type": "edit" })
            }
            else if (type === "history") {
                  formdata.id = data?.id
                  formdata.role = data?.role
                  formdata.role_type = data?.is_owner
                  formdata.member_name = { label: data.member_name, value: data.member_id }
                  setShowForm(true)
                  setFormData({ ...formdata, "type": "history" })
            }
            else if (type === "active") {
                  formdata.id = data?.id
                  formdata.role_type = data?.is_owner
                  formdata.role = { label: data.role, value: data.role_id }
                  formdata.member_name = { label: data.member_name, value: data.member_id }
                  formdata.is_active = data.is_active ? false : true
                  setSelectedMember({ id: data.member_id, unit_id: data.unit_id })
                  setFormData({ ...formdata, "type": "active" })
                  onFormSubmit()
            }
      }
      const updateState = (key, value) => {
            let error = formdata.error;
            error[key] = ""
            setFormData({ ...formdata, [key]: value, error })
      }
      //to filter data based on status
      const handleFilter = (value) => {
            setFilterData(value)
            getList(state?.main?.company_id, 0, 10, searchText, value)
      }
      const onCloseModal = () => {
            setShowForm(false)
            let resetData = {
                  id: undefined,
                  company_Name: "",
                  role_type: "Owner",
                  image: "",
                  member_name: "",
                  role: "",
                  start_period: new Date(),
                  is_active: true,
                  type: "add",
                  error: {
                        company_Name: "",
                        member_name: "",
                        role: "",
                        start_period: "",
                        is_active: "",
                        role_type: ""
                  }
            }
            setFormData(resetData)
            setSelectedMember("")
      }
      const render = () => {
            return (
                  <div>
                        <Subheader title={data?.property?.[0]?.propertyName} goBack={() => { history.goBack(-1) }} />
                        <Box className={classes.viewcontent}>
                              <MiniPropertyDetail
                                    t={t}
                                    logo={data?.property?.[0]?.logo}
                                    property_no={data?.property?.[0]?.property_no}
                                    company_name={data?.property?.[0]?.company?.name}
                                    property_name={data?.property?.[0]?.propertyName}
                                    address={data?.property?.[0]?.addressByID} />
                              <Box mt={1.5}>
                                    <ManagementCommitteeTable
                                          formdata={formdata}
                                          setFormData={setFormData}
                                          rowData={tableData}
                                          showForm={showForm}
                                          state={state}
                                          setShowForm={setShowForm}
                                          handleIcon={handleIcon}
                                          updateState={updateState}
                                          selectedMember={selectedMember}
                                          setSelectedMember={setSelectedMember}
                                          setFilterDrawer={setFilterDrawer}
                                          searchText={searchText}
                                          PropertyName={state?.main?.PropertyName}
                                          role={formdata}
                                          handleSearch={handleTableSearch}
                                          onFormSubmit={onFormSubmit}
                                          onCloseModal={onCloseModal}
                                          handlePagination={handlePagination}
                                          handleChangeLimit={handleChangeLimit}
                                          page={page}
                                          limit={limit}
                                          permissions={permissions}
                                          isDisableBtn={isDisableBtn}
                                    />
                              </Box>
                        </Box>
                        <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                              onApply={(value) => handleFilter(value)}
                              components={[
                                    {
                                          component: "toggleButton",
                                          value: filterData?.is_active,
                                          options: StatusOptionList,
                                          isMulti: true,
                                          state_name: "is_active",
                                          label: t("Status"),
                                    },
                              ]}

                        />

                  </div>
            )
      }
      return (
            <>
                  {accessCheckRender(render, permissions)}
            </>

      )
}
const props = {
      boxShadow: false
}
export default withNamespaces("managementCommittee")(withNavBars(ManagementCommitteeView, props));
