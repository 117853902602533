import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import "../../../App.css";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { propertyStyles } from "./style";
import ImageViewer from "react-simple-image-viewer";
import { returnValue } from "../../companyCreation/utils";
// import MyGoogleMap from '../../../components/interactivemap/myGoogleMaps';

import { concat_string, convertTimeUtcToZoneCalander, rich_text_replacer_condition, textReplacer } from '../../../utils';


export const PropertyDetailsViewing = (props) => {
      const { t } = (props);
      const [isSeeMore, setIsSeeMore] = React.useState(true)
      const classes = propertyStyles({ ...props, isSeeMore })
      const size = useWindowDimensions()
      const seeMoreDivRef = React.useRef(null);
      const [seeMoreHeight, setSeeMoreHeight] = React.useState(null)
      // const { data } = props;
      // const { address } = data ?? {};


      // const GoogleMap = React.useCallback(() => {
      //       return (
      //             <MyGoogleMap
      //                   lat={address?.latitude}
      //                   lng={address?.longitude}
      //                   center={{
      //                         lat: address?.latitude,
      //                         lng: address?.longitude
      //                   }}
      //                   zoom={13}
      //                   readonly

      //             />
      //       )
      // }, [address])
      React.useEffect(() => {
            setSeeMoreHeight(seeMoreDivRef?.current?.clientHeight ?? 0)
      }, [seeMoreDivRef?.current?.clientHeight, props]);

      return (

            <div style={{ height: size?.height - 220, padding: "4px", margin: "-4px" }}>
                  {/*property detials first card */}
                  <Grid container className={classes.card} style={{ padding: "16px 16px 16px 0px", }}>
                        <Grid item xs={12} sm={12} md={2} lg={2} >
                              <div className={classes.imgDiv}>
                                    <img src={props?.data?.logo ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                                          onClick={() => props?.openImageViewer([props?.data?.logo])}

                                    />
                                    <Typography className={classes.imgTag}>&nbsp;&nbsp;{props?.data?.property_no}&nbsp;&nbsp;</Typography>
                              </div>
                              {props?.isViewerOpen && (
                                    <ImageViewer
                                          src={props?.currentImage}
                                          currentIndex={0}
                                          onClose={props?.closeImageViewer}
                                          disableScroll={false}
                                          backgroundStyle={{
                                                backgroundColor: "rgba(0,0,0,0.9)",
                                          }}
                                          className={classes.popupimg}
                                          closeOnClickOutside={true}
                                    />
                              )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} className={classes.imgDiv2}>
                              <Typography className={classes.title}>{t("Property Details")}</Typography>
                              <Grid container spacing={2}>
                                    <Grid item xs={2} md={3} lg={3}>
                                          <Typography className={classes.heading}>{t("Company Name")}</Typography>
                                          <Typography className={classes.sub}>{props?.data?.company?.name ? props?.data?.company?.name : " - "}</Typography>
                                    </Grid>
                                    <Grid item xs={2} md={2} lg={2}>
                                          <Typography className={classes.heading}>{t("Name")}</Typography>
                                          <Typography className={classes.sub}>
                                                {props?.data?.name ? props?.data?.name : " - "}
                                          </Typography>
                                    </Grid>
                                    <Grid item xs={2} md={2} lg={2}>
                                          <Typography className={classes.heading}>{t("Type")}</Typography>
                                          <Typography className={classes.sub}>
                                                {props?.data?.property_groupByID?.group_name ? props?.data?.property_groupByID?.group_name : "- "}
                                          </Typography> </Grid>
                                    <Grid item xs={2} md={2} lg={2}>
                                          <Typography className={classes.heading}>{t("Purpose")}</Typography>
                                          <Typography className={classes.sub}>
                                                {props?.data?.property_purpose ? props?.data?.property_purpose : "-"}
                                          </Typography></Grid>
                                    <Grid item xs={2} md={2} lg={2}>

                                          <Typography className={classes.heading}> {t("Payment Period")}</Typography>
                                          <Typography className={classes.sub}>
                                                {props?.data?.payment_period ? props?.data?.payment_period : "-"}
                                          </Typography> </Grid>
                                    <Grid item xs={2} md={1} lg={1}>
                                          <Typography className={classes.heading}>{t("Status")}</Typography>
                                          <Typography className={classes.sub}>{props?.data?.is_active ? "Active" : "In active"}</Typography> </Grid>

                              </Grid>
                              {props?.data?.description &&
                                    <>
                                          <Grid container marginTop="14px">
                                                <Grid item>
                                                      <Typography className={classes.heading}>{t("Property Description")}</Typography>
                                                </Grid>
                                          </Grid>
                                          <Grid ref={seeMoreDivRef} container className={classes.descriptionDiv}>
                                                <Grid item>
                                                      <Typography
                                                            dangerouslySetInnerHTML={{
                                                                  __html: returnValue(textReplacer({
                                                                        text: props?.data?.description,
                                                                        condition: rich_text_replacer_condition,
                                                                  }), "-")
                                                            }} />
                                                </Grid>
                                          </Grid>
                                          {seeMoreHeight > 199 &&
                                                <div
                                                      className={classes.seeMore}
                                                      onClick={() => setIsSeeMore(!isSeeMore)}>
                                                      {isSeeMore ? "See More" : "See Less"}
                                                </div>
                                          }
                                    </>
                              }

                              {/* <Box marginTop="14px">
                                    {props?.data?.description &&
                                          <TitleDesc
                                                title={"Property Description"}
                                                dangerouslySetInnerHTML={returnValue(props?.data?.description, "-")}
                                          />
                                    }
                              </Box> */}
                        </Grid>
                        <Grid item md={0} lg={4.5} />
                  </Grid>
                  <br />
                  {/* owner details */}
                  <Grid container className={classes.card} style={{ padding: "16px 16px 16px 0px", }}>
                        <Grid item xs={12} sm={12} md={2} lg={2} >
                              <div className={classes.imgDiv}>
                                    <Avatar src={props?.data?.ownerByID?.logo ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                                          onClick={() => props?.openImageViewer([props?.data?.ownerByID?.logo])}

                                    />
                              </div>
                              {props?.isViewerOpen && (
                                    <ImageViewer
                                          src={props?.currentImage}
                                          currentIndex={0}
                                          onClose={props?.closeImageViewer}
                                          disableScroll={false}
                                          backgroundStyle={{
                                                backgroundColor: "rgba(0,0,0,0.9)",
                                          }}
                                          className={classes.popupimg}
                                          closeOnClickOutside={true}
                                    />
                              )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} className={classes.imgDiv2}>
                              <Typography className={classes.title}>{t("Owner Details")}</Typography>
                              <Grid container spacing={2}>
                                    <Grid item xs={3} md={3} lg={3}>
                                          <Typography className={classes.heading}>{t("Name")}</Typography>
                                          <Typography className={classes.sub}>{props?.data?.ownerByID?.name ? props?.data?.ownerByID?.name : " - "}</Typography>
                                    </Grid>

                                    <Grid item xs={3} md={3} lg={3}>
                                          <Typography className={classes.heading}>{t("Mobile No")}</Typography>
                                          <Typography className={classes.sub}>
                                                {concat_string(
                                                      {
                                                            mobile_code: props?.data?.ownerByID?.mobile_no_country_code,
                                                            mobile_no: props?.data?.ownerByID?.mobile_no,
                                                      },
                                                      ["mobile_code", "mobile_no"],
                                                      " - ",
                                                      "-"
                                                )}
                                          </Typography> </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                          <Typography className={classes.heading}>{t("Email")}</Typography>
                                          <Typography className={classes.sub}>
                                                {props?.data?.ownerByID?.email_id ? props?.data?.ownerByID?.email_id : "-"}
                                          </Typography></Grid>

                              </Grid>

                        </Grid>
                  </Grid>

                  <br />
                  {/*property detials second card */}
                  <Grid container className={classes.card}>
                        <Grid item md={12} >
                              <Grid container>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Hierarchy")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.property_hierarchyByID ? props?.data?.property_hierarchyByID?.label : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>

                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Revenue Type")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.revenue_type ? props?.data?.revenue_type : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    {/* <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>Public Listing</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.is_listing ? "Yes" : "No"}
                                                </Typography>
                                          </Box>
                                    </Grid> */}
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Unit of Measure")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.uomByID ? props?.data?.uomByID?.label : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Total Area")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.total_area ?? "-"}
                                                      <span className={classes.matric}>{props?.data?.total_area &&
                                                            (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Set Back Area")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.set_back_area ?? "-"}
                                                      <span className={classes.matric}>{props?.data?.set_back_area &&
                                                            (props?.data?.uomByID ? props?.data?.uomByID?.label : "")}</span></Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Year Built")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.year_built ? convertTimeUtcToZoneCalander(props?.data?.year_built) : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Handover Date")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.planned_hand_over_date ? convertTimeUtcToZoneCalander(props?.data?.planned_hand_over_date) : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Swimming Pools")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.swimming_pools ? props?.data?.swimming_pools : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Elevators")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.elevators ? props?.data?.elevators : "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>


                                    <Grid item xs={2}>
                                          <Box marginTop="16px">
                                                <Typography className={classes.heading}>{t("Pets Policy")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.pet_policy ?? "-"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    {/* As per CR commented */}
                                    {/* <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}> Discussion Group</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.discussion_forum ? "Enabled" : "Disabled"}
                                                </Typography>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}> Amenities Booking</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.amenities_booking ? "Enabled" : "Disabled"}
                                                </Typography>
                                          </Box>
                                    </Grid> */}
                                    {/* <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>Asset Functional Location</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.is_asset_functional_location ? "Yes" : "No"}
                                                </Typography>
                                          </Box>
                                    </Grid> */}
                                    {/* <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>Asset Property</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.is_asset_property ? "Yes" : "No"}
                                                </Typography>
                                          </Box>
                                    </Grid> */}
                                    <Grid item xs={2}>
                                          <Box marginTop="8px">
                                                <Typography className={classes.heading}>{t("Community")}</Typography>
                                                <Typography className={classes.sub}>
                                                      {props?.data?.is_community ? "Yes" : "No"}
                                                </Typography>
                                          </Box>
                                    </Grid>

                              </Grid>
                        </Grid>
                  </Grid>
                  <br />
                  {/*property detials third card */}
                  <div className={classes.card}>
                        <Grid container spacing={2}>
                              {/* <Grid item xs={5}>
                                    <div className={classes.map}>
                                          <GoogleMap />
                                    </div>
                              </Grid> */}
                              {/* when map component is needed plz use below commented grid item */}

                              {/* <Grid item xs={3}> */}
                              <Grid item xs={6}>
                                    <div className={classes.addressRoot} style={{height: "100%"}}>
                                          <Stack direction="row" >
                                                <img src='/images/loc.svg' alt='' />
                                                <Typography className={classes.title}>&nbsp;{t("Address")}</Typography>
                                          </Stack>
                                          <Box height="12px" />
                                          <Typography className={classes.sub}>
                                                {props?.data?.address?.street_1 + "," ?? ""}
                                                {props?.data?.address?.street_2 + "," ?? ""}
                                                {props?.data?.address?.landmark + "," ?? ""}
                                                {/* {props?.data?.address?.street_3 + "," ?? ""} */}
                                                {props?.data?.address?.district + "," ?? ""}
                                                {props?.data?.address?.country + "," ?? ""}
                                                {props?.data?.address?.zipcode ?? ""}
                                          </Typography>
                                          <Box height="16px" />
                                          <Stack direction="row" >
                                                <Typography className={classes.heading}>{t("Latitude")} : </Typography>
                                                <Typography className={classes.sub1} >&nbsp;{props?.data?.address?.latitude}</Typography>
                                          </Stack>
                                          <Box height="12px" />
                                          <Stack direction="row" >
                                                <Typography className={classes.heading}>{t("Longitude")} : </Typography>
                                                <Typography className={classes.sub1} >&nbsp;{props?.data?.address?.longitude}</Typography>
                                          </Stack>
                                    </div>
                              </Grid>
                              {/* when map component is needed plz use below commented grid item */}
                              {/* <Grid item xs={4}> */}
                              <Grid item xs={6}>
                                    <div className={classes.addressRoot} style={{height: "100%"}}>
                                          <Stack direction="row" >
                                                <img src='/images/Group 7015.svg' alt='' />
                                                <Typography className={classes.title}>&nbsp;{t("Contact & Other Information")}</Typography>
                                          </Stack>
                                          <Grid container>
                                                <Grid item xs={6}>
                                                      <Box marginTop="18px">
                                                            <Typography className={classes.heading}>{t("Business Phone")} :</Typography>
                                                            <Typography className={classes.sub}>
                                                                  {props?.data?.business_phone_country_code + " " + props?.data?.business_phone}
                                                            </Typography>
                                                      </Box>
                                                      <Box marginTop="12px">
                                                            <Typography className={classes.heading}>{t("Website")} :</Typography>
                                                            <Typography className={classes.sub} noWrap>{props?.data?.website}</Typography>
                                                      </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                      <Box marginTop="18px">
                                                            <Typography className={classes.heading}>{t("Mobile Phone")} :</Typography>
                                                            <Typography className={classes.sub}>
                                                                  {props?.data?.mobile_country_code + " " + props?.data?.mobile_phone}
                                                            </Typography>
                                                      </Box>
                                                      <Box marginTop="12px">
                                                            <Typography className={classes.heading}>{t("Email Address")} :</Typography>
                                                            <Typography className={classes.sub} noWrap>{props?.data?.email}</Typography>
                                                      </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                      <Box marginTop="12px">
                                                            <Typography className={classes.heading}>{t("Communication Email Address")} :</Typography>
                                                            <Typography className={classes.sub} noWrap>{props?.data?.communication_email ?? "-"}</Typography>
                                                      </Box>
                                                </Grid>
                                          </Grid>
                                    </div>
                              </Grid>
                        </Grid>
                  </div>
            </div>
      )
}