import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { ActivityCard, FormGenerator, NewDatePicker, SelectBox, TextBox } from "../../../components"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"
import { GET_ACTIVITY_CATAGORY_MASTER, GET_PROPERTIES_BASED_ON_COMPANY, LEAD_ACTIVITY_MASTER } from "../../../graphql/queries"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, concat_string, enumSelect, enum_types, wrapLabelsInT } from "../../../utils"
import { loadOptions, loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions"
import { ListCard } from "./leadCard"
import { useStyles } from "./style"


export const CreateActivity = (
    {
        calendarFunctions = () => false,
        handleClose = () => false,
        selectedCompany = {},
        selectedTeams = {},
        setSelectedType = () => false,
        showActivityType = true,
        setShowActivityType = () => false,
        t = () => false
    }
) => {
    const classes = useStyles()
    const clientID = localStorage.getItem(LocalStorageKeys.clinetID) ?? undefined
    const Categories = ["Property", "Lead", "Opportunity"]

    const [activityData, setActivityData] = React.useState([])
    const alert = React.useContext(AlertContext)
    const minutes = 30;
    const ms = 1000 * 60 * minutes;
    const RoundOffTime = new Date(Math.ceil(new Date().getTime() / ms) * ms);
    const [data, setData] = useState({
        type: "",
        title: "",
        category: "Property",
        dateTime: RoundOffTime,
        notes: "",
        selectedProperty: "",
        lead_list: "",
        property_list: "",
        Priority: "",
        categoryData: "",
        email: "",
        followup: "",
        error: {
            title: "",
            dateTime: "",
            notes: "",
            Priority: "",
            lead_list: "",
            categoryData: "",
            email: "",
            followup: "",
            property_list: "",
        }
    })
    const [enumValue, setEnumValue] = React.useState({
        priority: [],
        followUp: []
    })
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const getLeadActivityMaster = () => {
        const payload = {
            query: LEAD_ACTIVITY_MASTER().loc.source.body,
            variable: {}
        };

        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setActivityData(res?.data?.data?.lead_activity_master)
        }).catch((err) => {
            console.log(err)
        });
    }
    React.useEffect(() => {
        // setSize({ isMedium: true, isNormal: false })
        getEnum()
        getLeadActivityMaster()
        setShowActivityType(true)
        //eslint-disable-next-line
    }, [])
    const handleClick = (val) => {
        setSelectedType(val)
        setShowActivityType(false)
        updateState("type", val)
        // setSize({ isMedium: false, isNormal: true })
    }
    const updateState = (k, v) => {
        let error=data?.error
        error[k]=""
        setData({ ...data, [k]: v,error })
    }

    const getEnum = async () => {
        const result = await enumSelect([
            enum_types?.urgent_type,
            enum_types?.follow_up_type,
        ]);
        setEnumValue({
            priority: result?.urgent_type ?? [],
            followUp: result?.follow_up_type,
        });
    };

    const validate = () => {
        let isValid = true;
        let error = data.error
        if (data?.notes === "") {
            isValid = false; error.notes = t("Notes is Required")
        }
        if (data?.category !== "Property" && data?.lead_list === "") {
            isValid = false; error.category = t("Lead List is Required")
        }
        if (data?.category === "Property" && data?.property_list === "") {
            isValid = false; error.category = t("Property List is Required")
        }
        if (data?.Priority === "") {
            isValid = false; error.Priority = t("Priority is Required")
        }
        if (data?.categoryData === "") {
            isValid = false; error.categoryData = t("category is Required")
        }
        if (data?.title === "") {
            isValid = false; error.title = t("Title is Required")
        }
        if (data?.type?.title === "Send Quotation" || data?.type?.title === "Send Email") {
            if (data?.email?.length === 0) {
                isValid = false; error.email = t("Email is Required")
            }
        }
        if (data?.type?.title === "Follow up" ||
            data?.type?.title === "Meeting" ||
            data?.type?.title === "Appointment") {
            if (data?.followup?.value?.length === 0) {
                isValid = false; error.followup = t("Followup is Required")
            }
        }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            })
        }
        setData({ ...data, error })
        return isValid
    }
    const save = () => {
        if (validate()) {
            setIsDisableBtn(true)
            const activity = {
                activity_category: data?.categoryData?.value,
                notes: data?.notes,
                priority: data?.Priority ?? undefined,
                proxy_lead_id: data?.category === "Lead" ? data?.lead_list?.proxy_lead_id : undefined ?? undefined,
                lead_id: data?.category !== "Lead" ? data?.lead_list?.lead_id : undefined ?? undefined,
                phone_number: data?.lead_list?.mobile_no ?? data?.property_list?.mobile_phone ?? undefined,
                date_time: `${moment(data?.dateTime).format("YYYY-MM-DD HH:mm:ss")}` ?? undefined,
                phone_code: data?.lead_list?.mobile_country_code ?? data?.property_list?.mobile_country_code ?? undefined,
                lead_activity_master: (data?.category === "Lead" || data?.category === "Property") ? data?.type?.id : undefined,
                lead_activitiy_master: data?.category === "Opportunity" ? data?.type?.id : undefined,
                property_id: data.category === "Property" ? data.property_list?.value : undefined,
                created_by: localStorage.getItem("profileID"),
                status: "Open",
                company_id: selectedCompany?.value,
                email_id: data?.lead_list?.email ?? data?.property_list?.email ?? undefined,
                type: data?.followup?.value ?? undefined,
                calendar_event_params: {
                    subject: "PropGOTO - "
                        + concat_string(
                            {
                                category_name: data?.categoryData?.label,
                                reference_name: data.category === "Property"
                                    ? data.property_list?.label
                                    : data?.lead_list?.lead_name,
                                reference_no: data.category === "Property"
                                    ? data.property_list?.property_no
                                    : data?.lead_list?.lead_no,
                            },
                            ["category_name", "reference_name", "reference_no"],
                            ", "
                        )
                        + (data?.Priority?.length > 0 ? ` - (${data?.Priority?.charAt(0).toUpperCase()}${data?.Priority?.slice(1)})` : ``),
                    notes: data?.notes?.length > 0 ? data?.notes : "",
                    start: {
                        dateTime: moment(data?.dateTime).format("YYYY-MM-DDTHH:mm:ss"),
                        timeZone: selectedCompany?.timezone?.length > 0 ? selectedCompany.timezone : "UTC",
                    },
                    end: {
                        dateTime: moment(data?.dateTime).add(30, "m").format("YYYY-MM-DDTHH:mm:ss"),
                        timeZone: selectedCompany?.timezone?.length > 0 ? selectedCompany.timezone : "UTC",
                    },
                },
            };

            NetworkCall(
                data?.category === "Lead" ? `${config.api_url}/lead_proxy/activity` : data?.category === "Property" ? `${config.api_url}/property/activity` : `${config.api_url}/lead/activity`,
                NetWorkCallMethods.post,
                {
                    activity,
                },
                null,
                true,
                false
            )
                .then((response) => {
                    calendarFunctions()
                    handleClose()
                    setIsDisableBtn(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: t("Activity Created Successfully")
                    });
                })
                .catch((error) => {
                    // alert.setSnack({
                    //     ...alert,
                    //     open: true,
                    //     severity: AlertProps.severity.error,
                    //     msg: "Internal error. Please try again later.",
                    // });
                    console.log(error)
                    setIsDisableBtn(false)
                });
        }
    };

    const manualResponse = (array) => array?.map(_ => {
        return {
            ..._,
            label: `${_?.lead_name} - ${_?.lead_no}`,
            value: _?.lead_id,
        }
    });


    const fullWidth = ["Follow up", "Meeting", "Appointment", "Send Quotation", "Send Email"]

    console.log(enumValue?.priority, "Hello");

    return (
        <Box>
            {showActivityType &&
                <Grid container style={{ padding: "12px" }}>
                    {activityData.map((val) => {
                        return (
                            <Grid item xs={6} md={4}>
                                <ActivityCard t={t} data={val} activityBoard onClick={() => handleClick(val)} />
                            </Grid>
                        );
                    })}
                </Grid>
            }
            {
                !showActivityType &&
                <>
                    <Box p={2} className={classes.content}>
                        <Grid container spacing={1.5}>
                            <Grid item md={fullWidth?.includes(data?.type?.title) ? 12 : 6} sm={fullWidth?.includes(data?.type?.title) ? 12 : 6} lg={fullWidth?.includes(data?.type?.title) ? 12 : 6}>
                                <Typography className={classes.categoryText}>{t("Select Category")}</Typography>
                                <Stack direction="row" mt={1}>
                                    {Categories?.map((x, i) => {
                                        return (
                                            <Stack className={data?.category === x ? classes.selectedtoggle : classes.togglebtntype} onClick={() => {
                                                updateState("category", x)
                                                setData({ ...data, category: x, lead_list: "", property_list: "" })
                                            }}>{t(x)}</Stack>
                                        )
                                    })}
                                </Stack>
                            </Grid>
                            <Grid item md={6} sm={6} lg={6}>
                                {/* <Typography className={classes.categoryText}>{data?.category}</Typography> */}

                                <Box>
                                    {
                                        (data?.lead_list?.label || data?.property_list?.label) ?
                                            // <Box mt={1}>
                                            <ListCard name={data?.category === "Property" ? data?.property_list?.label : data?.lead_list?.lead_name}
                                                leadNo={data?.lead_list?.lead_no}
                                                address={data?.property_list?.address?.city}
                                                url={data?.category === "Property" ? data?.property_list?.logo : data?.lead_list?.lead_image_url}
                                                handleDelete={() => updateState(data?.category === "Property" ? "property_list" : "lead_list", "")}
                                                heading={data?.category} />
                                            // </Box>
                                            :
                                            data?.category === "Property" ?
                                                <SelectBox
                                                    key={data?.category}
                                                    label={t(data?.category)}
                                                    placeholder={t(data?.category)}
                                                    loadOptions={(search, array, handleLoading) => loadOptions(search, array, handleLoading, GET_PROPERTIES_BASED_ON_COMPANY, "property", { company_id: selectedCompany?.value })}
                                                    isPaginate={true}
                                                    isRequired
                                                    onChange={(val) => updateState("property_list", val)}
                                                    menuPlacement={"bottom"}
                                                    menuOptionHeight={"140px"}
                                                    errorMessage={data?.error?.category}
                                                    isError={data?.error?.category?.length > 0}
                                                />
                                                :
                                                <SelectBox
                                                    key={data?.category}
                                                    label={data?.category}
                                                    placeholder={data?.category}
                                                    loadOptions={(search, array, handleLoading) =>
                                                        loadOptionsApis(
                                                            "activity_board/get_all_leads",
                                                            {
                                                                company_id: selectedCompany?.value,
                                                                team_id: selectedTeams?.value === "noteams" ? undefined : selectedTeams?.value,
                                                                is_proxy_lead: data?.category === "Lead" ? true : false
                                                            },
                                                            search,
                                                            array,
                                                            handleLoading,
                                                            "data",
                                                            {},
                                                            manualResponse
                                                        )
                                                    }
                                                    isPaginate={true}
                                                    isRequired
                                                    onChange={(val) => updateState("lead_list", val)}
                                                    menuPlacement={"bottom"}
                                                    menuOptionHeight={"140px"}
                                                    errorMessage={data?.error?.category}
                                                    isError={data?.error?.category?.length > 0}
                                                />
                                    }
                                </Box>
                            </Grid>

                            <Grid item md={6} sm={6} lg={6}>
                                <TextBox
                                    label={t("Title")}
                                    value={data?.title}
                                    placeholder={t("Enter Title")}
                                    onChange={(e) => updateState("title", e.target.value)}
                                    isrequired
                                    isError={data?.error?.title?.length > 0}
                                    errorMessage={data?.error?.title}
                                />
                            </Grid>

                            <Grid item md={6} sm={6} lg={6}>
                                <FormGenerator
                                    t={t}
                                    components={[
                                        {
                                            isActive: true,
                                            component: "select",
                                            label: t("Category"),
                                            value: data?.categoryData,
                                            placeholder: t("Category"),
                                            onChange: (value) => updateState("categoryData", value),
                                            error: data?.error?.categoryData,
                                            isRequired: true,
                                            size: {
                                                xs: 12,
                                                sm: 12,
                                                md: 12,
                                                lg: 12,
                                            },
                                            isPaginate: true,
                                            loadOptions: (search, array, handleLoading) =>
                                                loadOptions(
                                                    search,
                                                    array,
                                                    handleLoading,
                                                    GET_ACTIVITY_CATAGORY_MASTER,
                                                    "activity_category_master",
                                                    { isActive: true, client: clientID, company: selectedCompany?.value },
                                                    { label: "label", value: "value" },
                                                    false,
                                                    false,
                                                    data?.type?.id
                                                ),
                                        }]} />
                            </Grid>
                            {
                                (data?.type?.title === "Follow up" ||
                                    data?.type?.title === "Meeting" ||
                                    data?.type?.title === "Appointment") &&
                                <Grid item md={6} sm={6} lg={6}>
                                    <FormGenerator
                                        t={t}
                                        components={[
                                            {
                                                isActive:
                                                    data?.type?.title === "Follow up" ||
                                                        data?.type?.title === "Meeting" ||
                                                        data?.type?.title === "Appointment"
                                                        ? true
                                                        : false,
                                                component: "select",
                                                label: t("Followup Type"),
                                                value: data?.followup,
                                                placeholder: t("Followup"),
                                                onChange: (value) => updateState("followup", value),
                                                error: data?.state?.error?.followup,
                                                isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 12,
                                                    lg: 12,
                                                },
                                                options: enumValue?.followUp ?? [],
                                            }]} />
                                </Grid>
                            }
                            <Grid item md={6} sm={6} lg={6}>
                                <FormGenerator
                                    t={t}
                                    components={[
                                        {
                                            size: {
                                                xs: 12,
                                                sm: 12,
                                                md: 12,
                                                lg: 12,
                                            },
                                            isActive: true,
                                            component: "toggle",
                                            label: t("Priority"),
                                            value: data?.Priority,
                                            placeholder: t("Priority"),
                                            onChange: (value) => updateState("Priority", value),
                                            error: data?.error?.Priority,
                                            isRequired: true,
                                            options: wrapLabelsInT(enumValue?.priority, t) ?? [],
                                        }]} />
                            </Grid>

                            {
                                (data?.type?.title === "Send Quotation" || data?.type?.title === "Send Email") &&
                                <Grid item md={6} sm={6} lg={6}>
                                    <TextBox
                                        label={t("Email")}
                                        value={data?.email}
                                        placeholder={t("Enter Email")}
                                        onChange={(e) => updateState("email", e.target.value)}
                                        isrequired
                                        isError={data?.error?.email?.length > 0}
                                        errorMessage={data?.error?.email}
                                    />
                                </Grid>
                            }

                            <Grid item md={6} sm={6} lg={6}>
                                <NewDatePicker
                                    label={t("Date & Time")}
                                    placeholder={t("Select Visit Date & Time")}
                                    value={data?.dateTime ?? ""}
                                    isRequired
                                    onChange={(value) => {
                                        updateState("dateTime", value)
                                    }}
                                    // maxDate={new Date()}
                                    isError={data?.error?.dateTime?.length > 0}
                                    errorMessage={data?.error?.dateTime}
                                    newDate
                                />
                            </Grid>

                            <Grid item md={12} sm={12} lg={12}>
                                <TextBox
                                    label={t("Notes")}
                                    value={data?.notes}
                                    placeholder={t("Enter Notes")}
                                    multiline
                                    onChange={(e) => updateState("notes", e.target.value)}
                                    isrequired
                                    isError={data?.error?.notes?.length > 0}
                                    errorMessage={data?.error?.notes}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box p={2} display={"flex"} justifyContent={"end"}>
                        <Stack direction="row" spacing={1} alignItems="end">
                            <Button variant="outlined"
                                className={classes.cancelBtn}
                                onClick={() => {
                                    handleClose()
                                    setSelectedType({})
                                }}>{t("Cancel")}</Button>
                            <Button variant="contained" onClick={() => save()} sx={{ marginInlineStart: "8px !important" }}
                                disabled={isDisableBtn}>{t("Save")}</Button>

                        </Stack>
                    </Box>
                </>
            }
        </Box>
    )
}