import gql from "graphql-tag";
import { LocalStorageKeys } from "../utils";
export const GET_AMENITITES_CATEGORY = (offset, limit, searchText) => gql`
query GET_AMENITITES_CATEGORY{
  count: amenity_category(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      client: {eq :"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
    }
  ) {
    count:count_id
  }
  amenity_category(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
        client: {eq :"${localStorage.getItem(LocalStorageKeys.clinetID)}"}
         }
      offset:${offset}
      limit:${limit}
    ){
         label:name
        value:id

      }
}
`;