import { Box, Button, Grid, Stack } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { AlertDialog, SearchFilter, TableWithPagination } from '../../../components'
import { InspectionItemHeading, InspectionItemPath } from '../../../utils'
import AddBulkItem from './addBulkItem'
import { useStyles } from './style'


const InspectionItemTable = (props) => {
    const { t } = props
    const [newbulk, setNewBulk] = React.useState(false)
    const classes = useStyles()
    //To open Add modal
    const OpenNewAddBulkPopup = () => {
        setNewBulk(!newbulk)
        props.setEditData({})
    }
    const onClose = () => {
        setNewBulk(false)
        props.setEdit(false)
    }
    // const { t } = props
    return (
        <>
            <Box m={2}>

                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        <SearchFilter value={props.searchText} placeholder={t("searchBulkInspectionItems")} handleChange={(value) => props.handleSearch(value)} />

                    </Grid>
                    <Grid item xs={8}>

                        <Stack alignItems="center" justifyContent="flex-end" direction={"row"} spacing={1}>
                            {
                                props?.permissions?.create &&
                                <Button variant='contained' disableElevation className={classes.tableAddBtn}
                                    onClick={OpenNewAddBulkPopup}>{t("addNewBulkInspectionItem")}</Button>
                            }

                        </Stack>

                    </Grid>

                </Grid>

                {/* Inspectiontable */}
                <TableWithPagination
                    heading={InspectionItemHeading(t)}
                    path={InspectionItemPath}
                    rows={props.tableData ? props.tableData : []}
                    handleIcon={props?.handleIcon}
                    tableType="no-side"
                    showpagination
                    handlePagination={props?.handleTablePagination}
                    handleChangeLimit={props?.handleTablePageLimit}
                    totalRowsCount={props?.totalRows}
                    page={props?.page}
                    limit={props?.limit}
                    dataType={[
                        { type: ["text"], name: "reference_no", width: "200px" },
                        { type: ["text"], name: "name", width: "200px" },
                        { type: ["text"], name: "count", width: "700px" },
                        { type: ["more_6_ins_item"], icon: "icon" },
                    ]}
                    view={true}
                    edit={true}
                    delete={true}
                    height={props?.height}
                />
            </Box>
            {/* Add New Bulk inspection item popup */}
            <AlertDialog open={newbulk || props.edit}
                onClose={() => onClose()}
                header={props.edit ? t("Edit") : t("addNewBulkInspectionItem")}
                component={
                    <AddBulkItem
                        details={props.details}
                        editData={props.editData}
                        company={props?.company}
                        reload={props.reload}
                        buttonName={props?.edit ? t("Update") : t("createNewBulkInspectionItem")}
                        edit={props.edit} setEdit={props.setEdit}
                        setNewBulk={setNewBulk}
                        setEditData={props?.setEditData}
                        setpropertyItems={props?.setpropertyItems}
                    />}
                md
            />
        </>
    )
}
export default withNamespaces("inspection")(InspectionItemTable)