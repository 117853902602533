import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../utils";
export const dashboardStyles = makeStyles((theme) => ({
      root: {
            padding: "24px 24px 80px",
            backgroundColor: theme.palette.background.secondary,
            height: `calc(100vh - 64px)`,
            overflow: "auto"
      },
      card: {
            boxShadow: "0px 3px 30px #5C86CB2E",
            borderRadius: theme.palette.borderRadius,
            // border: "3px solid white",
            backgroundColor: theme.palette.background.paper,
            cursor: "pointer",
            minHeight: (props) => props?.height ? props?.height : "100%",
            padding: "24px",
            width: '100%'
      },
      Cardcontent: {
            padding: "12px 16px",
      },
      title: {
            fontSize:"1rem",
            color: theme.typography.color.primary,
            fontFamily: ExtraBold,
      },
      count: {
            fontSize:"1.5rem",
            color: theme.typography.color.primary,
            fontFamily: Bold,
      },

      image: {
            width: 46,
            height: 46,
            backgroundColor: (props) => props?.data?.color ?? "#fff",
      },
      section: {
            marginTop: '10px'
      },
      detailsCard1: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
      },
      detailsCard2: {
            color: '#4E5A6B',
            fontFamily: SemiBold,
            fontSize:"0.75rem",

      },
      marginLeft: {
            marginLeft: '20px'
      },
      cardContainer: {
            backgroundColor: '#F5F7FA',
            padding: '12px',
            borderRadius: '4px'
      }
}))