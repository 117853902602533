import { Box, Grid, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import React, { useContext, useEffect, useState } from 'react';
import { SelectBox, TextBox, ToggleButtonComponent } from '../../../components';
import { AlertContext } from '../../../contexts';
import { AlertProps, enum_types } from '../../../utils';
import { loadOptions } from '../../../utils/asyncPaginateLoadOptions';
import { useStylesCreation } from '../../propertcreation/createproperty/style';
import { GET_ALL_CURRENCIES, networkCallback } from './utilies';
import { Bold } from '../../../utils';
const CustomTypography = styled(Typography)(({ theme }) => ({
      fontSize:"0.75rem",
      fontFamily: Bold,
      color: theme.typography.color.secondary,
      marginBottom: theme.spacing(1)
}));
const Label = styled(Typography)(({ theme }) => ({
      color: theme.typography.color.tertiary,
      fontSize:"0.75rem",
}));
const buttonStyle = {
      borderColor: "#E4E8EE",
      borderRadius: 1,
      backgroundColor: "#fff",
}
const textBoxStyle = {
      padding: "8px 14px",
      borderRadius: 8,
      height: 47
}
export const BankingDetails = (props) => {
      const classes = useStylesCreation();
      const alert = useContext(AlertContext);
      const [loading, setLoading] = useState(false);
      const [bankAccountTypeOptions, setBankAccountTypeOptions] = useState([])
      useEffect(() => {
            getBankAccountType()
            // eslint-disable-next-line
      }, [])
      const getBankAccountType = async () => {
            let result = await networkCallback({ enumName: ["bank_account_type"] }, "enum");
            if (!result) {
                  return []
            }
            setBankAccountTypeOptions(result?.bank_account_type?.map(_ => ({ label: _, value: _ })));
      }
      const loadOptionData = async (search, array, type) => {
            setLoading(type);
            let result;
            let enumTypes = [enum_types.bank_routing_type]

            if (type === "currency") {
                  return loadOptions(search, array, setLoading, GET_ALL_CURRENCIES, 'currency_master');
            }
            else if (enumTypes?.includes(type)) {
                  result = await networkCallback({ enumName: [type] }, "enum", setLoading);
                  if (!result) {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Something went wrong",
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.center,
                        });
                        return {
                              options: [],
                              hasMore: false
                        }
                  }
                  return {
                        options: result?.[type] ?? [],
                        hasMore: false
                  }
            }
            else {
                  return {
                        options: [],
                        hasMore: false
                  }
            }
      }
      return (
            <div>
                  <Box className={classes.imagebox1}>
                        <CustomTypography>Banking Basic Details</CustomTypography>
                        <Grid container spacing={3}>
                              <Grid item xs={12} sm={4} md={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Bank Name"
                                          placeholder="Enter Bank Name"
                                          value={props?.data?.bankName ?? ""}
                                          onChange={(e) => props?.updateState("bankName", e.target.value)} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Branch Name"
                                          placeholder="Enter Branch Name"
                                          value={props?.data?.bankBranchName ?? ""}
                                          onChange={(e) => props?.updateState("bankBranchName", e.target.value)} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    <SelectBox
                                          label={'Currency'}
                                          placeholder={'Select Currency'}
                                          loading={loading === "currency"}
                                          loadOptions={(search, array) => loadOptionData(search, array, "currency")}
                                          isPaginate
                                          debounceTimeout={800}
                                          value={props?.data?.bankCurrency ?? null}
                                          onChange={(value) => props?.updateState('bankCurrency', value)} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    <Label
                                          variant="body1"
                                          color="textsecondary"
                                          align="left"
                                          gutterBottom>
                                          {"Bank Account Type"}
                                    </Label>
                                    <ToggleButtonComponent
                                          options={bankAccountTypeOptions}
                                          value={props?.data?.bankAccountType ?? []}
                                          onChange={(value) => props?.updateState('bankAccountType', value)}
                                          isMulti={false}
                                          fullWidth={false}
                                          buttonStyle={buttonStyle} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Account Number"
                                          placeholder="Enter Account Number"
                                          value={props?.data?.bankAccountNumber ?? ""}
                                          onChange={(e) => props?.updateState("bankAccountNumber", e.target.value)} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    <SelectBox
                                          label={'Bank Routing Type'}
                                          placeholder={'Select Bank Routing Type'}
                                          loadOptions={(search, array) => loadOptionData(search, array, enum_types.bank_routing_type)}
                                          isPaginate
                                          debounceTimeout={800}
                                          loading={loading === enum_types.bank_routing_type}
                                          isSearchable={false}
                                          value={props?.data?.bankRoutingType ?? null}
                                          onChange={(value) => props?.updateState('bankRoutingType', value)} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Bank Routing Code"
                                          placeholder="Enter Bank Routing Code"
                                          value={props?.data?.bankRoutingCode ?? ""}
                                          onChange={(e) => props?.updateState("bankRoutingCode", e.target.value)} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Address Line 1"
                                          placeholder="Enter Address Line 1"
                                          value={props?.data?.bankAddressLineOne ?? ""}
                                          onChange={(e) => props?.updateState("bankAddressLineOne", e.target.value)} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Address Line 2"
                                          placeholder="Enter Address Line 2"
                                          value={props?.data?.bankAddressLineTwo ?? ""}
                                          onChange={(e) => props?.updateState("bankAddressLineTwo", e.target.value)} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label={'City'}
                                          placeholder={'Enter City'}
                                          value={props?.data?.bankCity ?? null}
                                          onChange={(e) => props?.updateState('bankCity', e.target.value)} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label={'Country'}
                                          placeholder={'Enter Country'}
                                          value={props?.data?.bankCountry ?? null}
                                          onChange={(e) => props?.updateState('bankCountry', e.target.value)} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Cheque Name"
                                          placeholder="Enter Cheque Name"
                                          value={props?.data?.chequeName ?? ""}
                                          onChange={(e) => props?.updateState("chequeName", e.target.value)} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Preferred Cash Collection Office"
                                          placeholder="Enter Office"
                                          value={props?.data?.preferredCashCollectionOffice ?? ""}
                                          onChange={(e) => props?.updateState("preferredCashCollectionOffice", e.target.value)} />
                              </Grid>
                        </Grid>
                  </Box>
            </div>
      )
}