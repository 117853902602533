import gql from "graphql-tag";

export const CommuinityManager = `query($ID:String) {
      user_profiles(where:{user_id:{eq:$ID}}) {
        id
      }
    }`;
export const EmerencyNumber = `query($id:String){
  access_control(where:{user_profile_id:{eq:$id}}){
    property_id
  }
}`;

export const PropertyName = `query($property_id:[String]){
  property(where:{id:{in:$property_id},is_active:true}){
    value:id
    label:name

  }
}`;


export let GET_PROPERTY_BASED_ON_COMPANY = (offset, limit, searchText, company_id = [] , profile_id , role_id) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
      count: access_control(
        where: {
          property: { name: { iregex: "${searchText}" } }
          user_profile_id: { eq: "${profile_id}" }
          is_active: { eq: true }
          roles_id:{eq:"${role_id}"}
        }
      ) {
        count: count_id
      }
      property: access_control(
        where: {
          property: { name: { iregex: "${searchText}" } }
          user_profile_id: { eq: "${profile_id}" }
          is_active: { eq: true }
          roles_id:{eq:"${role_id}"}
        }
        offset:${offset}
        limit:${limit}
      ) {
        property {
          value: id
          label: name
        }
      }
}
`
  ;
export const GetList = `
query ($property_id:[String],$client:String,$offset:Int,$limit:Int,$searchText:String){
  totalCountData: emergency_master(
    where: {
      property_id: { in: $property_id }
      department: {iregex: $searchText}
            client : {eq:$client}

    }
  ) {
    totalCount: count_id
  }
  emergency_master(
    where:{
      property_id:{in:$property_id}
    	department: {iregex: $searchText}
      client : {eq:$client}
    }
    order_by:{created_at:desc}
    limit:$limit
    offset:$offset
  )
  {
    id
    name
    country_code
    contact_number
    department
    is_active
    property{
      name
      id
    }
  }
}`;


export const profession_master = (client = "", offset, limit, searchText) => gql`
query{
  totalCountData: profession_master(
   where: {
     name: {iregex: "${searchText}"}
           client : {eq:"${client}"}
    }
 ) {
   totalCount: count_id
 }
 profession_master(
   where:{
           client : {eq:"${client}"}
     name:{iregex:"${searchText}"}
   }
   order_by:{created_at:desc}
   offset:${offset}
   limit:${limit}
 ){
   id
   name
   description
    is_active
 }
}`;

export const GetThread = `
query($user_profile_id: String, $role_id: String) {
  access_control(
    where: {
      user_profile_id: { eq: $user_profile_id }
      roles: { id: { eq: $role_id } }
      is_active: { eq: true }
    }
  ) {
    user_profile_id
    user_profile {
      id
      first_name
    }
    property {
      value:id
      label:name
    }
    roles {
      id
      name
    }
  }
}

`;

export const Search = `query($search: String) {
  emergency_master(
    where: {
      department:{ilike:$search}
    }
  ) {
     name
    country_code
    contact_number
    department
    property{
      name
    }
  }
}`;

export const Search_profession = `query($search: String) {
  profession_master(
    where: {
      name:{ilike:$search}
    }
  ) {
    name
    description
  }
}`;
export const Get_property_by_user = `
 query($id:String){
  access_control(where:{user_profile_id:{eq:$id}}){
    property{
      label:name
      value:id
        company{
        id
      }
    }
  }
}`;

export const Get_company_id = `
 query($id:String){
  access_control(where:{user_profile_id:{eq:$id}},is_active:true){
    property{
      label:name
      value:id
        company{
        value:id
        label:name
      }
    }
  }
}`;

export const Get_roles_by_property_id = `
query($id: String) {
  access_control(where: { property_id: $id }) {
    roles {
      value:id
      label:name
    }
  }
}

`;
export const Get_notification_by_company_id = `
query($id: String, $offset: Int, $limit: Int) {
  totalCountData: notifications(where: { company_id: { in: $id } }) {
    totalCount: count_id
  }
  notifications(
    where: { company_id: { in: $id } }
    offset: $offset
    limit: $limit
  ) {
    id
    title
    description
    notifications_type
    content_type
    triggered_by
    created_at
    trigger_count
    url
    triggered_by{
      first_name
      last_name
    }
  }
}
`;


export const GET_PROPERTY_BASED_ON_COMPANY_MANGEMENT = () => {
  return gql`
  query GET_PROPERTY_BASED_ON_COMPANY_MANGEMENT{
    property(
      where: {
        company_id: { in: $id }
          is_active:{eq:true}
      }
      order_by: { created_at: desc }
    ){
        id
        }
  }
  `
}
  ;


export const GET_PROPERTY_ACCESS = () => {
  return gql`
query GET_PROPERTY_ID {
  access_control(
    where: {
      user_profile_id: { eq: $user_profile_id }
      roles: { id: { eq: $role_id } }
      property: { company_id: { eq: $companyID } }
      property: { is_active: { eq: true } }
      is_active: { eq: true }
    }
  ) {
    property_id
    property {
      id
      company_id
      is_active
    }
  }
}`
}


export const GET_UNIT_TYPE_BY_PURPOSE = () => {
  return gql`
  query {
    count: unit_type_master(
      where: { purpose: { eq: $purpose } }
      is_active: { eq: true }
      name: { iregex: "$searchText" }
    ) {
      count: count_id
    }
    unit_type_master(
      where: { purpose: { eq: $purpose } }
      is_active: { eq: true }
      name: { iregex: "$searchText" }
    ) {
      id
      name
    }
  }
  `
}