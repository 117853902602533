import gql from "graphql-tag";
export const MOVE_OUT_DATA = (offset, limit, searchText, agreement_request_id, clientID) => gql`
query {
     count: agreement_inspection_request(
    where:{
     agreement_request_id: "${agreement_request_id}"
      agreement_inspection: { reference_id: { iregex: "${searchText}" } }

    }
  ) {
    count:count_id
  }
  agreement_inspection_request(
    where: {
      agreement_request_id: "${agreement_request_id}"
      agreement_inspection: { reference_id: { iregex: "${searchText}" } }

    }
     offset:${offset}
      limit:${limit}
  ) {
    id
    agreement_inspection {
      inspection_resources {
        booked_resources {
          resource {
            contact {
              first_name
            }
          }
        }
      }
      status
      reference_id
      agreement_units {
        agreement_unit_users {
          contact {
            first_name
          }
        }
      }
      unit {
        unit_no
        property {
          name
        }
      }
    }
  }
}`
export const Path = ["inspectionID", "unitID", "associatedUnit", "propertyName", "tenantName", "inspectorName", "status"]

export const Heading = (t) => [
  { title: t("Inspection IDr"), field: "inspectionID" },
  { title: t("Unit ID"), field: "unitID" },
  { title: t("Associated Unit"), field: "associatedUnit", },
  { title: t("Property Name"), field: "propertyName", },
  { title: t("Tenant Name"), field: "tenantName", },
  { title: t("Inspector Name"), field: "inspectorName", },
  { title: t("Status"), field: "status", },
]

export const Type = [
  { type: ["text"], name: "inspectionID" },
  { type: ["text"], name: "unitID" },
  { type: ["text"], name: "associatedUnit" },
  { type: ["text"], name: "propertyName" },
  { type: ["text"], name: "tenantName" },
  { type: ["text"], name: "inspectorName" },
  { type: ["status_inspection"], name: "status" },
]