import { Box } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { statusType, statusType1, statusType2, statusType3 } from '../../utils/agreementUtils';
import { TabCompoent } from "./components";
import { Agreement, Lead, Oppertunity } from "./stepComponent";
import { timeDiffCalc } from "../../utils";
const TimeLineList = ({
  list = [],
  details = {},
  reload = () => false,
  t,
  agreement_id = "",
  load = "",
  setLoad = () => false,
  kycRequest = [],
  agreementReload = () => false,
  parentAgreement = [],
  terminatedAgreement = []

}) => {
  // states
  const [Details, setDetails] = React.useState("data");
  const [step, setStep] = React.useState(0);
  const current_date = new Date();
  let current_day = current_date.getDate();
  let no_of_days_for_led = timeDiffCalc(
    new Date(),
    new Date(details?.lease_end_date),
    current_day
  );

  const is_request_allowed = (details?.agreement_status === "Live");

  const is_termination_request_allowed =
    (
      !Boolean(details?.agreement_request?.some?.((_) =>
        (_?.request_purpose === "Termination" && _?.status === "Pending")))
    );

  const is_renewal_request_allowed =
    (
      no_of_days_for_led < 30 &&
      !Boolean(details?.agreement_request?.some?.((_) =>
        (_?.request_purpose === "Renewal" && ["Pending", "Approved"].includes(_?.status))))
    );

  // functions
  const onChange = (e) => {
    setLoad(false)
    setStep(e);
  };
  const terminated = list?.some((x) => x?.status === "Declined") || list?.some((x) => x?.status === "Onboarding")

  // component
  const components = {
    0: <Lead t={t} agreement_id={agreement_id} setDetails={setDetails} statusType={["Draft", "Review", list?.[0]?.status]} />,
    1: <Oppertunity reload={reload} t={t} agreement_id={agreement_id} setDetails={setDetails} statusType={["Draft", "Review", list?.[0]?.status]} />,

    2: (
      <Agreement
        list={list?.filter(
          (x) => x.status === "Review" || x.status === "Draft"
        )}
        details={details}
        reload={reload}
        t={t}
        i={2}
        is_status_card={statusType(list)[7]}
        nestStep={3}
        type="Agreement"
        statusType={["Draft", (list?.[0]?.status === "Draft" || list?.[0]?.status === "Declined") ? "Draft" : "Review", list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        is_overall={true}
        step={step}
        terminated={terminated}
        show_card={!!list?.find((x)=>x?.status === "Finance Review" && x?.type === "Approved") ? false : true}
        is_terminated={false}
      />
    ),
    3: (
      <Agreement
        list={list?.filter((x) => x.status === "KYC Screening")?.length > 0 ? list?.filter((x) => x.status === "KYC Screening") : [{ show: "is_show", status: "Review", type: "Approved" }]}
        details={details}
        reload={reload}
        t={t}
        i={3}
        is_status_card={statusType(list)[3]}
        nestStep={4}
        type="Agreement"
        statusType={["Review", (list?.[0]?.status === "Review" || list?.[0]?.status === "Declined") ? "Review" : "KYC Screening", list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        is_overall={true}
        step={step}
        terminated={terminated}
        show_card={!!list?.find((x)=>x?.status === "Finance Review" && x?.type === "Approved") ? false : true}
        is_terminated={false}
        kycRequest={kycRequest?.length > 0 ? true : false}

        
      />
    ),
    4: (
      <Agreement
        list={list?.filter((x) => x.status === "Finance Review")?.length > 0 ? list?.filter((x) => x.status === "Finance Review") : [{ show: "is_show", status: "KYC Screening", type: "Approved" }]}
        details={details}
        reload={reload}
        t={t}
        i={4}
        is_status_card={statusType(list)[4]}
        nestStep={5}
        type=""
        statusType={["KYC Screening", (list?.[0]?.status === "KYC Screening" || list?.[0]?.status === "Declined") ? "KYC Screening" : "Finance Review", list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        step={step}
        is_overall={true}
        show_card={!!list?.find((x)=>x?.status === "Finance Review" && x?.type === "Approved") ? false : true}
        terminated={terminated}
        is_terminated={false}

      />
    ),
    5: (
      <Agreement
        list={list?.filter((x) => x.status === "Onboarding")?.length > 0 ? list?.filter((x) => x.status === "Onboarding") : [{ show: "is_show", status: "Finance Review", type: "Approved" }]}
        details={details}
        reload={reload}
        t={t}
        i={5}
        is_status_card={statusType(list)[5]}
        nestStep={6}
        type=""
        statusType={["Finance Review", (list?.[0]?.status === "Confirmed" || list?.[0]?.status === "Declined") ? "Confirmed" : "Onboarding", list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        step={step}
        terminated={terminated}
        is_terminated={false}
        show_card={false}
        


      />
    ),
    6: (
      <Agreement
        list={list?.filter(
          (x) =>
            x.status !== "Onboarding" &&
            x.status !== "Review" &&
            x.status !== "Draft" &&
            x.status !== "KYC Screening" &&
            x.status !== "Finance Review" &&
            x.status !== "Confirmed"
        )}
        details={details}
        reload={reload}
        t={t}
        i={5}
        // is_loaded={true}
        statusType={["Onboarding", "Live", list?.[0]?.status]}
        showType={false}

        is_status_card={statusType(list)[6]}
        load={load}
        agreement_id={agreement_id}
        is_overall={true}
        terminated={true}
        // is_request={((details?.agreement_request?.[0]?.request_purpose === "Termination" &&
        //   details?.agreement_request?.[0]?.status === "Pending") || parentAgreement?.length > 0)
        //   ? false
        //   : true}
        is_request_allowed={is_request_allowed}
        is_termination_request_allowed={is_termination_request_allowed}
        is_renewal_request_allowed={is_renewal_request_allowed}
        agreementReload={agreementReload}
        parentAgreement={parentAgreement}


      />
    ),
  };
  const components1 = {
    0: (
      <Agreement
        list={list?.filter((x) => x.status === "Onboarding")?.length > 0 ? list?.filter((x) => x.status === "Onboarding") : [{ show: "is_show", status: "Finance Review", type: "Approved" }]}
        details={details}
        reload={reload}
        t={t}
        i={5}
        is_status_card={statusType2(list)[1]}
        nestStep={6}
        type=""
        statusType={
          ["Finance Review", "Confirmed", "Confirmed"]}
        agreement_id={agreement_id}
        load={load}
        step={step}
        is_terminated={true}
        is_overall={true}
        is_loaded={true}
        
      />
    ),
    1: (
      <Agreement
        list={list?.filter(
          (x) =>
            x.status === "Live"
        )}
        details={details}
        reload={reload}
        t={t}
        i={5}
        is_loaded={true}
        statusType={["Onboarding", "Live", list?.[0]?.status]}
        showType={true}
        is_status_card={statusType2(list)[2]}
        load={load}
        agreement_id={agreement_id}
        is_overall={true}
        agreementReload={agreementReload}
        parentAgreement={parentAgreement}
        // is_request={((details?.agreement_request?.[0]?.request_purpose === "Termination" &&
        //   details?.agreement_request?.[0]?.status === "Pending") || terminatedAgreement?.length > 0 || parentAgreement?.length > 0)
        //   ? false
        //   : true}
        is_request_allowed={is_request_allowed}
        is_termination_request_allowed={is_termination_request_allowed}
        is_renewal_request_allowed={is_renewal_request_allowed}
        terminated={true}

      />
    ),
  };
  const components2 = {
    0: (
      <Agreement
        list={list?.filter(
          (x) => x.status === "Review" || x.status === "Draft"
        )}
        details={details}
        reload={reload}
        t={t}
        i={2}
        is_status_card={statusType(list)[7]}
        nestStep={3}
        type="Agreement"
        statusType={["Draft", (list?.[0]?.status === "Draft" || list?.[0]?.status === "Declined") ? "Draft" : "Review", list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        is_overall={true}
        step={step}
        terminated={terminated}
        show_card={!!list?.find((x)=>x?.status === "Finance Review" && x?.type === "Approved") ? false : true}
        is_terminated={false}
      />
    ),
    1: (
      <Agreement
        list={list?.filter((x) => x.status === "KYC Screening")?.length > 0 ? list?.filter((x) => x.status === "KYC Screening") : [{ show: "is_show", status: "Review", type: "Approved" }]}
        details={details}
        reload={reload}
        t={t}
        i={3}
        is_status_card={statusType(list)[3]}
        nestStep={4}
        type="Agreement"
        statusType={["Review", (list?.[0]?.status === "Review" || list?.[0]?.status === "Declined") ? "Review" : "KYC Screening", list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        is_overall={true}
        step={step}
        terminated={terminated}
        show_card={!!list?.find((x)=>x?.status === "Finance Review" && x?.type === "Approved") ? false : true}
        kycRequest={kycRequest?.length > 0 ? true : false}
        is_terminated={false}


      />
    ),
    2: (
      <Agreement
        list={list?.filter((x) => x.status === "Finance Review")?.length > 0 ? list?.filter((x) => x.status === "Finance Review") : [{ show: "is_show", status: "KYC Screening", type: "Approved" }]}
        details={details}
        reload={reload}
        t={t}
        i={4}
        is_status_card={statusType(list)[4]}
        nestStep={5}
        type=""
        statusType={["KYC Screening", (list?.[0]?.status === "KYC Screening" || list?.[0]?.status === "Declined") ? "KYC Screening" : "Finance Review", list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        step={step}
        is_overall={true}
        terminated={terminated}
        show_card={!!list?.find((x)=>x?.status === "Finance Review" && x?.type === "Approved") ? false : true}
        is_terminated={false}


      />
    ),
    3: (
      <Agreement
        list={list?.filter((x) => x.status === "Onboarding")?.length > 0 ? list?.filter((x) => x.status === "Onboarding") : [{ show: "is_show", status: "Finance Review", type: "Approved" }]}
        details={details}
        reload={reload}
        t={t}
        i={5}
        is_status_card={statusType(list)[5]}
        nestStep={6}
        type=""
        statusType={["Finance Review", (list?.[0]?.status === "Confirmed" || list?.[0]?.status === "Declined") ? "Confirmed" : "Onboarding", list?.[0]?.status]}
        agreement_id={agreement_id}
        load={load}
        step={step}
        terminated={terminated}
        is_terminated={false}
        show_card={false}
        


      />
    ),
    // 6: (
    //   <Countinuity list={list} details={details} reload={reload} t={t} i={6} />
    // ),
    4: (
      <Agreement
        list={list?.filter(
          (x) =>
            x.status !== "Onboarding" &&
            x.status !== "Review" &&
            x.status !== "Draft" &&
            x.status !== "KYC Screening" &&
            x.status !== "Finance Review" &&
            x.status !== "Confirmed"
        )}
        details={details}
        reload={reload}
        t={t}
        i={5}
        // is_loaded={true}
        statusType={["Onboarding", "Live", list?.[0]?.status]}
        showType={true}
        is_status_card={statusType(list)[6]}
        load={load}
        agreement_id={agreement_id}
        is_overall={true}
        terminated={true}
        agreementReload={agreementReload}
        parentAgreement={parentAgreement}
        // is_request={((details?.agreement_request?.[0]?.request_purpose === "Termination" &&
        //   details?.agreement_request?.[0]?.status === "Pending") || terminatedAgreement?.length > 0 || parentAgreement?.length > 0)
        //   ? false
        //   : true}
        is_request_allowed={is_request_allowed}
        is_termination_request_allowed={is_termination_request_allowed}
        is_renewal_request_allowed={is_renewal_request_allowed}
      />
    ),
  };

  const comp = details?.is_renewal ? components2 : details?.quotation?.id ? components : components1
  return (
    <Box>
      <TabCompoent t={t} onChange={onChange} step={step} is_show={details?.is_renewal ? statusType3(list) : details?.quotation?.id ? Details?.length > 0 ? statusType(list) : statusType1(list) : statusType2(list)} indexOfOne={Details?.length > 0 ? true : false} details={details} />
      <Box p={2}>{Details?.length > 0 ? comp[step] : step === 0 ? comp[1] : comp[step + 1]}</Box>
    </Box>
  );
};
export default withNamespaces("agreement")(TimeLineList);
