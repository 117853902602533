import { useContext, useEffect, useState } from 'react';
import { Badge, Box, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import FilterIMG from '../../../../../assets/filter';
import { DatePickerTimeNew, DrawerComponent, FilterGenerator, Label, MobileNumberInputComponent, SearchFilter, SelectBox, TableWithPagination, TextBox, UseDebounce } from '../../../../../components';
import { AlertContext, AuthContext } from '../../../../../contexts';
import { activeOptions, activeOptionsList, AlertProps, concat_string, convertTimeUtcToZoneCalander, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions } from '../../../../../utils';
import { useStyles } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { TABLE_HEADING, TABLE_PATH, TABLE_TYPE, INITIAL_ADD_FORM_STATE, INITIAL_FILTER_STATE, INITIAL_TABLE_PAGE, INITIAL_TABLE_LIMIT, INITIAL_TABLE_OFFSET } from './utils/tableUtils';
import { NetworkCall } from '../../../../../networkcall';
import { BackendRoutes } from '../../../../../router/routes';
import { loadOptionsApis } from '../../../../../utils/asyncPaginateLoadOptions';
import { config } from '../../../../../config';
import { UtilityIcon } from './assets/utilityIcon';
import QRCode from 'react-qr-code';

export const UtilityList = ({
    t = () => false,
    permission = {},
    unitID = "",
    logo = "/images/imagesproperty.svg",
    unit_no = "",
    property_name = "",
    unit_name = "",
    location = "",
}) => {
    const selected_company = JSON?.parse?.(localStorage?.getItem?.(LocalStorageKeys?.selectedCompany) ?? {});
    const table_offset = INITIAL_TABLE_OFFSET;
    const property_location = concat_string(
        { property_name, ...location },
        [
            "property_name", "door_no", "street_1", "street_2",
            "street_3", "landmark", "area", "city", "district",
            "state", "country", "zipcode", "latitude", "longitude"
        ]
    );
    const size = useWindowDimensions();

    const classes = useStyles({ size });
    const debounce = UseDebounce();

    const alert = useContext(AlertContext);
    const auth = useContext(AuthContext);

    const [is_button_disable, set_is_button_disable] = useState(false);
    const [is_table_loading, set_is_table_loading] = useState(true);
    const [table_state, set_table_state] = useState({ rows: [], total_rows_count: 0, });
    const [table_search_text, set_table_search_text] = useState("");
    const [table_page, set_table_page] = useState(INITIAL_TABLE_PAGE);
    const [table_limit, set_table_limit] = useState(INITIAL_TABLE_LIMIT);
    const [filter_state, set_filter_state] = useState(INITIAL_FILTER_STATE);
    const [is_filter_open, set_is_filter_open] = useState(false);
    const [is_add_form_open, set_is_add_form_open] = useState(false);
    const [add_form_state, set_add_form_state] = useState(INITIAL_ADD_FORM_STATE);

    useEffect(() => {
        if (permission?.read && unitID?.length > 0) {
            getInitialData({ unit_id: unitID });
        }
        // eslint-disable-next-line
    }, [auth, permission?.read, unitID]);

    const getInitialData = async ({ unit_id = unitID }) => {
        return new Promise(async (resolve, reject) => {
            try {
                await Promise.all([
                    getTableData({ unit_id }),
                ]);
            } catch (error) {
                reject(error);
            }
        })
    }

    const getTableData = ({
        unit_id = unitID,
        offset = table_offset,
        limit = table_limit,
        search = table_search_text,
        filter = filter_state,
    }) => {
        set_is_button_disable(true);
        set_is_table_loading(true);
        let payload = {
            offset, limit, search, unit_id,
            is_active: filter?.is_active?.length === 0
                ? [true, false]
                : filter?.is_active,
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.utilities_utility_mapping_list}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            let temp_response = response?.data?.data ?? []
            let temp_row = temp_response?.map?.((_) => {
                return {
                    id: _?.id,
                    reference_no: _?.reference_no?.length > 0 ? _?.reference_no : "-",
                    utility_category_name: _?.utility_category_name?.length > 0 ? _?.utility_category_name : "-",
                    utility_master_name: _?.utility_master_name?.length > 0 ? _?.utility_master_name : "-",
                    provider: _?.provider?.length > 0 ? _?.provider : "-",
                    service_provider_name: _?.service_provider_name?.length > 0 ? _?.service_provider_name : "-",
                    price_per_uom: _?.price
                        ? concat_string(
                            {
                                price: `${_?.currency_symbol ? (_?.currency_symbol + ` `) : ``}${_?.price}`,
                                uom: _?.period,
                            },
                            ["price", "uom"],
                            " / "
                        )
                        : "-",
                    description: _?.description?.length > 0 ? _?.description : "-",
                    box_odu_number: _?.box_odu_number?.length > 0 ? _?.box_odu_number : "-",
                    meter_serial_no: _?.meter_serial_no?.length > 0 ? _?.meter_serial_no : "-",
                    subscription_no: _?.subscription_no?.length > 0 ? _?.subscription_no : "-",
                    mobile_no: _?.mobile_number
                        ? concat_string(
                            {
                                mobile_code: _?.mobile_no_country_code,
                                mobile_number: _?.mobile_number,
                            },
                            ["mobile_code", "mobile_number"],
                            "-"
                        )
                        : "-",
                    inspection_date: _?.inspection_date,
                    inspection_reading: _?.inspection_reading ?? "-",
                    last_reading_date: _?.last_reading_date,
                    last_reading: _?.last_reading ?? "-",
                    current_reading_date: _?.current_reading_date,
                    current_reading: _?.current_reading ?? "-",
                    is_active: _?.is_active ? "Active" : "Inactive",
                    data: _,
                }
            })
            set_table_state({
                rows: temp_row ?? [],
                total_rows_count: response?.data?.count ?? 0
            })
            set_is_button_disable(false);
            set_is_table_loading(false);
        }).catch(() => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        });
    }

    const handleTableSearch = ({ search }) => {
        set_table_search_text(search)
        debounce(() => searchTableFunction({ search }), 800)
    }

    const searchTableFunction = ({ search }) => {
        if (table_page > 1) { set_table_page(1) }
        getTableData({ search });
    }

    const handleAdd = () => {
        set_add_form_state({
            ...INITIAL_ADD_FORM_STATE,
            error: {
                utility_category: "",
                utility_master: "",
                description: "",
                box_odu_no: "",
                meter_serial_no: "",
                subscription_no: "",
                mobile: "",
                inspection_date: "",
                inspection_reading: "",
                last_reading_date: "",
                last_reading: "",
                current_reading_date: "",
                current_reading: "",
            }
        });
        set_is_add_form_open(true);
    }

    const handleTableIcon = ({ type, data }) => {
        const full_data = data?.data
        const temp_add_state = {
            form_type: type,
            id: full_data?.id,
            utility_category: full_data?.utility_category_id
                ? {
                    value: full_data?.utility_category_id,
                    label: full_data?.utility_category_name,
                }
                : "",
            utility_master: full_data?.utility_master_id
                ? {
                    value: full_data?.utility_master_id,
                    label: full_data?.utility_category_name,
                }
                : "",
            unit_utility_id: full_data?.reference_no ?? "",
            provider: full_data?.provider ?? "",
            service_provider_name: full_data?.service_provider_name ?? "",
            uom_name: full_data?.period ?? "",
            price_per_uom: full_data?.price ? `${full_data?.currency_symbol ? (full_data?.currency_symbol + ` `) : ``}${full_data?.price}` : "",
            description: full_data?.description ?? "",
            box_odu_no: full_data?.box_odu_number ?? "",
            meter_serial_no: full_data?.meter_serial_no ?? "",
            subscription_no: full_data?.subscription_no ?? "",
            mobile: (full_data?.mobile_number || full_data?.mobile_no_country_code)
                ? {
                    mobile_code: full_data?.mobile_no_country_code ?? "",
                    mobile: full_data?.mobile_number ?? "",
                }
                : "",
            inspection_date: full_data?.inspection_date ? new Date(convertTimeUtcToZoneCalander(full_data?.inspection_date, "DD MMM yyyy, hh:mm a")) : null,
            inspection_reading: full_data?.inspection_reading ?? 0,
            last_reading_date: full_data?.last_reading_date ? new Date(convertTimeUtcToZoneCalander(full_data?.last_reading_date, "DD MMM yyyy, hh:mm a")) : null,
            last_reading: full_data?.last_reading ?? 0,
            current_reading_date: full_data?.current_reading_date ? new Date(convertTimeUtcToZoneCalander(full_data?.current_reading_date, "DD MMM yyyy, hh:mm a")) : null,
            current_reading: full_data?.current_reading ?? 0,
            is_active: type === "active" ? !full_data?.is_active : full_data?.is_active,
            is_delete: type === "delete",
            error: {
                utility_category: "",
                utility_master: "",
                description: "",
                box_odu_no: "",
                meter_serial_no: "",
                subscription_no: "",
                mobile: "",
                inspection_date: "",
                inspection_reading: "",
                last_reading_date: "",
                last_reading: "",
                current_reading_date: "",
                current_reading: "",
            },
        }
        set_add_form_state({ ...temp_add_state })
        if (type === "edit" || type === "view") {
            set_is_button_disable(false);
            set_is_add_form_open(true);
        }
        else if (type === "active" || type === "delete") {
            handleCreateEdit({ final_add_state: temp_add_state });
        }
    }

    const handleTablePagination = ({ page = table_page }) => {
        set_table_page(page);
        let offset = (page - 1) * table_limit;
        getTableData({ offset });
    }

    const handleTablePageLimit = ({ limit = table_limit }) => {
        set_table_limit(limit);
        set_table_page(1);
        getTableData({ limit });
    }

    const updateAddFormState = ({ key, value, error_message }) => {
        let updated_errors = add_form_state?.error;
        updated_errors[key] = error_message ?? "";
        switch (key) {
            case "utility_category":
                set_add_form_state({
                    ...add_form_state,
                    [key]: value,
                    utility_master: "",
                    provider: "",
                    service_provider_name: "",
                    uom_name: "",
                    currency_id: null,
                    price: "",
                    price_per_uom: "",
                    error: {
                        ...updated_errors,
                        utility_master: "",
                    }
                });
                break;

            case "utility_master":
                set_add_form_state({
                    ...add_form_state,
                    [key]: value,
                    provider: value?.provider ?? "",
                    service_provider_name: value?.service_provider_name ?? "",
                    uom_name: value?.period ?? "",
                    currency_id: value?.currency_id ?? null,
                    price: value?.price ?? "",
                    price_per_uom: value?.price ? `${value?.currency_symbol ? (value?.currency_symbol + ` `) : ``}${value?.price}` : "",
                    error: updated_errors,
                });
                break;

            case "last_reading_date":
                set_add_form_state({
                    ...add_form_state,
                    [key]: value,
                    error: {
                        ...updated_errors,
                        current_reading_date: "",
                    }
                });
                break;

            case "last_reading":
                set_add_form_state({
                    ...add_form_state,
                    [key]: value,
                    error: {
                        ...updated_errors,
                        current_reading: "",
                    }
                });
                break;

            case "current_reading_date":
                set_add_form_state({
                    ...add_form_state,
                    [key]: value,
                    error: {
                        ...updated_errors,
                        last_reading_date: "",
                    }
                });
                break;

            case "current_reading":
                set_add_form_state({
                    ...add_form_state,
                    [key]: value,
                    error: {
                        ...updated_errors,
                        last_reading: "",
                    }
                });
                break;

            default:
                set_add_form_state({ ...add_form_state, [key]: value, error: updated_errors });
                break;
        }
    }

    const validate = () => {
        let is_valid = true;
        let error = add_form_state?.error;
        if (add_form_state?.utility_category?.length === 0) { is_valid = false; error.utility_category = t("Utility Category is Required") }
        if (add_form_state?.utility_master?.length === 0) { is_valid = false; error.utility_master = t("Utility Master is Required") }
        if (add_form_state?.box_odu_no?.length === 0) { is_valid = false; error.box_odu_no = t("Box/ODU Number is Required") }
        if (add_form_state?.uom?.length === 0) { is_valid = false; error.uom = t("UOM is Required") }
        if (add_form_state?.meter_serial_no?.length === 0) { is_valid = false; error.meter_serial_no = t("Meter Serial Number is Required") }
        if (add_form_state?.subscription_no?.length === 0) { is_valid = false; error.subscription_no = t("Subscription Number is Required") }
        if (add_form_state?.mobile?.length === 0) { is_valid = false; error.mobile = t("Mobile Number is Required") }
        if (add_form_state?.inspection_date?.length === 0) { is_valid = false; error.inspection_date = t("Inspection Date is Required") }
        if (add_form_state?.inspection_reading?.length === 0) { is_valid = false; error.inspection_reading = t("Inspection Reading is Required") }
        if (add_form_state?.last_reading_date?.length === 0) { is_valid = false; error.last_reading_date = t("Last Reading Date is Required") }
        if (add_form_state?.last_reading?.length === 0) {
            is_valid = false;
            error.last_reading = t("Last Reading is Required");
        } else if (parseFloat(add_form_state?.last_reading) > parseFloat(add_form_state?.current_reading ?? 0)) {
            is_valid = false;
            error.last_reading = t("Last Reading should be lesser than or equal to current reading");
        }
        if (add_form_state?.current_reading_date?.length === 0) { is_valid = false; error.current_reading_date = t("Current Reading Date is Required") }
        if (add_form_state?.current_reading?.length === 0) {
            is_valid = false;
            error.current_reading = t("Current Reading is Required");
        } else if (parseFloat(add_form_state?.current_reading) < parseFloat(add_form_state?.last_reading ?? 0)) {
            is_valid = false;
            error.current_reading = t("Current Reading should be greater than or equal to Last Reading");
        }

        if (!is_valid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            });
        }
        set_add_form_state({ ...add_form_state, error });
        return is_valid;
    }

    const handleCreateEdit = async ({ final_add_state }) => {
        if ((final_add_state?.form_type === "active" || final_add_state?.form_type === "delete") ? true : validate()) {
            set_is_button_disable(true);
            const current_date = new Date().toISOString();
            const profile_id = localStorage?.getItem?.(LocalStorageKeys?.profileID);

            const payload = {
                company_id: selected_company?.value ?? null,
                unit_id: unitID,
                category_id: final_add_state?.utility_category?.value ?? null,
                utility_id: final_add_state?.utility_master?.value ?? null,
                utilties_provider: final_add_state?.provider?.length > 0 ? final_add_state?.provider : null,
                currency_id: final_add_state?.currency_id ?? null,
                price: final_add_state?.price ?? null,
                period: final_add_state?.uom_name ?? null,
                description: final_add_state?.description?.length > 0 ? final_add_state?.description : null,
                box_odu_number: final_add_state?.box_odu_no?.length > 0 ? final_add_state?.box_odu_no : null,
                meter_serial_no: final_add_state?.meter_serial_no?.length > 0 ? final_add_state?.meter_serial_no : null,
                subscription_no: final_add_state?.subscription_no?.length > 0 ? final_add_state?.subscription_no : null,
                mobile_no_country_code: final_add_state?.mobile?.mobile_code?.length > 0 ? final_add_state?.mobile?.mobile_code : null,
                mobile_number: final_add_state?.mobile?.mobile?.length > 0 ? final_add_state?.mobile?.mobile : null,
                inspection_reading_date: final_add_state?.inspection_date ?? null,
                inspection_reading: final_add_state?.inspection_reading?.length > 0 ? parseFloat(final_add_state?.inspection_reading) : 0,
                prev_reading_date: final_add_state?.last_reading_date ?? null,
                prev_reading: final_add_state?.last_reading?.length > 0 ? parseFloat(final_add_state?.last_reading) : 0,
                last_reading_date: final_add_state?.current_reading_date ?? null,
                last_reading: final_add_state?.current_reading?.length > 0 ? parseFloat(final_add_state?.current_reading) : 0,
                is_active: final_add_state?.is_active ?? null,
                is_delete: final_add_state?.is_delete ?? null,
                updated_by: profile_id ?? null,
                updated_at: current_date ?? null,
            };

            switch (final_add_state?.form_type) {
                case "add":
                    payload.created_by = profile_id ?? null;
                    payload.created_at = current_date ?? null;
                    break;
                case "edit":
                    payload.id = final_add_state?.id ?? undefined;
                    break;
                case "active":
                    payload.id = final_add_state?.id ?? undefined;
                    break;
                case "delete":
                    payload.id = final_add_state?.id ?? undefined;
                    break;

                default:
                    break;
            }

            NetworkCall(
                `${config.api_url}${BackendRoutes?.unit_utils_upsert}`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then(() => {
                set_table_page(1);
                set_table_search_text("");
                getInitialData({});
                set_is_add_form_open(false);
                set_add_form_state({ ...INITIAL_ADD_FORM_STATE });
                set_is_button_disable(false);
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: final_add_state?.id
                        ? (final_add_state?.form_type === "delete"
                            ? t("Utility Master Deleted Successfully.!!!")
                            : t("Utility Master Updated Successfully.!!!")
                        )
                        : t("Utility Master Created Successfully.!!!"),
                })
            }).catch(() => {
                set_is_button_disable(false);
                alert.setSnack({
                    ...alert, open: true, msg: t("Some Thing Went Wrong"),
                    severity: AlertProps.severity.error,
                });
            });
        } else { return false }
    }

    const handleFilter = ({ filter }) => {
        set_filter_state(filter);
        set_table_page(1);
        getTableData({ filter });
    }

    const constructUtilityCategoryDropdownResponse = (array) => {
        const temp_utility_category_list = array?.map((i) => {
            return {
                ...i,
                label: i?.name,
                value: i?.id,
            }
        })
        return temp_utility_category_list
    };

    const constructUtilityMasterDropdownResponse = (array) => {
        const temp_utility_master_list = array?.map((i) => {
            return {
                ...i,
                label: i?.utility_name,
                value: i?.id,
            }
        })
        return temp_utility_master_list
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.body}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <SearchFilter
                            value={table_search_text}
                            placeholder={t("Search Utilities")}
                            customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                            handleChange={(search) => handleTableSearch({ search })} />
                    </Grid>
                    <Grid item xs={8}>
                        <Box display={"flex"} justifyContent={"end"}>
                            <Stack direction="row" spacing={2}
                                divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "16px" }} />}>
                                <IconButton
                                    className={classes.filter_button}
                                    onClick={() => set_is_filter_open(!is_filter_open)}>
                                    <Badge
                                        variant="dot"
                                        color="primary"
                                        invisible={!(filter_state.is_active?.length > 0)}>
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    onClick={handleAdd}>
                                    {t("Add A Utility")}
                                </Button>
                            </Stack>
                        </Box>
                    </Grid >
                    <Grid item xs={12}>
                        <TableWithPagination
                            is_loading={is_table_loading}
                            tableType="no-side"
                            heading={TABLE_HEADING(t)}
                            path={TABLE_PATH}
                            dataType={TABLE_TYPE}
                            rows={table_state?.rows}
                            onClick={() => { }}
                            handleIcon={(type, data) => handleTableIcon({ type, data })}
                            showpagination={true}
                            page={table_page}
                            handlePagination={(page) => handleTablePagination({ page })}
                            limit={table_limit}
                            handleChangeLimit={(limit) => handleTablePageLimit({ limit })}
                            totalRowsCount={table_state?.total_rows_count}
                            height={'calc(100vh - 445px)'}
                            view={permission?.read}
                            edit={permission?.update}
                            delete={permission?.delete} />
                    </Grid>
                </Grid >
                <FilterGenerator
                    open={is_filter_open}
                    onClose={() => set_is_filter_open(false)}
                    onApply={(filter) => handleFilter({ filter })}
                    components={[
                        {
                            component: "toggleButton",
                            value: filter_state?.is_active,
                            options: activeOptions(t),
                            isMulti: true,
                            state_name: "is_active",
                            label: t("Status")
                        },
                    ]} />
                <DrawerComponent
                    anchor={size?.width >= 600 ? "right" : "bottom"}
                    DrawerClass={classes.add_drawer}
                    open={is_add_form_open}
                    onClose={() => set_is_add_form_open(false)}
                    toggleDrawer={() => set_is_add_form_open(false)}
                    component={
                        <>
                            <Box className={classes.add_drawer_header}>
                                <Typography className={classes.add_drawer_header_title}>
                                    {add_form_state?.form_type === "add"
                                        ? t("Create Utility Mapping")
                                        : add_form_state?.form_type === "edit"
                                            ? t("Edit Utility Mapping")
                                            : add_form_state?.form_type === "view"
                                                ? t("View Utility Mapping")
                                                : t("Create Utility Mapping")
                                    }
                                </Typography>
                                <IconButton onClick={() => set_is_add_form_open(false)}
                                    className={classes.add_drawer_close_button}>
                                    <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                                </IconButton>
                            </Box>
                            <Box className={add_form_state?.form_type === "view"
                                ? classes.add_drawer_body_view
                                : classes.add_drawer_body}>
                                <Label isRequired label={t("Property And Units")} />
                                <Stack className={classes.add_drawer_property_unit_card}
                                    direction={"row"} p={"8px"} justifyContent={"start"}
                                    alignItems={"center"} columnGap={"12px"}>
                                    <img className={classes.add_drawer_property_unit_card_logo}
                                        src={logo} alt="" />
                                    <Stack direction={"column"} justifyContent={"center"}
                                        alignItems={"start"} rowGap={"2px"}>
                                        <Stack direction={"row"} justifyContent={"start"}
                                            alignItems={"center"} columnGap={"8px"}
                                            divider={<Divider orientation='vertical' />}>
                                            {unit_name &&
                                                <Typography
                                                    className={classes.add_drawer_property_unit_card_unit_name}>
                                                    {unit_name}
                                                </Typography>
                                            }
                                            {unit_no &&
                                                <Typography
                                                    className={classes.add_drawer_property_unit_card_unit_no}>
                                                    {unit_no}
                                                </Typography>
                                            }
                                        </Stack>
                                        {property_location &&
                                            <Typography
                                                className={classes.add_drawer_property_unit_card_property_location}>
                                                {property_location}
                                            </Typography>
                                        }
                                    </Stack>
                                </Stack>
                                <Box height={16} />
                                <SelectBox
                                    isRequired
                                    isReadOnly={add_form_state?.form_type === "view"}
                                    label={t("Utility Category")}
                                    placeholder={t("Select Utility Category")}
                                    value={add_form_state?.utility_category ?? ""}
                                    onChange={(value) => updateAddFormState({ key: "utility_category", value })}
                                    isPaginate
                                    debounceTimeout={800}
                                    loadOptions={(search, array, handleLoading) =>
                                        loadOptionsApis(
                                            BackendRoutes?.utilities_category_list?.slice(1),
                                            { is_active: [true] },
                                            search, array, handleLoading, "data", {},
                                            constructUtilityCategoryDropdownResponse,
                                        )}
                                    isError={add_form_state?.error?.utility_category?.length > 0}
                                    errorMessage={add_form_state?.error?.utility_category} />
                                <Box height={16} />
                                <SelectBox
                                    key={JSON.stringify(add_form_state?.utility_category)}
                                    isRequired
                                    isReadOnly={add_form_state?.form_type === "view" || !add_form_state?.utility_category?.value > 0}
                                    label={t("Utility Master")}
                                    placeholder={t("Select Utility Master")}
                                    value={add_form_state?.utility_master ?? ""}
                                    onChange={(value) => updateAddFormState({ key: "utility_master", value })}
                                    isPaginate
                                    debounceTimeout={800}
                                    loadOptions={(search, array, handleLoading) =>
                                        loadOptionsApis(
                                            BackendRoutes?.utilities_utility_master_list?.slice(1),
                                            {
                                                is_active: [true],
                                                company_id: selected_company?.value,
                                                category_ids: [add_form_state?.utility_category?.value],
                                            },
                                            search, array, handleLoading, "data", {},
                                            constructUtilityMasterDropdownResponse,
                                        )}
                                    isError={add_form_state?.error?.utility_master?.length > 0}
                                    errorMessage={add_form_state?.error?.utility_master} />
                                <Box height={16} />
                                {(add_form_state?.id ||
                                    add_form_state?.utility_master?.value) &&
                                    <>
                                        <Stack className={classes.add_drawer_utility_detail_card}
                                            direction={"row"} p={"12px"} justifyContent={"space-between"}
                                            alignItems={"start"} columnGap={"12px"}>
                                            <Stack direction={"column"} rowGap={"12px"}
                                                justifyContent={"space-between"} alignItems={"start"}>
                                                {(add_form_state?.unit_utility_id ||
                                                    add_form_state?.provider ||
                                                    add_form_state?.service_provider_name) &&
                                                    <Stack direction={"row"} columnGap={"10px"}
                                                        justifyContent={"start"} alignItems={"center"}>
                                                        <Box className={classes.add_drawer_utility_icon} p={"4px"} >
                                                            <UtilityIcon />
                                                        </Box>
                                                        <Stack direction={"column"} rowGap={"5px"}
                                                            justifyContent={"space-between"} alignItems={"start"}>
                                                            {add_form_state?.unit_utility_id &&
                                                                <Typography
                                                                    className={classes.add_drawer_unit_utility_id}>
                                                                    {add_form_state?.unit_utility_id}
                                                                </Typography>
                                                            }
                                                            {(add_form_state?.provider ||
                                                                add_form_state?.service_provider_name) &&
                                                                <Stack direction={"row"} columnGap={"8px"}
                                                                    justifyContent={"start"} alignItems={"center"}
                                                                    divider={<Box className={classes.add_drawer_period} />}>
                                                                    {add_form_state?.provider &&
                                                                        <Typography
                                                                            className={classes.add_drawer_provider}>
                                                                            {add_form_state?.provider}
                                                                        </Typography>
                                                                    }
                                                                    {add_form_state?.service_provider_name &&
                                                                        <Typography
                                                                            className={classes.add_drawer_service_provider_name}>
                                                                            {add_form_state?.service_provider_name}
                                                                        </Typography>
                                                                    }
                                                                </Stack>
                                                            }
                                                        </Stack>
                                                    </Stack>
                                                }
                                                {(add_form_state?.uom_name ||
                                                    add_form_state?.price_per_uom) &&
                                                    <Stack direction={"column"} rowGap={"10px"}
                                                        justifyContent={"space-between"} alignItems={"start"}>
                                                        {add_form_state?.uom_name &&
                                                            <Typography
                                                                className={classes.add_drawer_uom_label}>
                                                                {`${t("UOM")} : `}
                                                                <Typography
                                                                    className={classes.add_drawer_uom_value}>
                                                                    {add_form_state?.uom_name}
                                                                </Typography>
                                                            </Typography>
                                                        }
                                                        {add_form_state?.price_per_uom &&
                                                            <Typography
                                                                className={classes.add_drawer_price_per_uom_label}>
                                                                {`${t("Price per UOM")} : `}
                                                                <Typography
                                                                    className={classes.add_drawer_price_per_uom_value}>
                                                                    {add_form_state?.price_per_uom}
                                                                </Typography>
                                                            </Typography>
                                                        }
                                                    </Stack>
                                                }
                                            </Stack>
                                            {add_form_state?.id &&
                                                <QRCode size={100}
                                                    value={add_form_state?.id} />
                                            }
                                        </Stack>
                                        <Box height={16} />
                                    </>
                                }
                                <TextBox
                                    isReadonly={add_form_state?.form_type === "view"}
                                    multiline
                                    label={t("Description")}
                                    placeholder={t("Enter Description")}
                                    value={add_form_state?.description ?? ""}
                                    onChange={(e) => updateAddFormState({ key: "description", value: e?.target?.value })}
                                    isError={add_form_state?.error?.description?.length > 0}
                                    errorMessage={add_form_state?.error?.description} />
                                <Box height={16} />
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={"16px"}>
                                    <Box width={{ xs: "100%", sm: "50%" }}>
                                        <TextBox
                                            isrequired
                                            isReadonly={add_form_state?.form_type === "view"}
                                            label={t("Box/ODU Number")}
                                            placeholder={t("Enter Box/ODU Number")}
                                            value={add_form_state?.box_odu_no ?? ""}
                                            onChange={(e) => updateAddFormState({ key: "box_odu_no", value: e?.target?.value })}
                                            isError={add_form_state?.error?.box_odu_no?.length > 0}
                                            errorMessage={add_form_state?.error?.box_odu_no} />
                                    </Box>
                                    <Box width={{ xs: "100%", sm: "50%" }}>
                                        <TextBox
                                            isrequired
                                            isReadonly={add_form_state?.form_type === "view"}
                                            label={t("Meter Serial Number")}
                                            placeholder={t("Enter Meter Serial Number")}
                                            value={add_form_state?.meter_serial_no ?? ""}
                                            onChange={(e) => updateAddFormState({ key: "meter_serial_no", value: e?.target?.value })}
                                            isError={add_form_state?.error?.meter_serial_no?.length > 0}
                                            errorMessage={add_form_state?.error?.meter_serial_no} />
                                    </Box>
                                </Stack>
                                <Box height={16} />
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={"16px"}>
                                    <Box width={{ xs: "100%", sm: "50%" }}>
                                        <TextBox
                                            isrequired
                                            isReadonly={add_form_state?.form_type === "view"}
                                            label={t("Subscription Number")}
                                            placeholder={t("Enter Subscription Number")}
                                            value={add_form_state?.subscription_no ?? ""}
                                            onChange={(e) => updateAddFormState({ key: "subscription_no", value: e?.target?.value })}
                                            isError={add_form_state?.error?.subscription_no?.length > 0}
                                            errorMessage={add_form_state?.error?.subscription_no} />
                                    </Box>
                                    <Box width={{ xs: "100%", sm: "50%" }}>
                                        <MobileNumberInputComponent
                                            isRequired
                                            isReadonly={add_form_state?.form_type === "view"}
                                            label={t("Mobile Number")}
                                            placeholder={t("Mobile Number")}
                                            value={add_form_state?.mobile}
                                            handleChange={(value) => updateAddFormState({ key: "mobile", value: value })}
                                            onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            isError={add_form_state?.error?.subscription_no?.length > 0}
                                            errorMessage={add_form_state?.error?.subscription_no} />
                                    </Box>
                                </Stack>
                                <Box height={16} />
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={"16px"}>
                                    <Box width={{ xs: "100%", sm: "50%" }}>
                                        <DatePickerTimeNew
                                            isRequired
                                            isReadonly={add_form_state?.form_type === "view"}
                                            label={t("Inspection Date & Time")}
                                            placeholder={t("Select Date & Time")}
                                            dateFormat={"dd MMM yyyy, hh:mm a"}
                                            isTime
                                            maxDate={new Date()}
                                            value={add_form_state?.inspection_date}
                                            onChange={(value) => updateAddFormState({ key: "inspection_date", value: value })}
                                            isError={add_form_state?.error?.inspection_date?.length > 0}
                                            errorMessage={add_form_state?.error?.inspection_date} />
                                    </Box>
                                    <Box width={{ xs: "100%", sm: "50%" }}>
                                        <TextBox
                                            isrequired
                                            isReadonly={add_form_state?.form_type === "view"}
                                            type={"number"}
                                            label={t("Inspection Reading")}
                                            placeholder={t("Enter Inspection Reading")}
                                            value={add_form_state?.inspection_reading ?? ""}
                                            onChange={(e) => updateAddFormState({ key: "inspection_reading", value: e?.target?.value })}
                                            isError={add_form_state?.error?.inspection_reading?.length > 0}
                                            errorMessage={add_form_state?.error?.inspection_reading} />
                                    </Box>
                                </Stack>
                                <Box height={16} />
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={"16px"}>
                                    <Box width={{ xs: "100%", sm: "50%" }}>
                                        <DatePickerTimeNew
                                            isRequired
                                            isReadonly={add_form_state?.form_type === "view"}
                                            label={t("Last Reading Date & Time")}
                                            placeholder={t("Select Date & Time")}
                                            dateFormat={"dd MMM yyyy, hh:mm a"}
                                            isTime
                                            maxDate={new Date()}
                                            value={add_form_state?.last_reading_date}
                                            onChange={(value) => updateAddFormState({ key: "last_reading_date", value: value })}
                                            isError={add_form_state?.error?.last_reading_date?.length > 0}
                                            errorMessage={add_form_state?.error?.last_reading_date} />
                                    </Box>
                                    <Box width={{ xs: "100%", sm: "50%" }}>
                                        <TextBox
                                            isrequired
                                            isReadonly={add_form_state?.form_type === "view"}
                                            type={"number"}
                                            label={t("Last Reading")}
                                            placeholder={t("Enter Last Reading")}
                                            value={add_form_state?.last_reading ?? ""}
                                            onChange={(e) => updateAddFormState({ key: "last_reading", value: e?.target?.value })}
                                            isError={add_form_state?.error?.last_reading?.length > 0}
                                            errorMessage={add_form_state?.error?.last_reading} />
                                    </Box>
                                </Stack>
                                <Box height={16} />
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={"16px"}>
                                    <Box width={{ xs: "100%", sm: "50%" }}>
                                        <DatePickerTimeNew
                                            isRequired
                                            isReadonly={add_form_state?.form_type === "view"}
                                            label={t("Current Reading Date & Time")}
                                            placeholder={t("Select Date & Time")}
                                            dateFormat={"dd MMM yyyy, hh:mm a"}
                                            isTime
                                            maxDate={new Date()}
                                            value={add_form_state?.current_reading_date}
                                            onChange={(value) => updateAddFormState({ key: "current_reading_date", value: value })}
                                            isError={add_form_state?.error?.current_reading_date?.length > 0}
                                            errorMessage={add_form_state?.error?.current_reading_date} />
                                    </Box>
                                    <Box width={{ xs: "100%", sm: "50%" }}>
                                        <TextBox
                                            isrequired
                                            isReadonly={add_form_state?.form_type === "view"}
                                            type={"number"}
                                            label={t("Current Reading")}
                                            placeholder={t("Enter Current Reading")}
                                            value={add_form_state?.current_reading ?? ""}
                                            onChange={(e) => updateAddFormState({ key: "current_reading", value: e?.target?.value })}
                                            isError={add_form_state?.error?.current_reading?.length > 0}
                                            errorMessage={add_form_state?.error?.current_reading} />
                                    </Box>
                                </Stack>
                                <Box height={16} />
                                <Label isRequired label={t("Status")} />
                                <Box className={classes.add_drawer_button_container}>
                                    {activeOptionsList?.map((_) => {
                                        return <Button
                                            className={_?.value === add_form_state?.is_active
                                                ? classes.add_drawer_button_selected
                                                : classes.add_drawer_button_unselected
                                            }
                                            onClick={() => (add_form_state?.form_type === "add" ||
                                                add_form_state?.form_type === "edit")
                                                ? updateAddFormState({ key: "is_active", value: _?.value })
                                                : false
                                            }>
                                            {t(_?.label)}
                                        </Button>
                                    })}
                                </Box>
                                <Box height={16} />
                            </Box>
                            {(add_form_state?.form_type === "add" || add_form_state?.form_type === "edit") &&
                                <Box className={classes.add_drawer_footer}>
                                    {add_form_state?.form_type === "edit" &&
                                        <Button
                                            fullWidth
                                            className={classes.add_drawer_footer_close_button}
                                            onClick={() => set_is_add_form_open(false)}>
                                            {t("Cancel")}
                                        </Button>
                                    }
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        className={classes.add_drawer_footer_button}
                                        disabled={is_button_disable}
                                        onClick={() => handleCreateEdit({ final_add_state: add_form_state })}>
                                        {add_form_state?.form_type === "add"
                                            ? t("Create")
                                            : t("Save")
                                        }
                                    </Button>
                                </Box>}
                        </>}
                />
            </Box>
        </Box>
    );
}
