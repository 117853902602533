import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../../utils";
export const LeadStylesParent = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: 99,
    right: '70px',
    display: "flex",
    alignItems: "center",
    background: '#5078E1',
    boxShadow: "none",
    "&:hover": {
      background: '#5078E1',
    },
  },
  fabArabic: {
    position: "fixed",
    bottom: 99,
    left: '54px',
    display: "flex",
    alignItems: "center",
    background: '#5078E1',
    boxShadow: "none",
    "&:hover": {
      background: '#5078E1',
    },
  },
  sumbitidea: {
    color: "#fff",
    marginLeft: "10px",
    fontSize: "0.875rem",
  },
  leadtransfersbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",
    cursor: "pointer",
    backgroundColor: "#fff",
    margin: "10px 0px",
    borderRadius: "4px",
  },
  options: {
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: Bold,
  },
  menuList: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 6px #0717411F",
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "white",
    },
  },
  leadtransfersbox1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",
    cursor: "pointer",
    backgroundColor: "#fff",
    margin: "2px 0px",
    borderRadius: "4px",
  },
  bottombtn: {
    padding: "12px 19px",
    backgroundColor: "#fff",
    // boxShadow: "0px 0px 16px #00000014",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
    alignItems:'center'
  },	
  bottombtn1: {
    padding: "12px 19px",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
    alignItems:'center',
    backgroundColor:"#F2F4F7"
  },
   drawerTitle:{
    color:'#091B29',
    fontSize:"0.875rem",
    fontFamily:ExtraBold
  },
  formRoot: {
    backgroundColor: "white",
    width: '508px'
  },
  next: {
    fontFamily: SemiBold,
    color: "#fff",
    backgroundColor: "#5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
    height:"40px",
    width:"100%"
  },
}));

export const SummaryUseStyles = makeStyles((theme) => ({
  root: {},
  section: {
    padding: "16px",
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #0717411F",
  },
  header: {
    color: "#1C1C1C",
    fontFamily: Bold,
    fontSize: '0.875rem',
  },
  title: {
    color: "#98A0AC",
    fontFamily: SemiBold,
    fontSize: '0.875rem',
  },
  description: {
    color: "#091B29",
    fontFamily: SemiBold,
    fontSize: '0.875rem',
    wordWrap: "break-word",
  }
}));

export const EditLeadUseStyles = makeStyles((theme) => ({
  root: {},
  section: {
    padding: "16px",
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #0717411F",
  },
  header: {
    color: "#1C1C1C",
    fontFamily: Bold,
    fontSize: '0.875rem',
  },
  field_content: {
    height: ({ size }) => size?.height - 212,
    overflow: "overlay",
  },
  bottom_button_box: {
    padding: "10px",
    display: "flex",
    borderTop: "1px solid #0717411F",
    justifyContent: "end",
  },
  cancel_button: {
    border: "1px solid #5078E1",
    padding: "6px 10px",
    fontFamily: SemiBold,
    color: "#5078E1",
    backgroundColor: '#fff',
    "&:hover": {
      backgroundColor: '#fff',
    },
  },
  save_button: {
    border: "1px solid #5078E1",
    padding: "6px 10px",
    fontFamily: SemiBold,
    color: "#fff",
    backgroundColor: '#5078E1',
    "&:hover": {
      backgroundColor: '#5078E1',
    },
  },
  next: {
    fontFamily: SemiBold,
    color: "#fff",
    backgroundColor: "#5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
    height:"40px"
  },
 bottombtn: {
    padding: "12px 19px",
    backgroundColor: "#fff",
    // boxShadow: "0px 0px 16px #00000014",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
    alignItems:'center'
  },	
  bottombtn1: {
    padding: "12px 19px",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
    alignItems:'center',
    backgroundColor:"#F2F4F7"
  },
   drawerTitle:{
    color:'#091B29',
    fontSize: '0.875rem',
    fontFamily:ExtraBold
  },
  formRoot: {
    backgroundColor: "white",
    width: '508px'
  },
}));