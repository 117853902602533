import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import ServiceLevelCalendarIcon from "../../../assets/serviceLevelCalendarIcon"
import ServiceLevelCompanyIcon from "../../../assets/serviceLevelCompanyIcon"
import ServiceLevelTimeIcon from "../../../assets/serviceLevelTimeIcon"
import { useStyles } from "./style"
import { DatePickerNew } from "../../../components"
import { AlertProps, ExtraBold, NetWorkCallMethods, remCalc } from "../../../utils"
import { NetworkCall } from "../../../networkcall"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"

export const ServiceLevelAgreement = ({ state = {}, val = {}, t = () => false, reload = () => false }) => {
    const classes = useStyles()
    const [data, setData] = React.useState({
        actual_date: val?.actual_close_date ? new Date(val?.actual_close_date) : "",
        planned_date: val?.planned_close_date ? new Date(val?.planned_close_date) : ""
    })
    const alert = React.useContext(AlertContext);
    const ConstructData = [
        {
            title: t("Date Opened On"),
            icon: <ServiceLevelCalendarIcon />,
            value: moment(val?.created_at).format("DD MMM YYYY , HH:mm a"),
            subValue: null
        },
        {
            title: t("Acknowledged On"),
            icon: <ServiceLevelTimeIcon />,
            value: val?.acknowleged_time_diff,
            subValue: val?.acknowleged_date ? moment(val?.acknowleged_date).format("DD MMM YYYY , HH:mm a") : null
        },
        {
            title: t("1st Response"),
            icon: <ServiceLevelTimeIcon />,
            value: val?.first_response_time_diff,
            subValue: val?.first_response ? moment(val?.first_response).format("DD MMM YYYY , HH:mm a") : null
        },
        {
            title: t("Approval to start"),
            icon: <ServiceLevelTimeIcon />,
            value: val?.approval_time_diff,
            subValue: val?.approval_date ? moment(val?.approval_date).format("DD MMM YYYY , HH:mm a") : null
        },
        {
            title: t("Closure Date"),
            icon: <ServiceLevelCalendarIcon />,
            value: val?.closed_at !== null ? moment(val?.closed_on).format("DD MMM YYYY , HH:mm a") : null,
            subValue: null
        },
        {
            title: t("Closed By"),
            icon: <ServiceLevelCalendarIcon />,
            value: val?.closed_by ?? "-",
            subValue: null
        },
        {
            title: t("Property and Unit information"),
            icon: <ServiceLevelCompanyIcon />,
            value: val?.property_name ? `${val?.property_name}-${val?.unit_name}` : "-",
            subValue: null
        },
        {
            title: t("PLANNED DATE"),
            // icon: <ServiceLevelCalendarIcon />,
            comp: "calender",
            value: data?.planned_date,
            type: "planned_date",
            placeholder: t("Select Planned Date"),
            disable: false
        },
        {
            title: t("ACTUAL DATE"),
            // icon: <ServiceLevelCalendarIcon />,
            comp: "calender",
            value: data?.actual_date,
            type: "actual_date",
            placeholder: t("Select Actual Date"),
            disable: val?.type !== "Closed"
        },
    ]

    const handleDateChange = (type, value) => {
        const payload = {
            id: val?.id,
            type: state?.main?.request === "Maintenance" ? "Maintenance" : "General",
            planned_close_date: type === "planned_date" ? moment(value).format("YYYY-MM-DD") : undefined,
            actual_close_date: type === "actual_date" ? moment(value).format("YYYY-MM-DD") : undefined
        }
        NetworkCall(
            `${config.api_url}/request/update_request_date`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setData({
                planned_date: type === "planned_date" ? value : data?.planned_date,
                actual_date: type === "actual_date" ? value : data?.actual_date
            })
            reload()
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: type === "planned_date" ? t("Planned Date Updated") : t("Actual Date Updated"),
            });
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            });
        })
    }

    const ServiceLevelCard = ({ val = {} }) => {

        return (
            <Box className={classes.serviceLevelBox}>
                <Box display="flex" justifyContent={"space-between"}>
                    <Typography className={classes.serviceLevelTitle}>{val?.title}</Typography>
                    <Box>{val?.icon}</Box>
                </Box>
                <Box>
                    {
                        val?.comp ?
                            <>
                                <DatePickerNew
                                    label={""}
                                    placeholder={val?.placeholder}
                                    handleChange={(value) => handleDateChange(val?.type, value)}
                                    value={val?.value}
                                    noBorder
                                    height="24px"
                                    padding="0px"
                                    fontFamily={ExtraBold}
                                    fontSize={remCalc(16)}
                                    isReadonly={val?.disable}
                                />
                            </>
                            :
                            <>
                                {val?.subValue !== null ?
                                    <Stack direction="row" alignItems={"center"} spacing={1}>

                                        <Typography className={classes.serviceLevelValues} noWrap>&#x202a;{val?.value}&#x202c;</Typography>
                                        <Typography className={classes.serviceLevelsubValue}>&#x202a;{`(${val?.subValue})`}&#x202c;</Typography>
                                    </Stack>
                                    :
                                    val?.title === "Closed By" ?
                                        <Typography className={classes.serviceLevelValues} noWrap>{val?.value ?? "-"}</Typography> :
                                        <Typography className={classes.serviceLevelValues} noWrap>&#x202a;{val?.value ?? "-"}&#x202c;</Typography>
                                }
                            </>
                    }
                </Box>
            </Box>
        )
    }
    return (
        <Box className={classes.serviceLevelCard}>
            <Box p={2}>
                <Typography className={classes.serviceLevelValues}>{t("Key Performance Indicators")}</Typography>
            </Box>
            <Divider />
            <Box p={2}>
                <Grid container spacing={2}>
                    {ConstructData?.map((val) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <ServiceLevelCard val={val} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </Box>
    )
}