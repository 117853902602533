import { CHECK_PRIMARY_COMPONENT } from "../../graphql/queries";

export const RevenueTypeList = [
    { value: "Lease", label: "Lease" },
    { value: "Sale", label: "Sale" },
    { value: "Maintenance", label: "Maintenance" }
];

export const FormQuestionSwitchList1 = [
    { question: "Enable Tax?", state: "isTaxable" },
    { question: "Enable Price Override Functionality?", state: "isOverride" },
    { question: "Enable Reservation Amount Calculation?", state: "isReserve" },
    { question: "Enable Auto Security Deposit Calculation?", state: "isSecurity" }
];

export const FormQuestionSwitchList2 = [
    { question: "Enable Tax?", state: "isTaxable" },
];

export const ChargeableList = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
];

export const ValueBasisList1 = [
    { value: "Amount", label: "Fixed Amount" },
    { value: "per UOM", label: "UOM" },
    { value: "percentage", label: "Percentage" }
];

export const ValueBasisList2 = [
    ValueBasisList1[0],
    { value: "Rental Value", label: "Rental Value" },
    ValueBasisList1[2]
];

export const PaymentPeriodList = [
    { value: "Monthly", label: "Monthly" },
    { value: "Daily", label: "Daily" },
    { value: "Total", label: "Total" }
];

export const AddQuestions = [
    {// index: 0
        id: 1,
        serial: "01",
        question: "Primary",
        category: "Primary Pricing Component",
        categoryType: "Primary",
        info: "Base rent or monthly rental amount. you can have only one primary pricing component per property.",
        primaryColor: "#B3776D",
        secondaryColor: "#FEEAEA80",
        isPrimary: true,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: false,
        is_wallet: false,
        is_wallet_item: false,
        is_late_fee: false,
        is_commission_payment: false,
        revenueType: ["Manage", "Lease", "Sale"],
    },
    {// index: 1
        id: 2,
        serial: "02",
        question: "Secondary",
        category: "Secondary Components",
        categoryType: "Secondary",
        info: "Identify and configure pricing for maintenance and other services",
        primaryColor: "#896DB3",
        secondaryColor: "#EDE4FE80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
        is_wallet: false,
        is_wallet_item: false,
        is_late_fee: false,
        is_commission_payment: false,
        revenueType: ["Manage", "Lease", "Sale"],
    },
    {// index: 2
        id: 3,
        serial: "03",
        question: "One Time Charges",
        category: "One Time Charges",
        categoryType: "One Time",
        info: "Configure pricing which has to be calculated as one time charges",
        primaryColor: "#6DAFB3",
        secondaryColor: "#DBF0F180",
        isPrimary: false,
        isOneTime: true,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[2],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
        is_wallet: false,
        is_wallet_item: false,
        is_late_fee: false,
        is_commission_payment: false,
        revenueType: ["Manage", "Lease", "Sale"],
    },
    {// index: 3
        id: 4,
        serial: "04",
        question: "Refundables",
        category: "Refundables Price Component",
        categoryType: "Refundable",
        info: "Configure pricing which has to be calculated as refundable amount",
        primaryColor: "#6D80B3",
        secondaryColor: "#E4EDFF80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: true,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[2],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: false,
        is_wallet: false,
        is_wallet_item: false,
        is_late_fee: false,
        is_commission_payment: false,
        revenueType: ["Manage", "Lease", "Sale"],
    },
    {// index: 4
        id: 5,
        serial: "05",
        question: "Inventory Items",
        category: "Inventory Items Component",
        categoryType: "Item Based",
        info: "Configure pricing for items like services, materials etc.",
        primaryColor: "#B3A16D",
        secondaryColor: "#FFFAD880",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: true,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: false,
        is_wallet: false,
        is_wallet_item: false,
        is_late_fee: false,
        is_commission_payment: false,
        revenueType: ["Manage", "Lease", "Sale"],
    },
    {// index: 5
        id: 6,
        serial: "06",
        question: "Parking Slot",
        category: "Parking Slot Component",
        categoryType: "Parking Slot",
        info: "Configure pricing for items that has to be calculated for parking slot amount.",
        primaryColor: "#B3776D",
        secondaryColor: "#FEEAEA80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: true,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
        is_wallet: false,
        is_wallet_item: false,
        is_late_fee: false,
        is_commission_payment: false,
        revenueType: ["Manage", "Lease", "Sale"],
    },
    {// index: 6
        id: 7,
        serial: "07",
        question: "Wallet Points",
        category: "Wallet Points Component",
        categoryType: "Wallet Points",
        info: "Wallet Points.",
        primaryColor: "#896DB3",
        secondaryColor: "#EDE4FE80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[1],
        valueBasis: ValueBasisList1[0],
        isChargeable: false,
        is_wallet: true,
        is_wallet_item: false,
        is_late_fee: false,
        is_commission_payment: false,
        revenueType: ["Manage", "Lease"],
    },
    {// index: 7
        id: 8,
        serial: "08",
        question: "Wallet Item",
        category: "Wallet Item Component",
        categoryType: "Wallet Item",
        info: "Wallet Item.",
        primaryColor: "#B3A16D",
        secondaryColor: "#FFFAD880",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[1],
        valueBasis: ValueBasisList1[0],
        isChargeable: false,
        is_wallet: false,
        is_wallet_item: true,
        is_late_fee: false,
        is_commission_payment: false,
        revenueType: ["Manage", "Lease"],
    },
    {// index: 8
        id: 9,
        serial: "09",
        question: "Late Fees & Penalty",
        category: "Late Fees & Penalty Component",
        categoryType: "Late Fees & Penalty",
        info: "Late Fees & Penalty.",
        primaryColor: "#6DAFB3",
        secondaryColor: "#DBF0F180",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[2],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
        is_wallet: false,
        is_wallet_item: false,
        is_late_fee: true,
        is_commission_payment: false,
        is_active: false,
        revenueType: ["Manage", "Lease", "Sale"],
    },
    {// index: 9
        id: 10,
        serial: "09",
        question: "Commission & Payment",
        category: "Commission & Payment Component",
        categoryType: "Commission & Payment",
        info: "Commission & Payment",
        primaryColor: "#6D80B3",
        secondaryColor: "#E4EDFF80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[2],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
        is_wallet: false,
        is_wallet_item: false,
        is_late_fee: false,
        is_commission_payment: true,
        revenueType: ["Manage", "Lease", "Sale"],
    },
];

export const findPrimaryComponent = async ({ client = "", unit_id = "" }) => {
    let is_primary_component = false;
    await client.query({
        query: CHECK_PRIMARY_COMPONENT,
        fetchPolicy: "network-only",
        variables: { unit_id }
    }).then((res) => {
        is_primary_component = res?.data?.count?.[0]?.count > 0
    }).catch((err) => { console.log(err) });
    return is_primary_component
}
export const AddQuestionsComponent = [
    {// index: 0
        id: 1,
        serial: "01",
        question: "Primary",
        category: "Primary Pricing Component",
        categoryType: "Primary",
        info: "Base rent or monthly rental amount. you can have only one primary pricing component per property.",
        primaryColor: "#B3776D",
        secondaryColor: "#FEEAEA80",
        isPrimary: true,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: false,
        is_wallet: false,
        is_late_fee: false,
        is_commission_payment: false,
    },
    {// index: 1
        id: 2,
        serial: "02",
        question: "Secondary",
        category: "Secondary Components",
        categoryType: "Secondary",
        info: "Identify and configure pricing for maintenance and other services",
        primaryColor: "#896DB3",
        secondaryColor: "#EDE4FE80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
        is_wallet: false,
        is_late_fee: false,
        is_commission_payment: false,
    },
    {// index: 2
        id: 3,
        serial: "03",
        question: "One Time Charges",
        category: "One Time Charges",
        categoryType: "One Time",
        info: "Configure pricing which has to be calculated as one time charges",
        primaryColor: "#6DAFB3",
        secondaryColor: "#DBF0F180",
        isPrimary: false,
        isOneTime: true,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[2],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
        is_wallet: false,
        is_late_fee: false,
        is_commission_payment: false,
    },
    {// index: 3
        id: 4,
        serial: "04",
        question: "Refundables",
        category: "Refundables Price Component",
        categoryType: "Refundable",
        info: "Configure pricing which has to be calculated as refundable amount",
        primaryColor: "#6D80B3",
        secondaryColor: "#E4EDFF80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: true,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[2],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: false,
        is_wallet: false,
        is_late_fee: false,
        is_commission_payment: false,
    },
    {// index: 4
        id: 5,
        serial: "05",
        question: "Inventory Items",
        category: "Inventory Items Component",
        categoryType: "Item Based",
        info: "Configure pricing for items like services, materials etc.",
        primaryColor: "#B3A16D",
        secondaryColor: "#FFFAD880",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: true,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: false,
        is_active: false,
        is_wallet: false,
        is_late_fee: false,
        is_commission_payment: false,
    },
    {// index: 5
        id: 6,
        serial: "05",
        question: "Parking Slot",
        category: "Parking Slot Component",
        categoryType: "Parking Slot",
        info: "Configure pricing for items that has to be calculated for parking slot amount.",
        primaryColor: "#B3776D",
        secondaryColor: "#FEEAEA80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: true,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
        is_wallet: false,
        is_late_fee: false,
        is_commission_payment: false,
    },
    {// index: 6
        id: 7,
        serial: "06",
        question: "Wallet Points",
        category: "Wallet Points Component",
        categoryType: "Wallet Points",
        info: "Wallet Points",
        primaryColor: "#896DB3",
        secondaryColor: "#EDE4FE80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[0],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
        is_wallet: true,
        is_late_fee: false,
        is_commission_payment: false,
    },
    {// index: 7
        id: 8,
        serial: "07",
        question: "Late Fees & Penalty",
        category: "Late Fees & Penalty Component",
        categoryType: "Late Fees & Penalty",
        info: "Late Fees & Penalty",
        primaryColor: "#6DAFB3",
        secondaryColor: "#DBF0F180",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[2],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
        is_wallet: false,
        is_late_fee: true,
        is_commission_payment: false,
        is_active: false,
    },
    {// index: 8
        id: 9,
        serial: "07",
        question: "Commission & Payment",
        category: "Commission & Payment Component",
        categoryType: "Commission & Payment",
        info: "Commission & Payment",
        primaryColor: "#6D80B3",
        secondaryColor: "#E4EDFF80",
        isPrimary: false,
        isOneTime: false,
        isRefundable: false,
        isQuantity: false,
        isParking: false,
        isTaxable: false,
        isOverride: false,
        isReserve: false,
        isSecurity: false,
        paymentPeriod: PaymentPeriodList[2],
        chargeable: ChargeableList[0],
        valueBasis: ValueBasisList1[0],
        isChargeable: true,
        is_wallet: false,
        is_late_fee: false,
        is_commission_payment: true,
    },
];

export const FormQuestionSwitchList2Category = [
    AddQuestionsComponent[2].category,
    AddQuestionsComponent[3].category,
    AddQuestionsComponent[7].category,
    AddQuestionsComponent[8].category,
]

export const DisableFormQuestionSwitchListCategory = [
    AddQuestionsComponent[6].category,
]

export const ComponentTypeOptions = [
    {
        label: "Primary",
        value: "primary",
        filter_values: {
            primary: [true],
            is_onetime: [false],
            refundable: [false],
            is_parking: [false],
            is_wallet: [false],
            is_late_fee: [false],
            is_quantity: [false],
            is_commission_payment: [false],
        }
    },
    {
        label: "Secondary",
        value: "secondary",
        filter_values: {
            primary: [false],
            is_onetime: [false],
            refundable: [false],
            is_parking: [false],
            is_wallet: [false],
            is_late_fee: [false],
            is_quantity: [false],
            is_commission_payment: [false],
        },
    },
    {
        label: "One Time Charges",
        value: "one_time_charges",
        filter_values: {
            primary: [false],
            is_onetime: [true],
            refundable: [false],
            is_parking: [false],
            is_wallet: [false],
            is_late_fee: [false],
            is_quantity: [false],
            is_commission_payment: [false],
        },
    },
    {
        label: "Refundables",
        value: "refundables",

        filter_values: {
            primary: [false],
            is_onetime: [false],
            refundable: [true],
            is_parking: [false],
            is_wallet: [false],
            is_late_fee: [false],
            is_quantity: [false],
            is_commission_payment: [false],
        },
    },
    {
        label: "Parking Slot",
        value: "parking_slot",

        filter_values: {
            primary: [false],
            is_onetime: [false],
            refundable: [false],
            is_parking: [true],
            is_wallet: [false],
            is_late_fee: [false],
            is_quantity: [false],
            is_commission_payment: [false],
        },
    },
    {
        label: "Wallet Points",
        value: "wallet_points",

        filter_values: {
            primary: [false],
            is_onetime: [false],
            refundable: [false],
            is_parking: [false],
            is_wallet: [true],
            is_late_fee: [false],
            is_quantity: [false],
            is_commission_payment: [false],
        },
    },
    // {
    //     label: "Late Fees & Penalty",
    //     value: "late_fees_penalty",

    //     filter_values: {
    //         primary: [false],
    //         is_onetime: [false],
    //         refundable: [false],
    //         is_parking: [false],
    //         is_wallet: [false],
    //         is_late_fee: [true],
    //         is_quantity: [false],
    //         is_commission_payment: [false],
    //     },
    // },
    {
        label: "Commission & Payment",
        value: "commission_payment",

        filter_values: {
            primary: [false],
            is_onetime: [false],
            refundable: [false],
            is_parking: [false],
            is_wallet: [false],
            is_late_fee: [false],
            is_quantity: [false],
            is_commission_payment: [true],
        },
    },
];