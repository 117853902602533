/* eslint-disable no-unused-vars */
import { DomesticHelpdrawer, DomesticHelpHeading, DomesticHelpPath, DomesticHelpType, ServiceProviderdrawer, ServiceProviderHeading, ServiceProviderPath, ServiceProviderType, Vendordrawer, VendorHeading, VendorPath, VendorType } from ".";
import { GET_CURRENCY, GET_DOMESTIC_HELPER_MASTER, GET_GATE_MASTER, GET_IDENTIFICATION_MASTER_OPTION, GET_PARKING_MASTER, GET_PROFESSION_MASTER_OPTIONS, GET_SERVICE_PROVIDERS_MASTER, GET_VENDOR_MASTER } from "../../graphql/securityManager/queries";
import {
    EDIT_DOMESTIC_HELPER, EDIT_GATE_MASTER,
    EDIT_PARKING_MASTER, EDIT_SERVICE_PROVIDERS_MASTER, EDIT_VENDOR_MASTER, GATE_SEARCH,
    PARKING_SEARCH, UPDATE_DOMESTIC_HELPER, UPDATE_GATE_MASTER,
    UPDATE_PARKING_MASTER, UPDATE_SERVICE_PROVIDERS_MASTER, UPDATE_VENDOR_MASTER
} from "../../graphql/securityManager/saveMasterqueries";
import {
    Gatedrawer,
    GateHeading, GatePath, GateType, Parkingdrawer, ParkingHeading,
    ParkingPath,
    ParkingType
} from "./masterUtils";
import {timeZoneConverter} from "../../utils"

export const securityActions = (type, offset, limit, search) => {
    switch (type) {
        case 'Access Gates':
            return {
                type: GET_GATE_MASTER(offset, limit, search).loc.source.body,
                resfunc: (array) => {
                    let user_id = [];
                    let data;
                    let arr = array?.access_gates_master?.map((data, index) => {
                        user_id.push(data?.user_profile_id)
                        let mainData = {}
                        mainData.id = data?.id;
                        mainData.property_name = data?.property?.name;
                        mainData.name = data?.name;
                        mainData.is_active = data?.is_active;
                        mainData.is_external = data?.is_external;
                        mainData.description = data?.description;
                        mainData.gate_no = data?.gate_no
                        mainData.duty_date = data?.on_duty_date && timeZoneConverter(data?.on_duty_date , "DD MMM yyyy, hh:mm");
                        mainData.security_charge = data?.user_profile?.label;
                        mainData.securityCharge = data?.user_profile
                        mainData.icon = "edittoggle";
                        mainData.index = offset + index + 1;
                        return mainData
                    })
                    return data = { table: arr, id: user_id }
                },
                tbdata: {
                    heading: GateHeading,
                    path: GatePath,
                    type: GateType,
                    drawer: Gatedrawer,
                }
            }
        case "Visitor Parking Slots":
            return {
                type: GET_PARKING_MASTER(offset, limit, search).loc.source.body,
                resfunc: (array) => {
                    let data;
                    let arr = array?.parking_master?.map((data, index) => {
                        let parking = {};
                        parking.id = data?.id;
                        parking.property_name = data?.property?.name;
                        parking.parking_no = data.parking_no;
                        parking.is_active = data.is_active;
                        parking.rate = data?.rate;
                        parking.currency_symbol = `${data?.currency_master?.symbol}  ${data?.currency_master?.code}`;
                        parking.icon = "edittoggle"
                        parking.index = offset + index + 1
                        return parking;
                    })
                    return data = { table: arr }
                },
                tbdata: {
                    heading: ParkingHeading,
                    path: ParkingPath,
                    type: ParkingType,
                    drawer: Parkingdrawer
                }
            }
        case "Domestic Help":
            return {
                type: GET_DOMESTIC_HELPER_MASTER(offset, limit, search).loc.source.body,
                resfunc: (array) => {
                    let data;
                    let arr = array?.domestic_helper_master?.map((data, index) => {
                        let domesticHelpMaster = {};
                        domesticHelpMaster.id = data?.id;
                        domesticHelpMaster.name = data?.name ? data?.name : "-";
                        domesticHelpMaster.gender = data?.gender ? data?.gender : "-";
                        domesticHelpMaster.mobile_no = (data.mobile_country_code ? (data.mobile_country_code + " ") : "") +
                            (data.mobile_no ? (data.mobile_no) : "");
                        domesticHelpMaster.mobileNumber = (data.mobile_no ? (data.mobile_no) : ""); //for reference
                        domesticHelpMaster.countryCode = (data.mobile_country_code ? (data.mobile_country_code) : ""); //for reference
                        domesticHelpMaster.id_type = data?.identification_master?.label ? data.identification_master?.label : "-";
                        domesticHelpMaster.id_number = data?.identification_no ? data?.identification_no : "-";
                        domesticHelpMaster.profession = data?.profession_master?.name ? data?.profession_master?.name : "-";
                        domesticHelpMaster.is_active = data.is_active;
                        domesticHelpMaster.property_name = data?.property ? data?.property : [];
                        domesticHelpMaster.qr_code = data?.qr_code ? data?.qr_code : "";
                        domesticHelpMaster.icon = "edittoggle";
                        domesticHelpMaster.index = offset + index + 1
                        return domesticHelpMaster;
                    })
                    return data = { table: arr }
                },
                tbdata: {
                    heading: DomesticHelpHeading,
                    path: DomesticHelpPath,
                    type: DomesticHelpType,
                    drawer: DomesticHelpdrawer
                }
            }
        case "Vendor":
            return {
                type: GET_VENDOR_MASTER(offset, limit, search).loc.source.body,
                resfunc: (array) => {
                    let data;
                    let arr = array?.vendor_master?.map((data, index) => {
                        let vendorMaster = {};
                        vendorMaster.id = data?.id;
                        vendorMaster.vendor_name = data?.name ? data?.name : "-";
                        vendorMaster.vendor_code = data?.vendor_code ? data?.vendor_code : "-";
                        vendorMaster.is_active = data.is_active;
                        vendorMaster.property_name = data?.property ? data?.property : [];
                        vendorMaster.icon = "edittoggle";
                        vendorMaster.index = offset + index + 1
                        return vendorMaster;
                    })
                    return data = { table: arr }
                },
                tbdata: {
                    heading: VendorHeading,
                    path: VendorPath,
                    type: VendorType,
                    drawer: Vendordrawer
                }
            }
        case "Service Provider":
            return {
                type: GET_SERVICE_PROVIDERS_MASTER(offset, limit, search).loc.source.body,
                resfunc: (array) => {
                    let data;
                    let arr = array?.service_providers_master?.map((data, index) => {
                        let serviceProvidersMaster = {};
                        serviceProvidersMaster.id = data?.id;
                        serviceProvidersMaster.service_provider_name = data?.name ? data?.name : "-";
                        serviceProvidersMaster.service_provider_code = data?.service_provider_code ? data?.service_provider_code : "-";
                        serviceProvidersMaster.service_cateogry = data?.profession_master?.name ? data?.profession_master?.name : "";
                        serviceProvidersMaster.is_active = data.is_active;
                        serviceProvidersMaster.property_name = data?.property ? data?.property : [];
                        serviceProvidersMaster.icon = "edittoggle";
                        serviceProvidersMaster.index = offset + index + 1;
                        return serviceProvidersMaster;
                    })
                    return data = { table: arr }
                },
                tbdata: {
                    heading: ServiceProviderHeading,
                    path: ServiceProviderPath,
                    type: ServiceProviderType,
                    drawer: ServiceProviderdrawer
                }
            }
        default:
            break;

    }

}


export const securityUpdateActions = {
    'Access Gates': {
        type: UPDATE_GATE_MASTER,
    },
    'Visitor Parking Slots': {
        type: UPDATE_PARKING_MASTER,
    },
    'Domestic Help': {
        type: UPDATE_DOMESTIC_HELPER,
    },
    'Vendor': {
        type: UPDATE_VENDOR_MASTER,
    },
    'Service Provider': {
        type: UPDATE_SERVICE_PROVIDERS_MASTER,
    },
}

export const securityEditActions = {
    'Access Gates': {
        type: EDIT_GATE_MASTER,
        key: "gate_master_id"
    },
    'Visitor Parking Slots': {
        type: EDIT_PARKING_MASTER,
        key: "parkingId"
    },
    'Domestic Help': {
        type: EDIT_DOMESTIC_HELPER,
        key: "domestic_helper_id"
    },
    'Vendor': {
        type: EDIT_VENDOR_MASTER,
        key: "vendor_id"
    },
    'Service Provider': {
        type: EDIT_SERVICE_PROVIDERS_MASTER,
        key: "service_provider_id"
    }
}

export const securitySearch = {
    'Access Gates': {
        type: GATE_SEARCH,
        resfunc: (array) => {
            let user_id = [];
            let data;
            let arr = array?.access_gates_master?.map(data => {
                user_id.push(data?.user_profile_id)
                let mainData = {}
                mainData.id = data?.id;
                mainData.property_name = data?.property?.name;
                mainData.name = data?.name;
                mainData.is_active = data?.is_active;
                mainData.is_external = data?.is_external;
                mainData.description = data?.description;
                mainData.gate_no = data?.gate_no
                mainData.icon = "edittoggle";
                return mainData
            })
            return data = { table: arr, id: user_id }
        },
        tbdata: {
            heading: GateHeading,
            path: GatePath,
            type: GateType,
            drawer: Gatedrawer,
        }
    },
    "Visitor Parking Slots": {
        type: PARKING_SEARCH,
        resfunc: (array) => {
            let data;
            let arr = array?.parking_master?.map(data => {
                let parking = {};
                parking.id = data?.id;
                parking.property_name = data?.property?.name;
                parking.parking_no = data.parking_no;
                parking.is_active = data.is_active;
                parking.icon = "edittoggle"
                return parking;
            })
            return data = { table: arr }
        },
        tbdata: {
            heading: ParkingHeading,
            path: ParkingPath,
            type: ParkingType,
            drawer: Parkingdrawer
        }
    },
}

export const securityOptions = {
    'Profession Master Option': {
        type: GET_PROFESSION_MASTER_OPTIONS,
    },
    'Identification Type Master Option': {
        type: GET_IDENTIFICATION_MASTER_OPTION,
    },
    'Currency Option': {
        type: GET_CURRENCY,
    },
}
