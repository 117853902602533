// import { useApolloClient } from "@apollo/client";

import {
  Box
} from "@mui/material";
import React from "react";
import { getMailstone } from "../../graphql/quotationQueries";
import { CustomPaymentSelectBox } from "./paymentSelectBox";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";


export const PaymentSelectBox = ({
  label = "",
  handleChange = () => false,
  data = [],
  mileStoneName = "",
  companyId = "",
  error = "",
  mileStoneId = "",
  isRequired = "",
  value
}) => {



  // const client = useApolloClient();

  // const [mileStoneOptions, setMileStoneOptions] = React.useState([]);
  // const [loader, setLoader] = React.useState(false);
  // const getMailStoneOptions = (offset, filter) => {
  //   if (!filter) {
  //     setLoader(true);
  //   }
  //   client
  //     .query({
  //       query: getMailstone(offset, 10, "", {}, companyId),
  //       fetchPolicy: "network-only",
  //       variables: {
  //         company_id: companyId,
  //         offset: 0,
  //         limit: 10,
  //       },
  //     })
  //     .then((response) => {
  //       if (filter) {
  //         setMileStoneOptions(mileStoneOptions.concat(response?.data?.milestone_template_master))
  //       }
  //       else {
  //         setMileStoneOptions(response?.data?.milestone_template_master);
  //       }
  //       setLoader(false);
  //     })
  //     .catch((err) => {
  //       console.log(err, loader);
  //       setLoader(false);
  //     });
  // };
  // const handleClick = (event) => {
  //   getMailStoneOptions(0, false);
  // };

  // const completion = [
  //   {
  //     value: "On Completion",
  //     label: "On Completion",
  //   }

  // ]

  // const groupedOptions = [
  //   {
  //     label: "",
  //     options: completion
  //   }
  // ];

  return (
    <Box >
      <CustomPaymentSelectBox
        // options={groupedOptions}
        value={mileStoneName.length > 0 ? { value: mileStoneName, label: mileStoneName } : null}
        onChange={handleChange}
        mileStoneId={mileStoneId}
        data={data}
        label={"Payment Period"}
        placeholder={"Select Payment period"}
        error={error}
        required={isRequired}
        loadOptions={(search, array, handleLoading) =>
          loadOptions(
            search,
            array,
            handleLoading,
            getMailstone,
            "milestone_template_master",
            { company_id: companyId ,all_value:"On Completion",all_label:"On Completion",all:true},
            )
        }

      />

    </Box>
  );
};
