import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { FormGenerator } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { UPDATE_TRANSFER_LEAD } from "../../../graphql/oppertunityQueries";
import { GET_ROLES_NEW, GET_DEPARTMENT_BY_COMPANY_ID } from '../../../graphql/queries'
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods, LocalStorageKeys } from "../../../utils/constants";

const initialState = {
    role: "",
    name: "",
    remark: "",
    department: "",
    description:"",
    error: {
        role: "",
        name: "",
        remark: "",
        department: "",
        description:""
    }
}

export const TransferOppertunity = ({ t, oppertunity_id = "", onClose = () => false, company_id }) => {
    const client = useApolloClient();
    const [data, setData] = React.useState({ ...initialState })
    const [loading, setLoading] = React.useState(null)
    const alert = React.useContext(AlertContext);
    //update state
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        if (key === 'role') return setData({ ...data, [key]: value, name: "", error });
        setData({ ...data, [key]: value, error });

    };
    //validate
    const validateForm = () => {
        let isValid = true;
        let error = data.error;
        if (data?.role?.length === 0) {
            isValid = false;
            error.role = t("Role is Required");
        }
        if (data?.name?.length === 0) {
            isValid = false;
            error.name = t("Name is Required");
        }

        if (data?.description?.length === 0 || data?.description === undefined) {
            isValid = false;
            error.description = t("Description is Required");
        }
        setData({ ...data, error });
        return isValid;
    };
    //on submit
    const onSubmit = () => {
        if (validateForm()) {
            client.mutate({
                mutation: UPDATE_TRANSFER_LEAD,
                variables: {
                    leadID: oppertunity_id,
                    updatePayload: {
                        transfer_remarks: data?.remark,
                        lead_owner: data?.name?.user_id,
                    },
                }
            }).then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Oppertunity Transfer Successfully"),
                });
                onClose()

            }).catch((err) => {
                console.log(err)
            })

        } else {
            return false
        }
    }
    //load options
    const loadOptions = async (search = "", array, type) => {
        setLoading(type);
        let result, query, offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }
        switch (type) {
            case 'roles':
                query = GET_ROLES_NEW(offset, 10, search, localStorage.getItem(LocalStorageKeys?.clinetID)).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.client_roles],
                    hasMore: (array?.length + result?.client_roles?.length) < result?.count[0]?.count
                }
            case 'role':
                if(!data?.role?.value && !data?.department?.label){
                    setLoading(null);
                    return []
                }
                const api = await NetworkCall(
                    `${config.api_url}/client_user_roles/get_user_access`,
                    NetWorkCallMethods.post,
                    {
                        id: data?.role?.value,
                        offset: offset,
                        limit: 10,
                        search: search ?? "",
                        department: data?.department?.label
                    },
                    null,
                    true,
                    false
                )
                setLoading(null);
                //   console.log(api , 'api')
                // query = LEAD_NAME_OPTIONS(offset, 10, search, data?.role?.value , data?.department).loc.source.body;
                // result = await networkCallBack(query);
                return {
                    options: [...api?.data?.data?.map((val) => {
                        return {
                            label: `${val?.first_name} ${val?.lase_name ?? ""}`,
                            value: val?.id,
                            user_id: val?.id
                        }
                    })],
                    hasMore: (array?.length + api?.data?.data?.length) < api?.data?.count
                }
            case 'Department':
                if (!company_id) {
                    setLoading(null);
                    return { options: [] }
                }
                query = GET_DEPARTMENT_BY_COMPANY_ID(offset, 10, search, company_id, localStorage.getItem(LocalStorageKeys?.clinetID)).loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [...result?.department_master],
                    hasMore: (array?.length + result?.department_master?.length) < result?.count[0]?.count
                }
            default:
                return { options: [] }
        }
    }
    const networkCallBack = async (query, variable = {}) => {
        let payload = {
            query,
            variable: variable
        };

        const options = await NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                setLoading(null);
                return main
            })
            .catch((error) => {
                setLoading(null);
                return null
            });

        return options
    }
    const formData = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "select",
            label: t("Select Role"),
            value: data?.role,
            placeholder: t("Select Role"),
            onChange: (value) => updateState("role", value),
            error: data?.error?.role,
            isRequired: true,
            isPaginate: true,
            loadOptions: (search, array) => loadOptions(search, array, 'roles'),
            loading: loading === "roles" ? true : false,
            menuPlacement: "bottom"

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "select",
            label: t("Select Department"),
            value: data?.department,
            placeholder: t("Select Department"),
            onChange: (value) => updateState("department", value),
            error: data?.error?.department,
            // isRequired: true,
            isPaginate: true,
            loadOptions: (search, array) => loadOptions(search, array, 'Department'),
            loading: loading === "Department" ? true : false,
            menuPlacement: "bottom",
            isClearable: true

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "select",
            label: t("Select Name"),
            value: data?.name,
            placeholder: t("Select Name"),
            onChange: (value) => updateState("name", value),
            error: data?.error?.name,
            isRequired: true,
            isPaginate: true,
            loadOptions: (search, array) => loadOptions(search, array, 'role'),
            loading: loading === "role" ? true : false,
            key: (JSON.stringify(data?.department || data?.role)),
            menuPlacement: "bottom",
            isReadOnly: data?.role !== ""
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("Description"),
            value: data?.description,
            placeholder: t("Enter Description"),
            onChange: (value) => updateState("description", value?.target.value),
            error: data?.error?.description,
            isRequired: true,
            multiline: true
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "button",
            label: "Submit",
            onClick: (value) => onSubmit(),

        }
    ]

    return (
        <Box p={3}>
            <FormGenerator t={t} components={formData} />
        </Box>
    )
}