
import gql from "graphql-tag";

export const GET_INVENTORY = (offset, limit, searchText, { company_id }) => gql`
query GET_INVENTORY{
  count: inventory(
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
      company_id:"${company_id}"
      type:"Asset"
    }) {
    count:count_id
  }
  inventory(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
    where: {
      name: { iregex: "${searchText}" }
      is_active:{eq:true}
       type:"Asset"
      company_id:"${company_id}"
    }
    ){
    value:id
    label:name
  }
}`;


export const UPDATE_INVENTARY = gql`
mutation {
    inspection_items(where: { id: { eq: $id } }update: $updatePayload) {
      id
    }
}`;
