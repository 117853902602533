import gql from "graphql-tag";


export const GET_PROPERTY = gql`
query {
  count: property(
    where: { company_id: $company_id, 
    or:{
name: { iregex: $search }
property_no: { iregex: $search }

    }
    }
  ) {
    count: count_id
  }
  property(
    offset: $offset
    limit: $limit
    where: { company_id: $company_id, 
    or:{
name: { iregex: $search }
property_no: { iregex: $search }

    }
    }
    orderby: { created_at: desc }
  ) {
   id
   propertyName: name
   image:logo
   property_no
    unit {
      count_id
    }
  }
}

`

export const GET_PROPERTY_UNIT = gql`
query {
  property(where: { id: $id }) {
    id
    property_no
   propertyName: name
    image:logo
    description
    is_active
    property_groupByID {
      group_name
    }
    total_units: unit {
      count_id
    }
    property_purpose
    company {
      name
      id
    }
    address {
      id
      door_no
      street_1
      street_2
      street_3
      landmark
      area
      city
      district
      state
      country
      zipcode
      __typename
    }
    count: unit(where: { property_id: $id, 
    or:{
      name: { iregex: $search }
      unit_no: { iregex: $search }
    }
    
    }) {
      count: count_id
    }
    unit(
      offset: $offset
      limit: $limit
      where: { property_id: $id, 
      or:{
        name: { iregex: $search }
        unit_no: { iregex: $search }
      }
      
      }
      orderby: { created_at: desc }
    ) {
      id
      unitName: name
      logo
     unitNumber: unit_no
    unitCategory:unit_category_master{
      name
    }
    address {
      id
      door_no
      street_1
      street_2
      street_3
      landmark
      area
      city
      district
      state
      country
      zipcode
      __typename
    }
     unitPurpose: unit_purpose
     revenueType: revenue_type
    }
  }
}


`

export const GET_UNIT_MAPPING = gql`
query {
  unit(where: { id: $unit_id }) {
    unitName:name
    property_no:unit_no
    description
    is_active
    description
    unit_category_master{
      name
    }
    image:logo
     property {
      id
      company {
        name
      }
    }
  }
}

`

export const UPDATE_INSPECTION_MAPPING = gql`
mutation {
    inspection_item_mapping(where: { id: { eq: $id } } update: $updatePayload) {
      id
    }
}`;

export const GET_TEMPLATE = gql`
query {
  inspection_template(
    where: {property_id:$property_id is_active: true,
       name: { iregex: $search },
       client:$client 
      }
    offset: $offset
    limit: $limit
  ) {
    id
    Name:name
     count:inspection_template_items(where:{is_active:true}){
      count_id
    }
   sub_items: inspection_template_items (where:{is_active:true}) {
      id
      Name:name
      inspection_item
      count:item_count
      inspection_item{
        is_inventory
        id
        name
        inventory{
          name
        }
      }
    }
  }
}

`