import React from 'react'
import { TextBox } from '../../../components'
import { Box, Typography } from '@mui/material'
import { InvoiceDetailStyle } from './style'
import { CustomSelectBox } from '../../../components/customSelectBox'

export const HideShowTextBox = ({
    e = {},
    value = "",
    labelText = "",
    setInvoiceRow = () => false,
    amountCalculation = () => false,
    booked = false,
    keyvalue = "",
    loadOptions = () => false,
    loading = "",
    type = "",
    selectedCompany = "",
    slots = []
}) => {
    const [hide, setHide] = React.useState(false)
    const classes = InvoiceDetailStyle()

    const manualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                label: i?.name,
                value: i?.id,
                ...i
            }
        })
        return details
    }
    return (
        <>
            {
                type === "textbox" ?
                    <>
                        {
                            (e?.value?.extraInvoice && !booked && !hide) ? <Box sx={{ width: 70 }}>
                                <TextBox
                                    value={value}
                                    label={null} placeholder={""}
                                    height="36px"
                                    padding={"6px 4px 4px"}
                                    onChange={(v) => setInvoiceRow(e, keyvalue, v?.target?.value)}
                                    onBlur={() => {
                                        amountCalculation(true)
                                        setHide(true)
                                    }}
                                    type={"number"} />
                            </Box>
                                :
                                <Typography className={classes.tableBodyText} sx={{ width: 50 }} onClick={() => setHide(false)}>
                                    {labelText}
                                </Typography>
                        }
                    </> :
                    <>
                        {(e?.value?.extraInvoice && !booked && !hide) ?

                            <Box sx={{ width: 130, position: "relative", }}>
                                {/* <SelectBox
                                    value={value}
                                    selectHeight={"30px"}
                                    onChange={(v) => {
                                        setInvoiceRow(e, "label", v)
                                        setHide(true)
                                    }}
                                    loading={loading === "pricing_component"}
                                    isPaginate
                                    debounceTimeout={800}
                                    loadOptions={(search, array) => loadOptions(search, array, 'pricing_component')}
                                    menuPlacement={"auto"} /> */}


                                <CustomSelectBox
                                    value={value}
                                    menuOptionPadding={"0px 0px 8px 0px"}
                                    networkCallData={
                                        {
                                            path: "/free_text_invoice/get_free_text_item_types",
                                            payload: {
                                                company_id: selectedCompany?.value,
                                                item_type: "Item Master",
                                                selected_components: slots?.filter((i) => i?.value?.extraInvoice === true)?.map((e) => e?.value?.label && e?.value?.label?.value)?.filter((d) => d !== '') ?? []
                                            },
                                            mappingVariable: "items",
                                            manualResponse: manualResponse
                                        }
                                    }
                                    onChange={(val) => {
                                        setInvoiceRow(e, "label", val)
                                        setHide(true)
                                    }}
                                />
                            </Box>
                            :
                            <Typography className={classes.tableBodyText} onClick={() => setHide(false)}>
                                {labelText}
                            </Typography>
                        }
                    </>
            }
        </>
    )
}