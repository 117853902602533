import { Box, Grid, Typography } from "@mui/material";
import { SelectBox, TextBox, ToggleButtonComponent } from "../../../components";
import { useStyles } from "../styles";
import { wrapLabelsInT } from "../../../utils";
export const BankDetails = ({ data = {}, updateState = {}, enumValue = {}, loadOptions = () => false, loading = false, t = () => false }) => {
    const classes = useStyles()

    return (
        <Box marginTop="16px" className={classes.box}>
            <Typography className={classes.title}>{t("Banking Basic Details")}</Typography>
            <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography className={classes.label}>{t("Bank Account Type")}
                    </Typography>
                    <ToggleButtonComponent
                        options={wrapLabelsInT(enumValue?.bank_account_type, t)}
                        value={data?.account_type}
                        onChange={(value) => updateState("account_type", value)}

                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextBox
                        label={t("Account Number")}
                        placeholder={t("Enter Account Number")}
                        value={data?.account_no}
                        onChange={(e) => updateState("account_no", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextBox
                        label={t("Bank Name")}
                        placeholder={t("Enter Bank Name")}
                        value={data?.bank_name}
                        onChange={(e) => updateState("bank_name", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextBox
                        label={t("Branch Name")}
                        placeholder={t("Enter Branch Name")}
                        value={data?.branch_name}
                        onChange={(e) => updateState("branch_name", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SelectBox
                        label={t("Currency")}
                        placeholder={t("Select Currency")}
                        value={data?.bank_currency_id}
                        onChange={(value) => updateState("bank_currency_id", value)}
                        isPaginate
                        loadOptions={(search, array) => loadOptions(search, array, 'currency')}
                        loading={loading === "currency"}
                        debounceTimeout={800}

                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SelectBox
                        label={t("Bank Routing Type")}
                        placeholder={t("Select Bank Routing Type")}
                        value={data?.bank_route_type}
                        options={enumValue?.bank_routing_type}
                        onChange={(e) => updateState("bank_route_type", e)}
                        menuPlacement={"top"}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextBox
                        label={t("Bank Routing Code")}
                        placeholder={t("Enter Bank Routing Code")}
                        value={data?.bank_route_code}
                        onChange={(e) => updateState("bank_route_code", e.target.value)}

                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextBox
                        label={t("Address Line 1")}
                        placeholder={t("Enter Address Line 1")}
                        value={data?.bank_addrees1}
                        onChange={(e) => updateState("bank_addrees1", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextBox
                        label={t("Address Line 2")}
                        placeholder={t("Enter Address Line 2")}
                        value={data?.bank_addrees2}
                        onChange={(e) => updateState("bank_addrees2", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextBox
                        label={t("City")}
                        placeholder={t("Enter City")}
                        value={data?.bank_city}
                        onChange={(e) => updateState("bank_city", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SelectBox
                        label={t("Country")}
                        placeholder={t("Select Country")}
                        value={data?.bank_country}
                        onChange={(value) => updateState("bank_country", value)}
                        isPaginate
                        loadOptions={(search, array) => loadOptions(search, array, 'country')}
                        loading={loading === "country"}
                        debounceTimeout={800}
                        menuPlacement={"top"}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextBox
                        label={t("Preferred Cash Collection Office")}
                        placeholder={t("Enter Preferred Cash Collection Office")}
                        value={data?.collection_office ?? ""}
                        onChange={(e) => updateState("collection_office", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextBox
                        label={t("Cheque Payment beneficiary Name")}
                        placeholder={t("Enter Cheque Name")}
                        value={data?.cheque_name ?? ""}
                        onChange={(e) => updateState("cheque_name", e.target.value)}
                    />
                </Grid> */}
            </Grid>

        </Box >

    )
}