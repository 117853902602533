export const listing_tab_data = (t) => {
    return {
        subscription_and_plan: {
            value: "subscription_and_plan",
            label: t("Subscription & Plan details"),
        },
        // invoice_and_payment: {
        //     value: "invoice_and_payment",
        //     label: "Invoice & Payments",
        // },
        basic_info: {
            value: "basic_info",
            label: t("Basic Info"),
        },
        configuration: {
            value: "configuration",
            label: t("Configuration"),
        },
    }
}

export const listing_tab_paths = (t) => [
    listing_tab_data(t).subscription_and_plan.value,
    // listing_tab_data.invoice_and_payment.value,
    listing_tab_data(t).basic_info.value,
    listing_tab_data(t).configuration.value,
]

export const plan_name_color = ["#78B1FE", "#5AC782", "#F15A29", "#F1A129"]