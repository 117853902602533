import { useApolloClient } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import React from "react";
import { AlertDialog, LoadingSection, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../../components";
import { GET_COMPANY_MASTER } from "../../../graphql/clientSettingsQueries";
import { LocalStorageKeys, accessCheckRender, getRoutePermissionNew } from "../../../utils";
import { logodataType1, logoheading1, logopath1 } from "../../../utils/clientsettings";
import { LogoUploadForm } from "../components";
import { AuthContext } from "../../../contexts";
import { withNamespaces } from "react-i18next";

export const LogoApperance = ({ selected = {}, loading, t = () => false,
    handleLoading = () => false }) => {
    const [open, setOpen] = React.useState(false)
    const client = useApolloClient();
    const [limits, setLimit] = React.useState(10);
    const [selectData, setSelectData] = React.useState({})
    const [page, setPage] = React.useState(1);
    const [logoList, setLogoList] = React.useState({
        list: [],
        count: 0
    })
    const [searchText, setSearchText] = React.useState("");
    const debounce = UseDebounce();
    const auth = React.useContext(AuthContext);
    const [permission, setPermission] = React.useState({})



    //get Bannerlist
    const getBannerlist = (limit, offset, search) => {

        handleLoading(true)
        client.query({
            query: GET_COMPANY_MASTER,
            fetchPolicy: 'network-only',
            variables: {
                limit: limit,
                offset: offset,
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                search: search ?? ""
            },
        }).then((response) => {
            setLogoList({
                list: response.data.company_master,
                count: response.data.count[0]?.count
            })
            handleLoading(false)
        }).catch((err) => {
            console.log(err)
            handleLoading(false)
        })
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limits;
        getBannerlist(limits, offset)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getBannerlist(value, 0)
    }
    //search in table
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    const searchTableFunction = (e) => {
        getBannerlist(limits, 0, e)
    }
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getBannerlist(limits, 0, "")
            }
        }
        getBannerlist(limits, 0)
        // eslint-disable-next-line
    }, [auth])
    //onclick row
    const onClickRow = (data) => {
        setOpen(true)
        setSelectData(data)
    }
    const render = () => {
        return (
            <Box>
                {/*setting save */}
                <Subheader hideBackButton title={selected?.label} />

                <Box p={2}>
                    <Box backgroundColor={'#fff'} p={2}>
                        <Grid container>
                            <Grid item xs={4} pt={'0px !importantx'}>
                                <Box>
                                    {/*setting list */}
                                    <SearchFilter value={searchText} placeholder={t("Search Company")} handleChange={(value) => handleSearch(value)} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={2}>
                                    {
                                        loading ? <LoadingSection bottom={"45vh"} message="Fetching ..." /> :

                                            <TableWithPagination
                                                heading={logoheading1(t)}
                                                rows={logoList?.list}
                                                path={logopath1}
                                                showpagination={true}
                                                onClick={onClickRow}
                                                dataType={logodataType1}
                                                handlePagination={handlePagination}
                                                handleChangeLimit={handleChangeLimit}
                                                totalRowsCount={logoList?.count}
                                                page={page}
                                                limit={limits}
                                                height={'calc(100vh - 301px)'}
                                                view={true}
                                                marginTop={'0px !important'}
                                                edit={true}
                                                delete={true} />
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {/*upload dialog*/}
                <AlertDialog
                    medium
                    header={t("Logo & Appearance")}
                    onClose={() => setOpen(false)}
                    open={open}
                    component={
                        <>
                            <LogoUploadForm
                                reload={getBannerlist}
                                company={selectData}
                                onClose={() => setOpen(false)}
                                t={t}
                            />
                        </>
                    }
                />
            </Box>
        )
    }
    return (
        <div>
            {accessCheckRender(render, permission, "", loading)}
        </div>
    );
}
export default withNamespaces("banners")(LogoApperance)