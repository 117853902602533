/* eslint-disable array-callback-return */
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, Dialog, Grid, IconButton } from "@mui/material";
import Typography from '@mui/material/Typography';
import makeStyles from "@mui/styles/makeStyles";
import * as React from 'react';
import { TableWithPagination, UseDebounce } from '..';
import { SearchFilter } from "../../components/searchbar";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { LocalStorageKeys, NetWorkCallMethods, timeZoneConverter } from "../../utils";
import { OtherInfoCard } from "../otherInfoCard";
import { Bold } from '../../utils';
const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.typography.color.primary,
        fontFamily: Bold,
        fontSize:"1rem"
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
        },
    },
    titleBar: {
        borderBottom: "1px solid #E4E8EE"
    }
}))
export const DialogWithTable = (props) => {
    const { t } = (props)
    const classes = useStyles(props);
    const [loading, setLoading] = React.useState(true);
    const [offset] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [count, setCount] = React.useState("");
    const [expand, setExpand] = React.useState(false);
    const [search, setSearch] = React.useState("")
    const debounce = UseDebounce()
    const toggleAcordion = () => {
        setExpand(!expand);
    };
    const [list, setList] = React.useState([]);
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getTable(offset, limit, search);
    };

    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getTable(0, value, search);
    };

    const getTable = (offset, limit, serachText) => {
        if (expand === true) {
            //query
            if (props?.data?.query) {
                const payload = {
                    query: props?.data?.query,
                    variables: {
                        unitId: props?.data?.unitId && props?.data?.unitId,
                        agreementId: props?.data?.agreementId && props?.data?.agreementId,
                        offset: offset,
                        limit: limit,
                        searchText: serachText,
                        clientID: localStorage.getItem(LocalStorageKeys?.clinetID)

                    },

                };
                NetworkCall(
                    `${config.graphql_url}`,
                    NetWorkCallMethods.post,
                    payload,
                    null,
                    true,
                    false
                )
                    .then((response) => {
                        let main = response?.data?.data;
                        //let count = response?.data
                        const aData = [];

                        if (main?.unit_rental_breakup) {
                            main?.unit_rental_breakup.map(data => {
                                const aObj = {}
                                aObj.revenue = data?.revenue_type ?? "-"
                                aObj.component = data?.rental_breakup_master?.description ? data?.rental_breakup_master?.description : " - "
                                aObj.value = data?.value ? data?.value : " - "
                                aObj.currency = data?.currency?.symbol ? data?.currency?.symbol : " - "
                                aObj.chargeable = data?.is_chargeable ? "Yes" : "No"
                                aObj.valueType = data?.value_basis_type ? data?.value_basis_type : " - "
                                aObj.display = data?.display_percentage ? "true" : "false"
                                aObj.taxGroup = data?.vat_group_master?.group_name ? data?.vat_group_master?.group_name : " - "
                                aObj.taxGroup2 = data?.period_type ? data?.period_type : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.unit_vacancy) {
                            main?.unit_vacancy.map(data => {
                                const aObj = {}
                                aObj.unitNo = data?.unit_no
                                aObj.unitName = data?.name
                                aObj.image = data?.logo
                                aObj.companyName = data?.company_name ?? "-"
                                aObj.reservationNumber = data?.reservation_number ?? "-"
                                aObj.primaryContactNumber = data?.mobile_no ?? "-"
                                aObj.primaryContactName = data?.first_name ? `${data?.first_name} ${data?.last_name ?? ""}` : " - "
                                aObj.agreementNumber = data?.agreement_no ?? "-"
                                aObj.endDate = data?.available_to
                                aObj.startDate = data?.available_from
                                aObj.currentStatus = data?.current_status
                                aData.push(aObj)

                            })
                        }
                        if (main?.unit_amenities_breakup) {
                            main?.unit_amenities_breakup.map(data => {
                                const aObj = {}
                                aObj.amenitiesType = data?.amenities_type ? data?.amenities_type : " - "
                                aObj.name = data?.name ? data?.name : " - "
                                aObj.chargable = data?.chargable ? "Yes" : "No"
                                aData.push(aObj)
                            })
                        }
                        if (main?.unit_utilties) {
                            main?.unit_utilties.map(data => {
                                const aObj = {}
                                aObj.utilites = data?.utilites ? data?.utilites : " - "
                                aObj.meterSerialNo = data?.meter_serial_no ? data?.meter_serial_no : " - "
                                aObj.meterPointReference = data?.meter_point_reference ? data?.meter_point_reference : " - "
                                aObj.updatedDate = timeZoneConverter(data?.updated_at, "DD MMM YY") ? timeZoneConverter(data?.updated_at, "DD MMM YY") : " -"
                                aObj.description = data?.description ? data?.description : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.unit) {
                            main?.unit.map(data => {
                                const aObj = {}
                                aObj.roomName = data?.name ?? " - "
                                aObj.description = data?.description ?? " - "
                                aObj.roomno = data?.unit_no ?? " - "
                                aObj.roomSttaus = data?.status ?? " - "

                                aData.push(aObj)
                            })
                        }
                        if (main?.security_request) {
                            main?.security_request.map((data, index) => {
                                const aObj = {}
                                aObj.sno = (offset ?? 0) + index + 1
                                aObj.index = offset + index + 1
                                aObj.requestId = data?.request_no ? data?.request_no : ' - '
                                aObj.requestName = data?.name ? data?.name : " - "
                                aObj.category = data?.request_type ? data?.request_type : " - "
                                aObj.raisedDate = timeZoneConverter(data?.raised_no, "DD MMM YY") ? timeZoneConverter(data?.raised_no, "DD MMM YY") : " - "
                                aObj.status = data?.status ? data?.status : " - "
                                aObj.description = data?.description ? data?.description : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.resident) {
                            main?.resident.map((data, index) => {
                                const aObj = {}
                                aObj.residentName = `${data?.user_profiles?.first_name ?? ""} ${data?.user_profiles?.last_name ?? ""}`
                                aObj.residentphoneno = data?.user_profiles?.mobile_no ?? ""
                                aObj.to = timeZoneConverter(data?.to, "DD MMM YY") ? timeZoneConverter(data?.to) : " - "
                                aObj.from = timeZoneConverter(data?.from) ? timeZoneConverter(data?.from) : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.owner) {
                            main?.owner.map((data, index) => {
                                const aObj = {}
                                aObj.ownerName = `${data?.user_profiles?.first_name ?? ""} ${data?.user_profiles?.last_name ?? ""}`
                                aObj.ownerphoneno = data?.user_profiles?.mobile_no ?? ""
                                aObj.to = timeZoneConverter(data?.to) ? timeZoneConverter(data?.to) : " - "
                                aObj.from = timeZoneConverter(data?.from) ? timeZoneConverter(data?.from) : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.agreement_units) {
                            const unique = [...new Map(main?.agreement_units.map(item => [item.agreement['id'], item])).values()];
                            unique.map((data, index) => {
                                const aObj = {}
                                aObj.index = offset + index + 1
                                aObj.agreementNo = data?.agreement?.agreement_no ?? " - "
                                aObj.validfrom = data?.agreement?.lease_start_date ?? " - "
                                aObj.validto = data?.agreement?.lease_end_date ?? " - "
                                aObj.emailAddress = data?.agreement?.contact?.email_id ?? " - "
                                aObj.agreementuser = `${data?.agreement?.contact?.first_name ?? " "} ${data?.agreement?.contact?.last_name ?? " "}`
                                aObj.contactNo = `${data?.agreement?.contact?.mobile_no_country_code ?? ""} ${data?.agreement?.contact?.mobile_no ?? ""}`
                                aData.push(aObj)
                            })
                        }
                        if (main?.invoice_units && props?.data?.title === "Unit") {
                            main?.invoice_units.map((data, index) => {
                                const aObj = {}
                                aObj.sno = (offset ?? 0) + index + 1
                                aObj.index = offset + index + 1
                                aObj.unitID = data?.unit?.unit_no ?? " - "
                                aObj.tenantName = data?.tenant_profileByID?.first_name ?? " - "
                                aObj.primaryContact = data?.tenant_profileByID?.mobile_no_country_code + " " + data?.tenant_profileByID?.mobile_no ?? " - "
                                aObj.emailAddress = data?.tenant_profileByID?.email_id ?? " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.invoice_units && props?.data?.title === "Unit Invoices") {
                            main?.invoice_units.map((data, index) => {
                                const aObj = {}
                                aObj.sno = (offset ?? 0) + index + 1
                                aObj.index = offset + index + 1
                                aObj.invoiceNo = data?.invoice?.invoice_no ?? "-"
                                aObj.raiseon = data?.invoice?.invoice_date ?? "-"
                                aObj.raisedueon = data?.invoice?.payment_due_date ?? " "
                                aObj.status = data?.invoice?.payment_status ?? " "
                                aObj.amount = data?.invoice?.invoice_due_amount ?? " "
                                aObj.icon = "view"
                                aData.push(aObj)
                            })
                        }
                        if (main?.owner_customer?.[0]?.tenant_profileByID) {
                            main?.owner_customer.map((data, index) => {
                                const aObj = {}
                                aObj.sno = (offset ?? 0) + index + 1
                                aObj.index = offset + index + 1
                                aObj.name = `${data?.tenant_profileByID?.first_name} ${data?.tenant_profileByID?.last_name ? data?.tenant_profileByID?.last_name : " "}` ? `${data?.tenant_profileByID?.first_name} ${data?.tenant_profileByID?.last_name ? data?.tenant_profileByID?.last_name : " "}` : " -"
                                aObj.contactNumber = data?.tenant_profileByID?.mobile_no ? data?.tenant_profileByID?.mobile_no_country_code + " - " + data?.tenant_profileByID?.mobile_no : " - "
                                aObj.email = data?.tenant_profileByID?.email_id ? data?.tenant_profileByID?.email_id : " - "
                                aData.push(aObj)
                            })
                        }
                        if (main?.agreement) {
                            main?.agreement.map((data, index) => {
                                const aObj = {}
                                aObj.sno = (offset ?? 0) + index + 1
                                aObj.index = offset + index + 1
                                aObj.accountName = data?.account?.name ? data?.account?.name : " - "
                                aObj.accountId = data?.account_id ? data?.account_id : " - "
                                aObj.accountNumber = data?.account?.account_no ? data?.account?.account_no : " - "
                                aObj.companyName = data?.company?.name ? data?.company?.name : " - "
                                aData.push(aObj)

                            })
                        }
                        if (main?.request) {
                            main?.request.map((data, index) => {
                                const aObj = {}
                                aObj.requestId = data?.request_no
                                aObj.requestName = data?.description
                                aObj.raisedDate = timeZoneConverter(data?.raised_on)
                                aObj.category = data?.request_type
                                aObj.status = data?.request_status
                                aData.push(aObj)

                            })
                        }
                        setList(aData)
                        setLoading(false)
                        setCount(main?.count?.[0]?.count)


                    })
                    .catch((error) => {
                        console.log(error)

                    });
            }

            //api
            if (props?.data?.api) {
                const payload = {
                    tenantId: `${config.tenantId}`,
                    request_units: [props?.data?.unitId],
                    request_type: [
                        "Maintenance",
                        "General"
                    ],
                    offset: offset,
                    limit: limit,
                    search: serachText
                };
                NetworkCall(
                    `${config.api_url}${props?.data?.api}`,
                    NetWorkCallMethods.post,
                    payload,
                    null,
                    true,
                    false
                )
                    .then((response) => {
                        let main = response?.data?.data;

                        const aData = [];
                        if (main) {
                            main.map(data => {
                                const aObj = {}
                                aObj.requestId = data?.request_no
                                aObj.requestName = data?.request_name
                                aObj.raisedDate = timeZoneConverter(data?.create_date)
                                aObj.category = data?.request_type
                                aObj.description = data?.description
                                aObj.status = data?.request_status
                                aData.push(aObj)
                            })
                        }
                        setCount(response?.data?.totalCount)
                        setList(aData)
                        setLoading(false)

                    })
                    .catch((error) => {
                        //             alert.setSnack({
                        // ...alert,
                        // open: true,
                        // severity: AlertProps.severity.error,
                        // msg: "Please fill all mandatory field",
                        // vertical: AlertProps.vertical.top,
                        // horizontal: AlertProps.horizontal.center,
                        //  });
                        console.log(error)
                    });

            }

        }
    }
    React.useEffect(() => {
        getTable(offset, limit, search)
        // eslint-disable-next-line
    }, [expand, offset, limit])
    const handleSearch = (e) => {
        setSearch(e)
        debounce(() => searchTableFunction(e), 800)
    }

    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getTable(0, 10, e)
    }
    return (
        <div>
            <OtherInfoCard
                data={{
                    name: props?.data?.title,
                    view: `${t("View")} ${props?.data?.title}`,
                    color: props?.data?.color,
                    count: props?.data?.count
                }}
                t={t}
                unit
                onClick={() => {
                    setExpand(true)
                }}
            />
            <Dialog open={expand} maxWidth="lg" fullWidth onClose={toggleAcordion} className={classes.dialog}>
                <Box display="flex" alignItems="center" className={classes.titleBar} p={1}>
                    <Box flexGrow={1}><Typography className={classes.text} >{props?.data?.title}</Typography>
                    </Box>
                    <Box>
                        <IconButton size="small" onClick={toggleAcordion}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                </Box>
                {
                    props?.data?.title !== "Unit Vacancy Period" &&
                    <Box p={1} className={classes.titleBar}>
                        <Grid>
                            <Grid item xs={4}>
                                <SearchFilter handleChange={(e) => handleSearch(e)} value={search} placeholder={`${t("Search")} ${props?.data?.title}`} />
                            </Grid>
                            <Grid xs={8} />
                        </Grid>
                    </Box>
                }

                {loading ? (
                    <div style={{ minHeight: "300px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress color="secondary" />
                    </div>
                ) : (
                    <>

                        {list?.length > 0 ?
                            <div style={{ minHeight: "300px", padding: "12px" }}>
                                <TableWithPagination
                                    heading={list?.length > 0 ? props?.data?.table?.heading : []}
                                    rows={list}
                                    path={list?.length > 0 ? props?.data?.table?.path : []}
                                    showpagination={true}
                                    onClick={() => false}
                                    dataType={props?.data?.table?.dataType}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={count}
                                    page={page}
                                    limit={limit}
                                    height={'calc(100vh - 351px)'}
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            </div>
                            :
                            <div style={{ minHeight: "300px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography className={classes.text} textAlign="center">
                                    {t("No Records Found")}
                                </Typography>
                            </div>
                        }
                    </>
                )}

            </Dialog>

        </div >
    );
}
