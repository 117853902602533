
import * as React from "react"

const NewAgreementComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 21.208 26.863"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={0.048}
        y1={0.952}
        x2={0.509}
        y2={0.491}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#297cd2" />
      </linearGradient>
    </defs>
    <g
      id="icons8-agreement_1_"
      data-name="icons8-agreement (1)"
      transform="translate(-7.579 -4.211)"
    >
      <path
        id="Path_96422"
        data-name="Path 96422"
        d="M28.787,11.987V29.66a1.413,1.413,0,0,1-1.414,1.414H8.993A1.413,1.413,0,0,1,7.579,29.66V5.624A1.413,1.413,0,0,1,8.993,4.211H21.011Z"
        fill="#50e6ff"
      />
      <path
        id="Path_96423"
        data-name="Path 96423"
        d="M23.579,4.211v6.362a1.413,1.413,0,0,0,1.414,1.414h6.362Z"
        transform="translate(-2.568)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_96424"
        data-name="Path 96424"
        d="M12.5,31.251a.708.708,0,0,1-.358-1.318,10.5,10.5,0,0,0,1.524-1.172A8.171,8.171,0,0,1,12.5,24.252c0-3.371,1.309-5.725,3.181-5.725,1.826,0,2.474,2.162,2.474,4.014a9.429,9.429,0,0,1-2.519,6.253,1.958,1.958,0,0,0,1.1.336,5.2,5.2,0,0,0,2.71-.768,5.4,5.4,0,0,1-.589-2.554c0-1.556.715-3.747,2.3-3.747.583,0,1.938.279,1.938,2.86A5.29,5.29,0,0,1,21.612,28.4a1.525,1.525,0,0,0,.3.028c.763,0,1.146-.411,1.758-1.137A3.7,3.7,0,0,1,26.635,25.6a.707.707,0,1,1,0,1.414c-.842,0-1.265.452-1.886,1.188a3.579,3.579,0,0,1-2.84,1.639,2.718,2.718,0,0,1-1.5-.412,6.577,6.577,0,0,1-3.673,1.119,3.389,3.389,0,0,1-2.112-.73,11.493,11.493,0,0,1-1.773,1.341A.706.706,0,0,1,12.5,31.251Zm3.18-11.311c-.853,0-1.767,1.733-1.767,4.312a7.388,7.388,0,0,0,.775,3.455,8,8,0,0,0,2.053-5.166C16.738,21.535,16.45,19.94,15.678,19.94Zm5.484,3.535c-.334,0-.889,1.13-.889,2.334a4.453,4.453,0,0,0,.291,1.643,3.856,3.856,0,0,0,1.123-2.531C21.687,24.486,21.636,23.475,21.162,23.475Z"
        transform="translate(-0.676 -2.298)"
        fill="#057093"
      />
    </g>
  </svg>
)

export default NewAgreementComponent
