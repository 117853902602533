import { TabContext, TabList } from "@mui/lab"
import { Box, Grid, Tab, useMediaQuery } from "@mui/material"
import React from "react"
import { withNamespaces } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { Subheader } from "../../components"
import { NewLoader } from "../../components/newLoader"
import { config } from "../../config"
import { NetworkCall } from "../../networkcall"
import { Routes } from "../../router/routes"
import { NetWorkCallMethods, useWindowDimensions } from "../../utils"
import { AddItem } from "../createMaintenancePlanner/components/addItem"
import { BasicPlanDetails } from "../createMaintenancePlanner/components/basicPlanDetails"
import { ContractDetails } from "../createMaintenancePlanner/components/contractDetails"
import { ExecutionDetails } from "../createMaintenancePlanner/components/executionDetails"
import { PlanDataType, PlanHeading, PlanPath, constructViewData } from "../createMaintenancePlanner/utils"
import { useStyles } from "./style"
import { CommonTable } from "./table"
import { BOMDataType, BOMHeading, BOMPath, CheckListDataType, CheckListHeading, CheckListPath, DynamicTabDetails, ExecutedJobsDataType, ExecutedJobsHeading, ExecutedJobsPath, ExecutedWorkOrdersDataType, ExecutedWorkOrdersHeading, ExecutedWorkOrdersPath, InvoiceDataType, InvoiceHeading, InvoicePath, PropertyDataType, PropertyHeading, PropertyPath, ScheduleWorkOrdersDataType, ScheduleWorkOrdersHeading, ScheduleWorkOrdersPath, TabDetails, WorkPermitDataType, WorkPermitHeading, WorkPermitPath } from "./utils"

const ViewMaintenancePlanner = ({ t = () => false }) => {
    const history = useHistory()
    const classes = useStyles()
    const matches = useMediaQuery('(min-width:900px)')
    const size = useWindowDimensions()
    const { state } = useLocation()
    const [tabValue, setTabValue] = React.useState("1")
    const [loading, setLoading] = React.useState(false)
    const [viewDetails, setViewDetails] = React.useState([])
    const [tableData, setTableData] = React.useState({ row: [], count: 0 })
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue === "4") {
            const result = viewDetails?.BOM?.map((x) => {
                return {
                    ...x,
                    line_amount: `${state?.company?.currency_symbol} ${x?.line_amount}`,
                    item_type: x?.item_type?.value
                }
            })
            setTableData({ row: result, count: viewDetails?.BOM?.length })
        }
        else if (newValue === "2" && state?.planner_type === "Fixed") {
            const result = viewDetails?.selected_items?.map((x) => {
                return {
                    ...x,
                    item_type: x?.item_type?.value
                }
            })
            setTableData({ row: result, count: viewDetails?.selected_items?.length })
        }
        else if (newValue === "3" && state?.planner_type === "Fixed") {
            const result = viewDetails?.selected_resources?.map((x) => {
                return {
                    ...x,
                    image: x?.image_url,
                    item_type: x?.item_type?.value
                }
            })
            setTableData({ row: result, count: viewDetails?.selected_resources?.length })
        }
        else if (newValue === "5") {
            if (state?.planner_type === "Fixed") {
                setTableData(({ row: viewDetails?.selected_checklist, count: viewDetails?.selected_checklist?.length }))
            }
            else {
                setTableData(({ row: viewDetails?.dynamic_plan_preview, count: viewDetails?.dynamic_plan_preview?.length }))
            }
        }
        else if (newValue === "6") {
            setTableData(({ row: viewDetails?.view_schedule_work_orders, count: viewDetails?.view_schedule_work_orders?.length }))
        }
        else if (newValue === "7") {
            setTableData(({ row: viewDetails?.view_executed_work_orders, count: viewDetails?.view_executed_work_orders?.length }))
        }
        else if (newValue === "8") {
            getExecutedJobOrders()
        }
        else if (newValue === "2" && state?.planner_type === "Dynamic") {
            const result = viewDetails?.selected_dynamic_properties?.map((x) => {
                return {
                    ...x,
                    property_no: x?.property_no,
                    name: x?.property_name,
                    location: `${x?.city},${x?.country}`,
                    is_active: true,

                }
            })
            setTableData({ ...tableData, row: result })
        }
        else if (newValue === "3" && state?.planner_type === "Dynamic") {
            setTableData(({ row: viewDetails?.selected_dynamic_checklist, count: viewDetails?.selected_dynamic_checklist?.length }))

        }
        else {
            setTableData(({ row: [], count: 0 }))
        }
    };
    const getPublishedPlanDetails = () => {
        setLoading(true)
        const payload = {
            "id": state?.tableData?.id,
        }
        NetworkCall(
            `${config.api_url}/${state?.planner_type === "Fixed" ? "preventive_plan/get" : "preventive_dynamic_plan/get"}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const result = constructViewData(response?.data?.data, state?.planner_type)
            setViewDetails({ ...result })
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
        })
    }
    const getExecutedJobOrders = (offset = 0, limit = 10, company_id, search = "",) => {
        setLoading(true)
        const payload = {
            company_id: state?.company?.value,
            // "plan": state?.tableData?.id,
            preventive_plan_id: state?.tableData?.id,
            type: ["Schedule"],
            offset: offset,
            limit: limit,
            request_type: ["service"],
            search: search,
        }

        NetworkCall(
            `${config.api_url}/general_inspection/`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            const data = response?.data?.data?.data?.map((val) => {
                return ({
                    ...val,
                    plan_reference: state?.tableData?.reference_no,
                    job_referenece_no: val?.job_no,
                    job_name: val?.role,
                    reference_no: val?.item_reference_no,
                    name: val?.item_name ?? val?.name,
                    item_category: val?.category_name,
                    item_subcategory: val?.subcategory_name,
                    eta: val?.eta > 0 ? `${val?.eta} ${val?.eta > 1 ? "hrs" : "hr"}` : 0,
                    other_hours: val?.other_hours > 0 ? `${val?.other_hours} ${val?.other_hours > 1 ? "hrs" : "hr"}` : 0,
                    KPI: val?.overbooking_count > 0 ? "Delayed" : val?.ontime_count > 0 ? "Ontime" : "-",
                    actual_hours: val?.actual_hours !== null ? (val?.actual_hours > 0 ? `${val?.actual_hours} ${val?.actual_hours > 1 ? "hrs" : "hr"}` : "<1 hr") : "-"

                })
            })
            setTableData({ row: data, count: response?.data?.data?.count })

        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    React.useState(() => {
        if (state?.isView) {
            getPublishedPlanDetails()
        }
    }, [])
    const getHeading = (value) => {
        switch (value) {
            case "2": {
                return {
                    heading: PropertyHeading(t),
                    path: PropertyPath,
                    dataType: PropertyDataType
                }
            }
            case "3": {
                return {
                    heading: CheckListHeading(t),
                    path: CheckListPath,
                    dataType: CheckListDataType
                }
            }
            case "4": {
                return {
                    heading: BOMHeading(t),
                    path: BOMPath,
                    dataType: BOMDataType
                }
            }
            case "5": {
                return state?.planner_type === "Fixed" ? {
                    heading: CheckListHeading(t),
                    path: CheckListPath,
                    dataType: CheckListDataType
                } :
                    {
                        heading: PlanHeading(t),
                        path: PlanPath,
                        dataType: PlanDataType
                    }
            }
            case "6": {
                return {
                    heading: ScheduleWorkOrdersHeading(t),
                    path: ScheduleWorkOrdersPath,
                    dataType: ScheduleWorkOrdersDataType
                }
            }
            case "7": {
                return {
                    heading: ExecutedWorkOrdersHeading(t),
                    path: ExecutedWorkOrdersPath,
                    dataType: ExecutedWorkOrdersDataType
                }
            }
            case "8": {
                return {
                    heading: ExecutedJobsHeading(t),
                    path: ExecutedJobsPath,
                    dataType: ExecutedJobsDataType
                }
            }
            case "9": {
                return {
                    heading: WorkPermitHeading(t),
                    path: WorkPermitPath,
                    dataType: WorkPermitDataType
                }
            }
            case "10": {
                return {
                    heading: BOMHeading(t),
                    path: BOMPath,
                    dataType: BOMDataType
                }
            }
            case "11": {
                return {
                    heading: InvoiceHeading(t),
                    path: InvoicePath,
                    dataType: InvoiceDataType
                }
            }
            default: {
                return null
            }
        }
    }
    const goBack = () => {
        if (state?.planner_type === "Fixed") {
            history.push({ pathname: Routes?.fixedMaintenancePlanner, state: { tabValue: state?.tabValue } })
        }
        else {
            history.push({ pathname: Routes?.dynamicMaintenancePlanner, state: { tabValue: state?.tabValue } })
        }
    }
    return (
        <Box>
            <Subheader
                title={t("View Maintenance Planner")}
                goBack={() => goBack()}
            // padding={"12px 4px"}
            />

            <Box className={classes.root}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {state?.planner_type === "Fixed" ?
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons={false}>
                                {TabDetails?.map((val, i) => {
                                    return (
                                        <Tab label={val?.label} value={val?.value} className={classes.customtab} />
                                    )
                                })}
                            </TabList>
                            :
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons={false}>
                                {DynamicTabDetails?.map((val, i) => {
                                    return (
                                        <Tab label={val?.label} value={val?.value} className={classes.customtab} />
                                    )
                                })}
                            </TabList>
                        }
                    </Box>
                </TabContext>
                {loading ? <NewLoader /> :
                    <>
                        {tabValue === "1" ?
                            <Box height={matches ? "auto" : size?.height - 200} overflow="scroll">
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <BasicPlanDetails t={t} data={viewDetails} isView={true} state={state} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <ExecutionDetails t={t} data={viewDetails} isView={true} state={state} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <ContractDetails t={t} data={viewDetails} isView={true} state={state} />
                                    </Grid>
                                </Grid>
                            </Box>
                            : (state?.planner_type === "Fixed" && (tabValue === "2" || tabValue === "3")) ?
                                <Box mt={2}>
                                    <AddItem t={t} data={viewDetails} form_type={tabValue === "2" ? "unit" : "resource"} isView={true} state={state} />
                                </Box>
                                :
                                <CommonTable
                                    t={t}
                                    heading={getHeading(tabValue)?.heading}
                                    path={getHeading(tabValue)?.path}
                                    dataType={getHeading(tabValue)?.dataType}
                                    details={viewDetails}
                                    tabValue={tabValue}
                                    tableData={tableData}
                                    setTableData={setTableData}
                                    loading={loading}
                                    setLoading={setLoading}
                                    planner_type={state?.planner_type}
                                />
                        }
                    </>
                }
            </Box>

        </Box>
    )
}
export default withNamespaces("maintenancePlanner")(ViewMaintenancePlanner)