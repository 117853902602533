import React from "react";
import { Box, Typography, MenuItem } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useStyles } from "./style";
import { TickIcon } from "../svg";

export const SourceMenuOption = ({
  value = "",
  option = [],
  onChange = () => false,
  isReadOnly = false
}) => {
  const classes = useStyles();
  // state
  const [open, setOpen] = React.useState(false)

  // functions
  const handleClick = (event) => {
    setOpen(true);

  };
  const handleClose = (key, value) => {
    if (key === "save") {
      onChange(value);
    }
  };


  return (
    <Box className={classes.root} onClick={() => setOpen(!open)}>
      <Box
        onClick={!isReadOnly && handleClick}

        className={classes.srctypebox}
      >
        <Typography className={classes.srcText} noWrap>{value ?? ""}</Typography>
        <KeyboardArrowDownIcon className={classes.arrowIcon} />
      </Box>
      {
        (open && !isReadOnly) &&
        <Box className={classes.dropDown} onClick={() => handleClose("close", "x")}>
          {option?.map((x) => {
            return (
              <MenuItem
                onClick={() => handleClose("save", x)}
                className={classes.flexBox1}
              >
                <span className={classes.srcText}>
                  {" "}
                  {x?.value?.length > 0
                    ? `${x?.value?.substring(0, 25)}${x?.value?.length > 25 ? "..." : ""
                    } `
                    : "-"}
                </span>
                <Box>{value === x?.value && <TickIcon color={"#896DB3"} />}</Box>
              </MenuItem>
            );
          })}
        </Box>
      }


    </Box >

  );
};
