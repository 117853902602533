
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Divider, Grid, Stack, Tab } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { TransferLead } from '../../assets';
import ShortListIMG from '../../assets/shortlistImg';
import { AlertDialog, Subheader } from "../../components";
import { AuthContext } from '../../contexts';
import { Routes } from '../../router/routes';
import { accessCheckRender, getRoutePermissionNew } from '../../utils';
import { returnShortlistGoData } from '../../utils/oppertunityUtils';
import { CloseOppertunity, TransferOppertunity } from "./component";
import { useStyles } from "./styles";
import { Activity, Attachments, Details, Quotation, Todo } from "./tab";
import { withNamespaces } from 'react-i18next';
const OppertunityView = ({
    t
}) => {
    const classes = useStyles()
    const history = useHistory()
    const { state } = useLocation();
    const oppertunity_id = state?.id

    console.log(state , 'state')

    const [details, setDetails] = React.useState({})
    const [openTransfer, setOpenTransfer] = React.useState(false)
    const [openClose, setOpenClose] = React.useState(false)
    const [value, setValue] = React.useState("1")
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})
    const [shortListedCount, setShortListedCount] = React.useState("")

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
        }
        // eslint-disable-next-line
    }, [auth]);

    //onChange tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //go shortlist
    const goShortlisted = () => {
        history.push({
            pathname: Routes.shortlistedoppertunityunits,
            state: returnShortlistGoData(details)
        })
    }
    //close oppertunity
    const onCloseoppertunity = () => {
        setOpenClose(false)
        history.push(Routes.leads)
    }
    //close transfer
    const onCloseTransferoppertunity = () => {
        setOpenTransfer(false)
        history.push(Routes.leads)
    }
    const isRead = (details?.status === 3 || details?.status === 1) ? true : false

    const render = () => {
        return <>
            <Subheader title={t("View Opportunity")} goBack={() => history.goBack()} />
            <Box className={classes.root}>
                <TabContext value={value}>
                    <TabList onChange={handleChange}>
                        <Tab label={t("Opportunity")} value="1" />
                        <Tab label={t("Activity")} value="2" />
                        <Tab label={t("To do List")} value="3" />
                        <Tab label={t("Quotation")} value="4" />
                        <Tab label={t("Attachments")} value="5" />
                    </TabList>
                    <Divider />
                    <TabPanel value="1" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Details t={t} permission={permission} oppertunity_id={oppertunity_id} setDetails={setDetails} details={details} disabled={isRead} setShortListedCount={setShortListedCount} />
                        </Box>
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Activity t={t} permission={permission} oppertunity_id={oppertunity_id} selectedCompany={state?.value} disabled={isRead} details={details} />
                        </Box >
                    </TabPanel >
                    <TabPanel value="3" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Todo t={t} permission={permission} oppertunity_id={oppertunity_id} disabled={isRead} />
                        </Box >
                    </TabPanel >
                    <TabPanel value="4" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Quotation t={t} permission={permission} oppertunity_id={oppertunity_id} />
                        </Box >
                    </TabPanel >
                    <TabPanel value="5" sx={{ padding: "0px" }}>
                        <Box className={classes.tabRoot}>
                            <Attachments t={t} id={state?.id}/>
                            {/* <Quotation t={t} permission={permission} oppertunity_id={oppertunity_id} /> */}
                        </Box >
                    </TabPanel >
                </TabContext >
            </Box >
            {/*transfer oppertunity */}
            < AlertDialog
                onClose={() => setOpenTransfer(false)}
                open={openTransfer}
                header={t("Transfer Oppertunity")}
                component={<TransferOppertunity t={t} oppertunity_id={oppertunity_id} onClose={onCloseTransferoppertunity} company_id={state?.company?.value}/>}
                isNormal={true}
            />

            {/*close oppertunity */}
            < AlertDialog
                onClose={() => setOpenClose(false)}
                open={openClose}
                header={t("Close Oppertunity")}
                component={<CloseOppertunity t={t} oppertunity_id={oppertunity_id} onClose={onCloseoppertunity} />}
                isNormal={true}
            />


            <Grid container className={classes.btnRoot} justifyContent="space-between">
                <Grid item xs={6}>
                    <Stack direction="row" >
                        {permission?.update &&
                            <Button startIcon={<TransferLead style={{marginInlineEnd:"8px"}} />} className={classes.iconBtn} variant='contained' onClick={() => setOpenTransfer(true)} disabled={isRead}> {t("Transfer Opportunity")}</Button>}
                        {permission?.create &&
                            <Button sx={{marginInlineStart:"16px"}} startIcon={<ShortListIMG style={{marginInlineEnd:"8px"}} color="#8887D4" />} onClick={goShortlisted} disabled={isRead ? true : details?.shortlisted?.length > 0 ? false : true} className={classes.iconBtn} variant='contained'>&nbsp;&nbsp;{shortListedCount} {t("Units Shortlisted")}</Button>}
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        {permission?.update &&
                            <Button className={classes.closeBtn} variant='outlined' onClick={() => setOpenClose(true)} disabled={isRead}>{t("Close The Opportunity")}</Button>}
                        {/* <Button className={classes.requsetBtn} variant='contained'>Request For Reserve</Button> */}
                    </Stack>
                </Grid>
            </Grid>


        </>
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}
export default withNamespaces("leads")(OppertunityView);
