import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useStyles } from "./style";
import React from "react";
import { UploadImagePlaceholder } from "../../assets/uploadImagePlaceholder";
import { FileUploadCard } from "../fileUploadCard";
import { DeleteIcon } from "./deleteIcon";
import { RetryIcon } from "./retryIcon";
import { ClearIcon } from "./clearIcon";
import { SubscriptionsContext } from "../../subscriptionsContext";
import { useContext } from "react";
export const ItemCard = ({
    state,
    upload_file_ref,
}) => {
    const {
        t = () => false,
        grid_layout,
        file_upload_limit,
        allowed_file_format,
        handleChooseImage,
        handleUploadImage,
        handleUpdateEdit,
        handleDeleteImage,
        handleClearImage,
        handleCancel,
        handleSave,
    } = useContext(SubscriptionsContext);

    const classes = useStyles()

    return (
        <Grid item xs={grid_layout}>
            <Stack className={classes.item_card_root}
                direction={"column"} rowGap={"16px"}>
                <Stack direction={"row"} columnGap={"8px"}
                    justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} columnGap={"8px"}
                        justifyContent={"space-between"} alignItems={"center"}>
                        {state?.updated_image
                            ? <Box className={classes.image_div}>
                                <img className={classes.uploaded_image}
                                    src={state?.updated_image} alt="login_logo" />
                            </Box>
                            : <UploadImagePlaceholder />}
                        <Typography className={classes.item_card_label}>
                            {t(state?.label)}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} columnGap={"8px"}
                        justifyContent={"space-between"} alignItems={"center"}>
                        {state?.is_edit_mode
                            ? <>
                                <Button className={classes.cancel_button} disabled={state?.is_disable}
                                    onClick={() => handleCancel(state?.type)}>
                                    <Typography className={classes.cancel_text}>
                                        {t("Cancel")}
                                    </Typography>
                                </Button>
                                <Button className={classes.save_button} disabled={state?.is_disable}
                                    onClick={() => handleSave(state?.selected_remote_image, state?.type)}>
                                    <Typography className={classes.save_text}>
                                        {t("Save")}
                                    </Typography>
                                </Button>
                            </>
                            : <Button className={classes.edit_update_button} disabled={state?.is_disable}
                                onClick={() => handleUpdateEdit(state?.type)}>
                                <Typography className={classes.edit_update_text}>
                                    {state?.updated_image
                                        ? t("Update")
                                        : t("Edit")}
                                </Typography>
                            </Button>
                        }
                    </Stack>
                </Stack>
                {state?.is_edit_mode &&
                    ((state?.selected_local_image || state?.selected_remote_image || state?.updated_local_image)
                        ? <Stack className={classes.selected_image_section_div}
                            direction={"row"} columnGap={"4px"}
                            justifyContent={"space-between"} alignItems={"center"}>
                            <Stack direction={"row"} columnGap={"8px"}
                                justifyContent={"space-between"} alignItems={"center"}>
                                <Box className={classes.selected_image_div}>
                                    <img className={classes.selected_image}
                                        src={state?.selected_local_image ?? state?.selected_remote_image ?? state?.updated_local_image} alt="selected_image" />
                                </Box>
                                <Typography className={classes.item_card_label}>
                                    {t(state?.selected_image_name)}
                                </Typography>
                            </Stack>
                            {state?.is_try_again
                                ? <Stack direction={"row"} columnGap={"8px"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <Box className={classes.clear_button}
                                        onClick={() => !state?.is_disable && handleClearImage(state?.type)}>
                                        <ClearIcon />
                                    </Box>
                                    <Box className={classes.retry_button}
                                        onClick={() => !state?.is_disable && handleUploadImage(state?.selected_blob_image, state?.type)}>
                                        <RetryIcon />
                                    </Box>
                                </Stack>
                                : <Box className={classes.delete_button}
                                    onClick={() => !state?.is_disable && handleDeleteImage(state?.type)}>
                                    <DeleteIcon />
                                </Box>
                            }
                        </Stack>
                        : <FileUploadCard
                            t={t}
                            file_upload_limit={file_upload_limit}
                            allowed_file_format={allowed_file_format}
                            upload_file_ref={upload_file_ref}
                            handleChooseImage={() => handleChooseImage(state?.type)}
                            handleUploadImage={(file) => handleUploadImage(file, state?.type)} />
                    )
                }
            </Stack>
        </Grid>
    )
}