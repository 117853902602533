import { useApolloClient } from "@apollo/client";
import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import CityProperty from '../../assets/cityproperty';
import ContractProperty from '../../assets/contractproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { PropertyTabs, Subheader } from "../../components";
import { AuthContext, BackdropContext } from "../../contexts";
import { FLOOR_DETAILS_BY_BLOCK_ID } from "../../graphql/queries";
import { withNavBars } from "../../HOCs";
import { Routes } from "../../router/routes";
import { accessCheckRender, getRoutePermissionNew } from "../../utils";
import { unitStyles } from "./style";
import { Assets, FloorDetail, OtherInfo } from "./tabs";

import { withNamespaces } from "react-i18next";

const FloorDetails = ({
    t
}) => {
    const classes = unitStyles()
    const [value, setValue] = React.useState(1);
    const [details, setDetails] = React.useState({
        data: {},
        assets: [],
        asset: []
    })
    const search = useLocation().search;
    const floorId = new URLSearchParams(search).get("floorID");

    const client = useApolloClient();
    const history = useHistory()
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext)
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState("");
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: true,
                    message: "",
                });
                getFloorDetails()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    //openImageViewer
    const openImageViewer = React.useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    //closeImageViewer
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    const getFloorDetails = () => {
        client.query({
            query: FLOOR_DETAILS_BY_BLOCK_ID,
            fetchPolicy: 'network-only',
            variables: {
                floorId: floorId,
            }
        }).then((res) => {
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
            setDetails({
                data: res.data?.floor[0],
                assets: res.data?.assets,
                asset: res.data?.asset
            })
        })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const headerpage = [
        {
            id: "1",
            label: details?.data?.block?.name,
            type: "name"
        },
        {
            id: "2",
            label: details?.data?.name,
            type: "name"
        },
    ]
    const tabTittle = [
        {

            label: t("Floor Details"),
            imgSelect: <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {

            label: t("Assets"),
            imgSelect: <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
            value: 2,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {

            label: t("Other Informations"),
            imgSelect: <ContractProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
            value: 3,
            className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
        }
    ]
    const handleedit = () => {
        history.push({
            pathname: Routes.createFloorNew,
            state: {
                edit: {
                    blockID: details?.data?.block?.id,
                    floorID: floorId,
                    propertyID: details?.data?.property?.id,
                    isEdit: true,
                    company: details?.data?.floor?.[0]?.company,
                }
            }
        })
    }

    const render = () => {
        return <>
            <Subheader title={details?.data?.property?.name} pageoptions={headerpage} goBack={() => history.goBack()} />
            <PropertyTabs value={value} handleChange={handleChange}
                isEdit={permission?.update}
                handleedit={handleedit}
                tabTittle={tabTittle}
                showBtns={true}
                t={t}
                tab1={<FloorDetail data={details?.data} assets={details?.asset}
                    closeImageViewer={closeImageViewer}
                    openImageViewer={openImageViewer}
                    isViewerOpen={isViewerOpen}
                    currentImage={currentImage}
                    t={t}
                />}
                tab2={<Assets asset={details?.assets} data={details?.data} />}
                tab3={<OtherInfo t={t} data={details} />
                }

            />
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    )
}
const props = {
    boxShadow: false
}


export default withNamespaces("floorList")(withNavBars(FloorDetails, props))

