import { Box } from "@mui/material";
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PropertyTabs, Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, allowed_file_size, NetWorkCallMethods, useWindowDimensions, ValidateEmail } from "../../utils";
import { ServiceProviderDetails } from './serviceProviderDetails';
import { useStyles } from "./style";
import { mapResult, singleFileUpload, assestType, getRoutePermissionNew } from '../../utils/common';
import { GET_SERVICE_PROVIDER } from "../../graphql/queries";
// import { useApolloClient } from "@apollo/client";
import { withNamespaces } from "react-i18next";
import CityProperty from "../../assets/cityproperty";

const ServiceProviderCreateEdit = ({
      t
}) => {
      const size = useWindowDimensions()
      // const client = useApolloClient()
      const classes = useStyles(size)
      const { state } = useLocation();
      const mapLoad = !Boolean(state?.main?.serviceProviderID)
      const initial = {
            image: "",
            company: state?.main?.company,
            category: "",
            name: "",
            code: "",
            description: "",
            doorNo: '',
            addressLineOne: "",
            addressLineTwo: "",
            landmark: "",
            area: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            latitude: "",
            longitude: "",
            telephone: "",
            mobile: "",
            email: "",
            registration: "",
            registrationNo: "",
            bankName: "",
            branchName: "",
            currency: "",
            accountType: "",
            accountNo: "",
            routingType: "",
            routingCode: "",
            cashCollection: "",
            cheque: "",
            bankAddress1: "",
            bankAddress2: "",
            bankCity: "",
            bankCountry: "",
            facebook: "",
            twitter: "",
            linkedin: "",
            whatsapp: "",
            error: {
                  image: "",
                  company: "",
                  name: "",
                  telephone: "",
                  mobile: "",
                  email: "",
            }
      }

      const backdrop = React.useContext(BackdropContext);
      const [data, setData] = React.useState({ ...initial })
      const [disable, setDisable] = React.useState(false)
      const [load, setLoad] = React.useState(false)
      const alert = React.useContext(AlertContext);
      const auth = React.useContext(AuthContext)
      const history = useHistory();
      const [permission, setPermission] = React.useState({})
      // eslint-disable-next-line
      const [value, setValue] = React.useState(1);


      const tabTittle = [
            {
                  label: t("Contact Details"),
                  // sub: 'Contact Details',
                  imgSelect: <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                  value: 1,
                  className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
            },
      ]

      // use effect to get permission
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        getInitialData()
                  }
            }
            // eslint-disable-next-line
      }, [auth, state?.main?.serviceProviderID]);

      const updateState = (k, v) => {
            let error = data?.error;
            error[k] = "";
            setData({ ...data, [k]: v, error })
      }

      const removeimg = () => {
            setData({ ...data, image: "" })
      }

      const getServiceProvider = () => {
            backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
            // client.query({
            //       query: GET_SERVICE_PROVIDER, fetchPolicy: 'network-only',
            //       variables: { serviceProviderID: state?.main?.serviceProviderID }
            // }).then((r) => {
            //       const tempServiceProvider = r?.data?.service_providers_master?.[0]
            //       setData({
            //             image: tempServiceProvider?.image_url ?? null,
            //             company: state?.main?.company ?? null,
            //             category: tempServiceProvider?.profession_masterByID ?? null,
            //             name: tempServiceProvider?.name ?? null,
            //             code: tempServiceProvider?.service_provider_code ?? null,
            //             description: tempServiceProvider?.description ?? null,
            //             doorNo: tempServiceProvider?.service_providers_addressByID?.[0]?.door_no ?? null,
            //             addressLineOne: tempServiceProvider?.service_providers_addressByID?.[0]?.street_1 ?? null,
            //             addressLineTwo: tempServiceProvider?.service_providers_addressByID?.[0]?.street_2 ?? null,
            //             landmark: tempServiceProvider?.service_providers_addressByID?.[0]?.landmark ?? null,
            //             area: tempServiceProvider?.service_providers_addressByID?.[0]?.area ?? null,
            //             city: tempServiceProvider?.service_providers_addressByID?.[0]?.city ?? null,
            //             state: tempServiceProvider?.service_providers_addressByID?.[0]?.state ?? null,
            //             country: tempServiceProvider?.service_providers_addressByID?.[0]?.country ?? null,
            //             zipcode: tempServiceProvider?.service_providers_addressByID?.[0]?.pincode ?? null,
            //             latitude: tempServiceProvider?.service_providers_addressByID?.[0]?.latitude ?? null,
            //             longitude: tempServiceProvider?.service_providers_addressByID?.[0]?.longitude ?? null,
            //             telephone: tempServiceProvider?.service_providers_addressByID?.[0]?.telephone_no ?
            //                   {
            //                         mobile: tempServiceProvider?.service_providers_addressByID?.[0]?.telephone_no,
            //                         mobile_code: tempServiceProvider?.service_providers_addressByID?.[0]?.telephone_code,
            //                   } : null,
            //             mobile: tempServiceProvider?.service_providers_addressByID?.[0]?.mobile_no ?
            //                   {
            //                         mobile: tempServiceProvider?.service_providers_addressByID?.[0]?.mobile_no,
            //                         mobile_code: tempServiceProvider?.service_providers_addressByID?.[0]?.mobile_code,
            //                   } : null,
            //             email: tempServiceProvider?.service_providers_addressByID?.[0]?.email_id ?? null,
            //             registration: tempServiceProvider?.company_registration ?
            //                   {
            //                         value: tempServiceProvider?.company_registration,
            //                         label: tempServiceProvider?.company_registration,
            //                   } : null,
            //             registrationNo: tempServiceProvider?.company_registration_no ?? null,
            //             bankName: tempServiceProvider?.bank_name ?? null,
            //             branchName: tempServiceProvider?.branch_name ?? null,
            //             currency: tempServiceProvider?.currency_masterByID ?? null,
            //             accountType: tempServiceProvider?.account_type ?
            //                   {
            //                         value: tempServiceProvider?.account_type,
            //                         label: tempServiceProvider?.account_type,
            //                   } : null,
            //             accountNo: tempServiceProvider?.account_no ?? null,
            //             routingType: tempServiceProvider?.routing_type ?
            //                   {
            //                         value: tempServiceProvider?.routing_type,
            //                         label: tempServiceProvider?.routing_type,
            //                   } : null,
            //             routingCode: tempServiceProvider?.routing_code ?? null,
            //             cashCollection: tempServiceProvider?.cash_collection_office ?? null,
            //             cheque: tempServiceProvider?.cheque_name ?? null,
            //             bankAddress1: tempServiceProvider?.address_1 ?? null,
            //             bankAddress2: tempServiceProvider?.address_2 ?? null,
            //             bankCity: tempServiceProvider?.bank_city ?? null,
            //             bankCountry: tempServiceProvider?.bank_country ?? null,
            //             facebook: tempServiceProvider?.facebook ?? null,
            //             twitter: tempServiceProvider?.twitter ?? null,
            //             linkedin: tempServiceProvider?.linkedin ?? null,
            //             whatsapp: tempServiceProvider?.whatsapp ?? null,
            //             error: initial?.error
            //       })
            //       setLoad(true)
            //       backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            // })


            const payload = {
                  query: GET_SERVICE_PROVIDER,
                  variables: { serviceProviderID: state?.main?.serviceProviderID }
            };

            NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            ).then((res) => {
                  const tempServiceProvider = res?.data?.data?.service_providers_master?.[0]
                  setData({
                        image: tempServiceProvider?.image_url ?? null,
                        company: state?.main?.company ?? null,
                        category: tempServiceProvider?.profession_masterByID ?? null,
                        name: tempServiceProvider?.name ?? null,
                        code: tempServiceProvider?.service_provider_code ?? null,
                        description: tempServiceProvider?.description ?? null,
                        doorNo: tempServiceProvider?.service_providers_addressByID?.[0]?.door_no ?? null,
                        addressLineOne: tempServiceProvider?.service_providers_addressByID?.[0]?.street_1 ?? null,
                        addressLineTwo: tempServiceProvider?.service_providers_addressByID?.[0]?.street_2 ?? null,
                        landmark: tempServiceProvider?.service_providers_addressByID?.[0]?.landmark ?? null,
                        area: tempServiceProvider?.service_providers_addressByID?.[0]?.area ?? null,
                        city: tempServiceProvider?.service_providers_addressByID?.[0]?.city ?? null,
                        state: tempServiceProvider?.service_providers_addressByID?.[0]?.state ?? null,
                        country: tempServiceProvider?.service_providers_addressByID?.[0]?.country ?? null,
                        zipcode: tempServiceProvider?.service_providers_addressByID?.[0]?.pincode ?? null,
                        latitude: tempServiceProvider?.service_providers_addressByID?.[0]?.latitude ?? null,
                        longitude: tempServiceProvider?.service_providers_addressByID?.[0]?.longitude ?? null,
                        telephone: tempServiceProvider?.service_providers_addressByID?.[0]?.telephone_no ?
                              {
                                    mobile: tempServiceProvider?.service_providers_addressByID?.[0]?.telephone_no,
                                    mobile_code: tempServiceProvider?.service_providers_addressByID?.[0]?.telephone_code,
                              } : null,
                        mobile: tempServiceProvider?.service_providers_addressByID?.[0]?.mobile_no ?
                              {
                                    mobile: tempServiceProvider?.service_providers_addressByID?.[0]?.mobile_no,
                                    mobile_code: tempServiceProvider?.service_providers_addressByID?.[0]?.mobile_code,
                              } : null,
                        email: tempServiceProvider?.service_providers_addressByID?.[0]?.email_id ?? null,
                        registration: tempServiceProvider?.company_registration ?
                              {
                                    value: tempServiceProvider?.company_registration,
                                    label: tempServiceProvider?.company_registration,
                              } : null,
                        registrationNo: tempServiceProvider?.company_registration_no ?? null,
                        bankName: tempServiceProvider?.bank_name ?? null,
                        branchName: tempServiceProvider?.branch_name ?? null,
                        currency: tempServiceProvider?.currency_masterByID ?? null,
                        accountType: tempServiceProvider?.account_type ?
                              {
                                    value: tempServiceProvider?.account_type,
                                    label: tempServiceProvider?.account_type,
                              } : null,
                        accountNo: tempServiceProvider?.account_no ?? null,
                        routingType: tempServiceProvider?.routing_type ?
                              {
                                    value: tempServiceProvider?.routing_type,
                                    label: tempServiceProvider?.routing_type,
                              } : null,
                        routingCode: tempServiceProvider?.routing_code ?? null,
                        cashCollection: tempServiceProvider?.cash_collection_office ?? null,
                        cheque: tempServiceProvider?.cheque_name ?? null,
                        bankAddress1: tempServiceProvider?.address_1 ?? null,
                        bankAddress2: tempServiceProvider?.address_2 ?? null,
                        bankCity: tempServiceProvider?.bank_city ?? null,
                        bankCountry: tempServiceProvider?.bank_country ?? null,
                        facebook: tempServiceProvider?.facebook ?? null,
                        twitter: tempServiceProvider?.twitter ?? null,
                        linkedin: tempServiceProvider?.linkedin ?? null,
                        whatsapp: tempServiceProvider?.whatsapp ?? null,
                        error: initial?.error
                  })
                  setLoad(true)
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            }).catch((err) => {
                  console.log(err)
            });
      }
      const save = () => {
            if (validate()) {
                  setDisable(true)
                  const datas = {
                        name: data?.name ?? undefined,
                        is_active: true ?? undefined,
                        image_url: data?.image ?? undefined,
                        service_provider_code: data?.code ?? undefined,
                        company_id: state?.main?.company?.value ?? undefined,
                        profession_id: data?.category?.value ?? undefined,
                        description: data?.description ?? undefined,
                        account_type: data?.accountType?.value ?? undefined,
                        routing_type: data?.routingType?.value ?? undefined,
                        routing_code: data?.routingCode ?? undefined,
                        cash_collection_office: data?.cashCollection ?? undefined,
                        bank_name: data?.bankName ?? undefined,
                        currency_id: data?.currency?.value ?? undefined,
                        account_no: data?.accountNo ?? undefined,
                        company_registration: data?.registration?.value ?? undefined,
                        branch_name: data?.branchName ?? undefined,
                        cheque_name: data?.cheque ?? undefined,
                        address_1: data?.bankAddress1 ?? undefined,
                        address_2: data?.bankAddress2 ?? undefined,
                        bank_city: data?.city ?? undefined,
                        bank_country: data?.bankCountry ?? undefined,
                        company_registration_no: data?.registrationNo ?? undefined,
                        facebook: data?.facebook ?? undefined,
                        twitter: data?.twitter ?? undefined,
                        linkedin: data?.linkedin ?? undefined,
                        whatsapp: data?.whatsapp ?? undefined,
                        telephone_code: data?.telephone?.mobile_code ?? undefined,
                        telephone_no: data?.telephone?.mobile ?? undefined,
                        mobile_code: data?.mobile?.mobile_code ?? undefined,
                        mobile_no: data?.mobile?.mobile ?? undefined,
                        door_no: data?.doorNo ?? undefined,
                        landmark: data?.landmark ?? undefined,
                        street_1: data?.addressLineOne ?? undefined,
                        street_2: data?.addressLineTwo ?? undefined,
                        area: data?.area ?? undefined,
                        city: data?.city ?? undefined,
                        state: data?.state ?? undefined,
                        zipcode: data?.zipcode ?? undefined,
                        country: data?.country ?? undefined,
                        email_id: data?.email ?? undefined,
                        latitude: parseFloat(data?.latitude) ?? undefined,
                        longitude: parseFloat(data?.longitude) ?? undefined,
                  };
                  if (state?.main?.serviceProviderID) {
                        datas["id"] = state?.main?.serviceProviderID;
                  }
                  NetworkCall(
                        `${config.api_url}/service-provider/upsert`,
                        NetWorkCallMethods.post,
                        datas, null, true, false
                  ).then((response) => {
                        history.goBack(-1)
                        alert.setSnack({
                              ...alert, open: true, severity: AlertProps.severity.success,
                              msg: `${state?.main?.serviceProviderID ? t("Service Provider Updated Successfully.!!!") : t("Service Provider Created Successfully.!!!")}`,
                        });
                        setDisable(false)
                  }).catch((error) => {
                        alert.setSnack({
                              ...alert, open: true, severity: AlertProps.severity.error,
                              msg: t("Internal error. Please try again later."),
                        });
                        setDisable(false)
                  });
            } else { return false }
      }

      const checkSave = () => {
            if (state?.main?.serviceProviderID) {
                  if (permission.update) {
                        save()
                  } else {
                        alert.setSnack({
                              ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                              severity: AlertProps.severity.error,
                        })
                  }
            } else if (permission.create) {
                  save()
            } else {
                  alert.setSnack({
                        ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                        severity: AlertProps.severity.error,
                  })
            }
      }

      const getInitialData = () => {
            if (state?.main?.serviceProviderID) {
                  getServiceProvider()
            }
            else { setLoad(true) }
      }

      const validate = () => {
            let isValid = true;
            let error = data.error;
            // if (data?.image?.length === 0) {
            //       isValid = false;
            //       error.image = "Profile Image is Required";
            // }
            if (data?.company?.length === 0) {
                  isValid = false;
                  error.company = t("Company Name is Required");
            }
            if (data?.name?.length === 0) {
                  isValid = false;
                  error.name = t("Service Provider Name is Required");
            }
            if (state?.main?.serviceProviderID) {
                  if (data?.telephone?.mobile?.length === 0) {
                        isValid = false;
                        error.telephone = "Telephone is Required";
                  }
                  if (data?.mobile?.mobile.length === 0) {
                        isValid = false;
                        error.mobile = t("Mobile Phone is Required");
                  }
            }
            else {
                  if (data?.telephone?.length === 0) {
                        isValid = false;
                        error.telephone = t("Business Phone is Required");
                  }
                  if (data?.mobile?.length === 0) {
                        isValid = false;
                        error.mobile = t("Mobile Phone is Required");
                  }
            }
            // eslint-disable-next-line
            if (data?.email?.length !== 0 && !ValidateEmail(data?.email)) {
                  isValid = false;
                  error.email = t("Enter valid Email");
            }

            if (!isValid) {
                  alert.setSnack({
                        msg: t("Please fill all mandatory field"),
                  })
            }
            setData({ ...data, error });
            return isValid;
      };

      const mapResultData = (map) => {
            let result = mapResult(map);
            setData({ ...data, ...result })
      }
      const updateimg = async (data) => {
            backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading...", });
            try {
                  const uploaded_file = await singleFileUpload(data, { companyId: state?.main?.company?.value, type: assestType?.Thumbnails }, alert, allowed_file_size)
                  if (uploaded_file?.[0]?.url) {
                        updateState("image", uploaded_file?.[0]?.url)
                        backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
                  } else {
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                  }
            }
            catch (err) {
                  backdrop.setBackDrop({
                        ...backdrop, open: false,
                        message: t("Some Thing Went Wrong")
                  });
            }
      }

      const render = () => {
            return <>
                  <Subheader title={t("SERVICE PROVIDER ADD/EDIT")} goBack={() => history.goBack(-1)} />
                  {/* <div className={classes.content}>
                        <ServiceProviderDetails t={t} id='pushTitle' data={data} updateState={updateState} mapResult={mapResultData}
                              removeimg={removeimg} updateimg={updateimg} mapLoad={mapLoad} load={load} />
                  </div> */}

                  <PropertyTabs
                        value={value} 
                        backgroundColor
                        t={t}
                        tab1={(
                              <Box className={classes.root2}>
                                    <ServiceProviderDetails t={t} id='pushTitle' data={data} updateState={updateState} mapResult={mapResultData}
                                          removeimg={removeimg} updateimg={updateimg} mapLoad={mapLoad} load={load} />
                              </Box>
                        )}
                        isHeigth
                        tabTittle={tabTittle}
                        showBtns={true}
                        isSaveDisable={disable === true ? true : false}
                        saveBtnText={state?.main?.serviceProviderID ? t("Update") : t("Submit")}
                        goBack={() => history.goBack(-1)}
                        save={() => checkSave()}
                  />
                  {/* <Box className={classes.bottombtn}>
                        <Button className={classes.Cancel}
                              onClick={() => history.goBack(-1)}>
                              {t("Cancel")}
                        </Button>
                        <Button className={classes.next} onClick={checkSave}
                              disabled={disable === true ? true : false}>
                              {state?.main?.propertyID ? t("Update") : t("Submit")}
                        </Button>
                  </Box> */}
            </>
      }

      return <div className={classes.root}>
            {accessCheckRender(render, permission)}
      </div>
}
export default withNamespaces("serviceProviderView")(ServiceProviderCreateEdit)