import { Box, Grid } from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  InvoiceDetailsHead,
  InvoiceDetailsPath,
  NetWorkCallMethods
} from "../../utils";
import {
  CardDetails,
} from "../propertymanagerdashboard/components";
import { constractData1, constructResponseGetRevenueInvoiceTypeStats, getYearList, overdueHead, overduePath, revenueHead, revenuePath } from "./common";
import { dashboardStyles } from "./style";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import GraphComponent from "../propertymanagerdashboard/components/graphcomponent";
import { BackendRoutes, Routes } from "../../router/routes";

const RevenueDashboard = (props) => {
  const graph_offset = 0;
  const graph_page = 1;
  // classes
  const { t = () => false } = props;
  const classes = dashboardStyles(props);
  const [details, setDetails] = useState(null);
  const [invoiceDueDetails, setInvoiceDueDetails] = useState([])
  const [collectionDetails, setCollectionDetails] = useState([])
  const [projectedRevenue, setProjectedRevenue] = useState([])
  const [rentalRevenue, setRentalRevenue] = useState([])
  // context
  const alert = React.useContext(AlertContext);

  const [zoom, setZoom] = useState(false);
  const [zoomGraph, setZoomGraph] = useState("");
  const [loader, setLoader] = useState(false);
  const [limit, setLimit] = React.useState(10)
  const history = useHistory()
  const [page, setPage] = React.useState(1)
  const [graph_option_state,set_graph_option_state] = useState({
    revenue_invoice_type: { offset: graph_offset, page: graph_page },
  });
  const [revenue_invoice_type_state, set_revenue_invoice_type_state] = useState({ total: 0, data: [] });
  const zoomGraphFunction = (e) => {
    setZoomGraph(e);
    setZoom(true);
  };
  // close popup
  const handleCloseModule = () => {
    setZoom(false);
  };
  const scale = {
    x: {
      stacked: true,
      grid: {
        // drawBorder: false,
        display: false,
      }
    },
    y: {
      stacked: true,
      grid: {
        // drawBorder: false,
        display: false,
      }
    },
  };
  const scaleLine = {
    x: {
      grid: {
        // drawBorder: false,
        display: false,
      }
    },
    y: {
      grid: {
        // drawBorder: false,
        display: false,
      }
    },
  };
  const [years,setYears] = React.useState([])
  const [selectedYear,setSelectedYear] = React.useState()
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  //getDetails 
  const getDetails = () => {
    setLoader(true);
    const payload = {
      company_id: props?.selectedcompany?.value ?? "",
      "start_date": moment(props?.calandervalue?.value?.from_date).format(
        "YYYY-MM-DD"
      ),
      "end_date": moment(props?.calandervalue?.value?.to_date).format(
        "YYYY-MM-DD"
      )
    }
    NetworkCall(
      `${config.api_url}/finance_dashboard/`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const resut = constractData1(res?.data?.data ?? {}, t, props?.selectedcompany?.currency_symbol);
        setDetails(resut);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  }
  //getInvoice Due Details
  const getInvoiceDueDetails = (offset = 0, limit = 10) => {
    // setLoader(true);
    const payload = {
      company_id: props?.selectedcompany?.value ?? "",
      "start_date": moment(props?.calandervalue?.value?.from_date).format(
        "YYYY-MM-DD"
      ),
      "end_date": moment(props?.calandervalue?.value?.to_date).format(
        "YYYY-MM-DD"
      ),
      offset: offset,
      limit: limit
    }
    NetworkCall(
      `${config.api_url}/finance_dashboard/get_invoice_due_details`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructed = res?.data?.data?.data?.map((e) => {
          return {
            "invoice_due_amount": `${props?.selectedcompany?.currency_symbol}${e?.invoice_due_amount?.toLocaleString()}`,
            "invoice_no": e?.invoice_no,
            "payment_due_date": e?.payment_due_date,
            "name": e?.name,
            "account_no": e?.account_no
          }
        })
        setInvoiceDueDetails({ list: constructed, count: res?.data?.data?.count })
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  }
  const getCollectionStatus = () => {
    setLoader(true);
    const payload = {
      company_id: props?.selectedcompany?.value ?? "",
      "year": moment(new Date()).format("YYYY")
    }
    NetworkCall(
      `${config.api_url}/finance_dashboard/get_collection`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = res?.data?.data;
        let collectionStatus = {
          labels: result?.map((x) => { return moment(x?.month).format("MMM") }),
          datasets: [
            {
              label: 'Invoiced',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.total_amount : 0 }),
              barThickness: 5,
              backgroundColor: '#FF9340',
            },
            {
              label: 'Collected',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.collected_amount : 0 }),
              barThickness: 5,
              backgroundColor: '#25C967',
            },
            {
              label: 'Overdue',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.overdue_amount : 0 }),
              barThickness: 5,
              backgroundColor: '#58D0E0',
            },
          ]
        }
        setCollectionDetails(collectionStatus)
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  }
  const getProjectedRevenueDetails = (year) => {
    // setLoader(true);
    const payload = {
      company_id: props?.selectedcompany?.value ?? "",
      year: year
    }
    NetworkCall(
      `${config.api_url}/finance_dashboard/get_project_revenue`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = res?.data?.data
        let projected_revenue_overtime = {
          labels: result?.map((x) => { return moment(x?.month).format("MMM") }),
          datasets: [
            {
              label: 'Invoiced',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.total_amount : 0 }),
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgb(53, 162, 235)',
            },
            {
              label: 'Collected',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.collected : 0 }),
              borderColor: '#FF9340',
              backgroundColor: '#FF9340',
            },
          ]
        }
        setProjectedRevenue(projected_revenue_overtime)
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  }
  const getRevenueByRental = (year) => {
    // setLoader(true);
    const payload = {
      company_id: props?.selectedcompany?.value ?? "",
      year: year
    }
    NetworkCall(
      `${config.api_url}/finance_dashboard/get_invoice_revenue_by_rental`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = res?.data?.data
        let rentalRevenue = {
          labels: result?.map((x) => { return moment(x?.month).format("MMM") }),
          datasets: [
            {
              label: 'Primary',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.primary : 0 }),
              borderColor: '#58D0E0',
              backgroundColor: '#58D0E0',
            },
            {
              label: 'Refundable',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.refundable : 0 }),
              borderColor: '#D25ED6',
              backgroundColor: '#D25ED6',
            },
            {
              label: 'One Time',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.is_onetime : 0 }),
              borderColor: '#F7DF02',
              backgroundColor: '#F7DF02',
            },
            {
              label: 'Parking',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.is_parking : 0 }),
              borderColor: '#FF9340',
              backgroundColor: '#FF9340',
            },
            {
              label: 'Short Term',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.is_short_term : 0 }),
              borderColor: '#5AC782',
              backgroundColor: '#5AC782',
            },
            {
              label: 'Security',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.is_security : 0 }),
              borderColor: '#FF9340',
              backgroundColor: '#FF9340',
            },
            {
              label: 'Taxable',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.taxtable : 0 }),
              borderColor: '#A9ACFF',
              backgroundColor: '#A9ACFF',
            },
            {
              label: 'Quantity',
              data: result?.map((val, i) => { return months?.includes(moment(val?.month).format("MMM")) ? val?.is_quantity : 0 }),
              borderColor: '#686AF3',
              backgroundColor: '#686AF3',
            },
          ]
        }
        setRentalRevenue(rentalRevenue)
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  }

  // Function to handle pagination
  const handleTablePagination = (value) => {
    setPage(value)
    let offset = (value - 1) * limit
    getInvoiceDueDetails(offset, 10)
  }

  // Function to handle page limit
  const handleTablePageLimit = (value) => {
    setLimit(value)
    setPage(1)
    getInvoiceDueDetails(0, limit)
  }

  // getDetails
  // const getDetails = () => {
  //   setLoader(true);
  //   let teamsId = props?.selectedTeams?.value;
  //   const payload = {
  //     company_id: props?.selectedcompany?.value ?? "",
  //     start_date: `${moment(props?.calandervalue?.value?.from_date).format(
  //       "YYYY-MM-DD"
  //     )} 00:00:00`,
  //     end_date: `${moment(props?.calandervalue?.value?.to_date).format(
  //       "YYYY-MM-DD"
  //     )} 23:59:59`,
  //   };
  //   if (teamsId !== "noteams") {
  //     payload["team"] = teamsId
  //     payload["team_all_property"] = true

  //   }
  //   NetworkCall(
  //     `${config.api_url}/dashboard/lead/revenue_dashboard  `,
  //     NetWorkCallMethods.post,
  //     payload,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((res) => {
  //       const resut = constractData1(res?.data?.data ?? {});
  //       setDetails(resut);
  //       setLoader(false);
  //     })
  //     .catch((error) => {
  //       setLoader(false);
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: t("Some Thing Went Wrong"),
  //         vertical: AlertProps.vertical.top,
  //         horizontal: AlertProps.horizontal.center,
  //       });
  //     });
  // };

  useEffect(() => {
    if (props?.selectedcompany?.label?.length > 0) {
      const yearlist = getYearList();
      setYears(yearlist)
      setSelectedYear({ value: (moment(new Date()).format("YYYY")), label: moment(new Date()).format("YYYY") })
      getDetails();
      getInvoiceDueDetails(0, 10);
      getCollectionStatus();
      getProjectedRevenueDetails(moment(new Date()).format("YYYY"));
      getRevenueByRental(moment(new Date()).format("YYYY"))
      getRevenueInvoiceTypeStats({ company: props?.selectedcompany });

    }
    // eslint-disable-next-line
  }, [props?.selectedTeams, props?.calandervalue]);

  const getRevenueInvoiceTypeStats = ({
    company = props?.selectedcompany,
    from_date = props?.calandervalue?.value?.from_date,
    to_date = props?.calandervalue?.value?.to_date,
    offset = graph_option_state?.revenue_invoice_type?.offset,
    limit = graph_option_state?.revenue_invoice_type?.limit,
  }) => {
    const payload = {
      company_id: company?.value ?? "",
      start_date: moment(from_date).format("YYYY-MM-DD"),
      end_date: moment(to_date).format("YYYY-MM-DD"),
      offset, limit,
    };

    NetworkCall(
      `${config.api_url}${BackendRoutes?.finance_dashboard_get_invoice_type}`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
      const result = constructResponseGetRevenueInvoiceTypeStats({ data: res?.data?.data });
      set_revenue_invoice_type_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };
  
  const occupancyNavigate = (type, data, status) => {
        history.push({
            pathname: Routes.invoiceView,
            state: data?.id
        })
}

  return (loader ?
    (
      <NewLoader minusHeight="100px" />
    ) :
    (<div className={classes.root}>
      {" "}
      {/* section 1 */}
      <Grid container spacing={2}>
        <Grid item xs={12} >
          {/* property cards */}
          <Grid container spacing={2} >
            {details?.accountDetails?.map((x) => {
              return (
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <CardDetails data={x} maxWidth={"200px"} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} sx={{ display: "flex" }}>
          {/* Invoices By Status */}
          {/* <GraphComponent
            title={"Invoices by Status"}
            header={t("Invoices by Status")}
            graphData={details?.invoice_by_status ?? []}
            isPie
            innerRadius={75}
            isTotal
            total={details?.totalInvoices ?? 0}
            margin={"40px"}
            paddingAngle={2}
            isZoom
            onZoomClick={zoomGraphFunction}
            maxWidthGraph={"244px"}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 7.4,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 4.6,
                md: 12,
                lg: 12
              }
            }
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '16px',
                sm: "0px important",
              },
              bottom: {
                lg: "auto",
                md: '16px',
                sm: "0px",
              },
              height: {
                md: "400px"
              }
            }}
          /> */}
          <Grid container spacing={2}>
            {
              details?.customerDetail?.map((data) => {
                return (
                  <Grid item xs={12} sm={3} md={12} lg={12}>
                    <CardDetails data={data} maxWidth={"200px"} />
                  </Grid>
                )
              })
            }
            {/* <Grid item xs={4}>
              <CardDetails data={customerCount?.[0]} maxWidth={"200px"} />
            </Grid>
            <Grid item xs={4}>
              <CardDetails data={customerCount?.[0]} maxWidth={"200px"} />
            </Grid>
            <Grid item xs={4}>
              <CardDetails data={customerCount?.[0]} maxWidth={"200px"} />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          {/* Invoice Due Details By Customer */}
          <GraphComponent
            isTable
            t={t}
            title={"Overdue Invoices"}
            header={t("Overdue Invoices")}
            tableData={invoiceDueDetails?.list ?? []}
            occupancyNavigate={false}
            tabelheight={"400px"}
            heading={InvoiceDetailsHead(t)}
            path={InvoiceDetailsPath}
            showpagination={true}
            totalRowsCount={invoiceDueDetails?.count}
            page={page}
            limit={limit}
            handlePagination={handleTablePagination}
            handleChangeLimit={handleTablePageLimit}
            dataType={[
              { type: ["text"], name: "name" },
              { type: ["text"], name: "invoice_no" },
              { type: ["date"], name: "payment_due_date" },
              { type: ["text"], name: "invoice_due_amount" },
              // { type: ["text"], name: "due_split" },
            ]}
          />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={3} lg={3}>
          <GraphComponent
            title={"Invoices By Type"}
            header={t("Invoices By Type")}
            graphData={details?.invoice_by_type ?? []}
            isPie
            innerRadius={75}
            isTotal
            total={details?.totalInvoicesByType ?? 0}
            margin={"40px"}
            paddingAngle={2}
            isZoom
            onZoomClick={zoomGraphFunction}
            maxWidthGraph={"244px"}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 7.4,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 4.6,
                md: 12,
                lg: 12
              }
            }
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '16px',
                sm: "0px important",
              },
              bottom: {
                lg: "auto",
                md: '16px',
                sm: "0px",
              },
              height: {
                md: "400px"
              }
            }}
            t={t}
          />
        </Grid> */}

        <Grid item xs={12} sm={12} md={5} lg={5}>
          {/* Revenue by invoice type*/}
        <GraphComponent
          t={t}
          indexAxis={"y"}
          graphTitle={"Revenue by Invoice Type"}
          chartHeader={t("Revenue by Invoice Type")}
          chartHeight={480}
          padding={"0px 16px"}
          graphData={revenue_invoice_type_state?.data ?? []}          
          race_bar
          disableNoData
          scale={scale}
          justifyContent={'start'}
          gradiantStyle={{
            margin: '15px 0px 0px',
            top: { lg: "auto", md: '7px', sm: "3px", },
            height: { sm: "63px" }
          }}
          isNoLegend
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <GraphComponent
            graphTitle={"Invoice Ageing"}
            chartHeader={t("Invoice Ageing")}
            chartHeight={450}
            padding={"0px 16px"}
            color={["#FF8064"]}
            graphColor={["#FF8064"]}
            graphData={details?.invoice_ageing ?? []}
            is_line_bar
            scale={scale}
            gheight={300}
            isNoLegend  
            maxWidthGraph={"250px"}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <GraphComponent
            graphTitle={"Forcast Revenue for last 12 Months"}
            chartHeader={t("Forcast Revenue for last 12 Months")}
            chartHeight={450}
            padding={"0px 16px"}
            colors={["#F29000"]}
            noGraphtitle
            graphColor={["#F29000"]}
            graphData={details?.foreCasted ?? []}
            is_line_bar 
            gheight={300}
            scale={scale}
            justifyContent={'start'}
            t={t}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          {/*  */}
          <GraphComponent
            graphTitle={"Collections for the last 12 Months"}
            chartHeader={t("Collections for the last 12 Months")}
            chartHeight={480}
            padding={"0px 16px"}
            colors={["#7CB10D"]}
            graphColor={["#7CB10D"]}
            graphData={details?.collectedForeCasted ?? []}
            is_line_bar
            noGraphtitle
            scale={scale}
            gheight={300}
            // isLegend
            maxWidthGraph={"250px"}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
            t={t}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={6}>
          {/* Invoices By Type */}
          <GraphComponent
            graphTitle={"Invoiced Vs Collections for the Current Year"}
            chartHeader={t("Invoiced Vs Collections for the Current Year")}
            chartHeight={480}
            padding={"0px 16px"}
            graphData={projectedRevenue ?? []}
            t={t}
            line_new
            scale={scaleLine}
            isNoLegend
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <GraphComponent
            title={"Top 10 Revenue Accounts"}
            header={t("Top 10 Revenue Accounts")}
            isTableWithInfinity
            tableData={details?.topTenRevenueAccount ?? []}
            heading={revenueHead(t)}
            path={revenuePath}
            dataType={[
              { type: ["text"], name: "name" },
              { type: ["text"], name: "amount_paid" },
            ]}
            tabelheight={500}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <GraphComponent
            graphTitle={"Expiring Agreements for the last 12 Months"}
            chartHeader={t("Expiring Agreements for the last 12 Months")}
            chartHeight={480}
            padding={"0px 16px"}
            colors={["#83C19F"]}
            graphColor={["#83C19F"]}
            graphData={details?.expiring_agreements ?? []}
            is_line_bar
            scale={scale}
            gheight={350}
            // isLegend
            maxWidthGraph={"250px"}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
            t={t}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <GraphComponent
            title={"Top 10 Overdue Accounts"}
            header={t("Top 10 Overdue Accounts")}
            isTableWithInfinity
            tableData={details?.topTenOverDueAccount ?? []}
            heading={overdueHead(t)}
            path={overduePath}
            dataType={[
              { type: ["text"], name: "name" },
              { type: ["text"], name: "invoice_total_amount" },
            ]}
            tabelheight={450}
          />
        </Grid>


        {/* <NewLineChart /> */}
        {/* <Grid item xs={12} sm={12} md={6} lg={6}>
          <GraphComponent
            title={"Actual Revenue"}
            header={"Actual Revenue"}
            graphData={[
              {
                name: 'Page A',
                uv: 4000,
                pv: 2400,
                amt: 2400,
              },
              {
                name: 'Page B',
                uv: 3000,
                pv: 1398,
                amt: 2210,
              },
              {
                name: 'Page C',
                uv: 2000,
                pv: 9800,
                amt: 2290,
              },
              {
                name: 'Page D',
                uv: 2780,
                pv: 3908,
                amt: 2000,
              },
              {
                name: 'Page E',
                uv: 1890,
                pv: 4800,
                amt: 2181,
              },
              {
                name: 'Page F',
                uv: 2390,
                pv: 3800,
                amt: 2500,
              },
              {
                name: 'Page G',
                uv: 3490,
                pv: 4300,
                amt: 2100,
              },
            ]}
            newLineGraph
            margin={"40px"}
            scale={scale}
            t={t}
            gheight={350}
            isZoom
            onZoomClick={zoomGraphFunction}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
          />
        </Grid> */}

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <GraphComponent
            t={t}
            graphTitle={"Revenue by Pricing Components"}
            chartHeader={t("Revenue by Pricing Components")}
            chartHeight={500}
            height={"400px"}
            graphData={details?.invoice_by_type ?? []}
            innerRadius={45}
            padding={"10px 16px"}
            is_pie_new
            margin={"20px"}
            textMargin={'0px 0px 16px'}
          />
        </Grid>

        {/* <Grid item xs={12} sm={8} lg={3}>
          unit graph
          <GraphComponent
            title={"Agreements"}
            graphData={details?.active_agreement_by_agreement_type ?? []}
            isPie
            margin={"20px"}
            isZoom
            onZoomClick={zoomGraphFunction}
            maxWidthGraph={"288px"}
          />
        </Grid>
        Agreement Revenue For 3 Years
        <Grid item xs={12} sm={12} lg={3}>
          <GraphComponentRevenue
            title={"Agreement Revenue For 3 Years"}
            type={"barline"}
            height={"310px"}
            LineKey={"line"}
            barKey={"bar"}
            data={details?.agreement_revenue_for_three_years ?? []}
            revenue
            margin={"20px 10px 0px 0px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid> */}
      </Grid>
      {/* section2 */}
      {/* <Grid container spacing={2} mt={0.3}>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          
          <GraphComponent
            title={"Accounts Receivable Over Time"}
            header={t("Accounts Receivable Over Time")}
            graphData={details?.accountsReceivable ?? []}
            isPie
            innerRadius={75}
            centerTitle="Overdue"
            isTotal
            total={details?.accountsReceivableOverDue ?? 0}
            margin={"50px"}
            isZoom
            onZoomClick={zoomGraphFunction}
            maxWidthGraph={"250px"}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 7.4,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 4.6,
                md: 12,
                lg: 12
              }
            }
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '16px',
                sm: "0px important",
              },
              bottom: {
                lg: "auto",
                md: '16px',
                sm: "0px",
              },
              height: {
                md: "400px"
              }
            }}
            t={t}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <GraphComponent
            title={"Expiring Agreements"}
            header={t("Expiring Agreements")}
            graphData={details?.expiring_agreements ?? []}
            margin={"40px"}
            t={t}
            isBar
            scale={scale}
            gheight="300px"
            isDatalabels={false}
            isNoLegend
            onZoomClick={zoomGraphFunction}
            isZoom
            justifyContent={'start'}
            indexAxis={"y"}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <GraphComponent
            title={"Top 5 Outstanding Accounts"}
            header={t("Top 5 Outstanding Accounts")}
            graphData={details?.top5accounts ?? []}
            isPie
            innerRadius={75}
            isTotal
            total={details?.top5accountsTotal ?? 0}
            margin={"40px"}
            paddingAngle={2}
            isZoom
            onZoomClick={zoomGraphFunction}
            maxWidthGraph={"250px"}
            textMargin={'0px 0px 16px'}
            t={t}
            divider1={
              {
                xs: 12,
                sm: 7.4,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 4.6,
                md: 12,
                lg: 12
              }
            }
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '16px',
                sm: "0px important",
              },
              bottom: {
                lg: "auto",
                md: '16px',
                sm: "0px",
              },
              height: {
                md: "400px"
              }
            }}
          />
        </Grid>
      </Grid> */}
      {/* section 3 */}
      {/* <Grid container spacing={2} mt={0.3}>
        <Grid item xs={12} sm={12} lg={6}>
          <GraphComponent
            title={"Projected Revenue Overtime --- hide"}
            header={t("Projected Revenue Overtime")}
            graphData={projectedRevenue ?? []}
            t={t}
            line
            scale={scaleLine}
            select
            selectValue={selectedYear}
            handleChange={handleChangeYear}
            options={years}
            gheight={300}
            isZoom
            onZoomClick={zoomGraphFunction}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
          // maxWidthGraph={"250px"}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <GraphComponent
            title={"Collection Status (Invoiced Vs Collected Vs Overdue)"}
            header={t("Collection Status (Invoiced Vs Collected Vs Overdue)")}
            graphData={collectionDetails ?? []}
            isBar
            scale={scale}
            t={t}
            gheight={300}
            isLegend
            isZoom
            onZoomClick={zoomGraphFunction}
            maxWidthGraph={"250px"}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponentRevenue
            title={"Invoiced Revenue For 3 Years"}
            type={"barline"}
            height={"360px"}
            LineKey={"line"}
            barKey={"bar"}
            data={details?.invoiced_revenue_For_tree_years ?? []}
            revenue
            margin={"20px 0px 0px 0px"}
            cardGraphPadding={0}
            padding={"19px 19px 0px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        Expiring Agreements
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponentRevenue
            title={"Expiring Agreements"}
            type={"bar"}
            height={
              details?.expiring_agreements?.length > 0 ? "361px" : "434px"
            }
            XAxisKey={"name"}
            yAxisKey={"value"}
            data={details?.expiring_agreements ?? []}
            revenue
            margin={"20px 0px 0px 0px"}
            cardGraphPadding={0}
            padding={"19px 19px 0px"}
            isHorzontal
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
        Tenant Mix By Activity
        <Grid item xs={12} sm={6} lg={3}>
          <GraphComponent
            title={"Tenant Mix By Activity"}
            graphData={details?.Tenant ?? []}
            isPie
            margin={"20px"}
            isZoom
            onZoomClick={zoomGraphFunction}
          />
        </Grid>
      </Grid> */}
      {/* section4 */}
      {/* <Grid container spacing={2} mt={0.3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <GraphComponent
            title={"Age Summary ---hide"}
            header={t("Age Summary")}
            graphData={details?.age_summary ?? []}
            isBar
            scale={scale}
            gheight={300}
            // isLegend
            isNoLegend
            isZoom
            onZoomClick={zoomGraphFunction}
            maxWidthGraph={"250px"}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
            t={t}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <GraphComponent
            title={"Invoices Revenue By Rental Components Over Time"}
            header={t("Invoices Revenue By Rental Components Over Time")}
            graphData={rentalRevenue ?? []}
            isBar
            scale={scale}
            gheight={300}
            isLegend
            isZoom
            onZoomClick={zoomGraphFunction}
            maxWidthGraph={"250px"}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}
            t={t}
            position={'relative !important'}
          />
        </Grid>


        <Grid item xs={12} sm={12} md={4} lg={4}>
          Top 5 Outstanding Properties
        <GraphComponent
          title={"Top 5 Outstanding Properties"}
          graphData={details?.invoice_by_status ?? []}
          isPie
          innerRadius={75}
          isTotal
          total={details?.totalInvoices ?? 0}
          margin={"20px"}
          paddingAngle={2}
          isZoom
          onZoomClick={zoomGraphFunction}
          maxWidthGraph={"250px"}
          textMargin={'0px 0px 16px'}
          divider1={
            {
              xs: 12,
              sm: 7.4,
              md: 12,
              lg: 12
            }
          }
          divider={
            {
              xs: 12,
              sm: 4.6,
              md: 12,
              lg: 12
            }
          }
          gradiantStyle={{
            margin: '15px 0px 0px',
            top: {
              lg: "auto",
              md: '16px',
              sm: "0px important",
            },
            bottom: {
              lg: "auto",
              md: '16px',
              sm: "0px",
            },
            height: {
              md: "400px"
            }
          }}
        />
      </Grid> 
   Top 10 Outstanding By Properties
        <Grid item xs={12} sm={12} lg={4}>

          <GraphComponent
            title={"Top 10 Outstanding By Properties"}
            tableData={details?.topproperties ?? []}
            heading={top10PropertyHead}
            path={top10PropertyPath}
            dataType={[
              { type: ["text"], name: "name" },
              { type: ["text"], name: "amount" },
            ]}
            tabelheight={"calc(100vh - 450px)"}
            isTable
            revenue
          />
        </Grid>
        Top 10 Outstanding By Agreement
        <Grid item xs={12} sm={6} lg={4}>
          <GraphComponent
            title={"Top 10 Outstanding By Agreement"}
            tableData={details?.topagreement ?? []}
            heading={top10AgreeMentHead}
            path={top10AgreeMentPath}
            dataType={[
              { type: ["text"], name: "name" },
              { type: ["text"], name: "amount" },
            ]}
            tabelheight={"calc(100vh - 450px)"}
            isTable
            revenue
          />
        </Grid>
        Top 10 Outstanding By Account
        <Grid item xs={12} sm={6} lg={4}>
          <GraphComponent
            title={"Top 10 Outstanding By Account"}
            tableData={details?.topaccount ?? []}
            heading={top10AccountHead}
            path={top10AccountPath}
            dataType={[
              { type: ["text"], name: "number" },
              { type: ["text"], name: "name" },
              { type: ["text"], name: "amount" },
            ]}
            tabelheight={"calc(100vh - 450px)"}
            isTable
            revenue
          />
        </Grid>
      </Grid > */}
      {/* section 5 */}

      {/* zoom dialog for each graph */}

    </div >)
  );
};
export default withNamespaces("leaseAndSalesManagerDashboard")(RevenueDashboard);