import { Stack, Typography } from "@mui/material"
import { useStyles } from "./style"

export const LineIndicatorTab = ({
    t = () => false,
    path = [],
    data = {},
    selected = {},
    onClick = () => false,
}) => {
    const classes = useStyles()

    return <Stack direction={"row"} columnGap={"24px"}>
        {path?.map((i) => {
            return <Stack className={classes.tab} spacing={"12px"} onClick={() => onClick(data?.[i])}>

                <div />
                <Typography
                    className={
                        selected?.value === i ?
                            classes.selected_tab_text :
                            classes.unselected_tab_text
                    }>
                    {`${t(data?.[i]?.label)}${data?.[i]?.hasOwnProperty?.('count') ? ` (${data?.[i]?.count})` : ``}`}
                </Typography>
                <div className={
                    selected?.value === i ?
                        classes.selected_tab_indicator :
                        classes.unselected_tab_indicator
                } />
            </Stack>
        })}
    </Stack>
}