import gql from "graphql-tag";

export const ParkingGroupingMasterHeading = (t) => [
  { title: t("Parking Grouping Name"), field: "name" },
  { title: t("Property Name"), field: "property" },
  { title: t("Description"), field: "description" },
  { title: t("Status"), field: "status" },
  { title: "", field: "icon" },
];
export const ParkingGroupingMasterPath = ["name", "property", "description", "status"];

export const GET_PARKING_GROUPING_MASTER_TABLE_LIST = (client = "", offset = 0, limit = 10, searchText = "", propertyId = "", status = []) => {
  return gql`
query GET_PARKING_GROUPING_MASTER_TABLE_LIST($id:ID){
  count:parking_grouping_master(
     where: {
        property_id: { eq: "${propertyId}" }
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ''}
        name: { iregex: "${searchText}" }
       property:{ client:{eq:"${client}"}}
    }){
      count:count_id
    }
  parking_grouping_master(
     where: {
   property_id: { eq: "${propertyId}" }
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ''}
        name: { iregex: "${searchText}" }
       property:{ client:{eq:"${client}"}}
    }
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
    id
   name
   description
   property{
   value: id
    label:name
   }
    is_active
  }
}

`
}
export const UPDATE_PARKING_GROUPING_MASTER = gql`
mutation UPDATE_PARKING_GROUPING_MASTER(
    $ID: ID
    $updatePayload: parkingGroupingMastersInput
  ) {
    parking_grouping_master(
      where: { id: { eq: $ID } }
      update: $updatePayload
    ) {
      id
    }
  }`