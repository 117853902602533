import { Typography, Box, Grid } from '@mui/material';
import React from 'react'
import { dashboardStyles } from "./style";
import { TableWithPagination } from '../../components';
import { SecurityDashBoardsHeading, SecurityDashBoardsPath } from "../../utils";


export const Card = (props) => {
      const classes = dashboardStyles(props);
      return (
            <div className={classes.card}>
                  <Typography className={classes.title}>{props?.title}</Typography>
                  {
                        props?.isTabel ?
                              <Grid container spacing={2} className={classes.section}>
                                    <Grid item xs={12}>
                                          {/* tabel */}
                                          <TableWithPagination
                                                heading={SecurityDashBoardsHeading}
                                                rows={props?.tabellist ?? []}
                                                path={SecurityDashBoardsPath}
                                                showpagination={true}
                                                onUnitClick={() => (false)}
                                                showpdfbtn={false}
                                                showexcelbtn={false}
                                                showSearch={false}
                                                handleIcon={() => (false)}
                                                handlePagination={props?.handlePagination}
                                                handleChangeLimit={props?.handleChangeLimit}
                                                totalRowsCount={props?.count}
                                                page={props?.page}
                                                limit={props?.limit}
                                                dataType={[
                                                      { type: ["avatarmanagement"], name: "Image" },
                                                      { type: ["text"], name: "gate" },
                                                      { type: ["text"], name: "name" }
                                                ]}
                                                height={'calc(100vh - 350px)'}
                                                view={true}
                                                edit={true}
                                                delete={true} />
                                    </Grid>
                              </Grid>
                              :
                              <Grid container spacing={2} className={classes.section}>
                                    {
                                          props?.data?.map((x) => {
                                                return (
                                                      <Grid item xs={props?.divide}>
                                                            <Cards data={x} />
                                                      </Grid>
                                                )
                                          })
                                    }

                              </Grid>
                  }

            </div>
      )
}

const Cards = (props) => {
      const classes = dashboardStyles(props);
      return (
            <div className={classes.cardContainer}>
                  <Box className={classes.detailsCard1}>
                        <Box>
                              <Typography className={classes.count}>{props?.data?.title}</Typography>
                              <Typography className={classes.detailsCard2}>{props?.data?.sub}</Typography>
                        </Box>
                        <Box>
                              {props?.data?.image_type ? <img src={props?.data?.image} alt='images' /> : props?.data?.image}
                        </Box>
                  </Box>
            </div>
      )
}