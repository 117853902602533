import { useApolloClient } from "@apollo/client";
import { Grid } from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useState } from 'react';
import { ByCycle, FourWheeler, Security } from "../../assets/securitymanager";
import { Subheader } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GET_ACCESSTABEL, GET_ACCESS_CONTROL, GET_COUNT_OF_VECICLE_TYPE, GET_PARKING_SOLT_DETAILS, GET_SECURITY_GUARD_NO, GET_SECURITY_TABEL, GET_SECURITY_TABEL_BY_TYPE } from '../../graphql/dashboardQuery';
import { AlertProps, enumSelect, enum_types, getCompanyOption, getTotal, keyMapSource, LocalStorageKeys, startAndEndOfMonth, startAndEndOfWeek } from '../../utils';
import { GraphComponent } from '../leasemanagerdashboard/graphComponent';
import { Card } from './card';
import { dashboardStyles } from "./style";

const getMonth = [
      {
            label: 'Today',
            value: {
                  startDate: new Date(),
                  endDate: new Date(),
            }
      },
      {
            label: 'YesterDay',
            value: {
                  startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
                  endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
            }
      },
      {
            label: 'This Week',
            value: startAndEndOfWeek(new Date())
      },
      {
            label: 'This Month',
            value: startAndEndOfMonth()
      },
]




export const SecurityDashboard = (props) => {
      const auth = React.useContext(AuthContext);
      const [companyList, setCompanyList] = React.useState([]);
      // context
      const backdrop = React.useContext(BackdropContext);
      const alert = React.useContext(AlertContext);

      // initial state
      const initial = {
            Parking: getMonth?.[0],
            Movement: getMonth?.[0],
            Delivery: getMonth?.[0],
            property: [],
      }

      // classes
      const classes = dashboardStyles(props);
      // apolo clint
      const client = useApolloClient();
      // enum states
      const [enumValue, setEnumValue] = useState({
            parkingEnum: getMonth?.[0],
            movementEnum: getMonth?.[0],
            deleviryEnum: getMonth?.[0],
            DeliveryType: {},
            DeliveryStatus: {},
            properties: []
      })

      const [state, setState] = useState({ ...initial })
      // dashboard datas
      const [details, setDetails] = useState({
            deliveryData: {},
      })
      //access gate list state
      const [securitylist, setSecuritylist] = useState({
            count: '',
            list: []
      })
      // momment state
      const [momment, setMomment] = useState({})
      // security guard total
      const [securityGuardCount, setSecurityGuardCount] = useState({
            securitycount: ''
      })
      // veical count
      const [veicalCount, setVeicalcount] = useState({})
      // parking details
      const [parkingDetails, setParkingDetails] = useState({})

      const [selectedCompany, setSelectedCompany] = useState(null)

      const [page, setPage] = React.useState(1);
      const [limit, setLimit] = React.useState(10);

      // select box function
      const handleList = (key, e) => {
            setState({ ...state, [key]: e })
            if (key === 'Delivery') {
                  getSecurityDetails(enumValue?.DeliveryType?.type4, state?.property ?? [], moment(e?.value?.startDate).format("YYYY-MM-DD"), moment(e?.value?.endDate).format("YYYY-MM-DD"), enumValue?.DeliveryStatus)
            }
            if (key === 'Movement') {
                  getSecurityDetailsType(enumValue?.DeliveryType, state?.property, moment(e?.value?.startDate).format("YYYY-MM-DD"), moment(e?.value?.endDate).format("YYYY-MM-DD"), enumValue?.DeliveryStatus)
            }
            if (key === 'Parking') {
                  getParkingDetails(state?.property, moment(e?.value?.startDate).format("YYYY-MM-DD"), moment(e?.value?.endDate).format("YYYY-MM-DD"))
            }
      }

      // get security details
      const getSecurityDetails = (type, data, startDate, endDate, filterData2) => {
            client.query({
                  query: GET_SECURITY_TABEL(type, true, `${startDate} 00:00:00`, `${endDate} 23:59:59`, filterData2?.type1, filterData2?.type2, filterData2?.type3, filterData2?.type4),
                  fetchPolicy: 'network-only',
                  variables: {
                        "property_ids": data,
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID)
                  }
            }).then((res) => {
                  setDetails({ ...details, deliveryData: res?.data })
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })

      }
      // get Access Details
      const getAccessDetails = (data, offset, limit) => {
            client.query({
                  query: GET_ACCESSTABEL(),
                  fetchPolicy: 'network-only',
                  variables: {
                        "property_ids": data,
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID),
                        offset: offset,
                        limit: limit
                  }
            }).then((res) => {
                  const tabelData = res?.data?.access_gates_master?.map((x) => {
                        return {
                              Image: x?.user_profile?.image_url ?? "-",
                              gate: x?.gate_no,
                              // eslint-disable-next-line no-useless-concat
                              name: `${x?.user_profile?.first_name ?? "-"}` + `${x?.user_profile?.last_name?.length > 0 ? x?.user_profile?.last_name : ''}`,
                        }
                  })
                  setSecuritylist({ ...securitylist, count: res?.data?.count?.[0]?.count_id, list: tabelData })
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })

      }

      // GETaccess control
      const getProperty = (e) => {

            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            client.query({
                  query: GET_ACCESS_CONTROL,
                  fetchPolicy: 'network-only',
                  variables: {
                        companyID: e,
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID),
                        // roleID: localStorage.getItem(LocalStorageKeys.roleID)
                  }
            }).then((res) => {
                  setState({ ...state, property: res?.data?.access_control?.map((x) => x?.property_id) })
                  getEnum(res?.data?.access_control?.map((x) => x?.property_id))
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "Loading",
                  });
            }).catch((err) => {
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "Loading",
                  });
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }

      // get security by type details
      const getSecurityDetailsType = (type, data, startDate, endDate, filterData2) => {
            client.query({
                  query: GET_SECURITY_TABEL_BY_TYPE(type, `${startDate} 00:00:00`, `${endDate} 23:59:59`),
                  fetchPolicy: 'network-only',
                  variables: {
                        "property_ids": data,
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID),
                  }
            }).then((res) => {

                  setMomment(res?.data)
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })

      }

      // get total count of security guard
      const getSecurityGuardTotal = (data) => {
            client.query({
                  query: GET_SECURITY_GUARD_NO,
                  fetchPolicy: 'network-only',
                  variables: {
                        property_id: data,
                        role: "Security Guard",
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID),
                  }
            }).then((res) => {
                  setSecurityGuardCount({ ...securityGuardCount, securitycount: res?.data?.count?.[0]?.count_id })
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }

      // get vecicle type count
      const getVeicalType = (data, enumType) => {
            client.query({
                  query: GET_COUNT_OF_VECICLE_TYPE,
                  fetchPolicy: 'network-only',
                  variables: {
                        property_id: data,
                        fourwheeler: enumType?.type2,
                        twowheeler: enumType?.type1,
                        bicycle: enumType?.type3,
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID),
                  }
            }).then((res) => {
                  setVeicalcount(res?.data)
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }

      // GETaccess control
      const getParkingDetails = (data, startDate, endDate) => {
            client.query({
                  query: GET_PARKING_SOLT_DETAILS(startDate, endDate),
                  fetchPolicy: 'network-only',
                  variables: {
                        property_id: data ?? [],
                        clientID: localStorage.getItem(LocalStorageKeys.clinetID),
                  }
            }).then((res) => {
                  setParkingDetails(res?.data)
            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                  });
            })
      }


      // get enum
      const getEnum = async (data) => {

            const result = await enumSelect([enum_types?.security_request_type, enum_types?.delivery_status_type, enum_types?.vehicle_type])
            const filterData = await keyMapSource({ enumType: result?.security_request_type })
            const filterData2 = await keyMapSource({ enumType: result?.delivery_status_type })
            const filterData3 = await keyMapSource({ enumType: result?.vehicle_type })





            // get secuirty details
            getSecurityDetails(filterData?.type4, data, moment(state?.Delivery?.value?.startDate).format("YYYY-MM-DD"), moment(state?.Delivery?.value?.endDate).format("YYYY-MM-DD"), filterData2)

            getSecurityDetailsType(filterData, data, moment(state?.Movement?.value?.startDate).format("YYYY-MM-DD"), moment(state?.Movement?.value?.endDate).format("YYYY-MM-DD"), filterData2)


            getAccessDetails(data, 0, 10)

            getSecurityGuardTotal(data)

            getVeicalType(data, filterData3)

            getParkingDetails(data, moment(state?.Parking?.value?.startDate).format("YYYY-MM-DD"), moment(state?.Parking?.value?.endDate).format("YYYY-MM-DD"))





            setEnumValue({ ...enumValue, DeliveryType: filterData ?? {}, DeliveryStatus: filterData2 ?? {}, properties: data ?? [] })


            // getData(filterData, filterData2, filterData3, data)

      }


      // pagination function
      const handlePagination = (value) => {
            setPage(value);
            let offset = (value - 1) * limit;
            getAccessDetails(enumValue?.properties ?? [], offset, limit)
      }
      // pagination limit change function
      const handleChangeLimit = (value) => {
            setLimit(value);
            setPage(1);
            getAccessDetails(enumValue?.properties ?? [], 0, value)
      }


      // get socket function
      // const getData = (filterData, filterData1, filterData2, property_id) => {

      //       SocketSlug.addEventListener('message', function (event) {
      //             let tabelData = JSON.parse(event?.data)
      //             if (tabelData?.table === tabelName?.parking_slot || tabelData?.table === tabelName?.security_request) {
      //                   getParkingDetails(property_id, moment(state?.Parking?.value?.startDate).format("YYYY-MM-DD"), moment(state?.Parking?.value?.endDate).format("YYYY-MM-DD"))
      //             }
      //             if (tabelData?.table === tabelName?.security_request) {
      //                   getSecurityDetails(filterData?.type4, property_id, moment(state?.Delivery?.value?.startDate).format("YYYY-MM-DD"), moment(state?.Delivery?.value?.endDate).format("YYYY-MM-DD"), filterData1)

      //                   getSecurityDetailsType(filterData, property_id, moment(state?.Movement?.value?.startDate).format("YYYY-MM-DD"), moment(state?.Movement?.value?.endDate).format("YYYY-MM-DD"), filterData1)
      //             }
      //             if (tabelData?.table === tabelName?.access_control) {
      //                   getProperty()
      //             }
      //             if (tabelData?.table === tabelName?.access_gates_master) {
      //                   getAccessDetails(property_id, 0, 10)
      //             }
      //       });
      // }

      const handleCompanyChange = (e) => {
            setSelectedCompany(e)
            getProperty(e?.value)
      }

      useEffect(() => {
            let company = getCompanyOption(backdrop, auth, alert)
            if (company) {
                  setCompanyList(company?.list)
                  setSelectedCompany(company?.selected)
                  if (company?.list?.length > 0) {
                        getProperty(company?.selected?.value)
                  }
            }
            // eslint-disable-next-line
      }, [auth])


      // graph datas
      const graphData1 = [
            {
                  name: 'Visitors',
                  fill: '#58D0E0',
                  value: momment?.visitor?.[0]?.count
            },
            {
                  name: 'Workers',
                  fill: '#5AC782',
                  value: momment?.worker_gate?.[0]?.count
            },
            {
                  name: 'parking',
                  fill: '#FFED4B',
                  value: momment?.parking_pass?.[0]?.count
            },
            {
                  name: 'delivery',
                  fill: '#FF4B4B',
                  value: momment?.delivery_order?.[0]?.count
            },
      ]


      const graphData2 = [
            {
                  name: enumValue?.DeliveryStatus?.type1,
                  fill: '#58D0E0',
                  value: details?.deliveryData?.received_gate_count?.[0]?.count
            },
            {
                  name: enumValue?.DeliveryStatus?.type2,
                  fill: '#FFED4B',
                  value: details?.deliveryData?.yet_recived_gate_count?.[0]?.count
            },
            {
                  name: enumValue?.DeliveryStatus?.type3,
                  fill: '#FF4B4B',
                  value: details?.deliveryData?.cancelled_gate_count?.[0]?.count
            },
            {
                  name: enumValue?.DeliveryStatus?.type4,
                  fill: '#5AC782',
                  value: details?.deliveryData?.collected_gate_count?.[0]?.count
            }
      ]

      const cardData1 = [
            {
                  title: securityGuardCount?.securitycount ?? 0,
                  sub: 'No. Of Security Personal',
                  image: <Security />
            },
            // {
            //       title: '12',
            //       sub: 'No. Of Security Personal with App Access',
            //       image: <Access />
            // },
      ]

      // graph datas
      const graphData = [
            {
                  name: 'Occupied by Residents',
                  fill: '#58D0E0',
                  value: JSON.stringify(parkingDetails?.visitor_occupied?.[0]?.total_slots)?.length > 0 ? parkingDetails?.visitor_occupied?.[0]?.total_slots : 0
            },
            {
                  name: 'Occupied by Visitors',
                  fill: '#FFED4B',
                  value: JSON.stringify(parkingDetails?.Reserved_occupied?.[0]?.total_slots)?.length > 0 ? parkingDetails?.Reserved_occupied?.[0]?.total_slots : 0
            },
            {
                  name: 'Free Reserved Slots',
                  fill: '#FF4B4B',
                  value: JSON.stringify(parkingDetails?.Reserved_free?.[0]?.total_slots)?.length > 0 ? parkingDetails?.Reserved_free?.[0]?.total_slots : 0
            },
            {
                  name: 'Free Visitor Slots',
                  fill: '#5AC782',
                  value: JSON.stringify(parkingDetails?.Visitors_free?.[0]?.total_slots)?.length > 0 ? parkingDetails?.Visitors_free?.[0]?.total_slots : 0
            },
      ]


      // card datas
      const cardData = [
            {
                  title: veicalCount?.four_wheeler?.[0]?.count_id,
                  sub: 'Registered Four Wheelers',
                  image: <FourWheeler />
            },
            {
                  title: veicalCount?.bi_cycle?.[0]?.count_id,
                  sub: 'Registered Bi Cycles',
                  image: <ByCycle />
            },
            {
                  title: veicalCount?.two_wheeler?.[0]?.count_id,
                  sub: 'Registered Two wheelers',
                  image: 'images/twowheeler.svg',
                  image_type: true
            },
            {
                  title: veicalCount?.parking_violation_stats?.[0]?.violated_count ?? 0,
                  sub: 'Violation Parking',
                  image: 'images/police.svg',
                  image_type: true
            },
      ]

      return (
            <div>
                  <Subheader title="Security Manager" select options={companyList} value={selectedCompany} hideBackButton={true}
                        onchange={(e) => {
                              handleCompanyChange(e)
                        }

                        } />
                  <div className={classes.root}>
                        <Grid container spacing={2} >
                              {/* graph section */}
                              <Grid item xs={12} md={12} lg={6}>
                                    <Grid container spacing={3}>
                                          <Grid item xs={12}>
                                                {/* Parking Slots */}
                                                <GraphComponent dumydata={graphData} title={'Parking Slots'} graphData={graphData} isSelect option={getMonth ?? []} data={state} handleList={handleList} dropDownKey='Parking' dropDownState={state?.Parking} total={getTotal(graphData) ?? 0} />
                                          </Grid>
                                          <Grid item xs={12}>
                                                {/* Movement Statistics */}
                                                <GraphComponent dumydata={graphData1} title={'Movement Statistics'} graphData={graphData1} isSelect option={getMonth ?? []} data={state} handleList={handleList} dropDownKey='Movement' dropDownState={state?.Movement} total={momment?.visitor?.[0]?.count + momment?.worker_gate?.[0]?.count + momment?.parking_pass?.[0]?.count + momment?.delivery_order?.[0]?.count} />
                                          </Grid>
                                          <Grid item xs={12}>
                                                {/* Delivery Collections */}
                                                <GraphComponent dumydata={graphData2} title={'Delivery Collections'} graphData={graphData2} isSelect option={getMonth ?? []} handleList={handleList} dropDownKey='Delivery' dropDownState={state?.Delivery} total={details?.deliveryData?.received_gate_count?.[0]?.count + details?.deliveryData?.yet_recived_gate_count?.[0]?.count + details?.deliveryData?.cancelled_gate_count?.[0]?.count + details?.deliveryData?.collected_gate_count?.[0]?.count} />
                                          </Grid>
                                    </Grid>

                              </Grid>
                              {/* Card section */}
                              <Grid item xs={12} md={12} lg={6}>
                                    <Grid container spacing={3}>
                                          <Grid item xs={12}>
                                                {/* Registered Vehicles Statistics */}
                                                <Card title='Registered Vehicles Statistics' data={cardData} divide={6} />
                                          </Grid>
                                          <Grid item xs={12}>
                                                {/* Security Guards */}
                                                <Card title='Security Guards' data={cardData1} divide={12} />
                                          </Grid>
                                          <Grid item xs={12}>
                                                {/* Security In Charge On Duty */}
                                                <Card title='Security In Charge On Duty' isTabel count={securitylist?.count ?? ""} tabellist={securitylist?.list ?? []} handlePagination={handlePagination} handleChangeLimit={handleChangeLimit} page={page} limit={limit} />
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                  </div>
            </div>

      )
}
