import React from "react";
import { Typography, Box ,ClickAwayListener } from '@mui/material';
import { useStyles } from './styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getTemMailstone } from "../../graphql/quotationQueries";
import { useApolloClient } from "@apollo/client";
import { TickIcon } from '../svg';


export const TemplateDrawer = ({
    mistoneUpdate = () => false,
    milestoneTemplate = {},
    company_id = null,
    // is_selected = false,
}) => {
    const classes = useStyles()
    const client = useApolloClient();

    const [open, setOpen] = React.useState(false)
    const [mileStoneOptions, setMileStoneOptions] = React.useState([]);
    // const [offset, setOffset] = React.useState(0)
    

    // functions
    const getMailStoneOptions = (offset, filter) => {
     
        client
            .query({
                query: getTemMailstone(offset, 10, "", {}, company_id ?? null),
                fetchPolicy: "network-only",
                variables: {
                    company_id: company_id ?? null,
                    // offset: 0,
                    // limit: 10,
                },
            })
            .then((response) => {
                if (filter) {
                    setMileStoneOptions(mileStoneOptions.concat(response?.data?.milestone_template_master))
                }
                else {
                    setMileStoneOptions(response?.data?.milestone_template_master);
                }
                // if (is_selected) {
                //     mistoneUpdate(response?.data?.milestone_template_master?.[0])
                // }
            
            })
            .catch((err) => {
             console.log(err)
            });
    };
    // infinity scroll
    // const fetchMoreData = () => {
    //     setOffset(offset + 10);
    //     getMailStoneOptions(offset + 10, true);
    // };
    const openDrawer = (isOpen) => {
        if(isOpen){
            getMailStoneOptions(0, false)
        }
        setOpen(!open)
    }
    const updateFunction = (value) => {
        setOpen(!open)
        mistoneUpdate(value)
    }


    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div style={{ position: "relative" }}>
                {
                    !open ?
                        <Box className={classes.root} onClick={()=>openDrawer(true)}>
                            <Typography className={classes.templateTitle}>{milestoneTemplate?.label} <span className={classes.templateVariable}>( {milestoneTemplate?.template_type ?? "-"} )</span></Typography>
                            <KeyboardArrowDownIcon />

                        </Box> :
                        <Box className={open ? classes?.selectRoot : classes?.selectRoot1}>
                            <Box className={classes.select}>
                                <Typography className={classes.templateTitle}>{milestoneTemplate?.label} <span className={classes.templateVariable}>( {milestoneTemplate?.template_type ?? '-'} )</span></Typography>
                                <KeyboardArrowUpIcon />
                            </Box>
                            <Box p={1} height={`calc(100vh - 540px)`} style={{overflow:"auto"}}>
                                    {
                                        mileStoneOptions?.map((x, index) => {
                                            return (
                                                <Box className={index === mileStoneOptions?.length ? classes.select1 : classes.select} onClick={() => updateFunction(x)}>
                                                    <Typography className={classes.templateTitle}>{x?.label ?? "-"} <span className={classes.templateVariable}>( {x?.template_type ?? "-"} )</span></Typography>
                                                    {
                                                      milestoneTemplate?.value === x?.value &&   <TickIcon/>
                                                    }
                                                   
                                                </Box>
                                            )
                                        })
                                    }

                                </Box>


                            {/* <InfiniteScroll
                                dataLength={mileStoneOptions?.length ?? ""}
                                next={fetchMoreData}
                                hasMore={true}
                                height={`calc(100vh - 800px)`}
                            >
                                <Box p={1} >
                                    {
                                        mileStoneOptions?.map((x, index) => {
                                            return (
                                                <Box className={index === mileStoneOptions?.length ? classes.select1 : classes.select} onClick={() => updateFunction(x)}>
                                                    <Typography className={classes.templateTitle}>{x?.label ?? "-"} <span className={classes.templateVariable}>( {x?.template_type ?? "-"} )</span></Typography>
                                                    {
                                                      milestoneTemplate?.value === x?.value &&   <TickIcon/>
                                                    }
                                                   
                                                </Box>
                                            )
                                        })
                                    }

                                </Box>
                            </InfiniteScroll> */}
        
                        </Box>

                }

                {
                    open && <Box height="18px" />
                }
            </div>
        </ClickAwayListener>

    )
}