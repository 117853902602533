import React, { useState, useCallback, useEffect } from "react";
import { useStyles } from "../style";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useWindowDimensions, NetWorkCallMethods, AlertProps, SemiBold } from "../../../utils";
import moment from "moment-timezone";
import ImageViewer from "react-simple-image-viewer";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  GET_GENERAL_REQUEST,
  GET_MAINTANCE_REQUEST,
} from "../../../graphql/queries";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { AlertContext, BackdropContext } from "../../../contexts";
import { Bold } from "../../../utils";

export const View = (props) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  // context
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  // props
  const { request, CloseDialog, value } = props;
  // state
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [datas, setDatas] = useState({
    data: {},
    imagesListForView: [],
  });
  // get details
  const getDetails = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const data = {
      query:
        request === "Maintenance" ? GET_MAINTANCE_REQUEST : GET_GENERAL_REQUEST,
      variables: {
        generalRequestNo: value?.id,
        maintenanceRequestNo: value?.id,
        referenceId: value?.id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        const tempImageList = response?.data?.data?.images?.map(
          ({ url }) => url
        );
        setDatas({
          data: response.data.data ?? {},
          imagesListForView: tempImageList,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong please try again",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        });
      });
  };
  // open image viwer
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  // close image viwer
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  // track
  const track = [
    {
      date: datas?.data?.table?.[0]?.status?.[0]?.created_at,
      label: "Open",
      is_active: true
    },
    {
      date: datas?.data?.table?.[0]?.status?.[0]?.closed_on,
      label: "Closed",
      is_active: datas?.data?.table?.[0]?.status?.[0]?.type === 'Closed' ? true : false
    },
  ];

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <Grid container className={classes.titleroot}>
        <Grid item xs={11} sm={11} md={11} lg={11}>
          {/* title */}
          <Typography className={classes.bottomTitle}>{request}</Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} textAlign="right">
          {/* close icom */}
          <IconButton onClick={() => CloseDialog("close")} size="small">
            <CloseIcon className={classes.DialogCloseIcon} />
          </IconButton>
        </Grid>
      </Grid>
      <div style={{ height: size?.height - 300, overflow: "auto" }}>
        <Grid container padding="0px 20px">
          <Grid
            item
            xs={7}
            md={7}
            sm={7}
            lg={7}
            borderRight="1px solid #E4E8EE"
          >
            <div>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Box className={classes.service}>
                  <Typography className={classes.servicetittle}>
                    {datas?.data?.table?.[0]?.catagory?.type}
                  </Typography>
                </Box>
                <Box className={classes.service} style={{ marginLeft: "10px" }}>
                  <Typography className={classes.servicetittle}>
                    {datas?.data?.table?.[0]?.subcatagory?.type}
                  </Typography>
                </Box>
              </Box>
              <Grid marginTop="8px">
                <Typography className={classes.title}>
                  {datas?.data?.table?.[0]?.subject}
                </Typography>
                <Typography className={classes.title2}>
                  {datas?.data?.table?.[0]?.description}
                </Typography>
              </Grid>
              <Grid container textAlign="left" className={classes.headers}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container spacing={3}>
                    {datas?.data?.table?.[0]?.problemsince?.length > 0 && (
                      <Grid item xs={6} sm={6} lg={6} md={6}>
                        <Typography className={classes.quotationsummaryans}>
                          Problem Since
                        </Typography>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Box className={classes.waterLeakageIcons}>
                            <img
                              src="images/icons8-calendar (5).svg"
                              alt="no_img"
                            ></img>
                          </Box>

                          <Box className={classes.quotationsummaryquest}>
                            {moment(datas?.data?.table?.[0]?.problemsince)
                              .tz(moment.tz.guess())
                              .format("YYYY-MM-DD hh:mm")}
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    {datas?.data?.table?.[0]?.unit?.[0]?.unit?.name?.length > 0 && (
                      <Grid item xs={6} sm={6} lg={6} md={6}>
                        <Typography className={classes.quotationsummaryans}>
                          Unit
                        </Typography>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Box className={classes.waterLeakageIcons}>
                            <img src="images/Group 619.svg" alt="no_img"></img>
                          </Box>
                          <Box className={classes.quotationsummaryquest}>
                            {datas?.data?.table?.[0]?.unit?.[0]?.unit?.name}
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    {datas?.data?.table?.[0]?.raisedon?.length > 0 && (
                      <Grid item xs={6} sm={6} lg={6} md={6}>
                        <Typography className={classes.quotationsummaryans}>
                          Raised On
                        </Typography>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Box className={classes.waterLeakageIcons}>
                            <img
                              src="images/icons8-calendar (5).svg"
                              alt="no_img"
                            ></img>
                          </Box>
                          <Box className={classes.quotationsummaryquest}>
                            {moment(datas?.data?.table?.[0]?.raisedon)
                              .tz(moment.tz.guess())
                              .format("YYYY-MM-DD")}
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    {datas?.data?.table?.[0]?.prefferedtime?.length > 0 && (
                      <Grid item xs={6} sm={6} lg={6} md={6}>
                        <Typography className={classes.quotationsummaryans}>
                          Preferred Visit Date And Time
                        </Typography>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Box className={classes.waterLeakageIcons}>
                            <img
                              src="images/icons8-schedule (1).svg"
                              alt="no_img"
                            ></img>
                          </Box>
                          <Box className={classes.quotationsummaryquest}>
                            {moment(datas?.data?.table?.[0]?.prefferedtime)
                              .tz(moment.tz.guess())
                              .format("YYYY-MM-DD hh:mm")}
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {datas?.data?.images?.length > 0 && (
                <Grid container width="80%" marginTop="10px">
                  <Typography
                    className={classes.quotationsummaryans}
                    style={{ marginTop: "10px" }}
                  >
                    Images
                  </Typography>
                  <Grid container spacing={2}>
                    {datas?.data?.images?.map((x, index) => {
                      return (
                        <Grid item xs={4} className={classes.imagesParent}>
                          <Box className={classes.imgContainer}>
                            <div style={{ position: "relative" }}>
                              <img
                                type="file"
                                accept="image/*"
                                multiple
                                alt="not fount"
                                width={"88px"}
                                onClick={() => openImageViewer(index)}
                                src={x.url}
                                style={{
                                  objectFit: "cover",
                                  height: "88px",
                                  width: "100%",
                                  borderRadius: "12px",
                                  cursor: "pointer",
                                }}
                              />
                              {isViewerOpen && (
                                <ImageViewer
                                  src={datas?.imagesListForView}
                                  currentIndex={currentImage}
                                  onClose={closeImageViewer}
                                  disableScroll={false}
                                  backgroundStyle={{
                                    backgroundColor: "rgba(0,0,0,0.9)",
                                  }}
                                  closeOnClickOutside={true}
                                />
                              )}
                            </div>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              )}

              <Grid container marginTop="10px">
                {datas?.data?.table?.[0]?.name?.length > 0 ||
                  datas?.data?.table?.[0]?.mobile?.length > 0 ||
                  datas?.data?.table?.[0]?.alternative?.length > 0 ? (
                  <>
                    <Typography
                      className={classes.quotationsummaryans}
                      style={{ marginTop: "10px" }}
                    >
                      Contact Details
                    </Typography>
                    <Grid
                      container
                      spacing={3}
                      className={classes.contactDetails}
                    >
                      <Grid
                        item
                        lg={1.5}
                        xs={1.5}
                        md={1.5}
                        sm={1.5}
                        display="flex"
                      >
                        <IconButton className={classes.phoneIconBtn}>
                          <PhoneIcon className={classes.phoneIcon} />
                        </IconButton>
                      </Grid>
                      <Grid item lg={10.5} xs={10.5} md={10.5} sm={10.5}>
                        <div style={{ marginLeft: "8px" }}>
                          <Typography className={classes.contactDetailsName}>
                            {datas?.data?.table?.[0]?.name}
                          </Typography>
                          <Typography className={classes.contactDetailsNumbers}>
                            {datas?.data?.table?.[0]?.mobile}&nbsp;|{" "}
                            {datas?.data?.table?.[0]?.alternative}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {!datas?.data?.images && (
                      <>
                        <Grid container style={{ marginBottom: "10px" }}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.quotationsummaryans}
                              style={{ marginTop: "10px" }}
                            >
                              Created By
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className={classes.contactDetailsName}>
                              {datas?.data?.table?.[0]?.profile?.first_name}
                              {datas?.data?.table?.[0]?.profile?.last_name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            xs={5}
            md={5}
            sm={5}
            lg={5}
            style={{ padding: "4px", position: "relative" }}
          >
            <div style={{ height: size?.height - 400 }}>
              <div className={classes.createnewsectionlog}>
                <Typography
                  className={classes.title}
                  style={{ marginBottom: "20px" }}
                >
                  Status
                </Typography>
                <Grid container direction={"column"} spacing={1}>
                  {track
                    ?.filter((i) => i?.is_active === true)
                    .map((item, index, array) => {
                      return (
                        <Grid
                          container
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems="center"
                        >
                          <Grid
                            container
                            direction={"column"}
                            xs={2}
                            justifyContent={"start"}
                            alignItems="center"
                          >
                            {index !== 0 && (
                              <Typography
                                style={{
                                  color: "#5078E1",
                                  fontWeight: "bolder",
                                }}
                              >
                                |
                              </Typography>
                            )}
                            <Avatar
                              variant="circular"
                              style={{
                                height: "40px",
                                width: "40px",
                                border: "8px solid #F2F4F7",
                              }}
                            >
                              <Avatar
                                variant="circular"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  backgroundColor: "#5078E1",
                                }}
                              >
                                <img
                                  src={
                                    request === "Maintance"
                                      ? item?.label === "Open"
                                        ? "images/icons8-clock.svg"
                                        : "images/Path 953.svg"
                                      : item?.label === "Open"
                                        ? "images/icons8-clock.svg"
                                        : "images/Path 953.svg"
                                  }
                                  alt="document"
                                />
                              </Avatar>
                            </Avatar>
                            {index + 1 !== array.length && (
                              <Typography
                                style={{
                                  color: "#5078E1",
                                  fontWeight: "bolder",
                                }}
                              >
                                |
                              </Typography>
                            )}
                          </Grid>
                          <Grid
                            container
                            direction={"column"}
                            xs={10}
                            justifyContent={"center"}
                            alignItems="start"
                            style={{ padding: "0px 0px 0px 10px" }}
                          >
                            <div
                              style={{
                                marginBottom: index === 0 ? "16px" : "0px",
                                marginTop:
                                  index + 1 === array.length ? "22px" : "0px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize:"1rem",
                                  fontFamily: Bold,
                                  color: "#091B29",
                                }}
                              >
                                {item?.label}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize:"0.75rem",
                                  fontFamily: SemiBold,
                                  color: "#7C8594",
                                }}
                              >
                                {moment(item.date)
                                  .tz(moment.tz.guess())
                                  .format("YYYY-MM-DD hh:mm A")}
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {datas?.data?.table?.[0]?.status?.some((item) => item?.type === "Open") ? (
        <div className={classes.buttonParent}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => CloseDialog("open")}
          >
            Close Request
          </Button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
