import * as React from "react"
const ServiceLevelTimeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 113035">
      <path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 56011" />
      <path
        fill="#4e5a6b"
        d="M8.896 1a.659.659 0 1 0 0 1.317h.439v.911A7.911 7.911 0 1 0 16.027 6l.8-.8a.659.659 0 1 0-.931-.931l-.8.8a7.86 7.86 0 0 0-4.438-1.84v-.912h.438a.659.659 0 1 0 0-1.317Zm1.02 3.516a.659.659 0 0 0 .159 0A6.56 6.56 0 0 1 14.62 6.4a.659.659 0 0 0 .069.069A6.59 6.59 0 1 1 9.92 4.512Zm2.919 3.063a.659.659 0 0 0-.453.2l-2.049 2.048a1.315 1.315 0 1 0 .932.93l2.048-2.048a.659.659 0 0 0-.478-1.13Z"
        data-name="Group 113025"
      />
    </g>
  </svg>
)
export default ServiceLevelTimeIcon
