import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material"
import React, { useContext, useState } from "react"
import { withNamespaces } from "react-i18next"
import { useHistory } from "react-router-dom"
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination } from "../../components"
import { NewLoader } from "../../components/newLoader"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { Routes } from "../../router/routes"
import { AlertProps, NetWorkCallMethods, SurveyDraftFormPath, SurveyFormDraftDataType, SurveyFormDraftHeading, SurveyFormHeading, SurveyFormPath, SurveyFormPublishDataType, accessCheckRender, getCompanyOption, getRoutePermissionNew } from "../../utils"
import { DynamicTabs } from "./components/tab"
import { useStyles } from "./style"


const SurveyForm = ({ t }) => {
    const classes = useStyles()
    const history = useHistory()
    const alert = useContext(AlertContext)
    const TabList = [{ value: 1, title: "Publish" }, { value: 2, title: "Draft" }]
    const [loading, setLoading] = React.useState(true)
    const [tabValue, setTabValue] = React.useState(1);
    const [showDialog, setShowDialog] = React.useState({
        open: false, type: ""
    })
    const [searchText, setSearchText] = React.useState("")
    const [feedBackData, setFeedBackData] = React.useState({
        list: [],
        count: 0
    })
    const [filterData, setFilterData] = React.useState({
        inspection_type: [],
        status: [{ label: "Active", value: true }, { label: "In-active", value: false }]
    })
    const [drawer, setDrawer] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const auth = React.useContext(AuthContext);
    const [permission, setPermission] = useState({})
    const [selectedCompany, setSelectedCompany] = useState("")
    const backdrop = useContext(BackdropContext)
    const [companyList, setCompanyList] = React.useState([]);
    //get feedbackList
    const GetFeedBackList = (company_id, type, offset = 0, limit = 10, search = "", filterData) => {
        setLoading(true)

        const payload = {
            company_id: company_id,
            is_draft: type === 2 ? true : false,  //is_draft
            active: filterData?.status?.map((x) => { return x.value }),
            offset: offset,
            limit: limit,
            search: search
        }
        NetworkCall(
            `${config?.api_url}/survey/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoading(false)
            const result = res?.data?.data?.data?.map((x) => {
                return {
                    ...x,
                    is_active: x?.status
                }
            })
            setFeedBackData({ list: result, count: res?.data?.data?.count })
        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    //useEffect-initial load
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert);
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            GetFeedBackList(company?.selected?.value, tabValue, 0, 10, "", filterData)
        }
        //eslint-disable-next-line
    }, [auth])
    //onchange company
    const onChangeCompany = (val) => {
        setSelectedCompany(val)
        GetFeedBackList(val?.value, tabValue, 0, 10, "", filterData)
    }
    //tab change
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setLoading(true)
        GetFeedBackList(selectedCompany?.value, newValue, 0, 10, "", filterData)
    };
    //handle Search
    const handleSearch = (value) => {
        setSearchText(value)
        GetFeedBackList(selectedCompany?.value, tabValue, 0, 10, value, filterData)
    }
    //handleIcon
    const handleIcon = (type, data) => {
        if (type === "active/in-active" || type === "delete" || type === "publish") {
            // GetFeedBackDetailsById(data?.id, type)
            onSubmit(data)
        }
        else if (type === "edit") {
            history.push({
                pathname: Routes?.createSurveyForm,
                state: {
                    main: {
                        data: data,
                        is_edit: true,
                        company: selectedCompany,
                    }
                }
            })
        }
        else if (type === "view_feedBack") {
            history.push({
                pathname: Routes?.createSurveyForm,
                state: {
                    main: {
                        data: data,
                        company: selectedCompany,
                        is_view: true
                    }
                }
            })
            // GetFeedBackDetailsById(data?.id, type)
        }
    }
    //handlePagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        GetFeedBackList(selectedCompany?.value, tabValue, offset, 10, "", filterData)
    }
    //handleChangeLimit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        GetFeedBackList(selectedCompany?.value, tabValue, 0, value, "", filterData)

    }
    //on create button click
    const onCreate = () => {
        history.push({
            pathname: Routes?.createSurveyForm, state: {
                main: {
                    is_edit: false,
                    company: selectedCompany
                }
            }
        })
    }
    const onApplyFilter = (val) => {
        setFilterData(val)
        GetFeedBackList(selectedCompany?.value, tabValue, 0, 10, "", val)
    }
    //publish form
    const onSubmit = (data) => {
        let payload = {
            id: data?.id ?? undefined,
            is_active: data?.is_active ? false : true,
            // is_delete: type === "delete" ? true : false,
        }

        NetworkCall(
            `${config.api_url}/survey/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: `${t("Form")}  ${data?.is_active ? t("In-Activated") : t("Activated")} ${t("Successfully")}`,
            });
            setShowDialog({ ...showDialog, open: false })
            GetFeedBackList(selectedCompany?.value, tabValue, 0, 10, "", filterData)
        }).catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong please try again"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    const openfilter = () => {
        setDrawer(true)
    }
    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={true}
                    title={t("Survey Form")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        onChangeCompany(e)
                    }}
                />
                <Box className={classes.root}>
                    <DynamicTabs tabList={TabList} value={tabValue} handleChange={handleTabChange} />
                    <Box p={2}>
                        <Box display="flex" justifyContent={"space-between"}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <SearchFilter
                                        handleChange={handleSearch}
                                        value={searchText}
                                        placeholder={t("search")}
                                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                    />
                                </Grid>
                                <Grid item xs={9} alignContent="flex-end">
                                    <Stack
                                        divider={<Divider orientation="vertical" flexItem />}
                                        spacing={2}
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        direction="row">
                                        {/*add btn */}
                                        {
                                            filterData?.inspection_type?.length > 0 ?
                                                <Badge variant="dot" color="primary">
                                                    <IconButton className={classes.iconButton} onClick={openfilter}>
                                                        <img src="/images/filter.svg" alt="filter" />
                                                    </IconButton>
                                                </Badge>
                                                :
                                                <IconButton className={classes.iconButton} onClick={openfilter}>
                                                    <img src="/images/filter.svg" alt="filter" />
                                                </IconButton>
                                        }
                                        {permission?.create &&

                                            <Button variant="contained" onClick={onCreate} sx={{ height: '40px' }}>{t("Create")}</Button>
                                        }

                                    </Stack>
                                </Grid>
                            </Grid>

                        </Box>
                        {
                            loading ? (
                                <NewLoader minusHeight="180px" />
                            ) :
                                <Box mt={2}>
                                    <TableWithPagination
                                        heading={tabValue === 1 ? SurveyFormHeading(t) : SurveyFormDraftHeading(t)}
                                        rows={feedBackData?.list}
                                        dataType={tabValue === 1 ? SurveyFormPublishDataType : SurveyFormDraftDataType}
                                        path={tabValue === 1 ? SurveyFormPath : SurveyDraftFormPath}
                                        showpagination
                                        tableType="no-side"
                                        handleIcon={handleIcon}
                                        handlePagination={handlePagination}
                                        handleChangeLimit={handleChangeLimit}
                                        totalRowsCount={feedBackData?.count}
                                        page={page}
                                        limit={limit}
                                        className={classes.reqTable}
                                        // view={true}
                                        view={permission?.read}
                                        height={`calc(100vh - 350px)`}
                                        // edit={true}
                                        edit={permission?.update}
                                        // delete={true}
                                        delete={permission?.delete}
                                    />
                                </Box>
                        }
                    </Box>
                </Box>
                {/*filter component */}
                {drawer && (
                    <FilterGenerator
                        open={drawer}
                        onClose={() => setDrawer(false)}
                        components={[
                            {
                                component: "select",
                                value: filterData?.status,
                                state_name: "status",
                                label: t("Status"),
                                options: [{ label: "Active", value: true }, { label: "In-active", value: false }],
                                isMulti: true
                            },

                        ]}
                        onApply={(value) => onApplyFilter(value)}
                    />
                )}
            </Box>
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>

    )

}
export default withNamespaces("formBuilder")(SurveyForm)