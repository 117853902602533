import { Grid, Box, Stack, Typography, Button } from "@mui/material";
import React from "react";
import { MobileNumberInputComponent, TextBox } from "../../index";
import { ProfileUpload } from "../../LogoUpload/profileUpload"
import { useStyle } from "./style"
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import { LocalStorageKeys } from "../../../utils";
import { SELECT_ACCOUNT_ALL } from "../../../graphql/residentOnbordingQueries";
import { Steps } from "../../../screens/residentOnboarding/steps";

export const Contact = ({
    data,
    updateState = () => false, t = () => false
}) => {

    const classes = useStyle()


    const back=()=>{
        if(!data?.contactType){
           return updateState('contactType' , true)
        }
        updateState('step' , 0)

    }
    return (
        <Box >
            <Stack direction="row" justifyContent={'space-between'} p={2}>
                <Typography className={classes.contactTitle}>{data?.contactType ? t("BILLING PRIMARY CONTACT DETAILS") : t("CREATE NEW CONTACT")}</Typography>
                {
                    data?.contactType && <Typography className={classes.createNew} onClick={()=>updateState('contactType' , false)}>Create New</Typography>

                }
            </Stack>
            {/* existing contact dropdown */}
            <Box className={classes.addContactParent} >
                {
                    data?.contactType && 
                    <Box p={2} pt={0}>
                   <Steps t={t} component={[
                    {
                        type: "customSelectBox",
                        placeHolder: "Search Existing Contact",
                        value: data?.account,
                        handleChange: (value) => updateState('account' , value),
                        options: [],
                        sm: 12,
                        md: 12,
                        lg: 12,
                        isActive: true,
                        menuOptionHeight: 300,
                        color: "#F5F7FA",                      
                        loadOptions: (search, array, handleLoading) => {
                            return loadOptions(
                                search,
                                array,
                                handleLoading,
                                SELECT_ACCOUNT_ALL,
                                "contact_account",
                                { isActive: true, client: localStorage.getItem(LocalStorageKeys.clinetID) },
                                { contact: true },
                                false,
                                false,

                            )
                        },
                    }
                ]} />
                   </Box>
                }
            
                <Grid container spacing={2} p={2} pt={data?.contactType ? 0 : 2}>
            
                    <Grid item xs={12} >
                        <ProfileUpload
                            logo_title={""} data={data?.profile_img} profile
                            handleChange={(value) => updateState("profile_img", value)}
                        />
                    </Grid>
                    {/* name  */}
                    <Grid item xs={12} >
                        <TextBox label={t("Contact Name")} placeholder={t("Contact Name")}
                            value={data?.name} isrequired
                            onChange={(e) => {
                                updateState("name", e.target.value);
                            }}
                            isError={data?.error?.name?.length > 0}
                            errorMessage={data?.error?.name}
                        />
                    </Grid>

                    {/* mobile */}
                    <Grid item xs={12}>
                        <MobileNumberInputComponent label={t("Mobile")} placeholder={t("Mobile")}
                            value={data?.mobile} isRequired
                            handleChange={(value) => {
                                updateState("mobile", value);
                            }}
                            xs={3.2}
                            isError={data?.error?.mobile?.length > 0}
                            errorMessage={data?.error?.mobile}

                        />
                    </Grid>

                    {/*Telephone  */}
                    <Grid item xs={12}>
                        <MobileNumberInputComponent
                            label={t("Telephone")}
                            xs={3.2}
                            placeholder={t("Telephone")}
                            value={data?.telephone}
                            handleChange={(value) => {
                                updateState("telephone", value);
                            }}
                        />
                    </Grid>
                    {/*email  */}
                    <Grid item xs={12}>
                        <TextBox label={t("Email Address")} placeholder={t("Email Address")}
                            value={data?.email} isrequired
                            onChange={(e) => {
                                updateState("email", e.target.value);
                            }}
                            isError={data?.error?.email?.length > 0}
                            errorMessage={data?.error?.email}
                        />
                    </Grid>



                </Grid>
            </Box>
            {/* footer */}
            <Box p={2} style={{borderTop:"1px solid #F2F4F7"}}>
                <Stack direction="row" spacing={'8px'} >
                    <Button variant="outline" className={classes.previous} onClick={back}>
                        {t("back")}
                    </Button>
                    <Button variant="contained" className={classes.next}>
                        {t("Create")}
                    </Button>
                </Stack>
            </Box>
        </Box>

    )
}