import { useApolloClient } from "@apollo/client"
import { Box, Grid, Typography } from "@mui/material"
import { addMonths, eachDayOfInterval, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, subMonths } from "date-fns"
import moment from "moment"
import React from "react"
import { withNamespaces } from "react-i18next"
import { LoadingSection, Subheader } from "../../components"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { GET_CONTACTS_BYCOMPANY } from "../../graphql/timeOffRequest"
import { NetworkCall } from "../../networkcall"
import { AlertProps, Events, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from "../../utils"
import { EmployeeCard, StatsList, TimeOffPieChart } from "./components"
import { MonthlyCalendar } from "./components/calendar"
import { useStyles } from "./style"


const TimeOffRequest = ({ t }) => {
    const client = useApolloClient()
    const alert = React.useContext(AlertContext)
    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [result, setResult] = React.useState([])
    const [leaveList, setLeaveList] = React.useState([])
    const [isShow, setIsShow] = React.useState(false)
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [isDisableBtn,setIsDisableBtn]=React.useState(false)
    //month
    const date = new Date();
    const [month, setMonth] = React.useState(new Date())
    const [leaveRequestData, setLeaveRequestData] = React.useState([{
        dates: [],
        value: [],

    }])
    const [searchText, setSearchText] = React.useState("")
    const [offset, setOffset] = React.useState(10)
    const auth = React.useContext(AuthContext);
    const [permissions, setPermission] = React.useState({})
    const backdrop = React.useContext(BackdropContext)
    const [type, setType] = React.useState("PTO")
    const [consumedLeave, setConsumedLeave] = React.useState(0)
    const [loading, setLoading] = React.useState(true)
    const [resourceData, setResourceData] = React.useState({
        list: [],
        selectedResourceId: null,
        AllEmployees: [],
        selectedEmpData: {},
        data: []

    })
    const [finalDays, setFinalDays] = React.useState([])
    const [firstDay, setFirstDay] = React.useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [lastDay, setLastDay] = React.useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    //dateRange
    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }
    const monthNext = () => {
        const datebyMonth = addMonths(month, 1)
        const monthStart = startOfMonth(datebyMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        const firstDayofMonth = new Date(datebyMonth.getFullYear(), datebyMonth.getMonth(), 1)
        const lastDayofMonth = new Date(datebyMonth.getFullYear(), datebyMonth.getMonth() + 1, 0)
        let finaldays = dateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        setMonth(addMonths(month, 1))
        setFirstDay(firstDayofMonth)
        setLastDay(lastDayofMonth)
        getAllLeaveRequest(firstDayofMonth, lastDayofMonth, resourceData?.list?.id)
    }

    const monthPrev = () => {
        const datebyMonth = subMonths(month, 1)
        const monthStart = startOfMonth(datebyMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        const firstDayofMonth = new Date(datebyMonth.getFullYear(), datebyMonth.getMonth(), 1)
        const lastDayofMonth = new Date(datebyMonth.getFullYear(), datebyMonth.getMonth() + 1, 0)
        let finaldays = dateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        setMonth(subMonths(month, 1))
        setFirstDay(firstDayofMonth)
        setLastDay(lastDayofMonth)
        getAllLeaveRequest(firstDayofMonth, lastDayofMonth, resourceData?.list?.id)
    }
    //reset to current month
    const resetToCurrentMonth = (employeeChange) => {
        const datebyMonth = new Date()
        const monthStart = startOfMonth(datebyMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let finaldays = dateRange(monthstartDate, monthendDate)
        const firstDayofMonth = new Date(datebyMonth.getFullYear(), datebyMonth.getMonth(), 1)
        const lastDayofMonth = new Date(datebyMonth.getFullYear(), datebyMonth.getMonth() + 1, 0)
        setFirstDay(firstDayofMonth)
        setLastDay(lastDayofMonth)
        setFinalDays(finaldays)
        setMonth(new Date())
        if (employeeChange) {
            getAllLeaveRequest(firstDayofMonth, lastDayofMonth, resourceData?.selectedEmpData?.id)
        }
    }
    //get contacts
    const getContactList = (offset = 0, limit = 10, searchKey = "", companyId, isResources = true, search = false, companyChange = false) => {
        setSearchText(searchKey)
        client
            .query({
                query: GET_CONTACTS_BYCOMPANY,
                fetchPolicy: "network-only",
                variables: {
                    "offset": offset,
                    "limit": limit,
                    "company_id": companyId,
                    "client": localStorage.getItem(LocalStorageKeys.clinetID),
                    "search": searchKey
                },
            })
            .then((response) => {
                if (response?.data?.contact?.length > 0) {
                    const list = (search === true || companyChange === true) ? response?.data?.contact : resourceData?.AllEmployees.concat(response?.data?.contact)
                    if (search) {
                        setResourceData({
                            ...resourceData,
                            AllEmployees: list,
                        })
                    } else {
                        setResourceData({
                            list: list[0],
                            AllEmployees: list,
                            data: list,
                            selectedResourceId: list[0]?.id
                        })
                    }

                    if (!search && isResources) {
                        getLeaveTypeList(list[0]?.id)
                        getAllLeaveRequest(firstDay, lastDay, list[0]?.id)
                        getChartData(list[0]?.id, type)
                    }
                    setLoading(false)
                }
                else {
                    if (search) {
                        setResourceData({
                            ...resourceData,
                            AllEmployees: [],
                        })
                    }
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err);
            });
    };
    //getChartData
    const getChartData = (id, val) => {
        const payload = {
            "contact_id": id,
            "type": val === "PTO" ? "Paid" : val==="Short Leave" ?"Short Leave": "Earned"
        }
        NetworkCall(
            `${config.api_url}/leave_request/get_stats`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (res?.data?.data?.length > 0) {
                let sum = 0
                const data = res?.data?.data.map((val) => {
                    sum = sum + val?.value
                    return (
                        {
                            "name": val?.label,
                            "value": val?.value,
                            "fill": val?.color
                        }
                    )
                })
                setResult(data)
                setConsumedLeave(sum)
            }
            else {
                setResult([{
                    "name": "",
                    "value": 1,
                    "fill": "#E4E8EE",
                    "nodata": true
                }])
                setConsumedLeave(0)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    //getAllLeaveRequest
    const getAllLeaveRequest = (startDate, endDate, resource_id) => {
        const payload = {
            "contact_id": resource_id,
            "start_date": (startOfDay(new Date(startDate))),
            "end_date": (endOfDay(new Date(endDate)))
        }
        NetworkCall(
            `${config.api_url}/leave_request/get_all`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const tempResponse = res?.data?.data?.filter?.(_ => _?.status !== "Cancelled");
            let result = []
            result = tempResponse?.map((x) => { return x.date })
            setLeaveRequestData({ dates: result, value: tempResponse })
        }).catch((err) => {
            console.log(err)
        })
    }
    //getLeaveTypes
    const getLeaveTypeList = (resource_id) => {
        const payload = {
            "contact_id": resource_id,
        }
        NetworkCall(
            `${config.api_url}/leave_request/get_leave_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLeaveList(res?.data?.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    //more
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getContactList(offset + 10, 10, searchText, selectedCompany?.value, false, false, false);
    }
    React.useEffect(() => {
        // const firstDayofMonth = new Date(month.getFullYear(), month.getMonth(), 1)
        // const lastDayofMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0)
        const monthStart = startOfMonth(month);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let finaldays = dateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        let company = getCompanyOption(backdrop, auth, alert);
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                getContactList(0, 10, "", company?.selected?.value, true)
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)

            }
        }
        // eslint-disable-next-line
    }, [auth])
    //onchange company
    const onChangeCompany = (val) => {
        setSelectedCompany(val)
        setResourceData([])
        setSearchText("")
        getContactList(0, 10, "", val?.value, true, false, true)
        setOffset(0)
        resetToCurrentMonth()
    }
    const selectEmp = (val) => {
        setResourceData({
            ...resourceData,
            selectedResourceId: val?.id,
            selectedEmpData: val
        })
    }
    const changeEmployee = () => {
        setResourceData({ ...resourceData, list: resourceData?.selectedEmpData, searchText: "" })
        getLeaveTypeList(resourceData?.selectedEmpData?.id)
        getChartData(resourceData?.selectedEmpData?.id, type)
        setOpen(false)
        resetToCurrentMonth(true)
    }
    const onSubmit = (selectedDate, leaveType, selectedType, timeDetails, setTimeDetails = () => false) => {
       
        if (selectedDate?.startDate?.[0].length > 0 && timeDetails?.startTime <= timeDetails?.endTime) {
            const start_date = moment(selectedDate?.startDate?.[0]).format("YYYY-MM-DD");
            const start_time = timeDetails?.startTime
            const end_date = moment(selectedDate?.endDate?.[0]).format("YYYY-MM-DD");
            const end_time = timeDetails?.endTime
            const startDateTime = type === "Short Leave" ? moment(`${start_date} ${start_time}`, 'YYYY-MM-DD HH:mm:ss') : startOfDay(new Date(selectedDate?.startDate?.[0]));
            const endDateTime = type === "Short Leave" ? (selectedDate?.endDate?.length > 0 ? moment(`${end_date} ${end_time}`, 'YYYY-MM-DD HH:mm:ss') : moment(`${start_date} ${end_time}`, 'YYYY-MM-DD HH:mm:ss')) : (selectedDate?.endDate?.length > 0 ? endOfDay(new Date(selectedDate?.endDate?.[0])) : endOfDay(new Date(selectedDate?.startDate?.[0])));


            let btween_days = eachDayOfInterval({
                start: new Date(startDateTime),
                end: new Date(endDateTime),
            });
            let leave_range = btween_days?.map((val) => {
                return {
                    start_date_time: startOfDay(val),
                    end_date_time: endOfDay(val)
                };
            });
            setIsDisableBtn(true)
            const payload = {
                "contact_id": resourceData?.selectedResourceId,
                "start_date_time": moment(startDateTime).format("YYYY-MM-DDTHH:mm:ssZ"),
                "end_date_time": moment(endDateTime).format("YYYY-MM-DDTHH:mm:ssZ"),
                "type": type === "Short Leave" ? "hourly" : leaveType?.name,
                "half_day_type": leaveType?.name === "half_day" ? leaveType?.period : undefined,
                "leave_master_id": selectedType?.id,
                leave_range,
                "status":"Approved"
            }
            NetworkCall(
                `${config.api_url}/leave_request/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Leave Request Created Successfully"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                getAllLeaveRequest(firstDay, lastDay, resourceData?.selectedResourceId)
                getLeaveTypeList(resourceData?.selectedResourceId)
                getChartData(resourceData?.selectedResourceId, type)
                setIsShow(false)
                setTimeDetails({
                    startTime: "",
                    endTime: ""
                })
            }).catch((error) => {
                setIsDisableBtn(false)
                if (error.response) {
                    setIsShow(false)
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : t("Something went wrong"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                }
                else {
                    setIsShow(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }
            })
        }
        else {
            let msg = timeDetails?.startTime < timeDetails?.endTime ? t("Please Choose the date") : t("Please choose end time greater than start time")
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: msg,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
    }
    const closeModal = () => {
        setOpen(false)
        if (searchText !== "") {
            setSearchText("")
            getContactList(0, 10, "", selectedCompany?.value);
        }
    }
    const onChangeType = (val) => {
        setType(val)
        getChartData(resourceData?.selectedResourceId, val)
    }
    const searchEmployee = (val) => {
        getContactList(0, 10, val, selectedCompany?.value, false, true);
    }

    const calendarReload = () => {
        getLeaveTypeList(resourceData?.selectedResourceId);
        getChartData(resourceData?.selectedEmpData?.id, type);
        getAllLeaveRequest(firstDay, lastDay, resourceData?.selectedResourceId);
    }

  
    return (

        <Box>
            <Subheader hideBackButton title={t("Time Off")}
                select
                options={companyList}
                value={selectedCompany}
                onchange={(e) => {
                    onChangeCompany(e)
                }}
            />
            {
                loading ?
                    <LoadingSection top="20vh" message="Fetching ..." />
                    :
                    resourceData?.data?.length > 0 ?
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={3} >
                                <Box className={classes.block}>
                                    <Box className={classes.mainCard}>
                                        <EmployeeCard
                                            data={resourceData}
                                            setData={setResourceData}
                                            handleSearch={(value) => searchEmployee(value)}
                                            fetchMoreData={fetchMoreData}
                                            setSearchText={setSearchText}
                                            searchText={searchText}
                                            open={open}
                                            setOpen={setOpen}
                                            closeModal={closeModal}
                                            selectEmp={selectEmp}
                                            changeEmployee={changeEmployee}
                                            t={t}
                                        />
                                        <TimeOffPieChart
                                            resourceData={resourceData}
                                            onChangeType={onChangeType}
                                            type={type}
                                            result={result}
                                            consumedLeave={consumedLeave}
                                            t={t}
                                        />
                                        <Box className={classes.content}>
                                            <StatsList leaveList={leaveList} t={t} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Box className={classes.block}>
                                    <MonthlyCalendar
                                        dates={finalDays}
                                        month={month}
                                        events={Events}
                                        prev={monthPrev}
                                        next={monthNext}
                                        leaveList={leaveList}
                                        data={leaveRequestData}
                                        onSubmit={onSubmit}
                                        isShow={isShow}
                                        setIsShow={setIsShow}
                                        permissions={permissions}
                                        t={t}
                                        isDisableBtn={isDisableBtn}
                                        type={type}
                                        setType={setType}
                                        reload={calendarReload}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        :
                        <Box mt={4}>
                            <Typography textAlign={"center"} className={classes.empName}>{t("noContactsFound")}</Typography>
                        </Box>
            }
        </Box>
    )
}
export default withNamespaces("timeOffRequest")(TimeOffRequest)
