import * as React from "react"
const ServiceLevelCalendarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 112958">
      <path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 56011" />
      <g data-name="Group 113025">
        <path
          fill="#e4e8ee"
          d="M3.944 1.887s-2.266 3.215-2.047 3.58 16.223 0 16.223 0V3.056s-.363-.95-3.58-1.169-10.596 0-10.596 0Z"
          data-name="Path 100236"
        />
        <path
          fill="#4e5a6b"
          d="M4.25 1A3.262 3.262 0 0 0 1 4.25v11.5A3.262 3.262 0 0 0 4.25 19h11.5A3.262 3.262 0 0 0 19 15.75V4.25A3.262 3.262 0 0 0 15.75 1Zm0 1.5h11.5a1.739 1.739 0 0 1 1.75 1.75V5h-15v-.75A1.739 1.739 0 0 1 4.25 2.5Zm-1.75 4h15v9.25a1.739 1.739 0 0 1-1.75 1.75H4.25a1.739 1.739 0 0 1-1.75-1.75Zm3.25 2A1.25 1.25 0 1 0 7 9.75 1.25 1.25 0 0 0 5.75 8.5Zm4.25 0a1.25 1.25 0 1 0 1.25 1.25A1.25 1.25 0 0 0 10 8.5Zm4.25 0a1.25 1.25 0 1 0 1.25 1.25 1.25 1.25 0 0 0-1.25-1.25ZM5.75 13A1.25 1.25 0 1 0 7 14.25 1.25 1.25 0 0 0 5.75 13ZM10 13a1.25 1.25 0 1 0 1.25 1.25A1.25 1.25 0 0 0 10 13Z"
          data-name="icons8-calendar (5)"
        />
      </g>
    </g>
  </svg>
)
export default ServiceLevelCalendarIcon
