export const UPDATE_GATE_MASTER = `
mutation($insert: [access_gates_masterOutput!]) {
    access_gates_master(
    insert: $insert
  ) {
    id
  }
}`;

export const UPDATE_PARKING_MASTER = `
mutation($insert: [parking_masterOutput!]) {
    parking_master(
    insert: $insert
  ) {
    id
  }
}`;

export const UPDATE_DOMESTIC_HELPER = `
mutation($insert: [domestic_helper_masterInput!]) {
  domestic_helper_master(insert: $insert) {
    id
  }
}`;

export const UPDATE_VENDOR_MASTER = `
mutation UPDATE_VENDOR_MASTER {
  vendor_master(insert: $insert) {
    id
  }
}`;

export const UPDATE_SERVICE_PROVIDERS_MASTER = `
mutation UPDATE_SERVICE_PROVIDERS_MASTER {
  service_providers_master(insert: $insert) {
    id
  }
}`;

export const EDIT_GATE_MASTER =
  `mutation($gate_master_id: ID, $demo: access_gates_masterInput) {
    access_gates_master(
    where: { id: { eq: $gate_master_id } }
    update: $demo
  ) {
     id
  }
}`;

export const EDIT_PARKING_MASTER = `
mutation($parkingId: ID,$demo: parking_masterInput) {
    parking_master(
    where: { id: { eq: $parkingId } }
    update: $demo
  ) {
    id
  }
}`;

export const EDIT_DOMESTIC_HELPER = `
mutation($domestic_helper_id: ID, $demo: domestic_helper_masterInput) {
  domestic_helper_master(
    where: { id: { eq: $domestic_helper_id } }
    update: $demo
  ) {
    id
  }
}`;

export const EDIT_VENDOR_MASTER = `
mutation EDIT_VENDOR_MASTER {
  vendor_master(
    where: { id: { eq: $vendor_id } }
    update: $demo
  ) {
    id
  }
}`;


export const EDIT_SERVICE_PROVIDERS_MASTER = `
mutation EDIT_SERVICE_PROVIDERS_MASTER {
  service_providers_master(
    where: { id: { eq: $service_provider_id } }
    update: $demo
  ) {
    id
  }
}`;

export const GATE_SEARCH = `
query($search: String){
  access_gates_master(where: { property: { name: { ilike: $search } } }){
    id
    name
    description
    gate_no
    is_active
    property{
      name
    }
    user_profile_id
  }
}
`;

export const PARKING_SEARCH = `
query($search: String){
  parking_master(where:{
    parking_no:{ilike:$search}
  }){
    id
    parking_no
    is_active
    property{
      name
    }
  }
}
`;