import React from "react";
import { withNavBars } from "../../HOCs";
import { BusinessMaster } from "./master";

class MasterParent extends React.Component {
  render() {
    return <BusinessMaster {...this.props} />;
  }
}

export default withNavBars(MasterParent);