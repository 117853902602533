
export const YetToReceivePath = ["requestor_image", "reference_id", "name", "unit_id", "entry_gate", "expected_date_time", "notes"];

export const YetToReceiveHeading = (t) => [
    { title: t("Requestor"), field: "requestor_image" },
    { title: t("Reference ID"), field: "reference_id" },
    { title: t("Name"), field: "name" },
    { title: t("Unit ID"), field: "unit_id" },
    { title: t("Entry Gate"), field: "entry_gate" },
    { title: t("Expected Date And Time"), field: "expected_date_time" },
    { title: t("Notes"), field: "notes" },
];
export const YetToReceiveType = [
    { type: ["logo"], name: "requestor_image" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "expected_date_time" },
    { type: ["text"], name: "notes" },
];


export const ReceivedAtGatePath = ["delivery_item_image", "delivery_person_image", "date", "reference_id", "name", "received_gate", "received_date_time", "registered"];

export const ReceivedAtGateHeading = (t) => [
    { title: t("Date"), field: "date" },
    { title: t("Reference ID"), field: "reference_id" },
    { title: t("Name"), field: "name" },
    { title: t("Unit ID"), field: "unit_id" },
    { title: t("Received Gate"), field: "received_gate" },
    { title: t("Received Date And Time"), field: "received_date_time" },
];
export const ReceivedAtGateType = [
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "received_gate" },
    { type: ["text"], name: "received_date_time" },
];

export const CollectedPath = ["collected_person_image", "date", "reference_id", "collected_by_name", "unit_id", "received_gate", "received_date_time", "collected_by"];

export const CollectedHeading = (t) => [
    { title: t("Collected Person"), field: "collected_person_image" },
    { title: t("Date"), field: "date" },
    { title: t("Reference ID"), field: "reference_id" },
    { title: t("Collected By Name"), field: "collected_by_name" },
    { title: t("Unit ID"), field: "unit_id" },
    { title: t("Received Gate"), field: "received_gate" },
    { title: t("Received Date And Time"), field: "received_date_time" },
];
export const CollectedType = [
    { type: ["logo"], name: "collected_person_image" },
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "collected_by_name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "received_gate" },
    { type: ["text"], name: "received_date_time" },
];
