import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
// import { useHistory } from "react-router-dom";
import { ActiveCardStyles } from "./activeCardStyle";



export const ActiveCard = (props) => {
    const classes = ActiveCardStyles(props);
    // const history = useHistory();

    return (
        <>
            <div
                className={classes.card}
            // onClick={() => {
            //     history.push({
            //         pathname: props.data.link,
            //         state: props.data.main,
            //     });
            // }}
            >
                <div className={classes.Cardcontent}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid
                            item
                            xs={7}
                        >
                            <Typography variant="body1" className={classes.count}>
                                {props.data.count}
                            </Typography>
                        </Grid>
                        <Grid item xs={5} textAlign={"right"}>
                            <Box height="2px" />
                            {
                                props?.data?.image_type === true ? <img src={props?.data?.image} alt='images' /> : props.data.image
                            }

                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" className={classes.title}>
                                {props.data.name}
                            </Typography>
                        </Grid>
                        {props?.data?.more &&
                            <Grid item xs={12} marginTop="12px">

                                <div className={classes.div}>
                                    <Stack
                                        direction="row"
                                        divider={<Divider orientation="vertical" flexItem />}
                                        spacing={2}
                                        justifyContent={"space-evenly"}
                                        padding={1}
                                    >
                                        <Box>
                                            <Typography className={classes.title}>{props?.data?.additionalTitle_1}</Typography>
                                            <Typography className={classes.count}>{props?.data?.additionalCount_1}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className={classes.title}>{props?.data?.additionalTitle_2}</Typography>
                                            <Typography className={classes.count}>{props?.data?.additionalCount_2}</Typography>
                                        </Box>

                                    </Stack>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </div>
            </div>
        </>
    );
};
