/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from '@apollo/client';
import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from '@mui/material';
import React from 'react';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { GET_SERVICE_PROVIDER_LIST } from '../../graphql/queries';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys } from '../../utils';
import { useStyles } from "./style";
import { UPDATE_SERVICE_PROVIDER } from '../../graphql/mutations';
import {
    ServiceProviderHeading, ServiceProviderPath, ServiceProviderType, StatusOptionList
} from '../../utils/serviceProvider/serviceProviderUtils';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';

export const ServiceProviderListing = () => {

    const classes = useStyles()
    const debounce = UseDebounce()
    const client = useApolloClient()
    const history = useHistory()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)


    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [serviceProviderList, setServiceProviderList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState({ status: [true] })
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
        }
    }

    // useEffect to get service provider list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedCompany?.value) { getServiceProvider() }
    }, [selectedCompany, filterData])

    // Function to get service provider list based on the input data
    const getServiceProvider = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        client.query({
            query: GET_SERVICE_PROVIDER_LIST, fetchPolicy: "network-only",
            variables: {
                clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? "",
                status: (!filterData?.status || filterData?.status?.length === 0) ?
                    [true, false] : filterData?.status,
                companyID: selectedCompany?.value,
                search,
                offset,
                limit,
            }
        }).then((r) => {
            setServiceProviderList({
                data: r?.data?.service_providers_master,
                totalRowsCount: r?.data?.count?.[0]?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: "Some Thing Went Wrong"
            })
        })
    }

    // Function to show logo or initial
    const returnValue = (value = "", delimiter = "") => value ? value : delimiter

    // Set row data for table
    const ServiceProviderRows = React.useCallback(serviceProviderList?.data?.map((_) => {
        let j
        try {
            j = {
                id: _?.id,
                imageUrl: {
                    logo: returnValue(_?.image_url, "-"),
                    name: _?.name ? returnValue(_?.name?.charAt(0)) : ""
                },
                name: _?.name ?? "",
                serviceProviderCode: _?.service_provider_code ?? "",
                city: _?.service_providers_addressByID?.[0]?.city ?? "",
                primaryMobileNo: _?.service_providers_addressByID?.[0]?.mobile_no ?
                    (_?.service_providers_addressByID?.[0]?.mobile_code +
                        (_?.service_providers_addressByID?.[0]?.mobile_code ? " " : "") +
                        _?.service_providers_addressByID?.[0]?.mobile_no) : "",
                primaryEmailId: _?.service_providers_addressByID?.[0]?.email_id ?? "",
                status: _?.is_active ? "Active" : "Inactive",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: "Some Thing Went Wrong"
            })
        }
        return j
    }), [serviceProviderList])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in service provider list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getServiceProvider(0, limit, e)
    }

    // Function to open add service provider form
    const handleAddServiceProvider = () => {
        history.push({
            pathname: Routes.serviceProviderCreateEdit,
            state: {
                main: {
                    company: selectedCompany,
                    companyID: selectedCompany?.value,
                }
            }
        })
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        if (type === "view") { history.push(Routes.serviceProviderView + "?serviceProviderID=" + data?.id) }
        else if (type === "edit") {
            history.push({
                pathname: Routes.serviceProviderCreateEdit,
                state: {
                    main: {
                        company: selectedCompany,
                        companyID: selectedCompany?.value,
                        serviceProviderID: data?.id,
                    }
                }
            })
        }
        else if (type === "active" || type === "delete") { handleStatusDelete(type, data) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getServiceProvider(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getServiceProvider(0, value, searchText)
    }

    // Function to toggle status and delete service provider
    const handleStatusDelete = async (type, data) => {
        const currentDate = new Date().toISOString()
        const profileID = localStorage.getItem(LocalStorageKeys.profileID)

        const payload = {
            is_active: type === "active" ? !data?.data?.is_active : undefined,
            is_delete: type === "delete" ? true : undefined,
            updated_at: currentDate ?? undefined,
            updated_by: profileID ?? undefined
        }

        client.mutate({
            mutation: UPDATE_SERVICE_PROVIDER, fetchPolicy: 'network-only',
            variables: {
                serviceProviderID: data?.id,
                payload: payload
            }
        }).then((r) => {
            getServiceProvider()
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: `Service Provider ${type === "delete" ? `Deleted` : `Updated`} Successfully.!!!`,
            })
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: "Some Thing Went Wrong"
            })
        })
    }

    const render = () => {
        return <>
            <Subheader hideBackButton={true} title="Service Provider"
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            <div className={classes.root}>
                <Grid container className={classes.content} spacing={1}>
                    <Grid item xs={4}>
                        <SearchFilter value={searchText} placeholder="Search Service Provider"
                            handleChange={(value) => handleSearch(value)} />
                    </Grid>
                    <Grid item xs={8}>
                        <Box display={"flex"} justifyContent={"end"}>
                            <Stack direction="row" spacing={2}
                                divider={<Divider orientation="vertical" flexItem />}>
                                <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                    className={classes.filterButton}>
                                    <Badge variant="dot" color="primary"
                                        invisible={!(filterData.status?.length > 0)}>
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>
                                {permission.create && <Button variant="contained" className={classes.button}
                                    onClick={handleAddServiceProvider}>
                                    Add Service Provider
                                </Button>}
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TableWithPagination
                            heading={ServiceProviderHeading}
                            rows={ServiceProviderRows}
                            path={ServiceProviderPath}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={ServiceProviderType}
                            handlePagination={handleTablePagination}
                            handleChangeLimit={handleTablePageLimit}
                            totalRowsCount={serviceProviderList?.totalRowsCount}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            view={permission.read}
                            edit={permission.update}
                            delete={permission.delete} />
                    </Grid>
                </Grid>
                <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => setFilterData(value)}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: StatusOptionList,
                            isMulti: true,
                            state_name: "status",
                            label: "Status"
                        },
                    ]} />
            </div>
        </>
    }

    return <div >
        {accessCheckRender(render, permission)}
    </div>
}