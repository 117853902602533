import { Box, Grid } from "@mui/material"
import React, { useState } from "react"
import { withNamespaces } from "react-i18next"
import { LeadIcon } from "../../assets/lead"
import { DialogDrawer } from "../../components"
import { CompletedJobDataType, CompletedJobHdr, CompletedJobPath, OngoingJobDataType, OngoingJobHdr, OngoingJobPath, ResourceDataType, ResourceHdr, ResourcePath } from "../../utils"
import { DashboardStyles } from "./style"
import { CardDetails } from "../propertymanagerdashboard/components"
import { UtilityGraphComponent } from "./components/graphcomponent"
import GraphComponent from "../propertymanagerdashboard/components/graphcomponent";

const DashboardComponent = ({ t, data = {}, searchText = "", handleSearch = () => false, completedJobData = [], selectedResource = "", onChangeResource = () => false, resourcesJobData = [], property_id = "", resourcesVsJobData = [], timeOffType = "", onChangeTimeOffType = () => false, timeOffData = [], handleIcon = () => false, updateState = () => false, utilityData = {}, setUtilityData = () => false, selectedCompany = {}, utilityGraphData = {}, defaultUtilityUnit = "", defaultUtilityItem = "", page = 1, limit = 4, handleChangeLimit = () => false, handlePagination = () => false, handleResourcesVsJobsChangeLimit = () => false, handleResourcesVsJobsPagination = () => false, handleOngoingJobsPagination = () => false, handleOngoingJobsChangeLimit = () => false }) => {
    const classes = DashboardStyles()
    const [zoom, setZoom] = useState(false);
    const [zoomGraph, setZoomGraph] = useState("");
    const scale = {
        x: {
            grid: {
                // drawBorder: false,
                display: false,
            }
        },
        y: {
            grid: {
                // drawBorder: false,
                display: false,
            }
        },
    };
    const status = [
        {
            icon: <LeadIcon />,
            count: data?.details?.unassigned_job_count ?? 0,
            sub: t("Unassigned"),
            icon_type: true
        },
        {
            image: "/images/propertdashboard/block.svg",
            count: data?.details?.commenced_count ?? 0,
            sub: t("Commenced"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/pausedIcon.png",
            count: data?.details?.paushed_count ?? 0,
            sub: t("Paused"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/managerIcon.png",
            count: data?.details?.job_yet_verify_count ?? 0,
            sub: t("Manager Review"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/assigned.svg",
            count: data?.details?.assigned_job_count ?? 0,
            sub: t("Assigned"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/activeunits.png",
            count: data?.details?.completed_jobs_count ?? 0,
            sub: t("Completed"),
            image_type: true
        },
    ]
    const jobCompletionHistory = [
        {
            fill: "#F9A666",
            name: t("On Time"),
            count: resourcesJobData?.CompletionHistory?.Ontime
        },
        {
            fill: "#F66C02",
            name: t("Delayed"),
            count: resourcesJobData?.CompletionHistory?.delayed
        },
    ]

    const otherData = [
        {
            image: "/images/propertdashboard/ParkingIcon.png",
            count: data?.details?.utility_pass_count ?? 0,
            sub: t("Amenity Pass"),
            image_type: true
        },
        {

            icon: <LeadIcon />,
            count: data?.details?.utility_readings_count ?? 0,
            sub: t("Utility Reading"),
            icon_type: true
        },
        {
            image: "/images/invoice.svg",
            count: data?.details?.utility_invoice_count ?? 0,
            sub: t("Utility Invoice"),
            image_type: true
        },
    ]


    const utilityCards = [
        {
            title: t("Current Reading"),
            reading: utilityGraphData?.readings?.present_meter_readings ?? 0,
            image: "/images/propertdashboard/occupied.png"
        },
        {
            title: t("Past Reading"),
            reading: utilityGraphData?.readings?.previous_meter_readings ?? 0,
            image: "/images/propertdashboard/activeunits.png"

        },
    ]


    // zoom function
    const zoomGraphFunction = (e) => {
        setZoomGraph(e);
        setZoom(true);
    };

    // close popup
    const handleCloseModule = () => {
        setZoom(false);
    };
    const handleResourceIcon = (type, data) => {
        onChangeResource(data)
     
    }
    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} lg={1.5} sx={{ display: "flex" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CardDetails data={{
                                icon: <LeadIcon />,
                                count: data?.details?.open_order_count ?? 0,
                                sub: t("Open Orders"),
                                icon_type: true
                            }} padding={16} t={t} />
                        </Grid>
                        <Grid item xs={12}>
                            <CardDetails data={{
                                image: "/images/propertdashboard/activeunits.png",
                                count: data?.details?.completed_order_count ?? 0,
                                sub: t("Completed"),
                                image_type: true
                            }} padding={16} t={t} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8} lg={4.5}>
                    {/* work Orders */}
                    {/* <GraphComponent
                        title={"Inspection Work Orders"}
                        graphData={data?.WorkOrders}
                        isPie
                        margin={"30px"}
                        onZoomClick={zoomGraphFunction}
                        isZoom
                        textMargin={'0px 0px 16px'}
                        divider1={
                            {
                                xs: 12,
                                sm: 12,
                                md: 12,
                                lg: 12
                            }
                        }
                        divider={
                            {
                                xs: 12,
                                sm: 12,
                                md: 12,
                                lg: 12
                            }
                        }
                        gradiantStyle={{
                            margin: '0px 0px 0px',
                            display: "auto",
                            alignItems: "center",
                            top: {
                                lg: "auto",
                                md: '16px',
                                sm: "3px",
                            },
                            height: {
                                md: "400px"
                            }
                        }}
                    /> */}


                    <GraphComponent
                        t={t}
                        title={"Inspection Work Orders"}
                        header={t("Inspection Work Orders")}
                        graphData={data?.WorkOrders ?? []}
                        isPie
                        margin={"20px"}
                        minWidth={true}
                        isZoom
                        onZoomClick={zoomGraphFunction}
                        textMargin={'0px 0px 16px'}
                        divider={
                            {
                                xs: 12,
                                sm: 6,
                                md: 12,
                                lg: 12
                            }
                        }
                        divider1={
                            {
                                xs: 12,
                                sm: 6,
                                md: 12,
                                lg: 12
                            }
                        }
                        gradiantStyle={{
                            margin: '15px 0px 0px',
                            top: {
                                lg: "auto",
                                md: '16px',
                                sm: "0px important",
                            },
                            bottom: {
                                lg: "auto",
                                md: '16px',
                                sm: "0px",
                            },
                            height: {
                                md: "400px"
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                    {/* work Orders */}
                    <GraphComponent
                        t={t}
                        title={"Inspection Job Type"}
                        header={t("Inspection Job Type")}
                        graphData={data?.inspection_order_graph}
                        innerRadius={60}
                        isTotal={true}
                        total={data?.inspectionJobTotal}
                        isPie
                        margin={"20px"}
                        onZoomClick={zoomGraphFunction}
                        isZoom
                        textMargin={'0px 0px 16px'}
                        divider1={
                            {
                                xs: 12,
                                sm: 7.4,
                                md: 12,
                                lg: 12
                            }
                        }
                        divider={
                            {
                                xs: 12,
                                sm: 4.6,
                                md: 12,
                                lg: 12
                            }
                        }
                        gradiantStyle={{
                            margin: '0px 0px 0px',
                            display: "auto",
                            alignItems: "center",
                            top: {
                                lg: "auto",
                                md: '16px',
                                sm: "3px",
                            },
                            height: {
                                md: "400px"
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={3.5}>
                        {/* Job Status */}
                        <GraphComponent
                            t={t}
                            title={"Inspection Job Status"}
                            header={t("Inspection Job Status")}
                            graphData={data?.job_status_graph}
                            isBar
                            scale={scale}
                            gheight="250px"
                            isUtility={false}
                            onZoomClick={zoomGraphFunction}
                            isZoom
                            justifyContent={'start'}
                            gradiantStyle={{
                                margin: '15px 0px 0px',
                                top: {
                                    lg: "auto",
                                    md: '7px',
                                    sm: "3px",
                                },
                                height: {
                                    sm: "63px"
                                }
                            }}
                        />

                    </Grid>
                    <Grid item xs={12} md={12} lg={3.5}>
                        <Grid container spacing={2}>
                            {status?.map((x) => {
                                return (
                                    <Grid item xs={6} md={4} lg={6}>
                                        <CardDetails data={x} maxHeight={"125px"} t={t} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={5}>
                        {/* utility readings */}
                        <Box height={"263px"}>
                            <UtilityGraphComponent
                                t={t}
                                title={"Utility Readings"}
                                header={t("Utility Readings")}
                                graphData={utilityGraphData?.graphData}
                                margin={"20px"}
                                isUtility
                                utilityCards={utilityCards}
                                selectedCompany={selectedCompany}
                                utilityData={utilityData}
                                setUtilityData={setUtilityData}
                                updateState={updateState}
                                defaultUtilityUnit={defaultUtilityUnit}
                                defaultUtilityItem={defaultUtilityItem}

                            />
                        </Box>
                        <Grid container spacing={2} mt={0.3}>
                            {otherData?.map((x) => {
                                return (
                                    <Grid item xs={12} md={4} lg={4}>
                                        <CardDetails data={x} maxHeight={"125px"} t={t} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={8}>
                        <Box height="350px">
                            <GraphComponent
                                t={t}
                                isTable
                                title={"Completed jobs"}
                                header={t("Completed jobs")}
                                tableData={completedJobData?.list}
                                tabelheight={200}
                                heading={CompletedJobHdr(t)}
                                path={CompletedJobPath}
                                dataType={CompletedJobDataType}
                                isSearch={true}
                                showpagination={true}
                                totalRowsCount={completedJobData?.count}
                                page={page?.completedJobs}
                                limit={limit?.completedJobsLimit}
                                handleChangeLimit={handleChangeLimit}
                                handlePagination={handlePagination}
                                handleSearch={handleSearch}
                                searchText={searchText}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                        {/* work Orders */}
                        <Box height="350px">
                            <GraphComponent
                                t={t}
                                title={"Projected Vs Actual Vs Others"}
                                header={t("Projected Vs Actual Vs Others")}
                                graphData={data?.ProjectedHoursGraphData}
                                margin={"20px"}
                                isBar
                                scale={scale}
                                gheight="250px"
                                isDatalabels={false}
                                isLegend={true}
                                onZoomClick={zoomGraphFunction}
                                isZoom
                                justifyContent={'start'}
                                gradiantStyle={{
                                    margin: '15px 0px 0px',
                                    top: {
                                        lg: "auto",
                                        md: '7px',
                                        sm: "3px",
                                    },
                                    height: {
                                        sm: "63px"
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} lg={8} alignItems={"center"}>
                        <Box height="340px">
                            <GraphComponent
                                t={t}
                                isTable
                                title={"Resources Vs Jobs"}
                                header={t("Resources Vs Jobs")}
                                tableData={resourcesVsJobData?.list}
                                tabelheight={200}
                                heading={ResourceHdr(t)}
                                path={ResourcePath}
                                dataType={ResourceDataType}
                                handleSearch={handleSearch}
                                searchText={searchText}
                                enable_double_click={true}
                                occupancyNavigate={false}
                                showpagination={true}
                                totalRowsCount={resourcesVsJobData?.count}
                                page={page?.resourcesvsJobs}
                                limit={limit?.resourcesVsJobsLimit}
                                handleChangeLimit={handleResourcesVsJobsChangeLimit}
                                handlePagination={handleResourcesVsJobsPagination}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4} >
                        {/* work Orders */}
                        <Box height="340px">
                            <GraphComponent
                                t={t}
                                title={`${selectedResource?.label ?? ""} - Job Completion History`}
                                title1={"Job Completion History"}
                                header={t("Job Completion History")}
                                graphData={jobCompletionHistory?.some((x) => { return x?.count > 0 }) ? jobCompletionHistory : []}
                                innerRadius={50}
                                isTotal={true}
                                total={resourcesJobData?.CompletionHistory?.total}
                                isPie
                                margin={"20px"}
                                onZoomClick={zoomGraphFunction}
                                isZoom
                                textMargin={'0px 0px 16px'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 7.4,
                                        md: 12,
                                        lg: 12
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 4.6,
                                        md: 12,
                                        lg: 12
                                    }
                                }
                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "auto",
                                    alignItems: "center",
                                    top: {
                                        lg: "auto",
                                        md: '16px',
                                        sm: "3px",
                                    },
                                    height: {
                                        md: "400px"
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2} >

                    <Grid item xs={12} sm={12} lg={8} alignItems={"center"}>

                        <Box height="350px">
                            <GraphComponent
                                t={t}
                                isTable
                                title={`${selectedResource?.label ?? ""} - Ongoing jobs (${resourcesJobData?.list?.length})`}
                                header={`${selectedResource?.label ?? ""} - ${t("Ongoing jobs")} (${resourcesJobData?.list?.length})`}
                                tableData={resourcesJobData?.list}
                                tabelheight={200}
                                heading={OngoingJobHdr(t)}
                                // isSelect={true}
                                path={OngoingJobPath}
                                dataType={OngoingJobDataType}
                                selectedResource={selectedResource}
                                onChangeResource={onChangeResource}
                                handleSearch={handleSearch}
                                handleIcon={handleIcon}
                                showpagination={true}
                                totalRowsCount={resourcesJobData?.count}
                                page={page?.ongoingJobs}
                                limit={limit?.ongoingJobsLimit}
                                handlePagination={handleOngoingJobsPagination}
                                handleChangeLimit={handleOngoingJobsChangeLimit}
                                searchText={searchText}
                                property_id={property_id}
                            />

                        </Box>
                    </Grid>


                    <Grid item xs={12} sm={12} lg={4} >
                        {/* Time Off */}
                        <Box height="350px">
                            <GraphComponent
                                t={t}
                                title={`${selectedResource?.label ?? ""} - Time off Graph`}
                                title1={"Time off Graph"}
                                header={t("Time off Graph")}
                                graphData={timeOffData?.data}
                                innerRadius={60}
                                timeOff={true}
                                isTotal={true}
                                total={timeOffData?.count}
                                type={timeOffType}
                                onChangeType={onChangeTimeOffType}
                                isPie
                                margin={"40px"}
                                onZoomClick={zoomGraphFunction}
                                isZoom
                                textMargin={'0px 0px 16px'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 7.4,
                                        md: 12,
                                        lg: 12
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 4.6,
                                        md: 12,
                                        lg: 12
                                    }
                                }
                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "auto",
                                    alignItems: "center",
                                    top: {
                                        lg: "auto",
                                        md: '16px',
                                        sm: "3px",
                                    },
                                    height: {
                                        md: "400px"
                                    }
                                }}
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Box>
            {/* zoom dialog for each graph */}
            <DialogDrawer
                header={t(zoomGraph)}
                maxWidth={"md"}
                handleClose={handleCloseModule}
                open={zoom}
                height={"auto"}
                borderRadius={"12px"}
                padding={'0px'}
                onClose={handleCloseModule}
                component={
                    <div>
                        {zoomGraph === "Inspection Work Orders" && (
                            <GraphComponent
                                graphData={data?.WorkOrders}
                                isPie
                                t={t}
                                margin={"20px"}
                                padding={"16px"}
                                maxHeightGraph={"250px"}
                                minWidth={false}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }

                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '-8px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}
                            />
                        )}
                        {zoomGraph === "Inspection Job Type" && (
                            <GraphComponent
                                graphData={data?.inspection_order_graph}
                                isPie
                                innerRadius={100}
                                isTotal
                                total={data?.inspectionJobTotal}
                                paddingAngle={2}
                                t={t}
                                margin={"20px"}
                                padding={"16px"}
                                centerTop={"44%"}
                                maxHeightGraph={"250px"}
                                minWidth={false}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }

                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '-8px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}

                            />
                        )}
                        {zoomGraph === "Inspection Job Status" && (
                            <GraphComponent
                                // title={"Inspection Job Status"}
                                graphData={data?.job_status_graph}
                                isBar
                                scale={scale}
                                t={t}
                                margin={"20px"}
                                minWidth={false}
                                gheight={'228px'}
                                gwidth={400}
                                is_popUp
                                justifyContent={'start'}
                                padding={"0px 16px 16px"}
                                // divider1={
                                //     {
                                //         xs: 12,
                                //         sm: 4,
                                //         md: 4,
                                //         lg: 4
                                //     }
                                // }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12
                                    }
                                }

                                gradiantStyle={{
                                    margin: '15px 0px 0px',
                                    grid_template: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    maxHeight: "220px",
                                    top: {
                                        lg: "auto",
                                        md: '16px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "220px",
                                        sm: "220px",
                                        lg: "100%"
                                    }
                                }}
                            />
                        )}
                        {zoomGraph === "Projected Vs Actual Vs Others" && (
                            <GraphComponent
                                graphData={data?.ProjectedHoursGraphData ?? []}
                                isBar
                                scale={scale}
                                margin={"20px"}
                                minWidth={false}
                                gheight={'500px'}
                                gwidth={'400px'}
                                t={t}
                                is_popUp
                                justifyContent={'start'}
                                padding={"0px 16px 16px"}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 4,
                                        md: 4,
                                        lg: 4
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12
                                    }
                                }

                                gradiantStyle={{
                                    margin: '15px 0px 0px',
                                    grid_template: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '16px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}
                            />
                        )}
                        {zoomGraph === `${selectedResource?.label ?? ""} - Job Completion History` && (
                            <GraphComponent
                                graphData={jobCompletionHistory}
                                innerRadius={100}
                                isTotal={true}
                                t={t}
                                centerTop={"44%"}
                                total={resourcesJobData?.CompletionHistory?.total}
                                isPie
                                margin={"20px"}
                                padding={"16px"}
                                maxHeightGraph={"250px"}
                                minWidth={false}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }

                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '-8px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}
                            />
                        )}
                        {zoomGraph === `${selectedResource?.label ?? ""} - Time off Graph` && (
                            <GraphComponent
                                graphData={timeOffData?.data}
                                innerRadius={100}
                                timeOff={true}
                                t={t}
                                centerTop={"44%"}
                                isTotal={true}
                                total={timeOffData?.count}
                                type={timeOffType}
                                onChangeType={onChangeTimeOffType}
                                isPie
                                margin={"20px"}
                                padding={"16px"}
                                maxHeightGraph={"250px"}
                                minWidth={false}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }

                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '-8px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}
                            />
                        )}
                    </div>
                }
            />
        </Box >
    )
}
export default withNamespaces("inspectionManagerDashboard")(DashboardComponent);