import {
  activityCategoryDrawer,
  activityCategoryHeading,
  activityCategoryPath,
  activityCategoryType,
  activityDrawer,
  activityHeading,
  activityPath,
  activitySubCategoryDrawer,
  activitySubCategoryHeading,
  activitySubCategoryPath,
  activitySubCategoryType,
  activityType,
  CitiesDrawer,
  CitiesHeading,
  CitiesPath,
  CitiesType,
  CityAreaDrawer,
  CityAreaHeading,
  CityAreaPath,
  CityAreaType,
  CompanyDrawer,
  CompanyHeading,
  CompanyPath,
  CompanyType,
  CountryDrawer,
  Countryheading,
  CountryPath,
  CountryType,
  CurrencyDrawer,
  CurrencyHeading,
  CurrencyPath,
  CurrencyType,
  getTotalArray,
  leadTypeDrawer,
  leadTypeHeading,
  leadTypePath,
  leadTypeType,
  StateDrawer,
  StateHeading,
  StatePath,
  StateType,
  VATGroupDrawer,
  vatGroupheading,
  VATGroupItemDrawer,
  vatGroupItemheading,
  vatGroupItemPath,
  vatGroupItemType,
  vatGroupPath,
  vatGroupType
} from ".";
import {
  ACTIVITY_CATEGORY_SEARCH,
  ACTIVITY_MASTER_SEARCH,
  ACTIVITY_SUB_CATEGORY_SEARCH,
  CITY_AREA_SEARCH,
  CITY_SEARCH,
  COMPANY_SEARCH_SEARCH,
  COUNTRY_MASTER_SEARCH,
  CURRENCY_MASTER_SEARCH,
  EDIT_ACTIVITY_CATEGORY,
  EDIT_ACTIVITY_MASTER,
  EDIT_ACTIVITY_SUB_CATEGORY,
  EDIT_CITY,
  EDIT_CITY_AREA,
  EDIT_COMPANY,
  EDIT_COUNTRY_MASTER,
  EDIT_CURRENCY_MASTER,
  EDIT_LEAD_TYPE,
  EDIT_STATE,
  EDIT_VAT_GROUP,
  EDIT_VAT_GROUP_ITEM,
  GET_ACTIVITY_CATEGORY,
  GET_ACTIVITY_MASTER,
  GET_ACTIVITY_SUB_CATEGORY,
  GET_CITY,
  GET_CITY_AREA,
  GET_COMPANY,
  GET_COUNTRY_MASTER,
  GET_CURRENCY_MASTER,
  GET_LEAD_TYPE,
  GET_STATE,
  GET_VAT_GROUP,
  GET_VAT_GROUP_ITEM_BY_COMPANY,
  LEAD_TYPE_SEARCH,
  STATE_SEARCH,
  UPDATE_ACTIVITY_CATEGORY,
  UPDATE_ACTIVITY_MASTER,
  UPDATE_ACTIVITY_SUB_CATEGORY,
  UPDATE_CITY,
  UPDATE_CITY_AREA,
  UPDATE_COMPANY,
  UPDATE_COUNTRY_MASTER,
  UPDATE_CURRENCY_MASTER,
  UPDATE_LEAD_TYPE,
  UPDATE_STATE,
  UPDATE_VAT_GROUP,
  UPDATE_VAT_GROUP_ITEM,
  VAT_GROUP_ITEM_SEARCH,
  VAT_GROUP_SEARCH
} from "../graphql/bussinessMasterQuery";

export const bussinessActions = (type, offset, limit, search, company, status , t) => {
  switch (type) {
    case "Country":
      return {
        type: GET_COUNTRY_MASTER(offset, limit, search).loc.source.body,
        resfunc: (array) => {
          let arr = array?.country_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.country_name = data?.country_name;
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_external = data?.is_external;
            mainData.icon = "editviewtoggle";
            mainData.index = offset + index + 1;
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: Countryheading(t),
          path: CountryPath,
          type: CountryType,
          drawer: CountryDrawer(t),
        },
      };
    case "Currency":
      return {
        type: GET_CURRENCY_MASTER(offset, limit, search, status).loc.source.body,
        resfunc: (array) => {
          let arr = array?.currency_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.name = data?.name;
            mainData.code = data?.code;
            mainData.symbol = data?.symbol;
            mainData.country_name = {
              label: data?.country_master?.country_name,
              value: data?.country_master?.id
            }
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_external = data?.is_external;
            mainData.index = offset + index + 1;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: CurrencyHeading(t),
          path: CurrencyPath,
          type: CurrencyType,
          drawer: CurrencyDrawer(t),
        },
      };
    case "Tax Group":
      return {
        type: GET_VAT_GROUP(offset, limit, search, status).loc.source.body,
        resfunc: (array) => {
          let arr = array?.vat_group_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.group_name = data?.group_name;
            mainData.tax_group_code = {
              label: data?.vat_group_code,
              value: data?.vat_group_code
            }
            mainData.total_rate = data?.total_rate?.[0]?.total_rate;
            mainData.country_name = {
              label: data?.country_master?.country_name,
              value: data?.country_master?.id
            }
            mainData.coa_account_number=data?.com_master!==null?{
              label:data?.com_master?.name+"-"+parseInt(data?.com_master?.number),
              value:data?.com_master?.id
            }:""
            mainData.account_type=data?.com_master?.coa_type;
            mainData.account_number=data?.com_master!==null?data?.com_master?.name+"-"+parseInt(data?.com_master?.number):"-";
            mainData.Coa_account_type=data?.com_master?.coa_type!==null?{
              label:data?.com_master?.coa_type,
              value:data?.com_master?.coa_type
            }:""
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_external = data?.is_external;
            mainData.index = offset + index + 1;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: vatGroupheading(t),
          path: vatGroupPath,
          type: vatGroupType,
          drawer: VATGroupDrawer(t),
        },
      };
    case "Tax Item":
      return {
        type: GET_VAT_GROUP_ITEM_BY_COMPANY(offset, limit, search, company?.value, status).loc.source.body,
        resfunc: (array) => {
          let arr = array?.vat_group_item?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.group_name = data?.group_name;
            mainData.tax_group_code = {
              label: data?.vat_group_item_code,
              value: data?.vat_group_item_code
            }
            mainData.Vat_group = {
              label: data?.vat_group_master?.group_name,
              value: data?.vat_group_master?.id
            }
            mainData.country = {
              label: data?.vat_group_master?.country_masterByID?.country_name,
              value: data?.vat_group_master?.country_masterByID?.id
            }
            mainData.rate = data?.rate;
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_external = data?.is_external;
            mainData.index = offset + index + 1;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: vatGroupItemheading(t),
          path: vatGroupItemPath,
          type: vatGroupItemType,
          drawer: VATGroupItemDrawer(t),
        },
      };
    case "Company":
      return {
        type: GET_COMPANY(offset, limit, search, localStorage.getItem('clinetID')).loc.source.body,
        resfunc: (array) => {
          let arr = array?.company_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.name = data?.name;
            mainData.upload_logo = {
              logo: data?.logo,
              name: data?.name
            };
            mainData.company_code_name = data?.company_no ?? "";
            mainData.company_name = data?.name ?? "";
            mainData.city = data?.company_address?.[0]?.city;
            mainData.country_name = data?.company_address?.[0]?.country;
            mainData.company_type = data?.company_type_master?.name;
            // mainData.city = {
            //   label: data?.cities_master?.city,
            //   value: data?.cities_master?.id
            // };
            // mainData.country_name = {
            //   label: data?.cities_master?.state_master?.country_master?.country_name,
            //   value: data?.cities_master?.state_master?.country_master?.id
            // };
            mainData.state_name = {
              label: data?.cities_master?.state_master?.state_name,
              value: data?.cities_master?.state_master?.id
            };
            mainData.currency = data?.currency ? {
              label: data?.currency?.symbol,
              value: data?.currency?.id
            } : [];
            mainData.tax_group = data?.vat_group?.group_name ?? data?.vat_group_master?.group_name
            mainData.tax_rate = !!data?.vat_group?.vat_group_item ?
              `${getTotalArray(data?.vat_group?.vat_group_item, 'sum_rate')} %` :
              !!data?.vat_group_master?.vat_group_item ? `${getTotalArray(data?.vat_group_master?.vat_group_item, 'sum_rate')} %` : "";
            // mainData.tax_group = {
            //   label: data?.vat_group_master?.group_name,
            //   value: data?.vat_group_master?.id
            // }
            mainData.company_code = data?.company_no;
            // mainData.company_registration = data?.company_registration_number;
            // mainData.tax_registration = data?.tax_number;
            mainData.tax_registration = data?.tax_registration_number;
            mainData.description = data?.description;
            mainData.is_active = data?.is_active;
            mainData.is_external = data?.is_external;
            mainData.cash_collection_office = data?.cash_collection_office;
            mainData.cheque_name = data?.cheque_name;
            mainData.primary_bank_name = data?.primary_bank_name;
            mainData.primary_account_no = data?.primary_account_no;
            mainData.financial_system_code = data?.financial_system_code;
            mainData.account_type = data?.account_type;
            mainData.routing_type = data?.routing_type;
            mainData.bank_routing_code = data?.bank_routing_code;
            mainData.iban = data?.iban;
            mainData.index = offset + index + 1;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: CompanyHeading(t),
          path: CompanyPath,
          type: CompanyType,
          drawer: CompanyDrawer(t),
        },
      };
    case "State":
      return {
        type: GET_STATE(offset, limit, search).loc.source.body,
        resfunc: (array) => {
          let arr = array?.state_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.state_name = data?.state_name;
            mainData.country_name = {
              label: data?.country_master?.country_name,
              value: data?.country_master?.id
            };
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_external = data?.is_external;
            mainData.index = offset + index + 1;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: StateHeading(t),
          path: StatePath,
          type: StateType,
          drawer: StateDrawer(t),
        },
      };
    case "City":
      return {
        type: GET_CITY(offset, limit, search).loc.source.body,
        resfunc: (array) => {
          let arr = array?.cities_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.city = data?.city;
            mainData.country_name = {
              label: data?.state_master?.country_master?.country_name,
              value: data?.state_master?.country_master?.id
            };
            mainData.state_name = {
              label: data?.state_master?.state_name,
              value: data?.state_master?.id
            };
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_external = data?.is_external;
            mainData.index = offset + index + 1;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: CitiesHeading(t),
          path: CitiesPath,
          type: CitiesType,
          drawer: CitiesDrawer(t),
        },
      };
    case "Locality":
      return {
        type: GET_CITY_AREA(offset, limit, search).loc.source.body,
        resfunc: (array) => {
          let arr = array?.city_area_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.area_name = data?.area_name;
            mainData.city = {
              label: data?.cities_master?.city,
              value: data?.cities_master?.id
            };
            mainData.country_name = {
              label: data?.cities_master?.state_master?.country_master?.country_name,
              value: data?.cities_master?.state_master?.country_master?.id
            };
            mainData.state_name = {
              label: data?.cities_master?.state_master?.state_name,
              value: data?.cities_master?.state_master?.id
            };
            mainData.description = data?.description;
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_external = data?.is_external;
            mainData.index = offset + index + 1;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: CityAreaHeading,
          path: CityAreaPath,
          type: CityAreaType,
          drawer: CityAreaDrawer,
        },
      };
    case "Invoice":
      return {
        // type: GET_AMENITIES_TYPE_MASTER,
        // resfunc: (array) => {
        //     let arr = array?.amenities_type_master.map(data => {
        //         const aObj = {}
        //         aObj.name = data?.amenities_name
        //         aObj.description = data?.description
        //         aObj.notes = data?.notes
        //         aObj.icon = "editviewtoggle"
        //         aObj.is_external = data.is_external
        //         aObj.is_active = data.is_active
        //         return aObj;
        //     })
        //     return arr
        // },
        // tbdata: {
        //     heading: AmenitiesHeading,
        //     path: AmenitiesPath,
        //     type: Amenitiestype,
        //     drawer: Amenitiesdrawer,
        // }
      };
    case "Quotation status":
      return {
        // type: GET_AMENITIES_TYPE_MASTER,
        // resfunc: (array) => {
        //     let arr = array?.amenities_type_master.map(data => {
        //         const aObj = {}
        //         aObj.name = data?.amenities_name
        //         aObj.description = data?.description
        //         aObj.notes = data?.notes
        //         aObj.icon = "editviewtoggle"
        //         aObj.is_external = data.is_external
        //         aObj.is_active = data.is_active
        //         return aObj;
        //     })
        //     return arr
        // },
        // tbdata: {
        //     heading: AmenitiesHeading,
        //     path: AmenitiesPath,
        //     type: Amenitiestype,
        //     drawer: Amenitiesdrawer,
        // }
      };
    case "Lead type":
      return {
        type: GET_LEAD_TYPE(offset, limit, search).loc.source.body,
        resfunc: (array) => {
          let arr = array?.lead_type_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.name = data?.name;
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.index = offset + index + 1;
            mainData.is_external = data?.is_external;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: leadTypeHeading,
          path: leadTypePath,
          type: leadTypeType,
          drawer: leadTypeDrawer,
        },
      };
    case "Lead status":
      return {
        // type: GET_AMENITIES_TYPE_MASTER,
        // resfunc: (array) => {
        //     let arr = array?.amenities_type_master.map(data => {
        //         const aObj = {}
        //         aObj.name = data?.amenities_name
        //         aObj.description = data?.description
        //         aObj.notes = data?.notes
        //         aObj.icon = "editviewtoggle"
        //         aObj.is_external = data.is_external
        //         aObj.is_active = data.is_active
        //         return aObj;
        //     })
        //     return arr
        // },
        // tbdata: {
        //     heading: AmenitiesHeading,
        //     path: AmenitiesPath,
        //     type: Amenitiestype,
        //     drawer: Amenitiesdrawer,
        // }
      };
    case "Activity":
      return {
        type: GET_ACTIVITY_MASTER(offset, limit, search).loc.source.body,
        resfunc: (array) => {
          let arr = array?.lead_activity_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.type = data?.type;
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.index = offset + index + 1;
            mainData.is_external = data?.is_external;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: activityHeading(t),
          path: activityPath,
          type: activityType,
          drawer: activityDrawer(t),
        },
      };
    case "Activity Category":
      return {
        type: GET_ACTIVITY_CATEGORY(offset, limit, search).loc.source.body,
        resfunc: (array) => {
          let arr = array?.activity_category_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.activity_category = data?.activity_category;
            mainData.type = {
              label: data?.lead_activity_master?.type,
              value: data?.lead_activity_master?.id
            };
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.index = offset + index + 1;
            mainData.is_external = data?.is_external;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: activityCategoryHeading(t),
          path: activityCategoryPath,
          type: activityCategoryType,
          drawer: activityCategoryDrawer(t),
        },
      };
    case "Activity Sub-Category":
      return {
        type: GET_ACTIVITY_SUB_CATEGORY(offset, limit, search).loc.source.body,
        resfunc: (array) => {
          let arr = array?.activity_sub_category_master.map((data, index) => {
            const mainData = {};
            mainData.id = data?.id;
            mainData.sub_category = data?.sub_category;
            mainData.type = {
              label: data?.activity_category_master?.lead_activity_master?.type,
              value: data?.activity_category_master?.lead_activity_master?.id
            };
            mainData.activity_category = {
              label: data?.activity_category_master?.activity_category,
              value: data?.activity_category_master?.id
            };
            mainData.is_active = data.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.index = offset + index + 1;
            mainData.is_external = data.is_external;
            mainData.icon = "editviewtoggle";
            return mainData;
          });

          return arr;
        },
        tbdata: {
          heading: activitySubCategoryHeading,
          path: activitySubCategoryPath,
          type: activitySubCategoryType,
          drawer: activitySubCategoryDrawer,
        },
      };
    case "Move-in Move-out":
      return {
        // type: GET_AMENITIES_TYPE_MASTER,
        // resfunc: (array) => {
        //     let arr = array?.amenities_type_master.map(data => {
        //         const aObj = {}
        //         aObj.name = data?.amenities_name
        //         aObj.description = data?.description
        //         aObj.notes = data?.notes
        //         aObj.icon = "editviewtoggle"
        //         aObj.is_external = data.is_external
        //         aObj.is_active = data.is_active
        //         return aObj;
        //     })
        //     return arr
        // },
        // tbdata: {
        //     heading: AmenitiesHeading,
        //     path: AmenitiesPath,
        //     type: Amenitiestype,
        //     drawer: Amenitiesdrawer,
        // }
      };
    default:
      return null;
  }
};

export const bussinessUpdateActions = {
  Country: {
    type: UPDATE_COUNTRY_MASTER,
  },
  Currency: {
    type: UPDATE_CURRENCY_MASTER,
  },
  "TAX Group": {
    type: UPDATE_VAT_GROUP,
  },
  "Tax Item": {
    type: UPDATE_VAT_GROUP_ITEM,
  },
  Company: {
    type: UPDATE_COMPANY,
  },
  State: {
    type: UPDATE_STATE,
  },
  City: {
    type: UPDATE_CITY,
  },
  "Locality": {
    type: UPDATE_CITY_AREA,
  },
  Invoice: {
    // type: GET_AMENITIES_TYPE_MASTER,
    // resfunc: (array) => {
    //     let arr = array?.amenities_type_master.map(data => {
    //         const aObj = {}
    //         aObj.name = data?.amenities_name
    //         aObj.description = data?.description
    //         aObj.notes = data?.notes
    //         aObj.icon = "editviewtoggle"
    //         aObj.is_external = data.is_external
    //         aObj.is_active = data.is_active
    //         return aObj;
    //     })
    //     return arr
    // },
    // tbdata: {
    //     heading: AmenitiesHeading,
    //     path: AmenitiesPath,
    //     type: Amenitiestype,
    //     drawer: Amenitiesdrawer,
    // }
  },
  "Quotation status": {
    // type: GET_AMENITIES_TYPE_MASTER,
    // resfunc: (array) => {
    //     let arr = array?.amenities_type_master.map(data => {
    //         const aObj = {}
    //         aObj.name = data?.amenities_name
    //         aObj.description = data?.description
    //         aObj.notes = data?.notes
    //         aObj.icon = "editviewtoggle"
    //         aObj.is_external = data.is_external
    //         aObj.is_active = data.is_active
    //         return aObj;
    //     })
    //     return arr
    // },
    // tbdata: {
    //     heading: AmenitiesHeading,
    //     path: AmenitiesPath,
    //     type: Amenitiestype,
    //     drawer: Amenitiesdrawer,
    // }
  },
  "Lead type": {
    type: UPDATE_LEAD_TYPE,
  },
  "Lead status": {
    // type: GET_AMENITIES_TYPE_MASTER,
    // resfunc: (array) => {
    //     let arr = array?.amenities_type_master.map(data => {
    //         const aObj = {}
    //         aObj.name = data?.amenities_name
    //         aObj.description = data?.description
    //         aObj.notes = data?.notes
    //         aObj.icon = "editviewtoggle"
    //         aObj.is_external = data.is_external
    //         aObj.is_active = data.is_active
    //         return aObj;
    //     })
    //     return arr
    // },
    // tbdata: {
    //     heading: AmenitiesHeading,
    //     path: AmenitiesPath,
    //     type: Amenitiestype,
    //     drawer: Amenitiesdrawer,
    // }
  },
  Activity: {
    type: UPDATE_ACTIVITY_MASTER,
  },
  "Activity Category": {
    type: UPDATE_ACTIVITY_CATEGORY,
  },
  "Activity Sub-Category": {
    type: UPDATE_ACTIVITY_SUB_CATEGORY,
  },
  "Move-in Move-out": {
    // type: GET_AMENITIES_TYPE_MASTER,
    // resfunc: (array) => {
    //     let arr = array?.amenities_type_master.map(data => {
    //         const aObj = {}
    //         aObj.name = data?.amenities_name
    //         aObj.description = data?.description
    //         aObj.notes = data?.notes
    //         aObj.icon = "editviewtoggle"
    //         aObj.is_external = data.is_external
    //         aObj.is_active = data.is_active
    //         return aObj;
    //     })
    //     return arr
    // },
    // tbdata: {
    //     heading: AmenitiesHeading,
    //     path: AmenitiesPath,
    //     type: Amenitiestype,
    //     drawer: Amenitiesdrawer,
    // }
  },
};

export const bussinesssEditActions = {
  Country: {
    type: EDIT_COUNTRY_MASTER,
  },
  Currency: {
    type: EDIT_CURRENCY_MASTER,
  },
  "TAX Group": {
    type: EDIT_VAT_GROUP,
  },
  "Tax Item": {
    type: EDIT_VAT_GROUP_ITEM,
  },
  Company: {
    type: EDIT_COMPANY,
  },
  State: {
    type: EDIT_STATE,
  },
  City: {
    type: EDIT_CITY,
  },
  "Locality": {
    type: EDIT_CITY_AREA,
  },
  Invoice: {
    // type: EDIT_STATE,
  },
  "Quotation status": {
    // type: EDIT_STATE,
  },
  "Lead type": {
    type: EDIT_LEAD_TYPE,
  },
  "Lead status": {
    // type: EDIT_STATE,
  },
  Activity: {
    type: EDIT_ACTIVITY_MASTER,
  },
  "Activity Category": {
    type: EDIT_ACTIVITY_CATEGORY,
  },
  "Activity Sub-Category": {
    type: EDIT_ACTIVITY_SUB_CATEGORY,
  },
  "Move-in Move-out": {
    // type: EDIT_STATE,
  },
};

export const bussinessSearch = {
  Country: {
    type: COUNTRY_MASTER_SEARCH,
    resfunc: (array) => {
      let arr = array?.country_master?.map((data) => {
        let mainData = {};
        mainData.id = data?.id;
        mainData.country_name = data?.country_name;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });
      return arr;
    },
    tbdata: {
      heading: Countryheading,
      path: CountryPath,
      type: CountryType,
      drawer: CountryDrawer,
    },
  },
  Currency: {
    type: CURRENCY_MASTER_SEARCH,
    resfunc: (array) => {
      let arr = array?.currency_master?.map((data) => {
        let mainData = {};
        mainData.id = data?.id;
        mainData.name = data?.name;
        mainData.code = data?.code;
        mainData.symbol = data?.symbol;
        mainData.country_name = data?.country_name;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });
      return arr;
    },
    tbdata: {
      heading: CurrencyHeading,
      path: CurrencyPath,
      type: CurrencyType,
      drawer: CurrencyDrawer,
    },
  },
  "Tax Group": {
    type: VAT_GROUP_SEARCH,
    resfunc: (array) => {
      let arr = array?.vat_group_master?.map((data) => {
        let mainData = {};
        mainData.id = data?.id;
        mainData.group_name = data?.group_name;
        mainData.description = data?.description;
        mainData.country_name = data?.country_master?.country_name;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });
      return arr;
    },
    tbdata: {
      heading: vatGroupheading,
      path: vatGroupPath,
      type: vatGroupType,
      drawer: VATGroupDrawer,
    },
  },
  "Tax Item": {
    type: VAT_GROUP_ITEM_SEARCH,
    resfunc: (array) => {
      let arr = array?.vat_group_item?.map((data) => {
        let mainData = {};
        mainData.id = data?.id;
        mainData.group_name = data?.group_name;
        mainData.description = data?.description;
        mainData.Vat_group = data?.vat_group_master?.group_name;
        mainData.rate = data?.rate;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });
      return arr;
    },
    tbdata: {
      heading: vatGroupItemheading,
      path: vatGroupItemPath,
      type: vatGroupItemType,
      drawer: VATGroupItemDrawer,
    },
  },
  Company: {
    type: COMPANY_SEARCH_SEARCH,
    resfunc: (array) => {
      let arr = array?.company_master?.map((data) => {
        let mainData = {};
        mainData.id = data?.id;
        mainData.name = data?.name;
        mainData.description = data?.description;
        mainData.cheque_name = data?.cheque_name;
        mainData.primary_bank_name = data?.primary_bank_name;
        mainData.primary_account_no = data?.primary_account_no;
        mainData.financial_system_code = data?.financial_system_code;
        mainData.bank_routing_code = data?.bank_routing_code;
        mainData.iban = data?.iban;
        mainData.cash_collection_office = data?.cash_collection_office;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });
      return arr;
    },
    tbdata: {
      heading: CompanyHeading,
      path: CompanyPath,
      type: CompanyType,
      drawer: CompanyDrawer,
    },
  },
  State: {
    type: STATE_SEARCH,
    resfunc: (array) => {

      let arr = array?.state_master?.map((data) => {
        let mainData = {};
        mainData.id = data?.id;
        mainData.state_name = data?.state_name;
        mainData.country_name = data?.country_master?.country_name;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });
      return arr;
    },
    tbdata: {
      heading: StateHeading,
      path: StatePath,
      type: StateType,
      drawer: StateDrawer,
    },
  },
  City: {
    type: CITY_SEARCH,
    resfunc: (array) => {
      let arr = array?.cities_master?.map((data) => {
        let mainData = {};
        mainData.id = data?.id;
        mainData.city = data?.city;
        mainData.country_name = data?.country_master?.country_name;
        mainData.state_name = data?.state_master?.state_name;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });
      return arr;
    },
    tbdata: {
      heading: CitiesHeading,
      path: CitiesPath,
      type: CitiesType,
      drawer: CitiesDrawer,
    },
  },
  "Locality": {
    type: CITY_AREA_SEARCH,
    resfunc: (array) => {
      let arr = array?.city_area_master?.map((data) => {
        let mainData = {};
        mainData.id = data?.id;
        mainData.area_name = data?.area_name;
        mainData.city = data?.cities_master?.city;
        mainData.country_name = data?.country[0]?.country_name;
        mainData.description = data?.description;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });
      return arr;
    },
    tbdata: {
      heading: CityAreaHeading,
      path: CityAreaPath,
      type: CityAreaType,
      drawer: CityAreaDrawer,
    },
  },
  Invoice: {
    // type: GET_AMENITIES_TYPE_MASTER,
    // resfunc: (array) => {
    //     let arr = array?.amenities_type_master.map(data => {
    //         const aObj = {}
    //         aObj.name = data?.amenities_name
    //         aObj.description = data?.description
    //         aObj.notes = data?.notes
    //         aObj.icon = "editviewtoggle"
    //         aObj.is_external = data.is_external
    //         aObj.is_active = data.is_active
    //         return aObj;
    //     })
    //     return arr
    // },
    // tbdata: {
    //     heading: AmenitiesHeading,
    //     path: AmenitiesPath,
    //     type: Amenitiestype,
    //     drawer: Amenitiesdrawer,
    // }
  },
  "Quotation status": {
    // type: GET_AMENITIES_TYPE_MASTER,
    // resfunc: (array) => {
    //     let arr = array?.amenities_type_master.map(data => {
    //         const aObj = {}
    //         aObj.name = data?.amenities_name
    //         aObj.description = data?.description
    //         aObj.notes = data?.notes
    //         aObj.icon = "editviewtoggle"
    //         aObj.is_external = data.is_external
    //         aObj.is_active = data.is_active
    //         return aObj;
    //     })
    //     return arr
    // },
    // tbdata: {
    //     heading: AmenitiesHeading,
    //     path: AmenitiesPath,
    //     type: Amenitiestype,
    //     drawer: Amenitiesdrawer,
    // }
  },
  "Lead type": {
    type: LEAD_TYPE_SEARCH,
    resfunc: (array) => {
      let arr = array?.lead_type_master?.map((data) => {
        let mainData = {};
        mainData.id = data?.id;
        mainData.name = data?.name;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });
      return arr;
    },
    tbdata: {
      heading: leadTypeHeading,
      path: leadTypePath,
      type: leadTypeType,
      drawer: leadTypeDrawer,
    },
  },
  "Lead status": {
    // type: GET_AMENITIES_TYPE_MASTER,
    // resfunc: (array) => {
    //     let arr = array?.amenities_type_master.map(data => {
    //         const aObj = {}
    //         aObj.name = data?.amenities_name
    //         aObj.description = data?.description
    //         aObj.notes = data?.notes
    //         aObj.icon = "editviewtoggle"
    //         aObj.is_external = data.is_external
    //         aObj.is_active = data.is_active
    //         return aObj;
    //     })
    //     return arr
    // },
    // tbdata: {
    //     heading: AmenitiesHeading,
    //     path: AmenitiesPath,
    //     type: Amenitiestype,
    //     drawer: Amenitiesdrawer,
    // }
  },
  Activity: {
    type: ACTIVITY_MASTER_SEARCH,
    resfunc: (array) => {
      let arr = array?.lead_activity_master?.map((data) => {
        let mainData = {};
        mainData.id = data?.id;
        mainData.name = data?.name;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });
      return arr;
    },
    tbdata: {
      heading: activityHeading,
      path: activityPath,
      type: activityType,
      drawer: activityDrawer,
    },
  },
  "Activity Category": {
    type: ACTIVITY_CATEGORY_SEARCH,
    resfunc: (array) => {
      let arr = array?.activity_category_master?.map((data) => {
        let mainData = {};
        mainData.id = data?.id;
        mainData.activity_category = data?.activity_category;
        mainData.name = data?.lead_activity_master?.name;
        mainData.is_active = data?.is_active;
        mainData.is_external = data?.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });
      return arr;
    },
    tbdata: {
      heading: activityCategoryHeading,
      path: activityCategoryPath,
      type: activityCategoryType,
      drawer: activityCategoryDrawer,
    },
  },
  "Activity Sub-Category": {
    type: ACTIVITY_SUB_CATEGORY_SEARCH,
    resfunc: (array) => {
      let arr = array?.activity_sub_category_master.map((data) => {
        const mainData = {};
        mainData.sub_category = data?.sub_category;
        mainData.name = data?.lead_activity_master?.value
          ? data?.lead_activity_master?.value
          : data?.lead_activity_master?.name;
        mainData.activity_category = data?.activity_category[0]
          ?.activity_category?.value
          ? data?.activity_category[0]?.activity_category?.value
          : data?.activity_category[0]?.activity_category;
        // mainData.notes = data?.notes
        mainData.is_active = data.is_active;
        mainData.is_external = data.is_external;
        mainData.icon = "editviewtoggle";
        return mainData;
      });

      return arr;
    },
    tbdata: {
      heading: activitySubCategoryHeading,
      path: activitySubCategoryPath,
      type: activitySubCategoryType,
      drawer: activitySubCategoryDrawer,
    },
  },
};
