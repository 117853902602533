import React from "react"
import { Subheader, UseDebounce, SearchFilter, TableWithPagination, FilterGenerator } from "../../components"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { BodyDiv, FilterButton } from "./components/styledComponents"
import { accessCheckRender, AlertProps, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils"
import { Badge, Grid, Stack } from '@mui/material'
import FilterIMG from "../../assets/filter"
import { ActiveStatus, UnitHeading1, UnitPath1, UnitType1 } from "../../utils/unitVacancy/tableUtils"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { UnitVacancyList } from "./components/unitVacancyList";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { withNamespaces } from "react-i18next";

const UnitVacancyAllList = ({
    t
}) => {

    // constants
    const history = useHistory()
    const debounce = UseDebounce()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [list, setList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState({ status: [true] })
    const [fiterDrawer, setFilterDrawer] = React.useState(false)
    const [enumValue, setEnumValue] = React.useState({ unit_category: [] })
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [dialogState, setDialogState] = React.useState({})
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // useEffect to get property list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedCompany?.value) { getList() }
        // eslint-disable-next-line
    }, [selectedCompany, filterData])

    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.unit_category])
        setEnumValue({ unit_category: result?.unit_category })
    }

    // useEffect to load the Enum value while initial load
    React.useEffect(() => {
        getEnum()
    }, [])

    // Function to get property list based on the input data
    const getList = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        let today = new Date()
        let from = moment(today).format("YYYY-MM-DD")
        today.setDate(today.getDate() + 1)
        let to = moment(today).format("YYYY-MM-DD")
        let unitCategory = filterData.unit_category?.length ?
            filterData?.unit_category?.map(({ value }) => value) : []

        const payload = {
            activeStatus: (!filterData?.status || filterData?.status?.length === 0) ?
                [true, false] : filterData?.status,
            company_id: 1,
            unitCategory: unitCategory,
            from: from,
            to: to,
            search: search,
            offset: offset,
            limit: limit
        }

        NetworkCall(
            `${config.api_url}/unit_vacancy/get_unit_vacancy_period`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setList({
                    data: res?.data?.data?.unit,
                    totalRowsCount: res?.data?.data?.count?.[0]?.count
                })
                backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                });
            });
    }

    // eslint-disable-next-line
    const Row = React.useCallback(list?.data?.map((_) => {
        let p
        try {
            p = {
                id: _?.id,
                propertyName: _?.propertyByID?.name ?? "-",
                unitNo: _?.unit_no ?? "-",
                unitName: _?.name ?? "-",
                unitCategory: _?.unit_categoryByID?.name ?? "-",
                unitType: _?.unit_type_masterByID?.name ?? "-",
                unitCurrentStatus: _?.status ?? "-",
                status: _?.is_active ? "Active" : "Inactive",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        }
        return p
        // eslint-disable-next-line
    }), [list])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in property list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getList(0, limit, e)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        if (type === "view") {
            setDialogState({ ...data })
            setDialogOpen(true)
        }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getList(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getList(0, value, searchText)
    }

    const render = () => {
        return <>
            <Subheader goBack={() => history.goBack(-1)} title={t("All Unit Vacancy Period")}
                select options={companyList} value={selectedCompany} onchange={(e) => { handleCompanyChange(e) }} />
            <BodyDiv>
                <Grid container spacing={"8px"}>
                    <Grid item xs={4}>
                        <SearchFilter value={searchText} placeholder={t("Search")}
                            handleChange={(value) => handleSearch(value)} />
                    </Grid>
                    <Grid item xs={8} alignSelf={"center"}>
                        <Stack direction="row" spacing={"16px"} justifyContent={"end"}>
                            <FilterButton onClick={() => setFilterDrawer(!fiterDrawer)}>
                                <Badge variant="dot" color="primary"
                                    invisible={!(filterData.status?.length > 0)}>
                                    <FilterIMG color="#091b29" />
                                </Badge>
                            </FilterButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <TableWithPagination
                            heading={UnitHeading1(t)}
                            rows={Row}
                            path={UnitPath1}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={UnitType1}
                            handlePagination={handleTablePagination}
                            handleChangeLimit={handleTablePageLimit}
                            totalRowsCount={list?.totalRowsCount}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            view={true}
                            edit={true}
                            delete={true} />
                    </Grid>
                </Grid>
                <UnitVacancyList
                    dialogState={dialogState}
                    setDialogState={setDialogState}
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    t={t}
                />
                <FilterGenerator open={fiterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => setFilterData(value)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.unit_category,
                            options: enumValue?.unit_category,
                            isMulti: true,
                            label: t("Unit Category"),
                            state_name: "unit_category",
                            placeholder:t("Unit Category")
                        },
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: ActiveStatus(t),
                            isMulti: true,
                            state_name: "status",
                            label: t("Status"),
                        },
                    ]} />
            </BodyDiv>
        </>
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}
export default withNamespaces("unitVacancyPropertyList")(UnitVacancyAllList);