import { useApolloClient } from "@apollo/client";
import {
    Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import FilterIMG from "../../../../../assets/filter";
import {
    FilterGenerator, SearchFilter, SelectBox, TableWithPagination, TextBox, UseDebounce
} from "../../../../../components";
import { AlertContext, BackdropContext } from "../../../../../contexts";
import { GET_ACCESS_GATE } from "../../../../../graphql/queries";
import { AlertProps, LocalStorageKeys, enumSelect, enum_types } from "../../../../../utils";
import {
    AccessGatesHeading, AccessGatesPath, AccessGatesType, StatusOptionListLang
} from "../../../../../utils/accessGates/accessGatesListUtils";
import { useStyles } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { INSERT_ACCESS_GATE, UPDATE_ACCESS_GATE } from "../../../../../graphql/mutations";


export const AccessGatesList = (props) => {
    const { t } = props

    const defaultAddAccessGateState = {
        formType: "add",
        id: "",
        name: "",
        gateNo: "",
        gateType: "",
        userProfile: "",
        description: "",
        status: true,
        delete: false,
        error: {
            name: "",
            gateNo: "",
        }
    }
    const clientID = localStorage.getItem(LocalStorageKeys.clinetID) ?? undefined
    const companyID = props?.data?.company?.id ?? undefined

    const classes = useStyles()
    const search = useLocation().search
    const propertyID = new URLSearchParams(search).get("propertyID")
    const debounce = UseDebounce()
    const client = useApolloClient()

    // useContext
    const alert = React.useContext(AlertContext)
    const backdrop = React.useContext(BackdropContext)

    // useState
    const [addAccessGateState, setAddAccessGateState] = React.useState({ ...defaultAddAccessGateState })
    // const [loading, setLoading] = React.useState("")
    const [addAccessGateDialogOpen, setAddAccessGateDialogOpen] = React.useState(false)
    const [accessGateList, setAccessGateList] = React.useState({})
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState({ status: [true] })
    const [enumValue, setEnumValue] = React.useState({ accessGateType: [] })
    const [isDisableBtn,setIsDisableBtn]=React.useState(false)
    // useEffect to get Access Gate list using selected company and filter data when loading the screen
    React.useEffect(() => {
        if (props?.permission?.read) {
            setPage(1)
            if (propertyID) { getAccessGate() }
        }
        // eslint-disable-next-line
    }, [propertyID, filterData, props?.permission])

    // Function to get Access Gate list based on the input data
    const getAccessGate = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        client.query({
            query: GET_ACCESS_GATE, fetchPolicy: "network-only",
            variables: {
                clientID: localStorage.getItem(LocalStorageKeys.clinetID) ?? "",
                status: (!filterData?.status || filterData?.status?.length === 0) ?
                    [true, false] : filterData?.status,
                propertyID: propertyID,
                search,
                offset,
                limit,
            }
        }).then((r) => {
            setAccessGateList({
                data: r?.data?.access_gates_master,
                totalRowsCount: r?.data?.count?.[0].count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        })
    }

    // Set row data for table
    // eslint-disable-next-line
    const AccessGateRows = React.useCallback(accessGateList?.data?.map((_, index) => {
        let j
        try {
            j = {
                index: ((page - 1) * limit) + index + 1,
                id: _?.id,
                name: _?.name ?? "",
                gateNo: _?.gate_no ?? "",
                userProfile: _?.user_profileByID ?? "",
                description: _?.description ?? "-",
                status: _?.is_active ? "Active" : "Inactive",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        }
        return j
    }), [accessGateList])

    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.access_gate_type])
        setEnumValue({ accessGateType: result?.access_gate_type })
    }

    // useEffect to load the Enum value while initial load
    React.useEffect(() => {
        getEnum()
    }, [])

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in Access Gate list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getAccessGate(0, limit, e)
    }

    // Function to open add Access Gate form
    const handleAddAccessGate = () => {
        setAddAccessGateState({ ...defaultAddAccessGateState })
        setAddAccessGateDialogOpen(true)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        const tempData = data?.data
        const tempAddAccessGateState = {
            formType: type,
            id: tempData?.id ?? null,
            name: tempData?.name ?? null,
            gateNo: tempData?.gate_no ?? null,
            gateType: tempData?.gate_type ? { value: tempData?.gate_type, label: tempData?.gate_type } : null,
            userProfile: tempData?.user_profileByID ?? null,
            description: tempData?.description ?? null,
            status: type === "active" ? !tempData?.is_active : tempData?.is_active,
            delete: type === "delete" ?? tempData?.is_delete,
            error: defaultAddAccessGateState?.error
        }
        setAddAccessGateState({ ...tempAddAccessGateState })
        if (type === "edit" || type === "view") { setAddAccessGateDialogOpen(true) }
        else if (type === "active" || type === "delete") { handleCreateEdit(tempAddAccessGateState) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getAccessGate(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getAccessGate(0, value, searchText)
    }

    // Function to load Async Select Options
    // const loadOptions = async (search = "", array, type) => {
    //     setLoading(type)
    //     let result, query, offset, limit, variables = 0
    //     limit = 10
    //     if (search && !Boolean(array?.length)) { offset = 0 }
    //     else { offset = array?.length }

    //     switch (type) {
    //         case "access_control":
    //             query = GET_SECURITY_GUARD_BY_PROPERTY_ID
    //             variables = { clientID, propertyID, offset, limit, search }
    //             result = await networkCallBack(query, variables)
    //             return {
    //                 options: [...result?.access_control?.map((_) => _?.user_profilesByID)],
    //                 hasMore: (array?.length + result?.access_control?.length) < result?.count[0]?.count
    //             }
    //         default:
    //             return { options: [] }
    //     }
    // }

    // Function for network callback for Async Select Options
    // const networkCallBack = async (query, variables = {}) => {
    //     const options = await client.query({
    //         query, fetchPolicy: "network-only", variables
    //     }).then((r) => {
    //         let main = r?.data
    //         setLoading(null)
    //         return main
    //     }).catch((e) => {
    //         setLoading(null)
    //         alert.setSnack({
    //             ...alert, open: true,
    //             severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
    //         })
    //         return null
    //     })
    //     return options
    // }

    // Function to update addAccessGateState
    const updateAddAccessGateDialogState = (k, v) => {
        let error = addAccessGateState?.error
        error[k] = ""
        setAddAccessGateState({ ...addAccessGateState, [k]: v, error })
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true
        let error = addAccessGateState.error
        if (addAccessGateState?.name?.length === 0) { isValid = false; error.name = t("Gate Name is Required") }
        if (addAccessGateState?.gateNo?.length === 0) { isValid = false; error.gateNo = t("Gate Number is Required") }

        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            })
        }
        setAddAccessGateState({ ...addAccessGateState, error })
        return isValid
    }

    // Function to create a Access Gate
    const handleCreateEdit = async (data) => {
        if ((data?.formType === "active" || data?.formType === "delete") ? true : validate()) {
            const currentDate = new Date().toISOString()
            const profileID = localStorage.getItem(LocalStorageKeys.profileID)
            setIsDisableBtn(true)

            const payload = {
                company_id: companyID,
                description: data?.description ?? undefined,
                gate_no: data?.gateNo ?? undefined,
                gate_type: data?.gateType?.value ?? undefined,
                name: data?.name ?? undefined,
                property_id: propertyID,
                // user_profile_id: data?.userProfile?.value ?? undefined,
                is_active: data?.status ?? undefined,
                is_delete: data?.delete ?? undefined,
                updated_at: currentDate ?? undefined,
                updated_by: profileID ?? undefined
            }

            if (data?.formType === "add") {
                payload.client = clientID
                payload.created_at = currentDate ?? undefined
                payload.created_by = profileID ?? undefined
            }

            client.mutate({
                mutation: data?.id ? UPDATE_ACCESS_GATE : INSERT_ACCESS_GATE,
                fetchPolicy: 'network-only',
                variables: {
                    accessGatesMasterID: data?.id ?? undefined,
                    payload: data?.id ? payload : [payload]
                }
            }).then((r) => {
                setAddAccessGateState({ ...defaultAddAccessGateState })
                getAccessGate()
                setAddAccessGateDialogOpen(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: `Access Gate ${data?.id ?  data?.delete ? `Deleted` : `Updated` : `Created`} Successfully.!!!`,
                })
                setIsDisableBtn(false)
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
                })
                setIsDisableBtn(false)
            })

        } else { return false }
    }

    return <div className={classes.root} >
        <Grid container className={classes.content} spacing={1}>
            <Grid item xs={4}>
                <SearchFilter value={searchText} placeholder={t("Search Gates")}
                    handleChange={(value) => handleSearch(value)} />
            </Grid>
            <Grid item xs={8}>
                <Box display={"flex"} justifyContent={"end"}>
                    <Stack direction="row" spacing={2}
                        divider={<Divider orientation="vertical" flexItem />}>
                        <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                            className={classes.filterButton}>
                            <Badge variant="dot" color="primary"
                                invisible={!(filterData.status?.length > 0)}>
                                <FilterIMG color="#091b29" />
                            </Badge>
                        </IconButton>
                        {props?.permission?.create &&
                            <Button variant="contained" className={classes.button}
                                onClick={handleAddAccessGate}>
                                {t("Add Access Gate")}
                            </Button>}
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <TableWithPagination
                    heading={AccessGatesHeading(t)}
                    rows={AccessGateRows}
                    path={AccessGatesPath}
                    showpagination={true}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    handleIcon={handleTableIcon}
                    onClick={() => console.log("")}
                    tableType="no-side"
                    dataType={AccessGatesType}
                    handlePagination={handleTablePagination}
                    handleChangeLimit={handleTablePageLimit}
                    totalRowsCount={accessGateList?.count}
                    page={page}
                    limit={limit}
                    height={`calc(100vh - 409px)`}
                    view={props?.permission?.read}
                    edit={props?.permission?.update}
                    delete={props?.permission?.delete} />
            </Grid>
        </Grid>
        <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
            onApply={(value) => setFilterData(value)}
            components={[
                {
                    component: "toggleButton",
                    value: filterData?.status,
                    options: StatusOptionListLang(t)?.map((x)=>{
                        return{
                            ...x,
                            label:x?.label_lng
                        }
                    }),
                    isMulti: true,
                    state_name: "status",
                    label: t("Status"),
                },
            ]} />
        <Dialog
            className={classes.addAccessGateDialog}
            open={addAccessGateDialogOpen}
            onClose={() => setAddAccessGateDialogOpen(false)}>
            <div className={classes.addAccessGateDialogHeader}>
                <Typography className={classes.addAccessGateDialogHeaderTitle}>
                    {addAccessGateState?.formType === "add" ? t("Add Access Gate") :
                        addAccessGateState?.formType === "edit" ? t("Edit Access Gate") :
                            addAccessGateState?.formType === "view" ? t("View Access Gate") : t("Add Access Gate")}
                </Typography>
                <IconButton onClick={() => setAddAccessGateDialogOpen(false)}
                    className={classes.addAccessGateDialogCloseButton}>
                    <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                </IconButton>
            </div>
            <div className={classes.addAccessGateDialogBody}>
                <TextBox
                    isrequired
                    isReadonly={addAccessGateState?.formType === "view"}
                    label={t("Gate Name")}
                    placeholder={t("Enter Name")}
                    value={addAccessGateState?.name ?? ""}
                    onChange={(e) => updateAddAccessGateDialogState("name", e.target.value)}
                    isError={addAccessGateState?.error?.name?.length > 0}
                    errorMessage={addAccessGateState?.error?.name} />
                <Box height={16} />
                <TextBox
                    isrequired
                    isReadonly={addAccessGateState?.formType === "view"}
                    label={t("Gate Number")}
                    placeholder={t("Enter Number")}
                    value={addAccessGateState?.gateNo ?? ""}
                    onChange={(e) => updateAddAccessGateDialogState("gateNo", e.target.value)}
                    isError={addAccessGateState?.error?.gateNo?.length > 0}
                    errorMessage={addAccessGateState?.error?.gateNo} />
                <Box height={16} />
                <SelectBox
                    isReadOnly={
                        (addAccessGateState?.formType === "add" ||
                            addAccessGateState?.formType === "edit") ?
                            false : true}
                    label={t("Access Gate Type")}
                    placeholder={t("Select Type")}
                    options={enumValue?.accessGateType}
                    value={addAccessGateState?.gateType}
                    onChange={(value) => updateAddAccessGateDialogState("gateType", value)} />
                <Box height={16} />
                {/* <SelectBox
                    isReadOnly={
                        (addAccessGateState?.formType === "add" ||
                            addAccessGateState?.formType === "edit") ?
                            false : true}
                    label="Security Guard"
                    placeholder="Select Guard"
                    value={addAccessGateState?.userProfile}
                    onChange={(value) => updateAddAccessGateDialogState("userProfile", value)}
                    loading={loading === "access_control"}
                    isPaginate
                    debounceTimeout={800}
                    loadOptions={(search, array) => loadOptions(search, array, 'access_control')} />
                <Box height={16} /> */}
                <TextBox
                    isReadonly={addAccessGateState?.formType === "view"}
                    label={t("Notes")}
                    placeholder={t("Enter Notes")}
                    multiline
                    value={addAccessGateState?.description ?? ""}
                    onChange={(e) => updateAddAccessGateDialogState("description", e.target.value)} />
                <Box height={16} pb={7} />
                <Typography className={classes.addAccessGateDialogFieldLabel} noWrap>{t("Status")}</Typography>
                <div className={classes.addAccessGateDialogButtonContainer}>
                    {StatusOptionListLang(t).map((_) => {
                        return <Button className={_?.value === addAccessGateState?.status ?
                            classes.addAccessGateDialogButtonSelected :
                            classes.addAccessGateDialogButtonUnSelected}
                            onClick={() => (addAccessGateState?.formType === "add" ||
                                addAccessGateState?.formType === "edit") ?
                                updateAddAccessGateDialogState("status", _?.value) : false}>
                            {_?.label_lng}
                        </Button>
                    })}
                </div>
            </div>
            {(addAccessGateState?.formType === "add" || addAccessGateState?.formType === "edit") &&
                <div className={classes.addAccessGateDialogFooter}>
                    {addAccessGateState?.formType === "edit" && <><Button fullWidth
                        className={classes.addAccessGateDialogFooterCloseButton}
                        onClick={() => setAddAccessGateDialogOpen(false)}>
                        {t("Cancel")}
                    </Button></>}
                    <Button variant="contained" fullWidth
                        className={classes.addAccessGateDialogFooterButton}
                        onClick={() => handleCreateEdit(addAccessGateState)}
                        disabled={isDisableBtn}>
                        {addAccessGateState?.formType === "add" ? t("Create") : t("Save")}
                    </Button>
                </div>}
        </Dialog>
    </div>
}
