import {
    Avatar,
    Box,
    Button,
    Dialog,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import React, { useContext, useState } from 'react';
import {
    AlertProps,
    LocalStorageKeys,
    NetWorkCallMethods,
    ValidateEmail,
    colorObject,
    concat_string,
    convertTimeUtcToZoneCalander,
    enumSelect,
    enum_types,
    useWindowDimensions
} from "../../../utils";
import { useStyles } from '../../maintenancerequest/style';
// import { AssetView } from "../../propertyFinder3/component/assetView";
import { Close, KeyboardArrowRight } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { CloseIconWithBG } from "../../../assets";
import { ApproveRequestIcon } from '../../../assets/approveRequest';
import { CancelRequestIcon } from '../../../assets/cancelRequest';
import { NonAssetIcon } from "../../../assets/nonAssetIcon";
import { AlertDialog, DialogBox, DialogDrawer, FormGenerator, SelectBox, Subheader, TextBox, UploadComponent } from "../../../components";
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import { NetworkCall } from '../../../networkcall';
import { Slider as ImageViewer } from "../components/assets";
import SliderIMG from "react-slick";
import { CustomTypography, CustomTypography2, CustomTypography3, CustomTypography4, CustomTypography5, useStyles as useStyles1 } from './style';
import { CustomDropdown } from "../components/customDropdown/customDropdown";
import { BackendRoutes } from "../../../router/routes";
import { WorkerImagePlaceholder } from "../assets/workerImagePlaceholder";
import { PaidIcon } from "../assets/paidIcon";
import { DownloadIcon } from "../assets/downloadIcon";
import { EditIcon } from "../assets/editIcon";
import { CustomTypography1, WorkerTypeOptions, initial as edit_initial } from "../../maintenancerequest/common";
import { loadOptions } from "../../../utils/asyncPaginateLoadOptions";
import { SELECT_VENDOR } from "../../../graphql/queries";
import { GET_DOMESTIC_HELPER_MASTER, GET_PROFESSION_MASTER } from "../../maintenancerequest/utils";
import { CloseIcon } from "../assets/closeIcon";
import { ChargeIcon } from "../assets/chargeIcon";
import { PaidStatus } from "./utils";
import { FileViewer } from "react-file-viewer";
export const RequestView = ({ t = () => false, datas = {}, value = "", notesUpdate = () => false, dialogClose = () => false, load = false, noteChange = () => false, notes = "", type, request = false, handleTabs = () => false, setValue = () => false, reload = () => false, thirdPartyAssets = [], handleNotes = () => false, is_edit_work = false, setDatas = () => false }) => {
    // classes

    const size = useWindowDimensions();
    const seeMoreDivRef = React.useRef(null);
    const [seeMoreHeight, setSeeMoreHeight] = React.useState(null)
    const [isSeeMore, setIsSeeMore] = React.useState(true)
    const classes = useStyles({ isSeeMore });
    const classes1 = useStyles1({ type, is_worker_paid: datas?.data?.table?.[0]?.is_amount_paid })
    const selected_company = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
    const language = localStorage.getItem("i18nextLng");
    const { state } = useLocation();
    const [edit_state, set_edit_state] = useState({ ...edit_initial({ t }) });
    const [bool, setBool] = useState({
        isAcknowledge: false,
        showChargeable: false,
        firstResponse: null,
        showVisitor: true,
        showCancelMsg: false,
        showCosting: false,
        applyVisitorCharging: false,
        successModal: false,
        showSuccessMsg: false,
    })
    // state
    const alert = useContext(AlertContext)
    const [details, setDetails] = useState({
        amount: "",
        notes: ""
    })
    const [SelectedChargingMethod, setSelectedChargingMethod] = useState({ value: "Non Chargeable", label: "Non Chargeable" })
    const [options, set_options] = useState({
        request_type: [],
        work_order_status: [],
        payment_mode: [],
    })
    const [is_button_disable, set_is_button_disable] = React.useState(false)
    const [isViewImages, setIsViewImages] = React.useState({
        bool: false,
        imageViewer: false,
        data: []
    })
    const [showMore, setShowMore] = React.useState(false)
    const [is_view_more_open, set_is_view_more_open] = React.useState(false)
    const [is_edit_open, set_is_edit_open] = React.useState(false)
    const Imgsettings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
        // autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
    }
    const [assets, setAssets] = React.useState([])
    const [disableMail, setDisableMail] = React.useState(false)
    const [selectedImage, setSelectedImage] = React.useState({
        bool: false,
        url: ""
    })
    React.useEffect(() => {
        setSeeMoreHeight(seeMoreDivRef?.current?.clientHeight ?? 0)
    }, [seeMoreDivRef?.current?.clientHeight]);
    React.useEffect(() => {
        getOptions()
        if (datas?.is_edit_work) {
            handleEditView("edit")
            setDatas({ ...datas, is_edit_work: false })
        }

        if (datas?.data?.table?.[0]?.build_source === "Inspector" && datas?.data?.table?.[0]?.type === null && datas?.data?.table?.[0]?.is_acknowleged) {
            updateRequest({ charging_method: "Non Chargeable" }, true)
        }
        else if (datas?.data?.table?.[0]?.build_source === "Inspector" && datas?.data?.table?.[0]?.type === null && !datas?.data?.table?.[0]?.is_acknowleged) {
            updateRequest({ is_acknowleged: true })
        }
        //eslint-disable-next-line
    }, [datas])


    const getOptions = async () => {
        const result = await enumSelect([
            enum_types?.request_type,
            enum_types?.work_order_status,
            enum_types.payment_mode,
        ]);
        set_options({
            request_type: result?.request_type,
            work_order_status: result?.work_order_status,
            payment_mode: result?.payment_mode,
        })
    };

    // close image viwer
    const closeImageViewer = () => {
        setIsViewImages({ ...isViewImages, imageViewer: false, data: [] })
    };

    // sumbit notes
    const sumbit = () => {
        notesUpdate()
    }
    const handleChargingMethod = (val) => {
        setSelectedChargingMethod(val)
    }
    const handleChange = (val) => {
        if (val) {
            setBool({ ...bool, isAcknowledge: true })
            setTimeout(() => {
                setBool({ ...bool, isAcknowledge: false, showChargeable: true })
            }, 2000);
            updateRequest({ is_acknowleged: val })
        }

    }
    const updateRequest = (data, firstResponse) => {
        const payload = {
            "request_id": state?.main?.value,
            "type": state?.main?.request,
            "is_acknowleged": data?.is_acknowleged,
            "is_frozen": data?.is_frozen,
            "request_type": data?.charging_method,
            "costing_status": data?.costing_status,
            "initial_amount": data?.initial_amount,
            "initial_notes": data?.initial_notes,
            "currency_id": data?.currency_id
        }

        NetworkCall(
            `${config.api_url}/request_costing/update_request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            if (firstResponse) {
                setBool({ ...bool, firstResponse: true })
                setTimeout(() => {
                    setBool({ ...bool, firstResponse: false })
                }, 1000);
                if (state?.main?.request === "Maintenance") {
                    handleTabs(5)
                }

            }
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Request Updated Successfully",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
            reload()
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    const handleFirstResponse = (val) => {
        if (val) {
            updateRequest({ charging_method: SelectedChargingMethod?.value }, true)
        }
    }
    const cancelVistingCharge = () => {
        setBool({ ...bool, showVisitor: false, showCancelMsg: true })
        setTimeout(() => {
            setBool({ ...bool, showCancelMsg: false, showVisitor: false })
            // setBool({ ...bool, showCancelMsg: false, showCosting: true, showVisitor: false })

        }, 1000);
    }
    const closeModal = () => {
        setBool({ ...bool, applyVisitorCharging: false })
    }
    const sendRequest = () => {
        const payload = {
            "request_id": state?.main?.value,
            "type": state?.main?.request,
            "request_type": SelectedChargingMethod?.value,
            "initial_amount": details?.amount,
            "initial_notes": details?.notes,
            "currency_id": state?.main?.companyData?.currency_id
        }

        NetworkCall(
            `${config.api_url}/request_costing/update_request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setBool({ ...bool, applyVisitorCharging: false, showVisitor: false, successModal: true, showSuccessMsg: true, })
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Request Updated Successfully",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
            reload()

        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })

    }
    const handleChargeData = (k, v) => {
        setDetails({ ...details, [k]: v })
    }
    const closeSuccessModal = () => {
        setBool({ ...bool, successModal: false, showSuccessMsg: true, showVisitor: false })
        setTimeout(() => {
            setBool({ ...bool, successModal: false, showSuccessMsg: false, showVisitor: false, showCosting: true })
        }, 1000);
        if (state?.main?.request === "Maintenance") {
            handleTabs(5)
        }
    }
    const gotoCosting = () => {
        setBool({ ...bool, successModal: false, showSuccessMsg: true, showVisitor: false })
        setTimeout(() => {
            setBool({ ...bool, successModal: false, showSuccessMsg: false, showVisitor: false, showCosting: true })
        }, 1000);
        if (state?.main?.request === "Maintenance") {
            handleTabs(5)
        }
    }
    const openCostingDetails = () => {
        if (state?.main?.request === "Maintenance") {
            handleTabs(5)
        }
        setValue({ ...value, tabValue: 5 })
    }
    const handleImageViewer = () => {
        setIsViewImages({ ...isViewImages, imageViewer: true, data: JSON.parse(datas?.data?.table?.[0]?.inspection_mapping?.[0]?.assets) })
        setShowMore(false)
    }

    const handleWorkStatus = (status) => {
        if (datas?.data?.table?.[0]?.work_order_status !== status?.value) {
            const payload = {
                id: state?.main?.value,
                work_order_status: status?.value,
            }

            NetworkCall(
                `${config.api_url}${BackendRoutes?.request_outsource_work_update}`,
                NetWorkCallMethods.post, payload, null, true, false,
            ).then(() => {
                alert.setSnack({
                    ...alert, open: true,
                    msg: t("Work Status Updated Successfully"),
                    severity: AlertProps.severity.success,
                });
                reload();
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true, msg: t("Something Went Wrong"),
                    severity: AlertProps.severity.error,
                });
            })
        }
    }

    const showWorkerDetails = () => {
        let show = (datas?.data?.table?.[0]?.registered_worker?.label
            || datas?.data?.table?.[0]?.worker_contact_name
            || datas?.data?.table?.[0]?.registered_worker?.profession?.label
            || datas?.data?.table?.[0]?.profession?.label
            || datas?.data?.table?.[0]?.registered_worker?.mobile_no
            || datas?.data?.table?.[0]?.worker_contact_no
            || datas?.data?.table?.[0]?.registered_worker?.email_id
            || datas?.data?.table?.[0]?.worker_email_id
            || datas?.data?.table?.[0]?.registered_worker?.company_name
            || datas?.data?.table?.[0]?.worker_company_name);

        return show;
    }

    const handleEditView = (type) => {
        set_edit_state({
            workNo: datas?.data?.table?.[0]?.work_order_ref_no ?? "",
            workdate: datas?.data?.table?.[0]?.work_order_date ? new Date(datas?.data?.table?.[0]?.work_order_date) : null,
            workerStatus: datas?.data?.table?.[0]?.work_order_status ? {
                value: datas?.data?.table?.[0]?.work_order_status,
                label: datas?.data?.table?.[0]?.work_order_status
            } : "",
            vendor: datas?.data?.table?.[0]?.vendor_ref?.value ? datas?.data?.table?.[0]?.vendor_ref : "",
            vendor_email_id: datas?.data?.table?.[0]?.vendor_email_id ?? "",
            worker_type: datas?.data?.table?.[0]?.registered_worker?.value
                ? WorkerTypeOptions({ t })?.[0]?.value
                : WorkerTypeOptions({ t })?.[1]?.value,
            reg_worker: datas?.data?.table?.[0]?.registered_worker?.value ? datas?.data?.table?.[0]?.registered_worker : "",
            worker_contact_name: datas?.data?.table?.[0]?.worker_contact_name ?? "",
            profession: datas?.data?.table?.[0]?.profession?.value ? datas?.data?.table?.[0]?.profession : "",
            worker_contact: {
                mobile: datas?.data?.table?.[0]?.worker_contact_no ?? "",
                mobile_code: datas?.data?.table?.[0]?.worker_contact_country_code ?? "+91",
            },
            alternative_company: datas?.data?.table?.[0]?.alternative_company ?? "",
            worker_company_location: datas?.data?.table?.[0]?.worker_company_location ?? "",
            service_charges: datas?.data?.table?.[0]?.service_charges?.toString() ?? "",
            material_charges: datas?.data?.table?.[0]?.material_charges?.toString() ?? "",
            total_amount: datas?.data?.table?.[0]?.total_amount?.toString() ?? "",
            total_paid: datas?.data?.table?.[0]?.total_paid?.toString() ?? "",
            is_amount_paid: datas?.data?.table?.[0]?.is_amount_paid,
            payment_mode: datas?.data?.table?.[0]?.payment_mode
                ? {
                    value: datas?.data?.table?.[0]?.payment_mode,
                    label: datas?.data?.table?.[0]?.payment_mode
                }
                : "",
            paid_to: datas?.data?.table?.[0]?.paid_to ?? "",
            payment_date: datas?.data?.table?.[0]?.payment_date ? new Date(datas?.data?.table?.[0]?.payment_date) : null,
            invoice_no: datas?.data?.table?.[0]?.invoice_no ?? "",
            invoice_date: datas?.data?.table?.[0]?.invoice_date ? new Date(datas?.data?.table?.[0]?.invoice_date) : null,
            work_completion: datas?.data?.table?.[0]?.work_completion ?? "",
            assigned_date: datas?.data?.table?.[0]?.assigned_date ? new Date(datas?.data?.table?.[0]?.assigned_date) : null,
            error: {
                workNo: "",
                workdate: "",
                workerStatus: "",
                vendor: "",
                vendor_email_id: "",
                reg_worker: "",
                worker_contact_name: "",
                profession: "",
                alternative_company: "",
                worker_company_location: "",
                service_charges: "",
                material_charges: "",
                total_paid: "",
                payment_mode: "",
                paid_to: "",
                payment_date: "",
                invoice_no: "",
                invoice_date: "",
                work_completion: "",
                assigned_date: "",
            },
        })
        setAssets(datas?.data?.table?.[0]?.third_party_attachment)
        switch (type) {
            case "view":
                set_is_view_more_open(true);
                break;
            case "edit":
                set_is_edit_open(true);
                break;

            default:
                break;
        }
    }
    //   updateState
    const updateState = (key, value) => {
        let error = edit_state?.error;
        error[key] = "";
        if (key === "worker_type") {
            set_edit_state({
                ...edit_state,
                [key]: value,
                reg_worker: "",
                worker_contact_name: "",
                profession: "",
                worker_contact: { mobile: "", },
                alternative_company: "",
                worker_company_location: "",
                error
            });
        }
        else {
            set_edit_state({ ...edit_state, [key]: value, error });
        }
    };

    const manualDomesticHelperMasterOption = (array) => {
        const details = array?.map(i => i)
        return details;
    };

    const handleEditClose = (type) => {
        switch (type) {
            case "view":
                set_is_view_more_open(false);
                break;
            case "edit":
                set_is_edit_open(false);
                break;

            default:
                break;
        }
    }

    const handleEditUpdate = () => {
        set_is_button_disable(true)
        const constructedAssets = assets?.map((e) => {
            return {
                url: e?.url,
                assets_master_id: e?.asset_type,
                is_active: e?.is_active,
                file_meta: e?.file_meta,
                id: e?.id
            }
        })
        if (ValidateEmail(edit_state?.vendor_email_id)) {
            const payload = {
                id: datas?.data?.table?.[0]?.id,
                work_order_ref_no: edit_state?.workNo ? edit_state?.workNo : null,
                work_order_date: edit_state?.workdate ? edit_state?.workdate : null,
                work_order_status: edit_state?.workerStatus ? edit_state?.workerStatus?.value : null,
                vendor_ref: edit_state?.vendor ? edit_state?.vendor?.value : null,
                vendor_email_id: edit_state?.vendor_email_id,
                reg_worker_id: edit_state?.reg_worker?.value,
                worker_contact_name: edit_state?.reg_worker?.label ?? edit_state?.worker_contact_name,
                profession_id: edit_state?.reg_worker?.profession?.value ?? edit_state?.profession?.value,
                worker_contact_no: edit_state?.reg_worker?.mobile_no ?? (edit_state?.worker_contact?.mobile?.length > 0 ? edit_state?.worker_contact?.mobile : undefined),
                worker_contact_country_code: edit_state?.reg_worker?.mobile_no ?? (edit_state?.worker_contact?.mobile_code?.length > 0 ? edit_state?.worker_contact?.mobile_code : undefined),
                alternative_company: edit_state?.alternative_company,
                location: edit_state?.worker_company_location,
                service_charges: edit_state?.service_charges ? parseInt(edit_state?.service_charges) : 0,
                material_charges: edit_state?.material_charges ? parseInt(edit_state?.material_charges) : 0,
                total_amount: (edit_state?.service_charges ? parseInt(edit_state?.service_charges) : 0) + (edit_state?.material_charges ? parseInt(edit_state?.material_charges) : 0),
                total_paid: (edit_state?.total_paid ? parseInt(edit_state?.total_paid) : 0),
                currency_id: selected_company?.currency_id,
                payment_mode: edit_state?.payment_mode?.value,
                paid_to: edit_state?.paid_to,
                payment_date: edit_state?.payment_date ?? null,
                invoice_no: edit_state?.invoice_no,
                invoice_date: edit_state?.invoice_date ?? null,
                work_completion: edit_state?.work_completion,
                assigned_date: edit_state?.assigned_date ?? null,
                assets: constructedAssets ?? []
            };
            NetworkCall(
                `${config.api_url}${BackendRoutes?.request_outsource_work_update}`,
                NetWorkCallMethods.post, payload, null, true, false,
            ).then(() => {
                reload();
                alert.setSnack({
                    ...alert, open: true, severity: AlertProps.severity.success,
                    msg: "Outsourced Work Order Information Updated Successfully",
                });
                set_is_button_disable(false);
                set_is_edit_open(false);
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
                set_is_button_disable(false)
            });
        }
        else {
            set_is_button_disable(false)
            alert.setSnack({
                ...alert, open: true, msg: t("Please Enter Valid email Id"),
                severity: AlertProps.severity.error,
            });
        }
    }

    const handleDownload = () => {
        alert.setSnack({
            ...alert, open: true,
            severity: AlertProps.severity.info,
            msg: t("Downloading PDF..."),
        })
        const payLoad = {
            request_id: datas?.data?.table?.[0]?.id,
            type: "Maintenance",
        };

        NetworkCall(
            `${config.api_url}${BackendRoutes.request_template}`,
            NetWorkCallMethods.post, payLoad, null, true, false,
        ).then((response) => {
            const linkSource = `data:application/pdf;base64,${response?.data?.data}`;
            var link = document.createElement("a");
            link.href = linkSource;
            var fileName = `Maintenance_${datas?.data?.table?.[0]?.request_no}.pdf`;
            link.download = fileName;
            link.click();
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
            })
        }).finally(() => {
            alert.setSnack({
                ...alert, open: false, msg: "",
                severity: AlertProps.severity.info,
            })
        })
    }

    const handleSendMail = () => {
        setDisableMail(true)
        const payload = {
            request_id: datas?.data?.table?.[0]?.id
        }
        NetworkCall(
            `${config.api_url}/request/send_outworker_mail`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false,
        ).then((response) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: t("Email sent successfully"),
            })
            setDisableMail(false)
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
            })
        })
    }
    const statusStyle = {
        "Pending": classes.pendingStatus,
        "Approved": classes.approvedStatus,
        "Rejected": classes.rejectedStatus,
    }
    return (
        <div>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    md={8.5}
                    sm={12}
                    lg={8.5}
                // className={classes.root}
                >
                    <Box className={classes1.boxHeight}>
                        {/* <Subheader
                            title={datas?.data?.table?.[0]?.subject ?? "-"}
                            hideBackButton
                        /> */}
                        <Stack direction="row" spacing={2} p={2}>
                            {datas?.data?.table?.[0]?.images?.length > 0 &&
                                <Box position={"relative"} onMouseOver={() => setIsViewImages({ ...isViewImages, bool: true, data: datas?.data?.table?.[0]?.images })}
                                    onMouseLeave={() => setIsViewImages({ ...isViewImages, bool: false })}>
                                    <img src={datas?.data?.table?.[0]?.images?.[0]?.url} alt="request_image" className={classes1.requestImage} />
                                    {isViewImages?.bool &&
                                        <Box className={classes1.viewimg} onClick={() => setIsViewImages({ bool: false, imageViewer: true, data: datas?.data?.table?.[0]?.images })}>
                                            <Typography className={classes1.viewTxt}>{t("View")}</Typography>
                                        </Box>
                                    }
                                </Box>
                            }
                            <Box>
                                <Typography className={classes1.requesTitle} noWrap>{datas?.data?.table?.[0]?.subject}</Typography>
                                <Box className={classes.catagory}>
                                    <Box className={classes1.service}>
                                        <Typography className={classes.servicetittle} noWrap>
                                            {datas?.data?.table?.[0]?.catagory?.type}
                                        </Typography>
                                    </Box>
                                    <Box
                                        className={classes1.service}
                                        style={{ marginInlineStart: "10px" }}
                                    >
                                        <Typography className={classes.servicetittle} noWrap>
                                            {datas?.data?.table?.[0]?.subcatagory?.type}
                                        </Typography>
                                    </Box>
                                    {datas?.data?.table?.[0]?.planned_close_date !== null &&
                                        <Box
                                            className={classes1.service}
                                            style={{ marginInlineStart: "10px" }}
                                        >
                                            <Stack direction="row" spacing={0.2} alignItems="center">
                                                <Typography className={classes.servicetittle} noWrap>
                                                    {t("Planned Date")}:
                                                </Typography>
                                                <Typography className={classes.serviceBoldtittle} noWrap>
                                                    &#x202a;{moment(datas?.data?.table?.[0]?.planned_close_date).format("DD MMM YYYY")}&#x202c;
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    }
                                    {datas?.data?.table?.[0]?.priority !== null &&
                                        <Box
                                            className={classes1.priorityBox}
                                            style={{ marginInlineStart: "10px", backgroundColor: colorObject[datas?.data?.table?.[0]?.priority] }}
                                        >
                                            <Typography className={classes1.prioritytitle} noWrap>
                                                {datas?.data?.table?.[0]?.priority}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                                <Box mt={1} maxHeight={"100px"} overflow={"scroll"}>
                                    {datas?.data?.table?.[0]?.description?.split('\n').map((item, i) => <Typography className={classes1.description} key={i}>{item}</Typography>)}
                                </Box>
                            </Box>
                        </Stack>
                        <Box>
                            <Divider />
                        </Box>
                        <Box p={2}>
                            <Grid container spacing={2} alignItems={"center"}>
                                {datas?.data?.table?.[0]?.name?.length > 0 &&
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <Box>
                                            <CustomTypography>
                                                {t("Contact_Details")}
                                            </CustomTypography>
                                            <Box className={classes1.contactbox}>
                                                <Stack direction="row" spacing={2} alignItems="center">
                                                    <Avatar src="images/phonecall.svg" variant="square" />
                                                    <Box>
                                                        <Typography className={classes1?.contactName}>{datas?.data?.table?.[0]?.name}</Typography>
                                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                                            <Box>
                                                                {" "}
                                                                <Typography className={classes1.leadcontactNo} noWrap>
                                                                    {/* <Box className={classes.dot} /> */}
                                                                    {datas?.data?.table?.[0]?.mobile_country_code ?? ""}{" "}
                                                                    {datas?.data?.table?.[0]?.mobile ?? ""}
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                {" "}
                                                                {datas?.data?.table?.[0]?.alternative !== null &&
                                                                    <Typography className={classes1.leadcontact}>
                                                                        <Box className={classes1.dot} />
                                                                        <Typography className={classes1.leadcontact} noWrap>
                                                                            {" "}
                                                                            {datas?.data?.table?.[0]?.alternative ?? ""}
                                                                        </Typography>
                                                                    </Typography>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={6} md={8} lg={8}>
                                    <CustomTypography>
                                        {t("Request Against")}
                                    </CustomTypography>
                                    {datas?.data?.table?.[0]?.inspection_mapping?.length > 0 ?
                                        <Box className={classes1.contactbox} display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Stack direction="row" alignItems="center">
                                                <Avatar className={classes1.avatar} src={datas?.data?.table?.[0]?.inspection_mapping?.[0]?.assets?.length > 0 ? JSON.parse(datas?.data?.table?.[0]?.inspection_mapping?.[0]?.assets)?.[0]?.url : "/images/Group 98362.svg"} />
                                                <Box>
                                                    <Typography className={classes1?.contactName}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.name}</Typography>
                                                    <Typography className={classes1.leadcontactNo}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.item?.manufacturer?.name}</Typography>
                                                </Box>
                                            </Stack>
                                            <Box onClick={() => setShowMore(true)}>
                                                <Typography className={classes1.viewMoreTxt}>{t("View More")}</Typography>
                                            </Box>
                                        </Box>
                                        :
                                        <Box className={classes1.contactbox} display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Stack direction="row" alignItems="center">
                                                <Box style={{ marginInlineEnd: "8px" }}><NonAssetIcon /></Box>
                                                <Box>
                                                    <Typography className={classes1?.contactName}>{datas?.data?.table?.[0]?.unit?.unit?.name}</Typography>
                                                    <Stack direction="row" spacing={1} alignItems={"center"} divider={<Divider orientation="vertical" flexItem />}>
                                                        <Typography className={classes1.leadcontactNo}>{datas?.data?.table?.[0]?.unit?.unit?.unit_no}</Typography>
                                                        {datas?.data?.table?.[0]?.location !== null &&
                                                            <Typography className={classes1.leadcontactNo}>{datas?.data?.table?.[0]?.location?.name}</Typography>
                                                        }
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                            <Box mt={2} className={classes1.detailsReqBox}>
                                <Box p={1.5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={2.5} lg={2.5}>
                                            {/* PROBLEM SINCE */}
                                            {datas?.data?.table?.[0]?.problemsince?.length > 0 && (
                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <Box>
                                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                                            <Box className={classes.waterLeakageIcons}>
                                                                <img
                                                                    src="images/icons8-calendar (5).svg"
                                                                    alt="no_img"
                                                                ></img>
                                                            </Box>
                                                            <Typography className={classes.quotationsummaryans}>
                                                                {t("PROBLEM_SINCE")}
                                                            </Typography>

                                                        </Box>
                                                        <Box className={classes.quotationsummaryquest}>
                                                            &#x202a;{datas?.data?.table?.[0]?.problemsince ? convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.problemsince) : "-"}&#x202c;
                                                        </Box>
                                                    </Box>
                                                    <Divider orientation="vertical" className={classes1.verticalDivider} />

                                                </Box>
                                            )}
                                        </Grid>
                                        {/* RAISED ON */}

                                        {datas?.data?.table?.[0]?.raisedon?.length > 0 && (
                                            <Grid item xs={6} sm={6} md={2.5} lg={2.5}>
                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <Box>
                                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                                            <Box className={classes.waterLeakageIcons}>
                                                                <img
                                                                    src="images/icons8-calendar (5).svg"
                                                                    alt="no_img"
                                                                ></img>
                                                            </Box>
                                                            <Typography className={classes.quotationsummaryans}>
                                                                {t("RAISED_ON")}
                                                            </Typography>

                                                        </Box>
                                                        <Box className={classes.quotationsummaryquest}>
                                                            &#x202a;{datas?.data?.table?.[0]?.raisedon ? convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.raisedon)
                                                                : "-"}&#x202c;
                                                        </Box>
                                                    </Box>
                                                    <Divider orientation="vertical" className={classes1.verticalDivider} />
                                                </Box>
                                            </Grid>
                                        )}
                                        {/* prefered  Visit  date and time*/}


                                        <Grid item xs={6} sm={6} md={3} lg={3}>
                                            {/* Property and unit details */}
                                            <Box display={"flex"} justifyContent={"space-between"}>
                                                <Box>
                                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                                        <Box className={classes.waterLeakageIcons}>
                                                            <img src="images/Group 619.svg" alt="no_img"></img>
                                                        </Box>
                                                        <Typography className={classes.quotationsummaryans} noWrap>
                                                            {t("PROPERTY DETAILS")}
                                                        </Typography>

                                                    </Box>
                                                    <Box>
                                                        <Stack direction="row" alignItems="center">
                                                            <Typography className={classes.quotationsummaryquest} noWrap>
                                                                {datas?.data?.table?.[0]?.unit?.property?.name}
                                                            </Typography>
                                                            {/* <Box className={classes1.dotService}></Box>
                                                    <Typography className={classes.quotationsummarySubquest} noWrap>
                                                        {datas?.data?.table?.[0]?.unit?.unit?.name}
                                                    </Typography> */}
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                                <Divider orientation="vertical" className={classes1.verticalDivider} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={3} lg={3}>
                                            {/* Property and unit details */}
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <Box className={classes.waterLeakageIcons}>
                                                    <img src="images/Group 619.svg" alt="no_img"></img>
                                                </Box>
                                                <Typography className={classes.quotationsummaryans} noWrap>
                                                    {t("UNIT DETAILS")}
                                                </Typography>

                                            </Box>
                                            <Box>
                                                <Stack direction="row" alignItems="center">
                                                    {/* <Typography className={classes.quotationsummaryquest} noWrap>
                                                        {datas?.data?.table?.[0]?.unit?.property?.name}
                                                    </Typography> */}
                                                    {/* <Box className={classes1.dotService}></Box> */}
                                                    <Typography className={classes.quotationsummarySubquest} noWrap>
                                                        {datas?.data?.table?.[0]?.unit?.unit?.name}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box mt={1} mb={1}>
                                    <Divider />
                                </Box>
                                <Box p={1.5}>
                                    <Grid container spacing={2}>
                                        {datas?.data?.table?.[0]?.raisedon?.length > 0 && (
                                            <>
                                                <Grid item xs={6} sm={6} md={3} lg={3}>
                                                    <Box display={"flex"} justifyContent={"space-between"}>
                                                        <Box>
                                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                                <Box className={classes.waterLeakageIcons}>
                                                                    <img
                                                                        src="images/icons8-schedule (1).svg"
                                                                        alt="no_img"
                                                                    ></img>
                                                                </Box>
                                                                <Typography
                                                                    className={classes.quotationsummaryans}
                                                                    noWrap
                                                                >
                                                                    {t("PREFERRED VISIT DATE")}
                                                                </Typography>

                                                            </Box>
                                                            <Box className={classes.quotationsummaryquest}>
                                                                &#x202a;{datas?.data?.table?.[0]?.preferredtime ? convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.preferredtime, "DD MMM YYYY") : "-"
                                                                }&#x202c;
                                                            </Box>
                                                        </Box>
                                                        <Divider orientation="vertical" className={classes1.verticalDivider} />
                                                    </Box>
                                                </Grid>
                                                {datas?.data?.table?.[0]?.slot_name !== null &&
                                                    <Grid item xs={6} sm={6} md={4} lg={4}>
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <Box>
                                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                                    <Box className={classes.waterLeakageIcons}>
                                                                        <img
                                                                            src="images/icons8-schedule (1).svg"
                                                                            alt="no_img"
                                                                        ></img>
                                                                    </Box>
                                                                    <Typography
                                                                        className={classes.quotationsummaryans}
                                                                        noWrap
                                                                    >
                                                                        {t("PREFERRED VISIT TIME")}
                                                                    </Typography>

                                                                </Box>
                                                                <Box className={classes1.slotTime}>
                                                                    <Typography className={classes.quotationsummaryquest}>
                                                                        {datas?.data?.table?.[0]?.slot_name !== null ? datas?.data?.table?.[0]?.slot_name : "-"}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            {/* <Divider orientation="vertical" className={classes1.verticalDivider} /> */}
                                                        </Box>
                                                    </Grid>
                                                }
                                            </>
                                        )}
                                    </Grid>
                                </Box>
                            </Box>

                        </Box>
                        <Box>
                            <Divider />
                        </Box>

                        {
                            datas?.data?.table?.[0]?.work_order_status &&
                            <Box p={2}>
                                <Stack direction={"row"} columnGap={"8px"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography className={classes1.requestheading}>
                                        {t("OUTSOURCED WORK ORDER INFORMATION")}
                                    </Typography>
                                    <Button className={classes1.view_more_button}
                                        onClick={() => handleEditView("view")}>
                                        <Typography className={classes1.view_more_text}>
                                            {t("View More")}
                                        </Typography>
                                    </Button>
                                </Stack>
                                <Box className={classes1.detailsReqBox}>
                                    <Stack direction={"column"} spacing={"12px"}>
                                        <Stack direction={"row"} spacing={"18px"}
                                            justifyContent={"space-between"} alignItems={"center"}>
                                            <Stack direction={"row"} spacing={"18px"}
                                                justifyContent={"space-between"} alignItems={"center"}>
                                                {datas?.data?.table?.[0]?.work_order_ref_no &&
                                                    <Box className={classes1.tag_1}>
                                                        <Typography className={classes1.tag_text_1}>
                                                            {datas?.data?.table?.[0]?.work_order_ref_no}
                                                        </Typography>
                                                    </Box>
                                                }
                                                {datas?.data?.table?.[0]?.work_order_date &&
                                                    <Box className={classes1.tag_1}>
                                                        <Typography className={classes1.tag_text_1}>
                                                            &#x202a;{datas?.data?.table?.[0]?.work_order_date && convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.work_order_date)}&#x202c;
                                                        </Typography>
                                                    </Box>
                                                }
                                            </Stack>
                                            <Stack direction={"row"} columnGap={2}>
                                                <Typography className={statusStyle[datas?.data?.table?.[0]?.third_party_status_request?.[0]?.status]}>{datas?.data?.table?.[0]?.third_party_status_request?.[0]?.status}</Typography>
                                                {
                                                    (datas?.data?.table?.[0]?.vendor_email_id && datas?.data?.table?.[0]?.work_order_status === "assigned") &&
                                                    <Button className={classes.sendMail} onClick={() => handleSendMail()} disabled={disableMail}>{t("Send Mail")}</Button>
                                                }
                                                <CustomDropdown
                                                    t={t}
                                                    placeholder={"Select Status"}
                                                    options={options?.work_order_status}
                                                    value={t(datas?.data?.table?.[0]?.work_order_status)}
                                                    on_change={(value) => handleWorkStatus(value)}
                                                    root_height={"25px"}
                                                    root_padding={"4px 8px"}
                                                    menu_item_height={"30px"} />
                                            </Stack>

                                        </Stack>
                                        <Stack direction={"row"} spacing={"18px"}
                                            justifyContent={"space-between"} alignItems={"center"}>
                                            <Stack direction={"row"} spacing={"12px"}
                                                justifyContent={"space-between"} alignItems={"center"}>
                                                {datas?.data?.table?.[0]?.registered_worker?.helper_image
                                                    ? <img className={classes1.worker_image}
                                                        onClick={() => setIsViewImages({
                                                            ...isViewImages, imageViewer: true,
                                                            data: [{ url: datas?.data?.table?.[0]?.registered_worker?.helper_image }]
                                                        })}
                                                        src={datas?.data?.table?.[0]?.registered_worker?.helper_image} alt="" />
                                                    : <WorkerImagePlaceholder />
                                                }
                                                {showWorkerDetails() &&
                                                    <Stack direction={"column"} spacing={"4px"}>
                                                        <Stack direction={"row"} spacing={"4px"} alignItems={"center"}>
                                                            {(datas?.data?.table?.[0]?.registered_worker?.label
                                                                || datas?.data?.table?.[0]?.worker_contact_name) &&
                                                                <Typography className={classes1.worker_name}>
                                                                    {datas?.data?.table?.[0]?.registered_worker?.label
                                                                        ?? datas?.data?.table?.[0]?.worker_contact_name
                                                                        ?? "-"
                                                                    }
                                                                </Typography>
                                                            }
                                                            {datas?.data?.table?.[0]?.vendor_ref?.label &&
                                                                <Typography className={classes1.vendor_name}>
                                                                    {`(${datas?.data?.table?.[0]?.vendor_ref?.label})`
                                                                        ?? "-"
                                                                    }
                                                                </Typography>
                                                            }
                                                        </Stack>
                                                        <Stack direction={"row"} spacing={"4px"} alignItems={"center"}
                                                            divider={<Box className={classes1.worker_period} />}>
                                                            {(datas?.data?.table?.[0]?.registered_worker?.profession?.label
                                                                || datas?.data?.table?.[0]?.profession?.label) &&
                                                                <Typography className={classes1.worker_details}>
                                                                    {datas?.data?.table?.[0]?.registered_worker?.profession?.label
                                                                        ?? datas?.data?.table?.[0]?.profession?.label
                                                                        ?? "-"
                                                                    }
                                                                </Typography>
                                                            }
                                                            {(datas?.data?.table?.[0]?.registered_worker?.mobile_no
                                                                || datas?.data?.table?.[0]?.worker_contact_no) &&
                                                                <Typography className={classes1.worker_details}>
                                                                    {(datas?.data?.table?.[0]?.registered_worker?.mobile_no
                                                                        && concat_string(
                                                                            {
                                                                                mobile_code: datas?.data?.table?.[0]?.registered_worker?.mobile_country_code,
                                                                                mobile_no: datas?.data?.table?.[0]?.registered_worker?.mobile_no
                                                                            },
                                                                            ["mobile_code", "mobile_no"],
                                                                            "-",
                                                                        ))
                                                                        ?? (datas?.data?.table?.[0]?.worker_contact_no
                                                                            && concat_string(
                                                                                {
                                                                                    mobile_code: datas?.data?.table?.[0]?.worker_contact_country_code,
                                                                                    mobile_no: datas?.data?.table?.[0]?.worker_contact_no
                                                                                },
                                                                                ["mobile_code", "mobile_no"],
                                                                                "-",
                                                                            ))
                                                                        ?? "-"
                                                                    }
                                                                </Typography>
                                                            }
                                                            {(datas?.data?.table?.[0]?.registered_worker?.email_id
                                                                || datas?.data?.table?.[0]?.worker_email_id) &&
                                                                <Typography className={classes1.worker_details}>
                                                                    {datas?.data?.table?.[0]?.registered_worker?.email_id
                                                                        ?? datas?.data?.table?.[0]?.worker_email_id
                                                                        ?? "-"
                                                                    }
                                                                </Typography>
                                                            }
                                                            {(datas?.data?.table?.[0]?.registered_worker?.company_name
                                                                || datas?.data?.table?.[0]?.worker_company_name) &&
                                                                <Typography className={classes1.worker_details}>
                                                                    {datas?.data?.table?.[0]?.registered_worker?.company_name
                                                                        ?? datas?.data?.table?.[0]?.worker_company_name
                                                                        ?? "-"
                                                                    }
                                                                </Typography>
                                                            }
                                                        </Stack>
                                                    </Stack>}
                                            </Stack>
                                            <Stack direction={"row"} spacing={"18px"}
                                                justifyContent={"space-between"} alignItems={"center"}>
                                                {datas?.data?.table?.[0]?.total_amount > 0 &&
                                                    <Stack className={classes1.total_amount_tag}
                                                        direction={"row"} spacing={"8px"}
                                                        justifyContent={"space-between"} alignItems={"center"}>
                                                        {datas?.data?.table?.[0]?.total_amount > 0 &&
                                                            <Typography className={classes1.total_amount_label}>
                                                                {t("Total Amount ")}
                                                                <Typography className={classes1.total_amount_value}>
                                                                    {concat_string(
                                                                        {
                                                                            amount: datas?.data?.table?.[0]?.total_amount?.toString(),
                                                                            currency_code: datas?.data?.table?.[0]?.worker_currency,
                                                                        },
                                                                        ["amount", "currency_code"],
                                                                        " "
                                                                    )}
                                                                </Typography>
                                                            </Typography>
                                                        }
                                                        <Stack className={classes1.paid_tag}
                                                            direction={"row"} spacing={"6px"}
                                                            justifyContent={"space-between"} alignItems={"center"}>
                                                            {datas?.data?.table?.[0]?.is_amount_paid === 0
                                                                ? <PaidIcon />
                                                                : datas?.data?.table?.[0]?.is_amount_paid === 1
                                                                    ? <PaidIcon />
                                                                    : datas?.data?.table?.[0]?.is_amount_paid === 2
                                                                        ? <PaidIcon />
                                                                        : <PaidIcon />
                                                            }
                                                            <Typography className={classes1.paid_text}>
                                                                {t(PaidStatus?.[datas?.data?.table?.[0]?.is_amount_paid]?.label)}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>}
                                                <Stack direction={"row"} spacing={"8px"}
                                                    justifyContent={"space-between"} alignItems={"center"}>
                                                    <Box className={classes1.worker_action_button}
                                                        onClick={handleDownload}>
                                                        <DownloadIcon />
                                                    </Box>
                                                    {datas?.data?.table?.[0]?.work_order_status !== "closed" &&
                                                        <Box className={classes1.worker_action_button}
                                                            onClick={() => handleEditView("edit")}>
                                                            <EditIcon />
                                                        </Box>
                                                    }
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        {datas?.data?.table?.[0]?.work_completion?.length > 0 &&
                                            <>
                                                <CustomTypography1 mb={"16px"}>{t("Work Completion Details")}</CustomTypography1>
                                                <Box ref={seeMoreDivRef} className={classes.work_completion_section}>
                                                    {datas?.data?.table?.[0]?.work_completion?.length > 0
                                                        ? (datas?.data?.table?.[0]?.work_completion?.split('\n').map((_, i) =>
                                                            <CustomTypography2 sx={{ margin: "0px !important" }} key={i}>{_}</CustomTypography2>))
                                                        : "-"
                                                    }
                                                </Box>
                                                {seeMoreHeight > 49 &&
                                                    <Box
                                                        className={classes.seeMore}
                                                        onClick={() => setIsSeeMore(!isSeeMore)}>
                                                        {isSeeMore ? "See More" : "See Less"}
                                                    </Box>
                                                }
                                            </>
                                        }
                                    </Stack>
                                </Box>
                            </Box>
                        }
                    </Box >
                </Grid >

                {/* track */}
                < Grid
                    item
                    xs={12}
                    md={3.5}
                    sm={12}
                    lg={3.5}

                >
                    <>
                        {datas?.data?.table?.[0]?.current_status?.[0]?.type !== "Closed" && datas?.data?.table?.[0]?.current_status?.[0]?.type !== "Cancelled" && datas?.data?.table?.[0]?.type === null && datas?.data?.table?.[0]?.type !== "Non chargeable" &&
                            <>
                                {!bool?.isAcknowledge && !bool?.showChargeable && !datas?.data?.table?.[0]?.is_acknowleged &&
                                    <Box p={1} className={classes1.trackboxmod} display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography className={classes1.acknowledgeTxt}>{t("Acknowledge")}</Typography>
                                        <Switch onChange={(e) => handleChange(e.target.checked)} />
                                    </Box>
                                }
                                {bool?.isAcknowledge &&
                                    <Box p={1} className={classes1.trackboxmod} display="flex" justifyContent={"center"} alignItems={"center"} mb={2}>
                                        <Stack direction="row" spacing={1} alignItems={"center"} className={classes1.acknowledgeSentBox}>
                                            <CheckCircleIcon style={{ color: "#5AC782" }} />
                                            <Typography className={classes1.acknowledgeSent}>{t("Acknowledge Sent Successfully")}</Typography>
                                        </Stack>
                                    </Box>
                                }
                                {(bool?.showChargeable || datas?.data?.table?.[0]?.is_acknowleged) &&
                                    <Box className={classes1.trackboxmod} alignItems={"center"} mt={2}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Box pl={2} pr={2} pb={2}>
                                                    <SelectBox label={t("Charging Method")} options={options?.request_type} value={SelectedChargingMethod} selectHeight='35px' onChange={(val) => handleChargingMethod(val)} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        {SelectedChargingMethod?.value === "Non Chargeable" && bool?.firstResponse === null &&
                                            <>
                                                <Divider />
                                                <Box style={{ padding: "8px 16px" }} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Typography className={classes1.acknowledgeTxt}>{t("1st Response")}</Typography>
                                                    <Switch onChange={(e) => handleFirstResponse(e.target.checked)} />
                                                </Box>
                                                <Divider />
                                            </>
                                        }
                                        {bool?.firstResponse &&
                                            <>
                                                <Box p={1} display="flex" justifyContent={"center"} alignItems={"center"}>
                                                    <Stack direction="row" spacing={1} alignItems={"center"} className={classes1.acknowledgeSentBox}>
                                                        <CheckCircleIcon style={{ color: "#5AC782" }} />
                                                        <Typography className={classes1.acknowledgeSent}>1st Response Sent Successfully</Typography>
                                                    </Stack>
                                                </Box>
                                                <Divider />
                                            </>
                                        }
                                        {bool?.showVisitor && SelectedChargingMethod?.value === "Chargeable" &&
                                            <Box p={2} display={"flex"} justifyContent={"center"} >
                                                <Box display="flex" justifyContent={"space-between"} className={classes1.visitingCharges} alignItems={"center"} width={"400px"}>
                                                    <Box>
                                                        <Typography className={classes1.chargingReqTxt}>{(t("Upfront Charges and Payments"))}</Typography>
                                                        <Typography className={classes1.chargingSubReqTxt}>Do you want to send the request?</Typography>
                                                    </Box>
                                                    <Stack direction="row" spacing={0} alignItems={"center"}>
                                                        <IconButton onClick={cancelVistingCharge}><CancelRequestIcon /></IconButton>
                                                        <IconButton onClick={() => { setBool({ ...bool, applyVisitorCharging: true }) }}><ApproveRequestIcon /></IconButton>
                                                    </Stack>
                                                </Box>
                                            </Box>
                                        }
                                        {bool?.showCancelMsg &&
                                            <Box p={1} display="flex" justifyContent={"center"} alignItems={"center"}>
                                                <Stack direction="row" spacing={1} alignItems={"center"} className={classes1.visitingCharges}>
                                                    <CheckCircleIcon style={{ color: "#FF4B4B" }} />
                                                    <Typography className={classes1.acknowledgeTxt}>Visiting charge is Cancelled</Typography>
                                                </Stack>
                                            </Box>
                                        }
                                        {bool?.showSuccessMsg &&
                                            <Box p={1} display="flex" justifyContent={"center"} alignItems={"center"}>
                                                <Stack direction="row" spacing={1} alignItems={"center"} className={classes1.visitingCharges}>
                                                    <CheckCircleIcon style={{ color: "#5AC782" }} />
                                                    <Typography className={classes1.acknowledgeTxt}>Visiting charge sent</Typography>
                                                </Stack>
                                            </Box>
                                        }
                                        {
                                            bool?.showCosting &&
                                            <Box p={2} onClick={() => openCostingDetails()}>
                                                <Box display={"flex"} justifyContent={"center"} className={classes1.costingBox}>
                                                    <Stack direction={"row"} spacing={2} alignItems="center" >
                                                        <Typography className={classes1?.costingTxt}>Go to costing page</Typography>
                                                        <KeyboardArrowRight style={{ color: "#5078E1" }} />
                                                    </Stack>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                }
                            </>
                        }
                        <Box className={classes1.trackboxmod} mt={datas?.data?.table?.[0]?.initial_costing_status === null && datas?.data?.table?.[0]?.type !== "Non Chargeable" && datas?.data?.table?.[0]?.current_status?.[0]?.type !== "Closed" && datas?.data?.table?.[0]?.current_status?.[0]?.type !== "Cancelled" ? 2 : 0.5}>
                            <Subheader
                                title={t("Track")}
                                hideBackButton
                            />
                            <Box>
                                <Grid container direction={"column"} spacing={1}>
                                    <Box className={classes1.createnewsectionlog1} height={datas?.data?.table?.[0]?.type !== null ? 'calc(100vh - 355px)' : datas?.data?.table?.[0]?.type === null && datas?.data?.table?.[0]?.is_acknowleged && SelectedChargingMethod?.value === "Non Chargeable" && datas?.data?.table?.[0]?.current_status?.[0]?.type !== "Cancelled" && datas?.data?.table?.[0]?.current_status?.[0]?.type !== "Closed" ? 'calc(100vh - 515px)' : (datas?.data?.table?.[0]?.type === null && datas?.data?.table?.[0]?.is_acknowleged && SelectedChargingMethod?.value === "Chargeable" && datas?.data?.table?.[0]?.current_status?.[0]?.type !== "Cancelled" && datas?.data?.table?.[0]?.current_status?.[0]?.type !== "Closed") ? 'calc(100vh - 560px)' : (datas?.data?.table?.[0]?.current_status?.[0]?.type === "Cancelled" || datas?.data?.table?.[0]?.current_status?.[0]?.type === "Closed") ? 'calc(100vh - 353px)' : 'calc(100vh - 420px)'}>
                                        {datas?.data?.table?.[0]?.status
                                            .map((x, index, array) => {
                                                return (
                                                    <Box className={classes1.treeBox}>
                                                        <Box className={datas?.data?.table?.[0]?.status?.length - 1 !== index ? classes1.border : classes1.applynoborder}>

                                                            <Box>
                                                                <Avatar
                                                                    variant="circular"
                                                                    className={classes1.avatars1}
                                                                >
                                                                    <Avatar
                                                                        variant="circular"
                                                                        style={{
                                                                            height: "100%",
                                                                            width: "100%",
                                                                            backgroundColor: "#5078E1",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                state?.main?.request === "Maintance"
                                                                                    ? x?.status === "Open"
                                                                                        ? "images/icons8-clock.svg"
                                                                                        : "images/Path 953.svg"
                                                                                    : x?.status === "Open"
                                                                                        ? "images/icons8-clock.svg"
                                                                                        : "images/Path 953.svg"
                                                                            }
                                                                            alt="document"
                                                                        />
                                                                    </Avatar>
                                                                </Avatar>
                                                            </Box>
                                                            <Box marginInlineStart={"14px"}>
                                                                <Typography
                                                                    className={classes1.title}
                                                                >
                                                                    {x?.status}
                                                                </Typography>
                                                                <Box height={'4px'} />
                                                                {
                                                                    <>
                                                                        <>
                                                                            {x?.status?.length > 0 ?
                                                                                <Typography
                                                                                    className={classes1.notes}
                                                                                >
                                                                                    Notes: {x?.notes ?? "-"}
                                                                                </Typography>
                                                                                :
                                                                                <Typography
                                                                                    className={classes1.title}
                                                                                >
                                                                                    {x?.notes}
                                                                                </Typography>
                                                                            }
                                                                            <Box height={'4px'} />
                                                                            <Stack
                                                                                direction="row"
                                                                                divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "12px" }} />}
                                                                            >
                                                                                <Typography
                                                                                    className={classes1.dateTime}
                                                                                >
                                                                                    &#x202a;{x?.created_at ? moment(x?.created_at).format("DD MMM YY hh:mm a") : "-"
                                                                                    }&#x202c;
                                                                                </Typography>
                                                                                {x?.created_by &&
                                                                                    <Typography
                                                                                        className={classes1.dateTime}
                                                                                    >
                                                                                        {x?.created_by ? x?.created_by : "-"
                                                                                        }
                                                                                    </Typography>
                                                                                }
                                                                                {x?.closed_by && x?.status === "Closed" &&
                                                                                    <Typography
                                                                                        className={classes1.dateTime}
                                                                                    >
                                                                                        {x?.closed_by}
                                                                                    </Typography>
                                                                                }
                                                                            </Stack>
                                                                            {/* <Box height={'15px'} /> */}
                                                                        </>
                                                                    </>
                                                                }
                                                                {
                                                                    datas?.data?.table?.[0]?.status?.length !== index + 1 && <Box height={'20px'} />
                                                                }
                                                            </Box>
                                                        </Box>

                                                    </Box >
                                                )
                                            })}
                                    </Box >
                                </Grid >

                                <Box className={classes1.btnParent} >
                                    <Button className={classes1.btn} variant="contained" onClick={() => dialogClose("open", true)} disabled={datas?.data?.table?.[0]?.status?.[0]?.status === "Closed" || datas?.data?.table?.[0]?.status?.[0]?.status === "Cancelled"}>+{t("Add_Note")}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </>
                </Grid >
            </Grid >
            <Drawer
                anchor={"right"}
                open={showMore}
                onClose={() => setShowMore(false)}
            >
                <Box width="400px">
                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"} p={1.5} sx={{ backgroundColor: "#F2F4F7" }}>
                        <Typography className={classes1.drawerHeader}>{t("Asset / Item from unit")}</Typography>
                        <Box onClick={() => setShowMore(false)} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
                    </Box>
                    <Box><Divider /></Box>
                    {datas?.data?.table?.[0]?.inspection_mapping?.length > 0 &&
                        <Box p={2}>
                            <center>
                                {JSON.parse(datas?.data?.table?.[0]?.inspection_mapping?.[0]?.assets)?.length > 0 &&
                                    <SliderIMG {...Imgsettings}>
                                        {JSON.parse(datas?.data?.table?.[0]?.inspection_mapping?.[0]?.assets)?.map((x) => {
                                            return (

                                                <Box mb={1.5} onClick={() => handleImageViewer()}>
                                                    <img src={x?.url} alt="product asset" className={classes1.productImage} />
                                                </Box>

                                            )
                                        })}
                                    </SliderIMG>

                                }
                                <Box className={classes1.itemBox}>
                                    <Typography className={classes1.itemCondition}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.item_condition}</Typography>
                                </Box>
                                <Box mt={2}>
                                    <Typography className={classes1.itemName}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.name}</Typography>
                                </Box>
                            </center>
                            <Box mt={2} height={JSON.parse(datas?.data?.table?.[0]?.inspection_mapping?.[0]?.assets)?.length > 0 ? size?.height - 345 : size?.height - 205} overflow={"scroll"}>
                                <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography className={classes1.productTitle}>{t("Manufacturer")}</Typography>
                                    <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.item?.manufacturer?.name}</Typography>
                                </Box>
                                <Box mt={1.5} mb={1.5}><Divider /></Box>
                                <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography className={classes1.productTitle}>{t("Serial Number")}</Typography>
                                    <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.serial_number}</Typography>
                                </Box>
                                <Box mt={1.5} mb={1.5}><Divider /></Box>
                                <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography className={classes1.productTitle}>{t("Model Number")}</Typography>
                                    <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.model_number}</Typography>
                                </Box>
                                {datas?.data?.table?.[0]?.inspection_mapping?.[0]?.location !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes1.productTitle}>{t("Location")}</Typography>
                                            <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.location?.name}</Typography>
                                        </Box>
                                    </>
                                }
                                {datas?.data?.table?.[0]?.last_service_date !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes1.productTitle}>{t("Last Service")}</Typography>
                                            <Typography className={classes1.productValue}>{moment(datas?.data?.table?.[0]?.last_service_date).format("DD MMM YYYY")}</Typography>
                                        </Box>
                                    </>
                                }
                                {datas?.data?.table?.[0]?.inspection_mapping?.[0]?.device_name !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes1.productTitle}>{t("Device Name")}</Typography>
                                            <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.device_name ?? "-"}</Typography>
                                        </Box>
                                    </>
                                }
                                {datas?.data?.table?.[0]?.inspection_mapping?.[0]?.platform !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes1.productTitle}>{t("Platform")}</Typography>
                                            <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.platform ?? "-"}</Typography>
                                        </Box>
                                    </>
                                }
                                {datas?.data?.table?.[0]?.inspection_mapping?.[0]?.license !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes1.productTitle}>{t("License")}</Typography>
                                            <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.license ?? "-"}</Typography>
                                        </Box>
                                    </>
                                }
                                {datas?.data?.table?.[0]?.inspection_mapping?.[0]?.imei !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes1.productTitle}>{t("IMEI")}</Typography>
                                            <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.imei ?? "-"}</Typography>
                                        </Box>
                                    </>
                                }
                                {datas?.data?.table?.[0]?.inspection_mapping?.[0]?.mac !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes1.productTitle}>{t("Mac")}</Typography>
                                            <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.mac ?? "-"}</Typography>
                                        </Box>
                                    </>
                                }
                                {datas?.data?.table?.[0]?.inspection_mapping?.[0]?.hardware !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes1.productTitle}>{t("Hardware")}</Typography>
                                            <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.hardware ?? "-"}</Typography>
                                        </Box>
                                    </>
                                }
                                {datas?.data?.table?.[0]?.inspection_mapping?.[0]?.software !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes1.productTitle}>{t("Software")}</Typography>
                                            <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.software ?? "-"}</Typography>
                                        </Box>
                                    </>
                                }
                                {datas?.data?.table?.[0]?.inspection_mapping?.[0]?.firmware !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes1.productTitle}>{t("Firmware")}</Typography>
                                            <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.firmware ?? "-"}</Typography>
                                        </Box>
                                    </>
                                }
                                {datas?.data?.table?.[0]?.inspection_mapping?.[0]?.build !== null &&
                                    <>
                                        <Box mt={1.5} mb={1.5}><Divider /></Box>
                                        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes1.productTitle}>{t("Build")}</Typography>
                                            <Typography className={classes1.productValue}>{datas?.data?.table?.[0]?.inspection_mapping?.[0]?.build ?? "-"}</Typography>
                                        </Box>
                                    </>
                                }
                            </Box>
                        </Box>
                    }
                </Box>
            </Drawer>
            <AlertDialog
                open={bool?.applyVisitorCharging}
                onClose={() => closeModal()}
                header={t("UpfrontChargesAndPayments")}
                isNormal
                component={
                    <Box p={2}>
                        <TextBox
                            isrequired
                            label={"Amount"}
                            placeholder={"Enter Amount"}
                            type="number"
                            value={details?.amount ?? ""}
                            onChange={(e) =>
                                handleChargeData("amount", e.target.value)
                            }
                            endAdornment={state?.main?.companyData?.currency_symbol}
                        />
                        <Box mt={1}>
                            <TextBox
                                isrequired
                                label={t("Notes")}
                                placeholder={t("Notes")}
                                value={details?.notes ?? ""}
                                onChange={(e) =>
                                    handleChargeData("notes", e.target.value)
                                }
                                multiline
                            />
                        </Box>
                        <Box mt={6}>
                            <Stack direction={"row"}>
                                <Button variant="outlined" fullWidth onClick={() => closeModal()}>{t("Cancel")}</Button>
                                <Button variant="contained" fullWidth onClick={() => sendRequest()} disabled={details?.amount?.length > 0 ? false : true} sx={{ marginInlineStart: '16px' }}>{t("Send Request")}</Button>
                            </Stack>
                        </Box>
                    </Box>
                }
            />
            <AlertDialog open={bool?.successModal}
                // onClose={() => handleClose()}
                isnotTitle={true}
                medium
                component={
                    <Box className={classes1.showComplete} p={1} alignItems="center">
                        <Box display={"flex"} justifyContent="end" onClick={() => closeSuccessModal()}>
                            <Close className={classes1.closeicon} />
                        </Box>
                        <Grid container mt={6}>
                            {language !== "ar" ?
                                <>
                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={8}>
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                            <Typography className={classes1.q1hdr}>
                                                {`${t("UpfrontChargesAndPayments")} sent Successfully`}
                                            </Typography>
                                        </Box>
                                        <Box mt={3} ml={2} display="flex" justifyContent={"center"} alignItems="center">
                                            <Button variant="contained" className={classes1.yesbtn} onClick={() => gotoCosting()}>{t("Okay")}</Button>
                                        </Box>
                                    </Grid> </> : <>
                                    <Grid item xs={8}>
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                            <Typography className={classes1.q1hdr}>
                                                {`${t("UpfrontChargesAndPayments")} sent Successfully`}
                                            </Typography>
                                        </Box>
                                        <Box mt={3} ml={2} display="flex" justifyContent={"center"} alignItems="center">
                                            <Button variant="contained" className={classes1.yesbtn} onClick={() => gotoCosting()}>{t("Okay")}</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}></Grid>
                                </>
                            }
                        </Grid>
                    </Box>
                }
            />
            {/* add notes */}
            <DialogBox
                header={t('Add_Note')}
                maxWidth={"xs"}
                handleClose={() => dialogClose("open", false)}
                onClose={() => dialogClose("open", false)}
                open={load}
                component={
                    <Box p={2} backgroundColor="white">
                        <Box className={classes1.statusBox}>
                            <Typography className={classes1.convayTitle}>{t("What_convay")}</Typography>
                            <Box height={'10px'} />
                            <Typography className={classes1.status}><span className={classes1.subStatus}>{t("Status")}</span>&nbsp;{datas?.data?.table?.[0]?.status?.[0]?.status_type}</Typography>
                        </Box>
                        {datas?.data?.table?.[0]?.build_source === "Resident" &&
                            <Box mt={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography className={classes1.showNotesText}>{`Show to ${datas?.data?.table?.[0]?.build_source}`}</Typography>
                                <Switch onChange={(e) => handleNotes(e.target.checked)} />
                            </Box>
                        }
                        <TextBox
                            isrequired
                            label={t("Notes")}
                            placeholder={t("Notes")}
                            value={notes ?? ""}
                            onChange={(e) =>
                                noteChange(e.target.value)
                            }
                            multiline
                        />
                        <Box height={'30px'} />
                        <Button className={classes1.btn} variant="contained" disabled={notes?.length > 0 ? false : true} onClick={sumbit}>{t("Save")}</Button>
                    </Box>
                }
            />
            {/* view all images */}
            <Dialog
                open={isViewImages?.imageViewer}
                fullWidth
                maxWidth={"md"}
                onClose={() => closeImageViewer()}
                className={classes.dialog}
            >
                <ImageViewer assets={isViewImages?.data ?? []} padding={"0px 21px 0px 23px"} />
            </Dialog>
            <Drawer
                anchor={"right"}
                open={is_view_more_open}
                onClose={() => handleEditClose("view")}>
                <Box width={"400px"}>
                    <Box display="flex" alignItems={"center"} p={1.5} rowGap={2} sx={{ backgroundColor: "#F2F4F7" }}>
                        <Box onClick={() => handleEditClose("view")}
                            style={{ cursor: "pointer", display: "flex", alignItems: "center", marginInlineEnd: "16px" }}>
                            <CloseIcon />
                        </Box>
                        <Typography className={classes1.drawerHeader}>{t("OUTSOURCED WORK ORDER INFORMATION")}</Typography>
                    </Box>
                    <Box><Divider /></Box>
                    <Box sx={{ overflow: "overlay", height: size?.height - 65, padding: "16px", }}>
                        <Box className={classes1.view_grey_card} mb={"20px"}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={"8px"}>
                                <Stack direction={"column"} spacing={"4px"}>
                                    <Typography className={classes1.view_work_order_ref_no}>
                                        {edit_state?.workNo ?? "-"}
                                    </Typography>
                                    <Typography className={classes1.view_work_order_date}>
                                        &#x202a;{edit_state?.workdate ? convertTimeUtcToZoneCalander(edit_state?.workdate) : "-"}&#x202c;
                                    </Typography>
                                </Stack>
                                {edit_state?.workerStatus?.label &&
                                    <Box className={classes1.work_order_status_tag}>
                                        <Typography className={classes1.work_order_status_text}>
                                            {edit_state?.workerStatus?.label}
                                        </Typography>
                                    </Box>
                                }
                            </Stack>
                        </Box>
                        <CustomTypography2 mb={"8px"}>{t("VENDOR DETAILS")}</CustomTypography2>
                        <Box className={classes1.view_section_details_card} mb={"20px"}>
                            <Stack direction={"column"} divider={<Divider />} spacing={"16px"}>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Registered Vendor")}</CustomTypography3>
                                    <CustomTypography4>{edit_state?.vendor?.label ?? "-"}</CustomTypography4>
                                </Stack>
                            </Stack>
                        </Box>
                        <CustomTypography2 mb={"8px"}>{t("WORKER DETAILS")}</CustomTypography2>
                        <Box className={classes1.view_section_details_card} mb={"20px"}>
                            <Stack direction={"row"} spacing={"12px"} alignItems={"center"} mb={"24px"}>
                                {edit_state?.reg_worker?.helper_image
                                    ? <img className={classes1.view_worker_image}
                                        onClick={() => setIsViewImages({
                                            ...isViewImages, imageViewer: true,
                                            data: [{ url: edit_state?.reg_worker?.helper_image }]
                                        })}
                                        src={edit_state?.reg_worker?.helper_image} alt="" />
                                    : <WorkerImagePlaceholder />
                                }
                                <Stack direction={"column"} spacing={"4px"}>
                                    <Typography className={classes1.view_worker_name}>
                                        {edit_state?.reg_worker?.label
                                            ?? edit_state?.worker_contact_name
                                            ?? "-"
                                        }
                                    </Typography>
                                    <Typography className={classes1.view_worker_details}>
                                        {edit_state?.reg_worker?.profession?.label
                                            ?? edit_state?.profession?.label
                                            ?? "-"
                                        }
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={"column"} divider={<Divider />} spacing={"16px"}>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Assigned Date")}</CustomTypography3>
                                    <CustomTypography4>
                                        &#x202a;{edit_state?.assigned_date ? convertTimeUtcToZoneCalander(edit_state?.assigned_date) : "-"}&#x202c;
                                    </CustomTypography4>
                                </Stack>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Worker Type")}</CustomTypography3>
                                    <CustomTypography4>{edit_state?.reg_worker?.value ? "Registered" : "Unregistered"}</CustomTypography4>
                                </Stack>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Contact Number")}</CustomTypography3>
                                    <CustomTypography4>
                                        {edit_state?.worker_contact?.mobile
                                            ? concat_string(
                                                {
                                                    mobile_code: edit_state?.worker_contact?.mobile_code,
                                                    mobile_no: edit_state?.worker_contact?.mobile
                                                },
                                                ["mobile_code", "mobile_no"],
                                                "-",
                                            )
                                            : "-"
                                        }
                                    </CustomTypography4>
                                </Stack>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Email")}</CustomTypography3>
                                    <CustomTypography4>{edit_state?.vendor_email_id?.length > 0 ? edit_state?.vendor_email_id : "-"}</CustomTypography4>
                                </Stack>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Alternate Company")}</CustomTypography3>
                                    <CustomTypography4>{edit_state?.alternative_company?.length > 0 ? edit_state?.alternative_company : "-"}</CustomTypography4>
                                </Stack>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Location")}</CustomTypography3>
                                    <CustomTypography4>{edit_state?.worker_company_location?.length > 0 ? edit_state?.worker_company_location : "-"}</CustomTypography4>
                                </Stack>
                            </Stack>
                        </Box>
                        <CustomTypography2 mb={"8px"}>{t("CHARGES & PAYMENT DETAILS")}</CustomTypography2>
                        <Box className={classes1.view_section_details_card} mb={"20px"}>
                            <Stack direction={"row"} spacing={"12px"} justifyContent={"space-between"} alignItems={"center"} mb={"24px"}>
                                <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
                                    <ChargeIcon />
                                    <Stack direction={"column"} spacing={"4px"}>
                                        <Typography className={classes1.view_worker_name}>
                                            {concat_string(
                                                {
                                                    amount: ((edit_state?.service_charges ? parseInt(edit_state?.service_charges) : 0) + (edit_state?.material_charges ? parseInt(edit_state?.material_charges) : 0))?.toString(),
                                                    currency_code: selected_company?.code,
                                                },
                                                ["amount", "currency_code"],
                                                " "
                                            )}
                                        </Typography>
                                        <Typography className={classes1.view_worker_details}>
                                            {t("Total Charges")}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Box className={classes1.view_paid_tag}>
                                    <Typography className={classes1.view_paid_text}>
                                        {t(PaidStatus?.[edit_state?.is_amount_paid]?.label)}
                                    </Typography>
                                </Box>
                            </Stack>
                            <Stack direction={"column"} divider={<Divider />} spacing={"16px"} mb={"20px"}>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Service Charges")}</CustomTypography3>
                                    <CustomTypography4>
                                        {concat_string(
                                            {
                                                amount: edit_state?.service_charges?.toString(),
                                                currency_code: selected_company?.code,
                                            },
                                            ["amount", "currency_code"],
                                            " "
                                        )}
                                    </CustomTypography4>
                                </Stack>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Material Charges")}</CustomTypography3>
                                    <CustomTypography4>
                                        {concat_string(
                                            {
                                                amount: edit_state?.material_charges?.toString(),
                                                currency_code: selected_company?.code,
                                            },
                                            ["amount", "currency_code"],
                                            " "
                                        )}
                                    </CustomTypography4>
                                </Stack>
                            </Stack>
                            <CustomTypography5 mb={"20px"}>{t("Payment Details")}</CustomTypography5>
                            <Stack direction={"column"} divider={<Divider />} spacing={"16px"}>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Total Paid")}</CustomTypography3>
                                    <CustomTypography4>
                                        {concat_string(
                                            {
                                                amount: edit_state?.total_paid?.toString(),
                                                currency_code: selected_company?.code,
                                            },
                                            ["amount", "currency_code"],
                                            " "
                                        )}
                                    </CustomTypography4>
                                </Stack>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Payment Method")}</CustomTypography3>
                                    <CustomTypography4>{edit_state?.payment_mode?.label ?? "-"}</CustomTypography4>
                                </Stack>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Paid To")}</CustomTypography3>
                                    <CustomTypography4>{edit_state?.paid_to?.length > 0 ? edit_state?.paid_to : "-"}</CustomTypography4>
                                </Stack>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Payment Date")}</CustomTypography3>
                                    {edit_state?.payment_date
                                        ? <CustomTypography4>
                                            &#x202a;{edit_state?.payment_date ? convertTimeUtcToZoneCalander(edit_state?.payment_date) : "-"}&#x202c;
                                        </CustomTypography4>
                                        : <CustomTypography4>{"-"}</CustomTypography4>}
                                </Stack>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Invoice Number")}</CustomTypography3>
                                    <CustomTypography4>{edit_state?.invoice_no?.length > 0 ? edit_state?.invoice_no : "-"}</CustomTypography4>
                                </Stack>
                                <Stack columnGap={"16px"} direction={"row"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomTypography3>{t("Invoice Date")}</CustomTypography3>
                                    <CustomTypography4>
                                        &#x202a;{edit_state?.invoice_date ? convertTimeUtcToZoneCalander(edit_state?.invoice_date?.toISOString()) : "-"}&#x202c;
                                    </CustomTypography4>
                                </Stack>
                            </Stack>
                        </Box>
                        <CustomTypography2 mb={"8px"}>{t("WORK COMPLETION DETAILS")}</CustomTypography2>
                        <Box className={classes1.view_section_details_card} mb={"20px"}>
                            {edit_state?.work_completion?.length > 0 &&
                                <>
                                    <Box ref={seeMoreDivRef} className={classes.work_completion_section}>
                                        {edit_state?.work_completion?.length > 0
                                            ? (edit_state?.work_completion?.split('\n').map((_, i) =>
                                                <CustomTypography4 sx={{ margin: "0px !important" }} key={i}>{_}</CustomTypography4>))
                                            : "-"
                                        }
                                    </Box>
                                    {seeMoreHeight > 49 &&
                                        <Box
                                            className={classes.seeMore}
                                            onClick={() => setIsSeeMore(!isSeeMore)}>
                                            {isSeeMore ? "See More" : "See Less"}
                                        </Box>
                                    }
                                </>
                            }
                        </Box>
                        <CustomTypography2 mb={"8px"}>{t("ASSETS")}</CustomTypography2>
                        <Grid container spacing={1}>
                            {
                                datas?.assets?.map((val) => {
                                    return (
                                        <Grid item xs={6} onClick={() => setSelectedImage({
                                            bool: true,
                                            url: val
                                        })}>
                                            {
                                                val?.file_meta?.type === "pdf" ?
                                                    <Box sx={{ height: "150px", width: "100%" }}>
                                                        <FileViewer fileType={"pdf"} filePath={val?.url} />
                                                    </Box>
                                                    :
                                                    <img src={val?.url} alt="" style={{ height: "150px", width: "100%", borderRadius: "8px" }} />

                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </Box>
            </Drawer>
            <Drawer
                anchor={"right"}
                open={is_edit_open}
                onClose={() => handleEditClose("edit")}>
                <Box width={"800px"}>
                    <Box display="flex" alignItems={"center"} p={1.5} rowGap={2} sx={{ backgroundColor: "#F2F4F7" }}>
                        <Box onClick={() => handleEditClose("edit")}
                            style={{ cursor: "pointer", display: "flex", alignItems: "center", marginInlineEnd: "16px" }}>
                            <CloseIcon />
                        </Box>
                        <Typography className={classes1.drawerHeader}>{t("Edit Outsourced work order information")}</Typography>
                    </Box>
                    <Box><Divider /></Box>
                    <Box sx={{ overflow: "overlay", height: size?.height - 137 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className={classes.card}>
                                    <CustomTypography1>{t("BASIC DETAILS")}</CustomTypography1>
                                    <FormGenerator t={t}
                                        components={[
                                            {
                                                isActive: true,
                                                component: "datePickerWithTime",
                                                label: t("Assigned Date & Time"),
                                                value: edit_state?.assigned_date,
                                                placeholder: t("Assigned Date & Time"),
                                                onChange: (value) => updateState("assigned_date", value),
                                                error: edit_state?.error?.assigned_date,
                                                maxDate: new Date(),
                                                is_popover: true,
                                                border: '1.5px solid #E4E8EE !important',
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                            {
                                                isActive: true,
                                                component: "text",
                                                isReadonly: is_button_disable,
                                                label: t("Reference Ticket Number"),
                                                value: edit_state?.workNo,
                                                placeholder: t("Enter Reference Ticket Number"),
                                                onChange: (e) => updateState("workNo", e?.target?.value),
                                                error: edit_state?.error?.workNo,
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                                isPaginate: true,
                                            },
                                            {
                                                isActive: true,
                                                component: "datepicker",
                                                isReadonly: is_button_disable,
                                                label: t("Status Date"),
                                                value: edit_state?.workdate,
                                                placeholder: t("Select Status Date"),
                                                onChange: (value) => updateState("workdate", value),
                                                error: edit_state?.error?.workdate,
                                                maxDate: new Date(),
                                                // isRequired: true,
                                                isNot: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                                height: "47%"
                                            },
                                            {
                                                isActive: true,
                                                component: "select",
                                                isReadonly: is_button_disable,
                                                label: t("Status"),
                                                menu_text_transfrom: "capitalize",
                                                value: edit_state?.workerStatus,
                                                placeholder: t("Select Status"),
                                                onChange: (value) => updateState("workerStatus", value),
                                                error: edit_state?.error?.workerStatus,
                                                // isRequired: true,
                                                options: options?.work_order_status ?? [],
                                                zIndex: 0,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                }
                                            },
                                        ]}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.card}>
                                    <CustomTypography1>{t("VENDOR DETAILS")}</CustomTypography1>
                                    <FormGenerator t={t}
                                        components={[
                                            {
                                                isActive: true,
                                                component: "select",
                                                isReadonly: is_button_disable,
                                                label: t("Registered Vendor"),
                                                value: edit_state?.vendor,
                                                placeholder: t("Select Registered Vendor"),
                                                onChange: (value) => updateState("vendor", value),
                                                error: edit_state?.error?.vendor,
                                                // isRequired: true,
                                                zIndex: 0,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 6,
                                                },
                                                isPaginate: true,
                                                loadOptions: (search, array, handleLoading) =>
                                                    loadOptions(
                                                        search,
                                                        array,
                                                        handleLoading,
                                                        SELECT_VENDOR,
                                                        "vendor_master",
                                                        {
                                                            isActive: true,
                                                            client: localStorage.getItem(LocalStorageKeys.clinetID),
                                                            company_id: selected_company?.value,
                                                        },
                                                        { label: "label", value: "value" },
                                                        false,
                                                        false
                                                    ),
                                            },
                                        ]}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.card}>
                                    <CustomTypography1>{t("WORKER DETAILS")}</CustomTypography1>
                                    <FormGenerator t={t}
                                        components={[
                                            {
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                                isActive: true,
                                                component: "toggle",
                                                label: t("Worker Type"),
                                                value: edit_state?.worker_type,
                                                onChange: (value) => updateState("worker_type", value),
                                                error: edit_state?.error?.worker_type,
                                                options: WorkerTypeOptions({ t }),
                                                isReadonly: is_button_disable,
                                            },
                                            {
                                                isActive: edit_state?.worker_type === "registered",
                                                component: "select",
                                                label: t("Profession"),
                                                value: edit_state?.profession,
                                                isReadonly: is_button_disable,
                                                placeholder: t("Select Profession"),
                                                onChange: (value) => set_edit_state({ ...edit_state, profession: value, reg_worker: "" }),
                                                error: edit_state?.error?.profession,
                                                // isRequired: true,
                                                zIndex: 0,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                                isPaginate: true,
                                                loadOptions: (search, array, handleLoading) =>
                                                    loadOptions(
                                                        search,
                                                        array,
                                                        handleLoading,
                                                        GET_PROFESSION_MASTER,
                                                        "profession_master",
                                                        {
                                                            isActive: true,
                                                            client: localStorage.getItem(LocalStorageKeys.clinetID),
                                                            company_id: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.value,
                                                        },
                                                        { label: "label", value: "value" },
                                                        false,
                                                        false,
                                                    ),
                                            },
                                            {
                                                isActive: edit_state?.worker_type === WorkerTypeOptions({ t })?.[0]?.value ? true : false,
                                                component: "select",
                                                label: t("Registered Worker"),
                                                value: edit_state?.reg_worker,
                                                placeholder: t("Select Registered Worker"),
                                                isReadonly: is_button_disable,
                                                onChange: (value) => {
                                                    set_edit_state({
                                                        ...edit_state,
                                                        reg_worker: value,
                                                        vendor_email_id: value?.domestic_helper_address?.[0]?.email_id
                                                    })
                                                },
                                                error: edit_state?.error?.reg_worker,
                                                // isRequired: true,
                                                zIndex: 0,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                                isPaginate: true,
                                                key: JSON.stringify(edit_state?.profession),
                                                loadOptions: (search, array, handleLoading) =>
                                                    loadOptions(
                                                        search,
                                                        array,
                                                        handleLoading,
                                                        GET_DOMESTIC_HELPER_MASTER,
                                                        "domestic_helper_master",
                                                        {
                                                            isActive: true,
                                                            client: localStorage.getItem(LocalStorageKeys.clinetID),
                                                            company_id: selected_company?.value,
                                                            profession_id: edit_state?.profession?.value
                                                        },
                                                        {},
                                                        false,
                                                        manualDomesticHelperMasterOption,
                                                    ),
                                            },
                                            {
                                                isActive: edit_state?.worker_type === WorkerTypeOptions({ t })?.[1]?.value ? true : false,
                                                component: "text",
                                                label: t("Contact Name"),
                                                value: edit_state?.worker_contact_name,
                                                placeholder: t("Contact Name"),
                                                isReadonly: is_button_disable,
                                                onChange: (e) => updateState("worker_contact_name", e?.target?.value),
                                                error: edit_state?.error?.worker_contact_name,
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                            {
                                                isActive: true,
                                                component: "text",
                                                isReadonly: edit_state?.worker_type === "registered" ?? is_button_disable,
                                                label: t("Email"),
                                                value: edit_state?.vendor_email_id,
                                                placeholder: t("Enter Email"),
                                                onChange: (e) => updateState("vendor_email_id", e?.target?.value),
                                                error: edit_state?.error?.vendor_email_id,
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 6,
                                                },
                                            },
                                            {
                                                isActive: (edit_state?.worker_type === WorkerTypeOptions({ t })?.[0]?.value &&
                                                    edit_state?.reg_worker?.profession?.label) ? true : false,
                                                isReadonly: true,
                                                component: "text",
                                                label: t("Profession"),
                                                value: edit_state?.reg_worker?.profession?.label,
                                                placeholder: t("Select Profession"),
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                            {
                                                isActive: edit_state?.worker_type === WorkerTypeOptions({ t })?.[1]?.value ? true : false,
                                                component: "select",
                                                label: t("Profession"),
                                                value: edit_state?.profession,
                                                isReadonly: is_button_disable,
                                                placeholder: t("Select Profession"),
                                                onChange: (value) => updateState("profession", value),
                                                error: edit_state?.error?.profession,
                                                // isRequired: true,
                                                zIndex: 0,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                                isPaginate: true,
                                                loadOptions: (search, array, handleLoading) =>
                                                    loadOptions(
                                                        search,
                                                        array,
                                                        handleLoading,
                                                        GET_PROFESSION_MASTER,
                                                        "profession_master",
                                                        {
                                                            isActive: true,
                                                            client: localStorage.getItem(LocalStorageKeys.clinetID),
                                                            company_id: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.value,
                                                        },
                                                        { label: "label", value: "value" },
                                                        false,
                                                        false,
                                                    ),
                                            },
                                            {
                                                isActive: (edit_state?.worker_type === WorkerTypeOptions({ t })?.[0]?.value &&
                                                    edit_state?.reg_worker?.mobile_no) ? true : false,
                                                isReadonly: true,
                                                component: "text",
                                                label: t("Contact Number"),
                                                value: concat_string(
                                                    {
                                                        mobile_code: edit_state?.reg_worker?.mobile_country_code,
                                                        mobile_no: edit_state?.reg_worker?.mobile_no
                                                    },
                                                    ["mobile_code", "mobile_no"],
                                                    "-",
                                                ),
                                                placeholder: t("Select Contact Number"),
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                            {
                                                isActive: edit_state?.worker_type === WorkerTypeOptions({ t })?.[1]?.value ? true : false,
                                                isReadonly: is_button_disable,
                                                component: "mobile",
                                                label: t("Contact Number"),
                                                value: edit_state?.worker_contact,
                                                placeholder: t("Enter Contact Number"),
                                                onChange: (value) => updateState("worker_contact", value),
                                                error: edit_state?.error?.worker_contact,
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                                isPaginate: true,
                                                isRequired: true,
                                            },
                                            {
                                                isActive: edit_state?.worker_type === WorkerTypeOptions({ t })?.[1]?.value ? true : false,
                                                isReadonly: is_button_disable,
                                                component: "text",
                                                label: t("Alternate Company"),
                                                value: edit_state?.alternative_company,
                                                placeholder: t("Enter Alternate Company"),
                                                onChange: (e) => updateState("alternative_company", e?.target?.value),
                                                error: edit_state?.error?.alternative_company,
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                            {
                                                isActive: edit_state?.worker_type === WorkerTypeOptions({ t })?.[1]?.value ? true : false,
                                                isReadonly: is_button_disable,
                                                component: "text",
                                                label: t("Location"),
                                                value: edit_state?.worker_company_location,
                                                placeholder: t("Enter Location"),
                                                onChange: (e) => updateState("worker_company_location", e?.target?.value),
                                                error: edit_state?.error?.worker_company_location,
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.card}>
                                    <CustomTypography1>{t("CHARGES DETAILS")}</CustomTypography1>
                                    <FormGenerator t={t}
                                        components={[
                                            {
                                                isActive: true,
                                                isReadonly: is_button_disable,
                                                component: "text",
                                                type: "number",
                                                label: t("Service Charge"),
                                                value: edit_state?.service_charges,
                                                placeholder: t("Enter Service Charge"),
                                                onChange: (e) => updateState("service_charges", e?.target?.value),
                                                error: edit_state?.error?.service_charges,
                                                // isRequired: true,
                                                endAdornment: selected_company?.code,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                            {
                                                isActive: true,
                                                isReadonly: is_button_disable,
                                                component: "text",
                                                type: "number",
                                                label: t("Material Charge"),
                                                value: edit_state?.material_charges,
                                                placeholder: t("Enter Material Charge"),
                                                endAdornment: selected_company?.code,
                                                onChange: (e) => updateState("material_charges", e?.target?.value),
                                                error: edit_state?.error?.material_charges,
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                            {
                                                isActive: true,
                                                isReadonly: true,
                                                component: "text",
                                                type: "number",
                                                label: t("Total Amount"),
                                                endAdornment: selected_company?.code,
                                                value: ((edit_state?.service_charges ? parseInt(edit_state?.service_charges) : 0)
                                                    + (edit_state?.material_charges ? parseInt(edit_state?.material_charges) : 0)),
                                                placeholder: t("Enter Total Amount"),
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.card}>
                                    <CustomTypography1>{t("PAYMENT DETAILS")}</CustomTypography1>
                                    <FormGenerator t={t}
                                        components={[
                                            {
                                                isActive: true,
                                                isReadonly: is_button_disable,
                                                component: "text",
                                                type: "number",
                                                label: t("Total Paid"),
                                                value: edit_state?.total_paid,
                                                placeholder: t("Enter Total Paid"),
                                                onChange: (e) => updateState("total_paid", e?.target?.value),
                                                error: edit_state?.error?.total_paid,
                                                // isRequired: true,
                                                endAdornment: selected_company?.code,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                            {
                                                isActive: true,
                                                isReadonly: is_button_disable,
                                                component: "select",
                                                label: t("Payment Method"),
                                                value: edit_state?.payment_mode,
                                                placeholder: t("Select Payment Method"),
                                                onChange: (value) => updateState("payment_mode", value),
                                                error: edit_state?.error?.payment_mode,
                                                // isRequired: true,
                                                options: options?.payment_mode ?? [],
                                                zIndex: 0,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                }
                                            },
                                            {
                                                isActive: true,
                                                isReadonly: is_button_disable,
                                                component: "text",
                                                label: t("Paid To"),
                                                value: edit_state?.paid_to,
                                                placeholder: t("Enter Paid To"),
                                                onChange: (e) => updateState("paid_to", e?.target?.value),
                                                error: edit_state?.error?.paid_to,
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                            {
                                                isActive: true,
                                                isReadonly: is_button_disable,
                                                component: "date",
                                                label: t("Payment Date"),
                                                value: edit_state?.payment_date,
                                                placeholder: t("Enter Payment Date"),
                                                onChange: (value) => updateState("payment_date", value),
                                                error: edit_state?.error?.payment_date,
                                                isNot: true,
                                                maxDate: new Date(),
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                                height: "47px"
                                            },
                                            {
                                                isActive: true,
                                                isReadonly: is_button_disable,
                                                component: "text",
                                                label: t("Invoice Number"),
                                                value: edit_state?.invoice_no,
                                                placeholder: t("Enter Invoice Number"),
                                                onChange: (e) => updateState("invoice_no", e?.target?.value),
                                                error: edit_state?.error?.invoice_no,
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                            },
                                            {
                                                isActive: true,
                                                isReadonly: is_button_disable,
                                                component: "date",
                                                label: t("Invoice Date"),
                                                value: edit_state?.invoice_date,
                                                placeholder: t("Enter Invoice Date"),
                                                onChange: (value) => updateState("invoice_date", value),
                                                error: edit_state?.error?.invoice_date,
                                                isNot: true,
                                                maxDate: new Date(),
                                                size: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 4,
                                                },
                                                height: "47px"
                                            },
                                        ]}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.card}>
                                    <CustomTypography1>{t("WORK COMPLETION DETAILS")}</CustomTypography1>
                                    <FormGenerator t={t}
                                        components={[
                                            {
                                                isActive: true,
                                                isReadonly: is_button_disable,
                                                component: "text",
                                                label: "",
                                                multiline: true,
                                                value: edit_state?.work_completion,
                                                placeholder: t("Enter Work Completion Details"),
                                                onChange: (e) => updateState("work_completion", e?.target?.value),
                                                error: edit_state?.error?.work_completion,
                                                // isRequired: true,
                                                size: {
                                                    xs: 12,
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.card}>
                                    <CustomTypography1>{t("ATTACHMENTS")}</CustomTypography1>
                                    <UploadComponent
                                        handleChange={(val) => updateState("assets", val)}
                                        assets={assets}
                                        setAssets={setAssets}
                                        xs={4}
                                        height={"190px"}
                                        accept={"image/*,.pdf"}
                                        imageHeight={"188px"}
                                        border={true}
                                        isCustomDelete={true}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box><Divider /></Box>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={2} spacing={2}>
                        <Button className={classes1.cancel_button} disabled={is_button_disable}
                            onClick={() => handleEditClose("edit")}>
                            <Typography className={classes1.cancel_button_text}>
                                {t("Cancel")}
                            </Typography>
                        </Button>
                        <Button className={classes1.update_button} disabled={is_button_disable}
                            onClick={handleEditUpdate}>
                            <Typography className={classes1.update_button_text}>
                                {t("Update")}
                            </Typography>
                        </Button>
                    </Stack>
                </Box>
            </Drawer>
            <DialogDrawer
                open={selectedImage?.bool}
                onClose={() => setSelectedImage({
                    bool: false,
                    url: ""
                })}
                header={t("View")}
                maxWidth="md"
                height="500px"
                component={
                    <Box>
                        {
                            selectedImage?.url?.file_meta?.type === "pdf" ?
                                <Box sx={{ height: "500px", width: "100%" }}>
                                    <FileViewer fileType={"pdf"} filePath={selectedImage?.url?.url} />
                                </Box>
                                :
                                <img src={selectedImage?.url?.url} alt="" style={{ height: "500px", width: "100%", borderRadius: "8px" }} />

                        }
                    </Box>
                }
            />
        </div >
    )
}