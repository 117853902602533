import React from "react";
import { withNavBars } from "../../HOCs";
import ImportFileUpload from "./importFileUpload";

class ImportFileUploadParent extends React.Component {
  render() {
    return <ImportFileUpload {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ImportFileUploadParent, props);
