import React, { useState } from 'react';
import { LoadingSection, PropertyTabs, Subheader } from '../../components';
import { useStylesCreation } from "../propertcreation/createproperty/style";
import { useHistory } from 'react-router-dom';
import {
    VendorBasicDetails,
    PaymentAndTaxDetails,
    RegistrationDetails,
} from './tabs';
// import CityProperty from '../../assets/cityproperty';
// import ImagesProperty from '../../assets/imagesproperty';
// import ContractProperty from '../../assets/contractproperty';
import {
    listErrorState,
    constructPayload,
    GET_VENDOR_DETAILS,
    constructVendorDetails
} from './utils';
import { accessCheckRender, AlertProps, getRoutePermissionNew, LocalStorageKeys } from '../../utils';
import { AlertContext, AuthContext } from '../../contexts';
import { useApolloClient } from '@apollo/client';
import { networkCallback } from '../companyCreation/utils';
import { Routes } from '../../router/routes';
import { Box } from '@mui/material';
import { withNamespaces } from 'react-i18next';
import CityProperty from '../../assets/cityproperty';

const VendorCreation = (props) => {
    const { t } = (props);
    const { isEdit } = props;
    const VENDOR_ID = props?.match?.params?.id;
    const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
    const history = useHistory()
    const COMPANY_ID = { label: selectedCompany?.label, value: selectedCompany?.value }
    const client = useApolloClient();
    const classes = useStylesCreation();
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);
    const [mapLoad, setMapLoad] = useState(!Boolean(isEdit))
    const [value, setValue] = React.useState(1);
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)


    const tabTittle = [
        {
            label: t("Contact Details"),
            // sub: 'Contact Details',
            imgSelect: <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
    ]

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getVendor()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getVendor = () => {
        if (isEdit) {
            getVendorDetails(VENDOR_ID)
        }
    }

    const getVendorDetails = (id) => {
        setLoading('screen')
        client.query({
            query: GET_VENDOR_DETAILS,
            fetchPolicy: "network-only",
            variables: {
                id
            }
        }).then(res => {
            constructVendorDetailsData(res?.data?.vendor_master?.[0])
        }).catch(err => {
            console.log(err);
            setLoading(null);
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        })
    }

    const constructVendorDetailsData = (val) => {
        let vendorDetails = constructVendorDetails(val);
        setData(vendorDetails)
        setLoading(null);
    }

    // const tabTittle = useMemo(() => (
    //     [
    //         {
    //             label: 'Company Basic Details',
    //             sub: 'Enter Profile, Address & Contact Details',
    //             imgSelect: value > 1 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
    //             value: 1,
    //             className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
    //             selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
    //             subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
    //         },
    //         {
    //             label: 'Payment & Tax Details',
    //             sub: 'Upload assets like Image, 360 Image, Vid..',
    //             imgSelect: value > 2 ? <img src="/images/correct_icon.svg" alt='icon' /> : <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
    //             value: 2,
    //             className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
    //             selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
    //             subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
    //         },
    //         {
    //             label: 'Registration details',
    //             sub: 'Enter Authority, Utilities Details',
    //             imgSelect: value > 3 ? <img src="/images/correct_icon.svg" alt='icon' /> : <ContractProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
    //             value: 3,
    //             className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
    //             selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
    //             subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
    //         },
    //         {
    //             label: 'Assets',
    //             sub: 'Upload assets like Image, Videos',
    //             imgSelect: <ImagesProperty color={value === 4 ? '#5078E1' : '#98A0AC'} />,
    //             value: 4,
    //             className: value === 4 ? classes.tabtitle1 : classes.tabtitle,
    //             selectBack: value === 4 ? classes.selectBack1 : classes.selectBack,
    //             subtabtitle: value === 4 ? classes.subtabtitle1 : classes.subtabtitle
    //         },
    //     ]
    //     // eslint-disable-next-line
    // ), [value])

    const updateState = (stateKey, parentKey, key, value) => {
        let updateData;

        if (parentKey) {
            updateData = {
                ...data?.[stateKey], [parentKey]: {
                    ...data?.[stateKey]?.[parentKey], [key]: value
                }
            }
        }
        else {
            updateData = {
                ...data?.[stateKey], [key]: value
            }
        }

        setData({
            ...data, [stateKey]: updateData
        })

    }

    // const onChangeParticularState = (key, value) => {
    //     setData({
    //         ...data,
    //         [key]: {
    //             ...data?.[key],
    //             ...value
    //         }
    //     })
    // }

    const validation = (value) => {
        if (value === 4) return true
        let listCheck = listErrorState[value - 1];
        let stateKey = ['vendorProfile', 'paymentTaxDetails', 'registrationDetails', "contactInformation"];
        let returnCheckError = checkError(listCheck, stateKey[value - 1]);
        return !Object.keys(returnCheckError)?.length
        // return true

    }

    const checkError = (object, stateKey) => {
        let value, errorState = {};

        Object.keys(object)?.map(parentKey => {
            object?.[parentKey]?.map(key => {
                value = (key?.key === "primaryTelephone" || key?.key === "primaryMobile") ? data?.[stateKey]?.[parentKey]?.[key?.key]?.mobile : data?.[stateKey]?.[parentKey]?.[key?.key];
                let errorMsg = key?.errorMsg ?? t("This field is required");
                checkComponentError(value, errorState, stateKey, parentKey, key?.key, errorMsg)
                return key
            })
            return parentKey
        })
        //lat lng throws error is null
        if (errorState?.vendorProfile?.address?.latitude || errorState?.vendorProfile?.address?.longitude) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please choose location in map and proceed"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }
        setError(errorState)
        return errorState
    }

    const checkComponentError = (value, errorState, stateKey, parentKey, key, errorMsg) => {

        if (
            !value ||
            //to check Object
            (value?.constructor === Object && !Object?.keys(value)?.length) ||
            //to check Date
            (value?.constructor === Date && !value) ||
            //to check Array
            (value?.constructor === Array && !value?.length) ||
            //to check string
            (value?.constructor === String && !value?.trim()?.length)
        ) {
            constructErrorState(errorState, stateKey, parentKey, key, errorMsg);
        }

    }

    const constructErrorState = (errorState, stateKey, parentKey, key, errorMsg) => {
        if (!errorState[stateKey]) {
            errorState[stateKey] = {}
        }
        if (!errorState[stateKey][parentKey]) {
            errorState[stateKey][parentKey] = {}
        }
        if (!errorState[stateKey][parentKey][key]) {
            errorState[stateKey][parentKey][key] = {}
        }
        errorState[stateKey][parentKey][key]['error'] = true;
        errorState[stateKey][parentKey][key]['errorMsg'] = errorMsg;
    }

    const previous = () => {
        setValue(prevValue => prevValue - 1);
        if (!isEdit) {
            setMapLoad(false)
        }
    };

    const next = () => {
        if (validation(value)) {
            setValue(prevValue => prevValue + 1)
        }
    }

    const validate = () => {
        // let isValid = true

        // // if()

        // return isValid
        let listCheck = listErrorState[0];
        let stateKey = ['vendorProfile', 'paymentTaxDetails', 'registrationDetails'];
        let returnCheckError = checkError(listCheck, stateKey[0]);
        return !Object.keys(returnCheckError)?.length
    }

    const save = async () => {
        if (validate()) {
            setIsDisableBtn(true)
            setLoading('save')
            const { vendorProfile, paymentTaxDetails, registrationDetails, assets } = data;
            let payload = constructPayload(vendorProfile, paymentTaxDetails, registrationDetails, assets, COMPANY_ID?.value);
            if (isEdit) {
                payload.id = VENDOR_ID;
                payload.deleteAssets = assets?.deletedImages?.length ? assets?.deletedImages : [];
            }

            let result = await networkCallback(payload, 'vendor/upsert', setLoading, true);

            if (result?.data?.type === "success") {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${isEdit ? result?.data?.data : result?.data?.data?.message}`,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
                setIsDisableBtn(false)
            }
            else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Unable to create"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
                setIsDisableBtn(false)
            }
            history.push({
                pathname: Routes.vendorListing
            })
        } else {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }
    }

    const checkSave = (type) => {
        if (isEdit) {
            if (permission.update) {
                type === "previous" && previous()
                type === "next" && next()
                type === "save" && save()
            } else {
                alert.setSnack({
                    ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                    severity: AlertProps.severity.error,
                })
            }
        } else if (permission.create) {
            type === "previous" && previous()
            type === "next" && next()
            type === "save" && save()
        } else {
            alert.setSnack({
                ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                severity: AlertProps.severity.error,
            })
        }
    }
    const render = () => {
        return <>
            <Subheader
                title={isEdit ? data?.vendorProfile?.profileDetails?.vendorName : t("Create New Vendor")}
                goBack={() => history.goBack(-1)}
            // previousAndNextBtn={true}
            // previousBtn={() => checkSave("previous")}
            // nextBtn={() => checkSave("next")}
            // previousBtnDisabled={value <= 1 && true}
            // nextBtnDisabled={value === 4 ? true : false}
            />

            <PropertyTabs
                value={value}
                backgroundColor
                t={t}
                tab1={(
                    <Box className={classes.root2}>
                        {
                            loading === "screen" ? (
                                <LoadingSection
                                    bottom={"30vh"}
                                    message={t("Loading Vendor Details...")}
                                />
                            ) : (
                                <Box 
                                // sx={{ height: "calc(100vh - 200px)", overflow: "auto" }} m={2}
                                >
                                    {/* <PropertyTabs value={value} isHeigth
                                
                            )}
                            tabTittle={tabTittle}
                            showBtns={true}
                            isSaveDisable={value === 4 ? false : true}
                            saveBtnText={isEdit ? "Update" : "Submit"}
                            save={() => checkSave("save")}
                        /> */}
                                    <VendorBasicDetails
                                        data={data?.vendorProfile}
                                        error={error?.vendorProfile}
                                        updateState={(parentKey, key, value) => updateState('vendorProfile', parentKey, key, value)}
                                        mapLoad={mapLoad}
                                        companyID={COMPANY_ID}
                                        t={t}
                                    />

                                    <Box mt={2}>
                                        <PaymentAndTaxDetails
                                            data={data?.paymentTaxDetails}
                                            error={error?.paymentTaxDetails}
                                            updateState={(parentKey, key, value) => updateState('paymentTaxDetails', parentKey, key, value)}
                                            t={t}
                                        // countryID={data?.vendorProfile?.profileDetails?.vendorCountry?.value}
                                        />
                                    </Box>
                                    <Box mt={2}>
                                        <RegistrationDetails
                                            data={data?.registrationDetails}
                                            error={error?.registrationDetails}
                                            updateState={(parentKey, key, value) => updateState('registrationDetails', parentKey, key, value)}
                                            t={t}
                                        />
                                    </Box>

                                    {/* <VendorAssets
                                    isEdit={isEdit}
                                    data={data?.assets}
                                    error={error?.assets}
                                    updateState={(parentKey, key, value) => updateState('assets', parentKey, key, value)}
                                    onChangeParticularState={onChangeParticularState}
                                /> */}
                                </Box>
                            )
                        }
                    </Box>
                )}
                isHeigth
                tabTittle={tabTittle}
                showBtns={true}
                isSaveDisable={isDisableBtn}
                saveBtnText={isEdit ? t("Update") : t("Submit")}
                goBack={() => history.goBack(-1)}
                save={() => checkSave("save")}
            />


            {/* <Box className={classes.bottombtn}>
                <Button className={classes.Cancel}
                    onClick={() => history.goBack(-1)}>
                    {t("Cancel")}
                </Button>
                <Button className={classes.next} onClick={() => checkSave("save")}
                    disabled={isDisableBtn}
                >
                    {isEdit ? t("Update") : t("Submit")}
                </Button>
            </Box> */}
        </>
    }



    return (
        <div style={{ position: 'relative' }}>
            {accessCheckRender(render, permission)}
        </div>
    )
}
export default withNamespaces("vendorCreation")(VendorCreation)
