import React, { useState } from "react";
import { Subheader, FormGenerator, TextBox, SearchFilter } from "../../components";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Grid, Button, Typography, Stack, Drawer, Divider, Avatar } from "@mui/material";
// CustomTypography1
import { initial, CustomTypography, WorkerTypeOptions } from "./common";
import { useStyles } from "./style";
import { SingleImgUpload } from "./components";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
// concat_string
import { NetWorkCallMethods, AlertProps, accessCheckRender, getRoutePermissionNew, getCompanyOption, getPropertyOptions, LocalStorageKeys, enumSelect, enum_types, useWindowDimensions } from "../../utils";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import {
  GET_MAINTENANCE_CATEGORY_NEW as GET_MAINTENANCE_CATEGORY,
  GET_MAINTENANCE_SUB_CATEGORY_NEW as GET_MAINTENANCE_SUB_CATEGORY,
  SELECTUNITS_NEW as SELECTUNITS,
  GET_MAINTANCE_REQUEST,
  // SELECT_VENDOR
} from "../../graphql/queries";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { withNamespaces } from "react-i18next";
import { NonAssetIcon } from "../../assets/nonAssetIcon";
import { Add } from "@mui/icons-material";
import { TemplateAssetIcon } from "../../assets/templateAsset";
import { CloseIconWithBG, CircleIcon, DeleteIcon } from "../../assets";
import InfiniteScroll from "react-infinite-scroll-component";
// GET_DOMESTIC_HELPER_MASTER, GET_PROFESSION_MASTER,
import { loadOptionsApis } from "./utils";
import moment from "moment";
const Request = ({
  t
}) => {
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions()
  const selected_company = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
  const [state, setState] = useState({ ...initial({ t }) });
  const [images, setImages] = React.useState([]);
  const [uuid, setUUID] = React.useState(1);
  const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
  const { data } = useLocation();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})
  const [properties, setProperties] = React.useState([])
  const [slotsData, setSlotsData] = React.useState([])
  const [searchText, setSearchText] = React.useState("")
  const [options, set_options] = useState({
    work_order_status: [],
    urgent_type: [],
    payment_mode: [],
  });
  const [isDisableBtn, setIsDisableBtn] = React.useState(false)
  const [productList, setProductList] = React.useState([])
  const [offset, setOffset] = React.useState(0);
  const [product, setProduct] = React.useState({
    list: [],
    selectedProduct: {}
  })
  const [requestAgainst, setRequestAgainst] = React.useState({
    isUnit: false,
    isProduct: false
  })
  const [selectedSlot, setSelectedSlot] = React.useState("")
  const TimeIn24HrFormat = [null, null, { start: "08", end: "10" }, { start: "10", end: "12" }, { start: "12", end: "14" }, { start: "14", end: "16" }, { start: "16", end: "18" }, { start: "18", end: "20" }]

  // enum get
  const getEnum = async () => {
    const result = await enumSelect([enum_types.work_order_status, enum_types.urgent_type, enum_types.payment_mode]);
    set_options({
      work_order_status: result?.work_order_status,
      urgent_type: result?.urgent_type,
      payment_mode: result?.payment_mode,
    });
  };
  const getSlotMaster = (offset = 0, limit = 10, search = "") => {
    const payload = {
      offset: offset,
      limit: limit,
      search: search
    }
    NetworkCall(
      `${config.api_url}/time_slot_master/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (data?.main?.isEdit) getDetails(response?.data?.data);
        const result = response?.data?.data?.map((x, i) => {
          return {
            ...x,
            time: TimeIn24HrFormat[i]
          }
        })
        setSlotsData(result)
      })
      .catch((error) => {
        console.log(error)
      });
  };
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        let propertiesArray = getPropertyOptions(company)
        setProperties(propertiesArray ?? [])
        getEnum()
        getSlotMaster()
        // if (data?.main?.isEdit) getDetails();
        if (!data?.main?.isEdit) {
          let error = {
            Category: "",
            subCategory: "",
            Unit: "",
            Title: "",
            visitorDate: "",
            Description: "",
            workNo: "",
            workId: "",
            status: "",
            location: "",
            alternativeContact: "",
            PrimaryContact: "",
            Property: "",
            problemSince: "",
            contactName: "",
            member: "",
            priority: "",
            vendor_email_id: "",
            reg_worker: "",
            worker_contact_name: "",
            profession: "",
            alternative_company: "",
            worker_company_location: "",
            service_charges: "",
            material_charges: "",
            total_paid: "",
            payment_mode: "",
            paid_to: "",
            payment_date: "",
            invoice_no: "",
            invoice_date: "",
            work_completion: "",
            assigned_date: "",
            visitorTime: ""
          }
          setState({ ...state, error })
        }
      }
    }
    // eslint-disable-next-line
  }, [auth, data?.main?.id]);

  // validation
  const validateContact = () => {
    let isValid = true;
    let error = state.error;
    if (state?.contactName?.length === 0) {
      isValid = false;
      error.contactName = t("Contact Name is Required");
    }
    if (state?.PrimaryContact?.mobile?.length === 0) {
      isValid = false;
      error.PrimaryContact = t("Contact Number is Required");
    }
    // if (state?.alternativeContact?.mobile?.length === 0) {
    //   isValid = false;
    //   error.alternativeContact = "AlterNative Mobile is Required";
    // }
    if (state?.Category?.length === 0) {
      isValid = false;
      error.Category = t("Category is Required");
    }
    if (state?.Unit?.length === 0) {
      isValid = false;
      error.Unit = t("Unit is Required");
    }
    if (state?.visitorDate?.length === 0) {
      isValid = false;
      error.visitorDate = "Prefered date is Required";
    }
    if (state?.location?.length === 0) {
      isValid = false;
      error.location = t("Location is Required");
    }
    if (state?.subCategory?.length === 0) {
      isValid = false;
      error.subCategory = t("Sub category is Required");
    }
    if (state?.Title?.length === 0) {
      isValid = false;
      error.Title = t("Problem Title is Required");
    }
    if (state?.problemSince?.length === 0) {
      isValid = false;
      error.problemSince = "Problem Since is Required";
    }

    if (state?.Property?.length === 0) {
      isValid = false;
      error.Property = t("Property is Required");
    }

    if (state?.visitorTime === "") {
      isValid = false;
      error.visitorTime = t("Visit Time is Required");
    }
    if (
      state?.Category?.length === 0 ||
      state?.subCategory?.length === 0 ||
      state?.Unit?.length === 0 ||
      state?.Title?.length === 0 ||
      state?.visitorDate?.length === 0 ||
      state?.location?.length === 0 ||
      // state?.alternativeContact?.mobile?.length === 0 ||
      // state?.PrimaryContact?.mobile?.length === 0 ||
      state?.Property?.length === 0 ||
      state?.problemSince?.length === 0
      // state?.contactName?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }

    setState({ ...state, error });

    return isValid;
  };
  //   updateState
  const updateState = (key, value, k1, v1) => {
    let error = state?.error;
    error[key] = "";
    if (key === "Category") {
      setState({ ...state, subCategory: "", [key]: value, error });
    } else if (key === "Property") {
      setState({ ...state, Unit: "", [key]: value, error });
    }
    else if (key === "Unit") {
      getInspectionItems(value?.value)
      setState({ ...state, [key]: value, error });
    } else if (key === "worker_type") {
      setState({
        ...state,
        [key]: value,
        reg_worker: "",
        worker_contact_name: "",
        profession: "",
        worker_contact: { mobile: "", },
        alternative_company: "",
        worker_company_location: "",
        error
      });
    } else {
      setState({ ...state, [key]: value, [k1]: v1, error });
    }
  };
  //   handleAssetFile
  const handleAssetFile = (data) => {
    setDeletedAssetURL([...deletedAssetURL, data?.id]);
    setImages(images?.filter((val) => val?.url !== data.url));
  };

  const getInspectionItems = (unit_id, offset = 0, search = "", scroll = false) => {

    const payload = {
      unit_id: unit_id,
      search: search,
      offset: offset,
      limit: 10,
      active: null,
      item_type: ["Inventory", "Product", "Services"]
    }
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    NetworkCall(
      `${config.api_url}/inspection_item_mapping/getAll`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      if (scroll) {
        setProductList(productList?.concat(response?.data?.data));
      }
      else {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        setProductList(response?.data?.data)
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  // request creation
  const saveMaintenanceRequest = () => {
    if (validateContact()) {
      setIsDisableBtn(true)
      const payload = {
        type: 2,
        subject: state?.Title ?? null,
        description: state?.Description ?? null,
        category: state?.Category?.value ?? null,
        sub_category: state?.subCategory?.value ?? null,
        problem_since: state?.problemSince ?? null,
        preferred_time:
          state?.visitorDate ?? null,
        contact_name: state?.contactName?.length > 0 ? state?.contactName : undefined,
        contact_mobile: state?.PrimaryContact?.mobile?.length > 0 ? state?.PrimaryContact?.mobile : undefined,
        contact_alternative_mobile: state?.alternativeContact?.mobile?.length > 0 ? state?.alternativeContact?.mobile : undefined,
        work_order_ref_no: state?.workNo ? state?.workNo : null,
        work_order_date: state?.workdate ? state?.workdate : null,
        vendor_ref: state?.vendor ? state?.vendor?.value : null,
        work_order_status: state?.workerStatus ? state?.workerStatus?.value : null,
        image_urls: images?.map((x) => {
          return {
            url: x.url,
            "file_meta": {
              "file_name": x?.name ?? null,
              "file_size": x?.size ?? null,
              "file_type": x?.type ?? null
            }
          }
        }),
        units: state?.Unit?.label?.length > 0 ? [state?.Unit?.value] : null,
        userProfileId: localStorage.getItem("profileID"),
        propertyId: state?.Property?.value ?? null,
        companyId: data?.main?.company?.value ?? null,
        mobile_country_code: state?.PrimaryContact?.mobile_code?.length > 0 ? state?.PrimaryContact?.mobile_code : undefined,
        is_active: state?.status,
        inspection_mapping_id: product?.selectedProduct?.id ?? null,
        location_id: state?.location?.label?.length > 0 ? state?.location?.value : null,
        priority: state?.priority,
        vendor_email_id: state?.vendor_email_id,
        reg_worker_id: state?.worker_type === "registered" ? state?.reg_worker?.value : null,
        worker_contact_name: state?.worker_type === "unregistered" ? state?.worker_contact_name : null,
        profession_id: state?.worker_type === "unregistered" ? state?.profession?.value : null,
        worker_contact_no: state?.worker_type === "unregistered" ? state?.worker_contact?.mobile : null,
        worker_contact_country_code: state?.worker_type === "unregistered" ? state?.worker_contact?.mobile_code : null,
        alternative_company: state?.worker_type === "unregistered" ? state?.alternative_company : null,
        location: state?.worker_type === "unregistered" ? state?.worker_company_location : null,
        service_charges: state?.service_charges ? parseInt(state?.service_charges) : 0,
        material_charges: state?.material_charges ? parseInt(state?.material_charges) : 0,
        total_amount: (state?.service_charges ? parseInt(state?.service_charges) : 0) + (state?.material_charges ? parseInt(state?.material_charges) : 0),
        total_paid: (state?.total_paid ? parseInt(state?.total_paid) : 0),
        currency_id: selected_company?.currency_id,
        payment_mode: state?.payment_mode?.value,
        paid_to: state?.paid_to,
        payment_date: state?.payment_date ?? null,
        invoice_no: state?.invoice_no,
        invoice_date: state?.invoice_date ?? null,
        work_completion: state?.work_completion ?? null,
        assigned_date: state?.assigned_date ?? null,
        slot_id: state?.visitorTime?.value ?? null,
        slot_name: state?.visitorTime?.label ?? null
      };
      if (data?.main?.isEdit) {
        payload["id"] = data?.main?.id;
        payload["deleteAssets"] = deletedAssetURL;
      }
      NetworkCall(
        `${config.api_url}/request/${data?.main?.isEdit === true ? "update" : "create"
        }`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          history.push(Routes?.maintancerequest);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Maintenance Request ${data?.main?.isEdit === true ? "Updated" : "Created"
              } successfully.`,
          });
          setIsDisableBtn(false)
        })
        .catch((error) => {
          setIsDisableBtn(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something Went Wrong"),
          });
          setIsDisableBtn(false)
        });
    }
  };
  // get details
  const getDetails = (slotOptions = []) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const payload = {
      query: GET_MAINTANCE_REQUEST,
      variables: {
        generalRequestNo: data?.main?.id,
        maintenanceRequestNo: data?.main?.id,
        referenceId: data?.main?.id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let result = response?.data?.data;
        let image = result?.images?.map((x) => {
          return {
            url: x?.url,
            size: x?.file_meta?.file_size ?? "-",
            name: x?.file_meta?.file_name ?? '-',
            type: x?.file_meta?.file_type ?? "-",
            id: x?.id ?? ""
          }
        })
        setImages(image ?? []);
        setState({
          Category: {
            value: result?.table?.[0]?.catagory?.id ?? "",
            label: result?.table?.[0]?.catagory?.type ?? "",
          },
          subCategory: {
            value: result?.table?.[0]?.subcatagory?.id ?? "",
            label: result?.table?.[0]?.subcatagory?.type ?? "",
          },
          Unit: {
            value: result?.table?.[0]?.unit?.[0].unit?.id ?? "",
            label: result?.table?.[0]?.unit?.[0].unit?.name ?? "",
            unit_no: result?.table?.[0]?.unit?.[0].unit?.unit_no
          },
          Title: result?.table?.[0]?.subject ?? "",
          visitorDate: new Date(result?.table?.[0]?.prefferedtime) ?? "",
          Description: result?.table?.[0]?.description ?? "",
          alternativeContact: {
            mobile: result?.table?.[0]?.alternative ?? "",
            mobile_code: result?.table?.[0]?.mobile_country_code ?? "+91",
          },
          PrimaryContact: {
            mobile: result?.table?.[0]?.mobile ?? "",
            mobile_code: result?.table?.[0]?.mobile_country_code ?? "+91",

          },
          location: result?.table?.[0]?.location?.id ? {
            value: result?.table?.[0]?.location?.id ?? "",
            label: result?.table?.[0]?.location?.name ?? ""
          } : null,
          Property: {
            value: result?.table?.[0]?.unit?.[0].property?.id ?? "",
            label: result?.table?.[0]?.unit?.[0].property?.name ?? "",
          },
          problemSince: new Date(result?.table?.[0]?.problemsince),
          contactName: result?.table?.[0]?.name ?? "",
          status: result?.table?.[0]?.is_active ?? "",
          workdate: result?.table?.[0]?.work_order_date ? new Date(result?.table?.[0]?.work_order_date) : null,
          vendor: result?.table?.[0]?.vendor_ref ?? "",
          workerStatus: result?.table?.[0]?.work_order_status ? {
            value: result?.table?.[0]?.work_order_status,
            label: result?.table?.[0]?.work_order_status
          } : "",
          workNo: result?.table?.[0]?.work_order_ref_no ?? "",
          priority: result?.table?.[0]?.priority ?? "medium",
          vendor_email_id: result?.table?.[0]?.vendor_email_id ?? "",
          worker_type: result?.table?.[0]?.registered_worker?.value
            ? WorkerTypeOptions({ t })?.[0]?.value
            : WorkerTypeOptions({ t })?.[1]?.value,
          reg_worker: result?.table?.[0]?.registered_worker?.value ? result?.table?.[0]?.registered_worker : "",
          worker_contact_name: result?.table?.[0]?.worker_contact_name ?? "",
          profession: result?.table?.[0]?.profession?.value ? result?.table?.[0]?.profession : "",
          worker_contact: {
            mobile: result?.table?.[0]?.worker_contact_no ?? "",
            mobile_code: result?.table?.[0]?.worker_contact_country_code ?? "+91",
          },
          alternative_company: result?.table?.[0]?.alternative_company ?? "",
          worker_company_location: result?.table?.[0]?.worker_company_location ?? "",
          service_charges: result?.table?.[0]?.service_charges?.toString() ?? "",
          material_charges: result?.table?.[0]?.material_charges?.toString() ?? "",
          total_paid: result?.table?.[0]?.total_paid?.toString() ?? "",
          payment_mode: result?.table?.[0]?.payment_mode
            ? {
              value: result?.table?.[0]?.payment_mode,
              label: result?.table?.[0]?.payment_mode
            }
            : "",
          paid_to: result?.table?.[0]?.paid_to ?? "",
          payment_date: result?.table?.[0]?.payment_date ? new Date(result?.table?.[0]?.payment_date) : null,
          invoice_no: result?.table?.[0]?.invoice_no ?? "",
          invoice_date: result?.table?.[0]?.invoice_date ? new Date(result?.table?.[0]?.invoice_date) : null,
          work_completion: result?.table?.[0]?.work_completion,
          assigned_date: result?.table?.[0]?.assigned_date ? new Date(result?.table?.[0]?.assigned_date) : null,
          slot_id: result?.table?.[0]?.slot_id,
          visitorTime: slotOptions?.filter((x) => { return x?.value === result?.table?.[0]?.slot_id })?.[0] ?? "",
          error: {
            Category: "",
            subCategory: "",
            Unit: "",
            Title: "",
            visitorDate: "",
            Description: "",
            workNo: "",
            workId: "",
            status: "",
            location: "",
            alternativeContact: "",
            PrimaryContact: "",
            Property: "",
            problemSince: "",
            contactName: "",
            member: "",
            priority: "",
            vendor_email_id: "",
            reg_worker: "",
            worker_contact_name: "",
            profession: "",
            alternative_company: "",
            worker_company_location: "",
            service_charges: "",
            material_charges: "",
            total_paid: "",
            payment_mode: "",
            paid_to: "",
            payment_date: "",
            invoice_no: "",
            invoice_date: "",
            work_completion: "",
            assigned_date: "",
            visitorTime: ""
          },
        });
        setRequestAgainst({ isUnit: result?.table?.[0]?.inspection_item_mapping === null, isProduct: result?.table?.[0]?.inspection_item_mapping?.name?.length > 0 })
        setProduct({ ...product, selectedProduct: { ...result?.table?.[0]?.inspection_item_mapping, manufaturer_name: result?.table?.[0]?.inspection_item_mapping?.manufacturer?.[0]?.name, location_name: result?.table?.[0]?.location?.name } })
        getInspectionItems(result?.table?.[0]?.unit?.[0].unit?.id)
        setSelectedSlot(slotOptions?.filter((x) => { return x?.value === result?.table?.[0]?.slot_id })?.[0] ?? "")
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong please try again",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const handleSearch = (value) => {
    setSearchText(value)
    getInspectionItems(state?.Unit?.value, offset, value, false);
  }
  const handleSelectProduct = (value) => {
    setProduct({ ...product, selectedProduct: value })
    updateState("location", value?.location_id !== null ? { value: value?.location_id, label: value?.location_name } : null)
  }
  const fetchMoreData = () => {
    setOffset(offset + 10)
    getInspectionItems(state?.Unit?.value, offset + 10, "", true);
  }
  const handleProduct = (type) => {
    if (type === "add") {
      setProduct({ ...product, bool: false })
      setRequestAgainst({ ...requestAgainst, isProduct: true })
    }
    else {
      setRequestAgainst({ ...requestAgainst, isProduct: false })
      setProduct({ ...product, selectedProduct: "" })
      updateState("location", "")
    }
  }

  // const manualDomesticHelperMasterOption = (array) => {
  //   const details = array?.map(i => i)
  //   return details;
  // };
  const handleChangeSlot = (val) => {
    if (val?.time !== null && moment(state?.visitorDate).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
      const currentTime = moment(new Date())
      let fromTime = new Date(currentTime).setHours(val?.time?.start, 0, 0, 0);
      let toTime = (new Date(currentTime)).setHours(val?.time?.end, 0, 0, 0);
      if ((fromTime <= currentTime && currentTime <= toTime) || fromTime >= currentTime) {
        setSelectedSlot(val)
      } else {
        setSelectedSlot("")
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please choose visit time greater than current time"),
        });
      }
    }
    else {
      setSelectedSlot(val)
    }
  }
  const render = () => {
    return <>
      <Subheader
        title={`${data?.main?.isEdit ? t("Edit") : t("Create")} ${t("Maintenance Request")}`}
        goBack={() => {
          history.goBack(-1);
        }}
      />
      <Box className={classes.root1}>
        <Grid container spacing={3}>
          {/*  SERVICE DETAILS*/}
          <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>{t("SERVICE DETAILS")}</CustomTypography>
              <FormGenerator
                t={t}
                components={[
                  {
                    isActive: true,
                    component: "select",
                    label: t("Property"),
                    value: state?.Property,
                    placeholder: t("Property"),
                    onChange: (value) => updateState("Property", value),
                    error: state?.error?.Property,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    options: properties
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Unit"),
                    value: state?.Unit,
                    placeholder: t("Unit"),
                    onChange: (value) => updateState("Unit", value),
                    error: state?.error?.Unit,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        SELECTUNITS,
                        "unit",
                        { isActive: true },
                        {},
                        false,
                        false,
                        state?.Property?.value
                      ),
                    key: JSON.stringify(state?.Property),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Category"),
                    value: state?.Category,
                    placeholder: t("Category"),
                    onChange: (value) => updateState("Category", value),
                    error: state?.error?.Category,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        GET_MAINTENANCE_CATEGORY,
                        "maintenance_category_master",
                        { isActive: true },
                        { label: "label", value: "value" },
                        false,
                        false
                      ),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Sub-Category"),
                    value: state?.subCategory,
                    placeholder: t("Sub-Category"),
                    onChange: (value) => updateState("subCategory", value),
                    error: state?.error?.subCategory,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptions(
                        search,
                        array,
                        handleLoading,
                        GET_MAINTENANCE_SUB_CATEGORY,
                        "maintenance_sub_category_master",
                        { isActive: true },
                        { label: "label", value: "value" },
                        false,
                        false,
                        state?.Category?.value ?? ""
                      ),
                    key: JSON.stringify(state?.Category),
                  },
                  {
                    isActive: true,
                    component: "text",
                    label: t("Problem Title"),
                    value: state?.Title,
                    placeholder: t("Problem Title"),
                    onChange: (e) => updateState("Title", e?.target?.value),
                    error: state?.error?.Title,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 6,
                    },
                    isPaginate: true,
                  },

                  {
                    isActive: true,
                    component: "date",
                    label: t("Problem Since"),
                    value: state?.problemSince,
                    placeholder: t("Problem Since"),
                    onChange: (value) => updateState("problemSince", value),
                    error: state?.error?.problemSince,
                    maxDate: new Date(),
                    isRequired: true,
                    isNot: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    // height: "40%"
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Asset Location"),
                    value: state?.location,
                    placeholder: t("Select Location"),
                    onChange: (value) => updateState("location", value),
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "inspection_item_location_master",
                        {},
                        search,
                        array,
                        handleLoading,
                        "list",
                        {}
                      ),
                    error: state?.error?.location,
                    isRequired: true,
                    isReadonly: product?.selectedProduct?.name?.length > 0 && product?.selectedProduct?.location_id !== null ? true : false
                  },
                  {
                    isActive: true,
                    component: "date",
                    label: t("Preferred Visit Date"),
                    value: state?.visitorDate,
                    placeholder: t("Choose Preferred Visit Date"),
                    onChange: (value) => updateState("visitorDate", value, "visitorTime", ""),
                    error: state?.error?.visitorDate,
                    minDate: new Date(),
                    isRequired: true,
                    is_popover: true,
                    border: '1.5px solid #E4E8EE !important',
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                  },
                  {
                    isActive: true,
                    component: "timeSlot",
                    label: t("Preferred Visit Time"),
                    value: state?.visitorTime,
                    placeholder: t("Choose Preferred Time"),
                    onChange: (value) => updateState("visitorTime", value),
                    isRequired: true,
                    options: slotsData,
                    handleChange: (value) => handleChangeSlot(value),
                    selectedSlot: selectedSlot,
                    error: state?.error?.visitorTime,
                    border: '1.5px solid #E4E8EE !important',
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3.5,
                    },
                  },
                  {
                    size: {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 2.5,
                    },
                    isActive: true,
                    component: "priority",
                    // label: t("Priority"),
                    value: state?.priority,
                    placeholder: t("Priority"),
                    onChange: (value) => updateState("priority", value?.value),
                    error: state?.error?.priority,
                    isRequired: true,
                    options: options?.urgent_type,
                    // menuPlacement:"top"
                  },
                  {
                    size: {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 3,
                    },
                    isActive: true,
                    component: "toggle",
                    label: t("Status"),
                    value: state?.status,
                    placeholder: t("Status"),
                    onChange: (value) => updateState("status", value),
                    error: state?.error?.status,
                    isRequired: true,
                    options: [
                      { label: t("Active"), value: true },
                      { label: t("Inactive"), value: false },
                    ],
                  },
                  // {
                  //   isActive: true,
                  //   component: "text",
                  //   label: t("Problem Description"),
                  //   value: state?.Description,
                  //   multiline:true,
                  //   placeholder: t("Problem Description"),
                  //   onChange: (e) =>
                  //     updateState("Description", e?.target?.value),
                  //   error: state?.error?.Description,
                  //   size: {
                  //     xs: 12,
                  //     sm: 12,
                  //     md: 6,
                  //     lg: 6,
                  //   },
                  //   isPaginate: true,
                  // },
                ]}
              />
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextBox
                      label={t("Problem Description")}
                      placeholder={t("Problem Description")}
                      onChange={(e) => updateState("Description", e?.target?.value)}
                      value={state?.Description}
                      multiline={true}
                      rowheight={2.5}
                    />
                  </Grid>
                  {(requestAgainst?.isUnit && state?.Unit?.value) ?
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Typography className={classes.reqagainstlabel}>{t("Request Against")}</Typography>
                      <Box mt={1} display={"flex"} justifyContent={"space-between"} className={classes.requestAgainstBox} alignItems={"center"}>
                        <Stack direction="row" spacing={2} alignItems={"center"}>
                          <NonAssetIcon />
                          <Box style={{ marginInlineStart: "8px" }}>
                            <Typography className={classes.unittitle}>{state?.Unit?.label}</Typography>
                            <Typography className={classes.subTitle}>{state?.Unit?.unit_no}</Typography>
                          </Box>
                        </Stack>
                        <Box style={{ cursor: "pointer" }} onClick={() => setRequestAgainst({ ...requestAgainst, isUnit: false })}><DeleteIcon /></Box>
                      </Box>
                    </Grid>
                    : requestAgainst?.isProduct ?
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography className={classes.reqagainstlabel}>{t("Request Against")}</Typography>
                        <Box mt={1} display={"flex"} justifyContent={"space-between"} className={classes.requestAgainstBox} alignItems={"center"}>
                          <Stack direction="row" spacing={2} alignItems={"center"}>
                            {product?.selectedProduct?.assets?.length > 0 ?
                              <Avatar src={JSON.parse(product?.selectedProduct?.assets)?.[0]?.url} variant="square" className={classes.avatar} />
                              : <TemplateAssetIcon />}
                            <Box style={{ marginInlineStart: "8px" }}>
                              <Typography className={classes.unittitle}>{product?.selectedProduct?.name}</Typography>
                              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                {product?.selectedProduct?.location_name !== null &&
                                  <>
                                    <Typography className={classes.productSubTitle} noWrap>{product?.selectedProduct?.location_name ?? "-"}</Typography>
                                    <Box className={classes.dot}></Box></>
                                }
                                {product?.selectedProduct?.manufaturer_name !== null &&
                                  <>
                                    <Typography className={classes.productSubTitle} noWrap>{product?.selectedProduct?.manufaturer_name ?? "-"}</Typography>
                                    <Box className={classes.dot}></Box></>
                                }{product?.selectedProduct?.serial_number !== null &&
                                  <>
                                    <Typography className={classes.productSubTitle} noWrap>{product?.selectedProduct?.serial_number ?? "-"}</Typography>
                                    <Box className={classes.dot}></Box>
                                  </>
                                }
                                {product?.selectedProduct?.model_number !== null &&
                                  <>
                                    <Typography className={classes.productSubTitle} noWrap>{product?.selectedProduct?.model_number ?? "-"}</Typography>
                                    <Box className={classes.dot}></Box>
                                  </>
                                }
                                {product?.selectedProduct?.item_condition !== null &&
                                  <>
                                    <Typography className={classes.itemCondition} noWrap>{product?.selectedProduct?.item_condition ?? "-"}</Typography>
                                  </>
                                }
                              </Stack>
                            </Box>
                          </Stack>
                          <Box style={{ cursor: "pointer" }} onClick={() => handleProduct("delete")}><DeleteIcon /></Box>
                        </Box>
                      </Grid>
                      :
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography className={classes.reqagainstlabel}>{t("Request Against")}</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box mt={1} display={"flex"} justifyContent={"space-between"} className={classes.requestAgainstBox} alignItems={"center"}>
                              <Stack direction="row" spacing={2} alignItems={"center"}>
                                <NonAssetIcon />
                                <Box style={{ marginInlineStart: "8px" }}>
                                  <Typography className={classes.unittitle}>{t("Unit Level")}</Typography>
                                  <Typography className={classes.subTitle}>{t("For non-asset item")}</Typography>
                                </Box>
                              </Stack>
                              <Box style={{ cursor: "pointer" }}><Add color="primary" onClick={() => setRequestAgainst({ ...requestAgainst, isUnit: state?.Unit?.value ? true : false })} /></Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box mt={1} display={"flex"} justifyContent={"space-between"} className={classes.requestAgainstBox} alignItems={"center"}>
                              <Stack direction="row" spacing={2} alignItems={"center"}>
                                <TemplateAssetIcon />
                                <Box style={{ marginInlineStart: "8px" }}>
                                  <Typography className={classes.unittitle}>{t("Asset / item from unit")}</Typography>
                                  <Typography className={classes.subTitle}>{t("Eg: AC, TV, Light etc")}</Typography>
                                </Box>
                              </Stack>
                              <Box style={{ cursor: "pointer" }} onClick={() => setProduct({ ...product, bool: true })}><Add color="primary" /></Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                  }
                </Grid>
              </Box>
              {/* <Box mt={2}> */}
              {/* <FormGenerator
                  components={[
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 3,
                      },
                      isActive: true,
                      component: "priority",
                      // label: t("Priority"),
                      value: state?.priority,
                      placeholder: t("Priority"),
                      onChange: (value) => updateState("priority", value?.value),
                      error: state?.error?.priority,
                      isRequired: true,
                      options: options?.urgent_type,
                      // menuPlacement:"top"
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 3,
                      },
                      isActive: true,
                      component: "toggle",
                      label: t("Status"),
                      value: state?.status,
                      placeholder: t("Status"),
                      onChange: (value) => updateState("status", value),
                      error: state?.error?.status,
                      isRequired: true,
                      options: [
                        { label: t("Active"), value: true },
                        { label: t("Inactive"), value: false },
                      ],
                    },
                  ]}></FormGenerator> */}
              {/* </Box> */}
            </div>
          </Grid>
          {/* CONTACT*/}
          <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>{t("CONTACT")}</CustomTypography>
              <FormGenerator
                t={t}
                components={[
                  {
                    isActive: true,
                    component: "text",
                    label: t("Contact Name"),
                    value: state?.contactName,
                    placeholder: t("Contact Name"),
                    onChange: (e) =>
                      updateState("contactName", e?.target?.value),
                    error: state?.error?.contactName,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    isRequired: true,
                  },
                  {
                    isActive: true,
                    component: "mobile",
                    label: t("Primary Contact"),
                    value: state?.PrimaryContact,
                    placeholder: t("Primary Contact"),
                    onChange: (value) => updateState("PrimaryContact", value),
                    error: state?.error?.PrimaryContact,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    isRequired: true,
                  },
                  {
                    isActive: true,
                    component: "mobile",
                    label: t("Alternate Contact Number"),
                    value: state?.alternativeContact,
                    placeholder: t("Alternate Contact Number"),
                    onChange: (value) =>
                      updateState("alternativeContact", value),
                    errorMessage: state?.error?.alternativeContact,
                    // isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                  },
                ]}
              />
            </div>
          </Grid>
          {/* WORK ORDER (OPTIONAL) */}
          {/* <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className={classes.card}>
                  <CustomTypography>{t("3RD PARTY TECHNICAL ASSISTANCE")}</CustomTypography>
                  <CustomTypography1>{t("BASIC DETAILS")}</CustomTypography1>
                  <FormGenerator
                    t={t}
                    components={[
                      {
                        isActive: true,
                        component: "datePickerWithTime",
                        label: t("Assigned Date & Time"),
                        value: state?.assigned_date,
                        placeholder: t("Assigned Date & Time"),
                        onChange: (value) => updateState("assigned_date", value),
                        error: state?.error?.assigned_date,
                        maxDate: new Date(),
                        is_popover: true,
                        border: '1.5px solid #E4E8EE !important',
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                      {
                        isActive: true,
                        component: "text",
                        label: t("Reference Ticket Number"),
                        value: state?.workNo,
                        placeholder: t("Enter Reference Ticket Number"),
                        onChange: (e) => updateState("workNo", e?.target?.value),
                        error: state?.error?.workNo,
                        // isRequired: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                        isPaginate: true,
                      },
                      {
                        isActive: true,
                        component: "date",
                        label: t("Status Date"),
                        value: state?.workdate,
                        placeholder: t("Select Status Date"),
                        onChange: (value) => updateState("workdate", value),
                        error: state?.error?.workdate,
                        maxDate: new Date(),
                        // isRequired: true,
                        isNot: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                        height: "47%"
                      },
                      {
                        isActive: true,
                        component: "select",
                        label: t("Status"),
                        menu_text_transfrom: "capitalize",
                        value: state?.workerStatus,
                        placeholder: t("Select Status"),
                        onChange: (value) => updateState("workerStatus", value),
                        error: state?.error?.workerStatus,
                        // isRequired: true,
                        options: options?.work_order_status ?? [],
                        zIndex: 0,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        }
                      },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.card}>
                  <CustomTypography1>{t("VENDOR DETAILS")}</CustomTypography1>
                  <FormGenerator
                    t={t}
                    components={[
                      {
                        isActive: true,
                        component: "select",
                        label: t("Registered Vendor"),
                        value: state?.vendor,
                        placeholder: t("Select Registered Vendor"),
                        onChange: (value) => updateState("vendor", value),
                        error: state?.error?.vendor,
                        // isRequired: true,
                        zIndex: 0,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                        isPaginate: true,
                        loadOptions: (search, array, handleLoading) =>
                          loadOptions(
                            search,
                            array,
                            handleLoading,
                            SELECT_VENDOR,
                            "vendor_master",
                            {
                              isActive: true,
                              client: localStorage.getItem(LocalStorageKeys.clinetID),
                              company_id: selected_company?.value,
                            },
                            { label: "label", value: "value" },
                            false,
                            false
                          ),
                      },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.card}>
                  <CustomTypography1>{t("WORKER DETAILS")}</CustomTypography1>
                  <FormGenerator
                    t={t}
                    components={[
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 3,
                        },
                        isActive: true,
                        component: "toggle",
                        label: t("Worker Type"),
                        value: state?.worker_type,
                        onChange: (value) => updateState("worker_type", value),
                        error: state?.error?.worker_type,
                        options: WorkerTypeOptions({ t }),
                      },
                      {
                        isActive: state?.worker_type === "registered",
                        component: "select",
                        label: t("Profession"),
                        value: state?.profession,
                        placeholder: t("Select Profession"),
                        onChange: (value) => updateState("profession", value),
                        error: state?.error?.profession,
                        // isRequired: true,
                        zIndex: 0,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                        isPaginate: true,
                        loadOptions: (search, array, handleLoading) =>
                          loadOptions(
                            search,
                            array,
                            handleLoading,
                            GET_PROFESSION_MASTER,
                            "profession_master",
                            {
                              isActive: true,
                              client: localStorage.getItem(LocalStorageKeys.clinetID),
                              company_id: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.value,
                            },
                            { label: "label", value: "value" },
                            false,
                            false,
                          ),
                      },
                      {
                        isActive: state?.worker_type === WorkerTypeOptions({ t })?.[0]?.value ? true : false,
                        component: "select",
                        label: t("Registered Worker"),
                        value: state?.reg_worker,
                        placeholder: t("Select Registered Worker"),
                        onChange: (value) => {
                          setState({
                            ...state,
                            reg_worker: value,
                            vendor_email_id: value?.domestic_helper_address?.[0]?.email_id
                          })
                        },
                        error: state?.error?.reg_worker,
                        // isRequired: true,
                        zIndex: 0,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                        isPaginate: true,
                        key: JSON.stringify(state?.profession),
                        loadOptions: (search, array, handleLoading) =>
                          loadOptions(
                            search,
                            array,
                            handleLoading,
                            GET_DOMESTIC_HELPER_MASTER,
                            "domestic_helper_master",
                            {
                              isActive: true,
                              client: localStorage.getItem(LocalStorageKeys.clinetID),
                              company_id: selected_company?.value,
                              profession_id: state?.profession?.value
                            },
                            {},
                            false,
                            manualDomesticHelperMasterOption,
                          ),
                      },
                      {
                        isActive: state?.worker_type === WorkerTypeOptions({ t })?.[1]?.value ? true : false,
                        component: "text",
                        label: t("Contact Name"),
                        value: state?.worker_contact_name,
                        placeholder: t("Contact Name"),
                        onChange: (e) => updateState("worker_contact_name", e?.target?.value),
                        error: state?.error?.worker_contact_name,
                        // isRequired: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                      {
                        isActive: true,
                        component: "text",
                        label: t("Email"),
                        value: state?.vendor_email_id,
                        placeholder: t("Enter Email"),
                        onChange: (e) => updateState("vendor_email_id", e?.target?.value),
                        error: state?.error?.vendor_email_id,
                        isReadonly: state?.worker_type === "registered",
                        // isRequired: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                      {
                        isActive: (state?.worker_type === WorkerTypeOptions({ t })?.[0]?.value &&
                          state?.reg_worker?.profession?.label) ? true : false,
                        isReadonly: true,
                        component: "text",
                        label: t("Profession"),
                        value: state?.reg_worker?.profession?.label,
                        placeholder: t("Select Profession"),
                        // isRequired: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                      {
                        isActive: state?.worker_type === WorkerTypeOptions({ t })?.[1]?.value ? true : false,
                        component: "select",
                        label: t("Profession"),
                        value: state?.profession,
                        placeholder: t("Select Profession"),
                        onChange: (value) => updateState("profession", value),
                        error: state?.error?.profession,
                        // isRequired: true,
                        zIndex: 0,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                        isPaginate: true,
                        loadOptions: (search, array, handleLoading) =>
                          loadOptions(
                            search,
                            array,
                            handleLoading,
                            GET_PROFESSION_MASTER,
                            "profession_master",
                            {
                              isActive: true,
                              client: localStorage.getItem(LocalStorageKeys.clinetID),
                              company_id: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.value,
                            },
                            { label: "label", value: "value" },
                            false,
                            false,
                          ),
                      },
                      {
                        isActive: (state?.worker_type === WorkerTypeOptions({ t })?.[0]?.value &&
                          state?.reg_worker?.mobile_no) ? true : false,
                        isReadonly: true,
                        component: "text",
                        label: t("Contact Number"),
                        value: concat_string(
                          {
                            mobile_code: state?.reg_worker?.mobile_country_code,
                            mobile_no: state?.reg_worker?.mobile_no
                          },
                          ["mobile_code", "mobile_no"],
                          "-",
                        ),
                        placeholder: t("Select Contact Number"),
                        // isRequired: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                      {
                        isActive: state?.worker_type === WorkerTypeOptions({ t })?.[1]?.value ? true : false,
                        component: "mobile",
                        label: t("Contact Number"),
                        value: state?.worker_contact,
                        placeholder: t("Enter Contact Number"),
                        onChange: (value) => updateState("worker_contact", value),
                        error: state?.error?.worker_contact,
                        // isRequired: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                        isPaginate: true,
                        isRequired: true,
                      },
                      {
                        isActive: state?.worker_type === WorkerTypeOptions({ t })?.[1]?.value ? true : false,
                        component: "text",
                        label: t("Alternate Company"),
                        value: state?.alternative_company,
                        placeholder: t("Enter Alternate Company"),
                        onChange: (e) => updateState("alternative_company", e?.target?.value),
                        error: state?.error?.alternative_company,
                        // isRequired: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                      {
                        isActive: state?.worker_type === WorkerTypeOptions({ t })?.[1]?.value ? true : false,
                        component: "text",
                        label: t("Location"),
                        value: state?.worker_company_location,
                        placeholder: t("Enter Location"),
                        onChange: (e) => updateState("worker_company_location", e?.target?.value),
                        error: state?.error?.worker_company_location,
                        // isRequired: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.card}>
                  <CustomTypography1>{t("CHARGES DETAILS")}</CustomTypography1>
                  <FormGenerator  
                    t={t}
                    components={[
                      {
                        isActive: true,
                        component: "text",
                        type: "number",
                        label: t("Service Charge"),
                        value: state?.service_charges,
                        placeholder: t("Enter Service Charge"),
                        onChange: (e) => updateState("service_charges", e?.target?.value),
                        error: state?.error?.service_charges,
                        // isRequired: true,
                        endAdornment: selected_company?.code,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                      {
                        isActive: true,
                        component: "text",
                        type: "number",
                        label: t("Material Charge"),
                        value: state?.material_charges,
                        placeholder: t("Enter Material Charge"),
                        endAdornment: selected_company?.code,
                        onChange: (e) => updateState("material_charges", e?.target?.value),
                        error: state?.error?.material_charges,
                        // isRequired: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                      {
                        isActive: true,
                        isReadonly: true,
                        component: "text",
                        type: "number",
                        label: t("Total Amount"),
                        endAdornment: selected_company?.code,
                        value: ((state?.service_charges ? parseInt(state?.service_charges) : 0)
                          + (state?.material_charges ? parseInt(state?.material_charges) : 0)),
                        placeholder: t("Enter Total Amount"),
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.card}>
                  <CustomTypography1>{t("PAYMENT DETAILS")}</CustomTypography1>
                  <FormGenerator
                    t={t}
                    components={[
                      {
                        isActive: true,
                        component: "text",
                        type: "number",
                        label: t("Total Paid"),
                        value: state?.total_paid,
                        placeholder: t("Enter Total Paid"),
                        onChange: (e) => updateState("total_paid", e?.target?.value),
                        error: state?.error?.total_paid,
                        // isRequired: true,
                        endAdornment: selected_company?.code,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                      {
                        isActive: true,
                        component: "select",
                        label: t("Payment Method"),
                        value: state?.payment_mode,
                        placeholder: t("Select Payment Method"),
                        onChange: (value) => updateState("payment_mode", value),
                        error: state?.error?.payment_mode,
                        // isRequired: true,
                        options: options?.payment_mode ?? [],
                        zIndex: 0,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        }
                      },
                      {
                        isActive: true,
                        component: "text",
                        label: t("Paid To"),
                        value: state?.paid_to,
                        placeholder: t("Enter Paid To"),
                        onChange: (e) => updateState("paid_to", e?.target?.value),
                        error: state?.error?.paid_to,
                        // isRequired: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                      {
                        isActive: true,
                        component: "date",
                        label: t("Payment Date"),
                        value: state?.payment_date,
                        placeholder: t("Enter Payment Date"),
                        onChange: (value) => updateState("payment_date", value),
                        error: state?.error?.payment_date,
                        isNot: true,
                        maxDate: new Date(),
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                        height: "47%"
                      },
                      {
                        isActive: true,
                        component: "text",
                        label: t("Invoice Number"),
                        value: state?.invoice_no,
                        placeholder: t("Enter Invoice Number"),
                        onChange: (e) => updateState("invoice_no", e?.target?.value),
                        error: state?.error?.invoice_no,
                        // isRequired: true,
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                      },
                      {
                        isActive: true,
                        component: "date",
                        label: t("Invoice Date"),
                        value: state?.invoice_date,
                        placeholder: t("Enter Invoice Date"),
                        onChange: (value) => updateState("invoice_date", value),
                        error: state?.error?.invoice_date,
                        isNot: true,
                        maxDate: new Date(),
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 6,
                          lg: 3,
                        },
                        height: "47%"
                      },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.card}>
                  <CustomTypography1>{t("WORK COMPLETION")}</CustomTypography1>
                  <FormGenerator
                    t={t}
                    components={[
                      {
                        isActive: true,
                        component: "text",
                        label: "",
                        multiline: true,
                        value: state?.work_completion,
                        placeholder: t("Enter Work Completion Details"),
                        onChange: (e) => updateState("work_completion", e?.target?.value),
                        error: state?.error?.work_completion,
                        // isRequired: true,
                        size: {
                          xs: 12,
                        },
                      },
                    ]}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid> */}

          <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>{t("UPLOAD IMAGES")}</CustomTypography>
              <SingleImgUpload
                setUUID={setUUID}
                uuid={uuid}
                updateImageURL={setImages}
                handleFile={handleAssetFile}
                selectedImageURL={images}
                companyId={1}
                t={t}
              />
            </div>
          </Grid>

          {/* AddMemberCard */}
          {/* <Grid item xs={12}>
            <AddMemberCard
              data={{
                name: "Assign To",
                btnText: "Add Member",
              }}
              selectedMember={state?.member ?? ""}
              updateState={updateState}
            />
          </Grid> */}
        </Grid>
      </Box>

      {/* buttom btn */}
      <Box className={classes.bottombtn}>
        <Box>
          <Button
            className={classes.Cancel}
            onClick={() => {
              history.goBack(-1);
            }}
          >
            {t("Cancel")}
          </Button>
        </Box>
        {((data?.main?.isEdit && permission?.update) || (!Boolean(data?.main?.isEdit) && permission?.create)) &&
          <Box>
            <Button className={classes.next} onClick={saveMaintenanceRequest} disabled={isDisableBtn}>
              {data?.main?.isEdit ? t("Update") : t("Submit")}
            </Button>
          </Box>}
      </Box>
      <Drawer
        anchor={"right"}
        open={product?.bool}
        onClose={() => setProduct({ ...product, bool: false, selectedProduct: "" })}
      >
        <Box width="500px" position={"relative"} >
          <Box display="flex" justifyContent={"space-between"} alignItems={"center"} p={1.5} sx={{ backgroundColor: "#F2F4F7" }}>
            <Typography className={classes.drawerHeader}>{t("Asset / Item from unit")}</Typography>
            <Box onClick={() => setProduct({ ...product, bool: false, selectedProduct: "" })} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
          </Box>
          <Box><Divider /></Box>
          <Box p={2}>
            <SearchFilter
              handleChange={handleSearch}
              value={searchText}
              color={"white"}
              placeholder={t("Search Item")}
              customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
            <Box mt={2}>
              <InfiniteScroll
                dataLength={productList?.length ?? 10}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 165}
              >
                {productList?.length > 0 ? productList?.map((val, i) => {
                  return (
                    <>
                      <Stack direction="row" spacing={2} alignItems="center" onClick={() => handleSelectProduct(val)}>
                        {product?.selectedProduct?.id === val?.id ?
                          <Box style={{ cursor: "pointer" }}>
                            <CircleIcon color="#5078E1" /></Box>
                          : <Box style={{ cursor: "pointer" }}><CircleIcon />
                          </Box>}

                        {val?.assets?.length > 0 ?
                          <Avatar className={classes.avatar} src={JSON.parse(val?.assets)?.[0]?.url} />
                          :
                          <Avatar className={classes.avatar}>
                            <img src="/images/Group 98362.svg" alt="" />
                          </Avatar>
                        }
                        <Box>
                          <Typography className={classes.productName} noWrap>{val?.name}</Typography>
                          <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            {val?.location_name !== null &&
                              <>
                                <Typography className={classes.productSubTitle} noWrap>{val?.location_name ?? "-"}</Typography>
                                <Box className={classes.dot}></Box></>
                            }
                            {val?.manufaturer_name !== null &&
                              <>
                                <Typography className={classes.productSubTitle} noWrap>{val?.manufaturer_name ?? "-"}</Typography>
                                <Box className={classes.dot}></Box></>
                            }{val?.serial_number !== null &&
                              <>
                                <Typography className={classes.productSubTitle} noWrap>{val?.serial_number ?? "-"}</Typography>
                                <Box className={classes.dot}></Box>
                              </>
                            }
                            {val?.model_number !== null &&
                              <>
                                <Typography className={classes.productSubTitle} noWrap>{val?.model_number ?? "-"}</Typography>
                                <Box className={classes.dot}></Box>
                              </>
                            }
                            {val?.item_condition !== null &&
                              <>
                                <Typography className={classes.itemCondition} noWrap>{val?.item_condition ?? "-"}</Typography>
                              </>
                            }
                          </Stack>
                        </Box>
                      </Stack>
                      <Box>
                        {productList?.length - 1 !== i &&
                          <Box mt={1.5} mb={1.5}>
                            <Divider />
                          </Box>
                        }
                      </Box>
                    </>
                  )
                })

                  : <Box display={"flex"} justifyContent={"center"}>
                    <Typography>{t("No Items Found")}</Typography>
                  </Box>}
              </InfiniteScroll>
            </Box>

          </Box>
          <Box className={classes.addItembtn}>
            <Button variant="contained" fullWidth onClick={() => handleProduct("add")}>{t("Add")}</Button>
          </Box>
        </Box>
      </Drawer>
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};
export default withNamespaces("maintanceRequest")(Request)
