import { Box, Typography } from "@mui/material";
import { AccountList } from "../../../screens/quotationDetails/components/accountList";
import { LeadUnitDetails } from "../../../screens/quotationDetails/components/leadUnitDetails";
import { useStyles } from "./styles";
import { GET_CONTACT_DETAILS } from "../../../graphql/quotationQueries";
import { useApolloClient } from "@apollo/client";
import React from "react";

export const Preview = ({ t=()=>false,total = "", selectedAcc = {}, data = {}, contact = {}, disable = false ,setDisable = () => false ,isNew=false}) => {

    const classes = useStyles()
    const client = useApolloClient();

    const getContact = () => {
        client.query({
            query: GET_CONTACT_DETAILS,
            fetchPolicy: 'network-only',
            variables: {
                id: contact?.email
            }
        }).then((response) => {
            let res = response.data.contact[0]
              if (res) {
                setDisable(true)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    React.useEffect(() => {
        if (contact?.email) {
            getContact()
        }
        // eslint-disable-next-line
    }, [contact?.email])
    return (
        <Box>
            <Box p={2} className={classes.address1}>
            <Typography className={classes.titleMidle}>{t("AGREEMENT DETAILS")}</Typography>
                <LeadUnitDetails
                    unit
                    quott={{
                        unit: data?.units?.length,
                        unit_details: data?.units,
                        startDate: data?.quott?.lease_start_date,
                        endDate: data?.quott?.lease_end_date,
                        symbol: data?.quott?.symbol
                    }}
                    total={total} />
            </Box>
            <Box p={2} className={classes.address1}>
            <Typography className={classes.titleMidle}>{t("BILLING PRIMARY CONTACT DETAILS")}</Typography>
                <LeadUnitDetails data={{
                    name: contact?.name,
                    img: contact?.image_url,
                    phone: `${contact?.mobile?.mobile_code} ${contact?.mobile?.mobile}`,
                    mail: contact?.email,
                    nationality: contact?.nationality
                }}
                    lead
                    isNew={isNew ? true : false}
                />
            </Box>
            <Box p={2}>
            <Typography className={classes.titleMidle}>{t("BILLING ACCOUNT DETAILS")}</Typography>
            <AccountList disable value={selectedAcc} padding="12px"/>
            </Box>
            
        </Box>
    )
}