import { createContext, useContext, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { AlertProps, NetWorkCallMethods, fileUpload, getRoutePermissionNew, useWindowDimensions } from "../../utils";
import { listing_tab_data, listing_tab_paths } from "./utils/constant";
import { BasicInfoUseStyles, ConfigurationUseStyles, PlanAndSubscriptionsUseStyles, SubscriptionsUseStyles } from "./style";
import { AlertContext, AuthContext } from "../../contexts";
import { PlanAndSubscriptions } from "./planAndSubscription";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { BackendRoutes } from "../../router/routes";
import {
    constructAllSubscriptionResponse, constructGetBasicInfoResponse,
    constructGetPlanInfoResponse, constructGetConfigurationResponse, getGridLayout,
} from "./utils/common";
import { BasicInfo } from "./basicInfo";
import { Configuration } from "./configuration";
import { useRef } from "react";
import { UseDebounce } from "../../components";

export const SubscriptionsContext = createContext();

const SubscriptionsProvider = (props) => {
    const { t, loading, handleLoading } = props;
    const file_upload_limit = 5;
    const allowed_file_format = [".jpg", ".jpeg", ".png"];
    const size = useWindowDimensions();
    const grid_layout = getGridLayout(size);
    const initial_main_tab_data = listing_tab_data(t)?.configuration;
    const tab_paths = listing_tab_paths(t);
    const tab_data = listing_tab_data(t);
    const subscriptionClasses = SubscriptionsUseStyles({ size: size });
    const planAndSubscriptionClasses = PlanAndSubscriptionsUseStyles({ size: size });
    const basicInfoClasses = BasicInfoUseStyles({ size: size });
    const configurationClasses = ConfigurationUseStyles({ size: size });
    const login_logo_ref = useRef(null);
    const web_background_ref = useRef(null);
    const mobile_background_ref = useRef(null);
    const web_welcome_ref = useRef(null);
    const mobile_welcome_ref = useRef(null);
    const debounce = UseDebounce();

    // useHooks
    const auth = useContext(AuthContext);
    const alert = useContext(AlertContext);

    // useState
    const [permission, setPermission] = useState({});
    const [selected_main_tab_state, set_selected_main_tab_state] = useState(initial_main_tab_data);
    const [is_button_disable, set_is_button_disable] = useState(false);
    const [current_plan_state, set_current_plan_state] = useState({});
    const [modules_state, set_modules_state] = useState([]);
    const [plans_state, set_plans_state] = useState([]);
    const [all_subscription_state, set_all_subscription_state] = useState({});
    const [basic_info_state, set_basic_info_state] = useState({});
    const [login_logo_config_state, set_login_logo_config_state] = useState({});
    const [web_background_config_state, set_web_background_config_state] = useState({});
    const [mobile_background_config_state, set_mobile_background_config_state] = useState({});
    const [web_welcome_config_state, set_web_welcome_config_state] = useState({});
    const [mobile_welcome_config_state, set_mobile_welcome_config_state] = useState({});


    // useEffect to get permission
    useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getData(selected_main_tab_state)
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // useEffect to construct all plans details
    useEffect(() => {
        if (current_plan_state?.id && modules_state?.length > 0 && plans_state?.length > 0) {
            const temp_responses = {
                current_plan: current_plan_state,
                modules: modules_state,
                plans: plans_state,
            }
            let temp_all_subscription_state = constructAllSubscriptionResponse(t, temp_responses)
            set_all_subscription_state(temp_all_subscription_state)
            handleLoading(false);
            set_is_button_disable(false);
        }
        // eslint-disable-next-line
    }, [auth, current_plan_state, modules_state, plans_state]);

    // Function to handle main tab
    const handleMainTabChange = (selected_main_tab) => {
        if (is_button_disable === false && selected_main_tab_state?.value !== selected_main_tab?.value) {
            set_selected_main_tab_state(selected_main_tab)
            set_is_button_disable(true)
            getData(selected_main_tab)
        }
    }

    // Funtion to get data
    const getData = (selected_main_tab) => {
        switch (selected_main_tab?.value) {
            case listing_tab_data(t).subscription_and_plan.value:
                getSubscriptionAndPlanData();
                break;
            case listing_tab_data(t).basic_info.value:
                getBasicInfoData();
                break;
            case listing_tab_data(t).configuration.value:
                getConfigurationData();
                break;
            default:
                break;
        }
    }

    const getSubscriptionAndPlanData = () => {
        return new Promise(async (resolve, reject) => {
            try {
                await Promise.all([
                    getPlanInfo(),
                    getModules(),
                    getPlans(),
                ])
            } catch (error) {
                reject(error);
            }
        });
    }

    const getPlanInfo = () => {
        NetworkCall(
            `${config.api_url}${BackendRoutes?.subscription_plan_usage}`,
            NetWorkCallMethods.get, null, null, true, false
        ).then((response) => {
            const temp_current_plan_state = constructGetPlanInfoResponse(response?.data?.data);
            set_current_plan_state(temp_current_plan_state);
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
    }

    const getModules = () => {
        let payload = { offset: 0, limit: 100 }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.subscription_modules}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            set_modules_state(response?.data?.data?.data ?? []);
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
    }

    const getPlans = () => {
        let payload = { offset: 0, limit: 100 }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.subscription_get_all_plans}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            set_plans_state(response?.data?.data?.rows ?? []);
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
    }

    const getBasicInfoData = () => {
        return new Promise(async (resolve, reject) => {
            try {
                await Promise.all([
                    getBasicInfo(),
                ])
            } catch (error) {
                reject(error);
            }
        });
    }

    const getBasicInfo = () => {
        NetworkCall(
            `${config.api_url}${BackendRoutes?.subscription_basic_info}`,
            NetWorkCallMethods.get, null, null, true, false
        ).then((response) => {
            const temp_basic_info_state = constructGetBasicInfoResponse(response?.data?.data);
            set_basic_info_state(temp_basic_info_state);
            set_is_button_disable(false);
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
    }

    const getConfigurationData = () => {
        return new Promise(async (resolve, reject) => {
            try {
                await Promise.all([
                    getConfiguration(),
                ])
            } catch (error) {
                reject(error);
            }
        });
    }

    const getConfiguration = () => {
        NetworkCall(
            `${config.api_url}${BackendRoutes?.client_get_client_configuration}`,
            NetWorkCallMethods.get, null, null, true, false
        ).then((response) => {
            const temp_configuration_state = constructGetConfigurationResponse(response?.data?.data);
            set_login_logo_config_state(temp_configuration_state?.login_logo);
            set_web_background_config_state(temp_configuration_state?.web_background);
            set_mobile_background_config_state(temp_configuration_state?.mobile_background);
            set_web_welcome_config_state(temp_configuration_state?.web_welcome);
            set_mobile_welcome_config_state(temp_configuration_state?.mobile_welcome);
            set_is_button_disable(false);
            handleLoading(false);
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
    }

    const renderTab = (selected_main_tab) => {
        switch (selected_main_tab?.value) {
            case listing_tab_data(t).subscription_and_plan.value:
                return PlanAndSubscriptions(t);
            case listing_tab_data(t).basic_info.value:
                return BasicInfo(t);
            case listing_tab_data(t).configuration.value:
                return Configuration(t);
            default:
                break;
        }
    }

    const handleChooseImage = (type) => {
        switch (type) {
            case "login_logo":
                login_logo_ref.current.click();
                break;
            case "web_background":
                web_background_ref.current.click();
                break;
            case "mobile_background":
                mobile_background_ref.current.click();
                break;
            case "web_welcome":
                web_welcome_ref.current.click();
                break;
            case "mobile_welcome":
                mobile_welcome_ref.current.click();
                break;

            default:
                break;
        }
    };

    const handleUploadImage = async (file, type) => {
        const reader = new FileReader();

        reader.onload = async (event) => {
            const arrayBuffer = event.target.result;
            const view = new DataView(arrayBuffer);
            const firstByte = view.getUint8(0);
            const secondByte = view.getUint8(1);

            const is_jpeg = firstByte === 0xFF && secondByte === 0xD8;
            const is_png = firstByte === 0x89 && secondByte === 0x50;

            if (is_jpeg || is_png) {
                handleBlobImage(file, type);

                fileUpload(file, {}, alert)
                    .then((response) => {
                        handleSuccessImage(response, type);
                    })
                    .catch((err) => {
                        handleErrorImage(type);
                    });
            } else {
                alert.setSnack({
                    ...alert, open: true, msg: t("Invalid Image File"),
                    severity: AlertProps.severity.warning
                })
            }
        };

        reader.readAsArrayBuffer(file);
    };

    const handleBlobImage = (file, type) => {
        const local_image_url = URL.createObjectURL(file);

        let temp_selected_local_state = {
            selected_blob_image: file,
            selected_local_image: local_image_url,
            selected_image_name: `${t("Uploading")}...`,
            is_image_uploading: true,
            is_disable: true,
        }

        switch (type) {
            case "login_logo":
                set_login_logo_config_state({
                    ...login_logo_config_state,
                    ...temp_selected_local_state,
                })
                break;
            case "web_background":
                set_web_background_config_state({
                    ...web_background_config_state,
                    ...temp_selected_local_state,
                })
                break;
            case "mobile_background":
                set_mobile_background_config_state({
                    ...mobile_background_config_state,
                    ...temp_selected_local_state,
                })
                break;
            case "web_welcome":
                set_web_welcome_config_state({
                    ...web_welcome_config_state,
                    ...temp_selected_local_state,
                })
                break;
            case "mobile_welcome":
                set_mobile_welcome_config_state({
                    ...mobile_welcome_config_state,
                    ...temp_selected_local_state,
                })
                break;

            default:
                break;
        }
    }

    const handleSuccessImage = (response, type) => {
        let temp_selected_remote_state = {
            selected_remote_image: response?.[0]?.url,
            selected_image_name: response?.[0]?.url?.split('/')?.reverse()?.[0],
            selected_local_image: null,
            is_image_uploading: false,
            is_disable: false,
        }

        debounce(() => {
            switch (type) {
                case "login_logo":
                    set_login_logo_config_state({
                        ...login_logo_config_state,
                        ...temp_selected_remote_state,
                    })
                    break;
                case "web_background":
                    set_web_background_config_state({
                        ...web_background_config_state,
                        ...temp_selected_remote_state,
                    })
                    break;
                case "mobile_background":
                    set_mobile_background_config_state({
                        ...mobile_background_config_state,
                        ...temp_selected_remote_state,
                    })
                    break;
                case "web_welcome":
                    set_web_welcome_config_state({
                        ...web_welcome_config_state,
                        ...temp_selected_remote_state,
                    })
                    break;
                case "mobile_welcome":
                    set_mobile_welcome_config_state({
                        ...mobile_welcome_config_state,
                        ...temp_selected_remote_state,
                    })
                    break;

                default:
                    break;
            }
        }, 1000);
    }

    const handleErrorImage = (type) => {
        let temp_try_again_state = {
            selected_image_name: `${t("Failed. Try again")}...`,
            is_try_again: true,
            is_image_uploading: false,
            is_disable: false,
        }

        debounce(() => {
            switch (type) {
                case "login_logo":
                    set_login_logo_config_state({
                        ...login_logo_config_state,
                        ...temp_try_again_state,
                    })
                    break;
                case "web_background":
                    set_web_background_config_state({
                        ...web_background_config_state,
                        ...temp_try_again_state,
                    })
                    break;
                case "mobile_background":
                    set_mobile_background_config_state({
                        ...mobile_background_config_state,
                        ...temp_try_again_state,
                    })
                    break;
                case "web_welcome":
                    set_web_welcome_config_state({
                        ...web_welcome_config_state,
                        ...temp_try_again_state,
                    })
                    break;
                case "mobile_welcome":
                    set_mobile_welcome_config_state({
                        ...mobile_welcome_config_state,
                        ...temp_try_again_state,
                    })
                    break;

                default:
                    break;
            }
        }, 1000);
    }

    const handleDeleteImage = (type) => {
        let temp_delete_image_state = {
            updated_local_image: null,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
        }

        switch (type) {
            case "login_logo":
                set_login_logo_config_state({
                    ...login_logo_config_state,
                    ...temp_delete_image_state,
                })
                break;
            case "web_background":
                set_web_background_config_state({
                    ...web_background_config_state,
                    ...temp_delete_image_state,
                })
                break;
            case "mobile_background":
                set_mobile_background_config_state({
                    ...mobile_background_config_state,
                    ...temp_delete_image_state,
                })
                break;
            case "web_welcome":
                set_web_welcome_config_state({
                    ...web_welcome_config_state,
                    ...temp_delete_image_state,
                })
                break;
            case "mobile_welcome":
                set_mobile_welcome_config_state({
                    ...mobile_welcome_config_state,
                    ...temp_delete_image_state,
                })
                break;

            default:
                break;
        }
    }

    const handleUpdateEdit = (type) => {
        switch (type) {
            case "login_logo":
                set_login_logo_config_state({
                    ...login_logo_config_state,
                    is_edit_mode: true,
                })
                break;
            case "web_background":
                set_web_background_config_state({
                    ...web_background_config_state,
                    is_edit_mode: true,
                })
                break;
            case "mobile_background":
                set_mobile_background_config_state({
                    ...mobile_background_config_state,
                    is_edit_mode: true,
                })
                break;
            case "web_welcome":
                set_web_welcome_config_state({
                    ...web_welcome_config_state,
                    is_edit_mode: true,
                })
                break;
            case "mobile_welcome":
                set_mobile_welcome_config_state({
                    ...mobile_welcome_config_state,
                    is_edit_mode: true,
                })
                break;

            default:
                break;
        }
    }

    const handleClearImage = (type) => {
        let temp_clear_image_state = {
            updated_local_image: null,
            selected_remote_image: null,
            selected_local_image: null,
            is_try_again: false,
        }

        switch (type) {
            case "login_logo":
                set_login_logo_config_state({
                    ...login_logo_config_state,
                    ...temp_clear_image_state,
                })
                break;
            case "web_background":
                set_web_background_config_state({
                    ...web_background_config_state,
                    ...temp_clear_image_state,
                })
                break;
            case "mobile_background":
                set_mobile_background_config_state({
                    ...mobile_background_config_state,
                    ...temp_clear_image_state,
                })
                break;
            case "web_welcome":
                set_web_welcome_config_state({
                    ...web_welcome_config_state,
                    ...temp_clear_image_state,
                })
                break;
            case "mobile_welcome":
                set_mobile_welcome_config_state({
                    ...mobile_welcome_config_state,
                    ...temp_clear_image_state,
                })
                break;

            default:
                break;
        }
    }

    const handleCancel = (type) => {
        let temp_clear_image_state = {
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
        }

        switch (type) {
            case "login_logo":
                set_login_logo_config_state({
                    ...login_logo_config_state,
                    ...temp_clear_image_state,
                    updated_local_image: login_logo_config_state?.updated_image,
                })
                break;
            case "web_background":
                set_web_background_config_state({
                    ...web_background_config_state,
                    ...temp_clear_image_state,
                    updated_local_image: web_background_config_state?.updated_image,
                })
                break;
            case "mobile_background":
                set_mobile_background_config_state({
                    ...mobile_background_config_state,
                    ...temp_clear_image_state,
                    updated_local_image: mobile_background_config_state?.updated_image,
                })
                break;
            case "web_welcome":
                set_web_welcome_config_state({
                    ...web_welcome_config_state,
                    ...temp_clear_image_state,
                    updated_local_image: web_welcome_config_state?.updated_image,
                })
                break;
            case "mobile_welcome":
                set_mobile_welcome_config_state({
                    ...mobile_welcome_config_state,
                    ...temp_clear_image_state,
                    updated_local_image: mobile_welcome_config_state?.updated_image,
                })
                break;

            default:
                break;
        }
    }

    const handleSave = (url, type) => {
        let payload = { [type]: url }
        if (url) {
            NetworkCall(
                `${config.api_url}${BackendRoutes?.client_update_client}`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then((response) => {
                handleSaveImage(url, type);
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true, msg: t("Some Thing Went Wrong"),
                    severity: AlertProps.severity.error
                })
            })
        }
    }
    const handleSaveImage = (url, type) => {
        let temp_save_image_state = {
            updated_image: url,
            updated_local_image: url,
            selected_blob_image: null,
            selected_local_image: null,
            selected_remote_image: null,
            selected_image_name: url?.split?.("/")?.reverse()?.[0],
            is_disable: false,
            is_edit_mode: false,
            is_image_uploading: false,
            is_try_again: false,
        }

        debounce(() => {
            switch (type) {
                case "login_logo":
                    set_login_logo_config_state({
                        ...login_logo_config_state,
                        ...temp_save_image_state,
                    })
                    break;
                case "web_background":
                    set_web_background_config_state({
                        ...web_background_config_state,
                        ...temp_save_image_state,
                    })
                    break;
                case "mobile_background":
                    set_mobile_background_config_state({
                        ...mobile_background_config_state,
                        ...temp_save_image_state,
                    })
                    break;
                case "web_welcome":
                    set_web_welcome_config_state({
                        ...web_welcome_config_state,
                        ...temp_save_image_state,
                    })
                    break;
                case "mobile_welcome":
                    set_mobile_welcome_config_state({
                        ...mobile_welcome_config_state,
                        ...temp_save_image_state,
                    })
                    break;

                default:
                    break;
            }
        }, 1000);
    }

    return (
        <SubscriptionsContext.Provider
            value={{
                t,
                permission,
                loading,
                handleLoading,
                subscriptionClasses,
                planAndSubscriptionClasses,
                basicInfoClasses,
                configurationClasses,
                tab_paths,
                tab_data,
                grid_layout,
                file_upload_limit,
                allowed_file_format,
                login_logo_ref,
                web_background_ref,
                mobile_background_ref,
                web_welcome_ref,
                mobile_welcome_ref,

                selected_main_tab_state,
                current_plan_state,
                all_subscription_state,
                basic_info_state,
                login_logo_config_state,
                web_background_config_state,
                mobile_background_config_state,
                web_welcome_config_state,
                mobile_welcome_config_state,
                is_button_disable,

                getBasicInfo,
                handleMainTabChange,
                renderTab,
                handleChooseImage,
                handleUploadImage,
                handleDeleteImage,
                handleUpdateEdit,
                handleClearImage,
                handleCancel,
                handleSave,
            }}
        >
            {props?.children}
        </SubscriptionsContext.Provider>
    )
}

export default withNamespaces("subscriptions")(SubscriptionsProvider);