import gql from "graphql-tag";


export let GET_QUOTATION_LISTING = (offset, limit, searchText, company_id, unit_id, created_by, status, final_status) => gql`
query GET_QUOTATION_LISTING{
    count: quotation(
      where: { 
        quotation_no: { iregex: "${searchText}" } 
        company_id:{in:$company_id}
        is_active:{eq:true}
        ${created_by?.length ? `created_by: { id: { in: $created_by } }` : ''}
        ${unit_id?.length > 0 ? `quotation_units: { unit_id: { in: $unit_id } }` : ''}
        ${status?.length > 0 ? `quotation_status: { quotation_status_master: { id: { in: $status } } }` : ''}
        ${final_status ? `quote_status: { eq: $final_status }` : ''}
      }) {
      count: count_id
    }
    quotation(
      where: { 
        quotation_no: { iregex: "${searchText}" } 
        company_id:{in:$company_id}
        is_active:{eq:true}
        ${created_by?.length ? `created_by: { id: { in: $created_by } }` : ''}
        ${unit_id?.length > 0 ? `quotation_units: { unit_id: { in: $unit_id } }` : ''}
        ${status?.length > 0 ? `quotation_status: { quotation_status_master: { id: { in: $status } } }` : ''}
        ${final_status ? `quote_status: { eq: $final_status }` : ''}
      }
      order_by: { created_at: desc }
      offset: ${offset}
      limit: ${limit}
    ) {
      id
      quotation_no
      lead {
        name
        agent: contact {
          first_name
          last_name
        }
      }
      lease_duration
      lease_period
      total_amount
      exipry_on: quotation_expiry_date
      currency_master {
        symbol
      }
      status: quote_status
      units:quotation_units {
        unit_id
      }
    }
  }`;


export let GET_QUOTATION_DETAILS = (id) => gql`
query GET_QUOTATION_DETAILS{
  quotation(
    where:{id:{eq:"${id}"}}
  ) {
    quotation_no
    is_active
    created_at
    quote_status
    description
    created_by{
      id
    }
    quote_start_date
    quotation_expiry_date
    lease_period
    lease_start_date
    lease_end_date
    duration: quotation_duration
    quotation_duration_value
    grace_period
    agent: lead {
      lead_owner {
        first_name
        last_name
      }
    }
    leadDetails: lead {
      name
      contact {
        first_name
        last_name
        mobile_no
        mobile_no_country_code
      }
      account_no {
        account_no
      }
    }
    units: quotation_units {
      quote_id
      unit_total_primary
      unit_total_refundable
      currency_master {
        symbol
      }
      unit {
        id
        unit_no
        name
        floor {
          name
          floor_no
        }
        block {
          name
          block_no
        }
        total_area
        area_metric
        unit_type_master {
          name
        }
      }
    }
  }
}`;

export let GET_PROPERTY_BASED_ON_COMPANY = (offset,limit,searchText,company_id=[]) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: property(
    where:{
      company_id:{in:[${company_id}]}
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  property(
      where:{
        company_id:{in:[${company_id}]}
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`
;
export let GET_CREATEDBY_BASED_ON_COMPANY = (offset,limit,searchText,company_id=[]) => gql`
query GET_CREATEDBY_BASED_ON_COMPANY{
  count: quotation(
    where:{
      company_id:{in:[${company_id}]}
      created_by:{or:{first_name:{iregex:"mano"},last_name:{iregex:""}}}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  quotation(
      where:{
        company_id:{in:[${company_id}]}
        created_by:{or:{first_name:{iregex:"mano"},last_name:{iregex:""}}}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
      created_by{
      id
      first_name
      last_name
      } 
      }
}
`;

export const GET_STATUS_FROM_QUOTATION_STATUS_MASTER = (offset,limit,searchText,) =>gql`
query GET_STATUS_FROM_QUOTATION_STATUS_MASTER{
  count: quotation_status_master{
    count:count_id
  }
  quotation_status_master(
    offset:${offset}
    limit:${limit}
  ){
    label:quotation_status
    value:id
  }
}`

export const GET_UNIT_BASED_ON_PROPERTY = gql`
query GET_UNIT_BASED_ON_PROPERTY($data:[String!],$offset:Int!,$limit:Int!,$search:String!){
  count: unit(
    where: {
      property_id: { in: $data }
      is_active: { eq: true }
      name: { iregex: $search }
    }
  ) {
    count: count_id
  }
  unit(
    where: {
      property_id: { in: $data }
      is_active: { eq: true }
      name: { iregex: $search }
    }
      offset:$offset
      limit:$limit
  ) {
    value:id
    label:name
  }
}`


