import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GET_PROPERTY_BYCOMPANY } from "../../graphql/scheduleBoardQuery";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  getCalendarOptions,
  getCompanyOption,
  getRoutePermissionNew,
  JobType,
  NetWorkCallMethods,
  pageName,
  SwitchInspectionRoutes
} from "../../utils";
import DashboardComponent from "./dashboardComponent";
import { withNamespaces } from "react-i18next";

// import { DashBoard } from "./dashboard";

const InspectionManagerDashboard = (props) => {
  const { loading, handleLoading, t } = props;
  // states
  const history = useHistory();
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [companyList, setCompanyList] = React.useState([]);
  const [calendarOptions, setCalendarOptions] = React.useState([])
  const [selectedCalendar, setSelectedCalendar] = React.useState({})
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [permission, setPermission] = React.useState({});
  const [propertyData, setPropertyData] = React.useState([])
  const [searchText, setSearchText] = React.useState("")
  const [propertyValue, setPropertyValue] = React.useState([])
  const [selectedResource, setSelectedResource] = React.useState("")
  const [resourcesJobData, setResourcesJobData] = React.useState([])
  const [resourcesVsJobData, setResourcesVsJobData] = React.useState([])
  const [completedJobData, setCompletedJobData] = React.useState([])
  const [timeOffType, setTimeOffType] = React.useState("Paid")
  const [timeOffData, setTimeOffData] = React.useState([])
  const [utilityGraphData, setUtilityGraphData] = React.useState({
    "graphData": {
      "labels": [],
      "datasets": [
        {
          "label": "Utility Graph",
          "data": [],
          "fill": true,
          "backgroundColor": "#a450fb",
          "borderColor": "#13d882"
        }
      ]
    },
    "readings": {}
  })
  const [utilityData, setUtilityData] = React.useState({
    selected_property: "",
    selected_unit: "",
    selected_utility: "",
    propertyData: [],
    unitData: [],
    utilityItems: []
  })
  const [defaultUtilityUnit, setDefaultUtilityUnit] = React.useState({})
  const [defaultUtilityItem, setDefaultUtilityItem] = React.useState({})
  const [page, setPage] = React.useState({
    completedJobs: 1,
    resourcesvsJobs: 1,
    ongoingJobs:1
  })
  const [limit, setLimit] = React.useState({
    completedJobsLimit: 10,
    resourcesVsJobsLimit: 10,
    ongoingJobsLimit:10
  })
  const [data, setData] = React.useState({
    details: [],
    inspection_job_status: []
  })
  // use effect to get permission
  useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list)
          setSelectedCompany(company?.selected)
          getPropertyDropdown([company?.selected?.value])
          handleLoading(false)
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  React.useEffect(() => {
    if (selectedCompany?.value) {
      getPropertyDropdown([selectedCompany?.value])
    }
    //eslint-disable-next-line
  }, [selectedCompany, utilityData?.propertyData])
  const returnAllPropertyJson = (list) => {
    const data = list?.map((val) => {
      return {
        label: val?.label,
        value: [val?.value]
      }
    })
    return (
      ([
        {
          label: t("All Properties"),
          value: list?.map(i => { return i?.value })
        },
        ...data]
      ))
  }
  //handleCompanyChange
  const handleCompanyChange = (val) => {
    setSelectedCompany(val)
    getPropertyDropdown([val?.value])
  }
  const getPropertyDropdown = (company = []) => {
    let payload = {
      query: GET_PROPERTY_BYCOMPANY,
      variables: {
        company_id: company,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      const result = returnAllPropertyJson(res?.data?.data?.property);
      setUtilityData({
        ...utilityData,
        propertyData: res?.data?.data?.property,
        selected_property: res?.data?.data?.property?.[0],
      });
      getUnits(company, res?.data?.data?.property?.[0]?.value);
      setPropertyData(result);
      setPropertyValue(result?.[0]);
      let tempCalendarOptions = getCalendarOptions();
      setCalendarOptions(tempCalendarOptions);
      setSelectedCalendar(tempCalendarOptions[6]);
      getResources(0, 10, result?.[0]?.value);
      getDashboardData(
        result?.[0]?.value,
        tempCalendarOptions[6].value.from_date,
        tempCalendarOptions[6].value.to_date
      );
      getCompletedJobsData(
        0,
        10,
        result?.[0]?.value,
        tempCalendarOptions[6].value.from_date,
        tempCalendarOptions[6].value.to_date,
        ""
      );
      getResourceVsJobs(
        0,
        10,
        result?.[0]?.value,
        tempCalendarOptions[6].value.from_date,
        tempCalendarOptions[6].value.to_date
      );
    });
  };
  const getResources = (offset = 0, limit = 10, property_id) => {
    // let tempCalendarOptions = getCalendarOptions()
    const payload = {
      "property_id": property_id,
      "start": offset,
      "length": limit
    };
    NetworkCall(
      `${config.api_url}/inspection_manager/get_property_resources`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        // setSelectedResource(res?.data?.data?.[0])
        // getResourceJobsData(0, 10, property_id, tempCalendarOptions[6].value.from_date, tempCalendarOptions[6].value.to_date, res?.data?.data?.[0]?.value)
        // getTimeOffGraphData(0, 10, property_id, tempCalendarOptions[6].value.from_date, tempCalendarOptions[6].value.to_date, timeOffType, res?.data?.data?.[0]?.value)

      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getUnits = (companyId, propertyId) => {
    const payload = {
      "company_id": companyId,
      "property_id": propertyId,
    };
    NetworkCall(
      `${config.api_url}/unit/property_units`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setDefaultUtilityUnit(res?.data?.data?.units?.map((val) => {
          return {
            value: val?.id,
            label: val?.name
          }
        })?.[0])
        getUtilityItems(res?.data?.data?.units?.[0]?.id)
      })

      .catch((error) => {
        console.log(error)
      })
  }
  const getUtilityItems = (unit_id) => {
    const payload = {
      // "search": "",
      "unit_id": unit_id,
    }
    NetworkCall(
      `${config.api_url}/unit_meter_reading/get_utility`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setDefaultUtilityItem(res?.data?.data?.map((val) => {
          return {
            value: val?.value,
            label: val?.label
          }
        })?.[0])
        getUtilityGraphData(unit_id, res?.data?.data?.[0]?.value)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getDashboardData = (property_id, from_date, to_date) => {
    const payload = {
      "property_id": property_id,
      "start_date": from_date,
      "end_date": to_date
    };
    NetworkCall(
      `${config.api_url}/inspection_manager`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const total = res?.data?.data?.inspection_job_type_graph?.map((item) => parseInt(item?.count))
          .reduce((a, b) => a + b, 0);
        const inspection_order_graph = res?.data?.data?.inspection_job_type_graph?.map((val) => {
          return {
            name: JobType(val.type),
            count: val?.count,
            fill: val?.fill
          }
        })
        const WorkOrders = res?.data?.data?.order_graph?.map((val) => {
          return {
            name: val.type,
            count: val?.count,
            fill: val?.fill
          }
        })
        const ProjectedHoursGraphData = {
          "labels": res?.data?.data?.actual_other_projected?.map((val) => { return val?.type }),
          "datasets": [
            {
              "label": "",
              "data": res?.data?.data?.actual_other_projected?.map((val) => { return val?.count }),
              "backgroundColor": res?.data?.data?.actual_other_projected?.map((val) => { return val?.fill }),
              "borderWidth": 1,
              "barThickness": 40
            },
          ]
        }
        const JobStatusGraph = {
          "labels": res?.data?.data?.job_status_graph?.map((val) => { return val?.type }),
          "datasets": [
            {
              "label": "",
              "data": res?.data?.data?.job_status_graph?.map((val) => { return val?.count }),
              "backgroundColor": res?.data?.data?.job_status_graph?.map((val) => { return val?.fill }),
              "borderWidth": 1,
              "barThickness": 40
            },
          ]
        }
        setData({ ...data, details: res?.data?.data, inspection_order_graph: inspection_order_graph, WorkOrders: WorkOrders, ProjectedHoursGraphData: ProjectedHoursGraphData, inspectionJobTotal: total, job_status_graph: JobStatusGraph })
      })
      .catch((error) => {
        console.log(error)
      });
  }
  const getCompletedJobsData = (offset = 0, limit = 10, property_id, from_date, to_date, search = "", scroll = false) => {
    const payload = {
      "property_id": property_id,
      "start_date": from_date,
      "end_date": to_date,
      "search": search,
      "start": offset,
      "length": limit,
    };
    NetworkCall(
      `${config.api_url}/inspection_manager/actual_vs_other_vs_projected`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        if (scroll) {
          setCompletedJobData({ list: completedJobData.concat(res?.data?.data?.actual_projected_otherhours?.result), count: res?.data?.data?.actual_projected_otherhours?.count })
        }
        else {
          setCompletedJobData({ list: res?.data?.data?.actual_projected_otherhours?.result, count: res?.data?.data?.actual_projected_otherhours?.count })
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }
  const getResourceJobsData = (offset = 0, limit = 10, property_id, from_date, to_date, resourceId = "", scroll = false) => {
    const payload = {
      "property_id": property_id,
      "start_date": from_date,
      "end_date": to_date,
      "resource_id": resourceId,
      "start": offset,
      "length": limit,
    };
    NetworkCall(
      `${config.api_url}/inspection_manager/get_resource_jobs`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = res?.data?.data?.list?.list?.map((val) => {
          return {
            ...val,
            type: val?.request_type,
            request_type: JobType(val?.request_type)
          }
        })
        if (scroll) {
          setResourcesJobData({ list: resourcesJobData.concat(result), CompletionHistory: res?.data?.data?.resource_kpi,count:res?.data?.data?.list?.count  })
        }
        else {
          setResourcesJobData({ list: result, CompletionHistory: res?.data?.data?.resource_kpi,count:res?.data?.data?.list?.count })
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }
  const getResourceVsJobs = (offset = 0, limit = 10, property_id, from_date, to_date, scroll = false) => {
    const payload = {
      "property_id": property_id,
      "start_date": from_date,
      "end_date": to_date,
      "start": offset,
      "length": limit,
    };
    NetworkCall(
      `${config.api_url}/inspection_manager/get_resource_vs_jobs`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {

        if (scroll) {
          const initialResource = selectedResource
          setSelectedResource(initialResource)
          getResourceJobsData(0, 10, property_id, from_date, to_date, initialResource?.id)
          getTimeOffGraphData(0, 10, property_id, from_date, to_date, timeOffType, initialResource?.id)
          setResourcesVsJobData({list:resourcesVsJobData.concat(res?.data?.data?.resources),count:res?.data?.data?.count})
        }
        else {
          const initialResource = res?.data?.data?.resources?.[0]
          setSelectedResource(initialResource)
          getResourceJobsData(0, 10, property_id, from_date, to_date, initialResource?.id)
          getTimeOffGraphData(0, 10, property_id, from_date, to_date, timeOffType, initialResource?.id)
          setResourcesVsJobData({list:res?.data?.data?.resources,count:res?.data?.data?.count})
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }
  const getTimeOffGraphData = (offset = 0, limit = 10, property_id, from_date, to_date, type = "", resourceId) => {
    const payload = {
      "property_id": property_id,
      "start_date": from_date,
      "end_date": to_date,
      "start": offset,
      "length": limit,
      "type": type,
      "resource_id": resourceId
    };
    NetworkCall(
      `${config.api_url}/inspection_manager/time_off_data`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const total = res?.data?.data.map((item) => parseInt(item?.count))
          .reduce((a, b) => a + b, 0);
        const result = res?.data?.data?.map((val) => {
          return {
            name: val?.type,
            count: parseInt(val?.count),
            fill: val?.fill,
          }
        })
        setTimeOffData({ data: result, count: total })
      })
      .catch((error) => {
        console.log(error)
      });
  }
  //handle search
  const handleSearch = (value) => {
    setSearchText(value)
    getCompletedJobsData(0, 10, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, value)
  }
  //handlePropertyChange
  const onPropertyChange = (val) => {
    setPropertyValue(val)
    getDashboardData(val?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date)
    getCompletedJobsData(0, 10, val?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, "")
    getTimeOffGraphData(0, 10, val?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, timeOffType, selectedResource?.value)
    getResourceVsJobs(0, 10, val?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date)
    if (selectedResource?.value) {
      getResourceJobsData(0, 10, val?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, selectedResource?.value)
    }
  }

  // Function to change the Calendar
  const handleCalendarChange = (date) => {
    if (date?.load) {
      setSelectedCalendar(date)
      getDashboardData(propertyValue?.value, date.value.from_date, date.value.to_date)
      getCompletedJobsData(0, 10, propertyValue?.value, date.value.from_date, date.value.to_date, "")
      getResourceVsJobs(0, 10, propertyValue?.value, date.value.from_date, date.value.to_date)
      getTimeOffGraphData(0, 10, propertyValue?.value, date.value.from_date, date.value.to_date, timeOffType, selectedResource?.value)
      if (selectedResource?.value) {
        getResourceJobsData(0, 10, propertyValue?.value, date.value.from_date, date.value.to_date, selectedResource?.value)
      }
    }
  }


  const handlePagination = (value) => {
    setPage({ ...page, completedJobs: value });
    let offset = (value - 1) * limit?.completedJobsLimit;
    getCompletedJobsData(offset, 10, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, "")

  }
  const handleChangeLimit = (value) => {
    setLimit({ ...limit, completedJobsLimit: value });
    setPage({ ...page, completedJobs: 1 });
    getCompletedJobsData(0, limit?.completedJobsLimit, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, "")

  }
  const handleResourcesVsJobsPagination = (value) => {
    setPage({ ...page, resourcesvsJobs: value });
    let offset = (value - 1) * limit?.resourcesVsJobsLimit;
    getResourceVsJobs(offset, 10, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date)

  }
  const handleResourcesVsJobsChangeLimit = (value) => {
    setLimit({ ...limit, resourcesVsJobsLimit: value });
    setPage({ ...page, resourcesvsJobs: 1 });
    getResourceVsJobs(0, limit?.resourcesVsJobsLimit, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date)

  }
  const handleOngoingJobsPagination = (value) => {
    setPage({ ...page, ongoingJobs: value });
    let offset = (value - 1) * limit?.ongoingJobsLimit;
    getResourceJobsData(offset, 10, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, selectedResource?.value)

  }
  const handleOngoingJobsChangeLimit = (value) => {
    setLimit({ ...limit, ongoingJobsLimit: value });
    setPage({ ...page, ongoingJobsLimit: 1 });
    getResourceJobsData(0, limit?.ongoingJobsLimit, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, selectedResource?.value)

  }


  const onChangeResource = (val) => {
    const result = resourcesVsJobData?.list?.map((x) => {
      return {
        ...x,
        selectedRow: x?.id === val?.id ?? false
      }

    })
    setResourcesVsJobData({...resourcesVsJobData,list:result})
    setSelectedResource(val)
    getResourceJobsData(0, 10, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, val?.value)
    getTimeOffGraphData(0, 10, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, timeOffType, val?.value)

  }
  const onChangeTimeOffType = (value) => {
    setTimeOffType(value)
    getTimeOffGraphData(0, 10, propertyValue?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date, value, selectedResource?.value)
  }
  const handleIcon = (type, val) => {
    const RoutePath = SwitchInspectionRoutes(val?.type)
    history.push({
      pathname: RoutePath,
      state: {
        company: selectedCompany,
        id: val?.request_id,
        title: val?.reference_id,
        type: val?.type,
        agreement_id: val?.agreement_id,
        request_id: val?.request_id,
        reference_id: val?.reference_id,
        agreement_inspection_id: val?.agreement_inspection_id,
        main: {
          value: val?.request_id,
          request: val?.type === "maintenance" ? "Maintenance" : "General"
        }
      }
    })
  }
  //utilities
  const getUtilityGraphData = (unit_id, utility_id) => {
    // let tempCalendarOptions = getCalendarOptions()
    const payload = {

      "unit_id": unit_id,
      "utility_id": utility_id

    };
    NetworkCall(
      `${config.api_url}/inspection_manager/reading_graph`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = {
          "labels": res?.data?.data?.graph_data?.labels,
          "datasets": [
            {
              label: res?.data?.data?.graph_data?.datasets?.[0]?.label,
              data: res?.data?.data?.graph_data?.datasets?.[0]?.data,
              fill: true,
              backgroundColor: "#DDFFEA",
              borderColor: "#82E0A5"
            }
          ]
        }
        setUtilityGraphData({ graphData: result, readings: res?.data?.data?.reading_data })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const updateUtilityData = (k, v) => {
    if (k === "selected_property") {
      getUnits(selectedCompany?.value, v?.value)
      setUtilityData({ ...utilityData, selected_unit: "", [k]: v })
    }
    else if (k === "selected_unit") {
      getUtilityItems(v?.value)
      setUtilityData({ ...utilityData, [k]: v, selected_utility: "" })
    }
    else {
      getUtilityGraphData(utilityData?.selected_unit?.value?.length > 0 ? utilityData?.selected_unit?.value : defaultUtilityUnit?.value, v?.value)
      setUtilityData({ ...utilityData, [k]: v })
    }

  }
  const render = () => {
    return (
      <>
        {/* sub header */}
        <Subheader
          hideBackButton={true}
          title={t("Inspection Manager Dashboard")}
          select options={companyList}
          value={selectedCompany} onchange={(e) => handleCompanyChange(e)}
          calanderselect
          calendarOptions={calendarOptions} calanderValue={selectedCalendar}
          onChangeCalendar={(e) => handleCalendarChange(e)}
          onPropertyChange={(e) => {
            onPropertyChange(e)
          }}
          selectProperty
          propertyOptions={propertyData}
          propertyValue={propertyValue}
        />
        <DashboardComponent
          data={data}
          property_id={propertyValue?.value}
          searchText={searchText}
          setSearchText={setSearchText}
          handleSearch={handleSearch}
          completedJobData={completedJobData}
          page={page}
          limit={limit}
          handleChangeLimit={handleChangeLimit}
          handlePagination={handlePagination}
          handleResourcesVsJobsPagination={handleResourcesVsJobsPagination}
          handleResourcesVsJobsChangeLimit={handleResourcesVsJobsChangeLimit}
          handleOngoingJobsPagination={handleOngoingJobsPagination}
          handleOngoingJobsChangeLimit={handleOngoingJobsChangeLimit}
          onChangeResource={onChangeResource}
          selectedResource={selectedResource}
          setSelectedresource={setSelectedResource}
          resourcesJobData={resourcesJobData}
          resourcesVsJobData={resourcesVsJobData}
          timeOffType={timeOffType}
          setTimeOffType={setTimeOffType}
          onChangeTimeOffType={onChangeTimeOffType}
          handleIcon={handleIcon}
          timeOffData={timeOffData}
          selectedCompany={selectedCompany?.value}
          utilityData={utilityData}
          defaultUtilityUnit={defaultUtilityUnit}
          defaultUtilityItem={defaultUtilityItem}
          setUtilityData={setUtilityData}
          updateState={updateUtilityData}
          utilityGraphData={utilityGraphData}
          t={t}
        />
      </>
    );
  };

  return (
    <div>
      {accessCheckRender(render, permission, pageName.dashboard, loading)}
    </div>
  );
};
export default withNamespaces("inspectionManagerDashboard")(InspectionManagerDashboard);
