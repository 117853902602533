import React, { useState, useEffect } from "react";
import { withNavBars } from "../../HOCs";
import WorkspaceManagerDashboardComponent from "./dashboard";
const WorkspaceManagerDashboardParent = (props) => {
    return <WorkspaceManagerDashboardComponent {...props} />;
};

const EnhancedWorkspaceManagerDashboardParent = withNavBars(
    WorkspaceManagerDashboardParent
);

const WorkspaceManagerDashboard = (props) => {
     const [boxShadow, setBoxShadow] = useState(false);

    useEffect(() => {
    }, []);

    return <EnhancedWorkspaceManagerDashboardParent {...props} boxShadow={boxShadow} />;
};

export default WorkspaceManagerDashboard;