import { useApolloClient } from "@apollo/client";
import { Grid } from "@mui/material";
import Fab from "@mui/material/Fab";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import React, { useEffect, useState } from "react";
import { DialogBox, LoadingSection, TableWithPagination } from "../../../components";
import { ACTIVITY_TYPES, GET_LEAD_ACTIVITIES } from "../../../graphql/oppertunityQueries";
import { ActivityHeading, ActivityPath } from "../../../utils/oppertunityUtils";
import { AddActivity, Card, CloseActivity, ViewActivity } from "../activitycomponent";
import { useStyles } from "./styles";

const initial = {
    Category: "",
    Purpose: "",
    mobile: "",
    visitorDate: new Date(),
    Notes: "",
    Priority: "",
    Followup: "",
    location: "",
    email: "",
    isEdit: false,
    error: {
        Category: "",
        Purpose: "",
        mobile: "",
        visitorDate: "",
        Notes: "",
        Priority: "",
        Followup: "",
        location: "",
        email: "",
    },
};
export const Activity = (props) => {
    const language = localStorage.getItem("i18nextLng")
    const { t } = (props)
    // apolo client
    const client = useApolloClient();
    const [viewdialog, setViewDialog] = useState(false);
    const [type, setType] = useState("");
    const [addDialog, setAddDialog] = useState(false);
    const [loading, setLoading] = React.useState(true)
    const [viewDialog, setviewDialog] = useState(false);
    const [closeDialog, setCloseDialog] = useState(false);
    const [typeList, setTypeList] = useState([]);
    const [state, setState] = useState({ ...initial });
    const [activities, setActivities] = useState({
        data: [],
        count: 0,
    });
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    // classes
    const classes = useStyles(props);


    const typeSelect = (type) => {
        setType(type);
        setViewDialog(false);
        setAddDialog(true);
    };
    const getTypes = () => {
        client
            .query({
                query: ACTIVITY_TYPES,
                fetchPolicy: "network-only",
            })
            .then((res) => {
                setTypeList(res?.data?.lead_activity_master);

            })
            .catch((err) => {
                console.log(err)
            });
    };
    // updateState
    const updateState = (key, value) => {
        let error = state?.error;
        error[key] = "";
        setState({ ...state, [key]: value, error });
    };
    const validate = () => {
        let isValid = true;
        let error = state.error;

        if (state?.Category?.length === 0) {
            isValid = false;
            error.Category = t("Category is Required");
        }
        // if (state?.Purpose?.length === 0) {
        //     isValid = false;
        //     error.Purpose = "Purpose is Required";
        // }
        if (state?.mobile?.length === 0 && props?.type?.name === "Phone call") {
            isValid = false;
            error.mobile = t("Mobile Number is Required");
        }
        if (state?.visitorDate?.length === 0) {
            isValid = false;
            error.visitorDate = t("Call Date & Time is Required");
        }
        if (state?.Notes?.length === 0) {
            isValid = false;
            error.Notes = t("Notes is Required");
        }
        if (state?.Priority?.length === 0) {
            isValid = false;
            error.Priority = t("Priority is Required");
        }
        if (
            state?.Followup?.length === 0 &&
            (props?.type?.name === "Follow up" ||
                props?.type?.name === "Meeting" ||
                props?.type?.name === "Appointment")
        ) {
            isValid = false;
            error.Followup = t("Followup is Required");
        }
        if (
            state?.location?.length === 0 &&
            props?.state?.Followup?.value === "In-Person"
        ) {
            isValid = false;
            error.location = "location is Required";
        }
        if (
            state?.email?.length === 0 &&
            (props?.type?.name === "Send Email" ||
                props?.type?.name === "Send Quotation")
        ) {
            isValid = false;
            error.email = "email is Required";
        }
        setState({ ...state, error });

        return isValid;
    };
    // close pop up
    const handleClose = () => {
        setAddDialog(false);
        setState({ ...initial });
    };
    const getActivityList = (offset, limit) => {
        setLoading(true)
        client
            .query({
                query: GET_LEAD_ACTIVITIES,
                fetchPolicy: "network-only",
                variables: {
                    id: props?.oppertunity_id,
                    offset: offset,
                    limit: limit,
                },
            })
            .then((res) => {
                let result = res?.data?.lead_activities?.map((x) => {
                    return {
                        data: x,
                        image: x?.status === "Open" ? x?.proxy_lead_activity_master?.image_url : x?.proxy_lead_activity_master?.inactive_logo,
                        name: x?.proxy_lead_activity_master?.name,
                        info:
                            (x?.email_id?.length > 0 && x?.email_id) ||
                            (x?.location?.length > 0 && x?.location) ||
                            (x?.type?.length > 0 && x?.type) ||
                            (x?.notes?.length > 0 && x?.notes) ||
                            x?.phone_code + x?.phone_number,
                        Date: x?.date_time,
                        CreatedBy: `${x?.user_profiles?.first_name} ${x?.user_profiles?.last_name ?? ""}`,
                        Priority: x?.priority,
                        Status: x?.status ?? "",
                        icon: x?.status === "Open" ? "edit" : "",
                    };
                });

                setActivities({
                    data: result ?? [],
                    count: res?.data?.count?.[0]?.count,
                });
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            });
    };
    const handleIcon = (type, value) => {
        const data = value?.data;
        if (data?.status !== "Closed") {
            setState({
                Category: data?.activity_category ?? "",
                Purpose: {
                    value: data?.purpose ?? "",
                    label: data?.purpose ?? "",
                },
                mobile: {
                    mobile: data?.phone_number,
                    mobile_code: data?.phone_code,
                },
                visitorDate:
                    data?.date_time?.length > 0 ? new Date(data?.date_time) : "",
                Notes: data?.notes ?? "",
                Priority: data?.priority,
                Followup: {
                    value: data?.type ?? "",
                    label: data?.type ?? "",
                },
                location: data?.location ?? "",
                email: data?.email_id ?? "",
                isEdit: true,
                id: data?.id,
                error: {
                    Category: "",
                    Purpose: "",
                    mobile: "",
                    visitorDate: "",
                    Notes: "",
                    Priority: "",
                    Followup: "",
                    location: "",
                    email: "",
                },
            });
            setType(data?.proxy_lead_activity_master);
            if (type === "edit") {
                setAddDialog(true);
            } else {
                setviewDialog(true);
            }
        }
    };
    // pagination function
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getActivityList(offset, limit);
    };
    // pagination limit change function
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getActivityList(0, value);
    };
    // setviewDialog
    const closeDialogFunction = (key) => {
        if (key === "close") {
            setState({ ...initial });
        }
        setCloseDialog(!closeDialog);
        setviewDialog(false);
    };
    const closeView = () => {
        setviewDialog(false);
        setState({ ...initial });
    };
    // open edit
    const openEdit = () => {
        setAddDialog(true)
        setviewDialog(false)
    }
    useEffect(() => {
        if (props?.permission?.read) {
            getTypes();
            getActivityList(0, 10);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.permission]);
    return (
        <>
            {
                loading ? <LoadingSection top={"5vh"} message={t("Loading Acticity...")} /> :
                    <TableWithPagination
                        heading={ActivityHeading(t)}
                        rows={activities?.data ?? []}
                        path={ActivityPath}
                        showpagination={true}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        page={page}
                        limit={limit}
                        totalRowsCount={activities?.count}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        handleIcon={handleIcon}
                        tableType="no-side"
                        count="2"
                        dataType={[
                            { type: ["avatarmanagement"], name: "image" },
                            { type: ["text"], name: "name" },
                            { type: ["text"], name: "info" },
                            { type: ["date"], name: "Date" },
                            { type: ["text"], name: "CreatedBy" },
                            { type: ["text"], name: "Priority" },
                            { type: ["status2"], name: "Status" },
                            { type: ["icon"], icon: "icon" },
                        ]}
                        height={`calc(100vh - 360px)`}
                        view={props?.permission?.read}
                        edit={props?.permission?.update}
                        delete={props?.permission?.delete} />
            }
            {/* choose dialog */}
            <DialogBox
                open={viewdialog}
                padding={"24px"}
                maxWidth={"sm"}
                onClose={() => setViewDialog(false)}
                header={t("Create Activity")}
                width={"551px !important"}
                background
                component={
                    <div style={{ padding: "24px" }}>
                        <Grid container spacing={3}>
                            {typeList?.map((item) => {
                                return (
                                    <Grid item xs={4}>
                                        <Card t={t} data={item} onClick={typeSelect} type={type} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                }
            />
            {/* add activity */}
            <DialogBox
                open={addDialog}
                padding={"24px"}
                maxWidth={"md"}
                onClose={handleClose}
                header={`${state?.isEdit ? t("Edit Activity") : "Create Activity"} ${type?.name ? "-" : ""} ${type?.name ? type?.name : ""}`}
                width={"748px !important"}
                background
                component={
                    <div>
                        <AddActivity
                            cancel={handleClose}
                            type={type}
                            proxy_lead_id={props?.oppertunity_id}
                            opportunity_data={props?.details}
                            updateState={updateState}
                            state={state}
                            validate={validate}
                            getActivityList={getActivityList}
                            selectedCompany={props?.selectedCompany}
                            t={t}
                        />
                    </div>
                }
            />

            {/* Activity */}
            <DialogBox
                open={viewDialog}
                padding={"24px"}
                maxWidth={"md"}
                onClose={closeView}
                header={`Activity`}
                width={"748px !important"}
                background
                component={
                    <div>
                        <ViewActivity
                            state={state}
                            type={type}
                            viewDialogFunction={closeDialogFunction}
                            openEdit={openEdit}
                            t={t}
                        />
                    </div>
                }
            />
            {/* CloseActivity */}
            <DialogBox
                open={closeDialog}
                padding={"24px"}
                maxWidth={"xs"}
                onClose={() => closeDialogFunction("close")}
                header={`Close Activity`}
                width={"374px !important"}
                background
                component={
                    <div>
                        <CloseActivity
                            state={state}
                            type={type}
                            id={state?.id}
                            closeDialogFunction={closeDialogFunction}
                            getActivityList={getActivityList}
                        />
                    </div>
                }
            />

            {/* fab */}
            {props?.permission?.create && <Fab
                className={language === "ar" ? classes.fabArabic : classes.fab}
                size="large"
                color="primary"
                onClick={() => setViewDialog(true)}
            >
                <SpeedDialIcon />

            </Fab>}
        </>
    );
};
