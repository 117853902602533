import React from 'react';
import { Box, Typography } from "@mui/material";
import { dashboardStyles } from "./style";

export const CardDetails = (props) => {
      const classes = dashboardStyles(props);
      return (
            <div className={classes.card}>
                  <Box className={classes.detailsCard1}>
                        <Box>
                              <Typography className={classes.countCard}>{props?.data?.count}</Typography>
                        </Box>
                        <Box>
                             {
                                   props?.data?.image_type ? <img src={props?.data?.image} alt='images'/> : <Typography>{props?.data?.image}</Typography>
                             }
                        </Box>
                  </Box>
                  <Box >
                        <Typography className={classes.detailsCard2}>{props?.data?.sub}</Typography>
                  </Box>
            </div>
      )
}