import gql from "graphql-tag";


export const GET_PROPERTY = gql`
query {
    property(
      where: {
        client: $client
        company_id: $company_id
        is_active:true
        or: { property_no: { iregex: $search }, name: { iregex: $search } }
      }
    ) {
      id
      name
      logo
      property_no
      address {
        city
      }
    }
  }
`

export const Get_Contact_List = gql`
query {
    community_contacts(
      where: {
        is_active:true,
        is_share:true,
        property_id: $property_id
        name: { iregex: $search }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      contact_number
      country_code
      private:is_share
      profession:professionByID{
        name
      }
      unit {
       count: count_id
       unit_no
       name
      }
  
      posted_by: updated_by {
        first_name
      }
    }
  }
`