import React, { createContext } from 'react'
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from '../../utils'
import { GetMomentAddKey, PropertyUnitType } from './utils/contract'
import uuid from 'uuid'
import moment from 'moment'
import { withNamespaces } from 'react-i18next'
import SelectedProperty from './utils/selectedProperty'
import NonSelectedProperty from './utils/nonSelectedProperty'
import { AlertContext } from '../../contexts'

export const CreateNewEditContext = createContext()

const CreateNewEditContextProvider = (props) => {
    const { t = () => false } = props
    const initialCommissionState = {
        rowid: uuid.v4(),
        pricingFactor: "",
        pricingFactorAmount: "",
        pricingComponent: "",
        error: {
            pricingFactor: "",
            pricingFactorAmount: "",
            pricingComponent: "",
        }
    }

    const initialState = {
        selectedContractType: "",
        vendorContact: "",
        startDate: "",
        endDate: "",
        period: { select: 'Monthly', value: '' },
        selectScopeType: {
            title: "Properties",
            value: "Property",
            selectedIcon: <SelectedProperty />,
            notSelectedIcon: <NonSelectedProperty />,
            disable: true,
        },
        selectedProperty: [],
        selectedUnitProperty: "",
        selectedUnit: [],
        selectedRateConfiguration: "Custom",
        commissionList: [initialCommissionState],
        contractDetail: "",
        termsAndCondition: "",
        deletedScope: [],
        delectedCommission: [],
        error: {
            selectedContractType: "",
            vendorContact: "",
            startDate: "",
            period: "",
            selectScopeType: "",
            selectedProperty: "",
            selectedUnitProperty: "",
            selectedUnit: "",
            selectedRateConfiguration: "",
            contractDetail: "",
            termsAndCondition: "",
        }
    }

    const initialStateData = {
        step: 1,
        contractType: [],
        unit: false,
        type: "create",
        disableBtn: false
    }

    const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
    const alert = React.useContext(AlertContext)

    const [contractData, setContractData] = React.useState(initialState)

    const [stateData, setStateData] = React.useState(initialStateData)

    const [enumValue, setEnumValue] = React.useState({
        lease_type: [],
    });

    const [selectedValue, setSelectedValue] = React.useState('c');
    const [openPopup, setOpenPopup] = React.useState(false)

    // contract table  states
    const [contractList, setContractList] = React.useState([])
    const [count, setCount] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [publish, setPublish] = React.useState(false)
    const [filterData, setFilterData] = React.useState([])

    const updateState = (key, value) => {
        setContractData({ ...contractData, [key]: value, error: { ...contractData?.error, [key]: "" } })
    }

    const updateStateData = (key, value) => {
        setStateData({ ...stateData, [key]: value })
    }

    const resetCommission = (data) => {
        const deleted = contractData?.commissionList?.filter((e) => (!contractData?.delectedCommission?.includes(e?.id) && e?.id))?.map((i) => i?.id)
        setContractData({
            ...contractData,
            selectedRateConfiguration: data?.value,
            commissionList: [initialCommissionState],
            delectedCommission: [...contractData?.delectedCommission, ...deleted]
        })
    }

    const addCommission = () => {
        updateState("commissionList", [...contractData?.commissionList,
        {
            rowid: uuid.v4(),
            pricingFactor: "",
            pricingFactorAmount: "",
            pricingComponent: "",
            error: initialCommissionState?.error
        }])
    }

    const updateCommissionState = (key, value, rowData) => {
        let tempArray = contractData?.commissionList?.map((d) => {
            if (d?.rowid === rowData?.rowid) {
                let error = d?.error
                error[key] = ""
                return { ...d, [key]: value, error }
            }
            else {
                return d
            }
        })
        updateState("commissionList", tempArray)
    }

    const removeCommissionList = (data) => {
        let deleted = [...contractData?.delectedCommission, data?.id]
        // updateState("commissionList", contractData?.commissionList?.filter?.(item => item?.rowid !== data.rowid))
        setContractData({
            ...contractData,
            commissionList: contractData?.commissionList?.filter?.(item => item?.rowid !== data.rowid),
            delectedCommission: deleted
        })
    }

    const addUnit = () => {
        updateStateData("unit", false)
    }

    const handleTypeCard = (value) => {
        if (contractData?.selectedContractType?.value !== "Landlord") {
            if (contractData?.selectScopeType?.value === "Unit") {
                if (Object.keys(contractData?.selectedUnitProperty)?.length !== 0 && contractData?.selectedUnit?.length !== 0) {
                    setContractData({ ...contractData, error: { ...contractData?.error, selectScopeType: t("Please Remove all Unit and Property") } })
                } else if (Object.keys(contractData?.selectedUnitProperty)?.length !== 0) {
                    setContractData({ ...contractData, error: { ...contractData?.error, selectScopeType: t("Please Remove all Property") } })
                } else {
                    updateState("selectScopeType", value)
                }
            } else {
                if (contractData?.selectedProperty?.length !== 0) {
                    setContractData({ ...contractData, error: { ...contractData?.error, selectScopeType: t("Please Remove all Property") } })
                } else {
                    updateState("selectScopeType", value)
                }
            }
        }
    }

    const manualResponse = (array) => array?.map(i => {
        return {
            ...i,
            label: `${i?.first_name}`,
            value: i?.contact_id,
        }
    });

    const manualPropertyResponse = (array) => array?.data?.map((i) => {
        return {
            ...i,
            label: i?.property_name,
            value: i?.property_id,
            url: i?.property_assets?.[0]?.url
        }
    })

    const manualUnitResponse = (array) => array?.data?.map((i) => {
        return {
            ...i,
            label: i?.unit_name,
            value: i?.unit_id,
            url: i?.unit_assets?.[0]?.url
        }
    })

    const manualAccountResponse = (array) => array?.list?.map?.((i) => {
        return {
            ...i,
            label: i?.name,
            value: i?.id
        }
    })

    const manualContractTypeResponse = (array) => array?.data?.map((i) => {
        return {
            ...i,
            label: `${i?.name} Contract`,
            url: "/images/landlordContract.svg",
        }
    })

    const handleContractType = (value) => {
        if (contractData?.selectedContractType?.value === "Landlord") {
            let deleted = contractData?.selectedUnit?.length > 0 ? contractData?.selectedUnit?.map((e) => {
                return e?.scope_id && e?.scope_id
            }) : []
            setContractData({
                ...contractData,
                selectedContractType: value,
                vendorContact: "",
                selectScopeType: stateData?.type === "edit" ? contractData?.selectScopeType : value?.value === "Landlord" ? PropertyUnitType(t)?.[1] : Object.keys(contractData?.selectScopeType)?.length > 0 ? contractData?.selectScopeType : PropertyUnitType(t)?.[0],
                deletedScope: [...contractData?.deletedScope, ...deleted],
                error: { ...contractData?.error, selectedContractType: "", vendorContact: "" }
            })
        } else {
            setContractData({
                ...contractData,
                selectedContractType: value,
                vendorContact: "",
                selectScopeType: stateData?.type === "edit" ? contractData?.selectScopeType : value?.value === "Landlord" ? PropertyUnitType(t)?.[1] : Object.keys(contractData?.selectScopeType)?.length > 0 ? contractData?.selectScopeType : PropertyUnitType(t)?.[0],
                error: { ...contractData?.error, selectedContractType: "", vendorContact: "" }
            })
        }

    }

    const handleChangeProperty = (value) => {
        if (contractData?.selectedProperty?.includes(value)) {
            updateState("selectedProperty", contractData?.selectedProperty?.filter((i) => i?.value !== value?.value))
        } else {
            updateState("selectedProperty", [...contractData?.selectedProperty, value])
        }
    }

    const handleChangeUnitProperty = (value) => {
        setContractData({
            ...contractData, selectedUnitProperty: value,
            selectedUnit: [],
            error: { ...contractData?.error, selectedUnitProperty: "" }
        })
    }

    const handleChangeUnit = (value) => {
        if (contractData?.selectedUnit?.includes(value)) {
            updateState("selectedUnit", contractData?.selectedUnit?.filter((i) => i?.value !== value?.value))
        } else {
            updateState("selectedUnit", [...contractData?.selectedUnit, value])
        }
    }

    const handleDeleteProperty = (value) => {
        let deleted = []
        if (value?.scope_id) {
            deleted?.push(value?.scope_id)
        }

        setContractData({
            ...contractData,
            selectedProperty: contractData?.selectedProperty?.filter((i) => i?.value !== value?.value),
            deletedScope: [...contractData?.deletedScope, ...deleted]
        })
    }

    const handleDeleteUnit = (value) => {
        let deleted = []
        if (value?.scope_id) {
            deleted?.push(value?.scope_id)
        }
        setContractData({
            ...contractData,
            selectedUnit: contractData?.selectedUnit?.filter((i) => i?.value !== value?.value),
            deletedScope: [...contractData?.deletedScope, ...deleted]
        })
    }

    const handleDeleteUnitProperty = (e) => {
        let deleted_scope = contractData?.selectedUnit?.filter((e) => e?.scope_id && contractData?.deletedScope?.includes(e?.scope_id))?.map((i) => i?.scope_id)
        setContractData({
            ...contractData,
            deletedScope: [...contractData?.deletedScope, ...deleted_scope],
            selectedUnitProperty: "",
            selectedUnit: [],
            error: {
                ...contractData?.error,
                selectedUnitProperty: ""
            }
        })
    }

    const handleStartDate = (value) => {
        const endDate = contractData?.period?.value ? moment(value).add(contractData?.period?.value ?? 0, GetMomentAddKey[contractData?.period?.select]).format("YYYY-MM-DD") : value
        setContractData({
            ...contractData, startDate: value, endDate: endDate,
            error: { ...contractData?.error, startDate: "" }
        })
    }

    const handlePeriod = (value) => {
        const endDate = moment(contractData?.startDate).add(value?.value ?? 0, GetMomentAddKey[value?.select]).subtract(1, "days").format("YYYY-MM-DD")
        setContractData({
            ...contractData, period: value, endDate: endDate,
            error: { ...contractData?.error, period: "" }
        })
    }

    const validate = () => {
        let error = contractData?.error
        let isValid = true

        if (stateData?.step === 1) {
            if (contractData?.selectedContractType === "") {
                isValid = false
                error.selectedContractType = t("Contract Type is Required")
            }
            if (contractData?.vendorContact === "") {
                isValid = false
                error.vendorContact = contractData?.selectedContractType?.value === "Employee" ? t("Contact is Required") : t("Account is Required")
            }
            if (contractData?.startDate === "") {
                isValid = false
                error.startDate = t("Start date is Required")
            }
            if (contractData?.period?.value === "") {
                isValid = false
                error.period = t("Period is Required")
            }
        } else if (stateData?.step === 2) {
            if (contractData?.selectScopeType?.value === "Property" && contractData?.selectedProperty?.length === 0) {
                isValid = false
                error.selectedProperty = t("Property is Required")
            }
            if (contractData?.selectScopeType?.value === "Unit" && contractData?.selectedUnit?.length === 0) {
                isValid = false
                error.selectedUnit = t("Unit is Required")
            }

            if (stateData?.unit && contractData?.selectScopeType?.value === "Unit") {
                if (contractData?.selectedUnit?.length === 0) {
                    isValid = false
                    error.selectedUnit = t("Unit is Required")
                }

                if (contractData?.selectedUnitProperty === "") {
                    isValid = false
                    error.selectedUnitProperty = t("Property is Required")
                }
            }


        }
        // else if (stateData?.step === 3) {
        //     if (contractData?.selectedRateConfiguration === "Custom") {
        //         return true
        //     } else {
        //         let temp_commissionList = contractData?.commissionList?.map((data, i) => {
        //             let error = data?.error

        //             if (data?.pricingFactorAmount?.length === 0) {
        //                 isValid = false;
        //                 error.pricingFactorAmount = t("Pricing Factor Amount is Required")
        //             }
        //             if (contractData?.selectedRateConfiguration === "Percentage Base") {
        //                 if (data?.pricingComponent?.length === 0) {
        //                     isValid = false;
        //                     error.pricingComponent = t("Pricing Component is Required")
        //                 }
        //             } else {
        //                 if (data?.pricingFactor?.length === 0) {
        //                     isValid = false;
        //                     error.pricingFactor = t("Pricing Factor is Required")
        //                 }
        //             }

        //             return { ...data, error }
        //         })


        //         updateState("commissionList", temp_commissionList)
        //     }
        // } 
        else if (stateData?.step === 3) {
            if (contractData?.contractDetail === "") {
                isValid = false
                error.contractDetail = t("Contract Details is Required")
            } else if (contractData?.contractDetail === "<p><br></p>") {
                isValid = false
                error.contractDetail = t("Contract Details is Required")
            }

            if (contractData?.termsAndCondition === "") {
                isValid = false
                error.termsAndCondition = t("Terms and Condition is Required")
            } else if (contractData?.termsAndCondition === "<p><br></p>") {
                isValid = false
                error.termsAndCondition = t("Terms and Condition is Required")
            }
        }
        setContractData({ ...contractData, error })
        return isValid
    }
    const handlePrevious = () => {
        if (stateData?.unit) {
            updateStateData('unit', false)
            setContractData({
                ...contractData, selectedUnitProperty: "", selectedUnit: "",
                error: { ...contractData?.error, selectedUnit: "", selectedUnitProperty: "", selectScopeType: "" }
            })
        } else {
            stateData?.step - 1 >= 1 && updateStateData("step", stateData?.step - 1)
        }
    }

    const handleNext = () => {
        if (stateData?.type === "view") {
            if (stateData?.step === 3) {
                setStateData({ ...stateData, type: "edit", step: 1 })
            } else {
                updateStateData("step", stateData?.step + 1)
            }
        } else if (stateData?.step === 1) {
            if (validate()) {
                updateStateData("step", stateData?.step + 1)
            }
        } else if (stateData?.step === 2) {
            if (validate()) {
                updateStateData("step", stateData?.step + 1)
            }
            if (stateData?.unit) {
                if (validate()) {
                    addUnit()
                }
            }
        }
        // else if (stateData?.step === 3) {
        //     if (validate()) {
        //         updateStateData("step", stateData?.step + 1)
        //     }
        // }
        else if (stateData?.step === 3) {
            if (validate()) {
                createContract()
            }
        }
    }


    const createContract = () => {
        if (validate()) {
            updateStateData("disableBtn", true)
            const propertyScope = contractData?.selectedProperty?.map((e) => {
                return {
                    id: e?.scope_id ?? undefined,
                    property_id: e?.value,
                    scope_type: contractData?.selectScopeType?.value
                }
            })

            const unitScope = contractData?.selectedUnit?.map((e) => {
                return {
                    id: e?.scope_id ?? undefined,
                    unit_id: e?.value,
                    scope_type: contractData?.selectScopeType?.value
                }
            })

            const customCommission = contractData?.commissionList?.map((e) => {
                return {
                    commission_rate_id: e?.pricingFactor?.value ?? undefined,
                    currency_id: selectedCompany?.currency_id ?? undefined,
                    commission_rate_type: contractData?.selectedRateConfiguration ?? undefined,
                    value: Number(e?.pricingFactorAmount) ?? undefined,
                    pricing_component_id: e?.pricingComponent?.value ?? undefined,
                    id: e?.id ?? undefined
                }
            })

            const payload = {
                id: contractData?.id ?? undefined,
                contract_type: contractData?.selectedContractType?.id,
                start_date: moment(contractData?.startDate).format("YYYY-MM-DD") ?? undefined,
                end_date: contractData?.endDate ?? undefined,
                account_id: contractData?.selectedContractType?.value !== "Employee" ? contractData?.vendorContact?.value : undefined,
                contact_id: contractData?.selectedContractType?.value === "Employee" ? contractData?.vendorContact?.value : undefined,
                scopes: contractData?.selectScopeType?.value === "Property" ? propertyScope : unitScope,
                contract_details: contractData?.contractDetail ?? undefined,
                term_condition: contractData?.termsAndCondition ?? undefined,
                company_id: selectedCompany?.value,
                deleted_scopes: contractData?.deletedScope ?? [],
                deleted_commission: contractData?.delectedCommission ?? [],
                commission: contractData?.selectedRateConfiguration === "Custom" ? [{ commission_rate_type: contractData?.selectedRateConfiguration ?? undefined }] : customCommission,
                period: contractData?.period?.select,
                period_value: contractData?.period?.value
                // contract_type_value: state?.viewData?.type?.value
            }
            NetworkCall(
                `${config.api_url}/contract/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                handleOnClose()
                getContractList(0, "", selectedCompany?.value)
            }).catch((err) => {
                alert.setSnack({
                    ...alert, open: true, severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                })
            })
        }
    }
    const handleCreate = (type) => {
        setOpenPopup(!openPopup)
        if (type === "create") {
            setContractData(initialState)
            setStateData(initialStateData)
            setSelectedValue("c")
        }
    }

    const handleOnClose = () => {
        setOpenPopup(!openPopup)
        setContractData(initialState)
        setStateData(initialStateData)
    }

    const handleViewEdit = (type, data) => {
        setStateData({ ...stateData, type: type, isPublished: data?.status === "Published" ? true : false })
        const payload = {
            contract_id: data?.id
        }
        NetworkCall(
            `${config.api_url}/contract/get_contract`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {

            const resData = res?.data?.data

            const configType = res?.data?.data?.commission?.[0]?.commission_rate_type

            const unitProperty = () => {
                const properties = resData?.scope?.map((e) => {
                    return e?.unit?.property
                })
                const data = Array.from(new Set(properties?.map(JSON.stringify))).map(JSON.parse)
                return data
            }

            const propertyData = resData?.scope?.map((e) => {
                return {
                    ...e?.property,
                    scope_id: e?.id
                }
            })

            const unitData = resData?.scope?.map((e) => {
                return {
                    ...e?.unit,
                    scope_id: e?.id,
                }
            })

            const radioValue = {
                "Fixed Pricing": "a",
                "Percentage Base": "b",
                "Custom": "c"
            }

            let constructedCommission = resData?.commission?.map((e) => {
                return {
                    rowid: uuid.v4(),
                    id: e?.id,
                    pricingFactor: { label: e?.commission_config?.name, value: e?.commission_config?.id },
                    pricingFactorAmount: e?.value,
                    pricingComponent: e?.pricing_component,
                    commission_rate_type: configType,
                    error: {
                        pricingFactor: "",
                        pricingFactorAmount: "",
                        pricingComponent: "",
                    }
                }
            })
            let constructedData = {
                id: resData?.id,
                selectedContractType: { label: `${resData?.type?.name} Contract`, ...resData?.type },
                vendorContact: resData?.contact ?? resData?.account,
                startDate: resData?.start_date ? new Date(resData?.start_date) : "",
                endDate: resData?.end_date,
                selectScopeType: {
                    ...PropertyUnitType(t)?.filter((i) => i?.value === resData?.scope?.[0]?.scope_type)?.[0],
                    disable: true
                },
                selectedProperty: resData?.scope?.[0]?.scope_type === "Property" ? propertyData : [],
                selectedUnitProperty: resData?.scope?.[0]?.scope_type === "Unit" ? unitProperty()?.[0] : "",
                selectedUnit: resData?.scope?.[0]?.scope_type === "Unit" ? unitData : [],
                selectedRateConfiguration: configType,
                commissionList: constructedCommission,
                contractDetail: resData?.contract_details,
                termsAndCondition: resData?.term_condition,
                deletedScope: [],
                delectedCommission: [],
                period: { value: resData?.period_value, select: resData?.period },
                error: {
                    selectedContractType: "",
                    vendorContact: "",
                    startDate: "",
                    period: "",
                    selectScopeType: "",
                    selectedProperty: "",
                    selectedUnitProperty: "",
                    selectedUnit: "",
                    selectedRateConfiguration: "",
                    contractDetail: "",
                    termsAndCondition: "",
                }
            }
            setContractData(constructedData)
            setSelectedValue(radioValue[configType])
            handleCreate(type)

        }).catch((err) => {
            console.log("err", err)
            alert.setSnack({
                ...alert, open: true, severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    // contract table 

    const getContractList = (offset = 0, search = "", companyId = selectedCompany?.value, hide = false, filter = filterData) => {
        let constructed_status_id = filter?.status?.map((e) => e?.value)
        let constructed_account_num = filter?.accountNumber?.map((e) => e?.value)
        setLoading(true)
        const payload = {
            offset: offset,
            limit: 10,
            search: search,
            filter: {
                status_id: constructed_status_id ?? [],
                account_id: constructed_account_num ?? []
            },
            company_id: companyId
        }
        NetworkCall(
            `${config.api_url}/contract/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            let result = res?.data?.data?.data?.map((e) => {
                return {
                    id: e?.id,
                    contract_id: e?.contract_no ?? "-",
                    date: moment(e?.created_at).format("YYYY-MM-DD"),
                    contract_type: e?.type?.value ?? "-",
                    account_id: e?.account?.account_no ?? "-",
                    account_name: e?.account?.name ?? "-",
                    start_date: moment(new Date(e?.start_date)).format("YYYY-MM-DD"),
                    end_date: moment(new Date(e?.end_date)).format("YYYY-MM-DD"),
                    status: e?.contract_status?.name ?? "-",
                    created_by: e?.created_person ?? "-",
                }
            })
            setCount(res?.data?.data?.count)
            setContractList(result)
            setLoading(false)
            if (!hide) {
                setPublish(false)
            }

        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }
    return (
        <CreateNewEditContext.Provider
            value={{
                contractData,
                updateState,
                stateData,
                enumValue,
                updateStateData,
                setContractData,
                selectedCompany,
                resetCommission,
                addCommission,
                updateCommissionState,
                removeCommissionList,
                handleTypeCard,
                manualResponse,
                manualPropertyResponse,
                manualUnitResponse,
                manualAccountResponse,
                handlePrevious,
                handleNext,
                handleChangeProperty,
                handleChangeUnitProperty,
                handleChangeUnit,
                handleStartDate,
                handlePeriod,
                t,
                selectedValue,
                setSelectedValue,
                handleViewEdit,
                openPopup,
                setOpenPopup,
                handleCreate,
                handleOnClose,
                contractList,
                setContractList,
                count,
                setCount,
                loading,
                setLoading,
                publish,
                setPublish,
                getContractList,
                filterData,
                setFilterData,
                handleDeleteUnit,
                manualContractTypeResponse,
                handleDeleteProperty,
                handleDeleteUnitProperty,
                handleContractType,
                setEnumValue
            }}
        >
            {props.children}
        </CreateNewEditContext.Provider>
    );
}

export default withNamespaces("contract")(CreateNewEditContextProvider)