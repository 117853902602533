import { Avatar, Box, Divider, Grid, Popover, Stack, Typography } from '@mui/material'
import React from 'react'
import { ChooseCard } from './components/chooseCard'
import { StepsStyle } from './style'
import { DatePickerNew, SelectBox, TextBox } from '../../components'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { durationOption } from './utils/residentOnboard'
import { DurationBox } from '../../components/durationbox'
import { TypeCard } from './components/typeCard'
import { stringAvatar } from '../../utils'
import { DeleteIcon } from '../../assets'
import { NewRateConfiguration } from '../../screens/contract/components/newRateConfiguration'
import ReactQuill from 'react-quill'
import { ChooseCardWithSelect } from './components/chooseCardWithSelect'
import { CustomToggleButton } from './components/customToggleButton'

export const NewFormStep = ({ component = [] }) => {
    const classes = StepsStyle()

    // popover 

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, false] }],
                [{ color: [] }, { background: [] }],
                ["bold", "italic", "underline", "strike"],
                [{ align: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image"],
                ["emoji"],
                ["code-block"],
            ],
        },
        "emoji-toolbar": true,
        "emoji-textarea": true,
        "emoji-shortname": true,
    }

    const readOnlyModules = {
        toolbar: false,
    };

    return (
        <Grid container spacing={2}>
            {
                // eslint-disable-next-line
                component?.map((val) => {
                    if (val?.isActive) {
                        switch (val?.type) {
                            case "stepsCountWithLabel":
                                return (
                                    <Grid item md={12} sm={12} lg={12}>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <Typography className={classes.stepCount}>{val?.count ?? "01"}</Typography>
                                            <Typography className={classes.stepLabel} sx={{ marginInlineStart: 1 }}>{val?.label ?? ""}
                                                {val?.isRequired && <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>*</Typography>}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                )
                            case "typeCard":
                                return (
                                    <Grid item md={12} sm={12} lg={12}>
                                        <TypeCard
                                            data={val?.data}
                                            handleClick={val?.handleClick}
                                            selected={val?.selected}
                                            header={val?.header}
                                            error={val?.error}
                                            errorMsg={val?.errorMsg}
                                            isRequired={val?.isRequired}
                                            isReadOnly={val?.isReadOnly}
                                        />
                                    </Grid>
                                )
                            case "chooseComponent":
                                return (
                                    <Grid item md={12} sm={12} lg={12}>
                                        <ChooseCard
                                            header={val?.header}
                                            placeholder={val?.placeholder}
                                            title={val?.title}
                                            subTitle={val?.subTitle}
                                            noSelectedTitle={val?.noSelectedTitle}
                                            icon={val?.icon}
                                            options={val?.options}
                                            showRemove={val?.showRemove}
                                            visible={val?.visible}
                                            handleRemove={val?.handleRemove}
                                            topHeader={val?.topHeader}
                                            onchange={val?.onchange}
                                            value={val?.value}
                                            onDelete={val?.onDelete}
                                            loadOptions={val?.loadOptions}
                                            error={val?.error}
                                            errorMsg={val?.errorMsg}
                                            isRequired={val?.isRequired}
                                            isMulti={val?.isMulti}
                                            onDeleteMuti={val?.onDeleteMuti}
                                            onDeleteMutiCheck={val?.onDeleteMutiCheck}
                                            key={val?.key}
                                            menuPlacement={val?.menuPlacement}
                                            border={val?.border}
                                            isReadOnly={val?.isReadOnly}
                                            boldHeader={val?.boldHeader}
                                            isBorder={val?.isBorder}
                                            menuOptionHeight={val?.menuOptionHeight}
                                        />
                                    </Grid>
                                )
                            case "addButton":
                                return (
                                    <>
                                        {
                                            !val?.hidden &&
                                            <Grid item md={12} sm={12} lg={12}>
                                                <Stack>
                                                    <Typography className={classes.addBtn} onClick={val?.click}>{val?.text}</Typography>
                                                    {val?.error && <Typography mt={1} variant={"caption"} color={"error"}>{val?.errorMsg}</Typography>}
                                                </Stack>
                                            </Grid>
                                        }
                                    </>
                                )
                            case "multiLineText":
                                return (
                                    <Grid item md={12} sm={12} lg={12}>
                                        <Stack spacing={1}>
                                            <Typography className={classes.header}>{val?.header}{val?.isRequired && <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                                *
                                            </Typography>}</Typography>
                                            <TextBox
                                                multiline
                                                value={val?.value}
                                                isError={val?.error}
                                                errorMessage={val?.errorMsg}
                                                onChange={(e) => val?.handleChange(e.target.value)}
                                                label=""
                                                placeholder={val?.placeholder ?? ""}
                                                isReadonly={val?.isReadOnly}
                                            />
                                        </Stack>
                                    </Grid>
                                )
                            case "textWithSelect":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <TextBox
                                            id={"textWithSelect"}
                                            type={"number"}
                                            label={val?.header}
                                            placeholder=""
                                            value={val?.value}
                                            onChange={(value) => val?.handleChange(value.target.value)}
                                            endAdornment={
                                                <Stack direction={"row"}
                                                    spacing={1}
                                                    alignItems={"center"}
                                                    onClick={(event) => !val?.isReadOnly && handleClick(event)}
                                                    className={classes.endAdornment}
                                                    sx={{ height: `${val?.customHeight - 6}px !important` }}
                                                    justifyContent={"space-between"}
                                                >
                                                    <Typography className={classes.selectedValue} noWrap>
                                                        {val?.selectedValue?.label}
                                                    </Typography>
                                                    <KeyboardArrowDownIcon sx={{ fontSize: "0.875rem", marginTop: "4px" }} />
                                                </Stack>
                                            }
                                            height={val?.customHeight ?? "auto"}
                                            padding={val?.customPadding}
                                            isReadonly={val?.isReadOnly}
                                            textAlign={val?.textAlign ?? "left"} />
                                        <Popover
                                            id={"textWithSelect"}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClick}
                                            anchorOrigin={{ vertical: "bottom", horizontal: "left", }}>
                                            <Box p={1}>
                                                {
                                                    val?.customOption?.map((e, i, data) => {
                                                        return (
                                                            <>
                                                                <Typography
                                                                    className={classes.selectedValue}
                                                                    onClick={() => {
                                                                        val?.selectChange(e)
                                                                        handleClick()
                                                                    }}
                                                                    sx={{ cursor: "pointer", width: 60, padding: "4px" }}
                                                                >
                                                                    {e?.label}
                                                                </Typography>
                                                                {data?.length - 1 !== i && <Divider></Divider>}
                                                            </>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Popover>
                                    </Grid>
                                )
                            case "datePicker":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <DatePickerNew
                                            label={val?.header}
                                            placeholder={val?.header}
                                            value={val?.value}
                                            handleChange={(value) => val?.handleChange(value)}
                                            isrequired={val?.isrequired}
                                            isReadonly={val?.isReadOnly}
                                            isError={val?.isError}
                                            errorMessage={val?.errorMessage}
                                        />
                                    </Grid>
                                )
                            case "selectBox":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <SelectBox
                                            label={val?.header}
                                            placeholder={val?.header ?? val?.placeholder}
                                            onChange={(value) => val?.handleChange(value)}
                                            value={val?.value}
                                            options={val?.options}
                                            isRequired={val?.isrequired}
                                            isError={val?.isError}
                                            errorMessage={val?.errorMessage}
                                            isReadOnly={val?.isReadOnly}
                                            fontFamily={val?.fontFamily}
                                            menuPlacement={val?.menuPlacement}
                                        />
                                    </Grid>
                                )
                            case "customSelectBox":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <SelectBox
                                            isRequired={val?.isRequired}
                                            label={val?.header}
                                            placeholder={val?.placeholder}
                                            borderRadius={"8px"}
                                            options={val?.options}
                                            loadOptions={val?.loadOptions}                                            // isPaginate
                                            debounceTimeout={800}
                                            onChange={(value) => {
                                                val?.handleChange(value)
                                            }}
                                            color={val?.color}
                                            selectHeight="40px"
                                            menuOptionHeight={val?.menuOptionHeight ?? 300}
                                            isPaginate={true}
                                            value={val?.value}
                                            noSearch={true}
                                            isError={val?.isError}
                                            errorMessage={val?.errorMessage}
                                            isReadOnly={val?.isReadOnly}
                                            menuPlacement={val?.menuPlacement}
                                            customOptionComponent={(props) => {
                                                return (
                                                    <Stack direction={"row"} p={0.5} alignItems={"center"} className={classes.selectParent} style={{ border: (props?.isSelected) ? "1px solid #5078E1" : "1px solid #E4E8EE", backgroundColor: (props?.isSelected) ? "#F1F7FF" : "#fff", margin: "5px" }}>
                                                        <Avatar className={val?.roundedAvatar ? classes.roundedAvatar : classes.optionAvatar} src={props?.data?.url}></Avatar>
                                                        <Stack>
                                                            <Typography className={classes.optionHeader}>{props?.data?.label}</Typography>
                                                            {
                                                                !val?.hideLabel && props?.data?.value &&
                                                                <Typography className={classes.optionSubTitle}>{props?.data?.value}</Typography>
                                                            }
                                                        </Stack>
                                                    </Stack>
                                                )
                                                // <div style={{ padding: "8px", backgroundColor: props?.isSelected ? "red" : "white" }}>{`${props?.data?.label} - ${props?.isSelected ? "Selected" : "Unselected"}`}</div>
                                            }}
                                        />
                                        {!val?.withoutSelectedBox && <Box mt={"10px"}>
                                            {
                                                val?.value &&
                                                <Stack direction={"row"} spacing={2} p={0.5} alignItems={"center"} className={classes.selectParent} style={{ border: "1px solid #E4E8EE", backgroundColor: "#fff", padding: "12px" }}>
                                                    <Box className={classes.AccountImg1}>
                                                        {/* <AccountIMG color="#98A0AC" /> */}
                                                        <Avatar className={classes.optionAvatar} src={val?.value?.url}></Avatar>

                                                    </Box>
                                                    <Stack>
                                                        <Typography className={classes.optionHeader}>{val?.value?.label}</Typography>
                                                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                            <Typography className={classes.optionSubTitle}>{val?.value?.value1}</Typography>
                                                            {/* <Box className={classes.dot}></Box>
                                                            <Typography className={classes.optionSubTitle}>{val?.value?.value1}</Typography> */}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            }
                                        </Box>}




                                    </Grid>
                                )
                            case "duration":
                                return (
                                    <Grid
                                        item md={val?.md} sm={val?.sm} lg={val?.lg}
                                    >
                                        <DurationBox
                                            val={val}
                                            value={val?.value}
                                            options={val?.options}
                                            onChange={val?.onChange}
                                            isError={val?.isError}
                                            errorMessage={val?.errorMessage}
                                            isReadonly={val?.isReadOnly}
                                            isRequired={val?.isRequired}
                                            marginBottom={val?.marginBottom ?? "8px"}
                                            menuPlacement={val?.menuPlacement ?? "auto"}
                                            size={val?.item_size}
                                        />
                                    </Grid>
                                );
                            case "showSelectedList":
                                return (
                                    <Grid item sm={val?.sm} md={val?.md} lg={val?.lg}>
                                        <Typography className={classes.header}>{val?.header} {val?.count ?? ""}</Typography>

                                        <Stack rowGap={'12px'} mt={"12px"}>
                                            {
                                                val?.value?.map((element, i) => {
                                                    return (
                                                        <Stack direction={"row"} p={1} alignItems={"center"} className={classes.selectedListCard} justifyContent={"space-between"}>
                                                            <Stack direction={"row"}>
                                                                <Avatar className={classes.optionAvatar} {...stringAvatar(element?.label)} src={element?.unit_assets?.[0]?.url}></Avatar>
                                                                <Stack marginInlineStart={'8px'}>
                                                                    <Stack direction={"row"} spacing={1} divider={<Divider orientation="vertical" flexItem sx={{ height: "10px", marginTop: "4px !important" }} />}>
                                                                        <Typography className={classes.optionHeader}>{element?.label ?? ""}</Typography>
                                                                        <Typography className={classes.optionHeader}>{element?.type?.name ?? ""}</Typography>
                                                                    </Stack>
                                                                    <Stack direction={"row"} spacing={2} alignItems={"center"} sx={{ width: "100%" }}>
                                                                        <Typography className={classes.optionSubTitle} noWrap>{element?.address?.city ?? element?.mobileNo ?? element?.label1}</Typography>
                                                                        <Typography className={classes.optionSubTitle} noWrap>, {element?.address?.state}</Typography>
                                                                    </Stack>
                                                                </Stack>
                                                            </Stack>
                                                            {
                                                                !val?.isReadOnly &&
                                                                <DeleteIcon style={{ cursor: "pointer" }} onClick={() => val?.onDelete(element)} />
                                                            }
                                                        </Stack>
                                                    )
                                                })
                                            }
                                        </Stack>
                                    </Grid>
                                )
                            case "rateConfiguration":
                                return (
                                    <Grid item sm={val?.sm} md={val?.md} lg={val?.lg}>
                                        <NewRateConfiguration t={val?.t} />
                                    </Grid>
                                )
                            case "description":
                                return (
                                    <Grid item sm={val?.sm} md={val?.md} lg={val?.lg}>
                                        {val?.header &&
                                            <Typography className={classes.header} mb={"10px"}>
                                                {val?.header}{val?.isRequired && <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                                    *
                                                </Typography>}
                                            </Typography>}
                                        <ReactQuill
                                            onChange={(value) => val?.onChange(value)}
                                            value={val?.value}
                                            modules={val?.isReadOnly ? readOnlyModules : modules}
                                            className={classes.reactQuil}
                                            readOnly={val?.isReadOnly}
                                            theme={val?.isReadOnly ? "bubble" : "snow"}
                                        />
                                        {
                                            val?.error && (
                                                <Typography variant={"caption"} color={"error"}>
                                                    {val?.errorMsg}
                                                </Typography>
                                            )
                                        }
                                    </Grid>
                                )
                            case "headingText":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <Typography className={classes.headingText}>{val?.heading}</Typography>
                                    </Grid>
                                )
                            case "chooseCardWithSelect":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <ChooseCardWithSelect
                                            menuOptionHeight={"200px"}
                                            menuPlacement={"auto"}
                                            placeholder={val?.placeholder}
                                            loadOptions={val?.loadOptions}
                                            isReadOnly={val?.isReadOnly}
                                            onchange={val?.onchange}
                                            border={val?.isBorder}
                                            header={val?.header}
                                            isRequired={val?.isRequired}
                                            selectBoxHeader={val?.selectBoxHeader}
                                            value={val?.value}
                                            onDelete={val?.onDelete}
                                            secPlaceholder={val?.secPlaceholder}
                                            secLoadOptions={val?.secLoadOptions}
                                            secIsReadOnly={val?.secIsReadOnly}
                                            secOnchange={val?.secOnchange}
                                            secValue={val?.secValue}
                                            isError={val?.isError}
                                            errorMsg={val?.errorMsg}
                                            isSecError={val?.isSecError}
                                            errorSecMsg={val?.errorSecMsg} />
                                    </Grid>
                                )
                            case "customToggleButton":
                                return (
                                    <Grid item md={val?.md} sm={val?.sm} lg={val?.lg}>
                                        <CustomToggleButton
                                            header={val?.header}
                                            isRequired={val?.isRequired}
                                            list={val?.list}
                                            value={val?.value}
                                            onSelect={val?.onSelect}
                                            isReadOnly={val?.isReadOnly} />
                                        {
                                            val?.error && (
                                                <Typography variant={"caption"} color={"error"}>
                                                    {val?.errorMsg}
                                                </Typography>
                                            )
                                        }
                                    </Grid>
                                )
                            default:
                                break;
                        }
                    }
                })
            }
        </Grid>
    )
}