import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import BusinessMasterComponent from "../../components";

const useStyles = makeStyles((theme) => ({
    root: {

    },
}));



export const BusinessMaster = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <BusinessMasterComponent />
        </div>
    );
};
