import React from 'react';
import { TemplateAmount } from '../../../assets'
import {
    Box, Stack, Typography, Button
} from "@mui/material";
import {  TemplateDrawer } from '../../../components';
import { useApolloClient } from "@apollo/client";
import { getMailstoneTemplate } from "../../../graphql/quotationQueries";
import { LocalStorageKeys, AlertProps } from "../../../utils";
import { MileStoneTemplate } from "../../createquotation/component/mileStoneTemplate";
import {
    saledataTypequtation,
    saleheadingqutation,
    salepathqutation,
} from "../../../utils/createQuotationUtils";
import { AlertContext } from '../../../contexts/index';
import { useStylesDetailsCard } from '../../createquotation/component/styles';
import { HoleNumber } from '../function';


export const TemplateComponent = ({
    symbol = "$",
    total_quote_amount = 0,
    contract_start_date,
    t=()=>false,
    onSumbit=()=>false,
    totalValue={},
    total,
    ...rest
}) => {
    const client = useApolloClient();
    const alert = React.useContext(AlertContext);

    // states
    const [openDialog, setOpenDialog] = React.useState(false)
    const [valuetType, setValuetType] = React.useState("")
    const [remainingBalance,setReainingBalance]=React.useState(total??0)
    const [dataNew , setDataNew]= React.useState({})

    const getTotal = (data) => {
        // get total count
        let totalCount = data.reduce(function (prev, current) {
            return prev + +current.percentage_value;
        }, 0);

        return totalCount;
    };
    const getCalculation = (amount = 0, percentage) => {
        return (((Number(total) - Number(amount)) * percentage) / 100)
    }
    const getTemplate = (value, newSelect) => {
        client
            .query({
                query: getMailstoneTemplate,
                fetchPolicy: "network-only",
                variables: {
                    client: localStorage.getItem(LocalStorageKeys.clinetID),
                    template_id: value?.value,
                },
            })
            .then((response) => {
                if (value?.template_type === "percentage") {
                    setValuetType("percentage")
                }
                else if (value?.template_type === "amount") {
                    setValuetType("amount")
                }
                else {
                    setValuetType("variable")
                }
                const data = response?.data?.milestone_template_items?.map((val) => {
                    return { ...val, milestone: `${val?.delivery_milestone_masters?.name}`, percentage_value: `${val?.percentage} ${value?.template_type === "percentage" ? '%' : ''}`,payment_date:rest?.quote_details?.contract_start_date ? new Date(rest?.quote_details?.contract_start_date) : null };
                });
                if (value?.template_type === "amount") {

                    return setDataNew({
                        ...dataNew,
                        quotation_milestone:value,
                        payment_period: "Milestone Based",
                        delivery_timestone_template: value,
                        milestoneTemplate: value,
                        mailstoneLists: response?.data?.milestone_template_items?.map((val, index) => {
                            if (index === 0) {
                                return { ...val, milestone: `${val?.delivery_milestone_masters?.name}`, amount_text_value: "", percentage_value: val?.percentage,payment_date:rest?.quote_details?.contract_start_date ? new Date(rest?.quote_details?.contract_start_date) : null };
                            }
                            else {
                                return { ...val, milestone: `${val?.delivery_milestone_masters?.name}`, percentage_value: getCalculation(0, val?.percentage ?? 0) ,payment_date:rest?.quote_details?.contract_start_date ? new Date(rest?.quote_details?.contract_start_date) : null};
                            }
                        }),
                        mileStoneName: value?.label,
                        payment_period_value: value,
                        typeValue: value?.template_type ?? "",

                        // error
                    });
                }
                if (value?.template_type === "variable") {

                    return setDataNew({
                        ...dataNew,
                        quotation_milestone:value,
                        payment_period: "Milestone Based",
                        delivery_timestone_template: value,
                        milestoneTemplate: value,
                        mailstoneLists: response?.data?.milestone_template_items?.map((val) => {
                            return { ...val, milestone: `${val?.delivery_milestone_masters?.name}`, amount_text_value: 0, percentage_value: 0,payment_date:rest?.quote_details?.contract_start_date ? new Date(rest?.quote_details?.contract_start_date) : null };
                        }),
                        mileStoneName: value?.label,
                        payment_period_value: value,
                        typeValue: value?.template_type ?? "",
                        // error
                    });
                }


                // let error = data.error;
                // error.payment_period= "";
                setDataNew({
                    ...dataNew,
                    payment_period: "Milestone Based",
                    delivery_timestone_template: value,
                    mailstoneLists: data,
                    mileStoneName: value?.label,
                    payment_period_value: value,
                    milestoneTemplate: value,
                    typeValue: value?.template_type ?? "",
                    temp_template: value,
                    quotation_milestone:value
                    // error
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const mistoneUpdate = (value) => {
        getTemplate(value)
    }
    const amountOnchange = (value, index) => {

        const key = 'percentage_value'

        if (value?.target?.value > total) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please Enter Amount Less Then Amount To Apply",
            });
        } else {

            let result = []


            if (valuetType === "variable") {
                let allProperty = [...dataNew?.mailstoneLists];

                allProperty[index] = {
                    ...allProperty[index],
                    [key]: value?.target?.value,
                };

                let total_appied_amount = allProperty?.map(i => parseFloat(i?.percentage_value)).reduce((a, b) => a + b, 0);


                if (total_appied_amount > total) {

                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please Enter Amount Less Then All Amount",
                    });
                    result = dataNew?.mailstoneLists;

                } else {
                    result = [...allProperty]
                }

                allProperty=[]
            }
            else {
                result = dataNew?.mailstoneLists?.map((val, i) => {
                    return {
                        ...val,
                        percentage_value: i === 0 ? value?.target?.value?.length > 0 ? value?.target?.value : "" : getCalculation(value?.target?.value?.length > 0 ? value?.target?.value : 0, val?.percentage)
                    }
                })
            }
            let remaining_balance=result?.map(i=>parseFloat(i?.percentage_value?.length>0?i?.percentage_value:0))?.reduce((a, b) => a + b, 0)

            setReainingBalance(total-remaining_balance)

       
            setDataNew({
                ...dataNew,
                mailstoneLists: result,
            });

        
        }

    }

    const dateOnchange=(value,index)=>{
        let allProperty = [...dataNew?.mailstoneLists];

        allProperty[index] = {
            ...allProperty[index],
            payment_date: value,
        };

        setDataNew({
            ...dataNew,
            mailstoneLists: [...allProperty],
        });
    }

    const sumbit = () => {
        if (valuetType === "percentage") {
            setDataNew({
                ...dataNew,
                reload: true,
                isValid: false
            })
        }
        else if (valuetType === "amount" && (dataNew?.mailstoneLists?.[0]?.percentage_value?.length === 0 || dataNew?.mailstoneLists?.[0]?.percentage_value === "0" || dataNew?.mailstoneLists?.[0]?.percentage_value === 0 || !dataNew?.mailstoneLists?.[0]?.percentage_value.length > 0)) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please Enter Amount"),
            });
            setDataNew({
                ...dataNew,
                // reload: true,
                isValid: false
            })
            return false
        }
        else {
            const totalCount = getTotal(dataNew?.mailstoneLists)
            if (total !== totalCount) {

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Amount must be grater than Total Amount"),
                });
                setDataNew({
                    ...dataNew,
                    isValid: false,
                    reload: true,
                })
                return false
            }
        }
        setOpenDialog(!openDialog)
        setDataNew({
            ...dataNew,
            reload: true,
            isValid: true,
            quotation_milestone: dataNew?.quotation_milestone

        })
        onSumbit(dataNew?.mailstoneLists)
    }
    React.useEffect(() => {
        if (!dataNew?.reload) {
            getTemplate({value:rest?.quote_details?.milestone_template_master_id , label:rest?.quote_details?.milestone_template_master_name , template_type:rest?.quote_details?.template_type})
        }
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            <TemplateConfiguration contract_start_date={contract_start_date} remainingBalance={remainingBalance} total_quote_amount={total_quote_amount ?? 0} mistoneUpdate={mistoneUpdate} milestoneTemplate={dataNew?.delivery_timestone_template} dataNew={dataNew} valuetType={valuetType} dateOnchange={dateOnchange} amountOnchange={amountOnchange} total={total} symbol={symbol} sumbit={sumbit} t={t} />
        </div>
    )
}


const TemplateConfiguration = ({
    mistoneUpdate = () => false,
    milestoneTemplate = {},
    dataNew = {},
    valuetType = "",
    amountOnchange = () => false,
    total = "0",
    symbol = "-",
    sumbit = () => false,
    total_quote_amount = 0,
    t = () => false,
    remainingBalance=0,
    contract_start_date,
    dateOnchange=()=>false
}) => {
    const classes = useStylesDetailsCard();
   
    return (
        <Box>
                <Stack direction="row" spacing={1} alignItems='center' justifyContent={'space-between'} className={classes.amountRoot}>
                <Box display='flex' alignItems={'center'}>
                    <TemplateAmount />
                    <Typography className={classes.templateAmountTilte}>Total sale amount including Taxes</Typography>
                </Box>



                <Typography className={classes.templateAmountTilte} >{symbol ?? "-"} {HoleNumber(total_quote_amount)}</Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems='center' justifyContent={'space-between'} className={classes.amountRoot}>
                <Box display='flex' alignItems={'center'}>
                    <TemplateAmount />
                    <Typography className={classes.templateAmountTilte}>Total Amount for Milestone Distribution excluding taxes</Typography>
                </Box>



                <Typography className={classes.templateAmountTilte} >{symbol ?? "-"} {HoleNumber(total)}</Typography>
            </Stack>
            {
                valuetType==="variable" &&
                <Stack direction="row" spacing={1} alignItems='center' justifyContent={'space-between'} className={classes.amountRoot}>
                <Box display='flex' alignItems={'center'}>
                    <TemplateAmount />
                    <Typography className={classes.templateAmountTilte}>Unused Balance</Typography>
                </Box>
                <Typography className={classes.templateAmountTilte} >{symbol ?? "-"} {HoleNumber(remainingBalance) ?? 0}</Typography>
            </Stack>
            }
            <Box p={2}>
                <TemplateDrawer milestoneTemplate={milestoneTemplate} mistoneUpdate={mistoneUpdate} company_id={JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.value} />
                <Box height="18px" />
                {dataNew?.mailstoneLists?.length > 0 && (

                    <MileStoneTemplate
                        dataType={saledataTypequtation(valuetType)}
                        rows={dataNew?.mailstoneLists}
                        heading={saleheadingqutation(valuetType)}
                        path={salepathqutation(valuetType)}
                        title={dataNew?.mileStoneName}
                        amountOnchange={amountOnchange}
                        dateOnchange={dateOnchange}
                        currency={symbol}
                        contract_start_date={contract_start_date}
                    />

                )}
                <Box height="18px" />
                <Button className={classes.downBtn} onClick={sumbit}>{t("Update & Save")}</Button>
            </Box>
        </Box>
    )
}