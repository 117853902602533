/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import React, { useState, useContext, useEffect } from "react";
import { Subheader, UseDebounce } from "../../components";
import { useStyles } from "./style";
import { Box, Typography, Button, Stack } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { tabTittle } from "./common";
import { PropertySelect, AnnounsmentDetails } from "./component";
import { useLocation, useHistory } from "react-router-dom";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods, LocalStorageKeys, useWindowDimensions } from "../../utils";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import {
  GET_PROPERTY_BY_USER,
  GET_NOTIFICATION_DETAILS,
} from "../../graphql/queries";
import { useApolloClient } from "@apollo/client";

const initial1 = {
  title: "",
  Description: "",
  images: "",
};
const CreateAnnounsmentNew = ({ t }) => {
  const size = useWindowDimensions()
  // histroy
  const history = useHistory();
  // location
  const { state } = useLocation();
  // apolo clint
  const client = useApolloClient();
  // classes
  const classes = useStyles();
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  //debounce hook
  const debounce = UseDebounce();
  // ------------------------STATE-----------------------------------
  const [value, setValue] = useState({
    tabValue: 0,
  });
  const [data, setData] = useState(
    state?.main?.isEdit ? [] : [{ ...initial1 }]
  );
  const [data1, setData1] = useState({
    property_ids: "",
    build_id: "",
    Category: "",
    error: {
      property_ids: "",
      build_id: "",
      Category: "",
    },
  });
  const [uuid, setUUID] = useState(1);

  const [listingDetails, setListingDetails] = useState({
    property: [],
    role: [],
    propertyselectAll: false,
    roleselectAll: false,
  });
  const [listingDetails1, setListingDetails1] = useState({
    role: [],
    roleselectAll: false,
  });
  const [searchText, setSearchText] = useState({
    propertySearch: "",
    roleSearch: "",
    offsetProperty: 0,
    offsetRole: 0,
  });
  const [show, setShow] = useState(false);
  const [selectedProperty, setSelectedProperty] = React.useState([])
  const [selectedBuilds, setSelectedBuilds] = React.useState([])
  const [is_preview_dialog_open, set_is_preview_dialog_open] = useState(false);

  //--------------------------FUNCTION-------------------------------
  const updateState = (k, v) => {
    setValue({ ...value, [k]: v });
  };
  // tab change
  // const tabChange = (value) => {
  //   updateState("tabValue", value);
  // };
  // next click
  const next = () => {
    if (value?.tabValue === 0) {
      if (!listingDetails?.property?.some((x) => x?.is_active)) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.info,
          msg: t("Please Select Property."),
        });
        return true;
      } else if (!listingDetails1?.role?.some((x) => x?.is_active)) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.info,
          msg: t("Please Select Apps."),
        });
        return true;
      } else {
        updateState("tabValue", value?.tabValue + 1);
      }
    }
  };
  // previous click
  const previous = () => {
    updateState("tabValue", value?.tabValue - 1);
  };
  const updateState2 = (k, v) => {
    let error = data1?.error;
    error[k] = "";
    setData1({ ...data1, [k]: v, error });
  };
  // update data of array
  const updateState1 = (key, value, index, editor) => {
    let allProperty = [...data];
    allProperty[index] = {
      ...allProperty[index],
      [key]: value,
    };
    setData([...allProperty]);
  };
  // delete image
  const handleRemoveFields = (value, key, index) => {
    if (index === 0) {
      const edited = data?.map((val, i) =>
        i === index ? { ...val, images: "" } : val
      );
      setData(edited);
    } else {
      const values = [...data];
      values.splice(index, 1);
      setData(values);
    }
  };
  //image edit
  const editImage = (value, key, index) => {
    const edited = data?.map((val, i) =>
      i === index ? { ...val, images: value } : val
    );
    setData(edited);
  };
  const handleAddFields = (key) => {
    const addData = {
      title: "",
      Description: "",
    };
    const addData1 = {
      images: "",
    };
    const addData2 = {
      attachment: [],
    };
    if (key === "image") {
      setData([...data, addData1]);
    }
    if (key === "title") {
      setData([...data, addData]);
    }
    if (key === "Attachments") {
      setData([...data, addData2]);
      setShow(true);
    }
  };
  // choose property
  const chooseProperty = (value, index, key) => {
    if (selectedProperty.includes(listingDetails?.property?.[index]?.id)) {
      setSelectedProperty(selectedProperty?.filter((i) => i !== listingDetails?.property?.[index]?.id))
    } else {
      setSelectedProperty([...selectedProperty, listingDetails?.property?.[index]?.id])
    }

    if (value !== undefined) {
      let result = [...listingDetails?.property];
      result[index][key] = value;
      setListingDetails({
        ...listingDetails,
        property: result,
      });
    }
  };

  // choose role
  const chooseRole = (value, index, key) => {
    if (selectedBuilds?.includes(listingDetails1?.role?.[index]?.subTitle)) {
      setSelectedBuilds(selectedBuilds?.filter((i) => i !== listingDetails1?.role?.[index]?.subTitle))
    } else {
      setSelectedBuilds([...selectedBuilds, listingDetails1?.role?.[index]?.subTitle])
    }

    if (value !== undefined) {
      let result = [...listingDetails1?.role];
      result[index][key] = value;
      setListingDetails1({
        ...listingDetails1,
        role: result,
      });
    }
  };
  // select all
  const ChooseAll = (key) => {
    const property = listingDetails?.property?.map((x) => {
      return {
        ...x,
        is_active: !listingDetails?.propertyselectAll,
      };
    });
    const role = listingDetails1?.role?.map((x) => {
      return {
        ...x,
        is_active: !listingDetails1?.roleselectAll,
      };
    });
    if (key === "property") {
      setListingDetails({
        ...listingDetails,
        property: property,
        propertyselectAll: !listingDetails?.propertyselectAll,
      });
    } else {
      setListingDetails1({
        ...listingDetails1,
        role: role,
        roleselectAll: !listingDetails1?.roleselectAll,
      });
    }
  };
  const extractTitle = () => {
    let getId = document?.getElementById("pushTitle");
    let getTitle = getId.querySelector(".ql-editor").innerText;
    return getTitle;
  };
  // search function for role
  const searchFunctionBuild = (e) => {
    debounce(() => getBuilds(e, 0, false, true), 800);
    setSearchText({
      ...searchText,
      roleSearch: e ?? "",
    });
  };
  // search function for role
  const searchFunctionRole = (e) => {
    debounce(() => GetList(e, 0, false, true), 800);
    setSearchText({
      ...searchText,
      propertySearch: e ?? "",
    });
  };
  // infinity scroll function for roles
  const fetchMoreDataRole = () => {
    setSearchText({
      ...searchText,
      offsetRole: searchText?.offsetRole + 10,
    });
    getBuilds(searchText?.roleSearch, searchText?.offsetRole + 10, true);
  };
  // infinity scroll function for property
  const fetchMoreDataProperty = () => {
    setSearchText({
      ...searchText,
      offsetProperty: searchText?.offsetProperty + 60,
    });
    GetList(searchText?.propertySearch, searchText?.offsetProperty + 60, true);
  };
  // get property
  const GetList = (searchText, offSet, filter, isSearch) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    let clientID = localStorage.getItem(LocalStorageKeys.clinetID);
    const payload = {
      query: GET_PROPERTY_BY_USER(
        offSet,
        60,
        searchText,
        clientID,
        state?.main?.company?.value
      ),
    };
    NetworkCall(
      `${config.api_url}/graphql`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const listData = res?.data?.data?.property?.map((x) => {
          return {
            title: x?.name,
            subTitle: `${x?.address?.state} , ${x?.address?.country}`,
            is_active:
              (state?.main?.is_all_property && state?.main?.isEdit) ||
                listingDetails?.propertyselectAll
                ? true
                : false,
            logo: x?.logo,
            id: x?.id,
          };
        });
        let combined = listData?.map((item) => {
          return {
            ...item,
            is_active: state?.main?.property_ids
              ?.map((i) => i)
              ?.includes(item?.id ?? "")
              ? true
              : false,
          };
        });
        if (filter) {
          setListingDetails({
            ...listingDetails,
            property:
              !state?.main?.is_all_property && state?.main?.isEdit
                ? listingDetails?.property?.concat(combined) ?? []
                : listingDetails?.property?.concat(listData) ?? [],
          });
        } else {
          let finalArray = []
          listData?.map((e) => {
            if (selectedProperty?.includes(e?.id)) {
              finalArray.push({
                ...e,
                is_active: true
              })
            } else {
              finalArray.push(e)
            }
          })
          setListingDetails({
            ...listingDetails,
            propertyselectAll: state?.main?.isEdit
              ? state?.main?.is_all_property
              : listingDetails?.propertyselectAll,
            property:
              !state?.main?.is_all_property && state?.main?.isEdit
                ? combined ?? []
                : isSearch ? finalArray : listData ?? [],
          });
        }
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };
  // get builds list
  const getBuilds = (search, offset, filter, isSearch) => {
    const requestPayload = {
      offset: offset ?? 0,
      limit: 10,
      search: search ?? "",
    };
    NetworkCall(
      `${config.api_url}/app_build`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((res) => {
        const listData = res?.data?.data?.map((x) => {
          return {
            title: x?.build_name,
            subTitle: x?.build_code,
            is_active: false,
          };
        });
        let combined = listData?.map((item) => {
          return {
            ...item,
            is_active: state?.main?.build_ids
              ?.map((i) => i)
              ?.includes(item?.subTitle ?? "")
              ? true
              : false,
          };
        });
        if (filter) {
          setListingDetails1({
            ...listingDetails1,
            role: [...listingDetails1?.role, ...combined]
          });
        } else {
          let finalArray = []
          listData?.map((e) => {
            if (selectedBuilds?.includes(e?.subTitle)) {
              finalArray.push({
                ...e,
                is_active: true
              })
            } else {
              finalArray.push(e)
            }
          })
          setListingDetails1({
            ...listingDetails1,
            roleselectAll:
              state?.main?.isEdit &&
                state?.main?.build_ids?.length === res?.data?.data?.length
                ? true
                : false,
            role: state?.main?.isEdit ? combined ?? [] : isSearch ? finalArray : listData ?? [],
          });
        }
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong. Please try again"),
        });
      });
  };

  // announsment create
  const publish = (is_draft) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: `${is_draft ? "Drafting..." : "Publishing..."}`,
    });
    const elem = document.getElementById("body").outerHTML;
    if (validate()) {
      const payload = {
        category: data1?.Category?.value,
        company_id: state?.main?.company?.value,
        prop_ids: listingDetails?.propertyselectAll
          ? []
          : listingDetails?.property
            ?.filter((x) => x?.is_active === true)
            ?.map((x) => x?.id),
        to_builds: listingDetails1?.role
          ?.filter((x) => x?.is_active === true)
          ?.map((x) => x?.subTitle),
        title: extractTitle(),
        description: data,
        triggered_by: localStorage.getItem("profileID"),
        triggered_at: new Date().toISOString(),
        is_draft: is_draft,
        is_all_property: listingDetails?.propertyselectAll,
        email_attachments: data
          ?.filter((x) => x?.attachment)
          ?.map((x) => x)
          ?.flatMap((x) => x?.attachment)
          ?.map((x) => {
            return {
              path: x?.url ?? "",
              filename: x?.name ?? "",
            };
          }),
        email_body: elem ?? "",
      };
      if (state?.main?.isEdit) {
        payload["id"] = state?.main?.id;
      }
      NetworkCall(
        `${config.api_url}/notifications/upsert`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          history.push("/announcement");
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${t("Announcement")} ${state?.main?.isEdit
              ? t("Updated")
              : is_draft
                ? t("Draft Saved")
                : t("Published")
              } ${t("successfully")}`,
          });
        })
        .catch((error) => {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Internal error. Please try again later."),
          });
        });
    }
  };

  // validation
  const validate = () => {
    let isValid = true;
    let error = data1.error;
    if (data1?.Category?.length === 0) {
      isValid = false;
      error.Category = t("Category is Required");
    }
    setData1({ ...data1, error });

    return isValid;
  };
  // edit function
  const getDetails = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    client
      .query({
        query: GET_NOTIFICATION_DETAILS(state?.main?.id),
        fetchPolicy: "network-only",
      })
      .then((res) => {
        setData(res?.data?.notifications?.[0]?.description);
        setData1({
          ...data1,
          Category: {
            value: res?.data?.notifications?.[0]?.category,
            label: res?.data?.notifications?.[0]?.category,
          },
        });
        getBuilds("", 0);
        GetList("", 0);
        setShow(true);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };
  // --------------------------COMPONENTS-------------------------------
  const component = {
    0: (
      <PropertySelect
        t={t}
        chooseProperty={chooseProperty}
        chooseRole={chooseRole}
        listingDetails={listingDetails}
        ChooseAll={ChooseAll}
        searchFunctionBuild={searchFunctionBuild}
        searchFunctionRole={searchFunctionRole}
        searchText={searchText}
        fetchMoreDataRole={fetchMoreDataRole}
        fetchMoreDataProperty={fetchMoreDataProperty}
        listingDetails1={listingDetails1}
      />
    ),
    1: (
      <AnnounsmentDetails
        t={t}
        updateState1={updateState1}
        data={data}
        setData={setData}
        uuid={uuid}
        setUUID={setUUID}
        handleRemoveFields={handleRemoveFields}
        editImage={editImage}
        handleAddFields={handleAddFields}
        updateState2={updateState2}
        data1={data1}
        pushTitle="pushTitle"
        company={state?.main?.company}
        body="body"
        show={show}
        is_preview_dialog_open={is_preview_dialog_open}
        set_is_preview_dialog_open={set_is_preview_dialog_open}
      />
    ),
  };

  useEffect(() => {
    if (state?.main?.isEdit) {
      getDetails();
    } else {
      getBuilds("", 0);
      GetList("", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.main?.isEdit]);

  return (
    <div>
      {/* sub nav bar */}
      <Subheader
        title={state?.main?.isEdit ? t("Edit") : t("create")}
        goBack={() => {
          history.goBack(-1);
        }}
      />
      {/* tab */}
      <Stack direction={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"} sx={{backgroundColor: "white", paddingInlineEnd:"16px"}}>
      <Box className={classes.tab}>
        {tabTittle(value?.tabValue, classes)?.map((val, index) => {
          return (
            <Box className={val?.selectBack}>
              {val?.imgSelect ?? ""}
              <Typography className={val?.className}>{t(val?.label)}</Typography>
              <Box className={classes.borderAbsolute} />
            </Box>
          );
        })}
      </Box>
      {(value?.tabValue===1&&size?.width <= 899)&&
        <Button variant="outlined" onClick={()=>set_is_preview_dialog_open(!is_preview_dialog_open)}>
        {t("Preview Content")}
      </Button>}
      </Stack>
      <div>{component[value?.tabValue]}</div>
      {/* bottom btns */}
      <Box className={classes.bottombtn}>
        <Box>
          <Button
            variant="outlined"
            className={classes.Cancel}
            onClick={previous}
            disabled={value?.tabValue > 0 ? false : true}
          >
            {t("Back")}
          </Button>
        </Box>
        <Box>
          <Button
            variant="outlined"
            className={classes.Cancel}
            onClick={() => publish(true)}
            disabled={
              value?.tabValue === 1 && data1?.Category?.label?.length > 0
                ? false
                : true
            }
          >
            {t("Save As Draft")}
          </Button>
          <Button
            variant="contained"
            className={classes.next}
            onClick={value?.tabValue === 1 ? () => publish(false) : next}
            disabled={
              data1?.Category?.label?.length > 0 || value?.tabValue === 0
                ? false
                : true
            }
          >
            {value?.tabValue === 1 ? t("Publish") : t("Next")}
          </Button>
        </Box>
      </Box>
    </div>
  );
};
export default withNamespaces("announsment")(CreateAnnounsmentNew);
