import { Typography, Box, Button, Stack, Popover } from '@mui/material';
import React from 'react';
import { useStyles } from './style'
import { LocalStorageKeys } from '../../../utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ServiceJobIcon } from '../../../assets/serviceJob';
import { GeneralJobIcon } from '../../../assets/generalJob';
import { DeliveryJobIcon } from '../../../assets/deliveryJob';
import { WorkOrderIcon } from '../../../assets/workOrder';
import { AssetReplacementIcon } from '../../../assets/assetReplace';
import { ProjectedBOM } from '../../../assets/projectedBom';

export const Tab = ({
  data = {},
  details = {},
  options = [],
  onClick = () => false,
  value = 0,
  t,
  onAssign = () => false,
  onCloseRequest = () => false,
  onMenuClick = () => false,
  is_closeBtn = true,
  is_proposed_bom = false,
  requestName = "Maintenance"
}) => {
  // classes
  const classes = useStyles()
  const language = localStorage.getItem("i18nextLng")
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [type, setType] = React.useState("Create")
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)


  const createOptions = [
    {
      title: t("General Job"),
      backgroundColor: "#FFFCEB",
      icon: <GeneralJobIcon />,
      type: "general",
      is_active: true
    },
    {
      title: t("Service Job"),
      backgroundColor: "#F1F6FF",
      icon: <ServiceJobIcon />,
      type: "service",
      is_active: true
    },
    {
      title: t("Delivery Job"),
      backgroundColor: "#EDF7F8",
      icon: <DeliveryJobIcon />,
      type: "delivery-order",
      is_active: true
    },
    {
      title: t("Projected BOM"),
      backgroundColor: "#FEF4F4",
      icon: <ProjectedBOM />,
      type: "Projected BOM",
      is_active: is_proposed_bom ? false : true
    },
    {
      title: t("Vendor Work Order"),
      backgroundColor: "#F6F1FE",
      icon: <WorkOrderIcon />,
      type: "work-order",
      is_active:details?.data?.table?.[0]?.work_order_status !== "closed"
    },
    {
      title: t("Item Request"),
      backgroundColor: "#F1F7FF",
      icon: <AssetReplacementIcon />,
      type: "asset-replace",
      is_active: true
    },
  ]
  const handleClick = (event) => {
    setType("Create")
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseRequest = (event) => {
    setType("Close")
    setAnchorEl(event.currentTarget);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box>
      <Box className={classes.subheaderRoot}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems="center">
          <Box onClick={() => history.goBack()} className={classes.iconbtn} display="flex" alignItems="center">
            {language === "ar" ?
              <ArrowForwardIosIcon className={classes.iconArabic} htmlColor="black" /> :
              <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
            }

          </Box>
          <Box marginInlineStart="15px">
            <Typography className={classes.title}>{details?.data?.table?.[0]?.request_no}</Typography>
          </Box>
        </Stack>
        <Stack direction="row" float="right">
          {details?.data?.table?.[0]?.current_status?.[0]?.type !== "Reopened" && details?.data?.table?.[0]?.current_status?.[0]?.type !== "Open" && details?.data?.table?.[0]?.current_status?.[0]?.type !== "Assigned" ?
            parseInt(moduleId) === 24 &&
            <Button variant="contained" className={classes.closeRequest} onClick={() => onCloseRequest("Reopen")}>{t("Reopen")}</Button>

            :
            <>
              {parseInt(moduleId) === 24 &&
                <Button variant="outlined" aria-describedby={id} onClick={handleClick} className={classes.outline} disabled={(details?.data?.table?.[0]?.costing_status === "Approved" || details?.data?.table?.[0]?.type === "Non Chargeable" || details?.data?.table?.[0]?.initial_costing_status === "Approved") ? false : true}>{t("Create")}</Button>
              }
              {/* <Button variant="contained" className={classes.closeRequest} onClick={onCloseRequest}>{t("Close_Request")}</Button> */}
              <Button variant="contained" className={classes.closeRequest} onClick={handleCloseRequest}>{t("Close_Request")}</Button>
            </>

          }

          <Box width={'16px'} />
        </Stack>
      </Box>
      <Box className={classes.root}>
        <div className={classes.tabRoot}>
          {
            options &&
            options?.map((x, i) => {
              return (
                <Box onClick={() => onClick(x.value)} display="flex" alignItems="center" className={x?.value === value ? classes.selectBack1 : classes.selectBack}>
                  {x?.image}
                  <Box width={'16px'} />
                  <Typography className={x?.value === value ? classes.tabtitle1 : classes.tabtitle}>{x?.title}</Typography>
                  {
                    value === x.value && <Box className={classes.bottom} />
                  }
                </Box>
              )
            })
          }
        </div>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.menu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          PaperProps={{ sx: { width: type === "Close" ? "170px" : "250px" } }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {type === "Close" ?
            <>
              <Typography className={classes.menuItem} onClick={() => { return (onCloseRequest("Resolve"), setAnchorEl(null)) }}>{t("Resolve Request")}</Typography>
              <Typography className={classes.menuItem} onClick={() => { return (onCloseRequest("Cancel"), setAnchorEl(null)) }}>{t("Cancel Request")}</Typography>
            </>
            : requestName === "Maintenance" ?
              <Box p={1}>
                {createOptions?.filter((x) => { return x?.is_active })?.map((val, i) => {
                  return (
                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"} style={{ backgroundColor: val?.backgroundColor, borderRadius: "4px", cursor: val?.type === "asset-replace"?"not-allowed":"pointer"}} p={1} mb={createOptions?.length - 1 !== i ? 1 : 0} onClick={() => { return (onMenuClick(val?.type, val?.type === "service" ? data?.service_inspection : val?.type === "delivery-order" ? data?.delivery_inspection : null), val?.type!=="asset-replace"?setAnchorEl(null):"") }}>
                      <Stack direction="row" spacing={1} alignItems={"center"}>
                        {val?.icon}
                        <Typography className={classes.jobTitle}>{val?.title}</Typography>

                      </Stack>
                      {language === "ar" ?
                        <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
                        :
                        <ArrowForwardIosIcon className={classes.iconArabic} htmlColor="black" />
                      }
                    </Box>
                  )
                })}
              </Box>
              :
              <Box p={1}>
                <Box display="flex" justifyContent={"space-between"} alignItems={"center"} style={{ backgroundColor: "#EDF7F8", borderRadius: "4px", cursor: "pointer" }} p={1} onClick={() => { return (onMenuClick("delivery-order", data?.delivery_inspection), setAnchorEl(null)) }}>
                  <Stack direction="row" spacing={1} alignItems={"center"}>
                    <DeliveryJobIcon />
                    <Typography className={classes.jobTitle}>{t("delivery_work_order")}</Typography>
                  </Stack>
                  {language === "ar" ?
                    <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
                    :
                    <ArrowForwardIosIcon className={classes.iconArabic} htmlColor="black" />
                  }
                </Box>
              </Box>
            // <Typography className={classes.menuItem} onClick={() => { return (onMenuClick("delivery-order", data?.delivery_inspection), setAnchorEl(null)) }}>{t("delivery_work_order")}</Typography>

          }

        </Popover>
      </Box>
    </Box>
  )
}