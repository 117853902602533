import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import { ExtraBold } from "../../../utils";

const HighChartPlainLine = ({
  data = [],
  graphTitle,
  chartHeight,
  color = "#7cb5ec",
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const options = {
        chart: {
          type: "spline",
          renderTo: chartRef.current,
          marginTop: 50,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: graphTitle,
          align: "left",
          style: {
            color: "#091B29",
            fontSize: "1rem",
            fontFamily: ExtraBold,
            marginInlineStart: "8px",
          },
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: data.labels,
          tickmarkPlacement: "on",
          title: {
            enabled: false,
          },
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        tooltip: {
          shared: true,
          formatter: function () {
            var s = "<b>" + Highcharts.dateFormat("%B %Y", this.x) + "</b>";

            this.points.forEach(function (point) {
              var number = point.y;
              var formattedNumber;
              if (number >= 1e12) {
                formattedNumber = (number / 1e12).toFixed(0) + " Tn";
              } else if (number >= 1e9) {
                formattedNumber = (number / 1e9).toFixed(0) + " Bn";
              } else if (number >= 1e6) {
                formattedNumber = (number / 1e6).toFixed(0) + " Mn";
              } else if (number >= 1e3) {
                formattedNumber = (number / 1e3).toFixed(0) + " k";
              } else {
                formattedNumber = number;
              }
              s +=
                '<br/><span style="color:' +
                point.series.color +
                '">\u25CF</span> ' +
                point.series.name +
                ": <b>" +
                formattedNumber +
                "</b>";
            });

            return s;
          },
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true,
              radius: 3, // adjust as needed
              symbol: "circle",
            },
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.y;
              },
            },
          },
        },
        series: data.datasets.map((dataset) => ({
          name: dataset.label,
          data: dataset.data,
          color: color,
        })),
      };

      Highcharts.chart(options);
    }
  }, [data, graphTitle, chartHeight]);

  return (
    <div ref={chartRef} style={{ width: "100%", height: chartHeight }}></div>
  );
};

export default HighChartPlainLine;
