import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../../utils";

export const PropertyTypeCardStyle = makeStyles((theme) => ({
    card: {
        height: 79,
        width: 107,
        borderRadius: 8,
        border: `1px solid ${theme.palette.border.secondary}`,
        cursor: "pointer",
        marginInlineEnd: "8px"
    },
    label: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: theme.typography.color.primary
    },
    label1: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    selectedCard: {
        height: 79,
        width: 107,
        borderRadius: 8,
        border: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.info.light,
        cursor: "pointer",
        marginInlineEnd: "8px"
    },
    selectedLabel: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    },
    header: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    }
}))

export const ChooseCardStyle = makeStyles((theme, props) => ({
    title: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold
    },
    subTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    },
    box: {
        borderRadius: 4,
        // border: (props)=> props?.border ?? `1px solid ${theme.palette.border.primary}`,
        width: "100%"
    },
    header: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold
    },
    boldHeader: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    remove: {
        fontSize:"0.875rem",
        color: theme.palette.error.main,
        fontFamily: SemiBold,
        cursor: "pointer"
    },
    optionAvatar: {
        height: 32,
        width: 32,
        borderRadius: "4px !important",
        fontSize:"1rem",
        fontFamily: Bold
    },
    optionHeader: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: "#091B29"
    },
    optionSubTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
        whiteSpace: "noWrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginLeft:"0px !important",
        direction: "ltr"
    },
    dot: {
        height: 6,
        width: 6,
        background: theme.palette.border.primary,
        borderRadius: 50,
        margin: "0px 5px !important"
    },
    selectParent: {
        padding: "5px",
        borderRadius: 4,
        border: "1px solid #E4E8EE",
        margin: "5px",
    },
    choosecards: {
        padding: "5px",
        borderRadius: 4,
        border: "1px solid #F1F7FF",
        background: "#F1F7FF",
        position: "relative",
        display: "flex",
        // justifyContent:"space-between"

    },
    selectMultiParent: {
        borderBottom: "1px solid #E4E8EE !important",
        borderRadius: 0,
        borderTop: 'none !important',
        borderLeft: 'none !important',
        borderRight: 'none !important',
        margin: "5px"



    },
    choosecardsMuti: {
        padding: "5px",
        borderRadius: 4,
        border: "1px solid #E4E8EE",
        background: "#F6F1FE",
        position: "relative"

    },
    newRoot: {
        borderRadius: "0px 0px 4px 4px",
        cursor: "pointer",
        alignItems: "center",
        borderTop: "none"
    },
    existRoot: {
        borderRadius: "4px 4px 0px 0px",
        cursor: "pointer",
        alignItems: "center",
        borderBottom: "none"
    },
    labelBold: {
        color: "#showCard091B29",
        fontFamily: Bold,
        fontSize:"0.875rem",
        marginBottom: "8px"
    }
}))

export const DetailCardStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: Bold
    },
    existing: {
        fontSize:"0.75rem",
        color: theme.palette.info.main,
        background: theme.palette.info.light,
        borderRadius: 4,
        fontFamily: Bold,
        padding: 4
    },
    avatar: {
        height: 40,
        width: 40,
        borderRadius: 4
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: "50%",
        background: theme.palette.border.primary
    },
    content: {
        fontSize:"0.75rem",
        fontFamily: Regular,
        color: theme.typography.color.secondary
    }
}))

export const UnitCardStyle = makeStyles((theme) => ({
    avatar: {
        height: 100,
        borderRadius: 4,
        width: "100%"
    },
    card: {
        height: 250,
        width: "100%",
        background: "#fff",
        position: "relative",
        borderRadius: 6
    },
    deleteIcon: {
        background: "#fff",
        height: 26,
        width: 26,
        borderRadius: "50%",
        boxShadow: "0px 1px 4px #0000001A",
        position: "absolute",
        right: 20,
        top: 20,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer"
    },
    discountCard: {
        borderRadius: "4px 0px 0px 4px",
        background: theme.palette.warning.light,
        color: theme.palette.warning.main,
        textAlign: "center",
        fontSize:"0.75rem",
        fontFamily: Bold,
        width: "90px",
        padding: 2,
        position: "absolute",
        right: 0,
        top: 100,
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    amount: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.palette.warning.main
    },
    secTitle: {
        fontSize:"0.75rem",
        fontFamily: Regular,
        color: theme.typography.color.tertiary
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: "50%",
        background: theme.palette.border.primary
    },
    customise: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    }
}))

export const ProfileCreateEditStyle = makeStyles((theme) => ({
    profileBox: {
        position: "relative",
        width: 100
    },
    avatar: {
        height: 80,
        width: 80,
        background: "#F2F4F7"
    },
    cameraBox: {
        height: 24,
        width: 24,
        background: theme.palette.border.secondary,
        borderRadius: "50%",
        border: `2px solid #fff`,
        position: "absolute",
        bottom: 0,
        right: 15,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    }
}))
