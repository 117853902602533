import SearchIcon from '@mui/icons-material/Search';
import { Box, Drawer, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { CustomAsyncWithIcons, Location, PopoverDatePicker2, PopoverRanger } from '../../../components';
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { GET_PROPERTY_GROUP as queryOne } from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { ClearFilter } from "../component/clearFilter";
import { Filter } from "../component/filter";
import { finderStyles } from "../style";
export const Lease = (props) => {
    const classes = finderStyles()
    const history = useHistory()
    const customStyles = {
        control: {

            borderRight: '1px solid #ececec',
            borderRadius: 0,
            padding: "4px 10px",
            backgroundColor: 'none',
            border: 0
        }
    }
    const initialState = {
        city: props?.data?.city ?? "",
        type: props?.data?.type ?? {
            value: null,
            label: "All"
        },
        category: props?.data?.category ?? {
            label: "All",
            value: null,
        },
        purpose: props?.data?.purpose ?? {
            label: "Lease",
            value: "Lease",
        },
        startDate: props?.data?.startDate ?? "",
        endDate: props?.data?.endDate ?? "",
        range1: props?.data?.range1 ?? "",
        range2: props?.data?.range2 ?? "",
        rangetype: props?.data?.rangetype ?? "",
        furnish: props?.filterdata?.furnish,
    }
    const initialStatefilter = {
        propertyType: props?.filterdata?.propertyType ?? "",
        range1: props?.filterdata?.range1 ?? "",
        range2: props?.filterdata?.range2 ?? "",
        rangetype: props?.filterdata?.rangetype ?? "",
        budget1: props?.filterdata?.budget1 ?? "",
        budget2: props?.filterdata?.budget2 ?? "",
        budgettype: props?.filterdata?.budgettype ?? "",
        aminities: props?.filterdata?.aminities ?? "",
        facilitesbool: props?.filterdata?.facilitesbool ?? "",
        facilites: props?.filterdata?.facilites ?? "",
        facilitestype: props?.filterdata?.facilitestype ?? "",
        pet: props?.filterdata?.pet ?? "",
        furnish: props?.filterdata?.furnish,
    }
    const filterType = {
        facilites: "facilities",
        furnish: "furnish",
        amenities: "amenities",
        budget: "budget"

    }
    const [loading, setLoading] = React.useState(false)
    const [drawer, setDrawer] = React.useState(false)
    const alert = React.useContext(AlertContext);
    const [state, setState] = useState({ ...initialState })
    const [filter, setFilter] = React.useState(props?.open ?? false)
    const [statefilter, setStateFilter] = React.useState({ ...initialStatefilter })
    const [location, setLocation] = React.useState(props?.location ?? {})
    const [filters, setFilters] = React.useState(false)
    const didMount = React.useRef(false);
    const detectLocation = () => {
        if (navigator.geolocation && (!props?.value?.longitude)) {
            navigator.geolocation.getCurrentPosition((position) => {
                const lat = position.coords.latitude;
                const long = position.coords.longitude;
                fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${config.googleMapApiKey}`)
                    .then(data => data.json())
                    .then(({ results }) => {
                        if (Array.isArray(results)) {
                            const [info] = results;
                            let city = info?.address_components?.find(comp => comp?.types?.includes("locality"));
                            let location = info?.address_components?.find(comp => comp?.types?.includes("administrative_area_level_2"));
                            let _obj = {};
                            _obj["address"] = `${city?.long_name}, ${location?.long_name}`;
                            _obj["latitude"] = lat;
                            _obj["longitude"] = long;
                            _obj["city"] = city?.long_name;
                            _obj["location"] = location?.long_name;

                            setLocation(_obj)

                        }
                    }).catch(console.error);
            });
        }
    };
    React.useEffect(() => {
        if (window.location.pathname === "/result") {

        } else {
            detectLocation()
        }
        // eslint-disable-next-line
    }, [])
    const updateState = (key, value) => {

        setState({ ...state, [key]: value });
    };
    const updateStateFilter = (key, value) => {
        setStateFilter({ ...statefilter, [key]: value });
    };
    const propertyCategerieseList = [
        {
            label: "Residential Unit",
            value: "Residential Unit",
        },
        {
            label: "Co-Bedroom",
            value: "Co-Bedroom"
        },
        {
            label: "Co-Working",
            value: "Co-Working"
        },
        {
            label: "Co-Bed",
            value: "Co-Bed"
        },

    ];

    const loadOptions = async (search = "", array, type) => {

        setLoading(type);
        let result, query, offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }


        switch (type) {
            case 'property_group_master':
                query = queryOne(offset, 10, search, "Commercial").loc.source.body;
                result = await networkCallBack(query);
                return {
                    options: [{
                        value: null,
                        label: "All"
                    }, ...result?.property_group_master],
                    hasMore: (array?.length + result?.property_group_master?.length) < result?.count[0]?.count
                }
            default:
                return { options: [] }
        }
    }
    const networkCallBack = async (query, variable = {}) => {

        let payload = {
            query,
            variable: variable
        };

        const options = await NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                setLoading(null);
                return main
            })
            .catch((error) => {
                setLoading(null);

                return null
            });

        return options
    }

    const goResult = () => {
        if (
            location?.length === 0 || state?.type?.length === 0 || state?.category?.length === 0 || state?.durationday?.length === 0 || state?.durationtype?.date?.length === 0 || state?.durationday?.length === 0 || state?.range1?.length === 0 || state?.range2?.length === 0 || state?.rangetype?.length === 0
        ) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please Fill All Fields",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        } else {
            if (window.location.pathname === "/result") {
                const payload = {
                    tenantId: `${config.tenantId}`,
                    "search_location": {
                        "lat": location?.latitude,
                        "long": location?.longitude,
                        "distance": 10
                    },
                    "property_purpose": "Commercial",
                    "revenue_type": state?.purpose?.label?.length > 0 ? state?.purpose?.value : null,
                    "property_type": state?.type?.value ?? null,
                    "unit_category": state?.category?.value ?? null,
                    "unit_type": null,
                    "requiredBeds": null,
                    "requiredBaths": null,
                    "duration": {
                        "fromDate": state?.startDate ?? null,
                        "toDate": state?.endDate?.date ?? null,
                    },
                    "areaRequirements": {
                        "minArea": state?.range1,
                        "maxArea": state?.range2,
                        "areaUnit": state?.rangetype
                    },
                    "budget": {
                        "minCost": state?.filter?.budget1 ? parseInt(state?.filter?.budget1) : null,
                        "maxCost": state?.filter?.budget2 ? parseInt(state?.filter?.budget2) : null,
                        "currency": statefilter?.budgettype?.value ? statefilter?.budgettype?.value : null,
                    },
                    "hand_over_date": null,
                    "furnishing": statefilter?.furnish ? [statefilter?.furnish] : null,
                    "isPetAllowed": null,
                    "facilities": statefilter?.facilites?.length > 0 ? statefilter?.facilites?.map((val) => val?.value) : null,
                    "amenities": statefilter?.aminities?.length > 0 ? statefilter?.aminities?.map((val) => val?.value) : null,

                }
                props?.reload(payload)
                setFilters(false)
            } else {
                history.push({
                    pathname: Routes.propertyResult,
                    state: {
                        purpose: "Commercial",
                        type: "lease",
                        value: state,
                        filter: statefilter,
                        open: filter,
                        location: location
                    }
                })
            }
        }
    }
    const onClear = () => {
        setStateFilter(initialStatefilter)
        setDrawer(false)
        setFilter(false)
    }
    const clear = (value) => {
        if (value === "range") {
            setStateFilter({ ...statefilter, range1: "", range2: "", rangetype: "" });
            setFilters(true)
        } else if (value === "budget") {
            setStateFilter({ ...statefilter, budget1: "", budget2: "", budgettype: "" });
            setFilters(true)
        } else {
            setStateFilter({ ...statefilter, [value]: "" });
            setFilters(true)
        }
    }
    React.useEffect(() => {

        // Return early, if this is the first render:
        if (!didMount.current) {
            return didMount.current = true;
        }
        // Paste code to be executed on subsequent renders:
        if (window.location.pathname === "/result") {
            if (filters) {
                goResult()
            }

        } else {
            return false
        }
        // eslint-disable-next-line
    }, [filters]);
    return (
        <div>
            {
                window.location.pathname === '/propertyfinder' &&
                <>
                    {
                        statefilter?.propertyType?.label || statefilter?.range1 || statefilter?.range2 || statefilter?.rangetype || statefilter?.budget1 || statefilter?.budget2 || statefilter?.budgettype || statefilter?.aminities?.label || statefilter?.facilites || statefilter?.facilitestype
                            ?
                            <Typography className={classes.clearAll} onClick={onClear}>Clear All</Typography>
                            : ""
                    }</>
            }
            <Grid container>
                <Grid item xs={11.7}>
                    <Grid container alignItems="center" className={classes.selectorRoot}>
                        <Grid item xs={2.2} className={classes.selector}>
                            <Location
                                value={location}
                                handleChange={(value) => setLocation(value)}
                                placeholder="Enter City"
                            />
                        </Grid>

                        <Grid item xs={2.2} className={classes.selector}>
                            <CustomAsyncWithIcons
                                value={state?.type}
                                placeholder="Property Type"
                                icon="/images/propertyType.svg"
                                onChange={(value) => updateState('type', value)}
                                styles={{ ...customStyles }}
                                loading={loading === "property_group_master"}
                                isPaginate
                                debounceTimeout={800}
                                loadOptions={(search, array) => loadOptions(search, array, 'property_group_master')}
                            />
                        </Grid>
                        <Grid item xs={2.2} className={classes.selector}>
                            <CustomAsyncWithIcons
                                value={state?.category}
                                icon="/images/unitcatergry.svg"
                                placeholder="Unit Category"
                                onChange={(value) => updateState('category', value)}
                                options={propertyCategerieseList}
                                styles={{ ...customStyles }}
                            />

                        </Grid>
                        <Grid item xs={2.2} className={classes.selector}>
                            <PopoverDatePicker2
                                startIcon="/images/date.svg"
                                placeholder="Enter Check In & Out"
                                updateState={updateState}
                                startDate={state?.startDate}
                                endDate={state?.endDate}

                            />

                        </Grid>
                        <Grid item xs={2.7} className={classes.selector}>
                            <PopoverRanger
                                placeholder='Required Area'
                                startIcon="/images/range.svg"
                                updateState={updateState}
                                range1={state?.range1}
                                range2={state?.range2}
                                rangetype={state?.rangetype}
                            />
                        </Grid>

                        <Grid item xs={0.5} className={classes.search} onClick={goResult}>

                            <SearchIcon style={{ color: "white", fontSize: "24px" }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0.3} >
                    <Box display="flex" alignItems="center" marginTop="16px" marginLeft="8px">
                        <Box>
                            <IconButton size="small" onClick={() => setDrawer(true)}>
                                <img src="/images/filterWhite.svg" alt="" />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <ClearFilter filter={filter} state={statefilter} clear={clear} />
            <Drawer anchor='right' open={drawer} onClose={() => setDrawer(false)} className={classes.filterDialog}>
                <Filter onClear={onClear} type={filterType} state={statefilter} updateState={updateStateFilter} onClose={() => {
                    if (window.location.pathname === "/result") {

                    } else {
                        setFilter(false)
                    }
                    setDrawer(false)
                }}
                    applyfilter={() => {
                        setDrawer(false)
                        setFilter(true)
                        if (window.location.pathname === "/result") {
                            goResult()
                        }
                    }}
                />

            </Drawer>
        </div>
    )
}