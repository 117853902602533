import { Box, Grid, IconButton, Stack } from "@mui/material";
import moment from "moment";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { GET_JOB_MASTER } from "../../graphql/resourceBooking";
import { NetworkCall } from "../../networkcall";
import { enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, JobType, LocalStorageKeys, NetWorkCallMethods, resourceBookingDataType, resourceBookingHeading, resourceBookingPath, SwitchInspectionRoutes, AlertProps, SwitchInspectionRoutesName } from "../../utils";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import { useStyles } from "./style";

const ResourceBooking = ({ t }) => {
    const [tableData, setTableData] = React.useState([{
        inspection_id: "IN342",
        unit_id: "UNT-123",
        assigned_date: "05-12-2022",
        hours: 4,
        actual_hours: 0,
        assigned_to: "sonu",
        status: "Assigned"
    }])
    const auth = React.useContext(AuthContext)
    const [searchText, setSearchText] = React.useState("")
    const [permission, setPermission] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [loading, setLoading] = React.useState(true)
    const backdrop = React.useContext(BackdropContext)
    const [drawer, setDrawer] = React.useState(false)
    const [selectedCompany, setSelectedCompany] = React.useState("")
    const alert = React.useContext(AlertContext)
    const [companyList, setCompanyList] = React.useState([])
    const [filterData, setFilterData] = React.useState({
        type: [],
        job: []
    })
    const [enumValue, setValue] = React.useState({
        type: [],
    });
    const history = useHistory()
    const classes = useStyles()

    const getBookedResourcesList = (companyId, offset = 0, limit = 10, searchKey = "", filterData = {}) => {
        const requestPayload = {
            start: offset ?? 0,
            end: limit ?? 10,
            company_id: companyId,
            search: searchKey?.length > 0 ? searchKey : undefined,
            type: filterData?.type?.length > 0 ? filterData?.type?.map((x) => x?.value) : undefined,
            job: filterData?.job?.length > 0 ? filterData?.job?.map((x) => x?.value) : undefined,
        };
        NetworkCall(
            `${config.api_url}/booked-resource`,
            NetWorkCallMethods.post,
            requestPayload,
            null,
            true,
            false
        )
            .then((response) => {
                setLoading(false)
                const list = response?.data?.data?.map((val, index) => {
                    return {
                        ...val,
                        name: val?.first_name ?? "-",
                        start_date_time: `${moment(val?.start_date_time).format("DD MMM YY hh:mm A")} `,
                        end_date_time: ` ${moment(val?.end_date_time).format("DD MMM YY hh:mm A")}`,
                        eta: val?.eta_minutes ? val?.eta_minutes < 60 ? `${val?.eta_minutes} Mins` : `${(val?.eta_minutes / 60).toFixed(2)} Hrs` : val?.eta,
                        job: val?.name ?? "-",
                        type: JobType(val?.request_type) ?? "-",
                        created_at: `${moment(val?.created_at).format("DD MMM YY")}`,

                    }
                })
                setTableData({ list: list, count: response?.data?.count })
            }).catch((err) => {

                setLoading(false)

            })
    }
    // enum get
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_request_type_enum]);
        setValue({
            type: result?.inspection_request_type_enum,
        });
    };

    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        let company = getCompanyOption(backdrop, auth, alert)
        if (perm) {
            setPermission(perm)
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getBookedResourcesList(company?.selected?.value, 0, 10, "")
            getEnum()
        }
        //eslint-disable-next-line
    }, [auth])
    //handle company change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getBookedResourcesList(value?.value, 0, limit, "")
        setFilterData({ job: [], type: [] })
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getBookedResourcesList(selectedCompany?.value, offset, limit, "")
    }
    //handle pageRoute
    const handleRoutes = (type, val) => {
        const RoutePath = SwitchInspectionRoutes(val?.request_type);
        const path = SwitchInspectionRoutesName(val?.request_type)

        if (auth?.auth?.auth?.routes?.includes(path)) {
            history.push({
                pathname: RoutePath,
                state: {
                    company: selectedCompany,
                    id: val?.request_id,
                    title: val?.job_reference_id,
                    type: val?.request_type,
                    agreement_id: val?.agreement_id,
                    request_id: val?.request_id,
                    reference_id: val?.request_reference_id,
                    agreement_inspection_id: val?.inspection_id
                }
            })
        }else{
            alert.setSnack({
                ...alert, open: true, msg: t("You Don't Have a Access"),
                severity: AlertProps.severity.info,
              })
        }


    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getBookedResourcesList(selectedCompany?.value, 0, value, "")

    }
    const handleSearch = (value) => {
        setSearchText(value)
        getBookedResourcesList(selectedCompany?.value, 0, 10, value)
    }
    const openDrawer = () => {
        setDrawer(!drawer)
    }
    const onApplyFilter = (value) => {
        getBookedResourcesList(selectedCompany?.value, 0, 10, "", value)
        setFilterData(value)
    }
    return (
        <Box>
            <Subheader title={t("resource_Booking")} hideBackButton={true} select
                options={companyList}
                value={selectedCompany}
                onchange={(e) => {
                    handleCompanyChange(e)
                }} />
            {loading ?
                <LoadingSection top="20vh" message={"Fetching Details"} />
                :
                <Box className={classes.root}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={4}>
                            <Box>
                                {/*search */}
                                <SearchFilter label={false} placeholder={t("searchBookings")} handleChange={(value) => handleSearch(value)} value={searchText} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}/>
                            </Box>
                        </Grid>

                        <Grid item xs={8} display={"flex"} justifyContent={"end"}>
                            <Box display={"flex"} alignItems="center" sx={{ float: "right" }}>
                                <Stack
                                    direction="row"
                                    spacing={0.6}
                                    display={"flex"}
                                    alignItems="center"
                                >
                                    <Box>
                                        <IconButton
                                            size="small"
                                            className={classes.img}
                                            onClick={openDrawer}
                                        >
                                            <img src="/images/filter.svg" alt="filter" />
                                        </IconButton>
                                    </Box>
                                </Stack>
                                <Box width={"15px"} />
                            </Box>
                        </Grid>
                    </Grid>

                    <TableWithPagination
                        heading={resourceBookingHeading(t)}
                        rows={tableData?.list}
                        dataType={resourceBookingDataType}
                        path={resourceBookingPath}
                        showpagination
                        tableType="no-side"
                        handleIcon={handleRoutes}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={tableData?.count}
                        page={page}
                        limit={limit}
                        view={permission?.read && true}
                        height={`calc(100vh - 300px)`}
                        edit={true}
                        delete={true} />
                </Box>
            }

            {/* filter */}
            {drawer && (
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.job,
                            isMulti: true,
                            label: t("Job"),
                            state_name: "job",
                            loadOptions: (search, array, handleLoading) =>
                                loadOptions(
                                    search,
                                    array,
                                    handleLoading,
                                    GET_JOB_MASTER,
                                    "job_master",
                                    { isActive: true, client: localStorage.getItem(LocalStorageKeys.clinetID), companyId: selectedCompany?.value },
                                    { label: "label", value: "value" },
                                    false,
                                    false,
                                    []
                                ),
                            debounceTimeout: 800,
                            isPaginate: true,
                        },
                        {
                            component: "select",
                            value: filterData?.type,
                            isMulti: true,
                            label: t("Job Type"),
                            state_name: "type",
                            options: enumValue?.type ?? [],
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            )}
        </Box>
    )
}
export default withNamespaces("resourceBooking")(ResourceBooking)