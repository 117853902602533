import {
    Avatar,
    Box,
    Button,
    Grid, Typography
} from "@mui/material";
import React from 'react';
import { useHistory } from "react-router-dom"

// import { useStyles } from '../../maintenancerequest/style'
import { KeyboardArrowDown } from "@mui/icons-material";
import moment from "moment-timezone";
import { CasaGrandIcon, InspectionIcon } from '../../../assets';
import { AlertDialog, SearchFilter, Subheader, VerticalLinearStepper } from "../../../components";
import { useStyles as useStyles1 } from './style';
import InfiniteScroll from "react-infinite-scroll-component";
import { RemoveZUTCDateTime, SwitchInspectionRoutes, stringAvatar, useWindowDimensions } from "../../../utils";
import { CheckCircleIcon } from "../../../assets/checkCircleIcon";
import { TrackClockIcon } from "../../../assets/trackClockIcon";
import InfoIcon from "../../../assets/infoIcon";
import NoDataFound from "../../../assets/noData";


export const MoveTo = ({ t, datas = {}, goToRoutes = () => false, fetchMoreData = () => false, handleSearch = () => false, serviceDetails = "", showMore = false, setShowMore = () => false, searchText = "", setSearchText = () => false, request_type = "", setDatas = () => false, onChangeRequest = () => false, onCloseModal = () => false, getAllRequest = () => false }) => {

    // classes
    // const classes = useStyles()
    const size = useWindowDimensions();
    const classes1 = useStyles1()
    const history = useHistory()

    // track

    const card = ({ title = "", name = "", image = "", icon = "", subTitle = "", subTitle2 = "", status = "", multiple = false }) => {
        const SwitchColor = (val) => {
            switch (val) {
                case "Completed":
                    return {
                        color: "#5AC782",
                        backgroundColor: "#EEF9EE"
                    }
                case "Assigned":
                    return {
                        color: "#5078E1",
                        backgroundColor: "#F1F7FF"
                    }
                default:
                    return {
                        color: "#FF9340",
                        backgroundColor: "#FFF4EB"
                    }
            }

        }
        return (

            <Box className={classes1.card} style={{ cursor: title === "Inspection Detail" ? "Pointer" : "" }} >

                <Box display={"flex"} justifyContent={"space-between"} alignItems="center">
                    <Typography className={classes1.cardDetails}>{title}</Typography>
                    {multiple && <KeyboardArrowDown color="#98A0AC" style={{ fontSize: "1.25rem", cursor: "pointer" }} onClick={() => getAllRequest(request_type)} />}
                </Box>
                <Box height={'8px'} />
                <Grid container alignItems={"center"} onClick={() => goToRoutes(title, request_type)}>

                    <Grid item xs={2}>
                        {icon ?
                            <Box>{icon}</Box>
                            :
                            <Avatar src={image} alt="profile" width="40px" height="40px" variant="square" className={classes1.profilePic} {...stringAvatar(name)} />
                        }
                    </Grid>

                    <Grid item xs={9} marginInlineStart={'12px'}>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography className={classes1.cardTitle}>{name}</Typography>
                            {status && <Typography className={classes1.statusText} style={{ color: SwitchColor(status)?.color, backgroundColor: SwitchColor(status)?.backgroundColor }}>{status}</Typography>}
                        </Box>

                        {subTitle2 ?
                            <Box display="flex" alignItems={"center"} justifyContent={'space-between'}>
                                <Typography className={classes1.cardSubTitle} noWrap>{subTitle}</Typography>
                                <Box className={classes1.dot} />
                                <Typography className={classes1.cardSubTitle} noWrap>{subTitle2}</Typography>

                            </Box>
                            :
                            <Box mt={0.5}>
                                <Typography className={classes1.cardSubTitle} noWrap>{subTitle}</Typography>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box >
        )
    }
    const goToView = (val, type) => {
        const RoutePath = SwitchInspectionRoutes(type)
        history.push({
            pathname: RoutePath,
            state: {
                request_id: val?.request_id,
                reference_id: val?.job_no,
                id:type==="service"?datas?.selected_service_inspection?.id:type==="general"?datas?.selected_general_inspection?.id:datas?.selected_delivery_inspection?.id,
                agreement_inspection_id:type==="service"?datas?.selected_service_inspection?.id:type==="general"?datas?.selected_general_inspection?.id:datas?.selected_delivery_inspection?.id
            }
        })
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={3}>

                    <Box>
                        {card({ title: t("Detail"), name: serviceDetails?.inspection?.reference_id, icon: <InspectionIcon />, status: serviceDetails?.inspection?.status, subTitle: `Requested Order On ${moment(serviceDetails?.inspection?.execution).format("DD MMM YY")} by ${serviceDetails?.inspection?.created_by}`, multiple: true })}
                    </Box>

                    {serviceDetails?.inspection?.manager_name &&
                        <Box mt={2}>
                            {card({ title: t("Assigned By"), name: serviceDetails?.inspection?.manager_name, image: serviceDetails?.inspection?.manager_image, subTitle: serviceDetails?.inspection?.department })}
                        </Box>
                    }
                    {serviceDetails?.resource?.length > 0 &&
                        <Box mt={2}>
                            {card({ title: t("Assigned To"), name: serviceDetails?.resource?.[0]?.first_name, image: serviceDetails?.resource?.[0]?.image_url, subTitle: serviceDetails?.resource?.[0]?.department })}
                        </Box>
                    }
                    {serviceDetails?.property?.name &&
                        <Box mt={2}>
                            {card({ title: t("Property and Unit Details"), name: serviceDetails?.property?.name, icon: <CasaGrandIcon />, subTitle: serviceDetails?.unit?.name })}
                        </Box>
                    }
                </Grid>
                <Grid item xs={9}>
                    <Box className={classes1.timeLineBox}>
                        <Subheader
                            title={t("Track")}
                            hideBackButton
                        />
                        <Box height='calc(100vh - 280px)' p={2} backgroundColor={"white"} position={"relative"}>
                            {serviceDetails?.inspection_tracker?.length > 0 ?
                                <VerticalLinearStepper data={serviceDetails?.inspection_tracker?.map((val) => { return { name: val?.title, created_by: val?.created_by, date: RemoveZUTCDateTime(val?.created_at, "DD-MM-YYYY hh:mm A"), icon: val.title === "Inspection Completed" ? <Box className={classes1.checkIconcircle}><CheckCircleIcon /></Box> : <TrackClockIcon />, notes: val?.description } })} customTrack />
                                : <Box className={classes1.notrackfound}><NoDataFound /></Box>}

                        </Box>
                    </Box >
                </Grid >

            </Grid >
            <AlertDialog
                open={showMore}
                header={request_type === "service" ? t("Service Inspection Requests") : request_type === "general" ? t("General Inspection Requests") : t("Delivery Order Requests")}
                isNormal
                onClose={() => onCloseModal(request_type)}
                component={
                    <>
                        <Box p={3}>
                            <SearchFilter
                                value={searchText}
                                handleChange={(value) => {
                                    handleSearch(value, request_type)
                                }}

                                placeholder="Search"
                            />

                            <Box mt={2}>

                                {request_type === "service" ? datas.multipleServiceData?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={datas.multipleServiceData?.length ?? ""}
                                        next={() => fetchMoreData(request_type)}
                                        hasMore={true}
                                        height={size?.height - 420}
                                    >{datas.multipleServiceData?.map((val) => {
                                        return (

                                            <Grid container mt={1} className={(datas.selected_service_inspection?.id || serviceDetails?.inspection?.id) === val.id ? classes1.showborder : classes1.noborder} alignItems={"center"} onClick={() => setDatas({ ...datas, selected_service_inspection: val })}>
                                                <Grid item xs={1.5}>
                                                    <InspectionIcon />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Box display={"flex"} justifyContent={"space-between"}>
                                                        <Typography className={classes1.cardTitle}>{val?.job_no}</Typography>
                                                    </Box>
                                                    <Typography className={classes1.cardSubTitle} noWrap>{`${t("Requested order on")} ${moment(val?.created_at).format("DD MMM YY hh:mm A")} by ${val?.created_by}`}</Typography>
                                                </Grid>
                                                <Grid item xs={1.5}>
                                                    <Box display={"flex"} justifyContent={"end"} style={{ cursor: "pointer" }} onClick={() => goToView(val, "service")}>
                                                        <InfoIcon />
                                                    </Box>

                                                </Grid>

                                            </Grid >

                                        )
                                    })}</InfiniteScroll > : <Box mt={4} height={size?.height - 420}><Typography textAlign="center">{t("No Data Found")}</Typography></Box> :
                                    request_type === "general" ? datas.multipleGeneralData?.length > 0 ?
                                        <InfiniteScroll
                                            dataLength={datas.multipleGeneralData?.length ?? ""}
                                            next={() => fetchMoreData(request_type)}

                                            hasMore={true}
                                            height={size?.height - 420}
                                        >{datas.multipleGeneralData?.map((val) => {
                                            return (

                                                <Grid container mt={1} className={(datas.selected_general_inspection?.id || serviceDetails?.inspection?.id) === val.id ? classes1.showborder : classes1.noborder} alignItems={"center"} onClick={() => setDatas({ ...datas, selected_general_inspection: val })}>
                                                    <Grid item xs={1.5}>
                                                        <InspectionIcon />
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <Typography className={classes1.cardTitle}>{val?.job_no}</Typography>
                                                        </Box>
                                                        <Typography className={classes1.cardSubTitle} noWrap>{`${t("Requested order on")} ${moment(val?.created_at).format("DD MMM YY hh:mm A")} by ${val?.created_by}`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={1.5}>
                                                        <Box display={"flex"} justifyContent={"end"} style={{ cursor: "pointer" }} onClick={() => goToView(val, "general")}>
                                                            <InfoIcon />
                                                        </Box>

                                                    </Grid>

                                                </Grid >

                                            )
                                        })}</InfiniteScroll > : <Box mt={4} height={size?.height - 420}><Typography textAlign="center">No Data Found</Typography></Box> : datas?.multipleDeliveryOrderData?.length > 0 ? <InfiniteScroll
                                            dataLength={datas.multipleDeliveryOrderData?.length ?? ""}
                                            next={() => fetchMoreData(request_type)}
                                            hasMore={true}
                                            height={size?.height - 420}
                                        >{datas.multipleDeliveryOrderData?.map((val) => {
                                            return (

                                                <Grid container alignItems={"center"} className={(datas.selected_delivery_inspection?.id || serviceDetails?.inspection?.id) === val.id ? classes1.showborder : classes1.noborder} mt={1} onClick={() => setDatas({ ...datas, selected_delivery_inspection: val })}>
                                                    <Grid item xs={1.5}>
                                                        <InspectionIcon />
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <Typography className={classes1.cardTitle}>{val?.job_no}</Typography>
                                                        </Box>
                                                        <Typography className={classes1.cardSubTitle} noWrap>{`Requested order on ${moment(val?.created_at).format("DD MMM YY hh:mm A")} by ${val?.created_by}`}</Typography>
                                                    </Grid>

                                                    <Grid item xs={1.5}>
                                                        <Box display={"flex"} justifyContent={"end"} style={{ cursor: "pointer" }} onClick={() => goToView(val, "delivery-order")}>
                                                            <InfoIcon />
                                                        </Box>
                                                    </Grid>

                                                </Grid >

                                            )
                                        })}</InfiniteScroll > : <Box mt={4} height={size?.height - 420}><Typography textAlign="center">{t("No Data Found")}</Typography></Box>
                                }
                            </Box >

                        </Box >
                        <Box display="flex" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} p={2}>
                            <Button variant="contained" fullWidth onClick={() => onChangeRequest(request_type)}>{t("Change")}</Button>

                        </Box>
                    </>
                }
            />
        </div >
    )
}

