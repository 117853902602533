import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
} from "@mui/material";
import { ScreensStylesParent } from "./style";
import { Search, PermissionCard, TextBox } from "../components";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { config } from "../../../config";
import { CustomSelect } from "../components/filterGenerator/components/select";
// alert message context
import { AlertContext, BackdropContext } from "../../../contexts";
import { CHECK_ROLES, CHECK_PERMISSION_PERMISSION } from '../queries'
import { UseDebounce } from "../../../components"
import { NetworkCall } from '../../../networkcall';
import { AlertProps, NetWorkCallMethods } from "../../../utils";



// add role
const addRoles = {
  role: "",
  des: "",
  permissionListState: "",
  isEdit: false,
  id: "",
  deletedList: [],
  module: "",
  error: {
    role: "",
    module: "",
    permissionListState: "",
  },
};
export const Roles = (props) => {
  const { t } = props
  // style
  const classes = ScreensStylesParent();
  // state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addRole, setaddRole] = useState({ ...addRoles });
  const [permissionID, setPermissionID] = useState({
    id: "",
    structure: [],
  });
  const [permissions, setPermissions] = useState([]);
  const [permissionSearch, setPermissionSearch] = useState("");
  const [permissionOption, setPermissionOption] = useState([]);
  // const [deletedPermission, setDeletedPermission] = useState([]);
  const [toggledisable, setToggleDisable] = useState(true);
  // hidden and show state
  const [hiddenPermissionList, setHiddenPermissionList] = useState(false);
  const debounce = UseDebounce()
  const [checkState, setCheckState] = React.useState(false)


  // context
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);

  // dialog close function
  const handleClose = (key) => {
    setDialogOpen(!dialogOpen);
    if (key === "close") {
      setaddRole({
        ...addRole,
        role: "",
        des: "",
        isEdit: false,
        id: "",
        module: "",
        error: {
          role: "",
          module: "",
          permissionListState: "",
        },
      });
    }
  };

  const handleAddRole = () => {
    setDialogOpen(true);
    setaddRole(addRoles);
    setPermissionOption([])
    setHiddenPermissionList(false)
  }

  // onChange role
  const roleAddedFunction = (key, value) => {
    // let error = addRole?.error;
    // error[key] = "";
    setaddRole({ ...addRole, [key]: value });
  };

  // select permission
  const seclectPermission = (e) => {
    setPermissionID({
      id: e?.id,
    });
    setaddRole({
      role: e?.name,
      des: e?.description,
      id: e?.id,
      isEdit: true,
      module: e?.module,
      permissionListState: {
        value: e?.attached_permissions?.[0]?.id,
        label: e?.attached_permissions?.[0]?.name,
      },
    });
    getpermission(e?.module?.value);
    setToggleDisable(false);
  };

  // is acive permission
  const activePermission = (e) => {
    updateFunction(true, e);
  };

  // search list
  const searchPermission = (e) => {
    setPermissionSearch(e);
    getRoles(e);
  };
  // get roles list
  const getRoles = (searchvalue, infinity) => {
    if (infinity) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: t("Loading"),
      });
    }
    axios
      .get(
        `${config?.api_url}/iam/get_roles${searchvalue?.length > 0 ? `?search=${searchvalue}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((res) => {
        setPermissions(res?.data?.data);
        setPermissionID({
          id: res?.data?.data?.[0]?.id,
        });
        setaddRole({
          role: res?.data?.data?.[0]?.name,
          des: res?.data?.data?.[0]?.description,
          id: res?.data?.data?.[0]?.id,
          isEdit: true,
          module: res?.data?.data?.[0]?.module,
          permissionListState: {
            value: res?.data?.data?.[0]?.attached_permissions?.[0]?.id,
            label: res?.data?.data?.[0]?.attached_permissions?.[0]?.name,
          },
        });
        getpermission(res?.data?.data?.[0]?.module?.value);
        setToggleDisable(false);
        if (infinity) {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: t("Loading"),
          });
        }
      })
      .catch((err) => {
        if (infinity) {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: t("Loading"),
          });
        }
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
      });
  };
  //   get permission
  const getpermission = (e) => {
    if(!e){
      return null
    }
    axios
      .get(`${config?.api_url}/iam/get_permissions?module_id=${e}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        let option = res?.data?.data?.map((value) => {
          return {
            value: value?.id,
            label: value?.name,
          };
        });
        setPermissionOption(option);
        setHiddenPermissionList(true);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
      });
  };

  //   get repository
  const getRepository = (val) => {
    axios
      .get(
        `${config?.api_url}/iam/get_repository`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((res) => {
        if (val) {
          setaddRole({ ...addRole, permissionListState: [] });
        }
      })
      .catch((err) => { });
  };

  // add all roles
  const saveRole = () => {
    if (checkState) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Role Already Exists"),
      });
    }
    if (validate()) {
      const data = {
        name: addRole?.role,
        description: addRole?.des,
        module_id: addRole?.module?.value,
        permissions: [{ id: addRole?.permissionListState?.value }]
      };
      if (addRole?.isEdit) {
        data["id"] = permissionID?.id;
      }
      axios
        .post(
          `${config?.api_url}/iam/${addRole?.isEdit ? "update_roles" : "create_roles"
          }`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        )
        .then((res) => {
          getRoles("");
          handleClose("close");
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Role ${addRole?.isEdit ? "Updated" : "created"
              } successfully.`,
          });
        })
        .catch((err) => {
          handleClose("close");
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something Went Wrong."),
          });
        });
    }
  };

  // update
  const updateFunction = (key, value) => {
    const data = {
      id: permissionID?.id,
    };
    if (addRole?.isEdit) {
      data["id"] = permissionID?.id;
    }

    if (key) {
      data["is_active"] = value;
    }
    if (key === "is_delete") {
      data["is_delete"] = true;
    }
    if (key === "permission") {
      data["permissions"] = [{ id: addRole?.permissionListState?.value }];
    }

    axios
      .post(`${config?.api_url}/iam/update_roles`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        getRoles("");
        setHiddenPermissionList(true);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Role Updated successfully."),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
      });
  };

  const updatePermission = () => {
    if (validatePermissionList()) {
      updateFunction("permission");
    }
  };
  // cancel
  const cancelFunction = () => {
    setaddRole({ ...addRoles });
    setPermissionID({
      id: "",
      structure: [],
    });
  };

  // valdation
  //   add role validation
  const validate = () => {
    let isValid = true;
    let error = addRole.error;
    if (addRole?.role?.length === 0) {
      isValid = false;
      error.role = t("Name is Required");
    }
    if (addRole?.module?.length === 0) {
      isValid = false;
      error.module = t("Module is Required");
    }
    if (addRole?.permissionListState?.length === 0) {
      isValid = false;
      error.permissionListState = t("Permission is Required");
    }
    setaddRole({ ...addRole, error });

    return isValid;
  };
  const validatePermissionList = () => {
    let isValid = true;
    let error = addRole.error;
    if (addRole?.permissionListState?.length === 0) {
      isValid = false;
      error.permissionListState = t("Permission is Required");
    }
    setaddRole({ ...addRole, error });

    return isValid;
  };
  useEffect(() => {
    getRepository();
    getRoles("", true);
    // eslint-disable-next-line
  }, []);

  const check = (data) => {
    let payload = {
      query: data?.query
    }
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      if (res?.data?.data?.client_roles?.length > 0) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: `${data?.type} ${t("Already Exists")}`,
        });
        setCheckState(true)
      }
      else {
        setCheckState(false)
      }
    })
      .catch((err) => {
        setCheckState(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
      });
  }
  const checkrole = (e) => {
    debounce(() => check({
      type: "Role",
      query: CHECK_ROLES(e?.target?.value).loc.source.body,
    }), 800)
    roleAddedFunction("role", e?.target?.value)

  }
  const checkPermission = (id) => {
    const payload = {
      query: CHECK_PERMISSION_PERMISSION(id).loc.source.body
    }
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      if (res?.data?.data?.client_user_role_access?.map((x) => x?.client_role).filter(Boolean)?.length > 0) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: `${t("Permission cannot be deleted as its used in roles")}`,
        });
      }
      else {
        updateFunction("is_delete")
      }
    })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong."),
        });
      });
  }

  const deletePermission = () => {
    checkPermission(permissionID?.id)
  }
  return (
    <div>
      <Grid container>
        <Grid item sm={6} md={4} lg={4}>
          {/* search field */}
          <div className={classes.section}>
            <Search
              title={`${permissions?.length ?? 0} ${t("Role")}`}
              handleAdd={handleAddRole}
              searchFumction={searchPermission}
              search={permissionSearch}
              addBtn={true}
              t={t}
            />
          </div>
          {/* permission card */}
          <div className={classes.fixedStylePermission}>
            <div className={classes.section1}>
              {permissions?.map((x, index) => {
                return (
                  <PermissionCard
                    t={t}
                    title={x?.name}
                    data={x}
                    permissionID={permissionID?.id}
                    id={x?.id}
                    seclectPermission={seclectPermission}
                    editFunction={handleClose}
                    index={index}
                    activePermission={activePermission}
                    deletePermission={deletePermission}
                    disabled={toggledisable}
                  />
                );
              })}
            </div>
          </div>
        </Grid>
        <Grid item sm={6} md={8} lg={8} className={classes.borderLeft}>
          {/* title name */}
          <div className={classes.section}>
            <Typography className={classes.screenTitle}>
              {hiddenPermissionList ? t("Edit Role") : t("Roles")}
            </Typography>
          </div>
          {permissionID?.id?.length > 0 && (
            <div className={classes.fixedStyleRole}>
              <div className={classes.section1}>
                <>
                  <CustomSelect
                    color={"#98A0AC"}
                    fontSize={12}
                    required
                    fontWeight={400}
                    placeholder={t("Select Permission List")}
                    label={t("Permission List")}
                    options={permissionOption ?? []}
                    onChange={(value) =>
                      roleAddedFunction("permissionListState", value)
                    }
                    value={addRole?.permissionListState}
                    error={addRole?.error?.permissionListState?.length > 0}
                    errorText={addRole?.error?.permissionListState}
                  />
                </>
              </div >
              {hiddenPermissionList && (
                <Box className={classes.bottomButton2}>
                  <Button
                    className={classes.cancelBtn}
                    onClick={() => cancelFunction()}
                    variant="outlined"
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    className={classes.saveBtn}
                    onClick={() => updatePermission()}
                    variant="contained"
                    disabled={
                      addRole?.permissionListState?.label?.length > 0
                        ? false
                        : true
                    }
                  >
                    {addRole?.isEdit ? t("Update") : t("Save")}
                  </Button>
                </Box>
              )
              }
            </div >
          )}
        </Grid >
      </Grid >

      {/* add pop up */}
      < Dialog
        className={classes.dialog}
        open={dialogOpen}
        onClose={() => handleClose("close")}
        maxWidth="sm"
      >
        <DialogTitle className={classes.header}>
          <span> {addRole?.isEdit ? t("Edit") : t("Add New")} {t("Role")}</span>{" "}
          <CloseIcon
            onClick={() => handleClose("close")}
            style={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent>
          <div
            className={classes.dialogParent}>
            <CustomSelect
              marginBottom={4}
              color={"#98A0AC"}
              fontSize={12}
              required
              fontWeight={400}
              label={t("Module")}
              placeholder={t("Select Module")}
              options={props?.moduleOption ?? []}
              onChange={(e) => {
                roleAddedFunction("module", e)
                e ? getpermission(e?.value) : setHiddenPermissionList(false)
              }}
              value={addRole?.module}
              loading={props?.loading === "module"}
              error={addRole?.error?.module?.length > 0}
              errorText={addRole?.error?.module}
              isReadOnly={addRole?.isEdit ? true : false}
              menuPlacement={"bottom"}
              maxMenuHeight={"150px"}
              minMenuHeight={"150px"}

            />
            <Box style={{ height: "10px" }} />
            <TextBox
              height={'46px'}
              placeholder={""}
              value={addRole?.role}
              onChange={(value) => checkrole(value)}
              label={t("Role Name")}
              isrequired
              isError={addRole?.error?.role?.length > 0}
              errorMessage={addRole?.error?.role}
            />
            <Box style={{ height: "10px" }} />
            <CustomSelect
              isReadOnly={!hiddenPermissionList}
              menuPlacement={"bottom"}
              maxMenuHeight={"150px"}
              minMenuHeight={"150px"}
              color={"#98A0AC"}
              fontSize={12}
              required
              fontWeight={400}
              placeholder={t("Select Permission List")}
              label={t("Permission List")}
              options={permissionOption ?? []}
              onChange={(value) => roleAddedFunction("permissionListState", value)}
              value={addRole?.permissionListState}
              error={addRole?.error?.permissionListState?.length > 0}
              errorText={addRole?.error?.permissionListState} />
            <Box style={{ height: "10px" }} />
            <TextBox
              placeholder={t("Description")}
              multiline={true}
              value={addRole?.des}
              onChange={(e) => roleAddedFunction("des", e?.target?.value)}
              label={t("Description")}
            />
            <Box style={{ height: "10px" }} />
            <Box className={classes.addRoleButton}>
              <Box>
                <Button className={classes.cancelBtn} onClick={handleClose}>
                  {t("Cancel")}
                </Button>
              </Box>
              <Box>
                <Button className={classes.saveBtn} onClick={saveRole}>
                  {addRole?.isEdit ? t("Update") : t("Save")}
                </Button>
              </Box>
            </Box>
          </div>
        </DialogContent>
      </Dialog >
    </div >
  );
};
