import { Box, Button, Container, Grid, Hidden, Skeleton, Stack, Typography } from "@mui/material";
import { GET_USER_PROFILE_ID_BY_USER_ID as queryOne, updateDeviceToken } from "../../graphql/queries";
import React, { useContext, useEffect, useState } from "react";
import { useStyles } from "./style";
import { useHistory, useLocation } from "react-router-dom";
import { WelcomeBanner } from "../../components";
import { Routes } from "../../router/routes";
import { EnvTag } from "../../components/envTag";
import { TitleBar } from "../../components/titlebar";
import { BackButtonRound } from "../../assets/back_button_round";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, RetainLocalStorage, clearAccess, getAccessRoutes, verifyToken } from "../../utils";
import { LoginSuccess } from "../../router/access";
import axios from "axios";
import { CompanyList } from "./component/companylist";

export const ComapnyPage = ({
    t = () => false,
    size = {},
    screen_state = {},
    set_screen_state = () => false,
    initital_screen_state = {},
}) => {
    const version = localStorage.getItem(LocalStorageKeys.version);
    const classes = useStyles({ size });
    const { state } = useLocation();
    const history = useHistory();
    const backdrop = useContext(BackdropContext);
    const auth = useContext(AuthContext);
    const alert = useContext(AlertContext);
    const [client, setClient] = useState({
        id: state?.main?.clientData?.user?.clients?.[0]?.id,
        tenantsID: state?.main?.clientData?.user?.clients?.[0]?.tenants?.slug
    })
    const [open, setOpen] = useState({
        permission: {},
        bool: false,
        company: [],
        name: ""
    })
    const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);

    useEffect(() => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "", });
        if (client?.id) {
            if (state?.main?.clientData?.user?.clients?.length === 1) {
                handleSubmit()
            } else {
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            }
        } else {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }
        // eslint-disable-next-line
    }, [client?.id]);

    // get user details
    const getUserDetails = (roles, type, userId, access, permissionRoutes, last_login , initial_routes) => {
        let company = access?.[auth?.auth?.auth?.activeRole]?.company_ids;

        const payload = {
            query: queryOne,
            variables: { userID: userId },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            if (company?.length > 0) {
                setOpen({
                    bool: true,
                    permission: permissionRoutes,
                    company: company,
                    name: `${response?.data?.data?.user_profiles?.[0]?.first_name ?? ""} ${response?.data?.data?.user_profiles?.[0]?.last_name ?? ""}`
                })
            } else {
                let path = LoginSuccess(permissionRoutes);

                history.push({
                    pathname: path,
                    state: {
                        main: {
                            first_name: `${response?.data?.data?.user_profiles?.[0]?.first_name ?? ""} ${response?.data?.data?.user_profiles?.[0]?.last_name ?? ""}`,
                            isBanner: true
                        },
                    },
                });
            }

            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Client Selected Successfully"),
            });
            localStorage.setItem(
                LocalStorageKeys.profileID,
                response?.data?.data?.user_profiles?.[0]?.id
            );
        })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong please try again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            });
    }
    const handleAccess = (role_id) => {
        // select role api
        axios.get(`${config.authapi}/auth/select_role?role_id=${role_id}`, { headers: { Authorization: `Bearer ${localStorage.getItem(LocalStorageKeys.authToken)}` } }).then((res) => {
            // token set
            localStorage.setItem(
                LocalStorageKeys.authToken,
                res?.data?.token
            )
            // client ID
            localStorage.setItem(
                LocalStorageKeys.clinetID,
                res?.data?.selected_client?.id
            );
            // role switch token

            // localStorage.setItem(LocalStorageKeys.role_switch_token, res?.data?.role_switch_token);
            // role id
            localStorage.setItem(LocalStorageKeys.roleID, res?.data?.selected_role?.role?.role_id);
            // role name
            localStorage.setItem(LocalStorageKeys.activeRole, Object.keys(res?.data?.access)?.[0]);

            //get user profile details
            // getUserDetails(res?.data?.userProfile?.[0]?.roles ,res?.data?.selected_role?.role?.name ,res?.data?.selected_role?.profile_id)

            let access = res?.data?.access
            let permissionData = Object.keys(access)
            let permissionJson = access?.[permissionData?.[0]]?.role?.permissions?.[0]?.structure
            localStorage.setItem(LocalStorageKeys.activeRoleModuleId, access?.[permissionData?.[0]]?.module_id);
            let initial_routes = permissionJson?.children?.[0]?.router
            clearAccess()
            let permisionlist = getAccessRoutes(permissionJson)
            let permissionRoutes = permisionlist?.map((val) => val?.route)
            localStorage.setItem(LocalStorageKeys.permission, JSON.stringify(permissionRoutes))
            getUserDetails(res?.data?.userProfile?.[0]?.roles, Object.keys(res?.data?.access)?.[0], res?.data?.selected_role?.profile_id, access, permissionRoutes, res?.data?.userProfile?.[0]?.last_logged_in , initial_routes)

            setDeviceToken(res?.data?.userProfile?.[0]?.id, res?.data?.selected_client?.id)
        }).catch((err) => {
            console.log(err , 'err')
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some thing went wrong"),
            });
            RetainLocalStorage();
            history.push(Routes.login);
        })
    }

    const getModualList = (modulesData, modules) => {
        let data = [];
        //eslint-disable-next-line
        modulesData.map((key) => {
            let obj = {
                name: key,
                ...modules[key]
            }
            data.push(obj)
        })
        return data;
    }
    // sumbit function
    const handleSubmit = (e) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        if (e) {
            e.preventDefault()
        }
        // select client
        axios.get(`${config.authapi}/auth/select_client?client_id=${client?.id ?? ""}`, { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } })
            .then((res) => {

                if (Object.keys(res?.data?.userProfile?.[0]?.module_access_roles).length) {
                    let RolesID = res?.data?.userProfile?.filter(i => Object.keys(i?.module_access_roles)?.length > 0)?.[0]?.roles;
                    let modules = res?.data?.userProfile?.filter(i => Object.keys(i?.module_access_roles)?.length > 0)?.[0]?.module_access_roles ?? {};
                    let modulesData = Object.keys(modules)
                    let modulesArray1 = modulesData.map((key) => modules[key])
                    let modulesArray = getModualList(modulesData, modules);
                    auth.setModule(modulesArray)
                    // localStorage.setItem(LocalStorageKeys.modules, JSON.stringify(modulesArray));
                    localStorage.setItem(LocalStorageKeys.role, JSON.stringify(RolesID));
                    localStorage.setItem(LocalStorageKeys.slug, (res?.data?.selected_client?.tenants?.slug));
                    localStorage.setItem(LocalStorageKeys.clinetID, res?.data?.selected_client?.id);
                    localStorage.setItem(LocalStorageKeys.authToken, res?.data?.token);
                    if (res?.data?.display_name) {
                        localStorage.setItem(LocalStorageKeys.displayName, JSON.stringify(res?.data?.display_name));
                    }
                    if (res?.data?.plan_logo) {
                        localStorage.setItem(LocalStorageKeys.planLogo, JSON.stringify(res?.data?.plan_logo));
                    }
                    if (res.status === 200) {
                        window.auth = verifyToken(res?.data?.token)
                        handleAccess(modulesArray1?.[0]?.client_roles?.[0]?.id)

                    } else {
                        history.push(Routes.login)
                    }
                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("You don't have access to this client"),
                    });
                }
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }).catch((err) => {
                console.log(err)
                if (err?.response?.data?.status === 423) {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: err?.response?.data?.message,
                    });
                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: err?.response?.status === 406 ? err?.response?.data?.message : "Some thing went wrong",
                    });
                }

                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            });

    }
    // company select function
    const clientSelect = (client) => {
        setClient({
            id: client?.id,
            tenantsID: client?.tenants?.slug
        })
    }
    //onClose banner
    const closeBanner = () => {
        setOpen({
            ...open, bool: false
        })
        let path = LoginSuccess(open?.permission);
        history.push({
            pathname: path,
            state: {
                main: {
                    first_name: open?.name ?? "",
                    isBanner: true
                },
            },
        });
    }
    // set device token
    const setDeviceToken = (val, client) => {
        if (localStorage.getItem(LocalStorageKeys.deviceToken)) {
            const variables = {
                query: updateDeviceToken,
                variables: {
                    insert: [
                        {
                            user_profile_id: val,
                            token: localStorage.getItem(LocalStorageKeys.deviceToken),
                            is_active: true,
                            client: client,
                            app_build: config.app_x_build
                        },
                    ],
                },
            };

            NetworkCall(
                `${config.graphql_url}`,
                NetWorkCallMethods.post,
                variables,
                null,
                true,
                false
            )
                .then((response) => { })
                .catch((error) => {
                    console.log(error)
                });
        }
    };

    const handleGoBack = () => {
        history.goBack(-1);
    }

    const handleImageOnLoad = () => {
        setIsBackgroundImageLoading(false);
    }

    const handleImageOnError = () => {
        set_screen_state({ ...screen_state, mob_background: initital_screen_state?.mob_background });
    }

    return (
        <>
            {/* Mobile UI */}
            <Hidden smUp>
                <Container className={classes.mob_right_section_card} maxWidth="sm">
                    <TitleBar is_back_button title={t("Clients")} goBack={handleGoBack} />
                    <Grid container className={classes.mob_content}>
                        <Grid item xs={12} className={classes.mob_body_items}
                            sx={{ height: size?.height - 134, overflow: "overlay" }}>
                            <Box className={classes.mob_background}>
                                {isBackgroundImageLoading && <Skeleton variant="rounded" className={classes.mob_background_image} />}
                                <img className={classes.mob_background_image}
                                    style={{ display: isBackgroundImageLoading ? "none" : "block" }}
                                    src={screen_state?.mob_background} alt=""
                                    onLoad={handleImageOnLoad}
                                    onError={handleImageOnError} />
                            </Box>
                            <Box height={"16px"} />
                            <Typography className={classes.web_title}>
                                {t("Select Client")}
                            </Typography>
                            <Box height={"16px"} />
                            <Grid>
                                <Grid item xs={12}>
                                    <div className={classes.m_list}
                                        style={{
                                            overflow: "overlay", paddingBottom: "20px",
                                            height: size.height > 700 ? size?.height - 457 : 200,
                                        }}>
                                        {
                                            state?.main?.clientData?.user?.clients?.map((value) => {
                                                return <CompanyList t={t} data={value} companySelect={clientSelect} companyID={client?.id} />
                                            })
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} alignSelf={"self-end"} className={classes.mob_bottom_items}>
                            <Stack direction={"column"} alignItems={"start"}>
                                <Button className={classes.mob_set_up_new_button}
                                    onClick={handleSubmit}>
                                    <Typography className={classes.mob_set_up_new_button_text}>
                                        {t("Continue")}
                                    </Typography>
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Hidden>

            {/* Web & Tablet UI */}
            <Hidden smDown>
                <Container className={classes.web_right_section_card} maxWidth="sm">
                    <Grid container className={classes.web_content} justifyContent={"space-between"}>
                        <EnvTag top={16} right={16} />
                        <Grid item xs={12}>
                            <Box className={classes.web_back_button}
                                onClick={() => history.goBack(-1)}>
                                <BackButtonRound />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction={"column"} alignItems={"start"} display={"grid"}>
                                <Box height={"16px"} />
                                <img className={classes.web_logo} src={screen_state?.logo} alt={t("logo")} />
                                <Box height={"16px"} />
                                <Typography className={classes.web_title}>
                                    {t("Select Client")}
                                </Typography>
                                <Box height={"8px"} />
                                <Grid>
                                    <Grid item xs={12} marginBottom={"60px"}>
                                        <div style={{ height: 300 }} className={classes.list}>
                                            {
                                                state?.main?.clientData?.user?.clients?.map((value) => {
                                                    return (
                                                        <CompanyList data={value} companySelect={clientSelect} companyID={client?.id} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} alignSelf={"self-end"}>
                            <Stack direction={"column"} alignItems={"start"} display={"grid"}>
                                <Button className={classes.web_login_button}
                                    onClick={handleSubmit}>
                                    <Typography className={classes.web_login_button_text}>
                                        {t("Continue")}
                                    </Typography>
                                </Button>
                                <Box height={"24px"} />
                                <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    {version &&
                                        <Typography className={classes.version_no}>
                                            {`V ${version}`}
                                        </Typography>
                                    }
                                    <img className={classes.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Hidden>
            {
                open?.bool &&
                <WelcomeBanner open={open?.bool} company={open?.company} closeDrawer={closeBanner} name={open?.name} />
            }
        </>
    );
}