import { Box } from "@mui/material";
import React from "react";
import { CompanySettingsList } from "../../../components";
import { useStyles } from "./styles";


export const ResidentSetting = ({ t, data = {},
    updateState = () => false, }) => {
    const classes = useStyles();
    return (
        <Box>
            {/*setting save */}
            <Box className={classes.settingRoot}>
                {/*"Enable Reservation Feature"*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Discussion Forum")}
                        onChange={(value) => updateState("discussion", value)}
                        value={data?.discussion}
                        toggle={true}
                    />
                </Box>

                {/*Enable Auto Deposite Amount Calculation Feature*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("My Neighbours")}
                        onChange={(value) => updateState("neigbours", value)}
                        value={data?.neigbours}
                        toggle={true}
                    />

                </Box>
                {/*Enable Auto Reservation Amount Calculation Feature*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("My Contacts")}
                        onChange={(value) => updateState("contact", value)}
                        value={data?.contact}
                        toggle={true}
                    />
                </Box>

                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Survey")}
                        onChange={(value) => updateState("survey", value)}
                        value={data?.survey}
                        toggle={true}
                    />

                </Box>
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Walk-in Tracking")}
                        onChange={(value) => updateState("walkin", value)}
                        value={data?.walkin}
                        toggle={true}
                    />
                </Box>
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Parking Alarm Notification")}
                        onChange={(value) => updateState("parking", value)}
                        value={data?.parking}
                        toggle={true}
                    />
                </Box>
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Amenity Booking")}
                        onChange={(value) => updateState("booking", value)}
                        value={data?.booking}
                        toggle={true}
                    />
                </Box>
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Delivery Collection")}
                        onChange={(value) => updateState("deleivery", value)}
                        value={data?.deleivery}
                        toggle={true}
                    />
                </Box>
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Polls")}
                        onChange={(value) => updateState("polls", value)}
                        value={data?.polls}
                        toggle={true}
                    />
                </Box>
            </Box>
        </Box>
    )
}