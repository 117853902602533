import gql from "graphql-tag";

export const GET_GENERAL_CATEGORY = (offset, limit, searchText, { isActive = false, client }) => gql`
query GET_GENERAL_CATEGORY{
  count: general_category_master(
    where: {
      is_delete:{eq:false}
      name: { iregex: "${searchText}"}
     is_active:{in:[${isActive}]}
      client:{eq:"${client}"}
    }
  ){
    count:count_id
  }
    general_category_master(
      offset: ${offset}
      limit: ${limit}
      where: {is_delete:{eq:false} name: {iregex: "${searchText}"}
      is_active:{in:[${isActive}]}
      client:{eq:"${client}"}
    }
      order_by: { created_at: desc ,name:asc }
    ){
        id
        type: name
        is_active
        is_external
      }
}`;

export const GET_GENERAL_SUB_CATEGORY = (offset, limit, searchText, client, status) => gql`
query GET_GENERAL_SUB_CATEGORY{
  count: general_sub_category_master(
    where: {is_delete:{eq:false} name: {iregex: "${searchText}"}   client:{eq:"${client}"} 
    ${status?.length > 0 ? `is_active:{in:[${status}]}` : ''}
  }
  ){
    count:count_id
  }
  general_sub_category_master(
    offset: ${offset}
    limit: ${limit}
    where: {is_delete:{eq:false} name: {iregex: "${searchText}"}   client:{eq:"${client}"}
    ${status?.length > 0 ? `is_active:{in:[${status}]}` : ''}
  }
    order_by: { created_at: desc ,name:asc}
  ){
      id
      type: name
      general_category_master{
      type: name
      id
       }
    is_active
    is_external
    }
}`;

export const GET_STATUS_MASTER = (offset, limit, searchText, client) => gql`
query GET_STATUS_MASTER{
  count: general_status_master(
    where: {name: {iregex: "${searchText}"}   client:{eq:"${client}"}}
  ){
    count:count_id
  }
    general_status_master(
      offset: ${offset}
      limit: ${limit}
      where: {name: {iregex: "${searchText}"}   client:{eq:"${client}"}}
      order_by: { created_at: desc ,name:asc}
    ){
        id
        type: name
        is_active
        is_external
      }
}`;

export const GET_MAINTENANCE_CATEGORY = (offset, limit, searchText, { isActive, client }) => gql`
query GET_MAINTENANCE_CATEGORY{
  count: maintenance_category_master(
    where: {
      is_delete:{eq:false}
      name: {  iregex: "${searchText}"}
      is_active:{in:[${isActive}]}
      client:{eq:"${client}"}
  }
  ){
    count:count_id
  }
    maintenance_category_master(
      offset: ${offset}
      limit: ${limit}
      where: {is_delete:{eq:false} name: {iregex: "${searchText}"}
      is_active:{in:[${isActive}]}
      client:{eq:"${client}"}
    }
      order_by: { created_at: desc ,name:asc}
    ){
        id
        type: name
        is_active
        is_external
      }

}`;

export const GET_MAINTENANCE_SUB_CATEGORY = (offset, limit, searchText, client, status = []) => gql`
query GET_MAINTENANCE_SUB_CATEGORY{
  count: maintenance_sub_category_master(
    where: {is_delete:{eq:false} name: {iregex: "${searchText}"}   client:{eq:"${client}"}
    ${status?.length > 0 ? `is_active:{in:[${status}]}` : ''}}
  ){
    count:count_id
  }
    maintenance_sub_category_master(
      offset: ${offset}
      limit: ${limit}
      where: {is_delete:{eq:false} name: {iregex: "${searchText}"}   client:{eq:"${client}"}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ''}}
      order_by: { created_at: desc ,name:asc}
    ){
        id
        type: name
        maintenance_category_master{
          type: name
          id
        }
        is_active
        is_external
      }
}`;

export const GET_MAINTENANCE_STATUS = (offset, limit, searchText, client) => gql`
query GET_MAINTENANCE_STATUS{
  count: maintenance_status_master(
    where: {name: {iregex: "${searchText}"}   client:{eq:"${client}"}}
  ){
    count:count_id
  }
  maintenance_status_master(
  offset: ${offset}
  limit: ${limit}
  where: {name: {iregex: "${searchText}"}   client:{eq:"${client}"}}
  order_by: { created_at: desc ,name:asc }
  ){
    id
    type: name
    is_active
    is_external
  }
}`;

export const ADD_GENERAL_CATEGORY = `
mutation($insert: [general_category_masterInput!]) {
  general_category_master(
    insert: $insert
  ) {
    id
  }
}`;

export const ADD_SERVICE_MASTER = (tableName, tableType) => {
  return `
  mutation($insert: [${tableType}!]) {
    ${tableName}(
      insert: $insert
    ) {
      id
    }
  }`;
}


export const EDIT_SERVICE_MASTER = (tableName) => {
  return `
 mutation{
  ${tableName}(
    where: { id: { eq: $updateById } }
    update: $update
  ) {
     id
  }
}`;
}