import { useApolloClient } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import React from "react";
import { FormGenerator } from "../../../components";
import { UPDATE_KYC_STATUS } from "../../../graphql/agreementQurery";
import { AggrementComponentStyles } from "./styles";

export const KYCForm = ({ type = "", onClose = () => false, reload = () => false, selected = {}, t = () => false }) => {
    const [remarks, setRemarks] = React.useState("");
    const classes = AggrementComponentStyles()
    const client = useApolloClient();

    const onSubmit = () => {

        client.mutate({
            mutation: UPDATE_KYC_STATUS,
            variables: {
                id: selected?.id,
                payload: {
                    status: type === "Decline" ? "Declined" : "Verified",
                    remarks: remarks
                }
            }
        }).then((response) => {
            reload()
            onClose()
        }).catch((err) => {
            console.log(err)
        })
    }
    const formJson = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
            },
            isActive: true,
            component: "text",
            label: "Remarks",
            placeholder: "Enter Remarks",
            value: remarks,
            onChange: (value) => setRemarks(value?.target?.value),
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "button",
            label: "Close",
            variant: "outlined",
            onClick: () => onClose()
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
            },
            isActive: true,
            component: "button",
            label: "Update",
            onClick: () => onSubmit(),
            disable: remarks?.length > 0 ? false : true
        },
    ]
    return (
        <Box>
            <Typography
                sx={{
                    backgroundColor: type === "Decline" ? "#FFECEC" : "#EEF9EE",
                    color: type === "Decline" ? "#FF4B4B" : "#5AC782",
                }}
                className={classes.status}>{type}</Typography>
            <FormGenerator t={t} components={formJson} />
        </Box>
    )
}