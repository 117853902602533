import { Box, Grid } from "@mui/material"
import React from "react"
import { SearchFilter, TableWithPagination } from "../../../components"
import { NewLoader } from "../../../components/newLoader"
import { GET_PROPERTY_BYCOMPANY } from "../../../graphql/scheduleBoardQuery"
import { PropertyDataType, PropertyHeading, PropertyPath } from "../utils"
import { NetworkCall } from "../../../networkcall"
import { config } from "../../../config"
import { NetWorkCallMethods } from "../../../utils"

export const ChooseProperties = ({ state = {}, data = {}, t = () => false, updateState = () => false, form_type = "Unit", isView = false }) => {
    const [searchText, setSearchText] = React.useState("")
    // const [filterData, setFilterData] = React.useState({})
    const [tableData, setTableData] = React.useState({ row: [], count: 0 })
    const [loading, setLoading] = React.useState(false)
    // const [filterDrawer, setFilterDrawer] = React.useState({})
    // const [page, setPage] = React.useState(1)
    // const [limit, setLimit] = React.useState(10)
    // const [offset, setOffset] = React.useState(10)
    React.useEffect(() => {
        if (data?.contract_details === null|| data?.contract_details?.length === 0) {
            getPropertyDropdown()
        }
        else{
            const result = data?.selected_properties?.map((x) => {
                return {
                    ...x,
                    name: x?.property?.name,
                    value:x?.property?.id,
                    label: x?.property?.name,
                    logo:x?.property?.logo,
                    property_no:x?.property?.property_no,
                    location: x?.property?.address?.street_1?.concat(",")??"" +x?.property?.address?.city + "," + x?.property?.address?.country,
                    is_active: true,
                    is_checked:x?.is_checked
                }
            })
            setTableData({ row:state?.is_edit?data?.selected_properties:result,allData:data?.selected_properties })
        }
        //eslint-disable-next-line
    }, [])
    // const fetchMoreData = () => {
    //     setOffset(offset + 10)
    // }
    const getPropertyDropdown = (company = []) => {
      let payload = {
        query: GET_PROPERTY_BYCOMPANY,
        variables: {
          company_id: [state?.company?.value],
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      ).then((res) => {
        setLoading(false);
        const result = res?.data?.data?.property?.map((x) => {
          return {
            ...x,
            name: x?.label,
            location:
              x.address?.area +
              x.address?.street_1 +
              "," +
              x?.address?.city +
              "," +
              x.address?.country,
            is_active: true,
            is_checked:
              data?.selected_properties?.filter((val) => {
                return x?.value === (val?.value || val?.id);
              })?.[0]?.is_checked ?? false,
          };
        });
        setTableData({ row: result, allData: result });
      });
    };

    const handleTableSearch = (val) => {
        setSearchText(val)
        if (val?.length > 0) {
            const result =tableData?.row?.filter((x) => { return (x?.label.toLowerCase().includes(val.toLowerCase()) || x?.property_no.includes(val)) })
            setTableData({ ...tableData, row: result })
        }
        else {
            setTableData({...tableData,row:tableData?.allData})
            // console.log("result","no selected",data)
        }
    }
    // const handleTablePagination = () => {

    // }
    // const handleTablePageLimit = () => {

    // }
    const handleTableIcon = (type, details, is_checked) => {
        if (type === "customCheckBox") {
            const result = tableData?.row?.map((x) => {
                return {
                    ...x,
                    is_checked: x?.value === details?.value ? is_checked : x?.is_checked
                }

            })
            updateState("selected_properties", result?.filter((val) => { return val?.is_checked }))
            setTableData({ ...tableData, row: result })
        }
    }
    const allhandleCheck = (is_checked) => {
        const result = tableData?.row?.map((x) => {
            return {
                ...x,
                is_checked: is_checked
            }

        })
        setTableData({ ...tableData, row: result })
        updateState("selected_properties", result?.filter((val) => { return val?.is_checked }))
    }
    return (
        <Box>
            {/* {form_type === "checkList" &&
                <TabContext value={tabValue}>
                    <TabList onChange={handleTabChange}>
                        <Tab label={t("Monthly")} value="1" />
                        <Tab label={t("Draft")} value="2" />
                    </TabList>
                    <Divider />
                </TabContext>
            } */}
            {loading ? <NewLoader />
                :
                <Box p={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} lg={3}>
                            <SearchFilter value={searchText} placeholder={t("Search")}
                                handleChange={(value) => handleTableSearch(value)} />
                        </Grid>
                        <Grid item xs={6} sm={6} lg={9}>
                            <Box display={"flex"} justifyContent={"end"}>

                            </Box>
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        <TableWithPagination
                            heading={PropertyHeading(t)}
                            rows={tableData?.row}
                            path={PropertyPath}
                            showpagination={false}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={PropertyDataType}
                            allhandleCheck={allhandleCheck}
                            checkboxKey="is_checked"
                            allSelect={tableData?.row?.every((x) => { return x?.is_checked })}
                            // handlePagination={handleTablePagination}
                            // handleChangeLimit={handleTablePageLimit}
                            // totalRowsCount={form_type === "unit" ? data?.selected_items?.length : form_type === "resource" ? data?.selected_resources?.length : data?.selected_checklist?.length}
                            // page={page}
                            // limit={limit}
                            height={isView ? 'calc(100vh - 340px)' : 'calc(100vh - 400px)'}
                            view={true}
                            edit={true}
                            delete={true} />
                    </Box>
                </Box>
            }
        </Box>
    )
}