import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, Button, Divider, Drawer, Grid, Stack, Typography, useMediaQuery } from "@mui/material"
import moment from "moment"
import React from "react"
import { CloseIconWithBG } from '../../../assets'
import { CustomSelectOptions, TableWithPagination } from "../../../components"
import { config } from "../../../config"
import { NetworkCall } from "../../../networkcall"
import { NetWorkCallMethods } from "../../../utils"
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions'
import { useStyles } from "../style"
import { OverLapScheduleDataType, OverLapScheduleHeading, OverLapSchedulePath, ScheduleWorkOrdersDataType, ScheduleWorkOrdersHeading, ScheduleWorkOrdersPath } from "../utils"

export const ScheduleWorkOrders = ({ t = () => false, data = {}, state = {}, updateState = () => false }) => {
    const classes = useStyles()
    // const [searchText, setSearchText] = React.useState("")
    // const [page, setPage] = React.useState(1)
    // const [limit, setLimit] = React.useState(10)
    // const [listPage, setListPage] = React.useState(1)
    // const [listLimit, setListLimit] = React.useState(10)
    const matches = useMediaQuery('(min-width:900px)')
    const [showDetails, setShowDetails] = React.useState({ bool: false, data: {} })
    React.useEffect(() => {
        getScheduleWorkOrders()
        //eslint-disable-next-line
    }, [])

    const getScheduleWorkOrders = () => {
        const payload = {
            resources: data?.selected_resources?.map((x) => {
                return {
                    "value": x?.value,
                    "label": x?.label,
                    "reference_id": x?.reference_id,
                    "department": x?.department,
                    "job": x?.job,
                    "location": x?.location,
                    "user_no": x?.user_no
                }
            }),
            config: data?.selected_items?.map((x) => {
                return {
                    "unit_id": data?.PMPType === "Asset" ? x?.unit_id : x?.unit_id,
                    "property_id": x?.property_id ?? null,
                    "unit_mapping_id": x?.mapping_id ?? null,
                    "item_mapping_id": x?.mapping_id ?? null,
                    "item_id": x?.item_id ?? null,
                    "plan_config_type": data?.PMPType,
                    "is_active": true,
                    "name": x?.name,
                    "inspection_item": x?.inspection_item ?? null,
                    "property_name": x?.property ?? x?.property_name,
                    "unit_name": data?.PMPType === "Unit" ? x?.name : x.unit_name,
                    "item_name": data?.PMPType === "Asset" ? x?.name : x.item_name,
                    "manufacturer": x?.manufacturer,
                    "reference_no": x?.reference_no
                }
            }),
            start_date: moment(data?.execution_start_date).format("YYYY-MM-DD"),
            frequency: data?.frequency?.value,
            occurrences: data?.occurence > 1 ? parseInt(data?.occurence) : 0
        }

        NetworkCall(
            `${config.api_url}/preventive_plan/schedule_work_order`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const result = response?.data?.data?.map((x, index) => {
                return {
                    ...x,
                    id: index + 1,
                    resource: { ...x.resource, company_id: state?.company?.value },
                    validation: { over_lapping_count: x.over_lapping_count, is_overlap: x.is_overlap }
                }
            })
            updateState("schedule_work_orders", result)
        }).catch((err) => {
            console.log(err)
        })
    }
    const getOverlapSchedules = (data) => {
        const payload = {
            "execution": moment(data?.execution)?.format("YYYY-MM-DD"),
            "unit_id": data?.unit_id
        }

        NetworkCall(
            `${config.api_url}/preventive_plan/get_over_lapping_orders`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setShowDetails({ bool: true, data: data, list: response?.data?.data })
        }).catch((err) => {
            console.log(err)
        })
    }
    // const handleSearch = (val) => {
    //     setSearchText(val)
    //     if (val?.length > 0) {
    //         console.log(val, "serach value")
    //         const result = data?.selected_resources?.filter((x) => { return x?.name.toLowerCase().includes(val.toLowerCase()) })
    //         updateState("schedule_work_orders", result)
    //     }
    //     else {
    //         updateState("schedule_work_orders", data?.schedule_work_orders)
    //     }
    // }
    // const handleTablePagination = () => {

    // }
    // const handleTablePageLimit = () => {

    // }
    const handleTableIcon = (type, details, value, is_ScheduleDetails = false) => {
        if (type === "overlapped_jobs") {
            getOverlapSchedules(details)
        }
        else if (type === "resource_update") {
            const result = data.schedule_work_orders?.map((x) => {
                return {
                    ...x,
                    resource: x?.id === details?.id ? value : x?.resource
                }
            })
            if (is_ScheduleDetails) {
                setShowDetails({ ...showDetails, data: { ...showDetails.data, resource: value }, scheduleData: result })
            }
            else {
                updateState("schedule_work_orders", result)
            }
        }
        else if (type === "delete") {
            const result = data?.schedule_work_orders?.filter((x) => { return x?.id !== details?.id })
            updateState("schedule_work_orders", result)
        }
    }
    const InspectionItemManualResponse = (array) => {
        const details = array?.data?.map((i) => {
            return {
                label: i?.label,
                value: i?.value,
                ...i
            }
        })
        return details
    }
    const onScheduleResource = (result, data) => {
        updateState("schedule_work_orders", result?.length > 0 ? result : data?.schedule_work_orders)
        setShowDetails({ ...showDetails, bool: false })
    }
    return (
        <Box p={2}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {/* <SearchFilter value={searchText} placeholder={t("Search")}
                        handleChange={(value) => handleSearch(value)} /> */}
                    {data?.schedule_work_orders?.some((x) => x?.is_overlap) &&
                        <Box className={classes.warningRedCard}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <WarningIcon sx={{ color: "#FF4B4B" }} />
                                <Typography className={classes.warningRedText}>{t("For some items, there are existing jobs assigned for this PMP schedule period, Please check.")}</Typography>
                            </Stack>
                        </Box>
                    }
                </Grid>
            </Grid>
            <Box mt={2}>
                <TableWithPagination
                    heading={ScheduleWorkOrdersHeading(t)}
                    rows={data?.schedule_work_orders}
                    path={ScheduleWorkOrdersPath}
                    showpagination={false}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    handleIcon={handleTableIcon}
                    onClick={() => console.log("")}
                    tableType="no-side"
                    dataType={ScheduleWorkOrdersDataType}
                    // handlePagination={handleTablePagination}
                    // handleChangeLimit={handleTablePageLimit}
                    // totalRowsCount={data?.schedule_work_orders?.length}
                    // page={page}
                    // limit={limit}
                    height={data?.schedule_work_orders?.some((x) => x?.is_overlap) ? 'calc(100vh - 405px)' : 'calc(100vh - 365px)'}
                    view={true}
                    edit={true}
                    delete={true} />
            </Box>
            <Drawer
                anchor={"right"}
                open={showDetails?.bool}
                PaperProps={{
                    sx: { width: matches ? "70%" : "90%" },
                }}
                onClose={() => setShowDetails({ bool: false, data: {} })}
            >
                <Box>
                    <Stack direction="row" spacing={2} alignItems="center" p={1.5}>
                        <Box onClick={() => setShowDetails({ bool: false, data: {} })} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
                        <Typography className={classes.drawerHeader}>{t("Job Details")}</Typography>
                    </Stack>
                    <Divider />
                </Box>
                <Box p={2}>
                    <Box mb={2} className={classes.scheduleDetailsCard}>
                        <Typography className={classes.planTitle}>{t("Schedule Details")}</Typography>
                        <Box mt={1} display={"flex"} justifyContent={"space-between"}>
                            <Stack direction="row" spacing={4} alignItems="center">
                                <Box>
                                    <Typography className={classes.scheduledetailstext}>{moment(showDetails?.data?.execution).format("DD MMM YY")}</Typography>
                                    <Typography className={classes.scheduledetailslabel}>{t("Execution Date")}</Typography>
                                </Box>
                                {showDetails?.data?.plan_config_type === "Asset" &&
                                    <>
                                        <Box>
                                            <Typography className={classes.scheduledetailstext}>{showDetails?.data?.item_id}</Typography>
                                            <Typography className={classes.scheduledetailslabel}>{t("Item Id")}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className={classes.scheduledetailstext}>{showDetails?.data?.item_name}</Typography>
                                            <Typography className={classes.scheduledetailslabel}>{t("Item Name")}</Typography>
                                        </Box>
                                    </>
                                }
                                <Box>
                                    <Typography className={classes.scheduledetailstext}>{showDetails?.data?.unit_name},{showDetails?.data?.property_name}</Typography>
                                    <Typography className={classes.scheduledetailslabel}>{t("Unit & Property Name")}</Typography>
                                </Box>
                                <Box width={"200px"}>
                                    <CustomSelectOptions
                                        label={""}
                                        placeholder={""}
                                        onChange={(val) => handleTableIcon("resource_update", showDetails?.data, val, true)}
                                        // options={[]}
                                        loadOptions={(search, array, handleLoading) =>
                                            loadOptionsApis(
                                                `/queries/resources`,
                                                {
                                                    company_id: state?.company?.value
                                                },
                                                search,
                                                array,
                                                handleLoading,
                                                "data",
                                                {},
                                                InspectionItemManualResponse
                                            )}
                                        isPaginate={true}
                                        noSearch={true}
                                        closeIcon={false}
                                        // key={JSON.stringify(data?.category)}
                                        value={showDetails.data.resource}
                                        selectHeight={"35px"}
                                        color={"white"}
                                        noBorder={true}
                                        // isError={data?.error?.sub_category?.length > 0}
                                        // errorMessage={data?.error?.sub_category}
                                        menuPlacement={"bottom"}
                                    // isRequired
                                    // isReadOnly={true}
                                    />
                                </Box>
                            </Stack>
                            <Button variant="contained" onClick={() => onScheduleResource(showDetails?.scheduleData, showDetails)}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <EventAvailableIcon sx={{ color: "white", fontSize: "16px" }} />
                                    <Typography className={classes.scheduletxt}>{t("Schedule it")}</Typography>
                                </Stack>
                            </Button>
                        </Box>
                    </Box>
                    <TableWithPagination
                        heading={OverLapScheduleHeading(t)}
                        rows={showDetails?.list}
                        path={OverLapSchedulePath}
                        showpagination={false}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        onClick={() => console.log("")}
                        tableType="no-side"
                        dataType={OverLapScheduleDataType}
                        // handlePagination={handleTablePagination}
                        // handleChangeLimit={handleTablePageLimit}
                        // totalRowsCount={showDetails?.list?.length}
                        // page={listPage}
                        // limit={listLimit}
                        height={'calc(100vh - 200px)'}
                        view={true}
                        edit={true}
                        delete={true} />
                </Box>
            </Drawer>
        </Box>
    )
}