import { addDays, subDays } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { DevIcon } from "../assets/envIcons/dev_icon";
import { TestIcon } from "../assets/envIcons/test_icon";
import { UatIcon } from "../assets/envIcons/uat_icon";
import { config } from "../config";
import { NetworkCall } from "../networkcall";
import { Routes } from "../router/routes"

const SchoolIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path
      d="M15.974 3a.952.952 0 0 0-.32.066L2.641 8.144a.952.952 0 1 0 .692 1.773L16 4.974l12.668 4.943a.952.952 0 1 0 .692-1.773L16.346 3.066A.952.952 0 0 0 15.974 3Zm-9.5 8.253a1.9 1.9 0 0 0-1.9 1.9v13.335a1.9 1.9 0 0 0 1.9 1.9h7.335a2.519 2.519 0 0 0 4.374 0h7.335a1.9 1.9 0 0 0 1.9-1.9V13.157a1.9 1.9 0 0 0-1.9-1.9h-5.709a2.857 2.857 0 0 0-2.855 2.852V26.17a.952.952 0 1 1-1.9 0V14.109a2.857 2.857 0 0 0-2.857-2.857Z"
      fill="#78b1fe"
      data-name="Group 111084"
    />
  </svg>
)

const HospitalICon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path
      d="M13.772 2a3.5 3.5 0 0 0-3.5 3.5v1.591H6.136a3.5 3.5 0 0 0-3.5 3.5V26.5a3.5 3.5 0 0 0 3.5 3.5h7.636a.955.955 0 0 0 .955-.955v-4.136a.637.637 0 0 1 .636-.636h1.273a.637.637 0 0 1 .636.636v4.136a.955.955 0 0 0 .955.955h7.636a3.5 3.5 0 0 0 3.5-3.5V10.591a3.5 3.5 0 0 0-3.5-3.5h-4.136V5.5a3.5 3.5 0 0 0-3.5-3.5Zm-.318 1.909h5.091a1.272 1.272 0 0 1 1.273 1.273v5.091a1.272 1.272 0 0 1-1.273 1.273h-5.091a1.272 1.272 0 0 1-1.273-1.273V5.182a1.272 1.272 0 0 1 1.273-1.273Zm2.545 1.273a.955.955 0 0 0-.955.955v.636h-.636a.955.955 0 1 0 0 1.909h.636v.636a.955.955 0 0 0 1.909 0v-.636h.636a.955.955 0 1 0 0-1.909h-.636v-.637A.955.955 0 0 0 16 5.182Zm-8.272 9.545H9a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636H7.727a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636h-1.273a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636h-1.273a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636H23a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637ZM7.727 18.545H9a.637.637 0 0 1 .636.636v1.273A.637.637 0 0 1 9 21.09H7.727a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636h-1.273a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636h-1.273a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636H23a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637ZM7.727 22.364H9a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636H7.727a.637.637 0 0 1-.636-.636V23a.637.637 0 0 1 .636-.636Zm15.273 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636H23a.637.637 0 0 1-.636-.636V23a.637.637 0 0 1 .636-.636Z"
      fill="#ff9340"
      data-name="Group 111089"
    />
  </svg>
)


const RestaurantIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path
      d="m27.477 9.704-8.669-6.83a4.05 4.05 0 0 0-5.028 0l-8.669 6.83A5.509 5.509 0 0 0 3 14.056v12.62a3.328 3.328 0 0 0 3.323 3.323h5.539v-7.51a4.069 4.069 0 0 1-2.954-3.9v-6.282a.739.739 0 1 1 1.477 0v4.8a.37.37 0 0 0 .369.369h1.108a.37.37 0 0 0 .369-.369v-4.8a.739.739 0 0 1 1.477 0v4.8a.37.37 0 0 0 .369.369h1.108a.37.37 0 0 0 .369-.369v-4.8a.739.739 0 0 1 1.477 0v6.278a4.069 4.069 0 0 1-2.954 3.9v7.511h5.17V12.307a.738.738 0 0 1 1.2-.576c.131.1 3.231 2.669 3.231 10.029v.696a1.908 1.908 0 0 1-.563 1.357l-1.653 1.653v4.536h4.8a3.328 3.328 0 0 0 3.323-3.323V14.056a5.509 5.509 0 0 0-2.108-4.352Z"
      fill="#ff4b4b"
      data-name="Group 111090"
    />
  </svg>
)


const TransportIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 111088">
      <path
        data-name="icons8-public-transportation (1)"
        d="M8.82 3a5.39 5.39 0 0 0-5.385 5.385v1.843A1.073 1.073 0 0 0 2 11.256v3.59a1.077 1.077 0 0 0 1.436 1.029v12.253a1.435 1.435 0 0 0 1.436 1.436h.718a1.435 1.435 0 0 0 1.436-1.436h5.744v-5.384a4.573 4.573 0 0 1 .834-2.638l.638-2.73a4.76 4.76 0 0 1 .243-.747l-8.895.011V8.385a3.235 3.235 0 0 1 3.23-3.231h13.641a3.235 3.235 0 0 1 3.231 3.231v5.407a4.628 4.628 0 0 1 3.59 2.033v-4.569a1.077 1.077 0 0 0-1.082-1.077 1.065 1.065 0 0 0-.359.066v-1.86A5.39 5.39 0 0 0 22.461 3Zm5.026 3.59a1.077 1.077 0 1 0 0 2.154h3.59a1.077 1.077 0 1 0 0-2.154Zm4.94 8.615a3.241 3.241 0 0 0-3.147 2.5l-.688 2.95-.013.069a3.2 3.2 0 0 0-.733 2.023v6.1a.718.718 0 0 0 .718.718h1.436a.718.718 0 0 0 .718-.718v-.718h10.051v.718a.718.718 0 0 0 .718.718h1.436a.718.718 0 0 0 .718-.719v-6.1a3.2 3.2 0 0 0-.732-2.022l-.015-.07-.689-2.954a3.239 3.239 0 0 0-3.145-2.5Zm0 2.154h6.633a1.065 1.065 0 0 1 1.047.832l.308 1.324h-9.345l.309-1.324a1.066 1.066 0 0 1 1.048-.832Zm-9.607 3.59a1.436 1.436 0 1 1-1.436 1.436 1.435 1.435 0 0 1 1.436-1.436Zm8.257 2.151a1.077 1.077 0 1 1-1.077 1.077 1.077 1.077 0 0 1 1.077-1.077Zm9.333 0a1.077 1.077 0 1 1-1.077 1.077 1.077 1.077 0 0 1 1.077-1.077Z"
        fill="#98a0ac"
      />
    </g>
  </svg>
)

const ParkIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 111092">
      <path
        data-name="icons8-lake (1)"
        d="M15.668 2.706a2.162 2.162 0 0 0-1.95 1.195 1.153 1.153 0 0 0-.049.116l-1.68 4.715a2.161 2.161 0 0 0 1.96 3.058h.651v2.45a1.05 1.05 0 1 0 2.1 0v-2.45h.651a2.161 2.161 0 0 0 1.965-3.062l-1.679-4.711a1.153 1.153 0 0 0-.051-.116 2.162 2.162 0 0 0-1.918-1.195ZM24.75 4.79a3.85 3.85 0 0 0-1.05 7.55v3.3a1.05 1.05 0 1 0 2.1 0v-3.3a3.85 3.85 0 0 0-1.05-7.55Zm-16.878 7.7A5.717 5.717 0 0 0 2 18.212v2.332a8.76 8.76 0 0 0 8.75 8.75H24.4a5.6 5.6 0 1 0 0-11.2h-5.482a8.325 8.325 0 0 1-6.628-3.313 5.717 5.717 0 0 0-4.417-2.285Zm-.3 7.7H10.4a.7.7 0 1 1 0 1.4H7.6a.7.7 0 1 1-.029-1.4Zm12.6 2.1H23a.7.7 0 1 1 0 1.4h-2.8a.7.7 0 0 1-.029-1.4Zm-7.7 2.1H15.3a.7.7 0 0 1 0 1.4h-2.8a.7.7 0 1 1-.029-1.4Z"
        fill="#5ac782"
      />
    </g>
  </svg>
)




/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
  message: {
    some_thing_went_wrong: "Some Thing Went Wrong",
    please_fill_all_mandatory_field: "Please fill all mandatory field"
  }
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "authToken",
  version: "version",
  profileID: "profileID",
  roleID: "roleID",
  activeRole: "activeRole",
  activeRoleModuleId: "activeRoleModuleId",
  userProfileID: "profileID",
  role: "role",
  slug: "slug",
  clinetID: "clinetID",
  role_switch_token: "role_switch_token",
  permission: "permission",
  modules: "modules",
  deviceToken: "device_token",
  selectedCompany: "selectedCompany",
  prev_history_key: "prev_history_key",
  refreshed: "refreshed",
  displayName: "display_name",
  selectedProperty: "selectedProperty",
  planLogo: "plan_logo",
  lastCancelClickTime: "lastCancelClickTime",
  i18nextLng: "i18nextLng",
  is_taxable:"is_taxable"
};
export let RolesData = {
  manager: "Manager",
  securityManager: "Security Manager",
  communityManager: "Community Manager",
  salesManager: "Sales Manager",
  leaseManager: "Lease Manager",
  clientAdmin: "Client Admin",
  propertyLeaseSales: "Property Lease & Sales",
  propertyManagement: "Property Management",
  visitorsAndSecurity: "Visitors And Security",
  communityAndHOA: "Community and HOA",
  inspectionsManagement: "Inspections Management",
  clientManagement: "Client Management",
  facilityManagement: "Facility Management",
  financeAndAccounting: "Finance And Accounting",
  vendorPortal: "Vendor Portal",
  brokerPortal: "Broker Portal",
  residentAndTenantPortal: "Resident and Tenant Portal",
  landlordAndOwnerPortal: "Landlord and Owner Portal",
  tenantsAndResidents: "Tenants and Residents",
  securityGuard: "Security Guard",
  salesAgent: "Sales Agent",
  Inspector: "Inspector",
  fieldService: "Field Service",
  serviceProvider: "Service Provider",
  Township: "Township",
  landlordAndOwner: "Landlord and Owner",
  gateWay: "GateWay"




  // agent: "Agent",
  // tenant: "Tenant",
  // guard:"Security Guard"
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */

export let AssetMasterType = {
  profile: 1,
  image: 2,
  video: 3,
  pdf: 4,
  threeimage: 5,
};
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const findImageSize = (file) =>
  new Promise((resolve, reject) => {
    var _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = (e) => {
      resolve({
        width: e.target.width,
        height: e.target.height,
      });
    };
    img.onerror = (e) => {
      reject(e);
    };
  });

export const checkWebsiteUrl = (url) => {
  // eslint-disable-next-line
  let validURL =
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return validURL.test(url);
};

export const quotationStatus = {
  won: "Won",
  notIntrseted: "Not Interested",
  draft: "draft",
  active: "Active",
};
export let enumName = {
  unitCategory: "unit_category",
  userDefined: "User Defined",
  rentalValue: "Rental Value",
  percentage: "Percentage",
  perUOM: "Per UOM",
  daily: "Daily",
  residential: "Residential",
  commercial: "Commercial",
  storage: "Storage",
  mixed: "Mixed",
  advertisingSpace: "Advertising Space",
  commercialUnit: "Commercial Unit",
  coWorking: "Co-working",
  communityUnit: "Community Unit",
  residentialUnit: "Residential Unit",
  parkingUnit: "Parking Unit",
  storageSpace: "Storage Space",
  revenue_base: "Revenue based lease",
  bedSpace: "Bed Space",
  sale: "Sale",
  land: "Land",
  plot: "Plot",
  singleUnit: "Single Unit",
  multiUnit: "Multi-Unit",
  multiUnitFloor: "Multi-Unit by Floors",
  multiUnitBlockFloor: "Multi-Unit by Blocks",
  storageUnitStorageBin: "Multi-Unit by Storage Bin",
  storageUnitStorageTypeStorageBin: "Multi-Unit by Storage Type",
  pending: "Pending",
  monthly: "Monthly",
  renewal: "Renewal",
  termination: "Termination",
  rejected: "Rejected",
  cancelled: "Cancelled",
  approved: "Approved",
  propertyOwner: "Property Owner",
  milestoneBased: "Milestone Based",
  onCompletion: "On Completion",
  weekly: "Weekly",
  prepaid: "Prepaid",
  hourly: "Hourly",
  yearly: "Yearly",
  halfYearly: "Half Yearly",
  quarterly: "Quarterly",
  manage: "Manage",
  lease: "Lease",
  facilities: "Facilities",
};

export let enumLabel = {
  /**
  Pending Agreement Request Table
  */
  pendingART: "Pending",
  /**
  Pending Agreement Request View
  */
  pendingARV: "Waiting For Approval",
  /**
  Rejected Agreement Request
  */
  rejectedAR: "Declined",
  /**
  Cancelled Agreement Request
  */
  cancelledAR: "Cancelled",
  /**
  Approved Agreement Termination Request
  */
  approvedATR: "Terminated",
  /**
  Approved Agreement Renewal Request
  */
  approvedARR: "Converted",
};

export const calculateDate = (date, value = 0) => {
  let result = new Date(
    moment(date, "DD-MM-YYYY").add(value, "days").toISOString()
  );
  return result;
};

export const subtractDate = (date, value = 0) => {
  let result = new Date(
    moment(date, "DD-MM-YYYY").subtract(value, "days").toISOString()
  );
  return result;
};

export let quotationGen = {
  start: "In the Beginning",
  end: "In the End",
};

export const TabContant = {
  Tabs: [
    {
      month: "max",
      value: "0",
    },
    {
      month: "5Y",
      value: "5y",
    },
    {
      month: "3Y",
      value: "3y",
    },
    {
      month: "1Y",
      value: "1y",
    },
    {
      month: "6M",
      value: "6m",
    },
    {
      month: "3M",
      value: "3m",
    },
    {
      month: "1M",
      value: "1m",
    },
  ],

  TabYearly: [
    {
      label: "2022",
      value: 2022,
    },
    {
      label: "2021",
      value: 2021,
    },
  ],
};
export let sourse = [
  {
    label: "Website Form",
    value: "Website Form",
  },
  {
    label: "Website Chat",
    value: "Website Chat",
  },
  {
    label: "Offline",
    value: "Offline",
  },
  {
    label: "Sales Agent",
    value: "Sales Agent",
  },
];

export const DURATION_PERIOD = [
  { label: "Day", value: "Day" },
  { label: "Month", value: "Month" },
  { label: "Year", value: "Year" },
];

/**
 * Function to get ENUM dynamically
 * @param enumTypes `[String]`
 * @returns `Object`
 */
export const enumSelect = async (enumTypes, search) => {
  let result;

  const payload = { enumName: enumTypes, search: search };

  await NetworkCall(
    `${config.api_url}/enum`,
    NetWorkCallMethods.post,
    payload, null, true, false
  ).then((res) => {
    result = res?.data?.data;
  }).catch((err) => {
    console.log(err)
  })

  return result;
};

//networkcall back
export const networkCallback = async (
  givenPayload,
  url,
  handleLoading,
  returnFullResponse = false
) => {
  const payload = {
    ...givenPayload,
  };
  const options = await NetworkCall(
    `${config.api_url}/${url}`,
    NetWorkCallMethods.post,
    payload,
    true,
    true,
    false
  )
    .then((response) => {
      let main = response?.data?.data;
      handleLoading && handleLoading(null);
      return returnFullResponse ? response : main;
    })
    .catch((err) => {
      handleLoading && handleLoading(null);
      return err;
    });
  return options;
};

export const enum_types = {
  utility_period_type: "utility_period_type",
  amenities_type: "amenities_type",
  notification_category_type: "notification_category_type",
  account_relationship: "account_relationship",
  purpose_global_type: "purpose_global_type",
  revenue_type: "revenue_type",
  apply_grace_period_type: "apply_grace_period_type",
  urgent_type: "urgent_type",
  l_period: "l_period",
  lead_source: "lead_source",
  unit_payment_period: "payment_value_type",
  furnishing_type: "furnishing_type",
  orientation_type: "orientation_type",
  unit_breakup_type: "unit_breakup_type",
  property_payment_period: "property_payment_period",
  general_maintenance_type: "general_maintenance_type",
  preferred_mode_contact_type: "preferred_mode_contact_type",
  delivery_status_type: "delivery_status_type",
  security_request_type: "security_request_type",
  swift_type: "swift_type",
  domestic_value_type: "domestic_value_type",
  parking_type: "parking_type",
  bank_routing_type: "bank_routing_type",
  area_metric_type: "area_metric_type",
  company_lease_period: "company_lease_period",
  pricing_type: "pricing_type",
  company_payment_period: "company_payment_period",
  company_payment_gateway: "company_payment_gateway",
  company_registration: "company_registration",
  rera_type: "rera_type",
  tax_code: "tax_code",
  vat_code_type: "vat_code_type",
  vat_item_code_type: "vat_item_code_type",
  vehicle_type: "vehicle_type",
  unit_category: "unit_category",
  unit_status_type: "unit_status_type",
  agreement_status: "agreement_status",
  unit_vacancy_status: "unit_vacancy_status",
  billing_cycle: "billing_cycle",
  billing_cycle_date: "billing_cycle_date",
  pricing_period_type: "pricing_period_type",
  pet_policy_type: "pet_policy_type",
  relationship_type: "relationship_type",
  emergency_relationship_type: "emergency_relationship_type",
  access_gate_type: "access_gate_type",
  parking_area_type: "parking_area_type",
  payment_terms: "payment_terms",
  bank_account_type: "bank_account_type",
  payment_value_type: "payment_value_type",
  utilties_provider: "utilties_provider",
  property_hierarchy: "property_hierarchy",
  quote_validity_type: "quote_validity_type",
  payment_mode: "payment_mode",
  turnover_type: "turnover_type",
  auto_renewal_escalation: "auto_renewal_escalation",
  follow_up_type: "follow_up_type",
  sex_type: "sex_type",
  amenity_period: "amenity_period",
  cheque_status: "cheque_status",
  notifications_content_type: "notifications_content_type",
  proof_type: "proof_type",
  agreement_request_action: "agreement_request_action",
  value_basis_type: "value_basis_type",
  resource_period_enum: "resource_period_enum",
  vehicle_master_type_enum: "vehicle_type",
  compensated_invoices_type_enum: "compensated_invoices_type_enum",
  reservation_status_type: "reservation_status_type",
  reservation_status_type_new: "reservation_status_type_new",
  listing_type: "listing_type",
  vehicle_master_period_enum: "amenity_period",
  invoice_charge_type: "invoice_charge_type",
  leave_request_type: "leave_request_type",
  half_day_type: "half_day_type",
  free_text_invoice_type: "free_text_invoice_type",
  unit_execution_status: "unit_execution_status",
  work_order_status: "work_order_status",
  inspection_item_type: "inspection_item_type",
  inspection_item_condition: "inspection_item_condition",
  quote_status: "quote_status",
  inspection_request_type_enum: "inspection_request_type_enum",
  resource_leave_type: "resource_leave_type",
  security_status_enum_type: "security_status_enum_type",
  contract_commission_rate_type: "contract_commission_rate_type",
  feedback_element_type: "feedback_element_type",
  block_unit_type: "block_unit_type",
  duration_type: "duration_type",
  maintenance_request_type: "request_type",
  operational_status: "operational_status",
  agreement_inspection_status_type: "agreement_inspection_status_type",
  agreement_inspection_status_type_new: "agreement_inspection_status_type_new",
  org_role_type: "org_role_type",
  location_type: "location_type",
  free_text_invoice_payment_type: "free_text_invoice_payment_type",
  budget_type: "budget_type",
  expected_close_period: "expected_close_period",
  preferred_contact: "preferred_contact",
  request_type: "request_type",
  coa_type:"coa_type",
  frequency:"frequency",
  pmp_criteria:"pmp_criteria",
  payment_classification: "payment_classification"
};

export let billGenENUM = {
  start_payment: "Start of the Payment Period",
  last_day: "Last Day of the Month",
  custom_date: "Custom Date",
  mid_date: "Mid of the Month",
  first: "1st Day of the Month",
};

export const tabelName = {
  parking_slot: "parking_slot",
  security_request: "security_request",
  access_control: "access_control",
  access_gates_master: "access_gates_master",
};

export const enumValueType = {
  manage: "Manage",
  total: "Total",
};
export const title = [
  {
    label: "unit category",
    value: "unit category",
  },
  {
    label: "unit type",
    value: "unit type",
  },
];

export const Gender = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const BackendRoutes = {
  communityDashboardStats: "/community_manager",
  communityDashboardResidents: "/community_manager/resident_info",
  communityDashboardOwners: "/community_manager/owner_info",
  communityDashboardDiscussions: "/community_manager/active_discussion",
  communityDashboardActivities: "/dashboard/community/activities",
  communityDashboardAmenities: "/community_manager/amenities_booking",

};

export const CalendarOptions = {
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This Week",
  lastWeek: "Last Week",
  thisMonth: "This Month",
  lastMonth: "Last Month",
  thisQuarter: "This Quarter",
  lastQuarter: "Last Quarter",
  thisYear: "This Year",
  lastYear: "Last Year",
  custom: "Custom",
  tomorrow: "Tomorrow",
  nextWeek: "Next 1 Week",
  nextMonth: "Next 1 Month",
  nextYear: "Next 1 Year"
};

export const QuarterCalc = {
  thisQuarter: [
    {
      months: [0, 1, 2],
      year: 0,
      dateStart: 1,
      dateEnd: 31,
      monthStart: 0,
      monthEnd: 2,
    },
    {
      months: [3, 4, 5],
      year: 0,
      dateStart: 1,
      dateEnd: 30,
      monthStart: 3,
      monthEnd: 5,
    },
    {
      months: [6, 7, 8],
      year: 0,
      dateStart: 1,
      dateEnd: 30,
      monthStart: 6,
      monthEnd: 8,
    },
    {
      months: [9, 10, 11],
      year: 0,
      dateStart: 1,
      dateEnd: 31,
      monthStart: 9,
      monthEnd: 11,
    },
  ],
  lastQuarter: [
    {
      months: [0, 1, 2],
      year: 1,
      dateStart: 1,
      dateEnd: 31,
      monthStart: 9,
      monthEnd: 11,
    },
    {
      months: [3, 4, 5],
      year: 0,
      dateStart: 1,
      dateEnd: 31,
      monthStart: 0,
      monthEnd: 2,
    },
    {
      months: [6, 7, 8],
      year: 0,
      dateStart: 1,
      dateEnd: 30,
      monthStart: 3,
      monthEnd: 5,
    },
    {
      months: [9, 10, 11],
      year: 0,
      dateStart: 1,
      dateEnd: 30,
      monthStart: 6,
      monthEnd: 8,
    },
  ],
};

export const activeOptions = (t) => [
  {
    label: t("Active"),
    value: true,
  },
  {
    label: t("Inactive"),
    value: false,
  },
];

export const activeOptionsList =  [
  {
    label: "Active",
    value: true,
  },
  {
    label: "Inactive",
    value: false,
  },
];

//fonts function
// export const Regular =
//   "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif";
// export const Bold = "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif";
// export const SemiBold =
//   "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif";
// export const ExtraBold =
//   "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif";

const language = localStorage.getItem("i18nextLng")

export const Regular = language === "ar" ? "IBMPlexSansArabic-Regular" : "IBMPlexSans-Regular";
export const Bold = language === "ar" ? "IBMPlexSansArabic-Bold" : "IBMPlexSans-Bold";
export const SemiBold = language === "ar" ? "IBMPlexSansArabic-SemiBold" : "IBMPlexSans-SemiBold";
export const ExtraBold = language === "ar" ? "IBMPlexSansArabic-ExtraBold" : "IBMPlexSans-ExtraBold";
export const Italic = language === "ar" ? "IBMPlexSansArabic-Italic" : "IBMPlexSans-Italic"

export const EnableDisableOption = [
  { value: true, label: "Enable" },
  { value: false, label: "Disable" },
];

export const RequiredNotRequiredOption = [
  { value: true, label: "Required" },
  { value: false, label: "Not Required" },
];

export const YesNoOption = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const SalePaymentPeriod = [
  { value: enumName.prepaid, label: enumName.prepaid },
  { value: enumName.milestoneBased, label: enumName.milestoneBased },
  { value: enumName.onCompletion, label: enumName.onCompletion },
];

/**
 * Lease/Manage Payment Period
 */
export const LMPaymentPeriod = [
  { value: enumName.prepaid, label: enumName.prepaid },
  // { value: enumName.daily, label: enumName.daily },
  { value: enumName.weekly, label: enumName.weekly },
  { value: enumName.monthly, label: enumName.monthly },
  { value: enumName.quarterly, label: enumName.quarterly },
  { value: enumName.halfYearly, label: enumName.halfYearly },
  { value: enumName.yearly, label: enumName.yearly },
  { value: enumName.milestoneBased, label: enumName.milestoneBased },
  { value: enumName.onCompletion, label: enumName.onCompletion },
];

export const PaymentPeriod = {
  sale: SalePaymentPeriod,
  lease: LMPaymentPeriod,
  manage: LMPaymentPeriod,
};

const getIncreasedValue = (numA, numB) => 100;

const getDecresedValue = (numA, numB) => ((numA - numB) / numA) * 100;

const getRecommandedValue = (numA, numB) => ((numA - numB) / numA) * 100;

const type = {
  MAX: getIncreasedValue, // actual < input
  REC: getRecommandedValue, // actual < input
  MIN: getDecresedValue, // actual > input
};

export let max = type["MAX"];
export let rec = type["REC"];
export let min = type["MIN"];



export const ampmOptions = [
  { label: "AM", value: "AM" },
  { label: "PM", value: "PM" }
]
export const mobileApps =
  [
    {
      src: "images/switcher/resident.svg",
      name: "Resident",
      link: `${config.resident_url}`,
    },
    {
      src: "images/switcher/tenant.svg",
      name: "Tenant",
      link: `${config.tenant_url}`,
    },
    {
      src: "images/switcher/member.svg",
      name: "Member",
      link: `${config.member_url}`,
    },
    {
      src: "images/switcher/workspace.svg",
      name: "Workspace",
      link: `${config.workspace_url}`,
    },
    {
      src: "images/switcher/employee.svg",
      name: "Employee",
      link: `${config.employee_url}`,
    },
    {
      src: "images/switcher/owner.svg",
      name: "Owner",
      link: `${config.owner_url}`,
    },
    {
      src: "images/switcher/guard.svg",
      name: "Guard",
      link: `${config.security_guard_url}`,
    },
    {
      src: "images/switcher/inspector.svg",
      name: "Inspector",
      link: `${config.inspector_url}`,
    },
    {
      src: "images/switcher/worker.svg",
      name: "Worker",
      link: `${config.worker_url}`,
    },
    {
      src: "images/switcher/guest.svg",
      name: "Guest",
      link: `${config.guest_url}`,
    },
    {
      src: "images/switcher/wallet.svg",
      name: "Wallet",
      link: `${config.wallet_url}`,
    },
  ]

export const WebPortals =
  [
    {
      src: "images/switcher/resident.svg",
      name: "Customer Portal",
      link: `${config.customer_portal}`,
    },
    {
      src: "images/switcher/resident.svg",
      name: "Vendor Portal",
      link: `${config.vendor_portal}`,
    },
    {
      src: "images/switcher/agent.svg",
      name: "Agent Portal",
      link: `${config.agent_portal}`,
    },
    {
      src: "images/switcher/resident.svg",
      name: "Resource Portal",
      link: `${config.resource_portal}`,
    },
    {
      src: "images/switcher/realty.svg",
      name: "RealtyGOTO",
      link: `${config.realty_goto}`,
    },
    {
      src: "images/switcher/realty.svg",
      name: "AmenitiesGOTO",
      link: `${config.amenities_goto}`,
    },
    {
      src: "images/switcher/stay.svg",
      name: "StayGOTO",
      link: `${config.stay_goto}`,
    },
  ]

export const unitCategoryMaster = {
  1: {
    "id": 1,
    "ref_name": "Land",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  2: {
    "id": 2,
    "ref_name": "Residential",
    "field_permission": {
      "is_balcony_area": true, "is_terrace_area": true, "is_furnishing": true,
      "is_orientation": true, "is_total_rooms": true, "is_total_baths": true,
      "is_balconies": true, "is_pet_policy": true, "is_parent_unit_id": false,
      "is_carpet_area": true, "is_build_up_area": true, "is_total_area": true,
      "is_listing_status": true
    }
  },
  3: {
    "id": 3,
    "ref_name": "Advertising",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": false
    }
  },
  4: {
    "id": 4,
    "ref_name": "Commercial",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  5: {
    "id": 5,
    "ref_name": "Plot",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  6: {
    "id": 6,
    "ref_name": "Bed",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": true,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": true, "is_parent_unit_id": true,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  7: {
    "id": 7,
    "ref_name": "Storage",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  8: {
    "id": 8,
    "ref_name": "Parking",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  9: {
    "id": 9,
    "ref_name": "Coworking",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": true,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": true,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  10: {
    "id": 10,
    "ref_name": "Room",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": true,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": true, "is_parent_unit_id": true,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  11: {
    "id": 11,
    "ref_name": "Facility",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": false
    }
  },
  12: {
    "id": 12,
    "ref_name": "Warehouse",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
  14: {
    "id": 14,
    "ref_name": "Device",
    "field_permission": {
      "is_balcony_area": false, "is_terrace_area": false, "is_furnishing": false,
      "is_orientation": false, "is_total_rooms": false, "is_total_baths": false,
      "is_balconies": false, "is_pet_policy": false, "is_parent_unit_id": false,
      "is_carpet_area": false, "is_build_up_area": false, "is_total_area": true,
      "is_listing_status": true
    }
  },
}

export const ListingTypeOptions = [
  { label: "None", value: "None", },
  { label: "Private", value: "Private", },
  { label: "Public", value: "Public", },
];

export const addDaysWithLess = (date, count) => {
  let dates = date;
  let addedDays = addDays(dates, count)
  let lessedDays = subDays(addedDays, 1)
  return lessedDays;
}

export const subUserTimeOffset = (date) => {
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset).toISOString()
};

export const normalizeDate = (date) => {
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const app_env_1 = {
  "dev": {
    name: "Development", tag_color: "#FF4B4B"
  },
  "int": {
    name: "Internal", tag_color: "#78B1FE"
  },
  "sandbox": {
    name: "Sandbox", tag_color: "#FF9340"
  },
  "live": {
    name: "Live", tag_color: "#5AC782"
  },
  "test": {
    name: "Test", tag_color: "#78B1FE"
  },
}
export const app_env = (key) => {
  switch (key) {
    case "dev":
      return { name: "Development", tag_color: "#FF4B4B" }
    case "int":
      return { name: "Internal", tag_color: "#78B1FE" }
    case "sandbox":
      return { name: "Sandbox", tag_color: "#FF9340" }
    case "live":
      return { name: "Live", tag_color: "#5AC782" }
    case "test":
      return { name: "Test", tag_color: "#78B1FE" }
    case "uat":
      return { name: "UAT", tag_color: "#6BB93D" }
    default:
      return false
  }
}

export const InAndOutOption = [
  { label: "In", value: "In" },
  { label: "Out", value: "Out" }
]

export const StayTypetOption = [
  { label: "Short", value: "Short" },
  { label: "Long", value: "Long" }
]

export const InOutStatusVW = [
  { label: "Yet To Checkout", value: "Yet To Checkout" },
  { label: "Checked Out", value: "Checked Out" }
]

export const supporDropdown = [
  {
    name: "Write To Support Team",
    type: "email",
    link: `${config.support_team_email}`
  },
  {
    name: "Get Live Support",
    type: "link",
    link: `${config.support_live_support}`
  },
  {
    name: "View Help FAQs",
    type: "link",
    link: `${config.support_view_help_faq}`
  },
  {
    name: "Blogs",
    type: "link",
    link: `${config.support_blogs}`
  },
  {
    name: "Site Terms",
    type: "link",
    link: `${config.support_site_terms}`
  },
  {
    name: "Privacy Policy",
    type: "link",
    link: `${config.support_privacy_policy}`
  },
  {
    name: "About propGOTO",
    type: "link",
    link: `${config.support_about_propgoto}`
  }
]

export const CorrespondenceStatusColor = (id) => {
  switch (id) {
    case 1:
      return "#78B1FE"
    case 2:
      return "#5AC782"
    default:
      return "#78B1FE"
  }
}

export const allowed_file_size = 5

export const login_background_color = {
  "DEV": {
    "light": "#F7B733",
    "dark": "#FC4A1A",
    "tag": "#FA6420",
  },
  "INT": {
    "light": "#F7B733",
    "dark": "#FC4A1A",
    "tag": "#FA6420",
  },
  "UAT": {
    "light": "#A8E063",
    "dark": "#56AB2F",
    "tag": "#6BB93D",
  },
  "TEST": {
    "light": "#8F94FB",
    "dark": "#4E54C8",
    "tag": "#5E64D5",
  },
  "LIVE": {
    "light": "#56CCF2",
    "dark": "#2F80ED",
    "tag": "#FFFFFF",
  },
}

export const login_tag_includes = ["DEV", "INT", "UAT", "TEST"]

export const login_tag = {
  "DEV": {
    icon: (<DevIcon />),
    label: "DEV"
  },
  "INT": {
    icon: (<DevIcon />),
    label: "DEV"
  },
  "UAT": {
    icon: (<UatIcon />),
    label: "UAT"
  },
  "TEST": {
    icon: (<TestIcon />),
    label: "TEST"
  },
}


export const locationdata = [
  {
    id: 0,
    url: <SchoolIcon />,
    name: "Schools",
    propertylabel: "Near this property",
    color: "#F1F7FF",
    type: "school",
  },
  {
    id: 1,
    url: <HospitalICon />,
    name: "Hospitals",
    propertylabel: "Near this property",
    color: "#FFF4EB",
    type: "hospital"
  },
  {
    id: 2,
    url: <RestaurantIcon />,
    name: "Restaurants",
    propertylabel: "Near this property",
    color: "#FFECEC",
    type: "restaurant"
  },
  {
    id: 3,
    url: <ParkIcon />,
    name: "Parks",
    propertylabel: "Near this property",
    color: "#EEF9EE",
    type: "park"
  },
  {
    id: 4,
    url: <TransportIcon />,
    name: "Transportations",
    propertylabel: "Near this property",
    color: "#F5F7FA",
    type: "transportation"
  },
];

export const master_secret_key = {
  kyc_document_type: "nJQFIkvLwv",
  correspondences_type: "XnZpdgUqlv",
  amenity_categories: "OnrTPqVRIe",
  leave_type: "VLYzOhVuQB",
}

export const LanguageOptions = [
  { value: "en-US", label: "English" },
  { value: "es", label: "Español" },
  { value: "ar", label: "العربية" },
  { value: "fr", label: "Français" },
  // { value: "ta", label: "Tamil" },
  // { value: "hi", label: "Hindi" },
  // { value: "zh", label: "Chinese (Simplified)" },
  // { value: "ru", label: "Russian" },
  // { value: "pt", label: "Portuguese" },
  // { value: "it", label: "Italy" },
  // { value: "fil", label: "Filipino" },
  // { value: "id", label: "Indonesian" },
  // { value: "te", label: "Telugu" },
  // { value: "kn", label: "Kannada" },
  // { value: "ml", label: "Malayalam" },
  // { value: "de", label: "German" },
];
export const RTLLanguages = ["ar"]


export const returnQuotationRoutes = (isLead, isOppertunity) => {

  let route;
  if (isLead) {
    if (isOppertunity) {
      route = Routes.createQuotation
    } else {
      route = Routes.createOppertunityQuotation
    }
  } else {
    route = Routes.createQuotation
  }

  return route
}

export function toEnglishNumber(strNum) {
  var ar = '٠١٢٣٤٥٦٧٨٩'.split('');
  var en = '0123456789'.split('');
  strNum = strNum.replace(/[٠١٢٣٤٥٦٧٨٩]/g, x => en[ar.indexOf(x)]);
  strNum = strNum.replace(/[^\d]/g, '');
  return strNum;
}

export const getMonthDifference = (startDate, endDate) => {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
};


export const monthDiff = (d1, d2) => {

  const date1 = new Date(d1);
  const date2 = new Date(d2)

  // var months;
  // months = (d2.getFullYear() - d1.getFullYear()) * 12;
  // months -= d1.getMonth();
  // months += d2.getMonth();
  var timeDiff = Math.abs(date2.getTime() - date1.getTime());
  return Math.round(timeDiff / (2e3 * 3600 * 365.25));
};


export const calculateDaysBetweenDates = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const date1InMillis = date1.getTime();
  const date2InMillis = date2.getTime();
  const days = Math.round((date2InMillis - date1InMillis) / oneDay);
  return days;
};
// Add days to a date
export const addDay = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const agreement_status_object = (t = () => false, type) => {
  return {//{Approved,Cancelled,Pending,Rejected}
    Approved: {
      value: "Approved",
      label: t("Accepted"),
    },
    Cancelled: {
      value: "Cancelled",
      label: t("Cancelled"),
    },
    Pending: {
      value: "Pending",
      label: t("In Progress"),
    },
    Rejected: {
      value: "Rejected",
      label: t("Declined"),
    },
    default: {
      label: "-",
    },
  }
}

export const remCalc = (val) => {
  return `${(val < 12 ? (12 / 16) : (val / 16))}rem`
}

export const rich_text_replacer_condition = [
  { from: "<p>", to: "<div>" },
  { from: "</p>", to: "</div>" },
];

export const colorObject = {
  "low": "#06D6A0",
  "medium": "#FFD166",
  "high": "#EFB93C",
  "urgent": "#EF476F",
  "Plan": "#FFA024",
  "Develop": "#5AC782",
  "Release": "#5078E1",
  "Block": "#EF476F",
}

export function wrapLabelsInT(enumValues, t) {
  return enumValues.map(item => {
      return {
          label: `${t(item.label)}`,
          value: item.value
      };
  });
}