import { Box, Stack, Typography, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import moment from 'moment';
import React from 'react'
import { weeklyCalendarStyle, eventListStyle } from './style'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { AlertDialog, } from '../../../components';
import { ViewActivity } from './viewMore';
import { activityLogo } from '../utils';
export const SwitchColors = (val) => {
    switch (val) {
        case "Phone call":
            return "#30AFCE1F"
        case "Follow up":
            return "#3EA6831F"
        case "Appointment":
            return "#E534681F"
        case "Arrange Site Visit":
            return "#E293361F"
        case "Quotation":
            return "#5078E11F"
        case "Meeting":
            return '#DCDBF2'
        default:
            return "#5078E11F"
    }
}
// export const SwitchActivityIcons = (val) => {
//     switch (val) {
//         case "Phone call":
//             return "/images/Phonecall.svg"
//         case "Follow Up":
//             return "/images/followupAct.svg"
//         case "Appointment":
//             return "/images/appointmentAct.svg"
//         case "Site Visit":
//             return "/images/sitevisit.svg"
//         case "Quotation":
//             return "/images/quotationAct.svg"
//         default:
//             return "images/mail.svg"
//     }
// }

// export const SwitchActivityIcons = (val) => {
//     switch (val) {
//         case "Phone call":
//             return <PhoneCallIcon />
//         case "Follow Up":
//             return <FollowupIcon />
//         case "Appointment":
//             return <EventIcon />
//         case "Site Visit":
//             return <SiteVisitsIcon />
//         case "Quotation":
//             return <QuotationIcon />
//         default:
//             return <EmailSlIcon />
//     }
// }




// "Phone call": ,
//         "Follow up": ,
//         "Event": ,
//         "Send Email": ,
//         "Send Quotation": ,
//         "Arrange Site Visit": <SiteVisitsIcon />,
//         "Appointment": <AppoinmentIcon />,
//         "Meeting": <MeetIcon />
export const WeeklyCalendar = ({ t, weekdates = [], data = [], openDeclinePopup = () => false, loading = false, handleSelected = () => false }) => {
    const classes = weeklyCalendarStyle()
    const [viewMore, setViewMore] = React.useState(false)
    const [selected, setSelected] = React.useState({})
    const hours = [
        { label: "00:00-04:00", start: "00", end: "04" },
        { label: "04:00-08:00", start: "04", end: "08" },
        { label: "08:00-12:00", start: "08", end: "12" },
        { label: "12:00-16:00", start: "12", end: "16" },
        { label: "16:00-20:00", start: "16", end: "20" },
        { label: "20:00-24:00", start: "20", end: "24" },
    ]

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));
    return (
        <Box>
            <TableContainer className={classes.table_container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cells}>
                                <Stack spacing={1} alignItems={"center"}>
                                    <AccessTimeFilledIcon className={classes.listTitle} />
                                </Stack>
                            </TableCell>
                            {weekdates?.map((e) => {
                                return (
                                    <TableCell className={classes.cells}>
                                        <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
                                            <Stack>
                                                <Typography className={classes.dayLabel}>
                                                    {moment(e).format("ddd")}
                                                </Typography>
                                                <Typography className={classes.dateLabel}>
                                                    {moment(e).format("DD")}
                                                </Typography>
                                            </Stack>

                                        </Stack>
                                    </TableCell>
                                );
                            })}
                        </TableRow>


                    </TableHead>

                    {/* <Grid container sx={{
                width: {
                    md: "99.4%",
                    lg: "99.4%",
                    xl: "100%"
                }
            }}>
                <Grid item md={1} xs={1} className={classes.cells}>
                    <Stack spacing={1} alignItems={"center"}>
                        <AccessTimeFilledIcon className={classes.listTitle} />
                    </Stack>
                </Grid>
                {weekdates?.map((e) => {
                    return (
                        <Grid item md={1.57} xs={1.57} className={classes.cells}>
                            <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
                                <Stack>
                                    <Typography className={classes.dayLabel}>
                                        {moment(e).format("ddd")}
                                    </Typography>
                                    <Typography className={classes.dateLabel}>
                                        {moment(e).format("DD")}
                                    </Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                    );
                })}
            </Grid> */}
                    <TableBody>
                        {
                            hours?.map((val) => {
                                return (
                                    <TableRow>
                                        <TableCell className={classes.cells} sx={{ textAlign: "center" }} alignItems={"center"}>
                                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                <Typography className={classes.dayLabel}>{val?.label}</Typography>
                                            </Stack>
                                        </TableCell>
                                        {

                                            weekdates?.map(date => {
                                                return (
                                                    <TableCell className={classes.content_cells}>
                                                        {
                                                            data?.map(wd => {

                                                                return (
                                                                    <div>
                                                                        {
                                                                            // wd?.date === moment(date).format("YYYY-MM-DD") &&
                                                                            // wd?.calendarData?.filter((item) => /// old code
                                                                            // wd?.calendarData?.filter(number => number?.start?.slice(11, 13) >= val?.start && number?.end?.slice(11, 13) <= val?.end /// recent one
                                                                            // console.log("item?.start?.slice(11, 13)==>",item?.start?.slice(11, 13),"val?.start",val?.start)
                                                                            // (item?.start?.slice(11, 13) === val?.start && item?.end?.slice(11, 13) === val?.end)  // old code
                                                                            // (item?.start?.slice(11, 13) >= val?.start && item?.end?.slice(11, 13) <= val?.end)  /// new one
                                                                            wd?.calendarData?.filter((item) => (item.start.slice(11, 13) >= val.start && (item.end.slice(11, 13)==="00"?24<= val.end:item.end.slice(11, 13)<=val.end))
                                                                            )?.map((x) => {
                                                                                let data = [
                                                                                    {
                                                                                        name: "Phone call",
                                                                                        count: x?.activity?.filter((i) => i?.activity_name === "Phone call")?.length
                                                                                    },
                                                                                    {
                                                                                        name: "Follow up",
                                                                                        count: x?.activity?.filter((i) => i?.activity_name === "Follow up")?.length
                                                                                    },
                                                                                    {
                                                                                        name: "Meeting",
                                                                                        count: x?.activity?.filter((i) => i?.activity_name === "Meeting")?.length
                                                                                    },
                                                                                    {
                                                                                        name: "Appointment",
                                                                                        count: x?.activity?.filter((i) => i?.activity_name === "Appointment")?.length
                                                                                    },
                                                                                    {
                                                                                        name: "Arrange Site Visit",
                                                                                        count: x?.activity?.filter((i) => i?.activity_name === "Arrange Site Visit")?.length
                                                                                    },
                                                                                    {
                                                                                        name: "Send Quotation",
                                                                                        count: x?.activity?.filter((i) => i?.activity_name === "Send Quotation")?.length
                                                                                    },
                                                                                    {
                                                                                        name: "Send Email",
                                                                                        count: x?.activity?.filter((i) => i?.activity_name === "Send Email")?.length
                                                                                    }
                                                                                ]
                                                                                return (
                                                                                    <Box>
                                                                                        {moment(x?.start?.slice(0,10)).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY") &&
                                                                                            <Stack spacing={1}>
                                                                                                {
                                                                                                    data?.map((e) => {
                                                                                                        return (
                                                                                                            e?.count !== 0 &&
                                                                                                            <HtmlTooltip
                                                                                                                title={
                                                                                                                    // x?.parking_master_parking_type === "Reserved" ?
                                                                                                                    <React.Fragment>
                                                                                                                        <CustomToolTip t={t} data={e} openDeclinePopup={openDeclinePopup} setViewMore={setViewMore} setSelected={setSelected} handleSelected={handleSelected} date={date} />
                                                                                                                    </React.Fragment >
                                                                                                                    // : ""
                                                                                                                }
                                                                                                                arrow className={classes.customTooltip}
                                                                                                            >
                                                                                                                <Stack direction={"row"} spacing={1} sx={{ backgroundColor: SwitchColors(e?.name) }} p={0.4}
                                                                                                                    alignItems={"center"}
                                                                                                                    onClick={() => handleSelected(date)}>
                                                                                                                    <Box sx={{ marginTop: 0.5, marginLeft: 0.2 }}>
                                                                                                                        {activityLogo?.[e?.name]}
                                                                                                                    </Box>
                                                                                                                    <Typography className={classes.event_label}>{`${e?.name} ${e?.count > 1 ? "+" : ""}${e?.count === 1 ? "" : e?.count - 1}`}</Typography>
                                                                                                                </Stack>
                                                                                                            </HtmlTooltip >

                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </Stack >
                                                                                        }
                                                                                    </Box >
                                                                                )
                                                                            })
                                                                        }
                                                                    </div >
                                                                )
                                                            })
                                                        }
                                                    </TableCell >
                                                )
                                            })
                                        }
                                    </TableRow >
                                )
                            })
                        }
                    </TableBody >
                    {/* <Grid container className={classes.calenderRows}>
                <Grid item md={12} lg={12}>
                    <Grid container>
                        {
                            loading ?
                                <Grid item xs={12}>
                                    <LoadingSection />
                                </Grid>
                                :
                                hours?.map((val) => {
                                    return (
                                        <>
                                            <Grid item md={1} xs={1} className={classes.cells}>
                                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                    <Typography className={classes.dayLabel}>{val?.label}</Typography>
                                                </Stack>
                                            </Grid>

                                            {

                                                weekdates?.map(date => {
                                                    return (
                                                        <Grid item md={1.57} className={classes.content_cells}>
                                                            {
                                                                data?.map(wd => {
                                                                    return (
                                                                        <div>
                                                                            {
                                                                                // wd?.date === moment(date).format("YYYY-MM-DD") &&
                                                                                // wd?.calendarData?.filter((item) => /// old code
                                                                                // wd?.calendarData?.filter(number => number?.start?.slice(11, 13) >= val?.start && number?.end?.slice(11, 13) <= val?.end /// recent one
                                                                                // console.log("item?.start?.slice(11, 13)==>",item?.start?.slice(11, 13),"val?.start",val?.start)
                                                                                // (item?.start?.slice(11, 13) === val?.start && item?.end?.slice(11, 13) === val?.end)  // old code
                                                                                // (item?.start?.slice(11, 13) >= val?.start && item?.end?.slice(11, 13) <= val?.end)  /// new one

                                                                                wd?.calendarData?.filter((item) => (item.start.slice(11, 13) >= val.start && item.end.slice(11, 13) <= val.end)
                                                                                )?.map((x) => {
                                                                                    let data = [
                                                                                        {
                                                                                            name: "Phone call",
                                                                                            count: x?.activity?.filter((i) => i?.activity_name === "Phone call")?.length
                                                                                        },
                                                                                        {
                                                                                            name: "Follow up",
                                                                                            count: x?.activity?.filter((i) => i?.activity_name === "Follow up")?.length
                                                                                        },
                                                                                        {
                                                                                            name: "Meeting",
                                                                                            count: x?.activity?.filter((i) => i?.activity_name === "Meeting")?.length
                                                                                        },
                                                                                        {
                                                                                            name: "Appointment",
                                                                                            count: x?.activity?.filter((i) => i?.activity_name === "Appointment")?.length
                                                                                        },
                                                                                        {
                                                                                            name: "Arrange Site Visit",
                                                                                            count: x?.activity?.filter((i) => i?.activity_name === "Arrange Site Visit")?.length
                                                                                        },
                                                                                        {
                                                                                            name: "Send Quotation",
                                                                                            count: x?.activity?.filter((i) => i?.activity_name === "Send Quotation")?.length
                                                                                        },
                                                                                        {
                                                                                            name: "Send Email",
                                                                                            count: x?.activity?.filter((i) => i?.activity_name === "Send Email")?.length
                                                                                        }
                                                                                    ]
                                                                                    return (
                                                                                        <Box>
                                                                                            {moment(x?.start).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY") &&
                                                                                                <Stack spacing={1}>
                                                                                                    {
                                                                                                        data?.map((e) => {
                                                                                                            return (
                                                                                                                e?.count !== 0 &&
                                                                                                                <HtmlTooltip
                                                                                                                    title={
                                                                                                                        // x?.parking_master_parking_type === "Reserved" ?
                                                                                                                        <React.Fragment>
                                                                                                                            <CustomToolTip data={e} openDeclinePopup={openDeclinePopup} setViewMore={setViewMore} setSelected={setSelected} handleSelected={handleSelected} date={date} />
                                                                                                                        </React.Fragment>
                                                                                                                        // : ""
                                                                                                                    }
                                                                                                                    arrow className={classes.customTooltip}
                                                                                                                >
                                                                                                                    <Stack direction={"row"} spacing={1} sx={{ backgroundColor: SwitchColors(e?.name) }} p={0.4}
                                                                                                                        alignItems={"center"}
                                                                                                                        onClick={() => handleSelected(date)}>
                                                                                                                        <Box sx={{ marginTop: 0.5, marginLeft: 0.2 }}>
                                                                                                                            {activityLogo?.[e?.name]}
                                                                                                                        </Box>
                                                                                                                        <Typography className={classes.event_label}>{`${e?.name} ${e?.count > 1 ? "+" : ""}${e?.count === 1 ? "" : e?.count - 1}`}</Typography>
                                                                                                                    </Stack>
                                                                                                                </HtmlTooltip>

                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </Stack>}
                                                                                            
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    )
                                                })
                                            }


                                        </>
                                    );
                                })
                        }
                    </Grid>
                </Grid>
            </Grid> */}
                </Table >
            </TableContainer >
            <AlertDialog
                md
                open={viewMore}
                onClose={() => setViewMore(false)}
                header={t("Activity")}
                component={<ViewActivity data={selected} handleClose={() => setViewMore(false)} />}

            />
        </Box >
    )
}

const CustomToolTip = ({ data = {}, handleSelected = () => false, date = "", t }) => {
    const classes = eventListStyle()
    return (
        <Stack p={1}>
            <Stack direction={"column"} spacing={1} alignItems="center">
                <Stack direction="row" spacing={1}>
                    {/* <img src={SwitchActivityIcons(data?.activity_name)} width="24px" height="24px" alt="icon" /> */}
                    <Box>
                        {activityLogo?.[data?.name]}
                    </Box>
                    <Typography className={classes.ttTitle}> {data?.name} </Typography>
                </Stack>
                {/* <Stack pl={3.5}>
                    <Typography className={classes.ttsecTitle}> {moment.utc(data?.date_time).format("DD-MM-YYYY hh:mm A")} </Typography>
                </Stack>
                <Stack pl={3.5}>
                    <Typography className={classes.ttassigned_by}>Created By : {data?.first_name}, {`${data?.mobile_no_country_code ?? ""} ${data?.mobile_no ?? ""}`} </Typography>
                </Stack> */}
                <Stack pl={3.5}>
                    <Typography className={classes.moreLabel} onClick={() => {
                        handleSelected(date, data?.name)
                    }}> {t("View All")}</Typography>
                </Stack>
            </Stack >

        </Stack >
    )
}