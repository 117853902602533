import { Box, Button, Grid, Popover, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import ShowMoreText from "react-show-more-text";
import { getTimeLineListHeading } from "../../../utils/agreementUtils";
import { useStyles } from "../styles";
import { Person } from "./personDetails";
import { timeZoneConverter } from "../../../utils"
import {  AgreementSend } from "../../../assets";

const DetailCard = ({ data, t, backgroundColor, backgroundColorCard, type, details, isDraft, isFinace, approvedBy,onBtnClick=()=>false }) => {

  const classes = useStyles({ backgroundColor, backgroundColorCard });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const renderComponent = (val) => {
    switch (val) {
      case "Approved":
        return <Typography className={classes.tag}>{data?.status}</Typography>;
      case "Declined":
        return <Typography className={classes.tag1}>{data?.status}</Typography>;
      case "Expired":
        return <Typography className={classes.tag2}>{data?.status}</Typography>;
      default:
        return <Typography className={classes.tag}>{data?.status}</Typography>;
    }
  };

  return (
    <Box paddingTop="25px" marginInlineStart="12px">
      {data?.type?.length > 0 && (
        <>
          {renderComponent(data?.type)} <Box height="4px" />
        </>
      )}

      {
        isDraft ?
          <Box className={classes.timeLineMain}>
            <Typography className={classes.title}>
              {getTimeLineListHeading(data?.status, t, data?.type)}
            </Typography>
            <Typography className={classes.subTitle}>
              {details?.agreement_no} , {""}
              {t("Created On")}&nbsp;
              {timeZoneConverter(data?.created_at, "DD MMM YY hh:mm A")}
            </Typography></Box>
          :
          <>
            {
              type ?
                <Box className={classes.timeLineMain}>
                  <Typography className={classes.title}>
                    {getTimeLineListHeading(data?.status, t, data?.type)}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {type === "Approved" ? `${approvedBy} by` : t("Rejected By")} {data?.user_profiles?.first_name},&nbsp;
                    {type === "Approved" && `${approvedBy} on ${timeZoneConverter(data?.created_at, "DD MMM YY hh:mm A")}`}
                  </Typography>
                  {(data?.assignee !== null && !data?.type === "  ") && (
                    <Typography className={classes.assigned}>
                      {t("Assigned To")} :{" "}
                      <span onMouseEnter={handleClick} className={classes.link}>
                        {data?.assignee?.first_name}&nbsp;
                        {data?.assignee?.last_name ?? null}
                      </span>
                    </Typography>
                  )}
                  {data?.remarks && (
                    <Grid container className={classes.remarkRoot}>
                      <Grid item xs={1.5}>
                        <Typography noWrap className={classes.remarkBold}>
                          <b>{t("remarks")}&nbsp;</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={10.5}>
                        <ShowMoreText
                          lines={2}
                          more="Show More"
                          less="Show Less"
                          className={classes.remark}
                          anchorClass={classes.seeMoreLessTextStyle}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <Typography className={classes.remark}>
                            {data?.remarks}
                          </Typography>
                        </ShowMoreText>
                      </Grid>
                    </Grid>
                  )}
                </Box>
                :
                <Box className={classes.timeLineMain}>
                  <Typography className={classes.title}>
                    {getTimeLineListHeading(data?.status, t, data?.type,data)}
                  </Typography>
                  {

                    isFinace ?
                      <>
                      {
                        data?.data_type==="agreement_approval_send" &&
                        <Typography className={classes.subTitle} sx={{margin:"4px 0px"}}>Sent : {details?.contact?.first_name} , {details?.contact?.email_id}</Typography>
                      
                      }
                        <Typography className={classes.subTitle}>
                          {t("Created On")}&nbsp;
                          {timeZoneConverter(data?.created_at, "DD MMM YY hh:mm A")}
                        </Typography>
                        {(data?.remarks || data?.approval?.remarks) && (
                    <Grid container className={classes.remarkRoot}>
                      <Grid item xs={1.5}>
                        <Typography noWrap className={classes.remarkBold}>
                          <b>{t("remarks")}&nbsp;</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={10.5}>
                        <ShowMoreText
                          lines={2}
                          more="Show More"
                          less="Show Less"
                          className={classes.remark}
                          anchorClass={classes.seeMoreLessTextStyle}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <Typography className={classes.remark}>
                            {data?.remarks || data?.approval?.remarks}
                          </Typography>
                        </ShowMoreText>
                      </Grid>
                    </Grid>
                  )}
                        {
                        (data?.data_type==="agreement_approval_send" && !data?.approval?.status) &&
                        <Box display="flex" alignItems="center" className={classes.sendRoot}>
                          <Box marginTop={"2px"}>
                            <AgreementSend/>
                          </Box>
                          <Box marginInlineStart={2} flexGrow={1}>
                            <Typography className={classes.subTitle}>{details?.agreement_no}</Typography>
                          </Box>
                          <Box>
                            <Button className={classes.sendBtn} onClick={()=>onBtnClick("view",data?.approval?.template_id)}>View</Button>
                          </Box>
                          <Box> <Button className={classes.sendBtn} onClick={()=>onBtnClick("resend")}>Resend</Button></Box>
                        </Box>
                         }
                        </>
                      :
                      <>
                        <Typography className={classes.subTitle}>
                          {t("Assigned To")} {data?.user_profiles?.first_name},{""} on {""}
                          {timeZoneConverter(data?.created_at, "DD MMM YY hh:mm A")}
                        </Typography>
                        {
                          data?.assignee !== null &&
                          <Typography className={classes.assigned}>
                            {t("Assigned To")} :{" "}
                            <span onMouseEnter={handleClick} className={classes.link}>
                              {data?.assignee?.first_name}&nbsp;
                              {data?.assignee?.last_name ?? null}
                            </span>
                          </Typography>
                        }
                        {data?.remarks && (
                          <Grid container className={classes.remarkRoot}>
                            <Grid item xs={1.5}>
                              <Typography noWrap className={classes.remarkBold}>
                                <b>{t("remarks")}&nbsp;</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={10.5}>
                              <ShowMoreText
                                lines={2}
                                more="Show More"
                                less="Show Less"
                                className={classes.remark}
                                anchorClass={classes.seeMoreLessTextStyle}
                                expanded={false}
                                truncatedEndingComponent={"... "}
                              >
                                <Typography className={classes.remark}>
                                  {data?.remarks}
                                </Typography>
                              </ShowMoreText>
                            </Grid>
                          </Grid>
                        )}</>
                  }



                </Box>
            }</>
      }


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={classes.popover}
      >
        <Person data={data?.assignee} />
      </Popover>
    </Box>
  );
};
export default withNamespaces("agreement")(DetailCard);
