import { Box, Button, Container, Drawer, Grid, Hidden, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useStyles } from "./style";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../router/routes";
import jwt_decode from "jwt-decode";
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { TermsAndCondition } from "../../components/termsAndCondition/termsAndCondition";
import { EnvTag } from "../../components/envTag";
import { CloseButtonSquare } from "../../assets/close_button_square";

export const WelcomePage = ({
      t = () => false,
      size = {},
      term_and_condition_state = {},
      screen_state = {},
      set_screen_state = () => false,
      initital_screen_state = {},
}) => {
      const version = localStorage.getItem(LocalStorageKeys.version);
      const classes = useStyles({ size });
      const history = useHistory();
      const search = useLocation().search;
      const token = new URLSearchParams(search).get("token");
      const [decoded, setDecoded] = useState({
            data: null,
            token: "",
      });
      const alert = useContext(AlertContext);
      const [is_drawer_open, set_is_drawer_open] = useState(false);
      const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);

      useEffect(() => {
            if (token !== null && token !== "") {
                  const tokenData = jwt_decode(token);
                  setDecoded({
                        data: tokenData,
                        token: token,
                  });
            }
            // eslint-disable-next-line
      }, []);

      useEffect(() => {
            const img = new Image();

            img.src = screen_state?.mob_welcome_background;

            img.onload = () => {
                  setIsBackgroundImageLoading(true);
            };

            img.onerror = () => {
                  set_screen_state({ ...screen_state, mob_welcome_background: initital_screen_state?.mob_welcome_background });
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [screen_state?.mob_welcome_background]);

      const setUpNewAccount = () => {
            NetworkCall(
                  `${config.authapi}/auth/verifyUser?token=${decoded?.token}`,
                  NetWorkCallMethods.post, {}, null, false, false
            ).then((response) => {
                  if (response.status === 200) {
                        if (response?.data?.is_allowed) {
                              history.push(
                                    Routes.setpassword + "?welcomeAuthToken=" + token
                              );
                        } else {
                              history.push(Routes.login);
                        }
                  }
            }).catch((err) => {
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Some thing went wrong"),
                  });
            });
      };

      const logIn = () => {
            history.push({
                  pathname: Routes.login,
                  state: {
                        origin: Routes?.welcome
                  },
            });
      }

      const handleTermsAndConditions = (is_open = false) => {
            if (term_and_condition_state && is_open) {
                  set_is_drawer_open(true);
            } else {
                  set_is_drawer_open(false);
            }
      }

      return (
            <>
                  {/* Mobile UI */}
                  <Hidden smUp>
                        <Container className={classes.mob_right_section_card} maxWidth="sm"
                              sx={{
                                    backgroundImage: isBackgroundImageLoading
                                          ? `url(${screen_state?.mob_welcome_background})`
                                          : "none",
                                    backgroundColor: '#757575',
                              }}>
                              <Grid container className={classes.mob_content}>
                                    <Grid item xs={12} alignSelf={"self-end"} className={classes.mob_items}>
                                          <Stack direction={"column"} alignItems={"start"}>
                                                <img className={classes.mob_logo} src={screen_state?.logo} alt={t("logo")} />
                                                <Box height={"20px"} />
                                                <Typography className={classes.mob_description}>
                                                      {t("Easy, secure & most reliable solution for property & facility management")}
                                                </Typography>
                                                <Box height={"40px"} />
                                                <Button className={classes.mob_set_up_new_button} onClick={setUpNewAccount}>
                                                      <Typography className={classes.mob_set_up_new_button_text}>
                                                            {t("Set Up New Account")}
                                                      </Typography>
                                                </Button>
                                                <Box height={"16px"} />
                                                <Button className={classes.mob_log_in_button} onClick={logIn}>
                                                      <Typography className={classes.mob_log_in_button_text}>
                                                            {t("Log In")}
                                                      </Typography>
                                                </Button>
                                                <Box height={"20px"} />
                                                <Typography className={classes.mob_terms_of_use_description} textAlign={"center"}>
                                                      {t(`By tapping "Set Up New Account", you agree to our `)}
                                                      <Typography className={classes.mob_terms_of_use_word}
                                                            onClick={() => handleTermsAndConditions(true)}>
                                                            {t("Terms & Conditions")}
                                                      </Typography>
                                                </Typography>
                                          </Stack>
                                    </Grid>
                              </Grid>
                        </Container>
                  </Hidden>

                  {/* Web & Tablet UI */}
                  <Hidden smDown>
                        <Container className={classes.web_right_section_card} maxWidth="sm">
                              <Grid container className={classes.web_content}>
                                    <EnvTag top={16} right={16} />
                                    <Grid item xs={12}>
                                          <Stack direction={"column"} alignItems={"start"} display={"grid"} padding={"0px 16px"}>
                                                <img className={classes.web_logo} src={screen_state?.logo} alt={t("logo")} />
                                                <Box height={"16px"} />
                                          </Stack>
                                    </Grid>
                                    <Grid item xs={12} alignSelf={"self-end"}>
                                          <img className={classes.welcome_backdrop} src={screen_state?.welcome_backdrop} alt={""} />
                                          <Stack direction={"column"} alignItems={"start"} display={"grid"} padding={"0px 16px"}>
                                                <Button className={classes.web_set_up_new_button} onClick={setUpNewAccount}>
                                                      <Typography className={classes.web_set_up_new_button_text}>
                                                            {t("Set Up New Account")}
                                                      </Typography>
                                                </Button>
                                                <Box height={"16px"} />
                                                <Button className={classes.web_log_in_button} onClick={logIn}>
                                                      <Typography className={classes.web_log_in_button_text}>
                                                            {t("Log In")}
                                                      </Typography>
                                                </Button>
                                                <Box height={"16px"} />
                                                <Typography className={classes.web_terms_of_use_description}>
                                                      {t(`By tapping "Set Up New Account", you agree to our `)}
                                                      <Typography className={classes.web_terms_of_use_word}
                                                            onClick={() => handleTermsAndConditions(true)}>
                                                            {t("Terms of Use")}
                                                      </Typography>
                                                </Typography>
                                                <Box height={"40px"} />
                                                <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                                                      justifyContent={"space-between"} alignItems={"center"}>
                                                      {version &&
                                                            <Typography className={classes.web_version_no}>
                                                                  {`V ${version}`}
                                                            </Typography>
                                                      }
                                                      <img className={classes.web_powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                                                </Stack>
                                          </Stack>
                                    </Grid>
                              </Grid>
                        </Container>
                  </Hidden>

                  {/* Terms & Conditions */}
                  <Drawer
                        anchor={size?.width > 599 ? "right" : "bottom"}
                        className={classes.drawer}
                        open={is_drawer_open}
                        onClose={() => handleTermsAndConditions(false)}>
                        <Grid container>
                              <Grid className={classes.drawer_header_grid}
                                    item xs={12}>
                                    <Box height={"32px"} onClick={() => handleTermsAndConditions(false)}>
                                          <CloseButtonSquare />
                                    </Box>
                                    {size?.width > 599 &&
                                          <Typography className={classes.drawer_header_text}>
                                                {t("Terms Of Use")}
                                          </Typography>
                                    }
                              </Grid>
                              <Grid item xs={12}>
                                    <Box className={classes.drawer_content_box}>
                                          <TermsAndCondition termsCondition={term_and_condition_state} t={t} />
                                    </Box>
                                    <Box className={classes.drawer_button_box}>
                                          <Button
                                                fullWidth
                                                variant="contained"
                                                className={classes.agree_and_start_button}
                                                onClick={setUpNewAccount}>
                                                {t("Agree & Start")}
                                          </Button>
                                    </Box>
                              </Grid>
                        </Grid>
                  </Drawer>
            </>
      );
}  
