import gql from "graphql-tag";

export let KYC_DETAIL_VIEW_LIST = (contact_id, client_id, offset, limit, searchText, is_mandatory, proof_type, to) => `
query KYC_DETAIL_VIEW_LIST {
    count: know_your_client(
      where: {
        contact_id: { eq: "${contact_id}" }
        client: { eq: "${client_id}" }
        valid_to: { gteq: "${to}" }
        or:{
            proof_no: { iregex: "${searchText}" },
            number: { iregex: "${searchText}" }
          }
        ${proof_type !== "" ? `proof_id:{eq:"${proof_type}"}` : ''}
      }
    ) {
      count: count_id
    }
    know_your_client(
      where: {
        contact_id: { eq: "${contact_id}" }
        client: { eq: "${client_id}" }
        valid_to: { gteq: "${to}" }
        or:{
            proof_no: { iregex: "${searchText}" },
            number: { iregex: "${searchText}" }
          }
        ${proof_type !== "" ? `proof_id:{eq:"${proof_type}"}` : ''}
      }
      offset: ${offset}
      limit: ${limit}
    ) {
      id
      identification_master {
        id
        type
      }
      is_mandatory
      name
      url
      proof_no
      status
      number
      valid_from
      valid_to
      validity
      verified
      source
      country_master {
        id
        country_name
      }
      remarks
      contact {
        id
        first_name
        contact_no
        account_no
      }
    }
  }
`;


export const IDENTIFICATION_MASTER = (offset, limit) => gql`
query Identification_master{
  count:Identification_master{
    count:count_id
  }
  Identification_master(
    offset: ${offset}
    limit: ${limit}
  ){
    value:id
    label:type
  }
}
`