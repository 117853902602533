import { useApolloClient } from "@apollo/client";
// import DoneIcon from "@mui/icons-material/Done";
import { Box } from "@mui/material";
import React from "react";
// import { Account as AccountIMG } from "../../assets";
import { SearchFilter } from "../../components";
import { GETCONTACT } from "../../graphql/quotationQueries";
import { AccountList } from "../../screens/quotationDetails/components/accountList";
import { LocalStorageKeys, useWindowDimensions } from "../../utils";
import { useStyles } from "./style";
import InfiniteScroll from "react-infinite-scroll-component";
import { ContactList } from "../index";
export const ContactListComponet = ({
  company_id = "",
  setSelectedAcc = () => false,
  selectedAcc = "",
  contactId = "",
  chooseAccount = () => false,
  t
}) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [contact, setContact] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [offset, setOffset] = React.useState(0);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const size = useWindowDimensions();

  const getContact = (searchText, offset, searchBool) => {
    setSearch(searchText);
    client
      .query({
        query: GETCONTACT,
        fetchPolicy: "network-only",
        variables: {
          client: localStorage.getItem(LocalStorageKeys.clinetID),
          searchText: searchText,
          offset: offset,
          limit: 10,
        },
      })
      .then((response) => {
        if (searchBool) {
          setContact(response?.data?.contact);
        } else {
          setContact(contact.concat(response?.data?.contact));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const choose = (val) => {
    chooseAccount(val);
    setAnchorEl(false);
  };

  React.useEffect(() => {
    //initial load
    getContact(search, 0, false);
    // eslint-disable-next-line
  }, []);
  const fetchMoreData = () => {
    setOffset(offset + 10, false);
    getContact(search, offset + 10, false);
  };

  return (
    <Box>
      <Box onClick={handleClick} aria-describedby={id} className={classes.parent}>
        <SearchFilter
          value={search}
          handleChange={(e) => {
            getContact(e, 0, true);
          }}
          placeholder={t("Search Existing Contact")}
        />
      </Box>
      {anchorEl && (
        <div className={classes.accountListRoot1}>
          <InfiniteScroll
            dataLength={contact.length}
            next={fetchMoreData}
            hasMore={true}
            height={size?.height - 400}
          >
            <Box className={classes.accountListRoot}>
              {contact.map((val) => {
                return (
                  <>
                    <ContactList
                      data={{
                        name: `${val?.first_name} ${val?.last_name ?? ""}`,
                        img: val?.image_url,
                        phone: val?.mobile_no,
                        mail: val?.email,
                        id: val?.id,
                      }}
                      onClick={() => choose(val)}
                      leadtype={true}
                    />
                    <Box height="8px" />
                  </>
                );
              })}
            </Box>
          </InfiniteScroll>
        </div>
      )}

      <Box height="12px" />

      {selectedAcc?.id && (
        <AccountList
          iconColor="#5AC782"
          color="#EEF9EE"
          disable
          value={selectedAcc}
          t={t}
        />
      )}
    </Box>
  );
};
