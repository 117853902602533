import { useApolloClient } from "@apollo/client"
import { Box } from "@mui/material"
import { addDays, endOfDay, startOfDay, subDays } from 'date-fns'
import React from 'react'
import { AlertDialog, Subheader, UseDebounce } from "../../components"
import { NewLoader } from "../../components/newLoader"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { GET_TYPE_MASTER } from "../../graphql/resourceMaster"
import { NetworkCall } from "../../networkcall"
import { accessCheckRender, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods, returnCalendarDate } from '../../utils'
import { Calendar, CalHeader } from "./component"
import { DailyCalendar } from './dailyCalendar'
import { useStyles } from "./styles"
import { withNamespaces } from "react-i18next"
const ResourceBoard = (props) => {
    const { loading, handleLoading, t } = props;
    const classes = useStyles();
    const client = useApolloClient();
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext);
    const debounce = UseDebounce();
    const [companyList, setCompanyList] = React.useState([]);
    const [resourceList, setResourceList] = React.useState([]);
    const [selectedType, setSeletedType] = React.useState({});
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [list, setList] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const [loader, setLoader] = React.useState(true);
    const [permissions, setPermission] = React.useState({})
    const [weekdates, setWeekdates] = React.useState({
        startDate: new Date(),
        endDate: addDays(new Date(), 6)
    })
    const [view, setView] = React.useState({
        bool: false,
        data: {},
        datails: {}
    })
    const [offset, setOffset] = React.useState(0)
    const [filterData, setFilterData] = React.useState({
        department: [],
        job: [],
        profession: []
    })
    const getList = (type, start_date, end_date, company, search, offset = 0, reload = false, loading = true,filterDataValues) => {
        if (loading) {
            setLoader(true)
        }
        const payload = {
            start_date: startOfDay(start_date),
            end_date: endOfDay(end_date),
            company_id: company,
            type: type?.value,
            job:filterDataValues?.job?.map((x)=>{return x?.value})??[],
            profession:filterDataValues?.profession?.map((x)=>{return x?.value})??[],
            department:filterDataValues?.department?.map((x)=>{return x?.value})??[],
            search,
            offset: offset,
            limit: 10
        }
        NetworkCall(
            `${config.api_url}/resources/get_resource_board`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const data = returnCalendarDate(type?.label, res.data.result)
            if (reload) {
                setList(list.concat(data))
            } else {
                setList(data)
            }

            setLoader(false)
            handleLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }
    const getResourceMasterList = (company) => {
        client
            .query({
                query: GET_TYPE_MASTER,
                fetchPolicy: "network-only",
            }).then((res) => {
                setResourceList(res.data.resource_type_master?.filter((val) => { return val?.label === "Users" }))

                getList(res.data.resource_type_master?.filter(i => i?.resource_code === "RTM-5")?.[0], weekdates?.startDate, weekdates?.endDate, company, "",false,filterData)
                setSeletedType(res.data.resource_type_master?.filter(i => i?.resource_code === "RTM-5")?.[0])
            })
    }
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert);
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            if (company && perm?.read) {
                setPermission(perm)
                getResourceMasterList(company?.selected?.value)
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
            }
        }

        // eslint-disable-next-line
    }, [auth])

    let startDate = weekdates.startDate;
    const endDate = weekdates.endDate;
    var dates = []
    while (startDate <= endDate) {
        dates.push(startDate)
        startDate = addDays(startDate, 1)
    }
    //on next
    const next = () => {
        setWeekdates({
            startDate: addDays(weekdates.endDate, 1), endDate: addDays(weekdates.endDate, 7)
        })
        getList(selectedType, addDays(weekdates.endDate, 1), addDays(weekdates.endDate, 7), selectedCompany?.value, searchText,0,false,true,filterData)
        setOffset(0)
    }
    //on prev
    const prev = () => {
        setWeekdates({
            startDate: subDays(weekdates.startDate, 7), endDate: subDays(weekdates.startDate, 1)
        })
        getList(selectedType, subDays(weekdates.startDate, 7), subDays(weekdates.startDate, 1), selectedCompany?.value, searchText,0,false, true,filterData)
        setOffset(0)
    }
    //on change type
    const onChangeType = (val) => {
        setSeletedType(val)
        getList(val, weekdates?.startDate, weekdates?.endDate, selectedCompany?.value, "", 0,false,true,filterData)
        setOffset(0)
    }
    //on change
    const onChange = (val) => {
        setSearchText(val)
        debounce(() => getList(selectedType, weekdates?.startDate, weekdates?.endDate, selectedCompany?.value, val,0,false,true,filterData), 800)
        setOffset(0)
    }
    //onchange company
    const onChangeCompany = (val) => {
        setSelectedCompany(val)
        getList(selectedType, weekdates?.startDate,weekdates?.endDate,val?.value, "",0,false,true)
        setFilterData({})
        setOffset(0)
    }
    //on click view
    const onView = (data, detail) => {
        setView({
            bool: !view?.bool,
            data: data,
            details: detail
        })
    }

    //on scroll
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getList(selectedType, weekdates?.startDate, weekdates?.endDate, selectedCompany?.value, searchText, offset + 10, true, false,filterData)
    }
    const onApplyFilter = (value) => {
        setFilterData(value)
        getList(selectedType, weekdates?.startDate, weekdates?.endDate, selectedCompany?.value, "",0,false,true,value)
    }
    const render = () => {
        return (
            <Box>
                <Subheader hideBackButton title={t("Resource Board")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        onChangeCompany(e)
                    }}
                />

                <Box className={classes.root}>
                    <CalHeader t={t} setSeletedType={onChangeType} selectedType={selectedType} resource={resourceList} state={weekdates} prev={prev} next={next} onApplyFilter={onApplyFilter} filterData={filterData} companyId={selectedCompany?.value}/>

                    <Box height="12px" />
                    {
                        loader ?
                            <NewLoader minusHeight="158px" />
                            :

                            <Calendar t={t} fetchMoreData={fetchMoreData} onView={onView} selectedType={selectedType} value={searchText} onChange={onChange} dates={dates} weekdatas={list} />
                    }
                </Box >



                <AlertDialog
                    open={view?.bool}
                    onClose={onView}
                    md={true}

                    header={`${selectedType?.label} ${t("Schedule")}`}
                    component={<DailyCalendar t={t} selectedCompany={selectedCompany} data={view?.data} details={view?.details} />}

                />

            </Box >
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permissions, "", loading)}
        </Box>
    )
}
export default withNamespaces("resourceBoard")(ResourceBoard)
