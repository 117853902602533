import React from 'react'
import styled from '@mui/material/styles/styled';
import { AlertDialog, FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { Status, managementRoleMasterDatatype, managementRoleMasterHeading, managementRoleMasterPath } from './utils/managementRoleMaster';
import { ManagementRoleMasterStyle } from './style';
import { Box, Button, Divider, Grid, Stack } from '@mui/material';
import FilterIcon from '../../assets/filterIcon';
import { AlertContext, AuthContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { AlertProps, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew } from '../../utils';
import { AddRoleForm } from './addRoleForm';
import { withNamespaces } from 'react-i18next';

const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    height: `calc(100vh - 150px)`,
    overflow: "hidden",
    margin: "12px",
    position: "relative"
}))

const ManagementRoleMaster = (props) => {
    const { loading, t = () => false, handleLoading = () => false } = props;
    const classes = ManagementRoleMasterStyle()
    const alert = React.useContext(AlertContext)
    const debounce = UseDebounce()
    const auth = React.useContext(AuthContext)

    const [data, setData] = React.useState({
        page: 1,
        offset: 0,
        limit: 10,
        rowData: [],
        rowCount: 0,
        role_name: "",
        description: "",
        is_active: true,
        openAddRole: false,
        type: "create",
        error: {
            role_name: "",
            description: "",
            is_active: "",
        }
    })
    const [searchText, setSearchText] = React.useState("")
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const [filterData, setFilterData] = React.useState({
        is_active: [true]
    });
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [permission, setPermission] = React.useState({})

    const updateState = (key, value) => {
        setData({
            ...data,
            [key]: value,
            error: {
                ...data?.error,
                [key]: ""
            }
        })
    }

    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            getTableData()
        }
        // eslint-disable-next-line
    }, [auth])

    const handlePagination = (value) => {
        updateState("page", value)
        let offset = (value - 1) * data?.limit;
        getTableData(offset, data?.limit, "")
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setData({ ...data, limit: value, page: 1 })
        getTableData(0, value, "")

    }
    const handleTableSearch = (val) => {
        setSearchText(val)
        debounce(() => getTableData(0, 10, val), 800)
    }

    const handleFilter = (value) => {
        setFilterData(value)
        getTableData(0, 10, "", value)
    }

    const handleIcon = (type, rowdata) => {
        if (type === "edit") {
            setData({
                ...data,
                type: "edit",
                id: rowdata?.id,
                role_name: rowdata?.name,
                description: rowdata?.desc,
                is_active: rowdata?.is_active,
                openAddRole: true
            })
        }
        else if (type === "active") {
            const payload = {
                role_id: rowdata?.id,
                is_active: !rowdata?.is_active
            }
            NetworkCall(
                `${config.api_url}/my_organisation/in_active_role_master`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((res) => {
                    getTableData()
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: !rowdata?.is_active
                            ? `${rowdata?.name} Is Active Now!!!`
                            : `${rowdata?.name} Is In-Active Now!!!`,
                    });
                })
                .catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: AlertProps.message.some_thing_went_wrong,
                    });
                });
        }
    }

    const getTableData = (offset = 0, limit = 10, search = "", filter_data = filterData) => {
        const payload = {
            offset: offset,
            limit: limit,
            search: search,
            is_all_roles: true,
            is_active: filter_data?.is_active
        }
        NetworkCall(
            `${config.api_url}/my_organisation/get_roles`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const constructedData = res?.data?.data?.map((e) => {
                    return {
                        id: e?.id,
                        name: e?.role_name,
                        desc: e?.description,
                        is_active: e?.is_active,
                    }
                })
                setData({
                    ...data,
                    rowData: constructedData,
                    rowCount: res?.data?.count,
                    role_name: "",
                    description: "",
                    is_active: true,
                    openAddRole: false
                })
                handleLoading(false)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                });
            });
    }

    const onOpenModel = () => {
        setData({
            ...data,
            openAddRole: true
        })
    }

    const onCloseModal = () => {
        setData({
            ...data,
            role_name: "",
            description: "",
            is_active: true,
            openAddRole: false
        })
    }

    const validateForm = () => {
        let isValid = true;
        let error = data.error;
        if (data?.role_name?.length === 0) {
            isValid = false;
            error.role_name = t("Role Name is Required");
        }
        if (data?.description?.length === 0) {
            isValid = false;
            error.description = t("Description is Required");
        }
        if (data?.is_active?.length === 0) {
            isValid = false;
            error.is_active = t("Status is Required");
        }

        setData({ ...data, error });
        return isValid;
    }

    const handleSubmitForm = () => {
        setIsDisableBtn(true)
        if (validateForm()) {
            const payload = {
                role_name: data?.role_name,
                description: data?.description,
                is_active: data?.is_active,
                id: data?.id
            }
            NetworkCall(
                `${config.api_url}/my_organisation/upsert_role_master`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((res) => {
                    setIsDisableBtn(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: data?.type === "create" ? t("Role Added Successfully") : t("Role Updated Successfully"),
                    });
                    getTableData()
                })
                .catch((error) => {
                    setIsDisableBtn(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: AlertProps.message.some_thing_went_wrong,
                    });
                });
        } else {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            });
        }
    }

    const render = () => {
        return (
            <>
                <Subheader title={t("Management Role Master")} hideBackButton />
                <CustomPaper>
                    <Box m={2}>
                        <Grid container>
                            {/* searchbar */}
                            <Grid item xs={4}>
                                <SearchFilter
                                    handleChange={handleTableSearch}
                                    value={searchText}
                                    placeholder={t("Search by Role Name")}
                                />
                            </Grid>
                            {/* filterIcon */}
                            <Grid item xs={8} >
                                <Stack
                                    direction="row"
                                    divider={<Divider className={classes.dividerStyle} orientation="vertical" flexItem />}
                                    spacing={2}
                                    justifyContent={"end"}
                                >

                                    <FilterIcon onClick={() => setFilterDrawer(true)} />
                                    {permission?.create && <div className={classes.addbtn}>
                                        <Button variant="contained"
                                            onClick={() => onOpenModel()}
                                        >{t("Add Role")}</Button>
                                    </div>}
                                </Stack>
                            </Grid>
                        </Grid>
                        <TableWithPagination
                            heading={managementRoleMasterHeading(t)}
                            rows={data?.rowData}
                            path={managementRoleMasterPath}
                            dataType={managementRoleMasterDatatype}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            tableType="no-side"
                            handleIcon={handleIcon}
                            delete={false}
                            edit={true}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={data?.rowCount}
                            page={data?.page}
                            limit={data?.limit}
                            height={'calc(100vh - 300px)'}
                        />
                    </Box>
                </CustomPaper>

                <AlertDialog
                    isNormal
                    header={data?.type === "create" ? t("Add Role") : t("Edit Role")}
                    onClose={() => onCloseModal()}
                    open={data?.openAddRole}
                    component={
                        <AddRoleForm
                            onCloseModal={onCloseModal}
                            data={data}
                            updateState={updateState}
                            isDisableBtn={isDisableBtn}
                            handleSubmitForm={handleSubmitForm}
                            t={t}
                        />
                    }
                />

                <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => handleFilter(value)}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.is_active,
                            options: Status,
                            isMulti: true,
                            state_name: "is_active",
                            label: t("Status"),
                        },
                    ]}

                />
            </>
        )
    }
    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}

export default withNamespaces("managementRoleMaster")(ManagementRoleMaster)