import React from 'react'
import { SearchFilter, UseDebounce } from '../../../components'
import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import { AccountListStyle } from './styles'
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, stringAvatar } from '../../../utils'
import { config } from '../../../config'
import { NetworkCall } from '../../../networkcall'
import InfiniteScroll from 'react-infinite-scroll-component'
import { GET_COMPANY_ACCOUNT } from '../../../graphql/queries'
import { AlertContext } from '../../../contexts'

export const AccountList = ({
    selectedCompany = {},
    handleAdd = () => false,
    selected = {}
}) => {
    const classes = AccountListStyle()
    const debounce = UseDebounce()
    const alert = React.useContext(AlertContext)
    const [offset, setOffset] = React.useState(0)
    const [accountData, setAccountData] = React.useState({
        list: [],
        count: 0,
        selectedAccount: ""
    })
    const [searchText, setSearchText] = React.useState("")

    React.useEffect(() => {
        getAccountList(0, false, "", selected)
        // eslint-disable-next-line
    }, [])

    const fetchMoreData = () => {
        setOffset(offset + 10);
        getAccountList(offset + 10, true);
    };

    const handleSearch = (searchText) => {
        setSearchText(searchText)
        debounce(() => getAccountList(0, false, searchText), 800)

    }

    const getAccountList = (offset = 0, bool = false, search = "", selected = "") => {
        const payload = {
            query: GET_COMPANY_ACCOUNT.loc.source.body,
            variables: {
                client: localStorage.getItem(LocalStorageKeys.clinetID),
                id: selectedCompany?.value,
                searchText: search,
                offset: offset,
                limit: 10
            },

        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                if (bool) {
                    setAccountData({
                        ...accountData,
                        list: accountData?.list?.concat(res?.data?.data?.contact),
                        count: res?.data?.data?.count?.[0]?.count,
                        selectedAccount: accountData?.selectedAccount
                    })
                } else {
                    setAccountData({
                        ...accountData,
                        list: res?.data?.data?.contact,
                        count: res?.data?.data?.count?.[0]?.count,
                        selectedAccount: selected
                    })
                }
            })
            .catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                });
            });
    };
    return (
        <>
            <SearchFilter
                value={searchText}
                handleChange={(val) => handleSearch(val)}
                placeholder='Search By contact name, Phone number, Email Id'
            />
            <Box mt={2}>
                <InfiniteScroll
                    dataLength={accountData?.list?.length}
                    next={fetchMoreData}
                    hasMore={true}
                    height={"400px"}
                >
                    <Stack rowGap={1}>
                        {
                            accountData?.list?.map((data) => {
                                return (
                                    <Stack direction={"row"} columnGap={1}
                                        className={accountData?.selectedAccount?.id === data?.id ? classes.selectedBox : classes.box}
                                        onClick={() => setAccountData({ ...accountData, selectedAccount: data })}>
                                        <Avatar src={data?.logo} {...stringAvatar(data?.name)}></Avatar>
                                        <Stack>
                                            <Typography className={classes.title}>{data?.name}</Typography>
                                            <Typography className={classes.subTitle}>{data?.mobile_no_country_code ?? ""} {data?.mobile_no ?? "-"}, {data?.email_id ?? ""}</Typography>
                                        </Stack>
                                    </Stack>
                                )
                            })
                        }
                    </Stack>
                </InfiniteScroll>
            </Box>

            <Box p={"16px 16px 0px 16px"}>
                <Button fullWidth variant='contained' disableElevation className={classes.addBtn} onClick={() => handleAdd(accountData?.selectedAccount)}>Add</Button>
            </Box>
        </>
    )
}