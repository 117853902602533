/* eslint-disable array-callback-return */
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { SearchFilter, TableWithPagination } from "..";
import MasterUploadModal from ".."
import GenerateForm from  ".."
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps, Generaldrawer, Generalheading, GeneralPath, GeneralRow, GeneralType, LocalStorageKeys, NetWorkCallMethods,
  serviceActions,
  serviceAddActions,
  serviceEditActions, Servicelist
} from "../../utils";
import { UseDebounce } from '../customHooks';

const useStyles = makeStyles((theme, props) => ({
  root: {
    height: "100%",
  },
  grid: {
    borderRadius: "12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    // height: "100%",
    height: `calc(100vh - 93px)`,
    width: "100%",
    padding: 10,
    margin: 5,
    overflow: "auto"
  },
  gridList: {
    borderRadius: "12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    // height: "100%",
    height: `calc(100vh - 93px)`,
    padding: 10,
    margin: 5,
  },
  listText: {
    backgroundColor: "#071741",
    padding: 10,
    borderRadius: 10,
    color: "white",
    opacity: 1,
    cursor: "pointer"
  },
  list: {
    cursor: "pointer"
  },
  close: {
    position: "fixed",
    right: "310px",
    top: 10,
    backgroundColor: "white",
    padding: 5,
    display: "flex",
    borderRadius: 5,
    cursor: "pointer",
  },
}));

export const ServicesMaster = ({
  t
}) => {
  const classes = useStyles();
  const backdrop = React.useContext(BackdropContext);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("General");
  const [readOnly, setRead] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [listVal, setListVal] = useState("")
  const [list, setList] = useState(Servicelist);
  const [listTab, setTab] = useState("");
  const [editId, setEditId] = useState(null);
  const [disable, setDiable] = useState(false);
  const alert = React.useContext(AlertContext);

  const handleDrawer = () => {
    setEditId(null);

    const newDrawer = [...data.drawer]?.map((v) => {
      v.value = "";
      return v
    })

    setData({
      ...data,
      drawer: newDrawer
    })
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [data, setData] = useState({
    heading: Generalheading,
    row: GeneralRow,
    path: GeneralPath,
    type: GeneralType,
    drawer: Generaldrawer
  })

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  //debounce hook
  const debounce = UseDebounce();

  const getTable = (name, offset = 0, limit = 10, search = "") => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const jsonData = serviceActions(name, offset, limit, search);
    const payload = {
      query: jsonData.type,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        const wData = jsonData.resfunc(main)
        setData({
          heading: jsonData.tbdata.heading,
          row: wData,
          path: jsonData.tbdata.path,
          type: jsonData.tbdata.type,
          drawer: jsonData.tbdata.drawer,
          totalRowsCount: response?.data?.data?.count?.[0]?.count
        })
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      });

  }

  useEffect(() => {
    getTable(title);
    // eslint-disable-next-line
  }, [])

  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getTable(title, offset, limit, listTab)
  }

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getTable(title, 0, value, listTab)
  }

  const handleChange = async (type, value, checked) => {

    if (type === "edit") {

      setRead(false);
      setEditId(value?.id);

      let dependencyValue = ['maintenancecategory', 'generalcategory']
      const newDrawer = [...data.drawer]?.map((val) => {
        Object.keys(value).map(k => {
          if (val.state_name === k) {
            if (dependencyValue?.includes(k)) {
              val.value = {
                label: value?.[val.state_name],
                value: value?.valueID
              }
            }
            else {
              val.value = value[val.state_name]
            }
          }
        })
        return val
      })

      setData({
        ...data,
        drawer: newDrawer
      })
      setOpen(true);

    }
    if (type === "view") {
      setOpen(true);
      setRead(true);
      let newDrawer = [...data.drawer]
      newDrawer.map((val) => {
        Object.keys(value).map(k => {
          if (val.state_name === k) {
            return val.value = value[val.state_name]
          }
        })
      })
    }
    if (type === "toggle") {
      const c = {
        is_active: checked,
        updated_at: new Date().toISOString(),
        updated_by: localStorage.getItem(LocalStorageKeys.profileID)
      }
      editTablefuncSM(title, c, value.id)
    }
  };

  const handleList = (name, index) => {
    setPage(1);
    setLimit(10);
    setTab('');
    setTitle(name);
    getTable(name);
  };

  const handleUploadModal = (val, type) => {
    // selected tab master name
    if (type === "refresh") {
      getTable(title);
      setUploadModal(!uploadModal)
    } else {
      setUploadModal(!uploadModal)
    }
  }

  const handleListFilter = (e) => {
    setListVal(e)
    setList(Servicelist.filter(_ => _.name.toLowerCase().includes(e?.toLowerCase())))
  }

  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getTable(title, 0, limit, e)
  }

  const handleTab = (e) => {
    setTab(e)
    debounce(() => searchTableFunction(e), 800)
  }

  const saveFunction = (datas, title) => {
    let res = { ...datas };
    res.is_active = res?.is_active ? res?.is_active : false
    if (title === 'General Sub-Category') {
      res.name = datas?.type;
      res.general_category_id = datas?.generalcategory?.value;
      delete res.type
      delete res.generalcategory
    }

    else if (title === 'Maintenance Sub-Category') {
      res.name = datas?.type;
      res.maintenance_category_id = datas?.maintenancecategory?.value;
      delete res.type
      delete res.maintenancecategory
    }

    if (editId) {
      res.name = datas?.type;
      res.updated_at = new Date().toISOString();
      res.updated_by = localStorage.getItem(LocalStorageKeys.profileID);
      delete res.type
      editTablefuncSM(title, res);
    }
    else {
      res.name = datas?.type;
      res.created_at = new Date().toISOString();
      res.created_by = localStorage.getItem(LocalStorageKeys.profileID);
      res.updated_at = new Date().toISOString();
      res.updated_by = localStorage.getItem(LocalStorageKeys.profileID);
      delete res.type
      addTablefuncSM(title, res)
    }
  }

  const editTablefuncSM = (name, data, id) => {
    setDiable(false)
    const jsonData = serviceEditActions[name];
    const payload = {
      query: jsonData.type,
      variables: {
        'updateById': id ? id : editId,
        update: [data],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDiable(false)
        let offset = (page - 1) * limit;
        getTable(title, offset, limit, listTab)
        setOpen(false);
        setEditId(null)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Edited Successfully!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      })
      .catch((error) => {

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Not Edited!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      });
  }

  const addTablefuncSM = (name, data) => {
    setDiable(true)
    const jsonData = serviceAddActions[name];
    const payload = {
      query: jsonData.type,
      variables: {
        insert: [{ ...data, client: localStorage.getItem(LocalStorageKeys.clinetID) }],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (page > 1) {
          setPage(1);
        }
        getTable(title, 0, limit);
        setTab("")
        setDiable(false)
        setOpen(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Created Successfully!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Not Created!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })

      });
  }

  return (
    <div>
      <Grid constainer spacing={2} direction="row" display="flex">
        <Grid item xs={3} className={classes.gridList}>
          <SearchFilter
            value={listVal}
            handleChange={handleListFilter}
          />
          <List>
            {list.map((l, index) => (
              <ListItem
                className={l?.name === title ? classes.listText : classes.list}
                onClick={() => handleList(l.name, index)}>
                <ListItemText primary={l.name} />
              </ListItem>
            ))}

          </List>
        </Grid>
        <Grid
          item
          xs={9}
          className={classes.grid}
          justifyContent="space-between"
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xs={6}>
              <SearchFilter
                value={listTab}
                handleChange={handleTab}
              />
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Button
                color="primary"
                variant={"outlined"}
                onClick={handleUploadModal}
                style={{ marginRight: 12 }}
              >
                {t("Bulk Upload")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleDrawer}
              >
                {t("Add new")}
              </Button>
            </Grid>
          </div>

          <Grid item xs={12} className={classes.table}>
            {data.row && <TableWithPagination
              heading={data.heading}
              rows={data.row}
              path={data.path}
              count="2"
              handleIcon={handleChange}
              showpagination={true}
              onClick={() => false}
              // tableType = "normal"
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              dataType={data.type}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={data?.totalRowsCount}
              page={page}
              limit={limit}
              height={'calc(100vh - 240px)'}
              view={true}
              edit={true}
              delete={true} />
            }
          </Grid>

        </Grid>
      </Grid>


      {(data?.drawer && open) && <GenerateForm
        parent_id={'PropertyMasterDrawer'}
        nocancel
        loading={disable}
        open={open}
        readOnly={readOnly}
        save={saveFunction}
        handlecancel={handleClose}
        header={title}
        json={data?.drawer}
      />}

      {/* Upload Modal */}
      {uploadModal &&
        <MasterUploadModal
          open={uploadModal}
          closeModal={handleUploadModal}
          docsType={title}
        />
      }

    </div>
  );
};
